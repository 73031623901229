import { Box, Divider, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { TabContext, TabPanel } from '@mui/lab';
import PropTypes from 'prop-types';
import Calendar from 'components/Atoms/Calendar';
import { styles } from './styles';
import ViewPayrollCalendarDetails from './ViewPayheadDetails';
import PayPeriodListing from './PayPeriodListing';

const views = [
  {
    label: 'Basic Info',
    value: 'basic-info',
  },
  {
    label: 'Calendar',
    value: 'calendar',
  },
  {
    label: 'Pay Periods listing',
    value: 'pay-periods-listing',
  },
];

const dateKeys = {
  attendanceInputsCutoffDate: 'Attendance Inputs Cutoff Date',
  payChangesCutOffDate: 'Pay Changes Cutoff Date',
  payDate: 'Pay date',
  // payPeriodEndDate: 'End date',
  // payPeriodStartDate: 'Start date',
  payRunDate: 'Pay run date',
  payRunFinalApprovalDate: 'Pay Run Final Approval Date',
};

function getDarkColor() {
  let color = '#';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 6; i++) {
    color += Math.floor(Math.random() * 10);
  }
  return color;
}

const ViewTabs = ({ payrollCalendar }) => {
  const [view, setView] = useState('basic-info');
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const localEvents = [];
    if (payrollCalendar?.payPeirods?.length) {
      payrollCalendar?.payPeirods.forEach((period) => {
        const color = getDarkColor();
        Object.keys(dateKeys).forEach((dateKey) => {
          if (period[dateKey]) {
            localEvents.push({
              title: `${dateKeys[dateKey]} (Pay Period: ${period.payPeriodIndex})`,
              allDay: true,
              start: new Date(period[dateKey]),
              end: new Date(period[dateKey]),
              id: period.payPeriodIndex,
              color,
            });
          }
        });
      });
    }
    setEvents(localEvents);
  }, [payrollCalendar]);

  return (
    <TabContext value={view}>
      <Box>
        <Tabs value={view} onChange={(event, value) => setView(value)} aria-label="basic tabs example">
          {views.map((option, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Tab disabled={false} label={option.label} value={option.value} key={index} />
          ))}
        </Tabs>
        <Divider sx={styles.divider} />
      </Box>
      <TabPanel sx={styles.tabpanel} value="basic-info">
        <ViewPayrollCalendarDetails payrollCalendar={payrollCalendar} />
      </TabPanel>
      <TabPanel sx={styles.tabpanel} value="calendar">
        <Calendar events={events} />
      </TabPanel>
      <TabPanel sx={styles.tabpanel} value="pay-periods-listing">
        <PayPeriodListing data={payrollCalendar?.payPeirods} />
      </TabPanel>
    </TabContext>
  );
};

ViewTabs.defaultProps = {
  payrollCalendar: {},
};

ViewTabs.propTypes = {
  payrollCalendar: PropTypes.instanceOf(Object),
};

export default ViewTabs;
