import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import TitleWithText from 'components/Atoms/TitleWithText';

const Identifiers = ({ employeeDetail }) => {
  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText title="PAN Number" text={employeeDetail.panNumber} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText title="Aadhaar Number" text={employeeDetail.aadharNumber} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText title="Universal Access Number(UAN)" text={employeeDetail.uan} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText title="EPF Account Number" text={employeeDetail.epfAccountNumber} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText title="ESI Account Number" text={employeeDetail.esiAccountNumber} />
      </Grid>
    </Grid>
  );
};

Identifiers.defaultProps = {
  employeeDetail: {},
};

Identifiers.propTypes = {
  employeeDetail: PropTypes.shape({
    panNumber: PropTypes.string,
    aadharNumber: PropTypes.string,
    uan: PropTypes.string,
    epfAccountNumber: PropTypes.string,
    esiAccountNumber: PropTypes.string,
  }),
};

export default Identifiers;
