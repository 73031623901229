import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import Divider from 'components/Atoms/Divider';
import TitleWithText from 'components/Atoms/TitleWithText';
import Typography from 'components/Atoms/Typography';
import { validateDateElseHyphen } from 'utils/commonUtils';

const ReportActionDetails = ({ reportDetails, formik }) => {
  return (
    <Box padding="2rem 3rem">
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1, mb: 1.5 }}>
        <Box>
          <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
            Report title: {reportDetails?.title}
          </Typography>
        </Box>
      </Box>

      <Grid container columnSpacing={9}>
        <Grid item xs={12} md={3}>
          <TitleWithText title="Employee name" text={reportDetails?.employeeName} />
        </Grid>
        <Grid item xs={12} md={3}>
          <TitleWithText title="Employee ID" text={reportDetails?.employeeId} />
        </Grid>
        <Grid item xs={12} md={3}>
          <TitleWithText
            title="Expense period start date"
            text={validateDateElseHyphen(reportDetails.expensePeriodStartDate, 'DD MMMM, YYYY h:mm A')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TitleWithText
            title="Expense period end date"
            text={validateDateElseHyphen(reportDetails?.expensePeriodEndDate, 'DD MMMM, YYYY h:mm A')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TitleWithText title="Business Purpose" text={reportDetails?.businessPurpose} />
        </Grid>
      </Grid>

      <Divider sx={{ mt: 0.5, mb: 2 }} />

      <Box bgcolor="neutral.100" padding="2rem 3rem" borderRadius="0.75rem">
        <Grid container columnSpacing={9}>
          <Grid item xs={12} md={4}>
            <Typography variant="body2" fontWeight={500} sx={{ mb: 1 }}>
              Total Claim Amount
            </Typography>
            <Typography variant="h3">
              {reportDetails?.currency} {formik?.values?.totalClaimAmount}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="body2" fontWeight={500} sx={{ mb: 1 }}>
              Total Approved Amount
            </Typography>
            <Typography variant="h3">
              {reportDetails?.currency} {formik?.values?.totalApprovedAmount}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Divider sx={{ mt: 2.5, mb: 0.5 }} />
    </Box>
  );
};

ReportActionDetails.defaultProps = {
  reportDetails: {
    title: '',
    employeeName: '',
    employeeId: '',
    expensePeriodStartDate: '',
    expensePeriodEndDate: '',
    department: '',
    designation: '',
    reportingManager: '',
    location: '',
    businessPurpose: '',
    reportId: '',
    reportStatus: '',
    settlementStatus: '',
  },
  formik: {
    values: { totalClaimAmount: 0, totalApprovedAmount: 0 },
  },
};

ReportActionDetails.propTypes = {
  reportDetails: PropTypes.shape({
    title: PropTypes.string,
    employeeName: PropTypes.string,
    employeeId: PropTypes.string,
    expensePeriodStartDate: PropTypes.string,
    expensePeriodEndDate: PropTypes.string,
    department: PropTypes.string,
    designation: PropTypes.string,
    reportingManager: PropTypes.string,
    location: PropTypes.string,
    businessPurpose: PropTypes.string,
    reportId: PropTypes.string,
    reportStatus: PropTypes.string,
    settlementStatus: PropTypes.string,
  }),
  formik: PropTypes.shape({
    values: PropTypes.shape({ totalClaimAmount: PropTypes.number, totalApprovedAmount: PropTypes.number }),
  }),
};

export default ReportActionDetails;
