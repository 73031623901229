import React from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import AddAndEdit from 'components/Organisms/MyPay/PastEmployerTaxes/AddEdit';
import { Box, Skeleton } from '@mui/material';

const AddAndEditTemplate = ({
  breadcrumbData,
  formLoading,
  handleSubmit,
  handleCancel,
  defaultValues,
  isEdit,
  loading,
  draftLoading,
  handleDelete,
}) => {
  return (
    <BlankTemplate
      pageHeading={isEdit ? 'Edit Tax Details' : 'Add New Tax Details'}
      isBreadcrumb
      breadcrumbData={breadcrumbData}
      padding="0 3rem 2rem 3rem"
    >
      {formLoading ? (
        <Box sx={{ p: 4 }}>
          <Skeleton height={42} />
          <Skeleton />
          <Skeleton />
        </Box>
      ) : (
        <AddAndEdit
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          defaultValues={defaultValues}
          isEdit={isEdit}
          loading={loading}
          draftLoading={draftLoading}
          handleDelete={handleDelete}
        />
      )}
    </BlankTemplate>
  );
};

AddAndEditTemplate.defaultProps = {
  breadcrumbData: [
    { id: 1, name: 'Past Employer Taxes', path: '/home/mypay/past-employer-taxes', isActive: false },
    { id: 2, name: 'Add', path: '', isActive: true },
  ],
  formLoading: false,
  handleSubmit: () => {},
  handleCancel: () => {},
  defaultValues: {},
  isEdit: false,
  loading: false,
  draftLoading: false,
  handleDelete: () => {},
};

AddAndEditTemplate.propTypes = {
  breadcrumbData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
  formLoading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  defaultValues: PropTypes.shape({}),
  isEdit: PropTypes.bool,
  loading: PropTypes.bool,
  draftLoading: PropTypes.bool,
  handleDelete: PropTypes.func,
};

export default AddAndEditTemplate;
