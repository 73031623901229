export const miniSidebarStyle = {
  wrapper: {
    mb: 0.5,
    minHeight: 48,
    px: '0.25rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
