import React, { useState } from 'react';
import { Box, Grid, Divider, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import Button from 'components/Atoms/Button';
import InputField from 'components/Atoms/InputField/index';
import { useFormik } from 'formik';
import Dialog from 'components/Molecules/Dialog';
import * as yup from 'yup';
import Typographys from 'components/Atoms/Typography';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { profileStyle } from '../style';

const EditPassword = ({ handleSubmit, handleUpdatePassword, setHandleUpdatePassword }) => {
  const EditPasswordSchema = yup.object().shape({
    newPassword: yup.string().required('Required'),
    reEnterNewPassword: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], 'Password not matched')
      .required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      reEnterNewPassword: '',
    },
    validateOnMount: true,
    onSubmit: () => {
      setHandleUpdatePassword(true);
    },
    validationSchema: EditPasswordSchema,
  });

  const handleCancel = () => {
    formik.resetForm({
      values: {
        newPassword: '',
        reEnterNewPassword: '',
      },
    });
  };

  const theme = useTheme();
  return (
    <Box>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <Typographys variant="h5" color="neutral.800">
          Modify Password
        </Typographys>

        <PerfectScrollbar>
          <Box sx={{ maxHeight: 'calc(100vh - 38rem)' }}>
            <Grid container rowSpacing={2} columnSpacing={2} mt={0} pt={0}>
              <Grid item xs={12} md={4}>
                <InputField
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.newPassword}
                  name="newPassword"
                  id="newPassword"
                  label="New Password"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.reEnterNewPassword}
                  name="reEnterNewPassword"
                  id="reEnterNewPassword"
                  label="Re-Enter New Password"
                  fullWidth
                  type="password"
                />
              </Grid>
            </Grid>

            {Boolean(Object.keys(formik.errors).length) && formik.values.newPassword && (
              <Grid item xs={12} md={12} mt={2}>
                <Typographys variant="body2" mr={1} color="error">
                  {formik.values.newPassword && !formik.values.reEnterNewPassword
                    ? 'Please enter identical password in both boxes'
                    : 'Given passwords do not match! Please enter identical password in both boxes'}
                </Typographys>
              </Grid>
            )}
          </Box>
        </PerfectScrollbar>

        <Box gap={2} sx={profileStyle.btnContainer}>
          <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />
          <Box sx={profileStyle.submitWrapper}>
            <Button size="large" type="button" color="info" variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button size="large" type="submit" isDisable={Object.keys(formik.errors).length}>
              Update Password
            </Button>
          </Box>
        </Box>
      </Box>

      <Dialog
        dialogTitle="Change Password"
        dialogDesc="Are you sure you want to change the password?"
        secondaryBtn="Cancel"
        primaryBtn="Yes, Change"
        handleCancel={() => {
          setHandleUpdatePassword(false);
        }}
        open={handleUpdatePassword}
        handleClose={() => setHandleUpdatePassword(false)}
        handleSecondaryBtn={() => setHandleUpdatePassword(false)}
        handlePrimaryBtn={() => {
          handleSubmit(formik.values.newPassword);
        }}
      />
    </Box>
  );
};
EditPassword.defaultProps = {
  handleSubmit: () => {},
};
EditPassword.propTypes = {
  handleSubmit: PropTypes.func,
};
export default EditPassword;
