import React from 'react';
import { useParams } from 'react-router-dom';
import Typography from 'components/Atoms/Typography';
import { useSelector } from 'react-redux';
import AccessFilters from 'components/Templates/Employee/EmployeeList/AccessFilter';
import { viewAccessFilters } from 'api/employees';
import useFetcher from 'hooks/useFetcher';
import { convertResponseData } from './converter';
import useColumns from './useColumns';
import useActionItems from './useActionItems';
import useServices from './useServices';

const AccessFilter = () => {
  const { userId } = useParams();
  const services = useServices();
  const args = {
    empID: userId,
    refreshPage: services.state.refreshPage,
  };

  const [columns] = useColumns();
  const [accessFilters] = useFetcher(viewAccessFilters, args);
  const transformedData = convertResponseData(accessFilters);

  const [handleActionItemClick] = useActionItems(services);

  const { selectedEmployee } = useSelector((state) => {
    return state.employees;
  });

  const employeeName = `${selectedEmployee?.firstName} ${selectedEmployee?.lastName}`;

  if (accessFilters.error !== null) {
    return (
      <Typography variant="h6">
        {accessFilters?.error?.response?.data?.message ||
          `Error while loading payhead details for employee id : ${userId}.`}
      </Typography>
    );
  }
  return (
    <AccessFilters
      columns={columns}
      rows={transformedData.filterList}
      roleBasedMenu={handleActionItemClick}
      showActionList
      showRowSelector="false"
      statusOptions={transformedData.statusOptions}
      typeOptions={transformedData.typeOptions}
      subTypeOptions={transformedData.subTypeOptions}
      entitiesOptions={transformedData.entitiesOptions}
      handleSubmit={services.addAccessFilter}
      open={services.state.open}
      handleOpenDialog={services.handleOpenDialog}
      isModify={services.state.isModify}
      initialValues={services.state.initialValues}
      modifyAccessFilter={services.modifyAccessFilter}
      deleteAccessFilter={services.deleteAccessFilter}
      handleOpenDeleteDialog={services.handleOpenDeleteDialog}
      modifyAccessFilterAction={services.modifyAccessFilterAction}
      openDeleteDialog={services.state.openDeleteDialog}
      employeeID={userId}
      employeeName={employeeName}
      loading={accessFilters?.isLoading}
    />
  );
};

export default AccessFilter;
