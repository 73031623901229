import React from 'react';
import CommonCard from 'components/Molecules/CommonCard';
import { Box } from '@mui/material';
import BarCharts from 'components/Organisms/Charts/BarCharts';
import useFetchDashboardByCategory from './useFetchDashboardByCateogy';

const CategoryGraph = ({ chartOptions, isTeam }) => {
  const [{ data }] = useFetchDashboardByCategory();
  const barGraphData = data?.chartData?.length
    ? data?.chartData.map((item) => ({ category: item[0], value: item[1] }))
    : [];

  const XAxisData = {
    dataKey: 'category',
    value: 'Category',
    position: 'bottom',
    color: 'neutral',
  };

  const YAxisData = {
    value: 'Amount',
    position: 'top',
  };

  const barInfoData = [
    {
      name: '',
      dataKey: 'value',
      stackId: 'value',
      color: 'secondary',
      colorCode: 400,
    },
  ];

  return isTeam ? (
    <Box sx={{ mt: 2 }}>
      <CommonCard
        cardHeaderTitle="Category Wise Expenses"
        isHeaderFilter={false}
        minWidthHeaderDropdown="17rem"
        bodyContent={
          <BarCharts
            chartOptions={chartOptions}
            chartData={barGraphData}
            barData={barInfoData}
            legendOptions={{
              layout: 'horizontal',
              verticalAlign: 'bottom',
              align: 'center',
              iconType: 'circle',
            }}
            XAxisData={XAxisData}
            YAxisData={YAxisData}
          />
        }
        isFooter={false}
        cardBodyMinHeight="32rem"
        cardBodyPadding="5rem 0rem 1rem 1rem"
      />
    </Box>
  ) : (
    <div />
  );
};

export default CategoryGraph;
