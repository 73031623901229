import React from 'react';
import { Typography } from '@mui/material';
import { validateDateElseHyphen } from 'utils/commonUtils';
import { ChallanTableMenu } from './tableMenu';

const columns = [
  {
    fieldName: 'periodStartDate',
    name: 'i',
    label: 'Period Start Date',
    type: 'number',
    formatValue: ({ row, column }) => {
      return <Typography variant="body2">{validateDateElseHyphen(row[column.fieldName], 'DD MMMM YYYY')}</Typography>;
    },
  },
  {
    fieldName: 'periodEndDate',
    name: 'dn',
    label: 'Period End Date',
    type: 'string',
    formatValue: ({ row, column }) => (
      <Typography variant="body2">{validateDateElseHyphen(row[column.fieldName], 'DD MMMM YYYY')}</Typography>
    ),
  },
  {
    fieldName: 'amountDue',
    name: 'dn',
    label: 'Amount Due',
    type: 'string',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'amountPaid',
    name: 'pt',
    label: 'Amount Paid',
    type: 'string',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'dateOfPayment',
    name: 'el',
    label: 'Date Of Payment',
    type: 'string',
    formatValue: ({ row, column }) => (
      <Typography variant="body2">{validateDateElseHyphen(row[column.fieldName], 'DD MMMM YYYY')}</Typography>
    ),
  },
  {
    fieldName: 'modeOfPayment',
    name: 'et',
    label: 'Mode Of Payment',
    type: 'string',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'action',
    name: 'action',
    label: 'Actions',
    sticky: true,
    filter: true,
    formatValue: ({ row, column, onActionCallback }) => (
      <ChallanTableMenu row={row} column={column} onActionCallback={onActionCallback} />
    ),
  },
];

const useColumns = () => {
  return [columns];
};

export default useColumns;
