import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ActionQueryTemplate from 'components/Templates/TaskAndQueries/ActionQuery';
import { Box, Skeleton, Typography } from '@mui/material';
import useServices from '../common/useServices';
import useMenuItems from '../View/useMenuItems';

const EscalateQuery = () => {
  const { id } = useParams();
  const services = useServices();
  const [handleActionItemClick] = useMenuItems(services);
  const [escalateResult, setEscalateResult] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await services.escalateQueryService(id);
        setEscalateResult(res);
      } catch (error) {
        setEscalateResult(error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (services?.state?.queryDetails?.isLoading) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (escalateResult?.response?.status !== 200) {
    return (
      <Typography variant="h6">{escalateResult?.response?.data?.warning || 'Failed to escalate the query.'}</Typography>
    );
  }

  return (
    <ActionQueryTemplate
      handleSubmit={services.escalateQueryActionService}
      reportDetails={services.state.queryDetails?.queryDetails}
      handleEdit={() => services.modifyQueryNavigationService({ id })}
      actionMenuList={handleActionItemClick}
      isRecall={false}
      handleRecall={() => {}}
      handleAttachmentDownload={services.downloadDocument}
      showEdit
      formData={services.state.queryDetails.formData}
      handleCancel={services.handleReassignCancel}
      isReopen
      isEscalate
      auditLogData={[]}
    />
  );
};

export default EscalateQuery;
