export const attendanceStyle = {
  clockButtonWrap: {
    whiteSpace: 'pre',
    '@media (max-width: 900px)': {
      padding: '1rem 1.25!important',
      lineHeight: '2rem!important',
      minWidth: 'auto',
      order: '3',
    },
  },

  clockButton: {
    marginRight: '1.25rem;',
  },

  timerWrapper: {
    display: 'flex',
    width: '50%',
    justifyContent: 'space-around',
  },
};
