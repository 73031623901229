import React, { useEffect, useState } from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import ProfileEditVisaDetails from 'components/Organisms/Profile/VisaDetails/Edit';
import getAttachment from 'utils/getAttachments';
import { useThunk } from 'hooks/useThunk';
import { useSelector } from 'react-redux';
import { fetchVisaDetails } from 'store';
import { Typography, Skeleton, Box } from '@mui/material';
import useToast from 'components/Provider/useToast';
import { formDataApi } from 'api/api';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

const breadcrumbData = [
  { id: 1, name: 'Visa Details', path: '/profile/visa-details', isActive: false },
  { id: 2, name: 'Edit', path: '/edit', isActive: true },
];

const ProfileEditVisaDetailsPage = () => {
  const addToast = useToast();
  const navigate = useNavigate();
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [doFetchVisaDetails, isLoadingVisaDetails, loadingVisaDetailsError] = useThunk(fetchVisaDetails);
  const { visaDetails = [], employeeProfileInstance = [] } = useSelector((state) => state.profile);

  useEffect(() => {
    const query = new URLSearchParams({});
    doFetchVisaDetails(query);
  }, [doFetchVisaDetails, isInEditMode]);

  const editOptions = {
    defaultValue: {
      visa:
        visaDetails?.length > 0
          ? visaDetails.map((item) => ({
              visaId: item?.id || '',
              issuingCountry: item?.issuingCountry || '',
              placeOfIssue: item?.placeOfIssue || '',
              validFrom: item?.validFrom ? dayjs(item?.validFrom) : '',
              validTo: item?.validTo ? dayjs(item?.validTo) : '',
              issueDate: item?.issueDate ? dayjs(item?.issueDate) : '',
              referenceNumber: item?.referenceNumber || '',
              status: item?.status || '',
              comments: item?.comments || '',
              attachment: item?.documents[0]?.fileName,
              isNew: false,
            }))
          : [],
    },
  };

  const dateFormatChange = (value) => {
    return value ? dayjs(value).format('MM/DD/YYYY') : '';
  };

  // eslint-disable-next-line consistent-return
  const configureFormDataAndUpdate = async (visa) => {
    if (employeeProfileInstance) {
      const formData = new FormData();
      formData.append('id', employeeProfileInstance?.employee?.userId);
      visa.forEach((payload) => {
        const index = visa.indexOf(payload);
        formData.append(`visaRecords[${index}].issuingCountry`, payload?.issuingCountry || '');
        formData.append(`visaRecords[${index}].placeOfIssue`, payload?.placeOfIssue || '');
        formData.append(`visaRecords[${index}].validFrom`, dateFormatChange(payload?.validFrom));
        formData.append(`visaRecords[${index}].validTo`, dateFormatChange(payload?.validTo));
        formData.append(`visaRecords[${index}].issueDate`, dateFormatChange(payload?.issueDate));
        formData.append(`visaRecords[${index}].referenceNumber`, payload?.referenceNumber || '');
        formData.append(`visaRecords[${index}].status`, payload?.status || '');
        formData.append(`visaRecords[${index}].comments`, payload?.comments || '');
        if (payload?.attachments) {
          formData.append(`visaRecords[${index}].attachment`, payload?.attachments);
        } else if (!payload?.isNew) {
          formData.append(`visaRecords[${index}].id`, visaDetails[index]?.id);
        }
      });
      formData.append(`visaRecordCtr`, visa?.length);
      return formData;
    }
  };

  const handleCancel = () => {
    navigate('/profile/visa-details');
    setIsInEditMode(false);
  };

  const handleSubmit = async (e) => {
    const bodyFormData = await configureFormDataAndUpdate(e?.visa);

    try {
      setLoading(true);
      const response = await formDataApi.post(`restEmpIndex/updateVisaDetailsAction`, bodyFormData);
      if (response?.data?.status !== 'ERROR') {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || 'Visa details updated successfully',
        });
        setIsInEditMode(false);
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || 'Failed to update visa details',
      });
    } finally {
      setLoading(false);
      navigate(`/profile/visa-details`);
    }
  };

  if (isLoadingVisaDetails) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (loadingVisaDetailsError) {
    return (
      <Typography variant="h6">{loadingVisaDetailsError?.message || 'Error while fetching visa details.'}</Typography>
    );
  }

  return (
    <BlankTemplate
      pageHeading="Edit Visa Details"
      isBreadcrumb
      breadcrumbData={breadcrumbData}
      padding="0 3rem 2rem 3rem"
    >
      <ProfileEditVisaDetails
        defaultValue={editOptions.defaultValue}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        loading={loading}
      />
    </BlankTemplate>
  );
};

export default ProfileEditVisaDetailsPage;
