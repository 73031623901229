import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import PieCharts from 'components/Organisms/PieCharts';
import GraphColor from 'utils/GraphColor';

const LeaveBalanceCard = ({
  title,
  boxBackground,
  boxPadding,
  boxBorderRadius,
  count,
  totalCount,
  data,
  chartColor,
}) => {
  return (
    <Box
      bgcolor={boxBackground}
      padding={boxPadding}
      borderRadius={boxBorderRadius}
      sx={{ display: 'flex', gap: 3, flex: 1 }}
    >
      <PieCharts
        width={87}
        height={87}
        paddingAngle={0}
        innerRadius={25}
        outerRadius={40}
        data={data}
        colors={chartColor}
      />
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'end', gap: 1, mb: 1.5 }}>
          <Typographys variant="h1" fontWeight="400" color="secondary.400" sx={{ lineHeight: '1' }}>
            {count}
          </Typographys>
          <Typographys variant="body1" fontWeight="400" color="secondary.600">
            Out of {totalCount}
          </Typographys>
        </Box>
        <Typographys variant="body1" fontWeight="600" color="secondary.600">
          {title}
        </Typographys>
      </Box>
    </Box>
  );
};

LeaveBalanceCard.defaultProps = {
  boxBackground: 'info.200',
  boxPadding: '2rem',
  boxBorderRadius: '0.75rem',
  title: 'Casual Leaves Remaining',
  count: '12',
  totalCount: '24',
  data: [],
  chartColor: GraphColor('leaveGraphs'),
};
LeaveBalanceCard.propTypes = {
  boxBackground: PropTypes.string,
  boxPadding: PropTypes.string,
  boxBorderRadius: PropTypes.string,
  title: PropTypes.string,
  count: PropTypes.string,
  totalCount: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  chartColor: PropTypes.arrayOf(PropTypes.string),
};

export default LeaveBalanceCard;
