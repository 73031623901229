export const auditTrailStyle = {
  auditTrailCard: {
    borderBottom: '0.125rem solid ',
    borderColor: 'other.200',
    padding: '2.5rem 2.5rem',
    '&:first-child': {
      pt: 0,
      marginTop: '2.5rem',
    },

    '&:last-child': {
      borderBottom: 'none',
    },
  },
};
