import { createSlice } from '@reduxjs/toolkit';
import {
  fetchMyExpensesDashboardDetails,
  fetchCreateExpense,
  fetchMyExpenseDetails,
} from 'store/thunks/expenses/expenses';

const expenses = createSlice({
  name: 'expenses',
  initialState: {
    barChartAggregateExpensesDataColumns: [],
    barChartAggregateExpensesData: [],
    aggregateExpensedAmountsStatus: [],
    barChartAggregateCatWiseExpensesDataColumns: [],
    barChartAggregateCatWiseExpensesData: [],
    defaultCurrency: '',
    myTeamExpenseRecords: {
      count: 0,
      data: [],
    },
    myExpensesRecords: {
      count: 0,
      data: [],
    },
    createExpenseData: [],
    myExpenseDetails: [],
    allExpenseRecords: {
      count: 0,
      data: [],
    },
  },
  reducers: {
    updateMyExpensesRecords(state, action) {
      const { categoriesList = [], totalCount } = action.payload.data;
      state.myExpensesRecords.data = categoriesList;
      state.myExpensesRecords.count = totalCount;
    },
    updateMyTeamExpenseRecords(state, action) {
      const { results = [], totalCount } = action.payload;
      state.myTeamExpenseRecords.data = results;
      state.myTeamExpenseRecords.count = totalCount;
    },
    updateAllExpenseRecords(state, action) {
      const { results = [], totalCount } = action.payload;
      state.allExpenseRecords.data = results;
      state.allExpenseRecords.count = totalCount;
    },
  },

  extraReducers(builder) {
    builder.addCase(fetchMyExpensesDashboardDetails.fulfilled, (state, action) => {
      const { data = [] } = action.payload;
      const {
        barChartAggregateExpensesDataColumns = [],
        barChartAggregateExpensesData = [],
        aggregateExpensedAmountsStatusWiseMap = [],
        barChartAggregateCatWiseExpensesDataColumns = [],
        barChartAggregateCatWiseExpensesData = [],
      } = data;

      state.barChartAggregateExpensesDataColumns = barChartAggregateExpensesDataColumns;
      state.barChartAggregateExpensesData = barChartAggregateExpensesData;
      state.aggregateExpensedAmountsStatus = aggregateExpensedAmountsStatusWiseMap;
      state.barChartAggregateCatWiseExpensesDataColumns = barChartAggregateCatWiseExpensesDataColumns;
      state.barChartAggregateCatWiseExpensesData = barChartAggregateCatWiseExpensesData;
      state.defaultCurrency = data?.defaultCurrency || '';
    });

    builder.addCase(fetchMyExpenseDetails.fulfilled, (state, action) => {
      state.myExpenseDetails = action.payload?.data?.data;
    });

    builder.addCase(fetchCreateExpense.fulfilled, (state, action) => {
      state.createExpenseData = action.payload?.data?.data;
    });
  },
});

export const { updateMyTeamExpenseRecords, updateMyExpensesRecords, updateAllExpenseRecords } = expenses.actions;

export default expenses.reducer;
