import PropTypes from 'prop-types';
import React from 'react';
import MuiTableRow from '@mui/material/TableRow';

const TableRow = ({ children, hover, selected, rowColor }) => {
  return (
    <MuiTableRow
      hover={hover}
      selected={selected}
      sx={{
        ...(rowColor && { backgroundColor: rowColor }),
      }}
    >
      {children}
    </MuiTableRow>
  );
};

TableRow.propTypes = {
  hover: PropTypes.bool,
  selected: PropTypes.bool,
  children: PropTypes.node.isRequired,
  rowColor: PropTypes.string,
};

TableRow.defaultProps = {
  hover: false,
  selected: false,
  rowColor: '',
};
export default TableRow;
