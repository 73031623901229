import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Typographys from 'components/Atoms/Typography';

const TitleWithText = ({
  title,
  titleColor,
  textAlign,
  titleVariant,
  text,
  padding,
  textColor,
  textVariant,
  children,
}) => {
  return (
    <Box padding={padding}>
      <Box mb={0.5}>
        <Typographys align={textAlign} color={titleColor} variant={titleVariant}>
          {title}
        </Typographys>
      </Box>
      <Typographys align={textAlign} color={textColor} variant={textVariant}>
        {text}
        {children}
      </Typographys>
    </Box>
  );
};

TitleWithText.defaultProps = {
  title: ' ',
  titleColor: 'neutral.600',
  titleVariant: 'body2',
  text: '',
  textColor: 'neutral.800',
  textVariant: 'subtitle2',
  padding: '1.5rem 3rem 1.5rem 0',
  textAlign: 'inherit',
  children: '',
};

TitleWithText.propTypes = {
  title: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  titleColor: PropTypes.string,
  titleVariant: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  textColor: PropTypes.string,
  textVariant: PropTypes.string,
  padding: PropTypes.string,
  textAlign: PropTypes.string,
  children: PropTypes.node,
};

export default TitleWithText;
