import { Box, Grid, Card, CardContent, Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useToast from 'components/Provider/useToast';
import { listPayrollCalendars } from 'api/payrollCalendar';
import { formDataApi } from 'api/api';
import useFetcher from 'hooks/useFetcher';
import { fetchChallanById } from 'api/challan';
import { styles } from './styles';
import Form from './form';

const AddEditPayhead = (props) => {
  const params = useParams();
  const [payheadDetails, setPayheadDetails] = useState({
    data: {},
    isLoading: false,
  });
  const [calendars, setCalendars] = useState({
    data: {},
    isLoading: false,
  });
  const location = useLocation();
  const isEditMode = location.pathname.includes('edit');

  const { id } = useParams();

  const args = {
    challanId: id,
    isEditMode,
  };

  const [payhead] = useFetcher(fetchChallanById, args);

  const addToast = useToast();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = new URLSearchParams({});
        query.append('offset', 0);
        query.append('max', 100);
        const response = await listPayrollCalendars(query);
        if (response.payrollCalendarInstanceList) {
          setCalendars(response.payrollCalendarInstanceList);
        }
      } catch (error) {
        console.log(error);
      }

      setPayheadDetails({ ...payheadDetails, isLoading: true });
      const url = 'restPayIndex/createChallan';
      try {
        const response = await formDataApi.post(url);
        if (response?.data?.data) {
          setPayheadDetails({ data: response.data.data, isLoading: false });
        }
      } catch (err) {
        addToast({ type: 'error', title: 'ERROR', message: err?.message });
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, isEditMode, addToast]);

  return (
    <Box sx={styles.growBox}>
      <Container maxWidth="lg" sx={styles.pageWrapper}>
        <Grid container direction="column" spacing={2}>
          <Grid item container justifyContent="flex-start" alignItems="center" sx={{ pb: 1.5 }}>
            <Grid item>
              <Typography variant="h6" color="text.primary">
                {isEditMode ? 'Edit Challan' : 'Add Challan'}
              </Typography>
            </Grid>
          </Grid>
          <Grid item sx={{ pt: '0px !important' }}>
            <Card {...props}>
              <CardContent sx={{ maxHeight: 'calc(100vh - 140px)', overflowY: 'auto' }}>
                <Grid item sx={{ pt: 1 }}>
                  <Form
                    results={payhead?.data?.data?.challanInstance}
                    isEditMode={isEditMode}
                    payheadDetails={payheadDetails?.data || {}}
                    calendars={calendars}
                  />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AddEditPayhead;
