import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import TitleWithText from 'components/Atoms/TitleWithText';
import Typographys from 'components/Atoms/Typography';
import { onBoardingStyle } from '../style';

const ContactDetail = ({ contactDetails }) => {
  return (
    <Box px={3}>
      <Grid container columnSpacing={8} sx={onBoardingStyle.contactWrapper}>
        <Grid item xs={12} sm={6}>
          <Grid container rowSpacing={1} columnSpacing={1}>
            <Grid item xs={12} mb={1}>
              <Typographys variant="h5" color="neutral.700">
                Contact Details
              </Typographys>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TitleWithText title="Mobile Number" text={contactDetails?.mobile} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TitleWithText title="Email Address" text={contactDetails?.email} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container rowSpacing={1} columnSpacing={1}>
            <Grid item xs={12} mb={1}>
              <Typographys variant="h5" color="neutral.700">
                Emergency Contact
              </Typographys>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TitleWithText title="Name" text={contactDetails?.emergencyContactName} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TitleWithText title="Relation" text={contactDetails?.emergencyContactRelation} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TitleWithText title="Mobile Number" text={contactDetails?.emergencyContactMobile} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TitleWithText title="Email ID" text={contactDetails?.emergencyContactEmail} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container flexDirection="column">
            <Grid item>
              <Box mt={6} mb={2}>
                <Typographys variant="h5" color="neutral.700">
                  Permanent Residential Address
                </Typographys>
              </Box>
            </Grid>
            <Grid item>
              <TitleWithText title="Location Name (Optional)" text={contactDetails?.permanentLocation} />
            </Grid>
            <Grid item>
              <Box mb={0.5}>
                <Typographys color="neutral.600" variant="body2">
                  Address
                </Typographys>
              </Box>
              <Typographys color="neutral.700" variant="body2">
                {contactDetails?.permanentAddress1}
              </Typographys>
              <Typographys color="neutral.700" variant="body2">
                {contactDetails?.permanentAddress2}
              </Typographys>
              <Typographys color="neutral.700" variant="body2">
                {contactDetails?.permanentAddress3}
              </Typographys>
              <Typographys color="neutral.700" variant="body2">
                <Box component="div" sx={onBoardingStyle.addressDiv}>
                  {`${contactDetails?.permanentCity},`}
                </Box>

                <Box component="div" sx={onBoardingStyle.addressDiv}>
                  {contactDetails?.permanentState}
                </Box>

                <Box component="div" sx={onBoardingStyle.addressDiv}>
                  {contactDetails?.permanentCountry}
                </Box>

                <Box component="div" sx={onBoardingStyle.addressDiv}>
                  {contactDetails?.permanentPinOrZip}
                </Box>
              </Typographys>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container flexDirection="column">
            <Grid item>
              <Box mt={6} mb={2}>
                <Typographys variant="h5" color="neutral.700">
                  Present Communication Address
                </Typographys>
              </Box>
            </Grid>
            <Grid item>
              <TitleWithText title="Location Name (Optional)" text={contactDetails?.presentLocation} />
            </Grid>
            <Grid item>
              <Box mb={0.5}>
                <Typographys color="neutral.600" variant="body2">
                  Address
                </Typographys>
              </Box>
              <Typographys color="neutral.700" variant="body2">
                {contactDetails?.presentAddress1}
              </Typographys>
              <Typographys color="neutral.700" variant="body2">
                {contactDetails?.presentAddress2}
              </Typographys>
              <Typographys color="neutral.700" variant="body2">
                {contactDetails?.presentAddress3}
              </Typographys>
              <Typographys color="neutral.700" variant="body2">
                <Box component="div" sx={onBoardingStyle.addressDiv}>
                  {`${contactDetails?.presentCity},`}
                </Box>

                <Box component="div" sx={onBoardingStyle.addressDiv}>
                  {contactDetails?.presentState}
                </Box>

                <Box component="div" sx={onBoardingStyle.addressDiv}>
                  {contactDetails?.presentCountry}
                </Box>

                <Box component="div" sx={onBoardingStyle.addressDiv}>
                  {contactDetails?.presentPinOrZip}
                </Box>
              </Typographys>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container flexDirection="column">
            <Grid item>
              <Box mt={6} mb={2}>
                <Typographys variant="h5" color="neutral.700">
                  Office Address
                </Typographys>
              </Box>
            </Grid>
            <Grid item>
              <TitleWithText title="Location Name (Optional)" text={contactDetails?.officeLocation} />
            </Grid>
            <Grid item>
              <Box mb={0.5}>
                <Typographys color="neutral.600" variant="body2">
                  Address
                </Typographys>
              </Box>

              <Typographys color="neutral.700" variant="body2">
                {contactDetails?.officeAddress1}
              </Typographys>
              <Typographys color="neutral.700" variant="body2">
                {contactDetails?.officeAddress2}
              </Typographys>
              <Typographys color="neutral.700" variant="body2">
                {contactDetails?.officeAddress3}
              </Typographys>
              <Typographys color="neutral.700" variant="body2">
                <Box component="div" sx={onBoardingStyle.addressDiv}>
                  {`${contactDetails?.officeCity},`}
                </Box>

                <Box component="div" sx={onBoardingStyle.addressDiv}>
                  {contactDetails?.officeState}
                </Box>

                <Box component="div" sx={onBoardingStyle.addressDiv}>
                  {contactDetails?.officeCountry}
                </Box>

                <Box component="div" sx={onBoardingStyle.addressDiv}>
                  {contactDetails?.officePinOrZip}
                </Box>
              </Typographys>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

ContactDetail.defaultProps = {
  contactDetails: {
    mobile: '',
    email: '',
    maritalStatus: '',
    emergencyContactName: '',
    emergencyContactRelation: '',
    emergencyContactMobile: '',
    emergencyContactEmail: '',
    permanentLocation: '',
    permanentAddress1: '',
    permanentAddress2: '',
    permanentAddress3: '',
    permanentAddress4: '',
    presentLocation: '',
    presentAddress1: '',
    presentAddress2: '',
    presentAddress3: '',
    presentAddress4: '',
    officeLocation: '',
    officeAddress1: '',
    officeAddress2: '',
    officeAddress3: '',
    officeAddress4: '',
  },
};

ContactDetail.propTypes = {
  contactDetails: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ContactDetail;
