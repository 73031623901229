import React, { useState } from 'react';
import { Box, Button, IconButton, Menu, MenuList, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { usePopover } from 'hooks/use-popover';
import { DotsVertical as DotsVerticalIcon } from 'assets/icons/dots-vertical';
import PropTypes from 'prop-types';
import useToast from 'components/Provider/useToast';
import { useDispatch, useSelector } from 'react-redux';
import { updatePayrollCalendars } from 'store/slices/company/salary/payrollCalendars';
import { deletePayrollCalendar, listPayrollCalendars } from 'api/payrollCalendar';
import { hasPermission } from 'utils/permissions';
import { styles } from './styles';

export const PayrollCalendarTableMenu = ({ row }) => {
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const [openDialog, setOPenDialog] = useState(false);
  const [action, setAction] = useState('');
  const addToast = useToast();
  const authorities = useSelector((state) => state.session.user.authorities);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDialogClose = () => {
    setOPenDialog(false);
  };

  const handleDelete = async () => {
    try {
      const response = await deletePayrollCalendar(row.id);
      if (response.status === 200) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Payroll calendar deleted successfully`,
        });
        const query = new URLSearchParams({});
        query.append('offset', 0);
        query.append('max', 10);
        const listResponse = await listPayrollCalendars(query);
        if (listResponse) {
          dispatch(updatePayrollCalendars(listResponse));
        }
      }
    } catch (err) {
      addToast({ type: 'error', title: 'ERROR', message: err?.message || 'Failed to delete payroll calendar' });
    }
    handleClose();
    setOPenDialog(false);
  };

  const actionItems = [
    {
      label: 'Edit',
      type: 'modify_payrollCalendar',
      permissions: ['ROLE_BASE_PERMISSION_PAY_INDEX_UPDATE_PAYROLL_CALENDAR'],
      icon: <EditIcon />,
    },
    {
      label: 'Delete',
      type: 'delete',
      icon: <DeleteIcon />,
      permissions: ['ROLE_BASE_PERMISSION_PAY_INDEX_DELETE_PAYROLL_CALENDAR'],
      onClick: handleDelete,
    },
  ];

  const handleMenuItemClick = (actionInput) => {
    if (actionInput.type === 'modify_payrollCalendar') {
      navigate(`/company/salary/payrollCalendar/${row.id}/edit`);

      return;
    }

    setOPenDialog(true);

    setAction(() => {
      switch (actionInput.type) {
        case 'delete':
          return {
            title: 'Delete Payroll Calendar',
            confirmation: `Are you sure you want to delete payroll calendar - ${row?.name}?`,
            callback: actionInput.callback,
            ...actionInput,
          };
        default:
          return actionInput;
      }
    });
  };

  const renderDialogContent = (actionInput) => (
    <DialogContent sx={styles.tableMenuDialog}>
      <Box sx={styles.tableMenuDialog}>
        <DialogTitle>{actionInput.title}</DialogTitle>
        {actionInput.confirmation && <DialogContentText>{actionInput.confirmation}</DialogContentText>}
      </Box>
      {actionInput.children}
      <DialogActions sx={{ marginTop: '2.5rem', justifyContent: 'center' }}>
        <Button size="medium" variant="outlined" color="info" onClick={handleDialogClose}>
          Cancel
        </Button>
        <Button size="medium" variant="contained" color="error" onClick={actionInput.callback}>
          Delete
        </Button>
      </DialogActions>
    </DialogContent>
  );

  const renderMenuActions = () =>
    actionItems
      .filter((item) => !!hasPermission(authorities, item.permissions))
      .map((actionInput) => (
        <MenuItem
          key={actionInput.type}
          onClick={() =>
            handleMenuItemClick({
              label: actionInput.label,
              type: actionInput.type,
              callback: actionInput.onClick,
            })
          }
        >
          <ListItemIcon>{actionInput.icon}</ListItemIcon>
          <ListItemText variant="body2">{actionInput.label}</ListItemText>
        </MenuItem>
      ));

  return (
    <>
      <IconButton onClick={handleOpen} ref={anchorRef} color="primary">
        <DotsVerticalIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList dense>{renderMenuActions()}</MenuList>
      </Menu>
      <Dialog open={openDialog} onClose={() => setOPenDialog(false)}>
        {renderDialogContent(action)}
      </Dialog>
    </>
  );
};

PayrollCalendarTableMenu.propTypes = {
  row: PropTypes.shape(PropTypes.node).isRequired,
};
