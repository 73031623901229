import React from 'react';
import PropTypes from 'prop-types';
import Chips from 'components/Molecules/Chip';
import { statusColor } from 'utils/statusColor';
import { badgeColor } from 'utils/badgeColor';
import { Box } from '@mui/material';
import TableExtended from 'components/Molecules/TableExtended';
import ProfileListItem from 'components/Molecules/ProfileListItem';
import Typographys from 'components/Atoms/Typography';
import { teamAttendanceStyle } from '../style';

const TeamAttendanceTable = ({
  attendanceTableDataColumn,
  attendanceTableDataRow,
  keyColumn,
  sortDirection,
  sortBy,
  onSortChange,
  count,
  page,
  onPageChange,
  onRowClick,
  selectedItems,
  onSelect,
  onSelectAll,
  loading,
}) => {
  const getDayData = (day) => {
    return (
      <Box>
        {day === 'Holiday' || day === 'Absent' ? (
          <Chips label={day} isShowIcon={false} color={statusColor(day)} />
        ) : (
          <Typographys variant="body2" mr={1} color="neutral.800">
            {day}
          </Typographys>
        )}
      </Box>
    );
  };

  return (
    <Box sx={teamAttendanceStyle.teamAttendanceTableWrapper}>
      <TableExtended
        rows={attendanceTableDataRow.map((i) => {
          return {
            ...i,
            empName: (
              <ProfileListItem
                userName={i.empName}
                designation={i.empId}
                avatarSrc={i.empImgSrc}
                isShortName
                date={null}
                badge
                badgeColor={badgeColor(i.status)}
              />
            ),
          };
        })}
        loading={loading}
        columns={attendanceTableDataColumn}
        keyColumn={keyColumn}
        sortDirection={sortDirection}
        sortBy={sortBy}
        onSortChange={onSortChange}
        count={count}
        page={page}
        onPageChange={onPageChange}
        showPagination
        onRowClick={onRowClick}
        showRowSelector
        selectedItems={selectedItems}
        onSelect={onSelect}
        onSelectAll={onSelectAll}
      />
    </Box>
  );
};

TeamAttendanceTable.defaultProps = {
  attendanceTableDataColumn: [],
  attendanceTableDataRow: [],
  keyColumn: 'id',
  sortDirection: 'desc',
  sortBy: 'id',
  onSortChange: () => {},
  page: 1,
  onPageChange: () => {},
  count: 4,
  onRowClick: () => {},
  selectedItems: [],
  onSelect: () => {},
  onSelectAll: () => {},
  loading: false,
};
TeamAttendanceTable.propTypes = {
  attendanceTableDataColumn: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),
  attendanceTableDataRow: PropTypes.arrayOf(
    PropTypes.shape({
      empName: PropTypes.string,
      empImgSrc: PropTypes.string,
      status: PropTypes.string,
      empId: PropTypes.string,
      period: PropTypes.string,
      day1: PropTypes.string,
      day2: PropTypes.string,
      day3: PropTypes.string,
      day4: PropTypes.string,
      day5: PropTypes.string,
      day6: PropTypes.string,
    }),
  ),
  keyColumn: PropTypes.string,
  onSortChange: PropTypes.func,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  count: PropTypes.number,
  onRowClick: PropTypes.func,
  selectedItems: PropTypes.arrayOf,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  loading: PropTypes.bool,
};

export default TeamAttendanceTable;
