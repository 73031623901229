import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Box, Grid, Divider } from '@mui/material';
import { useDispatch } from 'react-redux';
import Button from 'components/Atoms/Button';
import TitleWithText from 'components/Atoms/TitleWithText';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import DatePicker from 'components/Atoms/Datepicker';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Checkbox from 'components/Atoms/Checkbox';
import InputField from 'components/Atoms/InputField/index';
import { updateEmployeeSeparationRequest } from 'store/slices/company/employees/separations';
import * as yup from 'yup';
import dayjs from 'dayjs';
import { separationStyle } from '../style';

const EmpActionRequest = ({ exitDetails, defaultValues, handleSubmit }) => {
  const dispatch = useDispatch();

  const ActionRequestSchema = yup.object().shape({
    comments: yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      comments: '',
      approvedLastWorkingDay: defaultValues?.approvedLastWorkingDay || '',
      eligibleForRehire: '',
    },

    validationSchema: ActionRequestSchema,
    validateOnMount: true,
    onSubmit: handleSubmit,
  });

  const handleDateChange = (date, fieldName) => {
    formik.setFieldValue(fieldName, date);
  };

  const handleEmployeeSeparationRequest = (request) => {
    dispatch(updateEmployeeSeparationRequest(request));
  };

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 40rem)' }}>
          <Grid mt={2} container columnSpacing={9}>
            <Grid item xs={12} md={4}>
              <TitleWithText title="Employee" text={exitDetails?.employeeName} />
            </Grid>

            <Grid item xs={12} md={4}>
              <TitleWithText title="Separation Type" text={exitDetails?.separationType} />
            </Grid>

            <Grid item xs={12} md={4}>
              <TitleWithText title="Requested Relieving Date" text={exitDetails?.relievingDate} />
            </Grid>

            <Grid item xs={12} md={4}>
              <TitleWithText title="Resignation Status" text={exitDetails?.resignationStatus} />
            </Grid>

            <Grid item xs={12} md={4} mt={2}>
              <DatePicker
                error={
                  Boolean(formik.touched.approvedLastWorkingDay && formik.errors.approvedLastWorkingDay) ||
                  (formik.touched.approvedLastWorkingDay && !formik.values.approvedLastWorkingDay)
                }
                helperText={formik.touched.approvedLastWorkingDay && formik.errors.approvedLastWorkingDay}
                onChange={(date) => handleDateChange(date, 'approvedLastWorkingDay')}
                onBlur={formik.handleBlur}
                value={formik.values?.approvedLastWorkingDay ? dayjs(formik.values?.approvedLastWorkingDay) : null}
                name="approvedLastWorkingDay"
                id="approvedLastWorkingDay"
                label="Approved Last Working Date"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TitleWithText title="Notice Period" text={exitDetails?.noticePeriod} />
            </Grid>
          </Grid>

          <Divider sx={separationStyle.Divider} />

          <Grid mt={2} container columnSpacing={9}>
            <Grid item xs={12} md={4}>
              <TitleWithText title="Exit Interview Date" text={exitDetails?.interviewDate} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TitleWithText title="Exit Interviewer" text={exitDetails?.interviewer} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TitleWithText title="Last Working Day (Per Policy)" text={exitDetails?.asPerPolicyLWD} />
            </Grid>
          </Grid>

          <Divider sx={separationStyle.Divider} />

          <Grid mt={2} container columnSpacing={9}>
            <Grid item xs={12} md={4} mt={1.5}>
              <Checkbox
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                checked={formik.values?.eligibleForRehire}
                color="secondary"
                name="eligibleForRehire"
                label="Eligible For Rehire"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TitleWithText title="Resignation Submission Date" text={exitDetails?.resignationSubmissionDate} />
            </Grid>
          </Grid>

          <Divider sx={separationStyle.Divider} />

          <Grid mt={2} container columnSpacing={9}>
            <Grid item xs={12} md={4}>
              <TitleWithText title="Resignation Reasons" text={exitDetails?.resignationReasons} />
            </Grid>
          </Grid>

          <Divider sx={separationStyle.Divider} />

          <InputField
            error={Boolean(formik.touched.comments && formik.errors.comments)}
            helperText={formik.touched.comments && formik.errors.comments}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values?.comments}
            name="comments"
            id="comments"
            label="Comments*"
            multiline
            maxWidth="40vw"
          />
          <Divider sx={separationStyle.Divider} />
        </Box>
      </PerfectScrollbar>

      <Box sx={separationStyle.buttonWrapper}>
        <Divider sx={separationStyle.Divider} />
        <Box sx={separationStyle.flexWrapper}>
          <Button
            size="large"
            startIcon={<CloseIcon />}
            color="error"
            type="submit"
            onClick={() => handleEmployeeSeparationRequest('Reject')}
          >
            Reject
          </Button>

          <Button
            size="large"
            startIcon={<DoneIcon />}
            type="submit"
            onClick={() => handleEmployeeSeparationRequest('Approve')}
          >
            Approve
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

EmpActionRequest.defaultProps = {
  exitDetails: {
    employeeName: '',
    separationType: '',
    relievingDate: '',
    interviewDate: '',
    interviewer: '',
    eligibleForRehire: '',
    resignationReasons: '',
    resignationStatus: '',
    supervisor: '',
    supervisorComments: '',
    supervisorAcceptanceDate: '',
    noticePeriod: '',
    asPerPolicyLWD: '',
    resignationSubmissionDate: '',
  },
  defaultValues: {},
  handleSubmit: () => {},
};

EmpActionRequest.propTypes = {
  exitDetails: PropTypes.shape({
    employeeName: PropTypes.string,
    separationType: PropTypes.string,
    relievingDate: PropTypes.string,
    interviewDate: PropTypes.string,
    interviewer: PropTypes.string,
    eligibleForRehire: PropTypes.string,
    resignationReasons: PropTypes.string,
    resignationStatus: PropTypes.string,
    supervisor: PropTypes.string,
    supervisorComments: PropTypes.string,
    supervisorAcceptanceDate: PropTypes.string,
    noticePeriod: PropTypes.string,
    asPerPolicyLWD: PropTypes.string,
    resignationSubmissionDate: PropTypes.string,
  }),
  defaultValues: PropTypes.shape({
    approvedLastWorkingDay: PropTypes.string,
  }),
  handleSubmit: PropTypes.func,
};

export default EmpActionRequest;
