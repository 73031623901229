import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import TableExtended from 'components/Molecules/TableExtended';
import Button from 'components/Atoms/Button';
import DownloadIcon from '@mui/icons-material/Download';
import FilterButton from 'components/Organisms/TableFilter/FilterButton';
import NoRecordsMessage from 'components/Organisms/CommonPageUtils/NoRecordsFound';
import { SalarySlipStyle } from '../../style';

const SalarySlipList = ({
  handleFilter,
  filterFields,
  handleDownload,
  loading,
  salarySlipDataColumn,
  salarySlipDataRow,
  keyColumn,
  sortDirection,
  sortBy,
  onSortChange,
  onRowClick,
  selectedItems,
  onSelect,
  onSelectAll,
}) => {
  return (
    <Box sx={SalarySlipStyle.mainWrapper}>
      {/* TODO: Not in phase 1 - Filters and bulk download */}
      {/* <Box display="flex" justifyContent="space-between" mb={2}>
        <FilterButton />
        {!loading && salarySlipDataRow.length === 0 ? (
          ''
        ) : (
          <Button size="medium" variant="outlined" startIcon={<DownloadIcon />} onClick={handleDownload}>
            Bulk Download
          </Button>
        )}
      </Box> */}
      <Box sx={SalarySlipStyle.tableWrapper}>
        {!loading && salarySlipDataRow.length === 0 ? (
          <NoRecordsMessage width="20rem" height="20rem" />
        ) : (
          <TableExtended
            loading={loading}
            columns={salarySlipDataColumn}
            rows={salarySlipDataRow.map((i) => ({
              ...i,
            }))}
            keyColumn={keyColumn}
            sortDirection={sortDirection}
            sortBy={sortBy}
            onSortChange={onSortChange}
            onRowClick={onRowClick}
            showRowSelector
            selectedItems={selectedItems}
            onSelect={onSelect}
            onSelectAll={onSelectAll}
          />
        )}
      </Box>
    </Box>
  );
};

SalarySlipList.defaultProps = {
  filterFields: [],
  handleFilter: () => {},
  handleDownload: () => {},
  loading: false,
  salarySlipDataColumn: [],
  salarySlipDataRow: [],
  keyColumn: 'id',
  sortDirection: 'desc',
  sortBy: 'id',
  onSortChange: () => {},
  onRowClick: () => {},
  selectedItems: [],
  onSelect: () => {},
  onSelectAll: () => {},
};

SalarySlipList.propTypes = {
  filterFields: PropTypes.arrayOf,
  handleFilter: PropTypes.func,
  handleDownload: PropTypes.func,
  loading: PropTypes.bool,
  salarySlipDataColumn: PropTypes.arrayOf,
  salarySlipDataRow: PropTypes.arrayOf,
  keyColumn: PropTypes.string,
  sortDirection: PropTypes.string,
  sortBy: PropTypes.string,
  onSortChange: PropTypes.func,
  onRowClick: PropTypes.func,
  selectedItems: PropTypes.arrayOf,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
};

export default SalarySlipList;
