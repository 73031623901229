import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { validateDateElseHyphen } from 'utils/commonUtils';
import Typographys from 'components/Atoms/Typography';
import Image from 'mui-image';
import { SalarySlipStyle } from '../../../style';
import Logo from '../../../../../Atoms/Logo';

const SalarySlipHeader = ({ companyName, companyAddress, companyLogo, payslipIssueDate }) => {
  return (
    <Box sx={SalarySlipStyle.slipHeaderWrapper}>
      <Box sx={SalarySlipStyle.slipHeaderLogo}>
        <Logo width="18rem" height="6rem" altText="Company Logo" />
      </Box>
      <Box sx={SalarySlipStyle.slipHeaderLeft}>
        <Typographys variant="subtitle2" color="neutral.800">
          Details
        </Typographys>
        <Typographys variant="body2" color="neutral.600">
          Date Issued {validateDateElseHyphen(payslipIssueDate, 'DD MMMM YYYY')} Pay Method Bank Transfer
        </Typographys>
      </Box>

      <Box sx={SalarySlipStyle.slipHeaderRight}>
        <Typographys variant="subtitle2" color="neutral.800" align="right">
          {companyName}
        </Typographys>
        <Typographys variant="body2" color="neutral.600" align="right">
          {companyAddress}
        </Typographys>
      </Box>
    </Box>
  );
};

SalarySlipHeader.defaultProps = {
  companyName: '',
  companyAddress: '',
  companyLogo: '',
  payslipIssueDate: 'MM/DD/YYYY',
};

SalarySlipHeader.propTypes = {
  companyName: PropTypes.string,
  companyAddress: PropTypes.string,
  companyLogo: PropTypes.string,
  payslipIssueDate: PropTypes.string,
};

export default SalarySlipHeader;
