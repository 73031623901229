/* eslint-disable no-unsafe-optional-chaining */
import dayjs from 'dayjs';
import { validateDateElseHyphen } from 'utils/commonUtils';
import { validateNumberElseHyphen } from '../../../../utils/commonUtils';

const getDeductionTableData = (currencySymbol, taxWorkingInstance) => {
  const finalDeductionTableData =
    taxWorkingInstance?.lineEntries &&
    taxWorkingInstance?.lineEntries
      .filter((item) => {
        return item?.declaredAnnualAmount > 0;
      })
      .map((deduction) => ({
        section: deduction?.statutoryReferenceSection,
        investmentProposed: `${currencySymbol} ${validateNumberElseHyphen(deduction?.declaredAnnualAmount)}`,
        investmentActual: `${currencySymbol} ${validateNumberElseHyphen(deduction?.proofSubmittedForAccount)}`,
        sectionLimit: `${currencySymbol} ${validateNumberElseHyphen(deduction?.annualExemptionLimitAmount)}`,
        deductionAmount: `${currencySymbol} ${validateNumberElseHyphen(deduction?.finalExemptionAmount)}`,
        declaredAnnualAmount: `${currencySymbol} ${deduction?.declaredAnnualAmount}`,
      }));
  return finalDeductionTableData;
};

const getExemptionsTableData = (currencySymbol, taxWorkingInstance, TDSWorkingInstance) => {
  const finalDeductionTableData =
    taxWorkingInstance?.lineEntries &&
    taxWorkingInstance?.lineEntries
      .filter((item) => {
        return (
          (item?.declaredAnnualAmount > 0 || item?.proofSubmittedForAccount > 0 || item?.finalExemptionAmount > 0) &&
          (TDSWorkingInstance?.data?.section10AllowancesList?.includes(item?.statutoryReferenceSection) ||
            item?.statutoryReferenceSection?.includes('10') ||
            item?.statutoryReferenceSection?.includes('80') ||
            item?.statutoryReferenceSection?.includes('24'))
        );
      })
      .map((deduction) => ({
        title: deduction?.statutoryReferenceSection,
        declaredamount: `${currencySymbol} ${deduction?.declaredAnnualAmount}`,
        proofsubmitted: `${currencySymbol} ${deduction?.proofSubmittedForAccount}`,
        annualexemptionlimit: `${currencySymbol} ${deduction?.annualExemptionLimitAmount}`,
        exemptionconsidered: `${currencySymbol} ${deduction?.finalExemptionAmount}`,
        declaredAnnualAmount: `${currencySymbol} ${deduction?.declaredAnnualAmount}`,
      }));
  return finalDeductionTableData;
};

const getDataArray = (array, type, currencySymbol) => {
  const extractedArray =
    array.length > 0
      ? array.map((item) => ({
          [`${type}`]: `${item?.payhead?.name}`,
          annualAmount: item?.finalAnnualValue
            ? `${currencySymbol} ${validateNumberElseHyphen(item?.finalAnnualValue) || '0'}`
            : '₹0',
          perPeriodAmount: item?.finalPerPeriodValue
            ? `${currencySymbol} ${validateNumberElseHyphen(item?.finalPerPeriodValue)}`
            : '₹0',
        }))
      : [];
  return extractedArray;
};

const getConfiguredTDSWorkingDetails = (TDSWorkingInstance, salaryDetails) => {
  if (!TDSWorkingInstance?.data) return null;
  const taxWorkingInstance = TDSWorkingInstance?.data?.taxWorkingInstance;
  const companyConfig = TDSWorkingInstance?.data?.companyConfig;
  const currencySymbol = taxWorkingInstance?.currency?.symbol || '';
  const grossEarningAnnual = TDSWorkingInstance?.data?.taxWorkingInstance?.grossEarningsAnnual || 0;
  const totalAnnualPerquisites = TDSWorkingInstance?.data?.taxWorkingInstance?.totalAnnualTaxablePerquisites || 0;
  const totalEarnings = grossEarningAnnual + totalAnnualPerquisites;
  const exemptionUnderSection10 = taxWorkingInstance?.totalAnnualExemptionsUnderSection10 || 0;
  const headSalaries =
    `${totalEarnings - exemptionUnderSection10}` - `${taxWorkingInstance?.totalAnnualDeductionsUnderSection16}`;
  const otherIncome = taxWorkingInstance?.additionalDeclaredIncome || 0;
  const otherTaxDeduction = taxWorkingInstance?.taxDeductedOtherThanSalary || 0;
  const grossTotalIncome = headSalaries + otherIncome - otherTaxDeduction;
  const taxableIncome = taxWorkingInstance?.netAnnualTaxableIncome || 0;
  const annualTax = taxWorkingInstance?.annualNetTax || 0;
  const annualNetTax = taxWorkingInstance?.totalTaxLiability - taxWorkingInstance?.netTaxPaidYTD;
  const rebateunderUS87A = taxWorkingInstance?.rebateUS87A || 0;
  const annualSurcharge = taxWorkingInstance?.annualSurcharge || 0;
  const totalTaxPaybleIncSurcharge = annualTax - rebateunderUS87A + annualSurcharge;
  const filteredPayHeads =
    TDSWorkingInstance?.data?.payheadsMap &&
    Object.values(TDSWorkingInstance?.data?.payheadsMap)
      .sort((a, b) => a.name - b.name)
      .filter(
        (payhead) =>
          !payhead.isInternalPayhead &&
          (payhead.payheadType === 'Earnings' ||
            payhead.payheadType === 'Benefits' ||
            payhead.payheadType === 'Reimbursements'),
      );
  const filteredeDductionPayHeads =
    TDSWorkingInstance?.data?.payheadsMap &&
    Object.values(TDSWorkingInstance?.data?.payheadsMap)
      .sort((a, b) => a.name - b.name)
      .filter(
        (payhead) =>
          !payhead.isInternalPayhead &&
          (payhead.payheadType === 'Deductions' || payhead.payheadType === 'Statutory Deductions - Employee'),
      );
  const deductionStatutoryRows = filteredeDductionPayHeads?.length
    ? filteredeDductionPayHeads.reduce((acc, payhead) => {
        const payheadName = payhead?.displayName;
        const payPeriodColumn = {};
        let total = 0;
        Object.values(TDSWorkingInstance?.data?.payPeriodsMap)?.forEach((payPeriod) => {
          if (TDSWorkingInstance?.data?.payPeriodSalSlipLineEntriesMap?.[payPeriod.id]?.[payhead.id]) {
            payPeriodColumn[dayjs(payPeriod?.payPeriodStartDate).format('MMMM').toLowerCase()] =
              TDSWorkingInstance?.data?.payPeriodSalSlipLineEntriesMap?.[payPeriod.id]?.[payhead.id];
            total += TDSWorkingInstance?.data?.payPeriodSalSlipLineEntriesMap?.[payPeriod.id]?.[payhead.id];
          } else if (
            TDSWorkingInstance?.data?.salStructLineEntriesMap[payhead.id] &&
            payPeriod?.payPeriodIndex >= (TDSWorkingInstance?.data?.earlietProcessedPayPeriod?.payPeriodIndex || 0) &&
            payPeriod?.payPeriodIndex > (TDSWorkingInstance?.data?.lastProcessedPayPeriod?.payPeriodIndex || 0)
          ) {
            const empFactor = TDSWorkingInstance?.data?.empDaysFactorMap?.[payPeriod?.payPeriodIndex] || 0;
            const calculated = empFactor * TDSWorkingInstance?.data?.salStructLineEntriesMap?.[payhead.id];
            payPeriodColumn[dayjs(payPeriod?.payPeriodStartDate).format('MMMM').toLowerCase()] = calculated;
            total += calculated;
          } else {
            payPeriodColumn[dayjs(payPeriod?.payPeriodStartDate).format('MMMM').toLowerCase()] = 0;
          }
        });
        payPeriodColumn.total = total;
        acc[payheadName] = payPeriodColumn;
        return acc;
      }, {})
    : {};

  const getComputedTds = () => {
    let computedPerPeriodTDS = 0;
    let projectedPerPeriodTDS = 0;
    let netTaxPendingCollection = 0;
    let netTaxPaidYTDIncPastTaxes = 0;
    let pastEmploymentTaxesPaid = 0;

    if (taxWorkingInstance?.previousEmployerTaxDetails?.length) {
      taxWorkingInstance?.previousEmployerTaxDetails.forEach((employerDetail) => {
        pastEmploymentTaxesPaid += employerDetail?.netTaxDeducted;
      });
    }

    if (TDSWorkingInstance?.data?.taxWorkingInstance?.netTaxPaidYTD || pastEmploymentTaxesPaid) {
      netTaxPaidYTDIncPastTaxes = TDSWorkingInstance?.data?.taxWorkingInstance?.netTaxPaidYTD;
    }

    if (TDSWorkingInstance?.data?.taxWorkingInstance?.totalTaxLiability) {
      netTaxPendingCollection =
        TDSWorkingInstance?.data?.taxWorkingInstance?.totalTaxLiability - netTaxPaidYTDIncPastTaxes;
    }

    if (
      TDSWorkingInstance?.data?.numProjectedPayPeriods !== 0 &&
      TDSWorkingInstance?.data?.taxWorkingInstance?.totalTaxLiability
    ) {
      projectedPerPeriodTDS =
        Math.round(
          100 *
            (TDSWorkingInstance?.data?.taxWorkingInstance?.totalTaxLiability -
              TDSWorkingInstance?.data?.taxWorkingInstance?.netTaxPaidYTD),
        ) / 100;
    } else {
      projectedPerPeriodTDS = TDSWorkingInstance?.data?.taxWorkingInstance?.totalPerPeriodTDS;
    }

    if (TDSWorkingInstance?.data?.numProjectedPayPeriods !== 0) {
      computedPerPeriodTDS =
        Math.round((100 * netTaxPendingCollection) / TDSWorkingInstance?.data?.numProjectedPayPeriods) / 100;
    } else {
      computedPerPeriodTDS = projectedPerPeriodTDS;
    }

    if (TDSWorkingInstance?.data?.numProjectedPayPeriods === 0) {
      computedPerPeriodTDS = 0;
      projectedPerPeriodTDS = 0;
    }

    return computedPerPeriodTDS;
  };

  const earningBenifitReimburseRows = filteredPayHeads?.length
    ? filteredPayHeads.reduce((acc, payhead) => {
        const payheadName = !payhead.taxable ? `${payhead?.displayName} *` : payhead?.displayName;
        const payPeriodColumn = {};
        let total = 0;
        Object.values(TDSWorkingInstance?.data?.payPeriodsMap)?.forEach((payPeriod) => {
          if (TDSWorkingInstance?.data?.payPeriodSalSlipLineEntriesMap?.[payPeriod.id]?.[payhead.id]) {
            payPeriodColumn[dayjs(payPeriod?.payPeriodStartDate).format('MMMM').toLowerCase()] =
              TDSWorkingInstance?.data?.payPeriodSalSlipLineEntriesMap?.[payPeriod.id]?.[payhead.id];
            total += TDSWorkingInstance?.data?.payPeriodSalSlipLineEntriesMap?.[payPeriod.id]?.[payhead.id];
          } else if (
            TDSWorkingInstance?.data?.salStructLineEntriesMap[payhead.id] &&
            payPeriod?.payPeriodIndex >= (TDSWorkingInstance?.data?.earlietProcessedPayPeriod?.payPeriodIndex || 0) &&
            payPeriod?.payPeriodIndex > (TDSWorkingInstance?.data?.lastProcessedPayPeriod?.payPeriodIndex || 0)
          ) {
            const empFactor = TDSWorkingInstance?.data?.empDaysFactorMap?.[payPeriod?.payPeriodIndex] || 0;
            const calculated = empFactor * TDSWorkingInstance?.data?.salStructLineEntriesMap?.[payhead.id];
            payPeriodColumn[dayjs(payPeriod?.payPeriodStartDate).format('MMMM').toLowerCase()] = calculated;
            total += calculated;
          } else if (payhead.name === 'TDS') {
            let computedPerPeriodTDS = 0;

            if (
              !TDSWorkingInstance?.data?.payPeriodSalSlipLineEntriesMap?.[payPeriod.id] &&
              payPeriod?.payPeriodIndex >= (TDSWorkingInstance?.data?.earlietProcessedPayPeriod?.payPeriodIndex || 0) &&
              payPeriod?.payPeriodIndex > (TDSWorkingInstance?.data?.lastProcessedPayPeriod?.payPeriodIndex || 0) &&
              TDSWorkingInstance?.data?.empDaysFactorMap?.[payPeriod?.payPeriodIndex]
            ) {
              computedPerPeriodTDS = getComputedTds();
            }

            payPeriodColumn[dayjs(payPeriod?.payPeriodStartDate).format('MMMM').toLowerCase()] = computedPerPeriodTDS;
            total += computedPerPeriodTDS;
          } else {
            payPeriodColumn[dayjs(payPeriod?.payPeriodStartDate).format('MMMM').toLowerCase()] = 0;
          }
        });
        payPeriodColumn.total = total;
        acc[payheadName] = payPeriodColumn;
        return acc;
      }, {})
    : {};

  let totalEarningRow = {};
  if (Object.keys(earningBenifitReimburseRows)?.length) {
    const firstKey = Object.keys(earningBenifitReimburseRows)[0];
    const firstValues = Object.keys(earningBenifitReimburseRows[firstKey]);
    const totalEarningRowData = firstValues.reduce((acc, curr) => {
      let total = 0;
      Object.keys(earningBenifitReimburseRows).forEach((row) => {
        total += earningBenifitReimburseRows[row][curr];
      });
      acc[curr] = total;
      return acc;
    }, {});

    totalEarningRow = {
      'Total Earnings': totalEarningRowData,
    };
  }
  let totalDeductionRow = {};
  if (Object.keys(deductionStatutoryRows)?.length) {
    const firstKey = Object.keys(deductionStatutoryRows)[0];
    const firstValues = Object.keys(deductionStatutoryRows[firstKey]);
    const totalDeductionRowData = firstValues.reduce((acc, curr) => {
      let total = 0;
      Object.keys(deductionStatutoryRows).forEach((row) => {
        total += deductionStatutoryRows[row][curr];
      });
      acc[curr] = total;
      return acc;
    }, {});

    totalDeductionRow = {
      'Total Deductions': totalDeductionRowData,
    };
  }
  const netEarningRow = {};
  const totalEarningsData = totalEarningRow['Total Earnings'];
  const totalDeductionData = totalDeductionRow['Total Deductions'];

  if (totalEarningsData && totalDeductionData) {
    const netEarningData = Object.keys(totalEarningsData).reduce((acc, curr) => {
      acc[curr] = totalEarningsData[curr] - totalDeductionData[curr];
      return acc;
    }, {});

    netEarningRow['Net Earnings'] = netEarningData;
  }

  const lopRow = {};
  const lopPayPeriodColumn = {};
  let total = 0;
  Object.values(TDSWorkingInstance?.data?.payPeriodsMap || {})?.forEach((payPeriod) => {
    if (TDSWorkingInstance?.data?.payPeriodLOPMap?.[payPeriod.id]) {
      lopPayPeriodColumn[dayjs(payPeriod?.payPeriodStartDate).format('MMMM').toLowerCase()] =
        TDSWorkingInstance?.data?.payPeriodLOPMap?.[payPeriod.id];
      total += TDSWorkingInstance?.data?.payPeriodLOPMap?.[payPeriod.id];
    } else {
      lopPayPeriodColumn[dayjs(payPeriod?.payPeriodStartDate).format('MMMM').toLowerCase()] = 0;
    }
  });
  lopPayPeriodColumn.total = total;
  lopRow['Loss Of Pay (days)'] = lopPayPeriodColumn;

  const columns = TDSWorkingInstance?.data?.payPeriodsMap
    ? Object.entries(TDSWorkingInstance?.data?.payPeriodsMap).map(([key, value]) => {
        return {
          fieldName: dayjs(value?.payPeriodStartDate).format('MMMM').toLowerCase(),
          label: dayjs(value?.payPeriodStartDate).format('MMMM YYYY'),
          formatValue: ({ row, column }) => validateNumberElseHyphen(row[column.fieldName]),
        };
      })
    : [];
  const numPayPeriodsInCal = 12;
  const TDSWorkingDetails = {
    empName: TDSWorkingInstance?.data?.employee?.fullName || '-',
    exemptionCalculationBasis: taxWorkingInstance?.exemptionCalculationBasis || '',
    empId: TDSWorkingInstance?.data?.employee?.profile?.employeeID || '-',
    categoryColumnsData: [
      ...columns,
      {
        fieldName: 'total',
        label: 'Sub Total',
        formatValue: ({ row, column }) => validateNumberElseHyphen(row[column.fieldName]),
      },
    ],
    categoryRowsData: {
      ...earningBenifitReimburseRows,
      ...totalEarningRow,
      ...lopRow,
      ...deductionStatutoryRows,
      ...totalDeductionRow,
      ...netEarningRow,
    },
    amountData: {
      a: {
        old: `${currencySymbol} ${validateNumberElseHyphen(taxWorkingInstance?.grossEarningsAnnual) || '-'}`,
      },
      b: {
        old: `${currencySymbol} ${validateNumberElseHyphen('0')}`,
      },
      c: {
        old: `${currencySymbol} ${validateNumberElseHyphen(taxWorkingInstance?.totalAnnualTaxablePerquisites) || '-'}`,
      },
      d: {
        old: `${currencySymbol} ${validateNumberElseHyphen(totalEarnings)}`,
      },
      e: {
        old: `${currencySymbol} ${validateNumberElseHyphen(exemptionUnderSection10)}`,
      },
      e0: {
        old: `${validateNumberElseHyphen(taxWorkingInstance?.totalHRARecieved)}`,
      },
      e3: {
        old: `${validateNumberElseHyphen(taxWorkingInstance?.annualRentPaid)}`,
      },
      e1: {
        old: `${currencySymbol} ${validateNumberElseHyphen(taxWorkingInstance?.totalAnnualExemption)}`,
      },
      e2: {
        old: `${validateNumberElseHyphen(0)}`,
      },
      f: {
        old: `${currencySymbol} ${validateNumberElseHyphen(totalEarnings - exemptionUnderSection10)}`,
      },
      g: {
        old: `${currencySymbol} ${validateNumberElseHyphen(taxWorkingInstance?.totalAnnualDeductionsUnderSection16)}`,
      },
      h: {
        old: `${currencySymbol} ${validateNumberElseHyphen(headSalaries.toFixed(2))}`,
      },
      i: {
        old: `${currencySymbol} ${validateNumberElseHyphen(otherIncome)}`,
      },
      j: {
        old: `${currencySymbol} ${validateNumberElseHyphen(otherTaxDeduction)}`,
      },
      k: {
        old: `${currencySymbol} ${validateNumberElseHyphen(grossTotalIncome.toFixed(2))}`,
      },
      l: {
        old: `${currencySymbol} ${
          validateNumberElseHyphen(taxWorkingInstance?.totalAnnualDeductionsUnderChapterVIA) || '-'
        }`,
      },
      m: {
        old: `${currencySymbol} ${
          validateNumberElseHyphen(taxWorkingInstance?.totalAnnualDeductionsUnderChapterVIA) || '-'
        }`,
      },
      n: {
        old: `${currencySymbol} ${validateNumberElseHyphen(taxableIncome.toFixed(2))}`,
      },
      o: {
        old: `${currencySymbol} ${validateNumberElseHyphen(annualTax)}`,
      },
      p: {
        old: `${currencySymbol} ${validateNumberElseHyphen(rebateunderUS87A)}`,
      },
      q: {
        old: `${currencySymbol} ${validateNumberElseHyphen(annualSurcharge)}`,
      },
      r: {
        old: `${currencySymbol} ${validateNumberElseHyphen(totalTaxPaybleIncSurcharge)}`,
      },
      s: {
        old: `${currencySymbol} ${validateNumberElseHyphen(taxWorkingInstance?.annualEducationalCess) || '-'}`,
      },
      t: {
        old: `${currencySymbol} ${validateNumberElseHyphen(taxWorkingInstance?.totalTaxLiability) || '-'}`,
      },
      u: {
        old: `${currencySymbol} ${validateNumberElseHyphen(taxWorkingInstance?.netTaxPaidYTD) || '-'}`,
      },
      v: {
        old: `${currencySymbol} ${validateNumberElseHyphen(annualNetTax) || '-'}`,
      },
      w: {
        old: `${currencySymbol} ${validateNumberElseHyphen(numPayPeriodsInCal) || '-'}`,
      },
      x: {
        old: `${currencySymbol} ${validateNumberElseHyphen(getComputedTds()) || '-'}`,
      },
    },
    hraAmount: `${currencySymbol} ${taxWorkingInstance?.totalHRARecieved}`,
    totalRentPaid: `${currencySymbol} ${taxWorkingInstance?.annualRentPaid}`,
    grossDeduction: [
      `${currencySymbol} ${(100 * taxWorkingInstance?.grossDeductionsAnnual) / numPayPeriodsInCal / 100}`,
      taxWorkingInstance?.grossDeductionsAnnual,
    ],
    standardDeduction: `${currencySymbol} ${taxWorkingInstance?.standardDeduction}`,
    currentFinancialYear: companyConfig?.currentFinancialYear,
    taxOnEmployment: `${currencySymbol} ${taxWorkingInstance?.totalAnnualDeductionsUnderSection16}`,
    deductionTableData: getDeductionTableData(currencySymbol, taxWorkingInstance),
    totalDeductionAmount: `${currencySymbol} ${taxWorkingInstance?.totalAnnualDeductionsUnderChapterVIA}`,
    rowEarnings: [
      {
        earnings: 'Earnings (from Salary)',
        annualAmount: `${currencySymbol} ${validateNumberElseHyphen(taxWorkingInstance?.totalAnnualEarnings) || 0}`,
        perPeriodAmount: `${currencySymbol} ${
          validateNumberElseHyphen(
            Math.round((100 * taxWorkingInstance?.totalAnnualEarnings) / numPayPeriodsInCal) / 100,
          ) || 0
        }`,
      },
      {
        earnings: 'Benefits',
        annualAmount: `${currencySymbol} ${validateNumberElseHyphen(taxWorkingInstance?.totalAnnualBenefits) || 0}`,
        perPeriodAmount: `${currencySymbol} ${
          validateNumberElseHyphen(
            Math.round((100 * taxWorkingInstance?.totalAnnualBenefits) / numPayPeriodsInCal) / 100,
          ) || 0
        }`,
      },
      {
        earnings: 'Reimbursements',
        annualAmount: `${currencySymbol} ${
          validateNumberElseHyphen(taxWorkingInstance?.totalAnnualReimbursements) || 0
        }`,
        perPeriodAmount: `${currencySymbol} ${
          validateNumberElseHyphen(
            Math.round((100 * taxWorkingInstance?.totalAnnualReimbursements) / numPayPeriodsInCal) / 100,
          ) || 0
        }`,
      },
      {
        earnings: 'Gross Earnings',
        annualAmount: `${currencySymbol} ${validateNumberElseHyphen(taxWorkingInstance?.grossEarningsAnnual) || 0}`,
        perPeriodAmount: `${currencySymbol} ${
          validateNumberElseHyphen(
            Math.round((100 * taxWorkingInstance?.grossEarningsAnnual) / numPayPeriodsInCal) / 100,
          ) || 0
        }`,
      },
    ],
    columnEarnings: [
      {
        fieldName: 'earnings',
        columnTitle: 'Earnings',
        columnWidth: '60%',
      },
      {
        fieldName: 'perPeriodAmount',
        columnTitle: 'Per Period',
        columnWidth: '20%',
      },
      {
        fieldName: 'annualAmount',
        columnTitle: 'Annual',
        columnWidth: '20%',
      },
    ],
    rowDeductions: [
      {
        deductions: 'Voluntary Deductions',
        annualAmount: `${currencySymbol} ${
          validateNumberElseHyphen(taxWorkingInstance?.totalVoluntaryAnnualDeductions) || 0
        }`,
        perPeriodAmount: `${currencySymbol} ${
          validateNumberElseHyphen(
            Math.round((100 * taxWorkingInstance?.totalVoluntaryAnnualDeductions) / numPayPeriodsInCal) / 100,
          ) || 0
        }`,
      },
      {
        deductions: 'Statutory Deductions',
        annualAmount: `${currencySymbol} ${
          validateNumberElseHyphen(taxWorkingInstance?.totalStatutoryAnnualDeductions) || 0
        }`,
        perPeriodAmount: `${currencySymbol} ${
          validateNumberElseHyphen(
            Math.round((100 * taxWorkingInstance?.totalStatutoryAnnualDeductions) / numPayPeriodsInCal) / 100,
          ) || 0
        }`,
      },
      {
        deductions: 'Loans and Advances (Deductions)',
        annualAmount: `${currencySymbol} ${
          validateNumberElseHyphen(taxWorkingInstance?.totalAnnualLoansAndAdvancesDeductions) || 0
        }`,
        perPeriodAmount: `${currencySymbol} ${
          validateNumberElseHyphen(
            Math.round((100 * taxWorkingInstance?.totalAnnualLoansAndAdvancesDeductions) / numPayPeriodsInCal) / 100,
          ) || 0
        }`,
      },
      {
        deductions: 'Gross Deductions',
        annualAmount: `${currencySymbol} ${validateNumberElseHyphen(taxWorkingInstance?.grossDeductionsAnnual) || 0}`,
        perPeriodAmount: `${currencySymbol} ${
          validateNumberElseHyphen(
            Math.round((100 * taxWorkingInstance?.grossDeductionsAnnual) / numPayPeriodsInCal) / 100,
          ) || 0
        }`,
      },
    ],
    columnDeductions: [
      {
        fieldName: 'deductions',
        columnTitle: 'Deductions',
        columnWidth: '60%',
      },
      {
        fieldName: 'perPeriodAmount',
        columnTitle: 'Per Period',
        columnWidth: '20%',
      },
      {
        fieldName: 'annualAmount',
        columnTitle: 'Annual',
        columnWidth: '20%',
      },
    ],
    rowRetirals: [
      {
        retirals: `Employer's Statutory Contributions`,
        annualAmount: `${currencySymbol} ${
          validateNumberElseHyphen(taxWorkingInstance?.totalEmployerStatAnnualContributions) || 0
        }`,
        perPeriodAmount: `${currencySymbol} ${
          validateNumberElseHyphen(
            Math.round((100 * taxWorkingInstance?.totalEmployerStatAnnualContributions) / numPayPeriodsInCal) / 100,
          ) || 0
        }`,
      },
      {
        retirals: 'Gratuity Contributions',
        annualAmount: `${currencySymbol} ${
          validateNumberElseHyphen(taxWorkingInstance?.totalAnnualGratuityContributions) || 0
        }`,
        perPeriodAmount: `${currencySymbol} ${
          validateNumberElseHyphen(
            Math.round((100 * taxWorkingInstance?.totalAnnualGratuityContributions) / numPayPeriodsInCal) / 100,
          ) || 0
        }`,
      },
    ],
    columnRetirals: [
      {
        fieldName: 'retirals',
        columnTitle: 'Retirals',
        columnWidth: '60%',
      },
      {
        fieldName: 'perPeriodAmount',
        columnTitle: 'Per Period',
        columnWidth: '20%',
      },
      {
        fieldName: 'annualAmount',
        columnTitle: 'Annual',
        columnWidth: '20%',
      },
    ],
    rowOther: [
      {
        others: 'Exemptions (Total)',
        annualAmount: `${currencySymbol} ${validateNumberElseHyphen(taxWorkingInstance?.totalAnnualExemption) || 0}`,
        perPeriodAmount: '',
      },
      {
        others: 'Total Taxable Income',
        annualAmount: `${currencySymbol} ${validateNumberElseHyphen(taxWorkingInstance?.netAnnualTaxableIncome) || 0}`,
        perPeriodAmount: '',
      },
      {
        others: 'Tax Deducted at Source (TDS)',
        annualAmount: `${currencySymbol} ${
          validateNumberElseHyphen(Math.round(taxWorkingInstance?.totalAnnualTDS)) || 0
        }`,
        perPeriodAmount: `${currencySymbol} ${
          validateNumberElseHyphen(
            Math.round((100 * taxWorkingInstance?.totalPerPeriodTDS) / numPayPeriodsInCal) / 100,
          ) || 0
        }`,
      },
    ],
    columnOther: [
      {
        fieldName: 'others',
        columnTitle: 'Others',
        columnWidth: '60%',
      },
      {
        fieldName: 'perPeriodAmount',
        columnTitle: 'Per Period',
        columnWidth: '20%',
      },
      {
        fieldName: 'annualAmount',
        columnTitle: 'Annual',
        columnWidth: '20%',
      },
    ],
    hraData: [
      {
        received: `${currencySymbol} ${validateNumberElseHyphen(taxWorkingInstance?.totalHRARecieved) || 0}`,
        basic: `${currencySymbol} ${validateNumberElseHyphen(taxWorkingInstance?.basicUsedForHRAExemption) || 0}`,
        rentPaid: `${currencySymbol} ${
          validateNumberElseHyphen(taxWorkingInstance?.rentPaidInExcessOfBasicThreshold) || 0
        }`,
      },
    ],
    rowExemption: getExemptionsTableData(currencySymbol, taxWorkingInstance, TDSWorkingInstance),
    columnExemption: [
      {
        fieldName: 'title',
        columnTitle: 'Title',
        columnWidth: '20%',
      },
      {
        fieldName: 'declaredamount',
        columnTitle: 'Declared Amount',
        columnWidth: '20%',
      },
      {
        fieldName: 'proofsubmitted',
        columnTitle: 'Proof Submitted',
        columnWidth: '20%',
      },
      {
        fieldName: 'annualexemptionlimit',
        columnTitle: 'Annual Exemption Limit',
        columnWidth: '20%',
      },
      {
        fieldName: 'exemptionconsidered',
        columnTitle: 'Exemption Considered',
        columnWidth: '20%',
      },
    ],
    startDate: validateDateElseHyphen(taxWorkingInstance?.company?.financialYearStartDate, 'DD MMMM YYYY'),
    endDate: validateDateElseHyphen(taxWorkingInstance?.company?.financialYearEndDate, 'DD MMMM YYYY'),
  };

  return TDSWorkingDetails;
};

export { getConfiguredTDSWorkingDetails };
