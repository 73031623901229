import React from 'react';
import { Box, Grid, Divider, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Typographys from 'components/Atoms/Typography';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import Button from 'components/Atoms/Button';
import InputField from 'components/Atoms/InputField/index';
import DatePicker from 'components/Atoms/Datepicker';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Attachments from 'components/Atoms/Attachments';
import * as yup from 'yup';
import Typography from '@mui/material/Typography';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Formik, FieldArray, getIn } from 'formik';
import { genericMobileRegex, genericEmailRegex } from 'utils/commonUtils';
import { profileStyle } from '../../style';

const ProfileEditEducationalHistory = ({ handleSubmit, handleCancel, defaultValue, loading }) => {
  const defaultObj = {
    institutionName: '',
    certificationName: '',
    startDate: '',
    endDate: '',
    specialization: '',
    registrationNumber: '',
    referenceName: '',
    referenceNumber: '',
    referenceEmail: '',
    comments: '',
    attachments: '',
    isNew: true,
  };

  const employeeSchema = yup.object().shape({
    education: yup.array().of(
      yup.object().shape({
        institutionName: yup.string().required('Required'),
        startDate: yup.string().required('Required'),
        endDate: yup.string().required('Required'),
        referenceNumber: yup.string().matches(genericMobileRegex, 'Invalid format, Please enter valid mobile number.'),
        referenceEmail: yup.string().matches(genericEmailRegex, 'Invalid format, Please enter valid email ID.'),
      }),
    ),
  });

  return (
    <Box>
      <Formik
        validationSchema={employeeSchema}
        validateOnMount
        initialValues={defaultValue}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(formik) => <Form formik={formik} handleCancel={handleCancel} defaultObj={defaultObj} loading={loading} />}
      </Formik>
    </Box>
  );
};

const Form = ({ formik, handleCancel, defaultObj, loading }) => {
  const handleDateChange = (date, setFieldValue, label1) => {
    setFieldValue(label1, date);
  };

  const theme = useTheme();
  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 38rem)' }} mt={3}>
          <FieldArray name="education">
            {({ push, remove }) => (
              <>
                {formik.values.education.map((e, index) => {
                  return (
                    <Box sx={profileStyle.formDivider}>
                      <Box sx={profileStyle.deleteContainer}>
                        <Typography variant="subtitle2" color="neutral.800">
                          Record {index + 1}
                        </Typography>
                        <Button
                          startIcon={<DeleteForeverIcon />}
                          onClick={(event) => {
                            event.preventDefault();
                            remove(index);
                          }}
                          variant="outlined"
                        >
                          Delete
                        </Button>
                      </Box>
                      <Grid container rowSpacing={2} columnSpacing={2} mb={2}>
                        <Grid item xs={12} sm={6} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `education[${index}].institutionName`) &&
                                getIn(formik.errors, `education[${index}].institutionName`),
                            )}
                            helperText={
                              getIn(formik.touched, `education[${index}].institutionName`) &&
                              getIn(formik.errors, `education[${index}].institutionName`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={e.institutionName}
                            name={`education[${index}].institutionName`}
                            id={`education[${index}].institutionName`}
                            label="Institution Name*"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `education[${index}].certificationName`) &&
                                getIn(formik.errors, `education[${index}].certificationName`),
                            )}
                            helperText={
                              getIn(formik.touched, `education[${index}].certificationName`) &&
                              getIn(formik.errors, `education[${index}].certificationName`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={e.certificationName}
                            name={`education[${index}].certificationName`}
                            id={`education[${index}].certificationName`}
                            label="Certification Name"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <DatePicker
                            error={
                              Boolean(
                                getIn(formik.touched, `education[${index}].startDate`) &&
                                  getIn(formik.errors, `education[${index}].startDate`),
                              ) ||
                              (getIn(formik.touched, `education[${index}].startDate`) && !e.startDate)
                            }
                            helperText={
                              getIn(formik.touched, `education[${index}].startDate`) &&
                              getIn(formik.errors, `education[${index}].startDate`)
                            }
                            onChange={(data) =>
                              handleDateChange(data, formik.setFieldValue, `education[${index}].startDate`)
                            }
                            onBlur={formik.handleBlur}
                            value={e?.startDate}
                            name={`education[${index}].startDate`}
                            id={`education[${index}].startDate`}
                            label="Start Date*"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <DatePicker
                            error={
                              Boolean(
                                getIn(formik.touched, `education[${index}].endDate`) &&
                                  getIn(formik.errors, `education[${index}].endDate`),
                              ) ||
                              (getIn(formik.touched, `education[${index}].endDate`) && !e.endDate)
                            }
                            helperText={
                              getIn(formik.touched, `education[${index}].endDate`) &&
                              getIn(formik.errors, `education[${index}].endDate`)
                            }
                            onChange={(data) =>
                              handleDateChange(data, formik.setFieldValue, `education[${index}].endDate`)
                            }
                            onBlur={formik.handleBlur}
                            value={e?.endDate}
                            minDate={formik.values.education[index].startDate}
                            name={`education[${index}].endDate`}
                            id={`education[${index}].endDate`}
                            label="End Date*"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `education[${index}].specialization`) &&
                                getIn(formik.errors, `education[${index}].specialization`),
                            )}
                            helperText={
                              getIn(formik.touched, `education[${index}].specialization`) &&
                              getIn(formik.errors, `education[${index}].specialization`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={e.specialization}
                            name={`education[${index}].specialization`}
                            id={`education[${index}].specialization`}
                            label="Specialization"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `education[${index}].registrationNumber`) &&
                                getIn(formik.errors, `education[${index}].registrationNumber`),
                            )}
                            helperText={
                              getIn(formik.touched, `education[${index}].registrationNumber`) &&
                              getIn(formik.errors, `education[${index}].registrationNumber`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={e.registrationNumber}
                            name={`education[${index}].registrationNumber`}
                            id={`education[${index}].registrationNumber`}
                            label="Registration Number"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `education[${index}].referenceName`) &&
                                getIn(formik.errors, `education[${index}].referenceName`),
                            )}
                            helperText={
                              getIn(formik.touched, `education[${index}].referenceName`) &&
                              getIn(formik.errors, `education[${index}].referenceName`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={e.referenceName}
                            name={`education[${index}].referenceName`}
                            id={`education[${index}].referenceName`}
                            label="Reference Name"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `education[${index}].referenceNumber`) &&
                                getIn(formik.errors, `education[${index}].referenceNumber`),
                            )}
                            helperText={
                              getIn(formik.touched, `education[${index}].referenceNumber`) &&
                              getIn(formik.errors, `education[${index}].referenceNumber`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={e.referenceNumber}
                            name={`education[${index}].referenceNumber`}
                            id={`education[${index}].referenceNumber`}
                            label="Reference Phone Number"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `education[${index}].referenceEmail`) &&
                                getIn(formik.errors, `education[${index}].referenceEmail`),
                            )}
                            helperText={
                              getIn(formik.touched, `education[${index}].referenceEmail`) &&
                              getIn(formik.errors, `education[${index}].referenceEmail`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={e.referenceEmail}
                            name={`education[${index}].referenceEmail`}
                            id={`education[${index}].referenceEmail`}
                            label="Reference Email"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `education[${index}].comments`) &&
                                getIn(formik.errors, `education[${index}].comments`),
                            )}
                            helperText={
                              getIn(formik.touched, `education[${index}].comments`) &&
                              getIn(formik.errors, `education[${index}].comments`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={e.comments}
                            name={`education[${index}].comments`}
                            id={`education[${index}].comments`}
                            label="Comments"
                            multiline
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={8} mt={2}>
                          <Attachments
                            isDeleteIcon
                            handleChange={(event) => {
                              formik.setFieldValue(`education[${index}].attachments`, event.target.files[0]);
                            }}
                            name={`education[${index}].attachments`}
                            id={`education[${index}].attachments`}
                            value={e.attachments}
                            handleDelete={() => {
                              formik.setFieldValue(`education[${index}].attachments`, '');
                            }}
                          />
                          <Grid item xs={12} md={8} mt={2}>
                            {e?.attachment && (
                              <Typographys variant="caption" color="neutral.600">
                                Previously Associated Attachment: {e?.attachment}
                              </Typographys>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })}

                <Box sx={profileStyle.addButton}>
                  <Button
                    size="medium"
                    variant="outlined"
                    startIcon={<AddOutlinedIcon />}
                    onClick={() => push(defaultObj)}
                    type="button"
                  >
                    Add Educational Record
                  </Button>
                </Box>
              </>
            )}
          </FieldArray>
        </Box>
      </PerfectScrollbar>

      <Box sx={profileStyle.btnContainer}>
        <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

        <Box sx={profileStyle.submitWrapper}>
          <Button
            size="large"
            color="info"
            variant="outlined"
            type="cancel"
            onClick={() => {
              formik.resetForm();
              handleCancel();
            }}
          >
            Cancel
          </Button>
          <LoadingButton loading={loading} color="primary" size="large" type="submit" variant="contained">
            Update
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

Form.defaultProps = {
  handleCancel: () => {},
  formik: {},
  defaultObj: {},
  loading: false,
};

Form.propTypes = {
  handleCancel: PropTypes.func,
  formik: PropTypes.shape({
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    values: PropTypes.shape({
      education: PropTypes.shape([]),
    }),
    resetForm: PropTypes.func,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    setFieldValue: PropTypes.func,
    loading: PropTypes.bool,
  }),
  defaultObj: PropTypes.shape({}),
  loading: PropTypes.bool,
};

ProfileEditEducationalHistory.defaultProps = {
  handleSubmit: () => {},
  handleCancel: () => {},
  defaultValue: {
    education: [
      {
        institutionName: '',
        certificationName: '',
        startDate: '',
        endDate: '',
        specialization: '',
        registrationNumber: '',
        referenceName: '',
        referenceNumber: '',
        referenceEmail: '',
        comments: '',
        attachment: '',
      },
    ],
  },
  loading: false,
};

ProfileEditEducationalHistory.propTypes = {
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,

  defaultValue: PropTypes.shape({}),
  loading: PropTypes.bool,
};

export default ProfileEditEducationalHistory;
