import { useSelector } from 'react-redux';
import { commonFilteredActionItems } from 'utils/commonUtils';
import {
  PAST_EMPLOYER_TAXES_STATUS_IN_DRAFT,
  PAST_EMPLOYER_TAXES_STATUS_IN_REVIEW,
  PAST_EMPLOYER_TAXES_STATUS_IN_APPROVED,
  PAST_EMPLOYER_TAXES_STATUS_IN_REJECT,
} from 'utils/SystemConfigConstants';

const getActionItems = (services) => {
  return [
    {
      name: 'View',
      onClick: services.viewPastEmploymentTax,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_PAY_INDEX_VIEW_PREVIOUS_EMP_TAX_DETAILS_BY_EMPLOYEE'],
      conditional: (row) =>
        row?.status === PAST_EMPLOYER_TAXES_STATUS_IN_DRAFT ||
        row?.status === PAST_EMPLOYER_TAXES_STATUS_IN_REVIEW ||
        row?.status === PAST_EMPLOYER_TAXES_STATUS_IN_APPROVED ||
        row?.status === PAST_EMPLOYER_TAXES_STATUS_IN_REJECT,
    },
    {
      name: 'Edit',
      onClick: services.editPastEmploymentTax,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_PAY_INDEX_UPDATE_PREVIOUS_EMP_TAX_DETAILS_BY_EMPLOYEE'],
      conditional: (row) => row?.status === PAST_EMPLOYER_TAXES_STATUS_IN_DRAFT,
    },
    {
      name: 'Submit For Review',
      onClick: services.handleSubmitDialog,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_PAY_INDEX_SUBMIT_FOR_REVIEW_PREVIOUS_EMP_TAX_DETAILS_BY_EMPLOYEE'],
      conditional: (row) => row?.status === PAST_EMPLOYER_TAXES_STATUS_IN_DRAFT,
    },
    {
      name: 'Delete',
      onClick: services.handleDeleteDialog,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_PAY_INDEX_DELETE_PREVIOUS_EMP_TAX_DETAILS_BY_EMPLOYEE'],
      conditional: (row) => row?.status === PAST_EMPLOYER_TAXES_STATUS_IN_DRAFT,
    },
  ];
};

const useActionItems = (services) => {
  const actions = getActionItems(services);
  const authorities = useSelector((state) => state.session.user.authorities);

  const handleActionItemClick = (row) => {
    return commonFilteredActionItems(authorities, actions, row);
  };

  return [handleActionItemClick];
};

export default useActionItems;
