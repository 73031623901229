import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, useTheme } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import Button from 'components/Atoms/Button';
import DownloadIcon from '@mui/icons-material/Download';
import { useSelector } from 'react-redux';
import { hasPermission } from 'utils/permissions';
import { SalarySlipStyle } from '../../style';
import MuiSwitch from '../../../../Molecules/Switch';
import SalarySlipHeader from './SalarySlipHeader';
import SalarySlipTable from './SalarySlipTable';
import ArrearsDetails from './ArrearsDetails';

const SalarySlipDetail = (
  {
    paySlipDate,
    handleDownload,
    handlePrint,
    companyName,
    companyAddress,
    companyLogo,
    payslipIssueDate,
    salarySlipData,
    loading,
    arrearDataColumn,
    arrearDataRow,
    sortDirection,
    sortBy,
    onSortChange,
    count,
    page,
    onPageChange,
    consolidatedSalarySlipsFlag,
  },
  ref,
) => {
  const theme = useTheme();
  const [showArrears, setShowArrears] = useState(false);
  const handleShowArrears = (event) => {
    setShowArrears(event.target.checked);
  };
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasPrintPermisson = hasPermission(authorities, ['ROLE_BASE_PERMISSION_PAY_INDEX_PRINT_MY_SALARY_SLIP']);

  return (
    <Box sx={{ p: 2 }} className="mySalaryDetails" ref={ref}>
      <Box sx={SalarySlipStyle.salarySlipWrapperHeader}>
        <Typographys variant="h6">PaySlip For the Month of {paySlipDate} </Typographys>
        {!!userHasPrintPermisson && (
          <Box sx={SalarySlipStyle.actionBtnGroup} className="no-print">
            <Button startIcon={<DownloadIcon fontSize="2.5rem" />} onClick={handleDownload}>
              Download
            </Button>
            <Button onClick={handlePrint} variant="outlined">
              Print Payslip
            </Button>
          </Box>
        )}
      </Box>
      <span className="no-print">
        {!consolidatedSalarySlipsFlag && (
          <Box mb={2} sx={SalarySlipStyle.muiSwitch}>
            <MuiSwitch
              handleChange={handleShowArrears}
              checked={showArrears}
              label="Show Arrears Details"
              labelPlacement="start"
            />
          </Box>
        )}
      </span>
      <Box mb={1.5}>
        <SalarySlipHeader
          companyName={companyName}
          companyAddress={companyAddress}
          companyLogo={companyLogo}
          payslipIssueDate={payslipIssueDate}
        />
      </Box>

      <SalarySlipTable salarySlipData={salarySlipData} />

      {showArrears && (
        <>
          <Divider sx={{ mt: 2, mb: 3.5, borderColor: theme.palette.other[200] }} />
          <ArrearsDetails
            loading={loading}
            arrearDataColumn={arrearDataColumn}
            arrearDataRow={arrearDataRow}
            sortDirection={sortDirection}
            sortBy={sortBy}
            onSortChange={onSortChange}
            count={count}
            page={page}
            onPageChange={onPageChange}
          />
        </>
      )}
    </Box>
  );
};

SalarySlipDetail.defaultProps = {
  paySlipDate: '',
  handleDownload: () => {},
  handlePrint: () => {},
  companyName: '',
  companyAddress: '',
  companyLogo: '',
  payslipIssueDate: 'MM/DD/YYYY',
  salarySlipData: {},
  loading: false,
  arrearDataColumn: [],
  arrearDataRow: [],
  sortDirection: 'asc',
  sortBy: () => {},
  onSortChange: () => {},
  count: 10,
  page: null,
  onPageChange: () => {},
};

SalarySlipDetail.propTypes = {
  paySlipDate: PropTypes.string,
  handleDownload: PropTypes.func,
  handlePrint: PropTypes.func,
  companyName: PropTypes.string,
  companyAddress: PropTypes.string,
  companyLogo: PropTypes.string,
  payslipIssueDate: PropTypes.string,
  salarySlipData: PropTypes.shape,
  loading: PropTypes.bool,
  arrearDataRow: PropTypes.arrayOf,
  arrearDataColumn: PropTypes.arrayOf,
  sortBy: PropTypes.func,
  sortDirection: PropTypes.string,
  count: PropTypes.number,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  onSortChange: PropTypes.func,
};

export default forwardRef(SalarySlipDetail);
