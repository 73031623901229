import React from 'react';
import InputField from 'components/Atoms/InputField';
import { getIn } from 'formik';
import { validateNumberElseHyphen } from 'utils/commonUtils';

const columns = [
  {
    fieldName: 'expenseCategory',
    label: 'Expense Category',
    formatValue: ({ row, column }) => row[column.fieldName]?.displayName,
  },
  {
    fieldName: 'billNumber',
    label: 'Bill/Receipt Number',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'expensedDate',
    label: 'Bill/Receipt Date',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'projectCode',
    label: 'Project Code',
    formatValue: ({ row }) => row?.project?.code || '',
  },
  {
    fieldName: 'currency',
    label: 'Currency',
    formatValue: ({ row }) =>
      `${row?.expensedCurrency?.country} - ${row?.expensedCurrency?.name} (${row?.expensedCurrency?.symbol})`,
  },
  {
    fieldName: 'expensedAmount',
    label: 'Claim Amount',
    formatValue: ({ row, column }) => validateNumberElseHyphen(row[column.fieldName]),
  },
  {
    fieldName: 'expensedAmount',
    label: 'Approved Amount',
    formatValue: ({ _row, _column, formik, index }) => (
      <InputField
        key={index}
        error={Boolean(
          getIn(formik.touched, `expenseRecord[${index}].approvedAmount`) &&
            getIn(formik.errors, `expenseRecord[${index}].approvedAmount`),
        )}
        helperText={
          getIn(formik.touched, `expenseRecord[${index}].approvedAmount`) &&
          getIn(formik.errors, `expenseRecord[${index}].approvedAmount`)
        }
        onChange={formik.handleChange}
        onBlur={() => {
          const sum = formik.values?.expenseRecord?.reduce((accumulator, object) => {
            return accumulator + (object.amount || object.approvedAmount);
          }, 0);
          formik.setFieldValue(`totalApprovedAmount`, Number.isNaN(sum) ? 0 : sum);
        }}
        value={formik.values?.expenseRecord[index]?.approvedAmount}
        name={`expenseRecord[${index}].approvedAmount`}
        id={`expenseRecord[${index}].approvedAmount`}
        type="number"
        fullWidth
      />
    ),
  },
  {
    fieldName: 'comment',
    label: 'Employee Notes',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'approvalNotes',
    label: 'Approval Notes',
    formatValue: ({ _row, _column, formik, index }) => (
      <InputField
        key={index}
        error={Boolean(
          getIn(formik.touched, `expenseRecord[${index}].approvalNotes`) &&
            getIn(formik.errors, `expenseRecord[${index}].approvalNotes`),
        )}
        helperText={
          getIn(formik.touched, `expenseRecord[${index}].approvalNotes`) &&
          getIn(formik.errors, `expenseRecord[${index}].approvalNotes`)
        }
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values?.expenseRecord[index]?.approvalNotes}
        name={`expenseRecord[${index}].approvalNotes`}
        id={`expenseRecord[${index}].approvalNotes`}
        fullWidth
      />
    ),
  },
];

const useColumns = () => {
  return [columns];
};

export default useColumns;
