import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Link, Button } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import IconWithTitle from 'components/Atoms/IconWithTitle';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Avatars from 'components/Molecules/Avatar';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import TitleWithText from 'components/Atoms/TitleWithText';
import { reporteeCardStyle } from './style';

const Reportee = ({ reporteeList, handleReporteeClick, handleUpwardHierarchyClick }) => {
  return (
    <Box p={1.5}>
      {reporteeList?.map((reportee) => (
        <Box sx={reporteeCardStyle.wrapper} m={1.5} lg={6} md={12} sm={12}>
          <Box sx={{ flexShrink: 0, justifyContent: 'center' }}>
            <Avatars avatarSrc={reportee?.avatarSrc} sx={reporteeCardStyle.avatar} />
          </Box>
          <Box sx={reporteeCardStyle.actionWrapper}>
            <Box sx={reporteeCardStyle.action}>
              <Typographys variant="h4">{`${reportee?.reporteeName}`}</Typographys>
              <Grid item sm={6} md="auto" pt={1}>
                <Button sx={{ textDecoration: 'none' }} onClick={() => handleReporteeClick(reportee?.reporteeId)}>
                  <TitleWithText padding="0" text="Reportees" textColor="#1976d2" />
                </Button>
              </Grid>
              <Grid item sm={6} md="auto">
                <Button
                  sx={{ textDecoration: 'none' }}
                  onClick={() => handleUpwardHierarchyClick(reportee?.reporteeId)}
                >
                  <TitleWithText padding="0" text="Upward Hierarchy" textColor="#1976d2" />
                </Button>
              </Grid>
            </Box>
            <Grid container sx={reporteeCardStyle?.contactInfo}>
              <Grid item sm={6} md="auto">
                <IconWithTitle iconElement={<LocationOnIcon fontSize="inherit" />} title={reportee?.location} />
              </Grid>
              <Grid item sm={6} md="auto">
                <Link sx={{ textDecoration: 'none' }} href={`mailto:${reportee?.emailAddresses}`}>
                  <IconWithTitle iconElement={<EmailIcon fontSize="inherit" />} title={reportee?.emailAddresses} />
                </Link>
              </Grid>
              <Grid item sm={6} md="auto">
                <Link sx={{ textDecoration: 'none' }} href={`tel:${reportee?.mobileNumbers}`}>
                  <IconWithTitle iconElement={<CallIcon fontSize="inherit" />} title={reportee?.mobileNumbers} />
                </Link>
              </Grid>
            </Grid>

            <Grid container sx={reporteeCardStyle.employmentInfo}>
              <Grid item sm={4} md="auto">
                <TitleWithText padding="0" title="Employee ID" text={reportee?.reporteeEmployeeId} />
              </Grid>
              <Grid item sm={4} md="auto">
                <TitleWithText padding="0" title="Designation" text={reportee?.designation} />
              </Grid>
              <Grid item sm={4} md="auto">
                <TitleWithText padding="0" title="Department" text={reportee?.department} />
              </Grid>
              <Grid item sm={4} md="auto">
                <TitleWithText padding="0" title="Supervisor" text={reportee?.supervisor} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

Reportee.defaultProps = {
  reporteeList: {
    name: 'Uday Kumar',
    avatarSrc: 'images/avatar.jpg',
    progressBarValue: 67,
  },
  handleReporteeClick: () => {},
  handleUpwardHierarchyClick: () => {},
};

Reportee.propTypes = {
  reporteeList: PropTypes.shape({
    name: PropTypes.string,
    avatarSrc: PropTypes.string,
    progressBarValue: PropTypes.number,
    location: PropTypes.string,
    email: PropTypes.string,
    mobile: PropTypes.string,
    phone: PropTypes.string,
    designation: PropTypes.string,
    department: PropTypes.string,
    supervisor: PropTypes.string,
    employeeID: PropTypes.string,
    employmentType: PropTypes.string,
  }),
  handleReporteeClick: PropTypes.func,
  handleUpwardHierarchyClick: PropTypes.func,
};

export default Reportee;
