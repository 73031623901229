import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Button from 'components/Atoms/Button';
import AddIcon from '@mui/icons-material/Add';
import DropdownMenu from 'components/Molecules/DropdownMenu';
import Typographys from 'components/Atoms/Typography';
import FilterButton from 'components/Organisms/TableFilter/FilterButton';
import TableExtended from 'components/Molecules/TableExtended';
import BulkUploadDocument from 'components/Organisms/Dialog/ActionList/BulkUploadDialog';
import NoRecordsMessage from 'components/Organisms/CommonPageUtils/NoRecordsFound';
import { profileStyle } from '../style';

const DocumentList = ({
  loading,
  onSortChange,
  rows,
  page,
  sortDirection,
  sortBy,
  handleSelect,
  onSelect,
  onSelectAll,
  count,
  selectedItems,
  showPagination,
  onPageChange,
  handleSort,
  handleRowClick,
  keyColumn,
  showActionList,
  handleAdd,
  roleBasedMenu,
  showRowSelector,
  showActions,
  showAddNew,
  columns,
  handleFilter,
  filterFields,
  handleBulkUploadProceed,
  isBulkUploadLoading,
  isBulkUploadError,
  handleBulkUploadChooseFile,
  handleBulkUploadClickHere,
  actionOptions,
  handleFiltersClear,
  showFilter,
}) => {
  const [showBulkUpload, setShowBulkUpload] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const handleBulkUploadCancel = () => {
    setIsOpen(false);
    setShowBulkUpload(false);
  };
  const handleBulkUpload = () => {
    setShowBulkUpload(true);
    setIsOpen(true);
  };
  const menuList = [
    { label: 'Bulk Upload', onClick: handleBulkUpload },
    { label: 'Bulk Publish', onClick: () => {} },
  ];
  return (
    <Box mb={9}>
      <Box sx={{ display: 'flex', justifyContent: `${showFilter ? 'space-between' : 'end'}` }}>
        <Box>
          {!!showFilter && (
            <FilterButton
              handleSubmit={handleFilter}
              attributeOptions={filterFields}
              handleFiltersClear={handleFiltersClear}
            />
          )}
        </Box>
        <Box sx={profileStyle.actionBtn}>
          {showAddNew && (
            <Button startIcon={<AddIcon fontSize="2.5rem" />} onClick={handleAdd}>
              Add New
            </Button>
          )}
          {showActions && <DropdownMenu btnLabel="Action" size="medium" menuLists={menuList} />}
        </Box>
      </Box>
      <Box mt={2}>
        {!loading && rows.length === 0 ? (
          <NoRecordsMessage width="20rem" height="20rem" />
        ) : (
          <TableExtended
            loading={loading}
            rows={rows}
            columns={columns}
            count={count}
            selectedItems={selectedItems}
            onSelect={onSelect}
            onSelectAll={onSelectAll}
            showRowSelector={showRowSelector}
            showPagination={showPagination}
            onPageChange={onPageChange}
            handleSort={handleSort}
            onRowClick={handleRowClick}
            page={page}
            keyColumn={keyColumn}
            roleBasedMenu={roleBasedMenu}
            handleSelect={handleSelect}
            showActionList={showActionList}
            sortBy={sortBy}
            sortDirection={sortDirection}
            onSortChange={onSortChange}
            actionOptions={actionOptions}
          />
        )}
      </Box>
      {showBulkUpload && (
        <BulkUploadDocument
          handleProceed={handleBulkUploadProceed}
          handleCancel={handleBulkUploadCancel}
          isLoading={isBulkUploadLoading}
          isError={isBulkUploadError}
          dialogTitle="Bulk Upload Document"
          open={isOpen}
          handleChooseFile={handleBulkUploadChooseFile}
          handleClick={handleBulkUploadClickHere}
        />
      )}
    </Box>
  );
};

DocumentList.defaultProps = {
  onSelect: () => {},
  onSelectAll: () => {},
  filterFields: [],
  loading: false,
  onSortChange: () => {},
  roleBasedMenu: () => {},
  rows: [],
  columns: [],
  handleSelect: () => {},
  sortBy: () => {},
  page: '',
  sortDirection: 'asc',
  handleAdd: () => {},
  handleBulkUploadProceed: () => {},
  isBulkUploadLoading: false,
  isBulkUploadError: false,
  handleBulkUploadChooseFile: () => {},
  handleBulkUploadClickHere: () => {},
  showRowSelector: false,
  showActions: false,
  showAddNew: false,
  actionOptions: [],
  handleFilter: () => {},
  count: 10,
  selectedItems: [],
  showPagination: false,
  onPageChange: () => {},
  handleSort: () => {},
  handleRowClick: () => {},
  keyColumn: '',
  showActionList: false,
  handleFiltersClear: () => {},
  showFilter: false,
};

DocumentList.propTypes = {
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  filterFields: PropTypes.arrayOf,
  loading: PropTypes.bool,
  onSortChange: PropTypes.func,
  roleBasedMenu: PropTypes.func,
  handleSelect: PropTypes.func,
  sortBy: PropTypes.func,
  page: PropTypes.number,
  sortDirection: PropTypes.string,
  rows: PropTypes.arrayOf,
  columns: PropTypes.arrayOf,
  handleAdd: PropTypes.func,
  handleBulkUploadProceed: PropTypes.func,
  isBulkUploadLoading: PropTypes.bool,
  isBulkUploadError: PropTypes.bool,
  handleBulkUploadChooseFile: PropTypes.func,
  handleBulkUploadClickHere: PropTypes.func,
  showRowSelector: PropTypes.bool,
  showActions: PropTypes.bool,
  showAddNew: PropTypes.bool,
  actionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  handleFilter: PropTypes.func,
  count: PropTypes.number,
  selectedItems: PropTypes.arrayOf,
  showActionList: PropTypes.bool,
  handleSort: PropTypes.func,
  handleRowClick: PropTypes.func,
  showPagination: PropTypes.bool,
  onPageChange: PropTypes.func,
  keyColumn: PropTypes.string,
  handleFiltersClear: PropTypes.func,
  showFilter: PropTypes.bool,
};

export default DocumentList;
