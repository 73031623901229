export const CompareTDSStyle = {
  sectionHeadingWithBtn: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  categoryTableWrapper: {
    '.MuiTable-root': {
      '.MuiTableBody-root': {
        '.MuiTableRow-root': {
          '.MuiTableCell-root': {
            p: 2,
            svg: {
              display: 'none',
            },
            '&:first-child': {
              minWidth: '30rem',
            },
            '&:last-child': {
              '.MuiTypography-root': {
                fontSize: '2rem',
                lineHeight: '3rem',
                fontWeight: '500',
              },
            },

            '.MuiTypography-root': {
              fontWeight: '400',
            },
          },

          '&:last-child ,&:nth-last-child(2)': {
            backgroundColor: 'neutral.100',

            '.MuiTableCell-root': {
              '.MuiTypography-root': {
                fontSize: '2rem',
                lineHeight: '3rem',
                fontWeight: '500',
              },
            },
          },
        },
      },
    },
  },

  hraTableWrapper: {
    overflow: 'auto',
    border: '0.125rem solid',
    borderColor: 'other.200',
    borderRadius: '0.5rem',
  },

  hraTableRow: {
    display: 'flex',
    p: '1rem',
  },

  deductionTableWrapper: {
    border: '0.125rem solid',
    borderColor: 'other.200',
    borderRadius: '0.5rem',
    '.MuiTable-root': {
      '.MuiTableRow-root': {
        '.MuiTableCell-root': {
          p: 1,
        },
      },
      '.MuiTableHead-root .MuiTableCell-root': {
        p: '1rem !important',
        textTransform: 'capitalize',
      },
    },
  },

  deductionTableRows: {
    '&>td, &>th': {
      border: 0,
    },
  },

  deductionTableFooter: {
    display: 'flex',
    p: '1rem',
    borderTop: '0.125rem solid',
    borderColor: 'other.200',
  },

  headsTableHeader: {
    display: 'flex',
    p: '12px 8px',
    backgroundColor: 'info.200',
  },

  headsTableRows: {
    display: 'flex',
    p: '1rem',
  },

  headsTableSubTitleRows: {
    display: 'flex',
    p: '0.5rem 1rem',
  },
  headsTableSubTitleColumn: {
    '> p': {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
};
