export const leaveCardGroupStyle = {
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '-1rem',

    '> div': {
      width: 'calc(20% - 6rem)',
      margin: '1rem',

      '@media screen and (max-width: 991px)': {
        width: 'calc(33% - 6rem)',
      },

      '@media screen and (max-width: 767px)': {
        width: 'calc(33% - 6rem)',
      },

      '@media screen and (max-width: 576px)': {
        width: 'calc(50% - 6rem)',
      },
    },
  },
};
