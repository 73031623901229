import React from 'react';
import PropTypes from 'prop-types';
import { Box, Link } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import Dropdown from 'components/Molecules/Dropdown';
import ActionDropDown from 'components/Molecules/ActionDropDown';
import { commonCardStyle } from './style';

const CommonCard = ({
  isHeader,
  cardHeaderTitle,
  isHeaderFilter,
  minWidthHeaderDropdown,
  headerFilterId,
  headerFilterLabel,
  headerFilterOptions,
  headerFilterHandleChange,
  bodyContent,
  isFooter,
  footerLink,
  footerLinkLabel,
  FooterContent,
  cardBodyMinHeight,
  cardBodyPadding,
  isSelect,
}) => {
  return (
    <Box sx={commonCardStyle.card}>
      {isHeader && (
        <Box sx={commonCardStyle.cardHeader}>
          <Typographys variant="h6" color="neutral.800">
            {cardHeaderTitle}
          </Typographys>
          {isHeaderFilter && (
            <Box minWidth={minWidthHeaderDropdown}>
              {isSelect ? (
                <Dropdown
                  variant="standard"
                  id={headerFilterId}
                  name={headerFilterId}
                  label={headerFilterLabel}
                  labelId={headerFilterId}
                  options={headerFilterOptions}
                  handleChange={headerFilterHandleChange}
                />
              ) : (
                <ActionDropDown
                  id={headerFilterId}
                  options={headerFilterOptions}
                  label={headerFilterLabel}
                  handleSelect={headerFilterHandleChange}
                />
              )}
            </Box>
          )}
        </Box>
      )}

      <Box padding={cardBodyPadding} minHeight={cardBodyMinHeight} sx={commonCardStyle.cardBody}>
        {bodyContent}
      </Box>
      {isFooter && (
        <Box sx={commonCardStyle.cardFooter}>
          <Link href={footerLink}>{footerLinkLabel}</Link> {FooterContent}
        </Box>
      )}
    </Box>
  );
};

CommonCard.defaultProps = {
  isHeader: true,
  cardHeaderTitle: 'New Hires',
  isHeaderFilter: true,
  minWidthHeaderDropdown: '6rem',
  headerFilterId: 'This week',
  headerFilterLabel: 'This Week',
  headerFilterOptions: [
    { id: 1, name: 'Option 1', onClick: () => {} },
    { id: 2, name: 'Option 2', onClick: () => {} },
    { id: 3, name: 'Option 3', onClick: () => {} },
  ],
  headerFilterHandleChange: () => {},
  bodyContent: '',
  isFooter: true,
  footerLink: '#',
  footerLinkLabel: 'View Details',
  FooterContent: '',
  cardBodyMinHeight: '20rem',
  cardBodyPadding: '0',
  isSelect: true,
};
CommonCard.propTypes = {
  isHeader: PropTypes.bool,
  cardHeaderTitle: PropTypes.string,
  isHeaderFilter: PropTypes.bool,
  minWidthHeaderDropdown: PropTypes.string,
  headerFilterId: PropTypes.string,
  headerFilterLabel: PropTypes.string,
  headerFilterOptions: PropTypes.arrayOf(PropTypes.shape()),
  headerFilterHandleChange: PropTypes.func,
  bodyContent: PropTypes.node,
  isFooter: PropTypes.bool,
  footerLink: PropTypes.string,
  footerLinkLabel: PropTypes.string,
  FooterContent: PropTypes.node,
  cardBodyMinHeight: PropTypes.string,
  cardBodyPadding: PropTypes.string,
  isSelect: PropTypes.string,
};

export default CommonCard;
