import React, { useState } from 'react';
import EditProfileTemplate from 'components/Templates/Profile/BasicProfile/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useToast from 'components/Provider/useToast';
import { API_URL } from 'api/api';
import { getCurrentUser } from 'api/login';
import { updateUser } from 'store/slices/user';
import dayjs from 'dayjs';
import { addEditBasicProfileDetails } from 'api/profile';
import { getBasicProfileFormData } from './getBasicProfileFormData';

const BasicProfileAddEdit = () => {
  const addToast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => {
    return state.session;
  });

  const {
    employeeProfileInstanceDetails,
    maritalStatusOptionsData,
    bloodGroupOptionsData,
    employeeInstanceDetails,
    companyConfig,
  } = useSelector((state) => {
    return state.profile;
  });

  const getEmergencyContactInfo = () => {
    const primaryReferenceDependents =
      employeeProfileInstanceDetails?.dependents &&
      employeeProfileInstanceDetails?.dependents?.filter(
        (item) => item?.emergencyContactReference === 'PrimaryReference',
      );
    return primaryReferenceDependents !== undefined ? primaryReferenceDependents[0] : '';
  };

  const editOptions = {
    defaultValues: {
      mobileNumber: employeeProfileInstanceDetails?.employee?.mobileNumbers
        ? employeeProfileInstanceDetails?.employee?.mobileNumbers
        : [],
      emailAddress: employeeProfileInstanceDetails?.employee?.emailAddresses
        ? employeeProfileInstanceDetails?.employee?.emailAddresses
        : [],
      telephoneNumber: employeeProfileInstanceDetails?.employee?.telephoneNumbers
        ? employeeProfileInstanceDetails?.employee?.telephoneNumbers
        : [],
      name: employeeProfileInstanceDetails?.employee?.fullName,
      relation: getEmergencyContactInfo()?.relationship,
      emergencyMobile: getEmergencyContactInfo()?.contactInfo?.mobileNumber,
      emergencyEmail: getEmergencyContactInfo()?.contactInfo?.emailAddress,
      maritalStatus:
        employeeInstanceDetails?.maritalStatus !== 'undefined' ? employeeInstanceDetails?.maritalStatus : 'UnMarried',
      fatherName: employeeProfileInstanceDetails?.fathersName || '',
      motherName: employeeProfileInstanceDetails?.mothersName || '',
      spouseName: employeeProfileInstanceDetails?.spouseName || '',
      bloodgroup: employeeProfileInstanceDetails?.bloodGroup,
      skypeid: employeeProfileInstanceDetails?.employee?.skypeID || '',
      facebookid: employeeProfileInstanceDetails?.employee?.facebookID || '',
      linkedinid: employeeProfileInstanceDetails?.employee?.linkedinID || '',
      googlePlusid: employeeProfileInstanceDetails?.employee?.googlePlusID || '',
      twitterid: employeeProfileInstanceDetails?.employee?.twitterID || '',
      instagramid: employeeProfileInstanceDetails?.employee?.instagramID || '',
      selfdescription: employeeProfileInstanceDetails?.selfDescription || '',
      passportnumber: employeeProfileInstanceDetails?.passportNumber || '',
      passportissuedate: dayjs(employeeProfileInstanceDetails?.passportIssueDate),
      passportexpirydate: dayjs(employeeProfileInstanceDetails?.passportExpiryDate),
      passportplaceissue: employeeProfileInstanceDetails?.passportPlaceOfIssue || '',
      passportcountry: employeeProfileInstanceDetails?.passportCountry || '',
      passportnamegiven: employeeProfileInstanceDetails?.passportNameGiven || '',
      esi: employeeProfileInstanceDetails?.employee?.esiAccountNumberIndia || '',
      eps: employeeProfileInstanceDetails?.employee?.epsAccountNumberIndia || '',
      epf: employeeProfileInstanceDetails?.employee?.epfAccountNumberIndia || '',
      uhid: employeeProfileInstanceDetails?.employee?.uhidAccountNumberIndia || '',
      uan: employeeProfileInstanceDetails?.employee?.uanNumberIndia || '',
      aadharNumber: employeeProfileInstanceDetails?.employee?.aadhaarNumberIndia || '',
      panNumber: employeeProfileInstanceDetails?.employee?.panNumberIndia || '',
      uploadProfile: user?.profilePic && user?.profilePic !== 'null' ? `${API_URL}/${user?.profilePic}` : '',
      makeAadhaarAndPANMandatory: companyConfig?.makeAadhaarAndPANMandatory,
      makePassportDetailsMandatory: companyConfig?.makePassportDetailsMandatory,
    },
    bloodgroupOptions: Object.values(bloodGroupOptionsData).map((item) => ({ id: item, name: item })),
    maritalStatusOptions: Object.values(maritalStatusOptionsData).map((item) => ({ id: item, name: item })),
  };

  const handleCancel = () => {
    navigate('/profile/basic-profile');
  };

  const handleSubmit = async (formikData) => {
    const bodyFormData = await getBasicProfileFormData(formikData);

    try {
      setLoading(true);
      const response = await addEditBasicProfileDetails(bodyFormData);
      if (response?.data?.status !== 'ERROR') {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Basic Profile Updated successfully`,
        });
      }
      const currentUser = await getCurrentUser();
      dispatch(updateUser({ ...currentUser.data, authorities: user.authorities }));
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to update profile`,
      });
    } finally {
      navigate('/profile/basic-profile');
      setLoading(false);
    }
  };

  return (
    <EditProfileTemplate
      defaultValues={editOptions.defaultValues}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      loading={loading}
      maritalStatusOptions={editOptions.maritalStatusOptions}
      bloodgroupOptions={editOptions.bloodgroupOptions}
    />
  );
};

export default BasicProfileAddEdit;
