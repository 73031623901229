import EmployeeTimeLine from './EmployeeTimeline';

const useEmployeeTabs = (finalEmployeeInstance) => {
  return {
    'User Identity': {
      userDetails: finalEmployeeInstance.userDetails,
    },
    'User Roles': {
      userRoles: finalEmployeeInstance.userRoles.join(','),
    },
    'Basic Profile': {
      employeeBasicDetail: finalEmployeeInstance.employeeBasicDetail,
    },
    'Additional Details': {
      onboardingDetails: finalEmployeeInstance.onboardingDetails,
      socialData: finalEmployeeInstance.socialData,
      educationHistory: finalEmployeeInstance.educationHistory,
      employmentHistory: finalEmployeeInstance.employmentHistory,
      visaDetails: finalEmployeeInstance.visaDetails,
      skillsAttainmentDetails: finalEmployeeInstance.skillsAttainmentDetails,
      keyEventDetails: finalEmployeeInstance.keyEventDetails,
      dependentDetails: finalEmployeeInstance.dependentDetails,
    },
    'Contact Details': {
      contactDetails: finalEmployeeInstance.contactDetails,
      emergencyContactDetails: finalEmployeeInstance.dependentDetails,
    },
    'Bank Account': {
      bankDetails: finalEmployeeInstance.bankDetails,
    },
    'Payroll Setting': {
      payrollSetting: finalEmployeeInstance.payrollSetting,
    },
    'Extended Attributes': {
      extendedAttribute: {
        hiringWave: '',
        deparment: '',
        designation: '',
        joiningBonus: '',
      },
    },
    Timelines: {
      RenderComponent: EmployeeTimeLine,
    },
    'Audit Trail': { auditTrailLists: finalEmployeeInstance.auditTrails },
    'Notification Setting': {
      notificationLists: finalEmployeeInstance.notificationLists,
    },
  };
};

export default useEmployeeTabs;
