import React from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import View from 'components/Organisms/MyPay/PastEmployerTaxes/View';
import { Box, Skeleton } from '@mui/material';

const ViewTemplate = ({
  breadcrumbData,
  taxDetails,
  handleEdit,
  pageHeading,
  menuList,
  handleAttachment,
  loading,
  auditLogLists,
  isEditAction,
}) => {
  return (
    <BlankTemplate pageHeading={pageHeading} isBreadcrumb breadcrumbData={breadcrumbData} padding="2rem 3rem">
      {loading ? (
        <Box sx={{ p: 4 }}>
          <Skeleton height={42} />
          <Skeleton />
          <Skeleton />
        </Box>
      ) : (
        <View
          handleAttachment={handleAttachment}
          taxDetails={taxDetails}
          handleEdit={handleEdit}
          menuList={menuList}
          auditLogLists={auditLogLists}
          isEditAction={isEditAction}
        />
      )}
    </BlankTemplate>
  );
};

ViewTemplate.defaultProps = {
  breadcrumbData: [
    { id: 1, name: 'Past Employer Taxes', path: '/home/mypay/past-employer-taxes', isActive: false },
    { id: 2, name: 'Tax Details', path: '', isActive: true },
  ],
  loading: false,
  handleAttachment: () => {},
  taxDetails: {},
  handleEdit: () => {},
  pageHeading: 'Past Employer Tax Details',
  menuList: [],
  auditLogLists: {},
  isEditAction: false,
};

ViewTemplate.propTypes = {
  breadcrumbData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
  handleAttachment: PropTypes.func,
  taxDetails: PropTypes.shape,
  handleEdit: PropTypes.func,
  pageHeading: PropTypes.string,
  menuList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  auditLogLists: PropTypes.shape,
  isEditAction: PropTypes.bool,
};

export default ViewTemplate;
