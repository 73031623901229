import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import { leaveHighlightCardStyle } from './style';
import LeaveBalanceList from './LeaveBalanceList';
import LeaveChart from '../LeaveChart';

const LeaveHighlightCard = ({ totalLeaveTransactions, leaveData }) => {
  return (
    <Box sx={leaveHighlightCardStyle.wrapper}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={3}>
          <Typographys variant="subtitle2" color="neutral.800">
            TOTAL LEAVE TRANSACTIONS
          </Typographys>
          <Typographys variant="h3" color="neutral.800">
            {totalLeaveTransactions}
          </Typographys>
        </Grid>
        <Grid item xs={12} sm={7} md={5}>
          <LeaveChart leaveData={leaveData} />
        </Grid>
        <Grid item xs={12} sm={5} md={4}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <LeaveBalanceList title="OPENING BALANCE" listData={leaveData} />
            </Grid>
            <Grid item xs={6}>
              <LeaveBalanceList title="CURRENT BALANCE" listData={leaveData} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

LeaveHighlightCard.defaultProps = {
  totalLeaveTransactions: 0,
  leaveData: [],
};
LeaveHighlightCard.propTypes = {
  totalLeaveTransactions: PropTypes.number,
  leaveData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
      openingBalance: PropTypes.number,
      leavesAvailed: PropTypes.number,
      currentBalance: PropTypes.number,
      transactions: PropTypes.number,
      pendingApprovalBalance: PropTypes.number,
    }),
  ),
};

export default LeaveHighlightCard;
