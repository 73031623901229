import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import SalarySlipDetailTemplate from 'components/Templates/MyPay/SalarySlips/Details';
import { fetchSalarySlipDetails, fetchConsolidatedSalarySlipDetails } from 'store';
import { useParams, useLocation } from 'react-router-dom';
import { useThunk } from 'hooks/useThunk';
import { ArrayTranspose } from 'utils/ArrayTranspose';
import dayjs from 'dayjs';
import { validateDateElseHyphen, validateNumberElseHyphen } from 'utils/commonUtils';
import { ToWords } from 'to-words';
import { Box, Typography, Skeleton } from '@mui/material';
import { calculateArrearsTotal } from './calculateArrearsTotal';
import { edTableComputedData } from './edTableComputedData';

const SalarySlipDetailPage = () => {
  const location = useLocation();
  const toWords = new ToWords();
  const arrearDataRow = [];
  const { id } = useParams();

  const [getSalarySlipDetails, isLoadingSalarySlipDetail, loadingErrorSalarySlipDetailError] =
    useThunk(fetchSalarySlipDetails);
  const [getConsolidatedSalarySlipDetails, isLoadingConsolidatedSalarySlipDetail, consolidatedSalarySlipDetailError] =
    useThunk(fetchConsolidatedSalarySlipDetails);
  const consolidatedSalarySlipsFlag = location.state?.consolidatedSalarySlipsFlag;
  const salarySlipIdForDownloadWhenConsolidatedSalarySlip = location.state?.salarySlipId;

  useEffect(() => {
    const query = new URLSearchParams({});
    query.append('id', id);
    if (!consolidatedSalarySlipsFlag) {
      getSalarySlipDetails(query);
    } else {
      getConsolidatedSalarySlipDetails(query);
    }
  }, [getSalarySlipDetails, getConsolidatedSalarySlipDetails, consolidatedSalarySlipsFlag, id]);

  const { salarySlipDetails } = useSelector((state) => state?.salarySlip);
  const salarySlipApiData = { ...salarySlipDetails?.data };

  if (isLoadingSalarySlipDetail || isLoadingConsolidatedSalarySlipDetail) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (loadingErrorSalarySlipDetailError !== null || consolidatedSalarySlipDetailError !== null) {
    return (
      <Typography variant="h6">
        {loadingErrorSalarySlipDetailError?.message ||
          consolidatedSalarySlipDetailError?.message ||
          'Error while fetching salary slip details.'}
      </Typography>
    );
  }

  let paidDays = '';
  if (salarySlipApiData?.salarySlipInstance?.isNumPaidDaysSavedOnCreate) {
    paidDays = salarySlipApiData?.salarySlipInstance?.numberOfPaidDays || '';
  } else if (
    salarySlipApiData?.salarySlipInstance?.numberOfWorkingDays &&
    salarySlipApiData?.salarySlipInstance?.numberOfLOPDays
  ) {
    paidDays =
      salarySlipApiData.salarySlipInstance.numberOfWorkingDays - salarySlipApiData.salarySlipInstance.numberOfLOPDays;
  }

  const salarySlipData = {
    ssDate: `${validateDateElseHyphen(
      salarySlipApiData?.salarySlipInstance?.periodStartDate,
      'DD MMMM YYYY',
    )} - ${validateDateElseHyphen(salarySlipApiData?.salarySlipInstance?.periodEndDate, 'DD MMMM YYYY')}`,
    employeeName: `${salarySlipApiData?.employeeInstance?.firstName} ${salarySlipApiData?.employeeInstance?.lastName}`,
    employeeID: salarySlipApiData?.employeeProfileInstance?.employeeID || '',
    designation: salarySlipApiData?.employeeInstance?.designation?.name
      ? salarySlipApiData.employeeInstance.designation.name
      : '-',
    location: salarySlipApiData?.employeeInstance?.location?.name
      ? salarySlipApiData.employeeInstance.location.name
      : '-',
    bank: salarySlipApiData?.bankDetail?.bankName ? salarySlipApiData.bankDetail.bankName : '-',
    bankAccountNo: salarySlipApiData?.bankDetail?.accountNumber ? salarySlipApiData.bankDetail.accountNumber : '-',
    pan: salarySlipApiData?.employeeInstance?.panNumberIndia ? salarySlipApiData.employeeInstance.panNumberIndia : '-',
    uan: salarySlipApiData?.employeeInstance?.uanNumberIndia ? salarySlipApiData.employeeInstance.uanNumberIndia : '-',
    pf: salarySlipApiData?.employeeInstance?.epfAccountNumberIndia
      ? salarySlipApiData.employeeInstance.epfAccountNumberIndia
      : '-',
    esi: salarySlipApiData?.employeeInstance?.esiAccountNumberIndia
      ? salarySlipApiData.employeeInstance.esiAccountNumberIndia
      : '-',
    dateOfJoining: validateDateElseHyphen(salarySlipApiData?.employeeProfileInstance?.dateOfJoining, 'DD MMMM YYYY'),

    dateOfLeaving: validateDateElseHyphen(salarySlipApiData?.employeeProfileInstance?.dateOfLeaving, 'DD MMMM YYYY'),
    workingDays: salarySlipApiData?.salarySlipInstance?.numberOfCalendarDays || '',
    lop: salarySlipApiData?.salarySlipInstance?.numberOfLOPDays || '',
    paidDays,
    edTableData: [],
    edTableTotalData: [],
    netSalary: salarySlipApiData?.salarySlipInstance?.netFinalSalary || '',
    netSalaryInWord: salarySlipApiData?.salarySlipInstance?.netFinalSalary
      ? `Rupees ${toWords.convert(salarySlipApiData?.salarySlipInstance?.netFinalSalary)} only`
      : '',
  };

  // ED Table computation

  // Generic Functions & Variables
  const earningsLineEntries = salarySlipApiData?.earningsLineEntries;
  const benefitsLineEntries = salarySlipApiData?.benefitsLineEntries;
  const reimbursementsLineEntries = salarySlipApiData?.reimbursementsLineEntries;
  const deductionsLineEntries = salarySlipApiData?.deductionsLineEntries;
  const statDeductionsEmployeeLineEntries = salarySlipApiData?.statDeductionsEmployeeLineEntries;
  const loansAndAdvancesLineEntries = salarySlipApiData?.loansAndAdvancesLineEntries;

  const payheadLineEntriesMap = salarySlipApiData?.payheadLineEntriesMap;
  const payheadValuesMap = salarySlipApiData?.payheadValuesMap;
  const ytdTotalsMap = salarySlipApiData?.finalPayheadNameWiseYTDTotals;

  // Arrear Calculations

  // Current Month Earning Computation
  let arrearsEarningsTotal = 0;
  arrearsEarningsTotal += earningsLineEntries
    ? calculateArrearsTotal(earningsLineEntries, payheadLineEntriesMap, payheadValuesMap, 'earning')
    : 0;
  arrearsEarningsTotal += benefitsLineEntries
    ? calculateArrearsTotal(benefitsLineEntries, payheadLineEntriesMap, payheadValuesMap, 'non earning')
    : 0;
  arrearsEarningsTotal += reimbursementsLineEntries
    ? calculateArrearsTotal(reimbursementsLineEntries, payheadLineEntriesMap, payheadValuesMap, 'non earning')
    : 0;

  // Current Month Deduction Computation
  let arrearsDeductionsTotal = 0;
  arrearsDeductionsTotal += deductionsLineEntries
    ? calculateArrearsTotal(deductionsLineEntries, payheadLineEntriesMap, payheadValuesMap, 'non earning')
    : 0;
  arrearsDeductionsTotal += statDeductionsEmployeeLineEntries
    ? calculateArrearsTotal(statDeductionsEmployeeLineEntries, payheadLineEntriesMap, payheadValuesMap, 'non earning')
    : 0;
  arrearsDeductionsTotal += loansAndAdvancesLineEntries
    ? calculateArrearsTotal(loansAndAdvancesLineEntries, payheadLineEntriesMap, payheadValuesMap, 'non earning')
    : 0;

  // YTD Earning Computation
  let ytdArrearsEarningsTotal = 0;
  ytdArrearsEarningsTotal += earningsLineEntries
    ? calculateArrearsTotal(earningsLineEntries, payheadLineEntriesMap, ytdTotalsMap, 'earning')
    : 0;
  ytdArrearsEarningsTotal += benefitsLineEntries
    ? calculateArrearsTotal(benefitsLineEntries, payheadLineEntriesMap, ytdTotalsMap, 'non earning')
    : 0;
  ytdArrearsEarningsTotal += reimbursementsLineEntries
    ? calculateArrearsTotal(reimbursementsLineEntries, payheadLineEntriesMap, ytdTotalsMap, 'non earning')
    : 0;

  // YTD Deduction Computation
  let ytdArrearsDeductionTotal = 0;
  ytdArrearsDeductionTotal += deductionsLineEntries
    ? calculateArrearsTotal(deductionsLineEntries, payheadLineEntriesMap, ytdTotalsMap, 'non earning')
    : 0;
  ytdArrearsDeductionTotal += statDeductionsEmployeeLineEntries
    ? calculateArrearsTotal(statDeductionsEmployeeLineEntries, payheadLineEntriesMap, ytdTotalsMap, 'non earning')
    : 0;
  ytdArrearsDeductionTotal += loansAndAdvancesLineEntries
    ? calculateArrearsTotal(loansAndAdvancesLineEntries, payheadLineEntriesMap, ytdTotalsMap, 'non earning')
    : 0;

  // ED Table Data Computation

  let crrMonthEarningsTotal = 0;
  let crrMonthDeductionsTotal = 0;
  let ytdEarningsTotal = 0;
  let ytdDeductionsTotal = 0;

  const edTableData = [[], [], [], [], []];

  // First Column Computation - PayHead Name
  let firstColDataArr = [];
  if (earningsLineEntries) {
    firstColDataArr = edTableComputedData(
      earningsLineEntries,
      payheadLineEntriesMap,
      payheadValuesMap,
      'earning',
      'heading',
    );
    edTableData[0].push(...firstColDataArr);
  }
  if (benefitsLineEntries) {
    firstColDataArr = edTableComputedData(
      benefitsLineEntries,
      payheadLineEntriesMap,
      payheadValuesMap,
      'non earning',
      'heading',
    );
    edTableData[0].push(...firstColDataArr);
  }
  if (reimbursementsLineEntries) {
    firstColDataArr = edTableComputedData(
      reimbursementsLineEntries,
      payheadLineEntriesMap,
      payheadValuesMap,
      'non earning',
      'heading',
    );
    edTableData[0].push(...firstColDataArr);
  }
  if (arrearsEarningsTotal) {
    edTableData[0].push('Arrears - Earnings');
  }
  if (deductionsLineEntries) {
    firstColDataArr = edTableComputedData(
      deductionsLineEntries,
      payheadLineEntriesMap,
      payheadValuesMap,
      'earning',
      'heading',
    );
    edTableData[0].push(...firstColDataArr);
  }
  if (statDeductionsEmployeeLineEntries) {
    firstColDataArr = edTableComputedData(
      statDeductionsEmployeeLineEntries,
      payheadLineEntriesMap,
      payheadValuesMap,
      'earning',
      'heading',
    );
    edTableData[0].push(...firstColDataArr);
  }
  if (loansAndAdvancesLineEntries) {
    firstColDataArr = edTableComputedData(
      loansAndAdvancesLineEntries,
      payheadLineEntriesMap,
      payheadValuesMap,
      'earning',
      'heading',
    );
    edTableData[0].push(...firstColDataArr);
  }
  if (arrearsDeductionsTotal) {
    edTableData[0].push('Arrears - Deductions');
  }

  // Second Column Computation - Current Month Earning
  let secondColDataArr = [];
  if (earningsLineEntries) {
    secondColDataArr = edTableComputedData(
      earningsLineEntries,
      payheadLineEntriesMap,
      payheadValuesMap,
      'earning',
      'data column',
    );
    crrMonthEarningsTotal += secondColDataArr.reduce((partialSum, a) => partialSum + a, 0);
    edTableData[1].push(...secondColDataArr);
  }
  if (benefitsLineEntries) {
    secondColDataArr = edTableComputedData(
      benefitsLineEntries,
      payheadLineEntriesMap,
      payheadValuesMap,
      'non earning',
      'data column',
    );
    crrMonthEarningsTotal += secondColDataArr.reduce((partialSum, a) => partialSum + a, 0);
    edTableData[1].push(...secondColDataArr);
  }
  if (reimbursementsLineEntries) {
    secondColDataArr = edTableComputedData(
      reimbursementsLineEntries,
      payheadLineEntriesMap,
      payheadValuesMap,
      'non earning',
      'data column',
    );
    crrMonthEarningsTotal += secondColDataArr.reduce((partialSum, a) => partialSum + a, 0);
    edTableData[1].push(...secondColDataArr);
  }
  if (arrearsEarningsTotal) {
    crrMonthEarningsTotal += arrearsEarningsTotal;
    edTableData[1].push(arrearsEarningsTotal);
  }
  if (deductionsLineEntries) {
    secondColDataArr = edTableComputedData(
      deductionsLineEntries,
      payheadLineEntriesMap,
      payheadValuesMap,
      'non earning',
      'data zero',
    );
    edTableData[1].push(...secondColDataArr);
  }
  if (statDeductionsEmployeeLineEntries) {
    secondColDataArr = edTableComputedData(
      statDeductionsEmployeeLineEntries,
      payheadLineEntriesMap,
      payheadValuesMap,
      'non earning',
      'data zero',
    );
    edTableData[1].push(...secondColDataArr);
  }
  if (loansAndAdvancesLineEntries) {
    secondColDataArr = edTableComputedData(
      loansAndAdvancesLineEntries,
      payheadLineEntriesMap,
      payheadValuesMap,
      'earning',
      'data zero',
    );
    edTableData[1].push(...secondColDataArr);
  }
  if (arrearsDeductionsTotal) {
    edTableData[1].push(0);
  }

  // Third Column Computation - Current Month Deduction
  let thirdColDataArr = [];
  if (earningsLineEntries) {
    thirdColDataArr = edTableComputedData(
      earningsLineEntries,
      payheadLineEntriesMap,
      payheadValuesMap,
      'earning',
      'data zero',
    );
    edTableData[2].push(...thirdColDataArr);
  }
  if (benefitsLineEntries) {
    thirdColDataArr = edTableComputedData(
      benefitsLineEntries,
      payheadLineEntriesMap,
      payheadValuesMap,
      'non earning',
      'data zero',
    );
    edTableData[2].push(...thirdColDataArr);
  }
  if (reimbursementsLineEntries) {
    thirdColDataArr = edTableComputedData(
      reimbursementsLineEntries,
      payheadLineEntriesMap,
      payheadValuesMap,
      'non earning',
      'data zero',
    );
    edTableData[2].push(...thirdColDataArr);
  }
  if (arrearsEarningsTotal) {
    edTableData[2].push(0);
  }
  if (deductionsLineEntries) {
    thirdColDataArr = edTableComputedData(
      deductionsLineEntries,
      payheadLineEntriesMap,
      payheadValuesMap,
      'earning',
      'data column',
    );
    crrMonthDeductionsTotal += thirdColDataArr.reduce((partialSum, a) => partialSum + a, 0);
    edTableData[2].push(...thirdColDataArr);
  }
  if (statDeductionsEmployeeLineEntries) {
    thirdColDataArr = edTableComputedData(
      statDeductionsEmployeeLineEntries,
      payheadLineEntriesMap,
      payheadValuesMap,
      'earning',
      'data column',
    );
    crrMonthDeductionsTotal += thirdColDataArr.reduce((partialSum, a) => partialSum + a, 0);
    edTableData[2].push(...thirdColDataArr);
  }
  if (loansAndAdvancesLineEntries) {
    thirdColDataArr = edTableComputedData(
      loansAndAdvancesLineEntries,
      payheadLineEntriesMap,
      payheadValuesMap,
      'earning',
      'data column',
    );
    crrMonthDeductionsTotal += thirdColDataArr.reduce((partialSum, a) => partialSum + a, 0);
    edTableData[2].push(...thirdColDataArr);
  }
  if (arrearsDeductionsTotal) {
    crrMonthDeductionsTotal += arrearsDeductionsTotal;
    edTableData[2].push(arrearsDeductionsTotal);
  }

  // Fourth Column Computation - YTD Earning
  let fourthColDataArr = [];
  if (earningsLineEntries) {
    fourthColDataArr = edTableComputedData(
      earningsLineEntries,
      payheadLineEntriesMap,
      payheadValuesMap,
      'earning',
      'data total',
      ytdTotalsMap,
    );
    ytdEarningsTotal += fourthColDataArr.reduce((partialSum, a) => partialSum + a, 0);
    edTableData[3].push(...fourthColDataArr);
  }
  if (benefitsLineEntries) {
    fourthColDataArr = edTableComputedData(
      benefitsLineEntries,
      payheadLineEntriesMap,
      payheadValuesMap,
      'non earning',
      'data total',
      ytdTotalsMap,
    );
    ytdEarningsTotal += fourthColDataArr.reduce((partialSum, a) => partialSum + a, 0);
    edTableData[3].push(...fourthColDataArr);
  }
  if (reimbursementsLineEntries) {
    fourthColDataArr = edTableComputedData(
      reimbursementsLineEntries,
      payheadLineEntriesMap,
      payheadValuesMap,
      'non earning',
      'data total',
      ytdTotalsMap,
    );
    ytdEarningsTotal += fourthColDataArr.reduce((partialSum, a) => partialSum + a, 0);
    edTableData[3].push(...fourthColDataArr);
  }
  if (ytdArrearsEarningsTotal) {
    ytdEarningsTotal += ytdArrearsEarningsTotal;
    edTableData[3].push(ytdArrearsEarningsTotal);
  }
  if (deductionsLineEntries) {
    fourthColDataArr = edTableComputedData(
      deductionsLineEntries,
      payheadLineEntriesMap,
      payheadValuesMap,
      'non earning',
      'data zero',
    );
    edTableData[3].push(...fourthColDataArr);
  }
  if (statDeductionsEmployeeLineEntries) {
    fourthColDataArr = edTableComputedData(
      statDeductionsEmployeeLineEntries,
      payheadLineEntriesMap,
      payheadValuesMap,
      'non earning',
      'data zero',
    );
    edTableData[3].push(...fourthColDataArr);
  }
  if (loansAndAdvancesLineEntries) {
    fourthColDataArr = edTableComputedData(
      loansAndAdvancesLineEntries,
      payheadLineEntriesMap,
      payheadValuesMap,
      'non earning',
      'data zero',
    );
    edTableData[3].push(...fourthColDataArr);
  }
  if (ytdArrearsDeductionTotal) {
    edTableData[3].push(0);
  }

  // Fifth Column Computation - YTD Deduction
  let fifthColDataArr = [];
  if (earningsLineEntries) {
    fifthColDataArr = edTableComputedData(
      earningsLineEntries,
      payheadLineEntriesMap,
      payheadValuesMap,
      'earning',
      'data zero',
    );
    edTableData[4].push(...fifthColDataArr);
  }
  if (benefitsLineEntries) {
    fifthColDataArr = edTableComputedData(
      benefitsLineEntries,
      payheadLineEntriesMap,
      payheadValuesMap,
      'non earning',
      'data zero',
    );
    edTableData[4].push(...fifthColDataArr);
  }
  if (reimbursementsLineEntries) {
    fifthColDataArr = edTableComputedData(
      reimbursementsLineEntries,
      payheadLineEntriesMap,
      payheadValuesMap,
      'non earning',
      'data zero',
    );
    edTableData[4].push(...fifthColDataArr);
  }
  if (ytdArrearsEarningsTotal) {
    edTableData[4].push(0);
  }
  if (deductionsLineEntries) {
    fifthColDataArr = edTableComputedData(
      deductionsLineEntries,
      payheadLineEntriesMap,
      payheadValuesMap,
      'non earning',
      'data total',
      ytdTotalsMap,
    );
    ytdDeductionsTotal += fifthColDataArr.reduce((partialSum, a) => partialSum + a, 0);
    edTableData[4].push(...fifthColDataArr);
  }
  if (statDeductionsEmployeeLineEntries) {
    fifthColDataArr = edTableComputedData(
      statDeductionsEmployeeLineEntries,
      payheadLineEntriesMap,
      payheadValuesMap,
      'non earning',
      'data total',
      ytdTotalsMap,
    );
    ytdDeductionsTotal += fifthColDataArr.reduce((partialSum, a) => partialSum + a, 0);
    edTableData[4].push(...fifthColDataArr);
  }
  if (loansAndAdvancesLineEntries) {
    fifthColDataArr = edTableComputedData(
      loansAndAdvancesLineEntries,
      payheadLineEntriesMap,
      payheadValuesMap,
      'non earning',
      'data total',
      ytdTotalsMap,
    );
    ytdDeductionsTotal += fifthColDataArr.reduce((partialSum, a) => partialSum + a, 0);
    edTableData[4].push(...fifthColDataArr);
  }
  if (ytdArrearsDeductionTotal) {
    ytdDeductionsTotal += ytdArrearsDeductionTotal;
    edTableData[4].push(ytdArrearsDeductionTotal);
  }

  salarySlipData.edTableTotalData = [
    crrMonthEarningsTotal,
    crrMonthDeductionsTotal,
    ytdEarningsTotal,
    ytdDeductionsTotal,
  ];
  salarySlipData.edTableData = ArrayTranspose(edTableData);

  // Arrear computation

  const arrearsPayPeriods = salarySlipApiData?.arrearsPayPeriods;
  // Column Names Computation
  const arrearDataColumn = [
    {
      fieldName: 'slNo',
      numeric: true,
      label: 'sl no',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'payheadName',
      label: 'payhead name',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'payheadType',
      label: 'payhead type',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
  ];

  if (arrearsPayPeriods) {
    Object.values(arrearsPayPeriods)
      .sort((a, b) => new Date(b.payPeriodStartDate) - new Date(a.payPeriodStartDate))
      .forEach((payPeriodInstance) => {
        arrearDataColumn.push({
          fieldName: dayjs(payPeriodInstance?.payPeriodStartDate).format('MMM-YYYY'),
          label: dayjs(payPeriodInstance?.payPeriodStartDate).format('MMM YYYY'),
          formatValue: ({ row, column }) => `₹ ${validateNumberElseHyphen(row[column.fieldName])}`,
        });
      });
  }

  arrearDataColumn.push({
    fieldName: 'total',
    label: 'total',
    formatValue: ({ row, column }) => `₹ ${validateNumberElseHyphen(row[column.fieldName])}`,
  });

  // Row Data Computation
  const arrearsEarningsLineEntries = salarySlipApiData?.arrearsEarningsLineEntries;
  const arrearsBenefitsLineEntries = salarySlipApiData?.arrearsBenefitsLineEntries;
  const arrearsReimbursementsLineEntries = salarySlipApiData?.arrearsReimbursementsLineEntries;
  const arrearsStatDeductionsEmployeeLineEntries = salarySlipApiData?.arrearsStatDeductionsEmployeeLineEntries;
  const payheadNamesMap = salarySlipApiData?.payheadNamesMap;
  let arrearEarningsRowData = {};
  let slNo = 0;
  const arrearsRowDataFunction = (entries) => {
    const arrearDataSubRow = [];
    if (entries && Object.values(entries).length) {
      const sortedKeys = Object.keys(entries).sort();
      sortedKeys.forEach((payheadName) => {
        const payheadLC = payheadNamesMap[payheadName];
        if (payheadLC && !payheadLC.isInternalPayhead) {
          // Initialize rowTotal
          let rowTotal = 0;
          slNo += 1;
          arrearEarningsRowData = {
            slNo,
            payheadName,
            payheadType: payheadLC.payheadType,
          };

          // Loop through pay periods
          const salarySlipLineEntryList = entries[payheadName];
          if (arrearsPayPeriods) {
            Object.values(arrearsPayPeriods)
              .sort((a, b) => new Date(a.payPeriodStartDate) - new Date(b.payPeriodStartDate))
              .forEach((payPeriodInstance) => {
                if (salarySlipLineEntryList) {
                  salarySlipLineEntryList.forEach((salSlipLineEntryLC) => {
                    if (salSlipLineEntryLC.payPeriod.id === payPeriodInstance.id) {
                      arrearEarningsRowData[dayjs(payPeriodInstance?.payPeriodStartDate).format('MMM-YYYY')] =
                        salSlipLineEntryLC?.finalValue;
                      rowTotal += parseFloat(salSlipLineEntryLC.finalValue);
                    }
                  });
                }
              });
          }
          arrearEarningsRowData.total = rowTotal;
          arrearDataSubRow.push(arrearEarningsRowData);
        }
      });
    }
    return arrearDataSubRow;
  };

  const arrearEntriesData = arrearsRowDataFunction(arrearsEarningsLineEntries);
  if (arrearEntriesData.length > 0) {
    arrearDataRow.push(...arrearEntriesData);
  }
  const arrearBenefitsData = arrearsRowDataFunction(arrearsBenefitsLineEntries);
  if (arrearBenefitsData.length > 0) {
    arrearDataRow.push(...arrearBenefitsData);
  }
  const arrearReimbursementsData = arrearsRowDataFunction(arrearsReimbursementsLineEntries);
  if (arrearReimbursementsData.length > 0) {
    arrearDataRow.push(...arrearReimbursementsData);
  }
  const arrearStatDeductionsEmployeeLineData = arrearsRowDataFunction(arrearsStatDeductionsEmployeeLineEntries);
  if (arrearStatDeductionsEmployeeLineData.length > 0) {
    arrearDataRow.push(...arrearStatDeductionsEmployeeLineData);
  }

  const paySlipDate = `${salarySlipApiData?.monthName} ${salarySlipApiData?.year}`;
  const companyName = salarySlipApiData?.companyInstance?.companyName;
  let companyAddress = '';
  if (salarySlipApiData?.address) {
    companyAddress = `${salarySlipApiData?.address?.addressLine1} ${salarySlipApiData?.address?.city} 
    ${salarySlipApiData?.address?.state} ${salarySlipApiData?.address?.country} 
    ${salarySlipApiData?.address?.pinOrZip}`;
  }
  const companyLogo = salarySlipApiData?.companyLogoURL || '';
  const payslipIssueDate = salarySlipApiData?.salarySlipInstance?.finalizationDate
    ? dayjs(salarySlipApiData.salarySlipInstance.finalizationDate).format('MM/DD/YYYY')
    : '';

  return (
    <SalarySlipDetailTemplate
      paySlipDate={paySlipDate}
      companyName={companyName}
      companyAddress={companyAddress}
      companyLogo={companyLogo}
      payslipIssueDate={payslipIssueDate}
      salarySlipData={salarySlipData}
      arrearDataColumn={arrearDataColumn}
      arrearDataRow={arrearDataRow}
      consolidatedSalarySlipsFlag={consolidatedSalarySlipsFlag}
      salarySlipIdForDownloadWhenConsolidatedSalarySlip={salarySlipIdForDownloadWhenConsolidatedSalarySlip}
    />
  );
};

export default SalarySlipDetailPage;
