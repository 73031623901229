import HomeIcon from '@mui/icons-material/Home';
import ArticleIcon from '@mui/icons-material/Article';
import SpeedIcon from '@mui/icons-material/Speed';
import PersonIcon from '@mui/icons-material/Person';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import GroupsIcon from '@mui/icons-material/Groups';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import BusinessIcon from '@mui/icons-material/Business';
import ApartmentIcon from '@mui/icons-material/Apartment';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PaidIcon from '@mui/icons-material/Paid';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import SecurityIcon from '@mui/icons-material/Security';
import DrawIcon from '@mui/icons-material/Draw';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import WalletIcon from '@mui/icons-material/Wallet';
import MoneyIcon from '@mui/icons-material/Money';
import PaymentIcon from '@mui/icons-material/Payment';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import HelpIcon from '@mui/icons-material/Help';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import LinkOffIcon from '@mui/icons-material/LinkOff';

const home = {
  id: 'home',
  title: 'Home',
  icon: HomeIcon,
  type: 'group',
  url: '',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      icon: SpeedIcon,
      type: 'item',
      url: '/home/dashboard',
      permissions: ['ROLE_BASE_PERMISSION_SENTINEL_EMP'],
    },
    {
      id: 'dashboard',
      title: 'Dashboard',
      icon: PersonIcon,
      type: 'item',
      url: '/home/alumni-dashboard',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_ALUMNI_DASHBOARD'],
    },
    {
      id: 'attendance',
      title: 'Attendance',
      icon: EventAvailableIcon,
      type: 'collapse',
      permissions: ['ROLE_BASE_PERMISSION_ATT_INDEX_DASHBOARD'],
      children: [
        {
          id: 'attendance_dashboard',
          title: 'Dashboard',
          icon: SpeedIcon,
          type: 'item',
          url: '/home/attendance/dashboard',
          permissions: ['ROLE_BASE_PERMISSION_ATT_INDEX_DASHBOARD'],
        },
      ],
    },
    {
      id: 'my_pay',
      title: 'My Pay',
      icon: PaidIcon,
      type: 'collapse',
      permissions: [
        'ROLE_BASE_PERMISSION_PAY_INDEX_MY_SALARY_DASHBOARD',
        'ROLE_BASE_PERMISSION_PAY_INDEX_LIST_MY_SALARY_SLIPS',
        'ROLE_BASE_PERMISSION_PAY_INDEX_VIEW_MY_SALARY_DETAILS',
        'ROLE_BASE_PERMISSION_PAY_INDEX_VIEW_MY_DECLARATION',
        'ROLE_BASE_PERMISSION_PAY_INDEX_UPDATE_MY_DECLARATION',
        'ROLE_BASE_PERMISSION_PAY_INDEX_VIEW_MY_FORM_12BB',
        'ROLE_BASE_PERMISSION_PAY_INDEX_VIEW_MY_TAX_WORKING',
        'ROLE_BASE_PERMISSION_PAY_INDEX_UPLOAD_MY_INVESTMENT_PROOFS',
        'ROLE_BASE_PERMISSION_PAY_INDEX_LIST_PREVIOUS_EMP_TAX_DETAILS_BY_EMPLOYEE',
      ],
      children: [
        {
          id: 'mypay_dashboard',
          title: 'Dashboard',
          icon: SpeedIcon,
          type: 'item',
          url: '/home/mypay/dashboard',
          permissions: ['ROLE_BASE_PERMISSION_PAY_INDEX_MY_SALARY_DASHBOARD'],
        },
        {
          id: 'pastEmployerTaxes',
          title: 'Past Employer Taxes',
          icon: ArticleIcon,
          type: 'item',
          url: '/home/mypay/past-employer-taxes',
          permissions: ['ROLE_BASE_PERMISSION_PAY_INDEX_LIST_PREVIOUS_EMP_TAX_DETAILS_BY_EMPLOYEE'],
        },
        {
          id: 'salarySlips',
          title: 'Salary Slips',
          icon: MarkEmailUnreadIcon,
          type: 'item',
          url: '/home/mypay/salary-slips',
          permissions: ['ROLE_BASE_PERMISSION_PAY_INDEX_LIST_MY_SALARY_SLIPS'],
        },
        {
          id: 'mySalaryDetails',
          title: 'My Salary Details',
          icon: ChangeHistoryIcon,
          type: 'item',
          url: '/home/mypay/my-salary-details',
          permissions: ['ROLE_BASE_PERMISSION_PAY_INDEX_VIEW_MY_SALARY_DETAILS'],
        },
        {
          id: 'declarationforTDS',
          title: 'Declaration for TDS',
          icon: SecurityIcon,
          type: 'item',
          url: '/home/mypay/tds-declaration',
          permissions: ['ROLE_BASE_PERMISSION_PAY_INDEX_VIEW_MY_DECLARATION'],
        },
        {
          id: 'Modifydeclaration',
          title: 'Modify Declaration',
          icon: DrawIcon,
          type: 'item',
          url: '/home/mypay/tds-declaration/edit',
          permissions: ['ROLE_BASE_PERMISSION_PAY_INDEX_UPDATE_MY_DECLARATION'],
        },
        {
          id: 'form12BB',
          title: 'Form 12BB',
          icon: DrawIcon,
          type: 'collapse',
          permissions: [
            'ROLE_BASE_PERMISSION_PAY_INDEX_VIEW_MY_FORM_12BB',
            'ROLE_BASE_PERMISSION_PAY_INDEX_UPLOAD_MY_FORM_12BB',
          ],
          children: [
            {
              id: 'generate_form12BB',
              title: 'Generate Form 12BB',
              icon: DownloadIcon,
              type: 'item',
              url: '/home/mypay/form-12BB',
              permissions: ['ROLE_BASE_PERMISSION_PAY_INDEX_VIEW_MY_FORM_12BB'],
            },
            {
              id: 'upload_form12BB',
              title: 'Upload Form 12BB',
              icon: UploadIcon,
              type: 'item',
              url: '/home/mypay/form-12BB/upload',
              permissions: ['ROLE_BASE_PERMISSION_PAY_INDEX_UPLOAD_MY_FORM_12BB'],
            },
          ],
        },
        {
          id: 'tdsworking',
          title: 'TDS working',
          icon: CreditCardIcon,
          type: 'item',
          url: '/home/mypay/tds-workings',
          permissions: ['ROLE_BASE_PERMISSION_PAY_INDEX_VIEW_MY_TAX_WORKING'],
        },
        {
          id: 'uploadInvestmentProof',
          title: 'Upload Investment Proofs',
          icon: DriveFolderUploadIcon,
          type: 'item',
          url: '/home/mypay/investment-proofs',
          permissions: ['ROLE_BASE_PERMISSION_PAY_INDEX_UPLOAD_MY_INVESTMENT_PROOFS'],
        },
        // duplicate screen to TDS working
        // {
        //   id: 'taxworking',
        //   title: 'Tax working',
        //   icon: CreditCardIcon,
        //   type: 'item',
        //   url: '/home/mypay/tax-workings',
        // },
      ],
    },
    // {
    //   id: 'employee',
    //   title: 'Employee',
    //   icon: PersonIcon,
    //   type: 'collapse',
    //   children: [
    //     {
    //       id: 'organisation',
    //       title: 'Organisation',
    //       icon: CorporateFareIcon,
    //       type: 'item',
    //       url: '/home/employee/organisation',
    //     },
    //   ],
    // },
    {
      id: 'home_expenses',
      title: 'Expenses',
      icon: AccountBalanceWalletIcon,
      type: 'collapse',
      permissions: [
        'ROLE_BASE_PERMISSION_EXPENSE_INDEX_DASHBOARD',
        'ROLE_BASE_PERMISSION_EXPENSE_INDEX_LIST_MY_EXPENSE_REPORTS',
      ],
      children: [
        {
          id: 'home_expenses_dashboard',
          title: 'Dashboard',
          icon: SpeedIcon,
          type: 'item',
          url: '/home/expenses/dashboard',
          permissions: ['ROLE_BASE_PERMISSION_EXPENSE_INDEX_DASHBOARD'],
        },
        {
          id: 'myExpenses',
          title: 'My Expenses',
          icon: ArticleIcon,
          type: 'item',
          url: '/home/expenses',
          permissions: ['ROLE_BASE_PERMISSION_EXPENSE_INDEX_LIST_MY_EXPENSE_REPORTS'],
        },
      ],
    },
    {
      id: 'home_leaves',
      title: 'Leaves',
      icon: BeachAccessIcon,
      type: 'collapse',
      permissions: [
        'ROLE_BASE_PERMISSION_LEAVE_INDEX_MY_LEAVE_DASHBOARD',
        'ROLE_BASE_PERMISSION_LEAVE_INDEX_LIST_MY_LEAVE_REQUESTS',
        'ROLE_BASE_PERMISSION_LEAVE_INDEX_DISPLAY_MY_LEAVE_ACCOUNTS',
      ],
      children: [
        {
          id: 'home_leaves_dashboard',
          title: 'Dashboard',
          icon: SpeedIcon,
          type: 'item',
          url: '/home/leaves/dashboard',
          permissions: ['ROLE_BASE_PERMISSION_LEAVE_INDEX_MY_LEAVE_DASHBOARD'],
        },
        {
          id: 'myLeaveRequests',
          title: 'My Leave Requests',
          icon: ApartmentIcon,
          type: 'item',
          url: '/home/leaves/my-leave-requests',
          permissions: ['ROLE_BASE_PERMISSION_LEAVE_INDEX_LIST_MY_LEAVE_REQUESTS'],
        },
        {
          id: 'myLeaveAccounts',
          title: 'My Leave Accounts',
          icon: FeaturedPlayListIcon,
          type: 'item',
          url: '/home/leaves/my-leave-accounts',
          permissions: ['ROLE_BASE_PERMISSION_LEAVE_INDEX_DISPLAY_MY_LEAVE_ACCOUNTS'],
        },
        // TODO: to be added later
        // {
        //   id: 'encashmentRequests',
        //   title: 'Encashment Requests',
        //   icon: FeaturedPlayListIcon,
        //   type: 'item',
        //   url: '/home/leaves/encashment-requests',
        // },
      ],
    },
    {
      id: 'myDocuments',
      title: 'My Documents',
      icon: InsertDriveFileIcon,
      type: 'item',
      url: '/home/documents',
      permissions: ['ROLE_BASE_PERMISSION_INDEX_LIST_DOCUMENTS'],
    },
    {
      id: 'homeTaskAndQueries',
      title: 'Task & Queries',
      icon: HelpIcon,
      type: 'collapse',
      permissions: [
        'ROLE_BASE_PERMISSION_QUER_INDEX_LIST_MY_QUERRIES',
        'ROLE_BASE_PERMISSION_QUER_INDEX_LIST_MY_ACTIONABLE_QUERRIES',
      ],
      children: [
        {
          id: 'myQueries',
          title: 'My Queries',
          icon: HelpCenterIcon,
          type: 'item',
          url: '/home/queries',
          permissions: ['ROLE_BASE_PERMISSION_QUER_INDEX_LIST_MY_QUERRIES'],
        },
        {
          id: 'myActionableQueries',
          title: 'My Actionable Queries',
          icon: ArticleIcon,
          type: 'item',
          url: '/home/actionable-queries',
          permissions: ['ROLE_BASE_PERMISSION_QUER_INDEX_LIST_MY_ACTIONABLE_QUERRIES'],
        },
      ],
    },
    {
      id: 'myReporting',
      title: 'My Reporting',
      icon: AccountTreeIcon,
      type: 'item',
      url: '/home/myReporting',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_MY_UPWARD_HIERARCHY'],
    },
    {
      id: 'separation',
      title: 'Separation',
      icon: LinkOffIcon,
      type: 'collapse',
      permissions: [
        'ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_CREATE_MY_EMPLOYEE_EXIT',
        'ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_MY_EXIT_DETAILS',
      ],
      children: [
        {
          id: 'initiateSeparation',
          title: 'Initiate Separation',
          icon: LinkOffIcon,
          type: 'item',
          url: '/home/separation/initiate-separation',
          permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_CREATE_MY_EMPLOYEE_EXIT'],
        },
        {
          id: 'viewSeparation',
          title: 'View Separation',
          icon: LinkOffIcon,
          type: 'item',
          url: '/home/separation/view-separation',
          permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_MY_EXIT_DETAILS'],
        },
      ],
    },
  ],
};

export default home;
