import dayjs from 'dayjs';
import FormData from 'form-data';

const getDataSet = (profileInstance) => {
  const data = {
    [`employee.maritalStatus`]: profileInstance?.maritalStatus || '',
    [`employee.panNumberIndia`]: profileInstance?.panNumber || '',
    [`employee.aadhaarNumberIndia`]: profileInstance?.aadharNumber || '',
    [`employee.uanNumberIndia`]: profileInstance?.uan || '',
    [`employee.uhidAccountNumberIndia`]: profileInstance?.uhid || '',
    [`employee.epfAccountNumberIndia`]: profileInstance?.epf || '',
    [`employee.epsAccountNumberIndia`]: profileInstance?.eps || '',
    [`employee.esiAccountNumberIndia`]: profileInstance?.esi || '',
    [`employee.skypeID`]: profileInstance?.skypeid || '',
    [`employee.facebookID`]: profileInstance?.facebookid || '',
    [`employee.linkedinID`]: profileInstance?.linkedinid || '',
    [`employee.googlePlusID`]: profileInstance?.googlePlusid || '',
    [`employee.twitterID`]: profileInstance?.twitterid || '',
    [`employee.instagramID`]: profileInstance?.instagramid || '',
    [`profile.fathersName`]: profileInstance?.fatherName || '',
    [`profile.spouseName`]: profileInstance?.spouseName || '',
    [`profile.mothersName`]: profileInstance?.motherName || '',
    [`profile.bloodGroup`]: profileInstance?.bloodgroup || '',
    [`profile.selfDescription`]: profileInstance?.selfdescription || '',
    [`profile.passportNumber`]: profileInstance?.passportnumber || '',
    [`profile.passportIssueDate`]: profileInstance?.passportissuedate
      ? dayjs(profileInstance?.passportissuedate).format('MM/DD/YYYY')
      : '',
    [`profile.passportExpiryDate`]: profileInstance?.passportexpirydate
      ? dayjs(profileInstance?.passportexpirydate).format('MM/DD/YYYY')
      : '',
    [`profile.passportPlaceOfIssue`]: profileInstance?.passportplaceissue || '',
    [`profile.passportCountry`]: profileInstance?.passportcountry || '',
    [`profile.passportNameGiven`]: profileInstance?.passportnamegiven || '',
    [`profile.pic`]: profileInstance?.uploadProfile,
  };

  if (profileInstance?.mobileNumber.length > 0) {
    profileInstance?.mobileNumber.forEach((mobileNumberItem) => {
      const index = profileInstance?.mobileNumber.indexOf(mobileNumberItem);
      data[`employee.mobileNumbers[${index}]`] = mobileNumberItem;
    });
    data.counter_mobileNumbers = profileInstance?.mobileNumber?.length;
  }

  if (profileInstance?.emailAddress.length > 0) {
    profileInstance?.emailAddress.forEach((emailAddressItem) => {
      const index = profileInstance?.emailAddress.indexOf(emailAddressItem);
      data[`employee.emailAddresses[${index}]`] = emailAddressItem;
    });
    data.counter_emailAddresses = profileInstance?.emailAddress?.length;
  }

  if (profileInstance?.telephoneNumber.length > 0) {
    profileInstance?.telephoneNumber.forEach((telephoneNumberItem) => {
      const index = profileInstance?.telephoneNumber.indexOf(telephoneNumberItem);
      data[`employee.telephoneNumbers[${index}]`] = telephoneNumberItem;
    });
    data.counter_telephoneNumbers = profileInstance?.telephoneNumber?.length;
  }

  return data;
};

const getBasicProfileFormData = async (profileInstance) => {
  const formData = new FormData();
  const profileFormDataSet = getDataSet(profileInstance);
  Object.keys(profileFormDataSet).forEach((profileFormPostKeys) => {
    formData.append(profileFormPostKeys, profileFormDataSet[profileFormPostKeys]);
  });

  return formData;
};

export { getBasicProfileFormData };
