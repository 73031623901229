import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import TitleWithText from 'components/Atoms/TitleWithText';
import Dropdown from 'components/Molecules/Dropdown';
import Typographys from 'components/Atoms/Typography';
import DatePicker from 'components/Atoms/Datepicker';
import InputField from 'components/Atoms/InputField/index';
import Button from 'components/Atoms/Button';
import Chips from 'components/Molecules/Chip';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Attachments from 'components/Atoms/Attachments';
import dayjs from 'dayjs';
import { dateFormat, validateDateElseHyphen } from 'utils/commonUtils';
import { lifeCycleRecordStyle } from '../style';
import useInitialValues from './initialValues';

const EditLifeCycleRecord = ({
  supervisorList,
  departmentList,
  designationList,
  locationList,
  employeeLevelList,
  employeeGradeList,
  divisionList,
  handleSubmit,
  handleCancel,
  recordDetails,
  changeType,
  loading,
}) => {
  const INITIAL_VALUES = useInitialValues(recordDetails);
  const isExtendProbation = changeType === 'Extend Probation';
  const isBeginPerformanceImprovement = changeType === 'Begin Performance Improvement';
  const isEndPerformanceImprovement = changeType === 'End Performance Improvement';
  const isDefault =
    changeType !== 'Terminate' &&
    changeType !== 'Background Verified' &&
    changeType !== 'Extend Probation' &&
    changeType !== 'Begin Performance Improvement' &&
    changeType !== 'End Performance Improvement';

  const formik = useFormik({
    initialValues: {
      ...(INITIAL_VALUES[changeType] || INITIAL_VALUES.default),
      effectiveStartDate: dateFormat(recordDetails?.effectiveStartDate),
      effectiveEndDate: dateFormat(recordDetails?.effectiveEndDate),
    },
    enableReinitialize: true,
    onSubmit: async (values, helpers) => {
      try {
        const {
          supervisor,
          department,
          designation,
          location,
          employeeLevel,
          employeeGrade,
          division,
          reasonOfTransfer,
          effectiveStartDate,
          attachment,
          effectiveEndDate,
        } = values;
        handleSubmit({
          supervisor,
          department,
          designation,
          location,
          employeeLevel,
          employeeGrade,
          division,
          effectiveStartDate,
          attachment,
          effectiveEndDate,
          reasonOfTransfer,
        });
        helpers.resetForm();
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 39.5rem)' }}>
          <Grid container sx={{ display: 'flex', alignItems: 'center', maxWidth: '97.5rem' }}>
            <Grid item xs={6} sm={3}>
              <TitleWithText title="Created by" text={recordDetails?.name || '-'} />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TitleWithText
                title="Status"
                text={recordDetails?.status ? <Chips label={recordDetails?.status} color="success" /> : '-'}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TitleWithText title="Created on" text={validateDateElseHyphen(recordDetails?.date, 'DD MMMM YYYY')} />
            </Grid>
          </Grid>

          <Box sx={lifeCycleRecordStyle.dataTable}>
            <Grid container sx={lifeCycleRecordStyle.dataRowHead}>
              <Grid item xs={4} sm={4}>
                <Typographys variant="caption">ATTRIBUTE NAME</Typographys>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Typographys variant="caption"> CURRENT VALUE</Typographys>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Typographys variant="caption"> NEW VALUE</Typographys>
              </Grid>
            </Grid>
            {isDefault && (
              <>
                <Grid container sx={lifeCycleRecordStyle.dataRow}>
                  <Grid item xs={4} sm={4}>
                    <Typographys variant="body2">Supervisor</Typographys>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <Typographys variant="body2">{recordDetails?.supervisor || '-'}</Typographys>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <Dropdown
                      error={Boolean(formik.touched.supervisor && formik.errors.supervisor)}
                      helperText={formik.touched.supervisor && formik.errors.supervisor}
                      handleChange={(e) => {
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.supervisor}
                      name="supervisor"
                      id="supervisor"
                      label="Select Supervisor"
                      autoComplete="Select Supervisor"
                      options={supervisorList}
                      fullWidth
                      autoFocus
                    />
                  </Grid>
                </Grid>

                <Grid container sx={lifeCycleRecordStyle.dataRow}>
                  <Grid item xs={4} sm={4}>
                    <Typographys variant="body2">Department</Typographys>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <Typographys variant="body2">{recordDetails?.department || '-'}</Typographys>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <Dropdown
                      error={Boolean(formik.touched.department && formik.errors.department)}
                      helperText={formik.touched.department && formik.errors.department}
                      handleChange={(e) => {
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.department}
                      name="department"
                      id="department"
                      label="Select Department"
                      autoComplete="Select Department"
                      options={departmentList}
                      fullWidth
                      autoFocus
                    />
                  </Grid>
                </Grid>

                <Grid container sx={lifeCycleRecordStyle.dataRow}>
                  <Grid item xs={4} sm={4}>
                    <Typographys variant="body2">Designation</Typographys>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <Typographys variant="body2">{recordDetails?.designation || '-'}</Typographys>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <Dropdown
                      error={Boolean(formik.touched.supervisor && formik.errors.designation)}
                      helperText={formik.touched.designation && formik.errors.designation}
                      handleChange={(e) => {
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.designation}
                      name="designation"
                      id="designation"
                      label="Select Designation"
                      autoComplete="Select Designation"
                      options={designationList}
                      fullWidth
                      autoFocus
                    />
                  </Grid>
                </Grid>

                <Grid container sx={lifeCycleRecordStyle.dataRow}>
                  <Grid item xs={4} sm={4}>
                    <Typographys variant="body2">Location</Typographys>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <Typographys variant="body2">{recordDetails?.location || '-'}</Typographys>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <Dropdown
                      error={Boolean(formik.touched.location && formik.errors.location)}
                      helperText={formik.touched.location && formik.errors.location}
                      handleChange={(e) => {
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.location}
                      name="location"
                      id="location"
                      label="Select Location"
                      autoComplete="Select Location"
                      options={locationList}
                      fullWidth
                      autoFocus
                    />
                  </Grid>
                </Grid>

                <Grid container sx={lifeCycleRecordStyle.dataRow}>
                  <Grid item xs={4} sm={4}>
                    <Typographys variant="body2">Employee Level</Typographys>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <Typographys variant="body2">{recordDetails?.employeeLevel || '-'}</Typographys>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <Dropdown
                      error={Boolean(formik.touched.employeeLevel && formik.errors.employeeLevel)}
                      helperText={formik.touched.employeeLevel && formik.errors.employeeLevel}
                      handleChange={(e) => {
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.employeeLevel}
                      name="employeeLevel"
                      id="employeeLevel"
                      label="Select Employee Level"
                      autoComplete="Select Employee Level"
                      options={employeeLevelList}
                      fullWidth
                      autoFocus
                    />
                  </Grid>
                </Grid>

                <Grid container sx={lifeCycleRecordStyle.dataRow}>
                  <Grid item xs={4} sm={4}>
                    <Typographys variant="body2">Employee Grade</Typographys>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <Typographys variant="body2">{recordDetails?.employeeGrade || '-'}</Typographys>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <Dropdown
                      error={Boolean(formik.touched.employeeGrade && formik.errors.employeeGrade)}
                      helperText={formik.touched.employeeGrade && formik.errors.employeeGrade}
                      handleChange={(e) => {
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.employeeGrade}
                      name="employeeGrade"
                      id="employeeGrade"
                      label="Select Employee Grade"
                      autoComplete="Select Employee Grade"
                      options={employeeGradeList}
                      fullWidth
                      autoFocus
                    />
                  </Grid>
                </Grid>

                <Grid container sx={lifeCycleRecordStyle.dataRow}>
                  <Grid item xs={4} sm={4}>
                    <Typographys variant="body2">Division</Typographys>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <Typographys variant="body2">{recordDetails?.division || '-'}</Typographys>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <Dropdown
                      error={Boolean(formik.touched.division && formik.errors.division)}
                      helperText={formik.touched.division && formik.errors.division}
                      handleChange={(e) => {
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.division}
                      name="division"
                      id="division"
                      label="Select Division"
                      autoComplete="Select Division"
                      options={divisionList}
                      fullWidth
                      autoFocus
                    />
                  </Grid>
                </Grid>
              </>
            )}

            <Grid container sx={lifeCycleRecordStyle.dataRow}>
              <Grid item xs={4} sm={4}>
                <Typographys variant="body2">Effective from</Typographys>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Typographys variant="body2">
                  {validateDateElseHyphen(recordDetails?.effectiveStartDate, 'DD MMMM YYYY')}
                </Typographys>
              </Grid>
              <Grid item xs={4} sm={4}>
                <DatePicker
                  error={
                    Boolean(formik.touched.effectiveStartDate && formik.errors.effectiveStartDate) ||
                    (formik.touched.effectiveStartDate && !formik.values.effectiveStartDate)
                  }
                  helperText={formik.touched.effectiveStartDate && formik.errors.effectiveStartDate}
                  onChange={(date) => {
                    formik.setFieldValue('effectiveStartDate', date);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values?.effectiveStartDate ? dayjs(formik.values?.effectiveStartDate) : null}
                  name="effectiveStartDate"
                  id="effectiveStartDate"
                  label="Select Date"
                  autoComplete="Select Date"
                  fullWidth
                  autoFocus
                />
              </Grid>
            </Grid>

            {(isExtendProbation || isBeginPerformanceImprovement || isEndPerformanceImprovement) && (
              <Grid container sx={lifeCycleRecordStyle.dataRow}>
                <Grid item xs={4} sm={4}>
                  <Typographys variant="body2">Effective upto</Typographys>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Typographys variant="body2">
                    {validateDateElseHyphen(recordDetails?.effectiveEndDate, 'DD MMMM YYYY')}
                  </Typographys>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <DatePicker
                    error={
                      Boolean(formik.touched.effectiveEndDate && formik.errors.effectiveEndDate) ||
                      (formik.touched.effectiveEndDate && !formik.values.effectiveEndDate)
                    }
                    helperText={formik.touched.effectiveEndDate && formik.errors.effectiveEndDate}
                    onChange={(date) => {
                      formik.setFieldValue('effectiveEndDate', date);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values?.effectiveEndDate ? dayjs(formik.values?.effectiveEndDate) : null}
                    name="effectiveEndDate"
                    id="effectiveEndDate"
                    label="Select Date"
                    autoComplete="Select Date"
                    fullWidth
                    autoFocus
                  />
                </Grid>
              </Grid>
            )}

            <Grid container sx={lifeCycleRecordStyle.dataRow}>
              <Grid item xs={4} sm={4}>
                <Typographys variant="body2">Reason for {changeType}</Typographys>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Typographys variant="body2">{recordDetails?.reasonOfTransfer || '-'}</Typographys>
              </Grid>
              <Grid item xs={4} sm={4}>
                <InputField
                  error={Boolean(formik.touched.reasonOfTransfer && formik.errors.reasonOfTransfer)}
                  helperText={formik.touched.reasonOfTransfer && formik.errors.reasonOfTransfer}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.reasonOfTransfer}
                  name="reasonOfTransfer"
                  id="reasonOfTransfer"
                  autoComplete="Reason for transfer"
                  label="New Reason"
                  size="small"
                  multiline
                  fullWidth
                  autoFocus
                />
              </Grid>
            </Grid>
            <Grid container sx={lifeCycleRecordStyle.dataRow}>
              <Grid item xs={4} sm={4}>
                <Typographys variant="body2">Attachments</Typographys>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Typographys variant="body2">{recordDetails?.attachmentFileName || '-'}</Typographys>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Attachments
                  title="Choose File"
                  wrapperStyles={{ display: 'block' }}
                  isDeleteIcon
                  handleChange={(event) => {
                    formik.setFieldValue('attachment', event.target.files[0]);
                  }}
                  name="attachment"
                  id="attachment"
                  value={formik.values.attachment}
                  handleDelete={() => {
                    formik.setFieldValue('attachment', '');
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </PerfectScrollbar>

      <Box sx={lifeCycleRecordStyle.btnContainer}>
        <Divider sx={lifeCycleRecordStyle.Divider} />
        <Box sx={lifeCycleRecordStyle.submitWrapper}>
          <Button
            size="large"
            color="info"
            variant="outlined"
            type="cancel"
            onClick={() => {
              formik.resetForm();
              handleCancel();
            }}
          >
            Cancel
          </Button>

          <LoadingButton color="primary" variant="contained" size="large" type="submit" loading={loading}>
            Update
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

EditLifeCycleRecord.defaultProps = {
  recordDetails: {},
  supervisorList: [
    { id: 1, name: 'Option 1', onClick: () => {} },
    { id: 2, name: 'Option 2', onClick: () => {} },
    { id: 3, name: 'Option 3', onClick: () => {} },
  ],
  departmentList: [
    { id: 1, name: 'Option 1', onClick: () => {} },
    { id: 2, name: 'Option 2', onClick: () => {} },
    { id: 3, name: 'Option 3', onClick: () => {} },
  ],
  designationList: [
    { id: 1, name: 'Option 1', onClick: () => {} },
    { id: 2, name: 'Option 2', onClick: () => {} },
    { id: 3, name: 'Option 3', onClick: () => {} },
  ],
  locationList: [
    { id: 1, name: 'Option 1', onClick: () => {} },
    { id: 2, name: 'Option 2', onClick: () => {} },
    { id: 3, name: 'Option 3', onClick: () => {} },
  ],
  employeeLevelList: [
    { id: 1, name: 'Option 1', onClick: () => {} },
    { id: 2, name: 'Option 2', onClick: () => {} },
    { id: 3, name: 'Option 3', onClick: () => {} },
  ],
  employeeGradeList: [
    { id: 1, name: 'Option 1', onClick: () => {} },
    { id: 2, name: 'Option 2', onClick: () => {} },
    { id: 3, name: 'Option 3', onClick: () => {} },
  ],
  divisionList: [
    { id: 1, name: 'Option 1', onClick: () => {} },
    { id: 2, name: 'Option 2', onClick: () => {} },
    { id: 3, name: 'Option 3', onClick: () => {} },
  ],
  handleCancel: () => {},
  handleSubmit: () => {},
  loading: false,
  changeType: '',
};

EditLifeCycleRecord.propTypes = {
  recordDetails: PropTypes.shape(),
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  supervisorList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  departmentList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  designationList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  locationList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  employeeLevelList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  employeeGradeList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  divisionList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  loading: PropTypes.bool,
  changeType: PropTypes.string,
};

export default EditLifeCycleRecord;
