import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Paper } from '@mui/material';
import Typography from 'components/Atoms/Typography';
import CommonFilledCard from 'components/Molecules/CommonFilledCard';
import PerfectScrollbar from 'react-perfect-scrollbar';

const LeaveBalanceCard = ({ balanceLeave }) => {
  return (
    <Paper variant="outline" sx={{ border: '0.125rem solid', borderColor: 'neutral.300', padding: '2.5rem 3rem' }}>
      <Box sx={{ minHeight: '44rem', maxHeight: '44rem' }}>
        <Typography variant="h6" color="neutral.800">
          Leave balance
        </Typography>

        <PerfectScrollbar>
          <Box sx={{ minHeight: '38.1rem', maxHeight: '38.1rem' }}>
            <Grid container spacing={1} mt={1.5}>
              {!balanceLeave?.length ? (
                <Typography sx={{ marginLeft: '8px' }}>No data available</Typography>
              ) : (
                balanceLeave?.map((list) => (
                  <Grid item xs={12} sm={6}>
                    <CommonFilledCard
                      key={list?.heading}
                      heading={list?.heading}
                      headingColor="secondary.500"
                      value={list?.value}
                      minimumHeight={{
                        xs: '10rem',
                        sm: '10rem',
                        md: '10rem',
                        lg: '16rem',
                        xl: '14rem',
                      }}
                    />
                  </Grid>
                ))
              )}
            </Grid>
          </Box>
        </PerfectScrollbar>
      </Box>
    </Paper>
  );
};

LeaveBalanceCard.defaultProps = {
  balanceLeave: [],
};
LeaveBalanceCard.propTypes = {
  balanceLeave: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
};

export default LeaveBalanceCard;
