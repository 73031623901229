import React from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import CustomLegend from 'components/Molecules/CustomLegend';
import { chartStyle } from './style';

const NumOfEmpLineChart = ({ empLineChartData, empTypeWiseColumns }) => {
  const theme = useTheme();

  const renderLine = () => {
    return empTypeWiseColumns?.map((item, index) => {
      if (index !== 0) {
        return (
          <Line type="monotone" name={item} dataKey={item} stroke={theme.palette.secondary[600]} strokeWidth={2} />
        );
      }
      return null;
    });
  };
  return (
    <Box sx={chartStyle.chartWrapper}>
      <ResponsiveContainer width="100%" height={310}>
        <LineChart width={730} height={310} data={empLineChartData} margin={{ top: 40, right: 60, left: 0 }}>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey={empTypeWiseColumns[0]}
            axisLine={{ stroke: theme.palette.other[200] }}
            tick={{ fill: theme.palette.neutral[600] }}
            tickLine={{ stroke: theme.palette.common.white }}
            style={chartStyle.axisLabel}
            label={{ value: 'Months', position: 'right', offset: 20, fill: theme.palette.neutral[800] }}
          />
          <YAxis
            axisLine={false}
            tick={{ fill: theme.palette.neutral[600] }}
            tickLine={{ stroke: theme.palette.common.white }}
            tickSize={20}
            style={chartStyle.axisLabel}
            label={{ value: 'People', position: 'top', offset: 20, fill: theme.palette.neutral[800] }}
          />
          <Tooltip />

          <Legend layout="vertical" align="right" verticalAlign="top" content={<CustomLegend />} />
          {renderLine()}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

NumOfEmpLineChart.defaultProps = {
  empLineChartData: [],
};
NumOfEmpLineChart.propTypes = {
  empLineChartData: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      month: PropTypes.string,
      candidate: PropTypes.number,
      contractor: PropTypes.number,
      consultant: PropTypes.number,
      intern: PropTypes.number,
      casual: PropTypes.number,
    }),
  ),
};

export default NumOfEmpLineChart;
