/* eslint-disable array-callback-return */
import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useToast from 'components/Provider/useToast';
import { formDataApi } from 'api/api';
import { isArray, isEmpty } from 'lodash';
import dayjs from 'dayjs';
import DialogSuccess from 'components/Organisms/Dialog/Employee/Success';
import Alert from 'components/Atoms/Alert';
/* eslint-disable max-len */
import { affiliatedEmployeesStyle } from 'components/Organisms/Employee/EmployeeGrouping/List/EmployeeGroupAffiliations/style';
import AddEmployeeTemplate from 'components/Templates/Employee/AddEmployee';
import { modifyUserAction, saveUserAction } from 'api/employees';
import { hasAnyPermission } from 'utils/permissions';
import { useSelector } from 'react-redux';
import { useCustomAttributesFormik } from './customFormik';
import { getEmpData, modifyPreCreateData } from './modifyPrecreateData';

const AddEditEmployee = (props) => {
  const [preCreateEmployeeOptions, setPreCreateEmployee] = useState({
    data: {},
    isLoading: false,
  });
  const [authorityList, setAuthorityList] = useState([]);
  const [roleMap, setRoleMap] = useState({});
  const location = useLocation();
  const isEditMode = location.pathname.includes('edit');
  const [employeeData, setEmployeeData] = useState(null);
  const [preModifyData, setPreModifyData] = useState({});
  const { id } = useParams();
  const [showSuccess, setShowSuccess] = useState(false);
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const navigate = useNavigate();
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasViewPermission = hasAnyPermission(authorities, [
    'ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_CREATE_SECUSER',
    'ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_EDIT_SECUSER',
  ]);

  const addToast = useToast();
  const { customAttributesFormik, metaCustomFieldDefinitions: metaCustomFields } = useCustomAttributesFormik(
    isEditMode ? preModifyData?.extendedDef : preCreateEmployeeOptions?.extendedDef,
    null,
    (value) => console.log(value),
  );
  const createEmployee = async (data) => {
    const customAttributesMetaData = isEmpty(metaCustomFields)
      ? []
      : [
          { attribute: 'customObjectInstance.customerID', value: metaCustomFields.customerID },
          {
            attribute: 'customObjectInstance.baseObjectMetaClassName',
            value: metaCustomFields.baseObjectMetaClassName,
          },
          {
            attribute: 'customObjectInstance.metaCustomObjectDefinitionUID',
            value: metaCustomFields.metaCustomObjectDefinitionID,
          },
        ];

    let metaIndex = 0;
    (metaCustomFields?.metaCustomFieldDefinitions || []).forEach((customField) => {
      if (customAttributesFormik.values[customField.fieldLabel]) {
        customAttributesMetaData.push(
          {
            attribute: `customObjectInstance.customFieldValues[${metaIndex}].fieldValue`,
            value: customAttributesFormik.values[customField.fieldLabel] || '',
          },
          {
            attribute: `customObjectInstance.customFieldValues[${metaIndex}].metaCustomFieldDefinitionID`,
            value: customField.metaCustomFieldDefinitionID,
          },
        );
        // eslint-disable-next-line no-plusplus
        metaIndex++;
      }
    });
    const userRoleBodyData = Object.keys(data['User Roles'])?.reduce((acc, curr) => {
      acc[curr] = data['User Roles'][curr] ? 'on' : '';
      return acc;
    }, {});

    const body = {
      // User Identity Tab data
      username: data['User Identity'].userName,
      password: data['User Identity'].password,
      'employee.firstName': data['User Identity'].firstName,
      'employee.middleName': data['User Identity'].middleName,
      'employee.lastName': data['User Identity'].lastName,
      'profile.employeeID': data['User Identity'].employeeId,
      accountExpired: data['User Identity'].accountExpired,
      accountLocked: data['User Identity'].accountLocked,
      enabled: data['User Identity'].accountEnabled,
      passwordExpired: data['User Identity'].passwordExpired,
      empgroups: data['User Identity'].group,
      // User Role data
      ...userRoleBodyData,
      // Basic Profile data
      'profile.pic': data['Basic Profile'].uploadProfile,
      'employee.gender': data['Basic Profile'].gender,
      'profile.fathersName': data['Basic Profile'].fatherName,
      'profile.mothersName': data['Basic Profile'].motherName,
      'profile.spouseName': data['Basic Profile'].spouceName,
      'employee.maritalStatus': data['Basic Profile'].maritalStatus,
      'profile.dateOfBirth': data['Basic Profile'].dateOfBirth
        ? dayjs(data['Basic Profile'].dateOfBirth).format('MM/DD/YYYY')
        : '',
      'profile.dateOfJoining': data['Basic Profile'].dateOfJoining
        ? dayjs(data['Basic Profile'].dateOfJoining).format('MM/DD/YYYY')
        : '',
      'employee.employmentType': data['Basic Profile'].employmentType,
      'employee.employmentStatus': data['Basic Profile'].employmentStatus,
      'employee.designation.id': data['Basic Profile'].designation,
      'employee.department.id': data['Basic Profile'].department,
      'employee.supervisor.id': data['Basic Profile'].supervisor,
      'employee.location.id': data['Basic Profile'].location,
      'employee.panNumberIndia': data['Basic Profile'].panNumber,
      'employee.aadhaarNumberIndia': data['Basic Profile'].aadharNumber,
      'employee.uanNumberIndia': data['Basic Profile'].uan,
      'employee.uhidAccountNumberIndia': data['Basic Profile'].uhid,
      'employee.epfAccountNumberIndia': data['Basic Profile'].epf,
      'employee.epsAccountNumberIndia': data['Basic Profile'].eps,
      'employee.esiAccountNumberIndia': data['Basic Profile'].esi,
      // OnBoarding
      'profile.onboardingReference': data['Additional Details'].OnBoarding.onboardingReference,
      'profile.bloodGroup': data['Additional Details'].OnBoarding.bloodGroup,
      'profile.seperationType': data['Additional Details'].OnBoarding.seperationType,
      'profile.dateOfLeaving': data['Additional Details'].OnBoarding.dateOfLeaving
        ? dayjs(data['Additional Details'].OnBoarding.dateOfLeaving).format('MM/DD/YYYY')
        : '',
      'profile.noticePeriod': data['Additional Details'].OnBoarding.noticePeriod,
      'profile.noticePeriodTimeUnit': data['Additional Details'].OnBoarding.noticePeriodUnit,
      'profile.confirmationDate': data['Additional Details'].OnBoarding.dateOfJoining
        ? dayjs(data['Additional Details'].OnBoarding.dateOfJoining).format('MM/DD/YYYY')
        : '',
      // Socia Data
      'employee.skypeID': data['Additional Details']['Social Data'].skype,
      'employee.facebookID': data['Additional Details']['Social Data'].facebook,
      'employee.linkedinID': data['Additional Details']['Social Data'].linkedin,
      'employee.googlePlusID': data['Additional Details']['Social Data'].googlePlus,
      'employee.twitterID': data['Additional Details']['Social Data'].twitter,
      'employee.instagramID': data['Additional Details']['Social Data'].instagram,
      // Contact details
      'permanentHomeAddress.locationName': data['Contact Details']?.permanentLocation,
      'permanentHomeAddress.addressLine1': data['Contact Details']?.permanentAddressLine1,
      'permanentHomeAddress.addressLine2': data['Contact Details']?.permanentAddressLine2,
      'permanentHomeAddress.addressLine3': data['Contact Details']?.permanentAddressLine3,
      'permanentHomeAddress.city': data['Contact Details']?.permanentCity,
      'permanentHomeAddress.state': data['Contact Details']?.permanentState,
      'permanentHomeAddress.country': data['Contact Details']?.permanentCountry,
      'permanentHomeAddress.pinOrZip': data['Contact Details']?.permanentPincode,

      'officeAddress.locationName': data['Contact Details']?.ofcLocation,
      'officeAddress.addressLine1': data['Contact Details']?.ofcAddressLine1,
      'officeAddress.addressLine2': data['Contact Details']?.ofcAddressLine2,
      'officeAddress.addressLine3': data['Contact Details']?.ofcAddressLine3,
      'officeAddress.city': data['Contact Details']?.ofcCity,
      'officeAddress.state': data['Contact Details']?.ofcState,
      'officeAddress.country': data['Contact Details']?.ofcCountry,
      'officeAddress.pinOrZip': data['Contact Details']?.ofcPincode,

      'presentCommunicationAddress.locationName': data['Contact Details']?.currentLocation,
      'presentCommunicationAddress.addressLine1': data['Contact Details']?.currentAddressLine1,
      'presentCommunicationAddress.addressLine2': data['Contact Details']?.currentAddressLine2,
      'presentCommunicationAddress.addressLine3': data['Contact Details']?.currentAddressLine3,
      'presentCommunicationAddress.city': data['Contact Details']?.currentCity,
      'presentCommunicationAddress.state': data['Contact Details']?.currentState,
      'presentCommunicationAddress.country': data['Contact Details']?.currentCountry,
      'presentCommunicationAddress.pinOrZip': data['Contact Details']?.currentPincode,
      // Payroll setting
      'employee.isDisabledIndia': data['Payroll Setting'].isDisable,
      'employee.isInternationalWorkerIndia': data['Payroll Setting'].internationalWorker,
      'employee.numberOfSchoolGoingChildren': data['Payroll Setting'].schoolChildren,
      'employee.numberOfChildrenInHostel': data['Payroll Setting'].hostelChildren,
      'employee.isEligibleForEPFIndia': data['Payroll Setting'].eligibleForEPF,
      'employee.isEligibleForESIIndia': data['Payroll Setting'].eligibleForESI,
      'employee.isEligibleForEPSIndia': data['Payroll Setting'].eligibleForEPS,
      'employee.isEligibleForEDLIIndia': data['Payroll Setting'].eligibleForEDLI,
      'employee.isProfessionalTaxApplicableIndia': data['Payroll Setting'].taxApplicable,
      'employee.isEligibleForLWFIndia': data['Payroll Setting'].labourWelfareFund,
      'employee.isMetroLocationIndia': data['Payroll Setting'].locatedInMetro,
      'employee.hasJoinedEPFBeforeCutOff': data['Payroll Setting'].joinedEPFPrior,
      'employee.professionalTaxJurisdictionIndia': data['Payroll Setting'].professionalTaxJurisdiction,
      'employee.lwfJurisdictionIndia': data['Payroll Setting'].labourWelfareFundJurisdiction,
      'employee.esiDispensaryIndia': data['Payroll Setting'].esiDispensary,
      'employee.employeeEPFContributionLevelIndia': data['Payroll Setting'].epfContributionLevel,
      'employee.employeeEPFContributionPercentIndia': data['Payroll Setting'].epfContribution,
      'employee.dateOfJoiningEPFIndia': data['Payroll Setting'].dateOfEnrollingepf
        ? dayjs(data['Payroll Setting'].dateOfEnrollingepf).format('MM/DD/YYYY')
        : '',
      'employee.dateOfLeavingEPFIndia': data['Payroll Setting'].dateOfLeavingepf
        ? dayjs(data['Payroll Setting'].dateOfLeavingepf).format('MM/DD/YYYY')
        : '',
      'employee.isPanIndiaValid': data['Payroll Setting'].isPanValid,
      'employee.panIndiaValidationDate': data['Payroll Setting'].panValidatedDate
        ? dayjs(data['Payroll Setting'].dateOfEnrollingepf).format('MM/DD/YYYY')
        : '',
      'employee.panIndiaValidatedBy.id': data['Payroll Setting'].panValidatedBy,
      'employee.isFlatTDSApplicable': data['Payroll Setting'].isEmpFlatTDSTaxable,
      'employee.isPanIndiaValidated': data['Payroll Setting'].panValidatedWithItDept,
      'employee.flatTDSRate': data['Payroll Setting'].rateOfFlatTDS,
      'employee.flatTDSCompBasis': data['Payroll Setting'].flatTDSComputationBasis,
      'employee.companySalaryAccount.id': data['Payroll Setting'].companySalaryAccount,
      'employee.companyExpReimburseAccount.id': data['Payroll Setting'].expenseReimbursementAcc,
      // Notification settings
      'employee.notifyOnSalSlipPubl': data['Notification Setting'].paySlipPublication,
      'employee.notifyOnExpRepAction': data['Notification Setting'].expensesReport,
      'employee.notifyOnLeavReqAction': data['Notification Setting'].leaveRequest,
      'employee.notifyOnAttnExcp': data['Notification Setting'].exceptionOccurance,
    };
    const bankFormikData = data['Bank Account']?.bank;
    if (bankFormikData?.length && isArray(bankFormikData)) {
      bankFormikData.map((bank, index) => {
        body[`bankAccountDetails[${index}].accountNumber`] = bank.accountNumber;
        body[`bankAccountDetails[${index}].accountType`] = bank.accountType;
        body[`bankAccountDetails[${index}].accountPurpose`] = bank.purpose;
        body[`bankAccountDetails[${index}].currency.id`] = bank.accountCurrecy;
        body[`bankAccountDetails[${index}].accountHolderName`] = bank.accountName;
        body[`bankAccountDetails[${index}].bankName`] = bank.bankName;
        body[`bankAccountDetails[${index}].bankBranch`] = bank.branchName;
        body[`bankAccountDetails[${index}].ifsCode`] = bank.ifsc;
        body[`bankAccountDetails[${index}].swiftCode`] = bank.swiftCode;
        body[`bankAccountDetails[${index}].preferredTransactionType`] = bank.transactionType;

        body[`bankAddress[${index}].locationName`] = bank.locationName;
        body[`bankAddress[${index}].addressLine1`] = bank.addressLine1;
        body[`bankAddress[${index}].addressLine2`] = bank.addressLine2;
        body[`bankAddress[${index}].addressLine3`] = bank.addressLine3;
        body[`bankAddress[${index}].city`] = bank.city;
        body[`bankAddress[${index}].state`] = bank.state;
        body[`bankAddress[${index}].country`] = bank.country;
        body[`bankAddress[${index}].pinOrZip`] = bank.pincode;
      });

      body.bankAccntCtr = bankFormikData?.length;
    }

    const mobileFormikData = data['Contact Details']?.mobileNumber;
    if (mobileFormikData?.length && isArray(mobileFormikData)) {
      mobileFormikData.map((number, index) => {
        body[`employee.mobileNumbers[${index}]`] = number;
      });
    }

    const emailFormikData = data['Contact Details']?.emailAddress;
    if (emailFormikData?.length && isArray(emailFormikData)) {
      emailFormikData.map((email, index) => {
        body[`employee.emailAddresses[${index}]`] = email;
      });
    }

    const educationFormikData = data['Additional Details']['Education/Certification History']?.education;

    if (educationFormikData?.length && isArray(educationFormikData)) {
      educationFormikData.map((educationData, index) => {
        body[`employee.educationRecords[${index}].institutionName`] = educationData.institutionName;
        body[`employee.educationRecords[${index}].certificationName`] = educationData.certificationName;
        body[`employee.educationRecords[${index}].startDate`] = educationData.startDate
          ? dayjs(educationData.startDate).format('MM/DD/YYYY')
          : '';
        body[`employee.educationRecords[${index}].endDate`] = educationData.endDate
          ? dayjs(educationData.endDate).format('MM/DD/YYYY')
          : '';
        body[`employee.educationRecords[${index}].specialization`] = educationData.specialization;
        body[`employee.educationRecords[${index}].registrationNumber`] = educationData.registrationNumber;
        body[`employee.educationRecords[${index}].referenceName`] = educationData.referenceName;
        body[`employee.educationRecords[${index}].referencePhoneNumber`] = educationData.referenceNumber;
        body[`employee.educationRecords[${index}].referenceEmail`] = educationData.referenceEmail;
        body[`employee.educationRecords[${index}].comments`] = educationData.clientNotes;
        body[`employee.educationRecords[${index}].attachment`] = educationData.attachment;
      });
      body.eduRecordCtr = educationFormikData?.length;
    }

    const employementFormikData = data['Additional Details']['Employment History']?.employment;
    if (employementFormikData?.length && isArray(employementFormikData)) {
      employementFormikData.map((employment, index) => {
        body[`employee.pastEmploymentRecords[${index}].employerName`] = employment.employerName;
        body[`employee.pastEmploymentRecords[${index}].employerAddress`] = employment.employerAddress;
        body[`employee.pastEmploymentRecords[${index}].employmentCommenceDate`] = employment.employmentCommenceDate
          ? dayjs(employment.employmentCommenceDate).format('MM/DD/YYYY')
          : '';
        body[`employee.pastEmploymentRecords[${index}].employmentEndDate`] = employment.employmentEndDate
          ? dayjs(employment.employmentEndDate).format('MM/DD/YYYY')
          : '';
        body[`employee.pastEmploymentRecords[${index}].reasonForLeaving`] = employment.reasonForLeaving;
        body[`employee.pastEmploymentRecords[${index}].employeeID`] = employment.employeeId;
        body[`employee.pastEmploymentRecords[${index}].startDesignation`] = employment.startDesignation;
        body[`employee.pastEmploymentRecords[${index}].endDesignation`] = employment.endDesignation;
        body[`employee.pastEmploymentRecords[${index}].referenceContactName`] = employment.referenceContactName;
        body[`employee.pastEmploymentRecords[${index}].referenceContactDesignation`] =
          employment.referenceContactDesignation;
        body[`employee.pastEmploymentRecords[${index}].referenceContactPhone`] = employment.referenceContactPhone;
        body[`employee.pastEmploymentRecords[${index}].referenceContactEmail`] = employment.referenceContactEmail;
        body[`employee.pastEmploymentRecords[${index}].attachment`] = employment.attachment;
      });
      body.empRecordCtr = employementFormikData?.length;
    }

    const visaFormikData = data['Additional Details']['Visa Details'];
    if (!isEmpty(visaFormikData)) {
      body[`visaRecords[${0}].issuingCountry`] = visaFormikData.issuingCountry;
      body[`visaRecords[${0}].placeOfIssue`] = visaFormikData.placeOfIssue;
      body[`visaRecords[${0}].validFrom`] = visaFormikData.validFrom
        ? dayjs(visaFormikData.validFrom).format('MM/DD/YYYY')
        : '';
      body[`visaRecords[${0}].validTo`] = visaFormikData.validTo
        ? dayjs(visaFormikData.validTo).format('MM/DD/YYYY')
        : '';
      body[`visaRecords[${0}].issueDate`] = visaFormikData.issueDate
        ? dayjs(visaFormikData.issueDate).format('MM/DD/YYYY')
        : '';
      body[`visaRecords[${0}].referenceNumber`] = visaFormikData.referenceNumber;
      body[`visaRecords[${0}].status`] = visaFormikData.visaStatus;
      body[`visaRecords[${0}].comments`] = visaFormikData.comments;
      body[`visaRecords[${0}].id`] = employeeData['Additional Details']['Visa Details'].id;
      if (visaFormikData.attachment) body[`visaRecords[${0}].attachment`] = visaFormikData.attachment;
      body.visaRecordCtr = 1;
    }

    const skillFormikData = data['Additional Details']['Skill Attainment Details']?.skillDetails;
    if (skillFormikData?.length && isArray(skillFormikData)) {
      skillFormikData.map((skillDetails, index) => {
        body[`employee.skillLevelAttainmentRecords[${index}].skill`] = skillDetails.skill;
        body[`employee.skillLevelAttainmentRecords[${index}].level`] = skillDetails.level;
        body[`employee.skillLevelAttainmentRecords[${index}].comments`] = skillDetails.comments;
        body[`employee.skillLevelAttainmentRecords[${index}].attachment`] = skillDetails.attachment;
      });
      body.skillRecordCtr = skillFormikData?.length;
    }

    const eventFormikData = data['Additional Details']['Key Life Event Details']?.keylifeEvent;
    if (eventFormikData?.length && isArray(eventFormikData)) {
      eventFormikData.map((keylifeEvent, index) => {
        body[`employee.eventRecords[${index}].eventType`] = keylifeEvent.keyLifeEventDetails;
        body[`employee.eventRecords[${index}].eventDate`] = keylifeEvent.eventDate
          ? dayjs(keylifeEvent.eventDate).format('MM/DD/YYYY')
          : '';
        body[`employee.eventRecords[${index}].comments`] = keylifeEvent.comments;
      });
      body.eventRecordCtr = eventFormikData?.length;
    }

    const dependentAndNominationFormikData = data['Additional Details']['Dependents Details']?.dependent;

    if (dependentAndNominationFormikData?.length && isArray(dependentAndNominationFormikData)) {
      dependentAndNominationFormikData.map((dependent, index) => {
        body[`dependentDetails[${index}].contactInfo.firstName`] = dependent.firstName;
        body[`dependentDetails[${index}].contactInfo.lastName`] = dependent.lastName;
        body[`dependentDetails[${index}].contactInfo.gender`] = dependent.gender;
        body[`dependentDetails[${index}].dateOfBirth`] = dependent.dateOfBirth
          ? dayjs(dependent.dateOfBirth).format('MM/DD/YYYY')
          : '';
        body[`dependentDetails[${index}].relationship`] = dependent.relation;
        body[`dependentDetails[${index}].contactInfo.mobileNumber`] = dependent.mobileNumber;
        body[`dependentDetails[${index}].contactInfo.emailAddress`] = dependent.emailId;
        body[`dependentDetails[${index}].bloodGroup`] = dependent.bloodGroup;
        body[`dependentDetails[${index}].epfNominationPercentage`] = dependent.epfPerc;
        body[`dependentDetails[${index}].epsNominationPercentage`] = dependent.epsPerc;
        body[`dependentDetails[${index}].insuranceNominationPercentage`] = dependent.insurancePerc;
        body[`dependentDetails[${index}].othersNominationPercentage`] = dependent.otherPerc;
        body[`dependentDetails[${index}].gratuityNominationPercentage`] = dependent.gratuityPerc;
        body[`dependentDetails[${index}].emergencyContactReference`] = dependent.emergencyContact;
      });
      body.dependentsCtr = dependentAndNominationFormikData?.length;
    }

    try {
      const bodyFormData = new FormData();
      Object.keys(body).forEach((key) => {
        if (body[key] && !(isArray(body[key]) && isEmpty(body[key]))) {
          bodyFormData.append(key, body[key]);
        }
      });

      customAttributesMetaData.forEach((customAttribute) => {
        bodyFormData.append(customAttribute.attribute, customAttribute.value);
      });

      if (isEditMode) {
        bodyFormData.append('id', preModifyData?.secUserInstance?.id);
      }

      const apiCall = isEditMode ? modifyUserAction : saveUserAction;

      const response = await apiCall(bodyFormData);
      if (isEditMode) setIsOpenAlert(true);
      else setShowSuccess(true);
      if (response.status === 200) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Employee ${isEditMode ? 'edited' : 'added'} successfully`,
        });
      }
    } catch (err) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: err.message || `Failed to ${isEditMode ? 'edit' : 'add'} employee`,
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setPreCreateEmployee({ ...preCreateEmployeeOptions, isLoading: true });

      const url = 'restEmpIndex/createUser';
      let createResponse = {};
      try {
        createResponse = await formDataApi.get(url);
        if (createResponse?.data) {
          const modifiedData = modifyPreCreateData(createResponse);
          setPreCreateEmployee(modifiedData);
          setAuthorityList(createResponse?.data?.authorityList);
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'ERROR',
          message: err.message || `Something went wrong`,
        });
      }
      if (isEditMode) {
        const preModifyurl = 'restEmpIndex/modifyUser';
        try {
          setEmployeeData({ ...employeeData, isLoading: true });

          const body = new FormData();
          body.append('id', id);
          const response = await formDataApi.post(preModifyurl, body);
          if (response?.data) {
            setPreModifyData(response.data);
            setRoleMap(response?.data?.roleMap);
            const empData = getEmpData(response, createResponse?.data?.authorityList);
            setEmployeeData(empData);
          }
        } catch (err) {
          addToast({
            type: 'error',
            title: 'ERROR',
            message: err.message || `Something went wrong`,
          });
        }
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isEditMode]);

  if (!userHasViewPermission) {
    return <Typography variant="h6">You do not have permission to view this page.</Typography>;
  }

  return (
    <Box>
      <AddEmployeeTemplate
        onSubmit={(data) => createEmployee(data)}
        handleCancel={() => navigate(`/company/employee/view/${id}`)}
        loading={
          isEditMode
            ? employeeData?.isLoading || preCreateEmployeeOptions?.isLoading || isEmpty(employeeData)
            : preCreateEmployeeOptions?.isLoading
        }
        dropdownOptions={preCreateEmployeeOptions}
        customAttributesFormik={customAttributesFormik}
        metaCustomFields={metaCustomFields}
        employeeData={employeeData}
        allowEdit={isEditMode}
        roleMap={roleMap}
      />
      <DialogSuccess
        open={showSuccess}
        handleClose={() => setShowSuccess(false)}
        handleComment={() => navigate('/company/employee/employees-list')}
        handleCancel={() => navigate('/company/employee/employees-list')}
      />
      {isOpenAlert && (
        <Box sx={affiliatedEmployeesStyle.alertBox}>
          <Alert
            severity="success"
            titleText="Success!"
            message="Employee details modified successfully"
            closeLabel={false}
            onClose={() => setIsOpenAlert(false)}
          />
        </Box>
      )}
    </Box>
  );
};

export default AddEditEmployee;
