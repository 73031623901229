import * as React from 'react';
import Paper from '@mui/material/Paper';
import Typographys from 'components/Atoms/Typography';
import { Box } from '@mui/material';

const HTable = ({ headers, rows, hasHeader, headsData }) => {
  return (
    <Paper
      sx={{
        overflow: 'auto',
        border: '1px solid #CDD2DC',
        borderRadius: '4px',
      }}
    >
      {hasHeader && (
        <Box sx={{ display: 'flex', p: '12px 8px', backgroundColor: 'actions.100' }}>
          <Box sx={{ flexBasis: '25%' }}>
            <Typographys variant="body2" color="text.300">
              HRA Received
            </Typographys>
          </Box>
          <Box sx={{ flexBasis: '25%' }}>
            <Typographys variant="body2" color="text.300">
              50% of Basic
            </Typographys>
          </Box>
          <Box sx={{ flexBasis: '25%' }}>
            <Typographys variant="body2" color="text.300">
              {`Rent paid >10% Basic`}
            </Typographys>
          </Box>
          <Box sx={{ flexBasis: '25%' }} />
        </Box>
      )}
      <Box sx={{ display: 'flex', p: '12px 8px' }}>
        <Box sx={{ width: '25%' }}>
          <Typographys variant="body2">₹10000</Typographys>
        </Box>
        <Box sx={{ width: '25%' }}>
          <Typographys variant="body2">₹10000</Typographys>
        </Box>
        <Box sx={{ width: '25%' }}>
          <Typographys variant="body2">₹0</Typographys>
        </Box>
        <Box sx={{ width: '25%' }} />
      </Box>
    </Paper>
  );
};

export default HTable;
