import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import BottomNavigation from 'components/Molecules/BottomNavigation';
import Header from 'components/Molecules/Header';
import MiniSidebar from 'components/Molecules/MiniSidebar';
import MainSidebar from 'components/Molecules/MainSidebar';
import { setLeftDrawerOpen } from 'store/slices/theme/customization';
import { AlertContextProvider } from 'components/Provider/AlertToastContext';

const HeaderwithSidebar = ({ title, left }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const leftDrawerOpened = useSelector((state) => state.customization.leftDrawerOpen);
  const handleLeftDrawerToggle = () => {
    dispatch(setLeftDrawerOpen(!leftDrawerOpened));
  };
  return (
    <>
      <AlertContextProvider>
        <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
      </AlertContextProvider>
      <Box>
        {matchDownSm ? <BottomNavigation /> : <MiniSidebar top="8rem" />}
        <MainSidebar
          open={matchDownSm ? leftDrawerOpened : !leftDrawerOpened}
          drawerToggle={handleLeftDrawerToggle}
          top={matchDownSm ? '0' : '8rem'}
          left={matchDownSm ? '0' : left}
          title={title}
        />
      </Box>
    </>
  );
};

HeaderwithSidebar.defaultProps = {
  title: 'Company',
  left: '10rem',
};

HeaderwithSidebar.propTypes = {
  title: PropTypes.string,
  left: PropTypes.string,
};

export default HeaderwithSidebar;
