import React from 'react';
import { Legend } from 'recharts';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import PieCharts from 'components/Organisms/PieCharts';
import GraphColor from 'utils/GraphColor';
import CustomLegend from 'components/Molecules/CustomLegend';
import { chartStyle } from './style';

const GenderGraph = ({ width, height, innerRadius, outerRadius, paddingAngle, genderData, colors, totalValue }) => {
  return (
    <Box sx={chartStyle.chartWrapper}>
      <PieCharts
        width={width}
        height={height}
        data={genderData}
        colors={colors}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        paddingAngle={paddingAngle}
      >
        <Legend
          content={
            <CustomLegend
              layout="horizontal"
              align="center"
              iconWidth="0.75rem"
              iconHeight="1.5rem"
              iconType="square"
              isLegendValue
              legendItemWidth="43%"
            />
          }
          wrapperStyle={chartStyle.legend}
        />
      </PieCharts>
      <Box sx={chartStyle.valueBox}>
        <Typographys variant="body2" color="neutral.800" align="center">
          Total
        </Typographys>
        <Typographys variant="h5" color="neutral.800" align="center">
          {totalValue}
        </Typographys>
      </Box>
    </Box>
  );
};

GenderGraph.defaultProps = {
  width: 315,
  height: 250,
  paddingAngle: 0,
  innerRadius: 50,
  outerRadius: 70,
  genderData: [],
  colors: GraphColor('genderGraphs'),
  totalValue: 100,
};
GenderGraph.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  innerRadius: PropTypes.number,
  outerRadius: PropTypes.number,
  paddingAngle: PropTypes.number,
  genderData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  colors: PropTypes.arrayOf({}),
  totalValue: PropTypes.number,
};

export default GenderGraph;
