import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { TDSWorkingStyle } from 'components/Organisms/MyPay/TDSWorking/style';
import TableExtended from 'components/Molecules/TableExtended';

const CategoryTable = ({ categoryColumnsData, categoryRowsData, loading }) => {
  const categoryColumn = [
    { fieldName: 'category', label: 'Category', formatValue: ({ row, column }) => row[column.fieldName] },
    ...categoryColumnsData,
  ];

  const categoryRows = Object.keys(categoryRowsData).map((item) => ({
    category: item,
    ...categoryRowsData[item],
  }));

  return (
    <Box sx={TDSWorkingStyle.categoryTableWrapper}>
      <TableExtended loading={loading} columns={categoryColumn} rows={categoryRows} />
    </Box>
  );
};
CategoryTable.defaultProps = {
  categoryColumnsData: [],
  categoryRowsData: {},
  loading: false,
};
CategoryTable.propTypes = {
  loading: PropTypes.bool,
  categoryColumnsData: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),
  categoryRowsData: PropTypes.shape({
    Basic: PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    Bonus: PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    "Children's Education Allowance": PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    "Children's Hostel Allowance": PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Conveyance Allowance': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Dearness Allowance': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Flat Allowance': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Flexible Allowance': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'House Rent Allowance': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Leave Travel Allowance*': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Lunch Allowance': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Medical Allowance': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Other Allowance': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Special Allowance': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Telephone/Internet Allowance': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Total Earnings': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Loss Of Pay (days)': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'LWF - Employee Contribution': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'NPS Deduction': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'PF Contribution (Employee)': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Professional Tax': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Income Tax': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Total Deductions': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Net Earnings': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
  }),
};

export default CategoryTable;
