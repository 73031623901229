import React, { useEffect, useState } from 'react';
import { Box, Grid, Divider, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import Button from 'components/Atoms/Button';
import Typographys from 'components/Atoms/Typography';
import InputField from 'components/Atoms/InputField';
import Checkbox from 'components/Atoms/Checkbox';
import * as yup from 'yup';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Formik, FieldArray, getIn } from 'formik';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { genericMobileRegex, genericEmailRegex } from 'utils/commonUtils';
import { addEmpStyle } from '../style';

const ContactDetails = ({ tabHeading, handleSubmit, handleCancel, propData, label, updateBtnText }) => {
  const defaultValues = propData.allTabsData[label];
  const SignupSchema = yup.object().shape({
    mobileNumber: yup
      .array()
      .of(
        yup
          .string()
          .required('Required')
          .matches(genericMobileRegex, 'Invalid format, Please enter valid mobile number.'),
      ),
    emailAddress: yup
      .array()
      .of(yup.string().required('Required').matches(genericEmailRegex, 'Invalid format, Please enter valid email Id.')),
    emergencyMobile: yup
      .string()
      .required('Required')
      .matches(genericMobileRegex, 'Invalid format, Please enter valid mobile number.'),
    emergencyEmail: yup.string().matches(genericEmailRegex, 'Invalid format, Please enter valid email Id.'),
  });

  const initialValues = Object.keys(defaultValues).length
    ? defaultValues
    : {
        mobileNumber: [''],
        emailAddress: [''],
        name: '',
        relation: '',
        emergencyMobile: '',
        emergencyEmail: '',
        permanentLocation: '',
        permanentAddressLine1: '',
        permanentAddressLine2: '',
        permanentAddressLine3: '',
        permanentCity: '',
        permanentState: '',
        permanentCountry: '',
        permanentPincode: '',
        currentLocation: '',
        currentAddressLine1: '',
        currentAddressLine2: '',
        currentAddressLine3: '',
        currentCity: '',
        currentState: '',
        currentCountry: '',
        currentPincode: '',
        ofcLocationName: '',
        ofcAddressLine1: '',
        ofcAddressLine2: '',
        ofcAddressLine3: '',
        ofcCity: '',
        ofcState: '',
        ofcCountry: '',
        ofcPincode: '',
        sameAddress: '',
      };

  return (
    <Box mt={1.5} sx={{ p: '2rem 3rem' }}>
      <Typographys variant="h5" color="neutral.800">
        {tabHeading}
      </Typographys>
      <Formik
        validationSchema={SignupSchema}
        initialValues={initialValues}
        validateOnMount
        onSubmit={(values, actions) => {
          handleSubmit(values);
          actions.setSubmitting(false);
        }}
      >
        {(formik) => (
          <Form
            formik={formik}
            handleCancel={handleCancel}
            updateBtnText={updateBtnText}
            propData={propData}
            label={label}
          />
        )}
      </Formik>
    </Box>
  );
};

const Form = ({ formik, handleCancel, propData, label, updateBtnText }) => {
  useEffect(() => {
    propData.updateFormData(formik.values, label);
  }, [formik.values, label]);
  const theme = useTheme();

  const [isChecked, setIsChecked] = useState(false);

  function handleSameAddress() {
    const {
      permanentLocation,
      permanentAddressLine1,
      permanentAddressLine2,
      permanentAddressLine3,
      permanentCity,
      permanentState,
      permanentCountry,
      permanentPincode,
    } = formik.values;

    formik.setFieldValue('currentLocation', permanentLocation);
    formik.setFieldValue('currentAddressLine1', permanentAddressLine1);
    formik.setFieldValue('currentAddressLine2', permanentAddressLine2);
    formik.setFieldValue('currentAddressLine3', permanentAddressLine3);
    formik.setFieldValue('currentCity', permanentCity);
    formik.setFieldValue('currentState', permanentState);
    formik.setFieldValue('currentCountry', permanentCountry);
    formik.setFieldValue('currentPincode', permanentPincode);
  }

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 50.3rem)' }}>
          <Grid container rowSpacing={2} columnSpacing={9} pt={0} mt={0} sx={addEmpStyle.formContainer}>
            <FieldArray name="mobileNumber">
              {({ push, remove }) => (
                <Grid item xs={12} md={4}>
                  {formik.values.mobileNumber.map((m, index) => (
                    <InputField
                      error={Boolean(
                        getIn(formik.touched, `mobileNumber[${index}]`) &&
                          getIn(formik.errors, `mobileNumber[${index}]`),
                      )}
                      helperText={
                        getIn(formik.touched, `mobileNumber[${index}]`) &&
                        getIn(formik.errors, `mobileNumber[${index}]`)
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={m}
                      name={`mobileNumber[${index}]`}
                      id={`mobileNumber[${index}]`}
                      label="Mobile Number*"
                      type="Number"
                      fullWidth
                      sx={{ marginBottom: '1.5rem' }}
                    />
                  ))}
                  <Box>
                    <Button
                      variant="outlined"
                      size="medium"
                      type="button"
                      startIcon={<AddOutlinedIcon />}
                      onClick={() => push('')}
                    >
                      Add
                    </Button>
                  </Box>
                </Grid>
              )}
            </FieldArray>
            <FieldArray name="emailAddress">
              {({ push, remove }) => (
                <Grid item xs={12} md={4}>
                  {formik.values.emailAddress.map((e, index) => (
                    <InputField
                      error={Boolean(
                        getIn(formik.touched, `emailAddress[${index}]`) &&
                          getIn(formik.errors, `emailAddress[${index}]`),
                      )}
                      helperText={
                        getIn(formik.touched, `emailAddress[${index}]`) &&
                        getIn(formik.errors, `emailAddress[${index}]`)
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={e}
                      name={`emailAddress[${index}]`}
                      id={`emailAddress[${index}]`}
                      label="Email Address*"
                      fullWidth
                      sx={{ marginBottom: '1.5rem' }}
                    />
                  ))}

                  <Box>
                    <Button
                      variant="outlined"
                      size="medium"
                      type="button"
                      startIcon={<AddOutlinedIcon />}
                      onClick={() => push('')}
                    >
                      Add
                    </Button>
                  </Box>
                </Grid>
              )}
            </FieldArray>
          </Grid>

          <Divider
            sx={{
              my: 2,
              backgroundColor: theme.palette.neutral[200],
            }}
          />
          <Divider
            sx={{
              my: 2,
              backgroundColor: theme.palette.neutral[200],
            }}
          />

          <Typographys variant="h5" color="neutral.800">
            Permanent Residential Address
          </Typographys>

          <Grid mt={0} pt={0} container rowSpacing={2} columnSpacing={9} sx={addEmpStyle.formContainer}>
            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.permanentLocation && formik.errors.permanentLocation)}
                helperText={formik.touched.permanentLocation && formik.errors.permanentLocation}
                onChange={(e) => {
                  setIsChecked(false);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.permanentLocation}
                name="permanentLocation"
                id="permanentLocation"
                label="Location Name (Optional)"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.permanentAddressLine1 && formik.errors.permanentAddressLine1)}
                helperText={formik.touched.permanentAddressLine1 && formik.errors.permanentAddressLine1}
                onChange={(e) => {
                  setIsChecked(false);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.permanentAddressLine1}
                name="permanentAddressLine1"
                id="permanentAddressLine1"
                label="Address Line 1"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.permanentAddressLine2 && formik.errors.permanentAddressLine2)}
                helperText={formik.touched.permanentAddressLine2 && formik.errors.permanentAddressLine2}
                onChange={(e) => {
                  setIsChecked(false);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.permanentAddressLine2}
                name="permanentAddressLine2"
                id="permanentAddressLine2"
                label="Address Line 2"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.permanentAddressLine3 && formik.errors.permanentAddressLine3)}
                helperText={formik.touched.permanentAddressLine3 && formik.errors.permanentAddressLine3}
                onChange={(e) => {
                  setIsChecked(false);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.permanentAddressLine3}
                name="permanentAddressLine3"
                id="permanentAddressLine3"
                label="Address Line 3"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.permanentCity && formik.errors.permanentCity)}
                helperText={formik.touched.permanentCity && formik.errors.permanentCity}
                onChange={(e) => {
                  setIsChecked(false);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.permanentCity}
                name="permanentCity"
                id="permanentCity"
                label="City"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.permanentState && formik.errors.permanentState)}
                helperText={formik.touched.permanentState && formik.errors.permanentState}
                onChange={(e) => {
                  setIsChecked(false);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.permanentState}
                name="permanentState"
                id="permanentState"
                label="State"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.permanentCountry && formik.errors.permanentCountry)}
                helperText={formik.touched.permanentCountry && formik.errors.permanentCountry}
                onChange={(e) => {
                  setIsChecked(false);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.permanentCountry}
                name="permanentCountry"
                id="permanentCountry"
                label="Country"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.permanentPincode && formik.errors.permanentPincode)}
                helperText={formik.touched.permanentPincode && formik.errors.permanentPincode}
                onChange={(e) => {
                  setIsChecked(false);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.permanentPincode}
                name="permanentPincode"
                id="permanentPincode"
                label="Pincode"
                type="number"
                fullWidth
              />
            </Grid>
          </Grid>

          <Divider
            sx={{
              my: 2,
              backgroundColor: theme.palette.neutral[200],
            }}
          />

          <Typographys variant="h5" color="neutral.800">
            Current Residential Address
          </Typographys>

          <Box mt={2} ml="1.75rem">
            <Checkbox
              onChange={(e) => {
                setIsChecked(e.target.checked);
                if (e.target.checked) {
                  handleSameAddress();
                }
              }}
              checked={isChecked}
              color="secondary"
              name="sameAddress"
              label="Same as permanent address"
            />
          </Box>

          <Grid mt={2} container rowSpacing={2} columnSpacing={9} sx={addEmpStyle.formContainer}>
            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.currentLocation && formik.errors.currentLocation)}
                helperText={formik.touched.currentLocation && formik.errors.currentLocation}
                onChange={(e) => {
                  setIsChecked(false);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.currentLocation}
                name="currentLocation"
                id="currentLocation"
                label="Location Name (Optional)"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.currentAddressLine1 && formik.errors.currentAddressLine1)}
                helperText={formik.touched.currentAddressLine1 && formik.errors.currentAddressLine1}
                onChange={(e) => {
                  setIsChecked(false);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.currentAddressLine1}
                name="currentAddressLine1"
                id="currentAddressLine1"
                label="Address Line 1"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.currentAddressLine2 && formik.errors.currentAddressLine2)}
                helperText={formik.touched.currentAddressLine2 && formik.errors.currentAddressLine2}
                onChange={(e) => {
                  setIsChecked(false);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.currentAddressLine2}
                name="currentAddressLine2"
                id="currentAddressLine2"
                label="Address Line 2"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.currentAddressLine3 && formik.errors.currentAddressLine3)}
                helperText={formik.touched.currentAddressLine3 && formik.errors.currentAddressLine3}
                onChange={(e) => {
                  setIsChecked(false);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.currentAddressLine3}
                name="currentAddressLine3"
                id="currentAddressLine3"
                label="Address Line 3"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.currentCity && formik.errors.currentCity)}
                helperText={formik.touched.currentCity && formik.errors.currentCity}
                onChange={(e) => {
                  setIsChecked(false);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.currentCity}
                name="currentCity"
                id="currentCity"
                label="City"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.currentState && formik.errors.currentState)}
                helperText={formik.touched.currentState && formik.errors.currentState}
                onChange={(e) => {
                  setIsChecked(false);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.currentState}
                name="currentState"
                id="currentState"
                label="State"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.currentCountry && formik.errors.currentCountry)}
                helperText={formik.touched.currentCountry && formik.errors.currentCountry}
                onChange={(e) => {
                  setIsChecked(false);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.currentCountry}
                name="currentCountry"
                id="currentCountry"
                label="Country"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.currentPincode && formik.errors.currentPincode)}
                helperText={formik.touched.currentPincode && formik.errors.currentPincode}
                onChange={(e) => {
                  setIsChecked(false);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.currentPincode}
                name="currentPincode"
                id="currentPincode"
                label="Pincode"
                type="number"
                fullWidth
              />
            </Grid>
          </Grid>

          <Divider
            sx={{
              my: 2,
              backgroundColor: theme.palette.neutral[200],
            }}
          />

          <Typographys variant="h5" color="neutral.800">
            Office Address
          </Typographys>

          <Grid mt={0} container rowSpacing={2} columnSpacing={9} sx={addEmpStyle.formContainer}>
            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.ofcLocationName && formik.errors.ofcLocationName)}
                helperText={formik.touched.ofcLocationName && formik.errors.ofcLocationName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.ofcLocationName}
                name="ofcLocationName"
                id="ofcLocationName"
                label="Location Name (Optional)"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.ofcAddressLine1 && formik.errors.ofcAddressLine1)}
                helperText={formik.touched.ofcAddressLine1 && formik.errors.ofcAddressLine1}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.ofcAddressLine1}
                name="ofcAddressLine1"
                id="ofcAddressLine1"
                label="Address Line 1"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.ofcAddressLine2 && formik.errors.ofcAddressLine2)}
                helperText={formik.touched.ofcAddressLine2 && formik.errors.ofcAddressLine2}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.ofcAddressLine2}
                name="ofcAddressLine2"
                id="ofcAddressLine2"
                label="Address Line 2"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.ofcAddressLine3 && formik.errors.ofcAddressLine3)}
                helperText={formik.touched.ofcAddressLine3 && formik.errors.ofcAddressLine3}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.ofcAddressLine3}
                name="ofcAddressLine3"
                id="ofcAddressLine3"
                label="Address Line 3"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.ofcCity && formik.errors.ofcCity)}
                helperText={formik.touched.ofcCity && formik.errors.ofcCity}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.ofcCity}
                name="ofcCity"
                id="ofcCity"
                label="City"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.ofcState && formik.errors.ofcState)}
                helperText={formik.touched.ofcState && formik.errors.ofcState}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.ofcState}
                name="ofcState"
                id="ofcState"
                label="State"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.ofcCountry && formik.errors.ofcCountry)}
                helperText={formik.touched.ofcCountry && formik.errors.ofcCountry}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.ofcCountry}
                name="ofcCountry"
                id="ofcCountry"
                label="Country"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.ofcPincode && formik.errors.ofcPincode)}
                helperText={formik.touched.ofcPincode && formik.errors.ofcPincode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.ofcPincode}
                name="ofcPincode"
                id="ofcPincode"
                label="Pincode"
                type="number"
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </PerfectScrollbar>

      <Box sx={addEmpStyle.btnContainer}>
        <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

        <Box sx={addEmpStyle.submitWrapper}>
          <Button
            size="large"
            color="info"
            variant="outlined"
            type="cancel"
            onClick={() => {
              formik.resetForm();
              handleCancel();
            }}
          >
            Cancel
          </Button>

          <Button size="large" type="submit" isDisable={false}>
            {propData.allowEdit ? updateBtnText : 'Next'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

Form.defaultProps = {
  handleCancel: () => {},
  propData: {},
  formik: {},
  label: '',
  updateBtnText: 'Update',
};

Form.propTypes = {
  propData: PropTypes.shape({
    updateFormData: PropTypes.func,
    allTabsData: PropTypes.shape({
      'Contact Details': PropTypes.shape({}),
    }),
    allowEdit: PropTypes.bool,
  }),
  handleCancel: PropTypes.func,
  updateBtnText: PropTypes.string,
  label: PropTypes.string,
  formik: PropTypes.shape({
    setFieldValue: PropTypes.func,
    errors: PropTypes.shape({
      name: PropTypes.string,
      relation: PropTypes.string,
      emergencyMobile: PropTypes.string,
      emergencyEmail: PropTypes.string,
      permanentLocation: PropTypes.string,
      permanentAddressLine1: PropTypes.string,
      permanentAddressLine2: PropTypes.string,
      permanentAddressLine3: PropTypes.string,
      permanentCity: PropTypes.string,
      permanentState: PropTypes.string,
      permanentCountry: PropTypes.string,
      permanentPincode: PropTypes.string,
      currentLocation: PropTypes.string,
      currentAddressLine1: PropTypes.string,
      currentAddressLine2: PropTypes.string,
      currentAddressLine3: PropTypes.string,
      currentCity: PropTypes.string,
      currentState: PropTypes.string,
      currentCountry: PropTypes.string,
      currentPincode: PropTypes.string,
      ofcLocationName: PropTypes.string,
      ofcAddressLine1: PropTypes.string,
      ofcAddressLine2: PropTypes.string,
      ofcAddressLine3: PropTypes.string,
      ofcCity: PropTypes.string,
      ofcState: PropTypes.string,
      ofcCountry: PropTypes.string,
      ofcPincode: PropTypes.string,
      sameAddress: PropTypes.string,
    }),

    touched: PropTypes.shape({
      name: PropTypes.string,
      relation: PropTypes.string,
      emergencyMobile: PropTypes.string,
      emergencyEmail: PropTypes.string,
      permanentLocation: PropTypes.string,
      permanentAddressLine1: PropTypes.string,
      permanentAddressLine2: PropTypes.string,
      permanentAddressLine3: PropTypes.string,
      permanentCity: PropTypes.string,
      permanentState: PropTypes.string,
      permanentCountry: PropTypes.string,
      permanentPincode: PropTypes.string,
      currentLocation: PropTypes.string,
      currentAddressLine1: PropTypes.string,
      currentAddressLine2: PropTypes.string,
      currentAddressLine3: PropTypes.string,
      currentCity: PropTypes.string,
      currentState: PropTypes.string,
      currentCountry: PropTypes.string,
      currentPincode: PropTypes.string,
      ofcLocationName: PropTypes.string,
      ofcAddressLine1: PropTypes.string,
      ofcAddressLine2: PropTypes.string,
      ofcAddressLine3: PropTypes.string,
      ofcCity: PropTypes.string,
      ofcState: PropTypes.string,
      ofcCountry: PropTypes.string,
      ofcPincode: PropTypes.string,
      sameAddress: PropTypes.string,
    }),

    values: PropTypes.shape({
      mobileNumber: PropTypes.shape([]),
      emailAddress: PropTypes.shape([]),
      name: PropTypes.string,
      relation: PropTypes.string,
      emergencyMobile: PropTypes.string,
      emergencyEmail: PropTypes.string,
      permanentLocation: PropTypes.string,
      permanentAddressLine1: PropTypes.string,
      permanentAddressLine2: PropTypes.string,
      permanentAddressLine3: PropTypes.string,
      permanentCity: PropTypes.string,
      permanentState: PropTypes.string,
      permanentCountry: PropTypes.string,
      permanentPincode: PropTypes.string,
      currentLocation: PropTypes.string,
      currentAddressLine1: PropTypes.string,
      currentAddressLine2: PropTypes.string,
      currentAddressLine3: PropTypes.string,
      currentCity: PropTypes.string,
      currentState: PropTypes.string,
      currentCountry: PropTypes.string,
      currentPincode: PropTypes.string,
      ofcLocationName: PropTypes.string,
      ofcAddressLine1: PropTypes.string,
      ofcAddressLine2: PropTypes.string,
      ofcAddressLine3: PropTypes.string,
      ofcCity: PropTypes.string,
      ofcState: PropTypes.string,
      ofcCountry: PropTypes.string,
      ofcPincode: PropTypes.string,
      sameAddress: PropTypes.string,
    }),
    resetForm: PropTypes.func,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
  }),
};

ContactDetails.defaultProps = {
  tabHeading: 'Contact Details',
  propData: {},
  handleSubmit: () => {},
  handleCancel: () => {},
  label: 'Contact Details',
  updateBtnText: 'Update',
};

ContactDetails.propTypes = {
  tabHeading: PropTypes.string,
  propData: PropTypes.shape({
    updateFormData: PropTypes.func,
    allTabsData: PropTypes.shape({
      'Contact Details': PropTypes.shape({}),
    }),
    allowEdit: PropTypes.bool,
  }),
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,

  label: PropTypes.string,
  updateBtnText: PropTypes.string,
};

export default ContactDetails;
