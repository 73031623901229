import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import AuditTrailCard from 'components/Organisms/Employee/EmployeeRecord/AuditTrailCard';
import Typography from 'components/Atoms/Typography';

const AuditTrail = ({ auditTrailLists }) => {
  return (
    <>
      <Typography variant="body1" fontWeight={600} color="#7C85A2" sx={{ mb: 4 }}>
        Approver Action Log
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        {auditTrailLists?.length > 0 &&
          auditTrailLists?.map((list) => (
            <Box>
              <AuditTrailCard
                avatarSrc={list?.avatarSrc}
                content={list?.content}
                date={list?.date}
                description={list?.description}
              />
            </Box>
          ))}
      </Box>
    </>
  );
};

AuditTrail.defaultProps = {
  auditTrailLists: [
    {
      avatarSrc: 'images/user.jpg',
      name: 'Uday kumar',
      date: '07 May 2020 00:03:21 IST',
    },
    {
      avatarSrc: 'images/user.jpg',
      name: 'Uday kumar',

      date: '07 May 2020 00:03:21 IST',
    },
    {
      avatarSrc: 'images/user.jpg',
      name: 'Uday kumar',

      date: '07 May 2020 00:03:21 IST',
    },
  ],
};

AuditTrail.propTypes = {
  auditTrailLists: PropTypes.arrayOf(
    PropTypes.shape({
      avatarSrc: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      date: PropTypes.string,
    }),
  ),
};

export default AuditTrail;
