import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Link } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import ProgressBar from 'components/Atoms/ProgressBar';
import Button from 'components/Atoms/Button';
import DropdownMenu from 'components/Molecules/DropdownMenu';
import IconWithTitle from 'components/Atoms/IconWithTitle';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EditIcon from '@mui/icons-material/Edit';
import Avatars from 'components/Molecules/Avatar';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import TitleWithText from 'components/Atoms/TitleWithText';
import { useParams, useNavigate } from 'react-router-dom';
import AnonymiseEmployee from 'components/Organisms/Dialog/ActionList/AnonymiseEmployee';
import { addAnonymiseEmployeeAction } from 'api/employees';
import useToast from 'components/Provider/useToast';

import { profileCardStyle } from './style';

const EmployeeProfileCard = ({ employeeDetails, employeeProfileDetail }) => {
  const addToast = useToast();
  const navigate = useNavigate();
  const { userId } = useParams();
  const [anonymiseEmployee, setAnonymiseEmployee] = useState(false);
  const [notes, setNotes] = useState('');

  const showAnonymiseEmployeeDailog = useCallback(() => setAnonymiseEmployee(!anonymiseEmployee), [anonymiseEmployee]);

  const notesValueChange = (event) => {
    const value = event?.target?.value;
    setNotes(value);
  };

  const addAnonymiseEmployee = async () => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', userId);
      bodyFormData.append('notes', notes);
      const response = await addAnonymiseEmployeeAction(bodyFormData);
      if (response.status === 200) {
        addToast({ type: 'success', title: 'SUCCESS', message: response?.data?.message });
      }
    } catch (error) {
      addToast({ type: 'error', title: 'ERROR', message: error?.message });
    } finally {
      showAnonymiseEmployeeDailog();
    }
  };

  const basicDetails = employeeDetails['Basic Profile'].employeeBasicDetail;
  const contactDetails = employeeDetails['Contact Details'].contactDetails;
  const extendedAttributes = employeeDetails['Extended Attributes'].extendedAttribute;
  const employeeID = employeeDetails['Additional Details'].employmentHistory?.employeeID;

  return (
    <Box sx={profileCardStyle.wrapper}>
      <Box sx={{ flexShrink: 0, justifyContent: 'center' }}>
        <Avatars avatarSrc={employeeProfileDetail.avatarSrc} sx={profileCardStyle.avatar} />
        <Box sx={{ my: 0.5 }}>
          <Typographys variant="subtitle1" color="neutral.600">
            Profile Completed
          </Typographys>
        </Box>
        <ProgressBar value={employeeProfileDetail.progressBarValue} />
      </Box>

      <Box sx={profileCardStyle.actionWrapper}>
        <Box sx={profileCardStyle.action}>
          <Typographys variant="h4">{employeeProfileDetail.name}</Typographys>
          <Box sx={profileCardStyle.actionBtn}>
            <Button
              startIcon={<EditIcon fontSize="2.5rem" />}
              onClick={() => navigate(`/company/employee/${userId}/edit`)}
            >
              Edit
            </Button>
            <Box sx={{ ml: 1.5 }}>
              <DropdownMenu
                btnLabel="Actions"
                menuLists={[
                  { id: 1, label: 'Add New', onClick: () => {} },
                  { id: 2, label: 'Bulk Upload', onClick: () => {} },
                  { id: 3, label: 'Send ESS invite', onClick: () => {} },
                  { id: 4, label: 'Download', onClick: () => {} },
                  {
                    id: 5,
                    label: 'Confirm employee',
                    onClick: () => {
                      navigate(`/company/employee/confirm-employee/${userId}`);
                    },
                  },
                  {
                    id: 6,
                    label: 'Demote employee',
                    onClick: () => {
                      navigate(`/company/employee/demote-employee/${userId}`);
                    },
                  },
                  {
                    id: 7,
                    label: 'Promote employee',
                    onClick: () => {
                      navigate(`/company/employee/promote-employee/${userId}`);
                    },
                  },
                  {
                    id: 8,
                    label: 'Transfer employee',
                    onClick: () => {
                      navigate(`/company/employee/transfer-employee/${userId}`);
                    },
                  },
                  {
                    id: 9,
                    label: 'Terminate employee',
                    onClick: () => {
                      navigate(`/company/employee/terminate-employee/${userId}`);
                    },
                  },
                  {
                    id: 10,
                    label: 'Extend probation',
                    onClick: () => {
                      navigate(`/company/employee/extend-probation/${userId}`);
                    },
                  },
                  {
                    id: 11,
                    label: 'Verify background',
                    onClick: () => {
                      navigate(`/company/employee/verify-background/${userId}`);
                    },
                  },
                  {
                    id: 12,
                    label: 'Initiate PIP',
                    onClick: () => {
                      navigate(`/company/employee/initiate-pip/${userId}`);
                    },
                  },
                  {
                    id: 13,
                    label: 'Conclude PIP',
                    onClick: () => {
                      navigate(`/company/employee/conclude-pip/${userId}`);
                    },
                  },
                  {
                    id: 14,
                    label: 'Anonymise employee',
                    onClick: () => {
                      showAnonymiseEmployeeDailog();
                    },
                  },
                  {
                    id: 15,
                    label: 'Access Filters',
                    onClick: () => {
                      navigate(`/company/employee/access-filters/${userId}`);
                    },
                  },
                ]}
              />
            </Box>
          </Box>
        </Box>

        <Grid container sx={profileCardStyle.contactInfo}>
          <Grid item sm={6} md="auto">
            <IconWithTitle iconElement={<LocationOnIcon fontSize="inherit" />} title={basicDetails?.location} />
          </Grid>
          <Grid item sm={6} md="auto">
            <Link sx={{ textDecoration: 'none' }} href={`mailto:${contactDetails?.email}`}>
              <IconWithTitle iconElement={<EmailIcon fontSize="inherit" />} title={contactDetails?.email} />
            </Link>
          </Grid>
          <Grid item sm={6} md="auto">
            <Link sx={{ textDecoration: 'none' }} href={`tel:${contactDetails?.mobile}`}>
              <IconWithTitle iconElement={<CallIcon fontSize="inherit" />} title={contactDetails?.mobile} />
            </Link>
          </Grid>
          <Grid item sm={6} md="auto">
            <IconWithTitle iconElement={<BusinessCenterIcon fontSize="inherit" />} title={contactDetails?.phone} />
          </Grid>
        </Grid>

        <Grid container sx={profileCardStyle.employmentInfo}>
          <Grid item sm={4} md="auto">
            <TitleWithText padding="0" title="Designation" text={employeeProfileDetail?.employmentInfo?.designation} />
          </Grid>
          <Grid item sm={4} md="auto">
            <TitleWithText padding="0" title="Department" text={employeeProfileDetail?.employmentInfo?.department} />
          </Grid>
          <Grid item sm={4} md="auto">
            <TitleWithText padding="0" title="Supervisor" text={basicDetails?.supervisor} />
          </Grid>
          <Grid item sm={4} md="auto">
            <TitleWithText padding="0" title="Employee ID" text={employeeProfileDetail?.employmentInfo?.employeeID} />
          </Grid>
          <Grid item sm={4} md="auto">
            <TitleWithText padding="0" title="Employment type" text={basicDetails?.employmentType} />
          </Grid>
        </Grid>
        {anonymiseEmployee && (
          <AnonymiseEmployee
            open={anonymiseEmployee}
            handleClose={showAnonymiseEmployeeDailog}
            handleCancel={showAnonymiseEmployeeDailog}
            handleComment={addAnonymiseEmployee}
            onChange={notesValueChange}
          />
        )}
      </Box>
    </Box>
  );
};

EmployeeProfileCard.defaultProps = {
  employeeDetails: {
    'Basic Profile': {
      employeeBasicDetail: {
        location: '-',
        supervisor: '-',
        employmentType: '-',
      },
    },
    'Contact Details': {
      contactDetails: {
        location: '-',
        supervisor: '-',
        employmentType: '-',
      },
    },
    'Extended Attributes': {
      extendedAttribute: {
        designation: '-',
        department: '-',
      },
    },
    'Additional Details': {
      employmentHistory: {
        employeeID: '-',
      },
    },
  },
};

EmployeeProfileCard.propTypes = {
  employeeDetails: PropTypes.shape({
    'Basic Profile': PropTypes.shape({
      employeeBasicDetail: PropTypes.shape({
        location: PropTypes.string,
        supervisor: PropTypes.string,
        employmentType: PropTypes.string,
      }),
    }),
    'Contact Details': PropTypes.shape({
      contactDetails: PropTypes.shape({
        email: PropTypes.string,
        mobile: PropTypes.string,
        phone: PropTypes.string,
      }),
    }),
    'Extended Attributes': PropTypes.shape({
      extendedAttribute: PropTypes.shape({
        designation: PropTypes.string,
        department: PropTypes.string,
      }),
    }),
    'Additional Details': PropTypes.shape({
      employmentHistory: PropTypes.shape({
        employeeID: PropTypes.string,
      }),
    }),
  }),
};

export default EmployeeProfileCard;
