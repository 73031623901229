import React, { useState } from 'react';
import useToast from 'components/Provider/useToast';
import { Box, Skeleton, Typography } from '@mui/material';
import Form12BBUploadTemplate from 'components/Templates/MyPay/Form12BB/Upload';
import { fetchUploadMyForm12BB, uploadMyForm12BBAction } from 'api/home/myPay';
import useFetcher from 'hooks/useFetcher';
import useDownloadFile from 'hooks/useDownloadFile';

const Form12BBUploadPage = () => {
  const [loading, setLoading] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const addToast = useToast();
  const downloadFile = useDownloadFile();
  const [form12BBApiData] = useFetcher(fetchUploadMyForm12BB, refreshPage);
  const { employeeDeductionDeclarationInstance = {} } = form12BBApiData.data;

  const formData = {
    status: employeeDeductionDeclarationInstance?.form12BB?.status || 'Draft',
    id: employeeDeductionDeclarationInstance?.form12BB?.supportingDocument?.id || '',
    documentName: employeeDeductionDeclarationInstance?.form12BB?.supportingDocument?.documentName || '-',
  };

  const handleSubmit = async (values) => {
    const body = new FormData();
    body.append(`form12bb`, values?.attachments);

    try {
      setLoading(true);
      const response = await uploadMyForm12BBAction(body);
      if (response?.status === 'SUCCESS') {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.message || 'Uploaded document successfully',
        });
        setRefreshPage(!refreshPage);
      } else {
        addToast({ type: 'error', title: 'ERROR', message: response?.message || 'Failed to upload documents' });
      }
    } catch (err) {
      addToast({ type: 'error', title: 'ERROR', message: err?.message || 'Failed to upload documents' });
    } finally {
      setLoading(false);
    }
  };

  const handleAttachment = (docid, filename) => {
    downloadFile({ docid }, filename, 'restExpIndex/downloadAttachment');
  };

  if (form12BBApiData?.isLoading) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (form12BBApiData.error !== null) {
    return (
      <Typography variant="h6">
        {form12BBApiData?.error?.response?.data?.message || 'Error while loading upload form-12bb page.'}
      </Typography>
    );
  }

  return (
    <Form12BBUploadTemplate
      formData={formData}
      handleSubmit={handleSubmit}
      handleAttachment={handleAttachment}
      loading={loading}
    />
  );
};

export default Form12BBUploadPage;
