import React, { useEffect, useState } from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import PromoteEmployee from 'components/Organisms/Employee/EmployeeRecord/ActionList/PromoteEmployee';
import { useThunk } from 'hooks/useThunk';
import { fetchPromoteEmployee } from 'store';
import { Typography, Box, Skeleton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { promoteEmployeeAction } from 'api/employees';
import dayjs from 'dayjs';
import useToast from 'components/Provider/useToast';

const PromoteEmployeePage = () => {
  const addToast = useToast();
  const navigate = useNavigate();
  const { userId } = useParams();

  const [loading, setLoading] = useState(false);
  const [getPromoteEmployee, isLoadingPromoteEmployee, loadingPromoteEmployeeError] = useThunk(fetchPromoteEmployee);

  useEffect(() => {
    const query = new URLSearchParams({});
    query.append('id', userId);
    getPromoteEmployee(query);
  }, [userId, getPromoteEmployee]);

  const { promoteEmployee } = useSelector((state) => state.employees);

  const employeeData = {
    supervisor: promoteEmployee?.employeeInstance?.supervisor?.fullName,
    department: promoteEmployee?.employeeInstance?.department?.name,
    designation: promoteEmployee?.employeeInstance?.designation?.name,
    location: promoteEmployee?.employeeInstance?.location?.name,
  };

  const supervisorOptions = promoteEmployee?.employeeList;
  const departmentOptions = promoteEmployee?.departmentsList;
  const designationOptions = promoteEmployee?.designationsList;
  const locationOptions = promoteEmployee?.locationsList;
  const userData = promoteEmployee?.user;

  const handleSubmit = async ({ effectiveStartDate, supervisor, department, designation, location, notes }) => {
    const newStartDate = effectiveStartDate ? dayjs(effectiveStartDate).format('MM/DD/YYYY') : '';
    const newNotes = notes || '';
    const bodyFormData = new FormData();
    bodyFormData.append('empID', promoteEmployee?.user?.employeeId);
    bodyFormData.append('CurrentAction', 'SaveAndSubmit');
    bodyFormData.append('effectiveStartDate', newStartDate);
    bodyFormData.append('employee.supervisor.id', supervisor);
    bodyFormData.append('employee.department.id', department);
    bodyFormData.append('employee.designation.id', designation);
    bodyFormData.append('employee.location.id', location);
    bodyFormData.append('notes', newNotes);

    try {
      setLoading(true);
      const response = await promoteEmployeeAction(bodyFormData);
      if (response?.data?.status === 'SUCCESS') {
        addToast({ type: 'success', title: 'SUCCESS', message: response?.data?.message });
        navigate(`/company/employee/view/${userId}`);
      } else {
        addToast({ type: 'error', title: 'ERROR', message: response?.data?.message });
      }
    } catch (error) {
      addToast({ type: 'error', title: 'ERROR', message: error?.response?.data?.message || error?.message });
    } finally {
      setLoading(false);
    }
  };

  if (loadingPromoteEmployeeError !== null) {
    return (
      <Typography variant="h6">
        {loadingPromoteEmployeeError?.message || `Error while loading current details for employee id : ${userId}.`}
      </Typography>
    );
  }

  const breadcrumbData = [
    { id: 1, name: 'Employee List', path: '/company/employee/employees-list', isActive: false },
    { id: 2, name: 'Employee Records', path: `/company/employee/view/${userId}`, isActive: false },
    { id: 2, name: 'Promote Employee', isActive: true },
  ];

  return isLoadingPromoteEmployee ? (
    <Box sx={{ p: 4 }}>
      <Skeleton height={70} />
      <Skeleton />
      <Skeleton />
    </Box>
  ) : (
    <BlankTemplate
      pageHeading={`Promote Employee: ${get(promoteEmployee, 'employeeInstance.fullName', '')} (${get(
        promoteEmployee,
        'employeeInstance.profile.employeeId',
        'NA',
      )})`}
      breadcrumbData={breadcrumbData}
      padding="3rem"
    >
      <PromoteEmployee
        employeeData={employeeData}
        supervisorOptions={supervisorOptions}
        departmentOptions={departmentOptions}
        designationOptions={designationOptions}
        locationOptions={locationOptions}
        userData={userData}
        handleSubmit={handleSubmit}
        loading={loading}
        btnLabel="Promote"
      />
    </BlankTemplate>
  );
};

export default PromoteEmployeePage;
