import PropTypes from 'prop-types';
import { Box, Button, Grid, MenuItem, Typography } from '@mui/material';
import React from 'react';
import { Plus as PlusIcon } from 'assets/icons/plus';
import DatePickers from '../../Atoms/Datepicker';
import InputField from '../../Atoms/InputField';

export const FilterDialogItem = (props) => {
  const { properties, filter, index, onAddFilter, onRemoveFilter, onPropertyChange, onValueChange } = props;

  return (
    <Box>
      <Typography color="textSecondary" sx={{ pb: 2 }} variant="caption">
        Where
      </Typography>
      <Grid container spacing={2} sx={{ pb: 2, pt: 2 }}>
        <Grid item xs={6}>
          <InputField
            fullWidth
            onChange={(event) => onPropertyChange(index, event.target.value)}
            select
            value={filter.property.name}
          >
            {properties.map((property) => (
              <MenuItem key={property.name} value={property.name}>
                {property.label}
              </MenuItem>
            ))}
          </InputField>
        </Grid>
        {!filter.operator.typesAccepted.includes('blank') &&
          (filter.operator.typesAccepted.includes('date') ? (
            <Grid item xs={6}>
              <DatePickers fullWidth onChange={(date) => onValueChange(index, date)} value={filter.value} />
            </Grid>
          ) : (
            <Grid item xs={6}>
              <InputField
                fullWidth
                onChange={(event) => onValueChange(index, event.target.value)}
                value={filter.value}
              />
            </Grid>
          ))}
      </Grid>
      <Button
        color="primary"
        onClick={() => onAddFilter(index + 1)}
        size="small"
        startIcon={<PlusIcon />}
        sx={{ mr: 2 }}
        variant="text"
      >
        Add Filter
      </Button>
      <Button onClick={() => onRemoveFilter(index)} size="small" sx={{ color: 'text.secondary' }} variant="text">
        Remove
      </Button>
    </Box>
  );
};

FilterDialogItem.propTypes = {
  properties: PropTypes.instanceOf(Array).isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  onAddFilter: PropTypes.func.isRequired,
  onRemoveFilter: PropTypes.func.isRequired,
  onPropertyChange: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
};
