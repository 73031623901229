import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import NoRecordsMessage from 'components/Organisms/CommonPageUtils/NoRecordsFound';
import LeaveAccount from '../LeaveAccount';
import FetchDetailsForm from '../FetchDetailsForm';
import RequestList from '../RequestList';

const TeamLeaveRequests = ({
  columns,
  rows,
  page,
  handleSelect,
  roleBasedMenu,
  leaveAccountData,
  handleSubmit,
  leaveCategoryOptions,
  leaveRequestStateOptions,
  hierarchyLevelOptions,
  showList,
  calendarYear,
  showActionList,
  loading,
  handleRowClick,
  showPagination,
  onPageChange,
  count,
}) => {
  const [isClicked, setIsClicked] = useState(false);
  const theme = useTheme();

  const handleClick = () => {
    setIsClicked(true);
  };

  return (
    <Box>
      {!!leaveAccountData?.length && (
        <Box sx={{ mb: 1 }}>
          <LeaveAccount leaveAccountData={leaveAccountData} calendarYear={calendarYear} />
        </Box>
      )}
      <FetchDetailsForm
        handleSubmit={handleSubmit}
        leaveCategoryOptions={leaveCategoryOptions}
        leaveRequestStateOptions={leaveRequestStateOptions}
        hierarchyLevelOptions={hierarchyLevelOptions}
        handleClick={handleClick}
      />
      {!!showList && (
        <>
          <Divider
            sx={{
              my: 2,
              backgroundColor: theme.palette.neutral[200],
            }}
          />
          <RequestList
            showActionList={showActionList}
            rows={rows}
            columns={columns}
            page={page}
            handleSelect={handleSelect}
            roleBasedMenu={roleBasedMenu}
            handleRowClick={handleRowClick}
            showPagination={showPagination}
            onPageChange={onPageChange}
            count={count}
            loading={loading}
          />
        </>
      )}
      {isClicked && rows.length === 0 && !loading && <NoRecordsMessage width="20rem" height="20rem" />}
    </Box>
  );
};

TeamLeaveRequests.defaultProps = {
  roleBasedMenu: () => {},
  rows: [],
  columns: [],
  handleSelect: () => {},
  page: '',
  leaveAccountData: [],
  handleSubmit: () => {},
  leaveCategoryOptions: [],
  leaveRequestStateOptions: [],
  hierarchyLevelOptions: [],
  showList: false,
  calendarYear: '',
  showActionList: true,
  loading: true,
  handleRowClick: () => {},
  showPagination: false,
  onPageChange: () => {},
  count: 0,
};

TeamLeaveRequests.propTypes = {
  showActionList: PropTypes.bool,
  roleBasedMenu: PropTypes.func,
  handleSelect: PropTypes.func,
  page: PropTypes.number,
  rows: PropTypes.arrayOf,
  leaveAccountData: PropTypes.arrayOf(
    PropTypes.shape({
      balance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  handleSubmit: PropTypes.func,
  leaveCategoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  leaveRequestStateOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  hierarchyLevelOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  showList: PropTypes.bool,
  calendarYear: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      numeric: PropTypes.bool,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),
  loading: PropTypes.bool,
  handleRowClick: PropTypes.func,
  showPagination: PropTypes.bool,
  count: PropTypes.number,
  onPageChange: PropTypes.func,
};
export default TeamLeaveRequests;
