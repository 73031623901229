export const buttonStyle = {
  actionBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  mainContainer: {
    marginBottom: '100px',
  },

  formContainer: {
    width: '100%',
    maxWidth: '100%',
    mt: 0,
    mb: 2.5,
  },

  formDivider: {
    '&:not(:first-child)': {
      borderTop: '0.125rem solid',
      borderColor: 'other.200',
      mt: 4,
      pt: 4,
    },
  },

  divider: {
    '&:not(:first-child)': {
      borderTop: '0.125rem solid',
      borderColor: 'other.200',
    },
  },

  addButton: {
    my: 3,
  },

  btnContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%',
    backgroundColor: 'common.white',
    zIndex: '9',
    margin: '0rem',
    padding: '0rem 3rem 2rem 3rem',
    boxSizing: 'border-box',
  },

  submitWrapper: {
    display: 'flex',
    columnGap: '2rem',
    justifyContent: 'flex-end',
  },

  addressDiv: {
    display: 'inline',
    mr: '0.5rem',
  },
};

export const addEmpStyle = {
  addWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: 2,
    my: 2,
  },

  submitWrapper: {
    display: 'flex',
    justifyContent: 'end',
    gap: 2,
  },

  btnContainer: {
    position: 'fixed',
    right: '0rem',
    bottom: '3.625rem',
    backgroundColor: 'common.white',
    zIndex: '9',
    margin: '0rem 3.25rem',
    width: 'calc(100% - 49.5rem)',
    padding: '0rem 3rem 3.25rem 3rem',
  },

  divWrapper: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '2rem',
  },

  formContainer: {
    width: '100%',
    maxWidth: '100%',
  },
};

export const separationStyle = {
  buttonWrapper: {
    position: 'fixed',
    right: '0rem',
    bottom: '3.625rem',
    backgroundColor: 'common.white',
    zIndex: '9',
    margin: '0rem 3.25rem',
    width: 'calc(100% - 49.5rem)',
    padding: '0rem 3rem 3.25rem 3rem',
  },

  Divider: {
    my: 2,
    backgroundColor: 'neutral.200',
  },

  flexWrapper: {
    display: 'flex',
    justifyContent: 'end',
    gap: 2,
  },

  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '4rem',
  },

  moreIcon: {
    cursor: 'pointer',
  },
};

export const affiliatedEmployeesStyle = {
  wrap: {
    width: '47.5rem',
  },
  alertBox: {
    position: 'fixed',
    right: '0',
    top: '9rem',
    width: '42.25rem',
    transition: 'ease-in-out .3s all',
  },
  button: {
    marginTop: '2rem',
  },
};
