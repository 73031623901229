import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMyLeaveList } from 'store';
import { useThunk } from 'hooks/useThunk';
import useController from 'hooks/useController';
import usePaginationAttributes from 'hooks/use-pagination-attributes';
import Dialog from 'components/Molecules/Dialog';
import { formDataApi } from 'api/api';
import { Typography, Skeleton, Box } from '@mui/material';

import { updateSelectedLeave, updateMyTeamLeave } from 'store/slices/leaves/myLeaves';
import TeamLeavesTemplate from 'components/Templates/Leave/TeamLeaves';
import { isArray, isEmpty } from 'lodash';
import dayjs from 'dayjs';
import useToast from 'components/Provider/useToast';
import useColumns from './useColumns';
import useActionItems from './useActionItems';
import useLeaveServices from './useLeaveServices';
import SubmitDialog from './SubmitDialog';

const TeamLeavesList = () => {
  const dispatch = useDispatch();
  const { pageSize } = usePaginationAttributes();
  const [columns] = useColumns();
  const [preFormData, setPreFormData] = useState({
    data: {},
    isLoading: false,
  });
  const [loadingMyTeamLeavesAction, setLoadingMyTeamLeavesAction] = useState(true);
  const [controller, handlePageChange, handleSortChange, handleFiltersApply, handleFiltersClear] = useController();
  const [doFetchMyLeave, isLoadingMyLeaves, loadingLeavesError] = useThunk(fetchMyLeaveList);
  const { selectedMyLeave, selectedMyLeaves, myTeamLeaves, loading } = useSelector((state) => state?.myLeaves);
  const addToast = useToast();

  const fetchLeavesAction = useCallback(() => {
    const query = new URLSearchParams({});
    if (controller) {
      query.append('sort', controller.sortBy);
      query.append('order', controller.sort);
    }
    query.append('offset', controller.page * pageSize);
    query.append('max', pageSize);
    doFetchMyLeave(query);
  }, [doFetchMyLeave, controller, pageSize]);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(updateMyTeamLeave({}));
      setPreFormData({ ...preFormData, isLoading: true });
      setLoadingMyTeamLeavesAction(false);
      const url = 'restLeaveIndex/listMyTeamLeaveRequests';
      try {
        const response = await formDataApi.get(url);
        if (response?.data) {
          const modifiedData = {
            leaveCategoryOptions: response?.data?.leaveCategories.map((data) => ({
              id: data.id,
              name: data.name,
            })),
            leaveRequestStateOptions: Object.values(response?.data?.leaveRequestStates).map((data) => ({
              id: data,
              name: data,
            })),
            hierarchyLevelOptions: response?.data?.hierarchyLevel.map((data) => ({
              id: data,
              name: data,
            })),
          };
          setPreFormData(modifiedData);
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const getTeamLeaves = async (data) => {
    const bodyObj = {
      startDate: data.startDate ? dayjs(data.startDate).format('MM/DD/YYYY') : '',
      endDate: data.endDate ? dayjs(data.endDate).format('MM/DD/YYYY') : '',
      requestState: data.leaveRequestStates,
      hierarchLevel: data.hierarchyLevel,
      leaveCategoryIDs: data.leaveCategory,
    };

    const body = new FormData();

    Object.keys(bodyObj).forEach((key) => {
      if (bodyObj[key] && !(isArray(bodyObj[key]) && isEmpty(bodyObj[key]))) {
        body.append(key, bodyObj[key]);
      }
    });
    setLoadingMyTeamLeavesAction(true);
    const url = 'restLeaveIndex/listMyTeamLeaveRequestsAction';
    const response = await formDataApi.post(url, body);
    setLoadingMyTeamLeavesAction(false);
    if (response?.data?.leaveRequestInstanceCount === 0) {
      addToast({
        type: 'success',
        title: 'SUCCESS',
        message: 'No records exist',
      });
    }
    dispatch(updateMyTeamLeave(response.data));
  };

  const leaveServices = useLeaveServices(fetchLeavesAction);
  const [menuList] = useActionItems(leaveServices);
  const handleCloseDialog = () => leaveServices.dispatchDialog({ type: 'Close' });

  if (isLoadingMyLeaves) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (loadingLeavesError !== null) {
    return <Typography variant="h6">{loadingLeavesError?.message || 'Error while loading leaves list.'}</Typography>;
  }

  return (
    <>
      <TeamLeavesTemplate
        leaveCategoryOptions={preFormData?.leaveCategoryOptions}
        leaveRequestStateOptions={preFormData?.leaveRequestStateOptions}
        hierarchyLevelOptions={preFormData?.hierarchyLevelOptions}
        handleSubmit={getTeamLeaves}
        showList={myTeamLeaves?.data?.length}
        rows={myTeamLeaves?.data}
        columns={columns}
        rowsPerPage={100}
        roleBasedMenu={menuList}
        totalRecordCount={myTeamLeaves?.count}
        loadNextPageData={handlePageChange}
        handleSort={handleSortChange}
        page={controller.page}
        sortDirection={controller.sort}
        sortBy={controller.sortBy}
        showActionList
        pageHeading="Team Leave Requests"
        handleSelect={(row) => {
          dispatch(updateSelectedLeave(row));
        }}
        handleRowClick={(row) => leaveServices.viewLeave(row)}
        loading={loadingMyTeamLeavesAction}
      />
      {selectedMyLeave !== null && (
        <>
          <Dialog
            open={leaveServices.dialogState.open === 'Delete'}
            dialogTitle="Delete Leave"
            dialogDesc={`Are you sure you want to delete ${selectedMyLeave?.leaveType}?`}
            secondaryBtn="Cancel"
            primaryBtn="Delete"
            primaryBtnColor="error"
            handlePrimaryBtn={() => leaveServices.deleteLeave(selectedMyLeave?.id, false)}
            bodyContent={false}
            handleSecondaryBtn={handleCloseDialog}
            handleClose={handleCloseDialog}
          />
          <SubmitDialog
            handleCancel={handleCloseDialog}
            open={leaveServices.dialogState.open === 'Submit'}
            name={selectedMyLeave?.leaveType}
            handleSubmit={() => leaveServices.submitLeave({ id: selectedMyLeave?.id, refresh: true })}
          />
          <SubmitDialog
            handleCancel={handleCloseDialog}
            open={leaveServices.dialogState.open === 'Approve'}
            name={selectedMyLeave?.leaveType}
            handleSubmit={({ note }) => leaveServices.approveLeave({ id: selectedMyLeave?.id, note, refresh: true })}
            title="Approve Leave"
            action="Approve"
            primaryButtonLabel="Approve"
          />
          <SubmitDialog
            handleCancel={handleCloseDialog}
            open={leaveServices.dialogState.open === 'Reject'}
            name={selectedMyLeave?.leaveType}
            handleSubmit={({ note }) => leaveServices.rejectLeave({ id: selectedMyLeave?.id, note, refresh: true })}
            title="Reject Leave"
            action="Reject"
            primaryButtonLabel="Reject"
          />
          <SubmitDialog
            handleCancel={handleCloseDialog}
            open={leaveServices.dialogState.open === 'Revert'}
            name={selectedMyLeave?.leaveType}
            handleSubmit={({ note }) => leaveServices.revertLeave({ id: selectedMyLeave?.id, note, refresh: true })}
            title="Revert Leave"
            action="Revert"
            primaryButtonLabel="Revert"
          />
        </>
      )}
    </>
  );
};

export default TeamLeavesList;
