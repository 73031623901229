import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Typographys from 'components/Atoms/Typography';
import TitleWithText from 'components/Atoms/TitleWithText';

const EmployeeDetailCard = ({ employeeData }) => {
  const theme = useTheme();
  return (
    <Box sx={{ background: theme.palette.actions[100], padding: '2rem 3rem', borderRadius: '0.75rem' }}>
      <Grid container columnSpacing={1} rowSpacing={1}>
        <Grid item xs={12} md={12} sx={{ mb: '2rem' }}>
          <Typographys variant="subtitle1" color="neutral.800">
            Current Details
          </Typographys>
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          <TitleWithText padding="0" title="Supervisor" text={employeeData.supervisor} />
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          <TitleWithText padding="0" title="Department" text={employeeData.department} />
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          <TitleWithText padding="0" title="Designation" text={employeeData.designation} />
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          <TitleWithText padding="0" title="Location" text={employeeData.location} />
        </Grid>
      </Grid>
    </Box>
  );
};

EmployeeDetailCard.defaultProps = {
  employeeData: {},
};

EmployeeDetailCard.propTypes = {
  employeeData: PropTypes.shape(),
};

export default EmployeeDetailCard;
