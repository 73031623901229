import React from 'react';
import { Box, Grid, Divider, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import Button from 'components/Atoms/Button';
import Typographys from 'components/Atoms/Typography';
import InputField from 'components/Atoms/InputField';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import { FieldArray, getIn } from 'formik';
import { profileStyle } from '../../../style';

const ContactDetails = ({ propData }) => {
  return (
    <Box mt={1.5}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 38rem)' }}>
          <Form formik={propData.formik} />
        </Box>
      </PerfectScrollbar>
    </Box>
  );
};

const Form = ({ formik }) => {
  const theme = useTheme();

  return (
    <Box px={3}>
      <Grid container rowSpacing={2} columnSpacing={9} pt={0} mt={0} sx={profileStyle.formContainer}>
        <FieldArray name="mobileNumber">
          {({ push, remove }) => (
            <Grid item xs={12} md={4}>
              {formik.values.mobileNumber.map((m, index) => (
                <Box sx={profileStyle.contactWrapper}>
                  <InputField
                    error={Boolean(
                      getIn(formik.touched, `mobileNumber[${index}]`) && getIn(formik.errors, `mobileNumber[${index}]`),
                    )}
                    helperText={
                      getIn(formik.touched, `mobileNumber[${index}]`) && getIn(formik.errors, `mobileNumber[${index}]`)
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={m}
                    name={`mobileNumber[${index}]`}
                    id={`mobileNumber[${index}]`}
                    label={`Mobile Number ${index + 1} ${index === 0 ? '(Primary)' : ''}`}
                    type="Number"
                    fullWidth
                    sx={{ marginBottom: '1.5rem' }}
                  />
                  <Box sx={profileStyle.deleteBox} onClick={() => remove(index)}>
                    <DeleteOutlineSharpIcon sx={{ color: 'neutral.600' }} />
                  </Box>
                </Box>
              ))}
              <Box>
                <Button
                  variant="outlined"
                  size="medium"
                  type="button"
                  startIcon={<AddOutlinedIcon />}
                  onClick={() => push('')}
                >
                  Add
                </Button>
              </Box>
            </Grid>
          )}
        </FieldArray>
        <FieldArray name="emailAddress">
          {({ push, remove }) => (
            <Grid item xs={12} md={4}>
              {formik.values.emailAddress.map((e, index) => (
                <Box sx={profileStyle.contactWrapper}>
                  <InputField
                    error={Boolean(
                      getIn(formik.touched, `emailAddress[${index}]`) && getIn(formik.errors, `emailAddress[${index}]`),
                    )}
                    helperText={
                      getIn(formik.touched, `emailAddress[${index}]`) && getIn(formik.errors, `emailAddress[${index}]`)
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={e}
                    name={`emailAddress[${index}]`}
                    id={`emailAddress[${index}]`}
                    label={`Email ID ${index + 1} ${index === 0 ? '(Primary)' : ''}`}
                    fullWidth
                    sx={{ marginBottom: '1.5rem' }}
                  />
                  <Box sx={profileStyle.deleteBox} onClick={() => remove(index)}>
                    <DeleteOutlineSharpIcon sx={{ color: 'neutral.600' }} />
                  </Box>
                </Box>
              ))}

              <Box>
                <Button
                  variant="outlined"
                  size="medium"
                  type="button"
                  startIcon={<AddOutlinedIcon />}
                  onClick={() => push('')}
                >
                  Add
                </Button>
              </Box>
            </Grid>
          )}
        </FieldArray>
      </Grid>
    </Box>
  );
};

Form.defaultProps = {
  formik: {},
};

Form.propTypes = {
  formik: PropTypes.shape({
    errors: PropTypes.shape({
      name: PropTypes.string,
      relation: PropTypes.string,
      emergencyMobile: PropTypes.string,
      emergencyEmail: PropTypes.string,
    }),

    touched: PropTypes.shape({
      name: PropTypes.string,
      relation: PropTypes.string,
      emergencyMobile: PropTypes.string,
      emergencyEmail: PropTypes.string,
    }),

    values: PropTypes.shape({
      mobileNumber: PropTypes.shape([]),
      emailAddress: PropTypes.shape([]),
      name: PropTypes.string,
      relation: PropTypes.string,
      emergencyMobile: PropTypes.string,
      emergencyEmail: PropTypes.string,
    }),
    resetForm: PropTypes.func,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
  }),
};

ContactDetails.defaultProps = {
  propData: {},
};

ContactDetails.propTypes = {
  propData: PropTypes.shape({
    formik: PropTypes.shape({
      errors: PropTypes.shape({
        name: PropTypes.string,
        relation: PropTypes.string,
        emergencyMobile: PropTypes.string,
        emergencyEmail: PropTypes.string,
      }),

      touched: PropTypes.shape({
        name: PropTypes.string,
        relation: PropTypes.string,
        emergencyMobile: PropTypes.string,
        emergencyEmail: PropTypes.string,
      }),

      values: PropTypes.shape({
        mobileNumber: PropTypes.shape([]),
        emailAddress: PropTypes.shape([]),
        name: PropTypes.string,
        relation: PropTypes.string,
        emergencyMobile: PropTypes.string,
        emergencyEmail: PropTypes.string,
      }),
      resetForm: PropTypes.func,
      handleBlur: PropTypes.func,
      handleChange: PropTypes.func,
      handleSubmit: PropTypes.func,
    }),
  }),
};

export default ContactDetails;
