/* eslint-disable no-unsafe-optional-chaining */
import React from 'react';
import { Box, Grid, Divider, useTheme } from '@mui/material';
import TitleWithText from 'components/Atoms/TitleWithText';
import Typographys from 'components/Atoms/Typography';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import IconWithTitle from 'components/Atoms/IconWithTitle';
import Button from 'components/Atoms/Button';
import { onBoardingStyle } from '../../style';

const VisaDetail = (details, handleDownloadFile, handleAttachment) => {
  const theme = useTheme();
  const { visaDetails = {}, passportDetails = {} } = details?.visaData;
  return (
    <Box>
      <Typographys variant="h5" color="neutral.700">
        Visa/Passport Details
      </Typographys>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 44rem)' }}>
          <Box mt={4}>
            {passportDetails.map((list) => (
              <>
                <Box my={2}>
                  <Typographys variant="h6" color="neutral.700">
                    Passport Details
                  </Typographys>
                </Box>

                <Grid container rowSpacing={1} columnSpacing={2} mt={0}>
                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Passport Number" text={list?.passportNumber} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Passport Issue Date" text={list?.passportIssueDate} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Passport Expiry Date" text={list?.passportExpiryDate} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Passport Place Of Issue" text={list?.passportIssuePlace} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Passport Country" text={list?.passportCountry} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Passport Name Given" text={list?.passportGivenName} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typographys variant="h6" color="neutral.600">
                      Attachment
                    </Typographys>

                    <Box sx={{ mt: 1 }}>
                      <IconWithTitle
                        onTextClick={handleDownloadFile}
                        isClickable
                        iconElement={<AttachFileIcon fontSize="inherit" />}
                        iconColor="secondary.600"
                        iconFontSize="2.5rem"
                        title={list.attachments}
                        titleColor="secondary.600"
                        titleVariant="body1"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </>
            ))}

            <Divider
              sx={{
                my: 2.5,
                backgroundColor: theme.palette.neutral[200],
              }}
            />
            <Box my={2}>
              <Typographys variant="h6" color="neutral.700">
                Visa Details
              </Typographys>
            </Box>
            {visaDetails.map((visaList, i) => (
              <>
                <Typographys variant="subtitle2" align="left" color="neutral.800">
                  Record {i + 1}
                </Typographys>
                <Grid container rowSpacing={1} columnSpacing={2} mt={0}>
                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Issuing Country" text={visaList?.issuingCountry} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Place of Issue" text={visaList?.placeOfIssue} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Valid From" text={visaList?.validFrom} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Valid To" text={visaList?.validTo} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Issue Date" text={visaList?.issueDate} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Reference Number" text={visaList?.referenceNumber} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Visa Status" text={visaList?.visaStatus} />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TitleWithText title="Comments" text={visaList?.comments} />
                  </Grid>

                  <Grid item xs={12}>
                    <Typographys variant="h6" color="neutral.600">
                      Attachment
                    </Typographys>

                    <Button
                      variant="text"
                      color="secondary"
                      onClick={() => handleAttachment(visaList?.attachmentId, visaList?.attachment)}
                      startIcon={<AttachFileIcon />}
                    >
                      {visaList?.attachment || ''}
                    </Button>
                  </Grid>
                </Grid>
                {i + 1 !== visaDetails.length && <Divider sx={onBoardingStyle.divider} />}
              </>
            ))}
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );
};

export default VisaDetail;
