import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, useTheme } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import ActionDropDown from 'components/Molecules/ActionDropDown';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import RangePicker from 'components/Molecules/DateRangePicker';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { validateDateElseHyphen } from 'utils/commonUtils';
import { teamAttendanceStyle } from '../style';

const RenderDateRangePicker = (handleSelect) => {
  return (
    <RangePicker
      handleSubmit={(data) => handleSelect(data)}
      placement="right-start"
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typographys color="inherit" variant="body2" fontWeight={500}>
            Custom Date
          </Typographys>
          <ArrowForwardIosIcon sx={{ fontSize: '1.5rem' }} />
        </Box>
      }
    />
  );
};
const TeamAttendanceStatus = ({
  ariaLabel,
  statusHandleChange,
  isStatusUp,
  attendanceStatus,
  handleFilterDateChange,
}) => {
  const theme = useTheme();
  const statusIcon = isStatusUp ? (
    <ArrowUpwardIcon style={{ width: '1.75rem', color: theme.palette.success[300] }} />
  ) : (
    <ArrowDownwardIcon style={{ width: '1.75rem', color: theme.palette.error[300] }} />
  );
  const [filterLabel, setFilterLabel] = useState('This Week');

  const onDateChange = (data) => {
    setFilterLabel(
      `${validateDateElseHyphen(data.startDate, 'DD MMMM YYYY')} to ${validateDateElseHyphen(
        data.endDate,
        'DD MMMM YYYY',
      )}`,
    );
    handleFilterDateChange(data);
  };

  const statusOptions = [
    { id: 1, name: 'This Week', onClick: () => statusHandleChange('This Week') },
    { id: 2, name: 'This Month', onClick: () => statusHandleChange('This Month') },
    { id: 3, name: 'This Year', onClick: () => statusHandleChange('This Year') },
    {
      id: 4,
      name: 'Custom Date',
      onClick: () => statusHandleChange('custom'),
      formatValue: () => RenderDateRangePicker(onDateChange),
    },
  ];

  return (
    <Box sx={teamAttendanceStyle.cardWrapper}>
      <Box sx={teamAttendanceStyle.cardHeader}>
        <Typographys variant="h6">Team Attendance Stats</Typographys>
        <Box sx={teamAttendanceStyle.cardHeaderDropdown}>
          <ActionDropDown ariaLabel={ariaLabel} label={filterLabel} options={statusOptions} />
        </Box>
      </Box>
      <Box sx={teamAttendanceStyle.cardBody}>
        <Box sx={teamAttendanceStyle.statusCard}>
          <Typographys variant="overline" color="neutral.600">
            Avg hrs/day
          </Typographys>
          <Typographys variant="h6" color="neutral.800">
            {attendanceStatus.avgHrPerDay}
          </Typographys>
          <Box sx={teamAttendanceStyle.statusCardIconTitle}>
            {statusIcon}
            <Typographys variant="caption" color="neutral.600">
              {attendanceStatus.avgHrPerDayDetails}
            </Typographys>
          </Box>
        </Box>
        <Box sx={teamAttendanceStyle.statusCard}>
          <Typographys variant="overline" color="neutral.600">
            On time arrival
          </Typographys>
          <Typographys variant="h6" color="neutral.800">
            {attendanceStatus.onTimeArrival}
          </Typographys>
          <Box sx={teamAttendanceStyle.statusCardIconTitle}>
            {statusIcon}
            <Typographys variant="caption" color="neutral.600">
              {attendanceStatus.onTimeArrivalDetails}
            </Typographys>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

TeamAttendanceStatus.defaultProps = {
  ariaLabel: '',
  statusHandleChange: () => {},
  attendanceStatus: {},
  isStatusUp: true,
  handleFilterDateChange: () => {},
};
TeamAttendanceStatus.propTypes = {
  ariaLabel: PropTypes.string,
  statusHandleChange: PropTypes.func,
  attendanceStatus: PropTypes.shape({
    avgHrPerDay: PropTypes.string,
    avgHrPerDayDetails: PropTypes.string,
    onTimeArrival: PropTypes.string,
    onTimeArrivalDetails: PropTypes.string,
  }),
  isStatusUp: PropTypes.bool,
  handleFilterDateChange: PropTypes.func,
};

export default TeamAttendanceStatus;
