export const actionPagesStyle = {
  wrapper: {
    width: 1,
    maxWidth: '80rem',
    display: 'flex',
    margin: 'auto',
    justifyContent: 'center',
    marginTop: '20rem',
    marginBottom: '20rem',
    alignItems: 'center',
    boxShadow:
      'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset',
    borderRadius: '0.5rem',
    padding: '8rem',
  },
  formWrapperIcon: {
    width: 1,
    maxWidth: '44.375rem',
    margin: 'auto',
    padding: '3rem',
  },
  formWrapperText: {
    width: 1,
    maxWidth: '44.375rem',
    margin: 'auto',
    mt: 6.5,
  },
  formbutton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    my: 4.5,
  },
  formtext: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 0.5,
  },
};
