import React from 'react';
import CommonCard from 'components/Molecules/CommonCard';
import { Box } from '@mui/material';
import LineCharts from 'components/Organisms/Charts/LineCharts';
import dayjs from 'dayjs';
import useFetchExpenseSummary from './useFetchExpenseSummary';

const TotalExpenseGraph = ({ chartOptions, isTeam }) => {
  const [{ data }] = useFetchExpenseSummary();
  const graphData = data?.chartData?.length
    ? data?.chartData.map((item) => ({ time: dayjs(item[0]).format('DD MMM YYYY'), value: item[1] })).reverse()
    : [];
  const XAxisData = {
    dataKey: 'time',
    value: 'Total Expenses',
    position: 'right',
  };

  const YAxisData = {
    value: 'Amount',
    position: 'top',
  };

  const infoData = [
    {
      name: 'Total Expenses',
      dataKey: 'value',
      type: 'monotone',
      color: 'secondary',
      colorCode: 400,
    },
  ];

  return isTeam ? (
    <Box sx={{ mt: 2 }}>
      <CommonCard
        cardHeaderTitle="Expenses Report Summary"
        isHeaderFilter={false}
        minWidthHeaderDropdown="17rem"
        bodyContent={
          <LineCharts
            chartOptions={chartOptions}
            chartData={graphData}
            lineData={infoData}
            legendOptions={{
              layout: 'horizontal',
              verticalAlign: 'bottom',
              align: 'right',
              legendLeftSpacing: '12rem',
            }}
            XAxisData={XAxisData}
            YAxisData={YAxisData}
          />
        }
        isFooter={false}
        cardBodyMinHeight="32rem"
        cardBodyPadding="5rem 0rem 1rem 1rem"
      />
    </Box>
  ) : (
    <div />
  );
};

export default TotalExpenseGraph;
