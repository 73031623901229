const getToastMessage = {
  ProfileBankAccount: () => {
    return ['Bank Account Updated Successfully', 'Failed To Update Bank Account'];
  },
  MyPayTds: () => {
    return ['Income Tax Details Edited Successfully', 'Failed To Edit Income Tax Details '];
  },
  PerformanceSetting: () => {
    return ['Performance Setting Updated Successfully', 'Failed To Update Performance Setting'];
  },
  InitiateSeparation: (name) => {
    return [`Employee Exit Initiated Successfully For ${name}`, `Failed To Initiate Employee Exit For ${name}`];
  },
  MyLeaves: (isEdit = false, toastMessageType = '') => {
    if (toastMessageType === 'draft') {
      return ['Leave Request Saved to Draft Successfully', 'Failed To Save Leave Request To Draft '];
    }
    if (toastMessageType === 'delete') {
      return ['New Leave Request Deleted Successfully', 'Failed To Delete New Leave Request'];
    }
    if (isEdit) {
      return ['New Leave Request Updated Successfully', 'Failed To Update New Leave Request'];
    }
    return ['New Leave Request Added Successfully', 'Failed To Add New Leave Request'];
  },
  TeamLeaves: (isApprove = false) => {
    if (isApprove) {
      return ['Leave Request Approved Successfully', 'Failed To Approve Leave Request'];
    }
    return ['Leave Request Rejected successfully', 'Failed To Reject Leave Request'];
  },
  Documents: (name, isEdit = false) => {
    if (isEdit) {
      return [`${name} Updated Successfully`, `Failed To Update ${name}`];
    }
    return [`${name} Added Successfully`, `Failed To Add ${name}`];
  },
  MyExpenses: (isEdit = false, toastMessageType = '') => {
    if (toastMessageType === 'draft') {
      return ['Expense Report Saved to Draft Successfully', 'Failed To Save Expense Report To Draft'];
    }
    if (isEdit) {
      return ['Expense Report Updated Successfully', 'Failed To Update Expense Report'];
    }
    return ['Expense Report Added Successfully', 'Failed To Add Expense Report'];
  },
  UploadInvestmentProofs: () => {
    return ['Investment Proof Uploaded Successfully', 'Failed To Update Investment Proof'];
  },
  AddEmployee: () => {
    return ['Employee Added Successfully', 'Failed To Add Employee'];
  },
  PayStructure: (isEdit = false, toastMessageType = '') => {
    if (toastMessageType === 'delete') {
      return ['Pay Structure Deleted Successfully', 'Failed To Delete Pay Structure'];
    }
    if (isEdit) {
      return ['Pay Structure Edited Successfully', 'Failed To Edit Pay Structure'];
    }
    return ['Pay Structure Added Successfully', 'Failed To Add Structure Add'];
  },
  ProfileBasicProfile: () => {
    return ['Basic Profile Updated Successfully', 'Failed To Update Basic Profile'];
  },
  ProfileAddresses: () => {
    return ['Addresses Details Updated Successfully', 'Failed To Update Addresses Details'];
  },
  ProfileDependants: () => {
    return ['Dependents Updated Successfully', 'Failed To Update Dependents'];
  },
  ProfileEmployeeHistory: () => {
    return ['Employment History Updated Successfully', 'Failed To Update Employment History'];
  },
  ProfileEducationHistory: () => {
    return ['Education History Updated Successfully', 'Failed To Update Education History'];
  },
  ProfileVisaDetails: () => {
    return ['Visa Details Updated Successfully', 'Failed To Update Visa Details'];
  },
  ProfileSkillDetails: () => {
    return ['Skill Details Updated Successfully', 'Failed To Update Skill Details'];
  },
  ProfileLifeEventDetails: () => {
    return ['Life Event Details Updated Successfully', 'Failed To Update Life Event Details '];
  },

  TaskAndQueries: (isEdit = false, isDelete = false) => {
    if (isEdit && isDelete) {
      return ['Query Deleted Successfully', 'Failed To Delete Query '];
    }
    if (isEdit) {
      return ['Query Updated Successfully', 'Failed To Update Query '];
    }
    return ['Query Added Successfully', 'Failed To Add Query'];
  },

  ProfileOnboardingRecords: () => {
    return ['Onboarding Records Updated Successfully', 'Failed To Update Onboarding Records'];
  },
  EmployeeMapping: (toastMessageType = '') => {
    if (toastMessageType === 'changePayStructure') {
      return ['Policy Mapped To Employee Updated Successfully', 'Failed To Update Policy Mapped To Employee'];
    }
    if (toastMessageType === 'removeEmployee') {
      return ['Employee Removed Successfully', 'Failed To Remove Employee'];
    }
    return ['Employee Pay Structure mappings updated Successfully', 'Failed To Update Employee Pay Structure Mappings'];
  },
};

export { getToastMessage };
