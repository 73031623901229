import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

const Typographys = ({ component, variant, align, children, noWrap, color, fontWeight, display, sx }) => {
  return (
    <Typography
      component={component}
      variant={variant}
      align={align}
      noWrap={noWrap}
      color={color}
      fontWeight={fontWeight}
      sx={sx}
      display={display}
    >
      {children}
    </Typography>
  );
};

Typographys.defaultProps = {
  align: 'left',
  noWrap: false,
  variant: 'body2',
  color: 'neutral.800',
  children: '',
  fontWeight: '',
  component: '',
  sx: {},
  display: '',
};

Typographys.propTypes = {
  variant: PropTypes.string,
  display: PropTypes.string,
  align: PropTypes.string,
  children: PropTypes.node,
  noWrap: PropTypes.bool,
  component: PropTypes.string,
  color: PropTypes.string,
  fontWeight: PropTypes.string,
  sx: PropTypes.shape(),
};

export default Typographys;
