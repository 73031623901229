import React from 'react';
import PropTypes from 'prop-types';
import DashboardTemplate from 'components/Templates/DashboardTemplate';
import CandidateDashboard from 'components/Organisms/Dashboard/CandidateDashboard';

const CandidateDashboardTemplate = ({ pageHeading, candidateData, handleDownload, contentTxt1, contentTxt2 }) => {
  return (
    <DashboardTemplate pageHeading={pageHeading} isBreadcrumb={false} padding="0rem 3rem">
      <CandidateDashboard
        candidateData={candidateData}
        handleDownload={handleDownload}
        contentTxt1={contentTxt1}
        contentTxt2={contentTxt2}
      />
    </DashboardTemplate>
  );
};

CandidateDashboardTemplate.defaultProps = {
  pageHeading: 'Hello',
  candidateData: {},
  handleDownload: () => {},
  contentTxt1: '',
  contentTxt2: '',
};

CandidateDashboardTemplate.propTypes = {
  pageHeading: PropTypes.string,
  candidateData: PropTypes.shape({}),
  handleDownload: PropTypes.func,
  contentTxt1: PropTypes.string,
  contentTxt2: PropTypes.string,
};

export default CandidateDashboardTemplate;
