import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import { timerProgressBarStyle } from './style';

const TimerProgressBar = ({ progressBarList, barHeight }) => {
  const durationVal = progressBarList.duration.hour * 60 + progressBarList.duration.min;
  const breakVal = progressBarList.break.hour * 60 + progressBarList.break.min;
  const overtimeVal = progressBarList.overtime.hour * 60 + progressBarList.overtime.min;
  const total = durationVal + breakVal + overtimeVal;

  return (
    <>
      <Box sx={{ ...timerProgressBarStyle.progressBarWrapper, height: barHeight }}>
        <Box
          sx={{
            ...timerProgressBarStyle.progressBar,
            width: `${(durationVal / total) * 100}%`,
            height: barHeight,
            bgcolor: 'secondary.600',
          }}
        />
        <Box
          sx={{
            ...timerProgressBarStyle.progressBar,
            width: `${(breakVal / total) * 100}%`,
            height: barHeight,
            bgcolor: 'warning.300',
          }}
        />
        <Box
          sx={{
            ...timerProgressBarStyle.progressBar,
            width: `${(overtimeVal / total) * 100}%`,
            height: barHeight,
            bgcolor: 'error.300',
          }}
        />
      </Box>
      <Box sx={{ ...timerProgressBarStyle.indicatorWrapper }}>
        <Box sx={{ ...timerProgressBarStyle.timeIndicator, bgcolor: 'secondary.600' }}> </Box>
        <Typographys variant="body2">
          Duration {`(${progressBarList.duration.hour}h ${progressBarList.duration.min}m)`}
        </Typographys>

        <Box sx={{ ...timerProgressBarStyle.timeIndicator, bgcolor: 'warning.300' }}> </Box>
        <Typographys variant="body2">
          Break {`(${progressBarList.break.hour}h ${progressBarList.break.min}m)`}
        </Typographys>

        <Box sx={{ ...timerProgressBarStyle.timeIndicator, bgcolor: 'error.300' }}> </Box>
        <Typographys variant="body2">
          Overtime {`(${progressBarList.overtime.hour}h ${progressBarList.overtime.min}m)`}
        </Typographys>
      </Box>
    </>
  );
};

TimerProgressBar.defaultProps = {
  progressBarList: [],
  barHeight: '1rem',
};

TimerProgressBar.propTypes = {
  progressBarList: PropTypes.arrayOf(
    PropTypes.shape({
      duration: PropTypes.shape({
        hour: PropTypes.number,
        min: PropTypes.number,
      }),
      break: PropTypes.shape({
        hour: PropTypes.number,
        min: PropTypes.number,
      }),
      overtime: PropTypes.shape({
        hour: PropTypes.number,
        min: PropTypes.number,
      }),
    }),
  ),
  barHeight: PropTypes.string,
};

export default TimerProgressBar;
