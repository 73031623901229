import React, { useState } from 'react';
import EditProfileDependentTemplate from 'components/Templates/Profile/Dependents/Edit';
import { useSelector } from 'react-redux';
import FormData from 'form-data';
import useToast from 'components/Provider/useToast';
import dayjs from 'dayjs';
import { formDataApi } from 'api/api';
import { useNavigate } from 'react-router-dom';

const AddEditDependents = () => {
  const addToast = useToast();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const { dependentsData, genderOptions, relationshipMap, contactReference } = useSelector((state) => {
    return state.profile;
  });

  const editOptions = {
    genderOptions: genderOptions ? Object.values(genderOptions).map((item) => ({ id: item, name: item })) : [],
    relationOptions: relationshipMap ? Object.values(relationshipMap).map((item) => ({ id: item, name: item })) : [],
    referenceOptions: contactReference ? Object.values(contactReference).map((item) => ({ id: item, name: item })) : [],
    defaultValue: {
      dependents: dependentsData?.map((item) => {
        return {
          mobileNumber: [item?.contactInfo?.mobileNumber],
          emailAddress: [item?.contactInfo?.emailAddress],
          telephoneNumber: [item?.contactInfo?.telephoneNumber],
          firstName: item?.contactInfo?.firstName,
          lastName: item?.contactInfo?.lastName,
          panNumber: item?.panNumberIndia,
          address: item?.address,
          gender: item?.contactInfo?.gender,
          relation: item?.relationship,
          dateOfBirth: item?.dateOfBirth ? dayjs(item.dateOfBirth) : '',
          epfPerc: item?.epfNominationPercentage,
          epsPerc: item?.epsNominationPercentage,
          insurancePerc: item?.insuranceNominationPercentage,
          otherPerc: item?.othersNominationPercentage,
          gratuityPerc: item?.gratuityNominationPercentage,
          emergencyContact: item?.emergencyContactReference || (contactReference && Object.values(contactReference)[0]),
        };
      }),
    },
  };

  const getDataSet = (data, index) => ({
    [`dependentDetails[${index}].contactInfo.firstName`]: data?.firstName || '',
    [`dependentDetails[${index}].contactInfo.lastName`]: data?.lastName || '',
    [`dependentDetails[${index}].contactInfo.gender`]: data?.gender || '',
    [`dependentDetails[${index}].dateOfBirth`]: data?.dateOfBirth ? dayjs(data?.dateOfBirth).format('DD/MM/YYYY') : '',
    [`dependentDetails[${index}].relationship`]: data?.relation || '',
    [`dependentDetails[${index}].panNumberIndia`]: data?.panNumber || '',
    [`dependentDetails[${index}].address`]: data?.address || '',
    [`dependentDetails[${index}].contactInfo.mobileNumber`]: data?.mobileNumber[0] || '',
    [`dependentDetails[${index}].contactInfo.emailAddress`]: data?.emailAddress[0] || '',
    [`dependentDetails[${index}].contactInfo.telephoneNumber`]: data?.telephoneNumber[0] || '',
    [`dependentDetails[${index}].epfNominationPercentage`]: data?.epfPerc || '',
    [`dependentDetails[${index}].epsNominationPercentage`]: data?.epsPerc || '',
    [`dependentDetails[${index}].insuranceNominationPercentage`]: data?.insurancePerc || '',
    [`dependentDetails[${index}].othersNominationPercentage`]: data?.otherPerc || '',
    [`dependentDetails[${index}].gratuityNominationPercentage`]: data?.gratuityPerc || '',
    [`dependentDetails[${index}].emergencyContactReference`]: data?.emergencyContact || '',
  });

  const configureFormDataAndUpdate = async (dependents) => {
    const formData = new FormData();
    dependents.forEach((payload) => {
      const index = dependents.indexOf(payload);
      const formDataObject = getDataSet(payload, index);
      Object.keys(formDataObject).forEach((property) => {
        formData.append(property, formDataObject[property]);
      });
    });
    formData.append(`dependentsCtr`, dependents.length);
    return formData;
  };

  const handleCancel = () => {
    navigate('/profile/dependents');
  };

  const handleSubmit = async (formikData) => {
    const bodyFormData = await configureFormDataAndUpdate(formikData.dependents);

    try {
      setLoading(true);
      const response = await formDataApi.post(`restEmpIndex/updateDependentsAction`, bodyFormData);
      if (response?.data?.status !== 'ERROR') {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || 'Dependent details updated successfully',
        });
      } else if (response?.data?.status === 'ERROR') {
        addToast({
          type: 'error',
          title: 'ERROR',
          message: response?.data?.message || 'Failed to update dependent details',
        });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || 'Failed to update dependent details',
      });
    } finally {
      setLoading(false);
      navigate('/profile/dependents');
    }
  };

  return (
    <EditProfileDependentTemplate
      defaultValue={editOptions.defaultValue}
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
      genderOptions={editOptions.genderOptions}
      relationOptions={editOptions.relationOptions}
      referenceOptions={editOptions.referenceOptions}
      loading={loading}
    />
  );
};

export default AddEditDependents;
