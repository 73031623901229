import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import CommonFilledCard from 'components/Molecules/CommonFilledCard';
import TeamAttendanceStatus from '../TeamAttendanceStatus';

const TeamAttendanceStatusGroup = ({
  statusOptions,
  statusHandleChange,
  attendanceStatus,
  isStatusUp,
  handleFilterDateChange,
  todayAttendanceStatus,
}) => {
  return (
    <Grid container spacing={2}>
      {todayAttendanceStatus && (
        <Grid item xs={12} md={6}>
          <TeamAttendanceStatus
            statusOptions={statusOptions}
            statusHandleChange={statusHandleChange}
            isStatusUp={isStatusUp}
            attendanceStatus={attendanceStatus}
            handleFilterDateChange={handleFilterDateChange}
          />
        </Grid>
      )}
      {todayAttendanceStatus && (
        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <CommonFilledCard
                heading="On time today"
                headingColor="secondary.500"
                value={todayAttendanceStatus.onTimeToday}
                boxBackground="common.white"
                boxBorder="0.125rem solid"
                boxBorderColor="neutral.300"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CommonFilledCard
                heading="Late arrivals today"
                headingColor="secondary.500"
                value={todayAttendanceStatus.lateArrivalsToday}
                boxBackground="common.white"
                boxBorder="0.125rem solid"
                boxBorderColor="neutral.300"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CommonFilledCard
                heading="WFH/On duty today"
                headingColor="secondary.500"
                value={todayAttendanceStatus.wfhOnDutyToday}
                boxBackground="common.white"
                boxBorder="0.125rem solid"
                boxBorderColor="neutral.300"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CommonFilledCard
                heading="Remote check-ins today"
                headingColor="secondary.500"
                value={todayAttendanceStatus.remoteCheckInsToday}
                boxBackground="common.white"
                boxBorder="0.125rem solid"
                boxBorderColor="neutral.300"
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

TeamAttendanceStatusGroup.defaultProps = {
  statusOptions: [],
  statusHandleChange: () => {},
  attendanceStatus: {},
  isStatusUp: true,
  handleFilterDateChange: () => {},
  todayAttendanceStatus: {},
};
TeamAttendanceStatusGroup.propTypes = {
  statusOptions: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, name: PropTypes.name, onClick: PropTypes.func }),
  ),
  statusHandleChange: PropTypes.func,
  attendanceStatus: PropTypes.shape({
    avgHrPerDay: PropTypes.string,
    avgHrPerDayDetails: PropTypes.string,
    onTimeArrival: PropTypes.string,
    onTimeArrivalDetails: PropTypes.string,
  }),
  isStatusUp: PropTypes.bool,
  handleFilterDateChange: PropTypes.func,
  todayAttendanceStatus: PropTypes.shape({
    onTimeToday: PropTypes.number,
    lateArrivalsToday: PropTypes.number,
    wfhOnDutyToday: PropTypes.number,
    remoteCheckInsToday: PropTypes.number,
  }),
};

export default TeamAttendanceStatusGroup;
