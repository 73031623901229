import React from 'react';
import PropTypes from 'prop-types';
import EmployeeCommonCard from 'components/Organisms/Employee/EmployeeCommonCard';
import MonthWiseGraph from '../Charts/MonthWiseGraph';

const MonthWiseTrendsCard = ({
  monthWiseTrendFilterOptions,
  monthWiseTrendFilterHandleChange,
  monthWiseData,
  onboardingAttritionColumns,
}) => {
  return (
    <EmployeeCommonCard
      cardHeaderTitle="Monthwise Trends"
      minWidthHeaderDropdown="12rem"
      headerFilterId="monthWiseTrendFilter"
      headerFilterLabel="Onboarding"
      headerFilterOptions={monthWiseTrendFilterOptions}
      headerFilterHandleChange={monthWiseTrendFilterHandleChange}
      bodyContent={
        <MonthWiseGraph monthWiseData={monthWiseData} onboardingAttritionColumns={onboardingAttritionColumns} />
      }
      isFooter={false}
      cardBodyMinHeight="32rem"
      cardBodyPadding="5rem 0 2rem 0"
    />
  );
};

MonthWiseTrendsCard.defaultProps = {
  monthWiseTrendFilterOptions: [],
  monthWiseTrendFilterHandleChange: () => {},
  monthWiseData: [],
};
MonthWiseTrendsCard.propTypes = {
  monthWiseTrendFilterOptions: PropTypes.arrayOf,
  monthWiseTrendFilterHandleChange: PropTypes.func,
  monthWiseData: PropTypes.arrayOf,
};

export default MonthWiseTrendsCard;
