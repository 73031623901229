import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@mui/material';
import { validateDateElseHyphen } from 'utils/commonUtils';
import TitleWithText from 'components/Atoms/TitleWithText';

const BasicProfile = ({ details }) => {
  return (
    <Box px={3}>
      <Grid container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Gender*" text={details?.gender?.toString()} />
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText
            title="Date Of Birth*"
            text={validateDateElseHyphen(details?.dob?.toString(), 'DD MMMM YYYY')}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText
            title="Date Of Joining*"
            text={validateDateElseHyphen(details?.doj?.toString(), 'DD MMMM YYYY')}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Employment Status*" text={details?.employmentStatus?.toString()} />
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Father’s Name*" text={details?.fatherName?.toString()} />
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Mother’s Name" text={details?.motherName?.toString()} />
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Marital Status" text={details?.maritalStatus?.toString()} />
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Spouse Name" text={details?.spouseName?.toString()} />
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Location" text={details?.location?.name?.toString()} />
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Supervisor" text={details?.supervisor?.fullName?.toString()} />
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Blood Group" text={details?.bloodGroup?.toString()} />
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Onboarding Reference" text={details?.onboardingReference?.toString()} />
        </Grid>
      </Grid>
    </Box>
  );
};

BasicProfile.defaultProps = {
  basicDetail: {
    gender: 'Male',
    maritalStatus: 'UnMarried',
    dob: '07/06/1960',
    doj: '04/06/2017',
    employmentStatus: 'Active',
    fatherName: 'Vidyasagar',
    motherName: 'Snehalatha',
    spouseName: '-',
    location: 'Mumbai',
    supervisor: 'Aachman Kabir',
    bloodGroup: 'B-',
    onboardingReference: 'Employee Referral',
  },
};

BasicProfile.propTypes = {
  basicDetail: PropTypes.shape({
    gender: PropTypes.string,
    maritalStatus: PropTypes.string,
    dob: PropTypes.string,
    doj: PropTypes.string,
    employmentStatus: PropTypes.string,
    fatherName: PropTypes.string,
    motherName: PropTypes.string,
    spouseName: PropTypes.string,
    location: PropTypes.string,
    supervisor: PropTypes.string,
    bloodGroup: PropTypes.string,
    onboardingReference: PropTypes.string,
  }),
};

export default BasicProfile;
