import React, { useCallback, useEffect, useState } from 'react';
import { Box, Card, CardContent, Container, Grid, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TableExtended from 'components/Molecules/TableExtended';
import useController from 'hooks/useController';
import DropdownMenu from 'components/Atoms/DropdownMenu';
import { useDispatch, useSelector } from 'react-redux';
import usePaginationAttributes from 'hooks/use-pagination-attributes';
import { updatePayheads } from 'store/slices/company/salary/payheads';
import NoRecordsMessage from 'components/Organisms/CommonPageUtils/NoRecordsFound';
import { listPayHeads, searchPayHeads } from 'api/payhead';
import { hasPermission } from 'utils/permissions';
import useColumns from './useColumns';
import { styles } from './styles';
import { PayheadFilters } from './payheadFilter';
import { BulkUploadDialog } from './BulkUploadDialog';

const PayheadsList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [controller, handlePageChange, handleSortChange, handleFiltersApply, handleFiltersClear] = useController();
  const [columns] = useColumns();
  const { payheads } = useSelector((state) => state.payheads);
  const { pageSize } = usePaginationAttributes();
  const [openBulkUploadDialog, setOpenBulkUploadDialog] = useState(false);
  const [openBulkUpdateDialog, setOpenBulkUpdateDialog] = useState(false);
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasViewPermission = hasPermission(authorities, ['ROLE_BASE_PERMISSION_PAY_INDEX_VIEW_PAYHEAD']);

  const menuItems = [
    {
      id: 1,
      label: 'Add new',
      permissions: ['ROLE_BASE_PERMISSION_PAY_INDEX_CREATE_PAYHEAD'],
      onClick: () => {
        navigate('/company/salary/payheads/add');
      },
    },
    {
      id: 1,
      label: 'Bulk Upload',
      // TODO: Add permissions for bulk upload
      permissions: [],
      onClick: () => {
        setOpenBulkUploadDialog(true);
      },
    },
    {
      id: 1,
      label: 'Bulk Update',
      // TODO: Add permissions for bulk update
      permissions: [],
      onClick: () => {
        setOpenBulkUpdateDialog(true);
      },
    },
  ];

  const menuActionItems = menuItems.filter((item) => !!hasPermission(authorities, item.permissions));

  const fetchData = useCallback(
    async (searched, filters, sort, sortBy, page) => {
      setLoading(true);
      try {
        if (searched) {
          const query = new URLSearchParams({});
          if (filters.length) {
            filters.forEach((filter) => query.append(filter.property, filter.value));
          }
          query.append('all', 'true');
          const response = await searchPayHeads(query);
          if (response?.data?.data) {
            dispatch({ type: 'resolved', payload: response?.data?.data });
          }
        } else {
          const query = new URLSearchParams({});
          // if (sortBy) {
          //   query.append('sort', sortBy);
          //   query.append('order', sort);
          // }
          query.append('offset', page * pageSize);
          query.append('max', pageSize);
          const response = await listPayHeads(query);
          if (response) {
            dispatch(updatePayheads(response));
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, pageSize],
  );

  const handleBulkUploadResult = (result) => {
    if (openBulkUploadDialog) {
      setOpenBulkUploadDialog(false);
    } else setOpenBulkUpdateDialog(false);
    if (result) {
      fetchData(false, [], 'desc', 'id', 0);
    }
  };

  useEffect(() => {
    if (fetchData)
      fetchData(controller?.searched, controller?.filters, controller?.sort, controller?.sortBy, controller?.page);
  }, [controller, fetchData]);

  return (
    <Box sx={styles.growBox}>
      <Container maxWidth="lg">
        <Grid container direction="column">
          <Grid item container justifyContent="flex-start" alignItems="center" sx={{ pb: 1.5 }}>
            <Grid item>
              <Typography variant="h6" color="text.primary">
                Pay Heads List
              </Typography>
            </Grid>
          </Grid>
          <Grid item sx={{ maxWidth: '100% !important' }}>
            <Card>
              <CardContent sx={{ maxHeight: 'calc(100vh - 140px)', overflowY: 'auto' }}>
                <Stack direction="row" justifyContent="end" alignItems="center" sx={{ pb: 2 }}>
                  {/* Ths code can be uncommented whenever the flters is picked up */}
                  {/* <PayheadFilters
                    disabled={loading}
                    filters={controller.filters}
                    onFiltersApply={handleFiltersApply}
                    onFiltersClear={handleFiltersClear}
                  /> */}
                  <DropdownMenu menuLists={menuActionItems} />
                </Stack>

                {!loading && payheads?.data.length === 0 ? (
                  <NoRecordsMessage width="20rem" height="20rem" />
                ) : (
                  <TableExtended
                    loading={loading}
                    columns={columns}
                    rows={payheads.data}
                    keyColumn="id"
                    sortDirection={controller?.sort}
                    sortBy={controller?.sortBy}
                    onSortChange={handleSortChange}
                    page={controller?.page}
                    onPageChange={handlePageChange}
                    count={payheads.count}
                    showPagination
                    onRowClick={(row) => !!userHasViewPermission && navigate(`/company/salary/payheads/view/${row.id}`)}
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <BulkUploadDialog
        onResult={handleBulkUploadResult}
        openBulkUploadDialog={openBulkUploadDialog || openBulkUpdateDialog}
        isBulkUpdate={openBulkUpdateDialog}
      />
    </Box>
  );
};

export default PayheadsList;
