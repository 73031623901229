import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Checkbox from 'components/Atoms/Checkbox';
import { useTheme } from '@mui/material/styles';
import * as yup from 'yup';
import Typographys from 'components/Atoms/Typography';
import InputField from 'components/Atoms/InputField';
import Button from 'components/Atoms/Button';
import { useFormik } from 'formik';
import { profileStyle } from '../../style';

const ProfileEditAddresses = ({ handleSubmit, handleCancel, defaultValue, loading }) => {
  const theme = useTheme();

  const AddressesSchema = yup.object().shape({
    permanentAddressLine1: yup.string().required('Required'),
    permanentCity: yup.string().required('Required'),
    permanentState: yup.string().required('Required'),
    permanentCountry: yup.string().required('Required'),
    permanentPincode: yup
      .string()
      .matches(/^[0-9]{5,6}$/, 'Invalid Pin Code')
      .required('Required'),
    currentAddressLine1: yup.string().required('Required'),
    currentCity: yup.string().required('Required'),
    currentState: yup.string().required('Required'),
    currentCountry: yup.string().required('Required'),
    currentPincode: yup
      .string()
      .matches(/^[0-9]{5,6}$/, 'Invalid Pin Code')
      .required('Required'),
    ofcAddressLine1: yup.string().required('Required'),
    ofcCity: yup.string().required('Required'),
    ofcState: yup.string().required('Required'),
    ofcCountry: yup.string().required('Required'),
    ofcPincode: yup
      .string()
      .matches(/^[0-9]{5,6}$/, 'Invalid Pin Code')
      .required('Required'),
  });

  const formik = useFormik({
    initialValues: defaultValue,
    validationSchema: AddressesSchema,
    validateOnMount: true,
    onSubmit: handleSubmit,
  });

  const handleAddressUpdate = (e) => {
    formik.handleChange(e);
    if (!formik.values.sameAddress?.includes('on')) {
      formik.setFieldValue('currentLocation', formik.values.permanentLocation);
      formik.setFieldValue('currentAddressLine1', formik.values.permanentAddressLine1);
      formik.setFieldValue('currentAddressLine2', formik.values.permanentAddressLine2);
      formik.setFieldValue('currentAddressLine3', formik.values.permanentAddressLine3);
      formik.setFieldValue('currentCity', formik.values.permanentCity);
      formik.setFieldValue('currentState', formik.values.permanentState);
      formik.setFieldValue('currentCountry', formik.values.permanentCountry);
      formik.setFieldValue('currentPincode', formik.values.permanentPincode);
    }
  };

  return (
    <Box sx={profileStyle.mainContainer}>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <Typographys variant="h5" color="neutral.800">
          Permanent Residential Address
        </Typographys>
        <PerfectScrollbar>
          <Box sx={{ maxHeight: 'calc(100vh - 41rem)' }}>
            <Grid container rowSpacing={2} columnSpacing={9} sx={profileStyle.formContainer}>
              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.permanentLocation && formik.errors.permanentLocation)}
                  helperText={formik.touched.permanentLocation && formik.errors.permanentLocation}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.permanentLocation}
                  name="permanentLocation"
                  id="permanentLocation"
                  label="Location Name (Optional)"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.permanentAddressLine1 && formik.errors.permanentAddressLine1)}
                  helperText={formik.touched.permanentAddressLine1 && formik.errors.permanentAddressLine1}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.permanentAddressLine1}
                  name="permanentAddressLine1"
                  id="permanentAddressLine1"
                  label="Address Line 1*"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.permanentAddressLine2 && formik.errors.permanentAddressLine2)}
                  helperText={formik.touched.permanentAddressLine2 && formik.errors.permanentAddressLine2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.permanentAddressLine2}
                  name="permanentAddressLine2"
                  id="permanentAddressLine2"
                  label="Address Line 2"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.permanentAddressLine3 && formik.errors.permanentAddressLine3)}
                  helperText={formik.touched.permanentAddressLine3 && formik.errors.permanentAddressLine3}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.permanentAddressLine3}
                  name="permanentAddressLine3"
                  id="permanentAddressLine3"
                  label="Address Line 3"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.permanentCity && formik.errors.permanentCity)}
                  helperText={formik.touched.permanentCity && formik.errors.permanentCity}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.permanentCity}
                  name="permanentCity"
                  id="permanentCity"
                  label="City*"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.permanentState && formik.errors.permanentState)}
                  helperText={formik.touched.permanentState && formik.errors.permanentState}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.permanentState}
                  name="permanentState"
                  id="permanentState"
                  label="State*"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.permanentCountry && formik.errors.permanentCountry)}
                  helperText={formik.touched.permanentCountry && formik.errors.permanentCountry}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.permanentCountry}
                  name="permanentCountry"
                  id="permanentCountry"
                  label="Country*"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.permanentPincode && formik.errors.permanentPincode)}
                  helperText={formik.touched.permanentPincode && formik.errors.permanentPincode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.permanentPincode}
                  name="permanentPincode"
                  id="permanentPincode"
                  label="Pincode*"
                  type="number"
                  fullWidth
                />
              </Grid>
            </Grid>

            <Typographys variant="h5" color="neutral.800">
              Present Communication Address
            </Typographys>

            <Box mt={2} ml="1.75rem">
              <Checkbox
                onChange={(e) => handleAddressUpdate(e)}
                key={formik.values.sameAddress}
                checked={formik.values.sameAddress?.includes('on')}
                color="secondary"
                name="sameAddress"
                label="Same as permanent address"
              />
            </Box>

            <Grid container rowSpacing={2} columnSpacing={9} sx={profileStyle.formContainer}>
              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.currentLocation && formik.errors.currentLocation)}
                  helperText={formik.touched.currentLocation && formik.errors.currentLocation}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.currentLocation}
                  name="currentLocation"
                  id="currentLocation"
                  label="Location Name (Optional)"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.currentAddressLine1 && formik.errors.currentAddressLine1)}
                  helperText={formik.touched.currentAddressLine1 && formik.errors.currentAddressLine1}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.currentAddressLine1}
                  name="currentAddressLine1"
                  id="currentAddressLine1"
                  label="Address Line 1*"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.currentAddressLine2 && formik.errors.currentAddressLine2)}
                  helperText={formik.touched.currentAddressLine2 && formik.errors.currentAddressLine2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.currentAddressLine2}
                  name="currentAddressLine2"
                  id="currentAddressLine2"
                  label="Address Line 2"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.currentAddressLine3 && formik.errors.currentAddressLine3)}
                  helperText={formik.touched.currentAddressLine3 && formik.errors.currentAddressLine3}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.currentAddressLine3}
                  name="currentAddressLine3"
                  id="currentAddressLine3"
                  label="Address Line 3"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.currentCity && formik.errors.currentCity)}
                  helperText={formik.touched.currentCity && formik.errors.currentCity}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.currentCity}
                  name="currentCity"
                  id="currentCity"
                  label="City*"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.currentState && formik.errors.currentState)}
                  helperText={formik.touched.currentState && formik.errors.currentState}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.currentState}
                  name="currentState"
                  id="currentState"
                  label="State*"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.currentCountry && formik.errors.currentCountry)}
                  helperText={formik.touched.currentCountry && formik.errors.currentCountry}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.currentCountry}
                  name="currentCountry"
                  id="currentCountry"
                  label="Country*"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.currentPincode && formik.errors.currentPincode)}
                  helperText={formik.touched.currentPincode && formik.errors.currentPincode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.currentPincode}
                  name="currentPincode"
                  id="currentPincode"
                  label="Pincode*"
                  type="number"
                  fullWidth
                />
              </Grid>
            </Grid>

            <Typographys variant="h5" color="neutral.800">
              Office Address
            </Typographys>

            <Grid container rowSpacing={2} columnSpacing={9} sx={profileStyle.formContainer}>
              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.ofcLocationName && formik.errors.ofcLocationName)}
                  helperText={formik.touched.ofcLocationName && formik.errors.ofcLocationName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ofcLocationName}
                  name="ofcLocationName"
                  id="ofcLocationName"
                  label="Location Name (Optional)"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.ofcAddressLine1 && formik.errors.ofcAddressLine1)}
                  helperText={formik.touched.ofcAddressLine1 && formik.errors.ofcAddressLine1}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ofcAddressLine1}
                  name="ofcAddressLine1"
                  id="ofcAddressLine1"
                  label="Address Line 1*"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.ofcAddressLine2 && formik.errors.ofcAddressLine2)}
                  helperText={formik.touched.ofcAddressLine2 && formik.errors.ofcAddressLine2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ofcAddressLine2}
                  name="ofcAddressLine2"
                  id="ofcAddressLine2"
                  label="Address Line 2"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.ofcAddressLine3 && formik.errors.ofcAddressLine3)}
                  helperText={formik.touched.ofcAddressLine3 && formik.errors.ofcAddressLine3}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ofcAddressLine3}
                  name="ofcAddressLine3"
                  id="ofcAddressLine3"
                  label="Address Line 3"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.ofcCity && formik.errors.ofcCity)}
                  helperText={formik.touched.ofcCity && formik.errors.ofcCity}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ofcCity}
                  name="ofcCity"
                  id="ofcCity"
                  label="City*"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.ofcState && formik.errors.ofcState)}
                  helperText={formik.touched.ofcState && formik.errors.ofcState}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ofcState}
                  name="ofcState"
                  id="ofcState"
                  label="State*"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.ofcCountry && formik.errors.ofcCountry)}
                  helperText={formik.touched.ofcCountry && formik.errors.ofcCountry}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ofcCountry}
                  name="ofcCountry"
                  id="ofcCountry"
                  label="Country*"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.ofcPincode && formik.errors.ofcPincode)}
                  helperText={formik.touched.ofcPincode && formik.errors.ofcPincode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ofcPincode}
                  name="ofcPincode"
                  id="ofcPincode"
                  label="Pincode*"
                  type="number"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
        </PerfectScrollbar>

        <Box sx={profileStyle.btnContainer}>
          <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

          <Box sx={profileStyle.submitWrapper}>
            <Button
              size="large"
              color="info"
              variant="outlined"
              type="cancel"
              onClick={() => {
                formik.resetForm();
                handleCancel();
              }}
            >
              Cancel
            </Button>
            <LoadingButton loading={loading} color="primary" size="large" type="submit" variant="contained">
              Update
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

ProfileEditAddresses.defaultProps = {
  handleSubmit: () => {},
  handleCancel: () => {},
  defaultValue: {},
  loading: false,
};

ProfileEditAddresses.propTypes = {
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  defaultValue: PropTypes.shape({}),
  loading: PropTypes.bool,
};

export default ProfileEditAddresses;
