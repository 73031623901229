import React, { useEffect, useState } from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import ProfileLifeEventDetailsEdit from 'components/Organisms/Profile/LifeEventDetails/Edit';
import { useThunk } from 'hooks/useThunk';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fetchLifeEventDetails } from 'store';
import { Typography, Skeleton, Box } from '@mui/material';
import useToast from 'components/Provider/useToast';
import { formDataApi } from 'api/api';
import { updatelifeeventDetailsData } from 'api/profile';
import dayjs from 'dayjs';

const breadcrumbData = [
  { id: 1, name: 'Life Event Details', path: '/profile/life-event-details', isActive: false },
  { id: 2, name: 'Edit', path: '/edit', isActive: true },
];

const ProfileLifeEventDetailsEditTemplate = () => {
  const addToast = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [lifeEventData, setLifeEventData] = useState([]);

  const lifeEventPrefillData = async () => {
    try {
      setPageLoading(true);

      const response = await updatelifeeventDetailsData();
      if (response?.status === 'SUCCESS') {
        setLifeEventData(response);
      }
      if (response?.status === 'ERROR') {
        addToast({
          type: 'error',
          title: 'ERROR',
          message: response?.message || `Failed to fetch life event details`,
        });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to fetch life event details`,
      });
      navigate('/profile/life-event-details');
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await lifeEventPrefillData();
    };

    fetchData();
  }, []);

  const editOptions = {
    defaultValue: {
      lifeEvents: lifeEventData?.eventRecords?.map((item) => ({
        eventType: item.eventType,
        eventDate: item.eventDate,
        comments: item.comments,
      })),
    },
    lifeEventOptions:
      lifeEventData?.eventType && Object.values(lifeEventData?.eventType)?.map((item) => ({ id: item, name: item })),
  };

  const dateFormatChange = (value) => {
    return value ? dayjs(value).format('MM/DD/YYYY') : '';
  };

  // eslint-disable-next-line consistent-return
  const configureFormDataAndUpdate = async (lifeEvents) => {
    if (lifeEventData?.employeeProfileInstance) {
      const formData = new FormData();
      formData.append('id', lifeEventData?.employeeProfileInstance?.employee?.userId);
      lifeEvents.forEach((payload) => {
        const index = lifeEvents.indexOf(payload);
        formData.append(`eventRecords[${index}].eventType`, payload?.eventType || '');
        formData.append(`eventRecords[${index}].eventDate`, dateFormatChange(payload?.eventDate));
        formData.append(`eventRecords[${index}].comments`, payload?.comments || '');
      });
      formData.append(`eventRecordCtr`, lifeEvents?.length);
      return formData;
    }
  };

  const handleCancel = () => {
    navigate('/profile/life-event-details');
  };

  const handleSubmit = async (e) => {
    const bodyFormData = await configureFormDataAndUpdate(e?.lifeEvents);

    try {
      setLoading(true);
      const response = await formDataApi.post(`restEmpIndex/updateEventDetailsAction`, bodyFormData);
      if (response?.data?.status !== 'ERROR') {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || 'Life event details updated successfully',
        });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || 'Failed to update life event details',
      });
    } finally {
      setLoading(false);
      navigate('/profile/life-event-details');
    }
  };

  if (pageLoading) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  return (
    <BlankTemplate
      pageHeading="Edit Life Event Details"
      isBreadcrumb
      breadcrumbData={breadcrumbData}
      padding="0 3rem 2rem 3rem"
    >
      <ProfileLifeEventDetailsEdit
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        defaultValue={editOptions.defaultValue}
        lifeEventOptions={editOptions.lifeEventOptions}
        isButtonDisabled={loading}
        loading={loading}
      />
    </BlankTemplate>
  );
};

ProfileLifeEventDetailsEditTemplate.defaultProps = {
  handleSubmit: () => {},
  handleCancel: () => {},
  defaultValue: {
    lifeEvents: [
      {
        eventType: '',
        eventDate: '',
        comments: '',
      },
    ],
  },
  lifeEventOptions: [],
};

export default ProfileLifeEventDetailsEditTemplate;
