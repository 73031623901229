/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import React, { isValidElement } from 'react';
import { TableContainer, TableBody } from '@mui/material';
import MuiTable from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Checkbox from 'components/Atoms/Checkbox';
import ActionList from 'components/Molecules/ActionList';
import PerfectScrollbar from 'react-perfect-scrollbar';

// project import
import TableHead from './TableHead';
import TableRow from './TableRow';
import TableCell from './TableCell';
import TablePagination from './TablePagination';
import TableShimmer from './TableShimmer';
import { tableStyle } from '../Table/style';

const Table = (props) => {
  const {
    columns,
    rows,
    keyColumn,
    sortDirection,
    sortBy,
    onSortChange,
    count,
    page,
    onPageChange,
    showPagination,
    onRowClick,
    showRowSelector,
    selectedItems,
    onSelect,
    onSelectAll,
    isDynamic = true,
    showActionList,
    roleBasedMenu,
    actionOptions,
    handleSelect,
    loading,
    actionLabel,
    actionLabelColor,
    formik,
  } = props;

  return (
    <>
      <PerfectScrollbar>
        <TableContainer sx={{ overflowX: 'unset' }} component={Paper}>
          <MuiTable size="small">
            <TableHead
              rows={rows}
              columns={columns}
              sortDirection={sortDirection}
              sortBy={sortBy}
              onSortChange={onSortChange}
              selectedItems={selectedItems}
              showRowSelector={showRowSelector}
              onSelectAll={onSelectAll}
              keyColumn={keyColumn}
              showActionList={showActionList}
            />
            {!loading ? (
              <TableBody>
                {rows?.map((row, index) => {
                  return (
                    <TableRow key={`${row[keyColumn]}_${index}`} hover rowColor={row?.rowColor}>
                      {showRowSelector && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="secondary"
                            checked={!!selectedItems.find((selectedEmployee) => selectedEmployee === row[keyColumn])}
                            onChange={(event) => onSelect(event, row[keyColumn])}
                          />
                        </TableCell>
                      )}
                      {columns?.map((column) => {
                        return (
                          <TableCell
                            row={row}
                            onRowClick={onRowClick}
                            key={column.label}
                            fontSize={row?.rowFont}
                            fontWeight={row?.fontWeight}
                          >
                            {isValidElement(row[column.fieldName])
                              ? row[column.fieldName]
                              : column.formatValue?.({ row, column, formik, index, page })}
                          </TableCell>
                        );
                      })}
                      {showActionList && (
                        <TableCell sticky align="center" sx={tableStyle.actionButton}>
                          <ActionList
                            options={isDynamic ? roleBasedMenu(row) : actionOptions}
                            data={row}
                            handleSelect={handleSelect}
                            label={actionLabel}
                            labelColor={actionLabelColor}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <TableBody>
                <TableShimmer columns={columns.length + 1} />
              </TableBody>
            )}
          </MuiTable>
        </TableContainer>
      </PerfectScrollbar>
      {showPagination && <TablePagination count={count} page={page} onPageChange={onPageChange} />}
    </>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.node).isRequired,
  rows: PropTypes.arrayOf(PropTypes.node).isRequired,
  keyColumn: PropTypes.string,
  onSortChange: PropTypes.func,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  showPagination: PropTypes.bool,
  count: PropTypes.number.isRequired,
  onRowClick: PropTypes.func,
  selectedItems: PropTypes.arrayOf,
  showRowSelector: PropTypes.bool,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  isDynamic: PropTypes.bool,
  showActionList: PropTypes.bool,
  handleSelect: PropTypes.func,
  roleBasedMenu: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      userRole: PropTypes.arrayOf,
      onClick: PropTypes.func,
    }),
  ),
  actionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      userRole: PropTypes.arrayOf,
      onClick: PropTypes.func,
    }),
  ),
  loading: PropTypes.bool,
  actionLabel: PropTypes.string,
  actionLabelColor: PropTypes.string,
  formik: PropTypes.shape({}),
};

Table.defaultProps = {
  keyColumn: 'id',
  sortDirection: 'desc',
  sortBy: 'id',
  onSortChange: () => {},
  page: 1,
  onPageChange: () => {},
  showPagination: false,
  onRowClick: () => {},
  selectedItems: [],
  showRowSelector: false,
  onSelect: () => {},
  onSelectAll: () => {},
  isDynamic: true,
  showActionList: false,
  roleBasedMenu: [],
  handleSelect: () => {},
  actionOptions: [
    { name: 'Add notes sdfsa', onClick: () => {} },
    { name: 'Delete', onClick: () => {} },
    { name: 'Lock', onClick: () => {} },
    { name: 'Deactivate', onClick: () => {} },
    { name: 'Send ESS invite', onClick: () => {} },
    { name: 'Convert to candidate', onClick: () => {} },
    { name: 'Add employee to group', onClick: () => {} },
    { name: 'Map life cycle policy', onClick: () => {} },
    { name: 'View onboarding records', onClick: () => {} },
    { name: 'Performance settings', onClick: () => {} },
    { name: 'Access filters', onClick: () => {} },
    { name: 'Emp timeline', onClick: () => {} },
    { name: 'Total timeline', onClick: () => {} },
    { name: 'Salary timeline', onClick: () => {} },
    { name: 'View activity log', onClick: () => {} },
  ],
  loading: false,
  actionLabel: '',
  actionLabelColor: 'secondary.600',
  formik: {},
};
export default Table;
