import { useSelector } from 'react-redux';
import { hasPermission } from 'utils/permissions';

const getActionItems = (leaveServices) => {
  return [
    {
      name: 'View',
      onClick: leaveServices.viewLeave,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_LEAVE_INDEX_VIEW_LEAVE_REQUEST'],
    },
    {
      name: 'Edit',
      onClick: leaveServices.modifyLeave,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_LEAVE_INDEX_UPDATE_LEAVE_REQUEST'],
      conditional: (item) => item?.lockStatus === 'UnLocked' && item?.requestState === 'Draft',
    },
    {
      name: 'Delete',
      onClick: () => leaveServices.handleOpenDialog('Delete'),
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_LEAVE_INDEX_DELETE_LEAVE_REQUEST'],
      conditional: (item) => item?.lockStatus === 'UnLocked' && item?.requestState === 'Draft',
    },
    {
      name: 'Submit',
      onClick: () => leaveServices.handleOpenDialog('Submit'),
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_LEAVE_INDEX_SUBMIT_LEAVE_REQUEST'],
      conditional: (item) => item?.requestState === 'Draft',
    },
    {
      name: 'Approve',
      onClick: () => leaveServices.handleOpenDialog('Approve'),
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_LEAVE_INDEX_APPROVE_LEAVE_REQUEST'],
      conditional: (item) => item?.requestState === 'Pending Approval',
    },
    {
      name: 'Reject',
      onClick: () => leaveServices.handleOpenDialog('Reject'),
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_LEAVE_INDEX_REJECT_LEAVE_REQUEST'],
      conditional: (item) => item?.requestState === 'Pending Approval',
    },
    {
      name: 'Revert',
      onClick: () => leaveServices.handleOpenDialog('Revert'),
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_LEAVE_INDEX_REVERT_LEAVE_REQUEST'],
      conditional: (item) => item?.requestState === 'Pending Approval',
    },
    {
      name: 'Approve Cancellation',
      onClick: () => leaveServices.handleOpenDialog('Approve'),
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_LEAVE_INDEX_APPROVE_LEAVE_REQUEST'],
      conditional: (item) => item?.requestState === 'Cancellation Pending Approval',
    },
    {
      name: 'Reject Cancellation',
      onClick: () => leaveServices.handleOpenDialog('Reject'),
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_LEAVE_INDEX_REJECT_LEAVE_REQUEST'],
      conditional: (item) => item?.requestState === 'Cancellation Pending Approval',
    },
  ];
};

const useActionItems = (leaveServices) => {
  const actions = getActionItems(leaveServices);
  const authorities = useSelector((state) => state.session.user.authorities);

  const handleActionItemClick = (row) => {
    const moreFilteredActionItems = actions.filter((action) => {
      const userHasPermission = hasPermission(authorities, action.permissions);
      if ((action.conditional && !action.conditional(row)) || !userHasPermission) {
        return false;
      }
      return true;
    });
    return moreFilteredActionItems;
  };

  return [handleActionItemClick];
};

export default useActionItems;
