import React from 'react';
import { Box, Grid, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import Button from 'components/Atoms/Button';
import InputField from 'components/Atoms/InputField/index';
import { useFormik } from 'formik';
import { useTheme } from '@mui/material/styles';
import Dropdown from 'components/Molecules/Dropdown';
import DatePicker from 'components/Atoms/Datepicker';
import * as yup from 'yup';
import { addEmpStyle } from '../../style';

const SeparationForm = ({ handleSubmit, separationData, acceptingManagerOptions, handleCancel, isEdit, loading }) => {
  const SeparationSchema = yup.object().shape({
    resignationAcceptanceManager: yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues: separationData,
    validationSchema: SeparationSchema,
    validateOnMount: true,
    onSubmit: handleSubmit,
  });

  const handleDateChange = (date, fieldName) => {
    formik.setFieldValue(fieldName, date);
  };

  const theme = useTheme();

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Grid container rowSpacing={2} columnSpacing={2} mt={0} sx={addEmpStyle.formContainer}>
        <Grid item xs={12} md={4}>
          <DatePicker
            error={Boolean(formik.touched.requestedRelievingDate && formik.errors.requestedRelievingDate)}
            helperText={formik.touched.requestedRelievingDate && formik.errors.requestedRelievingDate}
            onChange={(date) => handleDateChange(date, 'requestedRelievingDate')}
            onBlur={formik.handleBlur}
            value={formik.values.requestedRelievingDate}
            name="requestedRelievingDate"
            id="requestedRelievingDate"
            label="Requested Relieving Date"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Dropdown
            error={Boolean(formik.touched.resignationAcceptanceManager && formik.errors.resignationAcceptanceManager)}
            helperText={formik.touched.resignationAcceptanceManager && formik.errors.resignationAcceptanceManager}
            handleChange={(e) => {
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.resignationAcceptanceManager}
            name="resignationAcceptanceManager"
            id="resignationAcceptanceManager"
            label="Resignation Accepting Manager*"
            options={acceptingManagerOptions}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <InputField
            error={Boolean(formik.touched.resignationReason && formik.errors.resignationReason)}
            helperText={formik.touched.resignationReason && formik.errors.resignationReason}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.resignationReason}
            name="resignationReason"
            id="resignationReason"
            label="Resignation Reason"
            fullWidth
            multiline
          />
        </Grid>
      </Grid>

      <Box sx={addEmpStyle.btnContainer}>
        <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />
        <Box sx={addEmpStyle.submitWrapper}>
          {isEdit && (
            <Button
              size="large"
              color="info"
              variant="outlined"
              type="cancel"
              onClick={() => {
                formik.resetForm();
                handleCancel();
              }}
            >
              Cancel
            </Button>
          )}
          <LoadingButton
            size="large"
            type="submit"
            color="primary"
            loading={loading}
            variant="contained"
            disabled={Object.keys(formik.errors).length}
          >
            {isEdit ? 'Update' : 'Initiate'}
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

SeparationForm.defaultProps = {
  separationData: {},
  handleSubmit: () => {},
  handleCancel: () => {},
  acceptingManagerOptions: [],
  isEdit: false,
  loading: false,
};

SeparationForm.propTypes = {
  separationData: PropTypes.shape({}),
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  acceptingManagerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  isEdit: PropTypes.bool,
  loading: PropTypes.bool,
};

export default SeparationForm;
