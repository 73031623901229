export const earnings = [
  {
    title: 'Earnings (from Salary)',
    perPeriod: '₹10000',
    annual: '₹10000',
  },
  {
    title: 'Benefits',
    perPeriod: '₹10000',
    annual: '₹10000',
  },
  {
    title: 'Reimbursements',
    perPeriod: '₹10000',
    annual: '₹10000',
  },
  {
    title: 'Gross Earnings',
    perPeriod: '₹10000',
    annual: '₹10000',
  },
];

export const retirals = [
  {
    title: 'Employer’s Statutory Contributions',
    perPeriod: '₹10000',
    annual: '₹10000',
  },
  {
    title: 'Gratuity Contributions',
    perPeriod: '₹10000',
    annual: '₹10000',
  },
];

export const deductions = [
  {
    title: 'Voluntary Deductions',
    perPeriod: '₹10000',
    annual: '₹10000',
  },
  {
    title: 'Statutory Deductions',
    perPeriod: '₹10000',
    annual: '₹10000',
  },
  {
    title: 'Loans and Advances (Deductions)',
    perPeriod: '₹10000',
    annual: '₹10000',
  },
  {
    title: 'Gross Deductions',
    perPeriod: '₹10000',
    annual: '₹10000',
  },
];

export const others = [
  {
    title: 'Exemptions(Total)',
    perPeriod: '',
    annual: '₹10000',
  },
  {
    title: 'Total Taxable Income',
    perPeriod: '',
    annual: '₹10000',
  },
  {
    title: 'Total Deducted at Source (TDS)',
    perPeriod: '₹10000',
    annual: '₹10000',
  },
];
