import React, { useEffect, useState } from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import VerifyBackground from 'components/Organisms/Employee/EmployeeRecord/ActionList/VerifyBackground';
import { useThunk } from 'hooks/useThunk';
import { fetchVerifyBackground } from 'store';
import { Typography, Box, Skeleton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { verifyBackgroundAction } from 'api/employees';
import dayjs from 'dayjs';
import useToast from 'components/Provider/useToast';

const VerifyBackgroundPage = () => {
  const addToast = useToast();
  const navigate = useNavigate();
  const { userId } = useParams();

  const [loading, setLoading] = useState(false);
  const [getVerifyBackground, isLoadingVerifyBackground, loadingVerifyBackgroundError] =
    useThunk(fetchVerifyBackground);

  useEffect(() => {
    const query = new URLSearchParams({});
    query.append('id', userId);
    getVerifyBackground(query);
  }, [userId, getVerifyBackground]);

  const { verifyBackground } = useSelector((state) => state.employees);

  const employeeData = {
    supervisor: verifyBackground?.supervisor?.fullName,
    department: verifyBackground?.department?.name,
    designation: verifyBackground?.designation?.name,
    location: verifyBackground?.location?.name,
  };

  const handleSubmit = async ({ startDate, notes }) => {
    const newStartDate = startDate ? dayjs(startDate).format('MM/DD/YYYY') : '';
    const bodyFormData = new FormData();
    bodyFormData.append('empID', verifyBackground?.user?.employeeId);
    bodyFormData.append('CurrentAction', 'SaveAndSubmit');
    bodyFormData.append('effectiveStartDate', newStartDate);
    bodyFormData.append('notes', notes || '');

    try {
      setLoading(true);
      const response = await verifyBackgroundAction(bodyFormData);
      if (response?.data?.status === 'SUCCESS') {
        addToast({ type: 'success', title: 'SUCCESS', message: response?.data?.message });
        navigate(`/company/employee/view/${userId}`);
      } else {
        addToast({ type: 'error', title: 'ERROR', message: response?.data?.message });
      }
    } catch (error) {
      addToast({ type: 'error', title: 'ERROR', message: error?.response?.data?.message || error?.message });
    } finally {
      setLoading(false);
    }
  };

  if (loadingVerifyBackgroundError !== null) {
    return (
      <Typography variant="h6">
        {loadingVerifyBackgroundError?.message || `Error while loading details for employee id : ${userId}.`}
      </Typography>
    );
  }

  const breadcrumbData = [
    { id: 1, name: 'Employee List', path: '/company/employee/employees-list', isActive: false },
    { id: 2, name: 'Employee Records', path: `/company/employee/view/${userId}`, isActive: false },
    { id: 2, name: 'Background Verification', isActive: true },
  ];

  return isLoadingVerifyBackground ? (
    <Box sx={{ p: 4 }}>
      <Skeleton height={70} />
      <Skeleton />
      <Skeleton />
    </Box>
  ) : (
    <BlankTemplate
      pageHeading={`Employee: ${get(verifyBackground, 'fullName', '')} (${get(
        verifyBackground,
        'profile.employeeId',
        'NA',
      )})`}
      breadcrumbData={breadcrumbData}
      padding="3rem"
    >
      <VerifyBackground
        employeeData={employeeData}
        handleSubmit={handleSubmit}
        loading={loading}
        label="Background Verification"
        btnLabel="Update"
      />
    </BlankTemplate>
  );
};

export default VerifyBackgroundPage;
