import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import TitleWithText from 'components/Atoms/TitleWithText';
import Typographys from 'components/Atoms/Typography';

const Onboarding = ({ onboardingDetails }) => {
  return (
    <Grid container rowSpacing={1} columnSpacing={2}>
      <Grid item xs={12} mb={1}>
        <Typographys variant="h5" color="neutral.700">
          Onboarding
        </Typographys>
      </Grid>
      <Grid item xs={12} sm={4}>
        <TitleWithText title="Onboarding Reference" text={onboardingDetails.reference} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TitleWithText title="Blood Group" text={onboardingDetails.bloodGroup} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TitleWithText title="Separation Type" text={onboardingDetails.separationType} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TitleWithText title="Date of Leaving" text={onboardingDetails.dol} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TitleWithText title="Notice Period" text={onboardingDetails.noticePeriod} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TitleWithText title="Notice Period Unit" text={onboardingDetails.noticePeriodUnits} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TitleWithText title="Confirmation Date" text={onboardingDetails.doc} />
      </Grid>
    </Grid>
  );
};

Onboarding.defaultProps = {
  onboardingDetails: {
    reference: 'Other’s',
    bloodGroup: 'B+',
    separationType: 'Not Applicable',
    dol: '-',
    noticePeriod: '0 Months',
    noticePeriodUnits: '',
    doj: '-',
  },
};

Onboarding.propTypes = {
  onboardingDetails: PropTypes.shape({
    reference: PropTypes.string,
    bloodGroup: PropTypes.string,
    separationType: PropTypes.string,
    doj: PropTypes.string,
    noticePeriod: PropTypes.string,
    noticePeriodUnits: PropTypes.string,
    dol: PropTypes.string,
  }),
};

export default Onboarding;
