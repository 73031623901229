import { Box, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchModifyLeaveRequest, fetchLeavesCreateData } from 'store';
import { isArray, isEmpty } from 'lodash';
import dayjs from 'dayjs';
import { useThunk } from 'hooks/useThunk';
import { useSelector } from 'react-redux';
import { saveMyLeaveRequest, modifyLeaveRequestAction } from 'api/leave';
import useToast from 'components/Provider/useToast';
import CreateLeaveRequestTemplate from 'components/Templates/Leave/MyLeaves/MyLeaveRequests/CreateLeaveRequests';
import Dialog from 'components/Molecules/Dialog';
import useColumns from './useColumns';
import useLeaveServices from '../list/useLeaveServices';

const formatPreCreateResponse = (response) => {
  if (!response) {
    return {};
  }

  const { leaveCategories = [], leaveType = {} } = response;

  const retObj = {
    leaveCategories: leaveCategories?.length
      ? leaveCategories?.map((cat) => ({ id: cat.id, name: cat.displayName }))
      : [],
    leaveType: !isEmpty(leaveType) ? Object.values(leaveType).map((type) => ({ id: type, name: type })) : [],
  };

  return retObj;
};

const AddEditMyLeave = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const location = useLocation();
  const isEditMode = location.pathname.includes('edit');
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const addToast = useToast();
  const [columns] = useColumns();
  const leaveServices = useLeaveServices();

  const initialValues = {
    leaveCategory: '',
    leaveType: '',
    startDate: '',
    endDate: '',
    numberOfDays: '',
    numberOfHours: '',
    description: '',
    attachments: '',
    dateOfJoining: '',
  };

  const [getCreateLeave, isLoadingCreateLeave, loadingCreateLeaveError] = useThunk(fetchLeavesCreateData);
  const [getLeaveDetails, isLoadingLeaveDetails, loadingLeaveDetailsError] = useThunk(fetchModifyLeaveRequest);

  useEffect(() => {
    if (isEditMode) {
      const bodyFormData = new FormData();
      bodyFormData.append('id', id);
      getLeaveDetails(bodyFormData);
    } else {
      const getCreateLeaveData = async () => {
        const query = new URLSearchParams({});
        await getCreateLeave(query);
      };
      getCreateLeaveData();
    }
  }, [isEditMode, id, getLeaveDetails, getCreateLeave]);

  const { createLeavesData = {}, modifyLeaveRequestData = {} } = useSelector((state) => state?.myLeaves);
  const resData = isEditMode
    ? { ...modifyLeaveRequestData, leaveType: modifyLeaveRequestData?.leaveTypesMap || {} }
    : createLeavesData;
  const formattedResult = formatPreCreateResponse(resData);

  if (isEditMode) {
    initialValues.leaveCategory = modifyLeaveRequestData?.leaveRequestInstance?.leaveCategory?.id;
    initialValues.leaveType = modifyLeaveRequestData?.leaveRequestInstance?.leaveType;
    initialValues.startDate = modifyLeaveRequestData?.leaveRequestInstance?.startDate
      ? dayjs(modifyLeaveRequestData?.leaveRequestInstance?.startDate)
      : '';
    initialValues.endDate = modifyLeaveRequestData?.leaveRequestInstance?.endDate
      ? dayjs(modifyLeaveRequestData?.leaveRequestInstance?.endDate)
      : '';
    initialValues.numberOfDays = modifyLeaveRequestData?.leaveRequestInstance?.numberOfDays;
    initialValues.numberOfHours = modifyLeaveRequestData?.leaveRequestInstance?.numberOfHours;
    initialValues.description = modifyLeaveRequestData?.leaveRequestInstance?.description;
    initialValues.attachment = modifyLeaveRequestData?.leaveRequestInstance?.attachment?.fileName || '';
    initialValues.dateOfJoining = dayjs(JSON.parse(modifyLeaveRequestData?.jsonObj || '{}')?.dateOfJoining || '');
  } else {
    initialValues.dateOfJoining = dayjs(JSON.parse(createLeavesData?.jsonObj || '{}')?.dateOfJoining || '');
  }

  const createLeave = async (data, isDraft) => {
    const startDate = data.startDate ? dayjs(data.startDate).format('MM/DD/YYYY') : '';
    const endDate = data.endDate ? dayjs(data.endDate).format('MM/DD/YYYY') : '';
    const body = {
      description: data.description,
      'leaveCategory.id': data.leaveCategory,
      leaveType: data.leaveType,
      numberOfDays: data.numberOfDays,
      startDate,
      endDate,
      numberOfHours: data.numberOfHours,
      attachment: data?.attachments,
      opType: isDraft ? 'save' : 'saveSubmit',
      isSandwichRuleApplicable: data.isSandwichRuleApplicable,
    };

    try {
      setLoading(true);
      const bodyFormData = new FormData();
      Object.keys(body).forEach((key) => {
        if (key !== 'isSandwichRuleApplicable' && body[key] && !(isArray(body[key]) && isEmpty(body[key]))) {
          bodyFormData.append(key, body[key]);
        }
      });

      if (isEditMode) {
        bodyFormData.append('id', id);
      }

      const apiCall = isEditMode ? modifyLeaveRequestAction : saveMyLeaveRequest;
      const response = await apiCall(bodyFormData);

      if (response?.data?.status === 'SUCCESS') {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Leave request ${isEditMode ? 'updated' : 'added'} successfully`,
        });
        if (isEditMode) {
          navigate(`/home/leaves/my-leave-requests/${id}/view`);
        } else {
          navigate(`/home/leaves/my-leave-requests/${response?.data?.id}/view`);
        }
      } else {
        addToast({
          type: 'error',
          title: 'ERROR',
          message: response?.data?.message || 'Leave request Unsuccessful',
        });
      }
    } catch (err) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: err?.message || `Failed to ${isEditMode ? 'update' : 'add'} leave request`,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDraft = async (data) => {
    createLeave(data, true);
  };

  if ((isEditMode && isLoadingLeaveDetails) || isLoadingCreateLeave) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={42} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if ((isEditMode && loadingLeaveDetailsError !== null) || loadingCreateLeaveError !== null) {
    return <Typography variant="h6">Error while loading current details</Typography>;
  }

  const breadcrumbData = [
    { id: 1, name: 'My Leave Requests', path: '/home/leaves/my-leave-requests', isActive: false },
    { id: 2, name: isEditMode ? 'Edit' : 'Add', isActive: true },
  ];

  return (
    <Box>
      <CreateLeaveRequestTemplate
        pageHeading={isEditMode ? 'Edit Leave Request' : 'Add Leave Request '}
        breadcrumbData={breadcrumbData}
        loading={loading}
        preModifyData={resData || {}}
        columns={columns}
        rows={resData?.leaveAccounts?.length ? resData?.leaveAccounts : []}
        handleDraft={handleDraft}
        handleSubmit={createLeave}
        handleCancel={() => navigate('/home/leaves/my-leave-requests')}
        leaveTypeOptions={formattedResult?.leaveType}
        leaveCategoryOptions={formattedResult?.leaveCategories}
        isEdit={isEditMode}
        leaveFormData={initialValues}
        handleDelete={() => setOpenDialog(!openDialog)}
      />

      {isEditMode && modifyLeaveRequestData?.leaveRequestInstance !== null && (
        <Dialog
          open={openDialog}
          dialogTitle="Delete Leave"
          dialogDesc={`Are you sure you want to delete ${modifyLeaveRequestData?.leaveRequestInstance?.leaveType}?`}
          primaryBtn="Delete"
          secondaryBtn="Cancel"
          primaryBtnColor="error"
          handleSecondaryBtn={() => setOpenDialog(!openDialog)}
          handlePrimaryBtn={() => leaveServices.deleteLeave(modifyLeaveRequestData?.leaveRequestInstance?.id, false)}
          bodyContent={false}
          handleClose={() => setOpenDialog(!openDialog)}
        />
      )}
    </Box>
  );
};

export default AddEditMyLeave;
