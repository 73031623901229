const getHandleSubmit = (label) => {
  return (data, props) => {
    const { selectedIndex, setTabs, setAllTabsData } = props;

    setTabs((prevTabs) =>
      prevTabs.map((tab, idx) => {
        return {
          ...tab,
          isDisabled: idx <= selectedIndex + 1 ? 0 : 1,
        };
      }),
    );
    setAllTabsData((prevAllData) => {
      return {
        ...prevAllData,
        [label]: data,
      };
    });
  };
};

export default getHandleSubmit;
