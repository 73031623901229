import React from 'react';
import PropTypes from 'prop-types';
import Typographys from 'components/Atoms/Typography';
import { Box } from '@mui/material';
import SuccessStateSVG from 'assets/images/SuccessState.svg';
import ErrorStateSVG from 'assets/images/ErrorState.svg';
import { actionPagesStyle } from './style';

const ResponsePage = ({ titleText, responseMessage, pageType }) => {
  return (
    <Box sx={actionPagesStyle.wrapper}>
      <Box sx={actionPagesStyle.formWrapperIcon} component="form" noValidate>
        {pageType === 'success' && (
          <Box
            component="img"
            sx={{
              display: 'block',
              maxWidth: '20rem',
            }}
            alt="Images"
            src={SuccessStateSVG}
          />
        )}

        {pageType === 'error' && (
          <Box
            component="img"
            sx={{
              display: 'block',
              maxWidth: '20rem',
            }}
            alt="Images"
            src={ErrorStateSVG}
          />
        )}
      </Box>
      <Box sx={actionPagesStyle.formWrapperText} component="form" noValidate>
        <Typographys variant="h2" align="center" color="neutral.700">
          {titleText}
        </Typographys>
        <Typographys variant="h5" align="center" color="neutral.700">
          {responseMessage}
        </Typographys>
      </Box>
    </Box>
  );
};

ResponsePage.defaultProps = {
  titleText: 'Thank You!',
  responseMessage: 'Cannot reject leave at this moment.',
};

ResponsePage.propTypes = {
  titleText: PropTypes.string,
  responseMessage: PropTypes.string,
};

export default ResponsePage;
