import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import Breadcrumb from 'components/Molecules/Breadcrumbs';
import Typographys from 'components/Atoms/Typography';
import DropdownMenu from 'components/Molecules/DropdownMenu';
import { dashboardLayoutStyle } from './style';

const DashboardTemplate = ({
  isBreadcrumb,
  children,
  pageHeading,
  btnLabel,
  isActionMenuLists,
  actionMenuLists,
  isActionButton,
  handleAction,
  actionLabel,
  pageTitleVariant,
  breadcrumbData,
  minWidth = '',
}) => {
  return (
    <Box>
      {isBreadcrumb && (
        <Box mb={1.5}>
          <Breadcrumb breadcrumbs={breadcrumbData} />
        </Box>
      )}
      <Box sx={dashboardLayoutStyle.sectionHeader}>
        <Typographys variant={pageTitleVariant} color="neutral.800">
          {pageHeading}
        </Typographys>

        {!!actionMenuLists?.length > 0 && (
          <Box sx={dashboardLayoutStyle.actionBtn}>
            <DropdownMenu btnLabel={btnLabel} size="medium" menuLists={actionMenuLists} minWidth={minWidth} />
          </Box>
        )}

        {isActionButton && (
          <Button variant="outlined" onClick={handleAction}>
            {actionLabel}
          </Button>
        )}
      </Box>
      <Box sx={dashboardLayoutStyle.wrapper}>{children}</Box>
    </Box>
  );
};
DashboardTemplate.defaultProps = {
  pageHeading: 'Enter Heading',
  btnLabel: 'Quick Actions',
  isActionMenuLists: true,
  actionMenuLists: [],
  children: null,
  pageTitleVariant: 'h4',
  isActionButton: false,
  handleAction: () => {},
  actionLabel: 'Quick Actions',
  isBreadcrumb: true,
  breadcrumbData: [],
  minWidth: '',
};
DashboardTemplate.propTypes = {
  pageTitleVariant: PropTypes.string,
  pageHeading: PropTypes.string,
  btnLabel: PropTypes.string,
  isActionMenuLists: PropTypes.bool,
  actionMenuLists: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, label: PropTypes.string, onClick: PropTypes.func }),
  ),
  children: PropTypes.node,
  isActionButton: PropTypes.bool,
  handleAction: PropTypes.func,
  actionLabel: PropTypes.string,
  isBreadcrumb: PropTypes.bool,
  breadcrumbData: PropTypes.arrayOf(PropTypes.shape()),
  minWidth: PropTypes.string,
};
export default DashboardTemplate;
