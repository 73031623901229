import React, { useEffect, useState } from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import ExtendProbation from 'components/Organisms/Employee/EmployeeRecord/ActionList/ExtendProbation';
import { useThunk } from 'hooks/useThunk';
import { fetchExtendProbation } from 'store';
import { Typography, Box, Skeleton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { urlEncodedApi } from 'api/api';
import dayjs from 'dayjs';
import useToast from 'components/Provider/useToast';

const ExtendProbationPage = () => {
  const addToast = useToast();
  const navigate = useNavigate();
  const { userId } = useParams();
  const [loading, setLoading] = useState(false);
  const [getExtendProbation, isLoadingExtendProbation, loadingExtendProbationError] = useThunk(fetchExtendProbation);

  useEffect(() => {
    const query = new URLSearchParams({});
    query.append('id', userId);
    getExtendProbation(query);
  }, [userId, getExtendProbation]);

  const { extendProbation } = useSelector((state) => state.employees);

  const employeeData = {
    supervisor: extendProbation?.employeeInstance?.supervisor?.fullName,
    department: extendProbation?.employeeInstance?.department?.name,
    designation: extendProbation?.employeeInstance?.designation?.name,
    location: extendProbation?.employeeInstance?.location?.name,
  };

  const supervisorOptions = extendProbation?.employeeList;
  const departmentOptions = extendProbation?.departmentsList;
  const designationOptions = extendProbation?.designationsList;
  const locationOptions = extendProbation?.locationsList;
  const userData = extendProbation?.user;

  const handleSubmit = async ({ startDate, endDate, notes }) => {
    const newStartDate = startDate ? dayjs(startDate).format('MM/DD/YYYY') : '';
    const newEndDate = endDate ? dayjs(endDate).format('MM/DD/YYYY') : '';
    const newNotes = notes || '';
    const bodyFormData = new FormData();
    bodyFormData.append('empID', extendProbation?.user?.employeeId);
    bodyFormData.append('effectiveStartDate', newStartDate);
    bodyFormData.append('effectiveEndDate', newEndDate);
    bodyFormData.append('notes', newNotes);

    try {
      setLoading(true);
      const response = await urlEncodedApi.post(`restEmpIndex/extendProbationEmployeeAction`, bodyFormData);
      if (response?.data?.status === 'SUCCESS') {
        addToast({ type: 'success', title: 'SUCCESS', message: response?.data?.message });
        navigate(`/company/employee/view/${userId}`);
      } else {
        addToast({ type: 'error', title: 'ERROR', message: response?.data?.message });
      }
    } catch (error) {
      addToast({ type: 'error', title: 'ERROR', message: error?.response?.data?.message || error?.message });
    } finally {
      setLoading(false);
    }
  };

  if (loadingExtendProbationError !== null) {
    return (
      <Typography variant="h6">
        {loadingExtendProbationError?.message || 'Error while loading current details'}
      </Typography>
    );
  }

  const breadcrumbData = [
    { id: 1, name: 'Employee List', path: '/company/employee/employees-list', isActive: false },
    { id: 2, name: 'Employee Records', path: `/company/employee/view/${userId}`, isActive: false },
    { id: 2, name: 'Extend Probation', isActive: true },
  ];

  return isLoadingExtendProbation ? (
    <Box sx={{ p: 4 }}>
      <Skeleton height={70} />
      <Skeleton />
      <Skeleton />
    </Box>
  ) : (
    <BlankTemplate
      pageHeading={`Extend Employee Probation: ${get(extendProbation, 'employeeInstance.fullName', '')} (${get(
        extendProbation,
        'employeeInstance.profile.employeeId',
        'NA',
      )})`}
      breadcrumbData={breadcrumbData}
      padding="3rem"
    >
      <ExtendProbation
        employeeData={employeeData}
        supervisorOptions={supervisorOptions}
        departmentOptions={departmentOptions}
        designationOptions={designationOptions}
        locationOptions={locationOptions}
        userData={userData}
        handleSubmit={handleSubmit}
        loading={loading}
        label="Probation Extention"
        btnLabel="Extend Probation"
      />
    </BlankTemplate>
  );
};

export default ExtendProbationPage;
