import { useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import FormData from 'form-data';
import useToast from 'components/Provider/useToast';
import useSelection from 'hooks/useSelection';
import {
  bulkUpdateEmployeeLifeCycleMappings,
  viewEmployeeLifeCyclePolicyMappings,
  viewEmployeesMappedToLifeCyclePolicy,
  updateEmployeesMappedToLifeCyclePolicyAction,
  bulkUpdateEmployeeLifeCycleMappingsAction,
} from 'api/employees';

const initialPolicyState = {
  policyListItems: [],
  currentlyMappedEmployeesList: [],
  allEmployees: [],
  policyInstance: {},
  lcPolicies: [],
  openDeletePolicyDialog: false,
  loading: { empLoading: false, tabLoading: false, changePolicyLoading: false, bulkUpdatePolicyLoading: false },
};

const reducer = (state, action) => {
  const { type, payload } = action;
  let newState;
  switch (type) {
    case 'VIEW_MAPPED_EMPLOYEES': {
      const { currentlyMappedEmployeesList, allEmployees, policyInstance } = payload;
      newState = { ...state, currentlyMappedEmployeesList, allEmployees, policyInstance };
      break;
    }
    case 'OPEN_DELETE_POLICY_DIALOG': {
      newState = { ...state, openDeletePolicyDialog: !state.openDeletePolicyDialog };
      break;
    }
    case 'BULK_UPDATE_EMP_MAPPING': {
      const { lifeCyclePolicyInstanceList } = payload;
      newState = { ...state, lcPolicies: lifeCyclePolicyInstanceList };
      break;
    }
    case 'CHANGE_EMP_LOADING_STATE': {
      newState = { ...state, loading: { ...state.loading, empLoading: payload } };
      break;
    }
    case 'CHANGE_POLICY_LOADING_STATE': {
      newState = { ...state, loading: { ...state.loading, changePolicyLoading: payload } };
      break;
    }
    case 'BULK_UPDATE_POLICY_LOADING_STATE': {
      newState = { ...state, loading: { ...state.loading, bulkUpdatePolicyLoading: payload } };
      break;
    }
    case 'TAB_LOADING_STATE': {
      newState = { ...state, loading: { ...state.loading, tabLoading: payload } };
      break;
    }
    default:
      newState = state;
  }

  return newState;
};
const usePolicyServices = () => {
  const [policyState, dispatch] = useReducer(reducer, initialPolicyState);
  const addToast = useToast();
  const navigate = useNavigate();

  const [selectedEmployees, handleSelect, handleSelectAll, handleClearSelected] = useSelection(
    policyState.allEmployees,
  );

  const handlePreSelectdEmployees = (mappedEmployees) => {
    const event = { target: { checked: true } };
    mappedEmployees.forEach((item) => {
      handleSelect(event, item.id);
    });
  };

  const handlePolicyItemClick = async (policyInstance) => {
    try {
      dispatch({ type: 'CHANGE_EMP_LOADING_STATE', payload: true });
      handleClearSelected();
      const bodyFormData = new FormData();
      bodyFormData.append('id', policyInstance.id);
      const response = await viewEmployeesMappedToLifeCyclePolicy(bodyFormData);
      if (response.data) {
        dispatch({ type: 'VIEW_MAPPED_EMPLOYEES', payload: response.data });
        handlePreSelectdEmployees(response.data.currentlyMappedEmployeesList);
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || 'Something went wrong, try again',
      });
    } finally {
      dispatch({ type: 'CHANGE_EMP_LOADING_STATE', payload: false });
    }
  };

  const handlePolicySearch = () => {};

  const handleSearchAdd = () => {};

  const handleDetails = (item) => {
    navigate(`/company/employee/company-setup/lifecyle-policies/view/${item?.id}`);
  };

  const handleEditLCPolicy = (item) => {
    navigate(`/company/employee/company-setup/lifecyle-policies/${item.id}/edit`);
  };

  const handleOpenDeletePolicyDialog = () => {
    dispatch({ type: 'OPEN_DELETE_POLICY_DIALOG' });
  };

  const handleDeletePolicy = () => {};

  const handleTabChange = async (item) => {
    try {
      if (item === 1) {
        dispatch({ type: 'TAB_LOADING_STATE', payload: true });
        const response = await bulkUpdateEmployeeLifeCycleMappings();
        if (response.data) {
          dispatch({ type: 'BULK_UPDATE_EMP_MAPPING', payload: response.data });
        }
      } else if (item === 2) {
        const response = await viewEmployeeLifeCyclePolicyMappings();
        if (response.data) {
          dispatch({ type: 'BULK_UPDATE_EMP_MAPPING', payload: response.data });
        }
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || 'Something went wrong, try again',
      });
    } finally {
      dispatch({ type: 'TAB_LOADING_STATE', payload: false });
    }
  };

  const updatePolicyMappingToPolicyMapping = async () => {
    try {
      dispatch({ type: 'CHANGE_POLICY_LOADING_STATE', payload: true });
      const bodyFormData = new FormData();
      bodyFormData.append('id', policyState.policyInstance.id);
      selectedEmployees?.forEach((element) => {
        bodyFormData.append(`emp_${element}`, 'on');
      });
      const response = await updateEmployeesMappedToLifeCyclePolicyAction(bodyFormData);
      if (response.data) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Life cycle policy updated successfully`,
        });
        // dispatch({ type: 'VIEW_MAPPED_EMPLOYEES', payload: response.data });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || 'Something went wrong, try again',
      });
    } finally {
      dispatch({ type: 'CHANGE_POLICY_LOADING_STATE', payload: false });
    }
  };

  const changePolicyHandleClick = async (item) => {
    try {
      dispatch({ type: 'BULK_UPDATE_POLICY_LOADING_STATE', payload: true });
      const bodyFormData = new FormData();
      Object.keys(item)?.forEach((element) => {
        bodyFormData.append(`emp_${element}`, item[element]);
      });
      const response = await bulkUpdateEmployeeLifeCycleMappingsAction(bodyFormData);
      if (response.data) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || 'Life cycle policies updated successfully',
        });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || 'Something went wrong, try again',
      });
    } finally {
      dispatch({ type: 'BULK_UPDATE_POLICY_LOADING_STATE', payload: false });
    }
  };

  return {
    selectedEmployees,
    handleSelect,
    handleSelectAll,
    handleClearSelected,
    policyState,
    handlePolicyItemClick,
    handlePolicySearch,
    handleSearchAdd,
    handleDetails,
    handleEditLCPolicy,
    handleOpenDeletePolicyDialog,
    handleDeletePolicy,
    handleTabChange,
    updatePolicyMappingToPolicyMapping,
    changePolicyHandleClick,
  };
};

export default usePolicyServices;
