import {
  getProfileBankDetails,
  getDependentDetails,
  getEducationHistoryDetails,
  getemploymentHistoryDetails,
  skillDetailsData,
  lifeeventDetailsData,
  visaDetailsData,
  getMyExitDetails,
  getBasicprofileDetails,
  getProfileAddresses,
  myExitDetailsData,
  myExitWithdrawDetails,
  modifyEmployeeExitDetails,
} from 'api/profile';
import { asyncThunkWrapper } from 'utils/AsyncThunkWrapper';

const fetchVisaDetails = asyncThunkWrapper('profile/visaDetails', visaDetailsData);
const fetchBankAccountDetails = asyncThunkWrapper('bankAccount/fetch', getProfileBankDetails);
const fetchBasicProfileDetails = asyncThunkWrapper('BasicProfile/fetch', getBasicprofileDetails);
const fetchSkillDetails = asyncThunkWrapper('profile/SkillDetails', skillDetailsData);
const fetchLifeEventDetails = asyncThunkWrapper('profile/LifeEventDetails', lifeeventDetailsData);
const fetchEmploymentHistory = asyncThunkWrapper('employmentHistory/fetch', getemploymentHistoryDetails);
const fetchEducationalHistoryDetails = asyncThunkWrapper('educationHistory/fetch', getEducationHistoryDetails);
const fetchDependents = asyncThunkWrapper('dependents/fetch', getDependentDetails);
const fetchMyExitDetails = asyncThunkWrapper('initiateSeparation/fetch', getMyExitDetails);
const fetchDisplayEmpExitDetails = asyncThunkWrapper('empExitDetails/fetch', myExitDetailsData);
const fetchProfileAddresses = asyncThunkWrapper('addresses/fetch', getProfileAddresses);
const fetchMyExitWithdrawDetails = asyncThunkWrapper('withdrawDetails/fetch', myExitWithdrawDetails);
const fetchModifyEmployeeExitDetails = asyncThunkWrapper('modifyEmployeeExitDetails/fetch', modifyEmployeeExitDetails);

export {
  fetchBankAccountDetails,
  fetchDependents,
  fetchMyExitDetails,
  fetchDisplayEmpExitDetails,
  fetchBasicProfileDetails,
  fetchProfileAddresses,
  fetchEducationalHistoryDetails,
  fetchEmploymentHistory,
  fetchSkillDetails,
  fetchLifeEventDetails,
  fetchVisaDetails,
  fetchMyExitWithdrawDetails,
  fetchModifyEmployeeExitDetails,
};
