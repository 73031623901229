import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@mui/material';
import MuiTab from 'components/Molecules/Tab';
import AttendanceStats from 'components/Organisms/Attendance/AttendanceStats';
import AttendanceTimer from 'components/Organisms/Attendance/AttendanceTimer';
import AttendanceTimerStats from 'components/Organisms/Attendance/AttendanceTimerStats';
import { hasAnyPermission } from 'utils/permissions';
import { useSelector } from 'react-redux';
import MyAttendance from '../MyAttendance';
import { attendanceStyle } from '../style';

const AttendanceTab = ({
  timelineData,

  myAttendanceRows,
  myAttendanceColumns,
  myAttendanceHandleRowClick,
  myAttendancePage,
  myAttendanceSortBy,
  myAttendanceSortDirection,
  myAttendanceHandleSelect,
  myAttendanceOnSelectedChange,
  myAttendanceLoading,
  myAttendancekeyColumn,
  handleFilter,
  handleAttendanceRegularize,
  handleAttendanceDownload,
  handleMyAttendanceActionSelect,
  handleMyAttendanceThisWeek,
  handleMyAttendanceThisMonth,
  handleMyAttendanceThisYear,
  handleMyAttendanceDateRange,
  daysData,
  startTime,
  endTime,

  avgHours,
  arrivalTime,
  overtimeHrs,
  handleSelect,
  handleThisWeek,
  handleThisMonth,
  handleThisYear,
}) => {
  const authorities = useSelector((state) => state.session.user.authorities);

  const initialTabs = [
    {
      label: 'My Attendance',
      onChange: () => {},
      RenderComponent: MyAttendance,
      permissions: [
        'ROLE_BASE_PERMISSION_ATT_INDEX_MY_ATTENDANCE_THIS_WEEK',
        'ROLE_BASE_PERMISSION_ATT_INDEX_MY_ATTENDANCE_THIS_MONTH',
        'ROLE_BASE_PERMISSION_ATT_INDEX_MY_ATTENDANCE_CUSTOM_PERIOD',
      ],
      componentProps: {
        myAttendanceRows,
        myAttendanceColumns,
        myAttendanceHandleRowClick,
        myAttendancePage,
        myAttendanceSortBy,
        myAttendanceSortDirection,
        myAttendanceHandleSelect,
        myAttendanceOnSelectedChange,
        myAttendanceLoading,
        myAttendancekeyColumn,
        handleFilter,
        handleAttendanceRegularize,
        handleAttendanceDownload,
        handleMyAttendanceActionSelect,
        handleMyAttendanceThisWeek,
        handleMyAttendanceThisMonth,
        handleMyAttendanceThisYear,
        handleMyAttendanceDateRange,
      },
    },

    {
      label: 'Attendance Timer',
      onChange: () => {},
      RenderComponent: AttendanceTimer,
      permissions: ['ROLE_BASE_PERMISSION_ATT_INDEX_VIEW_MY_TIMER'],
      componentProps: {
        timelineData,
      },
    },
  ];

  const actionableTabs = initialTabs.filter((tab) => hasAnyPermission(authorities, tab.permissions));

  return (
    <Box>
      <Grid container spacing={2} sx={attendanceStyle.container}>
        <Grid item xs={12} md={6} sx={attendanceStyle.item}>
          <Box height="100%">
            <AttendanceStats
              avgHours={avgHours}
              arrivalTime={arrivalTime}
              overtimeHrs={overtimeHrs}
              handleSelect={handleSelect}
              handleThisWeek={handleThisWeek}
              handleThisMonth={handleThisMonth}
              handleThisYear={handleThisYear}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6} sx={attendanceStyle.item}>
          <Box height="100%">
            <AttendanceTimerStats daysData={daysData} startTime={startTime} endTime={endTime} />
          </Box>
        </Grid>
      </Grid>

      <Box sx={attendanceStyle.tabWrapper}>
        <MuiTab
          key={actionableTabs}
          orientation="horizontal"
          variant="scrollable"
          scrollButtons={false}
          textColor="primary"
          indicatorColor="primary"
          tabs={actionableTabs}
          panelPadding="2rem 0rem"
        />
      </Box>
    </Box>
  );
};

AttendanceTab.defaultProps = {
  timelineData: [],
  myAttendanceColumns: [],
  myAttendanceRows: [],
  myAttendancePage: 1,
  myAttendanceSortBy: 'id',
  myAttendanceSortDirection: 'asc',
  myAttendancekeyColumn: 'id',
  myAttendanceHandleRowClick: () => {},
  myAttendanceHandleSelect: () => {},
  myAttendanceOnSelectedChange: () => {},
  myAttendanceLoading: false,
  handleFilter: () => {},
  handleAttendanceRegularize: () => {},
  handleAttendanceDownload: () => {},
  handleMyAttendanceThisWeek: () => {},
  handleMyAttendanceThisMonth: () => {},
  handleMyAttendanceThisYear: () => {},
  handleMyAttendanceDateRange: () => {},
  handleMyAttendanceActionSelect: () => {},

  avgHours: '',
  arrivalTime: '',
  overtimeHrs: '',
  handleSelect: () => {},
  handleThisWeek: () => {},
  handleThisMonth: () => {},
  handleThisYear: () => {},

  daysData: {},
  startTime: '',
  endTime: '',
};

AttendanceTab.propTypes = {
  timelineData: PropTypes.shape({
    title: PropTypes.string,
    desc: PropTypes.string,
    iconKey: PropTypes.string,
  }),

  myAttendanceColumns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),

  myAttendanceRows: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.string,
      timeIn: PropTypes.string,
      timeOut: PropTypes.string,
      duration: PropTypes.string,
      interimPunches: PropTypes.string,
      attendance: PropTypes.string,
      exception: PropTypes.string,
      attendanceMarking: PropTypes.string,
    }),
  ),

  myAttendancePage: PropTypes.number,
  myAttendanceSortBy: PropTypes.string,
  myAttendanceSortDirection: PropTypes.string,
  myAttendanceHandleSelect: PropTypes.func,
  myAttendanceOnSelectedChange: PropTypes.func,
  myAttendanceHandleRowClick: PropTypes.func,
  myAttendanceLoading: PropTypes.bool,
  myAttendancekeyColumn: PropTypes.string,
  handleFilter: PropTypes.func,
  handleAttendanceRegularize: PropTypes.func,
  handleAttendanceDownload: PropTypes.func,
  handleMyAttendanceThisWeek: PropTypes.func,
  handleMyAttendanceThisMonth: PropTypes.func,
  handleMyAttendanceThisYear: PropTypes.func,
  handleMyAttendanceDateRange: PropTypes.func,
  handleMyAttendanceActionSelect: PropTypes.func,

  daysData: PropTypes.objectOf(
    PropTypes.shape({
      day: PropTypes.string,
      duration: PropTypes.shape({
        hour: PropTypes.number,
        min: PropTypes.number,
      }),

      break: PropTypes.shape({
        hour: PropTypes.number,
        min: PropTypes.number,
      }),

      overtime: PropTypes.shape({
        hour: PropTypes.number,
        min: PropTypes.number,
      }),
    }),
  ),

  startTime: PropTypes.string,
  endTime: PropTypes.string,

  avgHours: PropTypes.string,
  arrivalTime: PropTypes.string,
  overtimeHrs: PropTypes.string,
  handleSelect: PropTypes.func,
  handleThisWeek: PropTypes.func,
  handleThisMonth: PropTypes.func,
  handleThisYear: PropTypes.func,
};

export default AttendanceTab;
