const columns = [
  {
    fieldName: `day`,
    label: 'Day',
    formatValue: ({ row, column }) => row[column.fieldName],
  },

  {
    fieldName: `timeIn`,
    label: 'First Time In',
    formatValue: ({ row, column }) => row[column.fieldName],
  },

  {
    fieldName: `timeOut`,
    label: 'Last Time Out',
    formatValue: ({ row, column }) => row[column.fieldName],
  },

  {
    fieldName: `duration`,
    label: 'Duration',
    formatValue: ({ row, column }) => row[column.fieldName],
  },

  {
    fieldName: `interimPunches`,
    label: 'Interim Punches',
    formatValue: ({ row, column }) => row[column.fieldName],
  },

  {
    fieldName: `attendance`,
    label: 'Attendance',
    formatValue: ({ row, column }) => row[column.fieldName],
  },

  {
    fieldName: `exception`,
    label: 'Exception',
    formatValue: ({ row, column }) => row[column.fieldName],
  },

  {
    fieldName: `attendanceMarking`,
    label: 'Attendance Marking',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
];

const useColumns = () => {
  return [columns];
};

export default useColumns;
