export const logoContainerStyle = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '42.25rem',
    padding: '0 4rem 0 3rem',
    boxSizing: 'border-box',
    borderRight: 1,
    borderColor: 'neutral.300',
    height: '100%',
    '@media (max-width: 900px)': {
      width: '100%',
      maxWidth: '9.375rem',
      marginRight: '1.25rem',
      padding: 0,
      borderRight: 0,
    },
  },
  hamburgericonwrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  hamburgericon: {
    margin: '0',
  },
  divider: {
    height: '8rem',
    marginLeft: '3rem',
  },
};
