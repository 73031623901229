import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Skeleton } from '@mui/material';
import SurveyDetails from 'components/Templates/Surveys/View';
import Typography from 'components/Atoms/Typography';
import { completeSurveyAction } from 'api/dashboard';
import { useThunk } from 'hooks/useThunk';
import { useSelector } from 'react-redux';
import useToast from 'components/Provider/useToast';
import { fetchSurveyDetails } from 'store';
import { hasPermission } from 'utils/permissions';

const ViewSurveys = () => {
  const { id } = useParams();
  const addToast = useToast();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [doFetchSurveyDetails, isLoadingSurveyDetails, loadingSurveyDetailsError] = useThunk(fetchSurveyDetails);

  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasViewPermission = hasPermission(authorities, ['ROLE_BASE_PERMISSION_SENTINEL_EMP']);

  useEffect(() => {
    const formData = new FormData();
    formData.append('id', id);
    doFetchSurveyDetails(formData);
  }, [doFetchSurveyDetails, id]);

  const { surveySheetDetails = {} } = useSelector((state) => state?.dashboard);
  const { surveySheetInstance = {} } = surveySheetDetails;

  const handleSubmit = async (formValues) => {
    setLoading(true);

    const bodyFormData = new FormData();
    bodyFormData.append('id', id);
    if (formValues?.length > 0) {
      formValues?.forEach((item) => {
        bodyFormData.append(`${item?.id}.finalGrade`, item?.gradingScaleVal);
      });
    }

    try {
      const response = await completeSurveyAction(bodyFormData);
      if (response?.status !== 'ERROR') {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.message || 'Survey details updated successfully',
        });
        navigate('/home/dashboard');
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || 'Failed to update survey details',
      });
    } finally {
      setLoading(false);
    }
  };

  if (!userHasViewPermission) {
    return <Typography variant="h6">You do not have permission to view survey details.</Typography>;
  }

  if (isLoadingSurveyDetails) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (loadingSurveyDetailsError) {
    return (
      <Typography variant="h6">
        {loadingSurveyDetailsError?.message || 'Error while fetching survey details.'}
      </Typography>
    );
  }

  const breadcrumbData = [{ id: 1, name: 'Survey Sheet Details', isActive: true }];

  return (
    <SurveyDetails
      pageHeading="Survey Sheet Details"
      breadcrumbData={breadcrumbData}
      loading={loading}
      handleSubmit={handleSubmit}
      surveySheetDetails={surveySheetInstance}
    />
  );
};

export default ViewSurveys;
