import { isEmpty } from 'lodash';

const useFilters = (filtersData, optionsData) => {
  const { filters = {} } = filtersData;
  const filterFields = [
    {
      name: 'Query number',
      filterName: 'queryNumber',
      type: 'number',
      value: filters?.queryNumber,
    },
    {
      name: 'Title',
      filterName: 'title',
      type: 'string',
      value: filters?.title,
    },
    {
      name: 'Resolution Status',
      filterName: 'resolutionStatus',
      type: 'dropdown',
      options: !isEmpty(optionsData?.resolutionStatusOptions)
        ? Object.values(optionsData?.resolutionStatusOptions).map((data) => ({
            name: data,
            id: data,
          }))
        : [],
      value: filters?.resolutionStatus,
    },
    {
      name: 'Opened On',
      filterName: 'openDate',
      type: 'date',
      value: filters?.openDate,
    },
    {
      name: 'Closed on',
      filterName: 'closeDate',
      type: 'date',
      value: filters?.closeDate,
    },
  ];
  return { filterFields };
};

export default useFilters;
