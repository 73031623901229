import PropTypes from 'prop-types';
import React from 'react';
import Dialog from 'components/Molecules/Dialog';
import Dropdown from 'components/Molecules/Dropdown';
import { Grid } from '@mui/material';
import { useFormik } from 'formik';

const AddDataFilter = ({
  secondaryBtn,
  primaryBtn,
  primaryBtnColor,
  handleCancel,

  statusOptions,
  typeOptions,
  subTypeOptions,
  entitiesOptions,

  open,
  handleClose,
  handleSubmit,
  initialValues,
  isModify,
}) => {
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (data) => {
      if (isModify) {
        data = { ...data, id: initialValues.id };
      }
      handleSubmit(data);
    },
  });

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      dialogTitle="Add Data Filter"
      dialogDesc={false}
      secondaryBtn={secondaryBtn}
      primaryBtn={isModify ? 'Modify Filter' : 'Create Filter'}
      handlePrimaryBtn={formik.handleSubmit}
      handleSecondaryBtn={handleCancel}
      primaryBtnColor={primaryBtnColor}
      bodyContent={
        <Grid component="form" onSubmit={formik.handleSubmit} container rowGap={2}>
          <Dropdown
            handleChange={(e) => {
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.filterStatus}
            name="filterStatus"
            id="filterStatus"
            label="Filter Status"
            options={statusOptions}
            align="center"
          />
          <Dropdown
            handleChange={(e) => {
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.filtertype}
            name="filtertype"
            id="filtertype"
            label="Filter Type"
            options={typeOptions}
          />
          <Dropdown
            handleChange={(e) => {
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.filtersubtype}
            name="filtersubtype"
            id="filtersubtype"
            label="Filter Sub Type"
            options={subTypeOptions}
          />
          <Dropdown
            handleChange={(e) => {
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.allowedentities}
            name="allowedentities"
            id="allowedentities"
            label="Allowed Entities"
            options={entitiesOptions}
          />
        </Grid>
      }
    />
  );
};
AddDataFilter.defaultProps = {
  primaryBtnColor: 'primary',
  secondaryBtn: 'Cancel',
  primaryBtn: 'Create Filter',
  handleCancel: () => {},
  handleSubmit: () => {},

  statusOptions: [
    { id: 1, name: 'Filter Status 1', onClick: () => {} },
    { id: 2, name: 'Filter Status 2', onClick: () => {} },
    { id: 3, name: 'Filter Status 3', onClick: () => {} },
    { id: 4, name: 'Filter Status 4', onClick: () => {} },
    { id: 5, name: 'Filter Status 5', onClick: () => {} },
    { id: 6, name: 'Filter Status 6', onClick: () => {} },
  ],

  typeOptions: [
    { id: 1, name: 'Filter Type 1', onClick: () => {} },
    { id: 2, name: 'Filter Type 2', onClick: () => {} },
    { id: 3, name: 'Filter Type 3', onClick: () => {} },
    { id: 4, name: 'Filter Type 4', onClick: () => {} },
    { id: 5, name: 'Filter Type 5', onClick: () => {} },
    { id: 6, name: 'Filter Type 6', onClick: () => {} },
  ],

  subTypeOptions: [
    { id: 1, name: 'Filter Sub Type 1', onClick: () => {} },
    { id: 2, name: 'Filter Sub Type 2', onClick: () => {} },
    { id: 3, name: 'Filter Sub Type 3', onClick: () => {} },
    { id: 4, name: 'Filter Sub Type 4', onClick: () => {} },
    { id: 5, name: 'Filter Sub Type 5', onClick: () => {} },
    { id: 6, name: 'Filter Sub Type 6', onClick: () => {} },
  ],

  entitiesOptions: [
    { id: 1, name: 'Allowed Entities 1', onClick: () => {} },
    { id: 2, name: 'Allowed Entities 2', onClick: () => {} },
    { id: 3, name: 'Allowed Entities 3', onClick: () => {} },
    { id: 4, name: 'Allowed Entities 4', onClick: () => {} },
    { id: 5, name: 'Allowed Entities 5', onClick: () => {} },
    { id: 6, name: 'Allowed Entities 6', onClick: () => {} },
  ],
  open: true,
  handleClose: () => {},
  initialValues: {
    filterStatus: '',
    filtertype: '',
    filtersubtype: '',
    allowedentities: '',
  },
};
AddDataFilter.propTypes = {
  handleSubmit: PropTypes.func,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  primaryBtnColor: PropTypes.string,
  handleCancel: PropTypes.func,
  secondaryBtn: PropTypes.string,
  primaryBtn: PropTypes.string,

  statusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  typeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),

  subTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),

  entitiesOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  initialValues: PropTypes.shape({
    filterStatus: PropTypes.string,
    filtertype: PropTypes.string,
    filtersubtype: PropTypes.string,
    allowedentities: PropTypes.string,
  }),
};
export default AddDataFilter;
