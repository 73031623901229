import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const initialState = {
  page: 0,
  sort: 'desc',
  sortBy: '',
};

const STRINGIFY = (x) => btoa(JSON.stringify(x));
export const PARSE = (x) => JSON.parse(atob(x));

const parseUrlParams = (urlParams) => {
  const parsedParams = new URLSearchParams(urlParams).entries();
  const params = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of parsedParams) {
    params[key] = key === 'filters' ? PARSE(value) : decodeURIComponent(value);
  }
  return params;
};

const useController = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const hasFilters = searchParams?.size > 0 ? searchParams : initialState;
  const obj = new URLSearchParams(hasFilters);
  const allFilters = Object.fromEntries(obj.entries());

  const controller = useMemo(() => {
    return searchParams?.size > 0 ? parseUrlParams(searchParams) : initialState;
  }, [searchParams]);

  const handlePageChange = (newPage) => {
    setSearchParams({ ...allFilters, page: Number(newPage) });
  };

  const handleSortChange = (event, property) => {
    const isAsc = allFilters.sortBy === property && allFilters.sort === 'asc';
    setSearchParams({ ...allFilters, page: 0, sortBy: property, sort: isAsc ? 'desc' : 'asc' });
  };

  const handleFiltersApply = (newFilters = []) => {
    const parsedFilters = Object.fromEntries(
      newFilters?.length > 0
        ? newFilters.map((filterItem) => [
            filterItem?.filterName,
            filterItem?.type === 'date' ? dayjs(filterItem?.value).format('MM/DD/YYYY') : filterItem?.value,
          ])
        : [],
    );

    const stringifiedFilter = STRINGIFY({
      ...parsedFilters,
    });
    setSearchParams({ ...allFilters, page: 0, filters: stringifiedFilter });
  };

  const handleFiltersClear = () => {
    setSearchParams({});
  };

  return [controller, handlePageChange, handleSortChange, handleFiltersApply, handleFiltersClear];
};

export default useController;
