import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Button from 'components/Atoms/Button';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';
import { hasPermission } from 'utils/permissions';
import MuiTab from 'components/Molecules/Tab';
import ExpensesLists from 'components/Organisms/MyExpenses/MyExpensesReportsTabs/ExpensesList';
import { expensesStyle } from '../style';

const ExpensesList = ({ handleAdd, expenseReports, onTabChange, loading, showFilter }) => {
  const initialTabs = [
    {
      label: 'All reports',
      RenderComponent: ExpensesLists,
      componentProps: { ...expenseReports, loading, showFilter },
    },
    {
      label: 'Draft reports',
      RenderComponent: ExpensesLists,
      componentProps: { ...expenseReports, loading, showFilter },
    },
    {
      label: 'Submitted reports',
      RenderComponent: ExpensesLists,
      componentProps: { ...expenseReports, loading, showFilter },
    },
  ];

  const authorities = useSelector((state) => state?.session?.user?.authorities);
  const userHasCreateExpensePermission = hasPermission(authorities, [
    'ROLE_BASE_PERMISSION_EXPENSE_INDEX_CREATE_EXPENSE_REPORT',
  ]);
  return (
    <Box mb={9}>
      {!!userHasCreateExpensePermission && (
        <Box sx={expensesStyle.actionBtn}>
          <Button startIcon={<AddIcon fontSize="2.5rem" />} onClick={handleAdd}>
            Add New
          </Button>
        </Box>
      )}
      <Box sx={expensesStyle.tabWrapper}>
        <MuiTab
          key={initialTabs}
          orientation="horizontal"
          variant="scrollable"
          scrollButtons={false}
          textColor="primary"
          indicatorColor="primary"
          tabs={initialTabs}
          allowScrollButtonsMobile
          panelPadding="2rem 0rem"
          onTabChange={onTabChange}
        />
      </Box>
    </Box>
  );
};

ExpensesList.defaultProps = {
  handleAdd: () => {},
  expenseReports: {},
  loading: false,
};

ExpensesList.propTypes = {
  handleAdd: PropTypes.func,
  expenseReports: PropTypes.shape({}),
  loading: PropTypes.bool,
};

export default ExpensesList;
