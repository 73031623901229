export const TosterStyle = {
  alertBox: {
    position: 'fixed',
    right: '0',
    top: '9rem',
    width: '42.25rem',
    transition: 'ease-in-out .3s all',
    zIndex: '9',
  },
  button: {
    marginTop: '2rem',
  },
};

export const perfomanceStyle = {
  submitWrapper: {
    display: 'flex',
    justifyContent: 'end',
    gap: 2,
  },

  btnContainer: {
    position: 'absolute',
    right: '0',
    bottom: '0',
    width: '100%',
    boxSizing: 'border-box',
    transition: 'ease-in-out .3s all',
    zIndex: '9',
    margin: '0rem',
    padding: '0rem 3rem 2rem 3rem',
  },

  button: {
    marginTop: '2rem',
  },
};
