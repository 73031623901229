import PropTypes from 'prop-types';
import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MenuList from './MenuList';

const MainSidebar = ({ open, drawerToggle, top, left }) => {
  const Drawer = styled(
    MuiDrawer,
    {},
  )(({ theme }) => ({
    '& .MuiDrawer-paper': {
      padding: '1.25rem',
      width: theme.constants.majorSidebarWidth,
      top,
      left,
      height: `calc(100% - ${top})`,
      '& .MuiListItemIcon-root': {
        marginRight: '1.25rem',
      },
      '& .MuiListItemButton-root': {
        padding: '1.25rem 2rem',
        marginBottom: '0',

        ':hover': {
          background: theme.palette.primary[100],
        },
        '& .MuiTypography-body2': {
          fontSize: '1.75rem',
          lineHeight: '2.75rem',
        },
      },
      '& .Mui-selected': {
        background: theme.palette.primary[100],
      },
      '& .MuiList-root': {
        ':after': {
          content: 'none',
        },
        '& .MuiList-root': {
          '& .MuiListItemButton-root': {
            paddingLeft: '2.75rem',
          },
          '& .MuiList-root': {
            '& .MuiListItemButton-root': {
              paddingLeft: '4.5rem',
            },
          },
        },
      },
    },
  }));
  const theme = useTheme();
  const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Drawer
      variant={matchUpSm ? 'persistent' : 'temporary'}
      anchor="left"
      open={open}
      onClose={drawerToggle}
      ModalProps={{ keepMounted: true }}
    >
      <PerfectScrollbar>
        <MenuList />
      </PerfectScrollbar>
    </Drawer>
  );
};

MainSidebar.defaultProps = {
  top: '0',
  left: '0',
  open: true,
  drawerToggle: () => {},
};

MainSidebar.propTypes = {
  drawerToggle: PropTypes.func,
  open: PropTypes.bool,
  top: PropTypes.string,
  left: PropTypes.string,
};

export default MainSidebar;
