import get from 'lodash/get';
import { useNavigate, useParams } from 'react-router-dom';

const useExitListService = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const viewOffboardingList = (row) => {
    navigate(`/myteam/separations/exit-list/${id}/view/${row.id}`);
  };

  const editOffboardingList = (row) => {
    navigate(`/myteam/separations/exit-list/${id}/edit/${row.id}`);
  };

  return {
    viewOffboardingList,
    editOffboardingList,
  };
};

export default useExitListService;
