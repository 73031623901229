import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import Button from 'components/Atoms/Button';
import DropdownMenu from 'components/Molecules/DropdownMenu';
import EditIcon from '@mui/icons-material/Edit';
import TitleWithText from 'components/Atoms/TitleWithText';
import { profileCardStyle } from 'components/Organisms/Employee/EmployeeRecord/EmployeeProfileCard/style';
import { useNavigate } from 'react-router-dom';

const SalaryStructureCard = ({ isAdmin, employeeProfileDetail, employmentInfo, menuLists, handleClick }) => {
  const navigate = useNavigate();
  return (
    <Box sx={profileCardStyle.wrapper}>
      <Box sx={{ ...profileCardStyle.actionWrapper, pl: 0 }}>
        <Box sx={profileCardStyle.action}>
          <Typographys variant="h4">{employeeProfileDetail.name}</Typographys>
          {isAdmin ? (
            <Box sx={profileCardStyle.actionBtn}>
              <Button startIcon={<EditIcon fontSize="2.5rem" />} onClick={handleClick}>
                Edit Salary Details
              </Button>
              <Box sx={{ ml: 1.5 }}>
                <DropdownMenu btnLabel="Actions" menuLists={menuLists} />
              </Box>
            </Box>
          ) : (
            <Button
              startIcon={<EditIcon fontSize="2.5rem" />}
              onClick={() => navigate('/company/employee/employees-list/records/salaryStructure/taxWorking')}
            >
              Tax Working
            </Button>
          )}
        </Box>

        <Grid container sx={profileCardStyle.employmentInfo}>
          <Grid item sm={4} md="auto">
            <TitleWithText padding="0" title="Date of Joining" text={employmentInfo.designation} />
          </Grid>
          <Grid item sm={4} md="auto">
            <TitleWithText padding="0" title="Effective From" text={employmentInfo.department} />
          </Grid>
          <Grid item sm={4} md="auto">
            <TitleWithText padding="0" title="Effective To" text={employmentInfo.supervisor} />
          </Grid>
          <Grid item sm={4} md="auto">
            <TitleWithText padding="0" title="Salary Structure Status" text={employmentInfo.employeeID} />
          </Grid>
          <Grid item sm={4} md="auto">
            <TitleWithText padding="0" title="Pay Structure" text={employmentInfo.employmentType} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

SalaryStructureCard.defaultProps = {
  employeeProfileDetail: {
    name: 'Uday Kumar',
    avatarSrc: 'images/avatar.jpg',
    progressBarValue: 67,
  },
  employmentInfo: {
    designation: 'Head',
    department: 'Marketing',
    supervisor: 'Achman Kabir',
    employeeID: '1001',
    employmentType: 'Employee',
  },
  menuLists: [
    { id: 1, label: 'Add New', onClick: () => {} },
    { id: 2, label: 'Bulk Upload', onClick: () => {} },
    { id: 3, label: 'Send ESS invite', onClick: () => {} },
    { id: 4, label: 'Download', onClick: () => {} },
  ],
  handleClick: () => {},
};

SalaryStructureCard.propTypes = {
  employeeProfileDetail: PropTypes.shape({
    name: PropTypes.string,
    avatarSrc: PropTypes.string,
    progressBarValue: PropTypes.number,
    location: PropTypes.string,
    email: PropTypes.string,
    mobile: PropTypes.string,
    phone: PropTypes.string,
    designation: PropTypes.string,
    department: PropTypes.string,
    supervisor: PropTypes.string,
    employeeID: PropTypes.string,
    employmentType: PropTypes.string,
  }),
  menuLists: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  employmentInfo: PropTypes.shape({
    designation: PropTypes.string,
    department: PropTypes.string,
    supervisor: PropTypes.string,
    employeeID: PropTypes.string,
    employmentType: PropTypes.string,
  }),
  handleClick: PropTypes.func,
};

export default SalaryStructureCard;
