import { useSelector } from 'react-redux';

import { hasPermission } from 'utils/permissions';
import { REPORT_STATUS_REJECTED, ENTITY_ORIGIN_SYSTEM_DEFINED } from 'utils/SystemConfigConstants';

const getActionItems = (services, userId) => {
  const id = services?.state?.expenseReportDetails?.id;
  return [
    {
      label: 'Submit Report',
      onClick: () => services.submitExpenseReport({ id, view: true }),
      id: 1,
      permissions: ['ROLE_BASE_PERMISSION_EXPENSE_INDEX_SUBMIT_MY_EXPENSE_REPORT_FOR_REVIEW'],
      conditional: (row) => row?.reportStatus?.toUpperCase() === 'DRAFT',
    },
    {
      label: 'Recall Report',
      onClick: () => services.recallExpenseReport(id),
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EXPENSE_INDEX_RECALL_MY_EXPENSE_REPORT'],
      conditional: (row) =>
        (row?.employeeId === userId && row?.reportStatus?.toUpperCase() === 'SUBMITTED') ||
        row?.reportStatus?.toUpperCase() === 'UNDER REVIEW',
    },
    {
      label: 'Delete',
      onClick: services.handleDeleteDialog,
      id: 4,
      permissions: ['ROLE_BASE_PERMISSION_EXPENSE_INDEX_DELETE_EXPENSE_REPORT'],
      conditional: (row) =>
        row?.entityOrigin?.toUpperCase() !== ENTITY_ORIGIN_SYSTEM_DEFINED &&
        row?.reportStatus?.toUpperCase() !== REPORT_STATUS_REJECTED,
    },
  ];
};

const useMenuItems = (services) => {
  const { user } = useSelector((state) => state?.session);
  const authorities = user?.authorities;

  const actions = getActionItems(services, user?.employeeID);

  const moreFilteredActionItems = actions.filter((action) => {
    const userHasPermission = hasPermission(authorities, action.permissions);

    if (
      (action.conditional && !action.conditional(services.state.expenseReportDetails.reportDetails)) ||
      !userHasPermission
    ) {
      return false;
    }
    return true;
  });

  return [moreFilteredActionItems];
};

export default useMenuItems;
