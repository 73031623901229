export const tabStyle = {
  tabWrapper: {
    width: '100%',
    display: 'flex',
    overflow: 'hidden',
  },

  divider: {
    backgroundColor: 'other.200',
    width: '0.125rem',
    height: 'calc(100vh - 35rem)',
  },

  tabPanelWrapper: {
    width: '100%',
    boxSizing: 'border-box',
  },
};
