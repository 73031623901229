import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Divider } from '@mui/material';
import TitleWithText from 'components/Atoms/TitleWithText';
import Typographys from 'components/Atoms/Typography';
import Button from 'components/Atoms/Button';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { onBoardingStyle } from '../../style';

const EmploymentHistory = ({ handleAttachment, employmentHistory }) => {
  return (
    <>
      <Typographys variant="h5" color="neutral.700">
        Employment History
      </Typographys>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 44rem)' }}>
          <Box mt={2}>
            {employmentHistory.map((list, i) => (
              <>
                <Typographys variant="subtitle2" align="left" color="neutral.800">
                  Record {i + 1}
                </Typographys>
                <Grid container rowSpacing={1} columnSpacing={2}>
                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Employer Name" text={list.employerName} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Employer Address" text={list.employerAddress} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Commence Date" text={list.commenceDate} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="End Date" text={list.endDate} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Reason for Leaving" text={list.reasonForLeaving} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Employee ID" text={list.employeeID} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Start Designation" text={list.startDesignation} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="End Designation" text={list.endDesignation} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Reference Contact Name" text={list.referenceName} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Reference Designation" text={list.referenceDesignation} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Reference Phone" text={list.referencePhone} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Reference Contact Email" text={list.referenceEmail} />
                  </Grid>

                  <Grid item xs={12}>
                    <Typographys variant="h6" color="neutral.600">
                      Attachment
                    </Typographys>

                    <Button
                      variant="text"
                      color="secondary"
                      onClick={() => handleAttachment(list?.attachmentId, list?.attachment)}
                      startIcon={<AttachFileIcon />}
                    >
                      {list?.attachment || ''}
                    </Button>
                  </Grid>
                </Grid>
                {i + 1 !== employmentHistory.length && <Divider sx={onBoardingStyle.divider} />}
              </>
            ))}
          </Box>
        </Box>
      </PerfectScrollbar>
    </>
  );
};

EmploymentHistory.defaultProps = {
  employmentHistory: [],
  handleAttachment: () => {},
};

EmploymentHistory.propTypes = {
  employmentHistory: PropTypes.arrayOf(
    PropTypes.shape({
      employerName: PropTypes.string,
      employerAddress: PropTypes.string,
      commenceDate: PropTypes.string,
      endDate: PropTypes.string,
      reasonForLeaving: PropTypes.string,
      employeeID: PropTypes.string,
      startDesignation: PropTypes.string,
      endDesignation: PropTypes.string,
      referenceName: PropTypes.string,
      referenceDesignation: PropTypes.string,
      referencePhone: PropTypes.string,
      referenceEmail: PropTypes.string,
      attachments: PropTypes.string,
    }),
  ),
  handleAttachment: PropTypes.func,
};

export default EmploymentHistory;
