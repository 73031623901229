import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLCRecordsPendingMyApproval } from 'store';
import { updateSelectedLCRecord, updateLCRecords } from 'store/slices/company/employees/employee';
import { useThunk } from 'hooks/useThunk';
import { Skeleton, Box, Typography } from '@mui/material';
import useController from 'hooks/useController';
import usePaginationAttributes from 'hooks/use-pagination-attributes';
import LCRecordTemplate from 'components/Templates/Employee/LifeCycleRecord';
import { useNavigate } from 'react-router-dom';
import useFetcher from 'hooks/useFetcher';
import { searchLifeCycleCaseRecords, searchLifeCycleCaseRecordsAction } from 'api/employees';
import { postListApiFormat, getListApiFormat } from 'utils/ApiPayloadFormat';
import DeleteLifeCycleRecord from 'components/Organisms/Dialog/ActionList/DeleteLifeCycleRecord';
import { hasPermission } from 'utils/permissions';
import useColumns from './useColumns';
import useActionItems from './useActionItems';
import useLCRecordServices from './useLCRecordServices';
import useFilters from './useFilters';

const LCRecordsPendingMyApprovalList = () => {
  const dispatch = useDispatch();
  const { pageSize } = usePaginationAttributes();
  const [columns] = useColumns();
  const [controller, handlePageChange, handleSortChange, handleFiltersApply, handleFiltersClear] = useController();
  const navigate = useNavigate();
  const services = useLCRecordServices();
  const [menuList] = useActionItems(services);

  const [doFetchEmployees, isLoadingEmployees, loadingEmployeesError] = useThunk(fetchLCRecordsPendingMyApproval);
  const [filterOptions] = useFetcher(searchLifeCycleCaseRecords);
  const filterService = useFilters(controller, filterOptions?.data);
  const { selectedLCRecord, lifeCycleCaseRecords } = useSelector((state) => state?.employees);

  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasViewPermission = hasPermission(authorities, ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_CASE_RECORD']);

  const fetchEmployeesAction = useCallback(async () => {
    if (controller?.filters && Object.keys(controller?.filters).length > 0) {
      const formData = postListApiFormat(controller, pageSize);
      const response = await searchLifeCycleCaseRecordsAction(formData);
      if (response.status === 200) {
        dispatch(updateLCRecords(response.data));
      }
    } else {
      const queryData = getListApiFormat(controller, pageSize);
      doFetchEmployees(queryData);
    }
  }, [doFetchEmployees, controller, pageSize]);

  useEffect(() => {
    fetchEmployeesAction();
  }, [fetchEmployeesAction, controller, pageSize, services?.refreshPage]);

  if (isLoadingEmployees) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (loadingEmployeesError !== null) {
    return (
      <Typography variant="h6">
        {loadingEmployeesError?.message || 'Error while loading employee life cycle case records list.'}
      </Typography>
    );
  }

  return (
    <Box>
      <LCRecordTemplate
        pageHeading="List Life Cycle Case Records Pending Approval"
        loading={isLoadingEmployees}
        handleBulkSubmit={() => {}}
        actionBulkMenuLists={[]}
        lcRecordRows={lifeCycleCaseRecords?.data}
        lcRecordColumns={columns}
        lcRecordRowsPerPage={10}
        lcRecordTotalRecordCount={lifeCycleCaseRecords?.count}
        lcRecordLoadNextPageData={handlePageChange}
        lcRecordHandleSort={handleSortChange}
        lcRecordHandleRowClick={(row) =>
          userHasViewPermission &&
          navigate(`/company/life-cycle/life-cycle-records-pending-approval/${row?.id}/${row?.changeType}/view`)
        }
        lcRecordPage={controller.page}
        lcRecordSortBy={controller.sortBy}
        lcRecordSortDirection={controller.sort}
        lcRecordHandleSelect={(row) => dispatch(updateSelectedLCRecord(row))}
        lcRecordOnSelectedChange={() => {}}
        actionMenuLists={menuList}
        handleCancel={() => {}}
        handleChangePolicy={() => {}}
        filterFields={filterService?.filterFields}
        handleFilter={(data) => handleFiltersApply(data)}
        handleFiltersClear={handleFiltersClear}
      />
      {selectedLCRecord !== null && (
        <DeleteLifeCycleRecord
          open={services.showDeleteDialog}
          handleClose={services.handleDeleteDialog}
          handleCancel={services.handleDeleteDialog}
          handleDelete={() => {
            services.deleteRecords(selectedLCRecord);
          }}
        />
      )}
    </Box>
  );
};

export default LCRecordsPendingMyApprovalList;
