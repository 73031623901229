import React from 'react';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import menuItem from '../../../menu-items';
import NavGroup from './NavGroup';

const MenuList = () => {
  const customization = useSelector((state) => state.customization);

  const navItems = menuItem.items
    ?.filter((item) => item.id === customization?.selectedModule)
    ?.map((item) => {
      switch (item.type) {
        case 'group':
          return <NavGroup key={item.id} item={item} />;
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    });

  return navItems;
};

export default MenuList;
