import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Divider, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Button from 'components/Atoms/Button';
import InputField from 'components/Atoms/InputField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { onBoardingStyle } from '../../style';

const StatutoryIdentification = ({ handleSubmit, handleCancel, propData, label }) => {
  const validateInputValue = (val, maxlen) => {
    if (!val || val.length === 0) {
      return true;
    }
    return val.length === maxlen;
  };

  const statutoryValidationSchema = yup.object().shape({
    panNumber: yup
      .string()
      .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, 'Invalid PAN Number')
      .test('len', 'Invalid PAN Number', (val) => validateInputValue(val, 10))
      .required('Required'),

    aadharNumber: yup
      .string()
      .matches(/^[0-9]\d{11}$/, 'Invalid Aadhar Number')
      .test('len', 'Invalid Aadhar Number', (val) => validateInputValue(val, 12))
      .test('numeric', 'Invalid Aadhar Number', (val) => {
        if (!val || val.length === 0) {
          return true;
        }
        return /^\d+$/.test(val);
      })
      .required('Required'),

    uhidNumber: yup
      .string()
      .test('len', 'Invalid UHID Number', (val) => validateInputValue(val, 14))
      .test('numeric', 'Invalid UHID Number', (val) => {
        if (!val || val.length === 0) {
          return true;
        }
        return /^\d+$/.test(val);
      }),

    uanNumber: yup.string().test('len', 'Invalid UAN Number', (val) => validateInputValue(val, 12)),
  });

  const formik = useFormik({
    initialValues: propData.allTabsData[label],
    validateOnMount: true,
    validationSchema: statutoryValidationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    propData.updateFormData(formik.values, label);
  }, [formik.values, label]);

  const theme = useTheme();

  return (
    <Box component="form" onSubmit={formik.handleSubmit} px={3}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 41rem)' }}>
          <Grid container rowSpacing={2} columnSpacing={9} sx={onBoardingStyle.formContainer}>
            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.panNumber && formik.errors.panNumber)}
                helperText={formik.touched.panNumber && formik.errors.panNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik?.values?.panNumber}
                name="panNumber"
                id="panNumber"
                label="PAN Number*"
                disabled={propData?.initialIdentificationData?.panNumber}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.aadharNumber && formik.errors.aadharNumber)}
                helperText={formik.touched.aadharNumber && formik.errors.aadharNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik?.values?.aadharNumber}
                name="aadharNumber"
                id="aadharNumber"
                label="Aadhaar Number*"
                disabled={propData?.initialIdentificationData?.aadharNumber}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.uanNumber && formik.errors.uanNumber)}
                helperText={formik.touched.uanNumber && formik.errors.uanNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik?.values?.uanNumber}
                name="uanNumber"
                id="uanNumber"
                label="UAN Number"
                disabled={propData?.initialIdentificationData?.uanNumber}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.uhidNumber && formik.errors.uhidNumber)}
                helperText={formik.touched.uhidNumber && formik.errors.uhidNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik?.values?.uhidNumber}
                name="uhidNumber"
                id="uhidNumber"
                label="UHID Number"
                disabled={propData?.initialIdentificationData?.uhidNumber}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </PerfectScrollbar>

      <Box sx={onBoardingStyle.btnContainer}>
        <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

        <Box sx={onBoardingStyle.submitWrapper}>
          <Button
            size="large"
            color="info"
            variant="outlined"
            type="button"
            onClick={() => {
              formik.resetForm();
              handleCancel();
            }}
          >
            Cancel
          </Button>

          <LoadingButton loading={propData.loading} color="primary" size="large" type="submit" variant="contained">
            Save & Continue
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

StatutoryIdentification.defaultProps = {
  handleSubmit: () => {},
  handleCancel: () => {},
  propData: {
    updateFormData: () => {},
    allTabsData: {
      'STATUTORY IDENTIFICATION': {},
    },
  },
  label: 'STATUTORY IDENTIFICATION',
};

StatutoryIdentification.propTypes = {
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  propData: PropTypes.shape({
    loading: PropTypes.bool,
    updateFormData: PropTypes.func,
    allTabsData: PropTypes.shape({}),
  }),
  label: PropTypes.string,
};

export default StatutoryIdentification;
