import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import TitleWithText from 'components/Atoms/TitleWithText';
import Typographys from 'components/Atoms/Typography';

const PersonalInformation = ({
  employeePersonalInformation,
  employeeEmergencyContactInformation,
  employeeContactDetails,
  employeeContactEmailId,
}) => {
  return (
    <Box>
      <Grid container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Blood Group" text={employeePersonalInformation.bloodGroup} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Date of Birth" text={employeePersonalInformation.dateOfBirth} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Date of Joining" text={employeePersonalInformation.dateOfJoining} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Mother’s Name" text={employeePersonalInformation.motherName} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Father’s Name" text={employeePersonalInformation.fatherName} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Spouse’s Name" text={employeePersonalInformation.spouseName} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="# School Going Children" text={employeePersonalInformation.schoolGoingChildren} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="# Children in Hostel(s)" text={employeePersonalInformation.childrenHostel} />
        </Grid>
      </Grid>
      <Grid item xs={12} my={2}>
        <Typographys variant="h5" color="neutral.800">
          Emergency Contact
        </Typographys>
      </Grid>
      <Grid container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Name" text={employeeEmergencyContactInformation.name} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Mobile Number" text={employeeEmergencyContactInformation.mobileNumber} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Relation" text={employeeEmergencyContactInformation.relation} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Email ID" text={employeeEmergencyContactInformation.emailid} />
        </Grid>
      </Grid>
      <Grid item xs={12} mt={2} mb={4}>
        <Typographys variant="h5" color="neutral.800">
          Contact Details
        </Typographys>
      </Grid>
      <Grid container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={12} sx={{ mb: '-1rem' }}>
          <Typographys variant="h6" color="neutral.600">
            Mobile Numbers
          </Typographys>
        </Grid>
        {employeeContactDetails.map((item, index) => {
          return (
            <Grid item xs={12} sm={4} md={3}>
              <TitleWithText
                title={`Mobile Number ${index + 1} ${item.primary === true ? ' (Primary)' : ''}`}
                text={item}
              />
            </Grid>
          );
        })}
      </Grid>

      <Grid container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={12} mt={4} sx={{ mb: '-1rem' }}>
          <Typographys variant="h6" color="neutral.600">
            Email ID’s
          </Typographys>
        </Grid>
        {employeeContactEmailId.map((item, index) => {
          return (
            <Grid item xs={12} sm={4} md={3}>
              <TitleWithText title={`Email ID ${index + 1} ${item.primary === true ? ' (Primary)' : ''}`} text={item} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

PersonalInformation.defaultProps = {
  employeePersonalInformation: {},
  employeeEmergencyContactInformation: {},
  employeeContactDetails: [],
  employeeContactEmailId: [],
};

PersonalInformation.propTypes = {
  employeePersonalInformation: PropTypes.shape({
    bloodGroup: PropTypes.string,
    dateOfBirth: PropTypes.string,
    dateOfJoining: PropTypes.string,
    motherName: PropTypes.string,
    fatherName: PropTypes.string,
    spouseName: PropTypes.string,
    schoolGoingChildren: PropTypes.string,
    childrenHostel: PropTypes.string,
  }),

  employeeEmergencyContactInformation: PropTypes.shape({
    name: PropTypes.string,
    mobileNumber: PropTypes.string,
    relation: PropTypes.string,
    emailid: PropTypes.string,
  }),

  employeeContactDetails: PropTypes.arrayOf,
  employeeContactEmailId: PropTypes.arrayOf,
};

export default PersonalInformation;
