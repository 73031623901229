import React, { useCallback, useEffect, useState } from 'react';
import MyTeamExpensesList from 'components/Templates/MyExpenses/MyTeamExpenseList';
import Dialog from 'components/Molecules/Dialog';
import Typography from 'components/Atoms/Typography';
import useController from 'hooks/useController';
import usePaginationAttributes from 'hooks/use-pagination-attributes';
import useColumns from './useColumns';
import useServices from './useServices';
import useActionItems from './useActionItems';

const ReportPendingMyActions = () => {
  const [loading, setLoading] = useState(false);
  const { pageSize } = usePaginationAttributes();
  const [columns] = useColumns();
  const services = useServices();
  const [controller, handlePageChange, handleSortChange, handleFiltersApply, handleFiltersClear] = useController();

  const [roleBasedMenu] = useActionItems(services);

  const fetchExpensesReportAction = useCallback(async () => {
    const query = new URLSearchParams({});
    if (controller) {
      query.append('sort', controller.sortBy);
      query.append('order', controller.sort);
    }
    query.append('offset', controller.page * pageSize);
    query.append('max', pageSize);
    setLoading(true);
    await services.fetchMyActionableExpenseReports(query);
    setLoading(false);
  }, [controller, pageSize]);

  useEffect(() => {
    fetchExpensesReportAction();
  }, [fetchExpensesReportAction, controller, pageSize, services?.state?.refreshPage]);

  return (
    <>
      <MyTeamExpensesList
        pageHeading="Expense Reports Pending My Action"
        rows={services.state.listExpensesReport.data?.data?.employeeExpenseReportInstanceList}
        columns={columns}
        handleSelect={services.handleSelectedReport}
        handleFilter={() => {}}
        page={controller.page}
        count={services.state.listExpensesReport.data?.data?.employeeExpenseReportInstanceCount || 0}
        onPageChange={handlePageChange}
        showPagination
        showActionList
        roleBasedMenu={roleBasedMenu}
        sortDirection={controller.sortDirection}
        sortBy={controller.sortBy}
        onSortChange={handleSortChange}
        loading={loading}
        showFilter={false}
      />
      {services?.showDeleteDialog && services?.state?.expenseReportDetails?.id !== null && (
        <Dialog
          open={services?.showDeleteDialog}
          handleClose={services?.handleDeleteDialog}
          dialogTitle="Delete Expense"
          dialogDesc={
            <Typography variant="body2" color="neutral.600" align="center">
              Are you sure you want to delete
              <Typography color="neutral.800" sx={{ display: 'inline', pl: 1 }}>
                {services?.state?.expenseReportDetails?.displayName}
              </Typography>
              ?
            </Typography>
          }
          handlePrimaryBtn={() => services?.deleteExpenseReport({ id: services?.state?.expenseReportDetails?.id })}
          primaryBtnColor="error"
          primaryBtn="Delete"
          secondaryBtn="Cancel"
          handleSecondaryBtn={services?.handleDeleteDialog}
          bodyContent={false}
        />
      )}
    </>
  );
};

export default ReportPendingMyActions;
