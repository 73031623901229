import dayjs from 'dayjs';
import { API_URL } from 'api/api';

export const formatValues = (response, user) => {
  const { employeeProfileInstance, employeeInstance, dependents, secUserInstance } = response.data;

  return {
    'BASIC PROFILE': {
      gender: employeeInstance?.gender || '',
      maritalStatus: employeeInstance?.maritalStatus || '',
      dateOfBirth: employeeProfileInstance?.dateOfBirth ? dayjs(employeeProfileInstance?.dateOfBirth) : '',
      dateOfJoining: employeeProfileInstance?.dateOfJoining ? dayjs(employeeProfileInstance?.dateOfJoining) : '',
      employmentStatus: employeeProfileInstance?.employee?.employmentStatus || '',
      fatherName: employeeProfileInstance?.fathersName || '',
      motherName: employeeProfileInstance?.mothersName || '',
      spouseName: employeeProfileInstance?.spouseName || '',
      location: employeeProfileInstance?.employee?.location?.name || '',
      supervisor: employeeInstance?.supervisor || '',
      bloodGroup: employeeProfileInstance?.bloodGroup || '',
      onboardingReference: employeeProfileInstance?.onboardingReference || '',
      uploadProfile: user?.profilePic && user?.profilePic !== 'null' ? `${API_URL}/${user?.profilePic}` : '',
    },
    'STATUTORY IDENTIFICATION': {
      panNumber: employeeInstance?.panNumberIndia || '',
      aadharNumber: employeeInstance?.aadhaarNumberIndia || '',
      uanNumber: employeeInstance?.uanNumberIndia || '',
      uhidNumber: employeeInstance?.uhidAccountNumberIndia || '',
    },
    'ADDITIONAL DETAILS': {
      'Social Data': {
        skype: employeeInstance?.skypeID || '',
        facebook: employeeInstance?.facebookID || '',
        linkedin: employeeInstance?.linkedinID || '',
        googlePlus: employeeInstance?.googlePlusID || '',
        twitter: employeeInstance?.twitterID || '',
        instagram: employeeInstance?.instagramID || '',
      },
      'Education/Certification History': employeeProfileInstance?.educationRecords?.length
        ? {
            education: employeeProfileInstance?.educationRecords?.map((education) => ({
              id: education?.id || '',
              institutionName: education?.institutionName || '',
              certificationName: education?.certificationName || '',
              startDate: education?.startDate ? dayjs(education?.startDate) : '',
              endDate: education.endDate ? dayjs(education.endDate) : '',
              specialization: education?.specialization || '',
              registrationNumber: education?.registrationNumber || '',
              referenceName: education?.referenceName || '',
              referenceNumber: education?.referencePhoneNumber || '',
              referenceEmail: education?.referenceEmail || '',
              comments: education?.comments || '',
              attachments: education?.documents || '',
              isNew: false,
            })),
          }
        : { education: [] },
      'Employment History': employeeProfileInstance?.pastEmploymentRecords?.length
        ? {
            employment: employeeProfileInstance?.pastEmploymentRecords?.map((employer) => ({
              id: employer?.id || '',
              employerName: employer?.employerName || '',
              employerAddress: employer?.employerAddress || '',
              employmentCommenceDate: employer?.employmentCommenceDate ? dayjs(employer?.employmentCommenceDate) : '',
              employmentEndDate: employer?.employmentEndDate ? dayjs(employer?.employmentEndDate) : '',
              reasonForLeaving: employer?.reasonForLeaving || '',
              employeeId: employer?.employeeID || '',
              startDesignation: employer?.startDesignation || '',
              endDesignation: employer?.endDesignation || '',
              referenceContactName: employer?.referenceContactName || '',
              referenceContactDesignation: employer?.referenceContactDesignation || '',
              referenceContactPhone: employer?.referenceContactPhone || '',
              referenceContactEmail: employer?.referenceContactEmail || '',
              attachments: employer?.documents || '',
              isNew: false,
            })),
          }
        : { employment: [] },
      'Visa Details': {
        visa: employeeProfileInstance?.visaRecords?.length
          ? employeeProfileInstance?.visaRecords.map((record) => ({
              id: record?.id || '',
              issuingCountry: record?.issuingCountry || '',
              placeOfIssue: record?.placeOfIssue || '',
              validFrom: record?.validFrom ? dayjs(record?.validFrom) : '',
              validTo: record?.validTo ? dayjs(record?.validTo) : '',
              issueDate: record?.issueDate ? dayjs(record?.issueDate) : '',
              referenceNumber: record?.referenceNumber || '',
              visaStatus: record?.status || '',
              comments: record?.comments || '',
              attachments: record?.documents || '',
              isNew: false,
            }))
          : [
              {
                issuingCountry: '',
                placeOfIssue: '',
                validFrom: '',
                validTo: '',
                issueDate: '',
                referenceNumber: '',
                visaStatus: '',
                comments: '',
                attachment: '',
              },
            ],
        passportNumber: employeeProfileInstance?.passportNumber || '',
        passportIssuePlace: employeeProfileInstance?.passportPlaceOfIssue || '',
        passportIssueDate: employeeProfileInstance?.passportIssueDate
          ? dayjs(employeeProfileInstance?.passportIssueDate)
          : '',
        passportExpDate: employeeProfileInstance?.passportExpiryDate
          ? dayjs(employeeProfileInstance?.passportExpiryDate)
          : '',
        passportCountry: employeeProfileInstance?.passportCountry || '',
        passportGivenName: employeeProfileInstance?.passportNameGiven || '',
        passportAttachments: 'TODO',
      },
      'Skill Attainment Details': employeeProfileInstance?.skillLevelAttainmentRecords?.length
        ? {
            skillDetails: [
              {
                skill: employeeProfileInstance?.skillLevelAttainmentRecords?.[0]?.skill || '',
                level: employeeProfileInstance?.skillLevelAttainmentRecords?.[0]?.level || '',
                comments: employeeProfileInstance?.skillLevelAttainmentRecords?.[0]?.comments || '',
                attachment: '',
              },
            ],
          }
        : {
            skillDetails: [
              {
                skill: '',
                level: '',
                comments: '',
                attachment: '',
              },
            ],
          },
    },
    'CONTACT DETAILS': {
      mobileNumber: employeeInstance?.mobileNumbers?.length ? employeeInstance.mobileNumbers : [],
      emailAddress: employeeInstance?.emailAddresses?.length ? employeeInstance.emailAddresses : [],
      name: employeeProfileInstance?.dependents?.length
        ? employeeProfileInstance.dependents[0].emergencyContactReference
        : '',
      relation: employeeProfileInstance?.dependents?.length ? employeeProfileInstance.dependents[0].relationship : '',
      emergencyMobile: '',
      emergencyEmail: '',
      permanentLocation: employeeProfileInstance?.permanentHomeAddress?.locationName || '',
      permanentAddressLine1: employeeProfileInstance?.permanentHomeAddress?.addressLine1 || '',
      permanentAddressLine2: employeeProfileInstance?.permanentHomeAddress?.addressLine2 || '',
      permanentAddressLine3: employeeProfileInstance?.permanentHomeAddress?.addressLine3 || '',
      permanentCity: employeeProfileInstance?.permanentHomeAddress?.city || '',
      permanentState: employeeProfileInstance?.permanentHomeAddress?.state || '',
      permanentCountry: employeeProfileInstance?.permanentHomeAddress?.country || '',
      permanentPincode: employeeProfileInstance?.permanentHomeAddress?.pinOrZip || '',

      currentLocation: employeeProfileInstance?.presentCommunicationAddress?.locationName || '',
      currentAddressLine1: employeeProfileInstance?.presentCommunicationAddress?.addressLine1 || '',
      currentAddressLine2: employeeProfileInstance?.presentCommunicationAddress?.addressLine2 || '',
      currentAddressLine3: employeeProfileInstance?.presentCommunicationAddress?.addressLine3 || '',
      currentCity: employeeProfileInstance?.presentCommunicationAddress?.city || '',
      currentState: employeeProfileInstance?.presentCommunicationAddress?.state || '',
      currentCountry: employeeProfileInstance?.presentCommunicationAddress?.country || '',
      currentPincode: employeeProfileInstance?.presentCommunicationAddress?.pinOrZip || '',

      ofcLocationName: employeeProfileInstance?.officeAddress?.locationName || '',
      ofcAddressLine1: employeeProfileInstance?.officeAddress?.addressLine1 || '',
      ofcAddressLine2: employeeProfileInstance?.officeAddress?.addressLine2 || '',
      ofcAddressLine3: employeeProfileInstance?.officeAddress?.addressLine3 || '',
      ofcCity: employeeProfileInstance?.officeAddress?.city || '',
      ofcState: employeeProfileInstance?.officeAddress?.state || '',
      ofcCountry: employeeProfileInstance?.officeAddress?.country || '',
      ofcPincode: employeeProfileInstance?.officeAddress?.pinOrZip || '',
    },
    'Bank Account': employeeProfileInstance?.bankAccountDetails?.length
      ? {
          bank: employeeProfileInstance?.bankAccountDetails.map((bankAccount) => ({
            accountNumber: bankAccount?.accountNumber || '',
            accountName: bankAccount?.accountHolderName || '',
            accountType: bankAccount?.accountType || '',
            accountCurrency: bankAccount?.currencyId || '',
            purpose: bankAccount?.accountPurpose || '',
            transactionType: bankAccount?.preferredTransactionType || '',
            bankName: bankAccount?.bankName || '',
            branchName: bankAccount?.bankBranch || '',
            ifsc: bankAccount?.ifsCode || '',
            swiftCode: bankAccount?.swiftCode || '',
            locationName: bankAccount?.bankAddress?.locationName || '',
            addressLine1: bankAccount?.bankAddress?.addressLine1 || '',
            addressLine2: bankAccount?.bankAddress?.addressLine2 || '',
            addressLine3: bankAccount?.bankAddress?.addressLine3 || '',
            city: bankAccount?.bankAddress?.city || '',
            state: bankAccount?.bankAddress?.state || '',
            country: bankAccount?.bankAddress?.country || '',
            pincode: bankAccount?.bankAddress?.pinOrZip || '',
          })),
        }
      : { bank: [] },
    DEPENDENT: dependents?.length
      ? {
          dependent: dependents.map((dependent) => ({
            firstName: dependent?.contactInfo?.firstName || '',
            lastName: dependent?.contactInfo?.lastName || '',
            gender: dependent?.contactInfo?.gender || '',
            relation: dependent?.relationship || '',
            dateOfBirth: dependent?.dateOfBirth ? dayjs(dependent?.dateOfBirth) : '',
            mobileNumber: dependent?.contactInfo?.mobileNumber || '',
            emailId: dependent?.contactInfo?.emailAddress || '',
            epfPerc: dependent?.epfNominationPercentage || '0.0',
            epsPerc: dependent?.epsNominationPercentage || '0.0',
            insurancePerc: dependent?.insuranceNominationPercentage || '0.0',
            otherPerc: dependent?.othersNominationPercentage || '0.0',
            gratuityPerc: dependent?.gratuityNominationPercentage || '0.0',
            emergencyContact: dependent?.emergencyContactReference || '',
          })),
        }
      : { dependent: [] },

    onBoardingInstructions: secUserInstance?.company?.config?.onBoardingInstructions || '',
  };
};
