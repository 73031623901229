import React, { useEffect, useState } from 'react';
import AddAndEdit from 'components/Templates/MyPay/PastEmployerTaxes/AddEdit';
import Dialog from 'components/Molecules/Dialog';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import useToast from 'components/Provider/useToast';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import get from 'lodash/get';
import { useThunk } from 'hooks/useThunk';
import { isEmpty, isObject } from 'lodash';
import { formDataApi } from 'api/api';
import { previousEmploymentTaxDetailsCreate, previousEmploymentTaxDetailsEdit } from 'store';
import { PAST_EMPLOYER_TAXES_STATUS_IN_DRAFT } from 'utils/SystemConfigConstants';
import useService from '../List/useService';

const AddEditPage = () => {
  const addToast = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);
  const location = useLocation();
  const { id } = useParams();
  const services = useService();

  const defaultValues = {};

  const isEditMode = location.pathname.includes('edit') && id;

  const breadcrumbData = [
    { id: 1, name: 'Past Employer Taxes', path: '/home/mypay/past-employer-taxes', isActive: false },
    { id: 2, name: isEditMode ? 'Edit' : 'Add', path: '', isActive: true },
  ];

  const [
    getCreatePreviousEmploymentTaxDetails,
    isLoadingCreatePreviousEmploymentTaxDetails,
    createPreviousEmploymentTaxDetailsError,
  ] = useThunk(previousEmploymentTaxDetailsCreate);
  const [
    getEditPreviousEmploymentTaxDetails,
    isLoadingEditPreviousEmploymentTaxDetails,
    editPreviousEmploymentTaxDetailsError,
  ] = useThunk(previousEmploymentTaxDetailsEdit);

  useEffect(() => {
    if (isEditMode) {
      const bodyFormData = new FormData();
      bodyFormData.append('id', id);
      getEditPreviousEmploymentTaxDetails(bodyFormData);
    } else {
      const query = new URLSearchParams({});
      getCreatePreviousEmploymentTaxDetails(query);
    }
  }, [isEditMode, id, getEditPreviousEmploymentTaxDetails, getCreatePreviousEmploymentTaxDetails]);

  const { createPreviousEmploymentTaxDetails = {}, editPreviousEmploymentTaxDetails = {} } = useSelector(
    (state) => state?.myPay,
  );

  const taxDetailsPreviousEmployerIndiaInstance =
    editPreviousEmploymentTaxDetails?.taxDetailsPreviousEmployerIndiaInstance;

  if (
    isEditMode &&
    isObject(taxDetailsPreviousEmployerIndiaInstance) &&
    !isEmpty(taxDetailsPreviousEmployerIndiaInstance)
  ) {
    defaultValues.employerName = taxDetailsPreviousEmployerIndiaInstance?.employerName;
    defaultValues.employerTAN = taxDetailsPreviousEmployerIndiaInstance?.employerTAN;
    defaultValues.employerAddress = taxDetailsPreviousEmployerIndiaInstance?.employerAddress;
    defaultValues.incomeChargeableUnderHeadSalaries =
      taxDetailsPreviousEmployerIndiaInstance?.incomeChargeableUnderHeadSalaries;
    defaultValues.exemptionsUnderSection10 = taxDetailsPreviousEmployerIndiaInstance?.exemptionsUnderSection10;
    defaultValues.deductionsUnderSection16 = taxDetailsPreviousEmployerIndiaInstance?.deductionsUnderSection16;
    defaultValues.deductionUnderChapterVIA = taxDetailsPreviousEmployerIndiaInstance?.deductionUnderChapterVIA;
    defaultValues.netTaxPayable = taxDetailsPreviousEmployerIndiaInstance?.netTaxPayable;
    defaultValues.netTaxDeducted = taxDetailsPreviousEmployerIndiaInstance?.netTaxDeducted;
    defaultValues.netProfessionalTaxPaid = taxDetailsPreviousEmployerIndiaInstance?.netProfessionalTaxPaid;
    defaultValues.netPFContributed = taxDetailsPreviousEmployerIndiaInstance?.netPFContributed;
    defaultValues.taxPayableRefundable = taxDetailsPreviousEmployerIndiaInstance?.taxPayableRefundable;
    defaultValues.attachments = taxDetailsPreviousEmployerIndiaInstance?.attachment?.fileName;
    defaultValues.deleteBtnStatus =
      taxDetailsPreviousEmployerIndiaInstance?.status === PAST_EMPLOYER_TAXES_STATUS_IN_DRAFT;
  } else {
    defaultValues.employerName = '';
    defaultValues.employerTAN = '';
    defaultValues.employerAddress = '';
    defaultValues.incomeChargeableUnderHeadSalaries = '0';
    defaultValues.exemptionsUnderSection10 = '0';
    defaultValues.deductionsUnderSection16 = '0';
    defaultValues.deductionUnderChapterVIA = '0';
    defaultValues.netTaxPayable = '0';
    defaultValues.netTaxDeducted = '0';
    defaultValues.netProfessionalTaxPaid = '0';
    defaultValues.netPFContributed = '0';
    defaultValues.taxPayableRefundable = '0';
    defaultValues.attachments = '';
    defaultValues.deleteBtnStatus = false;
  }

  const handleSubmit = async (values) => {
    let response = {};
    const bodyFormData = new FormData();

    bodyFormData.append('employerName', get(values, 'employerName', ''));
    bodyFormData.append('employerTAN', get(values, 'employerTAN', ''));
    bodyFormData.append('employerAddress', get(values, 'employerAddress', ''));
    bodyFormData.append('incomeChargeableUnderHeadSalaries', get(values, 'incomeChargeableUnderHeadSalaries', ''));
    bodyFormData.append('exemptionsUnderSection10', get(values, 'exemptionsUnderSection10', ''));
    bodyFormData.append('deductionsUnderSection16', get(values, 'deductionsUnderSection16', ''));
    bodyFormData.append('deductionUnderChapterVIA', get(values, 'deductionUnderChapterVIA', ''));
    bodyFormData.append('netTaxPayable', get(values, 'netTaxPayable', ''));
    bodyFormData.append('netTaxDeducted', get(values, 'netTaxDeducted', ''));
    bodyFormData.append('netProfessionalTaxPaid', get(values, 'netProfessionalTaxPaid', ''));
    bodyFormData.append('netPFContributed', get(values, 'netPFContributed', ''));
    bodyFormData.append('taxPayableRefundable', get(values, 'taxPayableRefundable', ''));
    if (get(values, 'attachments', '')) bodyFormData.append('document', get(values, 'attachments', ''));
    if (get(values, 'draft', '')) bodyFormData.append('draft', get(values, 'draft', ''));

    try {
      setLoading(true);
      setDraftLoading(true);
      if (isEditMode) {
        bodyFormData.append('id', id);
        bodyFormData.append('version', taxDetailsPreviousEmployerIndiaInstance?.version);
        response = await formDataApi.put(
          'restPayIndex/modifyPreviousEmploymentTaxDetailsForEmployeeAction',
          bodyFormData,
        );
      } else {
        response = await formDataApi.post('restPayIndex/savePreviousEmploymentTaxDetailsForEmployee', bodyFormData);
      }

      const results = response?.data;
      if (results?.status === 'SUCCESS') {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: results?.message || `Previous employment tax ${isEditMode ? 'updated' : 'added'} successfully`,
        });
        const documentID = isEditMode ? id : results?.objID;
        navigate(`/home/mypay/past-employer-taxes/view/${documentID}`);
      } else {
        addToast({
          type: 'error',
          title: 'ERROR',
          message: results?.message || `Failed to ${isEditMode ? 'update' : 'add new'} previous employment tax`,
        });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to ${isEditMode ? 'update' : 'add new'} previous employment tax`,
      });
    } finally {
      setLoading(false);
      setDraftLoading(false);
    }
  };

  if (
    createPreviousEmploymentTaxDetailsError !== null ||
    (isEditMode && editPreviousEmploymentTaxDetailsError !== null)
  ) {
    return (
      <Typography variant="h6">
        {createPreviousEmploymentTaxDetailsError?.message ||
          editPreviousEmploymentTaxDetailsError?.message ||
          'Error while loading Previous Employment Tax details.'}
      </Typography>
    );
  }

  if (
    (createPreviousEmploymentTaxDetails &&
      !isEmpty(createPreviousEmploymentTaxDetails) &&
      createPreviousEmploymentTaxDetails?.status !== 'SUCCESS') ||
    (editPreviousEmploymentTaxDetails &&
      !isEmpty(editPreviousEmploymentTaxDetails) &&
      editPreviousEmploymentTaxDetails?.status !== 'SUCCESS')
  ) {
    return (
      <Typography variant="h6">
        {createPreviousEmploymentTaxDetails?.message ||
          editPreviousEmploymentTaxDetails?.message ||
          'Past Employer Taxes has been disabled for the employee.'}
      </Typography>
    );
  }

  return (
    <Box>
      <AddAndEdit
        formLoading={
          isEditMode ? isLoadingEditPreviousEmploymentTaxDetails : isLoadingCreatePreviousEmploymentTaxDetails
        }
        loading={loading}
        draftLoading={draftLoading}
        breadcrumbData={breadcrumbData}
        handleSubmit={handleSubmit}
        handleCancel={services?.listPastEmploymentTax}
        defaultValues={defaultValues}
        isEdit={isEditMode}
        handleDelete={services?.handleDeleteDialog}
      />
      {!!isEditMode && taxDetailsPreviousEmployerIndiaInstance !== null && (
        <Dialog
          open={services?.showDeleteDialog}
          handleClose={services?.handleDeleteDialog}
          dialogTitle="Delete Past Employer Tax"
          dialogDesc={
            <Typography variant="body2" color="neutral.600" align="center">
              Are you sure you want to delete the entry for
              <Typography color="neutral.800" sx={{ display: 'inline', pl: 1 }}>
                {taxDetailsPreviousEmployerIndiaInstance?.employerName}
              </Typography>
              ?
            </Typography>
          }
          handlePrimaryBtn={() => services?.deletePastEmploymentTax(taxDetailsPreviousEmployerIndiaInstance, true)}
          primaryBtnColor="error"
          primaryBtn="Delete"
          secondaryBtn="Cancel"
          handleSecondaryBtn={services?.handleDeleteDialog}
          bodyContent={false}
        />
      )}
    </Box>
  );
};
export default AddEditPage;
