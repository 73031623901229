import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import SearchBox from 'components/Molecules/SearchBox';
import DropdownMenu from 'components/Molecules/DropdownMenu';
import Typographys from 'components/Atoms/Typography';
import DatePicker from 'components/Atoms/Datepicker';
import Button from 'components/Atoms/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconWithTitle from 'components/Atoms/IconWithTitle';
import MuiTimeline from 'components/Organisms/Timeline';
import { timelineStyle } from '../style';

const ActivityLogTimeline = ({
  activityLogTitle,
  handleActivityLogSearch,
  activityLogFilter,
  activityLog,
  activityLogStartDateChange,
  activityLogEndDateChange,
  activityLogOnFetch,
}) => {
  const [showTimeLine, setShowTimeLine] = useState(false);
  const handleFetch = () => {
    activityLogOnFetch();
    setShowTimeLine(true);
  };

  return (
    <Box sx={timelineStyle.timelineWrapper}>
      {showTimeLine ? (
        <>
          <Box sx={timelineStyle.timelineHeader}>
            <IconWithTitle
              iconElement={<ArrowBackIcon fontSize="inherit" />}
              iconColor="neutral.600"
              iconFontSize="2rem"
              title={activityLogTitle}
              titleColor="neutral.600"
              titleVariant="body1"
              onClick={() => {
                setShowTimeLine(false);
              }}
            />
            <Box display="flex" alignItems="center">
              <Box mr={1} maxWidth="18.75rem">
                <SearchBox
                  size="small"
                  placeholder="Search..."
                  id="activityLogSearch"
                  name="activityLogSearch"
                  handleChange={handleActivityLogSearch}
                />
              </Box>
              <DropdownMenu btnLabel="Filter" color="info" menuLists={activityLogFilter} />
            </Box>
          </Box>

          <Box mt={3}>
            <MuiTimeline timeLineLists={activityLog} />
          </Box>
        </>
      ) : (
        <>
          <Box mb={1.75}>
            <Typographys variant="body2" color="neutral.600">
              Select the duration of timeline
            </Typographys>
          </Box>
          <Grid container columnGap={8.75} flexWrap={{ lg: 'noWrap' }}>
            <Grid item maxWidth="34.375rem" mb={2}>
              <DatePicker
                name="startDate"
                id="startDate"
                label="Start Date"
                autoComplete="Start Date"
                onChange={activityLogStartDateChange}
                required
                fullWidth
                autoFocus
              />
            </Grid>

            <Grid item maxWidth="34.375rem" mb={2}>
              <DatePicker
                name="endDate"
                id="endDate"
                label="End Date"
                autoComplete="endDate"
                onChange={activityLogEndDateChange}
                required
                fullWidth
                autoFocus
              />
            </Grid>
          </Grid>
          <Button variant="contained" size="medium" onClick={handleFetch}>
            Fetch
          </Button>
        </>
      )}
    </Box>
  );
};
ActivityLogTimeline.defaultProps = {
  activityLog: [
    {
      date: '5th Dec 2022',
      time: '3:50:51 IST',
      title: 'Activity Log Timeline',
      desc: 'Aachman kabir(1001)',
      note: '',
    },
    {
      date: '5th Dec 2022',
      time: '3:50:51 IST',
      title: 'Activity Log Timeline',
      desc: 'Aachman kabir(1001)',
      note: '',
    },
    {
      date: '5th Dec 2022',
      time: '3:50:51 IST',
      title: 'Activity Log Timeline',
      desc: 'Aachman kabir(1001)',
      note: '',
    },
    {
      date: '5th Dec 2022',
      time: '3:50:51 IST',
      title: 'Activity Log Timeline',
      desc: 'Aachman kabir(1001)',
      note: '',
    },
  ],
  activityLogTitle: 'Showing result from 1st Dec 2022 to 31th Dec 2022',
  handleActivityLogSearch: () => {},
  activityLogFilter: [
    { id: 1, label: 'Add New', onClick: () => {} },
    { id: 2, label: 'Bulk Upload', onClick: () => {} },
    { id: 3, label: 'Send ESS invite', onClick: () => {} },
    { id: 4, label: 'Download', onClick: () => {} },
  ],
  activityLogStartDateChange: () => {},
  activityLogEndDateChange: () => {},
  activityLogOnFetch: () => {},
};

ActivityLogTimeline.propTypes = {
  activityLog: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      time: PropTypes.string,
      title: PropTypes.string,
      desc: PropTypes.string,
      note: PropTypes.string,
    }),
  ),
  activityLogTitle: PropTypes.string,
  handleActivityLogSearch: PropTypes.func,
  activityLogFilter: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  activityLogStartDateChange: PropTypes.func,
  activityLogEndDateChange: PropTypes.func,
  activityLogOnFetch: PropTypes.func,
};

export default ActivityLogTimeline;
