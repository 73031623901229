import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Box } from '@mui/material';
import DashboardTemplate from 'components/Templates/DashboardTemplate';
import AlumniDashboard from 'components/Organisms/Dashboard/AlumniDashboard';

const AlumniDashboardTemplate = ({
  Heading,
  actionMenuLists,
  configuredTDSData,
  configuredFnfDetails,
  configuredSalaryDetails,
  salaryDetailsColumns,
  salarySlipDataColumn,
  salarySlipDataRow,
  loading,
  onSalarySlipRowRowClick,
  configuredDocumentDetails,
  userHasViewFnFStatementPermission,
  userHasPrintSalarySlipPermission,
}) => {
  return (
    <DashboardTemplate pageHeading={`Hello ${Heading}`} actionMenuLists={actionMenuLists}>
      {!loading ? (
        <AlumniDashboard
          configuredFnfDetails={configuredFnfDetails}
          configuredTDSData={configuredTDSData}
          configuredSalaryDetails={configuredSalaryDetails}
          salaryDetailsColumns={salaryDetailsColumns}
          salarySlipDataColumn={salarySlipDataColumn}
          salarySlipDataRow={salarySlipDataRow}
          onSalarySlipRowRowClick={onSalarySlipRowRowClick}
          loading={loading}
          configuredDocumentDetails={configuredDocumentDetails}
          userHasViewFnFStatementPermission={userHasViewFnFStatementPermission}
          userHasPrintSalarySlipPermission={userHasPrintSalarySlipPermission}
        />
      ) : (
        <Box sx={{ p: 4 }}>
          <Skeleton height={70} />
          <Skeleton />
          <Skeleton />
        </Box>
      )}
    </DashboardTemplate>
  );
};

AlumniDashboardTemplate.defaultProps = {
  userHasViewFnFStatementPermission: false,
  userHasPrintSalarySlipPermission: false,
  Heading: '',
  actionMenuLists: [],
  salarySlipDataColumn: [],
  salarySlipDataRow: [],
  loading: false,
  configuredFnfDetails: {},
  configuredSalaryDetails: {},
  salaryDetailsColumns: {},
  onSalarySlipRowRowClick: () => {},
  configuredDocumentDetails: {},
  configuredTDSData: {},
};

AlumniDashboardTemplate.propTypes = {
  userHasViewFnFStatementPermission: PropTypes.bool,
  userHasPrintSalarySlipPermission: PropTypes.bool,
  Heading: PropTypes.string,
  actionMenuLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  salarySlipDataColumn: PropTypes.arrayOf({}),
  salarySlipDataRow: PropTypes.arrayOf({}),
  loading: PropTypes.bool,
  configuredFnfDetails: PropTypes.shape({}),
  configuredSalaryDetails: PropTypes.shape({}),
  salaryDetailsColumns: PropTypes.shape({}),
  onSalarySlipRowRowClick: PropTypes.func,
  configuredDocumentDetails: PropTypes.shape({}),
  configuredTDSData: PropTypes.shape({}),
};

export default AlumniDashboardTemplate;
