export const calculateArrearsTotal = (entries, lineEntriesMap, valuesMap, actionType) => {
  let total = 0;
  let salarySlipLineEntryInstance;
  let isArrears;
  Object.keys(entries)
    .sort()
    .forEach((payheadName) => {
      salarySlipLineEntryInstance = lineEntriesMap[payheadName];
      if (actionType === 'earning') {
        isArrears =
          salarySlipLineEntryInstance &&
          !salarySlipLineEntryInstance.payhead.isInternalPayhead &&
          salarySlipLineEntryInstance.payhead.name.includes('Arrears');
      } else {
        isArrears = salarySlipLineEntryInstance && salarySlipLineEntryInstance.payhead.name.includes('Arrears');
      }
      if (valuesMap[payheadName] && isArrears) {
        total += valuesMap[payheadName];
      }
    });
  return total;
};
