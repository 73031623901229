import PropTypes from 'prop-types';
import React from 'react';
import MuiTableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

const StickyTableCell = styled(
  MuiTableCell,
  {},
)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.neutral[100],
    color: theme.palette.neutral[600],
    minWidth: '3rem',
    right: 0,
    position: 'sticky',
    zIndex: theme.zIndex.appBar + 2,
    filter: 'drop-shadow(-2px 0px 4px rgba(9, 30, 66, 0.08))',
    fontSize: '1.375rem',
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary[300],
    minWidth: '3rem',
    right: 0,
    position: 'sticky',
    zIndex: theme.zIndex.appBar + 1,
    filter: 'drop-shadow(-2px 0px 4px rgba(9, 30, 66, 0.08))',
  },
}));

const TableCell = ({ children, sticky, onRowClick, row, fontSize, fontWeight }) => {
  return sticky ? (
    <StickyTableCell>{children}</StickyTableCell>
  ) : (
    <MuiTableCell sx={{ cursor: 'pointer', fontSize, fontWeight }} onClick={() => onRowClick(row)}>
      {children}
    </MuiTableCell>
  );
};

TableCell.propTypes = {
  children: PropTypes.node.isRequired,
  sticky: PropTypes.bool,
  onRowClick: PropTypes.func,
  row: PropTypes.instanceOf(Object).isRequired,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
};

TableCell.defaultProps = {
  sticky: false,
  onRowClick: () => {},
  fontSize: '1.75rem',
  fontWeight: '400',
};
export default TableCell;
