import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Typographys from 'components/Atoms/Typography';
import TitleWithText from 'components/Atoms/TitleWithText';
import Logo from 'components/Atoms/Logo';
import { SalarySlipStyle } from '../../../../MyPay/style';

const UserFnfDetails = ({ employeeData, employeeId, employeeName }) => {
  const theme = useTheme();
  return (
    <Box sx={{ background: theme.palette.actions[100], padding: '2rem 3rem', borderRadius: '0.75rem' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={SalarySlipStyle.slipHeaderLogo}>
          <Logo width="18rem" height="6rem" altText="Company Logo" />
        </Box>
        <Box>
          <Typographys variant="h4">
            {employeeName} ({employeeId})
          </Typographys>
          <Box>
            <Typographys variant="body2" color="neutral.700">
              FnF slip for period: {employeeData?.startDate} to {employeeData?.endDate}
            </Typographys>
          </Box>
        </Box>
      </Box>
      <Divider sx={{ borderColor: theme.palette.other[200], margin: ' 2rem 0 2.5rem 0' }} />
      <Grid container columnSpacing={1} rowSpacing={1}>
        <Grid item xs={6} sm={3} md={3}>
          <TitleWithText padding="0" title="Designation" text={employeeData?.employeeDesignation} />
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          <TitleWithText padding="0" title="Last Working Date" text={employeeData?.lastWorkingDate} />
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          <TitleWithText padding="0" title="Bank" text={employeeData?.employeeBank} />
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          <TitleWithText padding="0" title="Bank Account Number" text={employeeData?.employeeBankAccountNumber} />
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          <TitleWithText padding="0" title="PAN Number" text={employeeData?.employeePanNumber} />
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          <TitleWithText padding="0" title="PF Number" text={employeeData?.employeePfNumber} />
        </Grid>
      </Grid>
      <Divider sx={{ borderColor: theme.palette.other[200], margin: ' 2rem 0 2.5rem 0' }} />
      <Grid container columnSpacing={1} rowSpacing={1}>
        <Grid item xs={6} sm={3} md={3}>
          <TitleWithText padding="0" title="Leaves Encashed" text={employeeData?.leavesEncashed} />
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          <TitleWithText padding="0" title="Processed Days" text={employeeData?.processedDays} />
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          <TitleWithText padding="0" title="Loss Of Pay" text={employeeData?.lossOfPay} />
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          <TitleWithText padding="0" title="Paid Days" text={employeeData?.paidDays} />
        </Grid>
      </Grid>
    </Box>
  );
};

UserFnfDetails.defaultProps = {
  employeeData: {},
  employeeId: '',
  employeeName: '',
};

UserFnfDetails.propTypes = {
  employeeData: PropTypes.shape({}),
  employeeId: PropTypes.string,
  employeeName: PropTypes.string,
};

export default UserFnfDetails;
