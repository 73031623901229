import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid, Paper, useTheme } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import CommonFilledCard from 'components/Molecules/CommonFilledCard';
import ActionDropDown from 'components/Molecules/ActionDropDown';
import TimerProgressBar from 'components/Molecules/TimerProgressBar';
import { validateDateElseHyphen } from 'utils/commonUtils';
import RangePicker from 'components/Molecules/DateRangePicker';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const RenderDateRangePicker = (handleSelect) => {
  return (
    <RangePicker
      handleSubmit={(data) => handleSelect(data)}
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typographys color="inherit" variant="body2" fontWeight={500}>
            Custom Date
          </Typographys>
          <ArrowForwardIosIcon sx={{ fontSize: '1.5rem' }} />
        </Box>
      }
    />
  );
};
const AttendanceStatsCard = ({
  attendanceStatsData,
  ariaLabel,
  progressBarList,
  handleFilterDateChange,
  handleAttendanceData,
  companyConfigHours,
}) => {
  const theme = useTheme();
  const [filterLabel, setFilterLabel] = useState('This Week');

  const onDateChange = (data) => {
    setFilterLabel(
      `${validateDateElseHyphen(data.startDate, 'DD MMMM YYYY')} to ${validateDateElseHyphen(data.endDate)}`,
    );
    handleFilterDateChange(data);
  };

  const filterOptions = [
    { name: 'This Week', onClick: () => handleAttendanceData('This Week') },
    { name: 'This Month', onClick: () => handleAttendanceData('This Month') },
    { name: 'This Year', onClick: () => handleAttendanceData('This Year') },
    {
      id: 4,
      name: 'Custom Date',
      onClick: () => handleAttendanceData('custom'),
      formatValue: () => RenderDateRangePicker(onDateChange),
    },
  ];

  return (
    <Paper variant="outline" sx={{ border: '0.125rem solid', borderColor: 'neutral.300', padding: '2.5rem 3rem' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2.5 }}>
        <Typographys variant="h6" color="neutral.800">
          Attendance stats
        </Typographys>
        <Box>
          <ActionDropDown ariaLabel={ariaLabel} label={filterLabel} options={filterOptions} />
        </Box>
      </Box>

      <Grid container spacing={1}>
        {attendanceStatsData.map((list) => (
          <Grid item xs={12} sm={3}>
            <CommonFilledCard
              key={list.heading}
              heading={list.heading}
              headingColor="secondary.500"
              headingMaxWidth="14rem"
              bottomSpace="1.5rem"
              value={list.value}
              minimumHeight={{
                xs: '10rem',
                sm: '10rem',
                md: '16rem',
                lg: '20rem',
                xl: '16rem',
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Divider sx={{ my: 3, backgroundColor: theme.palette.other[200] }} />
      <Typographys variant="body2">
        {' '}
        {`Today (${companyConfigHours?.officeHoursStartTime} - ${companyConfigHours?.officeHoursEndTime})`}
      </Typographys>
      <Box mt={3}>
        <TimerProgressBar progressBarList={progressBarList} />
      </Box>
    </Paper>
  );
};

AttendanceStatsCard.defaultProps = {
  ariaLabel: '',
  attendanceStatsData: [],
  progressBarList: {},
  handleFilterDateChange: () => {},
  handleAttendanceData: () => {},
};
AttendanceStatsCard.propTypes = {
  attendanceStatsData: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  ariaLabel: PropTypes.string,
  progressBarList: PropTypes.shape(
    PropTypes.shape({
      duration: PropTypes.shape({
        hour: PropTypes.number,
        min: PropTypes.number,
      }),
      break: PropTypes.shape({
        hour: PropTypes.number,
        min: PropTypes.number,
      }),
      overtime: PropTypes.shape({
        hour: PropTypes.number,
        min: PropTypes.number,
      }),
    }),
  ),
  handleFilterDateChange: PropTypes.func,
  handleAttendanceData: PropTypes.func,
};

export default AttendanceStatsCard;
