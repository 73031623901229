export const profileStyle = {
  actionBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  addressBox: {
    marginBottom: '4rem !important',
  },

  addressDetails: {
    display: 'inline',
    marginRight: '7px',
  },

  mainContainer: {
    marginBottom: '100px',
  },

  formContainer: {
    width: '100%',
    maxWidth: '100%',
    mt: 0,
    mb: 2.5,
  },

  formDivider: {
    '&:not(:first-child)': {
      borderTop: '0.125rem solid',
      borderColor: 'other.200',
      mt: 4,
      pt: 4,
    },
  },

  divider: {
    '&:not(:first-child)': {
      borderTop: '0.125rem solid',
      borderColor: 'other.200',
    },
  },

  addButton: {
    my: 3,
  },

  btnContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%',
    backgroundColor: 'common.white',
    zIndex: '9',
    margin: '0rem',
    padding: '0rem 3rem 2rem 3rem',
    boxSizing: 'border-box',
  },

  submitWrapper: {
    display: 'flex',
    columnGap: '2rem',
    justifyContent: 'flex-end',
  },

  addressDiv: {
    display: 'inline',
    mr: '0.5rem',
  },
  deleteContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '5rem',
    marginBottom: '2rem',
  },
  contactWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 1.5,
    mb: '1.5rem',
  },

  deleteBox: {
    cursor: 'pointer',
    height: '3rem',
    width: '3rem',
  },
};

export const addEmpStyle = {
  addWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: 2,
    my: 2,
  },

  submitWrapper: {
    display: 'flex',
    justifyContent: 'end',
    gap: 2,
  },

  btnContainer: {
    position: 'fixed',
    right: '0rem',
    bottom: '3.625rem',
    backgroundColor: 'common.white',
    zIndex: '9',
    margin: '0rem 3.25rem',
    width: 'calc(100% - 49.5rem)',
    padding: '0rem 3rem 3.25rem 3rem',
  },

  divWrapper: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '2rem',
  },

  formContainer: {
    width: '100%',
    maxWidth: '100%',
    marginBottom: '48px',
  },
};

export const separationStyle = {
  buttonWrapper: {
    position: 'fixed',
    right: '0rem',
    bottom: '3.625rem',
    backgroundColor: 'common.white',
    zIndex: '9',
    margin: '0rem 3.25rem',
    width: 'calc(100% - 49.5rem)',
    padding: '0rem 3rem 3.25rem 3rem',
  },

  Divider: {
    my: 2,
    backgroundColor: 'neutral.200',
  },

  flexWrapper: {
    display: 'flex',
    justifyContent: 'end',
    gap: 2,
  },

  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '4rem',
  },

  moreIcon: {
    cursor: 'pointer',
  },
};
