/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FormHelperText, Grid, Divider, Stack, Skeleton, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/system';
import useToast from 'components/Provider/useToast';
import { urlEncodedApi } from 'api/api';
import InputField from 'components/Atoms/InputField';
import DatePickers from 'components/Atoms/Datepicker';
import CheckBox from 'components/Atoms/Checkbox';
import Button from 'components/Atoms/Button';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { styles } from './styles';

const createPayloadMap = {
  displayName: 'name',
  payheadType: 'payheadType',
  'currency.id': 'currency',
  exemptionAllowanceType: 'exemptionAllownaceType',
  exemptionLevel: 'excemptionLevel',
  maxExemptionLimitPA: 'maxExcemptionLimit',
  exemptionStatuteReference: 'excemptionStatueReference',
  glName: 'generalLedgerName',
  glCode: 'generalLedgerCode',
  costCentreCode: 'costCentreCode',
  compensationType: 'compensationType',
  calculationType: 'calculationType',
  calculationFormula: 'calculationFormula',
  exemptionEffectiveDate: 'excemptionEffectiveDate',
  exempt: 'excempt',
  taxable: 'taxable',
  impactsGratuity: 'impactsGratuity',
  isPartOfEmployeeCTC: 'partOfCTCForEmployee',
  isPartOfCTC: 'partOfCTCForCompany',
  displayOnSalaryStructure: 'displayOnSalaryStructure',
  displayOnSalarySlip: 'displayOnPS',
  isLOPApplicable: 'isLopApplicable',
  isSSInclusionMandatory: 'MandatryInclInSalStruct',
};

const PayHeadForm = ({ isEditMode, payheadDetails, results }) => {
  const navigate = useNavigate();
  const {
    compansationTypes,
    companyPayheads,
    currenciesList,
    exemptionAllowanceTypes,
    exemptionLevelTypes,
    payrollCalculationTypes,
  } = payheadDetails?.data || {};

  const addToast = useToast();
  const [loading, setLoading] = useState(false);

  const handleCustomDetails = async (details) => {
    try {
      setLoading(true);
      const query = new URLSearchParams({});
      Object.keys(createPayloadMap).forEach((key) => {
        if (
          details[createPayloadMap[key]] ||
          (!details[createPayloadMap[key]] && details[createPayloadMap[key]] === false)
        ) {
          if (key === 'exemptionEffectiveDate') {
            query.append(key, dayjs(details[createPayloadMap[key]]).format('MM/DD/YYYY'));
          } else if (typeof details[createPayloadMap[key]] === 'boolean') {
            query.append(key, details[createPayloadMap[key]] ? 'on' : '');
          } else query.append(key, details[createPayloadMap[key]]);
        }
      });
      if (isEditMode) {
        query.append('id', results?.id);
      }
      const pathToCall = isEditMode ? 'restPayIndex/modifyPayheadAction' : 'restPayIndex/savePayhead';
      const response = await urlEncodedApi.post(pathToCall, query);
      if (response.status === 200) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Payhead ${isEditMode ? 'updated' : 'added'} successfully`,
        });
        navigate('/company/salary/payheads');
      } else {
        addToast({
          type: 'error',
          title: 'ERROR',
          message: response?.data?.message || `Failed to ${isEditMode ? 'update' : 'add'} payhead`,
        });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to ${isEditMode ? 'update' : 'add'} payhead`,
      });
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: results?.displayName ?? '',
      payheadType: results?.payheadType ?? '',
      currency: results?.currencyId ?? '',
      exemptionAllownaceType: results?.exemptionAllowanceType ?? '',
      excemptionLevel: results?.exemptionLevel ?? '',
      maxExcemptionLimit: results?.maxExemptionLimitPA ?? '',
      excemptionStatueReference: results?.exemptionStatuteReference ?? '',
      generalLedgerName: results?.glName ?? '',
      generalLedgerCode: results?.glCode ?? '',
      costCentreCode: results?.costCentreCode ?? '',
      compensationType: results?.compensationType ?? '',
      calculationType: results?.calculationType ?? '',
      calculationFormula: results?.calculationFormula ?? '',
      excemptionEffectiveDate: results?.exemptionEffectiveDate ? dayjs(results?.exemptionEffectiveDate) : '',
      excempt: results?.exempt ?? false,
      taxable: results?.taxable ?? true,
      impactsGratuity: results?.impactsGratuity ?? false,
      partOfCTCForEmployee: results?.isPartOfEmployeeCTC ?? true,
      partOfCTCForCompany: results?.isPartOfCTC ?? true,
      displayOnSalStruct: results?.displayOnSalStruct ?? true,
      displayOnPS: results?.displayOnSalarySlip ?? true,
      isLopApplicable: results?.isLOPApplicable ?? true,
      MandatryInclInSalStruct: results?.isSSInclusionMandatory ?? false,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().max(255),
      payheadType: Yup.string().max(255),
      currency: Yup.string().max(255),
    }),
    onSubmit: async (values, helpers) => {
      try {
        handleCustomDetails(values);
        // helpers.resetForm();
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });

  if (
    payheadDetails?.isLoading ||
    !(
      compansationTypes?.length &&
      companyPayheads?.length &&
      currenciesList?.length &&
      exemptionAllowanceTypes?.length &&
      exemptionLevelTypes?.length &&
      payrollCalculationTypes?.length
    )
  ) {
    return (
      <Box sx={{ py: 4 }}>
        <Skeleton height={42} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  return (
    <Grid container spacing={4} sx={{ py: 4 }}>
      <Grid item md={12} xs={12}>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <Grid container columnSpacing={6} rowSpacing={3}>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  label="Name"
                  name="name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={Boolean(formik.touched.name && formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  value={formik.values.name}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  error={Boolean(formik.touched.payheadType && formik.errors.payheadType)}
                  helperText={formik.touched.payheadType && formik.errors.payheadType}
                  label="Payhead Type"
                  name="payheadType"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  select
                  value={formik.values.payheadType}
                >
                  {companyPayheads.map((statusItem) => (
                    <MenuItem key={statusItem?.id} value={statusItem?.id}>
                      {statusItem?.nameWithType}
                    </MenuItem>
                  ))}
                </InputField>
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  error={Boolean(formik.touched.currency && formik.errors.currency)}
                  helperText={formik.touched.currency && formik.errors.currency}
                  label="Currency"
                  name="currency"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  select
                  value={formik.values.currency}
                >
                  {currenciesList.map((statusItem) => (
                    <MenuItem key={statusItem?.id} value={statusItem?.id}>
                      {statusItem?.name}
                    </MenuItem>
                  ))}
                </InputField>
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  error={Boolean(formik.touched.exemptionAllownaceType && formik.errors.exemptionAllownaceType)}
                  helperText={formik.touched.exemptionAllownaceType && formik.errors.exemptionAllownaceType}
                  label="Exemption Allownace Type"
                  name="exemptionAllownaceType"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  select
                  value={formik.values.exemptionAllownaceType}
                >
                  {exemptionAllowanceTypes.map((statusItem) => (
                    <MenuItem key={statusItem} value={statusItem}>
                      {statusItem}
                    </MenuItem>
                  ))}
                </InputField>
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  error={Boolean(formik.touched.excemptionLevel && formik.errors.excemptionLevel)}
                  helperText={formik.touched.excemptionLevel && formik.errors.excemptionLevel}
                  value={formik.values.excemptionLevel}
                  name="excemptionLevel"
                  label="Excemption Level"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  select
                >
                  {exemptionLevelTypes.map((statusItem) => (
                    <MenuItem key={statusItem} value={statusItem}>
                      {statusItem}
                    </MenuItem>
                  ))}
                </InputField>
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  error={Boolean(formik.touched.maxExcemptionLimit && formik.errors.maxExcemptionLimit)}
                  helperText={formik.touched.maxExcemptionLimit && formik.errors.maxExcemptionLimit}
                  value={formik.values.maxExcemptionLimit}
                  name="maxExcemptionLimit"
                  label="Max Excemption Limit (per annum)"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  fullWidth
                  label="Exemption Statute Reference"
                  size="small"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: formik.values.excemptionStatueReference,
                  }}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  multiline
                  name="excemptionStatueReference"
                  rows={1}
                  value={formik.values.excemptionStatueReference}
                  error={Boolean(formik.touched.excemptionStatueReference && formik.errors.excemptionStatueReference)}
                />
              </Grid>
              <Grid item xs={12} sx={styles.dividerWrapper}>
                <Divider flexItem orientation="horizontal" sx={styles.divider} />{' '}
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  label="General Ledger Name"
                  name="generalLedgerName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={Boolean(formik.touched.generalLedgerName && formik.errors.generalLedgerName)}
                  helperText={formik.touched.generalLedgerName && formik.errors.generalLedgerName}
                  value={formik.values.generalLedgerName}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  label="General Ledger Code"
                  error={Boolean(formik.touched.generalLedgerCode && formik.errors.generalLedgerCode)}
                  helperText={formik.touched.generalLedgerCode && formik.errors.generalLedgerCode}
                  name="generalLedgerCode"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.generalLedgerCode}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  label="Cost Centre Code"
                  error={Boolean(formik.touched.costCentreCode && formik.errors.costCentreCode)}
                  helperText={formik.touched.costCentreCode && formik.errors.costCentreCode}
                  name="costCentreCode"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.costCentreCode}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  label="Compensation Type"
                  error={Boolean(formik.touched.compensationType && formik.errors.compensationType)}
                  helperText={formik.touched.compensationType && formik.errors.compensationType}
                  name="compensationType"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  select
                  value={formik.values.compensationType}
                >
                  {compansationTypes.map((statusItem) => (
                    <MenuItem key={statusItem} value={statusItem}>
                      {statusItem}
                    </MenuItem>
                  ))}
                </InputField>
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  label="Calculation Type"
                  error={Boolean(formik.touched.calculationType && formik.errors.calculationType)}
                  helperText={formik.touched.calculationType && formik.errors.calculationType}
                  value={formik.values.calculationType}
                  name="calculationType"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  select
                >
                  {payrollCalculationTypes.map((statusItem) => (
                    <MenuItem key={statusItem} value={statusItem}>
                      {statusItem}
                    </MenuItem>
                  ))}
                </InputField>
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  label="Calculation Formula"
                  error={Boolean(formik.touched.calculationFormula && formik.errors.calculationFormula)}
                  helperText={formik.touched.calculationFormula && formik.errors.calculationFormula}
                  value={formik.values.calculationFormula}
                  name="calculationFormula"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} sx={styles.dividerWrapper}>
                <Divider flexItem orientation="horizontal" sx={styles.divider} />
              </Grid>
              <Grid item xs={4}>
                <DatePickers
                  id="Exemption Effective Date"
                  error={Boolean(formik.touched.excemptionEffectiveDate && formik.errors.excemptionEffectiveDate)}
                  label="Exemption Effective Date"
                  name="excemptionEffectiveDate"
                  value={formik.values.excemptionEffectiveDate}
                  onChange={(value) => {
                    formik.setFieldValue('excemptionEffectiveDate', Date.parse(value));
                  }}
                />
              </Grid>
              <Grid item xs={8} />
              <Grid item xs={6} sx={styles.checkbox}>
                <CheckBox
                  label="Exempt"
                  checked={formik.values.excempt}
                  name="excempt"
                  islabel
                  onChange={(event) => formik.handleChange(event)}
                />
              </Grid>
              <Grid item xs={6} sx={styles.checkbox}>
                <CheckBox
                  label="Taxable"
                  checked={formik.values.taxable}
                  name="taxable"
                  islabel
                  onChange={(event) => formik.handleChange(event)}
                />
              </Grid>
              <Grid item xs={6} sx={styles.checkbox}>
                <CheckBox
                  label="Impacts Gratuity"
                  checked={formik.values.impactsGratuity}
                  name="impactsGratuity"
                  islabel
                  onChange={(event) => formik.handleChange(event)}
                />
              </Grid>
              <Grid item xs={6} sx={styles.checkbox}>
                <CheckBox
                  label="Is Part of CTC for Employee?"
                  checked={formik.values.partOfCTCForEmployee}
                  name="partOfCTCForEmployee"
                  islabel
                  onChange={(event) => formik.handleChange(event)}
                />
              </Grid>
              <Grid item xs={6} sx={styles.checkbox}>
                <CheckBox
                  label="Is Part of CTC for Company?"
                  checked={formik.values.partOfCTCForCompany}
                  name="partOfCTCForCompany"
                  islabel
                  onChange={(event) => formik.handleChange(event)}
                />
              </Grid>
              <Grid item xs={6} sx={styles.checkbox}>
                <CheckBox
                  label="Display on Salary Structure?"
                  checked={formik.values.displayOnSalStruct}
                  name="displayOnSalStruct"
                  islabel
                  onChange={(event) => formik.handleChange(event)}
                />
              </Grid>
              <Grid item xs={6} sx={styles.checkbox}>
                <CheckBox
                  label="Display on PaySlip?"
                  checked={formik.values.displayOnPS}
                  name="displayOnPS"
                  islabel
                  onChange={(event) => formik.handleChange(event)}
                />
              </Grid>
              <Grid item xs={6} sx={styles.checkbox}>
                <CheckBox
                  label="Is Loss of Pay (LOP) Applicable?"
                  checked={formik.values.isLopApplicable}
                  name="isLopApplicable"
                  islabel
                  onChange={(event) => formik.handleChange(event)}
                />
              </Grid>
              <Grid item xs={6} sx={styles.checkbox}>
                <CheckBox
                  label="Mandatory inclusion in Salary Structure?"
                  checked={formik.values.MandatryInclInSalStruct}
                  name="MandatryInclInSalStruct"
                  islabel
                  onChange={(event) => formik.handleChange(event)}
                />
              </Grid>
              {formik.errors.submit && (
                <Grid item xs={12}>
                  <FormHelperText error>{formik.errors.submit}</FormHelperText>
                </Grid>
              )}
              <Grid item xs={12}>
                <Stack direction="row" justifyContent={isEditMode ? 'space-between' : 'end'}>
                  {/* {isEditMode && (
                    <Button color="primary" size="large" variant="outlined">
                      Delete
                    </Button>
                  )} */}
                  <Stack direction="row" justifyContent="end" spacing={2}>
                    <Button size="Large" variant="outlined" sx={{ color: 'text.200 !important' }}>
                      Cancel
                    </Button>
                    <LoadingButton loading={loading} color="primary" size="large" type="submit" variant="contained">
                      {isEditMode ? 'Update' : 'Create'}
                    </LoadingButton>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </div>
        </form>
      </Grid>
    </Grid>
  );
};

export default PayHeadForm;

PayHeadForm.propTypes = {
  payheadDetails: PropTypes.shape(PropTypes.node),
  isEditMode: PropTypes.bool,
  results: PropTypes.instanceOf(Object),
};

PayHeadForm.defaultProps = {
  payheadDetails: {},
  isEditMode: false,
  results: {},
};
