import React from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import QueryDetails from 'components/Organisms/TaskAndQueries/QueryDetails';
import ActionQuery from 'components/Organisms/TaskAndQueries/ActionQuery';

const ActionQueryTemplate = ({
  reportDetails,
  handleEdit,
  actionMenuList,
  isRecall,
  handleRecall,
  handleAttachmentDownload,
  showEdit,
  handleSubmit,
  formData,
  resolutionStatusOptions,
  handleCancel,
  isReopen,
  breadcrumbData,
  pageHeading,
  auditLogData,
  isEscalate,
}) => {
  return (
    <BlankTemplate pageHeading={pageHeading} breadcrumbData={breadcrumbData} padding="2rem 3rem">
      <ActionQuery
        handleSubmit={handleSubmit}
        formData={formData}
        resolutionStatusOptions={resolutionStatusOptions}
        handleCancel={handleCancel}
        isReopen={isReopen}
        isEscalate={isEscalate}
      />
      <QueryDetails
        reportDetails={reportDetails}
        handleEdit={handleEdit}
        actionMenuList={actionMenuList}
        isRecall={isRecall}
        handleRecall={handleRecall}
        handleAttachmentDownload={handleAttachmentDownload}
        showEdit={showEdit}
        showActions={false}
        auditLogData={auditLogData}
      />
    </BlankTemplate>
  );
};

ActionQueryTemplate.defaultProps = {
  reportDetails: {
    queryNumber: '',
    title: '',
    resolutionStatus: '',
    openedBy: '',
    closedBy: '',
    openDate: '',
    closeDate: '',
    submissionDate: '',
    currentOwner: '',
    category: '',
    topic: '',
    description: '',
    attachments: '',
  },
  handleEdit: () => {},
  auditLogData: [],
  actionMenuList: [],
  isRecall: false,
  handleRecall: () => {},
  handleAttachmentDownload: () => {},
  showEdit: true,
  formData: {},
  handleSubmit: () => {},
  resolutionStatusOptions: [],
  handleCancel: () => {},
  isReopen: false,
  breadcrumbData: [],
  pageHeading: '',
  isEscalate: false,
};

ActionQueryTemplate.propTypes = {
  reportDetails: PropTypes.shape({
    queryNumber: PropTypes.string,
    title: PropTypes.string,
    resolutionStatus: PropTypes.string,
    openedBy: PropTypes.string,
    closedBy: PropTypes.string,
    openDate: PropTypes.string,
    closeDate: PropTypes.string,
    submissionDate: PropTypes.string,
    currentOwner: PropTypes.string,
    category: PropTypes.string,
    topic: PropTypes.string,
    description: PropTypes.string,
    attachments: PropTypes.string,
  }),
  handleEdit: PropTypes.func,
  auditLogData: PropTypes.arrayOf(
    PropTypes.shape({
      avatarSrc: PropTypes.string,
      name: PropTypes.string,
      date: PropTypes.string,
    }),
  ),
  actionMenuList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  isRecall: PropTypes.bool,
  handleRecall: PropTypes.func,
  handleAttachmentDownload: PropTypes.func,
  showEdit: PropTypes.bool,
  formData: PropTypes.shape({
    resolutionStatus: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    comments: PropTypes.string,
  }),
  handleSubmit: PropTypes.func,
  resolutionStatusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  handleCancel: PropTypes.func,
  isReopen: PropTypes.bool,
  breadcrumbData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
  pageHeading: PropTypes.string,
  isEscalate: PropTypes.bool,
};

export default ActionQueryTemplate;
