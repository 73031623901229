export const breakPointsValues = {
  xs: 360,
  sm: 599,
  md: 839,
  lg: 1439,
  xl: 1919,
  mdSmallStart: 1024,
  mdSmallEnd: 1280,
  mdMediumStart: 1366,
  mdMedium2: 1440,
  mdMedium3: 1536,
  mdMedium4: 1600,
  mdMediumEnd: 1920,
  mdLargeStart: 2048,
  mdLargeEnd: 2560,
};
