import React from 'react';
import { Typography, Stack } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

export const convertPayheadData = (payhead) => {
  if (!payhead.data) {
    return {};
  }
  const { companyPayheadInstance } = payhead.data;
  const pageHeading = companyPayheadInstance?.displayName;
  const payheadDetails = [
    {
      label: 'Name',
      value: <Typography variant="subtitle2">{companyPayheadInstance?.displayName}</Typography>,
    },
    {
      label: 'Payhead Type',
      value: <Typography variant="subtitle2">{companyPayheadInstance?.payheadType} </Typography>,
    },
    {
      label: 'currency',
      value: <Typography variant="subtitle2"> {companyPayheadInstance?.currency}</Typography>,
    },
    {
      label: 'Exemption Allowance Type',
      value: <Typography variant="subtitle2">{companyPayheadInstance?.exemptionStatuteReference} </Typography>,
    },
    {
      label: 'Exemption Level',
      value: <Typography variant="subtitle2">{payhead?.data?.exemptionAllowanceType} </Typography>,
    },
    {
      label: 'Max Exemption Limit (per Annum)',
      value: <Typography variant="subtitle2">{companyPayheadInstance?.exemptionLevel}</Typography>,
    },
    {
      label: 'Exemption Statute Reference',
      value: <Typography variant="subtitle2">{companyPayheadInstance?.maxExemptionLimitPA}</Typography>,
    },
  ];

  const ledgerDetails = [
    {
      label: 'General ledger Name',
      value: <Typography variant="subtitle2">{companyPayheadInstance?.glName}</Typography>,
    },
    {
      label: 'General ledger Code',
      value: <Typography variant="subtitle2">{companyPayheadInstance?.glCode} </Typography>,
    },
    {
      label: 'Cost Centre Code',
      value: <Typography variant="subtitle2"> {companyPayheadInstance?.costCentreCode}</Typography>,
    },
    {
      label: 'Compensation Type',
      value: <Typography variant="subtitle2">{companyPayheadInstance?.compensationType} </Typography>,
    },
    {
      label: 'Calculation Type',
      value: <Typography variant="subtitle2">{companyPayheadInstance?.calculationType} </Typography>,
    },
    {
      label: 'Calculation Formula',
      value: <Typography variant="subtitle2">{companyPayheadInstance?.calculationFormula}</Typography>,
    },
  ];

  const otherDetails = {
    heading: {
      label: 'Exemption Effective Date',
      value: <Typography variant="subtitle2">{companyPayheadInstance?.exemptionEffectiveDate}</Typography>,
    },
    data: [
      {
        label: 'Is Part Of CTC for Company?',
        formatValue: (value) => (
          <Stack direction="row" alignItems="center" gap={1}>
            <CheckIcon color="secondary.600" />
            <Typography variant="body2" color="text.300">
              {value}
            </Typography>
          </Stack>
        ),
      },
      {
        label: 'Is Part of CTC for Employee?',
        formatValue: (value) => (
          <Stack direction="row" alignItems="center" gap={1}>
            <CheckIcon color="secondary.600" />
            <Typography variant="body2" color="text.300">
              {value}
            </Typography>
          </Stack>
        ),
      },
      {
        label: 'Display on PaySlip?',
        formatValue: (value) => (
          <Stack direction="row" alignItems="center" gap={1}>
            <CheckIcon color="secondary.600" />
            <Typography variant="body2" color="text.300">
              {value}
            </Typography>
          </Stack>
        ),
      },
      {
        label: 'Display on Salary Structure?',
        formatValue: (value) => (
          <Stack direction="row" alignItems="center" gap={1}>
            <CheckIcon color="secondary.600" />
            <Typography variant="body2" color="text.300">
              {value}
            </Typography>
          </Stack>
        ),
      },
      {
        label: 'Mandatory Inclusion in Salary Structure?',
        formatValue: (value) => (
          <Stack direction="row" alignItems="center" gap={1}>
            <CheckIcon color="secondary.600" />
            <Typography variant="body2" color="text.300">
              {value}
            </Typography>
          </Stack>
        ),
      },
      {
        label: 'Is Loss Of Pay (LOP) applicable?',
        formatValue: (value) => (
          <Stack direction="row" alignItems="center" gap={1}>
            <CheckIcon color="secondary.600" />
            <Typography variant="body2" color="text.300">
              {value}
            </Typography>
          </Stack>
        ),
      },
    ],
  };

  return { pageHeading, payheadDetails, ledgerDetails, otherDetails };
};
