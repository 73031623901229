import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import HorizontalStepper from 'components/Molecules/HorizontalStepper';
import BasicProfile from 'components/Organisms/Profile/OnboardingRecords/BasicProfile/Edit';
import StatutoryIdentification from 'components/Organisms/Profile/OnboardingRecords/StatutoryIdentification/Edit';
import Dependent from 'components/Organisms/Profile/OnboardingRecords/Dependents/Edit';
import AdditionalDetail from 'components/Organisms/Profile/OnboardingRecords/AdditionalDetails/Edit';
import Finalize from 'components/Organisms/Profile/OnboardingRecords/Finalize/Edit';
import ContactDetails from 'components/Organisms/Profile/OnboardingRecords/ContactDetails/Edit';
import BankAccount from 'components/Organisms/Profile/OnboardingRecords/BankAccount/Edit';

const EditForms = ({
  dropdownOptions,
  formData,
  handleSubmitForm: handleSubmit,
  handleCancel,
  handleFinalizeDownload,
  handleFinalizeAttachment,
  loading,
  onboardingForm,
}) => {
  const [allTabsData, setAllTabsData] = useState({
    'BASIC PROFILE': formData['BASIC PROFILE'],
    'STATUTORY IDENTIFICATION': formData['STATUTORY IDENTIFICATION'],
    'ADDITIONAL DETAILS': formData['ADDITIONAL DETAILS'],
    'CONTACT DETAILS': formData['CONTACT DETAILS'],
    DEPENDENT: formData.DEPENDENT,
    'Bank Account': formData['Bank Account'],
    FINALIZE: formData.FINALIZE,
  });

  function updateFormData(data, label) {
    const finalData = {
      ...allTabsData,
      [label]: data,
    };
    setAllTabsData(finalData);
  }

  const initialIdentificationData = onboardingForm.formData['STATUTORY IDENTIFICATION'];
  const initialBasicProfileData = onboardingForm.formData['BASIC PROFILE'];

  const steps = [
    {
      label: 'BASIC PROFILE',
      content: (
        <BasicProfile
          {...dropdownOptions.BasicProfile}
          handleSubmit={(data) => {
            handleSubmit({ label: 'BASIC PROFILE', data });
          }}
          handleCancel={() => handleCancel('BASIC PROFILE')}
          propData={{ allTabsData, updateFormData, loading, initialBasicProfileData }}
          label="BASIC PROFILE"
        />
      ),
    },
    {
      label: 'STATUTORY IDENTIFICATION',
      content: (
        <StatutoryIdentification
          handleSubmit={(data) => {
            handleSubmit({ label: 'STATUTORY IDENTIFICATION', data });
          }}
          handleCancel={() => handleCancel('STATUTORY IDENTIFICATION')}
          propData={{ allTabsData, updateFormData, loading, initialIdentificationData }}
          label="STATUTORY IDENTIFICATION"
        />
      ),
    },
    {
      label: 'ADDITIONAL DETAILS',
      content: (
        <AdditionalDetail
          dropdownOptions={dropdownOptions.AdditionalDetail}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          propData={{ allTabsData, updateFormData, loading }}
          label="ADDITIONAL DETAILS"
        />
      ),
    },
    {
      label: 'CONTACT DETAILS',
      content: (
        <ContactDetails
          handleSubmit={(data) => {
            handleSubmit({ label: 'CONTACT DETAILS', data });
          }}
          handleCancel={() => handleCancel('CONTACT DETAILS')}
          propData={{ allTabsData, allowEdit: true, updateFormData, loading }}
          label="CONTACT DETAILS"
          updateBtnText="Save & Continue"
        />
      ),
    },
    {
      label: 'DEPENDENT',
      content: (
        <Dependent
          {...dropdownOptions.Dependent}
          handleSubmit={(data) => {
            handleSubmit({ label: 'DEPENDENT', data });
          }}
          handleCancel={() => handleCancel('DEPENDENT')}
          propData={{ allTabsData, updateFormData, loading }}
          label="DEPENDENT"
        />
      ),
    },
    {
      label: 'BANK ACCOUNT',
      content: (
        <BankAccount
          {...dropdownOptions.BankAccount}
          handleCancel={() => handleCancel('Bank Account')}
          handleSubmit={(data) => {
            handleSubmit({ label: 'Bank Account', data });
          }}
          propData={{ allTabsData, allowEdit: true, updateFormData, loading }}
          label="Bank Account"
          updateBtnText="Save & Continue"
        />
      ),
    },
  ];

  return <HorizontalStepper steps={steps} disableTabClick={false} isButtonVisible={false} />;
};

EditForms.defaultProps = {
  dropdownOptions: {
    BasicProfile: {
      maritalStatusOptions: [],
      bloodGroupOptions: [],
      locationOptions: [],
      genderOptions: [],
      onboardingReferenceOptions: [],
    },

    Dependent: {
      genderOptions: [],
      relationOptions: [],
    },

    BankAccount: {
      transactionTypeOptions: [],
      accountCurrencyOptions: [],
      purposeOptions: [],
      accountTypeOptions: [],
    },

    AdditionalDetail: {
      skillOptions: [],
      levelOptions: [],
      lifeEventOptions: [],
    },
  },

  formData: {},
  handleSubmitForm: () => {},
  handleCancel: () => {},
  handleFinalizeDownload: () => {},
  handleFinalizeAttachment: () => {},
  loading: false,
};

EditForms.propTypes = {
  loading: PropTypes.bool,
  dropdownOptions: PropTypes.shape({
    BasicProfile: PropTypes.shape({
      maritalStatusOptions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          name: PropTypes.string,
        }),
      ),

      bloodGroupOptions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          name: PropTypes.string,
        }),
      ),

      locationOptions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          name: PropTypes.string,
        }),
      ),

      genderOptions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          name: PropTypes.string,
        }),
      ),
    }),

    Dependent: PropTypes.shape({
      relationOptions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          name: PropTypes.string,
        }),
      ),

      genderOptions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          name: PropTypes.string,
        }),
      ),
    }),

    BankAccount: PropTypes.shape({
      transactionTypeOptions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          name: PropTypes.string,
        }),
      ),

      accountTypeOptions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          name: PropTypes.string,
        }),
      ),

      purposeOptions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          name: PropTypes.string,
        }),
      ),

      accountCurrencyOptions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          name: PropTypes.string,
        }),
      ),
    }),

    AdditionalDetail: PropTypes.shape({
      levelOptions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          name: PropTypes.string,
        }),
      ),

      skillOptions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          name: PropTypes.string,
        }),
      ),

      lifeEventOptions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          name: PropTypes.string,
        }),
      ),
    }),
  }),

  formData: PropTypes.shape({
    'BASIC PROFILE': PropTypes.shape({
      gender: PropTypes.string,
      fatherName: PropTypes.string,
      motherName: PropTypes.string,
      maritalstatus: PropTypes.string,
      spouseName: PropTypes.string,
      dateOfBirth: PropTypes.string,
      dateOfJoining: PropTypes.string,
      location: PropTypes.string,
      bloodGroup: PropTypes.string,
      onboardingReference: PropTypes.string,
      uploadProfile: PropTypes.string,
    }),

    'STATUTORY IDENTIFICATION': PropTypes.shape({
      panNumber: PropTypes.string,
      aadharNumber: PropTypes.string,
      uanNumber: PropTypes.string,
      uhidNumber: PropTypes.string,
    }),

    'ADDITIONAL DETAILS': PropTypes.shape({
      'Education/Certification History': PropTypes.shape({
        institutionName: PropTypes.string,
        certificationName: PropTypes.string,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        specialization: PropTypes.string,
        registrationNumber: PropTypes.string,
        referenceName: PropTypes.string,
        referenceNumber: PropTypes.string,
        referenceEmail: PropTypes.string,
        comments: PropTypes.string,
        attachments: PropTypes.string,
      }),

      'Employment History': PropTypes.shape({
        employeeName: PropTypes.string,
        employerAddress: PropTypes.string,
        employmentCommenceDate: PropTypes.string,
        employmentEndDate: PropTypes.string,
        reasonForLeaving: PropTypes.string,
        employeeId: PropTypes.string,
        startDesignation: PropTypes.string,
        endDesignation: PropTypes.string,
        referenceContactName: PropTypes.string,
        referenceContactDesignation: PropTypes.string,
        referenceContactPhone: PropTypes.string,
        referenceContactEmail: PropTypes.string,
        attachment: PropTypes.string,
      }),

      'Social Data': PropTypes.shape({
        skype: PropTypes.string,
        facebook: PropTypes.string,
        linkedin: PropTypes.string,
        googlePlus: PropTypes.string,
        twitter: PropTypes.string,
        instagram: PropTypes.string,
      }),

      'Visa Details': PropTypes.shape({
        passportNumber: PropTypes.string,
        passportIssueDate: PropTypes.string,
        passportExpDate: PropTypes.string,
        passportIssuePlace: PropTypes.string,
        passportCountry: PropTypes.string,
        passportGivenName: PropTypes.string,
        passportAttachments: PropTypes.string,
        visa: PropTypes.arrayOf(
          PropTypes.shape({
            issuePlace: PropTypes.string,
            validFrom: PropTypes.string,
            validTo: PropTypes.string,
            issueDate: PropTypes.string,
            referenceNumber: PropTypes.string,
            visaStatus: PropTypes.string,
            comments: PropTypes.string,
            attachment: PropTypes.string,
          }),
        ),
      }),

      'Skill Attainment Details': PropTypes.shape({
        eventDetails: PropTypes.string,
        eventDate: PropTypes.string,
        comments: PropTypes.string,
        attachments: PropTypes.string,
      }),

      'Key Life Event Details': PropTypes.shape({
        keyLifeEventDetails: PropTypes.string,
        eventDate: PropTypes.string,
        comments: PropTypes.string,
      }),
    }),

    'CONTACT DETAILS': PropTypes.shape({
      mobileNumber: PropTypes.arrayOf(PropTypes.string),
      emailAddress: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string,
      relation: PropTypes.string,
      emergencyMobile: PropTypes.string,
      emergencyEmail: PropTypes.string,
      permanentLocation: PropTypes.string,
      permanentAddressLine1: PropTypes.string,
      permanentAddressLine2: PropTypes.string,
      permanentAddressLine3: PropTypes.string,
      permanentCity: PropTypes.string,
      permanentState: PropTypes.string,
      permanentCountry: PropTypes.string,
      permanentPincode: PropTypes.number,
      currentLocation: PropTypes.string,
      currentAddressLine1: PropTypes.string,
      currentAddressLine2: PropTypes.string,
      currentAddressLine3: PropTypes.string,
      currentCity: PropTypes.string,
      currentState: PropTypes.string,
      currentCountry: PropTypes.string,
      currentPincode: PropTypes.number,
      ofcLocationName: PropTypes.string,
      ofcAddressLine1: PropTypes.string,
      ofcAddressLine2: PropTypes.string,
      ofcAddressLine3: PropTypes.string,
      ofcCity: PropTypes.string,
      ofcState: PropTypes.string,
      ofcCountry: PropTypes.string,
      ofcPincode: PropTypes.number,
      sameAddress: PropTypes.string,
    }),

    DEPENDENT: PropTypes.shape({
      gender: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      relation: PropTypes.string,
      dateOfBirth: PropTypes.string,
      mobileNumber: PropTypes.string,
      emailId: PropTypes.string,
      epfPerc: PropTypes.string,
      epsPerc: PropTypes.string,
      insurancePerc: PropTypes.string,
      otherPerc: PropTypes.string,
      gratuityPerc: PropTypes.string,
      emergencyContact: PropTypes.string,
    }),

    'Bank Account': PropTypes.shape({
      accountNumber: PropTypes.string,
      accountName: PropTypes.string,
      accountType: PropTypes.string,
      accountCurrency: PropTypes.string,
      purpose: PropTypes.string,
      transactionType: PropTypes.string,
      bankName: PropTypes.string,
      branchName: PropTypes.string,
      ifsc: PropTypes.string,
      swiftCode: PropTypes.string,
      locationName: PropTypes.string,
      addressLine1: PropTypes.string,
      addressLine2: PropTypes.string,
      addressLine3: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string,
      pincode: PropTypes.number,
    }),
  }),
  handleSubmitForm: PropTypes.func,
  handleCancel: PropTypes.func,
  handleFinalizeDownload: PropTypes.func,
  handleFinalizeAttachment: PropTypes.func,
};

export default EditForms;
