/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import EditOnboardingTemplate from 'components/Templates/Profile/OnboardingRecord/Edit';
import { useDispatch, useSelector } from 'react-redux';
import useToast from 'components/Provider/useToast';
import { Box, Skeleton } from '@mui/material';
import { updateOnBoardingRecordsAction } from 'api/employees';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser } from 'api/login';
import { updateUser } from 'store/slices/user';
import { formatOptions } from './formatOptions';
import { formatValues } from './formatValues';
import useFetchRecord from '../View/useFetchRecord';
import { formatBody } from './formatBody';

const EditOnboardingPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const addToast = useToast();
  const [loadingComplete, setLoadingComplete] = useState(true);
  const [loading, setLoading] = useState(false);
  const [record, fetchRecord] = useFetchRecord();
  const { user } = useSelector((state) => {
    return state.session;
  });

  const formattedRecord = formatValues(record, user);
  const formattedOptions = formatOptions(record);

  useEffect(() => {
    const fetchData = async () => {
      await fetchRecord();
      setLoadingComplete(false);
      return null;
    };
    fetchData();
  }, []);

  const handleSubmit = async ({ data }) => {
    setLoading(true);
    const newData = formatBody({ data, formattedRecord, record });

    try {
      const bodyFormData = new FormData();
      Object.keys(newData).forEach((key) => {
        bodyFormData.append(key, newData[key]);
      });

      const response = await updateOnBoardingRecordsAction(bodyFormData);
      if (response.status === 200) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.message || 'OnBoarding records updated successfully',
        });
        fetchRecord();
      }
      const currentUser = await getCurrentUser();
      dispatch(updateUser({ ...currentUser.data, authorities: user.authorities }));
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || 'Failed to update OnBoarding records',
      });
    }
    setLoading(false);
  };

  const handleCancel = () => {
    navigate('/profile/onboardingrecords');
  };

  return record.isLoading && loadingComplete ? (
    <Box sx={{ p: 4 }}>
      <Skeleton height={70} />
      <Skeleton />
      <Skeleton />
    </Box>
  ) : (
    <EditOnboardingTemplate
      onboardingForm={{
        dropdownOptions: formattedOptions,
        formData: formattedRecord,
      }}
      handleSubmit={(data) => handleSubmit(data)}
      handleCancel={handleCancel}
      loading={loading || record.isLoading}
    />
  );
};

export default EditOnboardingPage;
