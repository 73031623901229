import { formDataApi } from 'api/api';

export const acceptOfferAction = async (args) => {
  const response = await formDataApi.post(`restRecruitmentIndex/acceptOffer`, args);
  return response?.data;
};

export const rejectOfferAction = async (args) => {
  const response = await formDataApi.post(`restRecruitmentIndex/rejectOffer`, args);
  return response?.data;
};
