import { useSelector } from 'react-redux';
import { hasPermission, hasAnyPermission } from 'utils/permissions';

const RoleBasedGuard = ({ permissions, children, type }) => {
  const authorities = useSelector((state) => state.session.user.authorities);
  const permissionFunction = type === 'some' ? hasAnyPermission : hasPermission;
  if (permissionFunction(authorities, permissions)) return children;

  return 'You do not have permission to view this page.';
};

export default RoleBasedGuard;
