import React, { useState } from 'react';
import ProfileEditEmploymentHistoryTemplate from 'components/Templates/Profile/EmploymentHistory/Edit';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useToast from 'components/Provider/useToast';
import dayjs from 'dayjs';
import FormData from 'form-data';
import { updateEmploymentHistory } from 'api/profile';
import { dateFormat } from 'utils/commonUtils';

const EmploymentHistoryAddEdit = () => {
  const addToast = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { employmentHistoryDetails } = useSelector((state) => {
    return state.profile;
  });

  const editOptions = {
    defaultValue: {
      employment:
        employmentHistoryDetails &&
        employmentHistoryDetails?.map((employment) => {
          return {
            employmentId: employment?.id || '',
            employeeName: employment?.employerName || '',
            employerAddress: employment?.employerAddress || '',
            employmentCommenceDate: employment?.employmentCommenceDate ? dayjs(employment?.employmentCommenceDate) : '',
            employmentEndDate: employment?.employmentEndDate ? dayjs(employment?.employmentEndDate) : '',
            reasonForLeaving: employment?.reasonForLeaving || '',
            employeeId: employment?.employeeID,
            startDesignation: employment?.startDesignation || '',
            endDesignation: employment?.endDesignation || '',
            referenceContactName: employment?.referenceContactName || '',
            referenceContactDesignation: employment?.referenceContactDesignation || '',
            referenceContactPhone: employment?.referenceContactPhone || '',
            referenceContactEmail: employment?.referenceContactEmail || '',
            attachment: employment?.documents.length > 0 ? employment?.documents[0].fileName : '',
            isNew: false,
          };
        }),
    },
  };

  const getDataSet = (employment, index) => {
    const data = {
      [`pastEmploymentRecords[${index}].employerName`]: employment?.employeeName,
      [`pastEmploymentRecords[${index}].employerAddress`]: employment?.employerAddress,
      [`pastEmploymentRecords[${index}].employmentCommenceDate`]: dateFormat(employment?.employmentCommenceDate),
      [`pastEmploymentRecords[${index}].employmentEndDate`]: dateFormat(employment?.employmentEndDate),
      [`pastEmploymentRecords[${index}].reasonForLeaving`]: employment?.reasonForLeaving,
      [`pastEmploymentRecords[${index}].employeeID`]: employment?.employeeId,
      [`pastEmploymentRecords[${index}].startDesignation`]: employment?.startDesignation,
      [`pastEmploymentRecords[${index}].endDesignation`]: employment?.endDesignation,
      [`pastEmploymentRecords[${index}].referenceContactName`]: employment?.referenceContactName,
      [`pastEmploymentRecords[${index}].referenceContactDesignation`]: employment?.referenceContactDesignation,
      [`pastEmploymentRecords[${index}].referenceContactPhone`]: employment?.referenceContactPhone,
      [`pastEmploymentRecords[${index}].referenceContactEmail`]: employment?.referenceContactEmail,
    };

    if (employment?.attachments) {
      data[`pastEmploymentRecords[${index}].attachment`] = employment?.attachments;
    } else if (!employment?.isNew) {
      data[`pastEmploymentRecords[${index}].id`] = employmentHistoryDetails[index]?.id;
    }
    return data;
  };

  const configureFormDataAndUpdate = async (employment) => {
    const formData = new FormData();
    employment.forEach((payload) => {
      const index = employment.indexOf(payload);
      const formDataObject = getDataSet(payload, index);
      Object.keys(formDataObject).forEach((property) => {
        formData.append(property, formDataObject[property]);
      });
    });
    formData.append(`empRecordCtr`, employment.length);
    return formData;
  };

  const handleCancel = () => {
    navigate('/profile/employment-history');
  };

  const handleSubmit = async (formikData) => {
    const bodyFormData = await configureFormDataAndUpdate(formikData.employment);

    try {
      setLoading(true);
      const response = await updateEmploymentHistory(bodyFormData);
      if (response?.data?.status !== 'ERROR') {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || 'Employment history updated successfully',
        });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || 'Failed to update employment history',
      });
    } finally {
      setLoading(false);
      navigate('/profile/employment-history');
    }
  };

  return (
    <ProfileEditEmploymentHistoryTemplate
      defaultValue={editOptions.defaultValue}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      loading={loading}
    />
  );
};

export default EmploymentHistoryAddEdit;
