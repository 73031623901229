import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import AddQueryTemplate from 'components/Templates/TaskAndQueries/AddQuery';
import { useThunk } from 'hooks/useThunk';
import { fetchEditQueryDetails, fetchCreateQuery } from 'store';
import { Box, Skeleton, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import DeleteQuery from 'components/Organisms/TaskAndQueries/DeleteQuery';
import useServices from '../common/useServices';

const AddQuery = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const isEditMode = location.pathname.includes('edit');
  const services = useServices();

  const editBreadcrumbData = [
    { id: 1, name: 'My Actionable Queries', path: '/home/actionable-queries', isActive: false },
    { id: 2, name: 'Query Details', path: `/home/actionable-queries/${id}/view`, isActive: false },
    { id: 2, name: 'Edit', isActive: true },
  ];

  const addBreadcrumbData = [
    { id: 1, name: 'My Actionable Queries', path: '/home/actionable-queries', isActive: false },
    { id: 2, name: 'Add New', isActive: true },
  ];
  const [topicOptions, setTopicOptions] = useState([]);
  const [getCreateQuery, isLoadingCreateQuery, loadingCreateQueryError] = useThunk(fetchCreateQuery);
  const [getQueryDetails, isLoadingQueryDetails, loadingQueryDetailsError] = useThunk(fetchEditQueryDetails);

  const { createQueriesData = {}, editQueryDetails = {} } = useSelector((stateData) => stateData?.queries);
  const categoriesList = createQueriesData?.categoriesList;
  const categoryOptions = !isEmpty(categoriesList)
    ? Object.keys(categoriesList)?.map((item) => ({
        id: categoriesList[item]?.id,
        name: `${categoriesList[item]?.name || ''} (${categoriesList[item]?.categoryCode || ''})`,
      }))
    : [];

  const handleTopicOptions = async (catId) => {
    const topicsMap = (createQueriesData && createQueriesData?.catTopicsMap?.[catId]) || [];
    await setTopicOptions(() => {
      return !isEmpty(topicsMap)
        ? Object.keys(topicsMap)?.map((item) => ({
            id: topicsMap[item]?.id,
            name: `${topicsMap[item].name || ''} (${topicsMap[item]?.topicCode || ''})`,
          }))
        : [];
    });
  };

  useEffect(() => {
    async function getCreateQueryData() {
      const query = new URLSearchParams({});
      await getCreateQuery(query);
    }
    getCreateQueryData();
  }, [getCreateQuery]);

  useEffect(() => {
    (async () => {
      if (isEditMode) {
        const query = new URLSearchParams({});
        query.append('id', id);
        await getQueryDetails(query);
      }
    })();
  }, [isEditMode, id, getQueryDetails]);

  useEffect(() => {
    if (!!isEditMode && editQueryDetails?.queryInstance?.categoryId) {
      (async () => {
        await handleTopicOptions(editQueryDetails?.queryInstance?.categoryId);
      })();
    }
  }, [isEditMode, categoriesList, editQueryDetails?.queryInstance?.categoryId]);

  const defaultValues = isEditMode
    ? {
        id: editQueryDetails?.queryInstance?.id,
        title: editQueryDetails?.queryInstance?.title,
        category: editQueryDetails?.queryInstance?.categoryId,
        topic: editQueryDetails?.queryInstance?.topicId,
        description: editQueryDetails?.queryInstance?.description,
        documentName: editQueryDetails?.queryInstance?.attachment?.documentName,
        currentOwner: editQueryDetails?.queryInstance?.currentOwnerId,
        resolutionStatus: editQueryDetails?.queryInstance?.resolutionStatus,
      }
    : {};

  const handleSubmit = async (formData) => {
    setLoading(true);
    await services.saveQueryService(isEditMode, formData);
    setLoading(false);
  };

  const handleDeleteQuery = () => {
    if (isEditMode) {
      services.handleDeleteDialog();
    }
  };

  if (loadingCreateQueryError !== null || (!!isEditMode && loadingQueryDetailsError)) {
    return (
      <Typography variant="h6">
        {loadingCreateQueryError?.message || loadingQueryDetailsError?.message || 'Error while loading query details.'}
      </Typography>
    );
  }

  if (isLoadingCreateQuery || (!!isEditMode && isLoadingQueryDetails)) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={42} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  return (
    <Box>
      <AddQueryTemplate
        breadcrumbData={isEditMode ? editBreadcrumbData : addBreadcrumbData}
        pageHeading={isEditMode ? 'Modify Query' : 'Add New Query'}
        loading={loading}
        handleSubmit={handleSubmit}
        formData={defaultValues}
        categoryOptions={categoryOptions}
        topicOptions={topicOptions}
        handleDeleteQuery={handleDeleteQuery}
        isEdit={isEditMode}
        handleCancel={() => navigate('/home/actionable-queries')}
        handleTopicOptions={handleTopicOptions}
      />
      {!!isEditMode && editQueryDetails !== null && (
        <DeleteQuery
          open={services.showDeleteDialog}
          handleClose={services.handleDeleteDialog}
          handleCancel={services.handleDeleteDialog}
          handleDelete={() => {
            services.deleteQueryService(editQueryDetails?.queryInstance?.id);
          }}
          fileName={editQueryDetails?.queryInstance?.attachment?.documentName || ''}
        />
      )}
    </Box>
  );
};

export default AddQuery;
