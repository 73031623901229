import React from 'react';
import PropTypes from 'prop-types';
import MuiTab from 'components/Molecules/Tab';
import LCPoliciesTab from 'components/Organisms/Employee/LCPolicies/List/LCPoliciesTab';
import BulkUpdateTab from 'components/Organisms/Employee/LCPolicies/List/BulkUpdateTab';
import EmployeeMappingTab from 'components/Organisms/Employee/LCPolicies/List/EmployeeMappingTab';
import { Box } from '@mui/material';
import { policyListStyle } from './style';

const LCPolicyListsTemplate = ({
  lcPoliciesGraphData,
  policyListItems,
  handleSearch,
  handleSearchAdd,
  listFilterFields,
  listHandleFilter,
  policyName,
  allEmployees,
  empColumns,
  empTotalRecordCount,
  empLoadNextPageData,
  empHandleSort,
  empHandleRowClick,
  empPage,
  empSortBy,
  empSortDirection,
  empHandleSelect,
  empOnSelectedChange,
  handleDetails,
  handleAdd,
  handleDelete,
  handleCancel,
  openDeleteLCPolicy,
  handleClose,
  handleDeleteEmployee,
  handleCancelEmployee,
  openEmployee,
  handleCloseEmployee,
  numOfEmployee,
  handleRemoveEmployee,
  bulkUpdateFilterFields,
  bulkUpdateHandleFilter,
  bulkUpdateRows,
  bulkUpdateTotalRecordCount,
  bulkUpdateLoadNextPageData,
  bulkUpdateHandleSort,
  bulkUpdateHandleRowClick,
  bulkUpdatePage,
  bulkUpdateSortBy,
  bulkUpdateSortDirection,
  bulkUpdateHandleSelect,
  bulkUpdateOnSelectedChange,
  changePolicyHandleClick,
  bulkUpdateHandleCancel,
  empMappingGraphData,
  empMappedFilterFields,
  empMappedHandleFilter,
  empMappedRows,
  empMappedTotalRecordCount,
  empMappedLoadNextPageData,
  empMappedHandleSort,
  empMappedHandleRowClick,
  empMappedPage,
  empMappedSortBy,
  empMappedSortDirection,
  empMappedHandleCancel,
  empMappedHandleApplyChanges,
  empUnMappedFilterFields,
  empUnMappedHandleFilter,
  empUnMappedRows,
  empUnMappedTotalRecordCount,
  empUnMappedLoadNextPageData,
  empUnMappedHandleSort,
  empUnMappedHandleRowClick,
  empUnMappedPage,
  empUnMappedSortBy,
  empUnMappedSortDirection,
  empUnMappedHandleCancel,
  empUnMappedHandleApplyChanges,
  fetchMorePolicyListItems,
  handlePolicyItemClick,
  selectedItems,
  onSelect,
  onSelectAll,
  showRowSelector,
  showPagination,
  keyColumn,
  actionMenuLists,
  bulkUpdateColumns,
  onTabChange,
  lcPolicies,
  loading,
}) => {
  const policyListTab = [
    {
      label: 'Life Cycle Policies',
      onChange: () => {},
      RenderComponent: LCPoliciesTab,
      componentProps: {
        lcPoliciesGraphData,
        policyListItems,
        handleSearch,
        handleSearchAdd,
        listFilterFields,
        listHandleFilter,
        policyName,
        allEmployees,
        empColumns,
        empTotalRecordCount,
        empLoadNextPageData,
        empHandleSort,
        empHandleRowClick,
        empPage,
        empSortBy,
        empSortDirection,
        empHandleSelect,
        empOnSelectedChange,
        handleDetails,
        handleAdd,
        handleDelete,
        handleCancel,
        openDeleteLCPolicy,
        handleClose,
        handleDeleteEmployee,
        handleCancelEmployee,
        openEmployee,
        handleCloseEmployee,
        numOfEmployee,
        handleRemoveEmployee,
        fetchMorePolicyListItems,
        handlePolicyItemClick,
        selectedItems,
        onSelect,
        onSelectAll,
        showRowSelector,
        showPagination,
        keyColumn,
        actionMenuLists,
        loading,
      },
    },
    {
      label: 'Bulk Updates',
      onChange: () => {},
      RenderComponent: BulkUpdateTab,
      componentProps: {
        bulkUpdateFilterFields,
        bulkUpdateHandleFilter,
        bulkUpdateRows,
        bulkUpdateTotalRecordCount,
        bulkUpdateLoadNextPageData,
        bulkUpdateHandleSort,
        bulkUpdateHandleRowClick,
        bulkUpdatePage,
        bulkUpdateSortBy,
        bulkUpdateSortDirection,
        bulkUpdateHandleSelect,
        bulkUpdateOnSelectedChange,
        changePolicyHandleClick,
        bulkUpdateHandleCancel,
        bulkUpdateColumns,
        lcPolicies,
        loading,
      },
    },
    {
      label: 'Employee Mappings',
      onChange: () => {},
      RenderComponent: EmployeeMappingTab,
      componentProps: {
        empMappingGraphData,
        empMappedFilterFields,
        empMappedHandleFilter,
        empMappedRows,
        empMappedTotalRecordCount,
        empMappedLoadNextPageData,
        empMappedHandleSort,
        empMappedHandleRowClick,
        empMappedPage,
        empMappedSortBy,
        empMappedSortDirection,
        empMappedHandleCancel,
        empMappedHandleApplyChanges,
        empUnMappedFilterFields,
        empUnMappedHandleFilter,
        empUnMappedRows,
        empUnMappedTotalRecordCount,
        empUnMappedLoadNextPageData,
        empUnMappedHandleSort,
        empUnMappedHandleRowClick,
        empUnMappedPage,
        empUnMappedSortBy,
        empUnMappedSortDirection,
        empUnMappedHandleCancel,
        empUnMappedHandleApplyChanges,
        loading,
      },
    },
  ];
  return (
    <Box sx={policyListStyle.wrapper}>
      <MuiTab
        textColor="primary"
        indicatorColor="primary"
        tabs={policyListTab}
        panelPadding={{ xs: '2rem 0', md: '0' }}
        orientation="horizontal"
        variant="scrollable"
        scrollButtons={false}
        onTabChange={onTabChange}
      />
    </Box>
  );
};

LCPolicyListsTemplate.defaultProps = {
  lcPoliciesGraphData: [
    { name: 'Invite Sent', value: 50 },
    { name: 'Pending invites', value: 8 },
    { name: 'Onboarding Initated', value: 4 },
  ],
  handleSearch: () => {},
  handleSearchAdd: () => {},
  policyListItems: [
    {
      id: 1,
      heading: 'Default Life Cycle Policy',
      subHeading: '45 employees',
      active: true,
      employeeList: [
        {
          id: 1,
          employeeId: 101,
          employeeName: 'Employee 1',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 102,
          employeeName: 'Employee 2',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 2,
      heading: 'Life Cycle Policy 2022',
      subHeading: '45 employees',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 103,
          employeeName: 'Employee 3',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 104,
          employeeName: 'Employee 4',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 3,
      heading: 'Updated Life Cycle Policy',
      subHeading: '45 employees',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 105,
          employeeName: 'Employee 5',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 106,
          employeeName: 'Employee 6',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 4,
      heading: 'Description',
      subHeading: 'Vel Diam ultiricies diashadsajdn',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 107,
          employeeName: 'Employee 7',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 108,
          employeeName: 'Employee 8',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 5,
      heading: 'Default Life Cycle Policy',
      subHeading: '45 employees',
      active: true,
      employeeList: [
        {
          id: 1,
          employeeId: 101,
          employeeName: 'Employee 1',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 102,
          employeeName: 'Employee 2',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 6,
      heading: 'Life Cycle Policy 2022',
      subHeading: '45 employees',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 103,
          employeeName: 'Employee 3',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 104,
          employeeName: 'Employee 4',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 7,
      heading: 'Updated Life Cycle Policy',
      subHeading: '45 employees',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 105,
          employeeName: 'Employee 5',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 106,
          employeeName: 'Employee 6',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 8,
      heading: 'Description',
      subHeading: 'Vel Diam ultiricies diashadsajdn',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 107,
          employeeName: 'Employee 7',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 108,
          employeeName: 'Employee 8',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 9,
      heading: 'Default Life Cycle Policy',
      subHeading: '45 employees',
      active: true,
      employeeList: [
        {
          id: 1,
          employeeId: 101,
          employeeName: 'Employee 1',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 102,
          employeeName: 'Employee 2',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 10,
      heading: 'Life Cycle Policy 2022',
      subHeading: '45 employees',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 103,
          employeeName: 'Employee 3',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 104,
          employeeName: 'Employee 4',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 11,
      heading: 'Updated Life Cycle Policy',
      subHeading: '45 employees',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 105,
          employeeName: 'Employee 5',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 106,
          employeeName: 'Employee 6',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 12,
      heading: 'Description',
      subHeading: 'Vel Diam ultiricies diashadsajdn',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 107,
          employeeName: 'Employee 7',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 108,
          employeeName: 'Employee 8',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 13,
      heading: 'Default Life Cycle Policy',
      subHeading: '45 employees',
      active: true,
      employeeList: [
        {
          id: 1,
          employeeId: 101,
          employeeName: 'Employee 1',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 102,
          employeeName: 'Employee 2',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 14,
      heading: 'Life Cycle Policy 2022',
      subHeading: '45 employees',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 103,
          employeeName: 'Employee 3',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 15,
          employeeId: 104,
          employeeName: 'Employee 4',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 15,
      heading: 'Updated Life Cycle Policy',
      subHeading: '45 employees',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 105,
          employeeName: 'Employee 5',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 106,
          employeeName: 'Employee 6',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 16,
      heading: 'Description',
      subHeading: 'Vel Diam ultiricies diashadsajdn',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 107,
          employeeName: 'Employee 7',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 108,
          employeeName: 'Employee 8',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 17,
      heading: 'Default Life Cycle Policy',
      subHeading: '45 employees',
      active: true,
      employeeList: [
        {
          id: 1,
          employeeId: 101,
          employeeName: 'Employee 1',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 102,
          employeeName: 'Employee 2',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 18,
      heading: 'Life Cycle Policy 2022',
      subHeading: '45 employees',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 103,
          employeeName: 'Employee 3',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 104,
          employeeName: 'Employee 4',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 19,
      heading: 'Updated Life Cycle Policy',
      subHeading: '45 employees',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 105,
          employeeName: 'Employee 5',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 106,
          employeeName: 'Employee 6',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 20,
      heading: 'Description',
      subHeading: 'Vel Diam ultiricies diashadsajdn',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 107,
          employeeName: 'Employee 7',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 108,
          employeeName: 'Employee 8',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 21,
      heading: 'Default Life Cycle Policy',
      subHeading: '45 employees',
      active: true,
      employeeList: [
        {
          id: 1,
          employeeId: 101,
          employeeName: 'Employee 1',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 102,
          employeeName: 'Employee 2',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 22,
      heading: 'Life Cycle Policy 2022',
      subHeading: '45 employees',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 103,
          employeeName: 'Employee 3',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 104,
          employeeName: 'Employee 4',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 23,
      heading: 'Updated Life Cycle Policy',
      subHeading: '45 employees',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 105,
          employeeName: 'Employee 5',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 106,
          employeeName: 'Employee 6',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 24,
      heading: 'Description',
      subHeading: 'Vel Diam ultiricies diashadsajdn',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 107,
          employeeName: 'Employee 7',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 108,
          employeeName: 'Employee 8',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 25,
      heading: 'Default Life Cycle Policy',
      subHeading: '45 employees',
      active: true,
      employeeList: [
        {
          id: 1,
          employeeId: 101,
          employeeName: 'Employee 1',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 102,
          employeeName: 'Employee 2',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 26,
      heading: 'Life Cycle Policy 2022',
      subHeading: '45 employees',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 103,
          employeeName: 'Employee 3',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 104,
          employeeName: 'Employee 4',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 27,
      heading: 'Updated Life Cycle Policy',
      subHeading: '45 employees',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 105,
          employeeName: 'Employee 5',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 106,
          employeeName: 'Employee 6',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 28,
      heading: 'Description',
      subHeading: 'Vel Diam ultiricies diashadsajdn',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 107,
          employeeName: 'Employee 7',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 108,
          employeeName: 'Employee 8',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 29,
      heading: 'Default Life Cycle Policy',
      subHeading: '45 employees',
      active: true,
      employeeList: [
        {
          id: 1,
          employeeId: 101,
          employeeName: 'Employee 1',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 102,
          employeeName: 'Employee 2',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 30,
      heading: 'Life Cycle Policy 2022',
      subHeading: '45 employees',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 103,
          employeeName: 'Employee 3',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 104,
          employeeName: 'Employee 4',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 31,
      heading: 'Updated Life Cycle Policy',
      subHeading: '45 employees',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 105,
          employeeName: 'Employee 5',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 106,
          employeeName: 'Employee 6',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 32,
      heading: 'Description',
      subHeading: 'Vel Diam ultiricies diashadsajdn',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 107,
          employeeName: 'Employee 7',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 108,
          employeeName: 'Employee 8',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
  ],
  listFilterFields: [
    {
      name: 'Employee ID',
      type: 'number',
    },
    {
      name: 'Employee Name',
      type: 'string',
    },
    {
      name: 'LC Policy',
      type: 'string',
    },
    {
      name: 'Department',
      type: 'string',
    },
    {
      name: 'Designation',
      type: 'string',
    },

    {
      name: 'Location',
      type: 'string',
    },
  ],
  listHandleFilter: () => {},
  policyName: 'Default life Cycle Policy',
  allEmployees: [
    {
      id: 1,
      employeeId: 101,
      employeeName: 'Employee 1',
      lcPolicy: 'Life Cycle Policy 2022',
      department: 'Finance',
      designation: 'Base Department',
      location: 'mumbai',
      selected: true,
    },
    {
      id: 2,
      employeeId: 102,
      employeeName: 'Employee 2',
      lcPolicy: 'Life Cycle Policy 2022',
      department: 'Services',
      designation: 'Base Department',
      location: 'mumbai',
    },
  ],
  empColumns: [
    {
      fieldName: 'employeeId',
      numeric: true,
      label: 'EMPLOYEE ID',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'employeeName',
      numeric: true,
      label: 'EMPLOYEE NAME',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'lcPolicy',
      numeric: false,
      label: 'LC POLICY',
      formatValue: ({ row, column }) => row[column.fieldName],
    },

    {
      fieldName: 'department',
      numeric: true,
      label: 'DEPARTMENT',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'designation',
      numeric: true,
      label: 'DESIGNATION',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'location',
      numeric: true,
      label: 'LOCATION',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
  ],
  empTotalRecordCount: 5,
  empLoadNextPageData: () => {},
  empHandleSort: () => {},
  empHandleRowClick: () => {},
  empPage: 1,
  empSortBy: '',
  empSortDirection: 'asc',
  empHandleSelect: () => {},
  empOnSelectedChange: () => {},
  handleDetails: () => {},
  handleAdd: () => {},
  handleDelete: () => {},
  handleCancel: () => {},
  openDeleteLCPolicy: false,
  handleClose: () => {},
  handleDeleteEmployee: () => {},
  handleCancelEmployee: () => {},
  openEmployee: false,
  handleCloseEmployee: () => {},
  numOfEmployee: 1,
  handleRemoveEmployee: () => {},
  bulkUpdateFilterFields: [],
  bulkUpdateHandleFilter: () => {},
  bulkUpdateTotalRecordCount: 5,
  bulkUpdateLoadNextPageData: () => {},
  bulkUpdateHandleSort: () => {},
  bulkUpdateHandleRowClick: () => {},
  bulkUpdatePage: 1,
  bulkUpdateSortBy: '',
  bulkUpdateSortDirection: 'asc',
  bulkUpdateHandleSelect: () => {},
  bulkUpdateOnSelectedChange: () => {},
  changePolicyHandleClick: () => {},
  bulkUpdateHandleCancel: () => {},

  empMappingGraphData: [
    { name: 'Invite Sent', value: 50 },
    { name: 'Pending invites', value: 8 },
    { name: 'Onboarding Initated', value: 4 },
  ],
  empMappedFilterFields: [
    {
      name: 'Employee Id',
      type: 'number',
    },
    {
      name: 'Employee Name',
      type: 'string',
    },
    {
      name: 'Lc Policy',
      type: 'number',
    },
    {
      name: 'Status',
      type: 'string',
    },
    {
      name: 'Department',
      type: 'string',
    },
    {
      name: 'Designation',
      type: 'string',
    },
    {
      name: 'Location',
      type: 'string',
    },
    {
      name: 'Employment Type',
      type: 'string',
    },
  ],
  empMappedHandleFilter: () => {},
  empMappedRows: [
    {
      id: '1',
      employeeId: 1,
      employeeName: 'Sachins',
      lcPolicy: [
        { id: 0, name: 'Default Life Cycle Policy' },
        { id: 1, name: 'Life Cycle Policy 2022' },
        { id: 2, name: 'Updated Life Cycle Policy' },
        { id: 3, name: 'Description' },
      ],
      status: 'Mapped',
      department: 'IT',
      designation: 'Design Head',
      location: 'Mumbai',
      employmentType: 'Permanent',
    },
    {
      id: '2',
      employeeId: 2,
      employeeName: 'Sachin',
      lcPolicy: [
        { id: 0, name: 'Default Life Cycle Policy' },
        { id: 1, name: 'Life Cycle Policy 2022' },
        { id: 2, name: 'Updated Life Cycle Policy' },
        { id: 3, name: 'Description' },
      ],
      status: 'Mapped',
      department: 'IT',
      designation: 'Design Head',
      location: 'Mumbai',
      employmentType: 'Permanent',
    },
    {
      id: '3',
      employeeId: 3,
      employeeName: 'Sachin',
      lcPolicy: [
        { id: 0, name: 'Default Life Cycle Policy' },
        { id: 1, name: 'Life Cycle Policy 2022' },
        { id: 2, name: 'Updated Life Cycle Policy' },
        { id: 3, name: 'Description' },
      ],
      status: 'Mapped',
      department: 'IT',
      designation: 'Design Head',
      location: 'Mumbai',
      employmentType: 'Permanent',
    },
    {
      id: '4',
      employeeId: 4,
      employeeName: 'Sachin',
      lcPolicy: [
        { id: 0, name: 'Default Life Cycle Policy' },
        { id: 1, name: 'Life Cycle Policy 2022' },
        { id: 2, name: 'Updated Life Cycle Policy' },
        { id: 3, name: 'Description' },
      ],
      status: 'Mapped',
      department: 'IT',
      designation: 'Design Head',
      location: 'Mumbai',
      employmentType: 'Permanent',
    },
  ],
  empMappedTotalRecordCount: 5,
  empMappedLoadNextPageData: () => {},
  empMappedHandleSort: () => {},
  empMappedHandleRowClick: () => {},
  empMappedPage: 1,
  empMappedSortBy: '',
  empMappedSortDirection: 'asc',
  empMappedHandleCancel: () => {},
  empMappedHandleApplyChanges: () => {},
  empUnMappedFilterFields: [
    {
      name: 'Employee Id',
      type: 'number',
    },
    {
      name: 'Employee Name',
      type: 'string',
    },
    {
      name: 'Lc Policy',
      type: 'number',
    },
    {
      name: 'Status',
      type: 'string',
    },
    {
      name: 'Department',
      type: 'string',
    },
    {
      name: 'Designation',
      type: 'string',
    },
    {
      name: 'Location',
      type: 'string',
    },
    {
      name: 'Employment Type',
      type: 'string',
    },
  ],
  empUnMappedHandleFilter: () => {},
  empUnMappedRows: [
    {
      id: '1',
      employeeId: 1,
      employeeName: 'Sachin',
      lcPolicy: [
        { id: 0, name: 'Default Life Cycle Policy' },
        { id: 1, name: 'Life Cycle Policy 2022' },
        { id: 2, name: 'Updated Life Cycle Policy' },
        { id: 3, name: 'Description' },
      ],
      status: 'UnMapped',
      department: 'IT',
      designation: 'Design Head',
      location: 'Mumbai',
      employmentType: 'Permanent',
    },
    {
      id: '2',
      employeeId: 2,
      employeeName: 'Sachin',
      lcPolicy: [
        { id: 0, name: 'Default Life Cycle Policy' },
        { id: 1, name: 'Life Cycle Policy 2022' },
        { id: 2, name: 'Updated Life Cycle Policy' },
        { id: 3, name: 'Description' },
      ],
      status: 'UnMapped',
      department: 'IT',
      designation: 'Design Head',
      location: 'Mumbai',
      employmentType: 'Permanent',
    },
    {
      id: '3',
      employeeId: 3,
      employeeName: 'Sachin',
      lcPolicy: [
        { id: 0, name: 'Default Life Cycle Policy' },
        { id: 1, name: 'Life Cycle Policy 2022' },
        { id: 2, name: 'Updated Life Cycle Policy' },
        { id: 3, name: 'Description' },
      ],
      status: 'UnMapped',
      department: 'IT',
      designation: 'Design Head',
      location: 'Mumbai',
      employmentType: 'Permanent',
    },
    {
      id: '4',
      employeeId: 4,
      employeeName: 'Sachin',
      lcPolicy: [
        { id: 0, name: 'Default Life Cycle Policy' },
        { id: 1, name: 'Life Cycle Policy 2022' },
        { id: 2, name: 'Updated Life Cycle Policy' },
        { id: 3, name: 'Description' },
      ],
      status: 'UnMapped',
      department: 'IT',
      designation: 'Design Head',
      location: 'Mumbai',
      employmentType: 'Permanent',
    },
  ],
  empUnMappedTotalRecordCount: 5,
  empUnMappedLoadNextPageData: () => {},
  empUnMappedHandleSort: () => {},
  empUnMappedHandleRowClick: () => {},
  empUnMappedPage: 1,
  empUnMappedSortBy: '',
  empUnMappedSortDirection: 'asc',
  empUnMappedHandleCancel: () => {},
  empUnMappedHandleApplyChanges: () => {},
  fetchMorePolicyListItems: () => {},
  handlePolicyItemClick: () => {},
  bulkUpdateColumns: [
    {
      fieldName: `employeeId`,
      label: 'EMPLOYEE ID',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `firstName`,
      label: 'FIRST NAME',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `lastName`,
      label: 'LAST NAME',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `lcPolicy`,
      label: 'LIFE CYCLE POLICY',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `status`,
      label: 'EMPLOYMENT STATUS',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `department`,
      label: 'Department',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `designation`,
      label: 'Designation',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `employmentType`,
      label: 'Employment Type',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `supervisor`,
      label: 'Supervisor',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `selectedPolicy`,
      label: 'Selected Policy',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
  ],
  bulkUpdateRows: [
    {
      id: '1',
      employeeId: 0,
      firstName: 'Sharad',
      lastName: 'Deshpandey',
      lcPolicy: [
        { id: 0, name: 'Default Life Cycle Policy' },
        { id: 1, name: 'Life Cycle Policy 2022' },
        { id: 2, name: 'Updated Life Cycle Policy' },
        { id: 3, name: 'Description' },
      ],
      status: 'Active',
      department: 'IT',
      designation: 'Design Head',
      location: 'Mumbai',
      employmentType: 'Permanent',
      supervisor: 'Deshpandey',
      selectedPolicy: 'Amir ',
    },
    {
      id: '2',
      employeeId: 1,
      firstName: 'Sharad',
      lastName: 'Deshpandey',
      lcPolicy: [
        { id: 0, name: 'Default Life Cycle Policy' },
        { id: 1, name: 'Life Cycle Policy 2022' },
        { id: 2, name: 'Updated Life Cycle Policy' },
        { id: 3, name: 'Description' },
      ],
      status: 'Active',
      department: 'IT',
      designation: 'Design Head',
      location: 'Mumbai',
      employmentType: 'Permanent',
      supervisor: 'Deshpandey',
      selectedPolicy: '',
    },
    {
      id: '3',
      employeeId: 3,
      firstName: 'Sharad',
      lastName: 'Deshpandey',
      lcPolicy: [
        { id: 0, name: 'Default Life Cycle Policy' },
        { id: 1, name: 'Life Cycle Policy 2022' },
        { id: 2, name: 'Updated Life Cycle Policy' },
        { id: 3, name: 'Description' },
      ],
      status: 'InActive',
      department: 'IT',
      designation: 'Design Head',
      location: 'Mumbai',
      employmentType: 'Permanent',
      supervisor: 'Deshpandey',
      selectedPolicy: '',
    },
    {
      id: '4',
      employeeId: 4,
      firstName: 'Sharad',
      lastName: 'Deshpandey',
      lcPolicy: [
        { id: 0, name: 'Default Life Cycle Policy' },
        { id: 1, name: 'Life Cycle Policy 2022' },
        { id: 2, name: 'Updated Life Cycle Policy' },
        { id: 3, name: 'Description' },
      ],
      status: 'InActive',
      department: 'IT',
      designation: 'Design Head',
      location: 'Mumbai',
      employmentType: 'Permanent',
      supervisor: 'Deshpandey',
      selectedPolicy: '',
    },
    {
      id: '1',
      employeeId: 0,
      firstName: 'Sharad',
      lastName: 'Deshpandey',
      lcPolicy: [
        { id: 0, name: 'Default Life Cycle Policy' },
        { id: 1, name: 'Life Cycle Policy 2022' },
        { id: 2, name: 'Updated Life Cycle Policy' },
        { id: 3, name: 'Description' },
      ],
      status: 'Active',
      department: 'IT',
      designation: 'Design Head',
      location: 'Mumbai',
      employmentType: 'Permanent',
      supervisor: 'Deshpandey',
      selectedPolicy: 'Amir ',
    },
    {
      id: '2',
      employeeId: 1,
      firstName: 'Sharad',
      lastName: 'Deshpandey',
      lcPolicy: [
        { id: 0, name: 'Default Life Cycle Policy' },
        { id: 1, name: 'Life Cycle Policy 2022' },
        { id: 2, name: 'Updated Life Cycle Policy' },
        { id: 3, name: 'Description' },
      ],
      status: 'Active',
      department: 'IT',
      designation: 'Design Head',
      location: 'Mumbai',
      employmentType: 'Permanent',
      supervisor: 'Deshpandey',
      selectedPolicy: '',
    },
    {
      id: '3',
      employeeId: 3,
      firstName: 'Sharad',
      lastName: 'Deshpandey',
      lcPolicy: [
        { id: 0, name: 'Default Life Cycle Policy' },
        { id: 1, name: 'Life Cycle Policy 2022' },
        { id: 2, name: 'Updated Life Cycle Policy' },
        { id: 3, name: 'Description' },
      ],
      status: 'InActive',
      department: 'IT',
      designation: 'Design Head',
      location: 'Mumbai',
      employmentType: 'Permanent',
      supervisor: 'Deshpandey',
      selectedPolicy: '',
    },
    {
      id: '4',
      employeeId: 4,
      firstName: 'Sharad',
      lastName: 'Deshpandey',
      lcPolicy: [
        { id: 0, name: 'Default Life Cycle Policy' },
        { id: 1, name: 'Life Cycle Policy 2022' },
        { id: 2, name: 'Updated Life Cycle Policy' },
        { id: 3, name: 'Description' },
      ],
      status: 'InActive',
      department: 'IT',
      designation: 'Design Head',
      location: 'Mumbai',
      employmentType: 'Permanent',
      supervisor: 'Deshpandey',
      selectedPolicy: '',
    },
  ],
  onTabChange: () => {},
  lcPolicies: [
    {
      id: 1,
      name: 'Policy Name',
      onClick: () => {},
    },
  ],
  loading: {},
  selectedItems: [],
  onSelect: () => {},
  onSelectAll: () => {},
  showRowSelector: false,
  showPagination: false,
  keyColumn: '',
  actionMenuLists: [
    {
      id: 1,
      label: 'Edit',
      onClick: () => {},
    },
  ],
};

LCPolicyListsTemplate.propTypes = {
  lcPoliciesGraphData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  policyListItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      heading: PropTypes.string,
      subHeading: PropTypes.string,
      employeeList: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          employeeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          employeeName: PropTypes.string,
          lcPolicy: PropTypes.string,
          department: PropTypes.string,
          designation: PropTypes.string,
          location: PropTypes.string,
        }),
      ),
    }),
  ),
  handleSearch: PropTypes.func,
  handleSearchAdd: PropTypes.func,
  listFilterFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  listHandleFilter: PropTypes.func,
  policyName: PropTypes.string,
  allEmployees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      employeeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      employeeName: PropTypes.string,
      lcPolicy: PropTypes.string,
      department: PropTypes.string,
      designation: PropTypes.string,
      location: PropTypes.string,
      selected: true,
    }),
  ),
  empColumns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),
  empTotalRecordCount: PropTypes.number,
  empLoadNextPageData: PropTypes.func,
  empHandleSort: PropTypes.func,
  empHandleRowClick: PropTypes.func,
  empPage: PropTypes.number,
  empSortBy: PropTypes.string,
  empSortDirection: PropTypes.oneOf(['asc', 'desc']),
  empHandleSelect: PropTypes.func,
  empOnSelectedChange: PropTypes.func,
  handleDetails: PropTypes.func,
  handleAdd: PropTypes.func,
  openDeleteLCPolicy: PropTypes.bool,
  handleClose: PropTypes.func,
  handleDelete: PropTypes.func,
  handleCancel: PropTypes.func,
  handleDeleteEmployee: PropTypes.func,
  handleCancelEmployee: PropTypes.func,
  openEmployee: PropTypes.bool,
  handleCloseEmployee: PropTypes.func,
  numOfEmployee: PropTypes.number,
  handleRemoveEmployee: PropTypes.func,

  bulkUpdateFilterFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  bulkUpdateHandleFilter: PropTypes.func,
  bulkUpdateRows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      employeeId: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      lcPolicy: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        }),
      ),
      status: PropTypes.string,
      department: PropTypes.string,
      designation: PropTypes.string,
      location: PropTypes.string,
      employmentType: PropTypes.string,
      supervisor: PropTypes.string,
      selectedPolicy: PropTypes.string,
    }),
  ),
  bulkUpdateTotalRecordCount: PropTypes.number,
  bulkUpdateLoadNextPageData: PropTypes.func,
  bulkUpdateHandleSort: PropTypes.func,
  bulkUpdateHandleRowClick: PropTypes.func,
  bulkUpdatePage: PropTypes.number,
  bulkUpdateSortBy: PropTypes.string,
  bulkUpdateSortDirection: PropTypes.oneOf(['asc', 'desc']),
  bulkUpdateHandleSelect: PropTypes.func,
  bulkUpdateOnSelectedChange: PropTypes.func,
  changePolicyHandleClick: PropTypes.func,
  bulkUpdateHandleCancel: PropTypes.func,

  empMappingGraphData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  empMappedFilterFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  empMappedHandleFilter: PropTypes.func,
  empMappedRows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      employeeId: PropTypes.number,
      employeeName: PropTypes.string,
      payStructure: PropTypes.node,
      status: PropTypes.string,
      gender: PropTypes.string,
      department: PropTypes.string,
      designation: PropTypes.string,
      location: PropTypes.string,
      employmentType: PropTypes.string,
    }),
  ),
  empMappedTotalRecordCount: PropTypes.number,
  empMappedLoadNextPageData: PropTypes.func,
  empMappedHandleSort: PropTypes.func,
  empMappedHandleRowClick: PropTypes.func,
  empMappedPage: PropTypes.number,
  empMappedSortBy: PropTypes.string,
  empMappedSortDirection: PropTypes.string,
  empMappedHandleCancel: PropTypes.func,
  empMappedHandleApplyChanges: PropTypes.func,
  empUnMappedFilterFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  empUnMappedHandleFilter: PropTypes.func,
  empUnMappedRows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      employeeId: PropTypes.number,
      employeeName: PropTypes.string,
      lcPolicy: PropTypes.string,
      userName: PropTypes.string,
      status: PropTypes.string,
      gender: PropTypes.string,
      department: PropTypes.string,
      designation: PropTypes.string,
      location: PropTypes.string,
      employmentType: PropTypes.string,
    }),
  ),
  empUnMappedTotalRecordCount: PropTypes.number,
  empUnMappedLoadNextPageData: PropTypes.func,
  empUnMappedHandleSort: PropTypes.func,
  empUnMappedHandleRowClick: PropTypes.func,
  empUnMappedPage: PropTypes.number,
  empUnMappedSortBy: PropTypes.string,
  empUnMappedSortDirection: PropTypes.string,
  empUnMappedHandleCancel: PropTypes.func,
  empUnMappedHandleApplyChanges: PropTypes.func,
  fetchMorePolicyListItems: PropTypes.func,
  handlePolicyItemClick: PropTypes.func,
  bulkUpdateColumns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),
  onTabChange: PropTypes.func,
  lcPolicies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  loading: PropTypes.bool,
  selectedItems: PropTypes.arrayOf,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  showRowSelector: PropTypes.bool,
  showPagination: PropTypes.bool,
  keyColumn: PropTypes.string,
  actionMenuLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
};

export default LCPolicyListsTemplate;
