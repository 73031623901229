const getColumns = () => {
  const columns = {
    earningsColumns: [
      {
        fieldName: 'earnings',
        columnTitle: 'Earnings',
        columnWidth: '60%',
      },
      {
        fieldName: 'perPeriodAmount',
        columnTitle: 'Per Period',
        columnWidth: '20%',
      },
      {
        fieldName: 'annualAmount',
        columnTitle: 'Annual',
        columnWidth: '20%',
      },
    ],
    benefitsColumns: [
      {
        fieldName: 'benefits',
        columnTitle: 'Benefits',
        columnWidth: '60%',
      },
      {
        fieldName: 'perPeriodAmount',
        columnTitle: 'Per Period',
        columnWidth: '20%',
      },
      {
        fieldName: 'annualAmount',
        columnTitle: 'Annual',
        columnWidth: '20%',
      },
    ],
    reimbursementsColumns: [
      {
        fieldName: 'reimbursements',
        columnTitle: 'Reimbursements',
        columnWidth: '60%',
      },
      {
        fieldName: 'perPeriodAmount',
        columnTitle: 'Per Period',
        columnWidth: '20%',
      },
      {
        fieldName: 'annualAmount',
        columnTitle: 'Annual',
        columnWidth: '20%',
      },
    ],
    variablePayColumns: [
      {
        fieldName: 'variablePay',
        columnTitle: 'Variable Pay - Witholdings',
        columnWidth: '60%',
      },
      {
        fieldName: 'perPeriodAmount',
        columnTitle: 'Per Period',
        columnWidth: '20%',
      },
      {
        fieldName: 'annualAmount',
        columnTitle: 'Annual',
        columnWidth: '20%',
      },
    ],
    statutoryDeductionsColumns: [
      {
        fieldName: 'statutoryDeductions',
        columnTitle: 'Statutory Deductions from Employees (if applicable)',
        columnWidth: '60%',
      },
      {
        fieldName: 'perPeriodAmount',
        columnTitle: 'Per Period',
        columnWidth: '20%',
      },
      {
        fieldName: 'annualAmount',
        columnTitle: 'Annual',
        columnWidth: '20%',
      },
    ],
    employerRetiralColumns: [
      {
        fieldName: 'employerRetiral',
        columnTitle: "Employer's Retiral Contributions (if applicable)",
        columnWidth: '60%',
      },
      {
        fieldName: 'perPeriodAmount',
        columnTitle: 'Per Period',
        columnWidth: '20%',
      },
      {
        fieldName: 'annualAmount',
        columnTitle: 'Annual',
        columnWidth: '20%',
      },
    ],
    fixedComponentsColumns: [
      {
        fieldName: 'fixedComponents',
        columnTitle: 'Fixed Components',
        columnWidth: '60%',
      },
      {
        fieldName: 'perPeriodAmount',
        columnTitle: 'Per Period',
        columnWidth: '20%',
      },
      {
        fieldName: 'annualAmount',
        columnTitle: 'Annual',
        columnWidth: '20%',
      },
    ],
    netSalaryColumns: [
      {
        fieldName: 'netSalary',
        columnTitle: 'Net Salary',
        columnWidth: '60%',
      },
      {
        fieldName: 'perPeriodAmount',
        columnTitle: 'Per Period',
        columnWidth: '20%',
      },
      {
        fieldName: 'annualAmount',
        columnTitle: 'Annual',
        columnWidth: '20%',
      },
    ],
    netVariablePayColumns: [
      {
        fieldName: 'netVariablePay',
        columnTitle: 'Net Variable Pay',
        columnWidth: '60%',
      },
      {
        fieldName: 'perPeriodAmount',
        columnTitle: 'Per Period',
        columnWidth: '20%',
      },
      {
        fieldName: 'annualAmount',
        columnTitle: 'Annual',
        columnWidth: '20%',
      },
    ],
    retiralColumns: [
      {
        fieldName: 'retiral',
        columnTitle: 'Retiral',
        columnWidth: '60%',
      },
      {
        fieldName: 'perPeriodAmount',
        columnTitle: 'Per Period',
        columnWidth: '20%',
      },
      {
        fieldName: 'annualAmount',
        columnTitle: 'Annual',
        columnWidth: '20%',
      },
    ],
    totalColumns: [
      {
        fieldName: 'total',
        columnTitle: 'Total',
        columnWidth: '60%',
      },
      {
        fieldName: 'perPeriodAmount',
        columnTitle: 'Per Period',
        columnWidth: '20%',
      },
      {
        fieldName: 'annualAmount',
        columnTitle: 'Annual',
        columnWidth: '20%',
      },
    ],
  };
  return columns;
};

export { getColumns };
