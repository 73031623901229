import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import TableExtended from 'components/Molecules/TableExtended';
import Typographys from 'components/Atoms/Typography';
import { SalarySlipStyle } from '../../../style';

const ArrearsDetails = ({
  loading,
  arrearDataColumn,
  arrearDataRow,
  sortDirection,
  sortBy,
  onSortChange,
  count,
  page,
  onPageChange,
}) => {
  return (
    <Box sx={SalarySlipStyle.arrearTableWrapper}>
      <Typographys variant="h6" color="neutral.800">
        Arrears Computation Details:
      </Typographys>

      <TableExtended
        loading={loading}
        columns={arrearDataColumn}
        rows={arrearDataRow}
        showCheckbox={false}
        sortDirection={sortDirection}
        sortBy={sortBy}
        onSortChange={onSortChange}
        count={count}
        page={page}
        onPageChange={onPageChange}
        showActionList={false}
      />
    </Box>
  );
};

ArrearsDetails.defaultProps = {
  loading: false,
  arrearDataColumn: [],
  arrearDataRow: [],
  sortDirection: 'asc',
  sortBy: () => {},
  onSortChange: () => {},
  count: 10,
  page: null,
  onPageChange: () => {},
};

ArrearsDetails.propTypes = {
  loading: PropTypes.bool,
  arrearDataRow: PropTypes.arrayOf,
  arrearDataColumn: PropTypes.arrayOf,
  sortBy: PropTypes.func,
  sortDirection: PropTypes.string,
  count: PropTypes.number,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  onSortChange: PropTypes.func,
};

export default ArrearsDetails;
