import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Box, Divider, Grid, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import Typographys from 'components/Atoms/Typography';
import DatePicker from 'components/Atoms/Datepicker';
import Attachments from 'components/Atoms/Attachments';
import Button from 'components/Atoms/Button';
import CodeEditor from 'components/Molecules/CodeEditor';
import dayjs from 'dayjs';
import { empRecordActionDetailStyle } from '../style';

const EffectiveDateForm = ({ heading, label, btnLabel, handleSubmit, handleCancel, loading, isAttachment }) => {
  const theme = useTheme();

  const EffectiveDateSchema = yup.object().shape({
    startDate: yup.mixed().required('Date is required'),
    notes: yup.string(),
  });

  const formik = useFormik({
    initialValues: { startDate: '', notes: '' },
    onSubmit: async (values, helpers) => {
      try {
        handleSubmit(values);
        // helpers.resetForm();
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
    validationSchema: EffectiveDateSchema,
  });

  return (
    <>
      <Typographys variant="body2">{heading}</Typographys>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <Box sx={empRecordActionDetailStyle.formWrapper}>
          <Grid container rowGap={2}>
            <Grid item sm={4}>
              <DatePicker
                error={Boolean(formik.touched.startDate && formik.errors.startDate)}
                label={`${label} Effective Date*`}
                name="startDate"
                id="startDate"
                value={formik?.values?.startDate ? dayjs(formik?.values?.startDate) : ''}
                onBlur={formik.handleBlur}
                onChange={(value) => {
                  formik.setFieldValue('startDate', Date.parse(value));
                }}
                fullWidth
              />
            </Grid>

            <Grid item sm={12}>
              <CodeEditor
                error={Boolean(formik.touched.notes && formik.errors.notes)}
                helperText={formik.touched.notes && formik.errors.notes}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.notes}
                name="notes"
                id="notes"
                label={`${label} notes`}
              />
            </Grid>
            {isAttachment && (
              <Grid item sm={12}>
                <Attachments
                  isDeleteIcon
                  handleChange={(event) => {
                    formik.setFieldValue(`attachments`, event.target.files[0]);
                  }}
                  name="attachments"
                  id="attachments"
                  value={formik.values.attachments}
                  handleDelete={() => {
                    formik.setFieldValue(`attachments`, '');
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Box>
        <Box sx={empRecordActionDetailStyle.btnContainer}>
          <Divider sx={{ my: 2, borderColor: theme.palette.other[200] }} />
          <Box sx={empRecordActionDetailStyle.submitWrapper}>
            <Button
              size="large"
              color="info"
              variant="outlined"
              type="reset"
              onClick={() => {
                formik.resetForm();
                handleCancel();
              }}
            >
              Cancel
            </Button>
            <LoadingButton loading={loading} color="primary" size="large" type="submit" variant="contained">
              {btnLabel}
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

EffectiveDateForm.defaultProps = {
  heading: '',
  label: '',
  btnLabel: '',
  handleSubmit: () => {},
  handleCancel: () => {},
  loading: false,
  isAttachment: false,
};

EffectiveDateForm.propTypes = {
  heading: PropTypes.string,
  label: PropTypes.string,
  btnLabel: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  loading: PropTypes.bool,
  isAttachment: PropTypes.bool,
};

export default EffectiveDateForm;
