import PropTypes from 'prop-types';
import React from 'react';
import { Grid, CircularProgress } from '@mui/material';
import Typography from 'components/Atoms/Typography';
import { useTheme } from '@mui/material/styles';

const ViewPayrollCalendarDetails = ({ payrollCalendar }) => {
  const theme = useTheme();
  if (!payrollCalendar) {
    return <CircularProgress />;
  }

  const {
    name,
    status,
    payRunPeriodicity,
    assessmentYear,
    calendarEndDate,
    calendarStartDate,
    financialYear,
    applicableEmployeeTypes,
    firstProcessedPayPeriodStartDate,
    lockStatus,
  } = payrollCalendar;

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item container xs={8} spacing={2}>
        <Grid item xs={6}>
          <Typography variant="body2" sx={{ color: `${theme.palette.neutral[600]}!important` }}>
            Name
          </Typography>
          {name}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" sx={{ color: `${theme.palette.neutral[600]}!important` }}>
            Applicable Employment Types
          </Typography>
          -
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" sx={{ color: `${theme.palette.neutral[600]}!important` }}>
            Status
          </Typography>
          {status}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" sx={{ color: `${theme.palette.neutral[600]}!important` }}>
            Calendar Start Date
          </Typography>
          {calendarStartDate}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" sx={{ color: `${theme.palette.neutral[600]}!important` }}>
            Calendar End Date
          </Typography>
          {calendarEndDate}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" sx={{ color: `${theme.palette.neutral[600]}!important` }}>
            Financial Year
          </Typography>
          {financialYear}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" sx={{ color: `${theme.palette.neutral[600]}!important` }}>
            Assessment Year
          </Typography>
          {assessmentYear}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" sx={{ color: `${theme.palette.neutral[600]}!important` }}>
            Pay Run Periodicity
          </Typography>
          {payRunPeriodicity}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" sx={{ color: `${theme.palette.neutral[600]}!important` }}>
            Lock Status
          </Typography>
          {lockStatus}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" sx={{ color: `${theme.palette.neutral[600]}!important` }}>
            First Processed Pay Period Start Date
          </Typography>
          {firstProcessedPayPeriodStartDate}
        </Grid>
      </Grid>
    </Grid>
  );
};

ViewPayrollCalendarDetails.defaultProps = {
  payrollCalendar: {},
};

ViewPayrollCalendarDetails.propTypes = {
  payrollCalendar: PropTypes.instanceOf(Object),
};

export default ViewPayrollCalendarDetails;
