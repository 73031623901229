import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid } from '@mui/material';
import TitleWithText from 'components/Atoms/TitleWithText';
import Typographys from 'components/Atoms/Typography';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { onBoardingStyle } from '../style';

const Dependents = ({ dependentsDetails }) => {
  return (
    <Box px={3}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 38rem)' }}>
          <Typographys sx={{ mb: 1.5 }} variant="h5" color="neutral.700">
            Dependent Records
          </Typographys>

          {dependentsDetails.map((list, i) => (
            <Box>
              <Typographys variant="subtitle2" align="left" color="neutral.800">
                Record {i + 1}
              </Typographys>

              <Grid container rowSpacing={1} columnSpacing={2} sx={onBoardingStyle.formContainer}>
                <Grid item xs={12} sm={4} md={3}>
                  <TitleWithText title="First Name" text={list.firstName} />
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                  <TitleWithText title="Last Name" text={list.lastName} />
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                  <TitleWithText title="Gender" text={list.gender} />
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                  <TitleWithText title="Date of Birth" text={list.dob} />
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                  <TitleWithText title="Relation" text={list.relation} />
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                  <TitleWithText title="PAN Details" text={list.panDetails} />
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                  <TitleWithText title="Address" text={list.address} />
                </Grid>
              </Grid>

              <Box mt={3}>
                <Typographys variant="h5" color="neutral.700">
                  Contact Details
                </Typographys>
              </Box>

              <Grid container rowSpacing={1} columnSpacing={2} sx={onBoardingStyle.formContainer}>
                <Grid item xs={12} sm={4} md={3}>
                  <TitleWithText title="Mobile Number" text={list.mobileNumber} />
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                  <TitleWithText title="Email Address" text={list.emailAddress} />
                </Grid>
              </Grid>

              <Box mt={3}>
                <Typographys variant="h5" color="neutral.700">
                  Nomination Details
                </Typographys>
              </Box>

              <Grid container rowSpacing={1} columnSpacing={2} sx={onBoardingStyle.formContainer}>
                <Grid item xs={12} sm={4} md={3}>
                  <TitleWithText title="EPF Nomination %" text={list.epfPerc} />
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                  <TitleWithText title="EPS Nomination %" text={list.epsPerc} />
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                  <TitleWithText title="Insurance Nomination %" text={list.insurancePerc} />
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                  <TitleWithText title="Others Nomination %" text={list.otherPerc} />
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                  <TitleWithText title="Gratuity  Nomination %" text={list.gratuityPerc} />
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                  <TitleWithText title="Emergency Contact Reference" text={list.emergencyContact} />
                </Grid>
              </Grid>
              {i + 1 !== dependentsDetails.length && <Divider sx={onBoardingStyle.divider} />}
            </Box>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );
};

Dependents.defaultProps = {
  dependentsDetails: [],
};

Dependents.propTypes = {
  dependentsDetails: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      gender: PropTypes.string,
      relation: PropTypes.string,
      dob: PropTypes.string,
      mobileNumber: PropTypes.string,
      emailAddress: PropTypes.string,
      epfPerc: PropTypes.number,
      epsPerc: PropTypes.number,
      insurancePerc: PropTypes.number,
      otherPerc: PropTypes.number,
      gratuityPerc: PropTypes.number,
      emergencyContact: PropTypes.string,
    }),
  ),
};

export default Dependents;
