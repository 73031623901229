import React from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import LeaveRequests from 'components/Organisms/Leave/MyLeaves/MyLeaveRequests/LeaveRequests';

const LeaveRequestsTemplate = ({
  rows,
  columns,
  roleBasedMenu,
  page,
  sortDirection,
  sortBy,
  handleSelect,
  handleAddRequest,
  leaveBalance,
  chartData,
  actions,
  showFilter,
  handleFilter,
  onSortChange,
  showActionList,
  pageHeading,
  handleRowClick,
  handleFiltersClear,
  filterFields,
  loading,
  showPagination,
  loadNextPageData,
  userHasCreateLeavePermission,
  count,
}) => {
  return (
    <BlankTemplate pageHeading={pageHeading} isBreadcrumb={false} padding="2rem 3rem">
      <LeaveRequests
        loading={loading}
        rows={rows}
        columns={columns}
        roleBasedMenu={roleBasedMenu}
        page={page}
        handleFiltersClear={handleFiltersClear}
        sortDirection={sortDirection}
        handleRowClick={handleRowClick}
        sortBy={sortBy}
        handleSelect={handleSelect}
        handleAddRequest={handleAddRequest}
        leaveBalance={leaveBalance}
        chartData={chartData}
        actions={actions}
        showFilter={showFilter}
        handleFilter={handleFilter}
        onSortChange={onSortChange}
        filterFields={filterFields}
        showActionList={showActionList}
        showPagination={showPagination}
        onPageChange={loadNextPageData}
        userHasCreateLeavePermission={userHasCreateLeavePermission}
        count={count}
      />
    </BlankTemplate>
  );
};

LeaveRequestsTemplate.defaultProps = {
  pageHeading: '',
  rows: [],
  columns: [],
  handleAddRequest: () => {},
  handleSelect: () => {},
  sortBy: () => {},
  page: 1,
  sortDirection: 'asc',
  roleBasedMenu: () => {},
  leaveBalance: [],
  chartData: [],
  actions: true,
  showFilter: false,
  handleFilter: () => {},
  onSortChange: () => {},
  showActionList: true,
  handleRowClick: () => {},
  loading: false,
  showPagination: true,
  loadNextPageData: () => {},
  count: 0,
};

LeaveRequestsTemplate.propTypes = {
  handleRowClick: PropTypes.func,
  handleAddRequest: PropTypes.func,
  handleSelect: PropTypes.func,
  sortBy: PropTypes.func,
  page: PropTypes.number,
  sortDirection: PropTypes.string,
  roleBasedMenu: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),

  leaveBalance: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      totalCount: PropTypes.string,
      available: PropTypes.string,
    }),
  ),

  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  actions: PropTypes.bool,
  showFilter: PropTypes.bool,
  handleFilter: PropTypes.func,
  onSortChange: PropTypes.func,
  showActionList: PropTypes.bool,
  pageHeading: PropTypes.string,

  columns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      numeric: PropTypes.bool,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      leaveCategory: PropTypes.string,
      leaveType: PropTypes.string,
      status: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
  showPagination: PropTypes.bool,
  count: PropTypes.number,
  loadNextPageData: PropTypes.func,
};

export default LeaveRequestsTemplate;
