import React from 'react';
import Table from 'components/Molecules/TableExtended';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Button from 'components/Atoms/Button';
import * as yup from 'yup';
import DropdownMenu from 'components/Molecules/DropdownMenu';
import NoRecordsMessage from 'components/Organisms/CommonPageUtils/NoRecordsFound';
import Dropdown from 'components/Molecules/Dropdown';

const SeparationList = ({
  loading,
  onSortChange,
  rows,
  page,
  sortDirection,
  sortBy,
  handleSelect,
  onSelect,
  onSelectAll,
  count,
  selectedItems,
  showPagination,
  onPageChange,
  handleSort,
  handleRowClick,
  keyColumn,
  showActionList,
  menuList,
  roleBasedMenu,
  showRowSelector,
  showActions,
  columns,
  actionOptions,
  handleSubmit,
  employeeOptions,
  showInitiateSeparation,
}) => {
  const formik = useFormik({
    initialValues: { employee: '' },
    onSubmit: (data) => handleSubmit(data),
  });

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }} component="form" onSubmit={formik.handleSubmit}>
        {showInitiateSeparation && (
          <Box sx={{ width: '30%' }}>
            <Dropdown
              error={Boolean(formik.touched.employee && formik.errors.employee)}
              helperText={formik.touched.employee && formik.errors.employee}
              handleChange={(e) => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.employee}
              name="employee"
              id="employee"
              label="Select Employee"
              options={employeeOptions}
            />
          </Box>
        )}

        <Box sx={{ display: 'flex', gap: 1 }}>
          {showActions && <DropdownMenu menuLists={menuList} size="medium" btnLabel="Actions" color="primary" />}
          {showInitiateSeparation && (
            <Button
              size="Medium"
              variant="contained"
              color="primary"
              type="submit"
              isDisable={formik.values.employee.length === 0}
            >
              Initiate Separation
            </Button>
          )}
        </Box>
      </Box>
      <Box mt={2}>
        {!loading && rows.length === 0 ? (
          <NoRecordsMessage width="20rem" height="20rem" />
        ) : (
          <Table
            loading={loading}
            rows={rows}
            columns={columns}
            count={count}
            selectedItems={selectedItems}
            onSelect={onSelect}
            onSelectAll={onSelectAll}
            showRowSelector={showRowSelector}
            showPagination={showPagination}
            onPageChange={onPageChange}
            handleSort={handleSort}
            handleRowClick={handleRowClick}
            page={page}
            keyColumn={keyColumn}
            roleBasedMenu={roleBasedMenu}
            handleSelect={handleSelect}
            showActionList={showActionList}
            sortBy={sortBy}
            sortDirection={sortDirection}
            onSortChange={onSortChange}
            actionOptions={actionOptions}
          />
        )}
      </Box>
    </Box>
  );
};

SeparationList.defaultProps = {
  loading: false,
  onSortChange: () => {},
  roleBasedMenu: () => {},
  rows: [],
  columns: [],
  handleSelect: () => {},
  onSelect: () => {},
  onSelectAll: () => {},
  sortBy: () => {},
  page: 1,
  sortDirection: 'asc',
  menuList: [
    { label: 'Bulk Delete', onClick: () => {} },
    { label: 'Bulk Lock Details', onClick: () => {} },
    { label: 'Bulk Accept', onClick: () => {} },
  ],
  handleSubmit: () => {},
  showRowSelector: true,
  showActions: true,
  showInitiateSeparation: true,
  actionOptions: [],
  count: 10,
  selectedItems: [],
  showPagination: false,
  onPageChange: (page) => {},
  handleSort: () => {},
  handleRowClick: () => {},
  keyColumn: '',
  showActionList: false,
  employeeOptions: [{ id: 302980, name: 'Harry' }],
};

SeparationList.propTypes = {
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  onSortChange: PropTypes.func,
  roleBasedMenu: PropTypes.func,
  handleSelect: PropTypes.func,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  sortBy: PropTypes.func,
  page: PropTypes.number,
  sortDirection: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      employeeId: PropTypes.string,
      employee: PropTypes.string,
      resignationStatus: PropTypes.string,
      relievingDate: PropTypes.string,
      submissionDate: PropTypes.string,
      acceptanceDate: PropTypes.string,
    }),
  ),

  columns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
      numeric: PropTypes.bool,
      formatValue: PropTypes.func,
    }),
  ),

  menuList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  showRowSelector: PropTypes.bool,
  showActions: PropTypes.bool,
  showInitiateSeparation: PropTypes.bool,
  actionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  count: PropTypes.number,
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      employeeId: PropTypes.string,
      employee: PropTypes.string,
      resignationStatus: PropTypes.string,
      relievingDate: PropTypes.string,
      submissionDate: PropTypes.string,
      acceptanceDate: PropTypes.string,
    }),
  ),

  showActionList: PropTypes.bool,
  handleSort: PropTypes.func,
  handleRowClick: PropTypes.func,
  showPagination: PropTypes.bool,
  onPageChange: PropTypes.func,
  keyColumn: PropTypes.string,
  employeeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
};
export default SeparationList;
