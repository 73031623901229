import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import TitleWithText from 'components/Atoms/TitleWithText';
import Typographys from 'components/Atoms/Typography';
import Button from 'components/Atoms/Button';
import EditIcon from '@mui/icons-material/Edit';
import PerfectScrollbar from 'react-perfect-scrollbar';
import NoRecordsMessage from 'components/Organisms/CommonPageUtils/NoRecordsFound';
import { validateDateElseHyphen } from 'utils/commonUtils';
import { hasPermission } from 'utils/permissions';
import { useSelector } from 'react-redux';
import { profileStyle } from '../style';

const ProfileDependents = ({ details, handleEdit }) => {
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasEditPermission = hasPermission(authorities, ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_DEPENDENTS']);

  return (
    <>
      <Box sx={profileStyle.actionBtn}>
        {!!userHasEditPermission && (
          <Button startIcon={<EditIcon fontSize="2.5rem" />} onClick={handleEdit}>
            {details.length > 0 ? 'Edit' : 'Add'}
          </Button>
        )}
      </Box>

      {details.length > 0 ? (
        <PerfectScrollbar>
          <Box sx={{ maxHeight: 'calc(100vh - 38rem)' }}>
            <Box mt={4}>
              {details &&
                details?.map((list, index) => (
                  <Box sx={profileStyle.formDivider}>
                    <Typographys variant="h5" color="neutral.700">
                      Dependent {index + 1}
                    </Typographys>

                    <Grid container rowSpacing={1} columnSpacing={2} sx={profileStyle.formContainer}>
                      <Grid item xs={12} sm={4} md={3}>
                        <TitleWithText title="First Name" text={list?.contactInfo?.firstName || '-'} />
                      </Grid>

                      <Grid item xs={12} sm={4} md={3}>
                        <TitleWithText title="Last Name" text={list?.contactInfo?.lastName || '-'} />
                      </Grid>

                      <Grid item xs={12} sm={4} md={3}>
                        <TitleWithText title="Gender" text={list?.contactInfo?.gender || '-'} />
                      </Grid>

                      <Grid item xs={12} sm={4} md={3}>
                        <TitleWithText title="Relation" text={list?.relationship || '-'} />
                      </Grid>

                      <Grid item xs={12} sm={4} md={3}>
                        <TitleWithText
                          title="Date of Birth"
                          text={validateDateElseHyphen(list?.dateOfBirth, 'DD MMMM YYYY')}
                        />
                      </Grid>

                      <Grid item xs={12} sm={4} md={3}>
                        <TitleWithText title="PAN Number" text={list?.panNumberIndia || '-'} />
                      </Grid>
                      <Grid item xs={12} sm={4} md={3}>
                        <TitleWithText title="Address" text={list?.address || '-'} />
                      </Grid>
                    </Grid>

                    <Box mt={3}>
                      <Typographys variant="h5" color="neutral.700">
                        Contact Details
                      </Typographys>
                    </Box>

                    <Grid container rowSpacing={1} columnSpacing={2} mb={0}>
                      <Grid item xs={12} sm={4} md={3}>
                        <TitleWithText title="Mobile Number" text={list?.contactInfo?.mobileNumber || '-'} />
                      </Grid>
                      <Grid item xs={12} sm={4} md={3}>
                        <Box mb={0.5}>
                          <TitleWithText title="Telephone Number" text={list?.contactInfo?.telephoneNumber || '-'} />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={4} md={3}>
                        <Box mb={0.5}>
                          <TitleWithText title="Email Address" text={list?.contactInfo?.emailAddress || '-'} />
                        </Box>
                      </Grid>
                    </Grid>
                    <Box mt={3}>
                      <Typographys variant="h5" color="neutral.700">
                        Nomination Details
                      </Typographys>
                    </Box>

                    <Grid container rowSpacing={1} columnSpacing={2} sx={profileStyle.formContainer}>
                      <Grid item xs={12} sm={4} md={3}>
                        <TitleWithText title="EPF Nomination %" text={list?.epfNominationPercentage || '-'} />
                      </Grid>

                      <Grid item xs={12} sm={4} md={3}>
                        <TitleWithText title="EPS Nomination %" text={list?.epsNominationPercentage || '-'} />
                      </Grid>

                      <Grid item xs={12} sm={4} md={3}>
                        <TitleWithText
                          title="Insurance Nomination %"
                          text={list?.insuranceNominationPercentage || '-'}
                        />
                      </Grid>

                      <Grid item xs={12} sm={4} md={3}>
                        <TitleWithText title="Others Nomination %" text={list?.othersNominationPercentage || '-'} />
                      </Grid>

                      <Grid item xs={12} sm={4} md={3}>
                        <TitleWithText
                          title="Gratuity  Nomination %"
                          text={list?.gratuityNominationPercentage || '-'}
                        />
                      </Grid>

                      <Grid item xs={12} sm={4} md={3}>
                        <TitleWithText
                          title="Emergency Contact Reference"
                          text={list?.emergencyContactReference || '-'}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                ))}
            </Box>
          </Box>
        </PerfectScrollbar>
      ) : (
        <NoRecordsMessage width="20rem" height="20rem" />
      )}
    </>
  );
};

ProfileDependents.defaultProps = {
  details: [],
  handleEdit: () => {},
};

ProfileDependents.propTypes = {
  details: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      gender: PropTypes.string,
      relation: PropTypes.string,
      dob: PropTypes.string,
    }),
  ),
  handleEdit: PropTypes.func,
};

export default ProfileDependents;
