import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { API_URL } from 'api/api';
import LogoDark from '../../../assets/images/Logo.svg';
import LogoLight from '../../../assets/images/Logo-white.svg';
import logoIcon from '../../../assets/images/Logoicon.svg';

const Image = styled('img')({
  display: 'block',
  maxWidth: '100%',
});
const Logo = ({ altText, width, height, isDark, onClick }) => {
  const [isDefaultImage, setIsDefaultImage] = useState(false);
  const { user = {} } = useSelector((state) => state?.session);
  const companyLogo = `${API_URL}/assets/Documents/${user?.companyUID}/logos/logo-100.png`;

  const addDefaultIconSrc = (e) => {
    e.target.src = logoIcon;
  };

  const addDefaultSrc = (e) => {
    setIsDefaultImage(true);
    if (isDark) {
      e.target.src = LogoDark;
    } else {
      e.target.src = LogoLight;
    }
  };

  const theme = useTheme();
  const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box>
      {matchDownSm || matchDownMd ? (
        <Image onError={addDefaultIconSrc} alt={altText} src={companyLogo} onClick={onClick} sx={{ width: '7rem' }} />
      ) : (
        <Image
          onError={addDefaultSrc}
          alt={altText}
          src={companyLogo}
          sx={{ width: { width }, height: { height }, padding: `${isDefaultImage ? 'none' : '2rem'}` }}
          onClick={onClick}
        />
      )}
    </Box>
  );
};
Logo.defaultProps = {
  width: '20rem',
  height: '5.75rem',
  altText: 'Logo',
  isDark: true,
  onClick: () => {},
};
Logo.propTypes = {
  altText: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isDark: PropTypes.bool,
  onClick: PropTypes.func,
};
export default Logo;
