export const headerStyle = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '8rem',
    borderBottom: 1,
    borderColor: 'neutral.300',
    '@media (max-width: 900px)': {
      p: '1.75rem 2.5rem',
      height: 'auto',
    },
  },
  headerRightSide: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0 3rem 0 4rem',

    '@media (max-width: 900px)': {
      justifyContent: 'flex-end',
      padding: '0',
    },
  },

  searchWrap: {
    '@media (max-width: 900px)': {
      order: '2',
    },
    '@media (min-width: 900px)': {
      width: '100%',
    },
  },

  notificationButton: {
    p: '0',
    ml: '2.5rem',
    color: 'neutral.600',
    '@media (max-width: 900px)': {
      ml: '0',
      order: '1',
    },
  },
};
