/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Link, Skeleton, Typography } from '@mui/material';
import { useThunk } from 'hooks/useThunk';
import { fetchMyExitWithdrawDetails } from 'store';

const WithdrawSeparation = () => {
  const navigate = useNavigate();

  const [displayMyWithdrawDetails, isLoadingDisplayMyWithdrawDetails, loadingErrorDisplayMyWithdrawDetails] =
    useThunk(fetchMyExitWithdrawDetails);

  const { myExitWithdrawDetails } = useSelector((state) => {
    return state.profile;
  });

  useEffect(() => {
    const query = new URLSearchParams({});
    displayMyWithdrawDetails(query);
  }, [displayMyWithdrawDetails]);

  if (isLoadingDisplayMyWithdrawDetails) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (loadingErrorDisplayMyWithdrawDetails) {
    return (
      <Typography variant="h6">{myExitWithdrawDetails?.message || 'Error while loading current details.'}</Typography>
    );
  }

  if (myExitWithdrawDetails?.status === 'WARNING' || myExitWithdrawDetails?.status !== 'SUCCESS') {
    return <h3>{myExitWithdrawDetails.message}</h3>;
  }

  const handleClick = () => {
    navigate('/home/separation/initiate-separation');
  };

  return (
    <>
      <h4 style={{ margin: '0 0 16px 0' }}>Separation withdrawn successfully.</h4>
      Click&nbsp;
      <Link tabIndex={0} component="button" onClick={handleClick}>
        here
      </Link>
      &nbsp;to initiate a separation.
    </>
  );
};

export default WithdrawSeparation;
