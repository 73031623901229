export const candidateDashboardStyle = {
  card: {
    border: '0.125rem solid',
    borderColor: 'neutral.300',
    borderRadius: '0.625rem',
    backgroundColor: 'common.white',
  },
  contentWrapper: { marginTop: '6rem', marginBottom: '3rem' },
  contentImg: { maxWidth: '25%', marginBottom: '6rem' },
  contentTxt: { fontWeight: '400', fontSize: '2rem', color: '#7C85A2', marginBottom: '1rem' },
};
