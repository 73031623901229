export const earnings = [
  {
    title: '1) Basic',
    perPeriod: '₹10000',
    annual: '₹10000',
  },
  {
    title: '2) Bonus',
    perPeriod: '₹10000',
    annual: '₹10000',
  },
  {
    title: '3) Conveyance Allowance',
    perPeriod: '₹10000',
    annual: '₹10000',
  },
  {
    title: '4) Dearness Allowance',
    perPeriod: '₹10000',
    annual: '₹10000',
  },
  {
    title: '5) Flat Allowance',
    perPeriod: '₹10000',
    annual: '₹10000',
  },
  {
    title: '6) Flexible Allowance',
    perPeriod: '₹10000',
    annual: '₹10000',
  },
  {
    title: '7) House Rent Allowance (0.5*(Basic+DearnessAllowance))',
    perPeriod: '₹10000',
    annual: '₹10000',
  },
  {
    title: '8) Other Allowance',
    perPeriod: '₹10000',
    annual: '₹10000',
  },
  {
    title: '9) Special Allowance',
    perPeriod: '₹10000',
    annual: '₹10000',
  },
];

export const benefits = [
  {
    title: "10) Children's Education Allowance",
    perPeriod: '₹10000',
    annual: '₹10000',
  },
  {
    title: "11) Children's Hostel Allowance",
    perPeriod: '₹10000',
    annual: '₹10000',
  },
  {
    title: '12) Lunch Allowance',
    perPeriod: '₹10000',
    annual: '₹10000',
  },
];

export const reimbursements = [
  {
    title: '13) Leave Travel Allowance',
    perPeriod: '₹10000',
    annual: '₹10000',
  },
  {
    title: '14) Medical Allowance',
    perPeriod: '₹10000',
    annual: '₹10000',
  },
  {
    title: '15) Telephone/Internet Allowance',
    perPeriod: '₹10000',
    annual: '₹10000',
  },
];

export const variables = [
  {
    title: '16) Bonus - Witholding (Bonus)',
    perPeriod: '₹10000',
    annual: '₹10000',
  },
];

export const deductions = [
  {
    title: '17) NPS Deduction',
    perPeriod: '₹10000',
    annual: '₹10000',
  },
];

export const otherDeductions = [
  {
    title: '18) Statutory Deductions from Employees (if applicable)',
    perPeriod: '₹10000',
    annual: '₹10000',
  },
  {
    title: '19) Gratuity Contributions from Employer (if applicable)',
    perPeriod: '₹10000',
    annual: '₹10000',
  },
];

export const gratuity = [
  {
    title: `20) Employer's Retiral Contributions (if applicable)`,
    perPeriod: '₹10000',
    annual: '₹10000',
  },
];

export const fixedComponents = [
  {
    title: 'Gross Earnings (Earnings + Benefits + Reimbursements)',
    perPeriod: '₹10000',
    annual: '₹10000',
  },
  {
    title: `Gross Deductions (Statutory Deductions from Employee)
    (exclusive of TDS/Income Tax **)`,
    perPeriod: '₹10000',
    annual: '₹10000',
  },
];

export const netSalary = [
  {
    title: `Net Salary (Gross Earnings - Gross Deductions) 
    (exclusive of TDS/Income Tax **)`,
    perPeriod: '₹10000',
    annual: '₹10000',
  },
];

export const netVariable = [
  {
    title: `Net Variable Pay (includes all witholdings **)`,
    perPeriod: '₹10000',
    annual: '₹10000',
  },
];

export const retiral = [
  {
    title: `Employer Retiral and Admin Contributions 
    (Gratuity + PF + ESI + Admin charges) (** where applicable)`,
    perPeriod: '₹10000',
    annual: '₹10000',
  },
];

export const total = [
  {
    title: `Cost To Company (Gross Earnings + Variable Pay + Retiral & Admin 
      Contributions + Employee LWF Contributions ** (in case configured 
      not to be deducted from employee)) (** where applicable)`,
    perPeriod: '₹10000',
    annual: '₹10000',
  },
];
