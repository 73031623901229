import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from 'components/Atoms/Button';
import Typography from 'components/Atoms/Typography';
import { Divider, Grid } from '@mui/material';
import Dropdown from 'components/Molecules/Dropdown';
import InputField from 'components/Atoms/InputField/index';
import Dialog from 'components/Molecules/Dialog';
import DatePicker from 'components/Atoms/Datepicker';

import AddIcon from '@mui/icons-material/Add';
import { TableFilterStyle } from './style';

const RenderInput = ({
  name,
  handleDateChange,
  handleDropdownChange,
  handleInputChange,
  value,
  attributeOptions,
  type,
  disabled,
}) => {
  if (type === 'date') {
    return (
      <DatePicker
        onChange={handleDateChange}
        value={value}
        name="value"
        id="value"
        label="*"
        fullWidth
        disabled={!disabled}
      />
    );
  }
  if (type === 'dropdown') {
    return (
      <Dropdown
        id="attribute"
        label=""
        labelId="attribute"
        options={attributeOptions?.length && attributeOptions?.find((i) => i?.name === name)?.options}
        handleChange={handleDropdownChange}
        value={value}
        disabled={!disabled}
      />
    );
  }
  if (type === 'number') {
    return (
      <InputField
        onChange={handleInputChange}
        value={value}
        type="number"
        name="value"
        id="value"
        label=""
        autoComplete=""
        fullWidth
        disabled={!disabled}
      />
    );
  }

  return (
    <InputField
      onChange={handleInputChange}
      value={value}
      name="value"
      id="value"
      label=""
      autoComplete=""
      fullWidth
      disabled={!disabled}
    />
  );
};

const TableFilter = ({
  dialogTitle,
  primaryBtn,
  primaryBtnColor,
  secondaryBtn,
  handleSecondaryBtn,
  open,
  handleClose,
  attributeOptions,
  alignmentActionBtn,
  handleSubmit,
}) => {
  const filteredOptions =
    attributeOptions?.length && attributeOptions?.filter((option) => option?.value && option?.value !== '');
  const [selectedOptions, setSelectedOptions] = useState(
    filteredOptions?.length ? filteredOptions : [{ name: '', value: '', type: '', filterName: '' }],
  );
  const addedDropDownOptions = (option) =>
    attributeOptions?.length &&
    attributeOptions
      ?.filter((i) => !selectedOptions.find((item) => item?.name === i?.name) || i?.name === option?.name)
      ?.map((o) => ({ ...o, id: o?.name }));

  const handleAddedDropdownChange = (e, index) => {
    const options = selectedOptions.map((a) => {
      return { ...a };
    });
    options[index] = {
      ...options[index],
      name: e?.target?.value,
      value: '',
      type: attributeOptions?.length && attributeOptions?.find((i) => i?.name === e?.target?.value)?.type,
      filterName: attributeOptions?.length && attributeOptions?.find((i) => i?.name === e?.target?.value)?.filterName,
    };
    setSelectedOptions(options);
  };

  const handleRemove = (index) => {
    if (selectedOptions?.length === 1) {
      setSelectedOptions([{ name: '', value: '', type: '', filterName: '' }]);
      return;
    }
    const options = selectedOptions.map((a) => {
      return { ...a };
    });
    options.splice(index, 1);
    setSelectedOptions(options);
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      dialogTitle={dialogTitle}
      dialogDesc={false}
      primaryBtn={primaryBtn}
      secondaryBtn={secondaryBtn}
      handleSecondaryBtn={() => {
        setSelectedOptions([{ name: '', value: '', type: '', filterName: '' }]);
        handleSecondaryBtn();
      }}
      primaryBtnColor={primaryBtnColor}
      alignmentActionBtn={alignmentActionBtn}
      handlePrimaryBtn={() => handleSubmit(selectedOptions)}
      isPrimaryBtnDisable={selectedOptions.find((option) => !option?.value)}
      isClose
      bodyContent={
        <Box sx={{ width: '100%', px: 1 }}>
          {selectedOptions.map((option, index) => (
            <>
              <Grid container spacing={1}>
                <Grid item xs={12} align="left">
                  <Typography variant="body2" color="neutral.800">
                    Attributes:
                  </Typography>
                </Grid>
                <Grid item xs={6} align="left">
                  <Dropdown
                    id="attribute"
                    label=""
                    labelId="attribute"
                    options={addedDropDownOptions(option)}
                    handleChange={(e) => handleAddedDropdownChange(e, index)}
                    value={option?.name}
                  />
                </Grid>
                <Grid item xs={6} align="left">
                  <RenderInput
                    name={option?.name}
                    type={option?.type}
                    handleDateChange={(e) => {
                      const options = selectedOptions.map((a) => {
                        return { ...a };
                      });
                      options[index] = { ...options[index], value: e };
                      setSelectedOptions(options);
                    }}
                    handleDropdownChange={(e) => {
                      const options = selectedOptions.map((a) => {
                        return { ...a };
                      });
                      options[index] = { ...options[index], value: e?.target?.value };
                      setSelectedOptions(options);
                    }}
                    handleInputChange={(e) => {
                      const options = selectedOptions.map((a) => {
                        return { ...a };
                      });
                      options[index] = { ...options[index], value: e?.target?.value };
                      setSelectedOptions(options);
                    }}
                    value={option?.value}
                    attributeOptions={attributeOptions}
                    disabled={!!option?.name}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} align="right" sx={TableFilterStyle.filterActionBtn}>
                  {index === selectedOptions.length - 1 && (
                    <Box>
                      <Button
                        size="medium"
                        variant="text"
                        onClick={() => {
                          setSelectedOptions([...selectedOptions, { name: '', value: '', type: '', filterName: '' }]);
                        }}
                        startIcon={<AddIcon />}
                        isDisable={!option?.value || selectedOptions?.length === attributeOptions?.length}
                      >
                        Add filter
                      </Button>
                    </Box>
                  )}
                  <Box>
                    <Button
                      size="medium"
                      variant="text"
                      onClick={() => handleRemove(index)}
                      color="error"
                      isDisable={!option?.name && selectedOptions?.length === 1}
                    >
                      Remove
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              {index + 1 !== selectedOptions?.length && <Divider sx={TableFilterStyle.separator} />}
            </>
          ))}
        </Box>
      }
    />
  );
};

TableFilter.defaultProps = {
  dialogTitle: 'Filters',
  primaryBtn: '',
  primaryBtnColor: 'primary',
  handleSubmit: () => {},
  handleSecondaryBtn: () => {},
  alignmentActionBtn: 'end',
  open: true,
  handleClose: () => {},

  attributeOptions: [
    { id: 'Name', name: 'Name', type: 'string', onClick: () => {} },
    { id: 'Count', name: 'Count', type: 'number', onClick: () => {} },
    {
      id: 'Status',
      name: 'Status',
      type: 'dropdown',
      onClick: () => {},
      options: [
        { id: 'active', name: 'active' },
        { id: 'inactive', name: 'inactive' },
      ],
    },
    { id: 'Created', name: 'Created', type: 'date', onClick: () => {} },
  ],
  secondaryBtn: '',
};

RenderInput.propTypes = {
  attributeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  name: PropTypes.string,
  handleDateChange: PropTypes.func,
  handleDropdownChange: PropTypes.func,
  handleInputChange: PropTypes.func,
  value: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

RenderInput.defaultProps = {
  attributeOptions: [
    { id: 'Name', name: 'Name', type: 'string', onClick: () => {} },
    { id: 'Count', name: 'Count', type: 'number', onClick: () => {} },
    {
      id: 'Status',
      name: 'Status',
      type: 'dropdown',
      onClick: () => {},
      options: [
        { id: 'active', name: 'active' },
        { id: 'inactive', name: 'inactive' },
      ],
    },
    { id: 'Created', name: 'Created', type: 'date', onClick: () => {} },
  ],
  name: '',
  handleDateChange: () => {},
  handleDropdownChange: () => {},
  handleInputChange: () => {},
  value: '',
  type: '',
  disabled: false,
};

TableFilter.propTypes = {
  alignmentActionBtn: PropTypes.string,
  handleSubmit: PropTypes.func,
  attributeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  dialogTitle: PropTypes.string,

  primaryBtn: PropTypes.string,
  primaryBtnColor: PropTypes.string,
  handleSecondaryBtn: PropTypes.func,
  secondaryBtn: PropTypes.string,
};

export default TableFilter;
