import React, { useEffect } from 'react';
import RecordDetails from 'components/Templates/Employee/LifeCycleRecord/Details';
import DeleteLifeCycleRecord from 'components/Organisms/Dialog/ActionList/DeleteLifeCycleRecord';
import { useThunk } from 'hooks/useThunk';
import { fetchLCRecordDetails } from 'store';
import { useSelector } from 'react-redux';
import { Box, Skeleton, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { hasPermission } from 'utils/permissions';
import useLCRecordServices from '../List/useLCRecordServices';
import useMenuItems from './useMenuItems';

const RecordDetailsPage = () => {
  const { userId, changeType } = useParams();
  const services = useLCRecordServices();
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasViewPermission = hasPermission(authorities, ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_CASE_RECORD']);

  const breadcrumbData = [
    {
      id: 1,
      name: 'List Life Cycle Case Records Pending Approval',
      path: '/company/life-cycle/life-cycle-records-pending-approval',
      isActive: false,
    },
    { id: 2, name: 'Details', path: '', isActive: true },
  ];

  const [getRecordDetails, isLoadingRecordDetails, loadingRecordDetailsError] = useThunk(fetchLCRecordDetails);

  useEffect(() => {
    const bodyFormData = new FormData();
    bodyFormData.append('id', userId);
    bodyFormData.append('type', changeType);
    getRecordDetails(bodyFormData);
  }, [userId, changeType, getRecordDetails, services?.refreshPage]);

  const { lcRecordDetails = {} } = useSelector((state) => state.lifeCycleRecords);

  const userHasEditPermission =
    hasPermission(authorities, ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_CASE_RECORD']) &&
    (lcRecordDetails?.lifeCycleCaseRecord?.status === 'Approval Pending' ||
      lcRecordDetails?.lifeCycleCaseRecord?.status === 'Draft');

  const employeeName = lcRecordDetails?.lifeCycleCaseRecord?.employee?.fullName;
  const recordDetails = {
    name: lcRecordDetails?.lifeCycleCaseRecord?.createdBy?.fullName,
    date: lcRecordDetails?.lifeCycleCaseRecord?.createdOn,
    status: lcRecordDetails?.lifeCycleCaseRecord?.status,
    supervisor: lcRecordDetails?.employee?.supervisor?.fullName,
    department: lcRecordDetails?.employee?.department?.name,
    designation: lcRecordDetails?.employee?.designation?.name,
    location: lcRecordDetails?.employee?.location?.name,
    employeeLevel: lcRecordDetails?.employee?.empLevel?.name,
    employeeGrade: lcRecordDetails?.employee?.empGrade?.name,
    division: lcRecordDetails?.employee?.division?.name,
    changeType: lcRecordDetails?.lifeCycleCaseRecord?.changeType,
  };

  const newRecordDetails = {
    supervisor: lcRecordDetails?.supervisor?.fullName,
    department: lcRecordDetails?.department?.name,
    designation: lcRecordDetails?.designation?.name,
    location: lcRecordDetails?.location?.name,
    employeeLevel: lcRecordDetails?.employeeLevel?.name,
    employeeGrade: lcRecordDetails?.employeeGrade?.name,
    division: lcRecordDetails?.division?.name,
    effectiveDate: lcRecordDetails?.effectiveStartDate,
    effectiveEndDate: lcRecordDetails?.effectiveEndDate,
    reasonOfTransfer: lcRecordDetails?.reason || '-',
  };

  const [menuItems] = useMenuItems(lcRecordDetails, services);

  if (isLoadingRecordDetails) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (loadingRecordDetailsError !== null) {
    return (
      <Typography variant="h6">
        {loadingRecordDetailsError?.message || 'Error while loading life cycle case record details.'}
      </Typography>
    );
  }

  if (!userHasViewPermission) {
    return <Typography variant="h6">You do not have permission to view this page.</Typography>;
  }

  return (
    <Box>
      <RecordDetails
        pageHeading="Life Cycle Case Record Details"
        breadcrumbData={breadcrumbData}
        employeeName={employeeName}
        recordDetails={recordDetails}
        newRecordDetails={newRecordDetails}
        auditDetails={lcRecordDetails?.commentsMapList || {}}
        isEdit={userHasEditPermission}
        handleEdit={() => services.modifyEmployee(lcRecordDetails?.lifeCycleCaseRecord)}
        menuList={menuItems}
        creatorAttachment={lcRecordDetails?.creatorAttachment || {}}
        approverAttachments={lcRecordDetails?.approverAttachments || {}}
        handleAttachmentDownload={services.handleAttachment}
      />
      {lcRecordDetails !== null && (
        <DeleteLifeCycleRecord
          open={services.showDeleteDialog}
          handleClose={services.handleDeleteDialog}
          handleCancel={services.handleDeleteDialog}
          handleDelete={() => {
            services.deleteRecords(lcRecordDetails?.lifeCycleCaseRecord, true);
          }}
        />
      )}
    </Box>
  );
};

export default RecordDetailsPage;
