import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, useTheme, Divider } from '@mui/material';
import Dropdown from 'components/Molecules/Dropdown';
import InputField from 'components/Atoms/InputField';
import Typographys from 'components/Atoms/Typography';
import DatePicker from 'components/Atoms/Datepicker';
import PerfectScrollbar from 'react-perfect-scrollbar';

const AdvanceProfile = ({ bloodgroupOptions, propData, isPassportDetailsMandatory }) => {
  const formik = propData.formik;

  const handleDateChange = (date, fieldName) => {
    formik.setFieldValue(fieldName, date);
  };

  const theme = useTheme();

  return (
    <Box mt={1.5} px={3}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 45rem)' }}>
          <Grid container rowSpacing={2} columnSpacing={9} mt={0}>
            <Grid item xs={12} md={4}>
              <Dropdown
                error={Boolean(formik.touched.bloodgroup && formik.errors.bloodgroup)}
                helperText={formik.touched.bloodgroup && formik.errors.bloodgroup}
                handleChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.bloodgroup}
                name="bloodgroup"
                id="bloodgroup"
                label="Blood Group"
                options={bloodgroupOptions}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.skypeid && formik.errors.skypeid)}
                helperText={formik.touched.skypeid && formik.errors.skypeid}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.skypeid}
                name="skypeid"
                id="skypeid"
                label="Skype ID"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.facebookid && formik.errors.facebookid)}
                helperText={formik.touched.facebookid && formik.errors.facebookid}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.facebookid}
                name="facebookid"
                id="facebookid"
                label="Facebook ID"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.linkedinid && formik.errors.linkedinid)}
                helperText={formik.touched.linkedinid && formik.errors.linkedinid}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.linkedinid}
                name="linkedinid"
                id="linkedinid"
                label="Linkedin ID"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.googlePlusid && formik.errors.googlePlusid)}
                helperText={formik.touched.googlePlusid && formik.errors.googlePlusid}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.googlePlusid}
                name="googlePlusid"
                id="googlePlusid"
                label="GooglePlus ID"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.twitterid && formik.errors.twitterid)}
                helperText={formik.touched.twitterid && formik.errors.twitterid}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.twitterid}
                name="twitterid"
                id="twitterid"
                label="Twitter ID"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.instagramid && formik.errors.instagramid)}
                helperText={formik.touched.instagramid && formik.errors.instagramid}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.instagramid}
                name="instagramid"
                id="instagramid"
                label="Instagram ID"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <InputField
                error={Boolean(formik.touched.selfdescription && formik.errors.selfdescription)}
                helperText={formik.touched.selfdescription && formik.errors.selfdescription}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.selfdescription}
                name="selfdescription"
                id="selfdescription"
                label="Self Description"
                fullWidth
                multiline
              />
            </Grid>
          </Grid>
          <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

          <Typographys variant="h5" color="neutral.800">
            Passport Details
          </Typographys>

          <Grid mt={2} container rowSpacing={2} columnSpacing={9}>
            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.passportnumber && formik.errors.passportnumber)}
                helperText={formik.touched.passportnumber && formik.errors.passportnumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.passportnumber}
                name="passportnumber"
                id="passportnumber"
                label={[`Passport Number  ${isPassportDetailsMandatory}`]}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DatePicker
                helperText={formik.touched.passportissuedate && formik.errors.passportissuedate}
                onChange={(date) => handleDateChange(date, 'passportissuedate')}
                onBlur={formik.handleBlur}
                value={formik.values?.passportissuedate}
                name="passportissuedate"
                id="passportissuedate"
                label={[`Passport Issue Date  ${isPassportDetailsMandatory}`]}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <DatePicker
                helperText={formik.touched.passportexpirydate && formik.errors.passportexpirydate}
                onChange={(date) => handleDateChange(date, 'passportexpirydate')}
                onBlur={formik.handleBlur}
                value={formik.values?.passportexpirydate}
                name="passportexpirydate"
                id="passportexpirydate"
                label={[`Passport Expiry Date  ${isPassportDetailsMandatory}`]}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.passportplaceissue && formik.errors.passportplaceissue)}
                helperText={formik.touched.passportplaceissue && formik.errors.passportplaceissue}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.passportplaceissue}
                name="passportplaceissue"
                id="passportplaceissue"
                label={[`Passport Place of Issue  ${isPassportDetailsMandatory}`]}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.passportcountry && formik.errors.passportcountry)}
                helperText={formik.touched.passportcountry && formik.errors.passportcountry}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.passportcountry}
                name="passportcountry"
                id="passportcountry"
                label={[`Passport Country  ${isPassportDetailsMandatory}`]}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.passportnamegiven && formik.errors.passportnamegiven)}
                helperText={formik.touched.passportnamegiven && formik.errors.passportnamegiven}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.passportnamegiven}
                name="passportnamegiven"
                id="passportnamegiven"
                label={[`Passport Name Given ${isPassportDetailsMandatory}`]}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </PerfectScrollbar>
    </Box>
  );
};

AdvanceProfile.defaultProps = {
  bloodgroupOptions: [],
  propData: {},
};

AdvanceProfile.propTypes = {
  bloodgroupOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  propData: PropTypes.shape({
    formik: PropTypes.shape({
      errors: PropTypes.shape({
        bloodgroup: PropTypes.string,
        skypeid: PropTypes.string,
        facebookid: PropTypes.string,
        linkedinid: PropTypes.string,
        googlePlusid: PropTypes.string,
        twitterid: PropTypes.string,
        instagramid: PropTypes.string,
        selfdescription: PropTypes.string,
        passportnumber: PropTypes.string,
        passportissuedate: PropTypes.string,
        passportexpirydate: PropTypes.string,
        passportplaceissue: PropTypes.string,
        passportcountry: PropTypes.string,
        passportnamegiven: PropTypes.string,
      }),

      touched: PropTypes.shape({
        bloodgroup: PropTypes.string,
        skypeid: PropTypes.string,
        facebookid: PropTypes.string,
        linkedinid: PropTypes.string,
        googlePlusid: PropTypes.string,
        twitterid: PropTypes.string,
        instagramid: PropTypes.string,
        selfdescription: PropTypes.string,
        passportnumber: PropTypes.string,
        passportissuedate: PropTypes.string,
        passportexpirydate: PropTypes.string,
        passportplaceissue: PropTypes.string,
        passportcountry: PropTypes.string,
        passportnamegiven: PropTypes.string,
      }),

      values: PropTypes.shape({
        bloodgroup: PropTypes.string,
        skypeid: PropTypes.string,
        facebookid: PropTypes.string,
        linkedinid: PropTypes.string,
        googlePlusid: PropTypes.string,
        twitterid: PropTypes.string,
        instagramid: PropTypes.string,
        selfdescription: PropTypes.string,
        passportnumber: PropTypes.string,
        passportissuedate: PropTypes.string,
        passportexpirydate: PropTypes.string,
        passportplaceissue: PropTypes.string,
        passportcountry: PropTypes.string,
        passportnamegiven: PropTypes.string,
      }),
      setFieldValue: PropTypes.func,
      resetForm: PropTypes.func,
      handleBlur: PropTypes.func,
      handleChange: PropTypes.func,
      handleSubmit: PropTypes.func,
    }),
  }),
};

export default AdvanceProfile;
