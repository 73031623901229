import dayjs from 'dayjs';
import { API_URL } from 'api/api';

export const convertEmployeeData = (employee) => {
  if (!employee?.data) {
    return {};
  }

  const {
    secUserInstance = {},
    employeeInstance = {},
    employeeProfileInstance = {},
    company = {},
    roleMap = {},
    permanentHomeAddressInstance = {},
    presentCommunicationAddressInstance = {},
    officeAddressInstance = {},
    bankAccounts = [],
    educationRecords = [],
    pastEmployeeRecords = [],
    visaRecords = [],
    skillRecords = [],
    eventRecords = [],
    commentsMap = [],
    commentsMapList = [],
  } = employee.data;

  const getEmergencyContactDetails = () => {
    const primaryReference = employeeProfileInstance?.dependents?.filter(
      (item) => item?.emergencyContactReference === 'PrimaryReference',
    );
    return primaryReference?.[0] || {};
  };

  const getActionCreatorDetails = (comment) => {
    const commentsActionCreatorInstance = Object.keys(commentsMap)?.find((item) => item?.includes(comment?.id));
    return {
      fullName: commentsMap?.[`${commentsActionCreatorInstance}`]?.fullName || '',
      avatarSrc: `${API_URL}/${commentsMap?.[`${commentsActionCreatorInstance}`]?.profile?.profilePic}`,
    };
  };
  return {
    employeeProfileDetail: {
      name: `${employeeInstance?.firstName || ''} ${employeeInstance?.lastName || ''}`,
      avatarSrc: `${API_URL}/${employeeInstance?.profile?.profilePic}`,
      progressBarValue: 67,
      actionMenuLists: [
        { label: 'Add New' },
        { label: 'Bulk Upload' },
        { label: 'Send ESS invite' },
        { label: 'Download' },
      ],
      contactInfo: {
        location: employeeInstance?.location?.name,
        email: employeeInstance?.officialEmailID,
        mobile: employeeInstance?.mobileNumbers?.join(','),
        phone: employeeInstance?.telephoneNumbers?.join(','),
      },

      employmentInfo: {
        designation: employeeInstance?.designation?.name,
        department: employeeInstance?.department?.name,
        supervisor: employeeInstance?.supervisor?.fullName,
        employeeID: employeeProfileInstance?.employeeID,
        employmentType: employeeInstance?.employmentType,
      },
    },
    userDetails: {
      gender: employeeInstance?.gender,
      maritalStatus: employeeInstance?.maritalStatus,
      dob: employeeProfileInstance?.dateOfBirth,
      doj: employeeProfileInstance?.dateOfJoining,
      employmentStatus: employeeInstance?.employmentStatus,
      fatherName: employeeProfileInstance?.fathersName,
      motherName: employeeProfileInstance?.mothersName,
      spouseName: employeeProfileInstance?.spouseName,
    },
    userRoles: Object.keys(roleMap)?.filter((item) => roleMap[item] === true),
    employeeBasicDetail: {
      gender: employeeInstance?.gender || '-',
      maritalStatus: employeeInstance?.maritalStatus || '-',
      dob: employeeProfileInstance?.dateOfBirth || '-',
      doj: employeeProfileInstance?.dateOfJoining || '-',
      employmentStatus: employeeInstance?.employmentStatus || '-',
      fatherName: employeeProfileInstance?.fathersName || '-',
      motherName: employeeProfileInstance?.mothersName || '-',
      spouseName: employeeProfileInstance?.spouseName || '-',
      location: employeeInstance?.location?.name || '-',
      pan: employeeInstance?.panNumberIndia || '-',
      aadhaar: employeeInstance?.aadhaarNumberIndia || '-',
      uan: employeeInstance?.uanNumberIndia || '-',
      uhid: employeeInstance?.uhidAccountNumberIndia || '-',
      epf: employeeInstance?.epfAccountNumberIndia || '-',
      eps: employeeInstance?.epsAccountNumberIndia || '-',
      esi: employeeInstance?.esiAccountNumberIndia || '-',
      groupsAffiliations: '-',
      employmentType: employeeInstance?.employmentType || '-',
      employmentActiveStatus: employeeInstance?.employmentStatus || '-',
      supervisor: employeeInstance?.supervisor?.fullName || '-',
    },
    onboardingDetails: {
      reference: employeeProfileInstance?.onboardingReference || '-',
      bloodGroup: employeeProfileInstance?.bloodGroup || '-',
      separationType: employeeProfileInstance?.seperationType || '-',
      doc: employeeProfileInstance?.confirmationDate || '-',
      noticePeriod:
        employeeProfileInstance?.noticePeriod && employeeProfileInstance?.noticePeriodTimeUnit
          ? `${employeeProfileInstance?.noticePeriod} ${employeeProfileInstance?.noticePeriodTimeUnit}`
          : '-',
      noticePeriodUnits: employeeProfileInstance?.noticePeriodTimeUnit || '-',
      dol: dayjs(employeeProfileInstance?.dateOfLeaving).format('MMMM DD, YYYY') || '-',
    },
    socialData: {
      skypeID: employeeInstance?.skypeID || '-',
      facebookID: employeeInstance?.facebookID || '-',
      linkedinID: employeeInstance?.linkedinID || '-',
      googlePlusID: employeeInstance?.googlePlusID || '-',
      twitterID: employeeInstance?.twitterID || '-',
      instagramID: employeeInstance?.instagramID || '-',
    },
    educationHistory: educationRecords?.length
      ? educationRecords?.map((item) => ({
          institutionName: item?.institutionName || '-',
          certificationName: item?.certificationName || '-',
          startDate: item?.startDate || '-',
          endDate: item?.endDate || '-',
          specialization: item?.specialization || '-',
          registrationNumber: item?.registrationNumber || '-',
          referenceName: item?.referenceName || '-',
          referencePhone: item?.referencePhoneNumber || '-',
          registrationEmail: item?.referenceEmail || '-',
          certificateFile: item?.documents.length > 0 ? item?.documents[0]?.fileName : '',
        }))
      : [],
    employmentHistory: educationRecords?.length
      ? pastEmployeeRecords?.map((item) => ({
          employerName: item?.employerName || '-',
          employerAddress: item?.employerAddress || '-',
          commenceDate: item?.employmentCommenceDate || '-',
          endDate: item?.employmentEndDate || '-',
          reasonForLeaving: item?.reasonForLeaving || '-',
          employeeID: item?.employeeID || '-',
          startDesignation: item?.startDesignation || '-',
          endDesignation: item?.endDesignation || '-',
          referenceName: item?.referenceContactName || '-',
          referenceDesignation: item?.referenceContactDesignation || '-',
          referencePhone: item?.referenceContactPhone || '-',
          referenceEmail: item?.referenceContactEmail || '-',
          experienceLetter: item?.documents.length > 0 ? item?.documents[0]?.fileName : '',
        }))
      : [],
    visaDetails: visaRecords?.length
      ? visaRecords?.map((item) => ({
          issuingCountry: item.issuingCountry,
          placeOfIssue: item.placeOfIssue,
          validFrom: item.validFrom,
          validTo: item.validTo,
          issueDate: item.issueDate,
          referenceNumber: item.referenceNumber,
          visaStatus: item.status,
          comments: item.comments,
        }))
      : [],
    skillsAttainmentDetails: skillRecords?.length
      ? skillRecords?.map((item) => ({
          skill: item.skill?.name,
          level: item.level,
          comments: item.comments,
          documents: item.documents,
        }))
      : [],
    keyEventDetails: eventRecords?.length
      ? eventRecords?.map((item) => ({
          keyEventDetail: item.eventType,
          eventDate: item.eventDate,
          comments: item.comments,
        }))
      : [],
    dependentDetails: employeeProfileInstance?.dependents?.map((dependent) => ({
      gender: dependent?.contactInfo?.gender || '',
      firstName: dependent?.contactInfo?.firstName || '',
      lastName: dependent?.contactInfo?.lastName || '',
      relation: dependent?.relationship || '',
      dateOfBirth: dependent?.dateOfBirth ? dayjs(dependent?.dateOfBirth).format('MM/DD/YYYY') : '',
      mobileNumber: dependent?.contactInfo?.mobileNumber || '',
      emailId: dependent?.contactInfo?.emailAddress || '',
      epfPerc: dependent?.epfNominationPercentage,
      epsPerc: dependent?.epsNominationPercentage,
      insurancePerc: dependent?.insuranceNominationPercentage,
      otherPerc: dependent?.othersNominationPercentage,
      gratuityPerc: dependent?.gratuityNominationPercentage,
      emergencyContact: dependent?.emergencyContactReference || '',
      bloodGroup: dependent?.bloodGroup || '',
    })),
    contactDetails: {
      phone: employeeInstance?.telephoneNumbers?.join(',') || '-',
      mobile: employeeInstance?.mobileNumbers?.join(',') || '-',
      email: employeeInstance?.officialEmailID || '-',
      emergencyContactName: getEmergencyContactDetails()?.contactInfo?.firstName || '-',
      emergencyContactRelation: getEmergencyContactDetails()?.relationship || '-',
      emergencyContactMobile: getEmergencyContactDetails()?.mobileNumber || '-',
      emergencyContactEmail: getEmergencyContactDetails()?.emailAddress || '-',
      permanentLocation: permanentHomeAddressInstance.locationName,
      permanentAddress1: permanentHomeAddressInstance.addressLine1,
      permanentAddress2: permanentHomeAddressInstance.addressLine2,
      permanentAddress3: permanentHomeAddressInstance.addressLine3,
      // eslint-disable-next-line max-len
      permanentAddress4: `${permanentHomeAddressInstance.city}, ${permanentHomeAddressInstance.state} ${permanentHomeAddressInstance.country} ${permanentHomeAddressInstance.pinOrZip}`,
      presentLocation: presentCommunicationAddressInstance.locationName,
      presentAddress1: presentCommunicationAddressInstance.addressLine1,
      presentAddress2: presentCommunicationAddressInstance.addressLine2,
      presentAddress3: presentCommunicationAddressInstance.addressLine3,
      // eslint-disable-next-line max-len
      presentAddress4: `${presentCommunicationAddressInstance.city}, ${presentCommunicationAddressInstance.state} ${presentCommunicationAddressInstance.country} ${presentCommunicationAddressInstance.pinOrZip}`,
      officeLocation: officeAddressInstance.locationName,
      officeAddress1: officeAddressInstance.addressLine1,
      officeAddress2: officeAddressInstance.addressLine2,
      officeAddress3: officeAddressInstance.addressLine3,
      // eslint-disable-next-line max-len
      officeAddress4: `${officeAddressInstance.city}, ${officeAddressInstance.state} ${officeAddressInstance.country} ${officeAddressInstance.pinOrZip}`,
    },
    bankDetails: bankAccounts?.map((item) => ({
      accountNumber: item?.accountNumber,
      accountName: item?.accountHolderName,
      accountType: item?.accountType,
      accountCurrency: item?.currency,
      purpose: item?.accountPurpose,
      transactionType: item?.preferredTransactionType,
      bankName: item?.bankName,
      branchName: item?.bankBranch,
      IFSCCode: item?.ifsCode,
      swiftCode: item?.swiftCode,
      locationName: item?.bankAddress?.locationName,
      addressLine1: item?.bankAddress?.addressLine1,
      addressLine2: item?.bankAddress?.addressLine2,
      addressLine3: item?.bankAddress?.addressLine3,
      city: item?.bankAddress?.city,
      state: item?.bankAddress?.state,
      country: item?.bankAddress?.country,
      pinOrZip: item?.bankAddress?.pinOrZip,
    })),
    payrollSetting: {
      isDisable: employeeInstance?.isDisabledIndia,
      isInternationalWorker: employeeInstance?.isInternationalWorkerIndia,
      isEligibleEPF: employeeInstance?.isEligibleForEPFIndia,
      isEligibleESI: employeeInstance?.isEligibleForESIIndia,
      isEligibleEPS: employeeInstance?.isEligibleForEPSIndia,
      isEligibleEDLI: employeeInstance?.isEligibleForEDLIIndia,
      isEligibleProfessionalTax: employeeInstance?.isProfessionalTaxApplicableIndia,
      isEligibleLabourWelfareFund: employeeInstance?.isEligibleForLWFIndia,
      isLocatedInMetro: employeeInstance?.isMetroLocationIndia,
      isJoinedEPFSept2014: employeeInstance?.hasJoinedEPFBeforeCutOff,
      numOfChildInSchool: employeeInstance?.numberOfSchoolGoingChildren,
      numOfChildInHostel: employeeInstance?.numberOfChildrenInHostel,
      professionalTaxJurisdiction: employeeInstance?.professionalTaxJurisdictionIndia,
      labourWelfareFundJurisdiction: employeeInstance?.lwfJurisdictionIndia,
      esiDispensary: employeeInstance?.esiDispensaryIndia,
      dateEnrollingEPF: employeeInstance?.dateOfJoiningEPFIndia,
      epfContribution: employeeInstance?.employeeEPFContributionLevelIndia,
      dolEPF: employeeInstance?.dateOfLeavingEPFIndia,
      isPANValid: employeeInstance?.isPanIndiaValid,
      isPANValidatedWithITDepartment: employeeInstance?.isPanIndiaValidated,
      panValidatedBy: employeeInstance?.panIndiaValidatedBy,
      panValidateDate: employeeInstance?.panIndiaValidationDate,
      isEmployeeTaxedTDSRate: employeeInstance?.isFlatTDSApplicable,
      tds: employeeInstance?.flatTDSRate,
      panFlat: employeeInstance?.flatTDSCompBasis,
      companySAccount: employeeInstance?.companySalaryAccount,
      expenseReimbursementAc: employeeInstance?.companyExpReimburseAccount,
    },
    notificationLists: [
      { title: 'Notify Me upon Pay Slip Publication', status: employeeInstance?.notifyOnSalSlipPubl },
      { title: 'Notify Me upon Expense Report Updates', status: employeeInstance?.notifyOnExpRepAction },
      { title: 'Notify Me upon Leave Request Updates', status: employeeInstance?.notifyOnLeavReqAction },
      { title: 'Notify Me upon Attendance Exception Occurance', status: employeeInstance?.notifyOnAttnExcp },
      {
        title: 'Notify Me when Team Member Regularizes Attendance',
        status: employeeInstance?.notifyOnTeamMemAttnReglr,
      },
    ],
    auditTrails: commentsMapList?.map((item) => {
      return {
        avatarSrc: getActionCreatorDetails(item)?.avatarSrc,
        description: getActionCreatorDetails(item)?.fullName,
        content: item?.content?.split('<br/>'),
        date: item?.createdOn,
      };
    }),
  };
};
