import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Skeleton } from '@mui/material';
import SearchBox from 'components/Molecules/SearchBox';
import Button from 'components/Atoms/Button';
import AddIcon from '@mui/icons-material/Add';
import InfiniteScroll from 'react-infinite-scroll-component';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Typographys from 'components/Atoms/Typography';
import LCPolicyListItem from '../LCPolicyListItem';
import { policyListWithSearchStyle } from './style';

const LCPolicyListItemWithSearch = ({
  policyListItems,
  fetchMorePolicyListItems,
  handleSearch,
  handleAdd,
  setPolicyItem,
  handlePolicyItemClick,
  loading,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (policyListItems[0]) {
      setPolicyItem(policyListItems[0]);
      handlePolicyItemClick(policyListItems[0]);
    }
  }, []);

  return (
    <Box sx={policyListWithSearchStyle.wrapper}>
      {loading.policyLoading ? (
        <Box sx={{ width: '100%', padding: 2 }}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Box>
      ) : (
        <>
          <Box sx={policyListWithSearchStyle.searchWrapper}>
            <SearchBox
              id="policySearch"
              name="policySearch"
              placeholder="Search..."
              size="small"
              handleChange={handleSearch}
            />
            <Button variant="outlined" startIcon={<AddIcon fontSize="2.5rem" />} onClick={handleAdd}>
              Add
            </Button>
          </Box>
          <Box sx={policyListWithSearchStyle.scroll}>
            <InfiniteScroll
              dataLength={policyListItems.length}
              next={fetchMorePolicyListItems}
              hasMore
              height={500}
              loader={
                <Box padding="1.5rem 3rem">
                  <Typographys color="neutral.800" variant="body2">
                    Loading...
                  </Typographys>
                </Box>
              }
              endMessage={
                <Box padding="1.5rem 3rem">
                  <Typographys color="neutral.800" variant="body2">
                    No more items to load
                  </Typographys>
                </Box>
              }
            >
              <PerfectScrollbar>
                {policyListItems.map((item, index) => (
                  <LCPolicyListItem
                    key={item.id}
                    isActive={activeIndex === index}
                    heading={item.heading}
                    subHeading={item.subHeading}
                    handleClick={() => {
                      setPolicyItem(item);
                      setActiveIndex(index);
                      handlePolicyItemClick(item);
                    }}
                  />
                ))}
              </PerfectScrollbar>
            </InfiniteScroll>
          </Box>
        </>
      )}
    </Box>
  );
};
LCPolicyListItemWithSearch.defaultProps = {
  handleSearch: () => {},
  handleAdd: () => {},
  policyListItems: [
    {
      id: 1,
      heading: 'Default Life Cycle Policy',
      subHeading: '45 employees',
      active: true,
      employeeList: [
        {
          id: 1,
          employeeId: 101,
          employeeName: 'Employee 1',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 102,
          employeeName: 'Employee 2',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 2,
      heading: 'Life Cycle Policy 2022',
      subHeading: '45 employees',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 103,
          employeeName: 'Employee 3',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 104,
          employeeName: 'Employee 4',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 3,
      heading: 'Updated Life Cycle Policy',
      subHeading: '45 employees',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 105,
          employeeName: 'Employee 5',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 106,
          employeeName: 'Employee 6',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 4,
      heading: 'Description',
      subHeading: 'Vel Diam ultiricies diashadsajdn',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 107,
          employeeName: 'Employee 7',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 108,
          employeeName: 'Employee 8',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 5,
      heading: 'Default Life Cycle Policy',
      subHeading: '45 employees',
      active: true,
      employeeList: [
        {
          id: 1,
          employeeId: 101,
          employeeName: 'Employee 1',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 102,
          employeeName: 'Employee 2',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 6,
      heading: 'Life Cycle Policy 2022',
      subHeading: '45 employees',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 103,
          employeeName: 'Employee 3',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 104,
          employeeName: 'Employee 4',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 7,
      heading: 'Updated Life Cycle Policy',
      subHeading: '45 employees',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 105,
          employeeName: 'Employee 5',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 106,
          employeeName: 'Employee 6',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 8,
      heading: 'Description',
      subHeading: 'Vel Diam ultiricies diashadsajdn',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 107,
          employeeName: 'Employee 7',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 108,
          employeeName: 'Employee 8',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 9,
      heading: 'Default Life Cycle Policy',
      subHeading: '45 employees',
      active: true,
      employeeList: [
        {
          id: 1,
          employeeId: 101,
          employeeName: 'Employee 1',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 102,
          employeeName: 'Employee 2',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 10,
      heading: 'Life Cycle Policy 2022',
      subHeading: '45 employees',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 103,
          employeeName: 'Employee 3',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 104,
          employeeName: 'Employee 4',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 11,
      heading: 'Updated Life Cycle Policy',
      subHeading: '45 employees',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 105,
          employeeName: 'Employee 5',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 106,
          employeeName: 'Employee 6',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 12,
      heading: 'Description',
      subHeading: 'Vel Diam ultiricies diashadsajdn',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 107,
          employeeName: 'Employee 7',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 108,
          employeeName: 'Employee 8',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 13,
      heading: 'Default Life Cycle Policy',
      subHeading: '45 employees',
      active: true,
      employeeList: [
        {
          id: 1,
          employeeId: 101,
          employeeName: 'Employee 1',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 102,
          employeeName: 'Employee 2',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 14,
      heading: 'Life Cycle Policy 2022',
      subHeading: '45 employees',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 103,
          employeeName: 'Employee 3',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 15,
          employeeId: 104,
          employeeName: 'Employee 4',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 15,
      heading: 'Updated Life Cycle Policy',
      subHeading: '45 employees',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 105,
          employeeName: 'Employee 5',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 106,
          employeeName: 'Employee 6',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 16,
      heading: 'Description',
      subHeading: 'Vel Diam ultiricies diashadsajdn',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 107,
          employeeName: 'Employee 7',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 108,
          employeeName: 'Employee 8',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 17,
      heading: 'Default Life Cycle Policy',
      subHeading: '45 employees',
      active: true,
      employeeList: [
        {
          id: 1,
          employeeId: 101,
          employeeName: 'Employee 1',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 102,
          employeeName: 'Employee 2',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 18,
      heading: 'Life Cycle Policy 2022',
      subHeading: '45 employees',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 103,
          employeeName: 'Employee 3',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 104,
          employeeName: 'Employee 4',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 19,
      heading: 'Updated Life Cycle Policy',
      subHeading: '45 employees',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 105,
          employeeName: 'Employee 5',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 106,
          employeeName: 'Employee 6',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 20,
      heading: 'Description',
      subHeading: 'Vel Diam ultiricies diashadsajdn',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 107,
          employeeName: 'Employee 7',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 108,
          employeeName: 'Employee 8',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 21,
      heading: 'Default Life Cycle Policy',
      subHeading: '45 employees',
      active: true,
      employeeList: [
        {
          id: 1,
          employeeId: 101,
          employeeName: 'Employee 1',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 102,
          employeeName: 'Employee 2',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 22,
      heading: 'Life Cycle Policy 2022',
      subHeading: '45 employees',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 103,
          employeeName: 'Employee 3',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 104,
          employeeName: 'Employee 4',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 23,
      heading: 'Updated Life Cycle Policy',
      subHeading: '45 employees',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 105,
          employeeName: 'Employee 5',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 106,
          employeeName: 'Employee 6',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 24,
      heading: 'Description',
      subHeading: 'Vel Diam ultiricies diashadsajdn',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 107,
          employeeName: 'Employee 7',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 108,
          employeeName: 'Employee 8',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 25,
      heading: 'Default Life Cycle Policy',
      subHeading: '45 employees',
      active: true,
      employeeList: [
        {
          id: 1,
          employeeId: 101,
          employeeName: 'Employee 1',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 102,
          employeeName: 'Employee 2',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 26,
      heading: 'Life Cycle Policy 2022',
      subHeading: '45 employees',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 103,
          employeeName: 'Employee 3',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 104,
          employeeName: 'Employee 4',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 27,
      heading: 'Updated Life Cycle Policy',
      subHeading: '45 employees',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 105,
          employeeName: 'Employee 5',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 106,
          employeeName: 'Employee 6',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 28,
      heading: 'Description',
      subHeading: 'Vel Diam ultiricies diashadsajdn',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 107,
          employeeName: 'Employee 7',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 108,
          employeeName: 'Employee 8',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 29,
      heading: 'Default Life Cycle Policy',
      subHeading: '45 employees',
      active: true,
      employeeList: [
        {
          id: 1,
          employeeId: 101,
          employeeName: 'Employee 1',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 102,
          employeeName: 'Employee 2',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 30,
      heading: 'Life Cycle Policy 2022',
      subHeading: '45 employees',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 103,
          employeeName: 'Employee 3',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 104,
          employeeName: 'Employee 4',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 31,
      heading: 'Updated Life Cycle Policy',
      subHeading: '45 employees',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 105,
          employeeName: 'Employee 5',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 106,
          employeeName: 'Employee 6',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 32,
      heading: 'Description',
      subHeading: 'Vel Diam ultiricies diashadsajdn',
      active: false,
      employeeList: [
        {
          id: 1,
          employeeId: 107,
          employeeName: 'Employee 7',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 108,
          employeeName: 'Employee 8',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
  ],
  setPolicyItem: () => {},
  fetchMorePolicyListItems: () => {},
  handlePolicyItemClick: () => {},
  loading: {},
};

LCPolicyListItemWithSearch.propTypes = {
  policyListItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      heading: PropTypes.string,
      subHeading: PropTypes.string,
      employeeList: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          employeeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          employeeName: PropTypes.string,
          lcPolicy: PropTypes.string,
          department: PropTypes.string,
          designation: PropTypes.string,
          location: PropTypes.string,
        }),
      ),
    }),
  ),
  handleSearch: PropTypes.func,
  handleAdd: PropTypes.func,
  setPolicyItem: PropTypes.func,
  fetchMorePolicyListItems: PropTypes.func,
  handlePolicyItemClick: PropTypes.func,
  loading: PropTypes.shape,
};

export default LCPolicyListItemWithSearch;
