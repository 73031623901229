export const contactDetailStyle = {
  wrapper: {
    paddingLeft: '24px',
    marginTop: '24px',
    '.MuiGrid-item ': {
      '> .MuiBox-root ': {
        pl: 0,
      },
    },
  },
};
