import { validateDateElseHyphen } from 'utils/commonUtils';

const columns = [
  {
    fieldName: 'queryNumber',
    name: '',
    label: 'Query Number',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'title',
    name: '',
    label: 'Title',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'category',
    name: '',
    label: 'Category',
    formatValue: ({ row, column }) => row[column.fieldName]?.name,
  },
  {
    fieldName: 'topic',
    name: '',
    label: 'Topic',
    formatValue: ({ row, column }) => row[column.fieldName]?.name,
  },
  {
    fieldName: 'resolutionStatus',
    name: '',
    label: 'Resolution Status',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'currentOwner',
    name: '',
    label: 'Current Owner',
    formatValue: ({ row, column }) => row[column.fieldName]?.fullName,
  },
  {
    fieldName: 'openedBy',
    name: '',
    label: 'Opened By',
    formatValue: ({ row, column }) => row[column.fieldName]?.fullName,
  },
  {
    fieldName: 'sourceType',
    name: '',
    label: 'Source Type',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'openDate',
    name: '',
    label: 'Opened On',
    formatValue: ({ row, column }) => validateDateElseHyphen(row[column.fieldName], 'DD MMMM, YYYY h:mm A'),
  },
  {
    fieldName: 'closedBy',
    name: '',
    label: 'Closed By',
    formatValue: ({ row, column }) => row[column.fieldName]?.fullName,
  },
  {
    fieldName: 'closeDate',
    name: '',
    label: 'Closed On',
    formatValue: ({ row, column }) => validateDateElseHyphen(row[column.fieldName], 'DD MMMM, YYYY h:mm A'),
  },
];

const useColumns = () => {
  return [columns];
};

export default useColumns;
