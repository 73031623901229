import moment from 'moment';

const flatten = (obj, prefix = '', res = {}) =>
  Object.entries(obj).reduce((r, [key, val]) => {
    const k = `${prefix}${key}`;
    if (typeof val === 'object') {
      flatten(val, `${k}.`, r);
    } else {
      res[k] = val;
    }
    return r;
  }, res);

export const convertChallanData = (challan) => {
  if (!challan.data) {
    return {};
  }
  const { challanInstance } = challan.data;

  const flattenChallanInstance = flatten(challanInstance);
  console.log('flattenChallanInstance in converter ', flattenChallanInstance);
  const finalObject = {
    challanDeatils: {
      challanType: flattenChallanInstance.challanType,
      payrollCalendar: flattenChallanInstance['payPeriod.payrollCalendar'],
      payPeriod: '',
      periodStartDate: moment(flattenChallanInstance.periodStartDate).format('MMM DD YYYY'),
      periodEndDate: moment(flattenChallanInstance.periodEndDate).format('MMM DD YYYY'),
      amountDue: flattenChallanInstance.amountDue,
      amountPaid: flattenChallanInstance.amountPaid,
      dateOfPayment: moment(flattenChallanInstance.dateOfPayment).format('MMM DD YYYY'),
      modeOfPayment: flattenChallanInstance.modeOfPayment,
      bankName: flattenChallanInstance.bankName,
      branchName: flattenChallanInstance.branchName,
      branchCode: flattenChallanInstance.branchCode,
      paidByPerson: flattenChallanInstance.paidByPerson,
      paymentTransactionReference: flattenChallanInstance.paymentTransactionReference,
      challanCode: flattenChallanInstance.challanCode,
      description: flattenChallanInstance.description,
    },
    tdsPaymentDeatils: {
      incomeTaxComponent: flattenChallanInstance.incomeTaxComponent,
      surchargeComponent: flattenChallanInstance.surchargeComponent,
      eduCessComponent: flattenChallanInstance.eduCessComponent,
      interest: flattenChallanInstance.interest,
      fees: flattenChallanInstance.fees,
      penaltyOthers: flattenChallanInstance.penaltyOthers,
      others: flattenChallanInstance.others,
      lastTotalTaxPaid: flattenChallanInstance.lastTotalTaxPaid,
      totalChallanAmountDeposited: flattenChallanInstance.totalChallanAmountDeposited,
      lastBSRCode: flattenChallanInstance.lastBSRCode,
      lastPaymentDepositDate: moment(flattenChallanInstance.lastPaymentDepositDate).format('MMM DD YYYY'),
      lastChallanSerialNumber: flattenChallanInstance.lastChallanSerialNumber,
      interestApportioned: flattenChallanInstance.interestApportioned,
      voucherNumber: flattenChallanInstance.voucherNumber,
      voucherDate: moment(flattenChallanInstance.voucherDate).format('MMM DD YYYY'),
      chequeDDNumber: flattenChallanInstance.chequeDDNumber,
      deductionDate: moment(flattenChallanInstance.deductionDate).format('MMM DD YYYY'),
      minorHeadCode: flattenChallanInstance.minorHeadCode,
      latePaymentFee: flattenChallanInstance.latePaymentFee,
      acknowledgementNumber: flattenChallanInstance.acknowledgementNumber,
    },
    epfChallanDetails: {
      epfEEShareRemittedIndia: flattenChallanInstance.epfEEShareRemittedIndia,
      epfERShareRemittedIndia: flattenChallanInstance.epfERShareRemittedIndia,
      epsERShareRemittedIndia: flattenChallanInstance.epsERShareRemittedIndia,
      edliERShareRemittedIndia: flattenChallanInstance.edliERShareRemittedIndia,
      epfAdminChargeRemittedIndia: flattenChallanInstance.epfAdminChargeRemittedIndia,
      edliAdminChargeRemittedIndia: flattenChallanInstance.edliAdminChargeRemittedIndia,
    },
  };
  return finalObject;
};
