import PropTypes from 'prop-types';
import React from 'react';
import Dialog from 'components/Molecules/Dialog';

const LockEmployeeRecords = ({
  handleComment,
  handleCancel,
  secondaryBtn,
  primaryBtn,
  primaryBtnColor,
  open,
  handleClose,
  title,
  desc,
}) => {
  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      dialogTitle={title}
      dialogDesc={desc}
      secondaryBtn={secondaryBtn}
      primaryBtn={primaryBtn}
      handlePrimaryBtn={handleComment}
      handleSecondaryBtn={handleCancel}
      primaryBtnColor={primaryBtnColor}
      bodyContent={false}
    />
  );
};
LockEmployeeRecords.defaultProps = {
  title: 'Dialog Title',
  desc: 'Dailog Desc',
  primaryBtnColor: 'primary',
  secondaryBtn: 'Cancel',
  primaryBtn: 'Lock',
  handleComment: () => {},
  handleCancel: () => {},
  open: true,
  handleClose: () => {},
};
LockEmployeeRecords.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  primaryBtnColor: PropTypes.string,
  handleComment: PropTypes.func,
  handleCancel: PropTypes.func,
  secondaryBtn: PropTypes.string,
  primaryBtn: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
};
export default LockEmployeeRecords;
