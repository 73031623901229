import React, { useEffect, useState } from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import TerminateEmployee from 'components/Organisms/Employee/EmployeeRecord/ActionList/TerminateEmployee';
import { useThunk } from 'hooks/useThunk';
import { fetchTerminateEmployee } from 'store';
import { Typography, Box, Skeleton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { terminateEmployeeAction } from 'api/employees';
import dayjs from 'dayjs';
import useToast from 'components/Provider/useToast';

const TerminateEmployeePage = () => {
  const addToast = useToast();
  const navigate = useNavigate();
  const { userId } = useParams();

  const [loading, setLoading] = useState(false);
  const [getTerminateEmployee, isLoadingTerminateEmployee, loadingTerminateEmployeeError] =
    useThunk(fetchTerminateEmployee);

  useEffect(() => {
    const query = new URLSearchParams({});
    query.append('id', userId);
    getTerminateEmployee(query);
  }, [userId, getTerminateEmployee]);

  const { terminateEmployee } = useSelector((state) => state.employees);

  const employeeData = {
    supervisor: terminateEmployee?.supervisor?.fullName,
    department: terminateEmployee?.department?.name,
    designation: terminateEmployee?.designation?.name,
    location: terminateEmployee?.location?.name,
  };

  const handleSubmit = async ({ startDate, notes }) => {
    const newStartDate = startDate ? dayjs(startDate).format('MM/DD/YYYY') : '';
    const bodyFormData = new FormData();
    bodyFormData.append('empId', terminateEmployee?.user?.employeeId);
    bodyFormData.append('CurrentAction', terminateEmployee?.user?.employeeId);
    bodyFormData.append('effectiveStartDate', newStartDate);
    bodyFormData.append('notes', notes || '');

    try {
      setLoading(true);
      const response = await terminateEmployeeAction(bodyFormData);
      if (response?.data?.status === 'SUCCESS') {
        addToast({ type: 'success', title: 'SUCCESS', message: response?.data?.message });
        navigate(`/company/employee/view/${userId}`);
      } else {
        addToast({ type: 'error', title: 'ERROR', message: response?.data?.message });
      }
    } catch (error) {
      addToast({ type: 'error', title: 'ERROR', message: error?.response?.data?.message || error?.message });
    } finally {
      setLoading(false);
    }
  };

  if (loadingTerminateEmployeeError !== null) {
    return (
      <Typography variant="h6">
        {loadingTerminateEmployeeError?.message || `Error while loading details for employee id : ${userId}.`}
      </Typography>
    );
  }

  const breadcrumbData = [
    { id: 1, name: 'Employee List', path: '/company/employee/employees-list', isActive: false },
    { id: 2, name: 'Employee Records', path: `/company/employee/view/${userId}`, isActive: false },
    { id: 2, name: 'Terminate Employee', isActive: true },
  ];

  return isLoadingTerminateEmployee ? (
    <Box sx={{ p: 4 }}>
      <Skeleton height={70} />
      <Skeleton />
      <Skeleton />
    </Box>
  ) : (
    <BlankTemplate
      pageHeading={`Terminate Employee: ${get(terminateEmployee, 'fullName', '')} (${get(
        terminateEmployee,
        'profile.employeeId',
        'NA',
      )})`}
      breadcrumbData={breadcrumbData}
      padding="3rem"
    >
      <TerminateEmployee employeeData={employeeData} handleSubmit={handleSubmit} loading={loading} />
    </BlankTemplate>
  );
};

export default TerminateEmployeePage;
