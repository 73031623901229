import React from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import LeaveRequestDetails from 'components/Organisms/Leave/MyLeaves/MyLeaveRequests/LeaveRequestDetails';

const LeaveRequestDetailsTemplate = ({
  leaveDetails,
  handleEdit,
  actionMenuList,
  handleAttachment,
  breadcrumbData,
  pageHeading,
  isEdit,
}) => {
  return (
    <BlankTemplate pageHeading={pageHeading} breadcrumbData={breadcrumbData} padding="2rem 3rem">
      <LeaveRequestDetails
        leaveDetails={leaveDetails}
        handleEdit={handleEdit}
        actionMenuList={actionMenuList}
        handleAttachment={handleAttachment}
        isEdit={isEdit}
      />
    </BlankTemplate>
  );
};

LeaveRequestDetailsTemplate.defaultProps = {
  leaveDetails: {
    employeeId: '',
    employeeName: '',
    leaveCategory: '',
    leaveType: '',
    startDate: '',
    endDate: '',
    numberOfDays: '',
    numberOfHours: '',
    createdOn: '',
    createdBy: '',
    lastModifiedOn: '',
    lastModifiedBy: '',
    approvalDate: '',
    approvedBy: '',
    requestState: '',
    description: '    ',
    reviewComments: '',
    attachment: '',
  },
  handleEdit: () => {},
  actionMenuList: [],
  handleAttachment: () => {},
  breadcrumbData: [],
  pageHeading: 'Leave request details',
  isEdit: true,
};

LeaveRequestDetailsTemplate.propTypes = {
  leaveDetails: PropTypes.shape({
    employeeId: PropTypes.string,
    employeeName: PropTypes.string,
    leaveCategory: PropTypes.string,
    leaveType: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    numberOfDays: PropTypes.string,
    numberOfHours: PropTypes.string,
    createdOn: PropTypes.string,
    createdBy: PropTypes.string,
    lastModifiedOn: PropTypes.string,
    lastModifiedBy: PropTypes.string,
    approvalDate: PropTypes.string,
    approvedBy: PropTypes.string,
    requestState: PropTypes.string,
    description: PropTypes.string,
    reviewComments: PropTypes.string,
    attachment: PropTypes.string,
  }),
  handleEdit: PropTypes.func,
  actionMenuList: PropTypes.arrayOf,
  handleAttachment: PropTypes.func,
  breadcrumbData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
  pageHeading: PropTypes.string,
  isEdit: PropTypes.bool,
};

export default LeaveRequestDetailsTemplate;
