import React, { useEffect } from 'react';
import DocumentDetails from 'components/Templates/Documents/DocumentDetails';
import DeleteDocument from 'components/Organisms/Dialog/ActionList/DeleteDocument';
import { useThunk } from 'hooks/useThunk';
import { fetchMyDocumentDetails } from 'store';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { hasPermission } from 'utils/permissions';
import useMyDocumentService from '../List/useMyDocumentService';
import useMenuItems from './useMenuItems';

const DocumentDetailsPage = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const services = useMyDocumentService();
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasViewPermission = hasPermission(authorities, ['ROLE_BASE_PERMISSION_INDEX_VIEW_DOCUMENT']);

  const breadcrumbData = [
    { id: 1, name: 'My Documents', path: '/home/documents', isActive: false },
    { id: 2, name: 'Details', path: '', isActive: true },
  ];

  const [getDocumentDetails, isLoadingDocumentDetails, loadingDocumentDetailsError] = useThunk(fetchMyDocumentDetails);

  useEffect(() => {
    const query = new URLSearchParams({});
    query.append('id', id);
    getDocumentDetails(query);
  }, [id, getDocumentDetails, services?.refreshDocumentList]);

  const { documentDetails = [], documentOwnerDetails = [] } = useSelector((state) => state.documents);
  const documentOwnerName = documentOwnerDetails?.fullName;

  const handleEdit = () => {
    navigate(`/home/documents/edit/${id}`);
  };

  const [menuItems] = useMenuItems(documentDetails, services);

  if (loadingDocumentDetailsError !== null) {
    return (
      <Typography variant="h6">
        {loadingDocumentDetailsError?.message || 'Error while loading document details.'}
      </Typography>
    );
  }

  if (!userHasViewPermission) {
    return <Typography variant="h6">You do not have permission to view document details</Typography>;
  }

  return (
    <Box>
      <DocumentDetails
        breadcrumbData={breadcrumbData}
        documentDetails={documentDetails}
        documentName={documentDetails?.documentName}
        handleEdit={handleEdit}
        menuList={menuItems}
        documentOwnerName={documentOwnerName}
        loading={isLoadingDocumentDetails}
        handleAttachment={services?.handleAttachment}
      />
      {documentDetails !== null && (
        <DeleteDocument
          open={services.showDeleteDialog}
          handleClose={services.handleDeleteDialog}
          handleCancel={services.handleDeleteDialog}
          handleDelete={() => {
            services.deleteDocument(documentDetails);
          }}
          fileName={documentDetails?.fileName}
        />
      )}
    </Box>
  );
};

export default DocumentDetailsPage;
