export const statusCardStyle = {
  wrapper: {
    display: 'flex',
    gap: 1.5,
    p: 2,
    border: '0.125rem solid',
    borderColor: 'neutral.300',
    borderRadius: '0.625rem',
  },
};
