export const leaveStyle = {
  actionBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  formContainer: {
    width: '100%',
    maxWidth: '100%',
    mt: 0,
    mb: 2.5,
  },

  formDivider: {
    '&:not(:first-child)': {
      borderTop: '0.125rem solid',
      borderColor: 'other.200',
      mt: 4,
      pt: 4,
    },
  },

  textDivider: {
    '&:not(:first-child)': {
      borderTop: '0.125rem solid',
      borderColor: 'other.200',
      mt: 2.5,
      pt: 2.5,
    },
  },

  addButton: {
    my: 3,
  },

  btnContainer: {
    position: 'absolute',
    right: '0rem',
    bottom: '0',
    backgroundColor: 'common.white',
    zIndex: '9',
    margin: '0rem',
    width: '100%',
    boxSizing: 'border-box',
    padding: '0rem 3rem 3.25rem 3rem',
  },

  submitWrapper: {
    display: 'flex',
    justifyContent: 'end',
    gap: 2,
  },
};

export const leaveTableStyle = {
  container: {
    maxHeight: '180px',
    overflow: 'auto',
    border: '1px solid #B7BDCC',
    borderRadius: '1rem',
  },
};

export const leaveAccountBlock = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: 1,
  },
  innerBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 1.5,
    px: 5,
    flex: 1,
    '@media (min-width: 900px)': {
      ':not(:last-child)': {
        borderRight: '1px solid #CDD2DC',
      },
    },
  },
};
