import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import AddAndEditDocument from 'components/Organisms/Document/AddAndEdit';
import { Box, Skeleton } from '@mui/material';
import useToast from 'components/Provider/useToast';
import { getToastMessage } from 'utils/toastMessage';

const AddAndEditDocumentTemplate = ({
  breadcrumbData,
  loading,
  formLoading,
  handleSubmit,
  handleCancel,
  defaultValues,
  documentTypeOptions,
  statusTypeOptions,
  documentOwnerTypeOptions,
  isEdit,
  handleDelete,
  fileName,
  handleDeleteAttachment,
  ownerOptions,
  handleOwnerOptions,
  toastSuccessMessage,
  toastErrorMessage,
  showToast,
  toastType,
  entityOriginPermission,
  documentOwnerEditAllowed,
}) => {
  const addToast = useToast();

  useEffect(() => {
    if (showToast) {
      const successMessage = toastSuccessMessage || getToastMessage.Documents(fileName, isEdit)[0];
      const error = toastErrorMessage || getToastMessage.Documents(fileName, isEdit)[1];

      const title = toastType === 'success' ? 'Success!' : 'Error!';
      const type = toastType === 'success' ? 'success' : 'error';
      const message = toastType === 'success' ? successMessage : error;
      addToast({ type, title, message });
    }
  }, [showToast, toastErrorMessage, toastSuccessMessage, toastType]);

  return (
    <BlankTemplate
      pageHeading={isEdit ? `Edit ${fileName}` : 'Add New Document'}
      isBreadcrumb
      breadcrumbData={breadcrumbData}
      padding="0 3rem 2rem 3rem"
    >
      {formLoading ? (
        <Box sx={{ p: 4 }}>
          <Skeleton height={42} />
          <Skeleton />
          <Skeleton />
        </Box>
      ) : (
        <AddAndEditDocument
          documentOwnerEditAllowed={documentOwnerEditAllowed}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          defaultValues={defaultValues}
          documentTypeOptions={documentTypeOptions}
          statusTypeOptions={statusTypeOptions}
          documentOwnerTypeOptions={documentOwnerTypeOptions}
          isEdit={isEdit}
          handleDelete={handleDelete}
          handleDeleteAttachment={handleDeleteAttachment}
          ownerOptions={ownerOptions}
          handleOwnerOptions={handleOwnerOptions}
          loading={loading}
          entityOriginPermission={entityOriginPermission}
        />
      )}
    </BlankTemplate>
  );
};

AddAndEditDocumentTemplate.defaultProps = {
  breadcrumbData: [
    { id: 1, name: 'Documents', path: '/home/documents', isActive: false },
    { id: 2, name: 'Add', path: '', isActive: true },
  ],
  loading: false,
  formLoading: false,
  documentOwnerEditAllowed: false,
  fileName: '',
  handleSubmit: () => {},
  handleCancel: () => {},
  documentTypeOptions: [],
  statusTypeOptions: [],
  documentOwnerTypeOptions: [],
  defaultValues: {
    documentName: '',
    documentType: '',
    status: '',
    description: '',
    docVersion: '',
    documentOwnerType: '',
    owner: '',
  },
  isEdit: false,
  handleDelete: () => {},
  handleDeleteAttachment: () => {},
  ownerOptions: [],
  handleOwnerOptions: () => {},
  toastSuccessMessage: '',
  toastErrorMessage: '',
  showToast: false,
  toastType: 'success',
  entityOriginPermission: '',
};

AddAndEditDocumentTemplate.propTypes = {
  breadcrumbData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
  documentOwnerEditAllowed: PropTypes.bool,
  formLoading: PropTypes.bool,
  fileName: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  defaultValues: PropTypes.shape({}),
  documentOwnerTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  documentTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  statusTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  isEdit: PropTypes.bool,
  handleDelete: PropTypes.func,
  handleDeleteAttachment: PropTypes.func,
  ownerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  handleOwnerOptions: PropTypes.func,
  toastSuccessMessage: PropTypes.string,
  toastErrorMessage: PropTypes.string,
  showToast: PropTypes.bool,
  toastType: PropTypes.oneOf(['error', 'success']),
  entityOriginPermission: PropTypes.string,
};

export default AddAndEditDocumentTemplate;
