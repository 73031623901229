import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid } from '@mui/material';
import TitleWithText from 'components/Atoms/TitleWithText';
import Typographys from 'components/Atoms/Typography';
import { onBoardingStyle } from '../style';

const BankAccount = ({ bankDetails }) => {
  return (
    <Box px={3}>
      <Typographys variant="h5" color="neutral.700">
        Account Details
      </Typographys>
      {bankDetails.map((list, i) => (
        <Box>
          <Typographys sx={{ mt: 1 }} variant="subtitle2" align="left" color="neutral.800">
            Account {i + 1}
          </Typographys>
          <Box mt={1}>
            <Grid container rowSpacing={1} columnSpacing={2} sx={onBoardingStyle.bankAccountwrapper}>
              <Grid item xs={12} sm={4} md={3}>
                <TitleWithText title="Account Number" text={list.accountNumber} />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <TitleWithText title="Account Name" text={list.accountName} />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <TitleWithText title="Account Type" text={list.accountType} />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <TitleWithText title="Account Currency" text={list.accountCurrency} />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <TitleWithText title="Account Purpose" text={list.purpose} />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <TitleWithText title="Transaction type" text={list.transactionType} />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <TitleWithText title="Bank Name" text={list.bankName} />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <TitleWithText title="Branch name" text={list.branchName} />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <TitleWithText title="IFSC Code" text={list.IFSCCode} />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <TitleWithText title="Swift Code (Optional)" text={list.swiftCode} />
              </Grid>
            </Grid>
          </Box>
          <Grid container rowSpacing={1} columnSpacing={2} sx={onBoardingStyle.bankAccountwrapper}>
            <Grid item xs={12} mt={5} mb={1}>
              <Typographys variant="h5" color="neutral.700">
                Branch Address
              </Typographys>
            </Grid>
            <Grid item xs={12}>
              <TitleWithText title="Location Name (Optional)" text={list?.bankAddress?.locationName} />
            </Grid>
            <Grid item xs={12}>
              <Box mb={0.5}>
                <Typographys color="neutral.600" variant="body2">
                  Address
                </Typographys>
              </Box>
              <Typographys color="neutral.700" variant="body2">
                {list?.bankAddress?.addressLine1}
              </Typographys>
              <Typographys color="neutral.700" variant="body2">
                {list?.bankAddress?.addressLine2}
              </Typographys>
              <Typographys color="neutral.700" variant="body2">
                {list?.bankAddress?.addressLine3}
              </Typographys>
              <Typographys color="neutral.700" variant="body2">
                <Box component="div" sx={onBoardingStyle.addressDiv}>
                  {`${list?.bankAddress?.city},`}
                </Box>

                <Box component="div" sx={onBoardingStyle.addressDiv}>
                  {list?.bankAddress?.state}
                </Box>

                <Box component="div" sx={onBoardingStyle.addressDiv}>
                  {list?.bankAddress?.country}
                </Box>

                <Box component="div" sx={onBoardingStyle.addressDiv}>
                  {list?.bankAddress?.pinOrZip}
                </Box>
              </Typographys>
            </Grid>
          </Grid>
          {i + 1 !== bankDetails.length && <Divider sx={onBoardingStyle.divider} />}
        </Box>
      ))}
    </Box>
  );
};

BankAccount.defaultProps = {
  bankDetails: [],
  addressList: {},
};

BankAccount.propTypes = {
  bankDetails: PropTypes.arrayOf(
    PropTypes.shape({
      accountNumber: PropTypes.string,
      accountName: PropTypes.string,
      accountType: PropTypes.string,
      accountCurrency: PropTypes.string,
      purpose: PropTypes.string,
      transactionType: PropTypes.string,
      bankName: PropTypes.string,
      branchName: PropTypes.string,
      IFSCCode: PropTypes.string,
      swiftCode: PropTypes.string,
    }),
  ),
  addressList: PropTypes.shape({
    location: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    address3: PropTypes.string,
    address4: PropTypes.string,
  }),
};

export default BankAccount;
