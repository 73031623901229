import React from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import SalarySlipList from 'components/Organisms/MyPay/SalarySlips/List';

const SalarySlipListTemplate = ({
  handleFilter,
  filterFields,
  handleDownload,
  loading,
  salarySlipDataColumn,
  salarySlipDataRow,
  keyColumn,
  sortDirection,
  sortBy,
  onSortChange,
  count,
  page,
  onPageChange,
  onRowClick,
  selectedItems,
  onSelect,
  onSelectAll,
}) => {
  return (
    <BlankTemplate pageHeading="Salary slips" isBreadcrumb={false} padding="2rem 3rem">
      <SalarySlipList
        filterFields={filterFields}
        handleFilter={handleFilter}
        handleDownload={handleDownload}
        loading={loading}
        salarySlipDataColumn={salarySlipDataColumn}
        salarySlipDataRow={salarySlipDataRow}
        keyColumn={keyColumn}
        sortDirection={sortDirection}
        sortBy={sortBy}
        onSortChange={onSortChange}
        count={count}
        page={page}
        onPageChange={onPageChange}
        showPagination
        onRowClick={onRowClick}
        showRowSelector
        selectedItems={selectedItems}
        onSelect={onSelect}
        onSelectAll={onSelectAll}
      />
    </BlankTemplate>
  );
};

SalarySlipListTemplate.defaultProps = {
  filterFields: [],
  handleFilter: () => {},
  handleDownload: () => {},
  loading: false,
  salarySlipDataColumn: [],
  salarySlipDataRow: [],
  keyColumn: 'id',
  sortDirection: 'desc',
  sortBy: 'id',
  onSortChange: () => {},
  count: 10,
  page: null,
  onPageChange: () => {},
  onRowClick: () => {},
  selectedItems: [],
  onSelect: () => {},
  onSelectAll: () => {},
};

SalarySlipListTemplate.propTypes = {
  filterFields: PropTypes.arrayOf,
  handleFilter: PropTypes.func,
  handleDownload: PropTypes.func,
  loading: PropTypes.bool,
  salarySlipDataColumn: PropTypes.arrayOf,
  salarySlipDataRow: PropTypes.arrayOf,
  keyColumn: PropTypes.string,
  sortDirection: PropTypes.string,
  sortBy: PropTypes.string,
  onSortChange: PropTypes.func,
  count: PropTypes.number,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  onRowClick: PropTypes.func,
  selectedItems: PropTypes.arrayOf,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
};

export default SalarySlipListTemplate;
