import React from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import ExpensesList from 'components/Organisms/MyExpenses/MyExpensesReportsTabs/ExpensesList';

const MyTeamExpensesListTemplate = ({
  rows,
  page,
  count,
  onPageChange,
  showPagination,
  handleSelect,
  handleAdd,
  columns,
  handleFilter,
  showActionList,
  pageHeading,
  roleBasedMenu,
  sortDirection,
  sortBy,
  onSortChange,
  filterFields,
  loading,
  handleFiltersClear,
  showFilter,
}) => {
  return (
    <BlankTemplate pageHeading={pageHeading} isBreadcrumb={false} padding="0">
      <ExpensesList
        loading={loading}
        rows={rows}
        columns={columns}
        handleSelect={handleSelect}
        handleAdd={handleAdd}
        handleFilter={handleFilter}
        page={page}
        count={count}
        onPageChange={onPageChange}
        showPagination={showPagination}
        showActionList={showActionList}
        roleBasedMenu={roleBasedMenu}
        sortDirection={sortDirection}
        sortBy={sortBy}
        onSortChange={onSortChange}
        filterFields={filterFields}
        handleFiltersClear={handleFiltersClear}
        showFilter={showFilter}
      />
    </BlankTemplate>
  );
};

MyTeamExpensesListTemplate.defaultProps = {
  filterFields: [],
  sortDirection: 'desc',
  sortBy: 'id',
  onSortChange: () => {},
  roleBasedMenu: () => {},
  pageHeading: '',
  showActionList: false,
  showPagination: false,
  onPageChange: () => {},
  handleAdd: () => {},
  handleFilter: () => {},
  handleFiltersClear: () => {},
  rows: [],
  columns: [],
  handleSelect: () => {},
  page: 1,
  loading: false,
  showFilter: true,
};

MyTeamExpensesListTemplate.propTypes = {
  filterFields: PropTypes.arrayOf,
  onSortChange: PropTypes.func,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  roleBasedMenu: PropTypes.func,
  pageHeading: PropTypes.string,
  showActionList: PropTypes.bool,
  showPagination: PropTypes.bool,
  onPageChange: PropTypes.func,
  count: PropTypes.number.isRequired,
  handleAdd: PropTypes.func,
  handleFilter: PropTypes.func,
  handleFiltersClear: PropTypes.func,
  handleSelect: PropTypes.func,
  page: PropTypes.number,
  rows: PropTypes.arrayOf,
  columns: PropTypes.arrayOf,
  loading: PropTypes.bool,
  showFilter: PropTypes.bool,
};

export default MyTeamExpensesListTemplate;
