import { formDataApi } from 'api/api';

export const getAlumniDashboardDetails = async () => {
  const response = await formDataApi.get(`RestSentinel/alumniDashboard`);
  return response;
};

export const getAttendanceRecords = async () => {
  const response = await formDataApi.get(`RestSentinel/myLeaveDashboard`);
  return response;
};

export const getMyReportings = async () => {
  const response = await formDataApi.get(`/restEmpIndex/myUpwardHierarchy`);
  return response;
};

export const getEmployeeReportees = async (query) => {
  const response = await formDataApi.get(`/restEmpIndex/employeeReportees?${query.toString()}`);
  return response;
};

export const getEmployeeUpwardHierarchy = async (query) => {
  const response = await formDataApi.get(`/restEmpIndex/employeeUpwardHierarchy?${query.toString()}`);
  return response;
};
