import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import LeaveBalanceCard from 'components/Organisms/Leave/MyLeaves/MyLeaveRequests/LeaveBalance/LeaveBalanceCard';
import ListingTable from 'components/Organisms/ListingTable';

const LeaveRequests = ({
  rows,
  columns,
  roleBasedMenu,
  page,
  sortDirection,
  sortBy,
  handleSelect,
  handleAddRequest,
  leaveBalance,
  chartData,
  handleRowClick,
  actions,
  showFilter,
  handleFilter,
  onSortChange,
  showActionList,
  handleFiltersClear,
  filterFields,
  loading,
  showPagination,
  onPageChange,
  count,
  userHasCreateLeavePermission,
}) => {
  return (
    <Box>
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        {leaveBalance.map((item) => {
          return (
            <LeaveBalanceCard
              title={item.title}
              totalRecordCount={item.totalCount}
              count={item.available}
              data={chartData}
            />
          );
        })}
      </Box>
      <ListingTable
        handleFiltersClear={handleFiltersClear}
        rows={rows}
        columns={columns}
        roleBasedMenu={roleBasedMenu}
        onRowClick={handleRowClick}
        page={page}
        sortDirection={sortDirection}
        sortBy={sortBy}
        handleSelect={handleSelect}
        handleAddRequest={handleAddRequest}
        actions={actions}
        handleFilter={handleFilter}
        showFilter={showFilter}
        onSortChange={onSortChange}
        showActionList={showActionList}
        filterFields={filterFields}
        isDynamic
        addButtonText="Add New Request"
        showAddButton={userHasCreateLeavePermission}
        loading={loading}
        showPagination={showPagination}
        onPageChange={onPageChange}
        count={count}
      />
    </Box>
  );
};

LeaveRequests.defaultProps = {
  rows: [],
  columns: [],
  handleAddRequest: () => {},
  handleSelect: () => {},
  sortBy: () => {},
  page: 1,
  sortDirection: 'asc',
  roleBasedMenu: () => {},
  leaveBalance: [],
  chartData: [],
  filterFields: [],
  actions: true,
  showFilter: false,
  handleFilter: () => {},
  onSortChange: () => {},
  handleRowClick: () => {},
  showActionList: true,
  loading: false,
  handleFiltersClear: () => {},
  showPagination: false,
  onPageChange: () => {},
  count: 0,
};

LeaveRequests.propTypes = {
  handleAddRequest: PropTypes.func,
  handleSelect: PropTypes.func,
  sortBy: PropTypes.func,
  page: PropTypes.number,
  sortDirection: PropTypes.string,

  columns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      numeric: PropTypes.bool,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),

  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      leaveCategory: PropTypes.string,
      leaveType: PropTypes.string,
      status: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }),
  ),

  roleBasedMenu: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),

  leaveBalance: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      totalCount: PropTypes.string,
      available: PropTypes.string,
    }),
  ),

  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    }),
  ),

  actions: PropTypes.bool,
  showFilter: PropTypes.bool,
  handleFilter: PropTypes.func,
  onSortChange: PropTypes.func,
  handleRowClick: PropTypes.func,
  showActionList: PropTypes.bool,
  filterFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
  handleFiltersClear: PropTypes.func,
  showPagination: PropTypes.bool,
  count: PropTypes.number,
  onPageChange: PropTypes.func,
};
export default LeaveRequests;
