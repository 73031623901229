export const commonChartStyle = {
  chartWrapper: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    lineHeight: '160%',
    fontSize: '1.5rem',
    letterSpacing: '0.019rem',

    '.recharts-legend-wrapper': {
      transform: 'translateY(30px)',
    },

    '.legend-wrapper': {
      fontSize: '1.75rem',
      lineHeight: '160%',
      display: 'flex',
      justifyContent: 'center',
      color: 'neutral.800',
      columnGap: '3rem',
      padding: 0,
      margin: 0,

      li: {
        listStyle: 'none',
        display: 'flex',
        alignItems: 'center',
        columnGap: '1.5rem',

        '.legend-icon': {
          width: '1.5rem',
          height: '1.5rem',
          borderRadius: '50%',
        },
      },
    },
  },

  legend: {
    fontSize: '1.75rem',
    right: 0,
  },
};
