/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import ViewOffboardingTemplate from 'components/Templates/Employee/Company/Separation/ViewOffboardingTemplate';
import { useThunk } from 'hooks/useThunk';
import { fetchViewOffBoardingCheckList } from 'store';
import { useSelector } from 'react-redux';
import { Box, Skeleton, Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { validateDateElseHyphen } from 'utils/commonUtils';
import useExitListService from './useExitListService';
import useColumns from './useColumns';
import useActionItems from './useActionItems';

const ExitTaskList = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const services = useExitListService();
  const [columns] = useColumns();
  const [handleActionItemClick] = useActionItems(services);

  const [getViewOffBoardingCheckList, isLoadingOffbaoardingList, loadingOffboardingListError] =
    useThunk(fetchViewOffBoardingCheckList);
  useEffect(() => {
    const formData = new URLSearchParams();
    formData.append('id', id);
    getViewOffBoardingCheckList(formData);
  }, [id, getViewOffBoardingCheckList]);

  const { offBoardingCheckList } = useSelector((state) => state.separations);

  const exitDetails = {
    employeeName: offBoardingCheckList?.employee?.fullName,
    employeeId: offBoardingCheckList?.employee?.profile?.employeeID,
    supervisor: offBoardingCheckList?.employee?.supervisor?.fullName,
    dateOfJoining: validateDateElseHyphen(offBoardingCheckList?.employee?.profile?.dateOfJoining, 'DD MMM YYYY'),
    dateOfLeaving: validateDateElseHyphen(offBoardingCheckList?.employee?.profile?.dateOfLeaving, 'DD MMM YYYY'),
    listCompletionStatus: offBoardingCheckList?.simpleTaskListInstance?.completionStatus,
  };

  const handleEdit = (taskId) => {
    navigate(`/myteam/separations/edit/${taskId}`);
  };

  if (isLoadingOffbaoardingList) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (loadingOffboardingListError !== null) {
    return (
      <Typography variant="h6">
        {loadingOffboardingListError?.message || 'Error while loading employee exit details.'}
      </Typography>
    );
  }

  const fetchOffboardingDetails = (data) => {
    return !isEmpty(data?.tasks)
      ? data?.tasks?.map((detail, index) => ({
          taskDescription: detail?.taskDescription,
          taskName: detail?.taskName,
          owner: detail?.owner?.fullName,
          createdOn: validateDateElseHyphen(detail?.createdOn, 'DD MMM, YYYY'),
          dueBy: validateDateElseHyphen(detail?.dueBy, 'DD MMM, YYYY'),
          completionStatus: detail?.completionStatus,
          id: detail?.id,
          index: data?.tasks?.length > 0 && index + 1,
        }))
      : [];
  };

  const tables = !isEmpty(offBoardingCheckList)
    ? offBoardingCheckList?.simpleTaskListInstance?.children?.length
      ? offBoardingCheckList?.simpleTaskListInstance?.children?.map((data) => ({
          taskListName: data?.taskListName,
          details: fetchOffboardingDetails(data),
        }))
      : offBoardingCheckList?.simpleTaskListInstance?.tasks?.length
      ? offBoardingCheckList?.simpleTaskListInstance?.tasks?.map((data) => ({
          taskListName: '',
          rows: data.tasks?.length ? data?.tasks?.map((task, taskIndex) => ({ ...task, index: taskIndex + 1 })) : [],
        }))
      : []
    : [];

  return (
    <Box>
      <ViewOffboardingTemplate
        handleDelete={services.handleDeleteDialog}
        handleEdit={handleEdit}
        exitDetails={exitDetails}
        loading={isLoadingOffbaoardingList}
        pageHeading="Employee Exit Details"
        {...{
          tables,
          columns,
          keyColumn: 'id',
          roleBasedMenu: handleActionItemClick,
        }}
      />
    </Box>
  );
};

export default ExitTaskList;
