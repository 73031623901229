/* eslint-disable no-useless-concat */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import FormData from 'form-data';
import dayjs from 'dayjs';

import Timeline from 'components/Organisms/Employee/EmployeeRecord/EmployeeRecordTab/Timeline';
import { displayEmployeeTimeline, displaySalaryTimeline, displayTotalTimeline } from 'api/employees';

const formatRecord = (empRecInstList) => {
  let displayEmpRecBaseDetails = false;
  let displayLevelInfo = true;
  let entType = '';
  let empChangeRecNotes = '';
  let displayEmpRecDateOfChange = false;
  const record = {
    date: '',
    createdByName: '',
    effectiveStartDate: '',
    effectiveEndDate: '',
    oldRecValue: 0,
    newRecValue: 0,
    note: '',
    title: '',
  };
  empRecInstList?.forEach((empChangeRecord) => {
    empChangeRecNotes = empChangeRecord.notes;
    let oldRecValue = empChangeRecord.oldRecordName;
    let newRecValue = empChangeRecord.newRecordName;

    if (!displayEmpRecDateOfChange) {
      record.date = empChangeRecord.recordCreationDate || '';
      displayEmpRecDateOfChange = true;
    }

    if (!displayEmpRecBaseDetails) {
      record.createdByName = `${empChangeRecord?.createdBy?.fullName || ''}`;

      if (empChangeRecord.effectiveStartDate) {
        record.effectiveStartDate = `Effected From: ${empChangeRecord?.effectiveStartDate || ''}`;
      }
      if (empChangeRecord.effectiveEndDate) {
        record.effectiveEndDate = `Effected To: ${empChangeRecord?.effectiveEndDate || ''}`;
      }
      displayEmpRecBaseDetails = true;
    }

    switch (empChangeRecord.changeSubType) {
      case 'Supervisor Changed':
        entType = 'Supervisor';
        break;
      case 'Department Changed':
        entType = 'Department';
        break;
      case 'Designation Changed':
        entType = 'Designation';
        break;
      case 'Location Changed':
        entType = 'Location';
        break;
      case 'Grade Changed':
        entType = 'Grade';
        break;
      case 'Level Changed':
        entType = 'Level';
        break;
      case 'Division Changed':
        entType = '';
        break;
      case 'Miscellaneous':
        entType = 'Level';
        displayLevelInfo = false;
        break;
      case 'Employee CTC Revision':
        entType = 'Employee CTC';
        oldRecValue = empChangeRecord?.oldValue || 0;
        newRecValue = empChangeRecord?.newValue || 0;
        break;
      case 'Company CTC Revision':
        entType = 'Company CTC';
        oldRecValue = empChangeRecord?.oldValue || 0;
        newRecValue = empChangeRecord?.newValue || 0;
        break;

      default:
        displayLevelInfo = false;
        break;
    }
    if (displayLevelInfo) {
      // eslint-disable-next-line max-len
      record.displayLevelInfo1 = `Previous ${entType}: ${oldRecValue}`;
      record.displayLevelInfo2 = `New ${entType}: ${newRecValue}`;
    }
    record.note = empChangeRecNotes || '';
    record.title = empChangeRecord?.changeType || '';
  });
  return record;
};

const formatTimeline = ({ sortedDatesList, creationDateWiseEmpRecsMap }) => {
  const listItems = sortedDatesList?.map((item) => formatRecord(creationDateWiseEmpRecsMap[item]));
  return listItems;
};

const EmployeeTimeLine = () => {
  const { userId } = useParams();
  const [timeline, setTimeline] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const bodyFormData = new FormData();
  bodyFormData.append('id', userId);
  bodyFormData.append('startDate', startDate);
  bodyFormData.append('endDate', endDate);

  const handleEmpTimelineOnFetch = async () => {
    setTimeline([]);
    const response = await displayEmployeeTimeline(bodyFormData);
    if (response.data) {
      setTimeline(formatTimeline(response.data));
    }
  };

  const handleTotalTimelineOnFetch = async () => {
    setTimeline([]);
    const response = await displayTotalTimeline(bodyFormData);
    if (response.data) {
      setTimeline(formatTimeline(response.data));
    }
  };

  const handleSalaryTimelineOnFetch = async () => {
    setTimeline([]);
    const response = await displaySalaryTimeline(bodyFormData);
    if (response.data) {
      setTimeline(formatTimeline(response.data));
    }
  };

  const handleStartDateChange = (item) => {
    setStartDate(dayjs(item).format('DD/MM/YYYY'));
  };

  const handleEndDateChange = (item) => {
    setEndDate(dayjs(item).format('DD/MM/YYYY'));
  };

  return (
    <Timeline
      empTimelineTitle={`Showing result from ${startDate} to ${endDate}`}
      empTimelineStartDateChange={handleStartDateChange}
      empTimelineEndDateChange={handleEndDateChange}
      empTimeline={timeline}
      empTimelineOnFetch={handleEmpTimelineOnFetch}
      totalTimelineTitle={`Showing result from ${startDate} to ${endDate}`}
      totalTimelineStartDateChange={handleStartDateChange}
      totalTimelineEndDateChange={handleEndDateChange}
      totalTimeline={timeline}
      totalTimelineOnFetch={handleTotalTimelineOnFetch}
      salaryTimelineTitle={`Showing result from ${startDate} to ${endDate}`}
      salaryTimelineStartDateChange={handleStartDateChange}
      salaryTimelineEndDateChange={handleEndDateChange}
      salaryTimeline={timeline}
      salaryTimelineOnFetch={handleSalaryTimelineOnFetch}
    />
  );
};

export default EmployeeTimeLine;
