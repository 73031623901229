import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import Button from 'components/Atoms/Button';
import Avatars from 'components/Molecules/Avatar';
import { iconWithTitleAndDescStyle } from './style';

const IconWithTitleAndDesc = ({
  avatarSrc,
  isIcon,
  iconSrc,
  iconWidth,
  iconHeight,
  iconAlt,
  badge,
  badgeColor,
  badgeTop,
  badgeBottom,
  badgeLeft,
  badgeRight,
  isTitle,
  title,
  isDesc,
  desc,
  isTime,
  time,
  isButton,
  buttonText,
  handleDetails,
}) => {
  return (
    <Box sx={iconWithTitleAndDescStyle.iconWithTitleWrapper}>
      <Avatars
        avatarSrc={avatarSrc}
        isIcon={isIcon}
        iconSrc={iconSrc}
        iconWidth={iconWidth}
        iconHeight={iconHeight}
        iconAlt={iconAlt}
        badge={badge}
        badgeColor={badgeColor}
        sx={{ backgroundColor: 'secondary.400' }}
        badgeTop={badgeTop}
        badgeBottom={badgeBottom}
        badgeLeft={badgeLeft}
        badgeRight={badgeRight}
      />
      <Box>
        {isTitle && <Typographys variant="h6">{title}</Typographys>}
        {isDesc && <Typographys variant="body2">{desc} </Typographys>}
        {isTime && (
          <Typographys variant="caption" display="block" color="neutral.600">
            {time}
          </Typographys>
        )}
        {isButton && (
          <Button variant="text" color="secondary" onClick={handleDetails}>
            {buttonText}
          </Button>
        )}
      </Box>
    </Box>
  );
};

IconWithTitleAndDesc.defaultProps = {
  avatarSrc: '',
  isIcon: true,
  iconSrc: 'images/icons/surveys-icon.svg',
  iconWidth: 24,
  iconHeight: 24,
  iconAlt: 'email',
  badge: true,
  badgeColor: 'error.400',
  badgeTop: '0',
  badgeBottom: '',
  badgeLeft: '0',
  badgeRight: '',
  isTitle: true,
  title: '',
  isDesc: true,
  desc: '',
  isTime: true,
  time: '',
  isButton: true,
  buttonText: 'View details',
  handleDetails: () => {},
};

IconWithTitleAndDesc.propTypes = {
  avatarSrc: PropTypes.string,
  isIcon: PropTypes.bool,
  iconSrc: PropTypes.string,
  iconWidth: PropTypes.number,
  iconHeight: PropTypes.number,
  iconAlt: PropTypes.string,
  badge: PropTypes.bool,
  badgeColor: PropTypes.string,
  badgeTop: PropTypes.string,
  badgeBottom: PropTypes.string,
  badgeLeft: PropTypes.string,
  badgeRight: PropTypes.string,
  isTitle: PropTypes.bool,
  title: PropTypes.string,
  isDesc: PropTypes.bool,
  desc: PropTypes.string,
  isTime: PropTypes.bool,
  time: PropTypes.string,
  isButton: PropTypes.bool,
  buttonText: PropTypes.string,
  handleDetails: PropTypes.func,
};

export default IconWithTitleAndDesc;
