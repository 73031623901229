import React from 'react';
import { Box, Card, CardContent, Container, Grid, Stack, Skeleton } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import Button from 'components/Atoms/Button';
import Typography from 'components/Atoms/Typography';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from 'react-redux';
import { hasPermission } from 'utils/permissions';
import ViewExitTaskDetails from './ViewExitTaskDetails';
import useFetchTask from './useFetchTask';

const ViewExitTask = () => {
  const { taskId, id } = useParams();
  const [task] = useFetchTask(taskId);
  const navigate = useNavigate();

  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasEditPermission =
    hasPermission(authorities, ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_TASK']) &&
    task.data?.simpleTaskInstance?.completionStatus === 'Open';

  if (task.error !== null) {
    return (
      <Typography variant="h6">
        {task?.error?.response?.data?.message || `Error while loading task details for id : ${taskId}.`}
      </Typography>
    );
  }

  return (
    <Container maxWidth="lg">
      <Grid container direction="column">
        <Grid item container justifyContent="flex-start" alignItems="center" sx={{ pb: 1.5 }}>
          <Grid item>
            <Typography variant="h6" color="text.primary">
              OffBoarding Tasks details
            </Typography>
          </Grid>
        </Grid>
        <Grid item sx={{ maxWidth: '100% !important' }}>
          <Card variant="outlined" square>
            <CardContent>
              <Stack direction="row" gap={2} justifyContent="space-between" sx={{ pb: 2 }}>
                <Typography variant="h6" color="text.300">
                  {task.data?.simpleTaskInstance?.taskName}
                </Typography>
                {!!userHasEditPermission && (
                  <Stack direction="row" gap={2} justifyContent="end">
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => navigate(`/myteam/separations/exit-list/${id}/edit/${taskId}`)}
                      startIcon={<EditIcon fontSize="small" />}
                    >
                      Edit
                    </Button>
                  </Stack>
                )}
              </Stack>
              {task.isLoading ? (
                <Box sx={{ py: 4 }}>
                  <Skeleton height={42} />
                  <Skeleton />
                  <Skeleton />
                </Box>
              ) : (
                <ViewExitTaskDetails task={task.data?.simpleTaskInstance} />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ViewExitTask;
