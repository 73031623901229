import { useEffect, useReducer } from 'react';
import { viewMyOnboardingRecordAction } from 'api/employees';

const initialState = {
  isLoading: false,
  data: {},
  error: null,
};

const reducer = (state, action) => {
  const { type, payload } = action;
  let newState;
  switch (type) {
    case 'pending': {
      newState = { ...state, isLoading: true };
      break;
    }
    case 'resolved': {
      newState = { ...state, isLoading: false, data: payload };
      break;
    }
    case 'rejected': {
      newState = { ...state, isLoading: false, error: payload };
      break;
    }
    default: {
      newState = state;
    }
  }
  return newState;
};

const useFetchRecord = () => {
  const [employee, dispatch] = useReducer(reducer, initialState);

  const fetchRecord = async () => {
    dispatch({ type: 'pending' });
    try {
      const response = await viewMyOnboardingRecordAction();
      if (response) {
        dispatch({ type: 'resolved', payload: response });
      }
    } catch (error) {
      dispatch({ type: 'rejected', payload: error });
    }
  };

  return [employee, fetchRecord];
};

export default useFetchRecord;
