export const commonListStyle = {
  wrapper: {
    '& .MuiListItem-root': {
      paddingLeft: '1.5rem',
      margin: '1.25rem 0',
      '&:after': {
        content: "''",
        position: 'absolute',
        left: '0',
        top: '1rem',
        height: '5px',
        width: '5px',
        opacity: 1,
        borderRadius: '1rem',
        backgroundColor: 'common.black',
      },
      '& .MuiListItemText-root': {
        margin: '0',
      },
    },
  },
};
