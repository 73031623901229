import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import TableExtended from 'components/Molecules/TableExtended';
import Chips from 'components/Molecules/Chip';
import { statusColor } from 'utils/statusColor';
import Button from 'components/Atoms/Button';

const Attachments = ({
  loading,
  attachmentDataRow,
  attachmentDataColumns,
  page,
  sortDirection,
  sortBy,
  handleSort,
  count,
  onPageChange,
  onSortChange,
  handleAttachment,
}) => {
  return (
    <Box px={3}>
      <TableExtended
        rows={attachmentDataRow.map((item) => {
          return {
            ...item,
            approvalStatus: (
              <Chips label={item.approvalStatus} isShowIcon={false} color={statusColor(item.approvalStatus)} />
            ),
            attachment: (
              <Button
                variant="text"
                size="small"
                color="secondary"
                onClick={() => {
                  handleAttachment(item?.docId, item?.attachment);
                }}
              >
                {item.attachment}
              </Button>
            ),
          };
        })}
        loading={loading}
        columns={attachmentDataColumns}
        showActionList={false}
        showCheckbox={false}
        count={count}
        sortBy={sortBy}
        sortDirection={sortDirection}
        showPagination={false}
        page={page}
        showRowSelector={false}
      />
    </Box>
  );
};

Attachments.defaultProps = {
  loading: false,
  attachmentDataRow: [],
  attachmentDataColumns: [],
  handleSort: () => {},
  sortBy: () => {},
  sortDirection: 'asc',
  count: 10,
  page: '',
  onPageChange: () => {},
  onSortChange: () => {},
  handleAttachment: () => {},
};

Attachments.propTypes = {
  loading: PropTypes.shape,
  attachmentDataRow: PropTypes.arrayOf,
  attachmentDataColumns: PropTypes.arrayOf,
  sortBy: PropTypes.func,
  handleSort: PropTypes.func,
  sortDirection: PropTypes.string,
  count: PropTypes.number,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  onSortChange: PropTypes.func,
  handleAttachment: PropTypes.func,
};
export default Attachments;
