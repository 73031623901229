import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@mui/material';
import TitleWithText from 'components/Atoms/TitleWithText';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Typographys from 'components/Atoms/Typography';

const SocialData = ({ socialData }) => {
  return (
    <PerfectScrollbar>
      <Box sx={{ maxHeight: 'calc(100vh - 44rem)' }}>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} mb={1}>
            <Typographys variant="h5" color="neutral.700">
              Social Data
            </Typographys>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TitleWithText title="Skype ID" text={socialData.skypeID} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TitleWithText title="Facebook ID" text={socialData.facebookID} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TitleWithText title="Linkedin ID" text={socialData.linkedinID} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TitleWithText title="GooglePlus ID" text={socialData.googlePlusID} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TitleWithText title="Twitter ID" text={socialData.twitterID} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TitleWithText title="Instagram ID" text={socialData.instagramID} />
          </Grid>
        </Grid>
      </Box>
    </PerfectScrollbar>
  );
};

SocialData.defaultProps = {
  socialData: {
    skypeID: 'Uday@kumar',
    facebookID: 'Uday.kumar',
    linkedinID: 'Uday.kumar',
    googlePlusID: 'Uday.kumar',
    twitterID: 'Uday.kumar',
    instagramID: 'Uday.kumar',
  },
};

SocialData.propTypes = {
  socialData: PropTypes.shape({
    skypeID: PropTypes.string,
    facebookID: PropTypes.string,
    linkedinID: PropTypes.string,
    googlePlusID: PropTypes.string,
    twitterID: PropTypes.string,
    instagramID: PropTypes.string,
  }),
};

export default SocialData;
