import React, { useCallback, useEffect } from 'react';
import DocumentList from 'components/Templates/Documents/DocumentList';
import { useThunk } from 'hooks/useThunk';
import { fetchTeamDocumentList, fetchSearchTeamDocumentList } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  updateSelectedDocument,
  updateSelectedDocuments,
  updateAllSelectedDocuments,
} from 'store/slices/documents/documents';
import DeleteDocument from 'components/Organisms/Dialog/ActionList/DeleteDocument';
import useController from 'hooks/useController';
import usePaginationAttributes from 'hooks/use-pagination-attributes';
import { postListApiFormat, getListApiFormat } from 'utils/ApiPayloadFormat';
import { isArray, isEmpty } from 'lodash';
import { hasPermission } from 'utils/permissions';
import useColumns from './useColumns';
import useActionItems from './useActionItems';
import useDocumentService from './useDocumentService';
import useFilters from './useFilters';

const TeamDocumentsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [columns] = useColumns();
  const services = useDocumentService();
  const [controller, handlePageChange, handleSortChange, handleFiltersApply, handleFiltersClear] = useController();
  const { pageSize } = usePaginationAttributes();
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasViewPermission = hasPermission(authorities, ['ROLE_BASE_PERMISSION_INDEX_VIEW_DOCUMENT']);
  const userHasListPermission = hasPermission(authorities, ['ROLE_BASE_PERMISSION_INDEX_LIST_MY_ORG_DOCUMENTS']);
  const userHasFilterPermission = hasPermission(authorities, ['ROLE_BASE_PERMISSION_INDEX_SEARCH_MY_ORG_DOCUMENTS']);

  const [getDocumentList, isLoadingDocumentList, loadingDocumentListError] = useThunk(fetchTeamDocumentList);
  const [getSearchDocumentList, isLoadingSearchDocumentList, errorSearchDocumentList] =
    useThunk(fetchSearchTeamDocumentList);

  const fetchDocumentListAction = useCallback(async () => {
    if (controller?.filters && Object.keys(controller?.filters).length > 0) {
      const formData = postListApiFormat(controller, pageSize);
      formData.append('docOwnerType', 'Employee');
      getSearchDocumentList(formData);
    } else {
      const queryData = getListApiFormat(controller, pageSize);
      getDocumentList(queryData);
    }
  }, [controller, pageSize, getSearchDocumentList, getDocumentList]);

  useEffect(() => {
    fetchDocumentListAction();
  }, [fetchDocumentListAction, pageSize, services?.refreshDocumentList]);

  const {
    selectedDocument = [],
    selectedDocuments = [],
    docOwnerList = [],
    documents = [],
    documentTypes = [],
  } = useSelector((state) => state?.documents);

  const filterService = useFilters(controller, documentTypes);

  const documentsList =
    isArray(documents?.data) && !isEmpty(documents?.data)
      ? documents?.data?.map((item) => {
          const ownerName = docOwnerList[item?.ownerID];
          return { ...item, documentOwner: ownerName?.fullName || '' };
        })
      : [];

  const handleAdd = () => {
    navigate('/myteam/documents/add');
  };

  const handleSelect = (event, rowId) => {
    dispatch(updateSelectedDocuments({ isChecked: event.target.checked, rowId }));
  };

  const handleSelectAll = (event) => {
    dispatch(updateAllSelectedDocuments({ isChecked: event.target.checked }));
  };

  const [handleActionItemClick] = useActionItems(services);

  if (loadingDocumentListError !== null || errorSearchDocumentList !== null) {
    return (
      <Typography variant="h6">{loadingDocumentListError?.message || 'Error while loading documents list.'}</Typography>
    );
  }

  if (!userHasListPermission) {
    return <Typography variant="h6">You do not have permission to view documents list.</Typography>;
  }

  return (
    <Box>
      <DocumentList
        loading={isLoadingDocumentList || isLoadingSearchDocumentList}
        columns={columns}
        rows={documentsList}
        count={documents?.count}
        selectedItems={selectedDocuments}
        onSelect={handleSelect}
        onSelectAll={handleSelectAll}
        showPagination
        onPageChange={handlePageChange}
        onSortChange={handleSortChange}
        handleRowClick={(row) => !!userHasViewPermission && navigate(`/myteam/documents/view/${row?.id}`)}
        page={controller?.page}
        keyColumn="id"
        showActionList
        sortBy={controller?.sortBy}
        sortDirection={controller?.sort}
        roleBasedMenu={handleActionItemClick}
        handleSelect={(row) => dispatch(updateSelectedDocument(row))}
        onSelectedChange={() => {}}
        handleAdd={handleAdd}
        pageHeading="Team Documents"
        filterFields={filterService?.filterFields}
        handleFilter={(data) => handleFiltersApply(data)}
        handleFiltersClear={handleFiltersClear}
        showFilter={!!userHasFilterPermission}
      />
      {selectedDocument !== null && (
        <DeleteDocument
          open={services?.showDeleteDialog}
          handleClose={services?.handleDeleteDialog}
          handleCancel={services?.handleDeleteDialog}
          handleDelete={() => services?.deleteDocument(selectedDocument)}
          fileName={selectedDocument?.fileName}
        />
      )}
    </Box>
  );
};

export default TeamDocumentsList;
