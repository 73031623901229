import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import Typographys from 'components/Atoms/Typography';
import { Box, useTheme } from '@mui/material';
import CakeIcon from '@mui/icons-material/Cake';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import Toolbar from './Toolbar';
import Dialog from './Dialog';
import { calendarStyle } from './style';

const localizer = momentLocalizer(moment);

const Event = ({ event }) => {
  return (
    <span>
      {event.title}
      <br />
      {event.desc}
    </span>
  );
};

const Calendar = ({ events }) => {
  const theme = useTheme();
  function getShowMore(total) {
    return <Typographys color={theme.palette.secondary[600]}>{`+${total}`}</Typographys>;
  }

  const [open, setOpen] = useState(false);
  const [eventData, setEventData] = useState({});

  return (
    <>
      <Box sx={calendarStyle.bigCalendar}>
        <BigCalendar
          localizer={localizer}
          events={events.map((event) => ({
            ...event,
            desc: (
              <Box>
                {event.type === 'birthday' && <CakeIcon fontSize="3rem" />}
                {event.type === 'leaves' && <BeachAccessIcon fontSize="3rem" />}
                {event.desc}
              </Box>
            ),
          }))}
          tooltipAccessor={(event) => event?.title}
          defaultDate={new Date()}
          popup
          popupOffset={{ x: -10, y: -150 }}
          onSelectEvent={(event) => {
            if (event.type !== 'holiday') {
              setEventData(event);
              setOpen(true);
            }
          }}
          eventPropGetter={(event) => ({
            style: {
              backgroundColor: event.color,
              color: event.textColor,
              border: event.border,
              borderRadius: '0.75rem',
            },
          })}
          components={{
            toolbar: Toolbar,
            event: Event,
          }}
          messages={{
            showMore: getShowMore,
          }}
          views={['month', 'week', 'day']}
        />
      </Box>
      <Dialog
        open={open}
        handleClose={() => {
          setOpen(false);
          setEventData({});
        }}
        handleCancel={() => {
          setOpen(false);
          setEventData({});
        }}
        data={{ ...eventData }}
        type={eventData?.type}
      />
    </>
  );
};
Calendar.defaultProps = {
  events: [],
};
Calendar.propTypes = {
  events: PropTypes.shape([]),
};

Event.defaultProps = {
  event: {
    title: '',
    desc: '',
  },
};
Event.propTypes = {
  event: PropTypes.shape({
    title: PropTypes.string,
    desc: PropTypes.string,
  }),
};
export default Calendar;
