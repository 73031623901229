import { createSlice } from '@reduxjs/toolkit';

import { fetchCompareTDS, fetchViewTDS, fetchModifyViewTDS } from 'store/thunks/home/myPay/compareTDS';

const compareTDS = createSlice({
  name: 'compareTDS',
  initialState: {
    compareTDSDetails: [],
    viewTDSData: {
      loading: false,
      tdsDetails: [],
      error: '',
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchCompareTDS.fulfilled, (state, action) => {
      state.compareTDSDetails = action.payload;
    });
    builder.addCase(fetchViewTDS.pending, (state) => {
      state.viewTDSData.loading = true;
    });
    builder.addCase(fetchViewTDS.fulfilled, (state, action) => {
      state.viewTDSData = {
        loading: false,
        tdsDetails: action.payload,
        error: '',
      };
    });
    builder.addCase(fetchViewTDS.rejected, (state, action) => {
      state.viewTDSData = {
        loading: false,
        tdsDetails: [],
        error: action.error.message,
      };
    });
    builder.addCase(fetchModifyViewTDS.fulfilled, (state, action) => {
      state.viewTDSData = {
        loading: false,
        tdsDetails: action.payload,
        error: '',
      };
    });
  },
});

export default compareTDS.reducer;
