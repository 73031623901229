import React from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import TeamLeaveRequests from 'components/Organisms/Leave/TeamLeaves/TeamLeaveRequests';

const TeamLeavesTemplate = ({
  columns,
  rows,
  page,
  handleSelect,
  roleBasedMenu,
  leaveAccountData,
  handleSubmit,
  leaveCategoryOptions,
  leaveRequestStateOptions,
  hierarchyLevelOptions,
  showList,
  calendarYear,
  pageHeading,
  showActionList,
  loading,
  handleRowClick,
  showPagination,
  loadNextPageData,
  count,
}) => {
  return (
    <BlankTemplate pageHeading={pageHeading} isBreadcrumb={false} padding="3rem">
      <TeamLeaveRequests
        columns={columns}
        rows={rows}
        page={page}
        handleSelect={handleSelect}
        roleBasedMenu={roleBasedMenu}
        leaveAccountData={leaveAccountData}
        handleSubmit={handleSubmit}
        leaveCategoryOptions={leaveCategoryOptions}
        leaveRequestStateOptions={leaveRequestStateOptions}
        hierarchyLevelOptions={hierarchyLevelOptions}
        showList={showList}
        calendarYear={calendarYear}
        showActionList={showActionList}
        loading={loading}
        handleRowClick={handleRowClick}
        showPagination={showPagination}
        onPageChange={loadNextPageData}
        count={count}
      />
    </BlankTemplate>
  );
};

TeamLeavesTemplate.defaultProps = {
  roleBasedMenu: () => {},
  rows: [],
  handleSelect: () => {},
  page: '',
  leaveAccountData: [],
  handleSubmit: () => {},
  leaveCategoryOptions: [],
  leaveRequestStateOptions: [],
  hierarchyLevelOptions: [],
  showList: false,
  calendarYear: '',
  columns: [],
  pageHeading: '',
  showActionList: true,
  loading: true,
  handleRowClick: () => {},
  showPagination: false,
  loadNextPageData: () => {},
  count: 0,
};

TeamLeavesTemplate.propTypes = {
  showActionList: PropTypes.bool,
  roleBasedMenu: PropTypes.func,
  handleSelect: PropTypes.func,
  page: PropTypes.number,
  rows: PropTypes.arrayOf,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      numeric: PropTypes.bool,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),
  leaveAccountData: PropTypes.arrayOf(
    PropTypes.shape({
      balance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  handleSubmit: PropTypes.func,
  leaveCategoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  leaveRequestStateOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  hierarchyLevelOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  showList: PropTypes.bool,
  calendarYear: PropTypes.string,
  pageHeading: PropTypes.string,
  loading: PropTypes.bool,
  handleRowClick: PropTypes.func,
  showPagination: PropTypes.bool,
  count: PropTypes.number,
  loadNextPageData: PropTypes.func,
};

export default TeamLeavesTemplate;
