import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import MuiSwitch from 'components/Molecules/Switch';
import DropdownMenu from 'components/Molecules/DropdownMenu';
import ActionDropDown from 'components/Molecules/ActionDropDown';
import RangePicker from 'components/Molecules/DateRangePicker';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { validateDateElseHyphen } from 'utils/commonUtils';
import { hasPermission } from 'utils/permissions';
import { useSelector } from 'react-redux';
import TeamAttendanceGridTable from '../TeamAttendanceGridTable';
import TeamAttendanceTable from '../TeamAttendanceTable';
import { teamAttendanceStyle } from '../style';

const RenderDateRangePicker = (handleSelect) => {
  return (
    <RangePicker
      handleSubmit={(data) => handleSelect(data)}
      placement="left-start"
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typographys color="inherit" variant="body2" fontWeight={500}>
            Custom Date
          </Typographys>
          <ArrowForwardIosIcon sx={{ fontSize: '1.5rem' }} />
        </Box>
      }
    />
  );
};
const TeamAttendanceTableCard = ({
  attendanceStatusHandleChange,
  actionMenuLists,
  attendanceTableLoading,
  attendanceTableDataColumn,
  attendanceTableDataRow,
  attendanceTableKeyColumn,
  attendanceTablePage,
  attendanceTableSortDirection,
  attendanceTableSortBy,
  attendanceTableHandleSort,
  attendanceTableCount,
  attendanceTableOnPageChange,
  attendanceTableOnRowClick,
  attendanceTableSelectedItems,
  attendanceTableOnSelect,
  attendanceTableOnSelectAll,
  attendanceTableOnSortChange,
  isCheckAttendance,
  handleSelectAttendance,
  attendanceGridLoading,
  attendanceGridDataColumn,
  attendanceGridDataRow,
  attendanceGridHandleSort,
  attendanceGridSortBy,
  attendanceGridSortDirection,
  attendanceGridCount,
  attendanceGridPage,
  attendanceGridOnPageChange,
  attendanceGridOnSortChange,
}) => {
  const [detailView, setDetailView] = useState(false);
  const handleDetailView = (event) => {
    setDetailView(event.target.checked);
  };
  const [attendanceFilterLabel, setAttendanceFilterLabel] = useState('This Week');
  const authorities = useSelector((state) => state.session.user.authorities);

  const onDateChange = (data) => {
    setAttendanceFilterLabel(
      `${validateDateElseHyphen(data.startDate, 'DD MMMM YYYY')} to ${validateDateElseHyphen(
        data.endDate,
        'DD MMMM YYYY',
      )}`,
    );
    attendanceStatusHandleChange(data);
  };

  const attendanceStatusOptions = [
    {
      id: 1,
      name: 'This Week',
      permissions: ['ROLE_BASE_PERMISSION_ATT_INDEX_MY_TEAM_ATTENDANCE_THIS_WEEK'],
      onClick: () => attendanceStatusHandleChange('This Week'),
    },
    {
      id: 2,
      name: 'This Month',
      permissions: ['ROLE_BASE_PERMISSION_ATT_INDEX_MY_TEAM_ATTENDANCE_THIS_MONTH'],
      onClick: () => attendanceStatusHandleChange('This Month'),
    },
    { id: 3, name: 'This Year', permissions: [], onClick: () => attendanceStatusHandleChange('This Year') },
    {
      id: 4,
      name: 'Custom Date',
      permissions: ['ROLE_BASE_PERMISSION_ATT_INDEX_MY_TEAM_ATTENDANCE_CUSTOM_PERIOD'],
      onClick: () => attendanceStatusHandleChange('custom'),
      formatValue: () => RenderDateRangePicker(onDateChange),
    },
  ];

  const actionableAttendaceStatusOptions = attendanceStatusOptions.filter((item) =>
    hasPermission(authorities, item.permissions),
  );

  return (
    <Box sx={teamAttendanceStyle.teamAttendanceTableCardWrapper}>
      <Box sx={teamAttendanceStyle.teamAttendanceTableCardHeader}>
        <Typographys variant="h6">Team Attendance</Typographys>
        <Box sx={teamAttendanceStyle.teamAttendanceTableCardActionBtnGroup}>
          <MuiSwitch handleChange={handleDetailView} checked={detailView} label="Detailed view" />
          <Box sx={teamAttendanceStyle.cardHeaderDropdown}>
            <ActionDropDown label={attendanceFilterLabel} options={actionableAttendaceStatusOptions} />
          </Box>
          <DropdownMenu size="medium" btnLabel="Actions" color="primary" menuLists={actionMenuLists} />
        </Box>
      </Box>

      {detailView ? (
        <TeamAttendanceGridTable
          isCheckAttendance={isCheckAttendance}
          handleSelectAttendance={handleSelectAttendance}
          loading={attendanceGridLoading}
          attendanceDataColumn={attendanceGridDataColumn}
          attendanceDataRow={attendanceGridDataRow}
          page={attendanceGridPage}
          sortDirection={attendanceGridSortDirection}
          sortBy={attendanceGridSortBy}
          handleSort={attendanceGridHandleSort}
          count={attendanceGridCount}
          onPageChange={attendanceGridOnPageChange}
          onSortChange={attendanceGridOnSortChange}
        />
      ) : (
        <Box mt={2}>
          <TeamAttendanceTable
            loading={attendanceTableLoading}
            attendanceTableDataColumn={attendanceTableDataColumn}
            attendanceTableDataRow={attendanceTableDataRow}
            keyColumn={attendanceTableKeyColumn}
            page={attendanceTablePage}
            sortDirection={attendanceTableSortDirection}
            sortBy={attendanceTableSortBy}
            handleSort={attendanceTableHandleSort}
            count={attendanceTableCount}
            onPageChange={attendanceTableOnPageChange}
            onRowClick={attendanceTableOnRowClick}
            selectedItems={attendanceTableSelectedItems}
            onSelect={attendanceTableOnSelect}
            onSelectAll={attendanceTableOnSelectAll}
            onSortChange={attendanceTableOnSortChange}
          />
        </Box>
      )}
    </Box>
  );
};

TeamAttendanceTableCard.defaultProps = {
  attendanceStatusHandleChange: () => {},
  actionMenuLists: [],
  attendanceTableLoading: false,
  attendanceTableDataColumn: [],
  attendanceTableDataRow: [],
  attendanceTableKeyColumn: 'id',
  attendanceTableHandleSort: () => {},
  attendanceTableSortBy: () => {},
  attendanceTableSortDirection: 'asc',
  attendanceTableCount: 10,
  attendanceTablePage: null,
  attendanceTableOnPageChange: () => {},
  attendanceTableOnRowClick: () => {},
  attendanceTableSelectedItems: [],
  attendanceTableOnSelect: () => {},
  attendanceTableOnSelectAll: () => {},
  attendanceTableOnSortChange: () => {},

  isCheckAttendance: false,
  handleSelectAttendance: () => {},
  attendanceGridLoading: false,
  attendanceGridDataColumn: [],
  attendanceGridDataRow: [],
  attendanceGridHandleSort: () => {},
  attendanceGridSortBy: () => {},
  attendanceGridSortDirection: 'asc',
  attendanceGridCount: 10,
  attendanceGridPage: null,
  attendanceGridOnPageChange: () => {},
  attendanceGridOnSortChange: () => {},
};
TeamAttendanceTableCard.propTypes = {
  attendanceStatusHandleChange: PropTypes.func,
  actionMenuLists: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  attendanceTableLoading: PropTypes.bool,
  attendanceTableDataColumn: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),
  attendanceTableDataRow: PropTypes.arrayOf(
    PropTypes.shape({
      empName: PropTypes.string,
      empImgSrc: PropTypes.string,
      status: PropTypes.string,
      empId: PropTypes.string,
      period: PropTypes.string,
      day1: PropTypes.string,
      day2: PropTypes.string,
      day3: PropTypes.string,
      day4: PropTypes.string,
      day5: PropTypes.string,
      day6: PropTypes.string,
    }),
  ),
  attendanceTableKeyColumn: PropTypes.string,
  attendanceTableSortBy: PropTypes.func,
  attendanceTableHandleSort: PropTypes.func,
  attendanceTableSortDirection: PropTypes.string,
  attendanceTableCount: PropTypes.number,
  attendanceTablePage: PropTypes.number,
  attendanceTableOnPageChange: PropTypes.func,
  attendanceTableOnRowClick: PropTypes.func,
  attendanceTableSelectedItems: PropTypes.arrayOf,
  attendanceTableOnSelect: PropTypes.func,
  attendanceTableOnSelectAll: PropTypes.func,
  attendanceTableOnSortChange: PropTypes.func,

  isCheckAttendance: PropTypes.bool,
  handleSelectAttendance: PropTypes.func,
  attendanceGridLoading: PropTypes.bool,
  attendanceGridDataColumn: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),
  attendanceGridDataRow: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.string,
      firstTimeIn: PropTypes.string,
      lastTimeOut: PropTypes.string,
      duration: PropTypes.string,
      interimPunches: PropTypes.string,
      attendance: PropTypes.string,
      exception: PropTypes.string,
      attendanceMarking: PropTypes.string,
    }),
  ),
  attendanceGridSortBy: PropTypes.func,
  attendanceGridHandleSort: PropTypes.func,
  attendanceGridSortDirection: PropTypes.string,
  attendanceGridCount: PropTypes.number,
  attendanceGridPage: PropTypes.number,
  attendanceGridOnPageChange: PropTypes.func,
  attendanceGridOnSortChange: PropTypes.func,
};

export default TeamAttendanceTableCard;
