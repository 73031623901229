const columns = [
  {
    fieldName: 'index',
    numeric: true,
    label: 'Sl No.',
    type: 'number',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'taskName',
    numeric: false,
    label: 'Task Name',
    type: 'string',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'taskDescription',
    numeric: false,
    label: 'Task Description',
    type: 'string',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'owner',
    numeric: false,
    label: 'Task Owner',
    type: 'string',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'createdOn',
    numeric: false,
    label: 'Created On',
    type: 'string',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'dueBy',
    numeric: false,
    label: 'Due By',
    type: 'string',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'completionStatus',
    numeric: false,
    label: 'Completion Status',
    type: 'string',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
];

const useColumns = () => {
  return [columns];
};

export default useColumns;
