import { formDataApi } from 'api/api';

export const getTeamAttendanceTableRowData = async (customPeriod) => {
  const response = await formDataApi.get(`restAttendanceIndex/${customPeriod}`);
  return response?.data;
};

export const initiateTimer = async () => {
  const response = await formDataApi.post('restAttendanceIndex/initiateEmployeeTimer');
  return response?.data;
};

export const pauseTimer = async () => {
  const response = await formDataApi.post('restAttendanceIndex/pauseEmployeeTimer');
  return response?.data;
};

export const stopTimer = async () => {
  const response = await formDataApi.post('restAttendanceIndex/closeEmployeeTimer');
  return response?.data;
};

export const restartTimer = async (bodyFormData) => {
  const response = await formDataApi.post('restAttendanceIndex/restartEmployeeTimer', bodyFormData);
  return response?.data;
};
