import { createSlice } from '@reduxjs/toolkit';

import {
  fetchSalarySlipList,
  fetchSalarySlipDetails,
  fetchConsolidatedSalarySlipDetails,
} from 'store/thunks/home/myPay/salarySlip';

const salarySlip = createSlice({
  name: 'salarySlip',
  initialState: {
    salarySlipList: [],
    salarySlipDetails: [],
  },
  extraReducers(builder) {
    builder.addCase(fetchSalarySlipList.fulfilled, (state, action) => {
      state.salarySlipList = action.payload;
    });
    builder.addCase(fetchSalarySlipDetails.fulfilled, (state, action) => {
      state.salarySlipDetails = action.payload;
    });
    builder.addCase(fetchConsolidatedSalarySlipDetails.fulfilled, (state, action) => {
      state.salarySlipDetails = action.payload;
    });
  },
});

export default salarySlip.reducer;
