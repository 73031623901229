import React from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import OfferAcceptance from 'components/Organisms/Dashboard/CandidateDashboard/OfferAcceptance';

const OfferAcceptanceTemplate = ({ pageHeading, candidateData, handleDownload, handleSubmit, setDialog }) => {
  return (
    <BlankTemplate pageHeading={pageHeading} isBreadcrumb={false} padding="0">
      <OfferAcceptance
        candidateData={candidateData}
        handleDownload={handleDownload}
        handleSubmit={handleSubmit}
        setDialog={setDialog}
      />
    </BlankTemplate>
  );
};

OfferAcceptanceTemplate.defaultProps = {
  pageHeading: 'Offer acceptance',
  candidateData: {},
  handleDownload: () => {},
  handleSubmit: () => {},
};

OfferAcceptanceTemplate.propTypes = {
  pageHeading: PropTypes.string,
  candidateData: PropTypes.shape({}),
  handleDownload: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default OfferAcceptanceTemplate;
