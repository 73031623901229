import React, { useEffect } from 'react';
import { Box, Grid, Divider, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import Button from 'components/Atoms/Button';
import Typographys from 'components/Atoms/Typography';
import InputField from 'components/Atoms/InputField/index';
import DatePicker from 'components/Atoms/Datepicker';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Formik, FieldArray, getIn } from 'formik';
import Dropdown from 'components/Molecules/Dropdown';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { addEmpStyle } from '../../style';

const KeyLifeEventDetails = ({
  tabHeading,
  handleSubmit,
  handleCancel,
  lifeEventOptions,
  propData,
  label,
  updateBtnText,
}) => {
  const defaultObj = {
    keyLifeEventDetails: '',
    eventDate: '',
    comments: '',
  };

  const defaultValues =
    propData && propData.allTabsData && propData.allTabsData[label]
      ? propData.allTabsData[label]
      : { keylifeEvent: [defaultObj] };

  return (
    <Box mt={1.5}>
      <Typographys variant="h5" color="neutral.800">
        {tabHeading}
      </Typographys>
      <Formik
        initialValues={defaultValues}
        onSubmit={(values, actions) => {
          handleSubmit(values);
          actions.setSubmitting(false);
        }}
      >
        {(formik) => (
          <Form
            formik={formik}
            handleCancel={handleCancel}
            lifeEventOptions={lifeEventOptions}
            propData={propData}
            label={label}
            defaultObj={defaultObj}
            updateBtnText={updateBtnText}
          />
        )}
      </Formik>
    </Box>
  );
};

const Form = ({ formik, handleCancel, propData, lifeEventOptions, label, defaultObj, updateBtnText }) => {
  useEffect(() => {
    propData.updateFormData(formik.values, label);
  }, [formik.values, label]);

  const handleDateChange = (date, setFieldValue, label1) => {
    setFieldValue(label1, date);
  };

  const theme = useTheme();
  return (
    <Box mt={2} component="form" onSubmit={formik.handleSubmit}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 48.3rem)' }}>
          <FieldArray name="keylifeEvent">
            {({ push, remove }) => (
              <>
                {formik.values.keylifeEvent.map((k, index) => {
                  return (
                    <Box sx={addEmpStyle.rowDivider}>
                      <Box sx={addEmpStyle.removeWrapper}>
                        <Button
                          size="medium"
                          variant="outlined"
                          type="button"
                          startIcon={<DeleteOutlineIcon />}
                          onClick={() => remove(index)}
                        >
                          Delete
                        </Button>
                      </Box>

                      <Grid container rowSpacing={2} columnSpacing={9} mt={0} sx={addEmpStyle.formContainer}>
                        <Grid item xs={12} md={6}>
                          <Dropdown
                            error={Boolean(
                              getIn(formik.touched, `keylifeEvent[${index}].keyLifeEventDetails`) &&
                                getIn(formik.errors, `keylifeEvent[${index}].keyLifeEventDetails`),
                            )}
                            helperText={
                              getIn(formik.touched, `keylifeEvent[${index}].keyLifeEventDetails`) &&
                              getIn(formik.errors, `keylifeEvent[${index}].keyLifeEventDetails`)
                            }
                            handleChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={k.keyLifeEventDetails}
                            name={`keylifeEvent[${index}].keyLifeEventDetails`}
                            id={`keylifeEvent[${index}].keyLifeEventDetails`}
                            label="Key Life Events Details"
                            options={lifeEventOptions}
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <DatePicker
                            error={
                              Boolean(
                                getIn(formik.touched, `keylifeEvent[${index}].eventDate`) &&
                                  getIn(formik.errors, `keylifeEvent[${index}].eventDate`),
                              ) ||
                              (getIn(formik.touched, `keylifeEvent[${index}].eventDate`) && !k.eventDate)
                            }
                            helperText={
                              getIn(formik.touched, `keylifeEvent[${index}].eventDate`) &&
                              getIn(formik.errors, `keylifeEvent[${index}].eventDate`)
                            }
                            onChange={(data) =>
                              handleDateChange(data, formik.setFieldValue, `keylifeEvent[${index}].eventDate`)
                            }
                            onBlur={formik.handleBlur}
                            value={k?.eventDate}
                            name={`keylifeEvent[${index}].eventDate`}
                            id={`keylifeEvent[${index}].eventDate`}
                            label="Event Date"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `keylifeEvent[${index}].comments`) &&
                                getIn(formik.errors, `keylifeEvent[${index}].comments`),
                            )}
                            helperText={
                              getIn(formik.touched, `keylifeEvent[${index}].comments`) &&
                              getIn(formik.errors, `keylifeEvent[${index}].comments`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={k.comments}
                            name={`keylifeEvent[${index}].comments`}
                            id={`keylifeEvent[${index}].comments`}
                            label="Comments"
                            multiline
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })}
                <Box sx={{ mt: 2 }}>
                  <Button
                    size="medium"
                    variant="outlined"
                    type="button"
                    startIcon={<AddOutlinedIcon />}
                    onClick={() => push(defaultObj)}
                  >
                    Add Another
                  </Button>
                </Box>
              </>
            )}
          </FieldArray>
        </Box>
      </PerfectScrollbar>

      <Box sx={addEmpStyle.btnContainer}>
        <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

        <Box sx={addEmpStyle.submitWrapper}>
          <Button
            size="large"
            color="info"
            variant="outlined"
            type="cancel"
            onClick={() => {
              formik.resetForm();
              handleCancel();
            }}
          >
            Cancel
          </Button>

          <Button size="large" type="submit" isDisable={false}>
            {propData.allowEdit ? updateBtnText : 'Next'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

Form.defaultProps = {
  handleCancel: () => {},
  propData: {},
  formik: {},
  defaultObj: {},
  lifeEventOptions: [],
  label: 'Key Life Event Details',
  updateBtnText: 'Update',
};

Form.propTypes = {
  handleCancel: PropTypes.func,
  propData: PropTypes.shape({
    updateFormData: PropTypes.func,
    allTabsData: PropTypes.shape({
      'Key Life Event Details': PropTypes.shape({
        keylifeEvent: PropTypes.shape([]),
      }),
    }),
    allowEdit: PropTypes.bool,
  }),

  label: PropTypes.string,
  formik: PropTypes.shape({
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    values: PropTypes.shape({
      keylifeEvent: PropTypes.shape([]),
    }),
    resetForm: PropTypes.func,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    setFieldValue: PropTypes.func,
  }),
  defaultObj: PropTypes.shape({}),
  updateBtnText: PropTypes.string,

  lifeEventOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
};

KeyLifeEventDetails.defaultProps = {
  tabHeading: 'Key Life Event Details',
  handleSubmit: () => {},
  handleCancel: () => {},
  propData: {},
  lifeEventOptions: [],
  label: 'Key Life Event Details',
  updateBtnText: 'Update',
};

KeyLifeEventDetails.propTypes = {
  tabHeading: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,

  lifeEventOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  propData: PropTypes.shape({
    updateFormData: PropTypes.func,
    allTabsData: PropTypes.shape({
      'Key Life Event Details': PropTypes.shape({
        keylifeEvent: PropTypes.shape([]),
      }),
    }),
    allowEdit: PropTypes.bool,
  }),
  label: PropTypes.string,
  updateBtnText: PropTypes.string,
};

export default KeyLifeEventDetails;
