import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setChatSessionExpired } from 'store/slices/user';

const DialogflowMessenger = ({
  projectId,
  agentId,
  chatTitle,
  location = 'us-central1',
  languageCode = 'en',
  user,
}) => {
  const dispatch = useDispatch();
  const chatSessionExpired = useSelector((state) => state?.session?.chatSessionExpired);

  useEffect(() => {
    const checkSessionExpiry = () => {
      const sessionData = sessionStorage.getItem('chatSession');
      if (sessionData) {
        const { timestamp } = JSON.parse(sessionData);
        const currentTime = new Date().getTime();
        if (currentTime - timestamp > 30 * 60 * 1000) {
          const dfMessenger = document.querySelector('df-messenger');
          dfMessenger.startNewSession({ retainHistory: false });
          dispatch(setChatSessionExpired(true));
          sessionStorage.removeItem('chatSession');
        }
      } else {
        dispatch(setChatSessionExpired(true));
      }
    };

    setInterval(() => {
      checkSessionExpiry();
    }, 40000);
    const dfMessenger = document.querySelector('df-messenger');
    const handleMessengerOpened = (event) => {
      if (chatSessionExpired) {
        dfMessenger.startNewSession({ retainHistory: true });
        const queryParameters = {
          parameters: {
            company_identifier: user?.companyUID,
            company_name: user?.company,
            user_name: user?.firstName,
            user_id: user?.empDBID,
            url_prefix: user?.companyHostingUrl?.slice(8),
          },
        };
        dfMessenger.setQueryParameters(queryParameters);

        if (event?.detail?.isOpen) {
          dfMessenger.sendRequest('query', 'hi');
          dispatch(setChatSessionExpired(false));
        }

        sessionStorage.setItem('chatSession', JSON.stringify({ timestamp: new Date().getTime() }));

        const chatBubble = dfMessenger.shadowRoot.querySelector('df-messenger-chat-bubble');
        if (chatBubble) {
          chatBubble.setAttribute('chat-title', chatTitle);
        }
      }
    };
    if (dfMessenger) {
      dfMessenger.setAttribute('project-id', projectId);
      dfMessenger.setAttribute('agent-id', agentId);
      dfMessenger.setAttribute('location', location);
      dfMessenger.setAttribute('language-code', languageCode);

      dfMessenger.addEventListener('df-chat-open-changed', handleMessengerOpened);
    }
    return () => {
      dfMessenger.removeEventListener('df-chat-open-changed', handleMessengerOpened);
    };
  }, [projectId, agentId, chatTitle, location, languageCode, chatSessionExpired]);

  return (
    <div>
      <df-messenger />
    </div>
  );
};

DialogflowMessenger.defaultProps = {
  projectId: '',
  agentId: '',
  chatTitle: 'Default Title',
  location: 'us-central1',
  languageCode: 'en',
  user: {},
};

DialogflowMessenger.propTypes = {
  projectId: PropTypes.string,
  agentId: PropTypes.string,
  chatTitle: PropTypes.string,
  location: PropTypes.string,
  languageCode: PropTypes.string,
  user: PropTypes.shape({}),
};

export default DialogflowMessenger;
