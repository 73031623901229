import PropTypes from 'prop-types';
import React from 'react';
import MuiTableHead from '@mui/material/TableHead';
import Checkbox from 'components/Atoms/Checkbox';

// project import

import TableRow from '../TableRow';
import TableCell from '../TableCell';
import TableSortLabel from '../TableSortLabel';

const TableHead = ({
  rows,
  columns,
  sortDirection,
  sortBy,
  onSortChange,
  selectedItems,
  showRowSelector,
  onSelectAll,
  keyColumn,
  showActionList,
}) => {
  return (
    <MuiTableHead>
      <TableRow>
        {showRowSelector && (
          <TableCell padding="checkbox">
            <Checkbox
              color="secondary"
              checked={rows.length > 0 && selectedItems.length === rows.length}
              indeterminate={selectedItems.length > 0 && selectedItems.length < rows.length}
              onChange={(event) => onSelectAll(event, keyColumn)}
            />
          </TableCell>
        )}
        {columns?.map((column) => (
          <TableCell sticky={column.sticky} key={column.label}>
            <TableSortLabel
              disableSorting={column?.disableSorting}
              active={sortBy === column.fieldName}
              direction={sortBy === column.fieldName ? sortDirection : 'desc'}
              onClick={(event) => onSortChange(event, column.fieldName)}
            >
              {column.label}
            </TableSortLabel>
          </TableCell>
        ))}
        {showActionList && (
          <TableCell sticky align="center">
            ACTIONS
          </TableCell>
        )}
      </TableRow>
    </MuiTableHead>
  );
};

TableHead.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.node).isRequired,
  columns: PropTypes.arrayOf(PropTypes.node).isRequired,
  sortDirection: PropTypes.string,
  sortBy: PropTypes.string,
  onSortChange: PropTypes.func,
  selectedItems: PropTypes.arrayOf,
  keyColumn: PropTypes.string,
  showRowSelector: PropTypes.bool,
  onSelectAll: PropTypes.func,
  showActionList: PropTypes.bool,
};

TableHead.defaultProps = {
  sortDirection: 'desc',
  sortBy: 'id',
  onSortChange: () => {},
  selectedItems: [],
  keyColumn: '',
  showRowSelector: false,
  onSelectAll: () => {},
  showActionList: false,
};
export default TableHead;
