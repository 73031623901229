import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Divider, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Button from 'components/Atoms/Button';
import Typographys from 'components/Atoms/Typography';
import InputField from 'components/Atoms/InputField';
import Dropdown from 'components/Molecules/Dropdown';
import { Formik, FieldArray, getIn } from 'formik';
import * as yup from 'yup';
import DatePicker from 'components/Atoms/Datepicker';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { genericMobileRegex, genericEmailRegex, panRegex } from 'utils/commonUtils';
import Typography from '@mui/material/Typography';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { profileStyle } from '../../style';

const EditProfileDependents = ({
  handleSubmit,
  handleCancel,
  genderOptions,
  relationOptions,
  defaultValue,
  loading,
  referenceOptions,
}) => {
  const dependentSchema = yup.object().shape({
    dependents: yup.array().of(
      yup.object().shape({
        firstName: yup.string().required('Required'),
        lastName: yup.string().required('Required'),
        gender: yup.string().required('Required'),
        relation: yup.string().required('Required'),
        dateOfBirth: yup.string().required('Required'),
        panNumber: yup.string().matches(panRegex, 'Invalid format, Please enter valid pan number.'),
        address: yup.string(),
        mobileNumber: yup
          .array()
          .of(yup.string().matches(genericMobileRegex, 'Invalid format, Please enter valid mobile number.')),
        emailAddress: yup
          .array()
          .of(yup.string().matches(genericEmailRegex, 'Invalid format, Please enter valid email ID.')),
        epfPerc: yup
          .number()
          .min(0, 'Value must be greater than or equal to 0.')
          .max(100, 'Value must be less than or equal to 100.'),
        epsPerc: yup
          .number()
          .min(0, 'Value must be greater than or equal to 0.')
          .max(100, 'Value must be less than or equal to 100.'),
        insurancePerc: yup
          .number()
          .min(0, 'Value must be greater than or equal to 0.')
          .max(100, 'Value must be less than or equal to 100.'),
        otherPerc: yup
          .number()
          .min(0, 'Value must be greater than or equal to 0.')
          .max(100, 'Value must be less than or equal to 100.'),
        gratuityPerc: yup
          .number()
          .min(0, 'Value must be greater than or equal to 0.')
          .max(100, 'Value must be less than or equal to 100.'),
      }),
    ),
  });
  const defaultObj = {
    mobileNumber: [''],
    emailAddress: [''],
    telephoneNumber: [''],
    firstName: '',
    lastName: '',
    gender: '',
    relation: '',
    dateOfBirth: '',
    panNumber: '',
    address: '',
  };

  const handleDateChange = (date, setFieldValue, label1) => {
    setFieldValue(label1, date);
  };

  return (
    <Box>
      <Formik
        validationSchema={dependentSchema}
        validateOnMount
        initialValues={defaultValue}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(formik) => (
          <Form
            formik={formik}
            handleCancel={handleCancel}
            genderOptions={genderOptions}
            relationOptions={relationOptions}
            referenceOptions={referenceOptions}
            defaultObj={defaultObj}
            handleDateChange={handleDateChange}
            loading={loading}
          />
        )}
      </Formik>
    </Box>
  );
};

const Form = ({
  formik,
  genderOptions,
  relationOptions,
  referenceOptions,
  handleCancel,
  defaultObj,
  handleDateChange,
  loading,
}) => {
  const theme = useTheme();

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={profileStyle.mainContainer}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 38rem)' }}>
          <FieldArray name="dependents">
            {({ push: pushDependent, remove }) => (
              <>
                {formik.values.dependents.map((pd, index) => (
                  <Box sx={profileStyle.formDivider}>
                    <Typographys variant="h6" color="neutral.800">
                      Dependent {index + 1}
                    </Typographys>
                    <Box sx={profileStyle.deleteContainer}>
                      <Typography variant="subtitle2" color="neutral.800">
                        Record {index + 1}
                      </Typography>
                      <Button
                        startIcon={<DeleteForeverIcon />}
                        onClick={(event) => {
                          event.preventDefault();
                          remove(index);
                        }}
                        variant="outlined"
                      >
                        Delete
                      </Button>
                    </Box>

                    <Grid container rowSpacing={2} columnSpacing={2} sx={profileStyle.formContainer}>
                      <Grid item xs={12} sm={6} md={4}>
                        <InputField
                          error={Boolean(
                            getIn(formik.touched, `dependents[${index}].firstName`) &&
                              getIn(formik.errors, `dependents[${index}].firstName`),
                          )}
                          helperText={
                            getIn(formik.touched, `dependents[${index}].firstName`) &&
                            getIn(formik.errors, `dependents[${index}].firstName`)
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={pd.firstName}
                          name={`dependents[${index}].firstName`}
                          id={`dependents[${index}].firstName`}
                          label="First Name*"
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <InputField
                          error={Boolean(
                            getIn(formik.touched, `dependents[${index}].lastName`) &&
                              getIn(formik.errors, `dependents[${index}].lastName`),
                          )}
                          helperText={
                            getIn(formik.touched, `dependents[${index}].lastName`) &&
                            getIn(formik.errors, `dependents[${index}].lastName`)
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={pd.lastName}
                          name={`dependents[${index}].lastName`}
                          id={`dependents[${index}].lastName`}
                          label="Last Name*"
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Dropdown
                          error={Boolean(
                            getIn(formik.touched, `dependents[${index}].gender`) &&
                              getIn(formik.errors, `dependents[${index}].gender`),
                          )}
                          helperText={
                            getIn(formik.touched, `dependents[${index}].gender`) &&
                            getIn(formik.errors, `dependents[${index}].gender`)
                          }
                          handleChange={(e) => {
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          value={pd.gender}
                          name={`dependents[${index}].gender`}
                          id={`dependents[${index}].gender`}
                          label="Gender*"
                          options={genderOptions}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Dropdown
                          error={Boolean(
                            getIn(formik.touched, `dependents[${index}].relation`) &&
                              getIn(formik.errors, `dependents[${index}].relation`),
                          )}
                          helperText={
                            getIn(formik.touched, `dependents[${index}].relation`) &&
                            getIn(formik.errors, `dependents[${index}].relation`)
                          }
                          handleChange={(e) => {
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          value={pd.relation}
                          name={`dependents[${index}].relation`}
                          id={`dependents[${index}].relation`}
                          label="Relation*"
                          options={relationOptions}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <DatePicker
                          error={Boolean(
                            getIn(formik.touched, `dependents[${index}].dateOfBirth`) &&
                              getIn(formik.errors, `dependents[${index}].dateOfBirth`),
                          )}
                          helperText={
                            getIn(formik.touched, `dependents[${index}].dateOfBirth`) &&
                            getIn(formik.errors, `dependents[${index}].dateOfBirth`)
                          }
                          onChange={(data) =>
                            handleDateChange(data, formik.setFieldValue, `dependents[${index}].dateOfBirth`)
                          }
                          onBlur={formik.handleBlur}
                          value={pd?.dateOfBirth}
                          name={`dependents[${index}].dateOfBirth`}
                          id={`dependents[${index}].dateOfBirth`}
                          label="Date of Birth*"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <InputField
                          error={Boolean(
                            getIn(formik.touched, `dependents[${index}].panNumber`) &&
                              getIn(formik.errors, `dependents[${index}].panNumber`),
                          )}
                          helperText={
                            getIn(formik.touched, `dependents[${index}].panNumber`) &&
                            getIn(formik.errors, `dependents[${index}].panNumber`)
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={pd?.panNumber}
                          name={`dependents[${index}].panNumber`}
                          id={`dependents[${index}].panNumber`}
                          label="PAN Number"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <InputField
                          error={Boolean(
                            getIn(formik.touched, `dependents[${index}].address`) &&
                              getIn(formik.errors, `dependents[${index}].address`),
                          )}
                          helperText={
                            getIn(formik.touched, `dependents[${index}].address`) &&
                            getIn(formik.errors, `dependents[${index}].address`)
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={pd?.address}
                          name={`dependents[${index}].address`}
                          id={`dependents[${index}].address`}
                          label="Address"
                          fullWidth
                        />
                      </Grid>
                    </Grid>

                    <Typographys variant="h6" color="neutral.800">
                      Contact Details
                    </Typographys>

                    <Grid container rowSpacing={2} columnSpacing={2} sx={profileStyle.formContainer}>
                      <FieldArray name={`dependents[${index}].mobileNumber`}>
                        {({ push }) => (
                          <Grid item xs={12} sm={6} md={4}>
                            {pd.mobileNumber.map((m, i) => (
                              <InputField
                                error={Boolean(
                                  getIn(formik.touched, `dependents[${index}].mobileNumber[${i}]`) &&
                                    getIn(formik.errors, `dependents[${index}].mobileNumber[${i}]`),
                                )}
                                helperText={
                                  getIn(formik.touched, `dependents[${index}].mobileNumber[${i}]`) &&
                                  getIn(formik.errors, `dependents[${index}].mobileNumber[${i}]`)
                                }
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={m}
                                name={`dependents[${index}].mobileNumber[${i}]`}
                                id={`dependents[${index}].mobileNumber[${i}]`}
                                label="Mobile Number"
                                type="Number"
                                fullWidth
                                sx={{ marginBottom: '1.5rem' }}
                              />
                            ))}
                          </Grid>
                        )}
                      </FieldArray>
                      <FieldArray name={`dependents[${index}].telephoneNumber`}>
                        {({ push }) => (
                          <Grid item xs={12} sm={6} md={4}>
                            {pd.telephoneNumber.map((m, i) => (
                              <InputField
                                error={Boolean(
                                  getIn(formik.touched, `dependents[${index}].telephoneNumber[${i}]`) &&
                                    getIn(formik.errors, `dependents[${index}].telephoneNumber[${i}]`),
                                )}
                                helperText={
                                  getIn(formik.touched, `dependents[${index}].telephoneNumber[${i}]`) &&
                                  getIn(formik.errors, `dependents[${index}].telephoneNumber[${i}]`)
                                }
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={m}
                                name={`dependents[${index}].telephoneNumber[${i}]`}
                                id={`dependents[${index}].telephoneNumber[${i}]`}
                                label="Telephone Number"
                                type="Number"
                                fullWidth
                                sx={{ marginBottom: '1.5rem' }}
                              />
                            ))}
                          </Grid>
                        )}
                      </FieldArray>
                      <FieldArray name={`dependents[${index}].emailAddress`}>
                        {({ push }) => (
                          <Grid item xs={12} sm={6} md={4}>
                            {pd.emailAddress.map((e, i) => (
                              <InputField
                                error={Boolean(
                                  getIn(formik.touched, `dependents[${index}].emailAddress[${i}]`) &&
                                    getIn(formik.errors, `dependents[${index}].emailAddress[${i}]`),
                                )}
                                helperText={
                                  getIn(formik.touched, `dependents[${index}].emailAddress[${i}]`) &&
                                  getIn(formik.errors, `dependents[${index}].emailAddress[${i}]`)
                                }
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={e}
                                name={`dependents[${index}].emailAddress[${i}]`}
                                id={`dependents[${index}].emailAddress[${i}]`}
                                label="Email Address"
                                fullWidth
                                sx={{ marginBottom: '1.5rem' }}
                              />
                            ))}
                          </Grid>
                        )}
                      </FieldArray>
                    </Grid>
                    <Typographys variant="h6" color="neutral.800">
                      Nomination Details
                    </Typographys>

                    <Grid container rowSpacing={2} columnSpacing={9} sx={profileStyle.formContainer}>
                      <Grid item xs={12} md={4}>
                        <InputField
                          error={Boolean(
                            getIn(formik.touched, `dependents[${index}].epfPerc`) &&
                              getIn(formik.errors, `dependents[${index}].epfPerc`),
                          )}
                          helperText={
                            getIn(formik.touched, `dependents[${index}].epfPerc`) &&
                            getIn(formik.errors, `dependents[${index}].epfPerc`)
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={pd?.epfPerc}
                          name={`dependents[${index}].epfPerc`}
                          id={`dependents[${index}].epfPerc`}
                          label="EPF Nomination %"
                          type="number"
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <InputField
                          error={Boolean(
                            getIn(formik.touched, `dependents[${index}].epsPerc`) &&
                              getIn(formik.errors, `dependents[${index}].epsPerc`),
                          )}
                          helperText={
                            getIn(formik.touched, `dependents[${index}].epsPerc`) &&
                            getIn(formik.errors, `dependents[${index}].epsPerc`)
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={pd?.epsPerc}
                          name={`dependents[${index}].epsPerc`}
                          id={`dependents[${index}].epsPerc`}
                          label="EPS Nomination %"
                          type="number"
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <InputField
                          error={Boolean(
                            getIn(formik.touched, `dependents[${index}].insurancePerc`) &&
                              getIn(formik.errors, `dependents[${index}].insurancePerc`),
                          )}
                          helperText={
                            getIn(formik.touched, `dependents[${index}].insurancePerc`) &&
                            getIn(formik.errors, `dependents[${index}].insurancePerc`)
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={pd?.insurancePerc}
                          name={`dependents[${index}].insurancePerc`}
                          id={`dependents[${index}].insurancePerc`}
                          label="Insurance Nomination %"
                          type="number"
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <InputField
                          error={Boolean(
                            getIn(formik.touched, `dependents[${index}].otherPerc`) &&
                              getIn(formik.errors, `dependents[${index}].otherPerc`),
                          )}
                          helperText={
                            getIn(formik.touched, `dependents[${index}].otherPerc`) &&
                            getIn(formik.errors, `dependents[${index}].otherPerc`)
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={pd?.otherPerc}
                          name={`dependents[${index}].otherPerc`}
                          id={`dependents[${index}].otherPerc`}
                          label="Others Nomination %"
                          type="number"
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <InputField
                          error={Boolean(
                            getIn(formik.touched, `dependents[${index}].gratuityPerc`) &&
                              getIn(formik.errors, `dependents[${index}].gratuityPerc`),
                          )}
                          helperText={
                            getIn(formik.touched, `dependents[${index}].gratuityPerc`) &&
                            getIn(formik.errors, `dependents[${index}].gratuityPerc`)
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={pd?.gratuityPerc}
                          name={`dependents[${index}].gratuityPerc`}
                          id={`dependents[${index}].gratuityPerc`}
                          label="Gratuity  Nomination %"
                          type="number"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Dropdown
                          error={Boolean(
                            getIn(formik.touched, `dependents[${index}].emergencyContact`) &&
                              getIn(formik.errors, `dependents[${index}].emergencyContact`),
                          )}
                          helperText={
                            getIn(formik.touched, `dependents[${index}].emergencyContact`) &&
                            getIn(formik.errors, `dependents[${index}].emergencyContact`)
                          }
                          handleChange={(e) => {
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          value={pd.emergencyContact}
                          name={`dependents[${index}].emergencyContact`}
                          id={`dependents[${index}].emergencyContact`}
                          label="Emergency Contact Reference"
                          options={referenceOptions}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Box>
                ))}
                <Box sx={profileStyle.addButton}>
                  <Button
                    size="medium"
                    variant="outlined"
                    startIcon={<AddOutlinedIcon />}
                    onClick={() => pushDependent(defaultObj)}
                    type="button"
                  >
                    Add Dependent
                  </Button>
                </Box>
              </>
            )}
          </FieldArray>
        </Box>
      </PerfectScrollbar>

      <Box sx={profileStyle.btnContainer}>
        <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

        <Box sx={profileStyle.submitWrapper}>
          <Button
            size="large"
            color="info"
            variant="outlined"
            type="cancel"
            onClick={() => {
              formik.resetForm();
              handleCancel();
            }}
          >
            Cancel
          </Button>

          <LoadingButton loading={loading} color="primary" size="large" type="submit" variant="contained">
            Update
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

Form.defaultProps = {
  genderOptions: [],
  relationOptions: [],
  referenceOptions: [],
  defaultValue: {
    mobileNumber: [''],
    emailAddress: [''],
    firstName: '',
    lastName: '',
    gender: '',
    relation: '',
    dateOfBirth: '',
    epfPerc: '',
    epsPerc: '',
    insurancePerc: '',
    otherPerc: '',
    gratuityPerc: '',
    emergencyContact: '',
  },
  formik: {},
  handleCancel: () => {},
  defaultObj: {},
  handleDateChange: () => {},
  loading: false,
};

Form.propTypes = {
  genderOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  relationOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  referenceOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  defaultValue: PropTypes.shape({}),
  defaultObj: PropTypes.shape({}),
  handleCancel: PropTypes.func,
  handleDateChange: PropTypes.func,
  formik: PropTypes.shape({
    errors: PropTypes.shape({}),

    touched: PropTypes.shape({}),

    values: PropTypes.shape({
      dependents: PropTypes.shape({}),
    }),
    resetForm: PropTypes.func,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
  }),
  loading: false,
};

EditProfileDependents.defaultProps = {
  handleSubmit: () => {},
  handleCancel: () => {},
  genderOptions: [],
  referenceOptions: [],
  relationOptions: [],
  defaultValue: {
    dependents: [
      {
        mobileNumber: [''],
        emailAddress: [''],
        firstName: '',
        lastName: '',
        gender: '',
        relation: '',
        dateOfBirth: '',
        epfPerc: '',
        epsPerc: '',
        insurancePerc: '',
        otherPerc: '',
        gratuityPerc: '',
        emergencyContact: '',
      },
    ],
  },
  loading: false,
};

EditProfileDependents.propTypes = {
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  genderOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  relationOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  referenceOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  defaultValue: PropTypes.shape({}),
  loading: PropTypes.bool,
};

export default EditProfileDependents;
