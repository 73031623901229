import { isObject } from 'lodash';

export const postListApiFormat = (controller, pageSize) => {
  const bodyFormData = new FormData();
  bodyFormData.append('sort', controller?.sortBy);
  bodyFormData.append('order', controller?.sort);
  bodyFormData.append('offset', (controller?.page || 0) * pageSize);
  bodyFormData.append('max', pageSize);
  if (isObject(controller?.filters) && Object.keys(controller?.filters)?.length > 0) {
    Object.entries(controller?.filters).forEach(([key, value]) => {
      bodyFormData.append(key, value);
    });
  }
  return bodyFormData;
};

export const getListApiFormat = (controller, pageSize) => {
  const query = new URLSearchParams({});
  if (controller) {
    query.append('sort', controller?.sortBy);
    query.append('order', controller?.sort);
    query.append('offset', (controller?.page || 0) * pageSize);
    query.append('max', pageSize);
  }
  return query;
};
