import React, { useState, useEffect } from 'react';
import EmployeeDashboard from 'components/Templates/Employee/Dashboard';

import { fetchEmployeeDashboardData } from 'api/employees';
import useMenuItems from './useMenuItems';
import { transformDashboardData } from './converter';

const Dashboard = () => {
  const [menuItems] = useMenuItems();
  const [transformedResponse, setTransformedResponse] = useState({});

  const [empTypeWiseColumns, setEmpTypeWiseColumns] = useState([]);
  const [empLineChartData, setEmpLineChartData] = useState([]);

  const [monthWiseData, setMonthWiseData] = useState([]);

  const handleNumOfEmployeeChartFilterHandleChange = (event) => {
    const { value } = event.target;

    switch (value) {
      case 1: {
        setEmpTypeWiseColumns(transformedResponse.empTypeWiseColumns);
        setEmpLineChartData(transformedResponse.empTypeWiseData);
        break;
      }
      case 2: {
        setEmpTypeWiseColumns(transformedResponse.empTypeWiseColumns);
        setEmpLineChartData(transformedResponse.empTypeWiseData);
        break;
      }
      case 3: {
        setEmpTypeWiseColumns(transformedResponse.deptWiseColumns);
        setEmpLineChartData(transformedResponse.deptWiseData);
        break;
      }
      case 4: {
        setEmpTypeWiseColumns(transformedResponse.locWiseColumns);
        setEmpLineChartData(transformedResponse.locWiseData);
        break;
      }
      default:
    }
  };

  const handleMonthWiseTrendFilterHandleChange = (event) => {
    const { value } = event.target;

    switch (value) {
      case 1: {
        setMonthWiseData(transformedResponse.attritionData);
        break;
      }
      case 2: {
        setMonthWiseData(transformedResponse.onboardingData);
        break;
      }
      default:
    }
  };

  useEffect(() => {
    const fetchDashboardData = async () => {
      const response = await fetchEmployeeDashboardData();
      const data = transformDashboardData(response);
      setTransformedResponse(data);
      setEmpTypeWiseColumns(data.empTypeWiseColumns);
      setEmpLineChartData(data.empTypeWiseData);
      setMonthWiseData(data.attritionData);
    };

    fetchDashboardData();
  }, []);
  return (
    <EmployeeDashboard
      actionMenuLists={menuItems}
      numActiveValue={transformedResponse.activeEmpCount}
      numNewJoinessYTD={transformedResponse.totalEmpOnboardCount}
      numExitYTD={transformedResponse.totalEmpAttrCount}
      numNotInvited="0"
      genderData={transformedResponse.genderData}
      tenureWiseData={transformedResponse.tenureWiseData}
      numOfEmployeeChartFilterOptions={transformedResponse.numOfEmployeeChartFilterOptions}
      empTypeWiseColumns={empTypeWiseColumns}
      empLineChartData={empLineChartData}
      numOfEmployeeChartFilterHandleChange={handleNumOfEmployeeChartFilterHandleChange}
      monthWiseTrendFilterOptions={transformedResponse.monthWiseTrendFilterOptions}
      monthWiseData={monthWiseData}
      monthWiseTrendFilterHandleChange={handleMonthWiseTrendFilterHandleChange}
    />
  );
};

export default Dashboard;
