import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Paper, Box, Grid, Divider, useTheme } from '@mui/material';
import Button from 'components/Atoms/Button';
import Typographys from 'components/Atoms/Typography';
import InputField from 'components/Atoms/InputField/index';
import Dropdown from 'components/Molecules/Dropdown';
import DatePicker from 'components/Atoms/Datepicker';
import TitleWithText from 'components/Atoms/TitleWithText';
import AddIcon from '@mui/icons-material/Add';
import { myExpensesStyle } from 'components/Organisms/MyExpenses/style';
import Checkbox from 'components/Atoms/Checkbox';
import ActionList from 'components/Molecules/ActionList';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Attachments from 'components/Atoms/Attachments';
import { FieldArray, getIn } from 'formik';
import DialogDeleteExpensesReport from 'components/Organisms/Dialog/Expenses/DeleteReportsDialog';
import dayjs from 'dayjs';

const ExpenseRecord = ({
  formik,
  totalExpensedAmount,
  reimbursableAmount,
  expenseCategoryOptions,
  currencyOptions,
  paymentMethodOptions,
  projectsOptions,
  merchantOptions,
  push,
  remove,
  isEdit,
}) => {
  const theme = useTheme();
  const [key, setKey] = useState(2);
  const [deleteIndex, setDeleteIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [previousDoc, setPreviousDoc] = useState(false);

  const [expensedAmount, setExpensedAmount] = useState(0);
  const [totalReimbursableAmount, setTotalReimbursableAmount] = useState(0);

  const setCheckBoxState = () => {
    if (formik.values?.expenseRecord?.length > 0) {
      formik.values?.expenseRecord?.forEach((expense, index) => {
        formik.setFieldValue(`expenseRecord[${index}].reimbursed`, expense?.isReimburseable ? 'on' : 'off');
      });
    }
  };

  useEffect(() => {
    setExpensedAmount(totalExpensedAmount);
    setTotalReimbursableAmount(reimbursableAmount);
    if (isEdit) {
      setCheckBoxState();
      setPreviousDoc(true);
    }
  }, [totalExpensedAmount, reimbursableAmount]);

  const expenseRecordOption = (index) => [
    {
      id: 1,
      name: 'Allocate to Project',
      onClick: () => {
        formik.setFieldValue(`expenseRecord[${index}].showAllocation`, true);
      },
    },
    {
      id: 2,
      name: 'Tag to Vendor',
      onClick: () => {
        formik.setFieldValue(`expenseRecord[${index}].showTagToMerchant`, true);
      },
    },
  ];

  const actionHandleSelect = () => {};

  const updateTotalExpensedAmount = () => {
    const sum = formik.values?.expenseRecord?.reduce((accumulator, object) => {
      const finalAmount = accumulator + (object?.amount || object.expensedAmount) * (object?.exchangeRate || 1);
      return finalAmount;
    }, 0);
    const sumRem = formik.values?.expenseRecord
      ?.filter((item) => item.reimbursed === 'on')
      .reduce((accumulator, object) => {
        const finalAmount = accumulator + (object?.amount || object.expensedAmount) * (object?.exchangeRate || 1);
        return finalAmount;
      }, 0);

    setExpensedAmount(Number.isNaN(sum) ? 0 : sum);
    setTotalReimbursableAmount(Number.isNaN(sumRem) ? 0 : sumRem);
    formik.setFieldValue(`totalExpensedAmount`, Number.isNaN(sum) ? 0 : sum);
    formik.setFieldValue(`reimbursableAmount`, Number.isNaN(sumRem) ? 0 : sumRem);
  };

  useEffect(() => {
    updateTotalExpensedAmount();
  }, [formik.values]);

  const formatDate = (val) => {
    const newDate = val ? dayjs(val, 'DD-MMM-YYYY HH:mm:ss').format('MM/DD/YYYY') : '';
    return newDate ? dayjs(newDate, 'MM/DD/YYYY') : '';
  };

  return (
    <Paper sx={myExpensesStyle.expenseRecordWrapper}>
      <DialogDeleteExpensesReport
        handleDelete={() => {
          remove(deleteIndex);
          setOpen(false);
          updateTotalExpensedAmount();
        }}
        handleCancel={() => {
          setOpen(false);
        }}
        handleClose={() => {
          setOpen(false);
        }}
        open={open}
        reportName={`Expense Record: ${deleteIndex + 1}`}
      />
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12} md={4}>
          <TitleWithText
            title="Total Expensed Amount"
            text={`${formik.values?.defaultCurrency} ${expensedAmount}`}
            titleVariant="subtitle2"
            titleColor="neutral.800"
            textVariant="h3"
            padding="0"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typographys variant="h6" color="neutral.800">
            <TitleWithText
              title="Reimbursable Amount"
              text={`${formik.values?.defaultCurrency} ${totalReimbursableAmount}`}
              titleVariant="subtitle2"
              titleColor="neutral.800"
              textVariant="h3"
              padding="0"
            />
          </Typographys>
        </Grid>
        <Grid item xs={12} md={4} sx={myExpensesStyle.newExpenseBtn}>
          <Button
            type="button"
            size="medium"
            variant="outlined"
            onClick={() => {
              push({ allocations: [{}], key, reimbursed: 'on', isNew: 'true' });
              setKey(key + 1);
              setPreviousDoc(false);
            }}
            startIcon={<AddIcon />}
          >
            New Expense Record
          </Button>
        </Grid>
      </Grid>

      {formik.values?.expenseRecord?.length > 0 &&
        formik.values?.expenseRecord?.map((expense, index) => (
          <Box sx={myExpensesStyle.expenseRecordForm} key={expense.key || 1}>
            <Grid container rowSpacing={2} columnSpacing={2}>
              <Grid item xs={12} md={4}>
                <Typographys variant="h6" color="neutral.800">
                  Expense Record: {index + 1}
                </Typographys>
              </Grid>
              <Grid item xs={12} md={8} sx={myExpensesStyle.newExpenseActionBtnGroup}>
                <IconButton color="secondary">
                  <DeleteIcon
                    sx={myExpensesStyle.actionDeleteIcon}
                    onClick={() => {
                      setDeleteIndex(index);
                      setOpen(true);
                    }}
                  />
                </IconButton>
                <Divider orientation="vertical" sx={{ borderColor: theme.palette.other[200] }} />
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      formik.values.expenseRecord[index].reimbursed = 'on';
                    } else if (!e.target.checked) {
                      formik.values.expenseRecord[index].reimbursed = 'off';
                    }
                    updateTotalExpensedAmount();
                  }}
                  key={expense.reimbursed}
                  checked={expense.reimbursed?.includes('on')}
                  color="secondary"
                  name={`expenseRecord[${index}].reimbursed`}
                  label="To Be Reimbursed"
                />
                <ActionList options={expenseRecordOption(index)} handleSelect={actionHandleSelect} />
              </Grid>
              <Grid item xs={12} md={4}>
                <Dropdown
                  error={Boolean(getIn(formik.errors, `expenseRecord[${index}].expenseCategoryId`))}
                  helperText={getIn(formik.errors, `expenseRecord[${index}].expenseCategoryId`)}
                  handleChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={expense?.expenseCategoryId}
                  name={`expenseRecord[${index}].expenseCategoryId`}
                  id={`expenseRecord[${index}].expenseCategoryId`}
                  label="Expense Category*"
                  options={expenseCategoryOptions}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Dropdown
                  error={Boolean(
                    getIn(formik.touched, `expenseRecord[${index}].expensedCurrencyId`) &&
                      getIn(formik.errors, `expenseRecord[${index}].expensedCurrencyId`),
                  )}
                  helperText={
                    getIn(formik.touched, `expenseRecord[${index}].expensedCurrencyId`) &&
                    getIn(formik.errors, `expenseRecord[${index}].expensedCurrencyId`)
                  }
                  handleChange={(e) => {
                    formik.handleChange(e);
                    if (
                      formik.values?.reportingCurrencyId !== expense.expensedCurrencyId &&
                      expense.expensedCurrencyId !== undefined
                    ) {
                      formik.setFieldValue(`expenseRecord[${index}].exchangeRate`, 0);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  value={expense?.expensedCurrencyId}
                  name={`expenseRecord[${index}].expensedCurrencyId`}
                  id={`expenseRecord[${index}].expensedCurrencyId`}
                  label="Currency"
                  options={currencyOptions}
                  fullWidth
                />
              </Grid>
              {formik.values?.reportingCurrencyId !== expense.expensedCurrencyId &&
                expense.expensedCurrencyId !== undefined && (
                  <Grid item xs={12} md={4}>
                    <InputField
                      error={Boolean(getIn(formik.errors, `expenseRecord[${index}].exchangeRate`))}
                      helperText={getIn(formik.errors, `expenseRecord[${index}].exchangeRate`)}
                      onChange={formik.handleChange}
                      onBlur={() => {
                        updateTotalExpensedAmount();
                      }}
                      value={expense?.exchangeRate}
                      name={`expenseRecord[${index}].exchangeRate`}
                      id={`expenseRecord[${index}].exchangeRate`}
                      inputProps={{ min: 1 }}
                      label="Exchange Rate"
                      type="number"
                      fullWidth
                    />
                  </Grid>
                )}

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(getIn(formik.errors, `expenseRecord[${index}].amount`))}
                  helperText={getIn(formik.errors, `expenseRecord[${index}].amount`)}
                  onChange={formik.handleChange}
                  onBlur={() => {
                    updateTotalExpensedAmount();
                  }}
                  value={expense?.amount}
                  name={`expenseRecord[${index}].amount`}
                  id={`expenseRecord[${index}].amount`}
                  inputProps={{ min: 0 }}
                  label="Amount*"
                  type="number"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(
                    getIn(formik.touched, `expenseRecord[${index}].billNumber`) &&
                      getIn(formik.errors, `expenseRecord[${index}].billNumber`),
                  )}
                  helperText={
                    getIn(formik.touched, `expenseRecord[${index}].billNumber`) &&
                    getIn(formik.errors, `expenseRecord[${index}].billNumber`)
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={expense.billNumber}
                  name={`expenseRecord[${index}].billNumber`}
                  id={`expenseRecord[${index}].billNumber`}
                  label="Bill Number"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <DatePicker
                  error={Boolean(getIn(formik.errors, `expenseRecord[${index}].recordDate`))}
                  helperText={
                    getIn(formik.touched, `expenseRecord[${index}].recordDate`) &&
                    getIn(formik.errors, `expenseRecord[${index}].recordDate`)
                  }
                  onChange={(date) => formik.setFieldValue(`expenseRecord[${index}].recordDate`, date)}
                  onBlur={formik.handleBlur}
                  value={formatDate(expense?.recordDate)}
                  name={`expenseRecord[${index}].recordDate`}
                  id={`expenseRecord[${index}].recordDate`}
                  label="Date*"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Dropdown
                  error={Boolean(
                    getIn(formik.touched, `expenseRecord[${index}].paymentMethod`) &&
                      getIn(formik.errors, `expenseRecord[${index}].paymentMethod`),
                  )}
                  helperText={
                    getIn(formik.touched, `expenseRecord[${index}].paymentMethod`) &&
                    getIn(formik.errors, `expenseRecord[${index}].paymentMethod`)
                  }
                  handleChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={expense.paymentMethod}
                  name={`expenseRecord[${index}].paymentMethod`}
                  id={`expenseRecord[${index}].paymentMethod`}
                  label="Payment Method"
                  options={paymentMethodOptions}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4} mt={2}>
                <Attachments
                  isDeleteIcon
                  handleChange={(event) => {
                    formik.setFieldValue(`expenseRecord[${index}].expenseRecordAttachments`, event.target.files[0]);
                  }}
                  name={`expenseRecord[${index}].expenseRecordAttachments`}
                  id={`expenseRecord[${index}].expenseRecordAttachments`}
                  value={expense.expenseRecordAttachments}
                  accept="application/pdf"
                  handleDelete={() => {
                    formik.setFieldValue(`expenseRecord[${index}].expenseRecordAttachments`, '');
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} mt={2}>
                {isEdit && (
                  <Typographys variant="caption" color="neutral.600">
                    Previously Associated Attachment:{' '}
                    {expense?.supportingDocuments?.[0]?.fileName || 'No Files Attached.'}
                  </Typographys>
                )}
              </Grid>
            </Grid>
            {expense.showAllocation && (
              <>
                <Divider sx={{ mt: 3, mb: 2, borderColor: theme.palette.other[200] }} />
                <FieldArray name={`expenseRecord[${index}].allocations`}>
                  {({ push: pushAllocation, remove: removeAllocation }) => (
                    <Grid container rowSpacing={2} columnSpacing={2}>
                      <Grid item xs={12} md={8}>
                        <Typographys variant="subtitle2" color="neutral.800">
                          Allocate to project
                        </Typographys>
                      </Grid>
                      <Grid item xs={12} md={4} sx={myExpensesStyle.iconGrid}>
                        <AddBoxIcon sx={myExpensesStyle.addBoxIcon} onClick={() => pushAllocation({})} />
                      </Grid>
                      {expense?.allocations?.map((a, i) => (
                        <>
                          <Grid item xs={12} md={4}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `expenseRecord[${index}].allocations[${i}].allocation`) &&
                                  getIn(formik.errors, `expenseRecord[${index}].allocations[${i}].allocation`),
                              )}
                              helperText={
                                getIn(formik.touched, `expenseRecord[${index}].allocations[${i}].allocation`) &&
                                getIn(formik.errors, `expenseRecord[${index}].allocations[${i}].allocation`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={a.allocation}
                              name={`expenseRecord[${index}].allocations[${i}].allocation`}
                              id={`expenseRecord[${index}].allocations[${i}].allocation`}
                              label="Allocation"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Dropdown
                              error={Boolean(
                                getIn(formik.touched, `expenseRecord[${index}].allocations[${i}].projects`) &&
                                  getIn(formik.errors, `expenseRecord[${index}].allocations[${i}].projects`),
                              )}
                              helperText={
                                getIn(formik.touched, `expenseRecord[${index}].allocations[${i}].projects`) &&
                                getIn(formik.errors, `expenseRecord[${index}].allocations[${i}].projects`)
                              }
                              handleChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={a.projects}
                              name={`expenseRecord[${index}].allocations[${i}].projects`}
                              id={`expenseRecord[${index}].allocations[${i}].projects`}
                              label="Projects"
                              options={projectsOptions}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} md={4} sx={myExpensesStyle.iconGrid}>
                            <DeleteIcon
                              sx={myExpensesStyle.deleteIcon}
                              onClick={() => {
                                removeAllocation(i);
                              }}
                            />
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  )}
                </FieldArray>
              </>
            )}
            {expense.showTagToMerchant && (
              <>
                <Divider sx={{ mt: 3, mb: 2, borderColor: theme.palette.other[200] }} />
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={12} md={12}>
                    <Typographys variant="subtitle2" color="neutral.800">
                      Tag to Vendor
                    </Typographys>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Dropdown
                      error={Boolean(
                        getIn(formik.touched, `expenseRecord[${index}].merchant`) &&
                          getIn(formik.errors, `expenseRecord[${index}].merchant`),
                      )}
                      helperText={
                        getIn(formik.touched, `expenseRecord[${index}].merchant`) &&
                        getIn(formik.errors, `expenseRecord[${index}].merchant`)
                      }
                      handleChange={(e) => {
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={expense.merchant}
                      name={`expenseRecord[${index}].merchant`}
                      id={`expenseRecord[${index}].merchant`}
                      label="Vendor"
                      options={merchantOptions}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4} sx={myExpensesStyle.iconGrid}>
                    <DeleteIcon
                      sx={myExpensesStyle.deleteIcon}
                      onClick={() => {
                        formik.setFieldValue(`expenseRecord[${index}].showTagToMerchant`, false);
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        ))}
    </Paper>
  );
};
ExpenseRecord.defaultProps = {
  totalExpensedAmount: 0,
  reimbursableAmount: 0,
  expenseCategoryOptions: [],
  currencyOptions: [],
  paymentMethodOptions: [],
  formik: {
    values: {
      expenseRecord: [{ allocations: [{}], reimbursed: 'on' }],
      startDate: '',
      endDate: '',
      defaultCurrency: '₹',
    },
    handleBlur: () => {},
    handleChange: () => {},
    setFieldValue: () => {},
    touched: {},
    errors: {},
  },
  projectsOptions: [],
  merchantOptions: [],
  push: () => {},
  remove: () => {},
  isEdit: false,
};

ExpenseRecord.propTypes = {
  totalExpensedAmount: PropTypes.number,
  reimbursableAmount: PropTypes.number,
  formik: PropTypes.shape({
    values: PropTypes.shape({
      expenseRecord: PropTypes.arrayOf(
        PropTypes.shape({
          allocations: PropTypes.shape({}),
          reimbursed: PropTypes.string,
        }),
      ),
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      defaultCurrency: PropTypes.string,
    }),
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    setFieldValue: PropTypes.func,
    touched: PropTypes.shape({}),
    errors: PropTypes.shape({}),
  }),
  expenseCategoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  currencyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  paymentMethodOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  projectsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  merchantOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  push: PropTypes.func,
  remove: PropTypes.func,
  isEdit: PropTypes.bool,
};

export default ExpenseRecord;
