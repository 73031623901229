import { Grid, Card, CardContent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useToast from 'components/Provider/useToast';
import { formDataApi } from 'api/api';
import BlankTemplate from 'components/Templates/BlankTemplate';
import PayHeadForm from './form';
import useFetchPayhead from '../ViewPayhead/useFetchPayhead';

const AddEditPayhead = (props) => {
  const params = useParams();
  const [payheadDetails, setPayheadDetails] = useState({
    data: {},
    isLoading: false,
  });
  const location = useLocation();
  const isEditMode = location.pathname.includes('edit');
  const { id } = useParams();
  const [payhead] = useFetchPayhead(id, 'edit', isEditMode);
  const addToast = useToast();

  useEffect(() => {
    const fetchData = async () => {
      setPayheadDetails({ ...payheadDetails, isLoading: true });
      const bodyFormData = new FormData();
      bodyFormData.append('id', params.id);
      const url = 'restPayIndex/createPayhead';
      try {
        const response = await formDataApi.post(url, bodyFormData);
        if (response?.data?.data) {
          setPayheadDetails({ data: response.data.data, isLoading: false });
        }
      } catch (err) {
        addToast({ type: 'error', title: 'ERROR', message: err?.message });
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, isEditMode, addToast]);

  const breadcrumbData = [
    { id: 1, name: 'Payheads', path: '/company/salary/payheads', isActive: false },
    { id: 2, name: `${isEditMode ? 'Edit Payhead' : 'Add Payhead'}`, isActive: true },
  ];

  return (
    <BlankTemplate
      pageHeading={isEditMode ? 'Edit Payhead' : 'Add Payhead'}
      breadcrumbData={breadcrumbData}
      padding="2rem 3rem"
    >
      <Grid item sx={{ pt: '0px !important' }}>
        <Card {...props}>
          <CardContent sx={{ maxHeight: 'calc(100vh - 140px)', overflowY: 'auto' }}>
            <Grid item sx={{ pt: 1 }}>
              <PayHeadForm
                results={payhead?.data?.data?.companyPayheadInstance}
                isEditMode={isEditMode}
                payheadDetails={payheadDetails}
              />
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </BlankTemplate>
  );
};

export default AddEditPayhead;
