/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, useTheme } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import TDSInstructionForm from './Form';

const TDSInstruction = ({ regimeOptions, allowEdit, regimeType, formik, employeeDeductionDeclarationInstance }) => {
  const theme = useTheme();
  return (
    <Box>
      <Box>
        <Typographys variant="subtitle1" fontWeight="600" color="neutral.800">
          NOTE: Please follow the below listed steps to make your annual declarations
        </Typographys>
        <Box mt={1}>
          <Typographys variant="body1" color="neutral.800" sx={{ pb: 1 }}>
            1. If you live in a rented property and if your compensation includes House Rent Allowance, please do fill
            in the rent paid/payable for each of the months in the tab &apos;House Rent Declarations&apos;.
          </Typographys>
          <Typographys variant="body1" color="neutral.800" sx={{ pb: 1 }}>
            2. If your annual rent paid for the property you live in currently is greater than 1,00,000/- it is
            mandatory to provide details of the Landlord (Name, PAN and address).
          </Typographys>

          <Typographys variant="body1" color="neutral.800" sx={{ pb: 1 }}>
            3. In case you live in your own property and are currently servicing a house loan for the same, please do
            specify all the relevant details as required in the tab &apos;House/Property/Other Income&apos;.
          </Typographys>
          <Typographys variant="body1" color="neutral.800" sx={{ pb: 1 }}>
            4. In case you have let out your property(ies) and are getting rental income from the same, please do
            specify all the relevant details as required in the tab &apos;House/Property/Other Income`&apos;. This
            should include any local municipal taxes paid for the property, rent recieved and interest paid for the
            corresponding house loan. The rental income to be declared is the annual rental income you expect to make
            from the property(ies).
          </Typographys>
          <Typographys variant="body1" color="neutral.800" sx={{ pb: 1 }}>
            5. In case you have any other additional income from other sources that you would like to declare, please do
            the same in the tab &apos;House/Property/Other Income&apos;.
          </Typographys>
          <Typographys variant="body1" color="neutral.800" sx={{ pb: 1 }}>
            6. All investments you intend to make/made already (such as insurance, medical check-ups, contributions etc)
            need to be updated in the tab &apos;Investment Declarations&apos;.
          </Typographys>
          <Typographys variant="body1" color="neutral.800" sx={{ pb: 1 }}>
            7. You can choose to make declarations either in the Old Tax Regime or the New Tax Regime. Please note that
            if you choose the New Tax Regime, no exemptions or deductions will be allowed under Section 16, Section 24
            or Chapter VIA.
          </Typographys>
        </Box>
        {allowEdit && !employeeDeductionDeclarationInstance?.isTaxRegimeInitialized ? (
          <TDSInstructionForm regimeOptions={regimeOptions} formik={formik} />
        ) : (
          <>
            <Divider sx={{ mt: 3, mb: 2, borderColor: theme.palette.other[200] }} />
            <Typographys variant="h5" color="neutral.800" fontWeight="500">
              Tax Regime - &nbsp;
              <Typographys variant="h5" color="neutral.800" fontWeight="600" display="inline">
                {regimeType}
              </Typographys>
            </Typographys>
          </>
        )}
      </Box>
    </Box>
  );
};

TDSInstruction.defaultProps = {
  regimeOptions: [],
  allowEdit: true,
  regimeType: '',
  formik: {
    handleChange: () => {},
    handleBlur: () => {},
    values: {
      property: [],
      houseRent: [],
      investmentDec: [],
    },
    error: {},
  },
};

TDSInstruction.propTypes = {
  regimeOptions: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, name: PropTypes.string, onClick: PropTypes.func }),
  ),
  allowEdit: PropTypes.bool,
  regimeType: PropTypes.string,
  formik: PropTypes.shape({
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    values: PropTypes.shape({
      regime: PropTypes.string,
    }),
  }),
};

export default TDSInstruction;
