import React, { useEffect } from 'react';
import { Box, Grid, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import Button from 'components/Atoms/Button';
import Typographys from 'components/Atoms/Typography';
import InputField from 'components/Atoms/InputField/index';
import Dropdown from 'components/Molecules/Dropdown';
import { useFormik } from 'formik';
import { useTheme } from '@mui/material/styles';
import Checkbox from 'components/Atoms/Checkbox';
import * as yup from 'yup';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { addEmpStyle } from '../style';

const UserIdentity = ({ tabHeading, handleSubmit, handleCancel, groupOptions, propData, label }) => {
  const SignupSchema = yup.object().shape({
    userName: yup.string().required('Required'),
    password: yup
      .string('Enter your password')
      .min(8, 'Password should be of minimum 8 characters')
      .required('Required'),
    employeeId: yup.string().required('Required'),
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required'),
  });
  const defaultValues = propData.allTabsData['User Identity'];
  let initialValues = {
    userName: '',
    password: '',
    employeeId: propData.allTabsData['User Identity']?.employeeId,
    firstName: '',
    lastName: '',
    middleName: '',
    group: '',
    accountExpired: [],
    accountLocked: [],
    accountEnabled: ['on'],
    passwordExpired: [],
    enable: propData.allTabsData['User Identity']?.employeeIdEnable,
  };

  if (defaultValues.userName) {
    initialValues = defaultValues;
  }

  const formik = useFormik({
    initialValues,

    validationSchema: SignupSchema,
    validateOnMount: true,
    onSubmit: handleSubmit,
  });
  const theme = useTheme();
  useEffect(() => {
    propData.updateFormData(formik.values, label);
  }, [formik.values, label]);
  return (
    <Box sx={{ p: '2rem 3rem' }}>
      <Box mt={2} component="form" onSubmit={formik.handleSubmit}>
        <PerfectScrollbar>
          <Typographys variant="h5" color="neutral.800">
            {tabHeading}
          </Typographys>
          <Box mt={2} sx={{ maxHeight: 'calc(100vh - 47rem)' }}>
            <Grid container rowSpacing={2} columnSpacing={9} sx={addEmpStyle.formContainer}>
              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.userName && formik.errors.userName)}
                  helperText={formik.touched.userName && formik.errors.userName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.userName}
                  name="userName"
                  id="userName"
                  label="User Name*"
                  fullWidth
                />
              </Grid>

              {!propData?.allowEdit && (
                <Grid item xs={12} md={4}>
                  <InputField
                    error={Boolean(formik.touched.password && formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    name="password"
                    id="password"
                    type="password"
                    label="Password*"
                    fullWidth
                  />
                </Grid>
              )}

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.employeeId && formik.errors.employeeId)}
                  helperText={formik.touched.employeeId && formik.errors.employeeId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.employeeId}
                  name="employeeId"
                  id="employeeId"
                  label="Employee ID*"
                  fullWidth
                  disabled={formik.values?.enable}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.firstName && formik.errors.firstName)}
                  helperText={formik.touched.firstName && formik.errors.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  name="firstName"
                  id="firstName"
                  label="First Name*"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.middleName && formik.errors.middleName)}
                  helperText={formik.touched.middleName && formik.errors.middleName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.middleName}
                  name="middleName"
                  id="middleName"
                  label="Middle Name"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.lastName && formik.errors.lastName)}
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                  name="lastName"
                  id="lastName"
                  label="Last Name*"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Dropdown
                  error={Boolean(formik.touched.group && formik.errors.group)}
                  helperText={formik.touched.group && formik.errors.group}
                  handleChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.group}
                  name="group"
                  id="group"
                  label="Groups"
                  options={groupOptions}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

            <Grid container rowSpacing={2.5} flexWrap="wrap" sx={addEmpStyle.formContainer}>
              <Grid item xs={12} md={3}>
                <Checkbox
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  key={formik.values.accountExpired}
                  checked={formik.values.accountExpired?.includes('on')}
                  color="secondary"
                  name="accountExpired"
                  label="Account Expired"
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <Checkbox
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  key={formik.values.accountLocked}
                  checked={formik.values.accountLocked?.includes('on')}
                  color="secondary"
                  name="accountLocked"
                  label="Account Locked"
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <Checkbox
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  key={formik.values.accountEnabled}
                  checked={formik.values.accountEnabled?.includes('on')}
                  color="secondary"
                  name="accountEnabled"
                  label="Account Enabled"
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <Checkbox
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  key={formik.values.passwordExpired}
                  checked={formik.values.passwordExpired?.includes('on')}
                  color="secondary"
                  name="passwordExpired"
                  label="Password Expired"
                />
              </Grid>
            </Grid>
          </Box>
        </PerfectScrollbar>
        <Box mt={5} sx={addEmpStyle.btnContainer}>
          <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

          <Box sx={addEmpStyle.submitWrapper}>
            <Button
              size="large"
              color="info"
              variant="outlined"
              type="cancel"
              onClick={() => {
                formik.resetForm();
                handleCancel();
              }}
            >
              Cancel
            </Button>

            <Button size="large" type="submit" isDisable={false}>
              {propData?.allowEdit ? 'Update' : 'Next'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

UserIdentity.defaultProps = {
  tabHeading: 'Basic Details',
  propData: {},
  handleSubmit: () => {},
  handleCancel: () => {},
  groupOptions: [],
  label: '',
};

UserIdentity.propTypes = {
  tabHeading: PropTypes.string,
  propData: PropTypes.shape({
    updateFormData: PropTypes.func,
    allTabsData: PropTypes.shape({
      'User Identity': PropTypes.shape({
        userName: PropTypes.string,
        employeeId: PropTypes.string,
        employeeIdEnable: PropTypes.bool,
      }),
    }),
    allowEdit: PropTypes.bool,
  }),
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  groupOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
  label: PropTypes.string,
};

export default UserIdentity;
