import React from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import ProfileEditAddresses from 'components/Organisms/Profile/Addresses/Edit';

const ProfileEditAddressesTemplate = ({ handleSubmit, handleCancel, defaultValue, loading }) => {
  const breadcrumbData = [
    { id: 1, name: 'Addresses', path: '/profile/addresses', isActive: false },
    { id: 2, name: 'Edit', isActive: true },
  ];
  return (
    <BlankTemplate
      pageHeading="Edit Addresses Details"
      isBreadcrumb
      breadcrumbData={breadcrumbData}
      padding="2rem 3rem"
    >
      <ProfileEditAddresses
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        defaultValue={defaultValue}
        loading={loading}
      />
    </BlankTemplate>
  );
};

ProfileEditAddressesTemplate.defaultProps = {
  handleSubmit: () => {},
  handleCancel: () => {},
  defaultValue: {},
  loading: false,
};

ProfileEditAddressesTemplate.propTypes = {
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  defaultValue: PropTypes.shape({}),
  loading: PropTypes.bool,
};

export default ProfileEditAddressesTemplate;
