export const policyListStyle = {
  wrapper: {
    borderTop: '0.125rem solid',
    borderColor: 'neutral.300',
    backgroundColor: 'common.white',
    padding: '2rem 3rem',
    minHeight: '77.5rem',
  },
  actionBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2.5rem',
  },
};
