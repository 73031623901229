import { useState } from 'react';
import useToast from 'components/Provider/useToast';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUser } from 'api/login';
import { updateUser } from 'store/slices/user';
import useDownloadFile from 'hooks/useDownloadFile';
import { acceptOfferAction, rejectOfferAction } from 'api/recruitment';

const useServices = () => {
  const addToast = useToast();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.session);
  const [refreshPage, setRefreshPage] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const downloadFile = useDownloadFile();

  const handleDialogClose = () => setOpenDialog(!openDialog);

  const handleAttachment = async (id, fileName) => {
    downloadFile(
      { candidateID: id, isDocVersion: false },
      fileName !== null ? fileName : '',
      'restRecruitmentIndex/downloadCandidateOffer',
    );
  };

  const handleOffer = async (values, id) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('candidateID', id);
      bodyFormData.append('comments', values?.comments);
      const offerTypeApi = values?.offerType === 'accept' ? acceptOfferAction : rejectOfferAction;
      const response = await offerTypeApi(bodyFormData);
      handleDialogClose();
      if (response.status === 'SUCCESS') {
        const currentUser = await getCurrentUser();
        dispatch(updateUser({ ...currentUser?.data, authorities: user?.authorities }));
        setRefreshPage(!refreshPage);
        addToast({ type: 'success', title: 'SUCCESS', message: response?.message });
      } else {
        addToast({ type: 'error', title: 'ERROR', message: response?.message });
      }
    } catch (err) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: err.message || `Error while ${values?.offerType}ing the offer`,
      });
    }
  };

  return {
    handleAttachment,
    handleOffer,
    refreshPage,
    openDialog,
    setOpenDialog,
    handleDialogClose,
  };
};

export default useServices;
