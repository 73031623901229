import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import Button from 'components/Atoms/Button';
import DropdownMenu from 'components/Molecules/DropdownMenu';
import EditIcon from '@mui/icons-material/Edit';
import TitleWithText from 'components/Atoms/TitleWithText';
import ChipWithStatus from 'components/Molecules/ChipWithStatus';
import { policyDetailsStyle } from './style';

const LCPolicyBasicDetail = ({ policyDetails, menuLists, handleClick }) => {
  return (
    <>
      <Box sx={policyDetailsStyle.actionBtn}>
        <Button startIcon={<EditIcon fontSize="2.5rem" />} onClick={() => handleClick(policyDetails)}>
          Edit
        </Button>
        <Box ml={1.5} sx={policyDetailsStyle.dropdown}>
          <DropdownMenu btnLabel="Actions" menuLists={menuLists} minWidth="10rem" maxWidth="10rem" />
        </Box>
      </Box>

      <Grid container>
        <Grid item xs="12" sm={4} md="3">
          <TitleWithText title="Name" text={policyDetails.name} />
        </Grid>
        <Grid item xs="12" sm={4} md="3">
          <TitleWithText title="Status" text={<ChipWithStatus isActive={policyDetails.status} />} />
        </Grid>
        <Grid item xs="12" sm={4} md="3">
          <TitleWithText title="Created on" text={policyDetails.createdOn} />
        </Grid>
        <Grid item xs="12" sm={4} md="3">
          <TitleWithText title="Created by" text={policyDetails.createdBy} />
        </Grid>
        <Grid item xs="12" sm={4} md="3">
          <TitleWithText title="Default Policy Administrator" text={policyDetails.defaultPolicyAdministrator} />
        </Grid>
        <Grid item xs="12" sm={4} md="4">
          <TitleWithText title="Description" text={policyDetails.description} />
        </Grid>
      </Grid>
    </>
  );
};

LCPolicyBasicDetail.defaultProps = {
  policyDetails: {
    name: 'Default Life Cycle Policy',
    status: true,
    createdOn: '22-Feb-2023',
    createdBy: 'Setup Admin(0)',
    defaultPolicyAdministrator: 'Aarzoo Khurana (T292)',
    description: 'Vel diam ultircies dignismems augue',
  },
  menuLists: [{ id: 1, label: 'Delete', onClick: () => {} }],
  handleClick: () => {},
};

LCPolicyBasicDetail.propTypes = {
  policyDetails: PropTypes.shape({
    name: PropTypes.string,
    status: PropTypes.string,
    createdOn: PropTypes.string,
    createdBy: PropTypes.string,
    defaultPolicyAdministrator: PropTypes.string,
    description: PropTypes.string,
  }),
  menuLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  handleClick: PropTypes.func,
};

export default LCPolicyBasicDetail;
