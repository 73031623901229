export const tdsStyle = {
  basicDetailsWrapper: {
    padding: '2rem 3rem',
  },

  coBorrowerWrapper: {
    maxWidth: '95rem',
  },

  actionBtn: {
    display: 'flex',
    gap: 1,
    justifyContent: 'flex-end',
  },

  tabsWrapper: {
    padding: 0,
    borderTop: '0.125rem solid ',
    borderColor: 'neutral.300',
  },

  investmentDeclarationTable: {
    '.MuiTable-root.MuiTable-root .MuiTableCell-root': {
      '&:nth-child(2)': {
        width: '45%',

        '@media screen and (max-width: 767px)': {
          minWidth: '45rem',
        },
      },
    },
  },

  textWithTooltip: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: 5,
  },

  instructionForm: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '2rem',
    mt: 3,

    '.MuiTypography-root': {
      width: '30rem',
    },

    '.MuiFormControl-root': {
      maxWidth: '34.375rem',
    },
  },

  houseRentTableWrapper: {
    mb: '13.5rem',

    '.MuiTable-root.MuiTable-root': {
      '.MuiTableCell-root': {
        minWidth: '16rem',

        '&:first-child': {
          minWidth: 'auto',
        },
        '&:nth-child(3)': {
          minWidth: '20rem',
        },
        '&:nth-child(5)': {
          minWidth: '25rem',
        },
      },
    },
  },

  btnContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%',
    backgroundColor: 'common.white',
    zIndex: '9',
    margin: '0rem',
    padding: '0rem 3rem 2rem 3rem',
    boxSizing: 'border-box',
  },

  submitWrapper: {
    display: 'flex',
    justifyContent: 'end',
    gap: 2,
  },
  msgWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    backgroundColor: 'info.200',
    justifyContent: 'space-between',
    padding: '1rem 2.5rem 1rem 1.5rem',
    my: 1.5,
    gap: 1,
  },

  msgActionBtn: {
    display: 'flex',
    columnGap: 1.25,

    '.MuiSvgIcon-root': {
      cursor: 'pointer',
      color: 'secondary.600',
      width: '2.5rem',
    },
  },

  listIcon: {
    display: 'flex',
    columnGap: 1.25,
    mt: 1.5,

    '.MuiBox-root': {
      pt: 0,
    },

    '.MuiAvatar-root': {
      width: '2.5rem',
      height: '2.5rem',
    },
  },

  headingWithActionBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: 1.25,
  },

  checkboxWithLabel: {
    display: 'flex',
    columnGap: 1,
    mt: 3.5,
  },

  checkboxWithLabelView: {
    display: 'flex',
    columnGap: 1,
    mt: 1,
  },

  tableActionBtn: {
    display: 'flex',
    gap: 1,
    justifyContent: 'space-between',

    button: {
      textAlign: 'left',
    },

    '.MuiSvgIcon-root': {
      cursor: 'pointer',
      color: 'actions.600',
      width: '2.5rem',
    },
  },
};
