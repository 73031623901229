import React from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import DocumentDetails from 'components/Organisms/Document/DocumentsDetails';
import { Box, Skeleton } from '@mui/material';

const DocumentDetailsTemplate = ({
  breadcrumbData,
  documentDetails,
  handleEdit,
  documentName,
  menuList,
  handleAttachment,
  documentOwnerName,
  loading,
}) => {
  return (
    <BlankTemplate pageHeading={documentName} isBreadcrumb breadcrumbData={breadcrumbData} padding="2rem 3rem">
      {loading ? (
        <Box sx={{ p: 4 }}>
          <Skeleton height={42} />
          <Skeleton />
          <Skeleton />
        </Box>
      ) : (
        <DocumentDetails
          handleAttachment={handleAttachment}
          documentDetails={documentDetails}
          handleEdit={handleEdit}
          menuList={menuList}
          documentOwnerName={documentOwnerName}
        />
      )}
    </BlankTemplate>
  );
};

DocumentDetailsTemplate.defaultProps = {
  breadcrumbData: [
    { id: 1, name: 'Documents', path: '/home/documents', isActive: false },
    { id: 2, name: 'Document Details', path: '', isActive: true },
  ],
  loading: false,
  handleAttachment: () => {},
  documentDetails: [],
  handleEdit: () => {},
  documentName: '',
  menuList: [
    { label: 'Submit', onClick: () => {} },
    { label: 'Download', onClick: () => {} },
    { label: 'Unpublish', onClick: () => {} },
    { label: 'Delete', onClick: () => {}, color: 'error.400' },
  ],
  documentOwnerName: '',
};

DocumentDetailsTemplate.propTypes = {
  breadcrumbData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
  handleAttachment: PropTypes.func,
  documentDetails: PropTypes.arrayOf,
  handleEdit: PropTypes.func,
  documentName: PropTypes.string,
  menuList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  documentOwnerName: PropTypes.string,
};

export default DocumentDetailsTemplate;
