import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { hasPermission } from 'utils/permissions';
import PropTypes from 'prop-types';
import { AlertContextProvider } from 'components/Provider/AlertToastContext';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Box, CssBaseline, useMediaQuery } from '@mui/material';

// project import
import HeaderwithSidebar from 'components/Organisms/HeaderwithSidebar';
import DialogflowMessenger from './ChatBot';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  backgroundColor: theme.palette.actions[100],
  padding: '3rem',
  marginLeft: theme.constants.miniSidebarWidth,
  minHeight: 'calc(100vh - 10.25rem)',
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
  },
  ...(open && {
    marginLeft: 338,
  }),
  background: theme.palette.actions[100],
  maxWidth: '100vw',
  overflow: 'auto',
}));

const MainLayout = ({ children }) => {
  const { user = {}, candidateID = '' } = useSelector((state) => state?.session);
  const authorities = user?.authorities || [];
  const userHasViewAlumniDashboard = hasPermission(authorities, [
    'ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_ALUMNI_DASHBOARD',
  ]);
  const userHasRoleAlumni = hasPermission(authorities, ['ROLE_ALUMNI']);
  const theme = useTheme();
  const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const leftDrawerOpened = useSelector((state) => state.customization.leftDrawerOpen);
  return (
    <Box>
      <CssBaseline />
      <HeaderwithSidebar title="Company" left="10rem" />
      <Main open={matchDownSm ? leftDrawerOpened : !leftDrawerOpened}>
        <AlertContextProvider>
          <Outlet />
        </AlertContextProvider>
        {children}
        {user?.chatBotEnabled && !userHasViewAlumniDashboard && !userHasRoleAlumni && !candidateID && (
          <DialogflowMessenger
            projectId="sustained-pod-407914"
            agentId="ae02fd42-32e9-4d84-bb9a-640d246d0932"
            chatTitle="Rise Help"
            user={user}
          />
        )}
      </Main>
    </Box>
  );
};

MainLayout.defaultProps = {
  children: null,
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;
