import PropTypes from 'prop-types';
import React from 'react';
import MuiTableSortLabel from '@mui/material/TableSortLabel';

const TableSortLabel = ({ children, active, direction, disableSorting, onClick }) => {
  if (disableSorting) {
    return { children };
  }
  return (
    <MuiTableSortLabel active={active} direction={direction} sx={{ width: 'max-content' }} onClick={onClick}>
      {children}
    </MuiTableSortLabel>
  );
};

TableSortLabel.propTypes = {
  children: PropTypes.node.isRequired,
  active: PropTypes.bool,
  direction: PropTypes.string,
  disableSorting: PropTypes.bool,
  onClick: PropTypes.func,
};

TableSortLabel.defaultProps = {
  active: false,
  direction: 'desc',
  disableSorting: false,
  onClick: () => {},
};
export default TableSortLabel;
