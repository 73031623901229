import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Typographys from 'components/Atoms/Typography';
import Button from 'components/Atoms/Button';
import DownloadIcon from '@mui/icons-material/Download';
import SalaryDetailsTable from 'components/Organisms/MyPay/SalaryDetails/SalaryDetailsTable';
import useDownloadFile from 'hooks/useDownloadFile';
import { useReactToPrint } from 'react-to-print';
import UserFnfDetails from './UserFnfDetails';
import { alumniDashboardStyle } from '../style';

const FnfDetails = ({
  employeeData,
  employeeName,
  employeeId,
  earningsRows,
  earningsColumns,
  deductionsRows,
  deductionsColumns,
  loansAndAdvancesRows,
  loansAndAdvancesColumns,
  grossEarningsColumn,
  totalDeductionsColumn,
  netSalaryColumns,
  fnfRemarks,
  fnfSalSlipId,
  netFinalEarnings,
  netFinalDeductions,
  netFinalSalary,
  userHasPrintSalarySlipPermission,
}) => {
  const theme = useTheme();
  const componentRef = useRef();
  const downloadFile = useDownloadFile();

  const handlePrintFnF = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${employeeName}_Payslip`, // Optional: Customize the print document title
  });

  const handleDownloadFnF = () => {
    downloadFile({ id: fnfSalSlipId }, 'FnFSlip.pdf', 'restPayIndex/printMySalaryDetailsPDF');
  };

  return (
    <Box ref={componentRef} pl={1.5} pr={1.5}>
      <UserFnfDetails employeeData={employeeData} employeeId={employeeId} employeeName={employeeName} />
      <Divider sx={{ mt: 2, mb: 0.5, borderColor: theme.palette.other[200] }} />
      <Box>
        <Typographys variant="body2" color="neutral.800">
          {`Net Salary = Net Earnings - Net Deductions + Arrears (if any):
          ${netFinalEarnings} - ${netFinalDeductions} = ${netFinalSalary}`}
        </Typographys>
      </Box>
      <Divider sx={{ mt: 0.5, mb: 1.5, borderColor: theme.palette.other[200] }} />

      {!!earningsRows?.length && (
        <Box mt={2}>
          <SalaryDetailsTable rows={earningsRows} columns={earningsColumns} />
        </Box>
      )}
      {!!deductionsRows?.length && (
        <Box mt={2}>
          <SalaryDetailsTable rows={deductionsRows} columns={deductionsColumns} />
        </Box>
      )}
      {!!loansAndAdvancesRows?.length && (
        <Box mt={2}>
          <SalaryDetailsTable rows={loansAndAdvancesRows} columns={loansAndAdvancesColumns} />
        </Box>
      )}
      <Box mt={2}>
        <SalaryDetailsTable columns={grossEarningsColumn} />
      </Box>
      <Box mt={2}>
        <SalaryDetailsTable columns={totalDeductionsColumn} />
      </Box>
      <Box mt={2}>
        <SalaryDetailsTable columns={netSalaryColumns} />
      </Box>
      <Box mt={2}>
        <Divider sx={{ borderColor: theme.palette.other[200], margin: ' 3rem 0 2rem 0' }} />
        <Typographys variant="h6" fontWeight={600} sx={{ display: 'block', textTransform: 'uppercase' }}>
          Remarks: {fnfRemarks}
        </Typographys>
        <Divider sx={{ borderColor: theme.palette.other[200], margin: ' 2rem 0 0rem 0' }} />
        {!!userHasPrintSalarySlipPermission && (
          <Box sx={alumniDashboardStyle.actionBtnGroup} className="no-print">
            <Button startIcon={<DownloadIcon fontSize="2.5rem" />} onClick={handleDownloadFnF}>
              Download
            </Button>
            <Button onClick={handlePrintFnF} variant="outlined">
              Print FnF Slip
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

FnfDetails.defaultProps = {
  employeeData: {},
  employeeId: '',
  employeeName: '',
  earningsRows: [],
  earningsColumns: [],
  deductionsRows: [],
  deductionsColumns: [],
  loansAndAdvancesRows: [],
  loansAndAdvancesColumns: [],
  grossEarningsColumn: '',
  totalDeductionsColumn: '',
  netSalaryColumns: '',
  fnfRemarks: '',
};

FnfDetails.propTypes = {
  employeeData: PropTypes.shape({
    joiningDate: PropTypes.string,
    effectiveFrom: PropTypes.string,
    effectiveTo: PropTypes.string,
    CTCBased: PropTypes.string,
    salaryStructureStatus: PropTypes.string,
    payStructure: PropTypes.string,
  }),
  employeeId: PropTypes.string,
  employeeName: PropTypes.string,

  earningsRows: PropTypes.arrayOf(
    PropTypes.shape({
      earnings: PropTypes.string,
      subLabel: PropTypes.string,
      annualAmount: PropTypes.string,
      perPeriodAmount: PropTypes.string,
    }),
  ),
  earningsColumns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      columnTitle: PropTypes.string,
      columnWidth: PropTypes.string,
    }),
  ),
  deductionsRows: PropTypes.arrayOf(PropTypes.shape({})),
  deductionsColumns: PropTypes.arrayOf(PropTypes.shape({})),
  loansAndAdvancesRows: PropTypes.arrayOf(PropTypes.shape({})),
  loansAndAdvancesColumns: PropTypes.arrayOf(PropTypes.shape({})),
  grossEarningsColumn: PropTypes.string,
  totalDeductionsColumn: PropTypes.string,
  netSalaryColumns: PropTypes.string,
  fnfRemarks: PropTypes.string,
};

export default FnfDetails;
