import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import { policyListItemStyle } from './style';

const LCPolicyListItem = ({ heading, subHeading, isActive, handleClick }) => {
  return (
    <Box
      sx={policyListItemStyle.item}
      backgroundColor={isActive ? 'primary.100' : 'common.white'}
      onClick={handleClick}
    >
      <Typographys fontWeight="500" color={isActive ? 'primary.300' : 'neutral.800'}>
        {heading}
      </Typographys>
      <Typographys fontWeight="500" color={isActive ? 'primary.200' : 'neutral.600'}>
        {subHeading}
      </Typographys>
    </Box>
  );
};
LCPolicyListItem.defaultProps = {
  heading: 'Default Life Cycle Policy',
  subHeading: '45 employees',
  isActive: false,
  handleClick: () => {},
};

LCPolicyListItem.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  isActive: PropTypes.bool,
  handleClick: PropTypes.func,
};

export default LCPolicyListItem;
