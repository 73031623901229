import React from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import EmployeeExitDetails from 'components/Organisms/Profile/InitiateSeparation/EmployeeExitDetails';

const EmployeeExitDetailsTemplate = ({
  exitDetails,
  handleEdit,
  auditLogData,
  updatedLog,
  handleView,
  rows,
  columns,
  rowsPerPage,
  totalRecordCount,
  minimumRecordsLength,
  handleWithdrawSeparation,
}) => {
  return (
    <BlankTemplate pageHeading="Employee Exit Details" isBreadcrumb={false} padding="1rem 2rem">
      <EmployeeExitDetails
        exitDetails={exitDetails}
        handleEdit={handleEdit}
        auditLogData={auditLogData}
        updatedLog={updatedLog}
        handleView={handleView}
        rows={rows}
        columns={columns}
        rowsPerPage={rowsPerPage}
        totalRecordCount={totalRecordCount}
        minimumRecordsLength={minimumRecordsLength}
        handleWithdrawSeparation={handleWithdrawSeparation}
      />
    </BlankTemplate>
  );
};

EmployeeExitDetailsTemplate.defaultProps = {
  exitDetails: {
    employeeName: '',
    separationType: '',
    noticePeriod: '',
    resignationStatus: '',
    submissionDate: '',
    lastWorkingDay: '',
    requestedRelievingDate: '',
    approvedRelievingDate: 'null',
    resignationReasons: '',
    resignationAcceptingSupervisor: '',
    supervisorResignationAcceptanceDate: '',
    resignationAcceptingHR: '',
    HRResignationAcceptanceDate: '',
    exitInterviewDate: '',
    exitInterviewer: '',
    avatarSrc: '',
    name: '',
    date: '',
  },
  handleEdit: () => {},
  auditLogData: [],
  updatedLog: {},
  handleView: () => {},
  rowsPerPage: null,
  totalRecordCount: null,
  minimumRecordsLength: null,
  rows: [],
  columns: [],
  handleWithdrawSeparation: () => {},
};

EmployeeExitDetailsTemplate.propTypes = {
  exitDetails: PropTypes.shape({
    employeeName: PropTypes.string,
    separationType: PropTypes.string,
    noticePeriod: PropTypes.string,
    resignationStatus: PropTypes.string,
    submissionDate: PropTypes.string,
    lastWorkingDay: PropTypes.string,
    requestedRelievingDate: PropTypes.string,
    approvedRelievingDate: PropTypes.string,
    resignationReasons: PropTypes.string,
    resignationAcceptingSupervisor: PropTypes.string,
    supervisorResignationAcceptanceDate: PropTypes.string,
    resignationAcceptingHR: PropTypes.string,
    HRResignationAcceptanceDate: PropTypes.string,
    exitInterviewDate: PropTypes.string,
    exitInterviewer: PropTypes.string,
    avatarSrc: PropTypes.string,
    name: PropTypes.string,
    date: PropTypes.string,
  }),
  handleEdit: PropTypes.func,

  auditLogData: PropTypes.arrayOf({}),

  updatedLog: PropTypes.shape({
    updatedName: PropTypes.string,
    updatedDate: PropTypes.string,
  }),

  handleView: PropTypes.func,
  rowsPerPage: PropTypes.number,
  totalRecordCount: PropTypes.number,
  minimumRecordsLength: PropTypes.number,
  rows: PropTypes.arrayOf,
  columns: PropTypes.arrayOf,
  handleWithdrawSeparation: PropTypes.func,
};

export default EmployeeExitDetailsTemplate;
