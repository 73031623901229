import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Divider, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Button from 'components/Atoms/Button';
import InputField from 'components/Atoms/InputField';
import Typographys from 'components/Atoms/Typography';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { FieldArray, getIn, Formik } from 'formik';
import DatePicker from 'components/Atoms/Datepicker';
import Attachments from 'components/Atoms/Attachments';
import * as yup from 'yup';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { genericMobileRegex, genericEmailRegex } from 'utils/commonUtils';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';

import { onBoardingStyle } from '../../../style';

const VisaDetail = ({ handleCancel, propData, label, handleSubmit }) => {
  const defaultObj = {
    placeOfIssue: '',
    validFrom: '',
    validTo: '',
    issueDate: '',
    referenceNumber: '',
    visaStatus: '',
    comments: '',
    attachment: '',

    passportNumber: '',
    passportIssueDate: '',
    passportExpDate: '',
    passportIssuePlace: '',
    passportCountry: '',
    passportGivenName: '',
    passportAttachments: '',
    isNew: true,
  };
  const defaultValues = propData.allTabsData[label];

  const visaValidationSchema = yup.object().shape({
    visa: yup.array().of(
      yup.object().shape({
        passportNumber: yup
          .string()
          .matches(/^[A-Za-z0-9]+$/, 'Invalid passport number')
          .max(9, 'Invalid passport number'),

        referenceNumber: yup.string().matches(genericMobileRegex, 'Invalid format, Please enter valid mobile number.'),

        passportIssueDate: yup
          .date()
          .test(
            'missingPassportIssueDate',
            'Missing date fields',
            (passportIssueDate, { parent: { passportExpDate } }) => {
              return passportIssueDate || Boolean(!passportExpDate);
            },
          ),

        passportExpDate: yup
          .date()
          .test(
            'missingPassportExpDate',
            'Missing date fields',
            (passportExpDate, { parent: { passportIssueDate } }) => {
              return passportExpDate || Boolean(!passportIssueDate);
            },
          )
          .min(yup.ref('passportIssueDate'), "Passport expiry date can't be before passport issue date"),

        validFrom: yup
          .date()
          .test('missingvalidFrom', 'Missing date fields', (validFrom, { parent: { validTo, issueDate } }) => {
            return validFrom || Boolean(!validTo && !issueDate);
          }),

        validTo: yup
          .date()
          .test('missingvalidTo', 'Missing date fields', (validTo, { parent: { validFrom, issueDate } }) => {
            return validTo || Boolean(!validFrom && !issueDate);
          }),

        issueDate: yup
          .date()
          .test('missingissueDate', 'Missing date fields', (issueDate, { parent: { validTo, validFrom } }) => {
            return issueDate || Boolean(!validFrom && !validTo);
          })

          .test(
            'passportIssueDate',
            "Visa issue date can't be before passport issue date",
            (issueDate, { parent: { passportIssueDate } }) => {
              return passportIssueDate <= issueDate || !issueDate || !passportIssueDate;
            },
          )

          .test(
            'expiryDate',
            "Visa issue date can't be after passport expiry date",
            (issueDate, { parent: { passportExpDate } }) => {
              return passportExpDate >= issueDate || !issueDate || !passportExpDate;
            },
          )
          .test(
            'validFrom',
            "Visa issue date can't be after visa valid from",
            (issueDate, { parent: { validFrom } }) => {
              return validFrom >= issueDate || !issueDate || !validFrom;
            },
          )
          .test('validTo', "Visa issue date can't be after visa valid to", (issueDate, { parent: { validTo } }) => {
            return validTo >= issueDate || !issueDate || !validTo;
          }),
      }),
    ),
  });

  return (
    <Box mt={1.5}>
      <Formik
        initialValues={
          defaultValues.visa.length > 0 && Object.keys(defaultValues.visa[0]).length
            ? defaultValues
            : { visa: [{ key: 1 }] }
        }
        onSubmit={handleSubmit}
        validationSchema={visaValidationSchema}
      >
        {(formik) => (
          <Form formik={formik} handleCancel={handleCancel} propData={propData} label={label} defaultObj={defaultObj} />
        )}
      </Formik>
    </Box>
  );
};

const Form = ({ formik, handleCancel, propData, label, defaultObj }) => {
  const [keyCount, setKeyCount] = useState(2);
  useEffect(() => {
    propData.updateFormData(formik.values, label);
  }, [formik.values, label]);

  const handleDateChange = (date, setFieldValue, fieldName) => {
    setFieldValue(fieldName, date);
  };

  const theme = useTheme();
  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 59rem)' }}>
          <Box sx={{ mb: 2 }}>
            <Typographys variant="h5" color="neutral.800">
              Visa/Passport Details
            </Typographys>
          </Box>

          <Typographys variant="h6" color="neutral.800">
            Passport Details
          </Typographys>

          <Grid container rowSpacing={2} columnSpacing={9} sx={onBoardingStyle.formContainer}>
            <Grid item xs={12} md={6}>
              <InputField
                error={Boolean(formik.touched.passportNumber && formik.errors.passportNumber)}
                helperText={formik.touched.passportNumber && formik.errors.passportNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik?.values?.passportNumber}
                name="passportNumber"
                id="passportNumber"
                label="Passport Number"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <DatePicker
                error={Boolean(formik.touched.passportIssueDate && formik.errors.passportIssueDate)}
                helperText={formik.touched.passportIssueDate && formik.errors.passportIssueDate}
                onChange={(date) => handleDateChange(date, formik.setFieldValue, 'passportIssueDate')}
                onBlur={formik.handleBlur}
                value={formik.values?.passportIssueDate}
                name="passportIssueDate"
                id="passportIssueDate"
                label="Passport Issue Date"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <DatePicker
                error={Boolean(formik.touched.passportExpDate && formik.errors.passportExpDate)}
                helperText={formik.touched.passportExpDate && formik.errors.passportExpDate}
                onChange={(date) => handleDateChange(date, formik.setFieldValue, 'passportExpDate')}
                onBlur={formik.handleBlur}
                value={formik.values?.passportExpDate}
                minDate={formik.values?.passportIssueDate}
                name="passportExpDate"
                id="passportExpDate"
                label="Passport Expiry Date"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <InputField
                error={Boolean(formik.touched.passportIssuePlace && formik.errors.passportIssuePlace)}
                helperText={formik.touched.passportIssuePlace && formik.errors.passportIssuePlace}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik?.values?.passportIssuePlace}
                name="passportIssuePlace"
                id="passportIssuePlace"
                label="Passport Place Of Issue"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <InputField
                error={Boolean(formik.touched.passportCountry && formik.errors.passportCountry)}
                helperText={formik.touched.passportCountry && formik.errors.passportCountry}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik?.values?.passportCountry}
                name="passportCountry"
                id="passportCountry"
                label="Passport Country"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <InputField
                error={Boolean(formik.touched.passportGivenName && formik.errors.passportGivenName)}
                helperText={formik.touched.passportGivenName && formik.errors.passportGivenName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik?.values?.passportGivenName}
                name="passportGivenName"
                id="passportGivenName"
                label="Passport Name Given"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Attachments
                handleChange={(event) => {
                  formik.setFieldValue(`passportAttachments`, event.target.files[0]);
                }}
                name="passportAttachments"
                id="passportAttachments"
                value={formik?.values?.passportAttachments}
              />
            </Grid>
          </Grid>

          <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

          <Typographys variant="h6" color="neutral.800">
            Visa Details
          </Typographys>

          <FieldArray name="visa">
            {({ push, remove }) => (
              <Box>
                {formik?.values?.visa.map((e, index) => {
                  return (
                    <Accordion key={e.key} disableGutters elevation={0} square sx={onBoardingStyle.accordionWrapper}>
                      <AccordionSummary
                        aria-controls="visa-content"
                        id="visarecord-header"
                        expandIcon={<KeyboardArrowDownSharpIcon sx={{ fontSize: '3rem', color: 'neutral.800' }} />}
                      >
                        <Box sx={onBoardingStyle.deleteContainer}>
                          <Typography variant="subtitle2" color="neutral.800">
                            Record {index + 1}
                          </Typography>
                          <DeleteOutlineSharpIcon sx={{ color: 'neutral.600' }} onClick={() => remove(index)} />
                        </Box>
                      </AccordionSummary>

                      <AccordionDetails>
                        <Grid container rowSpacing={2} columnSpacing={9} pt={0} sx={onBoardingStyle.formContainer}>
                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `visa[${index}].issuingCountry`) &&
                                  getIn(formik.errors, `visa[${index}].issuingCountry`),
                              )}
                              helperText={
                                getIn(formik.touched, `visa[${index}].issuingCountry`) &&
                                getIn(formik.errors, `visa[${index}].issuingCountry`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.issuingCountry}
                              name={`visa[${index}].issuingCountry`}
                              id={`visa[${index}].issuingCountry`}
                              label="Issuing Country"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `visa[${index}].placeOfIssue`) &&
                                  getIn(formik.errors, `visa[${index}].placeOfIssue`),
                              )}
                              helperText={
                                getIn(formik.touched, `visa[${index}].placeOfIssue`) &&
                                getIn(formik.errors, `visa[${index}].placeOfIssue`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.placeOfIssue}
                              name={`visa[${index}].placeOfIssue`}
                              id={`visa[${index}].placeOfIssue`}
                              label="Place of Issue"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <DatePicker
                              error={Boolean(
                                getIn(formik.touched, `visa[${index}].validFrom`) &&
                                  getIn(formik.errors, `visa[${index}].validFrom`),
                              )}
                              helperText={
                                getIn(formik.touched, `visa[${index}].validFrom`) &&
                                getIn(formik.errors, `visa[${index}].validFrom`)
                              }
                              onChange={(date) =>
                                handleDateChange(date, formik.setFieldValue, `visa[${index}].validFrom`)
                              }
                              onBlur={formik.handleBlur}
                              value={e?.validFrom}
                              minDate={formik?.values?.passportIssueDate}
                              maxDate={formik?.values?.passportExpDate}
                              name={`visa[${index}].validFrom`}
                              id={`visa[${index}].validFrom`}
                              label="Valid From"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <DatePicker
                              error={Boolean(
                                getIn(formik.touched, `visa[${index}].validTo`) &&
                                  getIn(formik.errors, `visa[${index}].validTo`),
                              )}
                              helperText={
                                getIn(formik.touched, `visa[${index}].validTo`) &&
                                getIn(formik.errors, `visa[${index}].validTo`)
                              }
                              onChange={(date) =>
                                handleDateChange(date, formik.setFieldValue, `visa[${index}].validTo`)
                              }
                              onBlur={formik.handleBlur}
                              value={e?.validTo}
                              minDate={formik?.values?.visa[index].validFrom}
                              maxDate={formik?.values?.passportExpDate}
                              name={`visa[${index}].validTo`}
                              id={`visa[${index}].validTo`}
                              label="Valid To"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <DatePicker
                              error={Boolean(
                                getIn(formik.touched, `visa[${index}].issueDate`) &&
                                  getIn(formik.errors, `visa[${index}].issueDate`),
                              )}
                              helperText={
                                getIn(formik.touched, `visa[${index}].issueDate`) &&
                                getIn(formik.errors, `visa[${index}].issueDate`)
                              }
                              onChange={(date) =>
                                handleDateChange(date, formik.setFieldValue, `visa[${index}].issueDate`)
                              }
                              onBlur={formik.handleBlur}
                              value={e?.issueDate}
                              minDate={formik?.values?.passportIssueDate}
                              maxDate={formik?.values?.visa[index].validFrom}
                              name={`visa[${index}].issueDate`}
                              id={`visa[${index}].issueDate`}
                              label="Issue Date"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `visa[${index}].referenceNumber`) &&
                                  getIn(formik.errors, `visa[${index}].referenceNumber`),
                              )}
                              helperText={
                                getIn(formik.touched, `visa[${index}].referenceNumber`) &&
                                getIn(formik.errors, `visa[${index}].referenceNumber`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.referenceNumber}
                              name={`visa[${index}].referenceNumber`}
                              id={`visa[${index}].referenceNumber`}
                              label="Reference Number"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `visa[${index}].visaStatus`) &&
                                  getIn(formik.errors, `visa[${index}].visaStatus`),
                              )}
                              helperText={
                                getIn(formik.touched, `visa[${index}].visaStatus`) &&
                                getIn(formik.errors, `visa[${index}].visaStatus`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.visaStatus}
                              name={`visa[${index}].visaStatus`}
                              id={`visa[${index}].visaStatus`}
                              label="Visa Status"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `visa[${index}].comments`) &&
                                  getIn(formik.errors, `visa[${index}].comments`),
                              )}
                              helperText={
                                getIn(formik.touched, `visa[${index}].comments`) &&
                                getIn(formik.errors, `visa[${index}].comments`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.comments}
                              name={`visa[${index}].comments`}
                              id={`visa[${index}].comments`}
                              label="Comments"
                              fullWidth
                              multiline
                            />
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <Attachments
                              isDeleteIcon
                              handleChange={(event) => {
                                formik.setFieldValue(`visa[${index}].attachments`, event.target.files[0]);
                              }}
                              name={`visa[${index}].attachments`}
                              id={`visa[${index}].attachments`}
                              value={e.attachments}
                              handleDelete={() => {
                                formik.setFieldValue(`visa[${index}].attachments`, '');
                              }}
                            />
                            <Grid item xs={12} md={8} mt={2}>
                              {e?.attachments?.length > 0 && (
                                <Typographys variant="caption" color="neutral.600">
                                  Previously Associated Attachment: {e?.attachments?.[0]?.fileName || ''}
                                </Typographys>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}

                <Box sx={{ mt: 3 }}>
                  <Button
                    size="medium"
                    variant="outlined"
                    type="button"
                    startIcon={<AddOutlinedIcon />}
                    onClick={() => {
                      push({ ...defaultObj, key: keyCount });
                      setKeyCount(keyCount + 1);
                    }}
                  >
                    Add Visa
                  </Button>
                </Box>
              </Box>
            )}
          </FieldArray>
        </Box>
      </PerfectScrollbar>

      <Box sx={onBoardingStyle.btnContainer}>
        <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

        <Box sx={onBoardingStyle.submitWrapper}>
          <Button
            size="large"
            color="info"
            variant="outlined"
            type="button"
            onClick={() => {
              formik.resetForm();
              handleCancel();
            }}
          >
            Cancel
          </Button>

          <LoadingButton
            loading={propData.propData.loading}
            color="primary"
            size="large"
            type="submit"
            variant="contained"
          >
            Save & Continue
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

Form.defaultProps = {
  handleCancel: () => {},
  label: '',
  propData: {},
  formik: {},
  defaultObj: {},
};

Form.propTypes = {
  propData: PropTypes.shape({
    loading: PropTypes.bool,
    updateFormData: PropTypes.func,
    allTabsData: PropTypes.shape({
      'Visa Details': PropTypes.shape({
        visa: PropTypes.shape([]),
      }),
    }),
    allowEdit: PropTypes.bool,
  }),
  handleCancel: PropTypes.func,

  label: PropTypes.string,
  formik: PropTypes.shape({
    errors: PropTypes.shape({
      passportNumber: PropTypes.string,
      passportIssueDate: PropTypes.string,
      passportExpDate: PropTypes.string,
      passportIssuePlace: PropTypes.string,
      passportCountry: PropTypes.string,
      passportGivenName: PropTypes.string,
      passportAttachments: PropTypes.string,
    }),
    touched: PropTypes.shape({
      passportNumber: PropTypes.string,
      passportIssueDate: PropTypes.string,
      passportExpDate: PropTypes.string,
      passportIssuePlace: PropTypes.string,
      passportCountry: PropTypes.string,
      passportGivenName: PropTypes.string,
      passportAttachments: PropTypes.string,
    }),
    values: PropTypes.shape({
      passportNumber: PropTypes.string,
      passportIssueDate: PropTypes.string,
      passportExpDate: PropTypes.string,
      passportIssuePlace: PropTypes.string,
      passportCountry: PropTypes.string,
      passportGivenName: PropTypes.string,
      passportAttachments: PropTypes.string,
      visa: PropTypes.shape([]),
    }),
    resetForm: PropTypes.func,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    setFieldValue: PropTypes.func,
  }),
  defaultObj: PropTypes.shape({}),
};

VisaDetail.defaultProps = {
  handleSubmit: () => {},
  handleCancel: () => {},
  label: 'Visa Details',
  propData: {
    allTabsData: {
      'Visa Details': {
        visa: [
          {
            placeOfIssue: '',
            validFrom: '',
            validTo: '',
            issueDate: '',
            referenceNumber: '',
            visaStatus: '',
            comments: '',
            attachment: '',
          },
        ],

        passportNumber: '',
        passportIssueDate: '',
        passportExpDate: '',
        passportIssuePlace: '',
        passportCountry: '',
        passportGivenName: '',
        passportAttachments: '',
      },
    },
    updateFormData: () => {},
  },
};

VisaDetail.propTypes = {
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  propData: PropTypes.shape({
    allTabsData: PropTypes.shape({}),
    updateFormData: PropTypes.func,
  }),
  label: PropTypes.string,
};

export default VisaDetail;
