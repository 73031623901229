import React from 'react';
import PropTypes from 'prop-types';
import PieCharts from 'components/Organisms/PieCharts';
import { Box, Grid } from '@mui/material';
import TitleWithText from 'components/Atoms/TitleWithText';
import GraphColor from 'utils/GraphColor';
import { policyGraphStyle } from './style';

const GraphWithComponentDetail = ({ graphData, graphColors, graphBgColor }) => {
  return (
    <Box sx={policyGraphStyle.wrapper} bgcolor={graphBgColor}>
      <Grid container>
        <Grid item xs={12} sm={2} md={2}>
          <PieCharts width={100} height={100} innerRadius={35} outerRadius={50} data={graphData} colors={graphColors} />
        </Grid>
        <Grid item xs={12} sm={10} md={10}>
          <Grid container>
            <Grid item xs={4} sm={4} md={4}>
              <Box sx={policyGraphStyle.inviteCard}>
                <TitleWithText
                  title={graphData[0].value}
                  titleVariant="h1"
                  titleColor="secondary.600"
                  text={graphData[0].name}
                  textVariant="h6"
                  textAlign="center"
                  textColor="secondary.600"
                  titleGap={0}
                  padding="0 1.5rem"
                />
              </Box>
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <Box sx={policyGraphStyle.pendingCard}>
                <TitleWithText
                  title={graphData[1].value}
                  titleVariant="h1"
                  titleColor="secondary.400"
                  text={graphData[1].name}
                  textVariant="h6"
                  textAlign="center"
                  textColor="secondary.600"
                  titleGap={0}
                  padding="0 1.5rem"
                />
              </Box>
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <Box sx={policyGraphStyle.onboardingCard}>
                <TitleWithText
                  title={graphData[2].value}
                  titleVariant="h1"
                  titleColor="success.300"
                  text={graphData[2].name}
                  textVariant="h6"
                  textAlign="center"
                  textColor="success.600"
                  titleGap={0}
                  padding="0 1.5rem"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

GraphWithComponentDetail.defaultProps = {
  graphData: [
    { name: 'Invite Sent', value: 12 },
    { name: 'Pending invites', value: 8 },
    { name: 'Onboarding Initated', value: 4 },
  ],
  graphColors: GraphColor('empMappingGraph'),
  graphBgColor: 'neutral.100',
};

GraphWithComponentDetail.propTypes = {
  graphData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  graphColors: PropTypes.shape({}),
  graphBgColor: PropTypes.string,
};

export default GraphWithComponentDetail;
