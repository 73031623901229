import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Avatars from 'components/Molecules/Avatar';
import Typographys from 'components/Atoms/Typography';
import { validateDateElseHyphen } from 'utils/commonUtils';

const AuditTrailCard = ({ avatarSrc, description, date, content }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
      <Avatars avatarSrc={avatarSrc} variant="circular" sx={{ flexShrink: 0 }} />
      <Box pl={1.75}>
        <Typographys variant="body2" color="neutral.900">
          {description}
        </Typographys>
        <Box mt="-0.25rem">
          {content.length > 0 &&
            content.map((item) => (
              <Typographys variant="body2" color="neutral.600">
                {item}
              </Typographys>
            ))}
        </Box>
        <Box mt="-0.25rem">
          <Typographys variant="caption" color="neutral.900">
            {validateDateElseHyphen(date, 'DD MMMM, YYYY h:mm A')}
          </Typographys>
        </Box>
      </Box>
    </Box>
  );
};

AuditTrailCard.defaultProps = {
  avatarSrc: 'images/user.jpg',
  description: 'Uday kumar',
  date: '07 May 2020 00:03:21 IST',
  content: [],
};

AuditTrailCard.propTypes = {
  avatarSrc: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string,
  content: PropTypes.arrayOf(PropTypes.string),
};

export default AuditTrailCard;
