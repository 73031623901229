import React, { useState } from 'react';
import EmployeeFilterBar from 'components/Organisms/Employee/EmployeeFilterBar';
import TableExtended from 'components/Molecules/TableExtended';
import NoRecordsMessage from 'components/Organisms/CommonPageUtils/NoRecordsFound';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Alert from 'components/Atoms/Alert';
import Typographys from 'components/Atoms/Typography';
import Button from 'components/Atoms/Button';
import { TosterStyle } from './style';

const EmployeeList = ({
  loading,
  rows,
  columns,
  showActionList,
  count,
  onPageChange,
  roleBasedMenu,
  handleSort,
  handleRowClick,
  page,
  sortBy,
  sortDirection,
  handleSelect,
  selectedItems,
  onSelect,
  onSelectAll,
  showRowSelector,
  showPagination,
  keyColumn,
  onClickAddNew,
  onClickBulkUpload,
  onClickSendESSInvite,
  onClickDownload,
  onClickActive,
  onClickDisable,
  handleAdvanceSearch,
  menuList,
}) => {
  const [successMessage, setSuccessMessage] = useState(null);

  return (
    <Box px={3} py={2}>
      <Box sx={{ marginBottom: '3rem' }}>
        <EmployeeFilterBar
          onClickAddNew={onClickAddNew}
          onClickBulkUpload={onClickBulkUpload}
          onClickSendESSInvite={onClickSendESSInvite}
          onClickDownload={onClickDownload}
          onClickActive={onClickActive}
          onClickDisable={onClickDisable}
          handleAdvanceSearch={handleAdvanceSearch}
          menuList={menuList}
        />
      </Box>

      {!loading && rows.length === 0 ? (
        <NoRecordsMessage width="20rem" height="20rem" />
      ) : (
        <TableExtended
          loading={loading}
          columns={columns}
          rows={rows}
          count={count}
          selectedItems={selectedItems}
          onSelect={onSelect}
          onSelectAll={onSelectAll}
          showRowSelector={showRowSelector}
          showPagination={showPagination}
          onPageChange={onPageChange}
          handleSort={handleSort}
          onRowClick={handleRowClick}
          page={page}
          keyColumn={keyColumn}
          roleBasedMenu={roleBasedMenu}
          handleSelect={handleSelect}
          showActionList={showActionList}
          sortBy={sortBy}
          sortDirection={sortDirection}
        />
      )}
      {successMessage && (
        <Box sx={TosterStyle.alertBox}>
          <Alert
            severity="success"
            titleText={successMessage.title}
            message={
              <>
                <Typographys color="neutral.800" component="div">
                  {successMessage.disc}
                </Typographys>
                {successMessage?.button && (
                  <Box mt={1}>
                    <Button variant="text" color="secondary" size="small" onClick={successMessage.button.click}>
                      {successMessage.button.label}
                    </Button>
                  </Box>
                )}
              </>
            }
            closeLabel={false}
            onClose={() => setSuccessMessage(null)}
          />
        </Box>
      )}
    </Box>
  );
};
EmployeeList.defaultProps = {
  loading: false,
  rows: [],
  columns: [],
  showActionList: false,
  count: 10,
  handleSort: () => {},
  handleRowClick: () => {},
  page: 0,
  sortBy: '',
  sortDirection: 'asc',
  handleSelect: () => {},
  selectedItems: [],
  onSelect: () => {},
  onSelectAll: () => {},
  showRowSelector: false,
  showPagination: false,
  keyColumn: '',
  onPageChange: (page) => {},
  roleBasedMenu: [],
  onClickAddNew: () => {},
  onClickBulkUpload: () => {},
  onClickSendESSInvite: () => {},
  onClickDownload: () => {},
  onClickActive: () => {},
  onClickDisable: () => {},
  handleAdvanceSearch: () => {},
};
EmployeeList.propTypes = {
  loading: PropTypes.bool,
  columns: PropTypes.arrayOf,
  showActionList: PropTypes.bool,
  count: PropTypes.number,
  handleSort: PropTypes.func,
  handleRowClick: PropTypes.func,
  page: PropTypes.number,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  handleSelect: PropTypes.func,
  selectedItems: PropTypes.arrayOf,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  showRowSelector: PropTypes.bool,
  showPagination: PropTypes.bool,
  keyColumn: PropTypes.string,
  onPageChange: PropTypes.func,
  roleBasedMenu: PropTypes.arrayOf,
  handleAdvanceSearch: PropTypes.func,
  onClickAddNew: PropTypes.func,
  onClickBulkUpload: PropTypes.func,
  onClickSendESSInvite: PropTypes.func,
  onClickDownload: PropTypes.func,
  onClickActive: PropTypes.func,
  onClickDisable: PropTypes.func,
  rows: PropTypes.arrayOf,
};
export default EmployeeList;
