const columns = [
  {
    fieldName: 'slNo',
    numeric: true,
    label: 'sl no',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'payPeriod',
    label: 'pay period',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'grossEarnings',
    label: 'gross earnings',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'deductions',
    label: 'deductions',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'netEarnings',
    label: 'net earnings',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
];

const useColumns = () => {
  return [columns];
};

export default useColumns;
