import React, { useCallback, useEffect } from 'react';
import { Box, Card, CardContent, Container, Grid, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import Table from 'components/mui/Table';
import TableShimmer from 'components/mui/TableShimmer';
import useController from 'hooks/useController';
import Button from 'components/Atoms/Button';
import { useSelector } from 'react-redux';
import usePaginationAttributes from 'hooks/use-pagination-attributes';
import { fetchChallans } from 'store';
import { useThunk } from 'hooks/useThunk';
import useColumns from './useColumns';
import { styles } from './styles';
import { Filters } from './challanFilter';

const List = () => {
  const navigate = useNavigate();
  const [controller, handlePageChange, handleSortChange, handleFiltersApply, handleFiltersClear] = useController();
  const [columns] = useColumns();
  const { challans } = useSelector((state) => state.challans);
  const { pageSize } = usePaginationAttributes();

  const [doFetchChallans, isLoadingChallans, loadingChallansError] = useThunk(fetchChallans);

  const fetchChallansAction = useCallback(() => {
    const query = new URLSearchParams({});
    if (controller) {
      query.append('sort', controller.sortBy);
      query.append('order', controller.sort);
    }
    query.append('offset', controller.page * pageSize);
    query.append('max', pageSize);
    doFetchChallans(query);
  }, [doFetchChallans, controller, pageSize]);

  useEffect(() => {
    fetchChallansAction();
  }, [fetchChallansAction, controller, pageSize]);

  if (loadingChallansError !== null) {
    return <Typography variant="h6">{loadingChallansError?.message || 'Error while loading challan list.'}</Typography>;
  }

  return (
    <Box sx={styles.growBox}>
      <Container maxWidth="lg">
        <Grid container direction="column">
          <Grid item container justifyContent="flex-start" alignItems="center" sx={{ pb: 1.5 }}>
            <Grid item>
              <Typography variant="h6" color="text.primary">
                Challans
              </Typography>
            </Grid>
          </Grid>
          <Grid item sx={{ maxWidth: '100% !important' }}>
            <Card>
              <CardContent>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ pb: 2 }}>
                  <Filters
                    disabled={isLoadingChallans}
                    filters={controller.filters}
                    onFiltersApply={handleFiltersApply}
                    onFiltersClear={handleFiltersClear}
                  />
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => navigate('/company/salary/challans/add')}
                    startIcon={<AddIcon />}
                  >
                    Add New
                  </Button>
                </Stack>
                {isLoadingChallans ? (
                  <TableShimmer columns={columns} />
                ) : (
                  <Table
                    columns={columns}
                    rows={challans.data}
                    keyColumn="id"
                    sortDirection={controller?.sort}
                    sortBy={controller?.sortBy}
                    onSortChange={handleSortChange}
                    page={controller?.page}
                    onPageChange={handlePageChange}
                    count={challans.count}
                    showPagination
                    onRowClick={(row) => navigate(`/company/salary/challans/view/${row.id}`)}
                    onActionCallback={fetchChallansAction}
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default List;
