import React from 'react';
import PropTypes from 'prop-types';
import MuiTab from 'components/Molecules/Tab';
import { Box, Divider, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Button from 'components/Atoms/Button';
import { Formik } from 'formik';
import * as yup from 'yup';
import { genericMobileRegex, genericEmailRegex, aadhaarRegex, panRegex } from 'utils/commonUtils';
import BasicProfile from './BasicProfile';
import ContactDetails from './ContactDetails';
import AdvanceProfile from './AdvanceProfile';
import { profileStyle } from '../../style';

const EditProfile = ({
  handleCancel,
  handleSubmit,
  defaultValues,
  bloodgroupOptions,
  maritalStatusOptions,
  loading,
}) => {
  const theme = useTheme();
  const isAadharAndPanMandatory = defaultValues?.makeAadhaarAndPANMandatory ? '*' : '';
  const isPassportDetailsMandatory = defaultValues?.makePassportDetailsMandatory ? '*' : '';

  const initialTabs = [
    {
      label: 'Basic Profile',
      onChange: () => {},
      RenderComponent: BasicProfile,
      componentProps: {
        maritalStatusOptions,
        defaultValues,
        isAadharAndPanMandatory,
      },
    },
    {
      label: 'Contact Details',
      onChange: () => {},
      RenderComponent: ContactDetails,
      componentProps: {},
    },
    {
      label: 'Advance Profile',
      onChange: () => {},
      RenderComponent: AdvanceProfile,
      componentProps: { bloodgroupOptions, isPassportDetailsMandatory },
    },
  ];

  const ValidationSchema = yup.object().shape({
    fatherName: yup.string().required('Required'),
    maritalStatus: yup.string().required('Required'),
    mobileNumber: yup
      .array()
      .of(yup.string().matches(genericMobileRegex, 'Invalid format, Please enter valid mobile number.')),
    emailAddress: yup
      .array()
      .of(yup.string().matches(genericEmailRegex, 'Invalid format, Please enter valid email ID.')),
    emergencyMobile: yup.string().matches(genericMobileRegex, 'Invalid format, Please enter valid mobile number.'),
    emergencyEmail: yup.string().matches(genericEmailRegex, 'Invalid format, Please enter valid email ID.'),
    aadharNumber: defaultValues?.makeAadhaarAndPANMandatory
      ? yup.string().matches(aadhaarRegex, 'Invalid format, Please enter valid aadhaar number.').required('Required')
      : yup.string().matches(aadhaarRegex, 'Invalid format, Please enter valid aadhaar number.'),

    panNumber: defaultValues?.makeAadhaarAndPANMandatory
      ? yup.string().matches(panRegex, 'Invalid format, Please enter valid pan number.').required('Required')
      : yup.string().matches(panRegex, 'Invalid format, Please enter valid pan number.'),
    passportnumber: defaultValues?.makePassportDetailsMandatory ? yup.string().required('Required') : yup.string(),
    passportissuedate: defaultValues?.makePassportDetailsMandatory ? yup.string().required('Required') : yup.string(),
    passportexpirydate: defaultValues?.makePassportDetailsMandatory ? yup.string().required('Required') : yup.string(),
    passportplaceissue: defaultValues?.makePassportDetailsMandatory ? yup.string().required('Required') : yup.string(),
    passportcountry: defaultValues?.makePassportDetailsMandatory ? yup.string().required('Required') : yup.string(),
    passportnamegiven: defaultValues?.makePassportDetailsMandatory ? yup.string().required('Required') : yup.string(),
  });

  return (
    <Formik
      initialValues={defaultValues}
      validationSchema={ValidationSchema}
      validateOnMount
      onSubmit={(values, actions) => {
        handleSubmit(values);
        actions.setSubmitting(false);
      }}
    >
      {(formik) => (
        <Box component="form" onSubmit={formik.handleSubmit}>
          <MuiTab
            key={initialTabs}
            orientation="horizontal"
            variant="scrollable"
            scrollButtons={false}
            textColor="primary"
            indicatorColor="primary"
            tabs={initialTabs}
            allowScrollButtonsMobile
            disableChangingTabs
            handleSubmit={() => {}}
            propData={{ formik }}
            panelPadding="2rem 0rem"
          />

          <Box sx={profileStyle.btnContainer}>
            <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

            <Box sx={profileStyle.submitWrapper}>
              <Button
                size="large"
                color="info"
                variant="outlined"
                type="cancel"
                onClick={() => {
                  formik.resetForm();
                  handleCancel();
                }}
              >
                Cancel
              </Button>

              <LoadingButton loading={loading} color="primary" size="large" type="submit" variant="contained">
                Update
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      )}
    </Formik>
  );
};
EditProfile.defaultProps = {
  defaultValues: {
    mobileNumber: [''],
    emailAddress: [''],
    name: '',
    relation: '',
    emergencyMobile: '',
    emergencyEmail: '',
    maritalStatus: '',
    fatherName: '',
    motherName: '',
    spouseName: '',
    bloodgroup: '',
    skypeid: '',
    facebookid: '',
    linkedinid: '',
    googlePlusid: '',
    twitterid: '',
    instagramid: '',
    selfdescription: '',
    passportnumber: '',
    passportissuedate: '',
    passportexpirydate: '',
    passportplaceissue: '',
    passportcountry: '',
    passportnamegiven: '',
    makeAadhaarAndPANMandatory: false,
    makePassportDetailsMandatory: false,
  },
  maritalStatusOptions: [],
  bloodgroupOptions: [],
  handleCancel: () => {},
  handleSubmit: () => {},
  loading: false,
};

EditProfile.propTypes = {
  defaultValues: PropTypes.shape({
    makeAadhaarAndPANMandatory: PropTypes.bool,
    makePassportDetailsMandatory: PropTypes.bool,
  }),
  bloodgroupOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  maritalStatusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
};
export default EditProfile;
