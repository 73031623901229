import { createSlice } from '@reduxjs/toolkit';
import {
  fetchMyPendingLeaves,
  fetchMyLeaveList,
  fetchMyLeaveDetails,
  fetchMyLeaveAccounts,
  fetchModifyLeaveRequest,
  fetchLeavesCreateData,
} from 'store/thunks/leaves/myLeaves';

const myLeaves = createSlice({
  name: 'myLeaves',
  initialState: {
    selectedMyLeave: null,
    selectedMyLeaves: [],
    myLeaves: {
      count: 0,
      data: [],
      leaveCategories: [],
    },
    myTeamLeaves: {
      count: 0,
      data: [],
    },
    myLeaveAccounts: {
      count: 0,
      data: [],
    },
    myLeaveDetails: [],
    loading: false,
    createLeavesData: {},
    modifyLeaveRequestData: {},
  },
  reducers: {
    updateSelectedLeave(state, action) {
      state.selectedMyLeave = action.payload;
    },
    updateMyTeamLeave(state, action) {
      const { leaveRequestInstanceList = [], leaveRequestInstanceCount, leaveAccounts = [] } = action.payload;
      if (!state.myTeamLeaves) {
        state.myTeamLeaves = {
          count: 0,
          data: [],
        };
      }
      state.myTeamLeaves.data = leaveRequestInstanceList;
      state.myTeamLeaves.count = leaveRequestInstanceCount;
      state.teamLeaveAccounts = leaveAccounts;
    },
    updateSelectedLeaves(state, action) {
      const { isChecked, rowId } = action.payload;

      if (isChecked) {
        state.selectedMyLeaves.push(rowId);
        return;
      }
      state.selectedMyLeaves = state.selectedMyLeaves.filter((selectedRow) => selectedRow !== rowId);
    },
    updateAllSelectedDocuments(state, action) {
      const { isChecked } = action.payload;
      state.selectedMyLeaves = [];
      if (isChecked) {
        state.myLeaves.data.map((row) => state.selectedMyLeaves.push(row.id));
        return;
      }
      state.selectedMyLeaves = [];
    },
    updateMyLeaves(state, action) {
      const { leaveRequestInstanceList = [], leaveRequestInstanceCount, leaveCategories } = action.payload;
      state.myLeaves.data = leaveRequestInstanceList;
      state.myLeaves.count = leaveRequestInstanceCount;
    },
    updateLoadingState(state, action) {
      state.loading = !state.loading;
    },
  },

  extraReducers(builder) {
    builder.addCase(fetchMyLeaveList.fulfilled, (state, action) => {
      const { leaveRequestInstanceList = [], leaveRequestInstanceCount, leaveCategories } = action.payload;
      state.myLeaves.data = leaveRequestInstanceList;
      state.myLeaves.count = leaveRequestInstanceCount;
      state.myLeaves.leaveCategories = leaveCategories;
    });
    builder.addCase(fetchMyPendingLeaves.fulfilled, (state, action) => {
      const { leaveRequestInstanceList = [], leaveRequestInstanceCount, leaveCategories } = action.payload;
      state.myLeaves.data = leaveRequestInstanceList;
      state.myLeaves.count = leaveRequestInstanceCount;
      state.myLeaves.leaveCategories = leaveCategories;
    });
    builder.addCase(fetchMyLeaveAccounts.fulfilled, (state, action) => {
      const { leaveAccounts = [] } = action.payload;
      state.myLeaveAccounts.data = leaveAccounts;
      state.myLeaveAccounts.count = leaveAccounts?.length;
    });
    builder.addCase(fetchMyLeaveDetails.fulfilled, (state, action) => {
      const { leaveRequestInstance } = action.payload;
      state.myLeaveDetails = leaveRequestInstance;
    });
    builder.addCase(fetchModifyLeaveRequest.fulfilled, (state, action) => {
      state.modifyLeaveRequestData = action.payload?.data;
    });

    builder.addCase(fetchLeavesCreateData.fulfilled, (state, action) => {
      state.createLeavesData = action.payload?.data;
    });
  },
});

export const {
  updateMyLeaves,
  updateMyTeamLeave,
  updateSelectedLeave,
  updateSelectedLeaves,
  updateAllSelectedDocuments,
  updateLoadingState,
} = myLeaves.actions;

export default myLeaves.reducer;
