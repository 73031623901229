import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import TDSDetails from 'components/Organisms/MyPay/TDS/View';
import useToast from 'components/Provider/useToast';
import { getToastMessage } from 'utils/toastMessage';
import { useLocation } from 'react-router-dom';

const TDSTemplate = ({
  allowEdit,
  menuLists,
  basicDetails,
  handleEdit,
  regimeType,
  regimeOptions,
  cityOption,
  houseRentDataRow,
  houseRentFormSortDirection,
  houseRentFormSortBy,
  houseRentFormOnSortChange,
  houseRentTableSortDirection,
  houseRentTableSortBy,
  houseRentTableOnSortChange,
  otherIncomeDetails,
  propertyList,
  form12bAttachment,
  downloadForm12bAttachment,
  declarationDetails,
  downloadAttachment,
  handleFile,
  investmentDeclarationFormSortDirection,
  investmentDeclarationFormSortBy,
  investmentDeclarationFormOnSortChange,
  investmentDeclarationDataSortDirection,
  investmentDeclarationDataSortBy,
  investmentDeclarationDataOnSortChange,
  auditLogData,
  handleSearch,
  dateOption,
  startDate,
  endDate,
  propertyTypeOption,
  handleSubmit,
  handleCancel,
  propertyData,
  toastSuccessMessage,
  investmentDeclarations,
  toastErrorMessage,
  showToast,
  toastType,
  updateButtonDisabled,
  employeeDeductionDeclarationInstance,
  userHasProofSubmitPermission,
  isNewTaxRegime,
  isEditMode,
}) => {
  const addToast = useToast();
  const [breadcrumbData, setBreadcrumbData] = useState([]);
  const [pageHeading, setPageHeading] = useState('');
  useEffect(() => {
    if (showToast) {
      const successMessage = toastSuccessMessage || getToastMessage.MyPayTds()[0];
      const error = toastErrorMessage || getToastMessage.MyPayTds()[1];

      const title = toastType === 'success' ? 'Success!' : 'Error!';
      const type = toastType === 'success' ? 'success' : 'error';
      const message = toastType === 'success' ? successMessage : error;
      addToast({ type, title, message });
    }
  }, [showToast, toastErrorMessage, toastSuccessMessage, toastType]);

  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;

    const isEditPath = pathname.includes('/tds-declaration/edit');

    const newBreadcrumbData = isEditPath
      ? [
          { id: 1, name: 'Dashboard', path: '/home/mypay/dashboard', isActive: false },
          { id: 2, name: ' Modify Declaration ', isActive: true },
        ]
      : [
          { id: 1, name: 'Dashboard', path: '/home/mypay/dashboard', isActive: false },
          { id: 2, name: 'Declaration Details for TDS', isActive: true },
        ];
    const newPageHeading = isEditPath ? 'Modify Declaration' : 'Declaration Details for TDS';
    setBreadcrumbData(newBreadcrumbData);
    setPageHeading(newPageHeading);
  }, [location]);
  return (
    <BlankTemplate pageHeading={pageHeading} breadcrumbData={breadcrumbData} isBreadcrumb padding="0">
      <TDSDetails
        allowEdit={allowEdit}
        menuLists={menuLists}
        basicDetails={basicDetails}
        handleEdit={handleEdit}
        regimeType={regimeType}
        regimeOptions={regimeOptions}
        cityOption={cityOption}
        houseRentDataRow={houseRentDataRow}
        houseRentFormSortDirection={houseRentFormSortDirection}
        houseRentFormSortBy={houseRentFormSortBy}
        houseRentFormOnSortChange={houseRentFormOnSortChange}
        houseRentTableSortDirection={houseRentTableSortDirection}
        houseRentTableSortBy={houseRentTableSortBy}
        houseRentTableOnSortChange={houseRentTableOnSortChange}
        otherIncomeDetails={otherIncomeDetails}
        propertyList={propertyList}
        employeeDeductionDeclarationInstance={employeeDeductionDeclarationInstance}
        investmentDeclarations={investmentDeclarations}
        form12bAttachment={form12bAttachment}
        downloadForm12bAttachment={downloadForm12bAttachment}
        declarationDetails={declarationDetails}
        downloadAttachment={downloadAttachment}
        handleFile={handleFile}
        investmentDeclarationFormSortDirection={investmentDeclarationFormSortDirection}
        investmentDeclarationFormSortBy={investmentDeclarationFormSortBy}
        investmentDeclarationFormOnSortChange={investmentDeclarationFormOnSortChange}
        investmentDeclarationDataSortDirection={investmentDeclarationDataSortDirection}
        investmentDeclarationDataSortBy={investmentDeclarationDataSortBy}
        investmentDeclarationDataOnSortChange={investmentDeclarationDataOnSortChange}
        auditLogData={auditLogData}
        handleSearch={handleSearch}
        dateOption={dateOption}
        startDate={startDate}
        endDate={endDate}
        propertyTypeOption={propertyTypeOption}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        propertyData={propertyList}
        updateButtonDisabled={updateButtonDisabled}
        userHasProofSubmitPermission={userHasProofSubmitPermission}
        isNewTaxRegime={isNewTaxRegime}
        isEditMode={isEditMode}
      />
    </BlankTemplate>
  );
};

TDSTemplate.defaultProps = {
  allowEdit: false,
  basicDetails: {},
  handleEdit: () => {},
  menuLists: [],
  regimeType: '',
  regimeOptions: [],
  cityOption: [],
  houseRentDataRow: [],
  houseRentFormSortDirection: 'desc',
  houseRentFormSortBy: 'id',
  houseRentFormOnSortChange: () => {},
  houseRentTableSortDirection: 'desc',
  houseRentTableSortBy: 'id',
  houseRentTableOnSortChange: () => {},
  otherIncomeDetails: {},
  form12bAttachment: '',
  downloadForm12bAttachment: () => {},
  declarationDetails: [],
  downloadAttachment: () => {},
  handleFile: () => {},
  investmentDeclarationFormSortDirection: 'desc',
  investmentDeclarationFormSortBy: 'id',
  investmentDeclarationFormOnSortChange: () => {},
  investmentDeclarationDataSortDirection: 'desc',
  investmentDeclarationDataSortBy: 'id',
  investmentDeclarationDataOnSortChange: () => {},
  auditLogData: [],
  handleSearch: () => {},
  dateOption: [],
  startDate: '',
  endDate: '',
  handleSubmit: () => {},
  handleCancel: () => {},
  propertyTypeOption: [],
  propertyData: [],
  toastSuccessMessage: '',
  toastErrorMessage: '',
  showToast: false,
  toastType: 'success',
  updateButtonDisabled: false,
};

TDSTemplate.propTypes = {
  allowEdit: PropTypes.bool,
  basicDetails: PropTypes.shape({
    employeeName: PropTypes.string,
    employeeID: PropTypes.string,
    designation: PropTypes.string,
    department: PropTypes.string,
    financialYear: PropTypes.string,
    assessmentYear: PropTypes.string,
    panNumber: PropTypes.string,
    aadhaarNumber: PropTypes.string,
    dateOfJoining: PropTypes.string,
    submissionsDeclaration: PropTypes.string,
    submissionsStatus: PropTypes.string,
    submissionsActionOwner: PropTypes.string,
    submissionsActionOwnerId: PropTypes.string,
    submissionAllowed: PropTypes.string,
    proofSubmissionStatus: PropTypes.string,
    proofSubmissionActionOwner: PropTypes.string,
    proofSubmissionActionOwnerId: PropTypes.string,
    approvedAmountStatus: PropTypes.string,
  }),
  handleEdit: PropTypes.func,
  menuLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  regimeType: PropTypes.string,
  regimeOptions: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, name: PropTypes.string, onClick: PropTypes.func }),
  ),
  houseRentDataRow: PropTypes.arrayOf(
    PropTypes.shape({
      slNo: PropTypes.number,
      period: PropTypes.string,
      landlordName: PropTypes.string,
      landlordPan: PropTypes.string,
      landlordAddress: PropTypes.string,
      city: PropTypes.string,
      rentPaid: PropTypes.number,
    }),
  ),
  houseRentFormSortDirection: PropTypes.string,
  houseRentFormSortBy: PropTypes.string,
  houseRentFormOnSortChange: PropTypes.func,
  houseRentTableSortDirection: PropTypes.string,
  houseRentTableSortBy: PropTypes.string,
  houseRentTableOnSortChange: PropTypes.func,
  cityOption: PropTypes.arrayOf(
    PropTypes.shape(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string, onClick: PropTypes.func })),
  ),
  otherIncomeDetails: PropTypes.shape({ otherSources: PropTypes.number }),
  form12bAttachment: PropTypes.string,
  downloadForm12bAttachment: PropTypes.func,
  declarationDetails: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      proof: PropTypes.string,
    }),
  ),
  downloadAttachment: PropTypes.func,
  handleFile: PropTypes.func,
  investmentDeclarationFormSortDirection: PropTypes.func,
  investmentDeclarationFormSortBy: PropTypes.string,
  investmentDeclarationFormOnSortChange: PropTypes.string,
  investmentDeclarationDataSortDirection: PropTypes.func,
  investmentDeclarationDataSortBy: PropTypes.string,
  investmentDeclarationDataOnSortChange: PropTypes.string,
  auditLogData: PropTypes.arrayOf(
    PropTypes.shape(
      PropTypes.shape({
        id: PropTypes.num,
        auditLog: PropTypes.shape({
          avatarSrc: PropTypes.string,
          name: PropTypes.string,
          date: PropTypes.string,
        }),

        updatedLog: PropTypes.shape({
          updatedName: PropTypes.string,
          updatedDate: PropTypes.string,
        }),
        handleLog: PropTypes.func,
        handleEdit: PropTypes.func,
        handleAccept: PropTypes.func,
        handleDelete: PropTypes.func,
        rowsPerPage: PropTypes.num,
        totalRecordCount: PropTypes.num,
        minimumRecordsLength: PropTypes.num,
        auditLogRows: PropTypes.arrayOf(
          PropTypes.shape({
            fieldName: PropTypes.string,
            oldValue: PropTypes.string,
            newValue: PropTypes.string,
          }),
        ),
      }),
    ),
  ),
  dateOption: PropTypes.arrayOf(
    PropTypes.shape(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string, onClick: PropTypes.func })),
  ),
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  handleSearch: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  propertyTypeOption: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })),
  propertyData: PropTypes.arrayOf(
    PropTypes.shape({
      propertyType: PropTypes.num,
      propertyName: PropTypes.string,
      address: PropTypes.string,
      constructionCompletedOn: PropTypes.string,
      loanAmount: PropTypes.number,
      loanBorrowedOn: PropTypes.string,
      lenderName: PropTypes.string,
      lenderPAN: PropTypes.string,
      jointlyOwnedProperty: PropTypes.string,
      coBorrower1Name: PropTypes.string,
      coBorrower1Pan: PropTypes.string,
      coBorrower1Relationship: PropTypes.string,
      coBorrower2Name: PropTypes.string,
      coBorrower2Pan: PropTypes.string,
      coBorrower2Relationship: PropTypes.string,
      coBorrower3Name: PropTypes.string,
      coBorrower3Pan: PropTypes.string,
      coBorrower3Relationship: PropTypes.string,
      emiInterest1Year: PropTypes.number,
      emiInterest2Year: PropTypes.number,
      percentageOfInterestRepaid: PropTypes.string,
      interest: PropTypes.number,
      lettableValue: PropTypes.number,
      taxPaidToLocalAuthorities: PropTypes.number,
      standardDeductionOnLettableValue: PropTypes.number,
      interestPaidOnHousingLoan: PropTypes.number,
      netIncomeFromHouse: PropTypes.number,
      declarationProperty: PropTypes.string,
    }),
  ),
  toastSuccessMessage: PropTypes.string,
  toastErrorMessage: PropTypes.string,
  showToast: PropTypes.bool,
  toastType: PropTypes.oneOf(['error', 'success']),
  updateButtonDisabled: PropTypes.bool,
};

export default TDSTemplate;
