import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import LeaveCardGroup from './LeaveCardGroup';
import LeaveTransactions from './LeaveTransactions';

const LeaveDetails = ({
  openingLeave,
  leavesAvailed,
  transactions,
  pendingApproval,
  remainingBalance,
  loading,
  leaveTransactionsColumn,
  leaveTransactionsRow,
  page,
  sortDirection,
  sortBy,
  handleSort,
  count,
  onPageChange,
  onSortChange,
}) => {
  return (
    <Box>
      <LeaveCardGroup
        openingLeave={openingLeave}
        leavesAvailed={leavesAvailed}
        transactions={transactions}
        pendingApproval={pendingApproval}
        remainingBalance={remainingBalance}
      />
      <Box mt={2}>
        <LeaveTransactions
          loading={loading}
          leaveTransactionsColumn={leaveTransactionsColumn}
          leaveTransactionsRow={leaveTransactionsRow}
          page={page}
          sortDirection={sortDirection}
          sortBy={sortBy}
          handleSort={handleSort}
          count={count}
          onPageChange={onPageChange}
          onSortChange={onSortChange}
        />
      </Box>
    </Box>
  );
};

LeaveDetails.defaultProps = {
  openingLeave: 24,
  leavesAvailed: 12,
  transactions: 3,
  pendingApproval: 4,
  remainingBalance: 12,
  loading: false,
  leaveTransactionsColumn: [],
  leaveTransactionsRow: [],
  handleSort: () => {},
  sortBy: () => {},
  sortDirection: 'asc',
  count: 6,
  page: 1,
  onPageChange: () => {},
  onSortChange: () => {},
};

LeaveDetails.propTypes = {
  openingLeave: PropTypes.number,
  leavesAvailed: PropTypes.number,
  transactions: PropTypes.number,
  pendingApproval: PropTypes.number,
  remainingBalance: PropTypes.number,
  loading: PropTypes.bool,
  leaveTransactionsColumn: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),
  leaveTransactionsRow: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      leavesAvailed: PropTypes.string,
      leavesAccounted: PropTypes.number,
      leavesDeducted: PropTypes.number,
    }),
  ),
  sortBy: PropTypes.func,
  handleSort: PropTypes.func,
  sortDirection: PropTypes.string,
  count: PropTypes.number,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  onSortChange: PropTypes.func,
};

export default LeaveDetails;
