const GraphColor = (type) => {
  const colors = {
    pieCharts: ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'],
    genderGraphs: ['#215263', '#0F9292', '#86C8C8', '#F0F9F9'],
    onboardingGraphs: ['#215263', '#0F9292', '#7BC67E'],
    empMappingGraph: ['#215263', '#0F9292', '#7BC67E'],
    leaveChart: ['#215263', '#0F9292', '#86C8C8'],
    leaveGraphs: ['#215263', '#0F9292'],
    SalaryCompositionCharts: ['#215263', '#187B96', '#0F9292', '#86C8C8', '#BCE2E2', '#E1E3EA'],
    pieChartsColors: ['#215263', '#187B96', '#0F9292', '#86C8C8', '#BCE2E2', '#E1E3EA'],
  };

  return colors[type];
};

export default GraphColor;
