import React from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import PropTypes from 'prop-types';
import EditProfile from 'components/Organisms/Profile/BasicProfile/Edit';

const EditProfileTemplate = ({
  handleCancel,
  handleSubmit,
  defaultValues,
  bloodgroupOptions,
  maritalStatusOptions,
  loading,
}) => {
  const breadcrumbData = [
    { id: 1, name: 'Basic Details', path: '/profile/basic-profile', isActive: false },
    { id: 2, name: 'Edit', isActive: true },
  ];

  return (
    <BlankTemplate padding="0rem" pageHeading="Edit basic Profile Details" breadcrumbData={breadcrumbData}>
      <EditProfile
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        defaultValues={defaultValues}
        bloodgroupOptions={bloodgroupOptions}
        maritalStatusOptions={maritalStatusOptions}
        loading={loading}
      />
    </BlankTemplate>
  );
};

EditProfileTemplate.defaultProps = {
  defaultValues: {
    mobileNumber: [''],
    emailAddress: [''],
    name: '',
    relation: '',
    emergencyMobile: '',
    emergencyEmail: '',
    maritalStatus: '',
    fatherName: '',
    motherName: '',
    spouseName: '',
    bloodgroup: '',
    skypeid: '',
    facebookid: '',
    linkedinid: '',
    googlePlusid: '',
    twitterid: '',
    instagramid: '',
    selfdescription: '',
    passportnumber: '',
    passportissuedate: '',
    passportexpirydate: '',
    passportplaceissue: '',
    passportcountry: '',
    passportnamegiven: '',
  },
  maritalStatusOptions: [],
  bloodgroupOptions: [],
  handleCancel: () => {},
  handleSubmit: () => {},
  loading: false,
};

EditProfileTemplate.propTypes = {
  defaultValues: PropTypes.shape({}),
  bloodgroupOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  maritalStatusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
};

export default EditProfileTemplate;
