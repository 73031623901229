import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useToast from 'components/Provider/useToast';
import MyTeamExpensesList from 'components/Templates/MyExpenses/MyTeamExpenseList';
import { searchMyOrgExpenseReports, searchMyOrgExpenseReportsAction } from 'api/expenses';
import useController from 'hooks/useController';
import useFetcher from 'hooks/useFetcher';
import usePaginationAttributes from 'hooks/use-pagination-attributes';
import { updateMyTeamExpenseRecords } from 'store/slices/expenses/expenses';
import RejectApproveDialog from 'components/Organisms/MyExpenses/ReportDetails/RejectApproveDialog';
import Dialog from 'components/Molecules/Dialog';
import Typography from 'components/Atoms/Typography';
import { postListApiFormat, getListApiFormat } from 'utils/ApiPayloadFormat';
import { hasPermission } from 'utils/permissions';
import useColumns from './useColumns';
import useServices from './useServices';
import useActionItems from './useActionItems';
import useFilters from './useFilters';

const ListMyTeamExpenses = () => {
  const { pageSize } = usePaginationAttributes();
  const [columns] = useColumns();
  const services = useServices();
  const addToast = useToast();
  const dispatch = useDispatch();

  const [filterOptions] = useFetcher(searchMyOrgExpenseReports);
  const [controller, handlePageChange, handleSortChange, handleFiltersApply, handleFiltersClear] = useController();

  const filterService = useFilters(controller, filterOptions?.data?.data);

  const authorities = useSelector((state) => state.session.user.authorities);
  const userCanFilter = hasPermission(authorities, [
    'ROLE_BASE_PERMISSION_EXPENSE_INDEX_SEARCH_MY_ORG_EXPENSE_REPORTS',
  ]);

  const [roleBasedMenu] = useActionItems(services);

  const fetchExpensesReportAction = useCallback(async () => {
    if (controller?.filters && Object.keys(controller?.filters).length > 0) {
      const formData = postListApiFormat(controller, pageSize);
      const response = await searchMyOrgExpenseReportsAction(formData);
      if (response.status === 200) {
        dispatch(updateMyTeamExpenseRecords(response?.data?.data));
        services.fetchUpdatedMyOrgExpenseReports(response?.data?.data);
      } else {
        addToast({ type: 'error', title: 'ERROR', message: response?.data?.data?.message });
      }
    } else {
      const queryData = getListApiFormat(controller, pageSize);
      services.fetchMyOrgExpenseReports(queryData);
    }
  }, [controller, pageSize, searchMyOrgExpenseReportsAction]);

  useEffect(() => {
    fetchExpensesReportAction();
  }, [fetchExpensesReportAction, controller, pageSize, services?.state?.refreshPage]);

  const expenseReportServices = services.state.listExpensesReport.data;

  return (
    <>
      <MyTeamExpensesList
        loading={services.state.listExpensesReport.loading}
        pageHeading="Team Expenses"
        rows={
          controller?.filters && Object.keys(controller?.filters).length > 0
            ? expenseReportServices?.results
            : expenseReportServices?.data?.employeeExpenseReportInstanceList
        }
        columns={columns}
        handleSelect={services.handleSelectedReport}
        page={controller.page}
        count={
          controller?.filters && Object.keys(controller?.filters).length > 0
            ? expenseReportServices?.totalCount
            : expenseReportServices?.data?.employeeExpenseReportInstanceCount || 0
        }
        onPageChange={handlePageChange}
        showPagination
        showActionList
        roleBasedMenu={roleBasedMenu}
        sortDirection={controller.sortDirection}
        sortBy={controller.sortBy}
        onSortChange={handleSortChange}
        filterFields={filterService?.filterFields}
        handleFilter={(data) => handleFiltersApply(data)}
        handleFiltersClear={handleFiltersClear}
        handleAttachmentDownload={services.downloadDocument}
        supportingDocuments={services?.state?.expenseReportDetails?.supportingDocuments}
        showFilter={userCanFilter}
      />
      <RejectApproveDialog
        open={services?.state?.open}
        handleCancel={() => services.approveRejectExpenseReport(false)}
        handleClose={() => services.approveRejectExpenseReport(false)}
        handleSubmit={() => services.approveRejectExpenseReportAction(fetchExpensesReportAction)}
        onChange={services.onChangeApproveRejectComment}
        isApprove={services?.state?.isApprove}
        reportTitle={services?.state?.expenseReportDetails?.name}
      />
      {services?.showDeleteDialog && services?.state?.expenseReportDetails?.id !== null && (
        <Dialog
          open={services?.showDeleteDialog}
          handleClose={services?.handleDeleteDialog}
          dialogTitle="Delete Expense"
          dialogDesc={
            <Typography variant="body2" color="neutral.600" align="center">
              Are you sure you want to delete
              <Typography color="neutral.800" sx={{ display: 'inline', pl: 1 }}>
                {services?.state?.expenseReportDetails?.displayName}
              </Typography>
              ?
            </Typography>
          }
          handlePrimaryBtn={() => services?.deleteExpenseReport({ id: services?.state?.expenseReportDetails?.id })}
          primaryBtnColor="error"
          primaryBtn="Delete"
          secondaryBtn="Cancel"
          handleSecondaryBtn={services?.handleDeleteDialog}
          bodyContent={false}
        />
      )}
    </>
  );
};

export default ListMyTeamExpenses;
