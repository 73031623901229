/* eslint-disable no-unused-vars */
import React from 'react';
import { FormHelperText, Grid, Stack, Skeleton, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/system';
import InputField from 'components/Atoms/InputField';
import DatePickers from 'components/Atoms/Datepicker';
import Button from 'components/Atoms/Button';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import Typographys from 'components/Atoms/Typography';

const PayPeriodForm = ({ isEditMode, payrollCalendar, results, formik, loading, handleCancel }) => {
  const { payRunPeriodicity } = payrollCalendar?.data || {};

  if (payrollCalendar?.isLoading || !payRunPeriodicity?.length) {
    return (
      <Box sx={{ py: 4 }}>
        <Skeleton height={42} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  return (
    <Grid container spacing={4} sx={{ py: 4 }}>
      <Grid item md={12} xs={12}>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <Grid container columnSpacing={6} rowSpacing={3}>
              <Grid item xs={12}>
                <Typographys variant="h6">Pay Period Setup</Typographys>
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  error={Boolean(formik.touched.payRunPeriodicity && formik.errors.payRunPeriodicity)}
                  helperText={formik.touched.payRunPeriodicity && formik.errors.payRunPeriodicity}
                  label="Pay Run Periodicity*"
                  name="payRunPeriodicity"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  select
                  value={formik.values.payRunPeriodicity}
                >
                  {payRunPeriodicity.map((statusItem) => (
                    <MenuItem key={statusItem} value={statusItem}>
                      {statusItem}
                    </MenuItem>
                  ))}
                </InputField>
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  error={Boolean(formik.touched.lockStatus && formik.errors.lockStatus)}
                  helperText={formik.touched.lockStatus && formik.errors.lockStatus}
                  label="Lock Status"
                  name="lockStatus"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  select
                  value={formik.values.lockStatus}
                >
                  {['Locked', 'Unlocked'].map((statusItem) => (
                    <MenuItem key={statusItem} value={statusItem}>
                      {statusItem}
                    </MenuItem>
                  ))}
                </InputField>
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  error={Boolean(formik.touched.status && formik.errors.status)}
                  helperText={formik.touched.status && formik.errors.status}
                  label="Status*"
                  name="status"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  select
                  value={formik.values.status}
                >
                  {['Active', 'Inactive'].map((statusItem) => (
                    <MenuItem key={statusItem} value={statusItem}>
                      {statusItem}
                    </MenuItem>
                  ))}
                </InputField>
              </Grid>
              <Grid item xs={4}>
                <DatePickers
                  id="First Processed Pay Period Start Date"
                  error={Boolean(formik.touched.firstProcessedPPStartDate && formik.errors.firstProcessedPPStartDate)}
                  label="First Processed Pay Period Start Date*"
                  name="firstProcessedPPStartDate"
                  value={formik.values.firstProcessedPPStartDate}
                  onChange={(value) => {
                    formik.setFieldValue('firstProcessedPPStartDate', dayjs(value));
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  error={Boolean(formik.touched.payDateOffsetFromEndDate && formik.errors.payDateOffsetFromEndDate)}
                  helperText={formik.touched.payDateOffsetFromEndDate && formik.errors.payDateOffsetFromEndDate}
                  label="Pay Date Offset From Period End Date"
                  name="payDateOffsetFromEndDate"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.payDateOffsetFromEndDate}
                  type="number"
                />
              </Grid>

              <Grid item xs={4} />
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  error={Boolean(
                    formik.touched.attendanceCutoffFromPeriodDate && formik.errors.attendanceCutoffFromPeriodDate,
                  )}
                  helperText={
                    formik.touched.attendanceCutoffFromPeriodDate && formik.errors.attendanceCutoffFromPeriodDate
                  }
                  label="Attendance Cutoff Offset From Period Date"
                  name="attendanceCutoffFromPeriodDate"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.attendanceCutoffFromPeriodDate}
                  type="number"
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  error={Boolean(formik.touched.payChangedFromPeriodDate && formik.errors.payChangedFromPeriodDate)}
                  helperText={formik.touched.payChangedFromPeriodDate && formik.errors.payChangedFromPeriodDate}
                  label="Pay Changes Cutoff Offset From Period End Date*"
                  name="payChangedFromPeriodDate"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="number"
                  value={formik.values.payChangedFromPeriodDate}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  error={Boolean(formik.touched.finalPayrunFromPeriodDate && formik.errors.finalPayrunFromPeriodDate)}
                  helperText={formik.touched.finalPayrunFromPeriodDate && formik.errors.finalPayrunFromPeriodDate}
                  label="Final Payrun Approval offset From Period End Date"
                  name="finalPayrunFromPeriodDate"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="number"
                  value={formik.values.finalPayrunFromPeriodDate}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  error={Boolean(
                    formik.touched.payRunOffsetFromPayPeriodEndDate && formik.errors.payRunOffsetFromPayPeriodEndDate,
                  )}
                  helperText={
                    formik.touched.payRunOffsetFromPayPeriodEndDate && formik.errors.payRunOffsetFromPayPeriodEndDate
                  }
                  label="Payrun Offset From Period End Date*"
                  name="payRunOffsetFromPayPeriodEndDate"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="number"
                  value={formik.values.payRunOffsetFromPayPeriodEndDate}
                />
              </Grid>
              {formik.errors.submit && (
                <Grid item xs={12}>
                  <FormHelperText error>{formik.errors.submit}</FormHelperText>
                </Grid>
              )}
              <Grid item xs={12}>
                <Stack direction="row" justifyContent={isEditMode ? 'space-between' : 'end'}>
                  {/* {isEditMode && (
                    <Button color="primary" size="large" variant="outlined">
                      Delete
                    </Button>
                  )} */}
                  <Stack direction="row" justifyContent="end" spacing={2}>
                    <Button
                      onClick={handleCancel}
                      type="button"
                      size="Large"
                      variant="outlined"
                      sx={{ color: 'text.200 !important' }}
                    >
                      Back
                    </Button>
                    <LoadingButton
                      type="submit"
                      loading={loading}
                      color="primary"
                      size="large"
                      variant="contained"
                      onClick={formik.handleSubmit}
                    >
                      {isEditMode ? 'Update' : 'Create'}
                    </LoadingButton>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </div>
        </form>
      </Grid>
    </Grid>
  );
};

export default PayPeriodForm;

PayPeriodForm.propTypes = {
  payrollCalendar: PropTypes.shape(PropTypes.node),
  isEditMode: PropTypes.bool,
  loading: PropTypes.bool,
  results: PropTypes.instanceOf(Object),
  formik: PropTypes.instanceOf(Object).isRequired,
  handleCancel: PropTypes.func.isRequired,
};

PayPeriodForm.defaultProps = {
  payrollCalendar: {},
  isEditMode: false,
  results: {},
  loading: false,
};
