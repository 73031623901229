export const shadowWhiteTiny = '0 1px 5px 0 rgba(118, 148, 188, 0.12)';

export const shadowWhiteMidsize = '0 10px 40px -5px rgba(0, 64, 184, 0.16)';

export const shadowWhiteBigDown = '0 33px 64px -19px rgba(0, 64, 184, 0.08)';

export const shadowWhiteBigTop = '0 -33px 64px -19px rgba(0, 64, 184, 0.08)';

export const shadowWhiteMidsizeHardDown =
  '0px 20px 40px -19px rgba(57, 70, 92, 0.08), 0px 10px 60px -19px rgba(57, 70, 92, 0.2)';

export const shadowWhiteMidsizeHardTop =
  '0px -20px 40px -19px rgba(57, 70, 92, 0.08), 0px 10px 60px -19px rgba(57, 70, 92, 0.2)';

export const shadowBlueMidsize = '0 8px 26px -4px rgba(21, 98, 240, 0.2)';

export const shadowDarkTiny = '0 12px 24px -7px rgba(3, 61, 163, 0.24)';

export const shadowDarkMidsize = '0 2px 64px -12px rgba(3, 61, 163, 0.16)';

export const shadowDarkBig = '0 34px 64px -16px rgba(3, 61, 163, 0.16)';
