import React, { useState } from 'react';
import ProfileEditBankAccountTemplate from 'components/Templates/Profile/BankAccount/Edit';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useToast from 'components/Provider/useToast';
import { formDataApi } from 'api/api';

const AddEditBankAccounts = () => {
  const addToast = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { bankAccounts, currencies, transactionTypes, accountTypes, bankPurposeOptions } = useSelector((state) => {
    return state.profile;
  });

  const handleCancel = () => {
    navigate('/profile/bank-accounts');
  };

  const editOptions = {
    transactionTypeOptions: Object.values(transactionTypes).map((item) => ({ id: item, name: item })),
    bankNameOptions: [],
    accountTypeOptions: Object.values(accountTypes).map((item) => ({ id: item, name: item })),
    swiftCodeTypeOptions: [],
    stateOptions: [],
    countryOptions: [],
    accountCurrencyOptions: currencies?.map((item) => ({ id: item.id, name: item.name })),
    purposeOptions: bankPurposeOptions
      ? Object.values(bankPurposeOptions)?.map((item) => ({ id: item, name: item }))
      : [],
    cityOptions: [],
    defaultValue: {
      bank:
        bankAccounts?.length > 0
          ? bankAccounts?.map((item) => ({
              accountNumber: item?.accountNumber,
              accountName: item?.accountHolderName,
              accountType: item?.accountType,
              accountCurrency: item?.currency?.id || '',
              purpose: item?.accountPurpose || '',
              transactionType: item?.preferredTransactionType || '',
              bankName: item?.bankName || '',
              branchName: item?.bankBranch || '',
              ifsc: item?.ifsCode || '',
              swiftCode: item?.swiftCode || '',
              locationName: item?.bankAddress?.locationName || '',
              addressLine1: item?.bankAddress?.addressLine1 || '',
              addressLine2: item?.bankAddress?.addressLine2 || '',
              addressLine3: item?.bankAddress?.addressLine3 || '',
              city: item?.bankAddress?.city || '',
              state: item?.bankAddress?.state || '',
              country: item?.bankAddress?.country || '',
              pincode: item?.bankAddress?.pinOrZip || '',
            }))
          : [],
    },
  };

  const getBankDetails = (index) => {
    const currencyId = bankAccounts[index]?.currencyId || '';
    const bid = bankAccounts[index].id;
    const baid = bankAccounts[index].bankAddressId;
    return { currencyId, bid, baid };
  };

  const getDataSet = (bank, index) => {
    const data = {
      [`bankAccountDetails[${index}].accountNumber`]: bank?.accountNumber || '',
      [`bankAccountDetails[${index}].accountHolderName`]: bank?.accountName || '',
      [`bankAccountDetails[${index}].accountType`]: bank?.accountType || '',
      [`bankAccountDetails[${index}].accountPurpose`]: bank?.purpose || '',
      [`bankAccountDetails[${index}].preferredTransactionType`]: bank?.transactionType || '',
      [`bankAccountDetails[${index}].bankName`]: bank?.bankName || '',
      [`bankAccountDetails[${index}].bankBranch`]: bank?.branchName || '',
      [`bankAccountDetails[${index}].ifsCode`]: bank?.ifsc || '',
      [`bankAccountDetails[${index}].swiftCode`]: bank?.swiftCode || '',
      [`bankAddress[${index}].locationName`]: bank?.locationName || '',
      [`bankAddress[${index}].addressLine1`]: bank?.addressLine1 || '',
      [`bankAddress[${index}].addressLine2`]: bank?.addressLine2 || '',
      [`bankAddress[${index}].addressLine3`]: bank?.addressLine3 || '',
      [`bankAddress[${index}].city`]: bank?.city || '',
      [`bankAddress[${index}].state`]: bank?.state || '',
      [`bankAddress[${index}].country`]: bank?.country || '',
      [`bankAddress[${index}].pinOrZip`]: bank?.pincode || '',
    };
    if (bankAccounts[index] !== undefined) {
      data[`bankAccountDetails[${index}].currency.id`] = bank?.accountCurrency || getBankDetails(index).currencyId;
      data[`bankAccountDetails[${index}].bid`] = getBankDetails(index).bid;
      data[`bankAccountDetails[${index}].baid`] = getBankDetails(index).baid;
    } else {
      data[`bankAccountDetails[${index}].currency.id`] = currencies[0].company.defaultCurrency;
    }

    return data;
  };

  const configureFormDataAndUpdate = async (bank) => {
    const formData = new FormData();
    bank.forEach((payload) => {
      const index = bank.indexOf(payload);
      const formDataObject = getDataSet(payload, index);
      Object.keys(formDataObject).forEach((property) => {
        formData.append(property, formDataObject[property]);
      });
    });
    formData.append(`bankAccntCtr`, bank.length);

    return formData;
  };

  const handleSubmit = async (formikData) => {
    const bodyFormData = await configureFormDataAndUpdate(formikData.bank);

    try {
      setLoading(true);
      const response = await formDataApi.post(`restEmpIndex/updateBankAccountsAction`, bodyFormData);
      if (response?.data?.status !== 'ERROR') {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || 'Bank account details updated successfully',
        });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || 'Failed to update bank account details',
      });
    } finally {
      navigate('/profile/bank-accounts');
      setLoading(false);
    }
  };

  return (
    <ProfileEditBankAccountTemplate
      transactionTypeOptions={editOptions.transactionTypeOptions}
      accountTypeOptions={editOptions.accountTypeOptions}
      accountCurrencyOptions={editOptions.accountCurrencyOptions}
      purposeOptions={editOptions.purposeOptions}
      defaultValue={editOptions.defaultValue}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      loading={loading}
    />
  );
};

export default AddEditBankAccounts;
