import React, { useEffect, useState } from 'react';
import { useThunk } from 'hooks/useThunk';
import { fetchMyLeaveDetails } from 'store';
import { useSelector } from 'react-redux';
import { Box, Typography, Skeleton } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import useDownloadFile from 'hooks/useDownloadFile';
import LeaveRequestDetails from 'components/Templates/Leave/MyLeaves/MyLeaveRequests/LeaveRequestDetails';
import Dialog from 'components/Molecules/Dialog';
import { hasPermission } from 'utils/permissions';
import useLeaveServices from '../list/useLeaveServices';
import useMenuItems from './useMenuItems';

const LeaveDetailsPage = () => {
  const navigate = useNavigate();
  const downloadFile = useDownloadFile();
  const { id } = useParams();
  const services = useLeaveServices();
  const [openDelete, setOpenDelete] = useState(false);
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasViewPermisson = hasPermission(authorities, ['ROLE_BASE_PERMISSION_LEAVE_INDEX_VIEW_LEAVE_REQUEST']);

  const [getLeaveDetails, isLoadingDetails, loadingLeaveDetailsError] = useThunk(fetchMyLeaveDetails);

  useEffect(() => {
    const query = new URLSearchParams({});
    query.append('id', id);
    getLeaveDetails(query);
  }, [id, getLeaveDetails]);

  const { myLeaveDetails } = useSelector((state) => state.myLeaves);

  const handleEdit = () => {
    navigate(`/myteam/leaves/team-leave-requests/${id}/edit`);
  };

  const [menuItems] = useMenuItems(myLeaveDetails, services);

  const breadcrumbData = [
    { id: 1, name: 'Team Leave Request', path: '/myteam/leaves/team-leave-requests', isActive: false },
    { id: 2, name: 'Details', isActive: true },
  ];

  const handleAttachment = (docid, filename) => {
    downloadFile({ docid }, filename, 'restPayIndex/downloadAttachment');
  };

  if (isLoadingDetails) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (loadingLeaveDetailsError !== null) {
    return (
      <Typography variant="h6">{loadingLeaveDetailsError?.message || 'Error while loading leave details.'}</Typography>
    );
  }

  if (!userHasViewPermisson) {
    return <Typography variant="h6">You do not have permission to view leave request details</Typography>;
  }

  return (
    <Box>
      <LeaveRequestDetails
        breadcrumbData={breadcrumbData}
        pageHeading="Leave request details"
        leaveDetails={{
          employeeId: myLeaveDetails?.employeeId,
          employeeName: myLeaveDetails?.employee?.fullName,
          leaveCategory: myLeaveDetails?.leaveCategory?.displayName,
          leaveType: myLeaveDetails?.leaveType,
          startDate: myLeaveDetails?.startDate,
          endDate: myLeaveDetails?.endDate,
          numberOfDays: myLeaveDetails?.numberOfDays,
          numberOfHours: myLeaveDetails?.numberOfHours,
          createdOn: myLeaveDetails?.createdOn,
          createdBy: myLeaveDetails?.createdBy?.fullName,
          lastModifiedOn: myLeaveDetails?.lastModifiedOn,
          lastModifiedBy: myLeaveDetails?.lastModifiedBy?.fullName,
          approvalDate: myLeaveDetails?.approvalDate,
          approvedBy: myLeaveDetails?.approvedBy?.fullName,
          requestState: myLeaveDetails?.requestState,
          description: myLeaveDetails?.description,
          reviewComments: myLeaveDetails?.reviewComments,
          attachment: myLeaveDetails?.attachment?.fileName,
          attachmentId: myLeaveDetails?.attachmentId,
        }}
        handleEdit={handleEdit}
        actionMenuList={menuItems}
        handleAttachment={handleAttachment}
      />
      {openDelete && myLeaveDetails !== null && (
        <Dialog
          open={openDelete}
          handleClose={() => setOpenDelete(false)}
          dialogTitle="Delete Leave"
          dialogDesc={`Are you sure you want to delete ${myLeaveDetails?.leaveType}?`}
          secondaryBtn="Cancel"
          primaryBtn="Delete"
          handlePrimaryBtn={() => services.deleteLeave(id, false)}
          handleSecondaryBtn={() => setOpenDelete(false)}
          bodyContent={false}
        />
      )}
    </Box>
  );
};

export default LeaveDetailsPage;
