import { useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import FormData from 'form-data';
import {
  deleteLeaveRequest,
  submitLeaveRequest,
  approveLeaveRequest,
  revertToDraftLeaveRequest,
  rejectLeaveRequest,
} from 'api/leave';
import useToast from 'components/Provider/useToast';
import { updateLoadingState } from 'store/slices/leaves/myLeaves';
import { useDispatch } from 'react-redux';

const dialogsInitState = {
  open: null,
};

const reducer = (state, action) => {
  const { type } = action;
  let newState;
  switch (type) {
    case 'Delete': {
      newState = { ...state, open: 'Delete' };
      break;
    }
    case 'Submit': {
      newState = { ...state, open: 'Submit' };
      break;
    }
    case 'Approve': {
      newState = { ...state, open: 'Approve' };
      break;
    }
    case 'Reject': {
      newState = { ...state, open: 'Reject' };
      break;
    }
    case 'Revert': {
      newState = { ...state, open: 'Revert' };
      break;
    }
    case 'Close': {
      newState = { ...state, open: null };
      break;
    }

    default: {
      newState = state;
    }
  }
  return newState;
};

const useLeaveServices = () => {
  const navigate = useNavigate();
  const [dialogState, dispatchDialog] = useReducer(reducer, dialogsInitState);
  const addToast = useToast();
  const dispatch = useDispatch();

  const modifyLeave = (row) => {
    navigate(`/myteam/leaves/team-leave-requests/${row?.id}/edit`);
  };

  const viewLeave = (row) => {
    navigate(`/myteam/leaves/team-leave-requests/${row?.id}/view`);
  };

  const handleOpenDialog = (type, payload) => {
    dispatchDialog({ type, payload });
  };

  const handleRefreshPage = (refresh) => {
    if (refresh) {
      dispatch(updateLoadingState(true));
    } else {
      navigate('/myteam/leaves/team-leave-requests');
    }
  };

  const deleteLeave = async (leaveId, refresh) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', leaveId);
      const response = await deleteLeaveRequest(bodyFormData);
      if (response?.status === 200) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Leave deleted successfully`,
        });
        handleRefreshPage(refresh);
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to delete leave request`,
      });
    } finally {
      dispatchDialog({ type: 'Close' });
    }
  };

  const submitLeave = async ({ id, refresh }) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', id);

      const response = await submitLeaveRequest(bodyFormData);
      if (response?.status === 200) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Leave submitted successfully`,
        });
        handleRefreshPage(refresh);
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to submit leave request`,
      });
    } finally {
      dispatchDialog({ type: 'Close' });
    }
  };

  const approveLeave = async ({ id, note, refresh }) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', id);
      bodyFormData.append('version', 1);
      bodyFormData.append('reviewComments', note);

      const response = await approveLeaveRequest(bodyFormData);
      if (response?.status === 200) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Leave approved successfully`,
        });
        handleRefreshPage(refresh);
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to approve leave request`,
      });
    } finally {
      dispatchDialog({ type: 'Close' });
    }
  };

  const revertLeave = async ({ id, note, refresh }) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', id);
      bodyFormData.append('version', 1);
      bodyFormData.append('reviewComments', note);

      const response = await revertToDraftLeaveRequest(bodyFormData);
      if (response?.status === 200) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Leave reverted successfully`,
        });
        handleRefreshPage(refresh);
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to revert leave request`,
      });
    } finally {
      dispatchDialog({ type: 'Close' });
    }
  };

  const rejectLeave = async ({ id, note, refresh }) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', id);
      bodyFormData.append('version', 1);
      bodyFormData.append('reviewComments', note);

      const response = await rejectLeaveRequest(bodyFormData);
      if (response?.status === 200) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Leave rejected successfully`,
        });
        handleRefreshPage(refresh);
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to reject leave request`,
      });
    } finally {
      dispatchDialog({ type: 'Close' });
    }
  };

  return {
    dialogState,
    dispatchDialog,
    deleteLeave,
    submitLeave,
    handleOpenDialog,
    modifyLeave,
    approveLeave,
    rejectLeave,
    revertLeave,
    viewLeave,
  };
};

export default useLeaveServices;
