import React, { useEffect, useState } from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import ConcludePIP from 'components/Organisms/Employee/EmployeeRecord/ActionList/ConcludePIP';
import { useThunk } from 'hooks/useThunk';
import { fetchConcludePIP } from 'store';
import { Typography, Box, Skeleton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { concludeEmployeePIPAction } from 'api/employees';
import dayjs from 'dayjs';
import useToast from 'components/Provider/useToast';

const ConcludePIPPage = () => {
  const addToast = useToast();
  const navigate = useNavigate();
  const { userId } = useParams();

  const [loading, setLoading] = useState(false);
  const [getConcludePIP, isLoadingConcludePIP, loadingConcludePIPError] = useThunk(fetchConcludePIP);

  useEffect(() => {
    const query = new URLSearchParams({});
    query.append('id', userId);
    getConcludePIP(query);
  }, [userId, getConcludePIP]);

  const { concludePIP } = useSelector((state) => state.employees);

  const employeeData = {
    supervisor: concludePIP?.employeeInstance?.supervisor?.fullName,
    department: concludePIP?.employeeInstance?.department?.name,
    designation: concludePIP?.employeeInstance?.designation?.name,
    location: concludePIP?.employeeInstance?.location?.name,
  };

  const handleSubmit = async ({ startDate, endDate, notes }) => {
    const newStartDate = startDate ? dayjs(startDate).format('MM/DD/YYYY') : '';
    const newEndDate = endDate ? dayjs(endDate).format('MM/DD/YYYY') : '';
    const bodyFormData = new FormData();
    bodyFormData.append('empID', concludePIP?.user?.employeeId);
    bodyFormData.append('CurrentAction', 'SaveAndSubmit');
    bodyFormData.append('effectiveStartDate', newStartDate);
    bodyFormData.append('effectiveEndDate', newEndDate);
    bodyFormData.append('notes', notes || '');

    try {
      setLoading(true);
      const response = await concludeEmployeePIPAction(bodyFormData);
      if (response?.data?.status === 'SUCCESS') {
        addToast({ type: 'success', title: 'SUCCESS', message: response?.data?.message });
        navigate(`/company/employee/view/${userId}`);
      } else {
        addToast({ type: 'error', title: 'ERROR', message: response?.data?.message });
      }
    } catch (error) {
      addToast({ type: 'error', title: 'ERROR', message: error?.response?.data?.message || error?.message });
    } finally {
      setLoading(false);
    }
  };

  if (loadingConcludePIPError !== null) {
    return (
      <Typography variant="h6">
        {loadingConcludePIPError?.message || `Error while loading current details for employee id : ${userId}.`}
      </Typography>
    );
  }

  const breadcrumbData = [
    { id: 1, name: 'Employee List', path: '/company/employee/employees-list', isActive: false },
    { id: 2, name: 'Employee Records', path: `/company/employee/view/${userId}`, isActive: false },
    { id: 2, name: 'Conclude PIP', isActive: true },
  ];

  return isLoadingConcludePIP ? (
    <Box sx={{ p: 4 }}>
      <Skeleton height={70} />
      <Skeleton />
      <Skeleton />
    </Box>
  ) : (
    <BlankTemplate
      pageHeading={`Employee: ${get(concludePIP, 'employeeInstance.fullName', '')} (${get(
        concludePIP,
        'employeeInstance.profile.employeeId',
        'NA',
      )})`}
      breadcrumbData={breadcrumbData}
      padding="3rem"
    >
      <ConcludePIP
        employeeData={employeeData}
        handleSubmit={handleSubmit}
        loading={loading}
        label="PIP"
        btnLabel="Conclude PIP"
      />
    </BlankTemplate>
  );
};

export default ConcludePIPPage;
