export const radiusNone = '0px';

export const radiusXtiny = '4px';

export const radiusTiny = '8px';

export const radiusMidsize = '12px';

export const radiusBig = '20px';

export const radiusRound = '999px';
