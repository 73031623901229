import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import PropTypes from 'prop-types';

import { noop } from 'lodash';
import { menuStyles } from './menuStyles';
import { withThemeProvider } from '../../design-tokens/theme/theme';

const MuiMenu = ({ anchorEl, options, open, handleClose, onClick, width, variant = 'dark' }) => {
  const styles = menuStyles[variant]({ width });

  return (
    <Menu
      id="dropDownMenu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      classes={{ paper: styles.menuPaper }}
      data-testid="dropDownMenu"
    >
      {options?.map((Option, index) => (
        <MenuItem
          key={index}
          className={styles.menuList}
          onClick={() => onClick(Option)}
          data-testid={`dropDownMenuItem${index}`}
        >
          {Option?.img && (
            <ListItemIcon data-testid={`dropDownImageItem${index}`}>
              {Option?.isIcon ? <Option.img /> : <img src={Option?.img} alt="list item icon" />}
            </ListItemIcon>
          )}
          {Option?.label}
        </MenuItem>
      ))}
    </Menu>
  );
};

MuiMenu.propTypes = {
  options: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  width: PropTypes.number,
};

MuiMenu.defaultProps = {
  options: [
    { label: 'Option 1', value: 'Option 1' },
    { label: 'Option 2', value: 'Option 2' },
    { label: 'Option 3', value: 'Option 3' },
    { label: 'Option 4', value: 'Option 4' },
  ],
  open: false,
  handleClose: noop,
  onClick: noop,
  width: 204,
};

export default withThemeProvider(MuiMenu);
