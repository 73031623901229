import React from 'react';
import { Box, Grid, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import Button from 'components/Atoms/Button';
import { useFormik } from 'formik';
import { useTheme } from '@mui/material/styles';
import Dropdown from 'components/Molecules/Dropdown';
import * as yup from 'yup';
import InputField from 'components/Atoms/InputField/index';
import { taskStyle } from '../style';

const ActionQuery = ({ handleSubmit, formData, resolutionStatusOptions, handleCancel, isReopen, isEscalate }) => {
  const defaultValues = formData || {
    resolutionStatus: '',
    comments: '',
    id: '',
  };

  const formScheme = yup.object().shape({
    comments: yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: formScheme,
    validateOnMount: true,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const theme = useTheme();

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Grid container rowSpacing={2} columnSpacing={2} mt={0} sx={taskStyle.formContainer}>
        {!isReopen && (
          <Grid item xs={12} md={4}>
            <Dropdown
              error={Boolean(formik.touched.resolutionStatus && formik.errors.resolutionStatus)}
              helperText={formik.touched.resolutionStatus && formik.errors.resolutionStatus}
              handleChange={(e) => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.resolutionStatus}
              name="resolutionStatus"
              id="resolutionStatus"
              label="Resolution Status*"
              options={resolutionStatusOptions}
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={12} md={12}>
          <InputField
            error={Boolean(formik.touched.comments && formik.errors.comments)}
            helperText={formik.touched.comments && formik.errors.comments}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.comments}
            name="comments"
            id="comments"
            label="Comments*"
            fullWidth
            multiline
          />
        </Grid>
      </Grid>
      <Divider sx={{ my: 3, backgroundColor: theme.palette.neutral[200] }} />
      <Box sx={taskStyle.submitButtons}>
        <Button
          size="large"
          color="info"
          variant="outlined"
          type="cancel"
          onClick={() => {
            formik.resetForm();
            handleCancel();
          }}
        >
          Cancel
        </Button>
        <Button size="large" type="submit">
          {/* eslint-disable-next-line no-nested-ternary */}
          {isReopen ? (isEscalate ? 'Escalate' : 'Reopen') : 'Action'}
        </Button>
      </Box>
      <Divider sx={{ mt: 6, mb: 3, backgroundColor: theme.palette.neutral[200] }} />
    </Box>
  );
};

ActionQuery.defaultProps = {
  formData: {},
  handleSubmit: () => {},
  resolutionStatusOptions: [],
  handleCancel: () => {},
  isReopen: false,
  isEscalate: false,
};

ActionQuery.propTypes = {
  formData: PropTypes.shape({
    resolutionStatus: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    comments: PropTypes.string,
  }),
  handleSubmit: PropTypes.func,
  resolutionStatusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  handleCancel: PropTypes.func,
  isReopen: PropTypes.func,
  isEscalate: PropTypes.bool,
};

export default ActionQuery;
