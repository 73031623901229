/* eslint-disable no-unused-expressions */
import React from 'react';
import { Box } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import Avatars from 'components/Molecules/Avatar';
import moment from 'moment';
import userImage from 'assets/images/user-round.svg';

const AuditLog = ({ commentsMapList }) => {
  return (
    <>
      <Typographys variant="h6" sx={{ mt: 4 }}>
        Audit Log
      </Typographys>
      {commentsMapList?.length
        ? commentsMapList.map((comment) => (
            <Box sx={{ display: 'flex', alignItems: 'flex-start', mt: 1 }}>
              <Avatars avatarSrc={userImage} variant="circular" sx={{ flexShrink: 0 }} />
              <Box pl={1.75}>
                <Typographys variant="body2" color="neutral.800">
                  {comment?.content}
                </Typographys>
                <Box mt="-0.25rem">
                  <Typographys variant="caption" color="neutral.600">
                    {moment(comment?.createdOn).format('DD MMMM YYYY')}
                  </Typographys>
                </Box>
              </Box>
            </Box>
          ))
        : ''}
    </>
  );
};

export default AuditLog;
