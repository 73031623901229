import { useEffect } from 'react';
import useToast from 'components/Provider/useToast';

const UnderDevelopment = () => {
  const addToast = useToast();

  useEffect(() => {
    addToast({
      type: 'success',
      title: 'Under Development',
      message: 'Page you are trying to access is under development. Please try again after few days',
    });
  }, [addToast]);

  return null;
};

export default UnderDevelopment;
