import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import TitleWithText from 'components/Atoms/TitleWithText';
import Typographys from 'components/Atoms/Typography';
import NoRecordsMessage from 'components/Organisms/CommonPageUtils/NoRecordsFound';
import Button from 'components/Atoms/Button';
import EditIcon from '@mui/icons-material/Edit';
import { hasPermission } from 'utils/permissions';
import { useSelector } from 'react-redux';
import { profileStyle } from '../style';

const ProfileAddresses = ({ contactDetails, handleEdit }) => {
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasEditPermission = hasPermission(authorities, ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_ADDRESS']);
  const areAddressesUnavailable =
    !contactDetails?.permanentHomeAddress &&
    !contactDetails?.presentCommunicationAddress &&
    !contactDetails?.officeAddress;

  return (
    <>
      <Box sx={profileStyle.actionBtn}>
        {!!userHasEditPermission && (
          <Button startIcon={<EditIcon fontSize="2.5rem" />} onClick={handleEdit}>
            {areAddressesUnavailable ? 'Add' : 'Edit'}
          </Button>
        )}
      </Box>
      <Grid container columnSpacing={8} mt={4}>
        {contactDetails?.permanentHomeAddress && (
          <Grid item xs={12} sm={6} sx={profileStyle.addressBox}>
            <Grid container flexDirection="column">
              <Grid item>
                <Box mb={2}>
                  <Typographys variant="h5" color="neutral.700">
                    Permanent Residential Address
                  </Typographys>
                </Box>
              </Grid>
              <Grid item>
                <TitleWithText title="Location Name" text={contactDetails?.permanentHomeAddress?.locationName || '-'} />
              </Grid>
              <Grid item>
                <Box mb={0.5}>
                  <Typographys color="neutral.600" variant="body2">
                    Address
                  </Typographys>
                </Box>
                <Box>
                  <Typographys color="neutral.700" variant="body2">
                    {contactDetails?.permanentHomeAddress?.addressLine1}
                  </Typographys>
                  <Typographys color="neutral.700" variant="body2">
                    {contactDetails?.permanentHomeAddress?.addressLine2}
                  </Typographys>
                  <Typographys color="neutral.700" variant="body2">
                    {contactDetails?.permanentHomeAddress?.addressLine3}
                  </Typographys>
                  <Typographys color="neutral.700" variant="body2">
                    <Box component="div" sx={profileStyle.addressDetails}>
                      {contactDetails?.permanentHomeAddress?.city}
                    </Box>
                    <Box component="div" sx={profileStyle.addressDetails}>
                      {contactDetails?.permanentHomeAddress?.state}
                    </Box>
                    <Box component="div" sx={profileStyle.addressDetails}>
                      {contactDetails?.permanentHomeAddress?.country}
                    </Box>
                    <Box component="div" sx={profileStyle.addressDetails}>
                      {contactDetails?.permanentHomeAddress?.pinOrZip}
                    </Box>
                  </Typographys>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
        {contactDetails?.presentCommunicationAddress && (
          <Grid item xs={12} sm={6} sx={profileStyle.addressBox}>
            <Grid container flexDirection="column">
              <Grid item>
                <Box mb={2}>
                  <Typographys variant="h5" color="neutral.700">
                    Present Residential Address
                  </Typographys>
                </Box>
              </Grid>
              <Grid item>
                <TitleWithText
                  title="Location Name"
                  text={contactDetails?.presentCommunicationAddress?.locationName || '-'}
                />
              </Grid>
              <Grid item>
                <Box mb={0.5}>
                  <Typographys color="neutral.600" variant="body2">
                    Address
                  </Typographys>
                </Box>
                <Box>
                  <Typographys color="neutral.700" variant="body2">
                    {contactDetails?.presentCommunicationAddress?.addressLine1}
                  </Typographys>
                  <Typographys color="neutral.700" variant="body2">
                    {contactDetails?.presentCommunicationAddress?.addressLine2}
                  </Typographys>
                  <Typographys color="neutral.700" variant="body2">
                    {contactDetails?.presentCommunicationAddress?.addressLine3}
                  </Typographys>
                  <Typographys color="neutral.700" variant="body2">
                    <Box component="div" sx={profileStyle.addressDetails}>
                      {contactDetails?.presentCommunicationAddress?.city}
                    </Box>
                    <Box component="div" sx={profileStyle.addressDetails}>
                      {contactDetails?.presentCommunicationAddress?.state}
                    </Box>
                    <Box component="div" sx={profileStyle.addressDetails}>
                      {contactDetails?.presentCommunicationAddress?.country}
                    </Box>
                    <Box component="div" sx={profileStyle.addressDetails}>
                      {contactDetails?.presentCommunicationAddress?.pinOrZip}
                    </Box>
                  </Typographys>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
        {contactDetails?.officeAddress && (
          <Grid item xs={12} sm={6} sx={profileStyle.addressBox}>
            <Grid container flexDirection="column">
              <Grid item>
                <Box mb={2}>
                  <Typographys variant="h5" color="neutral.700">
                    Office Address
                  </Typographys>
                </Box>
              </Grid>
              <Grid item>
                <TitleWithText title="Location Name" text={contactDetails.officeAddress?.locationName || '-'} />
              </Grid>
              <Grid item>
                <Box mb={0.5}>
                  <Typographys color="neutral.600" variant="body2">
                    Address
                  </Typographys>
                </Box>
                <Box>
                  <Typographys color="neutral.700" variant="body2">
                    {contactDetails?.officeAddress?.addressLine1}
                  </Typographys>
                  <Typographys color="neutral.700" variant="body2">
                    {contactDetails?.officeAddress?.addressLine2}
                  </Typographys>
                  <Typographys color="neutral.700" variant="body2">
                    {contactDetails?.officeAddress?.addressLine3}
                  </Typographys>
                  <Typographys color="neutral.700" variant="body2">
                    <Box component="div" sx={profileStyle.addressDetails}>
                      {contactDetails?.officeAddress?.city}
                    </Box>
                    <Box component="div" sx={profileStyle.addressDetails}>
                      {contactDetails?.officeAddress?.state}
                    </Box>
                    <Box component="div" sx={profileStyle.addressDetails}>
                      {contactDetails?.officeAddress?.country}
                    </Box>
                    <Box component="div" sx={profileStyle.addressDetails}>
                      {contactDetails?.officeAddress?.pinOrZip}
                    </Box>
                  </Typographys>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      {areAddressesUnavailable && <NoRecordsMessage width="20rem" height="20rem" />}
    </>
  );
};

ProfileAddresses.defaultProps = {
  contactDetails: {},
  handleEdit: () => {},
};

const GenericAddressPropTypes = {
  locationName: PropTypes.string,
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  addressLine3: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  country: PropTypes.string,
  pinOrZip: PropTypes.number,
};

ProfileAddresses.propTypes = {
  contactDetails: PropTypes.arrayOf(
    PropTypes.shape({
      officeAddress: PropTypes.shape(GenericAddressPropTypes),
      permanentHomeAddress: PropTypes.shape(GenericAddressPropTypes),
      presentCommunicationAddress: PropTypes.shape(GenericAddressPropTypes),
    }),
  ),
  handleEdit: PropTypes.func,
};

export default ProfileAddresses;
