import React from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import PropTypes from 'prop-types';
import EmployeeExitDetailsPerEmployee from 'components/Organisms/Employee/Company/Separation/ExitDetailsEmployee';

const EmpExitDetailsPerEmployeeTemplate = ({
  pageHeading,
  handleLog,
  handleEdit,
  handleDelete,
  handleAccept,
  exitDetails,
  auditLogData,
  lockStatus,
}) => {
  const breadcrumbData = [
    { id: 1, name: 'Separation', path: '/myteam/separations', isActive: false },
    { id: 2, name: 'View Details', isActive: true },
  ];

  return (
    <BlankTemplate pageHeading={pageHeading} breadcrumbData={breadcrumbData}>
      <EmployeeExitDetailsPerEmployee
        handleAccept={handleAccept}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        handleLog={handleLog}
        exitDetails={exitDetails}
        auditLogData={auditLogData}
        lockStatus={lockStatus}
      />
    </BlankTemplate>
  );
};

EmpExitDetailsPerEmployeeTemplate.defaultProps = {
  pageHeading: 'Employee Exit Details',
  handleLog: () => {},
  handleEdit: () => {},
  handleAccept: () => {},
  handleDelete: () => {},
  exitDetails: {},
  auditLogData: [],
  lockStatus: 'Locked',
};

EmpExitDetailsPerEmployeeTemplate.propTypes = {
  pageHeading: PropTypes.string,
  exitDetails: PropTypes.shape({}),
  handleLog: PropTypes.func,
  handleEdit: PropTypes.func,
  handleAccept: PropTypes.func,
  handleDelete: PropTypes.func,

  auditLogData: PropTypes.arrayOf(
    PropTypes.shape({
      updatedLog: PropTypes.shape({
        updatedName: PropTypes.string,
        updatedDate: PropTypes.string,
      }),

      handleView: PropTypes.func,
      rowsPerPage: PropTypes.number,
      totalRecordCount: PropTypes.number,

      auditLogRows: PropTypes.shape({
        fieldName: PropTypes.string,
        oldValue: PropTypes.string,
        newValue: PropTypes.string,
      }),
    }),
  ),
  lockStatus: PropTypes.string,
};

export default EmpExitDetailsPerEmployeeTemplate;
