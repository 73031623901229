import { createSlice } from '@reduxjs/toolkit';
import {
  fetchMyQueriesList,
  fetchEditQueryDetails,
  fetchCreateQuery,
  fetchViewQueryDetails,
} from 'store/thunks/queries/queries';

const queries = createSlice({
  name: 'queries',
  initialState: {
    queries: {
      count: 0,
      data: [],
      resolutionStatusOptions: [],
      currentEmployee: {},
    },
    editQueryDetails: {},
    createQueriesData: {},
    viewQueryDetails: {},
  },
  reducers: {
    updateQueriesList(state, action) {
      state.queries.data = action?.payload?.data?.queryInstanceList;
      state.queries.count = action?.payload?.data?.totalCount;
    },
  },

  extraReducers(builder) {
    builder.addCase(fetchMyQueriesList.fulfilled, (state, action) => {
      state.queries.data = action?.payload?.data?.queryInstanceList;
      state.queries.count = action?.payload?.data?.queryInstanceCount;
      state.queries.resolutionStatusOptions = action?.payload?.resolutionStatusOptions;
      state.queries.currentEmployee = action?.payload?.data?.currentEmployee;
    });

    builder.addCase(fetchEditQueryDetails.fulfilled, (state, action) => {
      state.editQueryDetails = action.payload?.data?.data;
    });

    builder.addCase(fetchCreateQuery.fulfilled, (state, action) => {
      state.createQueriesData = action.payload?.data?.data;
    });

    builder.addCase(fetchViewQueryDetails.fulfilled, (state, action) => {
      state.viewQueryDetails = action.payload?.data?.data;
    });
  },
});

export const { updateQueriesList } = queries.actions;

export default queries.reducer;
