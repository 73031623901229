import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from 'components/Atoms/Button';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { initiateTimer, pauseTimer, stopTimer, restartTimer } from 'api/attendance';
import { Box } from '@mui/material';
import { attendanceStyle } from './style';

const AttendanceTimer = () => {
  const [timerState, setTimerState] = useState('');
  const userId = useSelector((state) => state?.session?.user?.empDBID);

  const captureTimerState = (state) => {
    const storeState = localStorage.setItem('timer', state);
    return setTimerState(storeState);
  };

  useEffect(() => {
    if (timerState === 'Timer Running') {
      captureTimerState('Timer Running');
    } else if (timerState === 'Timer Paused') {
      captureTimerState('Timer Paused');
    } else if (timerState === 'Timer Stopped') {
      captureTimerState('Timer Stopped');
    }
  }, [timerState]);

  const initiateTimerClick = async () => {
    const response = await initiateTimer();
    if (response.status === 'SUCCESS') {
      setTimerState(response?.data?.timer?.timerState);
    }
  };

  const pauseTimerClick = async () => {
    const response = await pauseTimer();
    if (response.status === 'SUCCESS') {
      setTimerState(response?.data?.timer?.timerState);
    }
  };

  const stopTimerClick = async () => {
    const response = await stopTimer();
    if (response.status === 'SUCCESS') {
      setTimerState(response?.data?.timer?.timerState);
    }
  };

  const restartTimerClick = async () => {
    const bodyFormData = new FormData();
    bodyFormData.append('id', userId);
    const response = await restartTimer(bodyFormData);
    if (response.status === 'SUCCESS') {
      setTimerState(response?.data?.timer?.timerState);
    }
  };

  const retrieveTimerState = localStorage.getItem('timer');

  return (
    <>
      {retrieveTimerState === null || retrieveTimerState === 'Timer Stopped' ? (
        <Button sx={attendanceStyle.timerWrapper} size="Medium" color="secondary" onClick={initiateTimerClick}>
          <AccessTimeIcon sx={attendanceStyle.clockButton} />
          Clock in
        </Button>
      ) : null}

      {retrieveTimerState === 'Timer Paused' && (
        <Button sx={attendanceStyle.timerWrapper} size="Medium" color="secondary" onClick={restartTimerClick}>
          <RestartAltIcon sx={attendanceStyle.clockButton} />
          Restart
        </Button>
      )}
      {retrieveTimerState === 'Timer Running' && (
        <Box sx={attendanceStyle.timerWrapper}>
          <Button sx={attendanceStyle.clockButtonWrap} size="Medium" color="secondary" onClick={pauseTimerClick}>
            <PauseIcon sx={attendanceStyle.clockButton} />
            Pause Timer
          </Button>
          <Button sx={attendanceStyle.clockButtonWrap} size="Medium" color="secondary" onClick={stopTimerClick}>
            <StopIcon sx={attendanceStyle.clockButton} />
            End Timer
          </Button>
        </Box>
      )}
    </>
  );
};

export default AttendanceTimer;
