import React from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import SurveySheetDetails from 'components/Organisms/Surveys/Details';

const SurveyDetailsTemplate = ({ pageHeading, breadcrumbData, loading, handleSubmit, surveySheetDetails }) => {
  return (
    <BlankTemplate pageHeading={pageHeading} isBreadcrumb={false} padding="2rem 3rem">
      <SurveySheetDetails loading={loading} handleSubmit={handleSubmit} surveySheetDetails={surveySheetDetails} />
    </BlankTemplate>
  );
};

SurveyDetailsTemplate.defaultProps = {
  pageHeading: 'Survey Sheet Details',
  breadcrumbData: [{ id: 1, name: 'Survey Sheet Details', isActive: true }],
  surveySheetDetails: {},
  loading: false,
  handleSubmit: () => {},
};

SurveyDetailsTemplate.propTypes = {
  pageHeading: PropTypes.string,
  breadcrumbData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
  surveySheetDetails: PropTypes.shape({}),
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func,
};

export default SurveyDetailsTemplate;
