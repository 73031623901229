import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Skeleton } from '@mui/material';

import ReportDetails from 'components/Templates/MyExpenses/ReportDetails';
import RejectApproveDialog from 'components/Organisms/MyExpenses/ReportDetails/RejectApproveDialog';
import Dialog from 'components/Molecules/Dialog';
import Typography from 'components/Atoms/Typography';
import useServices from '../List/useServices';
import useColumns from './useExpensesColumns';
import useDownloadAction from './useDownloadAction';
import useMenuItems from './useMenuItems';

const ViewMyExpenses = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const services = useServices();
  const [columns] = useColumns();
  const [roleBasedMenu] = useDownloadAction(services);
  const [handleActionItemClick] = useMenuItems(services);
  useEffect(() => {
    services.fetchExpenseReportDetails(id);
  }, [id, services?.state?.refreshPage]);

  if (services?.state?.expenseReportDetails?.isLoading) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  const breadcrumbData = [
    { id: 1, name: 'My Expenses', path: '/home/expenses', isActive: false },
    { id: 2, name: `${services?.state?.expenseReportDetails?.reportDetails?.title}`, isActive: true },
  ];

  return (
    <>
      <ReportDetails
        reportDetails={services?.state?.expenseReportDetails?.reportDetails}
        columns={columns}
        rows={services?.state?.expenseReportDetails?.rows}
        supportingDocuments={services?.state?.expenseReportDetails?.supportingDocuments}
        amountData={services?.state?.expenseReportDetails?.amountData}
        auditData={services?.state?.expenseReportDetails?.auditData}
        handleEdit={() => services.modifyExpenseReport({ id })}
        roleBasedMenu={roleBasedMenu}
        actionMenuList={handleActionItemClick}
        breadcrumbData={breadcrumbData}
        handleAttachmentDownload={services.downloadDocument}
      />
      <RejectApproveDialog
        open={services?.state?.open}
        handleCancel={() => services.approveRejectExpenseReport(false)}
        handleClose={() => services.approveRejectExpenseReport(false)}
        handleSubmit={services.approveRejectExpenseReportAction}
        onChange={services.onChangeApproveRejectComment}
        isApprove={services?.state?.isApprove}
        reportTitle={services?.state?.expenseReportDetails?.reportDetails?.title}
      />
      {!!services.showDeleteDialog && (
        <Dialog
          open={services.showDeleteDialog}
          handleClose={services.handleDeleteDialog}
          dialogTitle="Delete Expense"
          dialogDesc={
            <Typography variant="body2" color="neutral.600" align="center">
              Are you sure you want to delete
              <Typography color="neutral.800" sx={{ display: 'inline', pl: 1 }}>
                {services?.state?.expenseReportDetails?.reportDetails?.title}
              </Typography>
              ?
            </Typography>
          }
          primaryBtn="Delete"
          secondaryBtn="Cancel"
          primaryBtnColor="error"
          handleSecondaryBtn={services.handleDeleteDialog}
          handlePrimaryBtn={() => services.deleteExpenseReport({ id, view: true })}
          handleCancel={services.handleDeleteDialog}
          bodyContent={false}
        />
      )}
    </>
  );
};

export default ViewMyExpenses;
