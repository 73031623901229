export const ChooseFileStyle = {
  wrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    minWidth: '27rem',
  },
  attachFile: {
    position: 'relative',
    borderRadius: '0.375rem',
    width: '100%',
    maxWidth: '13.5rem',
    mr: '1.25rem',
    boxSizing: 'border-box',
    cursor: 'pointer',
    selection: 'none',
    '&.MuiButton-outlinedInfo': {
      borderColor: 'primary.300',
      fontSize: '1.625rem',
      lineHeight: '2.75rem',
      ':hover': {
        backgroundColor: 'common.white',
        opacity: 0.8,
        color: 'primary.300',
        borderColor: 'primary.300',
      },
    },
  },

  attachInput: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    opacity: 0,
  },
};
