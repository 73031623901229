export const myPayStyle = {
  table: {
    // borderCollapse: 'separate',
    // borderSpacing: '0 10px', // Adjust the spacing between rows
  },
  tableCell: {
    borderBottom: 'none',
    borderTop: 'none',
    right: 0, // Remove the vertical line divider
    padding: '8px 16px',
  },
  tableWrapper: {
    mt: 1.5,
    mb: 2,
    'MuiTableHead-root': {
      background: 'neutral.100',
    },
    '.MuiTable-root.MuiTable-root .MuiTableCell-root': {
      border: '1px solid',
      borderRight: 'none',
      borderLeft: 'none',
      borderColor: 'other.200',
      py: 2,

      '.MuiTable-root.MuiTable-root': {
        margin: '-2rem',
        width: 'calc(100% + 4rem)',
      },
    },
  },
  chooseFileWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    p: '1rem',
    backgroundColor: 'info.200',
    alignItems: 'center',
    mb: '2rem',
  },
  btnContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%',
    backgroundColor: 'common.white',
    zIndex: '9',
    margin: '0rem',
    padding: '0rem 3rem 2rem 3rem',
    boxSizing: 'border-box',
  },

  submitWrapper: {
    display: 'flex',
    justifyContent: 'end',
    gap: 2,
  },
};
