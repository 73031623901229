import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import ToolTip from 'components/Atoms/ToolTip';
import { tdsStyle } from 'components/Organisms/MyPay/TDS/View/style';
import Button from 'components/Atoms/Button';
import TableExtended from 'components/Molecules/TableExtended';

const InvestmentDeclarationData = ({
  form12bAttachment,
  downloadForm12bAttachment,
  investmentDeclarations,
  declarationDetails,
  downloadAttachment,
  sortDirection,
  sortBy,
  onSortChange,
}) => {
  const investmentDeclarationColumn = [
    {
      fieldName: `slNo`,
      label: 'Sl No',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `investmentHead`,
      label: 'INVESTMENT/DEDUCTION HEAD',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `annualDeclaredAmt`,
      label: 'Annual Declared Amount',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `proofDocuments`,
      label: 'Proof documents submitted',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `proofSubmittedAmount`,
      label: 'Proof Submitted Amount',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
  ];

  return (
    <TableExtended
      loading={false}
      rows={investmentDeclarations.map((i, index) => {
        return {
          ...i,
          slNo: index + 1,
          investmentHead: (
            <Box sx={tdsStyle.textWithTooltip}>
              <Typographys variant="body2">{i.title}</Typographys>
              <ToolTip tooltipTitle={i.tooltipTitle} title={i.tooltip} placement="top" />
            </Box>
          ),
          annualDeclaredAmt: (
            <Typographys variant="body2">
              {i.currDeclrAmt === 0 ? i.currDeclrAmt : Number(i.currDeclrAmt).toFixed(1)}
            </Typographys>
          ),
          proofDocuments: i?.proof?.id ? (
            <Button
              variant="text"
              color="secondary"
              onClick={(e) => {
                e.preventDefault();
                downloadAttachment(i?.proof?.id, i?.proof?.documentName);
              }}
            >
              {i?.proof?.documentName}
            </Button>
          ) : (
            ''
          ),
          proofSubmittedAmount: (
            <Typographys variant="body2">{i?.proofSubmittedAmount?.proofSubmittedAmount}</Typographys>
          ),
        };
      })}
      columns={investmentDeclarationColumn}
      showActionList={false}
      showCheckbox={false}
      sortDirection={sortDirection}
      sortBy={sortBy}
      onSortChange={onSortChange}
    />
  );
};
InvestmentDeclarationData.defaultProps = {
  declarationDetails: [],
  downloadAttachment: () => {},
  sortDirection: 'desc',
  sortBy: 'id',
  onSortChange: () => {},
};
InvestmentDeclarationData.propTypes = {
  declarationDetails: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      proof: PropTypes.string,
    }),
  ),
  downloadAttachment: PropTypes.func,
  onSortChange: PropTypes.func,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
};

export default InvestmentDeclarationData;
