export const SurveyStyle = {
  boxWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    mt: 2.5,
    backgroundColor: 'neutral.100',
    p: 3,
    borderRadius: '0.75rem',
    mb: 2.5,
  },

  questionsTitle: { textAlign: 'right', paddingRight: '16px' },
};
