import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import MuiTabs from '@mui/material/Tabs';
import { styled, useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import { ListItemIcon } from '@mui/material';
import { useDispatch } from 'react-redux';
import { updateSelectedModule } from 'store/slices/theme/customization';
import menuItems from '../../menu-items/index';

const BottomNavigation = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const theme = useTheme();

  const handleChange = (item) => {
    setValue(item);
    dispatch(updateSelectedModule(item));
  };
  const Tabs = styled(MuiTabs)(() => ({
    '& .MuiTab-root': {
      minHeight: 'auto',
      fontSize: '1.75rem',
      lineHeight: '2.5rem',
      color: theme.palette.neutral[600],
      fontWeight: '400',
      padding: '0.5rem 1.25rem',
      borderRadius: '0.5rem',
      marginLeft: 0,
      marginRight: '0.625rem',
      '& .MuiTabs-scrollableX': {
        '& .MuiTabs-flexContainer': {
          borderBottom: 'none',
        },
      },
      '& .MuiListItemIcon-root': {
        marginBottom: '0.5rem',
      },
      '& .MuiSvgIcon-root': {
        color: theme.palette.neutral[600],
      },
      ':hover': {
        background: theme.palette.primary[100],
        color: theme.palette.primary[300],
        '& .MuiSvgIcon-root': {
          color: theme.palette.primary[300],
        },
      },
      '&.Mui-selected': {
        background: theme.palette.primary[100],
        color: theme.palette.primary[300],
        '& .MuiSvgIcon-root': {
          color: theme.palette.primary[300],
        },
      },
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  }));
  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        textColor="secondary"
        indicatorColor="primary"
        aria-label="scrollable auto tabs example"
      >
        {menuItems.items
          .filter((item) => item.type === 'group')
          .map((item) => {
            const Icon = item.icon;
            return (
              <Tab
                key={item.id}
                icon={
                  <ListItemIcon sx={{ justifyContent: 'center' }}>
                    <Icon stroke={1.5} sx={{ height: 20, width: 20 }} />
                  </ListItemIcon>
                }
                label={item.title}
                value={item.id}
              />
            );
          })}
      </Tabs>
    </Paper>
  );
};
export default BottomNavigation;
