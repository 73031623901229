export const alumniDashboardStyle = {
  tabCardWrapper: {
    border: '0.125rem solid',
    borderColor: 'neutral.300',
    padding: '0',
    maxHeight: '100%',
    borderRadius: '6px',
    backgroundColor: 'common.white',
  },
  actionBtnGroup: {
    display: 'flex',
    columnGap: 1,
    marginTop: '4rem',
    marginBottom: '3.5rem',
    justifyContent: 'right',
  },
};
