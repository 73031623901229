import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import ProfileEditEmploymentHistory from 'components/Organisms/Profile/EmploymentHistory/Edit';
import useToast from 'components/Provider/useToast';
import { getToastMessage } from 'utils/toastMessage';

const ProfileEditEmploymentHistoryTemplate = ({
  handleSubmit,
  handleCancel,
  defaultValue,
  toastSuccessMessage,
  toastErrorMessage,
  showToast,
  toastType,
  loading,
}) => {
  const breadcrumbData = [
    { id: 1, name: 'Employment History', path: '/profile/employment-history', isActive: false },
    { id: 2, name: 'Edit', isActive: true },
  ];

  const addToast = useToast();

  useEffect(() => {
    if (showToast) {
      const successMessage = toastSuccessMessage || getToastMessage.ProfileEmployeeHistory()[0];
      const error = toastErrorMessage || getToastMessage.ProfileEmployeeHistory()[1];

      const title = toastType === 'success' ? 'Success!' : 'Error!';
      const type = toastType === 'success' ? 'success' : 'error';
      const message = toastType === 'success' ? successMessage : error;
      addToast({ type, title, message });
    }
  }, [showToast, toastErrorMessage, toastSuccessMessage, toastType]);

  return (
    <BlankTemplate
      pageHeading="Edit Employment History"
      isBreadcrumb
      breadcrumbData={breadcrumbData}
      padding="0 3rem 2rem 3rem"
    >
      <ProfileEditEmploymentHistory
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        defaultValue={defaultValue}
        loading={loading}
      />
    </BlankTemplate>
  );
};

ProfileEditEmploymentHistoryTemplate.defaultProps = {
  handleSubmit: () => {},
  handleCancel: () => {},
  defaultValue: {
    employment: [
      {
        employeeName: '',
        employerAddress: '',
        employmentCommenceDate: '',
        employmentEndDate: '',
        reasonForLeaving: '',
        employeeId: '',
        startDesignation: '',
        endDesignation: '',
        referenceContactName: '',
        referenceContactDesignation: '',
        referenceContactPhone: '',
        referenceContactEmail: '',
        attachment: '',
      },
    ],
  },
  toastSuccessMessage: '',
  toastErrorMessage: '',
  showToast: false,
  toastType: 'success',
  loading: false,
};

ProfileEditEmploymentHistoryTemplate.propTypes = {
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  defaultValue: PropTypes.shape({}),
  toastSuccessMessage: PropTypes.string,
  toastErrorMessage: PropTypes.string,
  showToast: PropTypes.bool,
  toastType: PropTypes.oneOf(['error', 'success']),
  loading: PropTypes.bool,
};

export default ProfileEditEmploymentHistoryTemplate;
