import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import TitleWithText from 'components/Atoms/TitleWithText';

const TdsPaymentDetails = ({ tdsPaymentDeatils }) => {
  return (
    <>
      <Typographys variant="h5" color="neutral.700">
        TDS Payment Details
      </Typographys>
      <Grid container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Income Tax" text={tdsPaymentDeatils.incomeTaxComponent} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Surcharge" text={tdsPaymentDeatils.surchargeComponent} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Cess" text={tdsPaymentDeatils.eduCessComponent} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Interest" text={tdsPaymentDeatils.interest} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Fees" text={tdsPaymentDeatils.fees} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Penalty" text={tdsPaymentDeatils.penaltyOthers} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Others" text={tdsPaymentDeatils.others} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Last Total Tax Paid" text={tdsPaymentDeatils.lastTotalTaxPaid} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Total Amount Deposited" text={tdsPaymentDeatils.totalChallanAmountDeposited} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Last Challan BSR Code" text={tdsPaymentDeatils.lastBSRCode} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Last Challan Payment Date" text={tdsPaymentDeatils.lastPaymentDepositDate} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Last Challan Serial Number" text={tdsPaymentDeatils.lastChallanSerialNumber} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Interest Apportioned" text={tdsPaymentDeatils.interestApportioned} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Voucher Number" text={tdsPaymentDeatils.voucherNumber} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Voucher Date" text={tdsPaymentDeatils.voucherDate} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Cheque/DD Number" text={tdsPaymentDeatils.chequeDDNumber} />
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Deduction Date" text={tdsPaymentDeatils.deductionDate} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Minorhead Code" text={tdsPaymentDeatils.minorHeadCode} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Late Payment Fee" text={tdsPaymentDeatils.latePaymentFee} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Acknowledgement Number" text={tdsPaymentDeatils.acknowledgementNumber} />
        </Grid>
      </Grid>
    </>
  );
};

TdsPaymentDetails.defaultProps = {
  tdsPaymentDeatils: {
    incomeTaxComponent: 'Male',
    surchargeComponent: 'UnMarried',
    eduCessComponent: '07/06/1960',
    interest: '04/06/2017',
    fees: 'Full Time',
    penaltyOthers: 'Vidyasagar',
    others: 'Snehalatha',
    lastTotalTaxPaid: '-',
    totalChallanAmountDeposited: 'Mumbai',
    lastBSRCode: 'AIVpDUAFTA',
    lastPaymentDepositDate: '123456789128',
    lastChallanSerialNumber: '100002345878886',
    interestApportioned: 'AJHTUH5654TH',
    voucherNumber: '34567124552',
    voucherDate: '6787659223',
    chequeDDNumber: '890898723239',
    deductionDate: 'AJHTUH5654TH',
    minorHeadCode: '34567124552',
    latePaymentFee: '6787659223',
    acknowledgementNumber: '890898723239',
  },
};

TdsPaymentDetails.propTypes = {
  tdsPaymentDeatils: PropTypes.shape({
    incomeTaxComponent: PropTypes.string,
    surchargeComponent: PropTypes.string,
    eduCessComponent: PropTypes.string,
    interest: PropTypes.string,
    fees: PropTypes.string,
    penaltyOthers: PropTypes.string,
    others: PropTypes.string,
    lastTotalTaxPaid: PropTypes.string,
    totalChallanAmountDeposited: PropTypes.string,
    lastBSRCode: PropTypes.string,
    lastPaymentDepositDate: PropTypes.string,
    lastChallanSerialNumber: PropTypes.string,
    interestApportioned: PropTypes.string,
    voucherNumber: PropTypes.string,
    voucherDate: PropTypes.string,
    chequeDDNumber: PropTypes.string,
    deductionDate: PropTypes.string,
    minorHeadCode: PropTypes.string,
    latePaymentFee: PropTypes.string,
    acknowledgementNumber: PropTypes.string,
  }),
};

export default TdsPaymentDetails;
