import React from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import ListingTable from 'components/Organisms/ListingTable';

const TaskAndQueriesTemplate = ({
  pageHeading,
  rows,
  columns,
  roleBasedMenu,
  page,
  sortDirection,
  sortBy,
  handleSelect,
  handleAddRequest,
  actions,
  handleFilter,
  showFilter,
  onSortChange,
  onPageChange,
  showPagination,
  onRowClick,
  showRowSelector,
  selectedItems,
  onSelect,
  onSelectAll,
  isDynamic,
  showActionList,
  actionOptions,
  count,
  filterFields,
  handleFiltersClear,
  showAddButton,
  loading,
}) => {
  return (
    <BlankTemplate pageHeading={pageHeading} isBreadcrumb={false} padding="2rem 3rem">
      <ListingTable
        loading={loading}
        rows={rows}
        columns={columns}
        roleBasedMenu={roleBasedMenu}
        page={page}
        sortDirection={sortDirection}
        sortBy={sortBy}
        handleSelect={handleSelect}
        handleAddRequest={handleAddRequest}
        actions={actions}
        handleFilter={handleFilter}
        showFilter={showFilter}
        onSortChange={onSortChange}
        onPageChange={onPageChange}
        showPagination={showPagination}
        onRowClick={onRowClick}
        showRowSelector={showRowSelector}
        selectedItems={selectedItems}
        onSelect={onSelect}
        onSelectAll={onSelectAll}
        isDynamic={isDynamic}
        showActionList={showActionList}
        actionOptions={actionOptions}
        count={count}
        filterFields={filterFields}
        handleFiltersClear={handleFiltersClear}
        showAddButton={showAddButton}
      />
    </BlankTemplate>
  );
};

TaskAndQueriesTemplate.defaultProps = {
  pageHeading: 'My Queries',
  rows: [],
  columns: [],
  handleAddRequest: () => {},
  handleSelect: () => {},
  sortBy: () => {},
  page: '',
  sortDirection: '',
  roleBasedMenu: () => {},
  actions: true,
  handleFilter: () => {},
  showFilter: true,
  onSortChange: () => {},
  onPageChange: () => {},
  showPagination: false,
  onRowClick: () => {},
  selectedItems: [],
  showRowSelector: false,
  onSelect: () => {},
  onSelectAll: () => {},
  isDynamic: true,
  showActionList: true,
  actionOptions: [],
  filterFields: [],
  handleFiltersClear: () => {},
  showAddButton: true,
  loading: false,
};

TaskAndQueriesTemplate.propTypes = {
  pageHeading: PropTypes.string,
  handleAddRequest: PropTypes.func,
  handleSelect: PropTypes.func,
  sortBy: PropTypes.func,
  page: PropTypes.number,
  sortDirection: PropTypes.string,
  columns: PropTypes.arrayOf,
  rows: PropTypes.arrayOf,
  roleBasedMenu: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  actions: PropTypes.bool,
  handleFilter: PropTypes.bool,
  showFilter: PropTypes.bool,
  onSortChange: PropTypes.func,
  onPageChange: PropTypes.func,
  showPagination: PropTypes.bool,
  count: PropTypes.number.isRequired,
  onRowClick: PropTypes.func,
  selectedItems: PropTypes.arrayOf,
  showRowSelector: PropTypes.bool,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  isDynamic: PropTypes.bool,
  showActionList: PropTypes.bool,
  actionOptions: PropTypes.arrayOf,
  filterFields: PropTypes.arrayOf,
  handleFiltersClear: PropTypes.func,
  showAddButton: PropTypes.bool,
  loading: PropTypes.bool,
};

export default TaskAndQueriesTemplate;
