import React, { useEffect, useState } from 'react';
import Table from 'components/Molecules/TableExtended';
import Typography from 'components/Atoms/Typography';
import BlankTemplate from 'components/Templates/BlankTemplate';
import { useNavigate, useParams } from 'react-router-dom';
import { hasPermission } from 'utils/permissions';
import { useSelector } from 'react-redux';
import { getMyReportings, getEmployeeUpwardHierarchy } from 'api/home';

const MyReporting = () => {
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasViewEmployeeReporteePermission = hasPermission(authorities, [
    'ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_LIST_EMPLOYEE_REPORTEES',
  ]);

  const navigate = useNavigate();
  const [isloading, setIsLoading] = useState(false);
  const [myReportingDetails, setMyReportingDetails] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (id) {
        const query = new URLSearchParams();
        query.append('id', id);
        const data = await getEmployeeUpwardHierarchy(query);
        setMyReportingDetails(data);
      } else {
        const data = await getMyReportings();
        setMyReportingDetails(data);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [id]);

  if (myReportingDetails?.data?.status === 'ERROR') {
    return (
      <Typography variant="h6">
        {myReportingDetails?.data?.message || `Error while loading my reporting details.`}
      </Typography>
    );
  }

  const columns = [
    {
      fieldName: 'upwardLevel',
      numeric: true,
      label: 'Upward Level',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'employeeName',
      label: 'Employee Name',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'employeeId',
      label: 'Employee Id',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'supervisor',
      label: 'Supervisor',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
  ];

  const onRowClick = (row) => {
    if (userHasViewEmployeeReporteePermission) {
      navigate(`/home/reportees/${row.id}`);
    }
  };

  const rows =
    myReportingDetails?.data?.employeeLevelMap !== undefined
      ? Object.entries(myReportingDetails?.data?.employeeLevelMap).map(([hierarchyLevel, hierarchyDetails]) => ({
          id: hierarchyDetails?.id,
          upwardLevel: hierarchyLevel,
          employeeName: hierarchyDetails?.fullName || '-',
          employeeId: hierarchyDetails?.profile?.employeeID || '-',
          supervisor: hierarchyDetails?.supervisor?.fullName || '-',
        }))
      : [];

  return (
    <BlankTemplate isBreadcrumb={false} pageHeading="Upward Hierarchy">
      <Table columns={columns} rows={rows} count="0" loading={isloading} onRowClick={onRowClick} />
    </BlankTemplate>
  );
};

export default MyReporting;
