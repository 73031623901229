import { makeStyles } from '@mui/styles';
import { v4 as uuid } from 'uuid';

const uid = uuid();

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      paddingRight: theme.space.spaceS4x,
      paddingLeft: theme.space.spaceS4x,
      backgroundColor: theme.palette.colors.colorBackgroundBase,
      border: theme.palette.colors.colorBorderNeutral,
      borderRadius: theme.shape.radiusTiny,
      borderWidth: theme.border.borderThin,
      borderStyle: 'solid',
    },
    fieldset: {
      paddingTop: theme.space.spaceS4x,
      paddingBottom: theme.space.spaceS6x,
    },
    focusedRoot: {
      border: theme.palette.colors.colorBorderPrimary,
      borderWidth: theme.border.borderThin,
      borderStyle: 'solid',
    },
    focusedRootBottomBorder: {
      borderBottomLeftRadius: theme.space.spaceSZero,
      borderBottomRightRadius: theme.space.spaceSZero,
      borderBottomWidth: theme.space.spaceSZero,
    },
  }),
  {
    classNamePrefix: 'textEditorContainer',
    name: 'textEditorContainer',
    generateId: uid,
    index: uid,
  },
);
