import { useState, useReducer } from 'react';
import useToast from 'components/Provider/useToast';
import { employeeExitsDelete, employeeExitslockDetails, initiateSpecificEmpExit } from 'api/employees/separations';
import get from 'lodash/get';
import { useNavigate } from 'react-router-dom';

const initialState = {
  employeeSeparationDetails: null,
};

const reducer = (state, action) => {
  const { type, payload } = action;
  let newState;
  switch (type) {
    case 'FETCH_EMPLOYEE_SEPARATION': {
      newState = { ...state, employeeSeparationDetails: payload };
      break;
    }
    default: {
      newState = state;
    }
  }
  return newState;
};

const useSeparationsService = () => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showLockDetailsDialog, setShowLockDetailsDialog] = useState(false);
  const [refreshEmployeeExitsList, setRefreshEmployeeExitsList] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const addToast = useToast();
  const navigate = useNavigate();

  const viewSeparation = (row) => {
    const id = get(row, 'empExit.id', '');
    navigate(`/myteam/separations/view/${id}`);
  };

  const editSeparation = (row) => {
    const id = get(row, 'empExit.id', '');
    navigate(`/myteam/separations/edit/${id}`);
  };

  const handleDeleteDialog = () => {
    setShowDeleteDialog(!showDeleteDialog);
  };

  const handleLockDetailsDialog = () => {
    setShowLockDetailsDialog(!showLockDetailsDialog);
  };

  const handleActionRequest = (row) => {
    const id = get(row, 'empExit.id', '');
    navigate(`/myteam/separations/action-request/${id}`);
  };

  const handleViewExitTaskList = (row) => {
    const id = get(row, 'empExit.employeeId', '');
    navigate(`/myteam/separations/exit-list/${id}`);
  };

  const deleteSeparation = async (id) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', id);
      const response = await employeeExitsDelete(bodyFormData);
      if (response?.status === 'SUCCESS') {
        addToast({ type: 'success', title: 'SUCCESS', message: response?.message });
        setRefreshEmployeeExitsList(!refreshEmployeeExitsList);
      }
    } catch (error) {
      addToast({ type: 'error', title: 'ERROR', message: error?.message });
    } finally {
      handleDeleteDialog(false);
    }
  };

  const lockDetailsSeparation = async (row) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', get(row, 'empExit.id', ''));
      const response = await employeeExitslockDetails(bodyFormData);
      if (response?.status === 'SUCCESS') {
        addToast({ type: 'success', title: 'SUCCESS', message: response?.message });
        setRefreshEmployeeExitsList(!refreshEmployeeExitsList);
      }
    } catch (error) {
      addToast({ type: 'error', title: 'ERROR', message: error?.message });
    } finally {
      handleLockDetailsDialog(false);
    }
  };

  const handleInitiateSeparation = (response) => {
    if (response) {
      dispatch({ type: 'FETCH_EMPLOYEE_SEPARATION', payload: response });
    }
  };

  return {
    state,
    deleteSeparation,
    showDeleteDialog,
    handleDeleteDialog,
    refreshEmployeeExitsList,
    viewSeparation,
    editSeparation,
    showLockDetailsDialog,
    handleLockDetailsDialog,
    lockDetailsSeparation,
    handleActionRequest,
    handleViewExitTaskList,
    handleInitiateSeparation,
  };
};

export default useSeparationsService;
