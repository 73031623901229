import PropTypes from 'prop-types';
import React from 'react';
import { Grid, Box, useTheme, Divider } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import TitleWithText from 'components/Atoms/TitleWithText';
import Chips from 'components/Molecules/Chip';
import InputField from 'components/Atoms/InputField/index';
import Attachments from 'components/Atoms/Attachments';
import Button from 'components/Atoms/Button';
import { validateDateElseHyphen } from 'utils/commonUtils';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import IconWithTitle from 'components/Atoms/IconWithTitle';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { isEmpty, isObject } from 'lodash';
import { lifeCycleRecordStyle } from '../style';

const LcCaseRecordAction = ({
  recordDetails,
  newRecordDetails,
  handleSubmit,
  handleCancel,
  loading,
  creatorAttachment,
  approverAttachments,
  handleAttachmentDownload,
  handleExtendProbation,
  userHasExtendProbationPermission,
}) => {
  const formik = useFormik({
    initialValues: {
      attachment: '',
      notes: '',
    },
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const theme = useTheme();
  const isVerifiedType =
    recordDetails?.changeType === 'Background Verified' ||
    recordDetails?.changeType === 'Terminate' ||
    recordDetails?.changeType === 'Confirm';
  const isExtendProbation =
    recordDetails?.changeType === 'Extend Probation' || recordDetails?.changeType === 'Begin Performance Improvement';
  const isDefault = !isVerifiedType && !isExtendProbation;
  return (
    <>
      <Box
        sx={{
          background: theme.palette.actions[100],
          padding: '2rem 3rem',
          marginBottom: '3rem',
          borderRadius: '0.75rem',
        }}
      >
        <Grid container columnSpacing={1} rowSpacing={1}>
          <Grid item xs={6} sm={3} md={3}>
            <TitleWithText title="Created by" text={recordDetails?.name || '-'} />
          </Grid>
          <Grid item xs={6} sm={3} md={3}>
            <TitleWithText title="Status" text={<Chips label={recordDetails?.status || '-'} color="success" />} />
          </Grid>
          <Grid item xs={6} sm={3} md={3}>
            <TitleWithText title="Created on" text={validateDateElseHyphen(recordDetails?.date, 'DD MMMM YYYY')} />
          </Grid>
        </Grid>
      </Box>

      <Box sx={lifeCycleRecordStyle.dataTable}>
        <Grid container sx={lifeCycleRecordStyle.dataRowHead}>
          <Grid item xs={6} sm={6}>
            <Typographys variant="caption">Current Employee Details</Typographys>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Typographys variant="caption"> Change Record Details</Typographys>
          </Grid>
        </Grid>
        <Grid container sx={{ ...lifeCycleRecordStyle.dataRow, alignItems: 'start' }}>
          <Grid item xs={6} sm={6}>
            <Grid container>
              <>
                <Grid container xs={6} sm={6} sx={{ mb: 1 }}>
                  <Typographys component="span" variant="body1">
                    Supervisor
                  </Typographys>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typographys component="span" variant="body2">
                    {recordDetails?.supervisor || '-'}
                  </Typographys>
                </Grid>
              </>
              <>
                <Grid container xs={6} sm={6} sx={{ mb: 1 }}>
                  <Typographys component="span" variant="body1">
                    Department
                  </Typographys>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typographys component="span" variant="body2">
                    {recordDetails?.department || '-'}
                  </Typographys>
                </Grid>
              </>
              <>
                <Grid container xs={6} sm={6} sx={{ mb: 1 }}>
                  <Typographys component="span" variant="body1">
                    Designation
                  </Typographys>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typographys component="span" variant="body2">
                    {recordDetails?.designation || '-'}
                  </Typographys>
                </Grid>
              </>
              <>
                <Grid container xs={6} sm={6} sx={{ mb: 1 }}>
                  <Typographys component="span" variant="body1">
                    Location
                  </Typographys>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typographys component="span" variant="body2">
                    {recordDetails?.location || '-'}
                  </Typographys>
                </Grid>
              </>
              <>
                <Grid container xs={6} sm={6} sx={{ mb: 1 }}>
                  <Typographys component="span" variant="body1">
                    Employee Level
                  </Typographys>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typographys component="span" variant="body2">
                    {recordDetails?.employeeLevel || '-'}
                  </Typographys>
                </Grid>
              </>
              <>
                <Grid container xs={6} sm={6} sx={{ mb: 1 }}>
                  <Typographys component="span" variant="body1">
                    Employee Grade
                  </Typographys>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typographys component="span" variant="body2">
                    {recordDetails?.employeeGrade || '-'}
                  </Typographys>
                </Grid>
              </>
              <>
                <Grid container xs={6} sm={6} sx={{ mb: 1 }}>
                  <Typographys component="span" variant="body1">
                    Division
                  </Typographys>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typographys component="span" variant="body2">
                    {recordDetails?.division || '-'}
                  </Typographys>
                </Grid>
              </>
              <>
                <Grid container xs={6} sm={6} sx={{ mb: 1 }}>
                  <Typographys component="span" variant="body1">
                    Reason For {recordDetails?.changeType}
                  </Typographys>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typographys component="span" variant="body2">
                    {newRecordDetails?.reason || '-'}
                  </Typographys>
                </Grid>
              </>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Grid container>
              {!!(isVerifiedType || isExtendProbation) && (
                <>
                  <Grid container xs={6} sm={6} sx={{ mb: 1 }}>
                    <Typographys component="span" variant="body1">
                      {recordDetails?.changeType} Effective From
                    </Typographys>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typographys component="span" variant="body2">
                      {validateDateElseHyphen(newRecordDetails?.effectiveDate, 'DD MMMM YYYY')}
                    </Typographys>
                  </Grid>
                </>
              )}
              {!!isExtendProbation && (
                <>
                  <Grid container xs={6} sm={6} sx={{ mb: 1 }}>
                    <Typographys component="span" variant="body1">
                      {recordDetails?.changeType} Effective Up to
                    </Typographys>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typographys component="span" variant="body2">
                      {validateDateElseHyphen(newRecordDetails?.effectiveEndDate, 'DD MMMM YYYY')}
                    </Typographys>
                  </Grid>
                </>
              )}
              {!!isDefault && (
                <>
                  <Grid container xs={6} sm={6} sx={{ mb: 1 }}>
                    <Typographys component="span" variant="body1">
                      Supervisor
                    </Typographys>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typographys component="span" variant="body2">
                      {newRecordDetails?.supervisor || '-'}
                    </Typographys>
                  </Grid>
                  <Grid container xs={6} sm={6} sx={{ mb: 1 }}>
                    <Typographys component="span" variant="body1">
                      Department
                    </Typographys>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typographys component="span" variant="body2">
                      {newRecordDetails?.department || '-'}
                    </Typographys>
                  </Grid>

                  <Grid container xs={6} sm={6} sx={{ mb: 1 }}>
                    <Typographys component="span" variant="body1">
                      Designation
                    </Typographys>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typographys component="span" variant="body2">
                      {newRecordDetails?.designation || '-'}
                    </Typographys>
                  </Grid>

                  <Grid container xs={6} sm={6} sx={{ mb: 1 }}>
                    <Typographys component="span" variant="body1">
                      Location
                    </Typographys>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typographys component="span" variant="body2">
                      {newRecordDetails?.location || '-'}
                    </Typographys>
                  </Grid>

                  <Grid container xs={6} sm={6} sx={{ mb: 1 }}>
                    <Typographys component="span" variant="body1">
                      Employee Level
                    </Typographys>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typographys component="span" variant="body2">
                      {newRecordDetails?.employeeLevel || '-'}
                    </Typographys>
                  </Grid>

                  <Grid container xs={6} sm={6} sx={{ mb: 1 }}>
                    <Typographys component="span" variant="body1">
                      Employee Grade
                    </Typographys>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typographys component="span" variant="body2">
                      {newRecordDetails?.employeeGrade || '-'}
                    </Typographys>
                  </Grid>

                  <Grid container xs={6} sm={6} sx={{ mb: 1 }}>
                    <Typographys component="span" variant="body1">
                      Division
                    </Typographys>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typographys component="span" variant="body2">
                      {newRecordDetails?.division || '-'}
                    </Typographys>
                  </Grid>

                  <Grid container xs={6} sm={6} sx={{ mb: 1 }}>
                    <Typographys component="span" variant="body1">
                      Effective start date
                    </Typographys>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typographys component="span" variant="body2">
                      {validateDateElseHyphen(newRecordDetails?.effectiveDate, 'DD MMMM YYYY')}
                    </Typographys>
                  </Grid>
                </>
              )}
              <>
                <Grid container xs={6} sm={6} sx={{ mb: 1 }}>
                  <Typographys component="span" variant="body1">
                    Attachment
                  </Typographys>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Attachments
                    wrapperStyles={{ flexDirection: 'column', alignItems: 'start' }}
                    isDeleteIcon
                    handleChange={(event) => {
                      formik.setFieldValue(`attachment`, event.target.files[0]);
                    }}
                    name="attachment"
                    id="attachment"
                    value={formik.values.attachment}
                    handleDelete={() => {
                      formik.setFieldValue(`attachment`, '');
                    }}
                  />
                </Grid>
              </>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {isObject(creatorAttachment) && !isEmpty(creatorAttachment) && (
        <>
          <Divider sx={{ mt: 0.5, mb: 2, backgroundColor: theme.palette.neutral[200] }} />

          <Box sx={{ mt: 1.5, mb: 1.5 }}>
            <Typographys variant="h6" color="neutral.800">
              Record Creator Attachment:
            </Typographys>

            <Box sx={{ mt: 1.5, width: 'max-content' }}>
              <Box>
                <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                  <span>1.</span>
                  <IconWithTitle
                    sx={{ cursor: 'pointer', width: 'max-content' }}
                    iconElement={<AttachFileIcon fontSize="inherit" />}
                    iconColor="actions.600"
                    iconFontSize="2.5rem"
                    title={creatorAttachment?.documentName}
                    titleColor="neutral.800"
                    titleVariant="body1"
                    onClick={() => handleAttachmentDownload(creatorAttachment?.id, creatorAttachment?.documentName)}
                    isClickable
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}

      {isObject(approverAttachments) && !isEmpty(approverAttachments) && (
        <>
          <Divider sx={{ mt: 0.5, mb: 2, backgroundColor: theme.palette.neutral[200] }} />

          <Box sx={{ mt: 1.5, mb: 1.5 }}>
            <Typographys variant="h6" color="neutral.800">
              Approver Attachments:
            </Typographys>

            <Box sx={{ mt: 1.5, width: 'max-content' }}>
              <Box>
                {approverAttachments?.map((item, i) => (
                  <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                    <span>{i + 1}.</span>
                    <IconWithTitle
                      sx={{ cursor: 'pointer', width: 'max-content' }}
                      iconElement={<AttachFileIcon fontSize="inherit" />}
                      iconColor="actions.600"
                      iconFontSize="2.5rem"
                      title={item?.documentName}
                      titleColor="neutral.800"
                      titleVariant="body1"
                      onClick={() => handleAttachmentDownload(item?.id, item?.documentName)}
                      isClickable
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </>
      )}
      <Divider sx={{ mt: 0.5, mb: 2, backgroundColor: theme.palette.neutral[200] }} />
      <Box sx={{ mt: 3, mb: 10 }}>
        <InputField
          error={Boolean(formik.touched.notes && formik.errors.notes)}
          helperText={formik.touched.notes && formik.errors.notes}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.notes}
          name="notes"
          id="notes"
          label="Approval/Rejection Notes"
          multiline
          fullWidth
          InputLabelProps={{
            shrink: 'true',
          }}
        />
      </Box>
      <Box sx={lifeCycleRecordStyle.btnContainer}>
        <Divider sx={{ my: 2, borderColor: theme.palette.other[200] }} />
        <Box sx={lifeCycleRecordStyle.submitWrapper}>
          <Button
            size="large"
            color="info"
            variant="outlined"
            type="cancel"
            onClick={() => {
              formik.resetForm();
              handleCancel();
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            size="large"
            variant="outlined"
            onClick={() => handleSubmit(formik.values, 'Revert')}
          >
            Reject
          </LoadingButton>
          {!!userHasExtendProbationPermission && (
            <LoadingButton loading={loading} size="large" variant="outlined" onClick={handleExtendProbation}>
              Extend Probation
            </LoadingButton>
          )}
          <LoadingButton
            loading={loading}
            color="primary"
            size="large"
            type="submit"
            variant="contained"
            onClick={() => handleSubmit(formik.values, 'Approve')}
          >
            Approve
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
};

LcCaseRecordAction.propTypes = {
  recordDetails: {},
  newRecordDetails: {},
  handleCancel: () => {},
  handleSubmit: () => {},
  loading: false,
  creatorAttachment: {},
  approverAttachments: {},
  handleAttachmentDownload: () => {},
};

LcCaseRecordAction.defaultProps = {
  recordDetails: PropTypes.shape(),
  newRecordDetails: PropTypes.shape(),
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  creatorAttachment: PropTypes.shape(),
  approverAttachments: PropTypes.shape(),
  handleAttachmentDownload: PropTypes.func,
};
export default LcCaseRecordAction;
