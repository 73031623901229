export const iconWithTitleAndDescStyle = {
  iconWithTitleWrapper: {
    display: 'flex',
    columnGap: '2rem',
    alignItems: 'flex-start',
    button: {
      mt: '0.5rem',
    },
  },
};
