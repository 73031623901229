export const policyListTabStyle = {
  wrapper: {
    backgroundColor: 'common.white',
    padding: '2rem 3rem',

    '.MuiTableContainer-root': {
      mt: 2,
    },

    '.MuiFormControl-root': {
      '.MuiInputLabel-root': {
        mt: 0,
      },
      '.MuiSelect-select': {
        minWidth: '22rem',
      },
      '.MuiOutlinedInput-root .MuiSelect-outlined': {
        paddingTop: '1rem',
        paddingBottom: '1rem',
      },
    },

    table: {
      td: {
        '&:nth-child(4)': {
          minWidth: '25rem',
        },
      },
    },
  },

  employeeMappingWrapper: {
    padding: 0,
    borderTop: '0.125rem solid ',
    borderColor: 'neutral.300',
    backgroundColor: 'common.white',

    '.MuiTabs-root': {
      px: 2,
      borderBottom: '0.125rem solid ',
      borderColor: 'other.200',
    },
    '.MuiTabs-flexContainer': {
      display: 'inline-flex',
    },
  },

  divider: {
    my: 2,
    borderColor: 'other.200',
  },

  btnContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%',
    backgroundColor: 'common.white',
    zIndex: '9',
    margin: '0rem',
    padding: '0rem 3rem 2rem 3rem',
    boxSizing: 'border-box',
  },

  submitWrapper: {
    display: 'flex',
    columnGap: '2rem',
    justifyContent: 'flex-end',
  },
};
