import { api, jsonApi } from 'api/api';

export const loginUser = async ({ username, password }) => {
  const data = JSON.stringify({ username, password });
  const response = await jsonApi.post('/api/login', data);
  return response.data;
};

export const logoutUser = async () => {
  const response = await jsonApi.post('/restSentinel/logout');
  return response;
};

export const resetPassword = async (data) => {
  const response = await api.post('/authRest/forgotPasswordAction', data);
  return response.data;
};

export const getCurrentUser = async () => {
  const response = await jsonApi.post('restApi/getCurrentUser');
  return response;
};
