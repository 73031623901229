import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { myPayStyle } from '../../style';

const ClaimsDetails = ({ claimsDetailsData, landlordInfo }) => {
  let epfJSX = '';
  const deductionJSX = {
    '80C': [],
    '80CCC': [],
    '80CCD(1)': [],
    outside80C: [],
  };
  if (claimsDetailsData.employeePFContribution !== 0) {
    epfJSX = (
      <TableRow>
        <TableCell />
        <TableCell>
          <Typographys variant="body2">Employees Provident Fund (auto populated through payroll)</Typographys>
        </TableCell>
        <TableCell>
          <Typographys variant="body2">{claimsDetailsData.employeePFContribution}</Typographys>
        </TableCell>
        <TableCell variant="body2">{claimsDetailsData.ePFEP}</TableCell>
      </TableRow>
    );
  }
  if (Object.keys(claimsDetailsData.deductionData).length > 0) {
    Object.keys(claimsDetailsData.deductionData).forEach((deductionName) => {
      const deductionEachData = claimsDetailsData.deductionData[deductionName];
      if (deductionEachData.length > 0) {
        deductionEachData.forEach((eachSecItemData) => {
          deductionJSX[deductionName].push(
            <TableRow>
              <TableCell />
              <TableCell>{eachSecItemData.name}</TableCell>
              <TableCell>{eachSecItemData.amount}</TableCell>
              <TableCell variant="body2">{eachSecItemData.lastTableCellText}</TableCell>
            </TableRow>,
          );
        });
      }
    });
  }
  const landlordInfoJSX = [];
  if (landlordInfo.length > 0) {
    landlordInfo.forEach((info) =>
      landlordInfoJSX.push(
        <TableRow>
          <TableCell />
          <TableCell>
            {info.map((innerData) => (
              <Typographys variant="body2">{innerData.title}</Typographys>
            ))}
          </TableCell>
          <TableCell>
            {info.map((innerData) => (
              <Typographys variant="body2">{innerData.value}</Typographys>
            ))}
          </TableCell>
          <TableCell variant="body2">Photo copy of Rent Receipts /Rental agreement</TableCell>
        </TableRow>,
      ),
    );
  }

  let propertyDataJSX = '';
  if (claimsDetailsData.financialInstitutionsHeadingDataArr.length > 0) {
    propertyDataJSX = (
      <TableRow>
        <TableCell />
        <TableCell>
          {claimsDetailsData.financialInstitutionsHeadingDataArr.map((financialInstituteData, ind) => (
            <>
              <Typographys variant="body2">
                {ind + 1}. Interest on {financialInstituteData.propertyType} (Including Pre-EMI Interest) Financial
                Institutions (if available)
              </Typographys>
              <Typographys variant="subtitle2">
                Name of the Financial Institution: {financialInstituteData.lenderName}
              </Typographys>
              <Typographys variant="subtitle2">
                PAN of the Financial Institution: {financialInstituteData.lenderPAN}
              </Typographys>
              <Typographys variant="subtitle2">
                Address of the Financial Institution: {financialInstituteData.lenderAddress}
              </Typographys>
            </>
          ))}
        </TableCell>
        <TableCell>
          <Typographys variant="body2">
            {claimsDetailsData.financialInstitutionsAmountArr.map((amount) => (
              <>
                {amount}
                <br />
              </>
            ))}
          </Typographys>
        </TableCell>
        <TableCell variant="body2">Housing Loan certificate from Bank / Bank Statement</TableCell>
      </TableRow>
    );
  }

  return (
    <Box>
      <Typographys variant="h6" mr={1} color="neutral.800" align="center">
        Details of claims and evidence there of
      </Typographys>
      <Box mt={2} sx={myPayStyle.tableWrapper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Si. No.</TableCell>
              <TableCell>Nature of Claim</TableCell>
              <TableCell>Amount (Rs.)</TableCell>
              <TableCell>Evidence/particulars</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typographys variant="body2">(1)</Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="body2">(2)</Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="body2">(3)</Typographys>
              </TableCell>
              <TableCell variant="body2">(4)</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typographys variant="body2">1</Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="body2">House Rent Allowance:</Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="body2">{claimsDetailsData?.hraAmount || 0}</Typographys>
              </TableCell>
              <TableCell variant="body2">{claimsDetailsData?.hraEP}</TableCell>
            </TableRow>

            {landlordInfoJSX}

            <TableRow>
              <TableCell>
                <Typographys variant="body2">2</Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="body2">Leave travel concessions or assistance:</Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="body2">{claimsDetailsData?.lTAmount || 0}</Typographys>
              </TableCell>
              <TableCell variant="body2">{claimsDetailsData?.lTEP}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typographys variant="body2">3</Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="body2">
                  Deductions of interest on borrowing (income from house property):
                </Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="body2">{claimsDetailsData?.dIBAmount}</Typographys>
              </TableCell>
              <TableCell variant="body2">{claimsDetailsData?.dIBEP}</TableCell>
            </TableRow>

            {propertyDataJSX}

            <TableRow>
              <TableCell>
                <Typographys variant="body2">4</Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="body2">Deductions under Chapter VI-A:</Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="body2">{claimsDetailsData?.dUCAmount}</Typographys>
              </TableCell>
              <TableCell variant="body2">{claimsDetailsData?.dUCEP}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell />
              <TableCell>
                <Typographys variant="body2">A. Section 80C, 80CCCC and 80CCD:</Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="body2">{claimsDetailsData?.dUCSectionAmount}</Typographys>
              </TableCell>
              <TableCell variant="body2">{claimsDetailsData?.dUCSectionEP}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell />
              <TableCell>
                <Typographys variant="body2">(i). Section 80C:</Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="body2">{claimsDetailsData?.dUCSection80CAmount}</Typographys>
              </TableCell>
              <TableCell variant="body2">{claimsDetailsData?.dUCSection80CEP}</TableCell>
            </TableRow>

            {epfJSX}

            {deductionJSX['80C']}

            <TableRow>
              <TableCell />
              <TableCell>
                <Typographys variant="body2">(ii). Life Insurance Pension Scheme (Section 80CCC):</Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="body2">{claimsDetailsData?.lIPSAmount}</Typographys>
              </TableCell>
              <TableCell variant="body2">{claimsDetailsData?.lIPSEP}</TableCell>
            </TableRow>

            {deductionJSX['80CCC']}

            <TableRow>
              <TableCell />
              <TableCell>
                <Typographys variant="body2">
                  (iii). Employee&#39;s contribution towards NPS (Section 80CCD):
                </Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="body2">{claimsDetailsData?.eCTNPSAmount}</Typographys>
              </TableCell>
              <TableCell variant="body2">{claimsDetailsData?.eCTNPSEP}</TableCell>
            </TableRow>

            {deductionJSX['80CCD(1)']}

            <TableRow>
              <TableCell />
              <TableCell>
                <Typographys variant="body2">
                  B. Other Sections (e.g. 80E, 80G, 80TTA, etc.) under Chapter VI-A.
                </Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="body2">{claimsDetailsData?.oSUCAmount}</Typographys>
              </TableCell>
              <TableCell variant="body2">{claimsDetailsData?.oSUCEP}</TableCell>
            </TableRow>
          </TableBody>

          {deductionJSX.outside80C}
        </Table>
      </Box>
    </Box>
  );
};
ClaimsDetails.defaultProps = {
  claimsDetailsData: {},
  landlordInfo: [],
};

ClaimsDetails.propTypes = {
  claimsDetailsData: PropTypes.shape,
  landlordInfo: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
  ),
};
export default ClaimsDetails;
