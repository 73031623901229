import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import FormData from 'form-data';
import SelectEmployeeGroup from 'components/Organisms/Dialog/ActionList/SelectEmployeeGroup';

import { fetchGenericEmployeeGroupAction } from 'api/employees';

const convertData = (data) => {
  return data?.genericGroupInstanceList
    ?.filter((item) => item.groupStatus === 'ACTIVE')
    .map((item) => ({
      id: item.id,
      name: item.groupName,
      onClick: () => {},
    }));
};

const MapEmployeeGroupContainer = ({ open, handleClose, handleComment, selectedEmployee }) => {
  const [items, setItems] = useState([]);
  const [gid, setGid] = useState('');

  const handleChange = (event) => {
    const selectedItem = event.target.value;
    setGid(selectedItem);
  };

  useEffect(() => {
    const fetchGenericEmployeeGroup = async () => {
      const query = new URLSearchParams();
      query.append('et', 'EMPLOYEES');
      const response = await fetchGenericEmployeeGroupAction(query);
      if (response.status === 200) {
        const transformedData = convertData(response.data);
        setItems(transformedData);
      }
    };

    if (open) fetchGenericEmployeeGroup();
  }, [selectedEmployee?.id, open]);
  return (
    <SelectEmployeeGroup
      open={open}
      handleClose={handleClose}
      handleCancel={handleClose}
      handleComment={() => {
        handleComment(selectedEmployee.id, gid);
      }}
      userName={selectedEmployee?.firstName}
      options={items}
      handleChange={handleChange}
    />
  );
};

MapEmployeeGroupContainer.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleComment: PropTypes.func,
  selectedEmployee: PropTypes.shape({
    firstName: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
};

MapEmployeeGroupContainer.defaultProps = {
  handleComment: () => {},
  open: false,
  handleClose: () => {},
};

export default MapEmployeeGroupContainer;
