import PropTypes from 'prop-types';
import React from 'react';
import Dialog from 'components/Molecules/Dialog';
import Typographys from 'components/Atoms/Typography';

const DeleteLifeCycleRecord = ({ handleCancel, primaryBtn, secondaryBtn, open, handleClose, handleDelete }) => {
  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      dialogTitle="Delete Life cycle Case Record"
      dialogDesc={
        <Typographys variant="body2" color="neutral.600" align="center">
          Are you sure you want to delete employee’s life cycle case record?
        </Typographys>
      }
      handlePrimaryBtn={handleDelete}
      primaryBtnColor="error"
      primaryBtn={primaryBtn}
      secondaryBtn={secondaryBtn}
      handleSecondaryBtn={handleCancel}
      bodyContent={false}
    />
  );
};
DeleteLifeCycleRecord.defaultProps = {
  handleDelete: () => {},
  primaryBtn: 'Delete',
  secondaryBtn: 'Cancel',
  handleCancel: () => {},
  open: true,
  handleClose: () => {},
};
DeleteLifeCycleRecord.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleDelete: PropTypes.func,
  handleCancel: PropTypes.func,
  primaryBtn: PropTypes.string,
  secondaryBtn: PropTypes.string,
};
export default DeleteLifeCycleRecord;
