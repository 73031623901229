import { formDataApi } from 'api/api';

// Home > My Pay > Salary Slips
export const salarySlipList = async () => {
  const response = await formDataApi.get('restPayIndex/mySalarySlips');
  return response?.data;
};

export const viewTDSDetails = async () => {
  const response = await formDataApi.get('restPayIndex/myDeclaration');
  return response?.data;
};

// Home > My Pay > SalarySlipDetails when - consolidatedSalarySlips: false
export const salarySlipDetails = async (query) => {
  const response = await formDataApi.get(`restPayIndex/salarySlipDetails?${query.toString()}`);
  return response?.data;
};

// Home > My Pay > SalarySlipDetails when - consolidatedSalarySlips: true
export const consolidatedSalarySlipDetails = async (query) => {
  const response = await formDataApi.get(`restPayIndex/myConsolidatedSalarySlipDetails?${query.toString()}`);
  return response?.data;
};

// Home > My Pay > Form12BB
export const form12BB = async () => {
  const response = await formDataApi.get('restPayIndex/displayMyForm12BB');
  return response?.data;
};

// Home > My Pay > My Salary Details
export const getMySalaryDetails = async () => {
  const response = await formDataApi.get('restPayIndex/displayMySalaryDetails');
  return response?.data;
};

// Home > My Pay > TDS Workings
export const getTDSWorkingDetails = async () => {
  const response = await formDataApi.get('restPayIndex/displayMyTaxWorking');
  return response?.data;
};

export const compareTDSDetails = async () => {
  const response = await formDataApi.get('restPayIndex/assessMyTDSWorkingsUnderRegimes');
  return response?.data;
};

// Home > My Pay > Upload Investment Proofs
export const uploadMyInvestmentProofs = async () => {
  const response = await formDataApi.get(`restPayIndex/uploadMyInvestmentProofs`);
  return response?.data;
};

export const uploadMyInvestmentProofsAction = async (formData) => {
  const response = await formDataApi.post(`restPayIndex/uploadMyInvestmentProofsAction`, formData);
  return response;
};

export const modifyTDSDetails = async () => {
  const response = await formDataApi.get('restPayIndex/modifyMyDeclaration');
  return response?.data;
};

export const fetchUploadMyForm12BB = async () => {
  const response = await formDataApi.get('restPayIndex/uploadMyForm12BB');
  return response;
};

export const uploadMyForm12BBAction = async (formData) => {
  const response = await formDataApi.post(`restPayIndex/uploadMyForm12BBAction`, formData);
  return response?.data;
};

export const fetchCreatePreviousEmploymentTaxDetails = async () => {
  const response = await formDataApi.get('restPayIndex/createPreviousEmploymentTaxDetailsForEmployee');
  return response?.data;
};

export const fetchEditPreviousEmploymentTaxDetails = async (formData) => {
  const response = await formDataApi.post(`restPayIndex/modifyPreviousEmploymentTaxDetailsForEmployee`, formData);
  return response?.data;
};

export const fetchPreviousEmploymentTaxDetails = async (query) => {
  const response = await formDataApi.get(
    `restPayIndex/displayPreviousEmploymentTaxDetailsForEmployee?${query.toString()}`,
  );
  return response?.data;
};

export const deletePreviousEmploymentTaxAction = async (formData) => {
  const response = await formDataApi.put('restPayIndex/deletePreviousEmploymentTaxDetailsForEmployee', formData);
  return response?.data;
};

export const submitPreviousEmploymentTaxAction = async (formData) => {
  const response = await formDataApi.put(
    'restPayIndex/submitForReviewPreviousEmploymentTaxDetailsForEmployee',
    formData,
  );
  return response?.data;
};

export const listPreviousEmploymentTaxes = async (query) => {
  const response = await formDataApi.get(
    `restPayIndex/listPreviousEmploymentTaxDetailsForEmployee?${query.toString()}`,
  );
  return response?.data;
};
