/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import MuiTab from 'components/Molecules/Tab';
import LanguageIcon from '@mui/icons-material/Language';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import SchoolIcon from '@mui/icons-material/School';
import HighlightIcon from '@mui/icons-material/Highlight';
import SocialData from 'components/Organisms/Profile/OnboardingRecords/AdditionalDetails/SocialData';
import KeyEventDetail from 'components/Organisms/Profile/OnboardingRecords/AdditionalDetails/KeyLifeEvents';
import VisaDetail from './VisaDetails/index';
import SkillsAttainmentDetail from './SkillsAttainmentDetails';
import EmploymentHistory from './EmploymentHistory';
import EducationHistory from './EducationHistory';
import { onBoardingStyle } from '../style';

const AdditionalDetail = ({
  socialData,
  educationHistory,
  employmentHistory,
  visaData,
  skillsAttainmentDetails,
  keyEventDetails,
  handleEducationAttachment,
  handleEmploymentAttachment,
  handleSkillAttachment,
  handleKeyEventAttachment,
  handleAttachment,
}) => {
  const [initialTabs, setTabs] = useState([
    {
      icon: <LanguageIcon />,
      iconPosition: 'start',
      label: 'Social Data',
      onChange: () => {},
      RenderComponent: SocialData,
      componentProps: { socialData },
    },
    {
      icon: <SchoolIcon />,
      iconPosition: 'start',
      label: 'Education/Certification History',
      onChange: () => {},
      RenderComponent: EducationHistory,
      componentProps: { educationHistory, handleAttachment },
    },
    {
      icon: <WatchLaterIcon />,
      iconPosition: 'start',
      label: 'Employment History',
      onChange: () => {},
      RenderComponent: EmploymentHistory,
      componentProps: { employmentHistory, handleAttachment },
    },
    {
      icon: <FlightTakeoffIcon />,
      iconPosition: 'start',
      label: 'Visa/Passport Details',
      onChange: () => {},
      RenderComponent: VisaDetail,
      componentProps: { visaData, handleAttachment },
    },
    {
      icon: <HighlightIcon />,
      iconPosition: 'start',
      label: 'Skills Attainment Details',
      onChange: () => {},
      RenderComponent: SkillsAttainmentDetail,
      componentProps: { skillsAttainmentDetails, handleAttachment },
    },

    // Commenting for now since its not present in hrEntries.

    // {
    //   icon: <EmojiEventsIcon />,
    //   iconPosition: 'start',
    //   label: 'Key Life Event Details',
    //   onChange: () => {},
    //   RenderComponent: KeyEventDetail,
    //   componentProps: { keyEventDetails, handleKeyEventAttachment },
    // },
  ]);

  return (
    <Box sx={onBoardingStyle.additionalWrapper}>
      <MuiTab
        orientation="vertical"
        textColor="primary"
        indicatorColor="primary"
        isVertical
        panelPadding={{ xs: '2rem 0', md: '2rem 0 2rem 3rem' }}
        isDropdownMob
        tabs={initialTabs}
        setTabs={setTabs}
      />
    </Box>
  );
};

AdditionalDetail.defaultProps = {
  onboardingDetails: {},
  socialData: {},
  educationHistory: [],
  employmentHistory: [],
  visaData: [],
  skillsAttainmentDetails: [],
  keyEventDetails: [],
  handleEducationAttachment: () => {},
  handleEmploymentAttachment: () => {},
  handleSkillAttachment: () => {},
  handleKeyEventAttachment: () => {},
};

AdditionalDetail.propTypes = {
  onboardingDetails: PropTypes.shape({}),
  socialData: PropTypes.shape({}),
  educationHistory: PropTypes.shape({}),
  employmentHistory: PropTypes.shape({}),
  visaData: PropTypes.shape({}),
  skillsAttainmentDetails: PropTypes.shape({}),
  keyEventDetails: PropTypes.shape({}),
  handleEducationAttachment: PropTypes.func,
  handleEmploymentAttachment: PropTypes.func,
  handleSkillAttachment: PropTypes.func,
  handleKeyEventAttachment: PropTypes.func,
};

export default AdditionalDetail;
