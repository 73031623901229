export const separationStyle = {
  buttonWrapper: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%',
    backgroundColor: 'common.white',
    zIndex: '9',
    margin: '0rem',
    padding: '0rem 3rem 2rem 3rem',
    boxSizing: 'border-box',
  },

  Divider: {
    my: 2,
    backgroundColor: 'neutral.200',
  },

  flexWrapper: {
    display: 'flex',
    justifyContent: 'end',
    gap: 2,
  },

  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '4rem',
  },

  moreIcon: {
    cursor: 'pointer',
  },
};
