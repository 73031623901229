import PropTypes from 'prop-types';
import React, { useState } from 'react';
import AddNotes from 'components/Organisms/Dialog/ActionList/AddNotes';

const convertData = (data) => {
  return {
    supervisor: '',
    department: data.department,
    designation: data.designation,
    location: data.location,
    id: data.id,
    name: `${data.firstName} ${data.lastName}`,
  };
};

const AddNoteContainer = ({ open, handleClose, handleComment, selectedEmployee }) => {
  const [note, setNote] = useState('');

  const handleChange = (event) => {
    const selectedItem = event.target.value;
    setNote(selectedItem);
  };

  const empData = convertData(selectedEmployee);

  return (
    <AddNotes
      open={open}
      handleClose={handleClose}
      handleCancel={handleClose}
      handleComment={() => {
        handleComment(selectedEmployee.id, note);
      }}
      userName={selectedEmployee?.firstName}
      empData={empData}
      onChange={handleChange}
    />
  );
};

AddNoteContainer.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleComment: PropTypes.func,
  selectedEmployee: PropTypes.shape({
    firstName: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
};

AddNoteContainer.defaultProps = {
  handleComment: () => {},
  open: false,
  handleClose: () => {},
};

export default AddNoteContainer;
