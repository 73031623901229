import React, { useEffect } from 'react';
import BasicProfileTemplate from 'components/Templates/Profile/BasicProfile/View/index';
import { fetchBasicProfileDetails } from 'store';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useThunk } from 'hooks/useThunk';
import { Typography, Skeleton, Box } from '@mui/material';
import { validateDateElseHyphen } from 'utils/commonUtils';
import { API_URL } from 'api/api';
import { GetDocumentList } from './useGetDocumentList';

const BasicProfile = () => {
  const navigate = useNavigate();

  const [doFetchBasicProfile, isLoadingBasicProfile, loadingBasicProfileError] = useThunk(fetchBasicProfileDetails);

  const { employeeProfileInstanceDetails, employeeInstanceDetails } = useSelector((state) => {
    return state.profile;
  });

  const { user } = useSelector((state) => {
    return state.session;
  });

  useEffect(() => {
    doFetchBasicProfile();
  }, [doFetchBasicProfile]);

  const handleEdit = () => {
    navigate('/profile/basic-profile/edit');
  };

  const getEmergencyContactInfo = () => {
    const primaryReferenceDependents =
      employeeProfileInstanceDetails?.dependents &&
      employeeProfileInstanceDetails?.dependents?.filter(
        (item) => item?.emergencyContactReference === 'PrimaryReference',
      );
    return primaryReferenceDependents?.[0] ? primaryReferenceDependents[0] : {};
  };

  const getDocumentRows = () => {
    let fullDocList = [];
    const identityDocArray =
      employeeProfileInstanceDetails?.documents !== undefined ? employeeProfileInstanceDetails?.documents : [];

    if (identityDocArray !== undefined) {
      fullDocList = [...fullDocList, ...identityDocArray];
    }

    const jobProfileArray =
      employeeProfileInstanceDetails?.pastEmploymentRecords !== undefined
        ? employeeProfileInstanceDetails?.pastEmploymentRecords.map((pastEmployer) =>
            pastEmployer?.documents.map((docDetails) => docDetails),
          )[0]
        : [];

    if (jobProfileArray !== undefined) {
      fullDocList = [...fullDocList, ...jobProfileArray];
    }

    const educationDocArray =
      employeeProfileInstanceDetails?.educationRecords !== undefined
        ? employeeProfileInstanceDetails?.educationRecords.map((educationItem) =>
            educationItem?.documents.map((docDetails) => docDetails),
          )[0]
        : [];

    if (educationDocArray !== undefined) {
      fullDocList = [...fullDocList, ...educationDocArray];
    }
    const rowOfDocs = GetDocumentList(fullDocList);
    return rowOfDocs;
  };

  const configuredData = {
    employeeDetail: {
      panNumber: employeeProfileInstanceDetails?.employee?.panNumberIndia || '-',
      aadharNumber: employeeProfileInstanceDetails?.employee?.aadhaarNumberIndia || '-',
      uan: employeeProfileInstanceDetails?.employee?.uanNumberIndia || '-',
      epfAccountNumber: employeeProfileInstanceDetails?.employee?.epfAccountNumberIndia || '-',
      esiAccountNumber: employeeProfileInstanceDetails?.employee?.esiAccountNumberIndia || '-',
    },
    employeePersonalInformation: {
      bloodGroup: employeeProfileInstanceDetails?.bloodGroup || '-',
      dateOfBirth: validateDateElseHyphen(employeeProfileInstanceDetails?.dateOfBirth, 'DD MMMM YYYY'),
      dateOfJoining: validateDateElseHyphen(employeeProfileInstanceDetails?.dateOfJoining, 'DD MMMM YYYY'),
      motherName: employeeProfileInstanceDetails?.mothersName || '-',
      fatherName: employeeProfileInstanceDetails?.fathersName || '-',
      spouseName: employeeProfileInstanceDetails?.spouseName || '-',
      schoolGoingChildren: employeeProfileInstanceDetails?.numberOfSchoolGoingChildren || 0,
      childrenHostel: employeeProfileInstanceDetails?.numberOfChildrenInHostel || 0,
    },
    employeeEmergencyContactInformation: {
      name: `${getEmergencyContactInfo()?.contactInfo?.firstName || '-'} ${
        getEmergencyContactInfo()?.contactInfo?.lastName || '-'
      }`,
      mobileNumber: getEmergencyContactInfo()?.contactInfo?.mobileNumber || '-',
      relation: getEmergencyContactInfo()?.relationship || '-',
      emailid: getEmergencyContactInfo()?.contactInfo?.emailAddress || '-',
    },

    employeeProfileDetail: {
      name: employeeProfileInstanceDetails?.employee?.fullName || '-',
      avatarSrc: user?.profilePic && user?.profilePic !== 'null' ? `${API_URL}/${user?.profilePic}` : '',
      location: employeeProfileInstanceDetails?.employee?.location?.name || '-',
      email: employeeProfileInstanceDetails?.employee?.emailAddresses
        ? employeeProfileInstanceDetails?.employee?.emailAddresses[0]
        : '-',
      mobile: employeeProfileInstanceDetails?.employee?.mobileNumbers
        ? employeeProfileInstanceDetails?.employee?.mobileNumbers[0]
        : '-',
      phone:
        employeeProfileInstanceDetails?.employee?.telephoneNumbers?.length > 0
          ? employeeProfileInstanceDetails?.employee?.telephoneNumbers[0]
          : '',
      designation: employeeInstanceDetails?.designation?.name || '-',
      department: employeeInstanceDetails?.department?.name || '-',
      supervisor: employeeInstanceDetails?.supervisor?.fullName || '-',
      employeeID: employeeProfileInstanceDetails?.employeeID || '-',
      employmentType: employeeProfileInstanceDetails?.employee?.employmentType || '-',
    },
    rowsPerPage: 10,
    rows: getDocumentRows(),
    employeeContactDetails: employeeProfileInstanceDetails?.employee?.mobileNumbers || '-',
    employeeContactEmailId: employeeProfileInstanceDetails?.employee?.emailAddresses || '-',
  };

  if (isLoadingBasicProfile) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (loadingBasicProfileError) {
    return (
      <Typography variant="h6">
        {loadingBasicProfileError?.message || 'Error while fetching profile details.'}
      </Typography>
    );
  }

  return (
    <BasicProfileTemplate
      employeeDetail={configuredData.employeeDetail}
      employeePersonalInformation={configuredData.employeePersonalInformation}
      employeeEmergencyContactInformation={configuredData.employeeEmergencyContactInformation}
      employeeProfileDetail={configuredData.employeeProfileDetail}
      rowsPerPage={configuredData.rowsPerPage}
      rows={configuredData.rows}
      employeeContactDetails={configuredData.employeeContactDetails}
      employeeContactEmailId={configuredData.employeeContactEmailId}
      handleEdit={handleEdit}
      loading={isLoadingBasicProfile}
    />
  );
};

export default BasicProfile;
