export const leaveStyle = {
  actionBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  formContainer: {
    width: '100%',
    maxWidth: '100%',
    mt: 0,
    mb: 5,
  },

  formDivider: {
    '&:not(:first-child)': {
      borderTop: '0.125rem solid',
      borderColor: 'other.200',
      mt: 4,
      pt: 4,
    },
  },

  textDivider: {
    '&:not(:first-child)': {
      borderTop: '0.125rem solid',
      borderColor: 'other.200',
      mt: 2.5,
      pt: 2.5,
    },
  },

  addButton: {
    my: 3,
  },

  btnContainer: {
    position: 'fixed',
    right: '0rem',
    bottom: '3.625rem',
    backgroundColor: 'common.white',
    zIndex: '9',
    margin: '0rem 3.25rem',
    width: 'calc(100% - 49.5rem)',
    padding: '0rem 3rem 3.25rem 3rem',
  },

  submitWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 2,
  },

  submitButtons: {
    display: 'flex',
    justifyContent: 'end',
    gap: 2,
    flex: 1,
  },
};

export const leaveTableStyle = {
  container: {
    maxHeight: '500px',
    overflow: 'auto',
    border: '1px solid #B7BDCC',
    borderRadius: '1rem',
  },
};
