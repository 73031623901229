import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import Button from 'components/Atoms/Button';
import EditIcon from '@mui/icons-material/Edit';
import TitleWithText from 'components/Atoms/TitleWithText';
import Typographys from 'components/Atoms/Typography';
import NoRecordsMessage from 'components/Organisms/CommonPageUtils/NoRecordsFound';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { validateDateElseHyphen } from 'utils/commonUtils';
import { hasPermission } from 'utils/permissions';
import { useSelector } from 'react-redux';
import { profileStyle } from '../style';

const ProfileVisaDetails = ({ visaDetails, handleEdit, handleAttachment }) => {
  const updatedDate = (value) => validateDateElseHyphen(value, 'DD MMMM YYYY');
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasEditPermission = hasPermission(authorities, [
    'ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_MY_VISA_DETAILS',
  ]);

  return (
    <>
      <Box sx={profileStyle.actionBtn}>
        {!!userHasEditPermission && (
          <Button startIcon={<EditIcon fontSize="2.5rem" />} onClick={handleEdit}>
            {visaDetails.length > 0 ? 'Edit' : 'Add'}
          </Button>
        )}
      </Box>

      {visaDetails.length > 0 ? (
        <Box mt={4}>
          {visaDetails.map((visaDetail) => (
            <Box sx={{ ...profileStyle.divider, mt: 4, pt: 2.5 }}>
              <Grid container rowSpacing={1} columnSpacing={2}>
                <Grid item xs={12} sm={4} lg={3}>
                  <TitleWithText title="Issuing Country" text={visaDetail?.issuingCountry || '-'} />
                </Grid>

                <Grid item xs={12} sm={4} lg={3}>
                  <TitleWithText title="Place of Issue" text={visaDetail?.placeOfIssue || '-'} />
                </Grid>

                <Grid item xs={12} sm={4} lg={3}>
                  <TitleWithText title="Valid From" text={updatedDate(visaDetail?.validFrom)} />
                </Grid>

                <Grid item xs={12} sm={4} lg={3}>
                  <TitleWithText title="Valid To" text={updatedDate(visaDetail?.validTo)} />
                </Grid>

                <Grid item xs={12} sm={4} lg={3}>
                  <TitleWithText title="Issue Date" text={updatedDate(visaDetail?.issueDate)} />
                </Grid>

                <Grid item xs={12} sm={4} lg={3}>
                  <TitleWithText title="Reference Number" text={visaDetail?.referenceNumber || '-'} />
                </Grid>

                <Grid item xs={12} sm={4} lg={3}>
                  <TitleWithText title="Visa Status" text={visaDetail?.status || '-'} />
                </Grid>

                <Grid item xs={12} sm={12} lg={12}>
                  <TitleWithText title="Comments" text={visaDetail?.comments || '-'} />
                </Grid>

                <Grid item xs={12} mt={3}>
                  <Typographys variant="h6" color="neutral.800">
                    Attachments
                  </Typographys>
                  <Button
                    variant="text"
                    color="secondary"
                    onClick={() =>
                      handleAttachment(visaDetail?.documents[0]?.id, visaDetail?.documents[0]?.documentName)
                    }
                    startIcon={<AttachFileIcon />}
                  >
                    {visaDetail?.documents[0]?.documentName || ''}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Box>
      ) : (
        <NoRecordsMessage width="20rem" height="20rem" />
      )}
    </>
  );
};

ProfileVisaDetails.defaultProps = {
  visaDetails: [],
  handleEdit: () => {},
  handleAttachment: () => {},
};

ProfileVisaDetails.propTypes = {
  visaDetails: PropTypes.arrayOf,
  handleEdit: PropTypes.func,
  handleAttachment: PropTypes.func,
};

export default ProfileVisaDetails;
