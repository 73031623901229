import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid } from '@mui/material';
import TitleWithText from 'components/Atoms/TitleWithText';
import Typography from 'components/Atoms/Typography';
import Button from 'components/Atoms/Button';
import EditIcon from '@mui/icons-material/Edit';
import DropdownMenu from 'components/Molecules/DropdownMenu';
import { validateDateElseHyphen } from 'utils/commonUtils';
import Chips from 'components/Molecules/Chip';
import Avatars from 'components/Molecules/Avatar';
import IconWithTitle from 'components/Atoms/IconWithTitle';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import userImage from 'assets/images/user-round.svg';
import { isObject, isEmpty } from 'lodash';
import { useTheme } from '@mui/material/styles';

import { lifeCycleRecordStyle } from '../style';

const RecordDetails = ({
  recordDetails,
  newRecordDetails,
  auditDetails,
  isEdit,
  handleEdit,
  menuList,
  creatorAttachment,
  approverAttachments,
  handleAttachmentDownload,
}) => {
  const theme = useTheme();
  const isExtendProbation = recordDetails?.changeType === 'Extend Probation';
  const isBeginPerformanceImprovement = recordDetails?.changeType === 'Begin Performance Improvement';
  const isEndPerformanceImprovement = recordDetails?.changeType === 'End Performance Improvement';
  const isDefault =
    recordDetails?.changeType !== 'Terminate' &&
    recordDetails?.changeType !== 'Background Verified' &&
    recordDetails?.changeType !== 'Extend Probation' &&
    recordDetails?.changeType !== 'Begin Performance Improvement' &&
    recordDetails?.changeType !== 'End Performance Improvement';
  return (
    <Box>
      <Box sx={lifeCycleRecordStyle.actionBtn}>
        {!!isEdit && (
          <Button startIcon={<EditIcon fontSize="2.5rem" />} onClick={handleEdit}>
            Edit
          </Button>
        )}
        {!!menuList?.length > 0 && <DropdownMenu btnLabel="Action" size="medium" menuLists={menuList} />}
      </Box>
      <Box mt={4}>
        <Box sx={lifeCycleRecordStyle.otherDivider}>
          <Grid container sx={{ display: 'flex', alignItems: 'start', maxWidth: '97.5rem' }}>
            <Grid item xs={6} sm={3}>
              <TitleWithText title="Created by" text={recordDetails?.name || '-'} />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TitleWithText
                title="Status"
                text={recordDetails?.status ? <Chips label={recordDetails?.status} color="success" /> : '-'}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TitleWithText title="Created on" text={validateDateElseHyphen(recordDetails?.date, 'DD MMMM YYYY')} />
            </Grid>
          </Grid>
          <Box sx={lifeCycleRecordStyle.dataTable}>
            <Grid container sx={lifeCycleRecordStyle.dataRowHead}>
              <Grid item xs={4} sm={4}>
                <Typography variant="caption">ATTRIBUTE NAME</Typography>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Typography variant="caption"> CURRENT VALUE</Typography>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Typography variant="caption"> NEW VALUE</Typography>
              </Grid>
            </Grid>
            <Grid container sx={lifeCycleRecordStyle.dataRow}>
              <Grid item xs={4} sm={4}>
                <Typography variant="body2">Supervisor</Typography>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Typography variant="body2">{recordDetails?.supervisor || '-'}</Typography>
              </Grid>
              {!!isDefault && (
                <Grid item xs={4} sm={4}>
                  <Typography variant="body2">{newRecordDetails?.supervisor || '-'}</Typography>
                </Grid>
              )}
            </Grid>

            <Grid container sx={lifeCycleRecordStyle.dataRow}>
              <Grid item xs={4} sm={4}>
                <Typography variant="body2">Department</Typography>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Typography variant="body2">{recordDetails?.department || '-'}</Typography>
              </Grid>
              {!!isDefault && (
                <Grid item xs={4} sm={4}>
                  <Typography variant="body2">{newRecordDetails?.department || '-'}</Typography>
                </Grid>
              )}
            </Grid>

            <Grid container sx={lifeCycleRecordStyle.dataRow}>
              <Grid item xs={4} sm={4}>
                <Typography variant="body2">Designation</Typography>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Typography variant="body2">{recordDetails?.designation || '-'}</Typography>
              </Grid>
              {!!isDefault && (
                <Grid item xs={4} sm={4}>
                  <Typography variant="body2">{newRecordDetails?.designation || '-'}</Typography>
                </Grid>
              )}
            </Grid>

            <Grid container sx={lifeCycleRecordStyle.dataRow}>
              <Grid item xs={4} sm={4}>
                <Typography variant="body2">Location</Typography>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Typography variant="body2">{recordDetails?.location || '-'}</Typography>
              </Grid>
              {!!isDefault && (
                <Grid item xs={4} sm={4}>
                  <Typography variant="body2">{newRecordDetails?.location || '-'}</Typography>
                </Grid>
              )}
            </Grid>

            <Grid container sx={lifeCycleRecordStyle.dataRow}>
              <Grid item xs={4} sm={4}>
                <Typography variant="body2">Employee Level</Typography>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Typography variant="body2">{recordDetails?.employeeLevel || '-'}</Typography>
              </Grid>
              {!!isDefault && (
                <Grid item xs={4} sm={4}>
                  <Typography variant="body2">{newRecordDetails?.employeeLevel || '-'}</Typography>
                </Grid>
              )}
            </Grid>

            <Grid container sx={lifeCycleRecordStyle.dataRow}>
              <Grid item xs={4} sm={4}>
                <Typography variant="body2">Employee Grade</Typography>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Typography variant="body2">{recordDetails?.employeeGrade || '-'}</Typography>
              </Grid>
              {!!isDefault && (
                <Grid item xs={4} sm={4}>
                  <Typography variant="body2">{newRecordDetails?.employeeGrade || '-'}</Typography>
                </Grid>
              )}
            </Grid>

            <Grid container sx={lifeCycleRecordStyle.dataRow}>
              <Grid item xs={4} sm={4}>
                <Typography variant="body2">Division</Typography>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Typography variant="body2">{recordDetails?.division || '-'}</Typography>
              </Grid>
              {!!isDefault && (
                <Grid item xs={4} sm={4}>
                  <Typography variant="body2">{newRecordDetails?.division || '-'}</Typography>
                </Grid>
              )}
            </Grid>

            <Grid container sx={lifeCycleRecordStyle.dataRow}>
              <Grid item xs={4} sm={4}>
                <Typography variant="body2">{recordDetails?.changeType} effective from</Typography>
              </Grid>
              <Grid item xs={4} sm={4} />
              <Grid item xs={4} sm={4}>
                <Typography variant="body2">
                  {validateDateElseHyphen(newRecordDetails?.effectiveDate, 'DD MMMM YYYY')}
                </Typography>
              </Grid>
            </Grid>

            {(isExtendProbation || isBeginPerformanceImprovement || isEndPerformanceImprovement) && (
              <Grid container sx={lifeCycleRecordStyle.dataRow}>
                <Grid item xs={4} sm={4}>
                  <Typography variant="body2">{recordDetails?.changeType} effective To</Typography>
                </Grid>
                <Grid item xs={4} sm={4} />
                <Grid item xs={4} sm={4}>
                  <Typography variant="body2">
                    {validateDateElseHyphen(newRecordDetails?.effectiveEndDate, 'DD MMMM YYYY')}
                  </Typography>
                </Grid>
              </Grid>
            )}

            <Grid container sx={lifeCycleRecordStyle.dataRow}>
              <Grid item xs={4} sm={4}>
                <Typography variant="body2">Reason for {recordDetails?.changeType}</Typography>
              </Grid>
              <Grid item xs={4} sm={4} />
              <Grid item xs={4} sm={4}>
                <Typography variant="body2">{newRecordDetails?.reasonOfTransfer || '-'}</Typography>
              </Grid>
            </Grid>
          </Box>

          {isObject(creatorAttachment) && !isEmpty(creatorAttachment) && (
            <>
              <Divider sx={{ mt: 0.5, mb: 2, backgroundColor: theme.palette.neutral[200] }} />

              <Box sx={{ mt: 1.5, mb: 1.5 }}>
                <Typography variant="h6" color="neutral.800">
                  Record Creator Attachment:
                </Typography>

                <Box sx={{ mt: 1.5, width: 'max-content' }}>
                  <Box>
                    <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                      <span>1.</span>
                      <IconWithTitle
                        sx={{ cursor: 'pointer', width: 'max-content' }}
                        iconElement={<AttachFileIcon fontSize="inherit" />}
                        iconColor="actions.600"
                        iconFontSize="2.5rem"
                        title={creatorAttachment?.documentName}
                        titleColor="neutral.800"
                        titleVariant="body1"
                        onClick={() => handleAttachmentDownload(creatorAttachment?.id, creatorAttachment?.documentName)}
                        isClickable
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          )}

          {isObject(approverAttachments) && !isEmpty(approverAttachments) && (
            <>
              <Divider sx={{ mt: 0.5, mb: 2, backgroundColor: theme.palette.neutral[200] }} />

              <Box sx={{ mt: 1.5, mb: 1.5 }}>
                <Typography variant="h6" color="neutral.800">
                  Approver Attachments:
                </Typography>

                <Box sx={{ mt: 1.5, width: 'max-content' }}>
                  <Box>
                    {approverAttachments?.map((item, i) => (
                      <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                        <span>{i + 1}.</span>
                        <IconWithTitle
                          sx={{ cursor: 'pointer', width: 'max-content' }}
                          iconElement={<AttachFileIcon fontSize="inherit" />}
                          iconColor="actions.600"
                          iconFontSize="2.5rem"
                          title={item?.documentName}
                          titleColor="neutral.800"
                          titleVariant="body1"
                          onClick={() => handleAttachmentDownload(item?.id, item?.documentName)}
                          isClickable
                        />
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </>
          )}

          <Divider sx={{ mt: 0.5, mb: 2, backgroundColor: theme.palette.neutral[200] }} />

          <Box sx={{ mt: 1.5, mb: 1.5 }}>
            <Typography variant="h6" color="neutral.800">
              Audit Log
            </Typography>

            <Box sx={{ mt: 1.5, width: 'max-content' }}>
              {isObject(auditDetails) && !isEmpty(auditDetails)
                ? Object.values(auditDetails)?.map((_comment, index) => (
                    <Box sx={{ display: 'flex', alignItems: 'flex-start', mt: 1 }}>
                      <Avatars avatarSrc={userImage} variant="circular" sx={{ flexShrink: 0 }} />
                      <Box pl={1.75}>
                        <Typography variant="body2" color="neutral.800">
                          {Object.values(auditDetails)[Object.values(auditDetails).length - index - 1]?.content}
                        </Typography>
                        <Box mt="-0.25rem">
                          <Typography variant="caption" color="neutral.600">
                            {validateDateElseHyphen(
                              Object.values(auditDetails)[Object.values(auditDetails).length - index - 1]?.createdOn,
                              'DD MMMM YYYY',
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ))
                : '-'}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

RecordDetails.defaultProps = {
  recordDetails: {},
  newRecordDetails: {},
  auditDetails: [],
  handleEdit: () => {},
  menuList: [],
  isEdit: false,
  creatorAttachment: {},
  approverAttachments: {},
  handleAttachmentDownload: () => {},
};

RecordDetails.propTypes = {
  recordDetails: PropTypes.shape({
    changeType: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    date: PropTypes.string,
    supervisor: PropTypes.string,
    department: PropTypes.string,
    designation: PropTypes.string,
    location: PropTypes.string,
    employeeLevel: PropTypes.string,
    employeeGrade: PropTypes.string,
    division: PropTypes.string,
  }),
  newRecordDetails: PropTypes.shape({
    supervisor: PropTypes.string,
    department: PropTypes.string,
    designation: PropTypes.string,
    location: PropTypes.string,
    employeeLevel: PropTypes.string,
    employeeGrade: PropTypes.string,
    division: PropTypes.string,
    effectiveDate: PropTypes.string,
    effectiveEndDate: PropTypes.string,
    reasonOfTransfer: PropTypes.string,
  }),
  auditDetails: PropTypes.arrayOf([]),
  handleEdit: PropTypes.func,
  menuList: PropTypes.arrayOf([]),
  isEdit: PropTypes.bool,
  creatorAttachment: PropTypes.shape(),
  approverAttachments: PropTypes.shape(),
  handleAttachmentDownload: PropTypes.func,
};

export default RecordDetails;
