import PropTypes from 'prop-types';
import React from 'react';
import Typographys from 'components/Atoms/Typography';
import { Box, Divider, IconButton } from '@mui/material';
import Avatars from 'components/Molecules/Avatar';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ListAltIcon from '@mui/icons-material/ListAlt';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { calendarStyle } from '../../style';

const LeaveDialog = ({ data }) => {
  return (
    <Box>
      <Box mb={2}>
        <Typographys variant="h4" align="left">
          {data.title}
        </Typographys>
      </Box>
      <Typographys variant="h5" align="left" color="neutral.600">
        {data.employeeName}
      </Typographys>
      <Typographys sx={{ mt: 2 }} variant="body2" align="left" color="neutral.600">
        {data.employeeDesignation}
      </Typographys>
      <Divider sx={{ ...calendarStyle.calenderDivider, my: 2 }} />
      <Typographys sx={{ mb: 2 }} variant="subtitle1" align="left" color="neutral.600">
        Leave details
      </Typographys>
      <Box sx={{ ...calendarStyle.deadlineButtonsWrapper, mb: 1 }}>
        <Box sx={calendarStyle.titleWithIcon}>
          <IconButton sx={{ color: 'actions.600', fontSize: '2.25rem' }}>
            <CalendarTodayIcon fontSize="inherit" />
          </IconButton>
          <Box>
            <Typographys sx={{ textTransform: 'none' }} color="neutral.800">
              {`Leave from - ${data.start}`}
            </Typographys>
          </Box>
        </Box>
        <Box sx={calendarStyle.titleWithIcon}>
          <IconButton sx={{ color: 'actions.600', fontSize: '2.25rem' }}>
            <CalendarTodayIcon fontSize="inherit" />
          </IconButton>
          <Box>
            <Typographys sx={{ textTransform: 'none' }} color="neutral.800">
              {`Leave To - ${data.end}`}
            </Typographys>
          </Box>
        </Box>

        <Box sx={calendarStyle.titleWithIcon}>
          <IconButton sx={{ color: 'actions.600', fontSize: '2.25rem' }}>
            <ListAltIcon fontSize="inherit" />
          </IconButton>
          <Box>
            <Typographys
              sx={{ textTransform: 'none' }}
              color="neutral.800"
            >{`Leave type - ${data.leaveType}`}</Typographys>
          </Box>
        </Box>

        <Box sx={calendarStyle.titleWithIcon}>
          <IconButton sx={{ color: 'actions.600', fontSize: '2.25rem' }}>
            <HelpOutlineOutlinedIcon fontSize="inherit" />
          </IconButton>
          <Box>
            <Typographys color="neutral.800">{`Reason - ${data.reason}`}</Typographys>
          </Box>
        </Box>
      </Box>

      <Divider sx={{ ...calendarStyle.calenderDivider, mt: 2 }} />
    </Box>
  );
};
LeaveDialog.defaultProps = {
  data: {},
};
LeaveDialog.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    employeeName: PropTypes.string,
    employeeImage: PropTypes.string,
    employeeDesignation: PropTypes.string,
    leaveDuration: PropTypes.string,
    leaveType: PropTypes.string,
    leaveReason: PropTypes.string,
    leaveTo: PropTypes.string,
  }),
};
export default LeaveDialog;
