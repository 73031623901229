import React from 'react';
import { useParams } from 'react-router-dom';
import Typography from 'components/Atoms/Typography';
import LCPolicyDetailsTemplate from 'components/Templates/Employee/LCPolicies/Details';
import { displayLifecyclePolicy } from 'api/employees';
import useFetcher from 'hooks/useFetcher';
import useActionItems from './useActionItems';
import { convertEmployeeData } from '../utils/converter';
import usePolicyServices from '../common/usePolicyServices';

const ViewLifecyclePolicy = () => {
  const { policyId } = useParams();
  const args = {
    id: policyId,
  };

  const [lcPolicy] = useFetcher(displayLifecyclePolicy, args);
  const policyServices = usePolicyServices();
  const [actionItems] = useActionItems(policyServices);
  const transformedData = convertEmployeeData(lcPolicy);

  if (lcPolicy.error !== null) {
    return (
      <Typography variant="h6">
        {lcPolicy?.error?.response?.data?.message ||
          `Error while loading lifecycle policy details for id : ${policyId}.`}
      </Typography>
    );
  }
  return lcPolicy.isLoading ? (
    'loading'
  ) : (
    <LCPolicyDetailsTemplate
      policyDetails={transformedData.policyDetails}
      pageHeading={transformedData.policyDetails?.name}
      breadcrumbData={[
        {
          id: 1,
          name: 'Life Cycle Policies',
          path: '/company/employee/company-setup/lifecyle-policies',
          isActive: false,
        },
        { id: 2, name: 'View Details', isActive: true },
      ]}
      handleClick={policyServices?.handleEditLCPolicy}
      menuLists={actionItems}
      transferApproverDetails={transformedData?.transferApproverDetails}
      transferApproverLists={transformedData?.transferApproverLists}
      promotionApproverDetails={transformedData?.promotionApproverDetails}
      promotionApproverLists={transformedData?.promotionApproverLists}
      demotionApproverDetails={transformedData?.demotionApproverDetails}
      demotionApproverLists={transformedData?.demotionApproverLists}
      confirmationAApproverDetails={transformedData?.confirmationAApproverDetails}
      confirmationApproverLists={transformedData?.confirmationApproverLists}
      terminationApproverDetails={transformedData?.terminationApproverDetails}
      terminationApproverLists={transformedData?.terminationApproverLists}
      probationExtensionApproverDetails={transformedData?.probationExtensionApproverDetails}
      probationExtensionApproverLists={transformedData?.probationExtensionApproverLists}
      pipInitiationApproverDetails={transformedData?.pipInitiationApproverDetails}
      pipInitiationApproverLists={transformedData?.pipInitiationApproverLists}
      pipConclusionApproverDetails={transformedData?.pipConclusionApproverDetails}
      pipConclusionApproverLists={transformedData?.pipConclusionApproverLists}
      bgVerificationApproverDetails={transformedData?.bgVerificationApproverDetails}
      bgVerificationApproverLists={transformedData?.bgVerificationApproverLists}
    />
  );
};

export default ViewLifecyclePolicy;
