/* eslint-disable react/prop-types */
import { Button, MenuItem, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { InputField } from 'components/mui/InputField';
import DatePicker from 'components/Atoms/Datepicker';
import { styles } from './styles';

const DynamicField = ({ formik, field, disabled = false }) => {
  const [fileName, setFileName] = useState(null);
  const handleFileChange = useCallback(
    (key, value) => {
      formik.setFieldValue(key, value);
    },
    [formik?.setFieldValue],
  );

  if (field.fieldDataType === 'String' || field.fieldDataType === 'Double' || field.fieldDataType === 'Integer') {
    return (
      <InputField
        error={Boolean(formik.touched[field.fieldLabel] && formik.errors[field.fieldLabel])}
        fullWidth
        helperText={formik.touched[field.fieldLabel] && formik.errors[field.fieldLabel]}
        label={field.fieldLabel}
        name={field.fieldLabel}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        disabled={disabled}
        value={formik.values[field.fieldLabel]}
        type={field.fieldDataType === 'String' ? 'text' : 'number'}
      />
    );
  }
  if (field.fieldDataType === 'Date') {
    return (
      <DatePicker
        error={Boolean(formik.touched[field.fieldLabel] && formik.errors[field.fieldLabel])}
        helperText={formik.touched[field.fieldLabel] && formik.errors[field.fieldLabel]}
        label={field.fieldLabel}
        name={field.fieldLabel}
        onBlur={formik.handleBlur}
        onChange={formik.setFieldValue}
        value={formik.values[field.fieldLabel]}
      />
    );
  }
  if (field.fieldDataType === 'Single Selection List' || field.fieldDataType === 'Multiple Selection List') {
    return (
      <InputField
        fullWidth
        id={field.fieldLabel}
        name={field.fieldLabel}
        value={formik.values[field.fieldLabel]}
        label={field.fieldLabel}
        onChange={formik.handleChange}
        multiple={field.fieldDataType === 'Multiple Selection List'}
        select
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {field.fieldListOptions.map((fieldListOption) => {
          return (
            <MenuItem key={fieldListOption} value={fieldListOption}>
              {fieldListOption}
            </MenuItem>
          );
        })}
      </InputField>
    );
  }
  if (field.fieldDataType === 'Boolean') {
    return (
      <InputField
        fullWidth
        id={field.fieldLabel}
        name={field.fieldLabel}
        value={formik.values[field.fieldLabel]}
        label={field.fieldLabel}
        onChange={formik.handleChange}
        select
      >
        <MenuItem value="true">True</MenuItem>
        <MenuItem value="false">False</MenuItem>
      </InputField>
    );
  }
  if (field.fieldDataType === 'File Attachment') {
    return (
      <Button sx={styles.fileButton} variant="outlined" component="label" color="secondary">
        <div className="fileLabel">{field.fieldLabel}</div>
        {fileName ? (
          <div className="fileName">
            <Typography variant="body2">{fileName}</Typography>
          </div>
        ) : (
          <div className="noFile">
            <Typography variant="body2">Upload File</Typography>
          </div>
        )}
        <InputField
          error={Boolean(formik.touched[field.fieldLabel] && formik.errors[field.fieldLabel])}
          fullWidth
          helperText={formik.touched[field.fieldLabel] && formik.errors[field.fieldLabel]}
          name={field.fieldLabel}
          onBlur={formik.handleBlur}
          type="file"
          sx={{
            position: 'absolute',
            opacity: 0,
          }}
          InputLabelProps={{
            shrink: 'true',
          }}
          onChange={(e) => {
            const fileReader = new FileReader();
            fileReader.onload = () => {
              if (fileReader.readyState === 2) {
                handleFileChange([field.fieldLabel], fileReader.result);
              }
            };
            fileReader.readAsDataURL(e.target.files[0]);
            setFileName(e.target.files[0].name);
          }}
        />
      </Button>
    );
  }
  return null;
};

export default DynamicField;
