import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import Divider from '@mui/material/Divider';
import EmployeeSeparationDetails from '../EmployeeSeparationDetails';
import SeparationForm from '../SeparationForm';

const InitiateSeparationForm = ({
  employeeDetails,
  separationData,
  onSubmit,
  acceptingManagerOptions,
  handleCancel,
  isEdit,
  loading,
}) => {
  return (
    <Box>
      <Typographys color="neutral.600" variant="body2" sx={{ mb: 1.5 }}>
        Hi {employeeDetails?.employeeName},
        <br />
        We are very sorry to know that you have decided to initiate your separation. Please fill in all the relevant
        fields below and submit your separation request. Your manager will then be notified of the same. Subsequent
        actions will be managed by the system as per the Separations Management Policy defined by your HR Team. Should
        you be required to take any subsequent actions as part of the separations process, you will be intimated of the
        same through actions on your portal.
      </Typographys>
      <Typographys color="neutral.600" variant="body2" sx={{ mb: 1.5 }}>
        <Box component="span" color="neutral.800" fontSize={16} fontWeight={600}>
          NOTE:&nbsp;
        </Box>
        You will continue to have access to this portal until your last working day. Post that your access to the portal
        will be deactivated. Hence, we request you to please download any relevant information (such as your payslips,
        TDS workings etc) before your last working day.
      </Typographys>
      <Typographys color="neutral.600" variant="body2" sx={{ mb: 1.5 }}>
        Wishing you the best for your future.
      </Typographys>
      <Typographys color="neutral.600" variant="body2">
        Thanks and Regards, <br /> HR Department
      </Typographys>
      <Typographys variant="body2">{employeeDetails?.companyName}</Typographys>

      <Divider sx={{ my: 2.5 }} />

      <EmployeeSeparationDetails employeeDetails={employeeDetails} />
      <Divider sx={{ mt: 2.5, mb: 1 }} />
      <SeparationForm
        separationData={separationData}
        handleSubmit={onSubmit}
        handleCancel={handleCancel}
        acceptingManagerOptions={acceptingManagerOptions}
        isEdit={isEdit}
        loading={loading}
      />
    </Box>
  );
};

InitiateSeparationForm.defaultProps = {
  employeeDetails: {},
  separationData: {},
  onSubmit: () => {},
  handleCancel: () => {},
  acceptingManagerOptions: [],
  isEdit: false,
  loading: false,
};

InitiateSeparationForm.propTypes = {
  employeeDetails: PropTypes.shape({
    employeeName: PropTypes.string,
    companyName: PropTypes.string,
    separationType: PropTypes.string,
    noticePeriod: PropTypes.string,
    lastWorkingDay: PropTypes.string,
    resignationSubmissionDate: PropTypes.string,
  }),
  separationData: PropTypes.shape({}),
  onSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  acceptingManagerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  isEdit: PropTypes.bool,
  loading: PropTypes.bool,
};

export default InitiateSeparationForm;
