export const searchStyle = {
  wrapper: {
    position: 'absolute',
    top: '6.25rem',
    left: 0,
    backgroundColor: 'neutral.200',
    width: '100%',
    borderRadius: '0.5rem',
    padding: '1.25rem',
    fontSize: '1.75rem',
    fontFamily: 'Inter, sans-serif',
  },

  searchBar: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '50rem',
    width: '100%',
    '@media (max-width: 900px)': {
      padding: '1rem',
      width: 'auto',
    },
  },

  iconButton: {
    padding: 0,
    color: 'neutral.600',
    mr: 1,
    '@media (max-width: 900px)': {
      marginRight: 0,
    },
  },

  searchInput: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    '@media (max-width: 900px)': {
      position: 'fixed',
      left: 0,
      ml: 0,
      zIndex: '9',
      backgroundColor: 'neutral.100',
      width: '100%',
      top: '9rem',
      px: '1rem',
      boxSizing: 'border-box',
    },
  },
};
