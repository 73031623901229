import React from 'react';
import { Box, Grid, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import Button from 'components/Atoms/Button';
import InputField from 'components/Atoms/InputField/index';
import { useTheme } from '@mui/material/styles';
import Dropdown from 'components/Molecules/Dropdown';
import Attachments from 'components/Atoms/Attachments';
import * as yup from 'yup';
import DeleteIcon from '@mui/icons-material/Delete';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Typographys from 'components/Atoms/Typography';
import { taskStyle } from '../style';

const AddQuery = ({
  loading,
  draftLoading,
  handleSubmit,
  initialValues,
  categoryOptions,
  topicOptions,
  handleDeleteQuery,
  isEdit,
  handleCancel,
  handleTopicOptions,
}) => {
  const formSchema = yup.object().shape({
    title: yup.string().required('Required'),
    topic: yup.string().required('Required'),
  });

  const theme = useTheme();

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={(data) => {
        data.actionType = 'submit';
        handleSubmit(data);
      }}
    >
      {(formik) => (
        <Form component="form" onSubmit={formik.handleSubmit}>
          <PerfectScrollbar>
            <Box sx={{ maxHeight: 'calc(100vh - 38.5rem)' }}>
              <Grid container rowSpacing={2} columnSpacing={2} mt={0} sx={taskStyle.formContainer}>
                <Grid item xs={12} md={4}>
                  <InputField
                    error={Boolean(formik.touched.title && formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.title}
                    name="title"
                    id="title"
                    label="Title*"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Dropdown
                    error={Boolean(formik.touched.category && formik.errors.category)}
                    helperText={formik.touched.category && formik.errors.category}
                    handleChange={(e) => {
                      formik.handleChange(e);
                      handleTopicOptions(e?.target?.value);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.category}
                    name="category"
                    id="category"
                    label="Category"
                    options={categoryOptions}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Dropdown
                    error={Boolean(formik.touched.topic && formik.errors.topic)}
                    helperText={formik.touched.topic && formik.errors.topic}
                    handleChange={(e) => {
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.topic}
                    name="topic"
                    id="topic"
                    label="Topic*"
                    options={topicOptions}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <InputField
                    error={Boolean(formik.touched.description && formik.errors.description)}
                    helperText={formik.touched.description && formik.errors.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                    name="description"
                    id="description"
                    label="Description"
                    fullWidth
                    multiline
                  />
                </Grid>
              </Grid>

              <Box sx={{ mt: 2 }}>
                <Attachments
                  isDeleteIcon
                  handleChange={(event) => {
                    formik.setFieldValue(`attachment`, event.target.files[0]);
                  }}
                  name="attachment"
                  id="attachment"
                  value={formik.values.attachment}
                  handleDelete={() => {
                    formik.setFieldValue(`attachment`, '');
                  }}
                />
              </Box>
              {!!isEdit && initialValues?.documentName && (
                <Typographys variant="caption" color="neutral.600">
                  Previously Associated Attachment: {initialValues?.documentName}
                </Typographys>
              )}
            </Box>
          </PerfectScrollbar>
          <Box sx={taskStyle.btnContainer}>
            <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

            <Box sx={taskStyle.submitButtons}>
              <Button
                size="large"
                color="info"
                variant="outlined"
                type="cancel"
                onClick={() => {
                  formik.resetForm();
                  handleCancel();
                }}
              >
                Cancel
              </Button>
              {!isEdit && (
                <LoadingButton
                  size="large"
                  variant="outlined"
                  onClick={() => {
                    formik.setTouched({
                      title: true,
                      topic: true,
                    });
                    formik.setFieldValue('actionType', 'draft');
                    if (formik.values.title && formik.values.topic) handleSubmit(formik.values);
                  }}
                  loading={draftLoading}
                >
                  Save as Draft
                </LoadingButton>
              )}
              <LoadingButton color="primary" variant="contained" size="large" type="submit" loading={loading}>
                {isEdit ? 'Update' : 'Create'}
              </LoadingButton>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

AddQuery.defaultProps = {
  initialValues: {},
  handleSubmit: () => {},
  categoryOptions: [],
  topicOptions: {},
  isEdit: false,
  handleDeleteQuery: () => {},
  handleCancel: () => {},
  loading: false,
  draftLoading: false,
  handleTopicOptions: () => {},
};

AddQuery.propTypes = {
  initialValues: PropTypes.shape({}),
  handleSubmit: PropTypes.func,
  categoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  topicOptions: PropTypes.shape({}),
  isEdit: PropTypes.bool,
  handleDeleteQuery: PropTypes.func,
  handleCancel: PropTypes.func,
  loading: PropTypes.bool,
  draftLoading: PropTypes.bool,
  handleTopicOptions: PropTypes.func,
};

export default AddQuery;
