import React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { hasAnyPermission } from 'utils/permissions';
import menuItems from '../../menu-items/index';
import NavItem from './NavItem';

const MiniSidebar = ({ top }) => {
  const authorities = useSelector((state) => state.session.user.authorities);

  const Drawer = styled(MuiDrawer)(() => ({
    '& .MuiDrawer-paper': {
      top,
      height: `calc(100% - ${top})`,
    },
  }));

  return (
    <Drawer variant="permanent">
      <PerfectScrollbar>
        <List>
          {menuItems.items
            .filter((item) => item.type === 'group')
            .map(
              (item) =>
                hasAnyPermission(
                  authorities,
                  item.children.flatMap((child) => child.permissions),
                ) && <NavItem item={item} key={item.id} />,
            )}
        </List>
      </PerfectScrollbar>
    </Drawer>
  );
};

MiniSidebar.defaultProps = {
  top: '0',
};

MiniSidebar.propTypes = {
  top: PropTypes.string,
};

export default MiniSidebar;
