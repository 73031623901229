export const profileCardStyle = {
  profileCard: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  profileCardContent: {
    display: 'flex',
    gap: 1,
    width: '100%',
    justifyContent: 'space-between',
  },
};
