import React from 'react';
import { Box, Grid, Divider, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Typographys from 'components/Atoms/Typography';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import Button from 'components/Atoms/Button';
import InputField from 'components/Atoms/InputField/index';
import DatePicker from 'components/Atoms/Datepicker';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Attachments from 'components/Atoms/Attachments';
import Typography from '@mui/material/Typography';
import DeleteForeverIcon from '@mui/icons-material/DeleteOutlineSharp';
import * as yup from 'yup';
import { Formik, FieldArray, getIn } from 'formik';
import { genericMobileRegex, genericEmailRegex } from 'utils/commonUtils';
import { profileStyle } from '../../style';

const ProfileEditEmploymentHistory = ({ handleSubmit, handleCancel, defaultValue, loading }) => {
  const defaultObj = {
    employeeName: '',
    employerAddress: '',
    employmentCommenceDate: '',
    employmentEndDate: '',
    reasonForLeaving: '',
    employeeId: '',
    startDesignation: '',
    endDesignation: '',
    referenceContactName: '',
    referenceContactDesignation: '',
    referenceContactPhone: '',
    referenceContactEmail: '',
    attachments: '',
    isNew: true,
  };

  const employeeSchema = yup.object().shape({
    employment: yup.array().of(
      yup.object().shape({
        employeeName: yup.string().required('Required'),
        employmentCommenceDate: yup.string().required('Required'),
        employmentEndDate: yup.string().required('Required'),
        referenceContactPhone: yup
          .string()
          .matches(genericMobileRegex, 'Invalid format, Please enter valid mobile number.'),
        referenceContactEmail: yup.string().matches(genericEmailRegex, 'Invalid format, Please enter valid email ID.'),
      }),
    ),
  });

  return (
    <Box>
      <Formik
        validationSchema={employeeSchema}
        validateOnMount
        initialValues={defaultValue}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(formik) => <Form formik={formik} handleCancel={handleCancel} defaultObj={defaultObj} loading={loading} />}
      </Formik>
    </Box>
  );
};

const Form = ({ formik, handleCancel, defaultObj, loading }) => {
  const handleDateChange = (date, setFieldValue, label1) => {
    setFieldValue(label1, date);
  };

  const theme = useTheme();
  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 38rem)' }} mt={3}>
          <FieldArray name="employment">
            {({ push, remove }) => (
              <>
                {formik.values.employment.map((e, index) => {
                  return (
                    <Box sx={profileStyle.formDivider}>
                      <Box sx={profileStyle.deleteContainer}>
                        <Typography variant="subtitle2" color="neutral.800">
                          Record {index + 1}
                        </Typography>
                        <Button
                          startIcon={<DeleteForeverIcon />}
                          onClick={(event) => {
                            event.preventDefault();
                            remove(index);
                          }}
                          variant="outlined"
                        >
                          Delete
                        </Button>
                      </Box>
                      <Grid container rowSpacing={2} columnSpacing={2} mb={2}>
                        <Grid item xs={12} sm={6} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `employment[${index}].employeeName`) &&
                                getIn(formik.errors, `employment[${index}].employeeName`),
                            )}
                            helperText={
                              getIn(formik.touched, `employment[${index}].employeeName`) &&
                              getIn(formik.errors, `employment[${index}].employeeName`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={e.employeeName}
                            name={`employment[${index}].employeeName`}
                            id={`employment[${index}].employeeName`}
                            label="Employee Name*"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `employment[${index}].employerAddress`) &&
                                getIn(formik.errors, `employment[${index}].employerAddress`),
                            )}
                            helperText={
                              getIn(formik.touched, `employment[${index}].employerAddress`) &&
                              getIn(formik.errors, `employment[${index}].employerAddress`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={e.employerAddress}
                            name={`employment[${index}].employerAddress`}
                            id={`employment[${index}].employerAddress`}
                            label="Employer Address"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <DatePicker
                            error={
                              Boolean(
                                getIn(formik.touched, `employment[${index}].employmentCommenceDate`) &&
                                  getIn(formik.errors, `employment[${index}].employmentCommenceDate`),
                              ) ||
                              (getIn(formik.touched, `employment[${index}].employmentCommenceDate`) &&
                                !e.employmentCommenceDate)
                            }
                            helperText={
                              getIn(formik.touched, `employment[${index}].employmentCommenceDate`) &&
                              getIn(formik.errors, `employment[${index}].employmentCommenceDate`)
                            }
                            onChange={(data) =>
                              handleDateChange(
                                data,
                                formik.setFieldValue,
                                `employment[${index}].employmentCommenceDate`,
                              )
                            }
                            onBlur={formik.handleBlur}
                            value={e?.employmentCommenceDate}
                            name={`employment[${index}].employmentCommenceDate`}
                            id={`employment[${index}].employmentCommenceDate`}
                            label="Employment Commence date*"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <DatePicker
                            error={
                              Boolean(
                                getIn(formik.touched, `employment[${index}].employmentEndDate`) &&
                                  getIn(formik.errors, `employment[${index}].employmentEndDate`),
                              ) ||
                              (getIn(formik.touched, `employment[${index}].employmentEndDate`) && !e.employmentEndDate)
                            }
                            helperText={
                              getIn(formik.touched, `employment[${index}].employmentEndDate`) &&
                              getIn(formik.errors, `employment[${index}].employmentEndDate`)
                            }
                            onChange={(data) =>
                              handleDateChange(data, formik.setFieldValue, `employment[${index}].employmentEndDate`)
                            }
                            onBlur={formik.handleBlur}
                            value={e?.employmentEndDate}
                            minDate={formik.values.employment[index].employmentCommenceDate}
                            name={`employment[${index}].employmentEndDate`}
                            id={`employment[${index}].employmentEndDate`}
                            label="Employment End Date*"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `employment[${index}].reasonForLeaving`) &&
                                getIn(formik.errors, `employment[${index}].reasonForLeaving`),
                            )}
                            helperText={
                              getIn(formik.touched, `employment[${index}].reasonForLeaving`) &&
                              getIn(formik.errors, `employment[${index}].reasonForLeaving`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={e.reasonForLeaving}
                            name={`employment[${index}].reasonForLeaving`}
                            id={`employment[${index}].reasonForLeaving`}
                            label="Reason For Leaving"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `employment[${index}].employeeId`) &&
                                getIn(formik.errors, `employment[${index}].employeeId`),
                            )}
                            helperText={
                              getIn(formik.touched, `employment[${index}].employeeId`) &&
                              getIn(formik.errors, `employment[${index}].employeeId`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={e.employeeId}
                            name={`employment[${index}].employeeId`}
                            id={`employment[${index}].employeeId`}
                            label="Employee Id"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `employment[${index}].startDesignation`) &&
                                getIn(formik.errors, `employment[${index}].startDesignation`),
                            )}
                            helperText={
                              getIn(formik.touched, `employment[${index}].startDesignation`) &&
                              getIn(formik.errors, `employment[${index}].startDesignation`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={e.startDesignation}
                            name={`employment[${index}].startDesignation`}
                            id={`employment[${index}].startDesignation`}
                            label="Start Designation"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `employment[${index}].endDesignation`) &&
                                getIn(formik.errors, `employment[${index}].endDesignation`),
                            )}
                            helperText={
                              getIn(formik.touched, `employment[${index}].endDesignation`) &&
                              getIn(formik.errors, `employment[${index}].endDesignation`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={e.endDesignation}
                            name={`employment[${index}].endDesignation`}
                            id={`employment[${index}].endDesignation`}
                            label="End Designation"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `employment[${index}].referenceContactName`) &&
                                getIn(formik.errors, `employment[${index}].referenceContactName`),
                            )}
                            helperText={
                              getIn(formik.touched, `employment[${index}].referenceContactName`) &&
                              getIn(formik.errors, `employment[${index}].referenceContactName`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={e.referenceContactName}
                            name={`employment[${index}].referenceContactName`}
                            id={`employment[${index}].referenceContactName`}
                            label="Reference Contact Name"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `employment[${index}].referenceContactDesignation`) &&
                                getIn(formik.errors, `employment[${index}].referenceContactDesignation`),
                            )}
                            helperText={
                              getIn(formik.touched, `employment[${index}].referenceContactDesignation`) &&
                              getIn(formik.errors, `employment[${index}].referenceContactDesignation`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={e.referenceContactDesignation}
                            name={`employment[${index}].referenceContactDesignation`}
                            id={`employment[${index}].referenceContactDesignation`}
                            label="Reference Contact Designation"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `employment[${index}].referenceContactPhone`) &&
                                getIn(formik.errors, `employment[${index}].referenceContactPhone`),
                            )}
                            helperText={
                              getIn(formik.touched, `employment[${index}].referenceContactPhone`) &&
                              getIn(formik.errors, `employment[${index}].referenceContactPhone`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={e.referenceContactPhone}
                            name={`employment[${index}].referenceContactPhone`}
                            id={`employment[${index}].referenceContactPhone`}
                            label="Reference Contact Phone"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `employment[${index}].referenceContactEmail`) &&
                                getIn(formik.errors, `employment[${index}].referenceContactEmail`),
                            )}
                            helperText={
                              getIn(formik.touched, `employment[${index}].referenceContactEmail`) &&
                              getIn(formik.errors, `employment[${index}].referenceContactEmail`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={e.referenceContactEmail}
                            name={`employment[${index}].referenceContactEmail`}
                            id={`employment[${index}].referenceContactEmail`}
                            label="Reference Contact Email"
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Attachments
                          isDeleteIcon
                          handleChange={(event) => {
                            formik.setFieldValue(`employment[${index}].attachments`, event.target.files[0]);
                          }}
                          name={`employment[${index}].attachments`}
                          id={`employment[${index}].attachments`}
                          value={e.attachments}
                          handleDelete={() => {
                            formik.setFieldValue(`employment[${index}].attachments`, '');
                          }}
                        />
                        <Grid item xs={12} md={8} mt={2}>
                          {e?.attachment && (
                            <Typographys variant="caption" color="neutral.600">
                              Previously Associated Attachment: {e?.attachment}
                            </Typographys>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })}

                <Box sx={profileStyle.addButton}>
                  <Button
                    size="medium"
                    variant="outlined"
                    type="button"
                    startIcon={<AddOutlinedIcon />}
                    onClick={() => push(defaultObj)}
                  >
                    Add Employment Record
                  </Button>
                </Box>
              </>
            )}
          </FieldArray>
        </Box>
      </PerfectScrollbar>

      <Box sx={profileStyle.btnContainer}>
        <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

        <Box sx={profileStyle.submitWrapper}>
          <Button
            size="large"
            color="info"
            variant="outlined"
            type="cancel"
            onClick={() => {
              formik.resetForm();
              handleCancel();
            }}
          >
            Cancel
          </Button>
          <LoadingButton loading={loading} color="primary" size="large" type="submit" variant="contained">
            Update
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

Form.defaultProps = {
  handleCancel: () => {},
  formik: {},
  defaultObj: {},
  loading: false,
};

Form.propTypes = {
  handleCancel: PropTypes.func,
  formik: PropTypes.shape({
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    values: PropTypes.shape({
      employment: PropTypes.shape([]),
    }),
    resetForm: PropTypes.func,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    setFieldValue: PropTypes.func,
  }),
  defaultObj: PropTypes.shape({}),
  loading: PropTypes.bool,
};

ProfileEditEmploymentHistory.defaultProps = {
  handleSubmit: () => {},
  handleCancel: () => {},
  defaultValue: {
    employment: [
      {
        employeeName: '',
        employerAddress: '',
        employmentCommenceDate: '',
        employmentEndDate: '',
        reasonForLeaving: '',
        employeeId: '',
        startDesignation: '',
        endDesignation: '',
        referenceContactName: '',
        referenceContactDesignation: '',
        referenceContactPhone: '',
        referenceContactEmail: '',
        attachment: '',
      },
    ],
  },
  loading: false,
};

ProfileEditEmploymentHistory.propTypes = {
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,

  defaultValue: PropTypes.shape({}),
  loading: PropTypes.bool,
};

export default ProfileEditEmploymentHistory;
