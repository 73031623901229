import React from 'react';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import Button from 'components/Atoms/Button';
import InputField from 'components/Atoms/InputField/index';
import { useFormik } from 'formik';
import Dropdown from 'components/Molecules/Dropdown';
import DatePicker from 'components/Atoms/Datepicker';
import * as yup from 'yup';
import { leaveStyle } from 'components/Organisms/Leave/TeamLeaves/style';

const FetchDetailsForm = ({
  handleClick,
  handleSubmit,
  leaveCategoryOptions,
  leaveRequestStateOptions,
  hierarchyLevelOptions,
}) => {
  const defaultValues = {
    leaveCategory: '',
    startDate: '',
    endDate: '',
    hierarchyLevel: 'None',
    leaveRequestStates: '',
    // employeeName: '',
    // employeeID: '',
  };
  const isAccountsPage = window.location.pathname.includes('team-leave-accounts');

  const LeaveFormSchema = yup.object().shape({
    startDate: yup.string().required('Required'),
    endDate: yup.string().required('Required'),
  });

  const schema = isAccountsPage ? {} : { validationSchema: LeaveFormSchema };

  const formik = useFormik({
    initialValues: defaultValues,
    validateOnMount: true,
    onSubmit: handleSubmit,
    ...schema,
  });

  const handleDateChange = (date, fieldName) => {
    formik.setFieldValue(fieldName, date);
  };

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={isAccountsPage ? { display: 'flex' } : {}}>
      <Grid container rowSpacing={2} columnSpacing={2} mt={0} sx={leaveStyle.formContainer}>
        {/* <Grid item xs={12} md={4}>
          <InputField
            error={Boolean(formik.touched.employeeName && formik.errors.employeeName)}
            helperText={formik.touched.employeeName && formik.errors.employeeName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.employeeName}
            name="employeeName"
            id="employeeName"
            label="Employee Name"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputField
            error={Boolean(formik.touched.employeeID && formik.errors.employeeID)}
            helperText={formik.touched.employeeID && formik.errors.employeeID}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.employeeID}
            name="employeeID"
            id="employeeID"
            label="Employee ID"
            fullWidth
          />
        </Grid> */}
        {!isAccountsPage && (
          <>
            <Grid item xs={12} md={4}>
              <Dropdown
                error={Boolean(formik.touched.leaveCategory && formik.errors.leaveCategory)}
                helperText={formik.touched.leaveCategory && formik.errors.leaveCategory}
                handleChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.leaveCategory}
                name="leaveCategory"
                id="leaveCategory"
                label="Leave Category"
                options={leaveCategoryOptions}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DatePicker
                error={Boolean(formik.touched.startDate && formik.errors.startDate)}
                helperText={formik.touched.startDate && formik.errors.startDate}
                onChange={(date) => handleDateChange(date, 'startDate')}
                onBlur={formik.handleBlur}
                value={formik.values.startDate}
                name="startDate"
                id="startDate"
                label="Start Date*"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <DatePicker
                error={Boolean(formik.touched.endDate && formik.errors.endDate)}
                helperText={formik.touched.endDate && formik.errors.endDate}
                onChange={(date) => handleDateChange(date, 'endDate')}
                onBlur={formik.handleBlur}
                value={formik.values.endDate}
                name="endDate"
                id="endDate"
                label="End Date*"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Dropdown
                error={Boolean(formik.touched.leaveRequestStates && formik.errors.leaveRequestStates)}
                helperText={formik.touched.leaveRequestStates && formik.errors.leaveRequestStates}
                handleChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.leaveRequestStates}
                name="leaveRequestStates"
                id="leaveRequestStates"
                label="Leave Request States"
                options={leaveRequestStateOptions}
                fullWidth
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={4}>
          <Dropdown
            error={Boolean(formik.touched.hierarchyLevel && formik.errors.hierarchyLevel)}
            helperText={formik.touched.hierarchyLevel && formik.errors.hierarchyLevel}
            handleChange={(e) => {
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.hierarchyLevel}
            name="hierarchyLevel"
            id="hierarchyLevel"
            label="Hierarchy Level"
            options={hierarchyLevelOptions}
            fullWidth
          />
        </Grid>
      </Grid>

      <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
        <Button size="large" type="submit" onClick={handleClick}>
          Fetch
        </Button>
      </Box>
    </Box>
  );
};

FetchDetailsForm.defaultProps = {
  handleClick: () => {},
  handleSubmit: () => {},
  leaveCategoryOptions: [],
  leaveRequestStateOptions: [],
  hierarchyLevelOptions: [],
};

FetchDetailsForm.propTypes = {
  handleClick: PropTypes.func,
  handleSubmit: PropTypes.func,
  leaveCategoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  leaveRequestStateOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  hierarchyLevelOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
};

export default FetchDetailsForm;
