export const attendanceStyle = {
  wrapper: {
    padding: '2rem 3rem',
    border: '0.125rem solid',
    borderColor: 'neutral.300',
    borderRadius: '0.75rem',
    height: '100%',
    boxSizing: 'border-box',
    backgroundColor: 'white',
  },

  tabWrapper: {
    border: '0.125rem solid',
    borderColor: 'neutral.300',
    borderRadius: '0.75rem',
    height: '100%',
    boxSizing: 'border-box',
    my: 2,
    minHeight: 'calc(100vh - 43.5rem)',
    backgroundColor: 'white',
  },

  statWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  timeIndicator: {
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '50%',
  },

  indicatorWrapper: {
    display: 'flex',
    gap: 1.5,
    alignItems: 'center',
  },

  indicatorContainer: {
    display: 'none',
    gap: 3,
    alignItems: 'center',
  },

  actionWrapper: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    gap: 3,
    mb: 2,
  },

  daysWrapper: {
    display: 'flex',
    gap: 1.5,
  },

  dateRange: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },

  day: {
    width: '5rem',
    height: '5rem',
    borderRadius: '50%',
    cursor: 'pointer',
    borderWidth: '0.125rem',
    borderStyle: 'solid',
    borderColor: 'neutral.300',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: 'secondary.600',
      '.MuiTypography-avatarLetter': {
        color: 'common.white',
      },
    },
  },

  activeDay: {
    backgroundColor: 'secondary.600',
    '.MuiTypography-avatarLetter': {
      color: 'common.white',
    },
  },

  timerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
  },

  container: {
    display: 'flex',
    height: '100%',
  },
  item: {
    flex: 1,
  },
};
