import * as React from 'react';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import Typographys from 'components/Atoms/Typography';
import { Box } from '@mui/material';
import { CompareTDSStyle } from 'components/Organisms/MyPay/CompareTDS/style';

const IncomeTable = ({ incomeData, employerNames }) => {
  return (
    <Paper sx={CompareTDSStyle.hraTableWrapper}>
      {incomeData?.map((data, ind) =>
        data.map((innerData) => (
          <>
            <Box sx={{ display: 'flex', p: '12px 8px', backgroundColor: 'actions.100' }}>
              <Box sx={{ flexBasis: '100%' }}>
                <Typographys variant="body2">Employer name: {employerNames[ind]}</Typographys>
              </Box>
            </Box>
            <Box key={data.title} sx={CompareTDSStyle.hraTableRow}>
              <Box sx={{ width: '30%' }}>
                <Typographys variant="body2" color="neutral.600">
                  {innerData.title}
                </Typographys>
              </Box>
              <Box sx={{ width: '70%' }}>
                <Typographys variant="body2" color="neutral.600">
                  {innerData.amount}
                </Typographys>
              </Box>
            </Box>
          </>
        )),
      )}
    </Paper>
  );
};

IncomeTable.defaultProps = {
  incomeData: [],
  employerNames: [],
};

IncomeTable.propTypes = {
  incomeData: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        amount: PropTypes.string,
      }),
    ),
  ),
  employerNames: PropTypes.arrayOf(PropTypes.string),
};

export default IncomeTable;
