import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Typographys from 'components/Atoms/Typography';

const TitleWithTextInline = ({ title, titleColor, textAlign, titleVariant, text, padding, textColor, textVariant }) => {
  return (
    <Box padding={padding} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Typographys align={textAlign} color={titleColor} variant={titleVariant}>
        {title}
      </Typographys>
      <Typographys align={textAlign} color={textColor} variant={textVariant}>
        {text}
      </Typographys>
    </Box>
  );
};

TitleWithTextInline.defaultProps = {
  title: 'Designation ',
  titleColor: 'neutral.800',
  titleVariant: 'body2',
  text: 'Head',
  textColor: 'neutral.600',
  textVariant: 'subtitle2',
  padding: '1.5rem 3rem 1.5rem 0',
  textAlign: '',
};

TitleWithTextInline.propTypes = {
  title: PropTypes.string,
  titleColor: PropTypes.string,
  titleVariant: PropTypes.string,
  text: PropTypes.string,
  textColor: PropTypes.string,
  textVariant: PropTypes.string,
  padding: PropTypes.string,
  textAlign: PropTypes.string,
};

export default TitleWithTextInline;
