import * as React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Switch } from '@mui/material';

const MuiSwitch = ({ label, labelPlacement, checked, handleChange }) => {
  return (
    <FormControlLabel
      control={<Switch checked={checked} onChange={handleChange} />}
      label={label}
      labelPlacement={labelPlacement}
    />
  );
};

MuiSwitch.defaultProps = {
  label: 'Label',
  labelPlacement: 'right',
  checked: false,
  handleChange: () => {},
};

MuiSwitch.propTypes = {
  label: PropTypes.string,
  labelPlacement: PropTypes.string,
  checked: PropTypes.bool,
  handleChange: PropTypes.func,
};

export default MuiSwitch;
