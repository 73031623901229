import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Table from 'components/Molecules/TableExtended';

const RequestList = ({
  rows,
  page,
  handleSelect,
  roleBasedMenu,
  columns,
  showActionList,
  handleRowClick,
  showPagination,
  onPageChange,
  count,
  loading,
}) => {
  return (
    <Box>
      <Table
        loading={loading}
        rows={rows}
        columns={columns}
        showActionList={showActionList}
        page={page}
        handleSelect={handleSelect}
        roleBasedMenu={roleBasedMenu}
        isDynamic
        onRowClick={handleRowClick}
        showPagination={showPagination}
        onPageChange={onPageChange}
        count={count}
      />
    </Box>
  );
};

RequestList.defaultProps = {
  roleBasedMenu: () => {},
  rows: [],
  handleSelect: () => {},
  page: '',
  columns: [],
  showActionList: true,
  handleRowClick: () => {},
  showPagination: false,
  onPageChange: () => {},
  count: 0,
  loading: true,
};

RequestList.propTypes = {
  showActionList: PropTypes.bool,
  roleBasedMenu: PropTypes.func,
  handleSelect: PropTypes.func,
  page: PropTypes.number,
  rows: PropTypes.arrayOf,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      numeric: PropTypes.bool,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),
  handleRowClick: PropTypes.func,
  showPagination: PropTypes.bool,
  count: PropTypes.number,
  onPageChange: PropTypes.func,
  loading: PropTypes.bool,
};

export default RequestList;
