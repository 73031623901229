import React from 'react';
import PropTypes from 'prop-types';
import EmployeeCommonCard from 'components/Organisms/Employee/EmployeeCommonCard';
import ProfileListItem from 'components/Molecules/ProfileListItem';
import { useTheme } from '@mui/material/styles';
import { Box } from '@material-ui/core';

const OnboardingCard = ({
  onboardingNum,
  onboardingProfileLists,
  onboardingFilterOptions,
  onboardingFilterHandleChange,
}) => {
  const theme = useTheme();
  return (
    <EmployeeCommonCard
      cardHeaderTitle={`onboardings (${onboardingNum})`}
      headerFilterId="onboardingFilter"
      headerFilterLabel="Week"
      headerFilterOptions={onboardingFilterOptions}
      headerFilterHandleChange={onboardingFilterHandleChange}
      cardBodyMinHeight="40rem"
      bodyContent={onboardingProfileLists.map((list) => (
        <Box padding="1.25rem 2rem" border="0.125rem solid" borderColor={theme.palette.neutral[200]} borderTop="none">
          <ProfileListItem
            userName={list.userName}
            designation={list.designation}
            avatarSrc={list.avatarSrc}
            date={list.date}
          />
        </Box>
      ))}
      footerLink="#"
      footerLinkLabel="View Details"
    />
  );
};

OnboardingCard.defaultProps = {
  onboardingNum: 6,
  onboardingProfileLists: [],
  onboardingFilterOptions: [],
  onboardingFilterHandleChange: () => {},
};
OnboardingCard.propTypes = {
  onboardingNum: PropTypes.number,
  onboardingProfileLists: PropTypes.arrayOf,
  onboardingFilterOptions: PropTypes.arrayOf,
  onboardingFilterHandleChange: PropTypes.func,
};

export default OnboardingCard;
