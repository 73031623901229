import React, { useEffect, useState } from 'react';
import { Box, Grid, Divider, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import Button from 'components/Atoms/Button';
import Typographys from 'components/Atoms/Typography';
import InputField from 'components/Atoms/InputField/index';
import { useFormik } from 'formik';
import DatePicker from 'components/Atoms/Datepicker';
import Attachments from 'components/Atoms/Attachments';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useDownloadFile from 'hooks/useDownloadFile';
import { addEmpStyle } from '../../style';

const VisaDetails = ({ tabHeading, handleSubmit, handleCancel, propData, label }) => {
  const defaultValues = propData.allTabsData?.['Visa Details'] || {
    issuingCountry: '',
    placeOfIssue: '',
    validFrom: '',
    validTo: '',
    issueDate: '',
    referenceNumber: '',
    visaStatus: '',
    comments: '',
    attachment: '',
  };
  const [openBulkUploadDialog, setOpenBulkUploadDialog] = useState(false);
  const downloadFile = useDownloadFile();

  const formik = useFormik({
    initialValues: defaultValues,
    validateOnMount: true,
    onSubmit: handleSubmit,
  });

  const handleAttachment = (docid, filename) => {
    downloadFile({ docid }, filename, 'restPayIndex/downloadAttachment');
  };

  const handleDateChange = (date, fieldName) => {
    formik.setFieldValue(fieldName, date);
  };

  const theme = useTheme();
  useEffect(() => {
    propData.updateFormData(formik.values, label);
  }, [formik.values, label]);
  return (
    <Box mt={1.5}>
      <Typographys variant="h5" color="neutral.800">
        {tabHeading}
      </Typographys>

      <Box mt={2} component="form" onSubmit={formik.handleSubmit}>
        <PerfectScrollbar>
          <Box sx={{ maxHeight: 'calc(100vh - 47rem)' }}>
            <Grid container rowSpacing={2} columnSpacing={9} mt={0} sx={addEmpStyle.formContainer}>
              <Grid item xs={12} md={6}>
                <InputField
                  error={Boolean(formik.touched.issuingCountry && formik.errors.issuingCountry)}
                  helperText={formik.touched.issuingCountry && formik.errors.issuingCountry}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.issuingCountry}
                  name="issuingCountry"
                  id="issuingCountry"
                  label="Issuing Country"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputField
                  error={Boolean(formik.touched.placeOfIssue && formik.errors.placeOfIssue)}
                  helperText={formik.touched.placeOfIssue && formik.errors.placeOfIssue}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.placeOfIssue}
                  name="placeOfIssue"
                  id="placeOfIssue"
                  label="Place of Issue"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <DatePicker
                  error={
                    Boolean(formik.touched.validFrom && formik.errors.validFrom) ||
                    (formik.touched.validFrom && !formik.values.validFrom)
                  }
                  helperText={formik.touched.validFrom && formik.errors.validFrom}
                  onChange={(date) => handleDateChange(date, 'validFrom')}
                  onBlur={formik.handleBlur}
                  value={formik.values?.validFrom}
                  name="validFrom"
                  id="validFrom"
                  label="Valid From"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <DatePicker
                  error={
                    Boolean(formik.touched.validTo && formik.errors.validTo) ||
                    (formik.touched.validTo && !formik.values.validTo)
                  }
                  helperText={formik.touched.validTo && formik.errors.validTo}
                  onChange={(date) => handleDateChange(date, 'validTo')}
                  onBlur={formik.handleBlur}
                  value={formik.values?.validTo}
                  name="validTo"
                  id="validTo"
                  label="Valid To"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <DatePicker
                  error={
                    Boolean(formik.touched.issueDate && formik.errors.issueDate) ||
                    (formik.touched.issueDate && !formik.values.issueDate)
                  }
                  helperText={formik.touched.issueDate && formik.errors.issueDate}
                  onChange={(date) => handleDateChange(date, 'issueDate')}
                  onBlur={formik.handleBlur}
                  value={formik.values?.issueDate}
                  name="issueDate"
                  id="issueDate"
                  label="Issue Date"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputField
                  error={Boolean(formik.touched.referenceNumber && formik.errors.referenceNumber)}
                  helperText={formik.touched.referenceNumber && formik.errors.referenceNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.referenceNumber}
                  name="referenceNumber"
                  id="referenceNumber"
                  label="Reference Number"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputField
                  error={Boolean(formik.touched.visaStatus && formik.errors.visaStatus)}
                  helperText={formik.touched.visaStatus && formik.errors.visaStatus}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.visaStatus}
                  name="visaStatus"
                  id="visaStatus"
                  label="Visa Status"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <InputField
                  error={Boolean(formik.touched.comments && formik.errors.comments)}
                  helperText={formik.touched.comments && formik.errors.comments}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.comments}
                  name="comments"
                  id="comments"
                  label="Comments"
                  fullWidth
                  multiline
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 2 }}>
              <Attachments
                isDeleteIcon
                handleChange={(event) => {
                  formik.setFieldValue(`attachment`, event.target.files[0]);
                }}
                name="attachment"
                id="attachment"
                value={formik.values.attachment}
                handleDelete={() => {
                  formik.setFieldValue(`attachment`, '');
                }}
              />
              {!!defaultValues?.previousAttachment?.fileName && (
                <Box
                  sx={{ textDecoration: 'underline' }}
                  onClick={() =>
                    handleAttachment(defaultValues?.previousAttachment?.id, defaultValues?.previousAttachment?.fileName)
                  }
                >
                  {defaultValues?.previousAttachment?.fileName}
                </Box>
              )}
            </Box>
          </Box>
        </PerfectScrollbar>
        <Box sx={addEmpStyle.btnContainer}>
          <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

          <Box sx={addEmpStyle.submitWrapper}>
            <Button
              size="large"
              color="info"
              variant="outlined"
              type="cancel"
              onClick={() => {
                formik.resetForm();
                handleCancel();
              }}
            >
              Cancel
            </Button>

            <Button size="large" type="submit" isDisable={false}>
              {propData.allowEdit ? 'Update' : 'Next'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

VisaDetails.defaultProps = {
  tabHeading: 'Visa Details',
  propData: {},
  handleSubmit: () => {},
  handleCancel: () => {},
  label: '',
};

VisaDetails.propTypes = {
  tabHeading: PropTypes.string,
  propData: PropTypes.shape({
    updateFormData: PropTypes.func,
    allTabsData: PropTypes.shape({
      'Visa Details': PropTypes.shape({}),
    }),
    allowEdit: PropTypes.bool,
  }),
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  label: PropTypes.string,
};

export default VisaDetails;
