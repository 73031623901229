import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import MuiTab from 'components/Molecules/Tab';
import HistoryIcon from '@mui/icons-material/History';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import Person2Icon from '@mui/icons-material/Person2';
import EmployeeTimeline from './EmployeeTimeline';
import TotalTimeline from './TotalTimeline';
import SalaryTimeline from './SalaryTimeline';
import ActivityLogTimeline from './ActivityLogTimeline';

const Timeline = ({
  empTimelineTitle,
  handleEmpTimelineSearch,
  empTimelineFilter,
  empTimeline,
  empTimelineStartDateChange,
  empTimelineEndDateChange,
  empTimelineOnFetch,

  totalTimelineTitle,
  handleTotalTimelineSearch,
  totalTimelineFilter,
  totalTimeline,
  totalTimelineStartDateChange,
  totalTimelineEndDateChange,
  totalTimelineOnFetch,

  salaryTimelineTitle,
  handleSalaryTimeLineSearch,
  salaryTimeLineFilter,
  salaryTimeline,
  salaryTimelineStartDateChange,
  salaryTimelineEndDateChange,
  salaryTimelineOnFetch,

  activityLogTitle,
  handleActivityLogSearch,
  activityLogFilter,
  activityLog,
  activityLogStartDateChange,
  activityLogEndDateChange,
  activityLogOnFetch,
}) => {
  const timeLineTab = [
    {
      icon: <Person2Icon />,
      iconPosition: 'start',
      label: 'Employee Timeline',
      RenderComponent: EmployeeTimeline,
      componentProps: {
        empTimelineTitle,
        handleEmpTimelineSearch,
        empTimelineFilter,
        empTimeline,
        empTimelineStartDateChange,
        empTimelineEndDateChange,
        empTimelineOnFetch,
      },
    },
    {
      icon: <WatchLaterIcon />,
      iconPosition: 'start',
      label: 'Total Timeline',
      RenderComponent: TotalTimeline,
      componentProps: {
        totalTimelineTitle,
        handleTotalTimelineSearch,
        totalTimelineFilter,
        totalTimeline,
        totalTimelineStartDateChange,
        totalTimelineEndDateChange,
        totalTimelineOnFetch,
      },
    },
    {
      icon: <MonetizationOnIcon />,
      iconPosition: 'start',
      label: 'Salary Timeline',
      RenderComponent: SalaryTimeline,
      componentProps: {
        salaryTimelineTitle,
        handleSalaryTimeLineSearch,
        salaryTimeLineFilter,
        salaryTimeline,
        salaryTimelineStartDateChange,
        salaryTimelineEndDateChange,
        salaryTimelineOnFetch,
      },
    },
    {
      icon: <HistoryIcon />,
      iconPosition: 'start',
      label: 'Activity Log',
      RenderComponent: ActivityLogTimeline,
      componentProps: {
        activityLogTitle,
        handleActivityLogSearch,
        activityLogFilter,
        activityLog,
        activityLogStartDateChange,
        activityLogEndDateChange,
        activityLogOnFetch,
      },
    },
  ];

  return (
    <Box sx={{ display: 'flex', paddingLeft: '24px', marginTop: '24px' }}>
      <MuiTab
        orientation="vertical"
        textColor="primary"
        indicatorColor="primary"
        isVertical
        tabs={timeLineTab}
        panelPadding={{ xs: '2rem 0', md: '2rem 0 2rem 3rem' }}
        isDropdownMob
      />
    </Box>
  );
};

Timeline.defaultProps = {
  empTimelineTitle: '',
  handleEmpTimelineSearch: () => {},
  empTimelineFilter: [],
  empTimeline: [],
  empTimelineStartDateChange: () => {},
  empTimelineEndDateChange: () => {},
  empTimelineOnFetch: () => {},

  totalTimelineTitle: '',
  handleTotalTimelineSearch: () => {},
  totalTimelineFilter: [],
  totalTimeline: [],
  totalTimelineStartDateChange: () => {},
  totalTimelineEndDateChange: () => {},
  totalTimelineOnFetch: () => {},

  salaryTimelineTitle: '',
  handleSalaryTimeLineSearch: () => {},
  salaryTimeLineFilter: [],
  salaryTimeline: [],
  salaryTimelineStartDateChange: () => {},
  salaryTimelineEndDateChange: () => {},
  salaryTimelineOnFetch: () => {},

  activityLogTitle: '',
  handleActivityLogSearch: () => {},
  activityLogFilter: [],
  activityLog: [],
  activityLogStartDateChange: () => {},
  activityLogEndDateChange: () => {},
  activityLogOnFetch: () => {},
};

Timeline.propTypes = {
  empTimelineTitle: PropTypes.string,
  handleEmpTimelineSearch: PropTypes.func,
  empTimelineFilter: PropTypes.arrayOf(PropTypes.shape({})),
  empTimeline: PropTypes.arrayOf(PropTypes.shape({})),
  empTimelineStartDateChange: PropTypes.func,
  empTimelineEndDateChange: PropTypes.func,
  empTimelineOnFetch: PropTypes.func,

  totalTimelineTitle: PropTypes.string,
  handleTotalTimelineSearch: PropTypes.func,
  totalTimelineFilter: PropTypes.arrayOf(PropTypes.shape({})),
  totalTimeline: PropTypes.arrayOf(PropTypes.shape({})),
  totalTimelineStartDateChange: PropTypes.func,
  totalTimelineEndDateChange: PropTypes.func,
  totalTimelineOnFetch: PropTypes.func,

  salaryTimelineTitle: PropTypes.string,
  handleSalaryTimeLineSearch: PropTypes.func,
  salaryTimeLineFilter: PropTypes.arrayOf(PropTypes.shape({})),
  salaryTimeline: PropTypes.arrayOf(PropTypes.shape({})),
  salaryTimelineStartDateChange: PropTypes.func,
  salaryTimelineEndDateChange: PropTypes.func,
  salaryTimelineOnFetch: PropTypes.func,

  activityLogTitle: PropTypes.string,
  handleActivityLogSearch: PropTypes.func,
  activityLogFilter: PropTypes.arrayOf(PropTypes.shape({})),
  activityLog: PropTypes.arrayOf(PropTypes.shape({})),
  activityLogStartDateChange: PropTypes.func,
  activityLogEndDateChange: PropTypes.func,
  activityLogOnFetch: PropTypes.func,
};

export default Timeline;
