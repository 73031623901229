import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Divider, useTheme } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Button from 'components/Atoms/Button';
import InputField from 'components/Atoms/InputField';
import Typographys from 'components/Atoms/Typography';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { FieldArray, getIn, Formik } from 'formik';
import Dropdown from 'components/Molecules/Dropdown';
import * as yup from 'yup';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import Attachments from 'components/Atoms/Attachments';

import { onBoardingStyle } from '../../../style';

const SkillsAttainmentDetail = ({ handleCancel, propData, label, handleSubmit, skillOptions, levelOptions }) => {
  const defaultObj = {
    skill: '',
    level: '',
    comments: '',
  };
  const defaultValues = propData.allTabsData[label];

  const skillValidationSchema = yup.object().shape({});

  return (
    <Box mt={1.5}>
      <Formik
        initialValues={
          defaultValues.skillDetails.length > 0 && Object.keys(defaultValues.skillDetails[0]).length
            ? defaultValues
            : { skillDetails: [{ key: 1 }] }
        }
        onSubmit={handleSubmit}
        validationSchema={skillValidationSchema}
      >
        {(formik) => (
          <Form
            formik={formik}
            handleCancel={handleCancel}
            propData={propData}
            label={label}
            defaultObj={defaultObj}
            levelOptions={levelOptions}
            skillOptions={skillOptions}
          />
        )}
      </Formik>
    </Box>
  );
};

const Form = ({ formik, handleCancel, propData, label, defaultObj, skillOptions, levelOptions }) => {
  const [keyCount, setKeyCount] = useState(2);
  useEffect(() => {
    propData.updateFormData(formik.values, label);
  }, [formik.values, label]);

  const theme = useTheme();

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 59rem)' }}>
          <Box sx={{ mb: 2 }}>
            <Typographys variant="h5" color="neutral.800">
              Skill Attainment Details
            </Typographys>
          </Box>

          <FieldArray name="skillDetails">
            {({ push, remove }) => (
              <Box>
                {formik?.values?.skillDetails.map((s, index) => {
                  return (
                    <Accordion key={s.key} disableGutters elevation={0} square sx={onBoardingStyle.accordionWrapper}>
                      <AccordionSummary
                        aria-controls="skill-content"
                        id="skill-header"
                        expandIcon={<KeyboardArrowDownSharpIcon sx={{ fontSize: '3rem', color: 'neutral.800' }} />}
                      >
                        <Box sx={onBoardingStyle.deleteContainer}>
                          <Typography variant="subtitle2" color="neutral.800">
                            Record {index + 1}
                          </Typography>
                          <DeleteOutlineSharpIcon sx={{ color: 'neutral.600' }} onClick={() => remove(index)} />
                        </Box>
                      </AccordionSummary>

                      <AccordionDetails>
                        <Grid container rowSpacing={2} columnSpacing={9} pt={0} sx={onBoardingStyle.formContainer}>
                          <Grid item xs={12} md={6}>
                            <Dropdown
                              error={Boolean(
                                getIn(formik.touched, `skillDetails[${index}].skill`) &&
                                  getIn(formik.errors, `skillDetails[${index}].skill`),
                              )}
                              helperText={
                                getIn(formik.touched, `skillDetails[${index}].skill`) &&
                                getIn(formik.errors, `skillDetails[${index}].skill`)
                              }
                              handleChange={(e) => {
                                formik.handleChange(e);
                              }}
                              onBlur={formik.handleBlur}
                              options={skillOptions}
                              value={s.skill}
                              name={`skillDetails[${index}].skill`}
                              id={`skillDetails[${index}].skill`}
                              label="Skill"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <Dropdown
                              error={Boolean(
                                getIn(formik.touched, `skillDetails[${index}].level`) &&
                                  getIn(formik.errors, `skillDetails[${index}].level`),
                              )}
                              helperText={
                                getIn(formik.touched, `skillDetails[${index}].level`) &&
                                getIn(formik.errors, `skillDetails[${index}].level`)
                              }
                              handleChange={(e) => {
                                formik.handleChange(e);
                              }}
                              onBlur={formik.handleBlur}
                              value={s.level}
                              name={`skillDetails[${index}].level`}
                              id={`skillDetails[${index}].level`}
                              label="Level"
                              options={levelOptions}
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `skillDetails[${index}].comments`) &&
                                  getIn(formik.errors, `skillDetails[${index}].comments`),
                              )}
                              helperText={
                                getIn(formik.touched, `skillDetails[${index}].comments`) &&
                                getIn(formik.errors, `skillDetails[${index}].comments`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={s.comments}
                              name={`skillDetails[${index}].comments`}
                              id={`skillDetails[${index}].comments`}
                              label="Comments"
                              multiline
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <Attachments
                              handleChange={(event) => {
                                formik.setFieldValue(`skillDetails[${index}].attachments`, event.target.files[0]);
                              }}
                              name={`skillDetails[${index}].attachments`}
                              id={`skillDetails[${index}].attachments`}
                              value={s.attachments}
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}

                <Box sx={{ mt: 3 }}>
                  <Button
                    size="medium"
                    variant="outlined"
                    type="button"
                    startIcon={<AddOutlinedIcon />}
                    onClick={() => {
                      push({ ...defaultObj, key: keyCount });
                      setKeyCount(keyCount + 1);
                    }}
                  >
                    Add Another
                  </Button>
                </Box>
              </Box>
            )}
          </FieldArray>
        </Box>
      </PerfectScrollbar>

      <Box sx={onBoardingStyle.btnContainer}>
        <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

        <Box sx={onBoardingStyle.submitWrapper}>
          <Button
            size="large"
            color="info"
            variant="outlined"
            type="button"
            onClick={() => {
              formik.resetForm();
              handleCancel();
            }}
          >
            Cancel
          </Button>

          <Button size="large" type="submit" isDisable={false}>
            Save & Continue
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

Form.defaultProps = {
  handleCancel: () => {},
  label: '',
  propData: {},
  formik: {},
  defaultObj: {},
  levelOptions: [],
  skillOptions: [],
};

Form.propTypes = {
  propData: PropTypes.shape({
    updateFormData: PropTypes.func,
    allTabsData: PropTypes.shape({
      'Skill Attainment Details': PropTypes.shape(),
    }),
    allowEdit: PropTypes.bool,
  }),
  handleCancel: PropTypes.func,

  label: PropTypes.string,
  formik: PropTypes.shape({
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    values: PropTypes.shape(),
    resetForm: PropTypes.func,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    setFieldValue: PropTypes.func,
  }),
  defaultObj: PropTypes.shape({}),

  levelOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  skillOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
};

SkillsAttainmentDetail.defaultProps = {
  handleSubmit: () => {},
  handleCancel: () => {},
  label: 'Skill Attainment Details',

  propData: {
    allTabsData: {
      'Skill Attainment Details': {
        skillDetails: [
          {
            eventDetails: '',
            eventDate: '',
            comments: '',
            attachments: '',
          },
        ],
      },
    },
    updateFormData: () => {},
  },
  levelOptions: [],
  skillOptions: [],
};

SkillsAttainmentDetail.propTypes = {
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  propData: PropTypes.shape({
    allTabsData: PropTypes.shape({}),
    updateFormData: PropTypes.func,
  }),
  label: PropTypes.string,

  levelOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  skillOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
};

export default SkillsAttainmentDetail;
