import PropTypes from 'prop-types';
import React from 'react';
import Dialog from 'components/Molecules/Dialog';
import Dropdown from 'components/Molecules/Dropdown';

const SelectEmployeeGroup = ({
  handleComment,
  secondaryBtn,
  primaryBtn,
  dialogDesc,
  primaryBtnColor,
  options,
  errormessage,
  handleCancel,
  handleChange,
  error,
  open,
  handleClose,
}) => {
  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      dialogTitle="Select Employee Group"
      dialogDesc={dialogDesc}
      secondaryBtn={secondaryBtn}
      primaryBtn={primaryBtn}
      handlePrimaryBtn={handleComment}
      handleSecondaryBtn={handleCancel}
      color={primaryBtnColor}
      bodyContent={
        <Dropdown
          id="employeeGroup"
          label="Please Select"
          handleChange={handleChange}
          labelId="employeeGroup"
          options={options}
          errormessage={errormessage}
          error={error}
        />
      }
    />
  );
};
SelectEmployeeGroup.defaultProps = {
  dialogDesc: '',
  primaryBtnColor: 'primary',
  secondaryBtn: 'Cancel',
  primaryBtn: 'Add to group',
  handleComment: () => {},
  handleCancel: () => {},
  handleChange: () => {},
  error: false,
  errormessage: 'Error Messgae',
  options: [
    { id: 1, name: 'Option 1', onClick: () => {} },
    { id: 2, name: 'Option 2', onClick: () => {} },
    { id: 3, name: 'Option 3', onClick: () => {} },
    { id: 4, name: 'Option 4', onClick: () => {} },
    { id: 5, name: 'Option 5', onClick: () => {} },
    { id: 6, name: 'Option 6', onClick: () => {} },
  ],
  open: true,
  handleClose: () => {},
};
SelectEmployeeGroup.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  error: PropTypes.bool,
  errormessage: PropTypes.string,
  primaryBtnColor: PropTypes.string,
  dialogDesc: PropTypes.string,
  handleChange: PropTypes.func,
  handleComment: PropTypes.func,
  handleCancel: PropTypes.func,
  secondaryBtn: PropTypes.string,
  primaryBtn: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
};
export default SelectEmployeeGroup;
