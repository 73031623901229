import React from 'react';
import PropTypes from 'prop-types';
import { Box, Menu, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from 'components/Atoms/Button';
import { menuStyles } from './style';

const DropdownMenu = ({
  variant,
  btnLabel,
  size,
  color,
  menuLists,
  transformOrigin,
  anchorOrigin,
  minWidth,
  maxWidth,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (menuList) => {
    handleClose();
    menuList.onClick(menuList);
  };
  return (
    <>
      <Button variant={variant} size={size} color={color} onClick={handleClick} endIcon={<ArrowDropDownIcon />}>
        {btnLabel}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={transformOrigin}
        anchorOrigin={anchorOrigin}
        sx={{
          ...menuStyles.menu,
          minWidth,
          maxWidth,
        }}
      >
        {menuLists.map((menuList) => (
          <MenuItem
            key={menuList.id}
            value={menuList.label}
            onClick={() => handleMenuItemClick(menuList)}
            disabled={menuList?.disabled}
          >
            <Box sx={{ color: menuList?.color ? menuList.color : 'neutral.800' }} component="span">
              {menuList.label}
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
DropdownMenu.defaultProps = {
  variant: 'outlined',
  btnLabel: 'Action',
  menuLists: [
    { id: 1, label: 'Add New', onClick: () => {} },
    { id: 2, label: 'Bulk Upload', onClick: () => {} },
    { id: 3, label: 'Send ESS invite', onClick: () => {} },
    { id: 4, label: 'Download', onClick: () => {} },
  ],
  size: 'medium',
  color: 'primary',
  transformOrigin: { horizontal: 'right', vertical: 'top' },
  anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
  minWidth: '27.5rem',
  maxWidth: '27.5rem',
};
DropdownMenu.propTypes = {
  variant: PropTypes.string,
  menuLists: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  btnLabel: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  transformOrigin: PropTypes.shape({
    horizontal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    vertical: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  anchorOrigin: PropTypes.shape({
    horizontal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    vertical: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
};
export default DropdownMenu;
