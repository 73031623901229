import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import EmployeeDetailCard from 'components/Organisms/Employee/EmployeeRecord/ActionList/EmployeeDetailCard';
import EffectiveDateForm from 'components/Organisms/Employee/EmployeeRecord/ActionList/EffectiveDateForm';

const ConfirmEmployee = ({ employeeData, handleSubmit, handleCancel, loading }) => {
  return (
    <>
      <EmployeeDetailCard employeeData={employeeData} />
      <Box mt={2}>
        <EffectiveDateForm
          heading="**Please specify any confirmation notes as needed below."
          label="Confirmation"
          btnLabel="Confirm"
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          loading={loading}
          isAttachment
        />
      </Box>
    </>
  );
};

ConfirmEmployee.defaultProps = {
  employeeData: {},
  handleSubmit: () => {},
  handleCancel: () => {},
  loading: false,
};

ConfirmEmployee.propTypes = {
  employeeData: PropTypes.shape(),
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  loading: PropTypes.bool,
};

export default ConfirmEmployee;
