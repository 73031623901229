import dayjs from 'dayjs';
import { validateDateElseHyphen } from 'utils/commonUtils';
import { isObject, isEmpty } from 'lodash';
import { validateNumberElseHyphen } from '../../../../utils/commonUtils';

const getLastPayId = (myDashboardDetails) => {
  const latestPayPeriod = myDashboardDetails?.data?.payPeriodSalarySlipTotalsList
    ? myDashboardDetails?.data?.payPeriodSalarySlipTotalsList
        .sort((pay1, pay2) => dayjs(pay1?.payPeriod?.payDate).unix() - dayjs(pay2?.payPeriod?.payDate).unix())
        .reverse()
    : [];
  const payId = latestPayPeriod.length > 0 && latestPayPeriod[0]?.payPeriod?.id;
  return payId;
};

const getLastCreatedDate = (myDashboardDetails) => {
  const latestPayPeriod = myDashboardDetails?.data?.payPeriodSalarySlipTotalsList
    ? myDashboardDetails?.data?.payPeriodSalarySlipTotalsList
        .sort((pay1, pay2) => dayjs(pay1?.payPeriod?.payDate).unix() - dayjs(pay2?.payPeriod?.payDate).unix())
        .reverse()
    : [];
  const findDate =
    latestPayPeriod.length > 0 && validateDateElseHyphen(latestPayPeriod[0]?.payPeriod?.payDate, 'DD MMMM YYYY');

  return findDate;
};

const getSalaryCompositionArray = (myDashboardDetails) => {
  const payId = getLastPayId(myDashboardDetails);

  const payStructure =
    !isEmpty(myDashboardDetails?.data?.payPeriodSalSlipLineEntriesMap?.[`${payId}`]) &&
    isObject(myDashboardDetails?.data?.payPeriodSalSlipLineEntriesMap?.[`${payId}`])
      ? Object.entries(myDashboardDetails?.data?.payPeriodSalSlipLineEntriesMap?.[`${payId}`])
          .map(([key, val]) => ({
            name: myDashboardDetails?.data?.payheadIDSalStructLineEntryMap?.[`${key}`]?.payhead?.nameWithType,
            value: val,
          }))
          .filter((legend) => {
            return legend.name !== undefined;
          })
      : [];

  return payStructure;
};

const getDashboardPayDetails = (myDashboardDetails) => {
  const configuredData = {
    grossAmount: validateNumberElseHyphen(myDashboardDetails?.data?.taxWorkingInstance?.grossTotalIncomeAnnual),
    salaryDetails: [
      {
        title: 'Gross Earnings',
        amount: ` ₹ ${validateNumberElseHyphen(
          myDashboardDetails?.data?.taxWorkingInstance?.salaryStructure?.netAnnualEarnings,
        )}`,
      },
      {
        title: 'Gross Deductions',
        amount: ` ₹ ${validateNumberElseHyphen(
          myDashboardDetails?.data?.taxWorkingInstance?.salaryStructure?.netAnnualDeductions,
        )}`,
      },
      {
        title: 'Net Earnings',
        amount: `₹ ${validateNumberElseHyphen(
          myDashboardDetails?.data?.taxWorkingInstance?.salaryStructure?.netAnnualSalary,
        )}`,
      },
    ],
    dataWiseDetails: myDashboardDetails?.data?.typeWisePieChartData
      ? myDashboardDetails?.data?.typeWisePieChartData.map(([name, value]) => ({ name, value }))
      : [],
    dataWiseAggregates: myDashboardDetails?.data?.payheadWisePieChartData
      ? myDashboardDetails?.data?.payheadWisePieChartData.map(([name, value]) => ({ name, value }))
      : [],
    data: getSalaryCompositionArray(myDashboardDetails),
    lastCredited: getLastCreatedDate(myDashboardDetails),
  };

  return configuredData;
};

export { getDashboardPayDetails };
