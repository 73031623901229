import * as React from 'react';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import Typographys from 'components/Atoms/Typography';
import { Box } from '@mui/material';
import { TableStyle } from 'components/Organisms/MyPay/SalaryDetails/style';

const SalaryDetailsTable = ({ rows, columns, indexNo, isIndexNo }) => {
  return (
    <Paper sx={{ overflow: 'auto' }}>
      <Box sx={TableStyle.SalaryTableHeader}>
        {columns?.map((col) => {
          return (
            <Box sx={{ width: col?.columnWidth }}>
              <Typographys variant="h6">{col?.columnTitle}</Typographys>
            </Box>
          );
        })}
      </Box>

      {rows?.map((row, index) => {
        return (
          <Box sx={TableStyle.SalaryTableRows}>
            {columns?.map((col, i) => {
              return (
                <Box sx={{ width: col?.columnWidth }}>
                  <Typographys variant="body2">
                    {isIndexNo && <Box component="span">{i === 0 && indexNo + index}</Box>}
                    {row[col?.fieldName]}
                    {i === 0 && (
                      <Box component="span" sx={{ width: col?.columnWidth, color: 'neutral.600', marginLeft: '.5rem' }}>
                        {row?.subLabel}
                      </Box>
                    )}
                  </Typographys>
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Paper>
  );
};

SalaryDetailsTable.defaultProps = {
  rows: [],
  columns: [],
  isIndexNo: false,
  indexNo: 1,
};

SalaryDetailsTable.propTypes = {
  isIndexNo: PropTypes.bool,
  indexNo: PropTypes.number,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      earnings: PropTypes.string,
      subLabel: PropTypes.string,
      annualAmount: PropTypes.string,
      perPeriodAmount: PropTypes.string,
    }),
  ),

  columns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      columnTitle: PropTypes.string,
      columnWidth: PropTypes.string,
    }),
  ),
};

export default SalaryDetailsTable;
