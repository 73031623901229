import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Typography from 'components/Atoms/Typography';
import TableExtended from 'components/Molecules/TableExtended';

const HolidaysTab = ({ loading, holidaysColumn, holidaysRow, sortDirection, sortBy, onSortChange }) => {
  return (
    <PerfectScrollbar>
      <Box
        sx={{
          minHeight: '56.5rem',
          maxHeight: '56.5rem',
          maxWidth: {
            md: '40rem',
            lg: '42rem',
            xl: '100%',
          },
        }}
      >
        <Box mb="2.5rem">
          <Typography variant="h6">Holidays</Typography>
        </Box>
        {!holidaysRow?.length ? (
          <Typography>No data available</Typography>
        ) : (
          <TableExtended
            loading={loading}
            columns={holidaysColumn}
            rows={holidaysRow}
            showCheckbox={false}
            sortDirection={sortDirection}
            sortBy={sortBy}
            onSortChange={onSortChange}
          />
        )}
      </Box>
    </PerfectScrollbar>
  );
};

HolidaysTab.defaultProps = {
  loading: false,
  holidaysColumn: [],
  holidaysRow: [],
  sortDirection: 'asc',
  sortBy: 'id',
  onSortChange: () => {},
};
HolidaysTab.propTypes = {
  loading: PropTypes.bool,
  holidaysColumn: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),
  holidaysRow: PropTypes.arrayOf(
    PropTypes.shape({
      slNo: PropTypes.number,
      date: PropTypes.date,
      occasion: PropTypes.string,
    }),
  ),
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  onSortChange: PropTypes.func,
};

export default HolidaysTab;
