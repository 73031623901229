import React from 'react';
import PropTypes from 'prop-types';
import EmployeeCommonCard from 'components/Organisms/Employee/EmployeeCommonCard';
import NumOfEmpLineChart from '../Charts/NumOfEmpLineChart';

const NumOfEmployeeChartCard = ({
  numOfEmployeeChartFilterOptions,
  numOfEmployeeChartFilterHandleChange,
  empLineChartData,

  empTypeWiseColumns,
}) => {
  return (
    <EmployeeCommonCard
      cardHeaderTitle="Numbers of Employee"
      minWidthHeaderDropdown="17rem"
      headerFilterId="numOfEmployeeChartFilter"
      headerFilterLabel="Employment Type"
      headerFilterOptions={numOfEmployeeChartFilterOptions}
      headerFilterHandleChange={numOfEmployeeChartFilterHandleChange}
      bodyContent={<NumOfEmpLineChart empLineChartData={empLineChartData} empTypeWiseColumns={empTypeWiseColumns} />}
      isFooter={false}
      cardBodyMinHeight="32rem"
      cardBodyPadding="4rem 0 5rem 4rem"
    />
  );
};

NumOfEmployeeChartCard.defaultProps = {
  numOfEmployeeChartFilterOptions: [],
  numOfEmployeeChartFilterHandleChange: () => {},
  empLineChartData: [],
};
NumOfEmployeeChartCard.propTypes = {
  numOfEmployeeChartFilterOptions: PropTypes.arrayOf,
  numOfEmployeeChartFilterHandleChange: PropTypes.func,
  empLineChartData: PropTypes.arrayOf,
};

export default NumOfEmployeeChartCard;
