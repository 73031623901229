export const colorBackgroundPage = '#ffffff';

export const colorBackgroundPageFaded = '#f5f9fe';

export const colorBackgroundBase = '#ffffff';

export const colorBackgroundElevated = '#142746';

export const colorBackgroundElevatedHighlighted = '#263b5e';

export const colorBackgroundElevated2 = '#3a5075';

export const colorBackgroundElevated2Highlighted = '#48618a';

export const colorBackgroundNeutral = '#f5f9fe';

export const colorBackgroundNeutralFaded = '#cedcf3';

export const colorBackgroundNeutralHighlighted = '#e7f0fd';

export const colorBackgroundDisabled = '#eff3f8';

export const colorBackgroundPrimary = '#2066e4';

export const colorBackgroundPrimaryFaded = '#eaf1fd';

export const colorBackgroundPrimaryHighlighted = '#1855c4';

export const colorBackgroundPositive = '#2066e4';

export const colorBackgroundPositiveHighlighted = '#eaf1fd';

export const colorBackgroundCritical = '#e13755';

export const colorBackgroundCriticalHighlighted = '#ffedf0';

export const colorBackgroundToothHighlighted = '#e6eeff';

export const colorBackgroundToothActive = '#96baff';

export const colorOnBackgroundNeutral = '#03122b';

export const colorOnBackgroundPrimary = '#ffffff';

export const colorOnBackgroundPositive = '#ffffff';

export const colorOnBackgroundCritical = '#ffffff';

export const colorOnBackgroundElevated = '#ffffff';

export const colorOnBackgroundElevatedFaded = '#3c455d';

export const colorOnBackgroundNeutralHighlighted = '#03122b';

export const colorOnBackgroundPrimaryHighlighted = '#ffffff';

export const colorOnBackgroundPositiveHighlighted = '#ffffff';

export const colorOnBackgroundCriticalHighlighted = '#ffffff';

export const colorOnBackgroundElevatedHighlighted = '#ffffff';

export const colorForegroundNeutral = '#03122b';

export const colorForegroundNeutralFaded = '#263b5e';

export const colorForegroundNeutralHighlighted = '#41587e';

export const colorForegroundNeutralLight = '#5976a6';

export const colorForegroundNeutralLightFaded = '#6e8bb9';

export const colorForegroundNeutralLightHighlighted = '#6883af';

export const colorForegroundDisabled = '#adb5c5';

export const colorForegroundPrimary = '#2066e4';

export const colorForegroundPositive = '#2066e4';

export const colorForegroundCritical = '#e13755';

export const colorForegroundWhite = '#ffffff';

export const colorBorderNeutral = '#e7f0fd';

export const colorBorderNeutralFaded = '#f5f9fe';

export const colorBorderNeutralHighlighted = '#cedcf3';

export const colorBorderTooth = '#a7bbdf';

export const colorBorderToothHighlighted = '#5a93ff';

export const colorBorderToothActive = '#1365ff';

export const colorBorderPrimary = '#2066e4';

export const colorBorderPositive = '#2066e4';

export const colorBorderCritical = '#e13755';

export const colorBorderWhite = '#ffffff';

export const colorBlackNWhiteBlack = '#03122b';

export const colorBlackNWhiteWhite = '#ffffff';

export const colorTabToday = '#d629a5';

export const colorTabPlanOne = '#2066e4';

export const colorTabPlanTwo = '#31c931';

export const colorTabPlanThree = '#18b8c2';

export const colorTabPlanFour = '#e9873a';

export const colorTabPlanFive = '#7823e3';

export const colorTabPlanSix = '#e35036';

export const colorFindingFillingStroke = '#064aca';

export const colorFindingFillingFill = '#2a6dec';

export const colorFindingFillingFaded = '#c9dbfa';

export const colorFindingArtificialCrownStroke = '#5376ae';

export const colorFindingArtificialCrownFill = '#7696cf';

export const colorFindingMetallicFill = '#6588c7';

export const colorFindingMetallicStroke = '#3b63a4';

export const colorFindingInflamationFill = '#ed1a3f';

export const colorFindingInflamationStroke = '#b90222';

export const colorFindingInflamationFaded = '#ffc6d0';

export const colorFindingDirtFill = '#f9a84a';

export const colorFindingDirtStroke = '#d97d12';

export const colorFindingDirtFaded = '#ffcd94';

export const colorFindingRemovableFill = '#41cceb';

export const colorFindingRemovableStroke = '#1cb3d4';

export const colorFindingRemovableFaded = '#97edff';
