import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { validateDateElseHyphen } from 'utils/commonUtils';
import TitleWithText from 'components/Atoms/TitleWithText';

const BasicProfile = ({ employeeBasicDetail }) => {
  return (
    <Grid sx={{ paddingLeft: '24px' }} container rowSpacing={1} columnSpacing={2}>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText title="Gender" text={employeeBasicDetail.gender} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText title="Marital Status" text={employeeBasicDetail.maritalStatus} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText title="Date Of Birth" text={validateDateElseHyphen(employeeBasicDetail?.dob, 'MMMM DD, YYYY')} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText
          title="Date Of Joining"
          text={validateDateElseHyphen(employeeBasicDetail?.doj, 'MMMM DD, YYYY')}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText title="Employment Status" text={employeeBasicDetail.employmentStatus} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText title="Father’s Name" text={employeeBasicDetail.fatherName} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText title="Mother’s Name" text={employeeBasicDetail.motherName} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText title="Spouse Name" text={employeeBasicDetail.spouseName} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText title="Location" text={employeeBasicDetail.location} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText title="PAN Number" text={employeeBasicDetail.pan} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText title="Aadhaar Number" text={employeeBasicDetail.aadhaar} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText title="UAN Number" text={employeeBasicDetail.uan} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText title="UHID Number" text={employeeBasicDetail.uhid} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText title="EPF Account Number" text={employeeBasicDetail.epf} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText title="EPS Account Number" text={employeeBasicDetail.eps} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText title="ESI Account Number" text={employeeBasicDetail.esi} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText title="Groups Affiliations" text={employeeBasicDetail.groupsAffiliations} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText title="Employment Type" text={employeeBasicDetail.employmentType} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText title="Employment Status" text={employeeBasicDetail.employmentActiveStatus} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText title="Supervisor" text={employeeBasicDetail.supervisor} />
      </Grid>
    </Grid>
  );
};

BasicProfile.defaultProps = {
  employeeBasicDetail: {
    gender: 'Male',
    maritalStatus: 'UnMarried',
    dob: '07/06/1960',
    doj: '04/06/2017',
    employmentStatus: 'Full Time',
    fatherName: 'Vidyasagar',
    motherName: 'Snehalatha',
    spouseName: '-',
    location: 'Mumbai',
    pan: 'AIVpDUAFTA',
    aadhaar: '123456789128',
    uan: '100002345878886',
    uhid: 'AJHTUH5654TH',
    epf: '34567124552',
    eps: '6787659223',
    esi: '890898723239',
    groupsAffiliations: '-',
    employmentType: 'Permanent',
    employmentActiveStatus: 'ACTIVE',
    supervisor: 'Aachman Kabir',
  },
};

BasicProfile.propTypes = {
  employeeBasicDetail: PropTypes.shape({
    gender: PropTypes.string,
    maritalStatus: PropTypes.string,
    dob: PropTypes.string,
    doj: PropTypes.string,
    employmentStatus: PropTypes.string,
    fatherName: PropTypes.string,
    motherName: PropTypes.string,
    spouseName: PropTypes.string,
    location: PropTypes.string,
    pan: PropTypes.string,
    aadhaar: PropTypes.string,
    uan: PropTypes.string,
    uhid: PropTypes.string,
    epf: PropTypes.string,
    eps: PropTypes.string,
    esi: PropTypes.string,
    groupsAffiliations: PropTypes.string,
    employmentType: PropTypes.string,
    employmentActiveStatus: PropTypes.string,
    supervisor: PropTypes.string,
  }),
};

export default BasicProfile;
