import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import SearchBox from 'components/Molecules/SearchBox';
import DropdownMenu from 'components/Molecules/DropdownMenu';
import Typographys from 'components/Atoms/Typography';
import DatePicker from 'components/Atoms/Datepicker';
import Button from 'components/Atoms/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconWithTitle from 'components/Atoms/IconWithTitle';
import MuiTimeline from 'components/Organisms/Timeline';
import { timelineStyle } from '../style';

const EmployeeTimeline = ({
  empTimelineTitle,
  handleEmpTimelineSearch,
  empTimelineFilter,
  empTimeline,
  empTimelineStartDateChange,
  empTimelineEndDateChange,
  empTimelineOnFetch,
}) => {
  const [showTimeLine, setShowTimeLine] = useState(false);
  const handleFetch = () => {
    empTimelineOnFetch();
    setShowTimeLine(true);
  };

  return (
    <Box sx={timelineStyle.timelineWrapper}>
      {showTimeLine ? (
        <>
          <Box sx={timelineStyle.timelineHeader}>
            <IconWithTitle
              iconElement=<ArrowBackIcon fontSize="inherit" />
              iconColor="neutral.600"
              iconFontSize="2rem"
              title={empTimelineTitle}
              titleColor="neutral.600"
              titleVariant="body1"
              onClick={() => {
                setShowTimeLine(false);
              }}
            />
            <Box display="flex" alignItems="center">
              <Box mr={1} maxWidth="18.75rem">
                <SearchBox
                  size="small"
                  placeholder="Search..."
                  id="empTimeLineSearch"
                  name="empTimeLineSearch"
                  handleChange={handleEmpTimelineSearch}
                />
              </Box>
              <DropdownMenu btnLabel="Filter" color="info" menuLists={empTimelineFilter} />
            </Box>
          </Box>

          <Box mt={3}>
            <MuiTimeline timeLineLists={empTimeline} />
          </Box>
        </>
      ) : (
        <>
          <Box mb={1.75}>
            <Typographys variant="body2" color="neutral.600">
              Select the duration of timeline
            </Typographys>
          </Box>
          <Grid container columnGap={8.75} flexWrap={{ lg: 'noWrap' }}>
            <Grid item maxWidth="34.375rem" mb={2}>
              <DatePicker
                name="startDate"
                id="startDate"
                label="Start Date"
                autoComplete="Start Date"
                onChange={empTimelineStartDateChange}
                required
                fullWidth
                autoFocus
              />
            </Grid>

            <Grid item maxWidth="34.375rem" mb={2}>
              <DatePicker
                name="endDate"
                id="endDate"
                label="End Date"
                autoComplete="endDate"
                onChange={empTimelineEndDateChange}
                required
                fullWidth
                autoFocus
              />
            </Grid>
          </Grid>
          <Button variant="contained" size="medium" onClick={handleFetch}>
            Fetch
          </Button>
        </>
      )}
    </Box>
  );
};
EmployeeTimeline.defaultProps = {
  empTimeline: [
    {
      date: '5th Dec 2022',
      time: '3:50:51 IST',
      title: 'Employee Timeline',
      desc: 'Aachman kabir(1001)',
      note: '',
    },
    {
      date: '5th Dec 2022',
      time: '3:50:51 IST',
      title: 'Employee Timeline',
      desc: 'Aachman kabir(1001)',
      note: '',
    },
    {
      date: '5th Dec 2022',
      time: '3:50:51 IST',
      title: 'Employee Timeline',
      desc: 'Aachman kabir(1001)',
      note: '',
    },
    {
      date: '5th Dec 2022',
      time: '3:50:51 IST',
      title: 'Employee Timeline',
      desc: 'Aachman kabir(1001)',
      note: '',
    },
  ],
  empTimelineTitle: 'Showing result from 1st Dec 2022 to 31th Dec 2022',
  handleEmpTimelineSearch: () => {},
  empTimelineFilter: [
    { id: 1, label: 'Add New', onClick: () => {} },
    { id: 2, label: 'Bulk Upload', onClick: () => {} },
    { id: 3, label: 'Send ESS invite', onClick: () => {} },
    { id: 4, label: 'Download', onClick: () => {} },
  ],
  empTimelineStartDateChange: () => {},
  empTimelineEndDateChange: () => {},
  empTimelineOnFetch: () => {},
};

EmployeeTimeline.propTypes = {
  empTimeline: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      time: PropTypes.string,
      label: PropTypes.string,
      title: PropTypes.string,
      desc: PropTypes.string,
      note: PropTypes.string,
    }),
  ),
  empTimelineTitle: PropTypes.string,
  handleEmpTimelineSearch: PropTypes.func,
  empTimelineFilter: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  empTimelineStartDateChange: PropTypes.func,
  empTimelineEndDateChange: PropTypes.func,
  empTimelineOnFetch: PropTypes.func,
};

export default EmployeeTimeline;
