import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import TeamAttendanceStatusGroup from './TeamAttendanceStatusGroup';
import TeamAttendanceTableCard from './TeamAttendanceTableCard';

const AttendanceDashboard = ({
  statusOptions,
  statusHandleChange,
  attendanceStatus,
  isStatusUp,
  handleFilterDateChange,
  todayAttendanceStatus,
  attendanceStatusHandleChange,
  actionMenuLists,
  attendanceTableLoading,
  attendanceTableDataColumn,
  attendanceTableDataRow,
  attendanceTableKeyColumn,
  attendanceTablePage,
  attendanceTableSortDirection,
  attendanceTableSortBy,
  attendanceTableHandleSort,
  attendanceTableCount,
  attendanceTableOnPageChange,
  attendanceTableOnRowClick,
  attendanceTableSelectedItems,
  attendanceTableOnSelect,
  attendanceTableOnSelectAll,
  attendanceTableOnSortChange,
  isCheckAttendance,
  handleSelectAttendance,
  attendanceGridLoading,
  attendanceGridDataColumn,
  attendanceGridDataRow,
  attendanceGridHandleSort,
  attendanceGridSortBy,
  attendanceGridSortDirection,
  attendanceGridCount,
  attendanceGridPage,
  attendanceGridOnPageChange,
  attendanceGridOnSortChange,
}) => {
  return (
    <Box>
      <TeamAttendanceStatusGroup
        statusOptions={statusOptions}
        statusHandleChange={statusHandleChange}
        attendanceStatus={attendanceStatus}
        isStatusUp={isStatusUp}
        handleFilterDateChange={handleFilterDateChange}
        todayAttendanceStatus={todayAttendanceStatus}
      />
      <Box mt={2}>
        <TeamAttendanceTableCard
          attendanceStatusHandleChange={attendanceStatusHandleChange}
          actionMenuLists={actionMenuLists}
          attendanceTableLoading={attendanceTableLoading}
          attendanceTableDataColumn={attendanceTableDataColumn}
          attendanceTableDataRow={attendanceTableDataRow}
          attendanceTableKeyColumn={attendanceTableKeyColumn}
          attendanceTablePage={attendanceTablePage}
          attendanceTableSortDirection={attendanceTableSortDirection}
          attendanceTableSortBy={attendanceTableSortBy}
          attendanceTableHandleSort={attendanceTableHandleSort}
          attendanceTableCount={attendanceTableCount}
          attendanceTableOnPageChange={attendanceTableOnPageChange}
          attendanceTableOnRowClick={attendanceTableOnRowClick}
          attendanceTableSelectedItems={attendanceTableSelectedItems}
          attendanceTableOnSelect={attendanceTableOnSelect}
          attendanceTableOnSelectAll={attendanceTableOnSelectAll}
          attendanceTableOnSortChange={attendanceTableOnSortChange}
          isCheckAttendance={isCheckAttendance}
          handleSelectAttendance={handleSelectAttendance}
          attendanceGridLoading={attendanceGridLoading}
          attendanceGridDataColumn={attendanceGridDataColumn}
          attendanceGridDataRow={attendanceGridDataRow}
          attendanceGridHandleSort={attendanceGridHandleSort}
          attendanceGridSortBy={attendanceGridSortBy}
          attendanceGridSortDirection={attendanceGridSortDirection}
          attendanceGridCount={attendanceGridCount}
          attendanceGridPage={attendanceGridPage}
          attendanceGridOnPageChange={attendanceGridOnPageChange}
          attendanceGridOnSortChange={attendanceGridOnSortChange}
        />
      </Box>
    </Box>
  );
};

AttendanceDashboard.defaultProps = {
  statusOptions: [],
  statusHandleChange: () => {},
  attendanceStatus: {},
  isStatusUp: true,
  handleFilterDateChange: () => {},
  todayAttendanceStatus: {},
  attendanceStatusHandleChange: () => {},
  actionMenuLists: [],
  attendanceTableLoading: false,
  attendanceTableDataColumn: [],
  attendanceTableDataRow: [],
  attendanceTableKeyColumn: 'id',
  attendanceTableHandleSort: () => {},
  attendanceTableSortBy: () => {},
  attendanceTableSortDirection: 'asc',
  attendanceTableCount: 10,
  attendanceTablePage: null,
  attendanceTableOnPageChange: () => {},
  attendanceTableOnRowClick: () => {},
  attendanceTableSelectedItems: [],
  attendanceTableOnSelect: () => {},
  attendanceTableOnSelectAll: () => {},
  attendanceTableOnSortChange: () => {},
  handleSelectAttendance: () => {},
  isCheckAttendance: false,
  attendanceGridLoading: false,
  attendanceGridDataColumn: [],
  attendanceGridDataRow: [],
  attendanceGridHandleSort: () => {},
  attendanceGridSortBy: () => {},
  attendanceGridSortDirection: 'asc',
  attendanceGridCount: 10,
  attendanceGridPage: null,
  attendanceGridOnPageChange: () => {},
  attendanceGridOnSortChange: () => {},
};
AttendanceDashboard.propTypes = {
  statusOptions: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, name: PropTypes.string, onClick: PropTypes.func }),
  ),
  statusHandleChange: PropTypes.func,
  attendanceStatus: PropTypes.shape({
    avgHrPerDay: PropTypes.string,
    avgHrPerDayDetails: PropTypes.string,
    onTimeArrival: PropTypes.string,
    onTimeArrivalDetails: PropTypes.string,
  }),
  isStatusUp: PropTypes.bool,
  handleFilterDateChange: PropTypes.func,
  todayAttendanceStatus: PropTypes.shape({
    onTimeToday: PropTypes.number,
    lateArrivalsToday: PropTypes.number,
    wfhOnDutyToday: PropTypes.number,
    remoteCheckInsToday: PropTypes.number,
  }),
  attendanceStatusHandleChange: PropTypes.func,
  actionMenuLists: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  attendanceTableLoading: PropTypes.bool,
  attendanceTableDataColumn: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),
  attendanceTableDataRow: PropTypes.arrayOf(
    PropTypes.shape({
      empName: PropTypes.string,
      empImgSrc: PropTypes.string,
      status: PropTypes.string,
      empId: PropTypes.string,
      period: PropTypes.string,
      day1: PropTypes.string,
      day2: PropTypes.string,
      day3: PropTypes.string,
      day4: PropTypes.string,
      day5: PropTypes.string,
      day6: PropTypes.string,
    }),
  ),
  attendanceTableKeyColumn: PropTypes.string,
  attendanceTableSortBy: PropTypes.func,
  attendanceTableHandleSort: PropTypes.func,
  attendanceTableSortDirection: PropTypes.string,
  attendanceTableCount: PropTypes.number,
  attendanceTablePage: PropTypes.number,
  attendanceTableOnPageChange: PropTypes.func,
  attendanceTableOnRowClick: PropTypes.func,
  attendanceTableSelectedItems: PropTypes.arrayOf,
  attendanceTableOnSelect: PropTypes.func,
  attendanceTableOnSelectAll: PropTypes.func,
  attendanceTableOnSortChange: PropTypes.func,

  handleSelectAttendance: PropTypes.func,
  isCheckAttendance: PropTypes.bool,
  attendanceGridLoading: PropTypes.bool,
  attendanceGridDataColumn: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),
  attendanceGridDataRow: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.string,
      firstTimeIn: PropTypes.string,
      lastTimeOut: PropTypes.string,
      duration: PropTypes.string,
      interimPunches: PropTypes.string,
      attendance: PropTypes.string,
      exception: PropTypes.string,
      attendanceMarking: PropTypes.string,
    }),
  ),
  attendanceGridSortBy: PropTypes.func,
  attendanceGridHandleSort: PropTypes.func,
  attendanceGridSortDirection: PropTypes.string,
  attendanceGridCount: PropTypes.number,
  attendanceGridPage: PropTypes.number,
  attendanceGridOnPageChange: PropTypes.func,
  attendanceGridOnSortChange: PropTypes.func,
};

export default AttendanceDashboard;
