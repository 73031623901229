import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { CompareTDSStyle } from 'components/Organisms/MyPay/CompareTDS/style';
import TableExtended from 'components/Molecules/TableExtended';
import { validateNumberElseHyphen } from '../../../../../utils/commonUtils';

const CategoryTable = ({ categoryColumnsData, categoryRowsData }) => {
  const validatedRows = categoryRowsData.map((row) => {
    const validatedRow = {};
    Object.entries(row).forEach(([key, value]) => {
      validatedRow[key] = typeof value === 'number' ? validateNumberElseHyphen(value) : value;
    });
    return validatedRow;
  });

  return (
    <Box sx={CompareTDSStyle.categoryTableWrapper}>
      <TableExtended columns={categoryColumnsData} rows={validatedRows} />
    </Box>
  );
};
CategoryTable.defaultProps = {
  categoryColumnsData: [],
  categoryRowsData: [],
};
CategoryTable.propTypes = {
  categoryColumnsData: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  categoryRowsData: PropTypes.shape({
    'Professional Tax': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Income Tax': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Total Deductions': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Net Earnings': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
  }),
};

export default CategoryTable;
