import React, { useEffect, useState } from 'react';
import MyLeaveDashboard from 'components/Templates/Leaves/MyLeaves/Dashboard';
import { Typography, Skeleton, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useFetcher from 'hooks/useFetcher';
import { getMyLeavesDashboardData } from 'api/dashboard';
import { useSelector } from 'react-redux';
import { isObject, isEmpty } from 'lodash';
import useColumns from './useColumns';
import { getLeaveData, getUserLeaveTransactionsData } from './getConfiguredData';

const MyLeaveDashboardPage = () => {
  const navigate = useNavigate();
  const [columns] = useColumns();
  const [leaveData, setLeaveData] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [leaveTabsData, setLeaveTabsData] = useState([]);
  const [myLeavesDashboardData] = useFetcher(getMyLeavesDashboardData);

  const { user } = useSelector((state) => state?.session);

  function getLeaveTabsData(leavesDashboardData) {
    const input = leavesDashboardData?.leaveAccountSpecificChartsMap;
    let leaveTransactionsRow = [];
    return isObject(input) && !isEmpty(input)
      ? Object.entries(input).map(([key]) => {
          leaveTransactionsRow = getUserLeaveTransactionsData(
            key,
            user?.empDBID,
            myLeavesDashboardData?.data?.empIDLeaveCatWiseTranMap,
          );
          return {
            name: leavesDashboardData?.leaveAccountsMap[key]?.leaveCategory?.displayName,
            openingBalance: leavesDashboardData?.leaveAccountsMap[key]?.periodOpeningBalance,
            leavesAvailed: leavesDashboardData?.leaveAccountsMap[key]?.leavesAvailed,
            currentBalance: leavesDashboardData?.leaveAccountsMap[key]?.currentBalance,
            transactions: '-',
            pendingApprovalBalance: leavesDashboardData?.leaveAccountsMap[key]?.pendingApprovalBalance,
            leaveTransactionsColumn: columns,
            leaveTransactionsRow,
          };
        })
      : [];
  }

  useEffect(() => {
    if (myLeavesDashboardData.isLoading === false) {
      setLeaveData(getLeaveData(myLeavesDashboardData?.data));
    }
  }, [myLeavesDashboardData]);

  useEffect(() => {
    if (myLeavesDashboardData.isLoading === false) {
      setLeaveTabsData(getLeaveTabsData(myLeavesDashboardData?.data));
    }
  }, [myLeavesDashboardData]);

  if (myLeavesDashboardData.error !== null) {
    return (
      <Typography variant="h6">
        {myLeavesDashboardData?.error?.response?.data?.message || 'Error while fetching my leaves dashboard details.'}
      </Typography>
    );
  }

  const onTabChange = (val) => {
    setSelectedTab(val);
  };

  const handleAction = () => {
    navigate('/home/leaves/my-leave-requests/add');
  };

  return myLeavesDashboardData.isLoading ? (
    <Box sx={{ p: 4 }}>
      <Skeleton height={70} />
      <Skeleton />
      <Skeleton />
    </Box>
  ) : (
    <MyLeaveDashboard
      totalLeaveTransactions="-"
      leaveData={leaveData}
      leaveTabData={leaveTabsData}
      onTabChange={onTabChange}
      selectedTab={selectedTab}
      isActionMenuLists={false}
      isActionButton
      handleAction={handleAction}
      actionLabel="Add Leave Request"
    />
  );
};

export default MyLeaveDashboardPage;
