import { useSelector } from 'react-redux';
import { hasPermission } from 'utils/permissions';

const getActionItems = (employeeServices, authorities) => {
  return [
    {
      name: 'View',
      onClick: employeeServices.viewEmployee,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_SECUSER'],
    },
    {
      name: 'Modify',
      onClick: employeeServices.modifyEmployee,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_EDIT_SECUSER'],
      condtional: (row) => row.lockStatus?.toUpperCase() === 'UNLOCKED',
    },
    {
      name: 'Modify (No Roles)',
      onClick: employeeServices.modifyEmployeeWithoutRole,
      icon: '',
      condtional: (row) =>
        row.lockStatus?.toUpperCase() === 'UNLOCKED' &&
        !hasPermission(authorities, ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_EDIT_SECUSER']) &&
        hasPermission(authorities, ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_EDIT_SECUSER_WITHOUT_ROLES']),
    },
    {
      name: 'Delete',
      onClick: () => {
        employeeServices.handleOpenDialog('DELETE_EMPLOYEE');
      },
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_DELETE_SECUSER'],
      condtional: (row) => row.lockStatus?.toUpperCase() === 'UNLOCKED',
    },
    {
      name: 'Lock',
      onClick: () => {
        employeeServices.handleOpenDialog('LOCK_EMPLOYEE');
      },
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_LOCK_SECUSER'],
      condtional: (row) => row.lockStatus?.toUpperCase() === 'UNLOCKED',
    },
    {
      name: 'UnLock',
      onClick: () => {
        employeeServices.handleOpenDialog('UNLOCK_EMPLOYEE');
      },
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UNLOCK_SECUSER'],
      condtional: (row) => row.lockStatus?.toUpperCase() !== 'UNLOCKED',
    },
    {
      name: 'Deactivate',
      onClick: () => {
        employeeServices.handleOpenDialog('DEACTIVATE_EMPLOYEE');
      },
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_EMPLOYMENT_STATUS'],
      condtional: (row) => row.employmentStatus?.toUpperCase() === 'ACTIVE',
    },
    {
      name: 'Activate',
      onClick: () => {
        employeeServices.handleOpenDialog('ACTIVATE_EMPLOYEE');
      },
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_EMPLOYMENT_STATUS'],
      condtional: (row) => row.employmentStatus?.toUpperCase() === 'INACTIVE',
    },
    {
      name: 'Send ESS Invite',
      onClick: employeeServices.sendESSInvite,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_SEND_ESS_INVITE_TO_EMPLOYEE'],
    },
    {
      name: 'Convert to Candidate',
      onClick: employeeServices.convertToCandidate,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_CONVERT_EMP_TO_CANDIDATE'],
    },
    {
      name: 'Add employee to group',
      onClick: () => {
        employeeServices.handleOpenDialog('SELECT_EMPLOYEE_GROUP');
      },
      icon: '',
      permissions: [''],
    },
    {
      name: 'Map life cycle policy',
      onClick: () => {
        employeeServices.handleOpenDialog('MAP_LIFECYCLE_POLICY');
      },
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_SECUSER'],
    },
    // TODO: Part of Admin functionality
    // {
    //   name: 'View OnBoarding Records',
    //   onClick: employeeServices.viewOnboardingRecords,
    //   icon: '',
    //   permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_ONBOARDING_RECORDS'],
    // },
    // {
    //   name: 'Performance Settings',
    //   onClick: employeeServices.employeePerformanceSettings,
    //   icon: '',
    //   permissions: ['ROLE_BASE_PERMISSION_PERF_INDEX_VIEW_EMP_PERFORMANCE_SETTINGS'],
    // },
    {
      name: 'Add Notes',
      onClick: () => {
        employeeServices.handleOpenDialog('ADD_NOTES');
      },
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_ADD_EMPLOYEE_NOTE'],
    },

    // The below commented actions will be enabled when the routes for these tabs are added and is functional

    // {
    //   name: 'Emp TimeLine',
    //   onClick: () => {},
    //   icon: '',
    //   permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_TIMELINE'],
    // },
    // {
    //   name: 'Total TimeLine',
    //   onClick: () => {},
    //   icon: '',
    //   permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_COMPLETE_TIMELINE'],
    // },
    // {
    //   name: 'Salary TimeLine',
    //   onClick: () => {},
    //   icon: '',
    //   permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_SALARY_TIMELINE'],
    // },
    {
      name: 'Access Filters',
      onClick: employeeServices.employeeAccessFilters,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_ACCESS_FILTERS'],
    },
    // {
    //   name: 'View activity log',
    //   onClick: () => {},
    //   icon: '',
    //   permissions: [''],
    // },
  ];
};

const useActionItems = (employeeServices) => {
  const authorities = useSelector((state) => state.session.user.authorities);
  const actions = getActionItems(employeeServices, authorities);

  const handleActionItemClick = (row) => {
    const moreFilteredActionItems = actions.filter((action) => {
      const userHasPermission = hasPermission(authorities, action.permissions);
      if ((action.condtional && !action.condtional(row)) || !userHasPermission) {
        return false;
      }
      return true;
    });
    return moreFilteredActionItems;
  };

  return [handleActionItemClick];
};

export default useActionItems;
