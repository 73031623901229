import React from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import CustomLegend from 'components/Molecules/CustomLegend';
import { chartStyle } from '../style';

const LineCharts = ({ chartOptions, chartData, XAxisData, YAxisData, lineData, isLegend, legendOptions, lineDot }) => {
  const theme = useTheme();
  return (
    <Box sx={chartStyle.chartWrapper}>
      <ResponsiveContainer width="100%" height={chartOptions.height}>
        <LineChart
          width={chartOptions.width}
          height={chartOptions.height}
          data={chartData}
          margin={{ top: 40, bottom: 40, right: 15 }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey={XAxisData.dataKey}
            axisLine={{ stroke: theme.palette.other[200] }}
            tick={{ fill: theme.palette.neutral[600] }}
            tickLine={{ stroke: theme.palette.common.white }}
            style={chartStyle.axisLabel}
            label={{
              value: XAxisData.value,
              position: XAxisData.position,
              offset: 20,
              fill: theme.palette.neutral[800],
            }}
          />
          <YAxis
            axisLine={false}
            tick={{ fill: theme.palette.neutral[600] }}
            tickLine={{ stroke: theme.palette.common.white }}
            style={chartStyle.axisLabel}
            label={{
              value: YAxisData.value,
              position: YAxisData.position,
              offset: 20,
              fill: theme.palette.neutral[800],
            }}
            allowDecimals
            angle={-45}
          />
          <Tooltip />
          {isLegend && (
            <Legend
              layout={legendOptions.layout}
              align={legendOptions.align}
              verticalAlign={legendOptions.verticalAlign}
              content={
                <CustomLegend
                  layout={legendOptions.layout}
                  iconType={legendOptions.iconType}
                  iconWidth={legendOptions.iconWidth}
                  iconHeight={legendOptions.iconHeight}
                  legendLeftSpacing={legendOptions.legendLeftSpacing}
                />
              }
            />
          )}
          {lineData.map((data) => (
            <Line
              type={data.type}
              name={data.name}
              dataKey={data.dataKey}
              stroke={theme.palette[data.color][data.colorCode]}
              strokeWidth={2}
              dot={lineDot}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

LineCharts.defaultProps = {
  chartOptions: {},
  chartData: [],
  lineData: [],
  XAxisData: {},
  YAxisData: {},
  iconType: 'circle',
  iconWidth: '1.25rem',
  iconHeight: '1.25rem',
  lineDot: true,
  isLegend: true,
  legendOptions: {},
  legendLeftSpacing: '',
};
LineCharts.propTypes = {
  chartOptions: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  chartData: PropTypes.PropTypes.arrayOf(PropTypes.shape()),
  lineData: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
      dataKey: PropTypes.string,
      color: PropTypes.string,
      colorCode: PropTypes.number,
    }),
  ),
  XAxisData: PropTypes.shape({
    dataKey: PropTypes.string,
    value: PropTypes.string,
    position: PropTypes.string,
  }),
  YAxisData: PropTypes.shape({
    value: PropTypes.string,
    position: PropTypes.string,
  }),
  iconType: PropTypes.string,
  iconWidth: PropTypes.string,
  iconHeight: PropTypes.string,
  lineDot: PropTypes.bool,
  isLegend: PropTypes.bool,
  legendOptions: PropTypes.shape({
    layout: PropTypes.string,
    verticalAlign: PropTypes.string,
    align: PropTypes.string,
    iconType: PropTypes.string,
    iconSize: PropTypes.string,
    iconWidth: PropTypes.string,
    iconHeight: PropTypes.string,
    legendLeftSpacing: PropTypes.string,
  }),
  legendLeftSpacing: PropTypes.string,
};

export default LineCharts;
