import dayjs from 'dayjs';
import { isEmpty, isObject } from 'lodash';
import { API_URL } from 'api/api';

export const modifyPreCreateData = (response) => {
  const {
    customFieldValueMap,
    secUserInstance,
    mode,
    company,
    authorityList,
    employeeList,
    employeeGroups,
    employemntStatus,
    selectedGroups,
    departmentsList,
    dependentTypesMap,
    designationsList,
    gender,
    locationsList,
    employementType,
    martialStatus,
    currencies,
    companyAccntsMap,
    skillRecordInstance,
    eventType,
    accountPurpose,
    employeeEPFContribLevelsMap,
    seperationTypes,
    bloodGroupTypes,
    noticePeriod,
    skillLevel,
    statutorySubRegionsIndia,
    extendedDef,
    defaultTransactionTypes,
    defaultBankAccountTypes,
    defaultOnboardingReferences,
    employeeProfileInstance,
  } = response.data;

  return {
    userRoleGroupOptions: employeeGroups.map((item) => ({
      id: item.id,
      name: item.groupName,
    })),
    userRoleOptions: authorityList?.length
      ? authorityList.map((item) => ({ id: item.authority, name: item.displayName }))
      : [],
    basicProfileEmpTypeOptions: Object.values(employementType).map((item, index) => ({
      id: item,
      name: item,
    })),
    basicProfileEmpStatusOptions: employemntStatus.map((item, index) => ({
      id: item,
      name: item,
    })),
    basicProfileDepartmentOptions: departmentsList.map((item) => ({
      id: item.id,
      name: item.name,
    })),
    basicProfileDesignationOptions: designationsList.map((item) => ({
      id: item.id,
      name: item.name,
    })),
    basicProfileSupervisorOptions: employeeList.map((item) => ({
      id: item.id,
      name: item.fullName,
    })),
    basicProfileGenderOptions: Object.values(gender).map((item, index) => ({
      id: item,
      name: item,
    })),
    basicProfileLocationOptions: locationsList.map((item) => ({
      id: item.id,
      name: item.name,
    })),
    basicProfileMaritalOptions: Object.values(martialStatus).map((item, index) => ({
      id: item,
      name: item,
    })),
    basicProfileGroupOptions: employeeGroups.map((item) => ({
      id: item.id,
      name: item.groupName,
    })),
    additionalDetailOptions: {
      noticeUnitOptions: Object.values(noticePeriod).map((item, index) => ({
        id: item,
        name: item,
      })),
      onboardingSeperationOptions: Object.values(seperationTypes).map((item, index) => ({
        id: item,
        name: item,
      })),
      bloodOptions: Object.values(bloodGroupTypes).map((item, index) => ({
        id: item,
        name: item,
      })),
      onboadringRefOptions: defaultOnboardingReferences?.map((item, index) => ({
        id: item.value.split('Display_Name:')[1].split('|')[0]?.trim(),
        name: item.value.split('Display_Name:')[1].split('|')[0]?.trim(),
      })),
      // Not provided by SHIVDEEP in api
      skillOptions: Object.values(skillRecordInstance).map((item) => ({
        id: item?.id,
        name: item?.name,
      })),
      skillLevelOptions: Object.values(skillLevel).map((item) => ({
        id: item,
        name: item,
      })),
      lifeEventOptions:
        eventType && !isEmpty(eventType)
          ? Object.values(eventType || {}).map((item) => ({
              id: item,
              name: item,
            }))
          : [],
      genderOptions:
        gender && !isEmpty(gender)
          ? Object.values(gender).map((item) => ({
              id: item,
              name: item,
            }))
          : [],
      relationOptions:
        dependentTypesMap && !isEmpty(dependentTypesMap)
          ? Object.values(dependentTypesMap).map((item) => ({
              id: item,
              name: item,
            }))
          : [],
    },
    bankAccountTypeOptions: defaultBankAccountTypes?.map((item, index) => ({
      id: item.id,
      name: item.value.split('Display_Name:')[1].split('|')[0],
    })),
    bankAccountCurrecyOptions: currencies.map((item) => ({
      id: item.id,
      name: item.name,
    })),
    bankTansactionTypeOptions: defaultTransactionTypes?.map((item, index) => ({
      id: item.id,
      name: item.value.split('Display_Name:')[1].split('|')[0],
    })),
    bankPurposeOptions: Object.values(accountPurpose).map((item, index) => ({
      id: item,
      name: item,
    })),
    payrollTaxJurisdictionOptions: Object.values(statutorySubRegionsIndia).map((item, index) => ({
      id: item,
      name: item,
    })),
    payrollWelfareJurisdictionOptions: Object.values(statutorySubRegionsIndia).map((item, index) => ({
      id: item,
      name: item,
    })),
    payrollEpfContributionOptions: Object.values(employeeEPFContribLevelsMap).map((item, index) => ({
      id: item,
      name: item,
    })),
    payrollPanValidatedByOptions: employeeList.map((item) => ({
      id: item.id,
      name: item.fullName,
    })),
    // 5 options below NOT PROVIDED BY SHIVDEEP YET
    payrollSalaryAccountOptions:
      isObject(companyAccntsMap) && !isEmpty(companyAccntsMap) && companyAccntsMap['Default Salary Account']
        ? companyAccntsMap['Default Salary Account'].map((item, index) => ({
            id: item.id,
            name: `${item.accountHolderName} ${item.accountNumber}`,
          }))
        : [],
    payrollReimbursementAccOptions:
      isObject(companyAccntsMap) && !isEmpty(companyAccntsMap) && companyAccntsMap['Expense Reimbursements Account']
        ? companyAccntsMap['Expense Reimbursements Account'].map((item, index) => ({
            id: item.id,
            name: `${item.accountHolderName} ${item.accountNumber}`,
          }))
        : [],
    extendedDef,
    isLoading: false,
    employeeId: employeeProfileInstance?.employeeID || '',
    employeeIdEnable: employeeProfileInstance?.enable || '',
  };
};

export const getEmpData = (response, authList) => {
  const {
    mode,
    employeeList,
    employeeGroups,
    selectedGroups,
    departmentsList,
    designationsList,
    locationsList,
    companyAccntsMap,
    currencies,
    customObjectInstance,
    customFieldValueMap,
    parentEntityId,
    extendedDef,
    educationRecords,
    skillRecords,
    pastEmployeeRecords,
    eventRecords,
    visaRecords,
    commentsMap,
    secUserInstance,
    company,
    employeeInstance,
    employeeProfileInstance,
    geoConfigInstance,
    officeAddressInstance,
    presentCommunicationAddressInstance,
    permanentHomeAddressInstance,
    bankAccounts,
    companiesList,
    roleMap,
    isEmployerEPFContribCustomizable,
    message,
    status,
  } = response.data;
  const userRoles = authList.reduce((accumulator, item) => {
    accumulator[item?.authority] = roleMap[item?.displayName];
    return accumulator;
  }, {});
  return {
    'User Identity': {
      userName: secUserInstance?.username || '',
      password: secUserInstance?.password || '',
      employeeId: employeeProfileInstance?.employeeID || '',
      enable: employeeProfileInstance?.enable || '',
      firstName: employeeInstance?.firstName || '',
      lastName: employeeInstance?.lastName || '',
      middleName: employeeInstance?.middleName || '',
      group: selectedGroups.length ? selectedGroups[0].id : '',
      accountExpired: secUserInstance?.accountExpired ? ['on'] : [],
      accountLocked: secUserInstance?.accountLocked ? ['on'] : [],
      accountEnabled: secUserInstance?.enabled ? ['on'] : [],
      passwordExpired: secUserInstance?.passwordExpired ? ['on'] : [],
    },
    'User Roles': userRoles,
    'Basic Profile': {
      gender: employeeInstance?.gender || '',
      maritalStatus: 'UnMarried',
      dateOfBirth: employeeProfileInstance?.dateOfBirth ? dayjs(employeeProfileInstance?.dateOfBirth) : '',
      dateOfJoining: employeeProfileInstance?.dateOfJoining ? dayjs(employeeProfileInstance?.dateOfJoining) : '',
      employmentStatus: employeeInstance?.employmentStatus || '',
      fatherName: employeeProfileInstance?.fathersName || '',
      motherName: employeeProfileInstance?.mothersName || '',
      spouceName: employeeProfileInstance?.spouseName || '',
      panNumber: employeeInstance?.panNumberIndia || '',
      aadharNumber: employeeInstance?.aadhaarNumberIndia || '',
      uan: employeeInstance?.uanNumberIndia || '',
      uhid: employeeInstance?.uhidAccountNumberIndia || '',
      epf: employeeInstance?.epfAccountNumberIndia || '',
      eps: employeeInstance?.epsAccountNumberIndia || '',
      esi: employeeInstance?.esiAccountNumberIndia || '',
      groupsAffiliations: '-',
      employmentType: employeeInstance?.employmentType || '',
      employmentActiveStatus: employeeInstance?.employmentStatus || '',
      designation: secUserInstance?.employee?.designation?.id || '',
      department: secUserInstance?.employee?.department?.id || '',
      supervisor: secUserInstance?.employee?.supervisor?.id || '',
      location: secUserInstance?.employee?.location?.id || '',
      profilePic: `${API_URL}/${employeeInstance?.profile?.profilePic || ''}`,
    },
    'Additional Details': {
      OnBoarding: {
        onboardingReference: employeeProfileInstance?.onboardingReference || '',
        bloodGroup: employeeProfileInstance?.bloodGroup || '',
        seperationType: employeeProfileInstance?.seperationType || '',
        onboardingUnit: '',
        dateOfLeaving: employeeProfileInstance?.dateOfLeaving ? dayjs(employeeProfileInstance?.dateOfLeaving) : '',
        noticePeriod: employeeProfileInstance?.noticePeriod || '',
        noticePeriodUnit: employeeProfileInstance?.noticePeriodTimeUnit || '',
        dateOfJoining: employeeProfileInstance?.confirmationDate
          ? dayjs(employeeProfileInstance?.confirmationDate)
          : '',
      },
      'Social Data': {
        skype: employeeProfileInstance?.employee?.skypeID || '',
        facebook: employeeProfileInstance?.employee?.facebookID || '',
        linkedin: employeeProfileInstance?.employee?.linkedinID || '',
        googlePlus: employeeProfileInstance?.employee?.googlePlusID || '',
        twitter: employeeProfileInstance?.employee?.twitterID || '',
        instagram: employeeProfileInstance?.employee?.instagramID || '',
      },
      'Education/Certification History': {
        education: educationRecords?.length
          ? educationRecords.map((education) => ({
              institutionName: education?.institutionName || '',
              certificationName: education?.certificationName || '',
              startDate: education?.startDate ? dayjs(education?.startDate) : '',
              endDate: education?.endDate ? dayjs(education?.endDate) : '',
              specialization: education?.specialization || '',
              registrationNumber: education?.registrationNumber || '',
              referenceName: education?.referenceEmail || '',
              referenceNumber: education?.referencePhoneNumber || '',
              referenceEmail: education?.referenceEmail || '',
              clientNotes: education?.comments || '',
              attachment: education?.documents || '',
            }))
          : [{}],
      },
      'Employment History': {
        employment: pastEmployeeRecords?.length
          ? pastEmployeeRecords.map((employer) => ({
              employerName: employer?.employerName || '',
              employerAddress: employer?.employerAddress || '',
              employmentCommenceDate: employer?.employmentCommenceDate ? dayjs(employer?.employmentCommenceDate) : '',
              employmentEndDate: employer?.employmentEndDate ? dayjs(employer?.employmentEndDate) : '',
              reasonForLeaving: employer?.reasonForLeaving || '',
              employeeId: employer?.employeeID || '',
              startDesignation: employer?.startDesignation || '',
              endDesignation: employer?.endDesignation || '',
              referenceContactName: employer?.referenceContactName || '',
              referenceContactDesignation: employer?.referenceContactDesignation || '',
              referenceContactPhone: employer?.referenceContactPhone || '',
              referenceContactEmail: employer?.referenceContactEmail || '',
            }))
          : [{}],
      },
      'Visa Details': visaRecords?.length
        ? {
            issuingCountry: visaRecords[0]?.issuingCountry || '',
            placeOfIssue: visaRecords[0]?.placeOfIssue || '',
            validFrom: visaRecords[0]?.validFrom ? dayjs(visaRecords[0]?.validFrom) : '',
            validTo: visaRecords[0]?.validTo ? dayjs(visaRecords[0]?.validTo) : '',
            issueDate: visaRecords[0]?.issueDate ? dayjs(visaRecords[0]?.issueDate) : '',
            referenceNumber: visaRecords[0]?.referenceNumber || '',
            visaStatus: visaRecords[0]?.status || '',
            comments: '', // not coming in api
            attachment: '',
            previousAttachment: visaRecords[0]?.documents?.length ? visaRecords[0]?.documents[0] : '',
            id: visaRecords[0]?.id,
          }
        : {
            issuingCountry: '',
            placeOfIssue: '',
            validFrom: '',
            validTo: '',
            issueDate: '',
            referenceNumber: '',
            visaStatus: '',
            comments: '',
            attachment: '',
          },
      'Skill Attainment Details': {
        skillDetails: skillRecords?.length
          ? skillRecords.map((record) => ({
              skill: record?.skill?.id || '',
              level: record?.level || '',
              comments: record?.comments || '',
              attachment: record?.documents?.[0] || '',
            }))
          : [
              {
                skill: '',
                level: '',
                comments: '',
                attachment: '',
              },
            ],
      },
      'Key Life Event Details': {
        keylifeEvent: eventRecords?.length
          ? eventRecords.map((record) => ({
              keyLifeEventDetails: record?.eventType || '',
              eventDate: record?.eventDate ? dayjs(record?.eventDate) : '',
              comments: record?.comments || '',
            }))
          : [
              {
                keyLifeEventDetails: '',
                eventDate: '',
                comments: '',
              },
            ],
      },
      'Dependents Details': {
        dependent: employeeProfileInstance?.dependents?.map((dependent) => ({
          gender: dependent?.contactInfo?.gender || '',
          firstName: dependent?.contactInfo?.firstName || '',
          lastName: dependent?.contactInfo?.lastName || '',
          relation: dependent?.profile?.relationship || '',
          dateOfBirth: dependent?.dateOfBirth ? dayjs(dependent?.dateOfBirth) : '',
          mobileNumber: dependent?.contactInfo?.mobileNumber || '',
          emailId: dependent?.contactInfo?.emailAddress || '',
          epfPerc: dependent?.epfNominationPercentage,
          epsPerc: dependent?.epsNominationPercentage,
          insurancePerc: dependent?.insuranceNominationPercentage,
          otherPerc: dependent?.othersNominationPercentage,
          gratuityPerc: dependent?.gratuityNominationPercentage,
          emergencyContact: dependent?.emergencyContactReference || '',
          bloodGroup: dependent?.bloodGroup || '',
        })),
      },
    },
    'Contact Details': {
      mobileNumber: secUserInstance?.employee?.mobileNumbers?.length ? secUserInstance?.employee?.mobileNumbers : [''],
      emailAddress: secUserInstance?.employee?.emailAddresses?.length
        ? secUserInstance?.employee?.emailAddresses
        : [''],
      name: '',
      relation: '',
      emergencyMobile: '',
      emergencyEmail: '',
      permanentLocation: employeeProfileInstance?.permanentHomeAddress?.locationName || '',
      permanentAddressLine1: employeeProfileInstance?.permanentHomeAddress?.addressLine1 || '',
      permanentAddressLine2: employeeProfileInstance?.permanentHomeAddress?.addressLine2 || '',
      permanentAddressLine3: employeeProfileInstance?.permanentHomeAddress?.addressLine3 || '',
      permanentCity: employeeProfileInstance?.permanentHomeAddress?.city || '',
      permanentState: employeeProfileInstance?.permanentHomeAddress?.state || '',
      permanentCountry: employeeProfileInstance?.permanentHomeAddress?.country || '',
      permanentPincode: employeeProfileInstance?.permanentHomeAddress?.pinOrZip || '',
      currentLocation: employeeProfileInstance?.presentCommunicationAddress?.locationName || '',
      currentAddressLine1: employeeProfileInstance?.presentCommunicationAddress?.addressLine1 || '',
      currentAddressLine2: employeeProfileInstance?.presentCommunicationAddress?.addressLine2 || '',
      currentAddressLine3: employeeProfileInstance?.presentCommunicationAddress?.addressLine3 || '',
      currentCity: employeeProfileInstance?.presentCommunicationAddress?.city || '',
      currentState: employeeProfileInstance?.presentCommunicationAddress?.state || '',
      currentCountry: employeeProfileInstance?.presentCommunicationAddress?.country || '',
      currentPincode: employeeProfileInstance?.presentCommunicationAddress?.pinOrZip || '',
      ofcLocationName: employeeProfileInstance?.officeAddress?.locationName || '',
      ofcAddressLine1: employeeProfileInstance?.officeAddress?.addressLine1 || '',
      ofcAddressLine2: employeeProfileInstance?.officeAddress?.addressLine2 || '',
      ofcAddressLine3: employeeProfileInstance?.officeAddress?.addressLine3 || '',
      ofcCity: employeeProfileInstance?.officeAddress?.city || '',
      ofcState: employeeProfileInstance?.officeAddress?.state || '',
      ofcCountry: employeeProfileInstance?.officeAddress?.country || '',
      ofcPincode: employeeProfileInstance?.officeAddress?.pinOrZip || '',
      sameAddress: [],
    },
    'Bank Account': {
      bank: bankAccounts?.length
        ? bankAccounts.map((bank) => ({
            accountNumber: bank?.accountNumber || '',
            accountName: bank?.accountHolderName || '',
            accountType: bank?.accountType || '',
            accountCurrecy: bank?.currencyId || '',
            purpose: bank?.accountPurpose || '',
            transactionType: bank?.preferredTransactionType || '',
            bankName: bank?.bankName || '',
            branchName: bank?.bankBranch || '',
            ifsc: bank?.ifsCode || '',
            swiftCode: bank?.swiftCode || '',
            locationName: bank?.bankAddress?.locationName || '',
            addressLine1: bank?.bankAddress?.addressLine1 || '',
            addressLine2: bank?.bankAddress?.addressLine2 || '',
            addressLine3: bank?.bankAddress?.addressLine3 || '',
            city: bank?.bankAddress?.city || '',
            state: bank?.bankAddress?.state || '',
            country: bank?.bankAddress?.country || '',
            pincode: bank?.bankAddress?.pinOrZip || '',
          }))
        : [{}],
    },
    'Payroll Setting': {
      isDisable: employeeInstance?.isDisabledIndia ? ['on'] : [],
      internationalWorker: employeeInstance?.isInternationalWorkerIndia ? ['on'] : [],
      eligibleForEPF: employeeInstance?.isEligibleForEPFIndia ? ['on'] : [],
      eligibleForESI: employeeInstance?.isEligibleForESIIndia ? ['on'] : [],
      isEligibleEPS: employeeInstance?.isEligibleForEPSIndia ? ['on'] : [],
      eligibleForEDLI: employeeInstance?.isEligibleForEDLIIndia ? ['on'] : [],
      taxApplicable: employeeInstance?.isProfessionalTaxApplicableIndia ? ['on'] : [],
      labourWelfareFund: employeeInstance?.isEligibleForLWFIndia ? ['on'] : [],
      locatedInMetro: employeeInstance?.isMetroLocationIndia ? ['on'] : [],
      joinedEPFPrior: employeeInstance?.hasJoinedEPFBeforeCutOff ? ['on'] : [],
      schoolChildren: employeeInstance?.numberOfSchoolGoingChildren || '',
      hostelChildren: employeeInstance?.numberOfChildrenInHostel || '',
      professionalTaxJurisdiction: employeeInstance?.professionalTaxJurisdictionIndia || '',
      labourWelfareFundJurisdiction: employeeInstance?.lwfJurisdictionIndia || '',
      esiDispensary: employeeInstance?.esiDispensaryIndia || '',
      dateOfEnrollingepf: employeeInstance?.dateOfJoiningEPFIndia ? dayjs(employeeInstance?.dateOfJoiningEPFIndia) : '',
      epfContributionLevel: employeeInstance?.employeeEPFContributionLevelIndia || '',
      dateOfLeavingepf: employeeInstance?.dateOfLeavingEPFIndia ? dayjs(employeeInstance?.dateOfLeavingEPFIndia) : '',
      isPanValid: employeeInstance?.isPanIndiaValid ? ['on'] : [],
      panValidatedWithItDept: employeeInstance?.isPanIndiaValidated ? ['on'] : [],
      panValidatedBy: employeeInstance?.panIndiaValidatedBy?.id || '',
      panValidatedDate: employeeInstance?.panIndiaValidationDate ? dayjs(employeeInstance?.panIndiaValidationDate) : '',
      isEmpFlatTDSTaxable: employeeInstance?.isFlatTDSApplicable ? ['on'] : [],
      rateOfFlatTDS: employeeInstance?.flatTDSRate || '',
      flatTDSComputationBasis: employeeInstance?.flatTDSCompBasis || '',
      companySalaryAccount: employeeInstance?.companySalaryAccount || '',
      expenseReimbursementAcc: employeeInstance?.companyExpReimburseAccount || '',
    },
    'Notification Setting': {
      paySlipPublication: employeeInstance?.notifyOnSalSlipPubl ? ['on'] : [],
      leaveRequest: employeeInstance?.notifyOnLeavReqAction ? ['on'] : [],
      expensesReport: employeeInstance?.notifyOnExpRepAction ? ['on'] : [],
      exceptionOccurance: employeeInstance?.notifyOnAttnExcp ? ['on'] : [],
    },
    isLoading: false,
  };
};
