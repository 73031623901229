import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { hasPermission } from 'utils/permissions';

const getMenuItems = (navigate) => {
  return [
    {
      id: 1,
      label: 'New Employee',
      onClick: () => {
        navigate('/company/employee/add');
      },
      permissions: [],
    },
    {
      id: 2,
      label: 'List Employees',
      onClick: () => {
        navigate('/company/employee/employees-list');
      },
      permissions: [],
    },
    {
      id: 3,
      label: 'Search Employees',
      onClick: () => {
        navigate('/company/employee/employees-list/advanced-search');
      },
      permissions: [],
    },
    { id: 4, label: 'Org Hierarchy', onClick: () => {}, permissions: [] },
  ];
};

const useMenuItems = () => {
  const { user } = useSelector((state) => state?.session);
  const navigate = useNavigate();

  const menuItems = getMenuItems(navigate);
  // const filteredActionItem = menuItems.filter((action) => hasPermission(user.authorities, action.permissions));

  return [menuItems];
};

export default useMenuItems;
