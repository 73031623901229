import { createSlice } from '@reduxjs/toolkit';
import {
  fetchEmployeeExitsList,
  fetchEmployeeExitDetails,
  fetchInitiateEmpExit,
  fetchActionRequestDetails,
  fetchInitiateSpecificEmpExit,
} from 'store/thunks/company/separations';
import { fetchViewOffBoardingCheckList } from '../../../thunks/company/separations';

const separations = createSlice({
  name: 'separations',
  initialState: {
    selectedEmployee: null,
    selectedEmployees: [],
    employees: {
      count: 0,
      data: [],
    },
    employeeDetails: [],
    employeeProfileInstance: [],
    commentsMapList: [],
    commentsMap: [],
    initiateExitEmpList: [],
    seperationTypes: [],
    employeeSeparationRequest: '',
    employeeActionRequestDetails: [],
    employeeActionRequestProfileInstance: [],
    currentSupervisor: false,
    employeeActionResignationStatesMap: {},
    offBoardingCheckList: {},
    empList: [],
    employeeSeparation: {
      employeeInstance: {},
      employeeProfileInstance: {},
      separationTypes: {},
      empList: [],
    },
  },
  reducers: {
    updateSelectedEmployee(state, action) {
      state.selectedEmployee = action.payload;
    },
    updateSelectedEmployees(state, action) {
      const { isChecked, rowId } = action.payload;

      if (isChecked) {
        state.selectedEmployees.push(rowId);
        return;
      }
      state.selectedEmployees = state.selectedEmployees.filter((selectedRow) => selectedRow !== rowId);
    },
    updateAllSelectedEmployees(state, action) {
      const { isChecked } = action.payload;
      state.selectedEmployees = [];
      if (isChecked) {
        state.employees.data.map((row) => state.selectedEmployees.push(row.id));
        return;
      }
      state.selectedEmployees = [];
    },
    updateEmployeeSeparationRequest(state, action) {
      state.employeeSeparationRequest = action.payload;
    },
  },

  extraReducers(builder) {
    builder.addCase(fetchEmployeeExitsList.fulfilled, (state, action) => {
      const { finalDataSet = [], employeeExitInstanceCount, empList = [] } = action.payload;
      state.employees.data = finalDataSet;
      state.employees.count = employeeExitInstanceCount;
      state.empList = empList;
    });
    builder.addCase(fetchInitiateSpecificEmpExit.fulfilled, (state, action) => {
      const {
        employeeInstance = {},
        employeeProfileInstance = {},
        separationTypes = {},
        empList = [],
      } = action.payload;
      state.employeeSeparation.employeeInstance = employeeInstance;
      state.employeeSeparation.employeeProfileInstance = employeeProfileInstance;
      state.employeeSeparation.separationTypes = separationTypes;
      state.employeeSeparation.empList = empList;
    });
    builder.addCase(fetchEmployeeExitDetails.fulfilled, (state, action) => {
      const {
        employeeExitInstance = [],
        employeeProfileInstance = [],
        commentsMapList = [],
        commentsMap = [],
      } = action.payload;
      state.employeeDetails = employeeExitInstance;
      state.employeeProfileInstance = employeeProfileInstance;
      state.commentsMapList = commentsMapList;
      state.commentsMap = commentsMap;
    });
    builder.addCase(fetchViewOffBoardingCheckList.fulfilled, (state, action) => {
      state.offBoardingCheckList = action.payload;
    });
    builder.addCase(fetchInitiateEmpExit.fulfilled, (state, action) => {
      const { empList = [], seperationTypes = {}, resignationStatesMap = {} } = action.payload;
      state.initiateExitEmpList = empList;
      state.seperationTypes = seperationTypes;
      state.employeeActionResignationStatesMap = resignationStatesMap;
    });
    builder.addCase(fetchActionRequestDetails.fulfilled, (state, action) => {
      const {
        employeeExitInstance = [],
        employeeProfileInstance = [],
        isCurrActorSupervsior = false,
        resignationStatesMap = {},
      } = action.payload;
      state.employeeActionRequestDetails = employeeExitInstance;
      state.employeeActionRequestProfileInstance = employeeProfileInstance;
      state.currentSupervisor = isCurrActorSupervsior;
      state.employeeActionResignationStatesMap = resignationStatesMap;
    });
  },
});

export const {
  updateSelectedEmployee,
  updateSelectedEmployees,
  updateAllSelectedEmployees,
  updateEmployeeSeparationRequest,
} = separations.actions;

export default separations.reducer;
