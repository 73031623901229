export const policyListStyle = {
  wrapper: {
    border: '0.125rem solid',
    borderColor: 'neutral.300',
    marginTop: '1.5rem',
    maxHeight: 'calc(100vh - 10rem)',
    height: 'calc(100vh - 10rem)',
    marginBottom: '3rem',
    width: '100%',
    overflowY: 'auto',
    position: 'relative',
  },
};
