import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Divider, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Button from 'components/Atoms/Button';
import InputField from 'components/Atoms/InputField';
import Typographys from 'components/Atoms/Typography';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { FieldArray, getIn, Formik } from 'formik';
import DatePicker from 'components/Atoms/Datepicker';
import Attachments from 'components/Atoms/Attachments';
import * as yup from 'yup';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { genericMobileRegex, genericEmailRegex } from 'utils/commonUtils';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';

import { onBoardingStyle } from '../../../style';

const EmploymentHistory = ({ handleCancel, propData, label, handleSubmit }) => {
  const defaultObj = {
    employerName: '',
    employerAddress: '',
    employmentCommenceDate: '',
    employmentEndDate: '',
    reasonForLeaving: '',
    employeeId: '',
    startDesignation: '',
    endDesignation: '',
    referenceContactName: '',
    referenceContactDesignation: '',
    referenceContactPhone: '',
    referenceContactEmail: '',
    attachments: '',
    isNew: true,
  };
  const defaultValues = propData.allTabsData[label];
  const employmentValidationSchema = yup.object().shape({
    employment: yup.array().of(
      yup.object().shape({
        employerName: yup
          .string()
          .matches(/^[A-Za-z\s]+$/, '')
          .required('Required'),
        employmentCommenceDate: yup.string().required('Required'),
        employmentEndDate: yup.string().required('Required'),
        referenceContactPhone: yup
          .string()
          .matches(genericMobileRegex, 'Invalid format, Please enter valid mobile number.'),
        referenceContactEmail: yup.string().matches(genericEmailRegex, 'Invalid format, Please enter valid email ID.'),
      }),
    ),
  });

  return (
    <Box mt={1.5}>
      <Formik
        initialValues={
          defaultValues.employment.length > 0 && Object.keys(defaultValues.employment[0]).length
            ? defaultValues
            : { employment: [{ key: 1 }] }
        }
        onSubmit={handleSubmit}
        validateOnMount
        validationSchema={employmentValidationSchema}
      >
        {(formik) => (
          <Form formik={formik} handleCancel={handleCancel} propData={propData} label={label} defaultObj={defaultObj} />
        )}
      </Formik>
    </Box>
  );
};

const Form = ({ formik, handleCancel, propData, label, defaultObj }) => {
  const [keyCount, setKeyCount] = useState(2);
  useEffect(() => {
    propData.updateFormData(formik.values, label);
  }, [formik.values, label]);

  const handleDateChange = (date, setFieldValue, fieldName) => {
    setFieldValue(fieldName, date);
  };

  const theme = useTheme();

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 59rem)' }}>
          <Box sx={{ mb: 2 }}>
            <Typographys variant="h5" color="neutral.800">
              Employment History
            </Typographys>
          </Box>

          <FieldArray name="employment">
            {({ push, remove }) => (
              <Box>
                {formik?.values?.employment.map((e, index) => {
                  return (
                    <Accordion key={e.key} disableGutters elevation={0} square sx={onBoardingStyle.accordionWrapper}>
                      <AccordionSummary
                        aria-controls="employment-content"
                        id="employment-header"
                        expandIcon={<KeyboardArrowDownSharpIcon sx={{ fontSize: '3rem', color: 'neutral.800' }} />}
                      >
                        <Box sx={onBoardingStyle.deleteContainer}>
                          <Typography variant="subtitle2" color="neutral.800">
                            Record {index + 1}
                          </Typography>
                          {formik?.values?.employment.length > 1 && (
                            <DeleteOutlineSharpIcon sx={{ color: 'neutral.600' }} onClick={() => remove(index)} />
                          )}
                        </Box>
                      </AccordionSummary>

                      <AccordionDetails>
                        <Grid container rowSpacing={2} columnSpacing={9} pt={0} sx={onBoardingStyle.formContainer}>
                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `employment[${index}].employerName`) &&
                                  getIn(formik.errors, `employment[${index}].employerName`),
                              )}
                              helperText={
                                getIn(formik.touched, `employment[${index}].employerName`) &&
                                getIn(formik.errors, `employment[${index}].employerName`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.employerName}
                              name={`employment[${index}].employerName`}
                              id={`employment[${index}].employerName`}
                              label="Employer Name*"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `employment[${index}].employerAddress`) &&
                                  getIn(formik.errors, `employment[${index}].employerAddress`),
                              )}
                              helperText={
                                getIn(formik.touched, `employment[${index}].employerAddress`) &&
                                getIn(formik.errors, `employment[${index}].employerAddress`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.employerAddress}
                              name={`employment[${index}].employerAddress`}
                              id={`employment[${index}].employerAddress`}
                              label="Employer Address"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <DatePicker
                              error={Boolean(
                                getIn(formik.touched, `employment[${index}].employmentCommenceDate`) &&
                                  getIn(formik.errors, `employment[${index}].employmentCommenceDate`),
                              )}
                              helperText={
                                getIn(formik.touched, `employment[${index}].employmentCommenceDate`) &&
                                getIn(formik.errors, `employment[${index}].employmentCommenceDate`)
                              }
                              onChange={(date) =>
                                handleDateChange(
                                  date,
                                  formik.setFieldValue,
                                  `employment[${index}].employmentCommenceDate`,
                                )
                              }
                              onBlur={formik.handleBlur}
                              value={e?.employmentCommenceDate}
                              maxDate={e?.employmentEndDate}
                              name={`employment[${index}].employmentCommenceDate`}
                              id={`employment[${index}].employmentCommenceDate`}
                              label="Employment Commence date*"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <DatePicker
                              error={Boolean(
                                getIn(formik.touched, `employment[${index}].employmentEndDate`) &&
                                  getIn(formik.errors, `employment[${index}].employmentEndDate`),
                              )}
                              helperText={
                                getIn(formik.touched, `employment[${index}].employmentEndDate`) &&
                                getIn(formik.errors, `employment[${index}].employmentEndDate`)
                              }
                              onChange={(date) =>
                                handleDateChange(date, formik.setFieldValue, `employment[${index}].employmentEndDate`)
                              }
                              onBlur={formik.handleBlur}
                              value={e?.employmentEndDate}
                              name={`employment[${index}].employmentEndDate`}
                              id={`employment[${index}].employmentEndDate`}
                              label="Employment End Date*"
                              minDate={e?.employmentCommenceDate}
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `employment[${index}].reasonForLeaving`) &&
                                  getIn(formik.errors, `employment[${index}].reasonForLeaving`),
                              )}
                              helperText={
                                getIn(formik.touched, `employment[${index}].reasonForLeaving`) &&
                                getIn(formik.errors, `employment[${index}].reasonForLeaving`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.reasonForLeaving}
                              name={`employment[${index}].reasonForLeaving`}
                              id={`employment[${index}].reasonForLeaving`}
                              label="Reason For Leaving"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `employment[${index}].employeeId`) &&
                                  getIn(formik.errors, `employment[${index}].employeeId`),
                              )}
                              helperText={
                                getIn(formik.touched, `employment[${index}].employeeId`) &&
                                getIn(formik.errors, `employment[${index}].employeeId`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.employeeId}
                              name={`employment[${index}].employeeId`}
                              id={`employment[${index}].employeeId`}
                              label="Employee Id"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `employment[${index}].startDesignation`) &&
                                  getIn(formik.errors, `employment[${index}].startDesignation`),
                              )}
                              helperText={
                                getIn(formik.touched, `employment[${index}].startDesignation`) &&
                                getIn(formik.errors, `employment[${index}].startDesignation`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.startDesignation}
                              name={`employment[${index}].startDesignation`}
                              id={`employment[${index}].startDesignation`}
                              label="Start Designation"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `employment[${index}].endDesignation`) &&
                                  getIn(formik.errors, `employment[${index}].endDesignation`),
                              )}
                              helperText={
                                getIn(formik.touched, `employment[${index}].endDesignation`) &&
                                getIn(formik.errors, `employment[${index}].endDesignation`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.endDesignation}
                              name={`employment[${index}].endDesignation`}
                              id={`employment[${index}].endDesignation`}
                              label="End Designation"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `employment[${index}].referenceContactName`) &&
                                  getIn(formik.errors, `employment[${index}].referenceContactName`),
                              )}
                              helperText={
                                getIn(formik.touched, `employment[${index}].referenceContactName`) &&
                                getIn(formik.errors, `employment[${index}].referenceContactName`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.referenceContactName}
                              name={`employment[${index}].referenceContactName`}
                              id={`employment[${index}].referenceContactName`}
                              label="Reference Contact Name"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `employment[${index}].referenceContactDesignation`) &&
                                  getIn(formik.errors, `employment[${index}].referenceContactDesignation`),
                              )}
                              helperText={
                                getIn(formik.touched, `employment[${index}].referenceContactDesignation`) &&
                                getIn(formik.errors, `employment[${index}].referenceContactDesignation`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.referenceContactDesignation}
                              name={`employment[${index}].referenceContactDesignation`}
                              id={`employment[${index}].referenceContactDesignation`}
                              label="Reference Contact Designation"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `employment[${index}].referenceContactPhone`) &&
                                  getIn(formik.errors, `employment[${index}].referenceContactPhone`),
                              )}
                              helperText={
                                getIn(formik.touched, `employment[${index}].referenceContactPhone`) &&
                                getIn(formik.errors, `employment[${index}].referenceContactPhone`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.referenceContactPhone}
                              name={`employment[${index}].referenceContactPhone`}
                              id={`employment[${index}].referenceContactPhone`}
                              label="Reference Contact Phone"
                              type="number"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `employment[${index}].referenceContactEmail`) &&
                                  getIn(formik.errors, `employment[${index}].referenceContactEmail`),
                              )}
                              helperText={
                                getIn(formik.touched, `employment[${index}].referenceContactEmail`) &&
                                getIn(formik.errors, `employment[${index}].referenceContactEmail`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.referenceContactEmail}
                              name={`employment[${index}].referenceContactEmail`}
                              id={`employment[${index}].referenceContactEmail`}
                              label="Reference Contact Email"
                              type="email"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <Attachments
                              isDeleteIcon
                              handleChange={(event) => {
                                formik.setFieldValue(`employment[${index}].attachments`, event.target.files[0]);
                              }}
                              name={`employment[${index}].attachments`}
                              id={`employment[${index}].attachments`}
                              value={e.attachments}
                              handleDelete={() => {
                                formik.setFieldValue(`employment[${index}].attachments`, '');
                              }}
                            />
                            <Grid item xs={12} md={8} mt={2}>
                              {e?.attachments?.length > 0 && (
                                <Typographys variant="caption" color="neutral.600">
                                  Previously Associated Attachment: {e?.attachments?.[0]?.fileName || ''}
                                </Typographys>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
                <Box sx={{ mt: 3 }}>
                  <Button
                    size="medium"
                    variant="outlined"
                    type="button"
                    startIcon={<AddOutlinedIcon />}
                    onClick={() => {
                      push({ ...defaultObj, key: keyCount });
                      setKeyCount(keyCount + 1);
                    }}
                  >
                    Add Another
                  </Button>
                </Box>
              </Box>
            )}
          </FieldArray>
        </Box>
      </PerfectScrollbar>

      <Box sx={onBoardingStyle.btnContainer}>
        <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

        <Box sx={onBoardingStyle.submitWrapper}>
          <Button
            size="large"
            color="info"
            variant="outlined"
            type="button"
            onClick={() => {
              formik.resetForm();
              handleCancel();
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={propData.propData.loading}
            color="primary"
            size="large"
            type="submit"
            variant="contained"
          >
            Save & Continue
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

Form.defaultProps = {
  handleCancel: () => {},
  label: '',
  propData: {},
  formik: {},
  defaultObj: {},
};

Form.propTypes = {
  propData: PropTypes.shape({
    updateFormData: PropTypes.func,
    allTabsData: PropTypes.shape({
      'Employment History': PropTypes.shape({
        employment: PropTypes.shape([]),
      }),
    }),
    allowEdit: PropTypes.bool,
    propData: PropTypes.shape({
      loading: PropTypes.bool,
    }),
  }),
  handleCancel: PropTypes.func,

  label: PropTypes.string,
  formik: PropTypes.shape({
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    values: PropTypes.shape({
      employment: PropTypes.shape([]),
    }),
    resetForm: PropTypes.func,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    setFieldValue: PropTypes.func,
  }),
  defaultObj: PropTypes.shape({}),
};

EmploymentHistory.defaultProps = {
  handleSubmit: () => {},
  handleCancel: () => {},
  label: 'Employment History',
  propData: {
    allTabsData: {
      'Employment History': {
        employment: [
          {
            employerName: '',
            employerAddress: '',
            employmentCommenceDate: '',
            employmentEndDate: '',
            reasonForLeaving: '',
            employeeId: '',
            startDesignation: '',
            endDesignation: '',
            referenceContactName: '',
            referenceContactDesignation: '',
            referenceContactPhone: '',
            referenceContactEmail: '',
            attachments: '',
          },
        ],
      },
    },
    updateFormData: () => {},
  },
};

EmploymentHistory.propTypes = {
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  propData: PropTypes.shape({
    allTabsData: PropTypes.shape({}),
    updateFormData: PropTypes.func,
  }),
  label: PropTypes.string,
};

export default EmploymentHistory;
