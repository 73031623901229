import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TableExtended from 'components/Molecules/TableExtended';
import { Box, Divider, useTheme } from '@mui/material';
import Chips from 'components/Molecules/Chip';
import ChooseFile from 'components/Atoms/ChooseFile';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Button from 'components/Atoms/Button';
import { statusColor } from 'utils/statusColor';

import { onBoardingStyle } from '../../style';

const Attachments = ({
  attachmentEditLoading,
  attachmentEditDataRow,
  attachmentEditDataColumns,
  attachmentEditPage,
  attachmentEditSortDirection,
  attachmentEditSortBy,
  attachmentEditCount,
  attachmentEditOnPageChange,
  attachmentEditOnSortChange,
  attachmentEditHandleSubmit,
  attachmentEditHandleCancel,
  attachmentEditHandleDocument,
}) => {
  const [rows, setRows] = useState(attachmentEditDataRow);

  const theme = useTheme();

  return (
    <Box sx={onBoardingStyle.attachmentTableWrapper} px={3}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 37rem)' }}>
          <TableExtended
            rows={rows.map((i, index) => {
              return {
                ...i,
                newDocument: (
                  <ChooseFile
                    value={i.value}
                    name={i.slNo}
                    id={i.slNo}
                    handleChange={(e) => {
                      const rowCopy = [...rows];
                      rowCopy[index].value = e.target.files[0];
                      setRows(rowCopy);
                    }}
                  />
                ),
                approvalStatus: (
                  <Chips label={i.approvalStatus} isShowIcon={false} color={statusColor(i.approvalStatus)} />
                ),
                attachment: (
                  <Button
                    variant="text"
                    size="small"
                    color="secondary"
                    onClick={() => {
                      attachmentEditHandleDocument(i);
                    }}
                  >
                    {i.attachment}
                  </Button>
                ),
              };
            })}
            loading={attachmentEditLoading}
            columns={attachmentEditDataColumns}
            showActionList={false}
            showCheckbox={false}
            count={attachmentEditCount}
            sortBy={attachmentEditSortBy}
            sortDirection={attachmentEditSortDirection}
            showPagination
            page={attachmentEditPage}
            onPageChange={attachmentEditOnPageChange}
            onSortChange={attachmentEditOnSortChange}
          />
        </Box>
      </PerfectScrollbar>

      <Box sx={onBoardingStyle.btnContainer}>
        <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

        <Box sx={onBoardingStyle.submitWrapper}>
          <Button
            size="large"
            color="info"
            variant="outlined"
            type="button"
            onClick={() => {
              attachmentEditHandleCancel();
            }}
          >
            Cancel
          </Button>

          <Button
            size="large"
            type="submit"
            onClick={() => {
              attachmentEditHandleSubmit(rows);
            }}
            isDisable={false}
          >
            Save & Continue
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

Attachments.defaultProps = {
  attachmentEditLoading: false,
  attachmentEditDataRow: [],
  attachmentEditDataColumns: [],
  attachmentEditSortBy: 'id',
  attachmentEditSortDirection: 'asc',
  attachmentEditCount: 10,
  attachmentEditPage: '',
  attachmentEditOnPageChange: () => {},
  attachmentEditOnSortChange: () => {},
  attachmentEditHandleSubmit: () => {},
  attachmentEditHandleCancel: () => {},
  attachmentEditHandleDocument: () => {},
};

Attachments.propTypes = {
  attachmentEditLoading: PropTypes.bool,
  attachmentEditDataRow: PropTypes.arrayOf,
  attachmentEditDataColumns: PropTypes.arrayOf,
  attachmentEditSortBy: PropTypes.string,
  attachmentEditSortDirection: PropTypes.string,
  attachmentEditCount: PropTypes.number,
  attachmentEditPage: PropTypes.number,
  attachmentEditOnPageChange: PropTypes.func,
  attachmentEditOnSortChange: PropTypes.func,
  attachmentEditHandleSubmit: PropTypes.func,
  attachmentEditHandleCancel: PropTypes.func,
  attachmentEditHandleDocument: PropTypes.func,
};
export default Attachments;
