import React, { useEffect } from 'react';
import { Box, Grid, Divider, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import Button from 'components/Atoms/Button';
import Typographys from 'components/Atoms/Typography';
import { useFormik } from 'formik';
import Checkbox from 'components/Atoms/Checkbox';
import { addEmpStyle } from '../style';

const NotificationSetting = ({ tabHeading, handleSubmit, handleCancel, propData, label }) => {
  const defaultValues = propData.allTabsData['Notification Setting'];

  const formik = useFormik({
    initialValues: Object.keys(defaultValues).length
      ? defaultValues
      : {
          paySlipPublication: ['on'],
          leaveRequest: ['on'],
          expensesReport: ['on'],
          exceptionOccurance: ['on'],
        },

    validateOnMount: true,
    onSubmit: handleSubmit,
  });
  useEffect(() => {
    propData.updateFormData(formik.values, label);
  }, [formik.values, label]);

  const theme = useTheme();
  return (
    <Box sx={{ p: '2rem 3rem' }}>
      <Typographys variant="h5" color="neutral.800">
        {tabHeading}
      </Typographys>

      <Box mt={2} component="form" onSubmit={formik.handleSubmit}>
        <Grid container rowSpacing={2} columnSpacing={9} sx={addEmpStyle.formContainer}>
          <Grid item xs={12} md={12}>
            <Checkbox
              onChange={(e) => {
                formik.handleChange(e);
              }}
              key={formik.values.paySlipPublication}
              checked={formik.values.paySlipPublication?.includes('on')}
              color="secondary"
              name="paySlipPublication"
              label="Notify Me upon Pay Slip Publication"
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <Checkbox
              onChange={(e) => {
                formik.handleChange(e);
              }}
              key={formik.values.leaveRequest}
              checked={formik.values.leaveRequest?.includes('on')}
              color="secondary"
              name="leaveRequest"
              label="Notify Me upon Leave Request Updates"
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <Checkbox
              onChange={(e) => {
                formik.handleChange(e);
              }}
              key={formik.values.expensesReport}
              checked={formik.values.expensesReport?.includes('on')}
              color="secondary"
              name="expensesReport"
              label="Notify Me upon Expenses Report Updates"
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <Checkbox
              onChange={(e) => {
                formik.handleChange(e);
              }}
              key={formik.values.exceptionOccurance}
              checked={formik.values.exceptionOccurance?.includes('on')}
              color="secondary"
              name="exceptionOccurance"
              label="Notify Me upon Attendance Exception Occurance"
            />
          </Grid>
        </Grid>

        <Box sx={addEmpStyle.btnContainer}>
          <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

          <Box sx={addEmpStyle.submitWrapper}>
            <Button
              size="large"
              color="info"
              variant="outlined"
              type="cancel"
              onClick={() => {
                formik.resetForm();
                handleCancel();
              }}
            >
              Cancel
            </Button>

            <Button size="large" type="submit" isDisable={false}>
              {propData.allowEdit ? 'Update' : 'Next'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

NotificationSetting.defaultProps = {
  tabHeading: 'Notification Setting',
  propData: {},
  handleSubmit: () => {},
  handleCancel: () => {},
  label: '',
};

NotificationSetting.propTypes = {
  tabHeading: PropTypes.string,
  propData: PropTypes.shape({
    updateFormData: PropTypes.func,
    allTabsData: PropTypes.shape({
      'Notification Setting': PropTypes.shape({}),
    }),
    allowEdit: PropTypes.bool,
  }),
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  label: PropTypes.string,
};

export default NotificationSetting;
