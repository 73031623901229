import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Link } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import IconWithTitle from 'components/Atoms/IconWithTitle';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Avatars from 'components/Molecules/Avatar';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import TitleWithText from 'components/Atoms/TitleWithText';
import { profileCardStyle } from './style';

const ProfileCard = ({ profileDetail, contactInfo, employmentInfo }) => {
  return (
    <Box sx={profileCardStyle.wrapper}>
      <Box>
        <Avatars avatarSrc={profileDetail.avatarSrc} sx={profileCardStyle.avatar} />
      </Box>

      <Box sx={profileCardStyle.actionWrapper}>
        <Box>
          <Typographys variant="h4">{profileDetail.name}</Typographys>
        </Box>

        <Grid container sx={profileCardStyle.contactInfo}>
          <Grid item sm={6} md="auto">
            <IconWithTitle iconElement={<LocationOnIcon fontSize="inherit" />} title={contactInfo.location} />
          </Grid>

          <Grid item sm={6} md="auto">
            <Link sx={{ textDecoration: 'none' }} href={`mailto:${contactInfo.email}`}>
              <IconWithTitle iconElement={<EmailIcon fontSize="inherit" />} title={contactInfo.email} />
            </Link>
          </Grid>

          <Grid item sm={6} md="auto">
            <Link sx={{ textDecoration: 'none' }} href={`tel:${contactInfo.mobile}`}>
              <IconWithTitle iconElement={<CallIcon fontSize="inherit" />} title={contactInfo.mobile} />
            </Link>
          </Grid>

          <Grid item sm={6} md="auto">
            <IconWithTitle iconElement={<BusinessCenterIcon fontSize="inherit" />} title={contactInfo.phone} />
          </Grid>
        </Grid>

        <Grid container sx={profileCardStyle.employmentInfo}>
          <Grid item sm={4} md="auto">
            <TitleWithText padding="0" title="Designation" text={employmentInfo.designation} />
          </Grid>

          <Grid item sm={4} md="auto">
            <TitleWithText padding="0" title="Department" text={employmentInfo.department} />
          </Grid>

          <Grid item sm={4} md="auto">
            <TitleWithText padding="0" title="Supervisor" text={employmentInfo.supervisor} />
          </Grid>

          <Grid item sm={4} md="auto">
            <TitleWithText padding="0" title="Employee ID" text={employmentInfo.employeeID} />
          </Grid>

          <Grid item sm={4} md="auto">
            <TitleWithText padding="0" title="Employment type" text={employmentInfo.employmentType} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

ProfileCard.defaultProps = {
  profileDetail: {
    name: '',
    avatarSrc: '',
    progressBarValue: '',
  },

  contactInfo: {
    location: '',
    email: '',
    mobile: '',
    phone: '',
  },

  employmentInfo: {
    designation: '',
    department: '',
    supervisor: '',
    employeeID: '',
    employmentType: '',
  },
};

ProfileCard.propTypes = {
  profileDetail: PropTypes.shape({
    name: PropTypes.string,
    avatarSrc: PropTypes.string,
    progressBarValue: PropTypes.number,
    location: PropTypes.string,
    email: PropTypes.string,
    mobile: PropTypes.string,
    phone: PropTypes.string,
    designation: PropTypes.string,
    department: PropTypes.string,
    supervisor: PropTypes.string,
    employeeID: PropTypes.string,
    employmentType: PropTypes.string,
  }),

  contactInfo: PropTypes.shape({
    location: PropTypes.string,
    email: PropTypes.string,
    mobile: PropTypes.string,
    phone: PropTypes.string,
  }),

  employmentInfo: PropTypes.shape({
    designation: PropTypes.string,
    department: PropTypes.string,
    supervisor: PropTypes.string,
    employeeID: PropTypes.string,
    employmentType: PropTypes.string,
  }),
};

export default ProfileCard;
