import { colors } from '../../../../colors';

export const palette = {
  common: {
    mainBlue: colors.mariner,
    white: colors.white,
  },
  primary: {
    main: colors.mariner, // #346BD1
    light: colors.selago, // #F3F8FE
    dark: colors.royalBlue, // #2F70E8
  },
  colors,
};
