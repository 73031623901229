import React, { useEffect } from 'react';
import { Box, Grid, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import Button from 'components/Atoms/Button';
import Typographys from 'components/Atoms/Typography';
import InputField from 'components/Atoms/InputField';
import Dropdown from 'components/Molecules/Dropdown';
import Checkbox from 'components/Atoms/Checkbox';
import DatePicker from 'components/Atoms/Datepicker';
import ToolTip from 'components/Atoms/ToolTip';
import { useFormik } from 'formik';
import { useTheme } from '@mui/material/styles';
import * as yup from 'yup';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { addEmpStyle } from '../style';

const PayrollSetting = ({
  handleSubmit,
  handleCancel,
  taxJurisdictionOptions,
  welfareJurisdictionOptions,
  epfContributionOptions,
  employeeContributionOptions,
  panValidatedByOptions,
  salaryAccountOptions,
  reimbursementAccOptions,
  propData,
  label,
}) => {
  const defaultValues = propData.allTabsData['Payroll Setting'];

  const formik = useFormik({
    initialValues: Object.keys(defaultValues).length
      ? defaultValues
      : {
          isDisable: [],
          schoolChildren: '',
          hostelChildren: '',
          internationalWorker: [],
          eligibleForEPF: ['on'],
          eligibleForESI: ['on'],
          eligibleForEDLI: ['on'],
          taxApplicable: ['on'],
          labourWelfareFund: ['on'],
          locatedInMetro: ['on'],
          joinedEPFPrior: ['on'],
          professionalTaxJurisdiction: '',
          labourWelfareFundJurisdiction: '',
          esiDispensary: '',
          dateOfEnrollingepf: '',
          dateOfLeavingepf: '',
          epfContributionLevel: '',
          epfContribution: '',
          isPanValid: ['on'],
          panValidatedBy: '',
          panValidatedWithItDept: [],
          panValidatedDate: '',
          isEmpFlatTDSTaxable: [],
          rateOfFlatTDS: '',
          flatTDSComputationBasis: '',
          companySalaryAccount: '',
          expenseReimbursementAcc: '',
        },

    validateOnMount: true,
    onSubmit: handleSubmit,
  });

  const handleDateChange = (date, fieldName) => {
    formik.setFieldValue(fieldName, date);
  };

  const theme = useTheme();

  useEffect(() => {
    propData.updateFormData(formik.values, label);
  }, [formik.values, label]);

  return (
    <Box mt={2} sx={{ p: '2rem 3rem' }} component="form" onSubmit={formik.handleSubmit}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 44.3rem)' }}>
          <Grid container rowSpacing={2} columnSpacing={9} sx={addEmpStyle.formContainer}>
            <Grid item xs={12} md={4}>
              <Box mb="1.25rem">
                <Checkbox
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  key={formik.values.isDisable}
                  checked={formik.values.isDisable?.includes('on')}
                  color="secondary"
                  name="isDisable"
                  label="Is Disable"
                />
              </Box>

              <InputField
                error={Boolean(formik.touched.schoolChildren && formik.errors.schoolChildren)}
                helperText={formik.touched.schoolChildren && formik.errors.schoolChildren}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.schoolChildren}
                name="schoolChildren"
                id="schoolChildren"
                label="Number of School Going Children"
                type="Number"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Box mb="1.25rem">
                <Checkbox
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  key={formik.values.internationalWorker}
                  checked={formik.values.internationalWorker?.includes('on')}
                  color="secondary"
                  name="internationalWorker"
                  label="Is International Worker"
                />
              </Box>

              <InputField
                error={Boolean(formik.touched.hostelChildren && formik.errors.hostelChildren)}
                helperText={formik.touched.hostelChildren && formik.errors.hostelChildren}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.hostelChildren}
                name="hostelChildren"
                id="hostelChildren"
                label="Number of Children in Hostel"
                type="Number"
                fullWidth
              />
            </Grid>
          </Grid>

          <Divider
            sx={{
              my: 2,
              backgroundColor: theme.palette.neutral[200],
            }}
          />

          <Box sx={addEmpStyle.divWrapper}>
            <Typographys variant="subtitle1" color="neutral.600">
              EPF, ESI, PT & LWF Applicability
            </Typographys>

            <ToolTip title="This section pertains to the Employee’s EPF, ESI, PT and LWF settings." />
          </Box>

          <Grid container rowSpacing={2} columnSpacing={9} mt={0} sx={addEmpStyle.formContainer}>
            <Grid item xs={12} md={4}>
              <Checkbox
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                key={formik.values.eligibleForEPF}
                checked={formik.values.eligibleForEPF?.includes('on')}
                color="secondary"
                name="eligibleForEPF"
                label="Is Eligible For EPF"
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Checkbox
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                key={formik.values.eligibleForESI}
                checked={formik.values.eligibleForESI?.includes('on')}
                color="secondary"
                name="eligibleForESI"
                label="Is Eligible For ESI"
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Checkbox
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                key={formik.values.eligibleForEPS}
                checked={formik.values.eligibleForEPS?.includes('on')}
                color="secondary"
                name="eligibleForEPS"
                label="Is Eligible For EPS"
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Checkbox
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                key={formik.values.eligibleForEDLI}
                checked={formik.values.eligibleForEDLI?.includes('on')}
                color="secondary"
                name="eligibleForEDLI"
                label="Is Eligible For EDLI"
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Checkbox
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                key={formik.values.taxApplicable}
                checked={formik.values.taxApplicable?.includes('on')}
                color="secondary"
                name="taxApplicable"
                label="Is Professional Tax Applicable"
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Checkbox
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                key={formik.values.labourWelfareFund}
                checked={formik.values.labourWelfareFund?.includes('on')}
                color="secondary"
                name="labourWelfareFund"
                label="Is Labour Welfare Fund Contributions Applicable"
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Checkbox
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                key={formik.values.locatedInMetro}
                checked={formik.values.locatedInMetro?.includes('on')}
                color="secondary"
                name="locatedInMetro"
                label="Is Located in Metro?"
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Box sx={addEmpStyle.divWrapper}>
                <Checkbox
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  key={formik.values.joinedEPFPrior}
                  checked={formik.values.joinedEPFPrior?.includes('on')}
                  color="secondary"
                  name="joinedEPFPrior"
                  label="Has Joined EPF Prior to 1st Sept 2014?"
                />
                <ToolTip
                  title="Please click this option if the Employee was enrolled in EPF prior to 1st Sept 2014.
            This criteria along with their EPFGross wages will be used to determine if EPS is applicable
            for the employee or not."
                />
              </Box>
            </Grid>
          </Grid>

          <Divider
            sx={{
              my: 2,
              backgroundColor: theme.palette.neutral[200],
            }}
          />

          <Grid container rowSpacing={2} columnSpacing={9} sx={addEmpStyle.formContainer}>
            <Grid item xs={12} md={4}>
              <Box sx={addEmpStyle.divWrapper}>
                <Dropdown
                  error={Boolean(
                    formik.touched.professionalTaxJurisdiction && formik.errors.professionalTaxJurisdiction,
                  )}
                  helperText={formik.touched.professionalTaxJurisdiction && formik.errors.professionalTaxJurisdiction}
                  handleChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.professionalTaxJurisdiction}
                  name="professionalTaxJurisdiction"
                  id="professionalTaxJurisdiction"
                  label="Professional Tax Jurisdiction"
                  options={taxJurisdictionOptions}
                  fullWidth
                />
                <ToolTip title="The state where Professional Tax is applicable for the employee" />
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box sx={addEmpStyle.divWrapper}>
                <Dropdown
                  error={Boolean(
                    formik.touched.labourWelfareFundJurisdiction && formik.errors.labourWelfareFundJurisdiction,
                  )}
                  helperText={
                    formik.touched.labourWelfareFundJurisdiction && formik.errors.labourWelfareFundJurisdiction
                  }
                  handleChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.labourWelfareFundJurisdiction}
                  name="labourWelfareFundJurisdiction"
                  id="labourWelfareFundJurisdiction"
                  label="Labour Welfare Fund Jurisdiction"
                  options={welfareJurisdictionOptions}
                  fullWidth
                />
                <ToolTip title="The state where LWF is applicable for the employee" />
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box sx={addEmpStyle.divWrapper}>
                <InputField
                  error={Boolean(formik.touched.esiDispensary && formik.errors.esiDispensary)}
                  helperText={formik.touched.esiDispensary && formik.errors.esiDispensary}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.esiDispensary}
                  name="esiDispensary"
                  id="esiDispensary"
                  label="ESI Dispensary"
                  fullWidth
                />

                <ToolTip title="ESI Dispensary" />
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box sx={addEmpStyle.divWrapper}>
                <DatePicker
                  error={
                    Boolean(formik.touched.dateOfEnrollingepf && formik.errors.dateOfEnrollingepf) ||
                    (formik.touched.dateOfEnrollingepf && !formik.values.dateOfEnrollingepf)
                  }
                  helperText={formik.touched.dateOfEnrollingepf && formik.errors.dateOfEnrollingepf}
                  onChange={(date) => handleDateChange(date, 'dateOfEnrollingepf')}
                  onBlur={formik.handleBlur}
                  value={formik.values?.dateOfEnrollingepf}
                  name="dateOfEnrollingepf"
                  id="dateOfEnrollingepf"
                  label="Date of Enrolling in EPF"
                  fullWidth
                />
                <ToolTip title="The date when the employee got enrolled in the EPF scheme" />
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box sx={addEmpStyle.divWrapper}>
                <Dropdown
                  error={Boolean(formik.touched.epfContributionLevel && formik.errors.epfContributionLevel)}
                  helperText={formik.touched.epfContributionLevel && formik.errors.epfContributionLevel}
                  handleChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.epfContributionLevel}
                  name="epfContributionLevel"
                  id="epfContributionLevel"
                  label="Employee EPF Contribution Level"
                  options={epfContributionOptions}
                  fullWidth
                />
                <ToolTip title="This is the employee’s EPF contribution. This should be atleast 12% of the EPF Gross." />
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box sx={addEmpStyle.divWrapper}>
                <DatePicker
                  error={
                    Boolean(formik.touched.dateOfLeavingepf && formik.errors.dateOfLeavingepf) ||
                    (formik.touched.dateOfLeavingepf && !formik.values.dateOfLeavingepf)
                  }
                  helperText={formik.touched.dateOfLeavingepf && formik.errors.dateOfLeavingepf}
                  onChange={(date) => handleDateChange(date, 'dateOfLeavingepf')}
                  onBlur={formik.handleBlur}
                  value={formik.values.dateOfLeavingepf}
                  name="dateOfLeavingepf"
                  id="dateOfLeavingepf"
                  label="Date of Leaving in EPF"
                  fullWidth
                />
                <ToolTip
                  title="The date when the employee left the EPF scheme 
            (usually the last working day of the employee)"
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box sx={addEmpStyle.divWrapper}>
                <Dropdown
                  error={Boolean(formik.touched.epfContribution && formik.errors.epfContribution)}
                  helperText={formik.touched.epfContribution && formik.errors.epfContribution}
                  handleChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.epfContribution}
                  name="epfContribution"
                  id="epfContribution"
                  label="Employee EPF Contribution"
                  options={employeeContributionOptions}
                  fullWidth
                />
                <ToolTip
                  title="The Employee's EPF contribution can be: 1) Statutorily mandated level of 12% of EPF Gross,
              2) Above the mandated 12% of EPF Gross, 
              3) Upto Max Threshold Limit, 4) None"
                />
              </Box>
            </Grid>
          </Grid>

          <Divider
            sx={{
              my: 2,
              backgroundColor: theme.palette.neutral[200],
            }}
          />

          <Typographys variant="subtitle1" color="neutral.600">
            PAN Validation Status & Flat TDS Applicability
          </Typographys>

          <Grid container rowSpacing={2} columnSpacing={9} mt={0} sx={addEmpStyle.formContainer}>
            <Grid item xs={12} md={4}>
              <Box sx={addEmpStyle.divWrapper} mb="1.25rem">
                <Checkbox
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  key={formik.values.isPanValid}
                  checked={formik.values.isPanValid?.includes('on')}
                  color="secondary"
                  name="isPanValid"
                  label="Is PAN Valid?"
                />
                <ToolTip title="Is PAN Valid?" />
              </Box>

              <Box sx={addEmpStyle.divWrapper}>
                <Dropdown
                  error={Boolean(formik.touched.panValidatedBy && formik.errors.panValidatedBy)}
                  helperText={formik.touched.panValidatedBy && formik.errors.panValidatedBy}
                  handleChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.panValidatedBy}
                  name="panValidatedBy"
                  id="panValidatedBy"
                  label="PAN Validated By"
                  options={panValidatedByOptions}
                  fullWidth
                />

                <ToolTip title="Specify the employee who validated this employee PAN" />
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box sx={addEmpStyle.divWrapper} mb="1.25rem">
                <Checkbox
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  key={formik.values.panValidatedWithItDept}
                  checked={formik.values.panValidatedWithItDept?.includes('on')}
                  color="secondary"
                  name="panValidatedWithItDept"
                  label="Is PAN Validated with IT Department?"
                />
                <ToolTip
                  title="Select this in case the PAN number of the employee has been validated with 
              the Income Tax Department"
                />
              </Box>

              <Box sx={addEmpStyle.divWrapper}>
                <DatePicker
                  error={
                    Boolean(formik.touched.panValidatedDate && formik.errors.panValidatedDate) ||
                    (formik.touched.panValidatedDate && !formik.values.panValidatedDate)
                  }
                  helperText={formik.touched.panValidatedDate && formik.errors.panValidatedDate}
                  onChange={(date) => handleDateChange(date, 'panValidatedDate')}
                  onBlur={formik.handleBlur}
                  value={formik.values.panValidatedDate}
                  name="panValidatedDate"
                  id="panValidatedDate"
                  label="Date When Pan Was  Validated"
                  fullWidth
                />
                <ToolTip title="The date when the employee PAN was validated" />
              </Box>
            </Grid>
          </Grid>

          <Grid container rowSpacing={2} columnSpacing={9} mt={0} sx={addEmpStyle.formContainer}>
            <Grid item xs={12} md={4}>
              <Box sx={addEmpStyle.divWrapper} mb="1.25rem">
                <Checkbox
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  key={formik.values.isEmpFlatTDSTaxable}
                  checked={formik.values.isEmpFlatTDSTaxable?.includes('on')}
                  color="secondary"
                  name="isEmpFlatTDSTaxable"
                  label="Is Employee To Be Taxed At Flat TDS Rate"
                />
                <ToolTip
                  title="Select this in case the employee is eligible for Flat TDS rate.
               This is valid only for specific cases. 
              If unsure about applicability of flat TDS, please leave this unchecked"
                />
              </Box>

              <Box sx={addEmpStyle.divWrapper}>
                <InputField
                  error={Boolean(formik.touched.rateOfFlatTDS && formik.errors.rateOfFlatTDS)}
                  helperText={formik.touched.rateOfFlatTDS && formik.errors.rateOfFlatTDS}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.rateOfFlatTDS}
                  name="rateOfFlatTDS"
                  id="rateOfFlatTDS"
                  label="Rate of Flat TDS"
                  fullWidth
                />
                <ToolTip title="Specify the rate at which Flat TDS is to be applied for this employee" />
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box mt="4.25rem" sx={addEmpStyle.divWrapper}>
                <InputField
                  error={Boolean(formik.touched.flatTDSComputationBasis && formik.errors.flatTDSComputationBasis)}
                  helperText={formik.touched.flatTDSComputationBasis && formik.errors.flatTDSComputationBasis}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.flatTDSComputationBasis}
                  name="flatTDSComputationBasis"
                  id="flatTDSComputationBasis"
                  label="Flat TDS Computation Basis"
                  fullWidth
                />
                <ToolTip
                  title="Flat TDS can be computed either on the basis of actual earnings during each period or 
              on the basis of projected earnings for the rest of the financial year as well."
                />
              </Box>
            </Grid>
          </Grid>

          <Divider
            sx={{
              my: 2,
              backgroundColor: theme.palette.neutral[200],
            }}
          />

          <Box sx={addEmpStyle.divWrapper}>
            <Typographys variant="subtitle1" color="neutral.600">
              Company Salary & Expense Reimbursement Account
            </Typographys>

            <ToolTip
              title="These are the mapped Company Bank accounts for salaries 
        disbursal and  expense reimbursements."
            />
          </Box>

          <Grid container rowSpacing={2} columnSpacing={9} mt={0} sx={addEmpStyle.formContainer}>
            <Grid item xs={12} md={4}>
              <Box sx={addEmpStyle.divWrapper}>
                <Dropdown
                  error={Boolean(formik.touched.companySalaryAccount && formik.errors.companySalaryAccount)}
                  helperText={formik.touched.companySalaryAccount && formik.errors.companySalaryAccount}
                  handleChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.companySalaryAccount}
                  name="companySalaryAccount"
                  id="companySalaryAccount"
                  label="Company Salary Account"
                  options={salaryAccountOptions}
                  fullWidth
                />
                <ToolTip title="The Company Bank Account from which this employee's Salary will be paid." />
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box sx={addEmpStyle.divWrapper}>
                <Dropdown
                  error={Boolean(formik.touched.expenseReimbursementAcc && formik.errors.expenseReimbursementAcc)}
                  helperText={formik.touched.expenseReimbursementAcc && formik.errors.expenseReimbursementAcc}
                  handleChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.expenseReimbursementAcc}
                  name="expenseReimbursementAcc"
                  id="expenseReimbursementAcc"
                  label="Expense Reimbursement A/c"
                  options={reimbursementAccOptions}
                  fullWidth
                />
                <ToolTip
                  title="The Company Bank Account from which this employee's
               Expense Reimbursements will be paid."
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </PerfectScrollbar>
      <Box mt={10} sx={addEmpStyle.btnContainer}>
        <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />
        <Box sx={addEmpStyle.submitWrapper}>
          <Button
            size="large"
            color="info"
            variant="outlined"
            type="cancel"
            onClick={() => {
              formik.resetForm();
              handleCancel();
            }}
          >
            Cancel
          </Button>

          <Button size="large" type="submit" isDisable={false}>
            {propData.allowEdit ? 'Update' : 'Next'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

PayrollSetting.defaultProps = {
  handleSubmit: () => {},
  handleCancel: () => {},
  propData: {},
  taxJurisdictionOptions: [],
  welfareJurisdictionOptions: [],
  epfContributionOptions: [],
  employeeContributionOptions: [],
  panValidatedByOptions: [],
  salaryAccountOptions: [],
  reimbursementAccOptions: [],
  label: '',
};

PayrollSetting.propTypes = {
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  propData: PropTypes.shape({
    updateFormData: PropTypes.func,
    allTabsData: PropTypes.shape({
      'Payroll Setting': PropTypes.shape({}),
    }),
    allowEdit: PropTypes.bool,
  }),
  taxJurisdictionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  welfareJurisdictionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  epfContributionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  employeeContributionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  panValidatedByOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  salaryAccountOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  reimbursementAccOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  label: PropTypes.string,
};

export default PayrollSetting;
