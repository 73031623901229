import React from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import StatusCardGroup from 'components/Organisms/Employee/Dashboard/StatusCardGroup';
import DropdownMenu from 'components/Molecules/DropdownMenu';
import Typographys from 'components/Atoms/Typography';
import NumOfEmployeeCard from 'components/Organisms/Employee/Dashboard/NumOfEmployeeCard';
import NumOfEmployeeChartCard from 'components/Organisms/Employee/Dashboard/NumOfEmployeeChartCard';
import MonthWiseTrendsCard from 'components/Organisms/Employee/Dashboard/MonthWiseTrendsCard';
import NewHiresCard from 'components/Organisms/Employee/Dashboard/NewHiresCard';
import ExitsCard from 'components/Organisms/Employee/Dashboard/ExitsCard';
import OnboardingCard from 'components/Organisms/Employee/Dashboard/OnboardingCard';
import TenureWiseCountCard from 'components/Organisms/Employee/Dashboard/TenureWiseCountCard';
import GenderCard from 'components/Organisms/Employee/Dashboard/GenderCard';

const EmployeeDashboard = ({
  numActiveValue,
  numNewJoinessYTD,
  numExitYTD,
  numNotInvited,
  actionMenuLists,
  numOfEmployeeFilterOptions,
  numOfEmployeeFilterHandleChange,
  empData,
  empChartLevelWiseData,
  hireNum,
  hireProfileLists,
  hireFilterOptions,
  hireFilterHandleChange,
  exitNum,
  exitProfileLists,
  exitFilterOptions,
  exitFilterHandleChange,
  onboardingNum,
  onboardingProfileLists,
  onboardingFilterOptions,
  onboardingFilterHandleChange,
  tenureWiseData,
  genderData,
  totalValue,
  numOfEmployeeChartFilterOptions,
  numOfEmployeeChartFilterHandleChange,
  empLineChartData,
  monthWiseTrendFilterOptions,
  monthWiseTrendFilterHandleChange,
  monthWiseData,

  empTypeWiseColumns,
  onboardingAttritionColumns,
}) => {
  return (
    <BlankTemplate pageHeading="" isBreadcrumb={false}>
      <Box>
        <Box display="flex" justifyContent="space-between">
          <Typographys variant="h4" mr={1} color="neutral.800">
            Employee Dashboard
          </Typographys>
          <DropdownMenu btnLabel="Quick Actions" size="medium" menuLists={actionMenuLists} />
        </Box>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12} md={12}>
            <StatusCardGroup
              numActiveValue={numActiveValue}
              numNewJoinessYTD={numNewJoinessYTD}
              numExitYTD={numExitYTD}
              numNotInvited={numNotInvited}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <NumOfEmployeeCard
              numOfEmployeeFilterOptions={numOfEmployeeFilterOptions}
              numOfEmployeeFilterHandleChange={numOfEmployeeFilterHandleChange}
              empData={empData}
              empChartLevelWiseData={empChartLevelWiseData}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <NewHiresCard
              hireNum={hireNum}
              hireProfileLists={hireProfileLists}
              hireFilterOptions={hireFilterOptions}
              hireFilterHandleChange={hireFilterHandleChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ExitsCard
              exitNum={exitNum}
              exitProfileLists={exitProfileLists}
              exitFilterOptions={exitFilterOptions}
              exitFilterHandleChange={exitFilterHandleChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <OnboardingCard
              onboardingNum={onboardingNum}
              onboardingProfileLists={onboardingProfileLists}
              onboardingFilterOptions={onboardingFilterOptions}
              onboardingFilterHandleChange={onboardingFilterHandleChange}
            />
          </Grid>
          <Grid item md={6} lg={8}>
            <TenureWiseCountCard tenureWiseData={tenureWiseData} />
          </Grid>
          <Grid item md={6} lg={4}>
            <GenderCard genderData={genderData} totalValue={totalValue} />
          </Grid>
          <Grid item md={12}>
            <NumOfEmployeeChartCard
              numOfEmployeeChartFilterOptions={numOfEmployeeChartFilterOptions}
              numOfEmployeeChartFilterHandleChange={numOfEmployeeChartFilterHandleChange}
              empLineChartData={empLineChartData}
              empTypeWiseColumns={empTypeWiseColumns}
            />
          </Grid>
          <Grid item md={12}>
            <MonthWiseTrendsCard
              monthWiseTrendFilterOptions={monthWiseTrendFilterOptions}
              monthWiseTrendFilterHandleChange={monthWiseTrendFilterHandleChange}
              monthWiseData={monthWiseData}
              onboardingAttritionColumns={onboardingAttritionColumns}
            />
          </Grid>
        </Grid>
      </Box>
    </BlankTemplate>
  );
};

EmployeeDashboard.defaultProps = {
  numActiveValue: 6823,
  numNewJoinessYTD: 1,
  numExitYTD: 23,
  numNotInvited: 20,
  actionMenuLists: [],
  numOfEmployeeFilterOptions: [],
  numOfEmployeeFilterHandleChange: () => {},
  empData: [],
  empChartLevelWiseData: [],
  hireNum: 6,
  hireProfileLists: [],
  hireFilterOptions: [],
  hireFilterHandleChange: () => {},
  exitNum: 6,
  exitProfileLists: [],
  exitFilterOptions: [],
  exitFilterHandleChange: () => {},
  onboardingNum: 6,
  onboardingProfileLists: [],
  onboardingFilterOptions: [],
  onboardingFilterHandleChange: () => {},
  tenureWiseData: [],
  genderData: [],
  totalValue: 100,
  numOfEmployeeChartFilterOptions: [],
  numOfEmployeeChartFilterHandleChange: () => {},
  empLineChartData: [],
  monthWiseTrendFilterOptions: [],
  monthWiseTrendFilterHandleChange: () => {},
  monthWiseData: [],
};
EmployeeDashboard.propTypes = {
  numActiveValue: PropTypes.number,
  numNewJoinessYTD: PropTypes.number,
  numExitYTD: PropTypes.number,
  numNotInvited: PropTypes.number,
  actionMenuLists: PropTypes.arrayOf,
  numOfEmployeeFilterOptions: PropTypes.arrayOf,
  numOfEmployeeFilterHandleChange: PropTypes.func,
  empData: PropTypes.arrayOf,
  empChartLevelWiseData: PropTypes.arrayOf,
  hireNum: PropTypes.number,
  hireProfileLists: PropTypes.arrayOf,
  hireFilterOptions: PropTypes.arrayOf,
  hireFilterHandleChange: PropTypes.func,
  exitNum: PropTypes.number,
  exitProfileLists: PropTypes.arrayOf,
  exitFilterOptions: PropTypes.arrayOf,
  exitFilterHandleChange: PropTypes.func,
  onboardingNum: PropTypes.number,
  onboardingProfileLists: PropTypes.arrayOf,
  onboardingFilterOptions: PropTypes.arrayOf,
  onboardingFilterHandleChange: PropTypes.func,
  tenureWiseData: PropTypes.arrayOf,
  genderData: PropTypes.arrayOf,
  totalValue: PropTypes.number,
  numOfEmployeeChartFilterOptions: PropTypes.arrayOf,
  numOfEmployeeChartFilterHandleChange: PropTypes.func,
  empLineChartData: PropTypes.arrayOf,
  monthWiseTrendFilterOptions: PropTypes.arrayOf,
  monthWiseTrendFilterHandleChange: PropTypes.func,
  monthWiseData: PropTypes.arrayOf,
};

export default EmployeeDashboard;
