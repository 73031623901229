import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useToast from 'components/Provider/useToast';

import ReassignQueryTemplate from 'components/Templates/TaskAndQueries/ReassignQuery';
import useServices from '../common/useServices';

const ReassignQuery = () => {
  const addToast = useToast();
  const location = useLocation();
  const { id } = useParams();
  const isEditMode = location.pathname.includes('edit');
  const services = useServices();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await services.reassignQueryService(id);
      } catch (error) {
        addToast({ type: 'error', title: 'ERROR', message: error?.message });
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode, id]);
  return (
    <ReassignQueryTemplate
      handleSubmit={services.reassignQueryActionService}
      formData={services.state.queryDetails.formData}
      categoryOptions={services.state.queryDetails.categoryOptions}
      topicOptions={services.state.queryDetails.topicOptions}
      currentOwnerOptions={services.state.queryDetails.currentOwnerOptions}
      handleCancel={services.handleReassignCancel}
      queryDetails={services.state.queryDetails?.queryDetails}
    />
  );
};

export default ReassignQuery;
