import React from 'react';
import { Typography } from '@mui/material';

const columns = [
  {
    fieldName: 'firstName',
    name: '',
    label: 'First Name',
    formatValue: ({ row, column }) => {
      return <Typography variant="body2">{row[column.fieldName]}</Typography>;
    },
  },
  {
    fieldName: 'lastName',
    name: '',
    label: 'Last Name',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'employeeID',
    name: '',
    label: 'Employee ID',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'username',
    name: '',
    label: 'User Name',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'employmentStatus',
    name: '',
    label: 'Status',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'gender',
    name: '',
    label: 'Gender',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'department',
    name: '',
    label: 'Department',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'designation',
    name: '',
    label: 'Designation',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'location',
    name: '',
    label: 'Location',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'employmentType',
    name: '',
    label: 'Employment Type',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'supervisor',
    name: '',
    label: 'Supervisor',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
];

const useColumns = () => {
  return [columns];
};

export default useColumns;
