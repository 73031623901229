import { colors } from '../../../../../design-tokens/colors';
import { styles as typographyStyles } from '../styles';
import { typographyVariants as fontVariant } from '../../../../theme/constants/variants/typography';
import { colorVariant } from '../../../../theme/constants/variants/color';

const {
  MBody500,
  MBody600,
  MBody700,
  MCaptions600,
  MCaptions700,
  MH1500,
  MH1600,
  MH1700,
  MH2500,
  MH2600,
  MH2700,
  MH3500,
  MH3600,
  MH3700,
  MH4600,
  MH4700,
  MSmallBody500,
  MSmallBody600,
  MSmallBody700,
  MToothNumber,
} = fontVariant;

const { greys_500_grey, Grey50 } = colorVariant;

export const variants = [
  {
    props: { variant: MCaptions600 },
    style: {
      ...typographyStyles(600, 10),
    },
  },
  {
    props: { variant: MCaptions700 },
    style: {
      ...typographyStyles(700, 10),
    },
  },
  {
    props: { variant: MSmallBody500 },
    style: {
      ...typographyStyles(500, 12),
      letterSpacing: '-0.16px',
    },
  },
  {
    props: { variant: MSmallBody600 },
    style: {
      ...typographyStyles(600, 12),
      letterSpacing: '-0.16px',
    },
  },
  {
    props: { variant: MSmallBody700 },
    style: {
      ...typographyStyles(700, 12),
      letterSpacing: '-0.16px',
    },
  },
  {
    props: { variant: MBody500 },
    style: {
      ...typographyStyles(500, 14),
      letterSpacing: '-0.24px',
    },
  },
  {
    props: { variant: MBody600 },
    style: {
      ...typographyStyles(600, 14),
      letterSpacing: '-0.24px',
    },
  },
  {
    props: { variant: MBody700 },
    style: {
      ...typographyStyles(700, 14),
      letterSpacing: '-0.24px',
    },
  },
  {
    props: { variant: MH4600 },
    style: {
      ...typographyStyles(600, 16),
      letterSpacing: '-0.32px',
    },
  },
  {
    props: { variant: MH4700 },
    style: {
      ...typographyStyles(700, 16),
      letterSpacing: '-0.32px',
    },
  },
  {
    props: { variant: MH3500 },
    style: {
      ...typographyStyles(500, 22),
      letterSpacing: '-0.40px',
    },
  },
  {
    props: { variant: MH3600 },
    style: {
      ...typographyStyles(600, 22),
      letterSpacing: '-0.40px',
    },
  },
  {
    props: { variant: MH3700 },
    style: {
      ...typographyStyles(700, 22),
      letterSpacing: '-0.40px',
    },
  },
  {
    props: { variant: MH2500 },
    style: {
      ...typographyStyles(500, 26),
      letterSpacing: '-0.48px',
    },
  },
  {
    props: { variant: MH2600 },
    style: {
      ...typographyStyles(600, 26),
      letterSpacing: '-0.48px',
    },
  },
  {
    props: { variant: MH2700 },
    style: {
      ...typographyStyles(700, 26),
      letterSpacing: '-0.48px',
    },
  },
  {
    props: { variant: MH1500 },
    style: {
      ...typographyStyles(500, 32),
      letterSpacing: '-0.56px',
    },
  },
  {
    props: { variant: MH1600 },
    style: {
      ...typographyStyles(600, 32),
      letterSpacing: '-0.56px',
    },
  },
  {
    props: { variant: MH1700 },
    style: {
      ...typographyStyles(700, 32),
      letterSpacing: '-0.56px',
    },
  },
  {
    props: { variant: MToothNumber },
    style: {
      ...typographyStyles(500, 12),
    },
  },
  {
    props: { variant: MBody600, color: Grey50 },
    style: {
      color: colors.selago,
    },
  },
  {
    props: { variant: MBody600, color: greys_500_grey },
    style: {
      color: colors.shipCove,
    },
  },
];
