import { createExitBreakPlugin, createSoftBreakPlugin, createResetNodePlugin } from '@udecode/plate';
import { CONFIG } from '../config/config';
import { plateComponentUI } from '../config/plateComponentUI';
import { createMyPlugins } from '../config/typescript';

export const breakPluings = createMyPlugins(
  [
    createExitBreakPlugin(CONFIG.exitBreak),
    createSoftBreakPlugin(CONFIG.softBreak),
    createResetNodePlugin(CONFIG.resetBlockType),
  ],
  {
    components: plateComponentUI,
  },
);
