import React, { useEffect, useState } from 'react';
import { Grid, Box, Divider, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import Button from 'components/Atoms/Button';
import Typographys from 'components/Atoms/Typography';
import * as yup from 'yup';
import InputField from 'components/Atoms/InputField/index';
import DatePicker from 'components/Atoms/Datepicker';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Attachments from 'components/Atoms/Attachments';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import Typography from '@mui/material/Typography';
import { Formik, FieldArray, getIn } from 'formik';
import { genericMobileRegex, genericEmailRegex } from 'utils/commonUtils';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { addEmpStyle } from '../../style';

const EmploymentHistory = ({ tabHeading, handleSubmit, handleCancel, propData, label, updateBtnText }) => {
  const defaultValues = propData.allTabsData[label];

  const employmentSchema = yup.object().shape({
    employment: yup.array().of(
      yup.object().shape({
        employerName: yup.string().required('Required'),
        employmentCommenceDate: yup.string().required('Required'),
        employmentEndDate: yup.string().required('Required'),
        referenceContactPhone: yup
          .string()
          .matches(genericMobileRegex, 'Invalid format, Please enter valid mobile number.'),
        referenceContactEmail: yup.string().matches(genericEmailRegex, 'Invalid format, Please enter valid email ID.'),
      }),
    ),
  });

  const defaultObj = {
    employerName: '',
    employerAddress: '',
    employmentCommenceDate: '',
    employmentEndDate: '',
    reasonForLeaving: '',
    employeeId: '',
    startDesignation: '',
    endDesignation: '',
    referenceContactName: '',
    clientNotes: '',
    attachment: '',
  };

  const initialValues =
    defaultValues.employment.length > 0 && Object.keys(defaultValues.employment[0]).length
      ? defaultValues
      : {
          employment: [defaultObj],
        };

  return (
    <Box mt={1.5}>
      <Typographys variant="h5" color="neutral.800">
        {tabHeading}
      </Typographys>

      <Formik
        validationSchema={employmentSchema}
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          handleSubmit(values);
          actions.setSubmitting(false);
        }}
      >
        {(formik) => (
          <Form
            formik={formik}
            handleCancel={handleCancel}
            propData={propData}
            label={label}
            defaultObj={defaultObj}
            updateBtnText={updateBtnText}
          />
        )}
      </Formik>
    </Box>
  );
};

const Form = ({ formik, handleCancel, propData, label, defaultObj, updateBtnText }) => {
  const [openBulkUploadDialog, setOpenBulkUploadDialog] = useState(false);

  const handleDateChange = (date, setFieldValue, label1) => {
    setFieldValue(label1, date);
  };

  useEffect(() => {
    propData.updateFormData(formik.values, label);
  }, [formik.values, label]);

  const theme = useTheme();
  return (
    <Box mt={2} component="form" onSubmit={formik.handleSubmit}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 53rem)' }}>
          <FieldArray name="employment">
            {({ push, remove }) => (
              <Box>
                {formik.values.employment.map((e, index) => {
                  return (
                    <Accordion key={e.key} disableGutters elevation={0} square sx={addEmpStyle.accordionWrapper}>
                      <AccordionSummary
                        aria-controls="employment-content"
                        id="employment-header"
                        expandIcon={<KeyboardArrowDownSharpIcon sx={{ fontSize: '3rem', color: 'neutral.800' }} />}
                      >
                        <Box sx={addEmpStyle.deleteContainer}>
                          <Typography variant="subtitle2" color="neutral.800">
                            Record {index + 1}
                          </Typography>
                          <DeleteOutlineSharpIcon sx={{ color: 'neutral.600' }} onClick={() => remove(index)} />
                        </Box>
                      </AccordionSummary>

                      <AccordionDetails>
                        <Grid container rowSpacing={2} columnSpacing={9} mt={0} sx={addEmpStyle.formContainer}>
                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `employment[${index}].employerName`) &&
                                  getIn(formik.errors, `employment[${index}].employerName`),
                              )}
                              helperText={
                                getIn(formik.touched, `employment[${index}].employerName`) &&
                                getIn(formik.errors, `employment[${index}].employerName`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.employerName}
                              name={`employment[${index}].employerName`}
                              id={`employment[${index}].employerName`}
                              label="Employer Name"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `employment[${index}].employerAddress`) &&
                                  getIn(formik.errors, `employment[${index}].employerAddress`),
                              )}
                              helperText={
                                getIn(formik.touched, `employment[${index}].employerAddress`) &&
                                getIn(formik.errors, `employment[${index}].employerAddress`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.employerAddress}
                              name={`employment[${index}].employerAddress`}
                              id={`employment[${index}].employerAddress`}
                              label="Employer Address"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <DatePicker
                              error={
                                Boolean(
                                  getIn(formik.touched, `employment[${index}].employmentCommenceDate`) &&
                                    getIn(formik.errors, `employment[${index}].employmentCommenceDate`),
                                ) ||
                                (getIn(formik.touched, `employment[${index}].employmentCommenceDate`) &&
                                  !e.employmentCommenceDate)
                              }
                              helperText={
                                getIn(formik.touched, `employment[${index}].employmentCommenceDate`) &&
                                getIn(formik.errors, `employment[${index}].employmentCommenceDate`)
                              }
                              onChange={(data) =>
                                handleDateChange(
                                  data,
                                  formik.setFieldValue,
                                  `employment[${index}].employmentCommenceDate`,
                                )
                              }
                              onBlur={formik.handleBlur}
                              value={e?.employmentCommenceDate}
                              name={`employment[${index}].employmentCommenceDate`}
                              id={`employment[${index}].employmentCommenceDate`}
                              label="Employment Commence date"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <DatePicker
                              error={
                                Boolean(
                                  getIn(formik.touched, `employment[${index}].employmentEndDate`) &&
                                    getIn(formik.errors, `employment[${index}].employmentEndDate`),
                                ) ||
                                (getIn(formik.touched, `employment[${index}].employmentEndDate`) &&
                                  !e.employmentEndDate)
                              }
                              helperText={
                                getIn(formik.touched, `employment[${index}].employmentEndDate`) &&
                                getIn(formik.errors, `employment[${index}].employmentEndDate`)
                              }
                              onChange={(data) =>
                                handleDateChange(data, formik.setFieldValue, `employment[${index}].employmentEndDate`)
                              }
                              onBlur={formik.handleBlur}
                              value={e?.employmentEndDate}
                              name={`employment[${index}].employmentEndDate`}
                              id={`employment[${index}].employmentEndDate`}
                              label="Employment End Date"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `employment[${index}].reasonForLeaving`) &&
                                  getIn(formik.errors, `employment[${index}].reasonForLeaving`),
                              )}
                              helperText={
                                getIn(formik.touched, `employment[${index}].reasonForLeaving`) &&
                                getIn(formik.errors, `employment[${index}].reasonForLeaving`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.reasonForLeaving}
                              name={`employment[${index}].reasonForLeaving`}
                              id={`employment[${index}].reasonForLeaving`}
                              label="Reason For Leaving"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `employment[${index}].employeeId`) &&
                                  getIn(formik.errors, `employment[${index}].employeeId`),
                              )}
                              helperText={
                                getIn(formik.touched, `employment[${index}].employeeId`) &&
                                getIn(formik.errors, `employment[${index}].employeeId`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.employeeId}
                              name={`employment[${index}].employeeId`}
                              id={`employment[${index}].employeeId`}
                              label="Employee Id"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `employment[${index}].startDesignation`) &&
                                  getIn(formik.errors, `employment[${index}].startDesignation`),
                              )}
                              helperText={
                                getIn(formik.touched, `employment[${index}].startDesignation`) &&
                                getIn(formik.errors, `employment[${index}].startDesignation`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.startDesignation}
                              name={`employment[${index}].startDesignation`}
                              id={`employment[${index}].startDesignation`}
                              label="Start Designation"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `employment[${index}].endDesignation`) &&
                                  getIn(formik.errors, `employment[${index}].endDesignation`),
                              )}
                              helperText={
                                getIn(formik.touched, `employment[${index}].endDesignation`) &&
                                getIn(formik.errors, `employment[${index}].endDesignation`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.endDesignation}
                              name={`employment[${index}].endDesignation`}
                              id={`employment[${index}].endDesignation`}
                              label="End Designation"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `employment[${index}].referenceContactName`) &&
                                  getIn(formik.errors, `employment[${index}].referenceContactName`),
                              )}
                              helperText={
                                getIn(formik.touched, `employment[${index}].referenceContactName`) &&
                                getIn(formik.errors, `employment[${index}].referenceContactName`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.referenceContactName}
                              name={`employment[${index}].referenceContactName`}
                              id={`employment[${index}].referenceContactName`}
                              label="Reference Contact Name"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `employment[${index}].referenceContactDesignation`) &&
                                  getIn(formik.errors, `employment[${index}].referenceContactDesignation`),
                              )}
                              helperText={
                                getIn(formik.touched, `employment[${index}].referenceContactDesignation`) &&
                                getIn(formik.errors, `employment[${index}].referenceContactDesignation`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.referenceContactDesignation}
                              name={`employment[${index}].referenceContactDesignation`}
                              id={`employment[${index}].referenceContactDesignation`}
                              label="Reference Contact Designation"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `employment[${index}].referenceContactPhone`) &&
                                  getIn(formik.errors, `employment[${index}].referenceContactPhone`),
                              )}
                              helperText={
                                getIn(formik.touched, `employment[${index}].referenceContactPhone`) &&
                                getIn(formik.errors, `employment[${index}].referenceContactPhone`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.referenceContactPhone}
                              name={`employment[${index}].referenceContactPhone`}
                              id={`employment[${index}].referenceContactPhone`}
                              label="Reference Contact Phone"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `employment[${index}].referenceContactEmail`) &&
                                  getIn(formik.errors, `employment[${index}].referenceContactEmail`),
                              )}
                              helperText={
                                getIn(formik.touched, `employment[${index}].referenceContactEmail`) &&
                                getIn(formik.errors, `employment[${index}].referenceContactEmail`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.referenceContactEmail}
                              name={`employment[${index}].referenceContactEmail`}
                              id={`employment[${index}].referenceContactEmail`}
                              label="Reference Contact Email"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Attachments
                              isDeleteIcon
                              handleChange={(event) => {
                                formik.setFieldValue(`employment[${index}].attachment`, event.target.files[0]);
                              }}
                              name="attachment"
                              id="attachment"
                              value={e.attachment}
                              handleDelete={() => {
                                formik.setFieldValue(`employment[${index}].attachment`, '');
                              }}
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
                <Box sx={addEmpStyle.addWrapper}>
                  <Button
                    size="medium"
                    variant="outlined"
                    type="button"
                    startIcon={<AddOutlinedIcon />}
                    onClick={() => push(defaultObj)}
                  >
                    Add Another
                  </Button>
                </Box>
              </Box>
            )}
          </FieldArray>
        </Box>
      </PerfectScrollbar>

      <Box sx={addEmpStyle.btnContainer}>
        <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

        <Box sx={addEmpStyle.submitWrapper}>
          <Button
            size="large"
            color="info"
            variant="outlined"
            type="button"
            onClick={() => {
              formik.resetForm();
              handleCancel();
            }}
          >
            Cancel
          </Button>

          <Button size="large" type="submit" isDisable={false}>
            {propData.allowEdit ? updateBtnText : 'Next'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

Form.defaultProps = {
  handleCancel: () => {},
  propData: {},
  formik: {},
  defaultObj: {},
  label: 'Employment History',
  updateBtnText: 'Update',
};

Form.propTypes = {
  handleCancel: PropTypes.func,
  label: PropTypes.string,
  propData: PropTypes.shape({
    updateFormData: PropTypes.func,
    allTabsData: PropTypes.shape({
      'Employment History': PropTypes.shape({
        employment: PropTypes.shape([]),
      }),
    }),
    allowEdit: PropTypes.bool,
  }),
  formik: PropTypes.shape({
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    values: PropTypes.shape({
      employment: PropTypes.shape([]),
    }),
    resetForm: PropTypes.func,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    setFieldValue: PropTypes.func,
  }),
  defaultObj: PropTypes.shape({}),
  updateBtnText: PropTypes.string,
};

EmploymentHistory.defaultProps = {
  tabHeading: 'Employment History',
  handleSubmit: () => {},
  handleCancel: () => {},
  propData: {},
  label: 'Employment History',
  updateBtnText: 'Update',
};

EmploymentHistory.propTypes = {
  tabHeading: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  label: PropTypes.string,
  propData: PropTypes.shape({
    updateFormData: PropTypes.func,
    allTabsData: PropTypes.shape({
      'Employment History': PropTypes.shape({
        employment: PropTypes.shape([]),
      }),
    }),
    allowEdit: PropTypes.bool,
  }),
  updateBtnText: PropTypes.string,
};

export default EmploymentHistory;
