import React from 'react';
import { Typography } from '@mui/material';
import { validateDateElseHyphen } from 'utils/commonUtils';

const columns = [
  {
    fieldName: 'displayName',
    name: '',
    label: 'Leave Category',
    formatValue: ({ row, column }) => <Typography variant="body2">{row?.leaveCategory?.displayName}</Typography>,
  },
  {
    fieldName: 'leaveType',
    name: '',
    label: 'Leave Type',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'requestState',
    name: '',
    label: 'Status',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'startDate',
    name: '',
    label: 'Start Date',
    formatValue: ({ row, column }) => (
      <Typography variant="body2">{validateDateElseHyphen(row[column.fieldName], 'DD MMMM, YYYY h:mm A')}</Typography>
    ),
  },
  {
    fieldName: 'endDate',
    name: '',
    label: 'End Date',
    formatValue: ({ row, column }) => (
      <Typography variant="body2">{validateDateElseHyphen(row[column.fieldName], 'DD MMMM, YYYY h:mm A')}</Typography>
    ),
  },
  {
    fieldName: 'numberOfDays',
    name: '',
    label: 'Number Of Days',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'numberOfHours',
    name: '',
    label: 'Number Of Hours',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
];

const useColumns = () => {
  return [columns];
};

export default useColumns;
