import React, { useEffect } from 'react';
import { Box, Grid, Divider, useTheme, Skeleton } from '@mui/material';
import PropTypes from 'prop-types';
import Button from 'components/Atoms/Button';
import DynamicForm from 'components/DynamicForm';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { addEmpStyle } from '../style';

const ExtendedAttributes = ({ handleSubmit, handleCancel, propData, label }) => {
  const { customAttributesFormik, metaCustomFields } = propData;

  const defaultValues = propData.allTabsData['Extended Attributes'];
  useEffect(() => {
    propData.updateFormData(customAttributesFormik.values, label);
  }, [customAttributesFormik.values, label, propData]);

  const theme = useTheme();

  return (
    <Box sx={{ p: '2rem 3rem' }}>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit(customAttributesFormik.values);
        }}
      >
        <PerfectScrollbar>
          <Box sx={{ maxHeight: 'calc(100vh - 47.3rem)' }}>
            <Grid container spacing={4}>
              <Grid item md={12} xs={12}>
                {!customAttributesFormik.values && metaCustomFields?.metaCustomFieldDefinitions?.length ? (
                  <Box sx={{ py: 4 }}>
                    <Skeleton height={42} />
                    <Skeleton />
                    <Skeleton />
                  </Box>
                ) : (
                  <DynamicForm
                    formik={customAttributesFormik}
                    formFields={metaCustomFields?.metaCustomFieldDefinitions}
                    isModify={propData.allowEdit}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </PerfectScrollbar>

        <Box sx={addEmpStyle.btnContainer}>
          <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

          <Box sx={addEmpStyle.submitWrapper}>
            <Button
              size="large"
              color="info"
              variant="outlined"
              type="cancel"
              onClick={() => {
                customAttributesFormik.resetForm();
                handleCancel();
              }}
            >
              Cancel
            </Button>

            <Button size="large" type="submit" isDisable={Object.keys(customAttributesFormik.errors).length}>
              {propData.allowEdit ? 'Update' : 'Add Employee'}
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

ExtendedAttributes.defaultProps = {
  defaultValues: {},
  handleSubmit: () => {},
  handleCancel: () => {},
  propData: {},
  label: '',
};

ExtendedAttributes.propTypes = {
  defaultValues: PropTypes.shape({}),
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  propData: PropTypes.shape({
    updateFormData: PropTypes.func,
    allTabsData: PropTypes.shape({
      'Extended Attributes': PropTypes.shape({}),
    }),
    allowEdit: PropTypes.bool,
  }),
  label: PropTypes.string,
};

export default ExtendedAttributes;
