import React from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import AddAndEdit from 'components/Organisms/Employee/Company/Separation/ViewOffboarding/AddAndEdit';
import { Box, Skeleton } from '@mui/material';

const AddAndEditTemplate = ({
  breadcrumbData,
  loading,
  formLoading,
  handleSubmit,
  handleCancel,
  defaultValues,
  statusTypeOptions,
  isEdit,
  handleDelete,
  fileName,
  handleDeleteAttachment,
  ownerOptions,
  handleOwnerOptions,
  entityOriginPermission,
  preModifyData,
}) => {
  return (
    <BlankTemplate
      pageHeading={preModifyData?.taskName ? `Edit ${preModifyData?.taskName}` : ''}
      isBreadcrumb
      breadcrumbData={breadcrumbData}
      padding="0 3rem 2rem 3rem"
    >
      {formLoading ? (
        <Box sx={{ p: 4 }}>
          <Skeleton height={42} />
          <Skeleton />
          <Skeleton />
        </Box>
      ) : (
        <AddAndEdit
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          defaultValues={defaultValues}
          statusTypeOptions={statusTypeOptions}
          isEdit={isEdit}
          handleDelete={handleDelete}
          handleDeleteAttachment={handleDeleteAttachment}
          ownerOptions={ownerOptions}
          handleOwnerOptions={handleOwnerOptions}
          loading={loading}
          entityOriginPermission={entityOriginPermission}
          preModifyData={preModifyData}
        />
      )}
    </BlankTemplate>
  );
};

export default AddAndEditTemplate;
