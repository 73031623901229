import React from 'react';
import { Grid, Divider } from '@mui/material';
import InputField from 'components/Atoms/InputField';
import PropTypes from 'prop-types';
import Typographys from 'components/Atoms/Typography';

const EpfForm = ({ formik }) => {
  return (
    <>
      <Grid item xs={12}>
        <Divider flexItem orientation="horizontal" />{' '}
      </Grid>
      <Grid item xs={12}>
        <Typographys variant="subtitle1">EPF Remittance break up details</Typographys>
      </Grid>
      <Grid item xs={4} sx={{ pt: '1.25rem !important' }}>
        <InputField
          fullWidth
          error={Boolean(formik.touched.epfEmployeeShare && formik.errors.epfEmployeeShare)}
          helperText={formik.touched.epfEmployeeShare && formik.errors.epfEmployeeShare}
          value={formik.values.epfEmployeeShare}
          name="epfEmployeeShare"
          label="EPF Employee's Share"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={4} sx={{ pt: '1.25rem !important' }}>
        <InputField
          fullWidth
          error={Boolean(formik.touched.epfEmployerShare && formik.errors.epfEmployerShare)}
          helperText={formik.touched.epfEmployerShare && formik.errors.epfEmployerShare}
          value={formik.values.epfEmployerShare}
          name="epfEmployerShare"
          label="EPF Employer's Share"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={4} sx={{ pt: '1.25rem !important' }}>
        <InputField
          fullWidth
          error={Boolean(formik.touched.EPSContibRemmited && formik.errors.EPSContibRemmited)}
          helperText={formik.touched.EPSContibRemmited && formik.errors.EPSContibRemmited}
          value={formik.values.EPSContibRemmited}
          name="EPSContibRemmited"
          label="EPS Contribution (Remitted)"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={4}>
        <InputField
          fullWidth
          error={Boolean(formik.touched.EDLISchemeContrib && formik.errors.EDLISchemeContrib)}
          helperText={formik.touched.EDLISchemeContrib && formik.errors.EDLISchemeContrib}
          value={formik.values.EDLISchemeContrib}
          name="EDLISchemeContrib"
          label="EDLI Scheme Contribution (Remitted)"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={4}>
        <InputField
          fullWidth
          error={Boolean(formik.touched.EPFAdminCharges && formik.errors.EPFAdminCharges)}
          helperText={formik.touched.EPFAdminCharges && formik.errors.EPFAdminCharges}
          value={formik.values.EPFAdminCharges}
          name="EPFAdminCharges"
          label="EPF Admin Charges (Remitted)"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={4}>
        <InputField
          fullWidth
          error={Boolean(formik.touched.EDLIAdminCharges && formik.errors.EDLIAdminCharges)}
          helperText={formik.touched.EDLIAdminCharges && formik.errors.EDLIAdminCharges}
          value={formik.values.EDLIAdminCharges}
          name="EDLIAdminCharges"
          label="EDLI Admin Charges (Remitted)"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      </Grid>
    </>
  );
};

export default EpfForm;

EpfForm.propTypes = {
  formik: PropTypes.instanceOf(Object).isRequired,
};
