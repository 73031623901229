import { useSelector } from 'react-redux';
import { hasPermission } from 'utils/permissions';

const getActionItems = (services, selectedRow) => {
  return [
    {
      label: 'Download',
      onClick: () => services.handleAttachment(selectedRow),
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_INDEX_DOWNLOAD_DOCUMENT'],
    },
    {
      label: 'Publish',
      onClick: () => services.handlePublishDocument(selectedRow),
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_INDEX_PUBLISH_DOCUMENT'],
      conditional: (row) => row?.entityOrigin === 'ENTITY_USER_DEFINED' && row?.publicationStatus !== 'Pubished',
    },
    {
      label: 'Unpublish',
      onClick: () => services.handleUnPublishDocument(selectedRow),
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_INDEX_UNPUBLISH_DOCUMENT'],
      conditional: (row) => row?.entityOrigin === 'ENTITY_USER_DEFINED' && row?.publicationStatus === 'Pubished',
    },
    {
      label: 'Delete',
      onClick: services.handleDeleteDialog,
      icon: '',
      color: 'error.400',
      permissions: ['ROLE_BASE_PERMISSION_INDEX_DELETE_DOCUMENT'],
      conditional: (row) => row?.entityOrigin === 'ENTITY_USER_DEFINED',
    },
  ];
};

const useMenuItems = (row, services) => {
  const actions = getActionItems(services, row);
  const authorities = useSelector((state) => state.session.user.authorities);

  const moreFilteredActionItems = actions.filter((action) => {
    const userHasPermission = hasPermission(authorities, action.permissions);
    if ((action.conditional && !action.conditional(row)) || !userHasPermission) {
      return false;
    }
    return true;
  });
  return [moreFilteredActionItems];
};

export default useMenuItems;
