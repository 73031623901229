import React, { useEffect, useState } from 'react';
import Typography from 'components/Atoms/Typography';
import ReporteeTemplate from 'components/Templates/Reportee';
import { API_URL } from 'api/api';
import { Skeleton, Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { getEmployeeReportees } from 'api/home';

const Reportees = () => {
  const navigate = useNavigate();
  const [isloading, setIsLoading] = useState(false);
  const [myReporteeDetails, setMyReporteeDetails] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const query = new URLSearchParams();
      query.append('id', id);
      const data = await getEmployeeReportees(query);
      setMyReporteeDetails(data);
      setIsLoading(false);
    };
    fetchData();
  }, [id]);

  if (isloading) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (myReporteeDetails?.data?.status === 'ERROR') {
    return (
      <Typography variant="h6">
        {myReporteeDetails?.data?.message || `Error while loading employee reportees.`}
      </Typography>
    );
  }

  const breadcrumbData = [
    { id: 1, name: 'My Upward Hierarchy', path: '/home/myReporting', isActive: false },
    { id: 2, name: 'Reportees', isActive: true },
  ];

  const handleReporteeClick = (employeeId) => {
    navigate(`/home/reportees/${employeeId}`);
  };

  const handleUpwardHierarchyClick = (employeeId) => {
    navigate(`/home/myReporting/${employeeId}`);
  };

  const reporteeArray =
    (myReporteeDetails?.data?.employeeInstanceList &&
      myReporteeDetails?.data?.employeeInstanceList?.map((reportee) => ({
        reporteeId: reportee?.id || '',
        reporteeName: reportee?.fullName || '-',
        reporteeEmployeeId: reportee?.profile?.employeeID || '-',
        mobileNumbers: reportee?.mobileNumbers?.join(', ') || '-',
        telephoneNumbers: reportee?.telephoneNumbers?.join(', ') || '-',
        emailAddresses: reportee?.emailAddresses?.join(', ') || '-',
        faxNumbers: reportee?.faxNumbers?.join(', ') || '-',
        designation: reportee?.designation?.name || '-',
        department: reportee?.department?.name || '-',
        location: reportee?.location?.name || '-',
        supervisor: reportee?.supervisor?.fullName || '-',
        avatarSrc:
          reportee?.profile?.profilePic && reportee?.profile?.profilePic !== 'null'
            ? `${API_URL}/${reportee?.profile?.profilePic}`
            : '',
      }))) ||
    [];

  return (
    <ReporteeTemplate
      reporteeList={reporteeArray}
      handleReporteeClick={handleReporteeClick}
      handleUpwardHierarchyClick={handleUpwardHierarchyClick}
      breadcrumbData={breadcrumbData}
      headingName={myReporteeDetails?.data?.employeeInstance?.fullName || ''}
    />
  );
};

export default Reportees;
