import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import CommonFilledCard from 'components/Molecules/CommonFilledCard';
import { leaveCardGroupStyle } from './style';

const CommonFilledCardGroup = ({ openingLeave, leavesAvailed, transactions, pendingApproval, remainingBalance }) => {
  return (
    <Box sx={leaveCardGroupStyle.wrapper}>
      <CommonFilledCard heading="Opening Balance" value={openingLeave} />
      <CommonFilledCard heading="Leaves Availed" value={leavesAvailed} />
      <CommonFilledCard heading="Transactions" value={transactions} />
      <CommonFilledCard heading="Pending Approval" value={pendingApproval} />
      <CommonFilledCard heading="Remaining Balance" value={remainingBalance} />
    </Box>
  );
};

CommonFilledCardGroup.defaultProps = {
  openingLeave: 24,
  leavesAvailed: 12,
  transactions: 3,
  pendingApproval: 4,
  remainingBalance: 12,
};
CommonFilledCardGroup.propTypes = {
  openingLeave: PropTypes.number,
  leavesAvailed: PropTypes.number,
  transactions: PropTypes.number,
  pendingApproval: PropTypes.number,
  remainingBalance: PropTypes.number,
};

export default CommonFilledCardGroup;
