import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { leaveTableStyle } from 'components/Organisms/Leave/style';

const LeaveBalanceTable = ({ rows, columns }) => {
  return (
    <Box>
      {rows?.length ? (
        <>
          <Typographys variant="h6">Leave Balance</Typographys>
          <Box mt={2} sx={leaveTableStyle.container}>
            <Table>
              <TableHead sx={{ position: 'sticky', top: '0' }}>
                <TableRow>
                  {columns.map((column) => {
                    return <TableCell>{column.label}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow>
                    {columns.map((column) => {
                      return (
                        <TableCell sx={{ color: 'text.300' }}>
                          {column?.formatValue({ row: { ...row, index: index + 1 } })}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </>
      ) : (
        <Box />
      )}
    </Box>
  );
};

LeaveBalanceTable.defaultProps = {
  rows: [],
  columns: [],
};

LeaveBalanceTable.propTypes = {
  columns: PropTypes.arrayOf,
  rows: PropTypes.arrayOf,
};
export default LeaveBalanceTable;
