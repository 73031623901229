import { useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import FormData from 'form-data';
import { deleteLeaveRequest, submitLeaveRequest } from 'api/leave';
import useToast from 'components/Provider/useToast';
import { updateLoadingState } from 'store/slices/leaves/myLeaves';
import { useDispatch } from 'react-redux';

const dialogsInitState = {
  open: null,
};

const reducer = (state, action) => {
  const { type } = action;
  let newState;
  switch (type) {
    case 'Delete': {
      newState = { ...state, open: 'Delete' };
      break;
    }
    case 'Submit': {
      newState = { ...state, open: 'Submit' };
      break;
    }
    case 'Close': {
      newState = { ...state, open: null };
      break;
    }
    case 'Cancel': {
      newState = { ...state, open: 'Cancel' };
      break;
    }

    default: {
      newState = state;
    }
  }
  return newState;
};

const useLeaveServices = () => {
  const navigate = useNavigate();
  const addToast = useToast();
  const dispatch = useDispatch();

  const [dialogState, dispatchDialog] = useReducer(reducer, dialogsInitState);

  const viewLeave = (row) => {
    navigate(`/home/leaves/my-leave-requests/${row?.id}/view`);
  };

  const modifyLeave = (row) => {
    navigate(`/home/leaves/my-leave-requests/${row?.id}/edit`);
  };

  const handleOpenDialog = (type, payload) => {
    dispatchDialog({ type, payload });
  };

  const handleRefreshPage = (refresh) => {
    if (refresh) {
      dispatch(updateLoadingState(true));
    } else {
      navigate('/home/leaves/my-leave-requests');
    }
  };

  const deleteLeave = async (leaveId, refresh) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', leaveId);
      const response = await deleteLeaveRequest(bodyFormData);
      if (response?.status === 200) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Leave deleted successfully`,
        });
        handleRefreshPage(refresh);
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to delete leave request`,
      });
    } finally {
      dispatchDialog({ type: 'Close' });
    }
  };

  const submitLeave = async ({ id, refresh }) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', id);

      const response = await submitLeaveRequest(bodyFormData);
      if (response?.status === 200) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Leave submitted successfully`,
        });
        handleRefreshPage(refresh);
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to submit leave request`,
      });
    } finally {
      dispatchDialog({ type: 'Close' });
    }
  };

  const cancelLeave = async (leaveId, requestState, refresh) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', leaveId);
      bodyFormData.append('currentLeaveReqState', requestState);
      bodyFormData.append('leaveReqState', 'Cancellation Pending Approval');
      const response = await submitLeaveRequest(bodyFormData);
      if (response?.status === 200) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Leave cancelled successfully`,
        });
        handleRefreshPage(refresh);
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to cancel leave request`,
      });
    } finally {
      dispatchDialog({ type: 'Close' });
    }
  };

  return {
    dialogState,
    dispatchDialog,
    deleteLeave,
    submitLeave,
    handleOpenDialog,
    modifyLeave,
    viewLeave,
    cancelLeave,
  };
};

export default useLeaveServices;
