import React from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/Remove';
import IconWithTitle from 'components/Atoms/IconWithTitle';

const CheckWithTitle = ({ isChecked, iconColor, iconFontSize, titleColor, titleVariant, title, columnSpacing }) => {
  return (
    <IconWithTitle
      iconElement={isChecked ? <CheckIcon fontSize="inherit" /> : <RemoveIcon fontSize="inherit" />}
      iconColor={iconColor}
      iconFontSize={iconFontSize}
      columnSpacing={columnSpacing}
      title={title}
      titleVariant={titleVariant}
      titleColor={titleColor}
    />
  );
};

CheckWithTitle.defaultProps = {
  isChecked: true,
  iconColor: 'actions.600',
  iconFontSize: '2.25rem',
  title: 'Mumbai',
  titleColor: 'neutral.800',
  titleVariant: 'body2',
  columnSpacing: 0.5,
};

CheckWithTitle.propTypes = {
  isChecked: PropTypes.bool,
  iconColor: PropTypes.string,
  iconFontSize: PropTypes.string,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  titleVariant: PropTypes.string,
  columnSpacing: PropTypes.number,
};

export default CheckWithTitle;
