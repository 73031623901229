import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Calendar from 'components/Molecules/Calendar';
import CommonCard from 'components/Molecules/CommonCard';
import AttendanceStatsCard from './AttendanceStatsCard';
import LeaveBalanceCard from './LeaveBalanceCard';
import TabCard from './TabCard';
import SurveysAndAnnouncementsCard from './SurveysAndAnnouncementsCard';

const HomeDashboard = ({
  attendanceStatsData,
  progressBarList,
  handleFilterDateChange,
  balanceLeave,
  employeeList,
  teamMembersList,
  holidaysLoading,
  holidaysColumn,
  holidaysRow,
  holidaysSortDirection,
  holidaysSortBy,
  holidaysOnSortChange,
  documentsLoading,
  documentsColumn,
  documentsRow,
  documentsSortDirection,
  documentsSortBy,
  documentsOnSortChange,
  documentsCount,
  documentsPage,
  documentsOnPageChange,
  documentsHandleDownload,
  docFilterLabel,
  docFilterOptions,
  handleDocSelect,
  surveysList,
  announcementList,
  calendarYear,
  events,
  handleAttendanceData,
  handleDetails,
  loadingAnnouncementDetails,
  loadingAnnouncementDetailsError,
  companyConfigHours,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={8}>
        <TabCard
          employeeList={employeeList}
          teamMembersList={teamMembersList}
          holidaysLoading={holidaysLoading}
          holidaysColumn={holidaysColumn}
          holidaysRow={holidaysRow}
          holidaysSortDirection={holidaysSortDirection}
          holidaysSortBy={holidaysSortBy}
          holidaysOnSortChange={holidaysOnSortChange}
          documentsLoading={documentsLoading}
          documentsColumn={documentsColumn}
          documentsRow={documentsRow}
          documentsSortDirection={documentsSortDirection}
          documentsSortBy={documentsSortBy}
          documentsOnSortChange={documentsOnSortChange}
          documentsCount={documentsCount}
          documentsPage={documentsPage}
          documentsOnPageChange={documentsOnPageChange}
          documentsHandleDownload={documentsHandleDownload}
          docFilterLabel={docFilterLabel}
          docFilterOptions={docFilterOptions}
          handleDocSelect={handleDocSelect}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <SurveysAndAnnouncementsCard
          surveysList={surveysList}
          announcementList={announcementList}
          handleDetails={handleDetails}
          loadingAnnouncementDetails={loadingAnnouncementDetails}
          loadingAnnouncementDetailsError={loadingAnnouncementDetailsError}
        />
      </Grid>
      <Grid item xs={12} lg={8}>
        <AttendanceStatsCard
          attendanceStatsData={attendanceStatsData}
          progressBarList={progressBarList}
          handleFilterDateChange={handleFilterDateChange}
          handleAttendanceData={handleAttendanceData}
          companyConfigHours={companyConfigHours}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <LeaveBalanceCard balanceLeave={balanceLeave} />
      </Grid>
      <Grid item xs={12} lg={12}>
        <CommonCard
          cardHeaderTitle={`Calendar - FY ${calendarYear}`}
          isHeaderFilter={false}
          isFooter={false}
          isSelect={false}
          cardBodyPadding="3rem 0 0 0"
          bodyContent={<Calendar events={events} />}
        />
      </Grid>
    </Grid>
  );
};

HomeDashboard.defaultProps = {
  attendanceStatsData: [],
  progressBarList: {},
  handleFilterDateChange: () => {},
  handleAttendanceData: () => {},
  balanceLeave: [],
  employeeList: [],
  teamMembersList: [],
  holidaysLoading: false,
  holidaysColumn: [],
  holidaysRow: [],
  holidaysSortDirection: 'asc',
  holidaysSortBy: 'id',
  holidaysOnSortChange: () => {},
  documentsLoading: false,
  documentsColumn: [],
  documentsRow: [],
  documentsSortDirection: 'asc',
  documentsSortBy: 'id',
  documentsOnSortChange: () => {},
  documentsCount: 7,
  documentsPage: 0,
  documentsOnPageChange: () => {},
  documentsHandleDownload: () => {},
  docFilterLabel: '',
  docFilterOptions: [],
  handleDocSelect: () => {},
  surveysList: [],
  announcementList: [],
  calendarYear: '',
  events: [],
  handleDetails: () => {},
  loadingAnnouncementDetails: false,
  loadingAnnouncementDetailsError: {
    message: '',
  },
};
HomeDashboard.propTypes = {
  attendanceStatsData: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  progressBarList: PropTypes.shape(
    PropTypes.shape({
      duration: PropTypes.shape({
        hour: PropTypes.number,
        min: PropTypes.number,
      }),
      break: PropTypes.shape({
        hour: PropTypes.number,
        min: PropTypes.number,
      }),
      overtime: PropTypes.shape({
        hour: PropTypes.number,
        min: PropTypes.number,
      }),
    }),
  ),
  handleFilterDateChange: PropTypes.func,
  handleAttendanceData: PropTypes.func,
  balanceLeave: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  employeeList: PropTypes.arrayOf,
  teamMembersList: PropTypes.arrayOf(
    PropTypes.shape({
      profileImg: PropTypes.string,
      profileName: PropTypes.string,
      emailId: PropTypes.string,
      contactNumber: PropTypes.number,
      event: PropTypes.string,
    }),
  ),
  holidaysLoading: PropTypes.bool,
  holidaysColumn: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),
  holidaysRow: PropTypes.arrayOf(
    PropTypes.shape({
      slNo: PropTypes.number,
      date: PropTypes.date,
      occasion: PropTypes.string,
    }),
  ),
  holidaysSortDirection: PropTypes.string,
  holidaysSortBy: PropTypes.string,
  holidaysOnSortChange: PropTypes.func,
  documentsLoading: PropTypes.bool,
  documentsColumn: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),
  documentsRow: PropTypes.arrayOf(
    PropTypes.shape({
      slNo: PropTypes.number,
      documentName: PropTypes.string,
      section: PropTypes.string,
    }),
  ),
  documentsSortDirection: PropTypes.string,
  documentsSortBy: PropTypes.string,
  documentsOnSortChange: PropTypes.func,
  documentsCount: PropTypes.number,
  documentsPage: PropTypes.number,
  documentsOnPageChange: PropTypes.func,
  documentsHandleDownload: PropTypes.func,
  docFilterLabel: PropTypes.string,
  docFilterOptions: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, onClick: PropTypes.func })),
  handleDocSelect: PropTypes.func,
  surveysList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      desc: PropTypes.string,
      time: PropTypes.string,
      unRead: PropTypes.bool,
    }),
  ),
  announcementList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      desc: PropTypes.string,
      time: PropTypes.string,
      unRead: PropTypes.bool,
    }),
  ),
  calendarYear: PropTypes.string,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      start: PropTypes.string,
      end: PropTypes.string,
      color: PropTypes.string,
      textColor: PropTypes.string,
      border: PropTypes.string,
      type: PropTypes.string,
      data: PropTypes.shape({
        title: PropTypes.string,
        employeeName: PropTypes.string,
        birthdayDate: PropTypes.string,
        employeeImage: PropTypes.string,
        employeeDesignation: PropTypes.string,
      }),
    }),
  ),
  handleDetails: PropTypes.func,
  loadingAnnouncementDetails: PropTypes.bool,
  loadingAnnouncementDetailsError: PropTypes.shape({
    message: PropTypes.string,
  }),
};

export default HomeDashboard;
