import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@mui/material';
import TitleWithText from 'components/Atoms/TitleWithText';

const StatutoryIdentification = ({ identificationDetail }) => {
  return (
    <Box px={3}>
      <Grid container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="PAN Number" text={identificationDetail?.panNumber} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Aadhaar Number" text={identificationDetail?.aadharNumber} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="UAN Number" text={identificationDetail?.uan} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="UHID Number" text={identificationDetail?.uhid} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default StatutoryIdentification;
