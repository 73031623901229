import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import BlankTemplate from 'components/Templates/BlankTemplate';
import ProfileVisaDetails from 'components/Organisms/Profile/VisaDetails';
import { useThunk } from 'hooks/useThunk';
import { fetchVisaDetails } from 'store';
import { useNavigate } from 'react-router-dom';
import useDownloadFile from 'hooks/useDownloadFile';
import { Box, Skeleton, Typography } from '@mui/material';

const ProfileVisaDetailsPage = () => {
  const navigate = useNavigate();
  const downloadFile = useDownloadFile();

  const [getVisaDetails, isLoadingVisaDetails, loadingVisaDetailsError] = useThunk(fetchVisaDetails);

  useEffect(() => {
    const query = new URLSearchParams({});
    getVisaDetails(query);
  }, [getVisaDetails]);

  const { visaDetails } = useSelector((state) => {
    return state.profile;
  });

  const handleEdit = () => {
    navigate(`/profile/visa-details/edit`);
  };

  const handleAttachment = (docid, filename) => {
    downloadFile({ docid }, filename, 'restPayIndex/downloadAttachment');
  };

  if (loadingVisaDetailsError !== null) {
    return <Typography>{loadingVisaDetailsError?.message || 'Error while loading visa details.'}</Typography>;
  }

  return isLoadingVisaDetails ? (
    <Box sx={{ p: 4 }}>
      <Skeleton height={70} />
      <Skeleton />
      <Skeleton />
    </Box>
  ) : (
    <BlankTemplate pageHeading="Visa Details" isBreadcrumb={false} padding="2rem 3rem">
      <ProfileVisaDetails visaDetails={visaDetails} handleEdit={handleEdit} handleAttachment={handleAttachment} />
    </BlankTemplate>
  );
};

export default ProfileVisaDetailsPage;
