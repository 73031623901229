import React from 'react';
import PropTypes from 'prop-types';
import DashboardTemplate from 'components/Templates/DashboardTemplate';
import Dashboard from 'components/Organisms/Expenses/Dashboard';
import { Box } from '@mui/material';
import Typography from 'components/Atoms/Typography';
import GraphWithComponentDetail from './graph';
import CategoryGraph from './CategoryGraph';
import TotalExpenseGraph from './TotalExpenseGraph';

const ExpenseDashboard = ({
  pageHeading,
  expenseDetails,
  chartOptions,
  barData,
  legendOptions,
  iconType,
  iconWidth,
  iconHeight,
  lineData,
  lineChartOptions,
  lineChartData,
  handleBarChartCustomDateFilter,
  handleLineChartCustomDateFilter,
  isTeam,
  actionBtnLabel,
  handleAction,
  chartData,
  symbol,
}) => {
  return (
    <DashboardTemplate
      pageHeading={pageHeading}
      padding="0rem 3rem"
      isActionMenuLists={false}
      isActionButton
      actionLabel={actionBtnLabel}
      handleAction={handleAction}
    >
      <Dashboard
        expenseDetails={expenseDetails}
        chartOptions={chartOptions}
        chartData={chartData}
        barData={barData}
        legendOptions={legendOptions}
        iconType={iconType}
        iconWidth={iconWidth}
        iconHeight={iconHeight}
        lineData={lineData}
        lineChartOptions={lineChartOptions}
        lineChartData={lineChartData}
        handleBarChartCustomDateFilter={handleBarChartCustomDateFilter}
        handleLineChartCustomDateFilter={handleLineChartCustomDateFilter}
        isTeam={isTeam}
        symbol={symbol}
      />
      <TotalExpenseGraph isTeam={isTeam} chartOptions={chartOptions} />
      <CategoryGraph isTeam={isTeam} chartOptions={chartOptions} />
      {isTeam && chartData?.reportees?.length ? (
        chartData?.reportees.map((reportee) => (
          <Box sx={{ mt: 2 }}>
            <GraphWithComponentDetail
              data={chartData?.empWiseCatWiseChartsMap?.[reportee?.id]}
              reportee={reportee}
              status={chartData?.empWiseStatusWiseTotalsMap?.[reportee?.id]}
              reportData={chartData?.empWiseExpReportsMap?.[reportee?.id]}
            />
          </Box>
        ))
      ) : (
        <Box sx={{ textAlign: 'center', padding: '1rem' }}>
          <Typography variant="body2" color="text.secondary">
            No data found
          </Typography>
        </Box>
      )}
    </DashboardTemplate>
  );
};

ExpenseDashboard.defaultProps = {
  pageHeading: 'Expenses',
  expenseDetails: [],
  chartOptions: {
    width: 1000,
    height: 315,
    maxBarSize: 48,
  },
  lineChartOptions: {
    width: 730,
    height: 410,
  },
  chartData: [],
  lineChartData: [],
  barData: [],
  lineData: [],
  legendOptions: {
    layout: 'horizontal',
    verticalAlign: 'bottom',
    align: 'right',
    legendLeftSpacing: '12rem',
  },
  iconType: 'circle',
  iconWidth: '1.25rem',
  iconHeight: '1.25rem',
  handleBarChartCustomDateFilter: () => {},
  handleLineChartCustomDateFilter: () => {},
  isTeam: false,
  actionBtnLabel: 'Add Expense Report',
  handleAction: () => {},
  symbol: '',
};

ExpenseDashboard.propTypes = {
  pageHeading: PropTypes.string,
  expenseDetails: PropTypes.arrayOf,
  lineChartOptions: PropTypes.shape(),
  lineChartData: PropTypes.arrayOf(PropTypes.shape()),
  lineData: PropTypes.arrayOf(PropTypes.shape()),
  iconType: PropTypes.string,
  iconWidth: PropTypes.string,
  iconHeight: PropTypes.string,
  handleBarChartCustomDateFilter: PropTypes.func,
  handleLineChartCustomDateFilter: PropTypes.func,
  isTeam: PropTypes.bool,
  actionBtnLabel: PropTypes.string,
  handleAction: PropTypes.func,
  chartOptions: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    maxBarSize: PropTypes.number,
  }),
  chartData: PropTypes.PropTypes.arrayOf(PropTypes.shape()),
  barData: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      dataKey: PropTypes.string,
      stackId: PropTypes.string,
      color: PropTypes.string,
      colorCode: PropTypes.number,
    }),
  ),
  legendOptions: PropTypes.shape({
    layout: PropTypes.string,
    verticalAlign: PropTypes.string,
    align: PropTypes.string,
    iconType: PropTypes.string,
    iconSize: PropTypes.string,
    iconWidth: PropTypes.string,
    iconHeight: PropTypes.string,
    legendLeftSpacing: PropTypes.string,
  }),
  symbol: PropTypes.string,
};

export default ExpenseDashboard;
