import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Typographys from 'components/Atoms/Typography';

const InfoCard = ({
  heading,
  headingColor,
  value,
  valueColor,
  boxBackground,
  boxPadding,
  boxBorderRadius,
  borderColor,
}) => {
  return (
    <Box
      bgcolor={boxBackground}
      border="1px solid"
      borderColor={borderColor}
      padding={boxPadding}
      borderRadius={boxBorderRadius}
    >
      <Typographys
        variant="caption"
        fontWeight={600}
        color={headingColor}
        sx={{ textTransform: 'uppercase', letterSpacing: '0.15rem' }}
      >
        {heading}
      </Typographys>
      <Typographys variant="h6" color={valueColor} sx={{ mt: 0.5 }}>
        {value}
      </Typographys>
    </Box>
  );
};

InfoCard.defaultProps = {
  heading: 'OPENING BALANCE',
  headingColor: 'secondary.500',
  value: '24',
  valueColor: 'neutral.800',
  boxBackground: 'white',
  boxPadding: '2rem',
  boxBorderRadius: '0.75rem',
  borderColor: 'neutral.300',
};
InfoCard.propTypes = {
  heading: PropTypes.string,
  headingColor: PropTypes.string,
  value: PropTypes.number,
  valueColor: PropTypes.string,
  boxBackground: PropTypes.string,
  boxPadding: PropTypes.string,
  boxBorderRadius: PropTypes.string,
  borderColor: PropTypes.string,
};

export default InfoCard;
