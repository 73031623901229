import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import MuiTab from 'components/Molecules/Tab';
import Instructions from 'components/Organisms/Profile/InitiateSeparation/Instructions';
import InitiateSeparationForm from 'components/Organisms/Profile/InitiateSeparation/InitiateSeparationForm';

const InitiateSeparation = ({
  employeeDetails,
  separationData,
  handleSubmit,
  acceptingManagerOptions,
  handleCancel,
  isEdit,
  selectedTab,
  loading,
}) => {
  const initialTabs = [
    {
      label: 'Instructions',
      onChange: () => {},
      RenderComponent: Instructions,
      componentProps: {},
    },
    {
      label: 'Forms',
      onChange: () => {},
      RenderComponent: InitiateSeparationForm,
      componentProps: {
        employeeDetails,
        separationData,
        onSubmit: handleSubmit,
        acceptingManagerOptions,
        handleCancel,
        isEdit,
        loading,
      },
    },
  ];

  return (
    <Box>
      <MuiTab
        key={initialTabs}
        orientation="horizontal"
        variant="scrollable"
        scrollButtons={false}
        textColor="primary"
        indicatorColor="primary"
        tabs={initialTabs}
        allowScrollButtonsMobile
        panelPadding="2rem"
        selectedTab={selectedTab}
      />
    </Box>
  );
};

InitiateSeparation.defaultProps = {
  employeeDetails: {},
  separationData: {},
  handleSubmit: () => {},
  handleCancel: () => {},
  acceptingManagerOptions: [],
  isEdit: false,
  selectedTab: 0,
  loading: false,
};

InitiateSeparation.propTypes = {
  employeeDetails: PropTypes.shape({
    employeeName: PropTypes.string,
    separationType: PropTypes.string,
    noticePeriod: PropTypes.string,
    lastWorkingDay: PropTypes.string,
    resignationSubmissionDate: PropTypes.string,
  }),
  separationData: PropTypes.shape({}),
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  acceptingManagerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  isEdit: PropTypes.bool,
  selectedTab: PropTypes.number,
  loading: PropTypes.bool,
};

export default InitiateSeparation;
