import { useSelector } from 'react-redux';
import { hasPermission } from 'utils/permissions';

const getActionItems = (services, userId) => {
  return [
    {
      name: 'View',
      onClick: services.viewExpenseReport,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EXPENSE_INDEX_VIEW_EXPENSE_REPORT'],
    },
    {
      name: 'Modify',
      onClick: services.modifyExpenseReport,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EXPENSE_INDEX_UPDATE_EXPENSE_REPORT'],
      conditional: (row) => row?.lockStatus?.toUpperCase() === 'UNLOCKED',
    },
    {
      name: 'Delete',
      onClick: services.handleDeleteDialog,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EXPENSE_INDEX_DELETE_EXPENSE_REPORT'],
      conditional: (row) => row?.entityOrigin?.toUpperCase() !== 'ENTITY_SYSTEM_DEFINED',
    },
    {
      name: 'Submit Report',
      onClick: services.submitExpenseReport,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EXPENSE_INDEX_SUBMIT_MY_EXPENSE_REPORT_FOR_REVIEW'],
      conditional: (row) => row?.reportStatus?.toUpperCase() === 'DRAFT',
    },
    {
      name: 'Recall Report',
      onClick: services.actionNavigation,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EXPENSE_INDEX_RECALL_MY_EXPENSE_REPORT'],
      conditional: (row) =>
        row?.employee?.id === userId &&
        (row?.reportStatus?.toUpperCase() === 'SUBMITTED' || row?.reportStatus?.toUpperCase() === 'UNDER REVIEW'),
    },
    {
      name: 'Approve/Reject',
      onClick: services.actionNavigation,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EXPENSE_INDEX_ACTION_EXPENSE_REPORT'],
      conditional: (row) =>
        row?.reportStatus?.toUpperCase() === 'SUBMITTED' || row?.reportStatus?.toUpperCase() === 'UNDER REVIEW',
    },
  ];
};

const useActionItems = (employeeServices) => {
  const user = useSelector((state) => state.session.user);
  const authorities = user?.authorities;

  const handleActionItemClick = (row) => {
    const actions = getActionItems(employeeServices, user?.employeeID);
    const moreFilteredActionItems = actions.filter((action) => {
      const userHasPermission = hasPermission(authorities, action.permissions);
      if ((action.conditional && !action.conditional(row)) || !userHasPermission) {
        return false;
      }
      return true;
    });
    return moreFilteredActionItems;
  };

  return [handleActionItemClick];
};

export default useActionItems;
