import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import TableExtended from 'components/Molecules/TableExtended';
import Typographys from 'components/Atoms/Typography';
import Dropdown from 'components/Molecules/Dropdown';
import Chips from 'components/Molecules/Chip';
import Button from 'components/Atoms/Button';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useFormik } from 'formik';
import FilterButton from 'components/Organisms/TableFilter/FilterButton';
import { policyListTabStyle } from '../style';

const BulkUpdateTab = ({
  bulkUpdateHandleFilter,
  bulkUpdateFilterFields,
  bulkUpdateRows,
  bulkUpdateHandleRowClick,
  bulkUpdatePage,
  bulkUpdateSortBy,
  bulkUpdateSortDirection,
  bulkUpdateHandleSelect,
  bulkUpdateOnSelectedChange,
  changePolicyHandleClick,
  bulkUpdateHandleCancel,
  bulkUpdateColumns,
  lcPolicies,
  loading,
}) => {
  const theme = useTheme();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: bulkUpdateRows.reduce((acc, row) => {
      acc[row.id] = row.lifeCyclePolicyId ? row.lifeCyclePolicyId : 'select';
      return acc;
    }, {}),
    onSubmit: (values) => {
      Object.keys(values).forEach((id) => {
        if (values[id] === 'select') {
          values[id] = '';
        }
      });
      changePolicyHandleClick(values);
    },
  });
  const rows = bulkUpdateRows.map((row) => {
    const lcPoliciesFinal = lcPolicies.map((list) => ({
      id: `${list.id}`,
      name: list.name,
      onClick: () => {},
    }));
    lcPoliciesFinal.unshift({ id: 'select', name: 'Please Select ..' });
    return {
      ...row,
      lcPolicy: (
        <Dropdown
          id={row.id}
          name={row.id}
          label="Life Cycle Policy"
          size="small"
          handleChange={(d) => {
            formik.handleChange(d);
          }}
          onBlur={formik.handleBlur}
          value={formik.values[row.id] || row.lifeCyclePolicyId}
          options={lcPoliciesFinal}
        />
      ),
      status: <Chips label={row.status} isShowIcon={false} color="success" />,
    };
  });

  return (
    <Box sx={policyListTabStyle.wrapper}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 33rem)' }}>
          <Typographys variant="h6">Life Cycle Policy Mappings</Typographys>

          <Box mt={2}>
            <FilterButton bulkUpdateHandleFilter={bulkUpdateHandleFilter} attributeOptions={bulkUpdateFilterFields} />
            <TableExtended
              loading={loading.tabLoading}
              rows={rows}
              columns={bulkUpdateColumns}
              showActionList={false}
              showRowSelector={false}
              onRowClick={bulkUpdateHandleRowClick}
              page={bulkUpdatePage}
              sortBy={bulkUpdateSortBy}
              sortDirection={bulkUpdateSortDirection}
              handleSelect={bulkUpdateHandleSelect}
              onSelectedChange={bulkUpdateOnSelectedChange}
              showPagination={false}
            />
          </Box>
        </Box>
      </PerfectScrollbar>

      <Box sx={policyListTabStyle.btnContainer}>
        <Divider sx={policyListTabStyle.divider} />
        <Box sx={policyListTabStyle.submitWrapper}>
          <Button
            size="large"
            variant="outlined"
            color="info"
            onClick={() => {
              formik.resetForm();
              bulkUpdateHandleCancel();
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loading.changePolicyLoading}
            size="large"
            variant="contained"
            color="primary"
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            Change Policy
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

BulkUpdateTab.defaultProps = {
  bulkUpdateFilterFields: [
    {
      name: 'Employee ID',
      type: 'number',
    },
    {
      name: 'First Name',
      type: 'string',
    },
    {
      name: 'Last Name',
      type: 'string',
    },
    {
      name: 'Life Cycle Policy',
      type: 'number',
    },
    {
      name: 'Employment Status',
      type: 'number',
    },
    {
      name: 'Department',
      type: 'string',
    },
    {
      name: 'Designation',
      type: 'string',
    },
    {
      name: 'Employment Type',
      type: 'string',
    },
    {
      name: 'Supervisor',
      type: 'string',
    },
    {
      name: 'Selected Policy',
      type: 'string',
    },
  ],
  bulkUpdateHandleFilter: () => {},
  bulkUpdateRows: [
    {
      id: '1',
      employeeId: 0,
      firstName: 'Sharad',
      lastName: 'Deshpandey',
      lcPolicy: [
        { id: 0, name: 'Default Life Cycle Policy' },
        { id: 1, name: 'Life Cycle Policy 2022' },
        { id: 2, name: 'Updated Life Cycle Policy' },
        { id: 3, name: 'Description' },
      ],
      status: 'Active',
      department: 'IT',
      designation: 'Design Head',
      location: 'Mumbai',
      employmentType: 'Permanent',
      supervisor: 'Deshpandey',
      selectedPolicy: 'Amir ',
    },
    {
      id: '2',
      employeeId: 1,
      firstName: 'Sharad',
      lastName: 'Deshpandey',
      lcPolicy: [
        { id: 0, name: 'Default Life Cycle Policy' },
        { id: 1, name: 'Life Cycle Policy 2022' },
        { id: 2, name: 'Updated Life Cycle Policy' },
        { id: 3, name: 'Description' },
      ],
      status: 'Active',
      department: 'IT',
      designation: 'Design Head',
      location: 'Mumbai',
      employmentType: 'Permanent',
      supervisor: 'Deshpandey',
      selectedPolicy: '',
    },
    {
      id: '3',
      employeeId: 3,
      firstName: 'Sharad',
      lastName: 'Deshpandey',
      lcPolicy: [
        { id: 0, name: 'Default Life Cycle Policy' },
        { id: 1, name: 'Life Cycle Policy 2022' },
        { id: 2, name: 'Updated Life Cycle Policy' },
        { id: 3, name: 'Description' },
      ],
      status: 'InActive',
      department: 'IT',
      designation: 'Design Head',
      location: 'Mumbai',
      employmentType: 'Permanent',
      supervisor: 'Deshpandey',
      selectedPolicy: '',
    },
    {
      id: '4',
      employeeId: 4,
      firstName: 'Sharad',
      lastName: 'Deshpandey',
      lcPolicy: [
        { id: 0, name: 'Default Life Cycle Policy' },
        { id: 1, name: 'Life Cycle Policy 2022' },
        { id: 2, name: 'Updated Life Cycle Policy' },
        { id: 3, name: 'Description' },
      ],
      status: 'InActive',
      department: 'IT',
      designation: 'Design Head',
      location: 'Mumbai',
      employmentType: 'Permanent',
      supervisor: 'Deshpandey',
      selectedPolicy: '',
    },
    {
      id: '1',
      employeeId: 0,
      firstName: 'Sharad',
      lastName: 'Deshpandey',
      lcPolicy: [
        { id: 0, name: 'Default Life Cycle Policy' },
        { id: 1, name: 'Life Cycle Policy 2022' },
        { id: 2, name: 'Updated Life Cycle Policy' },
        { id: 3, name: 'Description' },
      ],
      status: 'Active',
      department: 'IT',
      designation: 'Design Head',
      location: 'Mumbai',
      employmentType: 'Permanent',
      supervisor: 'Deshpandey',
      selectedPolicy: 'Amir ',
    },
    {
      id: '2',
      employeeId: 1,
      firstName: 'Sharad',
      lastName: 'Deshpandey',
      lcPolicy: [
        { id: 0, name: 'Default Life Cycle Policy' },
        { id: 1, name: 'Life Cycle Policy 2022' },
        { id: 2, name: 'Updated Life Cycle Policy' },
        { id: 3, name: 'Description' },
      ],
      status: 'Active',
      department: 'IT',
      designation: 'Design Head',
      location: 'Mumbai',
      employmentType: 'Permanent',
      supervisor: 'Deshpandey',
      selectedPolicy: '',
    },
    {
      id: '3',
      employeeId: 3,
      firstName: 'Sharad',
      lastName: 'Deshpandey',
      lcPolicy: [
        { id: 0, name: 'Default Life Cycle Policy' },
        { id: 1, name: 'Life Cycle Policy 2022' },
        { id: 2, name: 'Updated Life Cycle Policy' },
        { id: 3, name: 'Description' },
      ],
      status: 'InActive',
      department: 'IT',
      designation: 'Design Head',
      location: 'Mumbai',
      employmentType: 'Permanent',
      supervisor: 'Deshpandey',
      selectedPolicy: '',
    },
    {
      id: '4',
      employeeId: 4,
      firstName: 'Sharad',
      lastName: 'Deshpandey',
      lcPolicy: [
        { id: 0, name: 'Default Life Cycle Policy' },
        { id: 1, name: 'Life Cycle Policy 2022' },
        { id: 2, name: 'Updated Life Cycle Policy' },
        { id: 3, name: 'Description' },
      ],
      status: 'InActive',
      department: 'IT',
      designation: 'Design Head',
      location: 'Mumbai',
      employmentType: 'Permanent',
      supervisor: 'Deshpandey',
      selectedPolicy: '',
    },
  ],
  bulkUpdateHandleRowClick: () => {},
  bulkUpdatePage: 1,
  bulkUpdateSortBy: '',
  bulkUpdateSortDirection: 'asc',
  bulkUpdateHandleSelect: () => {},
  bulkUpdateOnSelectedChange: () => {},
  changePolicyHandleClick: () => {},
  bulkUpdateHandleCancel: () => {},
  bulkUpdateColumns: [
    {
      fieldName: `employeeId`,
      label: 'EMPLOYEE ID',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `firstName`,
      label: 'FIRST NAME',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `lastName`,
      label: 'LAST NAME',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `lcPolicy`,
      label: 'LIFE CYCLE POLICY',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `status`,
      label: 'EMPLOYMENT STATUS',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `department`,
      label: 'Department',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `designation`,
      label: 'Designation',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `employmentType`,
      label: 'Employment Type',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `supervisor`,
      label: 'Supervisor',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `selectedPolicy`,
      label: 'Selected Policy',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
  ],
  lcPolicies: [
    {
      id: 1,
      name: 'Policy Name',
      onClick: () => {},
    },
  ],
  loading: {},
};

BulkUpdateTab.propTypes = {
  bulkUpdateFilterFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  bulkUpdateHandleFilter: PropTypes.func,
  bulkUpdateRows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      employeeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      lcPolicy: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          name: PropTypes.string,
        }),
      ),
      status: PropTypes.string,
      department: PropTypes.string,
      designation: PropTypes.string,
      location: PropTypes.string,
      employmentType: PropTypes.string,
      supervisor: PropTypes.string,
      selectedPolicy: PropTypes.string,
    }),
  ),
  bulkUpdateHandleRowClick: PropTypes.func,
  bulkUpdatePage: PropTypes.number,
  bulkUpdateSortBy: PropTypes.string,
  bulkUpdateSortDirection: PropTypes.oneOf(['asc', 'desc']),
  bulkUpdateHandleSelect: PropTypes.func,
  bulkUpdateOnSelectedChange: PropTypes.func,
  changePolicyHandleClick: PropTypes.func,
  bulkUpdateHandleCancel: PropTypes.func,
  bulkUpdateColumns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),
  lcPolicies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  loading: PropTypes.bool,
};
export default BulkUpdateTab;
