import React from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import PropTypes from 'prop-types';
import SalaryDetails from 'components/Organisms/MyPay/SalaryDetails';

const SalaryDetailsTemplate = ({
  employeeData,
  employeeId,
  employeeName,
  handleViewTaxWorking,
  earningsRows,
  earningsColumns,
  variablePayRows,
  variablePayColumns,

  statutoryDeductionsColumns,
  statutoryDeductionsRows,
  employerRetiralColumns,
  employerRetiralRows,
  fixedComponentsColumns,
  fixedComponentsRows,
  netSalaryColumns,
  netSalaryRows,
  netVariablePayColumns,
  netVariablePayRows,
  retiralColumns,
  retiralRows,
  totalColumns,
  totalRows,
  isOverTimeApplicable,
  benefitsRows,
  benifitColumns,
  reimbursementsRows,
  reimbursementColumns,
  userHasViewTaxWorkingPermission,
}) => {
  return (
    <BlankTemplate pageHeading="My salary details" isBreadcrumb={false} padding="2rem 3rem">
      <SalaryDetails
        employeeData={employeeData}
        employeeId={employeeId}
        employeeName={employeeName}
        handleViewTaxWorking={handleViewTaxWorking}
        earningsRows={earningsRows}
        earningsColumns={earningsColumns}
        variablePayRows={variablePayRows}
        variablePayColumns={variablePayColumns}
        statutoryDeductionsColumns={statutoryDeductionsColumns}
        statutoryDeductionsRows={statutoryDeductionsRows}
        employerRetiralColumns={employerRetiralColumns}
        employerRetiralRows={employerRetiralRows}
        fixedComponentsColumns={fixedComponentsColumns}
        fixedComponentsRows={fixedComponentsRows}
        netSalaryColumns={netSalaryColumns}
        netSalaryRows={netSalaryRows}
        netVariablePayColumns={netVariablePayColumns}
        netVariablePayRows={netVariablePayRows}
        retiralColumns={retiralColumns}
        retiralRows={retiralRows}
        totalColumns={totalColumns}
        totalRows={totalRows}
        isOverTimeApplicable={isOverTimeApplicable}
        benefitsRows={benefitsRows}
        benifitColumns={benifitColumns}
        reimbursementsRows={reimbursementsRows}
        reimbursementColumns={reimbursementColumns}
        userHasViewTaxWorkingPermission={userHasViewTaxWorkingPermission}
      />
    </BlankTemplate>
  );
};

SalaryDetailsTemplate.defaultProps = {
  employeeData: {},
  employeeId: '',
  employeeName: '',
  handleViewTaxWorking: () => {},
  earningsRows: [],
  earningsColumns: [],

  variablePayRows: [],
  variablePayColumns: [],

  statutoryDeductionsColumns: [],
  statutoryDeductionsRows: [],

  employerRetiralColumns: [],
  employerRetiralRows: [],

  fixedComponentsColumns: [],
  fixedComponentsRows: [],

  netSalaryColumns: [],
  netSalaryRows: [],

  netVariablePayColumns: [],
  netVariablePayRows: [],

  retiralColumns: [],
  retiralRows: [],

  totalColumns: [],
  totalRows: [],
  isOverTimeApplicable: '',
};

SalaryDetailsTemplate.propTypes = {
  employeeData: PropTypes.shape({
    joiningDate: PropTypes.string,
    effectiveFrom: PropTypes.string,
    effectiveTo: PropTypes.string,
    CTCBased: PropTypes.string,
    salaryStructureStatus: PropTypes.string,
    payStructure: PropTypes.string,
  }),
  employeeId: PropTypes.string,
  employeeName: PropTypes.string,
  handleViewTaxWorking: PropTypes.func,

  earningsRows: PropTypes.arrayOf(
    PropTypes.shape({
      earnings: PropTypes.string,
      subLabel: PropTypes.string,
      annualAmount: PropTypes.string,
      perPeriodAmount: PropTypes.string,
    }),
  ),
  earningsColumns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      columnTitle: PropTypes.string,
      columnWidth: PropTypes.string,
    }),
  ),

  variablePayRows: PropTypes.arrayOf(
    PropTypes.shape({
      variablePay: PropTypes.string,
      subLabel: PropTypes.string,
      annualAmount: PropTypes.string,
      perPeriodAmount: PropTypes.string,
    }),
  ),
  variablePayColumns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      columnTitle: PropTypes.string,
      columnWidth: PropTypes.string,
    }),
  ),

  statutoryDeductionsColumns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      columnTitle: PropTypes.string,
      columnWidth: PropTypes.string,
    }),
  ),
  statutoryDeductionsRows: PropTypes.arrayOf(
    PropTypes.shape({
      statutoryDeductions: PropTypes.string,
      subLabel: PropTypes.string,
      annualAmount: PropTypes.string,
      perPeriodAmount: PropTypes.string,
    }),
  ),

  employerRetiralColumns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      columnTitle: PropTypes.string,
      columnWidth: PropTypes.string,
    }),
  ),
  employerRetiralRows: PropTypes.arrayOf(
    PropTypes.shape({
      employerRetiral: PropTypes.string,
      subLabel: PropTypes.string,
      annualAmount: PropTypes.string,
      perPeriodAmount: PropTypes.string,
    }),
  ),

  fixedComponentsColumns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      columnTitle: PropTypes.string,
      columnWidth: PropTypes.string,
    }),
  ),
  fixedComponentsRows: PropTypes.arrayOf(
    PropTypes.shape({
      fixedComponents: PropTypes.string,
      subLabel: PropTypes.string,
      annualAmount: PropTypes.string,
      perPeriodAmount: PropTypes.string,
    }),
  ),

  netSalaryColumns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      columnTitle: PropTypes.string,
      columnWidth: PropTypes.string,
    }),
  ),
  netSalaryRows: PropTypes.arrayOf(
    PropTypes.shape({
      netSalary: PropTypes.string,
      subLabel: PropTypes.string,
      annualAmount: PropTypes.string,
      perPeriodAmount: PropTypes.string,
    }),
  ),

  netVariablePayColumns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      columnTitle: PropTypes.string,
      columnWidth: PropTypes.string,
    }),
  ),
  netVariablePayRows: PropTypes.arrayOf(
    PropTypes.shape({
      netVariablePay: PropTypes.string,
      subLabel: PropTypes.string,
      annualAmount: PropTypes.string,
      perPeriodAmount: PropTypes.string,
    }),
  ),

  retiralColumns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      columnTitle: PropTypes.string,
      columnWidth: PropTypes.string,
    }),
  ),
  retiralRows: PropTypes.arrayOf(
    PropTypes.shape({
      retiral: PropTypes.string,
      subLabel: PropTypes.string,
      annualAmount: PropTypes.string,
      perPeriodAmount: PropTypes.string,
    }),
  ),

  totalColumns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      columnTitle: PropTypes.string,
      columnWidth: PropTypes.string,
    }),
  ),
  totalRows: PropTypes.arrayOf(
    PropTypes.shape({
      total: PropTypes.string,
      subLabel: PropTypes.string,
      annualAmount: PropTypes.string,
      perPeriodAmount: PropTypes.string,
    }),
  ),
  isOverTimeApplicable: PropTypes.string,
};

export default SalaryDetailsTemplate;
