import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { formDataApi } from 'api/api';
import { Skeleton, Box } from '@mui/material';
import ResponsePage from 'components/Organisms/ResponsePage';

const LeaveActionPages = ({ requestType }) => {
  const [searchParams, _] = useSearchParams();
  const rCode = searchParams.get('r');
  const [loading, setLoading] = useState(false);
  const [responseSuccess, setResponseSuccess] = useState(false);
  const [apiResponse, setApiResponse] = useState('');

  const actionLeaveRequest = async () => {
    const bodyFormData = new FormData();
    bodyFormData.append('r', rCode);

    try {
      setLoading(true);
      let requestUrl;
      if (requestType === 'approve') {
        requestUrl = `restLeaveIndex/approveExternalLeaveRequestAction`;
      } else if (requestType === 'reject') {
        requestUrl = `restLeaveIndex/rejectExternalLeaveRequestAction`;
      }
      const response = await formDataApi.post(requestUrl, bodyFormData);
      if (response?.data?.status !== 'ERROR') {
        setResponseSuccess(true);
      } else if (response?.data?.status === 'ERROR') {
        setResponseSuccess(false);
      }
      setApiResponse(response?.data);
    } catch (error) {
      setResponseSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    actionLeaveRequest();
  }, []);

  if (loading) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  return responseSuccess ? (
    <ResponsePage pageType="success" titleText="Thank You!" responseMessage={apiResponse?.message} />
  ) : (
    <ResponsePage pageType="error" titleText="Aw Snap!" responseMessage={apiResponse?.message} />
  );
};

export default LeaveActionPages;
