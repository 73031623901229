import React from 'react';
import { Typography } from '@mui/material';
import { validateDateElseHyphen } from 'utils/commonUtils';
import Chips from 'components/Molecules/Chip';
import { resignationStatusColor } from 'utils/statusColor';

const columns = [
  {
    fieldName: 'empID',
    numeric: true,
    label: 'Employee id',
    type: 'number',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'fullName',
    numeric: false,
    label: 'Employee',
    type: 'string',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'resignationStatus',
    numeric: false,
    label: 'Resignation Status',
    type: 'string',
    formatValue: ({ row, column }) => {
      const color = resignationStatusColor(row?.empExit?.[column.fieldName]);
      return <Chips label={row?.empExit?.[column.fieldName]} color={color} />;
    },
  },
  {
    fieldName: 'approvedLWD',
    numeric: false,
    label: 'Relieving date',
    type: 'string',
    formatValue: ({ row, column }) => {
      return (
        <Typography variant="body2">
          {row?.empExit?.[column.fieldName] && validateDateElseHyphen(row?.empExit?.[column.fieldName], 'DD MMMM YYYY')}
        </Typography>
      );
    },
  },
  {
    fieldName: 'resignationSubmissionDate',
    numeric: false,
    label: 'Resig. submission date',
    type: 'string',
    formatValue: ({ row, column }) => {
      return (
        <Typography variant="body2">
          {row?.empExit?.[column.fieldName] && validateDateElseHyphen(row?.empExit?.[column.fieldName], 'DD MMMM YYYY')}
        </Typography>
      );
    },
  },
  {
    fieldName: 'resignationAcceptanceDate',
    numeric: false,
    label: 'Resig. acceptance date',
    type: 'string',
    formatValue: ({ row, column }) => {
      return (
        <Typography variant="body2">
          {row?.empExit?.[column.fieldName] && validateDateElseHyphen(row?.empExit?.[column.fieldName], 'DD MMMM YYYY')}
        </Typography>
      );
    },
  },
];

const useColumns = () => {
  return [columns];
};

export default useColumns;
