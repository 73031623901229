export const policyDetailsStyle = {
  actionBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    mb: 2,
  },
  dropdown: {
    menu: {
      '& .MuiMenu-paper': {
        minWidth: 'auto',
        maxWidth: 'auto',
      },
    },
  },
};
