import React from 'react';
import { MuiThemeProvider } from '../../design-tokens/theme/theme';
import { StylesProvider, createGenerateClassName } from '@mui/styles';
import Container from './TextEditorContainer';

const generateClassname = createGenerateClassName({
  productionPrefix: 'bit-text-editor-container',
  seed: 'bit-text-editor-container',
});

export const TextEditorContainer = ({ dataTestId, isTextEditorFocused, children, hideFocusedBottomBorder }) => {
  return (
    <StylesProvider generateClassName={generateClassname}>
      <MuiThemeProvider>
        <Container
          dataTestId={dataTestId}
          isTextEditorFocused={isTextEditorFocused}
          children={children}
          hideFocusedBottomBorder={hideFocusedBottomBorder}
        />
      </MuiThemeProvider>
    </StylesProvider>
  );
};

TextEditorContainer.defaultProps = {
  hideFocusedBottomBorder: true,
};
