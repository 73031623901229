import React from 'react';
import PropTypes from 'prop-types';
import { Box, colors } from '@mui/material';
import FilterButton from 'components/Organisms/TableFilter/FilterButton';
import Table from 'components/Molecules/TableExtended';
import NoRecordsMessage from 'components/Organisms/CommonPageUtils/NoRecordsFound';
import { expensesStyle } from '../../style';

const ExpensesList = ({
  count,
  onPageChange,
  showPagination,
  rows,
  page,
  handleSelect,
  roleBasedMenu,
  columns,
  handleFilter,
  showActionList,
  sortDirection,
  sortBy,
  onSortChange,
  handleFiltersClear,
  filterFields,
  loading,
  showFilter,
}) => {
  return (
    <Box px={3}>
      {!!showFilter && (
        <Box sx={expensesStyle.buttonWrapper}>
          <FilterButton
            handleSubmit={handleFilter}
            attributeOptions={filterFields}
            handleFiltersClear={handleFiltersClear}
          />
        </Box>
      )}
      <Box mt={2}>
        {!loading && rows.length === 0 ? (
          <NoRecordsMessage width="20rem" height="20rem" />
        ) : (
          <Table
            loading={loading}
            rows={rows}
            columns={columns}
            showActionList={showActionList}
            page={page}
            handleSelect={handleSelect}
            roleBasedMenu={roleBasedMenu}
            count={count}
            onPageChange={onPageChange}
            showPagination={showPagination}
            sortDirection={sortDirection}
            sortBy={sortBy}
            onSortChange={onSortChange}
            isDynamic
          />
        )}
      </Box>
    </Box>
  );
};

ExpensesList.defaultProps = {
  filterFields: [],
  showActionList: true,
  showPagination: false,
  handleFiltersClear: () => {},
  onPageChange: () => {},
  handleFilter: () => {},
  roleBasedMenu: () => {},
  rows: [],
  columns: [],
  handleSelect: () => {},
  page: '',
  sortDirection: 'desc',
  sortBy: 'id',
  onSortChange: () => {},
  loading: false,
  showFilter: true,
};

ExpensesList.propTypes = {
  filterFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  onSortChange: PropTypes.func,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  showActionList: PropTypes.bool,
  showPagination: PropTypes.bool,
  onPageChange: PropTypes.func,
  count: PropTypes.number.isRequired,
  handleFiltersClear: PropTypes.func,
  handleFilter: PropTypes.func,
  roleBasedMenu: PropTypes.func,
  handleSelect: PropTypes.func,
  page: PropTypes.number,
  rows: PropTypes.arrayOf,
  columns: PropTypes.arrayOf,
  loading: PropTypes.bool,
  showFilter: PropTypes.bool,
};

export default ExpensesList;
