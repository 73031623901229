import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import MuiTab from 'components/Molecules/Tab';
import { tdsStyle } from 'components/Organisms/MyPay/TDS/View/style';
import HouseRentDeclarations from './HouseRentDeclarations';
import TDSInstruction from './TDSInstruction';
import OtherIncome from './OtherIncome';
import InvestmentDeclarations from './InvestmentDeclarations';
import AuditLog from './AuditLog';

const TDSTabs = ({
  allowEdit,
  regimeType,
  regimeOptions,
  formik,
  cityOption,
  houseRentDataRow,
  houseRentFormSortDirection,
  houseRentFormSortBy,
  houseRentFormOnSortChange,
  houseRentTableSortDirection,
  houseRentTableSortBy,
  houseRentTableOnSortChange,
  otherIncomeDetails,
  propertyList,
  investmentDeclarations,
  form12bAttachment,
  downloadForm12bAttachment,
  declarationDetails,
  downloadAttachment,
  handleFile,
  investmentDeclarationFormSortDirection,
  investmentDeclarationFormSortBy,
  investmentDeclarationFormOnSortChange,
  investmentDeclarationDataSortDirection,
  investmentDeclarationDataSortBy,
  investmentDeclarationDataOnSortChange,
  auditLogData,
  handleSearch,
  dateOption,
  startDate,
  endDate,
  propertyTypeOption,
  employeeDeductionDeclarationInstance,
}) => {
  const tdsTabList = [
    {
      label: 'Instruction',
      onChange: () => {},
      RenderComponent: TDSInstruction,
      componentProps: {
        regimeOptions,
        allowEdit,
        formik,
        regimeType,
        employeeDeductionDeclarationInstance,
      },
    },
    {
      label: 'House Rent Declarations',
      onChange: () => {},
      RenderComponent: HouseRentDeclarations,
      componentProps: {
        houseRentDataRow,
        cityOption,
        allowEdit,
        formik,
        houseRentFormSortDirection,
        houseRentFormSortBy,
        houseRentFormOnSortChange,
        houseRentTableSortDirection,
        houseRentTableSortBy,
        houseRentTableOnSortChange,
      },
    },
    {
      label: 'House/Property/Other Income',
      onChange: () => {},
      RenderComponent: OtherIncome,
      componentProps: {
        otherIncomeDetails,
        propertyTypeOption,
        propertyList,
        allowEdit,
        formik,
      },
    },
    {
      label: 'Investment Declarations',
      onChange: () => {},
      RenderComponent: InvestmentDeclarations,
      componentProps: {
        form12bAttachment,
        downloadForm12bAttachment,
        declarationDetails,
        downloadAttachment,
        allowEdit,
        handleFile,
        formik,
        investmentDeclarationFormSortDirection,
        investmentDeclarationFormSortBy,
        investmentDeclarations,
        investmentDeclarationFormOnSortChange,
        investmentDeclarationDataSortDirection,
        investmentDeclarationDataSortBy,
        investmentDeclarationDataOnSortChange,
      },
    },
    {
      label: 'Audit Trial',
      onChange: () => {},
      RenderComponent: AuditLog,
      componentProps: {
        commentsMapList: auditLogData,
      },
    },
  ];

  return (
    <Box sx={tdsStyle.tabsWrapper}>
      <MuiTab
        textColor="primary"
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons={false}
        tabs={tdsTabList}
        panelPadding="2rem 3rem"
      />
    </Box>
  );
};

TDSTabs.defaultProps = {
  allowEdit: false,
  regimeType: '',
  regimeOptions: [],
  houseRentDataRow: [],
  houseRentFormSortDirection: 'desc',
  houseRentFormSortBy: 'id',
  houseRentFormOnSortChange: () => {},
  houseRentTableSortDirection: 'desc',
  houseRentTableSortBy: 'id',
  houseRentTableOnSortChange: () => {},
  cityOption: [],
  otherIncomeDetails: {},
  form12bAttachment: '',
  downloadForm12bAttachment: () => {},
  declarationDetails: [],
  downloadAttachment: () => {},
  handleFile: () => {},
  investmentDeclarationFormSortDirection: 'desc',
  investmentDeclarationFormSortBy: 'id',
  investmentDeclarationFormOnSortChange: () => {},
  investmentDeclarationDataSortDirection: 'desc',
  investmentDeclarationDataSortBy: 'id',
  investmentDeclarationDataOnSortChange: () => {},
  auditLogData: [],
  handleSearch: () => {},
  dateOption: [],
  startDate: '',
  endDate: '',
  propertyTypeOption: [],
  formik: {
    handleChange: () => {},
    handleBlur: () => {},
    values: {
      property: [],
      houseRent: [],
      investmentDec: [],
    },
    error: {},
  },
};
TDSTabs.propTypes = {
  allowEdit: PropTypes.bool,
  regimeOptions: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, name: PropTypes.string, onClick: PropTypes.func }),
  ),
  houseRentDataRow: PropTypes.arrayOf(
    PropTypes.shape({
      slNo: PropTypes.number,
      period: PropTypes.string,
      landlordName: PropTypes.string,
      landlordPan: PropTypes.string,
      landlordAddress: PropTypes.string,
      city: PropTypes.string,
      rentPaid: PropTypes.number,
    }),
  ),
  houseRentFormSortDirection: PropTypes.string,
  houseRentFormSortBy: PropTypes.string,
  houseRentFormOnSortChange: PropTypes.func,
  houseRentTableSortDirection: PropTypes.string,
  houseRentTableSortBy: PropTypes.string,
  houseRentTableOnSortChange: PropTypes.func,
  cityOption: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, name: PropTypes.string, onClick: PropTypes.func }),
  ),
  regimeType: PropTypes.string,
  otherIncomeDetails: PropTypes.shape({
    otherSources: PropTypes.number,
    propertyType: PropTypes.string,
    propertyName: PropTypes.string,
    address: PropTypes.string,
    constructionCompletedOn: PropTypes.string,
    loanAmount: PropTypes.number,
    loanBorrowedOn: PropTypes.string,
    lenderName: PropTypes.string,
    lenderPAN: PropTypes.string,
    isJointlyOwnedProperty: PropTypes.string,
    emiInterest1Year: PropTypes.number,
    emiInterest2Year: PropTypes.number,
    lettableValue: PropTypes.number,
    taxPaidToLocalAuthorities: PropTypes.number,
    standardDeductionOnLettableValue: PropTypes.number,
    interestPaidOnHousingLoan: PropTypes.number,
    netIncomeFromHouse: PropTypes.number,
  }),
  form12bAttachment: PropTypes.string,
  downloadForm12bAttachment: PropTypes.func,
  declarationDetails: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      proof: PropTypes.string,
    }),
  ),
  downloadAttachment: PropTypes.func,
  handleFile: PropTypes.func,
  investmentDeclarationFormSortDirection: PropTypes.string,
  investmentDeclarationFormSortBy: PropTypes.string,
  investmentDeclarationFormOnSortChange: PropTypes.func,
  investmentDeclarationDataSortDirection: PropTypes.string,
  investmentDeclarationDataSortBy: PropTypes.string,
  investmentDeclarationDataOnSortChange: PropTypes.func,
  auditLogData: PropTypes.arrayOf(
    PropTypes.shape(
      PropTypes.shape({
        id: PropTypes.num,
        auditLog: PropTypes.shape({
          avatarSrc: PropTypes.string,
          name: PropTypes.string,
          date: PropTypes.string,
        }),

        updatedLog: PropTypes.shape({
          updatedName: PropTypes.string,
          updatedDate: PropTypes.string,
        }),
        handleLog: PropTypes.func,
        handleEdit: PropTypes.func,
        handleAccept: PropTypes.func,
        handleDelete: PropTypes.func,
        rowsPerPage: PropTypes.num,
        totalRecordCount: PropTypes.num,
        minimumRecordsLength: PropTypes.num,
        auditLogRows: PropTypes.arrayOf(
          PropTypes.shape({
            fieldName: PropTypes.string,
            oldValue: PropTypes.string,
            newValue: PropTypes.string,
          }),
        ),
      }),
    ),
  ),
  handleSearch: PropTypes.func,
  dateOption: PropTypes.arrayOf(
    PropTypes.shape(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string, onClick: PropTypes.func })),
  ),
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  propertyTypeOption: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })),
  formik: PropTypes.shape({
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    values: PropTypes.shape({
      regime: PropTypes.string,
    }),
  }),
};

export default TDSTabs;
