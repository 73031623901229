export const ArrayTranspose = (arr) => {
  const outputArr = [];
  arr.forEach((val, ind) => {
    val.forEach((val2, ind2) => {
      if (!outputArr[ind2]) {
        outputArr[ind2] = [];
      }
      outputArr[ind2][ind] = val2;
    });
  });
  return outputArr;
};
