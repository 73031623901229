import React from 'react';
import PropTypes from 'prop-types';
import { StylesProvider, createGenerateClassName } from '@mui/styles';
import { MuiThemeProvider } from './design-tokens/theme/theme';
import { Editor } from './components/Editor';

const generateClassname = createGenerateClassName({
  productionPrefix: 'bit-text-editor',
  seed: 'bit-text-editor',
});

export const TextEditor = ({ dataTestId, setTextEditorFocused, value, onChange }) => {
  const handleInputFocus = () => {
    setTextEditorFocused(true);
  };

  return (
    <StylesProvider generateClassName={generateClassname}>
      <MuiThemeProvider>
        <Editor dataTestId={dataTestId} value={value} onInputFocus={handleInputFocus} onChange={onChange} />
      </MuiThemeProvider>
    </StylesProvider>
  );
};

TextEditor.propTypes = {
  /** Data test id to add unit test cases */
  dataTestId: PropTypes.string,
  /** Update state of text editor focus */
  setTextEditorFocused: PropTypes.func.isRequired,
  /** Text editor value */
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(null || undefined)]),
  /** Callback function, invoke on value change */
  onChange: PropTypes.func.isRequired,
};
