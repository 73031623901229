import React from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import PropTypes from 'prop-types';
import { Box, Divider, Paper, Skeleton, Stack } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import Button from 'components/Atoms/Button';
import { Print } from '@material-ui/icons';
import { headerStyle } from 'components/Molecules/Header/style';
import Table from 'components/mui/Table';
import Breadcrumb from 'components/Molecules/Breadcrumbs';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useColumns from './useColumns';
import { rows } from './rows';
import HeadsTable from './headsTable';
import HRATable from './hraTable';
import DeductionTable from './deductionTable';
import EarningsTable from '../table';
import { deductions, earnings, others, retirals } from './earningsData';
import ExemptionTable from './exemptionDetailsTable';

const headsData = [
  {
    title: 'A. Total Taxable Earnings (From Salary)',
    amount: '₹10000',
  },
  {
    title: 'B. Income from Previous Employer',
    amount: '₹10000',
  },
  {
    title: 'C. Taxable Perquisites Value',
    amount: '₹10000',
  },
  {
    title: 'D. Total Earnings (A + C)',
    amount: '₹10000',
  },
  {
    title: 'E. Less Exemptions Under Section 10',
    amount: '₹10000',
  },
];

const balanceData = [
  {
    title: 'F. Balance (D - E)',
    amount: '₹10000',
  },
  {
    title: 'G. Less Deductions Under Section 16',
    amount: '₹10000',
  },
];

const incomeData = [
  {
    title: 'H. Income Chargeable Under Head Salaries (F - G)',
    amount: '₹10000',
  },
  {
    title: 'I. Add any other income declared by Employee',
    amount: '₹10000',
  },
  {
    title: 'J. Less any other deductions claimed by Employee',
    amount: '₹10000',
  },
  {
    title: 'K. Gross Total Income (H + I - J)',
    amount: '₹10000',
  },
  {
    title: 'L. Less Deductions Under Chapter VI A',
    amount: '₹10000',
  },
];

const eligibleDeductionData = [
  {
    title: 'M. Total Eligible Deduction (Under Section 80)',
    amount: '₹10000',
  },
  {
    title: 'N. Taxable Income (K + B - M) (Rounded to nearest 10s per Section 288A)',
    amount: '₹10000',
  },
  {
    title: 'O. Tax on Income (N)',
    amount: '₹10000',
  },
  {
    title: `P. Less Rebate US 87A, before Surcharge and Cess (applicable
         only if Taxable income under N is less than 5,00,000/-)`,
    amount: '₹10000',
  },
  {
    title: 'Q. Surcharge on Income Tax (O)',
    amount: '₹10000',
  },
  {
    title: 'R. Total Tax Payable (incl of Surcharge) (O - P + Q)',
    amount: '₹10000',
  },
  {
    title: 'S. Education & Health Cess (@4% of R)',
    amount: '₹10000',
  },
  {
    title: 'T. Total Tax Liability (R + S) (rounded to nearest 10s per Section 288B)',
    amount: '₹10000',
  },
  {
    title: 'U. Net Tax Collected YTD (inclusive of Past Employment Taxes from B)',
    amount: '₹10000',
  },
  {
    title: 'V. Tax Pending',
    amount: '₹10000',
  },
  {
    title: 'W. Number of Payruns remaining during current FY',
    amount: '₹10000',
  },
  {
    title: 'X. TDS per pay period (projected)',
    amount: '₹10000',
  },
];

const TaxWorking = ({ loading, breadcrumbData, onSubmit, dropdownOptions }) => {
  const [columns] = useColumns(rows);

  return (
    <PerfectScrollbar>
      <Box mb={1.5}>
        <Breadcrumb breadcrumbs={breadcrumbData} />
      </Box>

      <Typographys variant="h6" color="neutral.700">
        Salary Structure
      </Typographys>
      {loading ? (
        <Box sx={{ py: 4 }}>
          <Skeleton height={42} />
          <Skeleton />
          <Skeleton />
        </Box>
      ) : (
        <>
          <Paper sx={{ overflow: 'auto' }} variant="outlined">
            <Box sx={{ p: 3 }}>
              <Stack direction="row" justifyContent="space-between">
                <Typographys variant="h4">Abeer Kalimohan(1001)</Typographys>
                <Button sx={headerStyle.clockButtonWrap} size="Medium" color="secondary">
                  <Print sx={headerStyle.clockButton} />
                  Clock in
                </Button>
              </Stack>
              <Typographys variant="body2" color="neutral.600">
                Period: 01-Apr-2020 to 31-Mar-2021
              </Typographys>
              <Divider sx={{ my: 2, backgroundColor: 'neutral.200' }} />
              <Typographys variant="body2" color="neutral.800">
                * Indicates Non-Taxable Earnings
              </Typographys>
              <Table columns={columns} rows={rows} keyColumn="category" />
            </Box>
          </Paper>
          <Paper sx={{ mt: 2, overflow: 'auto' }} variant="outlined">
            <Box sx={{ p: 3 }}>
              <HeadsTable headsData={headsData} hasHeader />
              <HRATable hasHeader />
              <Box sx={{ display: 'flex', p: '12px 8px 12px 24px' }}>
                <Box sx={{ width: '75%' }}>
                  <Typographys variant="body2" color="text.200">
                    Leave Travel Allowance:
                  </Typographys>
                </Box>
                <Box sx={{ width: '25%' }}>
                  <Typographys variant="body2">₹10000</Typographys>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', p: '12px 8px 12px 24px' }}>
                <Box sx={{ width: '75%' }}>
                  <Typographys variant="body2" color="text.300">
                    Total Exemption (inclusive of Standard Deduction of ₹ 50,000 (max)):
                  </Typographys>
                </Box>
                <Box sx={{ width: '25%' }}>
                  <Typographys variant="body2">₹10000</Typographys>
                </Box>
              </Box>
              <HeadsTable headsData={balanceData} hasHeader={false} />
              <Typographys variant="caption" color="neutral.600">
                <Box sx={{ pl: '8px' }}>1. Standard Deduction : Section 16(i) </Box>
              </Typographys>
              <Typographys variant="caption" color="neutral.600">
                <Box sx={{ pl: '8px' }}> 2. Tax on Employment : Section 16(iii) : ₹ 2,496 </Box>
              </Typographys>
              <HeadsTable headsData={incomeData} hasHeader={false} />
              <DeductionTable hasHeader />
              <HeadsTable headsData={eligibleDeductionData} hasHeader={false} />
            </Box>
          </Paper>
          <Paper sx={{ mt: 2, overflow: 'auto' }} variant="outlined">
            <Box sx={{ p: 3 }}>
              <EarningsTable rows={earnings} headers={['Earnings', 'Per Period', 'Annual']} />
            </Box>
            <Box sx={{ p: 3 }}>
              <EarningsTable rows={retirals} headers={['Retirals', 'Per Period', 'Annual']} />
            </Box>
            <Box sx={{ p: 3 }}>
              <EarningsTable rows={deductions} headers={['Deductions', 'Per Period', 'Annual']} />
            </Box>
            <Box sx={{ p: 3 }}>
              <EarningsTable rows={others} headers={['Others', 'Per Period', 'Annual']} />
            </Box>
          </Paper>
          <Paper sx={{ mt: 2, overflow: 'auto', p: 3 }} variant="outlined">
            <ExemptionTable />
          </Paper>
        </>
      )}
    </PerfectScrollbar>
  );
};

TaxWorking.defaultProps = {
  breadcrumbData: [
    { id: 1, name: 'Employee List', path: '/company/employee/employees-list', isActive: false },
    {
      id: 2,
      name: 'Employee Records',
      path: '/company/employee/employees-list/records/salaryStructure',
      isActive: false,
    },
    {
      id: 3,
      name: 'Salary Structure',
      path: '/company/employee/employees-list/records/salaryStructure',
      isActive: false,
    },
    {
      id: 4,
      name: 'Tax Working',
      path: '/company/employee/employees-list/records/salaryStructure/taxWorking',
      isActive: true,
    },
  ],
  onSubmit: (data) => {
    console.log(data);
  },
  dropdownOptions: {},
};

TaxWorking.propTypes = {
  onSubmit: PropTypes.func,
  breadcrumbData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
  dropdownOptions: PropTypes.shape({}),
};

export default TaxWorking;
