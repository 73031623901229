import PropTypes from 'prop-types';
import React from 'react';
import Box from '@mui/material/Box';
import Typographys from 'components/Atoms/Typography';
import Dialog from 'components/Molecules/Dialog';
import InputField from 'components/Atoms/InputField/index';
import Grid from '@mui/material/Grid';

const DialogAddNotes = ({
  empData,
  onChange,
  handleComment,
  handleCancel,
  secondaryBtn,
  primaryBtn,
  primaryBtnColor,
  open,
  handleClose,
}) => {
  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      dialogTitle="Add Note"
      dialogDesc={
        <Typographys variant="body2" align="center">
          Employee: {empData.name} {empData.id}
        </Typographys>
      }
      color={primaryBtnColor}
      secondaryBtn={secondaryBtn}
      primaryBtn={primaryBtn}
      handlePrimaryBtn={handleComment}
      handleSecondaryBtn={handleCancel}
      bodyContent={
        <Grid container spacing={0.5}>
          <Grid item xs={6} align="left">
            <Typographys variant="caption" color="neutral.600">
              Supervisor:
            </Typographys>
            <Typographys variant="caption"> {empData.supervisor}</Typographys>
          </Grid>
          <Grid item xs={6} align="left">
            <Typographys variant="caption" color="neutral.600">
              Department:
            </Typographys>
            <Typographys variant="caption"> {empData.department}</Typographys>
          </Grid>
          <Grid item xs={6} align="left">
            <Typographys variant="caption" color="neutral.600">
              Designation:
            </Typographys>
            <Typographys variant="caption"> {empData.designation}</Typographys>
          </Grid>
          <Grid item xs={6} align="left">
            <Typographys variant="caption" color="neutral.600">
              Location:
            </Typographys>
            <Typographys variant="caption"> {empData.location}</Typographys>
          </Grid>
          <Grid item xs={12} mt={1.5}>
            <Typographys variant="body2" align="left">
              Please specify any notes as needed below
            </Typographys>
            <Box mt={1}>
              <InputField
                multiline
                name="username"
                required
                fullWidth
                id="username"
                label="Type here..."
                autoComplete="username"
                autoFocus
                onChange={onChange}
              />
            </Box>
          </Grid>
        </Grid>
      }
    />
  );
};
DialogAddNotes.defaultProps = {
  secondaryBtn: 'Cancel',
  primaryBtn: 'Add Comment',
  handleComment: () => {},
  handleCancel: () => {},
  onChange: () => {},
  primaryBtnColor: 'primary',
  empData: {
    name: 'Uday Kumar',
    id: 10006,
    supervisor: 'Aachman Kabir',
    department: 'Services',
    designation: 'Sr VP - Services',
    location: 'Kolkatta',
  },
  open: true,
  handleClose: () => {},
};
DialogAddNotes.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  onChange: PropTypes.func,
  primaryBtnColor: PropTypes.string,
  handleComment: PropTypes.func,
  handleCancel: PropTypes.func,
  secondaryBtn: PropTypes.string,
  primaryBtn: PropTypes.string,
  empData: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    supervisor: PropTypes.string,
    department: PropTypes.string,
    designation: PropTypes.string,
    location: PropTypes.string,
  }),
};
export default DialogAddNotes;
