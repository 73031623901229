import { formDataApi } from 'api/api';

export const myQueriesListData = async (query) => {
  const response = await formDataApi.post(`restQueryIndex/listMyQueries?${query.toString()}`);
  return response?.data;
};

export const createQueriesData = async () => {
  const response = await formDataApi.get(`restQueryIndex/createQuery`);
  return response;
};

export const saveQuery = async (bodyFormData) => {
  const response = await formDataApi.post(`restQueryIndex/saveQuery`, bodyFormData);
  return response;
};

export const saveSubmitQuery = async (bodyFormData) => {
  const response = await formDataApi.post(`restQueryIndex/saveSubmitQuery`, bodyFormData);
  return response;
};

export const listQueries = async (query) => {
  const response = await formDataApi.get(`restQueryIndex/listQueries?${query.toString()}`);
  return response;
};

export const listMyActionbaleQueries = async (query) => {
  const response = await formDataApi.post(`restQueryIndex/listMyActionbaleQueries?${query.toString()}`);
  return response;
};

export const viewQuery = async (query) => {
  const response = await formDataApi.post(`restQueryIndex/viewQuery?${query.toString()}`);
  return response;
};

export const modifyQuery = async (query) => {
  const response = await formDataApi.post(`restQueryIndex/modifyQuery?${query.toString()}`);
  return response;
};

export const modifyQueryAction = async (bodyFormData) => {
  const response = await formDataApi.post(`restQueryIndex/modifyQueryAction`, bodyFormData);
  return response;
};

export const deleteQuery = async (bodyFormData) => {
  const response = await formDataApi.post(`restQueryIndex/deleteQuery`, bodyFormData);
  return response;
};

export const submitQuery = async (bodyFormData) => {
  const response = await formDataApi.post(`restQueryIndex/submitQuery`, bodyFormData);
  return response;
};

export const actionQuery = async (bodyFormData) => {
  const response = await formDataApi.post(`restQueryIndex/actionQuery`, bodyFormData);
  return response;
};

export const actionQueryAction = async (bodyFormData) => {
  const response = await formDataApi.post(`restQueryIndex/actionQueryAction`, bodyFormData);
  return response;
};

export const recallQuery = async (bodyFormData) => {
  const response = await formDataApi.post(`restQueryIndex/recallQuery`, bodyFormData);
  return response;
};

export const escalateQuery = async (bodyFormData) => {
  const response = await formDataApi.post(`restQueryIndex/escalateQuery`, bodyFormData);
  return response;
};

export const escalateQueryAction = async (bodyFormData) => {
  const response = await formDataApi.post(`restQueryIndex/escalateQueryAction`, bodyFormData);
  return response;
};

export const revertQuery = async (bodyFormData) => {
  const response = await formDataApi.post(`restQueryIndex/revertQuery`, bodyFormData);
  return response;
};

export const reassignQuery = async (bodyFormData) => {
  const response = await formDataApi.post(`restQueryIndex/reassignQuery`, bodyFormData);
  return response;
};

export const reassignQueryAction = async (bodyFormData) => {
  const response = await formDataApi.post(`restQueryIndex/reassignQueryAction`, bodyFormData);
  return response;
};

export const reopenQuery = async (bodyFormData) => {
  const response = await formDataApi.post(`restQueryIndex/reopenQuery`, bodyFormData);
  return response;
};

export const reopenQueryAction = async (bodyFormData) => {
  const response = await formDataApi.post(`restQueryIndex/reopenQueryAction`, bodyFormData);
  return response;
};

export const downloadDocumentAction = async (bodyFormData) => {
  const response = await formDataApi.post(`restQueryIndex/downloadAttachment`, bodyFormData);
  return response;
};

export const searchQuery = async () => {
  const response = await formDataApi.get(`restQueryIndex/searchQuery`);
  return response;
};

export const searchQueryAction = async (bodyFormData) => {
  const response = await formDataApi.post(`restQueryIndex/searchQueryAction`, bodyFormData);
  return response;
};

export const listMyQueries = async (bodyFormData) => {
  const response = await formDataApi.post(`restQueryIndex/listMyQueries`, bodyFormData);
  return response?.data;
};

export const createQuery = async () => {
  const response = await formDataApi.get(`restQueryIndex/createQuery`);
  return response;
};
