import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, useTheme } from '@mui/material';
import SalaryDetails from 'components/Organisms/MyPay/Dashboard/SalaryDetails';
import Typographys from 'components/Atoms/Typography';
import GraphColor from 'utils/GraphColor';
import InfoCard from 'components/Molecules/InfoCard';
import PieCharts from 'components/Organisms/Charts/PieCharts';
import { isEmpty } from 'lodash';
import { cardStyle } from './style';

const Dashboard = ({
  grossAmount,
  lastCredited,
  salaryDetails,

  data,
  colors,
  legendOptions,
  chartOptions,
  chartInfoLeft,

  dataWiseDetails,
  chartOptionsWiseDetails,

  dataWiseAggregates,
  chartOptionsWiseAggregates,
}) => {
  const theme = useTheme();
  return (
    <Box>
      <Box sx={cardStyle.card}>
        <SalaryDetails amount={grossAmount} lastCredited={lastCredited} />
        {!isEmpty(data) ? (
          <>
            <Divider
              sx={{ borderWdith: '0.125rem', mx: '11.5rem', borderColor: theme.palette.neutral[200] }}
              orientation="vertical"
              variant="fullWidth"
              flexItem
            />
            <Box>
              <PieCharts
                data={data}
                colors={colors}
                legendOptions={legendOptions}
                chartOptions={chartOptions}
                chartInfoLeft={chartInfoLeft}
                isShowChartInfo
                isShowCustomLegend
              />
            </Box>
          </>
        ) : (
          ''
        )}
      </Box>
      <Box sx={{ display: 'flex', gap: 1, my: 2 }}>
        {salaryDetails.map((item) => {
          return <InfoCard heading={item.title} value={item.amount} />;
        })}
      </Box>
      <Box sx={{ display: 'flex', gap: 1, my: 2, width: '100%' }}>
        <Box sx={cardStyle.card}>
          <Box>
            <Box>
              <Typographys variant="h6" color="neutral.800">
                Payhead wise details
              </Typographys>
            </Box>
            {!isEmpty(dataWiseDetails) ? (
              <PieCharts
                data={dataWiseDetails}
                colors={colors}
                legendOptions={legendOptions}
                chartOptions={chartOptionsWiseDetails}
                chartInfoLeft={chartInfoLeft}
                isShowChartInfo={false}
                isShowCustomLegend
              />
            ) : (
              <Box sx={{ textAlign: 'center', padding: '1rem' }}>
                <Typographys variant="body2" color="text.secondary">
                  No data found
                </Typographys>
              </Box>
            )}
          </Box>
        </Box>
        <Box sx={cardStyle.card}>
          <Box>
            <Box>
              <Typographys variant="h6" color="neutral.800">
                Payhead type wise aggregates
              </Typographys>
            </Box>
            {!isEmpty(dataWiseDetails) ? (
              <PieCharts
                data={dataWiseAggregates}
                colors={colors}
                legendOptions={legendOptions}
                chartOptions={chartOptionsWiseAggregates}
                chartInfoLeft={chartInfoLeft}
                isShowChartInfo={false}
                isShowCustomLegend
              />
            ) : (
              <Box sx={{ textAlign: 'center', padding: '1rem' }}>
                <Typographys variant="body2" color="text.secondary">
                  No data found
                </Typographys>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

Dashboard.defaultProps = {
  grossAmount: '-',
  lastCredited: '-',
  salaryDetails: [],

  data: [],
  colors: GraphColor('pieChartsColors'),
  legendOptions: {},
  chartOptions: {},
  chartInfoLeft: '',

  dataWiseDetails: [],
  chartOptionsWiseDetails: {},

  dataWiseAggregates: [],
  chartOptionsWiseAggregates: {},
};
Dashboard.propTypes = {
  grossAmount: PropTypes.string,
  lastCredited: PropTypes.string,
  salaryDetails: PropTypes.arrayOf(PropTypes.shape({})),

  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    }),
  ),

  dataWiseDetails: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  chartOptionsWiseDetails: PropTypes.shape({}),

  dataWiseAggregates: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  chartOptionsWiseAggregates: PropTypes.shape({}),
  colors: PropTypes.arrayOf(PropTypes.string),
  legendOptions: PropTypes.shape({}),
  chartOptions: PropTypes.shape({}),
  chartInfoLeft: PropTypes.string,
};

export default Dashboard;
