import React from 'react';
import { Chip, Typography } from '@mui/material';
import { validateDateElseHyphen } from 'utils/commonUtils';

const columns = [
  {
    fieldName: '',
    label: 'Employee Name',
    formatValue: ({ row }) => <Typography variant="body2">{row?.employee?.fullName}</Typography>,
  },
  {
    fieldName: 'status',
    label: 'Status',
    formatValue: ({ row, column }) => {
      return <Chip label={row?.[column?.fieldName]} sx={{ p: '0 !important' }} />;
    },
  },
  {
    fieldName: 'changeType',
    label: 'Change Type',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'effectiveStartDate',
    label: 'Effective From',
    formatValue: ({ row }) => validateDateElseHyphen(row?.effectiveStartDate, 'DD MMMM YYYY'),
  },
  {
    fieldName: 'effectiveEndDate',
    label: 'Effective Up To',
    formatValue: ({ row }) => validateDateElseHyphen(row?.effectiveEndDate, 'DD MMMM YYYY'),
  },
  {
    fieldName: 'currentApprover',
    label: 'Current Approver',
    formatValue: ({ row }) => row?.currentApprover && row?.currentApprover?.fullName,
  },
];

const useColumns = () => {
  return [columns];
};

export default useColumns;
