import PropTypes from 'prop-types';
import React from 'react';
import Box from '@mui/material/Box';
import Typographys from 'components/Atoms/Typography';
import Dialog from 'components/Molecules/Dialog';
import successIcon from 'assets/images/success.svg';

const DialogSuccess = ({
  handleComment,
  handleCancel,
  secondaryBtn,
  primaryBtn,
  primaryBtnColor,
  open,
  handleClose,
}) => {
  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      color={primaryBtnColor}
      secondaryBtn={secondaryBtn}
      primaryBtn={primaryBtn}
      handlePrimaryBtn={handleComment}
      handleSecondaryBtn={handleCancel}
      bodyContent={
        <Box>
          <img src={successIcon} height="59px" width="59px" alt="success" />
          <Box sx={{ mt: '1.75rem' }}>
            <Typographys variant="h4" align="center">
              Employee Added Successfully
            </Typographys>
          </Box>
        </Box>
      }
    />
  );
};
DialogSuccess.defaultProps = {
  primaryBtn: 'Go to employee list',
  secondaryBtn: '',
  handleComment: () => {},
  handleCancel: () => {},
  primaryBtnColor: 'primary',
  open: true,
  handleClose: () => {},
};
DialogSuccess.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  primaryBtnColor: PropTypes.string,
  handleComment: PropTypes.func,
  handleCancel: PropTypes.func,
  primaryBtn: PropTypes.string,
  secondaryBtn: PropTypes.string,
};
export default DialogSuccess;
