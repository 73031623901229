import PropTypes from 'prop-types';
import React from 'react';
import Dialog from 'components/Molecules/Dialog';
import Typographys from 'components/Atoms/Typography';

const DeleteLCPolicy = ({ handleDelete, handleCancel, open, handleClose, deletePolicyName }) => {
  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      dialogTitle="Delete Life Cycle Policy"
      dialogDesc=<Typographys component="div" color="neutral.600" align="center">
        Are you want to delete Default &nbsp;
        <Typographys display="inline" color="neutral.800">
          {deletePolicyName || 'Life Cycle Policy?'}
        </Typographys>
      </Typographys>
      secondaryBtn="Cancel"
      primaryBtn="Delete"
      handlePrimaryBtn={handleDelete}
      handleSecondaryBtn={handleCancel}
      primaryBtnColor="error"
      bodyContent={false}
    />
  );
};
DeleteLCPolicy.defaultProps = {
  handleDelete: () => {},
  handleCancel: () => {},
  open: true,
  handleClose: () => {},
  deletePolicyName: '',
};
DeleteLCPolicy.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleDelete: PropTypes.func,
  handleCancel: PropTypes.func,
  deletePolicyName: PropTypes.string,
};
export default DeleteLCPolicy;
