import React from 'react';
import { Box } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import Typography from '../Typography';
import { attachmentStyle } from './style';

const Attachments = ({
  handleChange,
  value,
  name,
  id,
  handleDownloadFile,
  handleDelete,
  isDeleteIcon,
  accept,
  wrapperStyles = {},
  title,
  isAttachIcon,
}) => {
  return (
    <Box sx={{ ...attachmentStyle.wrapper, ...wrapperStyles }}>
      <Box sx={attachmentStyle.attachFile}>
        <Button variant="text" component="label" sx={attachmentStyle.attachFileIcon}>
          {!!isAttachIcon && <AttachFileIcon />}
          <Typography variant="body1" color="secondary.500">
            {title || 'Attachments'}
          </Typography>
          <input
            name={name}
            id={id}
            hidden
            accept={accept}
            multiple
            type="file"
            onChange={handleChange}
            style={{ cursor: 'pointer' }}
          />
        </Button>
      </Box>
      {(value?.name || value?.fileName) && (
        <Box sx={{ display: 'flex', paddingLeft: '1.5rem' }}>
          <Button
            sx={{ fontWeight: '500', maxWidth: '100%' }}
            variant="text"
            color="secondary"
            onClick={() => {
              handleDownloadFile(value);
            }}
          >
            {value?.name || value?.fileName}
          </Button>
          {isDeleteIcon && <DeleteIcon size={18} onClick={handleDelete} color="action" />}
        </Box>
      )}
    </Box>
  );
};

Attachments.defaultProps = {
  isDeleteIcon: false,
  handleDelete: () => {},
  handleDownloadFile: () => {},
  handleChange: () => {},
  value: '',
  name: '',
  id: '',
  accept:
    'image/*,application/pdf,.zip,.rar,.7zip,application/zip,application/x-zip,application/x-zip-compressed,application/vnd.rar',
  wrapperStyles: {},
  title: 'Attachments',
  isAttachIcon: true,
};

Attachments.propTypes = {
  isDeleteIcon: PropTypes.bool,
  handleDelete: PropTypes.func,
  handleDownloadFile: PropTypes.func,
  handleChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  accept: PropTypes.string,
  wrapperStyles: PropTypes.shape({}),
  title: PropTypes.string,
  isAttachIcon: PropTypes.bool,
};

export default Attachments;
