import { useState } from 'react';
import useToast from 'components/Provider/useToast';
import { deletePreviousEmploymentTaxAction, submitPreviousEmploymentTaxAction } from 'api/home/myPay';
import get from 'lodash/get';
import { useNavigate } from 'react-router-dom';
import useDownloadFile from 'hooks/useDownloadFile';

const useService = () => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showSubmitDialog, setShowSubmitDialog] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const addToast = useToast();
  const navigate = useNavigate();
  const downloadFile = useDownloadFile();

  const addPastEmploymentTax = () => {
    navigate('/home/mypay/past-employer-taxes/add');
  };

  const listPastEmploymentTax = () => {
    navigate('/home/mypay/past-employer-taxes');
  };

  const viewPastEmploymentTax = (row) => {
    const id = get(row, 'id', '');
    navigate(`/home/mypay/past-employer-taxes/view/${id}`);
  };

  const editPastEmploymentTax = (row) => {
    const id = get(row, 'id', '');
    navigate(`/home/mypay/past-employer-taxes/edit/${id}`);
  };

  const handleAttachment = (row) => {
    downloadFile({ docid: row?.id }, row?.fileName, 'restPayIndex/downloadAttachment');
  };

  const handleDeleteDialog = () => {
    setShowDeleteDialog(!showDeleteDialog);
  };

  const handleSubmitDialog = () => {
    setShowSubmitDialog(!showSubmitDialog);
  };

  const deletePastEmploymentTax = async (row, listPage) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', get(row, 'id', ''));
      const response = await deletePreviousEmploymentTaxAction(bodyFormData);
      if (response.status === 'SUCCESS') {
        addToast({ type: 'success', title: 'SUCCESS', message: response?.message });
        if (listPage) {
          listPastEmploymentTax();
        } else {
          setRefreshPage(!refreshPage);
        }
      } else {
        addToast({ type: 'error', title: 'ERROR', message: response?.message });
      }
    } catch (error) {
      addToast({ type: 'error', title: 'ERROR', message: error?.message });
    } finally {
      handleDeleteDialog(false);
    }
  };

  const submitPastEmploymentTax = async (row) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', get(row, 'id', ''));
      const response = await submitPreviousEmploymentTaxAction(bodyFormData);
      if (response.status === 'SUCCESS') {
        addToast({ type: 'success', title: 'SUCCESS', message: response?.message });
        setRefreshPage(!refreshPage);
      } else {
        addToast({ type: 'error', title: 'ERROR', message: response?.message });
      }
    } catch (error) {
      addToast({ type: 'error', title: 'ERROR', message: error?.message });
    } finally {
      handleSubmitDialog(false);
    }
  };

  return {
    addPastEmploymentTax,
    listPastEmploymentTax,
    showDeleteDialog,
    handleDeleteDialog,
    deletePastEmploymentTax,
    refreshPage,
    submitPastEmploymentTax,
    viewPastEmploymentTax,
    editPastEmploymentTax,
    handleAttachment,
    showSubmitDialog,
    handleSubmitDialog,
  };
};

export default useService;
