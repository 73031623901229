import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchCompareTDS } from 'store';
import { Box, Typography, Skeleton } from '@mui/material';
import { useThunk } from 'hooks/useThunk';
import CompareTDSTemplateTemplate from 'components/Templates/MyPay/CompareTDS';
import dayjs from 'dayjs';
import useToast from 'components/Provider/useToast';
import getColumns from './getColumns';
import { validateNumberElseHyphen } from '../../../../utils/commonUtils';

const CompareTDSPage = () => {
  const addToast = useToast();
  const categoryRowsData = [];

  const [getCompareTDSData, isLoadingCompareTDS, loadingErrorCompareTDS] = useThunk(fetchCompareTDS);

  useEffect(() => {
    getCompareTDSData();
  }, [getCompareTDSData]);

  const { compareTDSDetails } = useSelector((state) => state.compareTDS);

  const compareTDSApiData = compareTDSDetails?.data;
  const sortedPayPeriods = !compareTDSApiData?.payPeriodsMap
    ? []
    : Object.values(compareTDSApiData?.payPeriodsMap).sort((a, b) => a.payPeriodIndex - b.payPeriodIndex);

  // Category Table Column Names
  const [categoryColumnsData] = getColumns(sortedPayPeriods);

  if (isLoadingCompareTDS) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (loadingErrorCompareTDS !== null) {
    return (
      <Typography variant="h6">
        {loadingErrorCompareTDS?.message || 'Error while fetching compare TDS information.'}
      </Typography>
    );
  }

  let amountData;
  let hraAmount;
  let totalRentPaid;
  let currencySymbol;
  let deductionTableData;
  let hraData;
  let incomeData;
  let employerNames;
  let statutoryData;
  let tdsWorkingProfile;
  let basicPerc;
  let standardDeduction;

  if (compareTDSApiData) {
    try {
      const payheadTypesMap = compareTDSApiData?.payheadTypesMap;
      const taxWorkingInstance = compareTDSApiData?.taxWorkingInstance;
      const earliestProcessedPayPeriodIndex =
        compareTDSApiData?.earliestProcessedPayPeriod && compareTDSApiData?.earliestProcessedPayPeriod?.payPeriodIndex
          ? compareTDSApiData.earliestProcessedPayPeriod.payPeriodIndex
          : 0;
      // Data for profile heading
      tdsWorkingProfile = {
        roleName: taxWorkingInstance?.employee?.fullName || '',
        profile: taxWorkingInstance?.employee?.profile?.employeeID || '',
      };

      // Category Table Row Data

      // Earnings rows
      const totalCalc = {};
      sortedPayPeriods.forEach((payPeriod) => {
        totalCalc[dayjs(payPeriod?.payPeriodStartDate).format('MMMM').toLowerCase()] = 0;
      });

      const payheadsMapSortedAsPerName = Object.values(compareTDSApiData?.payheadsMap || {}).sort((a, b) =>
        a.name.localeCompare(b.name),
      );
      payheadsMapSortedAsPerName.forEach((payhead) => {
        let payheadSubTotal = 0;
        if (
          !payhead.isInternalPayhead &&
          (payhead.payheadType === payheadTypesMap?.PAYHEAD_EARNINGS ||
            payhead.payheadType === payheadTypesMap?.PAYHEAD_BENEFITS ||
            payhead.payheadType === payheadTypesMap?.PAYHEAD_REIMBURSEMENTS)
        ) {
          const rowDataObj = {
            category: `${payhead.name} ${!payhead.taxable ? '*' : ''}`,
          };

          sortedPayPeriods.forEach((payPeriod) => {
            const ppIndexInt = payPeriod?.payPeriodIndex ? payPeriod?.payPeriodIndex?.intValue : 0;
            const empFactor = compareTDSApiData?.empDaysFactorMap[ppIndexInt]
              ? compareTDSApiData.empDaysFactorMap[ppIndexInt]
              : 0.0;

            let entryValue = 0;
            if (compareTDSApiData?.payPeriodSalSlipLineEntriesMap[payPeriod.id]?.[payhead.id]) {
              entryValue = compareTDSApiData.payPeriodSalSlipLineEntriesMap[payPeriod.id][payhead.id];
            } else if (
              compareTDSApiData?.salStructLineEntriesMap[payhead.id] &&
              ppIndexInt >= earliestProcessedPayPeriodIndex
            ) {
              entryValue = compareTDSApiData?.salStructLineEntriesMap?.[payhead.id]
                ? empFactor * compareTDSApiData.salStructLineEntriesMap[payhead.id]
                : 0;
            }
            rowDataObj[dayjs(payPeriod?.payPeriodStartDate).format('MMMM').toLowerCase()] = entryValue;
            payheadSubTotal += entryValue;
            totalCalc[dayjs(payPeriod?.payPeriodStartDate).format('MMMM').toLowerCase()] += entryValue;
          });
          rowDataObj.subTotal = payheadSubTotal;
          categoryRowsData.push(rowDataObj);
        }
      });

      // Total Earnings Row
      let rowDataObj = {
        category: 'Total Earnings',
      };
      Object.keys(totalCalc).forEach((totalCalcIndex) => {
        rowDataObj[totalCalcIndex] = totalCalc[totalCalcIndex];
      });
      const sum = Object.values(totalCalc).reduce((partialSum, a) => partialSum + a, 0);
      rowDataObj.subTotal = sum;
      categoryRowsData.push(rowDataObj);

      // Loss Of Pay (days)
      rowDataObj = {
        category: 'Loss Of Pay (days)',
      };
      let lopTotal = 0;
      sortedPayPeriods.forEach((payPeriod) => {
        rowDataObj[dayjs(payPeriod?.payPeriodStartDate).format('MMMM').toLowerCase()] =
          compareTDSApiData?.payPeriodLOPMap &&
          Object.keys(compareTDSApiData?.payPeriodLOPMap).length &&
          compareTDSApiData?.payPeriodLOPMap[payPeriod.id]
            ? compareTDSApiData?.payPeriodLOPMap[payPeriod.id]
            : 0;
        lopTotal += compareTDSApiData?.payPeriodLOPMap?.[payPeriod.id]
          ? compareTDSApiData.payPeriodLOPMap[payPeriod.id]
          : 0;
      });
      rowDataObj.subTotal = lopTotal;
      categoryRowsData.push(rowDataObj);

      // Deduction rows
      const totalDeductionCalc = {};
      sortedPayPeriods.forEach((payPeriod) => {
        totalDeductionCalc[dayjs(payPeriod?.payPeriodStartDate).format('MMMM').toLowerCase()] = 0;
      });

      let projectedPerPeriodTDS;
      if (compareTDSApiData?.numProjectedPayPeriods && taxWorkingInstance?.totalTaxLiability) {
        const netTaxPaidYTD = taxWorkingInstance?.netTaxPaidYTD || 0;
        projectedPerPeriodTDS =
          Math.round(
            (100 * (taxWorkingInstance.totalTaxLiability - netTaxPaidYTD)) / compareTDSApiData.numProjectedPayPeriods,
          ) / 100;
      } else {
        projectedPerPeriodTDS = taxWorkingInstance?.totalPerPeriodTDS || '';
      }

      payheadsMapSortedAsPerName.forEach((payhead) => {
        let payheadSubTotal = 0;
        if (
          !payhead.isInternalPayhead &&
          (payhead.payheadType === payheadTypesMap?.PAYHEAD_DEDUCTIONS ||
            payhead.payheadType === payheadTypesMap?.PAYHEAD_STATUTORY_DEDUCTIONS_EMPLOYEE)
        ) {
          rowDataObj = {
            category: payhead.name,
          };
          sortedPayPeriods.forEach((payPeriod) => {
            const ppIndexInt = payPeriod?.payPeriodIndex ? payPeriod?.payPeriodIndex?.intValue : 0;
            const empFactor = compareTDSApiData?.empDaysFactorMap[ppIndexInt]
              ? compareTDSApiData.empDaysFactorMap[ppIndexInt]
              : 0.0;

            const salSlipProcessed = compareTDSApiData?.payPeriodSalSlipLineEntriesMap?.[payPeriod.id] ? 1 : 0;
            let entryValue = 0;
            if (compareTDSApiData?.payPeriodSalSlipLineEntriesMap?.[payPeriod.id]?.[payhead.id]) {
              entryValue = compareTDSApiData.payPeriodSalSlipLineEntriesMap[payPeriod.id][payhead.id];
            } else if (
              compareTDSApiData?.salStructLineEntriesMap[payhead.id] &&
              ppIndexInt >= earliestProcessedPayPeriodIndex
            ) {
              entryValue = empFactor * compareTDSApiData.salStructLineEntriesMap[payhead.id];
            } else if (
              payhead.name === 'TDS' &&
              salSlipProcessed === 0 &&
              ppIndexInt >= earliestProcessedPayPeriodIndex &&
              compareTDSApiData.empDaysFactorMap[ppIndexInt]
            ) {
              entryValue = projectedPerPeriodTDS;
            }
            rowDataObj[dayjs(payPeriod?.payPeriodStartDate).format('MMMM').toLowerCase()] = entryValue;
            payheadSubTotal += entryValue;
            totalDeductionCalc[dayjs(payPeriod?.payPeriodStartDate).format('MMMM').toLowerCase()] += entryValue;
          });
          rowDataObj.subTotal = payheadSubTotal;
          categoryRowsData.push(rowDataObj);
        }
      });

      // Other Deductions Type Row
      payheadsMapSortedAsPerName.forEach((payhead) => {
        let payheadSubTotal = 0;
        if (
          !payhead.isInternalPayhead &&
          payhead.payheadType === payheadTypesMap?.PAYHEAD_PERQUISITES &&
          payhead.name === 'InterestFree/ConcessionalLoan'
        ) {
          rowDataObj = {
            category: payhead.name,
          };
          sortedPayPeriods.forEach((payPeriod) => {
            const ppIndexInt = payPeriod?.payPeriodIndex ? payPeriod?.payPeriodIndex?.intValue : 0;
            const empFactor = compareTDSApiData?.empDaysFactorMap[ppIndexInt]
              ? compareTDSApiData.empDaysFactorMap[ppIndexInt]
              : 0.0;

            const salSlipProcessed = compareTDSApiData?.payPeriodSalSlipLineEntriesMap?.[payPeriod.id] ? 1 : 0;
            let entryValue = 0;
            if (compareTDSApiData?.payPeriodSalSlipLineEntriesMap?.[payPeriod.id]?.[payhead.id]) {
              entryValue = compareTDSApiData.payPeriodSalSlipLineEntriesMap[payPeriod.id][payhead.id];
            } else if (
              compareTDSApiData?.salStructLineEntriesMap[payhead.id] &&
              ppIndexInt >= earliestProcessedPayPeriodIndex &&
              ppIndexInt > compareTDSApiData?.lastProcessedPayPeriodIndex
            ) {
              entryValue = empFactor * compareTDSApiData.salStructLineEntriesMap[payhead.id];
            } else if (
              payhead.name === 'TDS' &&
              salSlipProcessed === 0 &&
              ppIndexInt >= earliestProcessedPayPeriodIndex &&
              compareTDSApiData.empDaysFactorMap[ppIndexInt] &&
              ppIndexInt > compareTDSApiData?.lastProcessedPayPeriodIndex
            ) {
              entryValue = compareTDSApiData?.computedPerPeriodTDS;
            }
            rowDataObj[dayjs(payPeriod?.payPeriodStartDate).format('MMMM').toLowerCase()] = entryValue;
            payheadSubTotal += entryValue;
            totalDeductionCalc[dayjs(payPeriod?.payPeriodStartDate).format('MMMM').toLowerCase()] += entryValue;
          });
          rowDataObj.subTotal = payheadSubTotal;
          categoryRowsData.push(rowDataObj);
        }
      });

      // Total Deductions Row
      rowDataObj = {
        category: 'Total Deductions',
      };
      Object.keys(totalDeductionCalc).forEach((totalDedCalcInd) => {
        rowDataObj[totalDedCalcInd] = totalDeductionCalc[totalDedCalcInd];
      });
      const deductionsSum = Object.values(totalDeductionCalc).reduce((partialSum, a) => partialSum + a, 0);
      rowDataObj.subTotal = deductionsSum;
      categoryRowsData.push(rowDataObj);

      // Net Earnings
      rowDataObj = {
        category: 'Net Earnings',
      };

      let earningsTotal = 0;
      let deductionsTotal = 0;

      sortedPayPeriods.forEach((payPeriod) => {
        const ppIndexInt = payPeriod?.payPeriodIndex ? payPeriod?.payPeriodIndex?.intValue : 0;
        const empFactor = compareTDSApiData?.empDaysFactorMap[ppIndexInt]
          ? compareTDSApiData.empDaysFactorMap[ppIndexInt]
          : 0.0;
        const salSlipProcessed = compareTDSApiData?.payPeriodSalSlipLineEntriesMap?.[payPeriod.id] ? 1 : 0;
        let periodTotal = 0;

        payheadsMapSortedAsPerName.forEach((payhead) => {
          if (
            !payhead.isInternalPayhead &&
            (payhead.payheadType === payheadTypesMap?.PAYHEAD_EARNINGS ||
              payhead.payheadType === payheadTypesMap?.PAYHEAD_BENEFITS ||
              payhead.payheadType === payheadTypesMap?.PAYHEAD_REIMBURSEMENTS)
          ) {
            if (compareTDSApiData?.payPeriodSalSlipLineEntriesMap?.[payPeriod.id]?.[payhead.id]) {
              periodTotal += compareTDSApiData.payPeriodSalSlipLineEntriesMap[payPeriod.id][payhead.id];
              earningsTotal += compareTDSApiData.payPeriodSalSlipLineEntriesMap[payPeriod.id][payhead.id];
            } else if (
              compareTDSApiData?.salStructLineEntriesMap[payhead.id] &&
              ppIndexInt >= earliestProcessedPayPeriodIndex
            ) {
              periodTotal += compareTDSApiData?.salStructLineEntriesMap?.[payhead.id]
                ? empFactor * compareTDSApiData.salStructLineEntriesMap[payhead.id]
                : 0;
              earningsTotal += compareTDSApiData?.salStructLineEntriesMap?.[payhead.id]
                ? empFactor * compareTDSApiData.salStructLineEntriesMap[payhead.id]
                : 0;
            }
          } else if (
            !payhead.isInternalPayhead &&
            (payhead.payheadType === payheadTypesMap?.PAYHEAD_DEDUCTIONS ||
              payhead.payheadType === payheadTypesMap?.PAYHEAD_STATUTORY_DEDUCTIONS_EMPLOYEE)
          ) {
            if (compareTDSApiData?.payPeriodSalSlipLineEntriesMap?.[payPeriod.id]?.[payhead.id]) {
              periodTotal -= compareTDSApiData.payPeriodSalSlipLineEntriesMap[payPeriod.id][payhead.id];
              deductionsTotal += compareTDSApiData.payPeriodSalSlipLineEntriesMap[payPeriod.id][payhead.id];
            } else if (
              compareTDSApiData?.salStructLineEntriesMap?.[payhead.id] &&
              ppIndexInt >= earliestProcessedPayPeriodIndex
            ) {
              periodTotal -= compareTDSApiData?.salStructLineEntriesMap?.[payhead.id]
                ? empFactor * compareTDSApiData.salStructLineEntriesMap[payhead.id]
                : 0;
              deductionsTotal += compareTDSApiData?.salStructLineEntriesMap?.[payhead.id]
                ? empFactor * compareTDSApiData.salStructLineEntriesMap[payhead.id]
                : 0;
            } else if (
              payhead.name === 'TDS' &&
              salSlipProcessed === 0 &&
              ppIndexInt >= earliestProcessedPayPeriodIndex &&
              compareTDSApiData.empDaysFactorMap[ppIndexInt]
            ) {
              periodTotal -= projectedPerPeriodTDS;
              deductionsTotal += projectedPerPeriodTDS;
            }
          }
        });
        rowDataObj[dayjs(payPeriod?.payPeriodStartDate).format('MMMM').toLowerCase()] = periodTotal;
      });
      rowDataObj.subTotal = earningsTotal - deductionsTotal;
      categoryRowsData.push(rowDataObj);

      // Heads Table

      // Computation for row b
      let pastEmpTaxExist = 0;
      incomeData = [];
      employerNames = [];
      if (taxWorkingInstance?.previousEmployerTaxDetails) {
        taxWorkingInstance.previousEmployerTaxDetails.forEach((employerDetail) => {
          let incomeDataItem = [];
          if (taxWorkingInstance?.financialYear && employerDetail?.financialYear) {
            if (taxWorkingInstance.financialYear === employerDetail.financialYear) {
              employerNames.push(employerDetail?.employerName || '');
              incomeDataItem = [
                {
                  title: 'Income chargeable under head salaries',
                  amount: employerDetail?.incomeChargeableUnderHeadSalaries || '',
                },
                {
                  title: 'Deducations Under Chapter VI A',
                  amount: employerDetail?.deductionUnderChapterVIA || '',
                },
                {
                  title: 'Net Tax Payable',
                  amount: employerDetail?.netTaxPayable || '',
                },
                {
                  title: 'Net Tax Deducted',
                  amount: employerDetail?.netTaxDeducted || '',
                },
                {
                  title: 'Net Professional Tax Paid',
                  amount: employerDetail?.netProfessionalTaxPaid || '',
                },
                {
                  title: 'Net PF Contributed',
                  amount: employerDetail?.netPFContributed || '',
                },
                {
                  title: 'Net Tax Payable / Refundable',
                  amount: employerDetail?.taxPayableRefundable || '',
                },
              ];
              pastEmpTaxExist = 1;
            }
            if (incomeDataItem.length > 0) {
              incomeData.push(incomeDataItem);
            }
          }
        });
      }

      // Computation for row d
      let totalEarningsOldRegime = compareTDSApiData?.tdsMapOldRegime?.totalAnnualEarningsUnderPayheadTypeEarnings
        ? compareTDSApiData?.tdsMapOldRegime.totalAnnualEarningsUnderPayheadTypeEarnings
        : 0;
      totalEarningsOldRegime += compareTDSApiData?.tdsMapOldRegime?.totalAnnualTaxablePerquisites
        ? compareTDSApiData.tdsMapOldRegime.totalAnnualTaxablePerquisites
        : 0;

      let totalEarningsNewRegime = compareTDSApiData?.tdsMapNewRegime?.totalAnnualEarningsUnderPayheadTypeEarnings
        ? compareTDSApiData?.tdsMapNewRegime.totalAnnualEarningsUnderPayheadTypeEarnings
        : 0;
      totalEarningsNewRegime += compareTDSApiData?.tdsMapNewRegime?.totalAnnualTaxablePerquisites
        ? compareTDSApiData.tdsMapNewRegime.totalAnnualTaxablePerquisites
        : 0;

      // Computation for row e
      let hraExempted = 0;
      const totalHRAReceived = compareTDSApiData?.tdsMapOldRegime?.totalHRARecieved;
      const basicUsedForHRAExemption = compareTDSApiData?.tdsMapOldRegime?.basicUsedForHRAExemption;
      const rentPaidInExcessOfBasicThreshold = taxWorkingInstance?.rentPaidInExcessOfBasicThreshold;
      if (totalHRAReceived && basicUsedForHRAExemption) {
        hraExempted = Math.min(totalHRAReceived, basicUsedForHRAExemption);
      }
      if (hraExempted && rentPaidInExcessOfBasicThreshold) {
        hraExempted = Math.min(hraExempted, rentPaidInExcessOfBasicThreshold);
      }
      hraAmount = `${taxWorkingInstance?.currency?.symbol} ${hraExempted}`;
      totalRentPaid = `${taxWorkingInstance?.currency?.symbol} ${
        compareTDSApiData?.tdsMapOldRegime?.annualRentPaid || ''
      }`;

      // HRA Table Data
      hraData = [
        {
          received: compareTDSApiData?.tdsMapOldRegime?.totalHRARecieved || '-',
          basic: compareTDSApiData?.tdsMapOldRegime?.basicUsedForHRAExemption || '-',
          rentPaid: compareTDSApiData?.tdsMapOldRegime?.rentPaidInExcessOfBasicThreshold || '-',
        },
      ];
      basicPerc = compareTDSApiData?.employee?.isMetroLocationIndia ? '50' : '40';

      // Sort lineEntries based on statutoryReferenceSection
      statutoryData = [];
      const unMutatedLineEntries = [...(taxWorkingInstance?.lineEntries || [])];

      unMutatedLineEntries
        .sort((a, b) => a?.statutoryReferenceSection.localeCompare(b?.statutoryReferenceSection))
        .forEach((lineEntry) => {
          if (
            lineEntry?.statutoryReferenceSection &&
            (lineEntry?.statutoryReferenceSection.includes('10') ||
              taxWorkingInstance?.section10AllowancesList?.includes(lineEntry?.statutoryReferenceSection))
          ) {
            if (lineEntry?.finalExemptionAmount) {
              statutoryData.push({
                section: lineEntry?.statutoryReferenceSection || '',
                amount: lineEntry?.finalExemptionAmount,
              });
            }
          }
        });

      // subtitle values
      currencySymbol = taxWorkingInstance?.currency?.symbol;
      const currentFinancialYear = compareTDSApiData?.currentFinancialYear;
      standardDeduction = '';
      if (currentFinancialYear) {
        standardDeduction = currentFinancialYear === '2018-19' ? '40,000' : '50,000 (max)';
      }

      // Computation for row f
      let totalEarningsLessExemptionsOldRegime = totalEarningsOldRegime;
      let totalEarningsLessExemptionsNewRegime = totalEarningsNewRegime;
      const totalExemptionsOldRegime = compareTDSApiData?.tdsMapOldRegime?.totalAnnualExemptionsUnderSection10;
      if (totalExemptionsOldRegime) {
        totalEarningsLessExemptionsOldRegime -= totalExemptionsOldRegime;
      }
      const totalExemptionsNewRegime = compareTDSApiData?.tdsMapNewRegime?.totalAnnualExemptionsUnderSection10;
      if (totalExemptionsNewRegime) {
        totalEarningsLessExemptionsNewRegime -= totalExemptionsNewRegime;
      }

      // Computation for row l
      deductionTableData = [];
      if (taxWorkingInstance?.lineEntries) {
        unMutatedLineEntries
          ?.sort((a, b) => a?.statutoryReferenceSection.localeCompare(b?.statutoryReferenceSection))
          .forEach((lineEntry) => {
            if (lineEntry?.finalExemptionAmount && lineEntry?.statutoryReferenceSection) {
              if (lineEntry?.finalExemptionAmount !== 0.0 && lineEntry?.statutoryReferenceSection?.includes('80')) {
                deductionTableData.push({
                  section: lineEntry?.statutoryReferenceSection || '',
                  investmentProposed: `${currencySymbol} ${currencySymbol ? lineEntry?.declaredAnnualAmount || 0 : ''}`,
                  investmentActual: `${currencySymbol} ${
                    currencySymbol ? lineEntry?.proofSubmittedForAccount || 0 : ''
                  }`,
                  sectionLimit: `${currencySymbol} ${currencySymbol ? lineEntry?.annualExemptionLimitAmount || 0 : ''}`,
                  deductionAmount: `${currencySymbol} ${currencySymbol ? lineEntry?.finalExemptionAmount || 0 : ''}`,
                });
              }
            }
          });
      }
      // Computation for row r
      let totalTaxPayableOldRegime = 0;
      totalTaxPayableOldRegime += compareTDSApiData?.tdsMapOldRegime?.annualNetTax
        ? compareTDSApiData.tdsMapOldRegime.annualNetTax
        : 0;
      totalTaxPayableOldRegime += compareTDSApiData?.tdsMapOldRegime?.annualSurcharge
        ? compareTDSApiData.tdsMapOldRegime.annualSurcharge
        : 0;
      totalTaxPayableOldRegime += compareTDSApiData?.tdsMapOldRegime?.rebateUS87A
        ? -compareTDSApiData.tdsMapOldRegime.rebateUS87A
        : 0;

      let totalTaxPayableNewRegime = 0;
      totalTaxPayableNewRegime += compareTDSApiData?.tdsMapNewRegime?.annualNetTax
        ? compareTDSApiData.tdsMapNewRegime.annualNetTax
        : 0;
      totalTaxPayableNewRegime += compareTDSApiData?.tdsMapNewRegime?.annualSurcharge
        ? compareTDSApiData.tdsMapNewRegime.annualSurcharge
        : 0;
      totalTaxPayableNewRegime += compareTDSApiData?.tdsMapNewRegime?.rebateUS87A
        ? -compareTDSApiData.tdsMapNewRegime.rebateUS87A
        : 0;

      // Computation for row u
      const roundToTwoDecimals = (value) => Math.round(value * 100) / 100;
      let netTaxPaidYTDOldRegime = compareTDSApiData?.tdsMapOldRegime?.netTaxPaidYTD
        ? roundToTwoDecimals(compareTDSApiData.tdsMapOldRegime.netTaxPaidYTD)
        : 0;
      netTaxPaidYTDOldRegime += compareTDSApiData?.tdsMapOldRegime?.netPastTaxPaidYTD
        ? roundToTwoDecimals(compareTDSApiData.tdsMapOldRegime.netPastTaxPaidYTD)
        : 0;
      let netTaxPaidYTDNewRegime = compareTDSApiData?.tdsMapNewRegime?.netTaxPaidYTD
        ? roundToTwoDecimals(compareTDSApiData.tdsMapNewRegime.netTaxPaidYTD)
        : 0;
      netTaxPaidYTDNewRegime += compareTDSApiData?.tdsMapNewRegime?.netPastTaxPaidYTD
        ? roundToTwoDecimals(compareTDSApiData.tdsMapNewRegime.netPastTaxPaidYTD)
        : 0;

      // Computation for row x
      let totalAnnualTDSPendingOldRegime = 0;
      let totalAnnualTDSPendingNewRegime = 0;

      let projectedPerPeriodTDSOldRegime;
      if (compareTDSApiData?.numProjectedPayPeriods !== 0 && compareTDSApiData?.tdsMapOldRegime?.totalTaxLiability) {
        totalAnnualTDSPendingOldRegime =
          compareTDSApiData.tdsMapOldRegime.totalTaxLiability -
          (compareTDSApiData?.tdsMapOldRegime?.netTaxPaidYTD ? compareTDSApiData.tdsMapOldRegime.netTaxPaidYTD : 0);
        totalAnnualTDSPendingOldRegime -= compareTDSApiData?.tdsMapOldRegime?.netPastTaxPaidYTD
          ? compareTDSApiData.tdsMapOldRegime.netPastTaxPaidYTD
          : 0;
        if (compareTDSApiData?.numProjectedPayPeriods) {
          projectedPerPeriodTDSOldRegime =
            Math.round((totalAnnualTDSPendingOldRegime * 100) / compareTDSApiData.numProjectedPayPeriods) / 100;
        } else {
          projectedPerPeriodTDSOldRegime = '';
        }
      } else {
        projectedPerPeriodTDSOldRegime = compareTDSApiData?.tdsMapOldRegime?.totalPerPeriodTDS;
      }

      let projectedPerPeriodTDSNewRegime;
      if (
        compareTDSApiData?.numProjectedPayPeriods &&
        compareTDSApiData?.numProjectedPayPeriods !== 0 &&
        compareTDSApiData?.tdsMapNewRegime?.totalTaxLiability
      ) {
        const netTaxPaidYTD = compareTDSApiData?.tdsMapNewRegime?.netTaxPaidYTD || 0;
        totalAnnualTDSPendingNewRegime = compareTDSApiData.tdsMapNewRegime.totalTaxLiability - netTaxPaidYTD;
        totalAnnualTDSPendingNewRegime -= compareTDSApiData?.tdsMapNewRegime?.netPastTaxPaidYTD || 0;
        projectedPerPeriodTDSNewRegime =
          Math.round((100 * totalAnnualTDSPendingNewRegime) / compareTDSApiData.numProjectedPayPeriods) / 100;
      } else {
        projectedPerPeriodTDSNewRegime = compareTDSApiData?.tdsMapNewRegime?.totalPerPeriodTDS;
      }

      amountData = {
        a: {
          old: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapOldRegime?.totalAnnualEarningsUnderPayheadTypeEarnings) ||
            0
          }`.trim(),
          new: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapNewRegime?.totalAnnualEarningsUnderPayheadTypeEarnings) ||
            0
          }`.trim(),
        },
        b: {
          old:
            pastEmpTaxExist === 1
              ? `${currencySymbol || ''} ${
                  validateNumberElseHyphen(taxWorkingInstance?.pastEmpTotalTaxableIncomeUnderHeadSalaries) || 0
                }`.trim()
              : '',
          new:
            pastEmpTaxExist === 1
              ? `${currencySymbol || ''} ${
                  validateNumberElseHyphen(taxWorkingInstance?.pastEmpTotalTaxableIncomeUnderHeadSalaries) || 0
                }`.trim()
              : '',
        },
        c: {
          old: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapOldRegime?.totalAnnualTaxablePerquisites) || 0
          }`.trim(),
          new: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapNewRegime?.totalAnnualTaxablePerquisites) || 0
          }`.trim(),
        },
        d: {
          old: `${currencySymbol || ''} ${validateNumberElseHyphen(totalEarningsOldRegime) || 0}`.trim(),
          new: `${currencySymbol || ''} ${validateNumberElseHyphen(totalEarningsNewRegime) || 0}`.trim(),
        },
        e: {
          old: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapOldRegime?.totalAnnualExemptionsUnderSection10) || 0
          }`.trim(),
          new: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapNewRegime?.totalAnnualExemptionsUnderSection10) || 0
          }`.trim(),
        },
        e1: {
          old: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapOldRegime?.totalAnnualExemptionsUnderSection10) || 0
          }`.trim(),
          new: '',
        },
        f: {
          old: `${currencySymbol || ''} ${validateNumberElseHyphen(totalEarningsLessExemptionsOldRegime) || 0}`.trim(),
          new: `${currencySymbol || ''} ${validateNumberElseHyphen(totalEarningsLessExemptionsNewRegime) || 0}`.trim(),
        },
        g: {
          old: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapOldRegime?.totalAnnualDeductionsUnderSection16) || 0
          }`.trim(),
          new: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapNewRegime?.totalAnnualDeductionsUnderSection16) || 0
          }`.trim(),
        },
        h: {
          old: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapOldRegime?.totalAnnualIncomeUnderHeadSalaries) || 0
          }`.trim(),
          new: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapNewRegime?.totalAnnualIncomeUnderHeadSalaries) || 0
          }`.trim(),
        },
        i: {
          old: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapOldRegime?.additionalDeclaredIncome) || 0
          }`.trim(),
          new: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapNewRegime?.additionalDeclaredIncome) || 0
          }`.trim(),
        },
        j: {
          old: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapOldRegime?.additionalClaimedDeductions) || 0
          }`.trim(),
          new: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapNewRegime?.additionalClaimedDeductions) || 0
          }`.trim(),
        },
        k: {
          old: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapOldRegime?.grossTotalIncomeAnnual) || 0
          }`.trim(),
          new: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapNewRegime?.grossTotalIncomeAnnual) || 0
          }`.trim(),
        },
        l: {
          old: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapOldRegime?.totalAnnualDeductionsUnderChapterVIA) || 0
          }`.trim(),
          new: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapNewRegime?.totalAnnualDeductionsUnderChapterVIA) || 0
          }`.trim(),
        },
        m: {
          old: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapOldRegime?.totalAnnualDeductionsUnderChapterVIA) || 0
          }`.trim(),
          new: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapNewRegime?.totalAnnualDeductionsUnderChapterVIA) || 0
          }`.trim(),
        },
        n: {
          old: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapOldRegime?.netAnnualTaxableIncome) || 0
          }`.trim(),
          new: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapNewRegime?.netAnnualTaxableIncome) || 0
          }`.trim(),
        },
        o: {
          old: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapOldRegime?.annualNetTax) || 0
          }`.trim(),
          new: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapNewRegime?.annualNetTax) || 0
          }`.trim(),
        },
        p: {
          old: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapOldRegime?.rebateUS87A) || 0
          }`.trim(),
          new: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapNewRegime?.rebateUS87A) || 0
          }`.trim(),
        },
        q: {
          old: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapOldRegime?.annualSurcharge) || 0
          }`.trim(),
          new: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapNewRegime?.annualSurcharge) || 0
          }`.trim(),
        },
        r: {
          old: `${currencySymbol || ''} ${validateNumberElseHyphen(totalTaxPayableOldRegime) || 0}`.trim(),
          new: `${currencySymbol || ''} ${validateNumberElseHyphen(totalTaxPayableNewRegime) || 0}`.trim(),
        },
        s: {
          old: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapOldRegime?.annualEducationalCess) || 0
          }`.trim(),
          new: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapNewRegime?.annualEducationalCess) || 0
          }`.trim(),
        },
        t: {
          old: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapOldRegime?.totalTaxLiability) || 0
          }`.trim(),
          new: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapNewRegime?.totalTaxLiability) || 0
          }`.trim(),
        },
        u: {
          old: `${currencySymbol || ''} ${validateNumberElseHyphen(netTaxPaidYTDOldRegime) || 0}`.trim(),
          new: `${currencySymbol || ''} ${validateNumberElseHyphen(netTaxPaidYTDNewRegime) || 0}`.trim(),
        },
        v: {
          old: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapOldRegime?.totalAnnualTDSPending) || 0
          }`.trim(),
          new: `${currencySymbol || ''} ${
            validateNumberElseHyphen(compareTDSApiData?.tdsMapNewRegime?.totalAnnualTDSPending) || 0
          }`.trim(),
        },
        w: {
          old: validateNumberElseHyphen(compareTDSApiData?.numProjectedPayPeriods) || '',
          new: validateNumberElseHyphen(compareTDSApiData?.numProjectedPayPeriods) || '',
        },
        x: {
          old: `${currencySymbol || ''} ${validateNumberElseHyphen(projectedPerPeriodTDSOldRegime) || ''}`.trim(),
          new: `${currencySymbol || ''} ${validateNumberElseHyphen(projectedPerPeriodTDSNewRegime) || ''}`.trim(),
        },
      };
    } catch (err) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: 'Something went wrong, try again',
      });
    }
  } else {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  return (
    <CompareTDSTemplateTemplate
      empName={compareTDSApiData?.fullName || ''}
      empId={compareTDSApiData?.employeeID || ''}
      categoryColumnsData={categoryColumnsData}
      categoryRowsData={categoryRowsData}
      amountData={amountData}
      hraAmount={hraAmount}
      totalRentPaid={totalRentPaid}
      taxOnEmployment={`${currencySymbol || ''} ${
        currencySymbol ? compareTDSApiData?.tdsMapOldRegime?.totalAnnualDeductionsUnderSection16 || 0 : ''
      }`}
      deductionTableData={deductionTableData}
      totalDeductionAmount={`${currencySymbol || ''} ${
        currencySymbol
          ? validateNumberElseHyphen(compareTDSApiData?.tdsMapOldRegime?.totalAnnualDeductionsUnderChapterVIA) || 0
          : ''
      }`}
      hraData={hraData}
      incomeData={incomeData}
      employerNames={employerNames}
      statutoryData={statutoryData}
      tdsWorkingProfile={tdsWorkingProfile}
      totalExemptionPrice={`${currencySymbol || ''} ${
        currencySymbol ? compareTDSApiData?.tdsMapOldRegime?.totalAnnualExemptionsUnderSection10 || 0 : ''
      }`.trim()}
      startDate={compareTDSApiData?.startDate ? dayjs(compareTDSApiData.startDate).format('DD-MMM-YYYY') : ''}
      endDate={compareTDSApiData?.endDate ? dayjs(compareTDSApiData.endDate).format('DD-MMM-YYYY') : ''}
      basicPerc={basicPerc}
      standardDeduction={
        currencySymbol && standardDeduction
          ? `(inclusive of Standard Deduction of ${currencySymbol} ${standardDeduction})`
          : ''
      }
    />
  );
};

export default CompareTDSPage;
