import React, { useState } from 'react';
import { Box, Button, IconButton, Menu, MenuList, MenuItem, ListItemText } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import { usePopover } from 'hooks/use-popover';
import { DotsVertical as DotsVerticalIcon } from 'assets/icons/dots-vertical';
import PropTypes from 'prop-types';
import { deleteChallan, lockChallan, unLockChallan } from 'api/challan';
import useToast from 'components/Provider/useToast';
import { styles } from './styles';

export const ChallanTableMenu = ({ row, onActionCallback }) => {
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const [openDialog, setOPenDialog] = useState(false);
  const [action, setAction] = useState('');
  const addToast = useToast();

  const navigate = useNavigate();

  const handleDialogClose = () => {
    setOPenDialog(false);
  };

  const handleDelete = async () => {
    try {
      const response = await deleteChallan(row.id);
      if (response.status === 200) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Challan deleted successfully`,
        });
        onActionCallback();
      }
    } catch (err) {
      addToast({ type: 'error', title: 'ERROR', message: err?.message || 'Failed to delete challan' });
    }
    handleClose();
    setOPenDialog(false);
  };

  const handleLockAllocation = async () => {
    try {
      const response = await lockChallan(row.id);
      if (response.status === 200) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Challan allocation locked`,
        });
        onActionCallback();
      }
    } catch (err) {
      addToast({ type: 'error', title: 'ERROR', message: err?.message || 'Failed to lock challan allocation' });
    }
    handleClose();
    setOPenDialog(false);
  };

  const handleUnLockAllocation = async () => {
    try {
      const response = await unLockChallan(row.id);
      if (response.status === 200) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Challan allocation unlocked`,
        });
        onActionCallback();
      }
    } catch (err) {
      addToast({ type: 'error', title: 'ERROR', message: err?.message || 'Failed to unlock challan allocation' });
    }
    handleClose();
    setOPenDialog(false);
  };

  const actionItems = [
    {
      label: 'Edit',
      type: 'modify_challan',
      conditionals: (item) => item.lockStatus?.toUpperCase() === 'UNLOCKED',
    },
    {
      label: 'Allocate Challan',
      type: 'allocate_challan',
      conditionals: (item) => item.lockStatus?.toUpperCase() === 'UNLOCKED',
    },
    {
      label: 'View Allocations',
      type: 'view_allocations',
    },
    {
      label: 'Lock Allocations',
      type: 'lock_allocations',
      onClick: handleLockAllocation,
      conditionals: (item) => item.lockStatus?.toUpperCase() === 'UNLOCKED',
    },
    {
      label: 'UnLock Allocations',
      type: 'unlock_allocations',
      onClick: handleUnLockAllocation,
      conditionals: (item) => item.lockStatus?.toUpperCase() === 'LOCKED',
    },
    {
      label: 'Delete',
      type: 'delete',
      onClick: handleDelete,
      conditionals: (item) => item.lockStatus?.toUpperCase() === 'UNLOCKED',
    },
  ];

  const handleMenuItemClick = (actionInput) => {
    if (actionInput.type === 'modify_challan') {
      navigate(`/company/salary/challans/${row.id}/edit`);
      return;
    }
    if (actionInput.type === 'view_allocations') {
      navigate(`/company/salary/challans/view-allocations/${row.id}`);
      return;
    }
    if (actionInput.type === 'allocate_challan') {
      navigate(`/company/salary/challans/allocate-challan/${row.id}`);
      return;
    }
    if (actionInput.type === 'lock_allocations' || actionInput.type === 'unlock_allocations') {
      actionInput.callback();
      return;
    }

    setOPenDialog(true);

    setAction(() => {
      switch (actionInput.type) {
        case 'delete':
          return {
            title: 'Delete Challan',
            confirmation: `Are you sure you want to delete Challan - ${row?.challanType} -${row?.challanCode}?`,
            callback: actionInput.callback,
            ...actionInput,
          };
        default:
          return actionInput;
      }
    });
  };

  const renderDialogContent = (actionInput) => (
    <DialogContent sx={styles.tableMenuDialog}>
      <Box sx={styles.tableMenuDialog}>
        <DialogTitle>{actionInput.title}</DialogTitle>
        {actionInput.confirmation && <DialogContentText>{actionInput.confirmation}</DialogContentText>}
      </Box>
      {actionInput.children}
      <DialogActions sx={{ marginTop: '2.5rem', justifyContent: 'center' }}>
        <Button size="medium" variant="outlined" color="info" onClick={handleDialogClose}>
          Cancel
        </Button>
        <Button size="medium" variant="contained" color="error" onClick={actionInput.callback}>
          Delete
        </Button>
      </DialogActions>
    </DialogContent>
  );

  const renderMenuActions = () =>
    actionItems
      .filter((item) => (item.conditionals ? item.conditionals(row) : true))
      .map((actionInput) => (
        <MenuItem
          key={actionInput.type}
          onClick={() =>
            handleMenuItemClick({
              label: actionInput.label,
              type: actionInput.type,
              callback: actionInput.onClick,
            })
          }
        >
          <ListItemText variant="body2">{actionInput.label}</ListItemText>
        </MenuItem>
      ));

  return (
    <>
      <IconButton onClick={handleOpen} ref={anchorRef} color="primary">
        <DotsVerticalIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList dense>{renderMenuActions()}</MenuList>
      </Menu>
      <Dialog open={openDialog} onClose={() => setOPenDialog(false)}>
        {renderDialogContent(action)}
      </Dialog>
    </>
  );
};

ChallanTableMenu.propTypes = {
  row: PropTypes.shape(PropTypes.node).isRequired,
  onActionCallback: PropTypes.func.isRequired,
};
