import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Button from 'components/Atoms/Button';
import FilterListIcon from '@mui/icons-material/FilterList';
import TableFilter from 'components/Organisms/TableFilter';

const FilterButton = ({ attributeOptions, handleSubmit, handleFiltersClear }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        size="Medium"
        variant="outlined"
        color="info"
        startIcon={<FilterListIcon />}
        onClick={() => setOpen(true)}
      >
        Filter
      </Button>
      <TableFilter
        secondaryBtn="Reset"
        handleSecondaryBtn={() => {
          handleFiltersClear();
          setOpen(false);
        }}
        dialogTitle="Filters"
        primaryBtn="Apply"
        primaryBtnColor="primary"
        handleCancel={() => setOpen(false)}
        open={open}
        handleClose={() => setOpen(false)}
        attributeOptions={attributeOptions}
        alignmentActionBtn="end"
        handleSubmit={(data) => {
          handleSubmit(data);
          setOpen(false);
        }}
      />
    </>
  );
};

FilterButton.defaultProps = {
  attributeOptions: [],
  handleSubmit: () => {},
  handleFiltersClear: () => {},
};

FilterButton.propTypes = {
  attributeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  handleSubmit: PropTypes.func,
  handleFiltersClear: PropTypes.func,
};

export default FilterButton;
