import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, useTheme } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Typography from 'components/Atoms/Typography';
import ProfileWithInformation from 'components/Molecules/ProfileWithInformation';

const TeamMembersTab = ({ teamMembersList }) => {
  const theme = useTheme();
  return (
    <PerfectScrollbar>
      <Box sx={{ minHeight: '56.5rem', maxHeight: '56.5rem' }}>
        <Box mb="2.5rem">
          <Typography variant="h6">Team members</Typography>
        </Box>

        {!teamMembersList?.length ? (
          <Typography>No data available</Typography>
        ) : (
          teamMembersList?.map((list, index) => (
            <>
              <ProfileWithInformation
                profileImg={list?.profileImg}
                profileName={list?.profileName}
                isDesignation={list?.isDesignation}
                designation={list?.designation}
                iconElements={[list?.emailAddressesIcon, list?.mobileNumbersIcon]}
                titles={[list?.emailAddresses, list?.mobileNumbers]}
              />
              {!!index !== teamMembersList.length - 1 && (
                <Divider sx={{ my: 1, borderColor: theme.palette.other[200] }} />
              )}
            </>
          ))
        )}
      </Box>
    </PerfectScrollbar>
  );
};

TeamMembersTab.defaultProps = {
  teamMembersList: [],
};
TeamMembersTab.propTypes = {
  teamMembersList: PropTypes.arrayOf(
    PropTypes.shape({
      profileImg: PropTypes.string,
      profileName: PropTypes.string,
      isDesignation: PropTypes.bool,
      designation: PropTypes.string,
      iconElement1: PropTypes.string,
      title1: PropTypes.string,
      iconElement2: PropTypes.string,
      title2: PropTypes.string,
    }),
  ),
};

export default TeamMembersTab;
