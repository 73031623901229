import React from 'react';
import { Grid, Divider } from '@mui/material';
import InputField from 'components/Atoms/InputField';
import DatePickers from 'components/Atoms/Datepicker';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import Typographys from 'components/Atoms/Typography';

const TdsForm = ({ formik }) => {
  return (
    <>
      <Grid item xs={12}>
        <Divider flexItem orientation="horizontal" />{' '}
      </Grid>
      <Grid item xs={12}>
        <Typographys variant="subtitle1"> TDS Payment Details</Typographys>
      </Grid>
      <Grid item xs={4} sx={{ pt: '1.25rem !important' }}>
        <InputField
          fullWidth
          error={Boolean(formik.touched.incomeTax && formik.errors.incomeTax)}
          helperText={formik.touched.incomeTax && formik.errors.incomeTax}
          value={formik.values.incomeTax}
          name="incomeTax"
          label="Income Tax"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={4} sx={{ pt: '1.25rem !important' }}>
        <InputField
          fullWidth
          error={Boolean(formik.touched.surcharge && formik.errors.surcharge)}
          helperText={formik.touched.surcharge && formik.errors.surcharge}
          value={formik.values.surcharge}
          name="surcharge"
          label="Amount Due"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={4} sx={{ pt: '1.25rem !important' }}>
        <InputField
          fullWidth
          error={Boolean(formik.touched.cess && formik.errors.cess)}
          helperText={formik.touched.cess && formik.errors.cess}
          value={formik.values.cess}
          name="cess"
          label="Amount Due"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={4}>
        <InputField
          fullWidth
          error={Boolean(formik.touched.interest && formik.errors.interest)}
          helperText={formik.touched.interest && formik.errors.interest}
          value={formik.values.interest}
          name="interest"
          label="Amount Due"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={4}>
        <InputField
          fullWidth
          error={Boolean(formik.touched.fees && formik.errors.fees)}
          helperText={formik.touched.fees && formik.errors.fees}
          value={formik.values.fees}
          name="fees"
          label="Amount Due"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={4}>
        <InputField
          fullWidth
          error={Boolean(formik.touched.penalty && formik.errors.penalty)}
          helperText={formik.touched.penalty && formik.errors.penalty}
          value={formik.values.penalty}
          name="penalty"
          label="Amount Due"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={4}>
        <InputField
          fullWidth
          error={Boolean(formik.touched.others && formik.errors.others)}
          helperText={formik.touched.others && formik.errors.others}
          value={formik.values.others}
          name="others"
          label="Amount Due"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={4}>
        <InputField
          fullWidth
          error={Boolean(formik.touched.lastTotalPaidTax && formik.errors.lastTotalPaidTax)}
          helperText={formik.touched.lastTotalPaidTax && formik.errors.lastTotalPaidTax}
          value={formik.values.lastTotalPaidTax}
          name="lastTotalPaidTax"
          label="Amount Due"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={4}>
        <InputField
          fullWidth
          error={Boolean(formik.touched.totalAmountDeposited && formik.errors.totalAmountDeposited)}
          helperText={formik.touched.totalAmountDeposited && formik.errors.totalAmountDeposited}
          value={formik.values.totalAmountDeposited}
          name="totalAmountDeposited"
          label="Amount Due"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={4}>
        <InputField
          fullWidth
          error={Boolean(formik.touched.lastChallanBSRCode && formik.errors.lastChallanBSRCode)}
          helperText={formik.touched.lastChallanBSRCode && formik.errors.lastChallanBSRCode}
          value={formik.values.lastChallanBSRCode}
          name="lastChallanBSRCode"
          label="Amount Due"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={4}>
        <DatePickers
          id="Last Challan Payment Date"
          error={Boolean(formik.touched.LastChallanPaymentDate && formik.errors.LastChallanPaymentDate)}
          label="Last Challan Payment Date"
          name="LastChallanPaymentDate"
          value={formik?.values?.LastChallanPaymentDate ? dayjs(formik?.values?.LastChallanPaymentDate) : ''}
          onChange={(value) => {
            formik.setFieldValue('LastChallanPaymentDate', Date.parse(value));
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <InputField
          fullWidth
          error={Boolean(formik.touched.LastChallanSerialNumber && formik.errors.LastChallanSerialNumber)}
          helperText={formik.touched.LastChallanSerialNumber && formik.errors.LastChallanSerialNumber}
          value={formik.values.LastChallanSerialNumber}
          name="LastChallanSerialNumber"
          label="Last Challan Serial Number"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={4}>
        <InputField
          fullWidth
          error={Boolean(formik.touched.interestApportioned && formik.errors.interestApportioned)}
          helperText={formik.touched.interestApportioned && formik.errors.interestApportioned}
          value={formik.values.interestApportioned}
          name="interestApportioned"
          label="Interest Apportioned"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={4}>
        <InputField
          fullWidth
          error={Boolean(formik.touched.voucherNumber && formik.errors.voucherNumber)}
          helperText={formik.touched.voucherNumber && formik.errors.voucherNumber}
          value={formik.values.voucherNumber}
          name="voucherNumber"
          label="Voucher Number"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={4}>
        <DatePickers
          id="Voucher Date"
          error={Boolean(formik.touched.voucherDate && formik.errors.voucherDate)}
          label="Voucher Date"
          name="voucherDate"
          value={formik?.values?.voucherDate ? dayjs(formik?.values?.voucherDate) : ''}
          onChange={(value) => {
            formik.setFieldValue('voucherDate', Date.parse(value));
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <InputField
          fullWidth
          error={Boolean(formik.touched.chequeDDNumber && formik.errors.chequeDDNumber)}
          helperText={formik.touched.chequeDDNumber && formik.errors.chequeDDNumber}
          value={formik.values.chequeDDNumber}
          name="chequeDDNumber"
          label="Cheque/DD Number"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={4}>
        <DatePickers
          id="Deduction Date"
          error={Boolean(formik.touched.deductionDate && formik.errors.deductionDate)}
          label="Deduction Date"
          name="deductionDate"
          value={formik?.values?.deductionDate ? dayjs(formik?.values?.deductionDate) : ''}
          onChange={(value) => {
            formik.setFieldValue('deductionDate', Date.parse(value));
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <InputField
          fullWidth
          error={Boolean(formik.touched.minorheadCode && formik.errors.minorheadCode)}
          helperText={formik.touched.minorheadCode && formik.errors.minorheadCode}
          value={formik.values.minorheadCode}
          name="minorheadCode"
          label="Minorhead Code"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={4}>
        <InputField
          fullWidth
          error={Boolean(formik.touched.lastPaymentFee && formik.errors.lastPaymentFee)}
          helperText={formik.touched.lastPaymentFee && formik.errors.lastPaymentFee}
          value={formik.values.lastPaymentFee}
          name="lastPaymentFee"
          label="Late Payment Fee"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={4}>
        <InputField
          fullWidth
          error={Boolean(formik.touched.ackNumber && formik.errors.ackNumber)}
          helperText={formik.touched.ackNumber && formik.errors.ackNumber}
          value={formik.values.ackNumber}
          name="ackNumber"
          label="Acknowledgement Number"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      </Grid>
    </>
  );
};

export default TdsForm;

TdsForm.propTypes = {
  formik: PropTypes.instanceOf(Object).isRequired,
};
