import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { Box } from '@mui/material';
import Typography from 'components/Atoms/Typography';
import { uploadStyle } from './style';

const UploadProfile = ({ label, value, name, id, handleChange }) => {
  return (
    <Box sx={uploadStyle.wrapper}>
      <Button variant="text" color="info" component="label">
        <Box sx={uploadStyle.uploadIcon}>
          <PhotoCamera />
          {value && <Box sx={uploadStyle.preview}>{value && <img src={value} alt="File Preview" />}</Box>}
        </Box>
        {!value && <Typography variant="body2">{label}</Typography>}
        <input name={name} id={id} hidden accept="image/*" multiple type="file" onChange={handleChange} />
      </Button>
    </Box>
  );
};

UploadProfile.defaultProps = {
  label: 'Profile Picture',
  value: '',
  name: '',
  id: '',
  handleChange: () => {},
};

UploadProfile.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  handleChange: PropTypes.func,
};

export default UploadProfile;
