export const attachmentStyle = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },

  attachFile: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',

    '& .MuiSvgIcon-root': {
      color: 'secondary.500',
      fontSize: '2.5rem',
      mr: '0.625rem',
    },

    '& .MuiTypography-body1': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },

  attachFileIcon: {
    display: 'flex',
    alignItems: 'center',
    mr: '2rem',
    maxWidth: '100px',
  },

  fileNameContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 1.5,
  },
};
