import React from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import PropTypes from 'prop-types';

import AccessFilters from 'components/Organisms/Employee/EmployeeList/AccessFilter';

const AccessFilter = ({
  pageHeading,
  breadcrumbData,
  columns,
  rows,
  roleBasedMenu,
  handleSelect,
  showActionList,
  employeeName,
  employeeID,

  secondaryBtn,
  primaryBtn,
  primaryBtnColor,

  statusOptions,
  typeOptions,
  subTypeOptions,
  entitiesOptions,

  open,
  handleClose,
  handleSubmit,
  handleOpenDialog,
  initialValues,
  isModify,
  modifyAccessFilter,
  deleteAccessFilter,
  handleOpenDeleteDialog,
  modifyAccessFilterAction,
  openDeleteDialog,
  loading,
}) => {
  return (
    <BlankTemplate isBreadcrumb breadcrumbData={breadcrumbData} pageHeading={pageHeading}>
      <AccessFilters
        columns={columns}
        rows={rows}
        roleBasedMenu={roleBasedMenu}
        handleSelect={handleSelect}
        showActionList={showActionList}
        employeeName={employeeName}
        employeeID={employeeID}
        secondaryBtn={secondaryBtn}
        primaryBtn={primaryBtn}
        primaryBtnColor={primaryBtnColor}
        statusOptions={statusOptions}
        typeOptions={typeOptions}
        subTypeOptions={subTypeOptions}
        entitiesOptions={entitiesOptions}
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        handleOpenDialog={handleOpenDialog}
        initialValues={initialValues}
        isModify={isModify}
        modifyAccessFilter={modifyAccessFilter}
        deleteAccessFilter={deleteAccessFilter}
        handleOpenDeleteDialog={handleOpenDeleteDialog}
        modifyAccessFilterAction={modifyAccessFilterAction}
        openDeleteDialog={openDeleteDialog}
        loading={loading}
      />
    </BlankTemplate>
  );
};

AccessFilter.defaultProps = {
  pageHeading: 'Data Access Filter',
  breadcrumbData: [
    { id: 1, name: 'Employee List', path: '/company/employee/employees-list', isActive: false },
    { id: 2, name: 'Access Filter', isActive: true },
  ],
  handleSelect: () => {},
  showActionList: true,
  roleBasedMenu: (row) => {},
  rows: [],
  columns: [],
  employeeName: 'Uday kumar',
  employeeID: 10006,

  primaryBtnColor: 'primary',
  secondaryBtn: 'Cancel',
  primaryBtn: 'Create Filter',
  handleSubmit: () => {},

  statusOptions: [
    { id: 1, name: 'Filter Status 1', onClick: () => {} },
    { id: 2, name: 'Filter Status 2', onClick: () => {} },
    { id: 3, name: 'Filter Status 3', onClick: () => {} },
    { id: 4, name: 'Filter Status 4', onClick: () => {} },
    { id: 5, name: 'Filter Status 5', onClick: () => {} },
    { id: 6, name: 'Filter Status 6', onClick: () => {} },
  ],

  typeOptions: [
    { id: 1, name: 'Filter Type 1', onClick: () => {} },
    { id: 2, name: 'Filter Type 2', onClick: () => {} },
    { id: 3, name: 'Filter Type 3', onClick: () => {} },
    { id: 4, name: 'Filter Type 4', onClick: () => {} },
    { id: 5, name: 'Filter Type 5', onClick: () => {} },
    { id: 6, name: 'Filter Type 6', onClick: () => {} },
  ],

  subTypeOptions: [
    { id: 1, name: 'Filter Sub Type 1', onClick: () => {} },
    { id: 2, name: 'Filter Sub Type 2', onClick: () => {} },
    { id: 3, name: 'Filter Sub Type 3', onClick: () => {} },
    { id: 4, name: 'Filter Sub Type 4', onClick: () => {} },
    { id: 5, name: 'Filter Sub Type 5', onClick: () => {} },
    { id: 6, name: 'Filter Sub Type 6', onClick: () => {} },
  ],

  entitiesOptions: [
    { id: 1, name: 'Allowed Entities 1', onClick: () => {} },
    { id: 2, name: 'Allowed Entities 2', onClick: () => {} },
    { id: 3, name: 'Allowed Entities 3', onClick: () => {} },
    { id: 4, name: 'Allowed Entities 4', onClick: () => {} },
    { id: 5, name: 'Allowed Entities 5', onClick: () => {} },
    { id: 6, name: 'Allowed Entities 6', onClick: () => {} },
  ],
  open: true,
  handleClose: () => {},
  handleOpenDialog: () => {},
  initialValues: {
    filterStatus: '',
    filtertype: '',
    filtersubtype: '',
    allowedentities: '',
  },
  modifyAccessFilter: () => {},
  deleteAccessFilter: () => {},
  handleOpenDeleteDialog: () => {},
  modifyAccessFilterAction: () => {},
  openDeleteDialog: false,
  loading: false,
};

AccessFilter.propTypes = {
  pageHeading: PropTypes.string,
  breadcrumbData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
  handleSelect: PropTypes.func,
  showActionList: PropTypes.bool,
  roleBasedMenu: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      numeric: PropTypes.bool,
      label: PropTypes.string,
    }),
  ),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
      id: PropTypes.string,
      userRole: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      employeeid: PropTypes.number,
      username: PropTypes.string,
      status: PropTypes.node,
      gender: PropTypes.string,
    }),
  ),
  employeeName: PropTypes.string,
  employeeID: PropTypes.number,

  open: PropTypes.bool,
  handleClose: PropTypes.func,
  primaryBtnColor: PropTypes.string,
  secondaryBtn: PropTypes.string,
  primaryBtn: PropTypes.string,

  statusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  typeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),

  subTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),

  entitiesOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  handleSubmit: PropTypes.func,
  handleOpenDialog: PropTypes.func,
  initialValues: PropTypes.shape,
  modifyAccessFilter: PropTypes.func,
  deleteAccessFilter: PropTypes.func,
  handleOpenDeleteDialog: PropTypes.func,
  modifyAccessFilterAction: PropTypes.func,
  openDeleteDialog: PropTypes.bool,
  loading: PropTypes.bool,
};

export default AccessFilter;
