import React from 'react';
import PropTypes from 'prop-types';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import { Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Typographys from 'components/Atoms/Typography';

const MuiTimeline = ({ isIcon, icon, dotColor, timeLineLists }) => {
  return (
    <Timeline>
      {timeLineLists.map((list) => (
        <TimelineItem>
          <TimelineContent>
            <Box display="grid" flexDirection="column" rowGap={0.5}>
              <Typographys variant="subtitle2" color="neutral.700">
                {list.date}
              </Typographys>
              <Typographys variant="caption" color="neutral.600">
                {list.time}
              </Typographys>
            </Box>
          </TimelineContent>
          <TimelineSeparator>
            <TimelineDot color={dotColor}>{isIcon && icon}</TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Box display="grid" flexDirection="column" rowGap={1}>
              <Typographys variant="h6" color="neutral.700">
                {list.title}
              </Typographys>
              <Box>
                <Typographys variant="body2" color="neutral.800" display="inline-block">
                  Effected By:&nbsp;
                </Typographys>
                <Typographys variant="body1" color="secondary.600" display="inline-block">
                  {`${list.desc}`}
                </Typographys>
              </Box>

              <Typographys variant="caption" color="neutral.600">
                {`Notes: ${list.note}`}
              </Typographys>
            </Box>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};
MuiTimeline.defaultProps = {
  dotColor: 'secondary',
  isIcon: true,
  icon: <EditIcon />,
  timeLineLists: [
    {
      date: '5th Dec 2022',
      time: '3:50:51 IST',
      title: 'Miscellenous',
      desc: 'Aachman kabir(1001)',
      note: '',
    },
    {
      date: '5th Dec 2022',
      time: '3:50:51 IST',
      title: 'Miscellenous',
      desc: 'Aachman kabir(1001)',
      note: '',
    },
    {
      date: '5th Dec 2022',
      time: '3:50:51 IST',
      title: 'Miscellenous',
      desc: 'Aachman kabir(1001)',
      note: '',
    },
    {
      date: '5th Dec 2022',
      time: '3:50:51 IST',
      title: 'Miscellenous',
      desc: 'Aachman kabir(1001)',
      note: '',
    },
  ],
};

MuiTimeline.propTypes = {
  isIcon: PropTypes.bool,
  icon: PropTypes.node,
  dotColor: PropTypes.string,
  timeLineLists: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      time: PropTypes.string,
      title: PropTypes.string,
      desc: PropTypes.string,
      note: PropTypes.node,
    }),
  ),
};
export default MuiTimeline;
