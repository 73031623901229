import * as React from 'react';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import Typographys from 'components/Atoms/Typography';
import { Box } from '@mui/material';
import { CompareTDSStyle } from 'components/Organisms/MyPay/CompareTDS/style';

const HeadsTable = ({ hasHeader, headsData }) => {
  return (
    <Paper sx={{ overflow: 'auto' }}>
      {hasHeader && (
        <Box sx={CompareTDSStyle.headsTableHeader}>
          <Box sx={{ width: '56%' }}>
            <Typographys variant="h6">Heads</Typographys>
          </Box>
          <Box sx={{ width: '22%' }}>
            <Typographys variant="h6">Amount (Old Regime)</Typographys>
          </Box>
          <Box sx={{ width: '22%' }}>
            <Typographys variant="h6">Amount (New Regime)</Typographys>
          </Box>
        </Box>
      )}

      {headsData.map((head) =>
        head.type === 'subtitle' ? (
          <Box key={head.title} sx={CompareTDSStyle.headsTableSubTitleRows}>
            <Box sx={{ width: '56%' }}>
              <Typographys variant="caption" color="neutral.600">
                {head.title}
              </Typographys>
            </Box>
          </Box>
        ) : (
          <Box key={head.title} sx={CompareTDSStyle.headsTableRows}>
            <Box sx={{ width: '56%' }}>
              <Box sx={CompareTDSStyle.headsTableSubTitleColumn}>
                <Typographys variant="body2">
                  {head.title}
                  {head.subTitle && (
                    <Box component="span" variant="body2" color="neutral.600" sx={{ marginLeft: '.5rem' }}>
                      {' '}
                      {head.subTitle}
                    </Box>
                  )}
                </Typographys>
              </Box>
            </Box>
            <Box sx={{ width: '22%' }}>
              <Typographys variant="body2">{head.oldRegimeAmount}</Typographys>
            </Box>
            <Box sx={{ width: '22%' }}>
              <Typographys variant="body2">{head.newRegimeAmount}</Typographys>
            </Box>
          </Box>
        ),
      )}
    </Paper>
  );
};

HeadsTable.defaultProps = {
  headsData: [],
  hasHeader: false,
};

HeadsTable.propTypes = {
  headsData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      oldRegimeAmount: PropTypes.string,
      newRegimeAmount: PropTypes.string,
    }),
  ),
  hasHeader: PropTypes.bool,
};

export default HeadsTable;
