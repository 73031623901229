import React from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import PropTypes from 'prop-types';
import EditSeparation from 'components/Organisms/Profile/InitiateSeparation/EditSeparation';

const EditSeparationTemplate = ({
  handleSubmit,
  handleCancel,
  seperationOptions,
  employeeOptions,
  resStatusOptions,
  managerOptions,
  interviewerOptions,
  pageHeading,
  defaultValues,
  breadcrumbData,
  disableButton,
}) => {
  return (
    <BlankTemplate pageHeading={pageHeading} breadcrumbData={breadcrumbData}>
      <EditSeparation
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        seperationOptions={seperationOptions}
        employeeOptions={employeeOptions}
        resStatusOptions={resStatusOptions}
        managerOptions={managerOptions}
        interviewerOptions={interviewerOptions}
        defaultValues={defaultValues}
        disableButton={disableButton}
      />
    </BlankTemplate>
  );
};

EditSeparationTemplate.defaultProps = {
  pageHeading: 'Modify Separation Details',
  handleSubmit: () => {},
  handleCancel: () => {},
  defaultValues: {},
  seperationOptions: [],
  employeeOptions: [],
  resStatusOptions: [],
  managerOptions: [],
  interviewerOptions: [],
  breadcrumbData: [
    { id: 1, name: 'View Separation', path: '/home/separation/view-separation', isActive: false },
    { id: 2, name: 'Edit', path: '', isActive: true },
  ],
  disableButton: false,
};

EditSeparationTemplate.propTypes = {
  pageHeading: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  defaultValues: PropTypes.shape({}),
  seperationOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),

  employeeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),

  resStatusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),

  managerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),

  interviewerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  breadcrumbData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      path: PropTypes.string,
      isActive: PropTypes.bool,
    }),
  ),
  disableButton: PropTypes.bool,
};

export default EditSeparationTemplate;
