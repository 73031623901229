import {
  employeeExitsListData,
  employeeExitDetailsData,
  initiateEmpExitData,
  actionEmployeeExit,
  initiateSpecificEmpExit,
  viewOffBoardingCheckList,
} from 'api/employees/separations';
import { asyncThunkWrapper } from 'utils/AsyncThunkWrapper';

const fetchEmployeeExitsList = asyncThunkWrapper('separations/employeeExitsListData', employeeExitsListData);
const fetchEmployeeExitDetails = asyncThunkWrapper('separations/employeeExitDetails', employeeExitDetailsData);
const fetchInitiateEmpExit = asyncThunkWrapper('separations/initiateEmpExit', initiateEmpExitData);
const fetchViewOffBoardingCheckList = asyncThunkWrapper(
  'separations/viewOffBoardingCheckList',
  viewOffBoardingCheckList,
);
const fetchActionRequestDetails = asyncThunkWrapper('separations/actionRequestDetails', actionEmployeeExit);
const fetchInitiateSpecificEmpExit = asyncThunkWrapper('separations/initiateSpecificEmpExit', initiateSpecificEmpExit);

export {
  fetchEmployeeExitsList,
  fetchEmployeeExitDetails,
  fetchInitiateEmpExit,
  fetchActionRequestDetails,
  fetchViewOffBoardingCheckList,
  fetchInitiateSpecificEmpExit,
};
