export const spaceSZero = '0px';

export const spaceSHalf = '2px';

export const spaceS1x = '4px';

export const spaceS1xHalf = '6px';

export const spaceS2x = '8px';

export const spaceS3x = '12px';

export const spaceS4x = '16px';

export const spaceS5x = '20px';

export const spaceS6x = '24px';

export const spaceS8x = '32px';

export const spaceS10x = '40px';

export const spaceS18x = '72px';

export const spaceM1x = '4px';

export const spaceM2x = '8px';

export const spaceM3x = '12px';

export const spaceM4x = '16px';

export const spaceM6x = '24px';

export const spaceM8x = '32px';

export const spaceM10x = '40px';

export const spaceM12x = '48px';

export const spaceM16x = '64px';

export const spaceM20x = '80px';

export const spaceM24x = '96px';

export const spaceM32x = '128px';

export const spaceM40x = '160px';

export const spaceInsetNormalHalf = '2px 2px 2px 2px';

export const spaceInsetNormal1x = '4px 4px 4px 4px ';

export const spaceInsetNormal1xHalf = '6px 6px 6px 6px ';

export const spaceInsetNormal2x = '8px 8px 8px 8px ';

export const spaceInsetNormal3x = '12px 12px 12px 12px ';

export const spaceInsetNormal4x = '16px 16px 16px 16px ';

export const spaceInsetNormal5x = '20px 20px 20px 20px ';

export const spaceInsetSquashedZero1x = '0px 4px 0px 4px ';

export const spaceInsetSquashedHalf2x = '8px 2px 8px 2px ';

export const spaceInsetSquashed4x5x4x4x = '16px 20px 16px 16px ';

export const spaceInsetSquashed2x3x = '8px 12px 8px 12px ';

export const spaceInsetSquashed2x4x = '8px 16px 8px 16px ';

export const spaceInsetSquashed2x3x2x2x = '8px 12px 8px 8px ';

export const spaceInsetSquashed3x6x = '12px 24px 12px 24px ';

export const spaceInsetSquashed4x6x = '16px 24px 16px 24px';
