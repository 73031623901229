export const additionalDetailStyle = {
  wrapper: {
    paddingLeft: '24px',
    marginTop: '24px',
    '.MuiGrid-item': {
      '.MuiBox-root': {
        pl: 0,
      },
    },
  },
  rowDivider: {
    '.MuiGrid-container:not(:first-child)': {
      borderTop: '0.125rem solid',
      borderColor: 'other.200',
      mt: 3,
      pt: 2,
    },
  },
};
