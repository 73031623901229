import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import Dropdown from 'components/Molecules/Dropdown';
import { tdsStyle } from 'components/Organisms/MyPay/TDS/View/style';

const TDSInstructionForm = ({ regimeOptions, formik }) => {
  return (
    <Box sx={tdsStyle.instructionForm} mb={6}>
      <Typographys variant="h6" color="neutral.800" fontWeight="400">
        Please Choose a Tax Regime
      </Typographys>
      <Dropdown
        error={Boolean(formik.touched.regime && formik.errors.regime)}
        helperText={formik.touched.regime && formik.errors.regime}
        handleChange={(e) => {
          formik.handleChange(e);
        }}
        onBlur={formik.handleBlur}
        value={formik.values.regime}
        name="regime"
        id="regime"
        label="Select Tax Regime*"
        options={regimeOptions}
        align="left"
      />
    </Box>
  );
};

TDSInstructionForm.defaultProps = {
  regimeOptions: [],
  formik: {
    handleChange: () => {},
    handleBlur: () => {},
    values: {
      regime: '',
    },
  },
};
TDSInstructionForm.propTypes = {
  regimeOptions: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, name: PropTypes.string, onClick: PropTypes.func }),
  ),
  formik: PropTypes.shape({
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    values: PropTypes.shape({
      regime: PropTypes.string,
    }),
  }),
};

export default TDSInstructionForm;
