import { createTablePlugin } from '@udecode/plate';
import { plateComponentUI } from '../config/plateComponentUI';
import { createMyPlugins } from '../config/typescript';
import { autoformatPlugin } from './autoformat/autoformatPluing';
import { basicElementsPlugins } from './basicElementsPlugin';
import { basicMarksPlugins } from './basicMarkPlugin';
import { alignPlugins } from './alignPlugins';
import { breakPluings } from './breakPlugins';
import { dndPluings } from './dndPlugins';
import { listPlugins } from './listPlugins';
import { mediaPlugins } from './mediaPlugins';

export const plugins = createMyPlugins(
  [
    ...autoformatPlugin,
    ...alignPlugins,
    ...basicElementsPlugins,
    ...basicMarksPlugins,
    ...breakPluings,
    ...dndPluings,
    ...listPlugins,
    ...mediaPlugins,
    createTablePlugin(),
  ],
  {
    components: plateComponentUI,
  },
);
