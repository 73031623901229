import PropTypes from 'prop-types';
import React from 'react';
import Dialog from 'components/Molecules/Dialog';
import InputField from 'components/Atoms/InputField/index';
import { Box } from '@mui/material';
import Typographys from 'components/Atoms/Typography';

const RejectApproveDialog = ({ open, handleClose, handleCancel, handleSubmit, onChange, isApprove, reportTitle }) => {
  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      dialogTitle={`${isApprove ? 'Approve' : 'Reject'} Expense Report`}
      dialogDesc={
        <Typographys sx={{ textTransform: 'none' }} align="center" variant="body2" color="neutral.600">
          Are you sure you want to {isApprove ? 'approve' : 'reject'} the expense report -
          <Box component="span" fontWeight={600}>
            {reportTitle}
          </Box>{' '}
          ?
        </Typographys>
      }
      secondaryBtn="Cancel"
      primaryBtn={isApprove ? 'Approve' : 'Reject'}
      handlePrimaryBtn={handleSubmit}
      handleSecondaryBtn={handleCancel}
      primaryBtnColor={isApprove ? 'primary' : 'error'}
      bodyContent={
        <Box sx={{ width: '100%' }}>
          <InputField
            multiline
            name="comment"
            required
            fullWidth
            id="comment"
            label="Comments"
            autoComplete="comment"
            onChange={onChange}
          />
        </Box>
      }
    />
  );
};
RejectApproveDialog.defaultProps = {
  open: true,
  handleCancel: () => {},
  handleClose: () => {},
  handleSubmit: () => {},
  onChange: () => {},
  isApprove: true,
  reportTitle: '',
};
RejectApproveDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  onChange: PropTypes.func,
  isApprove: PropTypes.bool,
  reportTitle: PropTypes.string,
};
export default RejectApproveDialog;
