export const formatListLifeCyclePolicies = (args) => {
  return args?.map((item) => ({
    id: item.id,
    heading: item.name,
  }));
};
export const convertEmployeeData = (response) => {
  if (!response.data) {
    return {};
  }
  const {
    lifeCyclePolicyInstance,
    commentsMap,
    confirmApprovalIndexMap,
    demoteApprovalIndexMap,
    extendProbationApprovalIndexMap,
    pipApprovalIndexMap,
    promoteApprovalIndexMap,
    terminateApprovalIndexMap,
    transferApprovalIndexMap,
  } = response.data;
  return {
    policyDetails: {
      id: lifeCyclePolicyInstance?.id,
      name: lifeCyclePolicyInstance?.name,
      status: lifeCyclePolicyInstance?.status,
      createdOn: lifeCyclePolicyInstance?.createdOn,
      createdBy: lifeCyclePolicyInstance?.createdBy?.fullName,
      defaultPolicyAdministrator: lifeCyclePolicyInstance?.defaultPolicyAdministrator?.fullName,
      description: lifeCyclePolicyInstance?.description,
    },
    transferApproverDetails: {
      approverType: lifeCyclePolicyInstance?.transferApproverType,
      approverSLA: lifeCyclePolicyInstance?.transferApprovalSLA,
      approverUnit: lifeCyclePolicyInstance?.transferApprovalSLAUnit,
    },
    transferApproverLists: Object.keys(transferApprovalIndexMap || []).map((item, index) => ({
      name: transferApprovalIndexMap[item].fullNameWithID,
      id: index + 1,
      effectedBy: '',
    })),
    promotionApproverDetails: {
      approverType: lifeCyclePolicyInstance?.promoteApproverType,
      approverSLA: lifeCyclePolicyInstance?.promoteApprovalSLA,
      approverUnit: lifeCyclePolicyInstance?.promoteApprovalSLAUnit,
    },
    promotionApproverLists: Object.keys(promoteApprovalIndexMap || []).map((item, index) => ({
      name: promoteApprovalIndexMap[item].fullNameWithID,
      id: index + 1,
      effectedBy: '',
    })),
    demotionApproverDetails: {
      approverType: lifeCyclePolicyInstance?.demoteApproverType,
      approverSLA: lifeCyclePolicyInstance?.demoteApprovalSLA,
      approverUnit: lifeCyclePolicyInstance?.demoteApprovalSLAUnit,
    },
    demotionApproverLists: Object.keys(demoteApprovalIndexMap || []).map((item, index) => ({
      name: demoteApprovalIndexMap[item].fullNameWithID,
      id: index + 1,
      effectedBy: '',
    })),
    confirmationAApproverDetails: {
      approverType: lifeCyclePolicyInstance?.confirmApproverType,
      approverSLA: lifeCyclePolicyInstance?.confirmApprovalSLA,
      approverUnit: lifeCyclePolicyInstance?.confirmApprovalSLAUnit,
    },
    confirmationApproverLists: Object.keys(confirmApprovalIndexMap || []).map((item, index) => ({
      name: confirmApprovalIndexMap[item].fullNameWithID,
      id: index + 1,
      effectedBy: '',
    })),
    terminationApproverDetails: {
      approverType: lifeCyclePolicyInstance?.terminateApproverType,
      approverSLA: lifeCyclePolicyInstance?.terminateApprovalSLA,
      approverUnit: lifeCyclePolicyInstance?.terminateApprovalSLAUnit,
    },
    terminationApproverLists: Object.keys(terminateApprovalIndexMap || []).map((item, index) => ({
      name: terminateApprovalIndexMap[item].fullNameWithID,
      id: index + 1,
      effectedBy: '',
    })),
    probationExtensionApproverDetails: {
      approverType: lifeCyclePolicyInstance?.extendProbationApproverType,
      approverSLA: lifeCyclePolicyInstance?.extendProbationApprovalSLA,
      approverUnit: lifeCyclePolicyInstance?.extendProbationApprovalSLAUnit,
    },
    probationExtensionApproverLists: Object.keys(extendProbationApprovalIndexMap || []).map((item, index) => ({
      name: extendProbationApprovalIndexMap[item].fullNameWithID,
      id: index + 1,
      effectedBy: '',
    })),
    pipInitiationApproverDetails: {
      approverType: lifeCyclePolicyInstance?.pipApproverType,
      approverSLA: lifeCyclePolicyInstance?.pipApprovalSLA,
      approverUnit: lifeCyclePolicyInstance?.pipApprovalSLAUnit,
    },
    pipInitiationApproverLists: Object.keys(confirmApprovalIndexMap || []).map((item, index) => ({
      name: confirmApprovalIndexMap[item].fullNameWithID,
      id: index + 1,
      effectedBy: '',
    })),
    pipConclusionApproverDetails: {
      approverType: lifeCyclePolicyInstance?.concludePipApproverType,
      approverSLA: lifeCyclePolicyInstance?.concludePipApprovalSLA,
      approverUnit: lifeCyclePolicyInstance?.concludePipApprovalSLAUnit,
    },
    pipConclusionApproverLists: Object.keys(pipApprovalIndexMap || []).map((item, index) => ({
      name: pipApprovalIndexMap[item].fullNameWithID,
      id: index + 1,
      effectedBy: '',
    })),
    bgVerificationApproverDetails: {
      approverType: lifeCyclePolicyInstance?.backGroundVerificationApproverType,
      approverSLA: lifeCyclePolicyInstance?.backGroundVerificationApprovalSLA,
      approverUnit: lifeCyclePolicyInstance?.backGroundVerificationApprovalSLAUnit,
    },
    bgVerificationApproverLists: Object.keys(confirmApprovalIndexMap || []).map((item, index) => ({
      name: confirmApprovalIndexMap[item].fullNameWithID,
      id: index + 1,
      effectedBy: '',
    })),
  };
};
