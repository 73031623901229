import React, { useCallback, useEffect } from 'react';
import MyExpensesList from 'components/Templates/MyExpenses/MyExpensesReport';
import { useDispatch, useSelector } from 'react-redux';
import useToast from 'components/Provider/useToast';
import useController from 'hooks/useController';
import usePaginationAttributes from 'hooks/use-pagination-attributes';
import useFetcher from 'hooks/useFetcher';
import { searchMyExpenseReport, searchMyExpenseReportAction } from 'api/expenses';
import { updateMyExpensesRecords } from 'store/slices/expenses/expenses';
import Dialog from 'components/Molecules/Dialog';
import { postListApiFormat, getListApiFormat } from 'utils/ApiPayloadFormat';
import Typography from 'components/Atoms/Typography';
import { hasPermission } from 'utils/permissions';
import useColumns from './useColumns';
import useServices from './useServices';
import useActionItems from './useActionItems';
import useFilters from './useFilters';

const ListMyExpenses = () => {
  const { pageSize } = usePaginationAttributes();
  const [columns] = useColumns();
  const services = useServices();
  const addToast = useToast();
  const dispatch = useDispatch();
  const [controller, handlePageChange, handleSortChange, handleFiltersApply, handleFiltersClear] = useController();
  const [filterOptions] = useFetcher(searchMyExpenseReport);
  const [roleBasedMenu] = useActionItems(services);

  const filterService = useFilters(controller, filterOptions?.data?.data);

  const fetchExpensesReportAction = useCallback(async () => {
    if (controller?.filters && Object.keys(controller?.filters).length > 0) {
      const formData = postListApiFormat(controller, pageSize);

      const response = await searchMyExpenseReportAction(formData);
      if (response.status === 200) {
        dispatch(updateMyExpensesRecords(response.data));
        services.fetchUpdatedExpenseReport(response.data.data);
      } else {
        addToast({ type: 'error', title: 'ERROR', message: response?.data?.message });
      }
    } else {
      const queryData = getListApiFormat(controller, pageSize);
      services.fetchExpenseReport(services.state.selectedTab, queryData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controller, pageSize]);

  useEffect(() => {
    fetchExpensesReportAction();
  }, [fetchExpensesReportAction, controller, pageSize, services?.state?.refreshPage]);

  const expenseReportServices = services?.state?.listExpensesReport?.data;

  const authorities = useSelector((state) => state.session.user.authorities);
  const userCanFilter = hasPermission(authorities, ['ROLE_BASE_PERMISSION_EXPENSE_INDEX_SEARCH_MY_EXPENSE_REPORTS']);

  return (
    <>
      <MyExpensesList
        handleAdd={services.addExpenseReport}
        loading={services.state.listExpensesReport.loading}
        expenseReports={{
          columns,
          rows:
            controller?.filters && Object.keys(controller?.filters).length > 0
              ? expenseReportServices?.results
              : expenseReportServices?.data?.employeeExpenseReportInstanceList,
          roleBasedMenu,
          sortBy: controller.sortBy,
          sortDirection: controller.sort,
          onSortChange: handleSortChange,
          page: controller.page,
          onPageChange: handlePageChange,
          showPagination: true,
          count:
            controller?.filters && Object.keys(controller?.filters).length > 0
              ? expenseReportServices?.totalCount
              : expenseReportServices?.data?.employeeExpenseReportInstanceCount,
          filterFields: filterService?.filterFields,
          handleFilter: (data) => handleFiltersApply(data),
          handleFiltersClear,
          handleSelect: (row) => services.updateSelectedExpense(row),
        }}
        onTabChange={(selectedTab) => services.fetchExpenseReport(selectedTab)}
        showFilter={userCanFilter}
      />
      {!!services.showDeleteDialog && (
        <Dialog
          open={services.showDeleteDialog}
          handleClose={services.handleDeleteDialog}
          dialogTitle="Delete Expense"
          dialogDesc={
            <Typography variant="body2" color="neutral.600" align="center">
              Are you sure you want to delete
              <Typography color="neutral.800" sx={{ display: 'inline', pl: 1 }}>
                {services?.state?.selectedExpense?.displayName}
              </Typography>
              ?
            </Typography>
          }
          primaryBtn="Delete"
          secondaryBtn="Cancel"
          primaryBtnColor="error"
          handleSecondaryBtn={services.handleDeleteDialog}
          handlePrimaryBtn={() => services.deleteExpenseReport({ id: services.state.selectedExpense?.id })}
          handleCancel={services.handleDeleteDialog}
          bodyContent={false}
        />
      )}
    </>
  );
};

export default ListMyExpenses;
