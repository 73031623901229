import React, { useEffect, useState } from 'react';
import EditSeparation from 'components/Templates/Profile/InitiateSeparation/EditSeparation';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import useToast from 'components/Provider/useToast';
import { useNavigate, useParams } from 'react-router-dom';
import get from 'lodash/get';
import { fetchModifyEmployeeExitDetails, fetchInitiateEmpExit } from 'store';
import { useThunk } from 'hooks/useThunk';
import dayjs from 'dayjs';
import { modifyEmpExitDetails } from 'api/profile';
import { isEmpty, isObject } from 'lodash';

const EditSeparationPage = () => {
  const addToast = useToast();
  const navigate = useNavigate();
  const [disableButton, setDisableButton] = useState(false);
  const { id } = useParams();

  const breadcrumbData = [
    { id: 1, name: 'View Separation', path: '/home/separation/view-separation', isActive: false },
    { id: 2, name: 'Edit', path: '', isActive: true },
  ];

  const validateDateFormat = (val) => (val ? dayjs(val).format('MM/DD/YYYY') : '');

  const [getInitiateEmpExit, isLoadingInitiateEmpExit, loadingInitiateEmpExitError] = useThunk(fetchInitiateEmpExit);
  const [getEmployeeExitDetails, isLoadingEmployeeExitDetails, loadingEmployeeExitDetailsError] =
    useThunk(fetchModifyEmployeeExitDetails);

  useEffect(() => {
    async function getInitiateEmpExitData() {
      const query = new URLSearchParams({});
      await getInitiateEmpExit(query);
    }
    getInitiateEmpExitData();
  }, [getInitiateEmpExit]);

  const {
    seperationTypes = {},
    initiateExitEmpList = [],
    employeeActionResignationStatesMap = {},
  } = useSelector((state) => state.separations);
  const { modifyEmployeeExitDetails = {} } = useSelector((state) => state.profile);
  const { employeeExitInstance = {}, employeeProfileInstance = {} } = modifyEmployeeExitDetails;

  const seperationOptions =
    isObject(seperationTypes) && !isEmpty(seperationTypes)
      ? Object.entries(seperationTypes).map((item) => ({
          id: item[1],
          name: item[1],
        }))
      : [];

  const resStatusOptions =
    isObject(employeeActionResignationStatesMap) && !isEmpty(employeeActionResignationStatesMap)
      ? Object.entries(employeeActionResignationStatesMap).map((item) => ({
          id: item[1],
          name: item[1],
        }))
      : [];

  useEffect(() => {
    if (id) {
      const bodyFormData = new FormData();
      bodyFormData.append('id', id);
      bodyFormData.append('version', 1);
      getEmployeeExitDetails(bodyFormData);
    }
  }, [id, getEmployeeExitDetails]);

  const defaultValues = {};
  defaultValues.employee = employeeExitInstance?.employeeId;
  defaultValues.seperationType = employeeProfileInstance?.seperationType;
  defaultValues.relievingDate = employeeExitInstance?.approvedLWD;
  defaultValues.finalSettlementDate = employeeProfileInstance?.finalSettlementDate;
  defaultValues.resSubmissionDate = employeeExitInstance?.resignationSubmissionDate;
  defaultValues.resAcceptDate = employeeExitInstance?.resignationAcceptanceDate;
  defaultValues.resAcceptManager = employeeExitInstance?.resignationAcceptingManagerId;
  defaultValues.resStatus = employeeExitInstance?.resignationStatus;
  defaultValues.exitInterviewDate = employeeExitInstance?.exitInterviewDate;
  defaultValues.exitInterviewer = employeeExitInstance?.exitInterviewer?.id;
  defaultValues.eligibleForRehire = employeeExitInstance?.eligibleForRehire;
  defaultValues.resReasons = employeeExitInstance?.resignationReasons;

  const handleSubmit = async (values) => {
    const bodyFormData = new FormData();
    const eligibleForRehire = values.eligibleForRehire ? 'on' : '';
    bodyFormData.append('employee.id', get(values, 'employee', ''));
    bodyFormData.append('profile.seperationType', get(values, 'seperationType', ''));
    bodyFormData.append('profile.dateOfLeaving', validateDateFormat(values?.relievingDate));
    bodyFormData.append('profile.finalSettlementDate', validateDateFormat(values?.finalSettlementDate));
    bodyFormData.append('resignationSubmissionDate', validateDateFormat(values?.resSubmissionDate));
    bodyFormData.append('resignationAcceptanceDate', validateDateFormat(values?.resAcceptDate));
    bodyFormData.append('resignationAcceptingManager.id', get(values, 'resAcceptManager', ''));
    bodyFormData.append('resignationStatus', get(values, 'resStatus', ''));
    bodyFormData.append('exitInterviewDate', validateDateFormat(values?.exitInterviewDate));
    bodyFormData.append('exitInterviewer.id', get(values, 'exitInterviewer', ''));
    bodyFormData.append('eligibleForRehire', eligibleForRehire);
    bodyFormData.append('resignationReasons', get(values, 'resReasons', ''));
    try {
      setDisableButton(true);
      bodyFormData.append('id', employeeExitInstance?.id);
      bodyFormData.append('version', get(employeeExitInstance, 'version', 0));
      const response = await modifyEmpExitDetails(bodyFormData);
      if (response?.status !== 'ERROR') {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.message || 'Employee separation updated successfully',
        });
        navigate('/home/separation/view-separation');
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || 'Failed to update employee separation',
      });
    } finally {
      setDisableButton(false);
    }
  };

  const handleCancel = () => {
    navigate('/home/separation/view-separation');
  };

  if (loadingInitiateEmpExitError !== null || loadingEmployeeExitDetailsError) {
    return (
      <Typography variant="h6">
        {loadingInitiateEmpExitError?.message ||
          loadingEmployeeExitDetailsError?.message ||
          'Error while loading employee exit details.'}
      </Typography>
    );
  }

  return (
    <Box>
      <EditSeparation
        loading={isLoadingInitiateEmpExit && isLoadingEmployeeExitDetails}
        pageHeading="Modify Separation Details"
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        seperationOptions={seperationOptions}
        employeeOptions={initiateExitEmpList}
        resStatusOptions={resStatusOptions}
        managerOptions={initiateExitEmpList}
        interviewerOptions={initiateExitEmpList}
        defaultValues={defaultValues}
        breadcrumbData={breadcrumbData}
        disableButton={disableButton}
      />
    </Box>
  );
};
export default EditSeparationPage;
