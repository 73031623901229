import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
// TODO: Uncomment below imports to show notification icon button
// import IconButton from '@mui/material/IconButton';
// import NotificationsIcon from '@mui/icons-material/Notifications';
import Search from 'components/Molecules/Search';
import LogoContainer from 'components/Molecules/LogoConatiner';
import AccountMenu from 'components/Molecules/AccountMenu';
import { hasAnyPermission } from 'utils/permissions';
import { useSelector } from 'react-redux';
import AttendanceTimer from '../AttendanceTimer';
import { headerStyle } from './style';

const Header = ({ handleLeftDrawerToggle, onNotificationClick }) => {
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasTimerPermission = hasAnyPermission(authorities, [
    'ROLE_BASE_PERMISSION_ATT_INDEX_VIEW_MY_TIMER',
    'ROLE_BASE_PERMISSION_ATT_INDEX_LIST_MY_TEAM_TIMERS',
  ]);

  return (
    <Box sx={headerStyle.wrapper}>
      <LogoContainer handleLeftDrawerToggle={handleLeftDrawerToggle} width="20rem" height="auto" />

      <Box sx={headerStyle.headerRightSide}>
        <Box sx={headerStyle.searchWrap}>
          {/* TODO: below code is to show search icon button */}
          {/* <Search /> */}
        </Box>
        {!!userHasTimerPermission && <AttendanceTimer />}
        {/* TODO: Uncomment below code to show notification icon button */}
        {/* <IconButton sx={headerStyle.notificationButton} type="button" aria-label="search" onClick={onNotificationClick}>
          <NotificationsIcon />
        </IconButton> */}
        <AccountMenu />
      </Box>
    </Box>
  );
};

Header.defaultProps = {
  handleLeftDrawerToggle: () => {},
  onNotificationClick: () => {},
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
  onNotificationClick: PropTypes.func,
};

export default Header;
