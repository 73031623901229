const getAllDocumentsCombined = (employeeDocumentInstance) => {
  const employeeDoc =
    employeeDocumentInstance?.length > 0
      ? employeeDocumentInstance.map((doc, index) => ({
          slNo: index + 1,
          id: doc?.id,
          documentName: doc?.fileName,
          section: doc?.documentType,
        }))
      : [];

  return employeeDoc;
};

const getConfiguredDDocumentDetails = (employeeDocumentInstance, downloadFile) => {
  const fnfDetailsProps = {
    documentsRow: getAllDocumentsCombined(employeeDocumentInstance),
    documentsColumn: [
      {
        fieldName: `slNo`,
        label: 'Sl No',
        formatValue: ({ row, column }) => row[column.fieldName],
      },
      {
        fieldName: `documentName`,
        label: 'Document name',
        formatValue: ({ row, column }) => row[column.fieldName],
      },
      {
        fieldName: `section`,
        label: 'Section',
        formatValue: ({ row, column }) => row[column.fieldName],
      },
    ],
    documentsHandleDownload: (row) => {
      downloadFile({ docid: row?.id }, row?.documentName, 'restExpIndex/downloadAttachment');
    },
  };
  return fnfDetailsProps;
};

export { getConfiguredDDocumentDetails };
