import React from 'react';
import { ResponsiveContainer, ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import CustomLegend from 'components/Molecules/CustomLegend';
import { commonChartStyle } from '../style';

const MonthWiseGraph = ({ monthWiseData, onboardingAttritionColumns }) => {
  const theme = useTheme();

  return (
    <Box sx={commonChartStyle.chartWrapper}>
      <ResponsiveContainer width="100%" height={350}>
        <ComposedChart width={730} height={350} data={monthWiseData} margin={{ top: 40, bottom: 40 }}>
          <XAxis
            dataKey="Month"
            axisLine={{ stroke: theme.palette.other[200] }}
            tick={{ fill: theme.palette.neutral[600] }}
            tickLine={{ stroke: theme.palette.common.white }}
            style={commonChartStyle.axisLabel}
            label={{ value: 'Months', position: 'right', fill: theme.palette.neutral[800] }}
          />
          <YAxis
            yAxisId={1}
            axisLine={false}
            tick={{ fill: theme.palette.neutral[600] }}
            tickLine={{ stroke: theme.palette.common.white }}
            style={commonChartStyle.axisLabel}
            label={{ value: 'People', position: 'top', offset: 20, fill: theme.palette.neutral[800] }}
          />
          <YAxis
            yAxisId={2}
            axisLine={false}
            orientation="right"
            tick={{ fill: theme.palette.neutral[600] }}
            tickLine={{ stroke: theme.palette.common.white }}
            style={commonChartStyle.axisLabel}
          />
          <Tooltip />
          <Legend verticalAlign="bottom" align="center" iconSize="12" iconType="circle" content={<CustomLegend />} />
          <CartesianGrid strokeDasharray="3 3" vertical={false} stroke={theme.palette.other[200]} />
          <Bar name="Counts" yAxisId={1} dataKey="counts" barSize={40} fill={theme.palette.secondary[600]} />
          <Line
            name="Percentage"
            yAxisId={2}
            type="monotone"
            dataKey="percentage"
            stroke={theme.palette.secondary[200]}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
};

MonthWiseGraph.defaultProps = {
  monthWiseData: [],
};
MonthWiseGraph.propTypes = {
  monthWiseData: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      month: PropTypes.string,
      counts: PropTypes.number,
      percentage: PropTypes.number,
    }),
  ),
};

export default MonthWiseGraph;
