export const convertResponseData = (response) => {
  const statusOptions = [
    {
      id: 'ACTIVE',
      name: 'ACTIVE',
    },
    {
      id: 'INACTIVE',
      name: 'INACTIVE',
    },
  ];
  const typeOptions = [{ id: 'Attendance', name: 'Attendance' }];
  const subTypeOptions = [
    {
      id: 'Location',
      name: 'Location',
    },
    {
      id: 'Department',
      name: 'Department',
    },
    {
      id: 'Designation',
      name: 'Designation',
    },
    {
      id: 'Grade',
      name: 'Grade',
    },
    {
      id: 'Level',
      name: 'Level',
    },
  ];
  let entitiesOptions = [];
  let filterList = [];

  if (response.data?.locationsList) {
    entitiesOptions = response.data?.locationsList?.map((item) => ({ id: item.id, name: item.name }));
  }

  if (response.data?.filters) {
    filterList = response.data?.filters.map((item) => ({
      filterType: item.filterType,
      filterSubType: item.filterSubType,
      allowedLocations: item.allowedLocations?.map((location) => location.name).join(';'),
      allowedLocationsId: item.allowedLocations?.map((location) => location.id),
      status: item.status,
      createdOn: item.createdOn,
      createdBy: item.createdBy?.fullName,
      lastModifiedOn: item.lastModifiedOn,
      id: item.id,
    }));
  }

  return {
    statusOptions,
    typeOptions,
    subTypeOptions,
    entitiesOptions,
    filterList,
  };
};
