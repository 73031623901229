import React, { useEffect, useState } from 'react';
import InitiateEmployee from 'components/Templates/Employee/Company/Separation/InitiateEmployee';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import useToast from 'components/Provider/useToast';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import get from 'lodash/get';
import { fetchEmployeeExitDetails, fetchInitiateEmpExit } from 'store';
import { useThunk } from 'hooks/useThunk';
import dayjs from 'dayjs';
import { modifyEmpExitDetails, saveEmpExit } from 'api/profile';
import { isEmpty, isObject } from 'lodash';

const AddEditPage = () => {
  const addToast = useToast();
  const navigate = useNavigate();
  const [disableButton, setDisableButton] = useState(false);
  const location = useLocation();
  const { id } = useParams();

  const addBreadcrumbData = [
    { id: 1, name: 'Separation', path: '/myteam/separations', isActive: false },
    { id: 2, name: 'Initiate', path: '/initiate', isActive: true },
  ];

  const editBreadcrumbData = [
    { id: 1, name: 'Separation', path: '/myteam/separations', isActive: false },
    { id: 2, name: 'View Details', path: `/myteam/separations/view/${id}`, isActive: false },
    { id: 3, name: 'Edit', path: '/initiate', isActive: true },
  ];

  const validateDateFormat = (val) => (val ? dayjs(val).format('MM/DD/YYYY') : '');
  const defaultValues = {};

  const isEditMode = location.pathname.includes('edit') && id;

  const [getInitiateEmpExit, isLoadingInitiateEmpExit, loadingInitiateEmpExitError] = useThunk(fetchInitiateEmpExit);
  const [getEmployeeExitDetails, isLoadingEmployeeExitDetails, loadingEmployeeExitDetailsError] =
    useThunk(fetchEmployeeExitDetails);

  useEffect(() => {
    async function getInitiateEmpExitData() {
      const query = new URLSearchParams({});
      await getInitiateEmpExit(query);
    }
    getInitiateEmpExitData();
  }, [getInitiateEmpExit]);

  const {
    employeeDetails = [],
    employeeProfileInstance = [],
    seperationTypes = {},
    initiateExitEmpList = [],
    employeeActionResignationStatesMap = {},
  } = useSelector((state) => state.separations);

  const seperationOptions =
    isObject(seperationTypes) && !isEmpty(seperationTypes)
      ? Object.entries(seperationTypes).map((item) => ({
          id: item[1],
          name: item[1],
        }))
      : [];

  const resStatusOptions =
    isObject(employeeActionResignationStatesMap) && !isEmpty(employeeActionResignationStatesMap)
      ? Object.entries(employeeActionResignationStatesMap).map((item) => ({
          id: item[1],
          name: item[1],
        }))
      : [];

  useEffect(() => {
    if (isEditMode && id) {
      const query = new URLSearchParams({});
      query.append('id', id);
      getEmployeeExitDetails(query);
    }
  }, [isEditMode, id, getEmployeeExitDetails]);

  if (isEditMode) {
    defaultValues.employee = employeeDetails?.employeeId;
    defaultValues.seperationType = employeeProfileInstance?.seperationType;
    defaultValues.relievingDate = employeeDetails?.approvedLWD;
    defaultValues.finalSettlementDate = employeeProfileInstance?.finalSettlementDate;
    defaultValues.resSubmissionDate = employeeDetails?.resignationSubmissionDate;
    defaultValues.resAcceptDate = employeeDetails?.resignationAcceptanceDate;
    defaultValues.resAcceptManager = employeeDetails?.resignationAcceptingManagerId;
    defaultValues.resStatus = employeeDetails?.resignationStatus;
    defaultValues.exitInterviewDate = employeeDetails?.exitInterviewDate;
    defaultValues.exitInterviewer = employeeDetails?.exitInterviewer?.id;
    defaultValues.eligibleForRehire = employeeDetails?.eligibleForRehire;
    defaultValues.resReasons = employeeDetails?.resignationReasons;
  }

  const handleSubmit = async (values) => {
    const bodyFormData = new FormData();
    const eligibleForRehire = values.eligibleForRehire ? 'on' : '';
    bodyFormData.append('employee.id', get(values, 'employee', ''));
    bodyFormData.append('profile.seperationType', get(values, 'seperationType', ''));
    bodyFormData.append('profile.dateOfLeaving', validateDateFormat(values?.relievingDate));
    bodyFormData.append('profile.finalSettlementDate', validateDateFormat(values?.finalSettlementDate));
    bodyFormData.append('resignationSubmissionDate', validateDateFormat(values?.resSubmissionDate));
    bodyFormData.append('resignationAcceptanceDate', validateDateFormat(values?.resAcceptDate));
    bodyFormData.append('resignationAcceptingManager.id', get(values, 'resAcceptManager', ''));
    bodyFormData.append('resignationStatus', get(values, 'resStatus', ''));
    bodyFormData.append('exitInterviewDate', validateDateFormat(values?.exitInterviewDate));
    bodyFormData.append('exitInterviewer.id', get(values, 'exitInterviewer', ''));
    bodyFormData.append('eligibleForRehire', eligibleForRehire);
    bodyFormData.append('resignationReasons', get(values, 'resReasons', ''));
    try {
      setDisableButton(true);
      if (isEditMode) {
        bodyFormData.append('id', employeeDetails?.id);
        bodyFormData.append('version', get(employeeDetails, 'version', 0));
      }

      const response = isEditMode ? await modifyEmpExitDetails(bodyFormData) : await saveEmpExit(bodyFormData);
      if (response?.status !== 'ERROR') {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.message || `Employee separation ${isEditMode ? 'updated' : 'added'} successfully`,
        });
        navigate(`/myteam/separations/view/${id}`);
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to ${isEditMode ? 'update' : 'add new'} employee separation`,
      });
    } finally {
      setDisableButton(false);
    }
  };

  const handleCancel = () => {
    navigate('/myteam/separations');
  };

  if (loadingInitiateEmpExitError !== null || (isEditMode && loadingEmployeeExitDetailsError)) {
    return (
      <Typography variant="h6">
        {loadingInitiateEmpExitError?.message ||
          loadingEmployeeExitDetailsError?.message ||
          'Error while loading employee exit details.'}
      </Typography>
    );
  }

  return (
    <Box>
      <InitiateEmployee
        loading={isEditMode ? isLoadingInitiateEmpExit && isLoadingEmployeeExitDetails : isLoadingInitiateEmpExit}
        pageHeading={isEditMode ? 'Edit Employee Exit Details' : 'Initiate Employee Exit Details'}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        seperationOptions={seperationOptions}
        employeeOptions={initiateExitEmpList}
        resStatusOptions={resStatusOptions}
        managerOptions={initiateExitEmpList}
        interviewerOptions={initiateExitEmpList}
        allowEdit={isEditMode}
        defaultValues={defaultValues}
        breadcrumbData={isEditMode ? editBreadcrumbData : addBreadcrumbData}
        disableButton={disableButton}
      />
    </Box>
  );
};
export default AddEditPage;
