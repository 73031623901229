import PropTypes from 'prop-types';
import React from 'react';
import Typographys from 'components/Atoms/Typography';
import { Box, Divider, IconButton } from '@mui/material';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Button from 'components/Atoms/Button';
import { calendarStyle } from '../../style';

const TaskDialog = ({ data }) => {
  return (
    <Box>
      <Box mb={2}>
        <Typographys variant="h4" align="left">
          {data.title}
        </Typographys>
      </Box>

      <Typographys align="left" color="neutral.600">
        {`Description - ${data.description}`}
      </Typographys>
      <Divider sx={{ ...calendarStyle.calenderDivider, my: 2 }} />
      <Box sx={{ ...calendarStyle.deadlineButtonsWrapper, mb: 2 }}>
        <Box sx={calendarStyle.titleWithIcon}>
          <IconButton sx={{ color: 'actions.600', fontSize: '2.25rem' }}>
            <PermIdentityIcon fontSize="inherit" />
          </IconButton>
          <Box>
            <Typographys
              sx={{ textTransform: 'none' }}
              color="neutral.800"
            >{`Query added by - ${data.queryAddedBy}`}</Typographys>
          </Box>
        </Box>

        <Box sx={calendarStyle.titleWithIcon}>
          <IconButton sx={{ color: 'actions.600', fontSize: '2.25rem' }}>
            <CalendarTodayIcon fontSize="inherit" />
          </IconButton>
          <Box>
            <Typographys
              sx={{ textTransform: 'none' }}
              color="neutral.800"
            >{`Deadline - ${data.deadlineDate}`}</Typographys>
          </Box>
        </Box>
      </Box>

      <Box sx={calendarStyle.deadlineButtonsWrapper}>
        <Box sx={calendarStyle.meetingButtonsWrapper}>
          <Button
            fullWidth={false}
            size="medium"
            variant="contained"
            color="primary"
            onClick={() => {
              data.handleViewDetails();
            }}
          >
            View details
          </Button>
        </Box>
      </Box>
      <Divider sx={{ ...calendarStyle.calenderDivider, mt: 2 }} />
    </Box>
  );
};
TaskDialog.defaultProps = {
  data: {},
};
TaskDialog.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    queryAddedBy: PropTypes.string,
    deadlineDate: PropTypes.string,
    handleViewDetails: PropTypes.func,
  }),
};
export default TaskDialog;
