import PropTypes from 'prop-types';
import React from 'react';
import Dialog from 'components/Molecules/Dialog';

const LogoutDialog = ({ handleConfirmBtn, secondaryBtn, primaryBtn, primaryBtnColor, open, handleClose }) => {
  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      dialogTitle="Confirm Logout"
      bodyContent="You can improve your security further after logging out by closing this opened browser."
      secondaryBtn={secondaryBtn}
      primaryBtn={primaryBtn}
      handlePrimaryBtn={handleConfirmBtn}
      handleSecondaryBtn={handleClose}
      primaryBtnColor={primaryBtnColor}
    />
  );
};
LogoutDialog.defaultProps = {
  primaryBtnColor: 'warning',
  secondaryBtn: 'Close',
  primaryBtn: 'Confirm',
  handleConfirmBtn: () => {},
  open: true,
  handleClose: () => {},
};
LogoutDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  primaryBtnColor: PropTypes.string,
  handleConfirmBtn: PropTypes.func,
  secondaryBtn: PropTypes.string,
  primaryBtn: PropTypes.string,
};
export default LogoutDialog;
