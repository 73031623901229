import React, { useCallback, useEffect } from 'react';
import MyLeaveRequests from 'components/Templates/Leave/MyLeaves/MyLeaveRequests';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMyPendingLeaves } from 'store';
import { useThunk } from 'hooks/useThunk';
import useController from 'hooks/useController';
import usePaginationAttributes from 'hooks/use-pagination-attributes';
import Dialog from 'components/Molecules/Dialog';

import { updateSelectedLeave, updateMyLeaves } from 'store/slices/leaves/myLeaves';
import { listMyLeaveRequestsAction } from 'api/leave';
import dayjs from 'dayjs';
import useColumns from './useColumns';
import useActionItems from './useActionItems';
import useLeaveServices from './useLeaveServices';
import SubmitDialog from './SubmitDialog';

const MyLeavesList = () => {
  const dispatch = useDispatch();
  const { pageSize } = usePaginationAttributes();
  const [columns] = useColumns();
  const [controller, handlePageChange, handleSortChange, handleFiltersApply, handleFiltersClear] = useController();
  const navigate = useNavigate();
  const [doFetchMyLeave, isLoadingMyLeaves, loadingLeavesError] = useThunk(fetchMyPendingLeaves);
  const { selectedMyLeave, selectedMyLeaves, myLeaves, loading } = useSelector((state) => {
    return state.myLeaves;
  });

  const fetchLeavesAction = useCallback(async () => {
    if (controller?.filters?.length) {
      const bodyFormData = new FormData();
      bodyFormData.append('sort', controller.sortBy);
      bodyFormData.append('order', controller.sort);
      controller?.filters.forEach((filter) => bodyFormData.append(filter.property, filter.value));
      bodyFormData.append('offset', 0);
      bodyFormData.append('max', pageSize);
      const response = await listMyLeaveRequestsAction(bodyFormData);
      if (response.status === 200 || response.message === 'Successfully done listMyLeaveRequestsAction') {
        dispatch(updateMyLeaves(response));
      }
    } else {
      const query = new URLSearchParams({});
      if (controller) {
        query.append('sort', controller.sortBy);
        query.append('order', controller.sort);
      }
      query.append('offset', controller.page * pageSize);
      query.append('max', pageSize);
      doFetchMyLeave(query);
    }
  }, [doFetchMyLeave, controller, pageSize]);

  useEffect(() => {
    fetchLeavesAction();
  }, [fetchLeavesAction, controller, pageSize, loading]);

  const leaveServices = useLeaveServices(fetchLeavesAction);
  const [menuList] = useActionItems(leaveServices);
  const handleCloseDialog = () => leaveServices.dispatchDialog({ type: 'Close' });

  if (loadingLeavesError !== null) {
    return <Typography variant="h6">{loadingLeavesError?.message || 'Error while loading leaves list.'}</Typography>;
  }

  const categoryOptions = myLeaves?.leaveCategories?.length
    ? myLeaves?.leaveCategories.map((data) => ({
        name: data.displayName,
        id: data.id,
      }))
    : [];

  const filterNameMap = {
    'Start Date': 'startDate',
    'Leave Category': 'leaveCategoryIDs',
    'End Date': 'endDate',
  };

  const submitFilter = async (data) => {
    const resultFilter = data.map((filterItem) => {
      const resultBody = { property: { name: '' } };
      resultBody.property.name = filterNameMap[filterItem.name];
      const value = filterItem.type === 'date' ? dayjs(filterItem.value).format('MM/DD/YYYY') : filterItem.value;
      resultBody.value = value;
      return resultBody;
    });

    handleFiltersApply(resultFilter);
  };

  return (
    <>
      <MyLeaveRequests
        loading={isLoadingMyLeaves}
        rows={myLeaves?.data}
        columns={columns}
        rowsPerPage={10}
        roleBasedMenu={menuList}
        totalRecordCount={myLeaves?.count}
        loadNextPageData={handlePageChange}
        handleSort={handleSortChange}
        page={controller.page}
        sortDirection={controller.sort}
        sortBy={controller.sortBy}
        pageHeading="List Leave Requests Pending Action"
        handleSelect={(row) => {
          dispatch(updateSelectedLeave(row));
        }}
        handleRowClick={(row) => leaveServices.viewLeave(row)}
        handleAddRequest={() => navigate('/myteam/leaves/requests-pending-action/add')}
        filterFields={[
          {
            name: 'Start Date',
            type: 'date',
          },
          {
            name: 'End Date',
            type: 'date',
          },
          {
            name: 'Leave Category',
            type: 'dropdown',
            options: categoryOptions,
          },
        ]}
        handleFiltersClear={handleFiltersClear}
        handleFilter={submitFilter}
        actions={false}
      />
      {selectedMyLeave !== null && (
        <>
          <Dialog
            open={leaveServices.dialogState.open === 'Delete'}
            dialogTitle="Delete Leave"
            dialogDesc={`Are you sure you want to delete ${selectedMyLeave?.leaveType}?`}
            primaryBtn="Delete"
            secondaryBtn="Cancel"
            primaryBtnColor="error"
            handleSecondaryBtn={handleCloseDialog}
            bodyContent={false}
            handlePrimaryBtn={() => leaveServices.deleteLeave(selectedMyLeave?.id, false)}
            handleClose={handleCloseDialog}
          />
          <SubmitDialog
            handleCancel={handleCloseDialog}
            open={leaveServices.dialogState.open === 'Submit'}
            name={selectedMyLeave?.leaveType}
            handleSubmit={() => leaveServices.submitLeave({ id: selectedMyLeave?.id, refresh: true })}
            primaryButtonLabel="Submit"
          />
          <SubmitDialog
            handleCancel={handleCloseDialog}
            open={leaveServices.dialogState.open === 'Approve'}
            name={selectedMyLeave?.leaveType}
            handleSubmit={({ note }) => leaveServices.approveLeave({ id: selectedMyLeave?.id, note, refresh: true })}
            title="Approve Leave"
            action="Approve"
            primaryButtonLabel="Approve"
          />
          <SubmitDialog
            handleCancel={handleCloseDialog}
            open={leaveServices.dialogState.open === 'Reject'}
            name={selectedMyLeave?.leaveType}
            handleSubmit={({ note }) => leaveServices.rejectLeave({ id: selectedMyLeave?.id, note, refresh: true })}
            title="Reject Leave"
            action="Reject"
            primaryButtonLabel="Reject"
          />
          <SubmitDialog
            handleCancel={handleCloseDialog}
            open={leaveServices.dialogState.open === 'Revert'}
            name={selectedMyLeave?.leaveType}
            handleSubmit={({ note }) => leaveServices.revertLeave({ id: selectedMyLeave?.id, note, refresh: true })}
            title="Revert Leave"
            action="Revert"
            primaryButtonLabel="Revert"
          />
        </>
      )}
    </>
  );
};

export default MyLeavesList;
