import React, { useEffect } from 'react';
import LoginTemplate from 'components/Templates/Login';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, getCurrentUser } from 'api/login';
import { updateUser } from 'store/slices/user';
import useToast from 'components/Provider/useToast';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const addToast = useToast();
  const { empDBID = '', candidateID = '' } = useSelector((state) => state?.session?.user);

  const handleLogin = async (username, password) => {
    try {
      const loginResponse = await loginUser({ username, password });
      if (loginResponse.access_token) {
        localStorage.setItem('token', loginResponse.access_token);
        const currentUser = await getCurrentUser();
        if (currentUser.status === 200 && currentUser.data) {
          addToast({
            type: 'success',
            title: 'SUCCESS',
            message: currentUser?.data?.message || `Login successfull`,
          });
          dispatch(updateUser({ ...currentUser.data, authorities: loginResponse.roles }));
          // navigate('/dashboard');
        } else {
          dispatch(updateUser(null));
          navigate('/login');
        }
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message:
          error?.response?.status === 401
            ? 'Invalid username or password'
            : error.message || `Failed to login, please try again later`,
      });
    }
  };

  useEffect(() => {
    if (empDBID || candidateID) {
      navigate('/home/dashboard');
    }
  }, [empDBID, candidateID, navigate]);

  return (
    <LoginTemplate
      handleOnSubmit={handleLogin}
      forgotPasswordLink="/reset"
      headingText="ONE PLATFORM FOR ALL HR NEEDS"
      subHeading="Discover the potential of smoothly integrated workflows, systems, and procedures. "
    />
  );
};

export default Login;
