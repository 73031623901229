import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Button from 'components/Atoms/Button';
import InputField from 'components/Atoms/InputField';
import Dropdown from 'components/Molecules/Dropdown';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Attachments from 'components/Atoms/Attachments';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTheme } from '@mui/material/styles';
import { profileStyle } from 'components/Organisms/Profile/style';
import DatePicker from 'components/Atoms/Datepicker';
import dayjs from 'dayjs';

const AddAndEditTask = ({ handleSubmit, handleCancel, loading, preModifyData }) => {
  const defaultData = preModifyData || {
    taskName: '',
    owner: '',
    dueBy: '',
    dueMarkerDate: '',
    taskBeginDate: '',
    taskCompletionDate: '',
    taskCompletionPercentage: '',
    taskDescription: '',
    amount: '',
    action: '',
    taskCompletionStatus: '',
    comments: '',
    attachments: '',
  };

  const dependentSchema = yup.object().shape({
    taskName: yup.string().required('Required'),
    owner: yup.string().required('Required'),
    dueBy: yup.string().required('Required'),
    action: yup.string().required('Required'),
    taskCompletionStatus: yup.string().required('Required'),
    taskDescription: yup.string().required('Required'),
    taskCompletionPercentage: yup.string().required('Required'),
  });
  const formik = useFormik({
    initialValues: defaultData,
    validateOnMount: true,
    validationSchema: dependentSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });
  const theme = useTheme();
  const handleDateChange = (date, fieldName) => {
    formik.setFieldValue(fieldName, date);
  };

  return (
    <Box sx={{ py: 1 }}>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <PerfectScrollbar>
          <Box sx={{ maxHeight: 'calc(100vh - 48.3rem)' }}>
            <Grid container rowSpacing={2} columnSpacing={2} sx={profileStyle.formContainer}>
              <Grid item xs={12} sm={6} md={4}>
                <InputField
                  error={Boolean(formik.touched.taskName && formik.errors.taskName)}
                  helperText={formik.touched.taskName && formik.errors.taskName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  label="Task Name*"
                  value={formik.values.taskName}
                  name="taskName"
                  id="taskName"
                  fullWidth
                  InputLabelProps={{
                    shrink: formik.values.taskName,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Dropdown
                  error={Boolean(formik.touched.owner && formik.errors.owner)}
                  helperText={formik.touched.owner && formik.errors.owner}
                  handleChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  label="Owner*"
                  value={formik.values.owner}
                  name="owner"
                  id="owner"
                  options={preModifyData?.employeeList}
                  keyGetter={(item) => item.id}
                  titleGetter={(item) => item.name}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <DatePicker
                  error={Boolean(formik.touched.dueBy && formik.errors.dueBy)}
                  helperText={formik.touched.dueBy && formik.errors.dueBy}
                  value={formik.values?.dueBy ? dayjs(formik.values?.dueBy) : null}
                  onChange={(date) => handleDateChange(date, 'dueBy')}
                  name="dueBy"
                  id="dueBy"
                  label="Due By*"
                  fullWidth
                  autoFocus
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <DatePicker
                  error={Boolean(formik.touched.dueMarkerDate && formik.errors.dueMarkerDate)}
                  helperText={formik.touched.dueMarkerDate && formik.errors.dueMarkerDate}
                  value={formik.values?.dueMarkerDate ? dayjs(formik.values?.dueMarkerDate) : null}
                  onChange={(date) => handleDateChange(date, 'dueMarkerDate')}
                  name="dueMarkerDate"
                  id="dueMarkerDate"
                  label="Due Marker Date"
                  fullWidth
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DatePicker
                  error={Boolean(formik.touched.taskBeginDate && formik.errors.taskBeginDate)}
                  helperText={formik.touched.taskBeginDate && formik.errors.taskBeginDate}
                  value={formik.values?.taskBeginDate ? dayjs(formik.values?.taskBeginDate) : null}
                  onChange={(date) => handleDateChange(date, 'taskBeginDate')}
                  name="taskBeginDate"
                  id="taskBeginDate"
                  label="Task Begin Date"
                  fullWidth
                  autoFocus
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <DatePicker
                  error={Boolean(formik.touched.taskCompletionDate && formik.errors.taskCompletionDate)}
                  helperText={formik.touched.taskCompletionDate && formik.errors.taskCompletionDate}
                  value={formik.values?.taskCompletionDate ? dayjs(formik.values?.taskCompletionDate) : null}
                  onChange={(date) => handleDateChange(date, 'taskCompletionDate')}
                  name="taskCompletionDate"
                  id="taskCompletionDate"
                  label="Task Completion Date"
                  fullWidth
                  autoFocus
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <InputField
                  error={Boolean(formik.touched.amount && formik.errors.amount)}
                  helperText={formik.touched.amount && formik.errors.amount}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  label="Amount"
                  value={formik.values.amount}
                  name="amount"
                  id="amount"
                  fullWidth
                  type="number"
                  InputLabelProps={{
                    shrink: formik.values.amount != null,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Dropdown
                  error={Boolean(formik.touched.action && formik.errors.action)}
                  helperText={formik.touched.action && formik.errors.action}
                  handleChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  label="Action*"
                  value={formik.values.action}
                  name="action"
                  id="action"
                  options={preModifyData?.taskActionOptions}
                  keyGetter={(item) => item.name}
                  titleGetter={(item) => item.name}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Dropdown
                  error={Boolean(formik.touched.taskCompletionStatus && formik.errors.taskCompletionStatus)}
                  helperText={formik.touched.taskCompletionStatus && formik.errors.taskCompletionStatus}
                  handleChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  label="Task Completion Status*"
                  value={formik.values.taskCompletionStatus}
                  name="taskCompletionStatus"
                  id="taskCompletionStatus"
                  options={preModifyData?.simpleTaskStatusOptions}
                  keyGetter={(item) => item.name}
                  titleGetter={(item) => item.name}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <InputField
                  error={Boolean(formik.touched.taskCompletionPercentage && formik.errors.taskCompletionPercentage)}
                  helperText={formik.touched.taskCompletionPercentage && formik.errors.taskCompletionPercentage}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  label="Task Completion Percentage*"
                  value={formik.values.taskCompletionPercentage}
                  name="taskCompletionPercentage"
                  id="taskCompletionPercentage"
                  fullWidth
                  type="number"
                  InputLabelProps={{
                    shrink: formik.values.taskCompletionPercentage != null,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <InputField
                  error={Boolean(formik.touched.taskDescription && formik.errors.taskDescription)}
                  helperText={formik.touched.taskDescription && formik.errors.taskDescription}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  label="Task Description*"
                  value={formik.values.taskDescription}
                  name="taskDescription"
                  id="taskDescription"
                  fullWidth
                  multiline
                  InputLabelProps={{
                    shrink: formik.values.taskDescription,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <InputField
                  error={Boolean(formik.touched.comments && formik.errors.comments)}
                  helperText={formik.touched.comments && formik.errors.comments}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  label="Comments"
                  value={formik.values.comments}
                  name="comments"
                  id="comments"
                  fullWidth
                  multiline
                  InputLabelProps={{
                    shrink: formik.values.comments,
                  }}
                />
              </Grid>
            </Grid>

            <Box sx={{ display: 'flex', mb: 2, mt: 1 }}>
              <Attachments
                isDeleteIcon
                handleChange={(event) => {
                  formik.setFieldValue(`attachments`, event.target.files[0]);
                }}
                name="attachments"
                id="attachments"
                value={formik.values.attachments}
                handleDelete={() => {
                  formik.setFieldValue(`attachments`, '');
                }}
              />
            </Box>
          </Box>
        </PerfectScrollbar>

        <Box sx={profileStyle.btnContainer}>
          <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />
          <Box sx={profileStyle.submitWrapper}>
            <Box sx={{ display: 'flex', flex: '1', justifyContent: 'end', gap: 2 }}>
              <Button
                size="large"
                color="info"
                variant="outlined"
                type="cancel"
                onClick={() => {
                  formik.resetForm();
                  handleCancel();
                }}
              >
                Cancel
              </Button>
              <LoadingButton color="primary" loading={loading} size="large" type="submit" variant="contained">
                Update
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

AddAndEditTask.defaultProps = {
  handleSubmit: () => {},
  handleCancel: () => {},
  defaultValues: {},
  loading: false,
};

AddAndEditTask.propTypes = {
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  defaultValues: PropTypes.shape({}),
  loading: PropTypes.bool,
};

export default AddAndEditTask;
