import dayjs from 'dayjs';

const sortObjectAlphabetically = (objectAgs) => {
  const sortedEntries = Object.entries(objectAgs).sort(([keyA], [keyB]) => keyA.localeCompare(keyB));
  const sortedObj = Object.fromEntries(sortedEntries);

  return sortedObj;
};

const calculateArrears = (
  currencySymbol,
  payheadValuesMap,
  payheadLineEntriesMap,
  earningsLineEntries = {},
  benefitsLineEntries = {},
  reimbursementsLineEntries = {},
) => {
  let totalArrearAmount = 0;

  Object.entries(sortObjectAlphabetically(earningsLineEntries))?.forEach(([earningsName, earningAmount]) => {
    const salarySlipLineEntryInstance = payheadLineEntriesMap[earningsName];
    if (
      payheadValuesMap[earningsName] &&
      !salarySlipLineEntryInstance?.payhead?.isInternalPayhead &&
      salarySlipLineEntryInstance?.payhead?.name?.includes('Arrears')
    ) {
      totalArrearAmount += earningAmount;
    }
  });

  Object.entries(sortObjectAlphabetically(benefitsLineEntries))?.forEach(([benefitsName, benefitsAmount]) => {
    const salarySlipLineEntryInstance = payheadLineEntriesMap[benefitsName];
    if (payheadValuesMap[benefitsName] && salarySlipLineEntryInstance?.payhead?.name?.includes('Arrears')) {
      totalArrearAmount += benefitsAmount;
    }
  });

  Object.entries(sortObjectAlphabetically(reimbursementsLineEntries))?.forEach(
    ([reimbursementName, reimbursementAmount]) => {
      const salarySlipLineEntryInstance = payheadLineEntriesMap[reimbursementName];
      if (payheadValuesMap[reimbursementName] && salarySlipLineEntryInstance?.payhead?.name?.includes('Arrears')) {
        totalArrearAmount += reimbursementAmount;
      }
    },
  );

  return [
    { earnings: '', amount: '' },
    { earnings: 'Arrears', amount: `${currencySymbol} ${totalArrearAmount}` },
  ];
};

const getEarningsRows = (
  currencySymbol,
  payheadValuesMap,
  payheadLineEntriesMap,
  earningsLineEntries = {},
  benefitsLineEntries = {},
  reimbursementsLineEntries = {},
) => {
  const earningsLineEntriesList = Object.entries(sortObjectAlphabetically(earningsLineEntries))?.map(
    ([earningsName, earningAmount]) => {
      const salarySlipLineEntryInstance = payheadLineEntriesMap[earningsName];
      if (
        payheadValuesMap[earningsName] &&
        !salarySlipLineEntryInstance?.payhead?.isInternalPayhead &&
        !salarySlipLineEntryInstance?.payhead?.name?.includes('Arrears')
      ) {
        return {
          earnings: earningsName,
          amount: `${currencySymbol} ${earningAmount}`,
        };
      }
      return {};
    },
  );

  const benefitsLineEntriesList = Object.entries(sortObjectAlphabetically(benefitsLineEntries))?.map(
    ([benefitsName, benefitsAmount]) => {
      const salarySlipLineEntryInstance = payheadLineEntriesMap[benefitsName];
      if (payheadValuesMap[benefitsName] && !salarySlipLineEntryInstance?.payhead?.name?.includes('Arrears')) {
        return {
          earnings: benefitsName,
          amount: `${currencySymbol} ${benefitsAmount}`,
        };
      }
      return {};
    },
  );

  const reimbursementsLineEntriesList = Object.entries(sortObjectAlphabetically(reimbursementsLineEntries))?.map(
    ([reimbursementName, reimbursementAmount]) => {
      const salarySlipLineEntryInstance = payheadLineEntriesMap[reimbursementName];
      if (payheadValuesMap[reimbursementName] && !salarySlipLineEntryInstance?.payhead?.name?.includes('Arrears')) {
        return {
          earnings: reimbursementName,
          amount: `${currencySymbol} ${reimbursementAmount}`,
        };
      }
      return {};
    },
  );

  const arrearsList = calculateArrears(
    currencySymbol,
    payheadValuesMap,
    payheadLineEntriesMap,
    (earningsLineEntries = {}),
    (benefitsLineEntries = {}),
    (reimbursementsLineEntries = {}),
  );

  const finalEarningRows = [
    ...earningsLineEntriesList,
    ...benefitsLineEntriesList,
    ...reimbursementsLineEntriesList,
    ...arrearsList,
  ];

  return finalEarningRows;
};

const getDeductionRows = (currencySymbol, deductionsLineEntries = {}, statDeductionsEmployeeLineEntries = {}) => {
  const allDeductionsMerged = { ...deductionsLineEntries, ...statDeductionsEmployeeLineEntries };
  const finalDeductionRows = Object.entries(allDeductionsMerged)?.map(([deductionName, deductionAmount]) => ({
    deductions: deductionName,
    amount: `${currencySymbol} ${deductionAmount}`,
  }));

  return finalDeductionRows?.sort((a, b) => a?.earnings?.localeCompare(b?.earnings));
};

const getConfiguredFnfDetails = (FnfDetails) => {
  const currencySymbol = FnfDetails?.defaultCurrency?.symbol;
  const numberOfWorkingDays = FnfDetails?.salarySlipInstance?.numberOfWorkingDays;
  const numberOfLOPDays = FnfDetails?.salarySlipInstance?.numberOfLOPDays;
  const fnfDetailsProps = {
    employeeData: {
      employeeDesignation: FnfDetails?.employeeInstance?.designation?.name || '-',
      lastWorkingDate: FnfDetails?.employeeProfileInstance?.dateOfLeaving
        ? dayjs(FnfDetails?.employeeProfileInstance?.dateOfLeaving).format('DD MMMM YYYY')
        : '-',
      employeeBank: FnfDetails?.bankDetail?.bankName || '-',
      employeeBankAccountNumber: FnfDetails?.employeeProfileInstance?.bankAccountDetails?.accountNumber || '-',
      employeePanNumber: FnfDetails?.employeeInstance?.panNumberIndia || '-',
      employeePfNumber: FnfDetails?.employeeInstance?.epfAccountNumberIndia || '-',
      leavesEncashed: FnfDetails?.employeeFnFSettlementInstance?.numLeavesEncashed || '-',
      processedDays: FnfDetails?.salarySlipInstance?.numberOfCalendarDays || '-',
      lossOfPay: numberOfLOPDays || '-',
      paidDays: numberOfWorkingDays - numberOfLOPDays,
      startDate: FnfDetails?.salarySlipInstance?.periodStartDate
        ? dayjs(FnfDetails?.salarySlipInstance?.periodStartDate).format('DD MMMM YYYY')
        : '-',
      endDate: FnfDetails?.salarySlipInstance?.periodEndDate
        ? dayjs(FnfDetails?.salarySlipInstance?.periodEndDate).format('DD MMMM YYYY')
        : '-',
    },
    fnfSalSlipId: FnfDetails?.employeeFnFSettlementInstance?.fnfSalarySlip?.id,
    employeeId: FnfDetails?.employeeInstance?.profile?.employeeID,
    employeeName: FnfDetails?.employeeInstance?.fullName,
    employeeFirstName: FnfDetails?.employeeInstance?.firstName,
    netFinalEarnings: [`${currencySymbol} ${FnfDetails?.salarySlipInstance?.netFinalEarnings || 0}`],
    netFinalDeductions: [`${currencySymbol} ${FnfDetails?.salarySlipInstance?.netFinalDeductions || 0}`],
    netFinalSalary: [`${currencySymbol} ${FnfDetails?.salarySlipInstance?.netFinalSalary || 0}`],
    earningsColumns: [
      {
        fieldName: 'earnings',
        columnTitle: 'Earnings',
        columnWidth: '80%',
      },
      {
        fieldName: 'amount',
        columnTitle: '',
        columnWidth: '20%',
      },
    ],
    earningsRows: getEarningsRows(
      currencySymbol,
      FnfDetails?.payheadValuesMap,
      FnfDetails?.payheadLineEntriesMap,
      FnfDetails?.earningsLineEntries,
      FnfDetails?.benefitsLineEntries,
      FnfDetails?.reimbursementsLineEntries,
    ),
    deductionsColumns: [
      {
        fieldName: 'deductions',
        columnTitle: 'Deductions',
        columnWidth: '80%',
      },
      {
        fieldName: 'amount',
        columnTitle: '',
        columnWidth: '20%',
      },
    ],
    deductionsRows: getDeductionRows(
      currencySymbol,
      FnfDetails?.deductionsLineEntries,
      FnfDetails?.statDeductionsEmployeeLineEntries,
    ),
    loansAndAdvancesColumns: [
      {
        fieldName: 'loansAndAdvances',
        columnTitle: 'Loans And Advances',
        columnWidth: '80%',
      },
      {
        fieldName: 'amount',
        columnTitle: '',
        columnWidth: '20%',
      },
    ],
    loansAndAdvancesRows:
      FnfDetails?.loansAndAdvancesLineEntries &&
      Object.entries(FnfDetails?.loansAndAdvancesLineEntries)?.map(
        ([loansAndAdvancesName, loansAndAdvancesAmount]) => ({
          loansAndAdvances: loansAndAdvancesName,
          amount: `${currencySymbol} ${loansAndAdvancesAmount}`,
        }),
      ),
    grossEarningsColumn: [
      {
        fieldName: 'grossEarnings',
        columnTitle: 'Gross Earnings',
        columnWidth: '80%',
      },
      {
        fieldName: 'amount',
        columnTitle: [`${currencySymbol} ${FnfDetails?.salarySlipInstance?.netFinalEarnings || 0}`],
        columnWidth: '20%',
      },
    ],
    totalDeductionsColumn: [
      {
        fieldName: 'totalDeductions',
        columnTitle: 'Total Deductions',
        columnWidth: '80%',
      },
      {
        fieldName: 'amount',
        columnTitle: [`${currencySymbol} ${FnfDetails?.salarySlipInstance?.netFinalDeductions || 0}`],
        columnWidth: '20%',
      },
    ],
    netSalaryColumns: [
      {
        fieldName: 'netSalary',
        columnTitle: 'Net Salary',
        columnWidth: '80%',
      },
      {
        fieldName: 'amount',
        columnTitle: [`${currencySymbol} ${FnfDetails?.salarySlipInstance?.netFinalSalary || 0}`],
        columnWidth: '20%',
      },
    ],
    fnfRemarks: FnfDetails?.employeeFnFSettlementInstance?.finalRemarks || '',
  };
  return fnfDetailsProps;
};

export { getConfiguredFnfDetails };
