import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@mui/material';
import Button from 'components/Atoms/Button';
import Typographys from 'components/Atoms/Typography';
import DatePicker from 'components/Atoms/Datepicker';
import InputField from 'components/Atoms/InputField/index';
import Dropdown from 'components/Molecules/Dropdown';
import { useFormik } from 'formik';
import Divider from '@mui/material/Divider';
import TableExtended from 'components/Molecules/TableExtended';
import PerfectScrollbar from 'react-perfect-scrollbar';

const AdvanceSearch = ({
  handleSubmit,
  statusOptions,
  supervisorOptions,
  loading,
  rows,
  columns,
  count,
  showActionList,
  handleSort,
  handleRowClick,
  page,
  onPageChange,
  sortBy,
  sortDirection,
  handleSelect,
  showPagination,
  keyColumn,
  roleBasedMenu,
  handleSearch,
  searchCount,
}) => {
  const formik = useFormik({
    initialValues: {
      employeeName: '',
      userName: '',
      employeeId: '',
      department: '',
      location: '',
      designation: '',
      employmentStatus: '',
      employmentType: '',
      supervisor: '',
      dojStartDate: '',
      dojEndDate: '',
      dolStartDate: '',
      dolEndDate: '',
    },
    onSubmit: async (values, helpers) => {
      try {
        handleSubmit(values);
        // helpers.resetForm();
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });

  const handleDateChange = (fieldName, date) => {
    formik.setFieldValue(fieldName, date);
  };

  return (
    <Box>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 38rem)' }}>
          <Box component="form" onSubmit={formik.handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={9} mt={0}>
              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.employeeName && formik.errors.employeeName)}
                  helperText={formik.touched.employeeName && formik.errors.employeeName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.employeeName}
                  name="employeeName"
                  id="employeeName"
                  label="Employee Name"
                  fullWidth
                  autoFocus
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.userName && formik.errors.userName)}
                  helperText={formik.touched.userName && formik.errors.userName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.userName}
                  name="userName"
                  id="userName"
                  label="User Name"
                  fullWidth
                  autoFocus
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.employeeId && formik.errors.employeeId)}
                  helperText={formik.touched.employeeId && formik.errors.employeeId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.employeeId}
                  name="employeeId"
                  id="employeeId"
                  label="Employee ID"
                  fullWidth
                  autoFocus
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.department && formik.errors.department)}
                  helperText={formik.touched.department && formik.errors.department}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.department}
                  name="department"
                  id="department"
                  label="Department"
                  fullWidth
                  autoFocus
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.location && formik.errors.location)}
                  helperText={formik.touched.location && formik.errors.location}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.location}
                  name="location"
                  id="location"
                  label="Location"
                  fullWidth
                  autoFocus
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.designation && formik.errors.designation)}
                  helperText={formik.touched.designation && formik.errors.designation}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.designation}
                  name="designation"
                  id="designation"
                  label="Designation"
                  fullWidth
                  autoFocus
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Dropdown
                  error={Boolean(formik.touched.employmentStatus && formik.errors.employmentStatus)}
                  helperText={formik.touched.employmentStatus && formik.errors.employmentStatus}
                  handleChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.employmentStatus}
                  name="employmentStatus"
                  id="employmentStatus"
                  label="Employment Status"
                  options={statusOptions}
                  fullWidth
                  autoFocus
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.employmentType && formik.errors.employmentType)}
                  helperText={formik.touched.employmentType && formik.errors.employmentType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.employmentType}
                  name="employmentType"
                  id="employmentType"
                  label="Employment Type"
                  fullWidth
                  autoFocus
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Dropdown
                  error={Boolean(formik.touched.supervisor && formik.errors.supervisor)}
                  helperText={formik.touched.supervisor && formik.errors.supervisor}
                  handleChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.supervisor}
                  name="supervisor"
                  id="supervisor"
                  label="Supervisor"
                  options={supervisorOptions}
                  fullWidth
                  autoFocus
                />
              </Grid>
            </Grid>

            <Box sx={{ mt: 4, mb: 2.5 }}>
              <Typographys variant="subtitle2" color="neutral.600">
                Search by dates
              </Typographys>
            </Box>

            <Grid container rowSpacing={2} columnSpacing={9} mt={0} alignItems="center">
              <Grid item xs={12} md={4}>
                <Typographys variant="subtitle2">Date of Joining</Typographys>
              </Grid>

              <Grid item xs={12} md={4}>
                <DatePicker
                  error={Boolean(formik.touched.dojStartDate && formik.errors.dojStartDate)}
                  helperText={formik.touched.dojStartDate && formik.errors.dojStartDate}
                  onChange={(date) => handleDateChange(date, 'dojStartDate')}
                  value={formik.values?.dojStartDate}
                  name="dojStartDate"
                  id="dojStartDate"
                  label="Start Date"
                  fullWidth
                  autoFocus
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <DatePicker
                  error={Boolean(formik.touched.dojEndDate && formik.errors.dojEndDate)}
                  helperText={formik.touched.dojEndDate && formik.errors.dojEndDate}
                  value={formik.values?.dojEndDate}
                  onChange={(date) => handleDateChange(date, 'dojEndDate')}
                  name="dojEndDate"
                  id="dojEndDate"
                  label="End Date"
                  fullWidth
                  autoFocus
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Typographys variant="subtitle2">Date of Leaving</Typographys>
              </Grid>

              <Grid item xs={12} md={4}>
                <DatePicker
                  error={Boolean(formik.touched.dolStartDate && formik.errors.dolStartDate)}
                  helperText={formik.touched.dolStartDate && formik.errors.dolStartDate}
                  onChange={(date) => handleDateChange(date, 'dolStartDate')}
                  value={formik.values?.dolStartDate}
                  name="dolStartDate"
                  id="dolStartDate"
                  label="Start Date"
                  fullWidth
                  autoFocus
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <DatePicker
                  error={Boolean(formik.touched.dolEndDate && formik.errors.dolEndDate)}
                  helperText={formik.touched.dolEndDate && formik.errors.dolEndDate}
                  value={formik.values?.dolEndDate}
                  onChange={(date) => handleDateChange(date, 'dolEndDate')}
                  name="dolEndDate"
                  id="dolEndDate"
                  label="End Date"
                  fullWidth
                  autoFocus
                />
              </Grid>
            </Grid>

            <Box mt={5} sx={{ display: 'flex', justifyContent: 'end' }}>
              <Button
                onClick={handleSearch}
                width="auto"
                size="medium"
                type="submit"
                isDisable={Object.keys(formik.errors).length}
              >
                Search
              </Button>
            </Box>
          </Box>
          <Divider
            sx={{
              mt: 4.5,
            }}
          />
          <Box my={2}>
            <Typographys variant="body2" color="neutral.600">
              {searchCount} Search result
            </Typographys>
          </Box>

          <TableExtended
            loading={loading}
            columns={columns}
            rows={rows}
            count={count}
            showPagination={showPagination}
            onPageChange={onPageChange}
            handleSort={handleSort}
            handleRowClick={handleRowClick}
            page={page}
            keyColumn={keyColumn}
            roleBasedMenu={roleBasedMenu}
            handleSelect={handleSelect}
            showActionList={showActionList}
            sortBy={sortBy}
            sortDirection={sortDirection}
            isDynamic
          />
        </Box>
      </PerfectScrollbar>
    </Box>
  );
};

AdvanceSearch.defaultProps = {
  handleSubmit: () => {},
  statusOptions: [],
  supervisorOptions: [],
  loading: false,
  rows: [],
  columns: [],
  showActionList: true,
  count: 10,
  handleSort: () => {},
  handleRowClick: () => {},
  page: 1,
  sortBy: '',
  sortDirection: 'asc',
  handleSelect: () => {},
  showPagination: false,
  keyColumn: '',
  roleBasedMenu: () => {},
  onPageChange: () => {},
  handleSearch: () => {},
  searchCount: 2,
};

AdvanceSearch.propTypes = {
  handleSubmit: PropTypes.func,
  statusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  supervisorOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  loading: PropTypes.bool,
  rows: PropTypes.arrayOf,
  columns: PropTypes.arrayOf,
  showActionList: PropTypes.bool,
  count: PropTypes.number,
  handleSort: PropTypes.func,
  handleRowClick: PropTypes.func,
  page: PropTypes.number,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  handleSelect: PropTypes.func,
  showPagination: PropTypes.bool,
  keyColumn: PropTypes.string,
  roleBasedMenu: PropTypes.func,
  onPageChange: PropTypes.func,
  handleSearch: PropTypes.func,
  searchCount: PropTypes.number,
};
export default AdvanceSearch;
