import React, { useEffect, useState } from 'react';
/* eslint-disable jsx-a11y/anchor-is-valid */
import { saveMyExitDetails, modifyEmpExitDetails } from 'api/profile';
import { useSelector } from 'react-redux';
import InitiateSeparationTemplate from 'components/Templates/Profile/InitiateSeparation';
import { useLocation, useNavigate } from 'react-router-dom';
import { useThunk } from 'hooks/useThunk';
import { fetchMyExitDetails, fetchDisplayEmpExitDetails } from 'store';
import dayjs from 'dayjs';
import { Box, Link, Typography, Skeleton } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import { addEmpStyle } from 'components/Organisms/Profile/style';
import { ObjectToFormData } from 'utils/ObjectToFormData';
import useToast from 'components/Provider/useToast';
import { hasPermission } from 'utils/permissions';
import { get, isArray, isEmpty } from 'lodash';
import { validateElseHyphen, validateDateElseHyphen } from 'utils/commonUtils';

const InitiateSeparation = () => {
  const addToast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const isEdit = location.pathname.includes('edit');

  const session = useSelector((state) => state?.session);
  const empDBID = session?.user?.empDBID;

  const handleClick = () => {
    navigate('/home/separation/view-separation');
  };

  const authorities = session.user.authorities;
  const userHasViewPermission = hasPermission(authorities, [
    'ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_CREATE_MY_EMPLOYEE_EXIT',
  ]);

  const thunkToCall = isEdit ? fetchDisplayEmpExitDetails : fetchMyExitDetails;
  const [getMyExitDetails, isLoadingMyExitDetails, loadingErrorMyExitDetails] = useThunk(thunkToCall);

  useEffect(() => {
    if (!isEdit) {
      getMyExitDetails();
    } else {
      const query = new URLSearchParams({});
      query.append('empID', empDBID);
      getMyExitDetails(query);
    }
  }, [getMyExitDetails, isEdit, empDBID]);

  const exitDetails = useSelector((state) => {
    return isEdit ? state.profile.displayExitDetails : state.profile.exitDetails;
  });

  if (!userHasViewPermission) {
    return <Typography variant="h6">You do not have permission to initiate separation</Typography>;
  }

  if (isLoadingMyExitDetails) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (loadingErrorMyExitDetails) {
    return (
      <Typography variant="h6">
        {loadingErrorMyExitDetails?.message || 'Error while fetching initiate separation details.'}
      </Typography>
    );
  }

  const { employeeProfileInstance = {}, employeeExitInstance = {}, employeeInstance = {} } = exitDetails;
  if ((exitDetails?.status !== 'SUCCESS' || exitDetails?.status === undefined) && !isEdit) {
    return (
      <>
        <h3>{exitDetails.message}</h3>
        Click&nbsp;
        <Link tabIndex={0} component="button" onClick={handleClick}>
          here
        </Link>
        &nbsp;to view your existing separation request.
      </>
    );
  }

  // eslint-disable-next-line max-len
  const noticePeriod = `${employeeProfileInstance?.noticePeriod || '0'} ${
    employeeProfileInstance?.noticePeriodTimeUnit || ''
  }`;

  const employeeDetails = {
    employeeID: validateElseHyphen(employeeProfileInstance?.employeeID),
    employeeName: validateElseHyphen(employeeProfileInstance?.employee?.fullName),
    separationType:
      employeeProfileInstance?.seperationType === 'Undefined'
        ? 'Voluntary Resignation'
        : get(employeeProfileInstance, 'seperationType', 'Voluntary Resignation'),
    noticePeriod,
    companyName: validateElseHyphen(exitDetails?.companyName),
    resignationSubmissionDate: validateDateElseHyphen(dayjs(), 'DD MMM YYYY'),
  };

  const acceptingManagerOptions =
    isArray(exitDetails?.empList) && !isEmpty(exitDetails?.empList)
      ? exitDetails?.empList.map((item) => ({
          id: item?.id ? item.id.toString() : '',
          name: item?.fullNameWithID ? item.fullNameWithID : 'null',
        }))
      : [];

  if (!isEdit) {
    employeeDetails.lastWorkingDay = validateDateElseHyphen(exitDetails?.systemComputedLWD, 'DD MMMM YYYY');
  } else {
    employeeDetails.lastWorkingDay = validateDateElseHyphen(employeeExitInstance?.systemComputedLWD, 'DD MMM YYYY');
  }

  let separationData = {};
  if (isEdit) {
    separationData = {
      requestedRelievingDate: employeeProfileInstance?.dateOfLeaving
        ? dayjs(dayjs(employeeProfileInstance?.dateOfLeaving).format('MM/DD/YYYY').toString())
        : null,
      resignationAcceptanceManager: employeeInstance?.exitDetails?.resignationAcceptingManager?.id || '',
      resignationReason: employeeExitInstance?.resignationReasons || '',
    };
  } else {
    separationData = {
      resignationAcceptanceManager: employeeInstance?.supervisor?.id || '',
      requestedRelievingDate: '',
      resignationReason: '',
    };
  }

  // Function definitions
  const handleSubmit = async (formValues) => {
    setLoading(true);
    const args = {
      'employee.id': empDBID,
      'resignationAcceptingManager.id': formValues.resignationAcceptanceManager,
      resignationReasons: formValues.resignationReason,
      requestedRelievingDate: formValues?.requestedRelievingDate
        ? dayjs(formValues.requestedRelievingDate).format('MM/DD/YYYY')
        : dayjs().format('MM/DD/YYYY'),
      resignationSubmissionDate: dayjs().format('MM/DD/YYYY'),
      'profile.seperationType':
        employeeProfileInstance?.seperationType === 'Undefined'
          ? 'Voluntary Resignation'
          : get(employeeProfileInstance, 'seperationType', 'Voluntary Resignation'),
    };

    if (isEdit) {
      args.id = exitDetails?.id;
      args.resignationStatus = employeeExitInstance?.resignationStatus;
    }

    const response = isEdit
      ? await modifyEmpExitDetails(ObjectToFormData(args))
      : await saveMyExitDetails(ObjectToFormData(args));

    const msg = isEdit
      ? `Employee exit updated successfully for ${employeeDetails.employeeName}`
      : `Employee exit initiated successfully for ${employeeDetails.employeeName}`;
    if (
      response?.status !== 'SUCCESS' ||
      response?.status === undefined ||
      !response?.results?.employee?.exitDetailsId
    ) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: response?.status || 'Something went wrong, try again',
      });
    } else if (response?.status === 'SUCCESS') {
      addToast({
        type: 'success',
        title: 'SUCCESS',
        message: msg,
      });
      navigate('/home/separation/view-separation');
    }
    setLoading(false);
  };

  const handleCancel = async () => {
    navigate('/home/separation/view-separation');
  };

  return (
    <Box>
      <Typographys variant="h5" color="neutral.800">
        Initiate Separation
      </Typographys>
      <Box sx={addEmpStyle.container}>
        <InitiateSeparationTemplate
          employeeDetails={employeeDetails}
          separationData={separationData}
          handleSubmit={handleSubmit}
          acceptingManagerOptions={acceptingManagerOptions}
          handleCancel={handleCancel}
          isEdit={isEdit}
          loading={loading}
        />
      </Box>
    </Box>
  );
};

export default InitiateSeparation;
