import React, { useState, useEffect, memo } from 'react';
import Form12BBTemplate from 'components/Templates/MyPay/Form12BB';
import { form12BB } from 'api/home/myPay';
import useToast from 'components/Provider/useToast';
import { Box, Skeleton } from '@mui/material';

const Form12BBPage = () => {
  const addToast = useToast();
  const [form12BBApiData, setForm12BBApiData] = useState();
  const [isLoading, setLoadingContent] = useState(false);

  useEffect(() => {
    setLoadingContent(true);
    const getApiData = async () => {
      const results = await form12BB();
      return results;
    };
    getApiData().then((apiData) => {
      if (apiData?.data) {
        setForm12BBApiData(apiData.data);
        setLoadingContent(false);
      }
    });
  }, []);

  let claimsDetailsData = {};
  const landlordInfo = [];
  let employeeData = {};
  if (isLoading) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }
  try {
    if (form12BBApiData) {
      if (Object.values(form12BBApiData).length > 0) {
        // Generic Variables
        const employee = form12BBApiData?.employee;
        const profileInstance = form12BBApiData?.profileInstance;
        const form12BBInstance = form12BBApiData?.form12BBInstance;
        const employeeDeductionDeclarationInstance = form12BBApiData?.employeeDeductionDeclarationInstance;
        const landLordNameWiseRentMap = form12BBApiData?.landLordNameWiseRentMap;
        const landLordNameWisePANMap = form12BBApiData?.landLordNameWisePANMap;
        const landLordNameWiseAddrMap = form12BBApiData?.landLordNameWiseAddrMap;
        const totalRentPaidDuringFY = form12BBApiData?.totalRentPaidDuringFY;
        const ltaDeclaredAmt = form12BBApiData?.ltaDeclaredAmt;
        const employeePFContribution = form12BBApiData?.employeePFContribution;
        const deductionHeadsList = form12BBApiData?.deductionHeadsList;
        // const dedecHeadLineEntryMap = form12BBApiData?.dedecHeadLineEntryMap;
        const dedecHeadDeclrAmtsMap = form12BBApiData?.dedecHeadDeclrAmtsMap;
        const statRefSctionsOutside80C = form12BBApiData?.statRefSctionsOutside80C;
        const empHousePropertyDeclrList = form12BBApiData?.empHousePropertyDeclrList;
        const PROPERTY_TYPE_SELF_OCCUPIED = form12BBApiData?.propertyType?.PROPERTY_TYPE_SELF_OCCUPIED;
        const indiaTaxRegimeValues = form12BBApiData?.indiaTaxRegime;
        const dedecHeadProofAmtsMap = form12BBApiData?.dedecHeadProofAmtsMap;
        const housingRentPaid = form12BBApiData?.housingRentPaid;
        const proofSubmittedAmountForLTA = form12BBApiData?.proofSubmittedAmountForLTA;
        const empContribToNPS = form12BBApiData?.empContribToNPS;

        // Employee Details Computation
        let empFullAddressText = '';
        if (profileInstance?.presentCommunicationAddress) {
          const addressInstance = profileInstance?.presentCommunicationAddress;
          const addressFields = [
            'addressLine1',
            'addressLine2',
            'addressLine3',
            'city',
            'state',
            'country',
            'pinOrZip',
          ];
          addressFields.forEach((field) => {
            if (addressInstance[field]) {
              empFullAddressText += `${addressInstance[field]}, `;
            }
          });
        }

        let taxRegime = '';
        if (
          employeeDeductionDeclarationInstance?.taxRegime &&
          (indiaTaxRegimeValues?.INDIA_TAX_REGIME_OLD || indiaTaxRegimeValues?.INDIA_TAX_REGIME_NEW_2020)
        ) {
          if (employeeDeductionDeclarationInstance.taxRegime === indiaTaxRegimeValues.INDIA_TAX_REGIME_OLD) {
            taxRegime = 'old';
          } else if (
            employeeDeductionDeclarationInstance.taxRegime === indiaTaxRegimeValues.INDIA_TAX_REGIME_NEW_2020
          ) {
            taxRegime = 'new';
          }
        }

        employeeData = {
          fullName: employee?.fullName || '',
          fullAddress: empFullAddressText,
          pan: employee?.panNumberIndia || '',
          financialYear: form12BBInstance?.financialYear || '',
          taxRegime,
          fatherOrHusbandsName: employee?.fatherOrHusbandsName || '',
          designation: employee?.designation?.name || '',
        };

        // Claim Details >> Landlord Data Computation
        if (Object.values(landLordNameWiseRentMap).length !== 0) {
          Object.keys(landLordNameWiseRentMap)
            .sort()
            .forEach((landLordName) => {
              landlordInfo.push([
                {
                  title: 'Rent Paid to Landlord: ',
                  value: landLordNameWiseRentMap[landLordName],
                },
                {
                  title: 'Name of Landlord: ',
                  value: landLordName,
                },
                {
                  title: 'Address of Landlord: ',
                  value: landLordNameWiseAddrMap[landLordName],
                },
                {
                  title: 'PAN of Landlord: ',
                  value: landLordNameWisePANMap[landLordName],
                },
              ]);
            });
        } else if (totalRentPaidDuringFY) {
          landlordInfo.push([
            {
              title: 'Rent Paid to Landlord: ',
              value: totalRentPaidDuringFY,
            },
          ]);
        }

        // Property related computation
        const financialInstitutionsHeadingDataArr = [];
        const financialInstitutionsAmountArr = [];
        if (empHousePropertyDeclrList) {
          if (empHousePropertyDeclrList.length !== 0) {
            empHousePropertyDeclrList
              .sort((a, b) => a.id - b.id)
              .forEach((empHousePropDeclrInstance) => {
                financialInstitutionsHeadingDataArr.push({
                  propertyType: empHousePropDeclrInstance?.propertyType || '',
                  lenderName: empHousePropDeclrInstance?.lenderName || '',
                  lenderPAN: empHousePropDeclrInstance?.lenderPan || '',
                  lenderAddress: empHousePropDeclrInstance?.addressStr || '',
                });
                if (!empHousePropDeclrInstance?.propertyType) {
                  financialInstitutionsAmountArr.push('');
                } else {
                  financialInstitutionsAmountArr.push(
                    empHousePropDeclrInstance.propertyType === PROPERTY_TYPE_SELF_OCCUPIED
                      ? empHousePropDeclrInstance?.housingLoanInterestOnSelfOccupiedProperty || ''
                      : empHousePropDeclrInstance?.interestPaidOnHousingLoanForLetOutProperty || '',
                  );
                }
              });
          }
        }

        // let counter = 1;
        const deductionData = {
          '80C': [],
          '80CCC': [],
          '80CCD(1)': [],
          outside80C: [],
        };
        const deductionDataFunc = () => {
          deductionHeadsList
            .sort((a, b) => a.id - b.id)
            .forEach((deductionHead) => {
              // const lineEntry = dedecHeadLineEntryMap[deductionHead.id];
              let proofSubmittedAmt = 0.0;
              if (dedecHeadProofAmtsMap && dedecHeadProofAmtsMap[deductionHead?.id]) {
                proofSubmittedAmt = dedecHeadProofAmtsMap[deductionHead?.id];
              }
              if (deductionHead?.statutoryReferenceSection) {
                if (
                  (['80C', '80CCC', '80CCD(1)'].includes(deductionHead?.statutoryReferenceSection) ||
                    statRefSctionsOutside80C.includes(deductionHead?.statutoryReferenceSection)) &&
                  proofSubmittedAmt !== 0.0
                ) {
                  const deductionDataKey = ['80C', '80CCC', '80CCD(1)'].includes(
                    deductionHead?.statutoryReferenceSection,
                  )
                    ? deductionHead.statutoryReferenceSection
                    : 'outside80C';
                  deductionData[deductionDataKey].push({
                    name: deductionHead?.name,
                    amount: proofSubmittedAmt,
                    lastTableCellText: 'Photo copy of receipt',
                  });
                }
              }
            });
        };
        deductionDataFunc();

        claimsDetailsData = {
          hraAmount:
            dedecHeadProofAmtsMap && housingRentPaid && dedecHeadProofAmtsMap[housingRentPaid?.id]
              ? dedecHeadProofAmtsMap[housingRentPaid?.id]
              : 0,
          hraEP: '',
          lTAmount: proofSubmittedAmountForLTA || '0',
          lTEP: ltaDeclaredAmt ? 'Photo copy of receipt' : '',
          dIBAmount: '',
          dIBEP: '',
          dUCAmount: '',
          dUCEP: '',
          dUCSectionAmount: '',
          dUCSectionEP: '',
          dUCSection80CAmount: '',
          dUCSection80CEP: '',
          employeePFContribution,
          ePFEP: '',
          deductionData,
          lIPSAmount: '',
          lIPSEP: '',
          eCTNPSAmount:
            dedecHeadProofAmtsMap && empContribToNPS && dedecHeadProofAmtsMap[empContribToNPS?.id]
              ? dedecHeadProofAmtsMap[empContribToNPS?.id]
              : 0,
          eCTNPSEP: '',
          oSUCAmount: '',
          oSUCEP: '',
          financialInstitutionsHeadingDataArr,
          financialInstitutionsAmountArr,
        };
      } else {
        addToast({
          type: 'error',
          title: 'ERROR',
          message: 'No data found for Form 12BB',
        });
      }
    }
  } catch (err) {
    addToast({
      type: 'error',
      title: 'ERROR',
      message: 'Something went wrong, try again',
    });
  }

  return (
    <Form12BBTemplate employeeData={employeeData} claimsDetailsData={claimsDetailsData} landlordInfo={landlordInfo} />
  );
};

export default memo(Form12BBPage);
