import React, { useEffect, useState } from 'react';
import AttendanceTemplate from 'components/Templates/Attendance';
import { Typography, Skeleton, Box } from '@mui/material';
import useFetcher from 'hooks/useFetcher';
import { getAttendanceRecords } from 'api/home';
import { getConfiguredAttendanceData, getAvgAttendanceDetails } from './getConfiguredAttendanceData';
import useColumns from './useColumns';

const AttendanceDashboard = () => {
  const [myAttendanceDetails] = useFetcher(getAttendanceRecords);
  const [columns] = useColumns();

  const [selectedDuration, setSelectedDuration] = useState('This Week');
  const [avgAttendanceDetails, setAvgAttendanceDetails] = useState({ avgHours: '', arrivalTime: '', overtimeHrs: '' });

  const [selectedDurationForList, setSelectedDurationForList] = useState('This Week');
  const [avgAttendanceDetailsRows, setAvgAttendanceDetailsRows] = useState([]);
  const [customStartEndDate, setCustomStartEndDate] = useState({ startDate: '', endDate: '' });

  useEffect(() => {
    if (myAttendanceDetails.isLoading === false) {
      setAvgAttendanceDetails(getAvgAttendanceDetails(myAttendanceDetails, selectedDuration));
    }
  }, [selectedDuration, myAttendanceDetails]);

  useEffect(() => {
    if (myAttendanceDetails.isLoading === false) {
      setAvgAttendanceDetailsRows(
        getConfiguredAttendanceData(myAttendanceDetails, selectedDurationForList, customStartEndDate),
      );
    }
  }, [selectedDurationForList, myAttendanceDetails, customStartEndDate]);

  if (myAttendanceDetails.error !== null) {
    return (
      <Typography variant="h6">
        {myAttendanceDetails?.error?.response?.data?.message || 'Error while fetching attendance record details.'}
      </Typography>
    );
  }

  const handleThisWeek = (selectedOption) => {
    setSelectedDuration(selectedOption.name);
  };
  const handleThisMonth = (selectedOption) => {
    setSelectedDuration(selectedOption.name);
  };
  const handleThisYear = (selectedOption) => {
    setSelectedDuration(selectedOption.name);
  };
  const handleMyAttendanceThisWeek = (selectedOption) => {
    setSelectedDurationForList(selectedOption.name);
  };
  const handleMyAttendanceThisMonth = (selectedOption) => {
    setSelectedDurationForList(selectedOption.name);
  };
  const handleMyAttendanceThisYear = (selectedOption) => {
    setSelectedDurationForList(selectedOption.name);
  };
  const handleMyAttendanceDateRange = (selectedOption) => {
    setSelectedDurationForList('Custom date');
    setCustomStartEndDate({ startDate: selectedOption.startDate, endDate: selectedOption.endDate });
  };

  return myAttendanceDetails.isLoading ? (
    <Box sx={{ p: 4 }}>
      <Skeleton height={70} />
      <Skeleton />
      <Skeleton />
    </Box>
  ) : (
    <AttendanceTemplate
      myAttendanceRows={avgAttendanceDetailsRows.myAttendanceRows}
      myAttendanceColumns={columns}
      timelineData={avgAttendanceDetailsRows.timelineData}
      daysData={avgAttendanceDetailsRows.daysData}
      handleThisWeek={handleThisWeek}
      handleThisMonth={handleThisMonth}
      handleThisYear={handleThisYear}
      avgHours={avgAttendanceDetails.avgHours}
      arrivalTime={avgAttendanceDetails.arrivalTime}
      overtimeHrs={avgAttendanceDetails.overtimeHrs}
      handleMyAttendanceThisWeek={handleMyAttendanceThisWeek}
      handleMyAttendanceThisMonth={handleMyAttendanceThisMonth}
      handleMyAttendanceThisYear={handleMyAttendanceThisYear}
      handleMyAttendanceDateRange={handleMyAttendanceDateRange}
    />
  );
};

export default AttendanceDashboard;
