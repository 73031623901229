import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ActionQueryTemplate from 'components/Templates/TaskAndQueries/ActionQuery';
import { Box, Skeleton, Typography } from '@mui/material';
import { API_URL } from 'api/api';
import { isEmpty } from 'lodash';
import useServices from '../common/useServices';
import useMenuItems from '../View/useMenuItems';

const EscalateQuery = () => {
  const { id } = useParams();
  const services = useServices();
  const [handleActionItemClick] = useMenuItems(services);
  const [escalateResult, setEscalateResult] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await services.escalateQueryService(id);
        setEscalateResult(res);
      } catch (error) {
        setEscalateResult(error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (services?.state?.queryDetails?.isLoading) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (!isEmpty(escalateResult)) {
    return (
      <Typography variant="h6">{escalateResult?.response?.data?.warning || 'Failed to escalate the query.'}</Typography>
    );
  }
  const getActionCreatorDetails = (comment) => {
    const commentsActionCreatorInstance = Object.keys(services.state.queryDetails?.commentsMap)?.find((item) =>
      item.includes(comment?.id),
    );

    return {
      fullName: services.state.queryDetails?.commentsMap?.[`${commentsActionCreatorInstance}`]?.fullName || '',
      avatarSrc: `${API_URL}/${
        services.state.queryDetails?.commentsMap?.[`${commentsActionCreatorInstance}`]?.profile?.profilePic
      }`,
    };
  };

  const auditLogData =
    services.state.queryDetails?.queryDetails?.comments.length > 0
      ? services.state.queryDetails?.queryDetails?.comments
          .map((comment) => ({
            avatarSrc: getActionCreatorDetails(comment)?.avatarSrc,
            description: getActionCreatorDetails(comment)?.fullName,
            date: comment?.createdOn,
            // filtered out anchor tag containing attachment
            content: comment?.content.split('<br/>').filter((item) => !item.includes('<a href="')),
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date))
      : [];

  return (
    <ActionQueryTemplate
      handleSubmit={(row) => services.escalateQueryActionService({ id, comment: row?.comments })}
      reportDetails={services.state.queryDetails?.queryDetails}
      handleEdit={() => services.modifyQueryNavigationService({ id })}
      actionMenuList={handleActionItemClick}
      isRecall={false}
      handleRecall={() => {}}
      handleAttachmentDownload={services.downloadDocument}
      showEdit
      formData={services.state.queryDetails.formData}
      handleCancel={services.handleCancel}
      isReopen
      isEscalate
      auditLogData={auditLogData}
    />
  );
};

export default EscalateQuery;
