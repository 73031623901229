/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs, Link } from '@mui/material';
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Typographys from 'components/Atoms/Typography';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';

const Breadcrumb = ({ breadcrumbs, maxItems, separator }) => {
  const navigate = useNavigate();
  return (
    <Breadcrumbs separator={separator} maxItems={maxItems} aria-label="breadcrumb">
      {breadcrumbs.map(({ name, path, isActive }, key) =>
        isActive ? (
          <Typographys variant="body1" key={name} align="center" color="neutral.800">
            {name}
          </Typographys>
        ) : (
          <Box display="flex" alignItems="center" gap={1} key={name}>
            {key === 0 && (
              <Link color="primary" underline="none" key={name} onClick={() => navigate(-1)}>
                <ArrowBackSharpIcon fontSize="2.5rem" cursor="pointer" color="neutral.600" />
              </Link>
            )}
            <Link
              color="primary"
              underline="none"
              style={{ cursor: 'pointer' }}
              key={name}
              onClick={() => navigate(path)}
              aria-current="page"
            >
              {name}
            </Link>
          </Box>
        ),
      )}
    </Breadcrumbs>
  );
};

Breadcrumb.defaultProps = {
  maxItems: 4,
  separator: <KeyboardArrowRightIcon />,
  breadcrumbs: [
    { id: 1, name: 'Employee Grouping', path: '/employeegroup', isActive: false },
    { id: 2, name: 'Group Details', path: '/groupdetail', isActive: false },
    { id: 3, name: 'Edit', path: '/edit', isActive: false },
    { id: 4, name: 'Add Employee', path: '/addemployee', isActive: true },
  ],
};

Breadcrumb.propTypes = {
  maxItems: PropTypes.number,
  separator: PropTypes.node,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
};

export default Breadcrumb;
