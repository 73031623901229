import React from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import PropTypes from 'prop-types';
import ActionLifeCycleRecord from 'components/Organisms/Employee/LifeCycleRecord/Action';

const LCRecordActionTemplate = ({
  pageHeading,
  breadcrumbData,
  recordDetails,
  newRecordDetails,
  handleSubmit,
  handleCancel,
  loading,
  creatorAttachment,
  approverAttachments,
  handleAttachmentDownload,
  handleExtendProbation,
  userHasExtendProbationPermission,
}) => {
  return (
    <BlankTemplate pageHeading={pageHeading} isBreadcrumb breadcrumbData={breadcrumbData}>
      <ActionLifeCycleRecord
        loading={loading}
        recordDetails={recordDetails}
        newRecordDetails={newRecordDetails}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        creatorAttachment={creatorAttachment}
        approverAttachments={approverAttachments}
        handleAttachmentDownload={handleAttachmentDownload}
        handleExtendProbation={handleExtendProbation}
        userHasExtendProbationPermission={userHasExtendProbationPermission}
      />
    </BlankTemplate>
  );
};

LCRecordActionTemplate.defaultProps = {
  loading: false,
  pageHeading: 'Life Cycle Details: -',
  breadcrumbData: [
    { id: 1, name: 'Life Cycle Case Records', path: '/company/life-cycle/life-cycle-records', isActive: false },
    { id: 2, name: 'Action', isActive: true },
  ],
  recordDetails: {},
  newRecordDetails: {},
  handleSubmit: () => {},
  handleCancel: () => {},
  creatorAttachment: {},
  approverAttachments: {},
  handleAttachmentDownload: () => {},
};

LCRecordActionTemplate.propTypes = {
  loading: PropTypes.bool,
  pageHeading: PropTypes.string,
  breadcrumbData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
  recordDetails: PropTypes.shape(),
  newRecordDetails: PropTypes.shape(),
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  creatorAttachment: PropTypes.shape(),
  approverAttachments: PropTypes.shape(),
  handleAttachmentDownload: PropTypes.func,
};

export default LCRecordActionTemplate;
