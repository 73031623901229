/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import React from 'react';
import { TableContainer, TableBody } from '@mui/material';
import MuiTable from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import PerfectScrollbar from 'react-perfect-scrollbar';

// project import
import TableHead from './TableHead';
import TableRow from './TableRow';
import TableCell from './TableCell';
import TablePagination from '../TablePagination/index';

const Table = (props) => {
  const {
    columns,
    rows,
    keyColumn,
    sortDirection,
    sortBy,
    onSortChange,
    count,
    page,
    onPageChange,
    showPagination,
    onRowClick,
    onActionCallback,
  } = props;
  return (
    <>
      <PerfectScrollbar>
        <TableContainer sx={{ overflowX: 'unset' }} component={Paper}>
          <MuiTable size="small">
            <TableHead columns={columns} sortDirection={sortDirection} sortBy={sortBy} onSortChange={onSortChange} />
            <TableBody>
              {rows.map((row, index) => {
                return (
                  <TableRow key={`${row[keyColumn]}_${index}`} hover>
                    {columns.map((column) => {
                      return (
                        <TableCell row={row} onRowClick={onRowClick} sticky={column.sticky} key={column.label}>
                          {column.formatValue?.({ row, column, onActionCallback })}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </MuiTable>
        </TableContainer>
      </PerfectScrollbar>
      {showPagination && <TablePagination count={count} page={page} onPageChange={onPageChange} />}
    </>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.node).isRequired,
  rows: PropTypes.arrayOf(PropTypes.node).isRequired,
  keyColumn: PropTypes.string,
  onSortChange: PropTypes.func,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  showPagination: PropTypes.bool,
  count: PropTypes.number.isRequired,
  onRowClick: PropTypes.func,
  onActionCallback: PropTypes.func,
};

Table.defaultProps = {
  keyColumn: 'id',
  sortDirection: 'desc',
  sortBy: 'id',
  onSortChange: () => {},
  page: 1,
  onPageChange: () => {},
  showPagination: false,
  onRowClick: () => {},
  onActionCallback: () => {},
};
export default Table;
