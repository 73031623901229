export const profileWithInformationStyle = {
  profileWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    columnGap: '1rem',
  },
  profileContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    alignItems: 'flex-start',
  },
  profileInfo: {
    display: 'flex',
    columnGap: '1rem',
  },
};
