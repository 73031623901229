import React, { useEffect } from 'react';
import { Box, Grid, Divider, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import Button from 'components/Atoms/Button';
import Typographys from 'components/Atoms/Typography';
import InputField from 'components/Atoms/InputField/index';
import Dropdown from 'components/Molecules/Dropdown';
import { useFormik } from 'formik';
import DatePicker from 'components/Atoms/Datepicker';
import * as yup from 'yup';
import { addEmpStyle } from '../../style';

const OnBoarding = ({
  tabHeading,
  handleSubmit,
  handleCancel,
  noticeUnitOptions,
  seperationOptions,
  bloodOptions,
  onboadringRefOptions,
  propData,
  label,
}) => {
  const defaultValues = propData.allTabsData.OnBoarding;

  const formik = useFormik({
    initialValues: Object.keys(defaultValues).length
      ? defaultValues
      : {
          onboardingReference: '',
          bloodGroup: '',
          seperationType: '',
          onboardingUnit: '',
          dateOfLeaving: '',
          dateOfJoining: '',
          noticePeriod: '',
          noticePeriodUnit: '',
        },

    validateOnMount: true,
    onSubmit: handleSubmit,
  });

  const handleDateChange = (date, fieldName) => {
    formik.setFieldValue(fieldName, date);
  };
  useEffect(() => {
    propData.updateFormData(formik.values, label);
  }, [formik.values, label]);

  const theme = useTheme();
  return (
    <Box mt={1.5}>
      <Typographys variant="h5" color="neutral.800">
        {tabHeading}
      </Typographys>

      <Box mt={2} component="form" onSubmit={formik.handleSubmit}>
        <Grid container rowSpacing={2} columnSpacing={9} mt={0} sx={addEmpStyle.formContainer}>
          <Grid item xs={12} md={6}>
            <Dropdown
              error={Boolean(formik.touched.onboardingReference && formik.errors.onboardingReference)}
              helperText={formik.touched.onboardingReference && formik.errors.onboardingReference}
              handleChange={(e) => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.onboardingReference}
              name="onboardingReference"
              id="onboardingReference"
              label="Onboarding Reference"
              options={onboadringRefOptions}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Dropdown
              error={Boolean(formik.touched.bloodGroup && formik.errors.bloodGroup)}
              helperText={formik.touched.bloodGroup && formik.errors.bloodGroup}
              handleChange={(e) => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.bloodGroup}
              name="bloodGroup"
              id="bloodGroup"
              label="Blood Group"
              options={bloodOptions}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Dropdown
              error={Boolean(formik.touched.seperationType && formik.errors.seperationType)}
              helperText={formik.touched.seperationType && formik.errors.seperationType}
              handleChange={(e) => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.seperationType}
              name="seperationType"
              id="seperationType"
              label="Separation Type"
              options={seperationOptions}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <DatePicker
              error={
                Boolean(formik.touched.dateOfLeaving && formik.errors.dateOfLeaving) ||
                (formik.touched.dateOfLeaving && !formik.values.dateOfLeaving)
              }
              helperText={formik.touched.dateOfLeaving && formik.errors.dateOfLeaving}
              onChange={(date) => handleDateChange(date, 'dateOfLeaving')}
              onBlur={formik.handleBlur}
              value={formik.values?.dateOfLeaving}
              name="dateOfLeaving"
              id="dateOfLeaving"
              label="Date Of Leaving"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <InputField
              error={Boolean(formik.touched.noticePeriod && formik.errors.noticePeriod)}
              helperText={formik.touched.noticePeriod && formik.errors.noticePeriod}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.noticePeriod}
              name="noticePeriod"
              id="noticePeriod"
              label="Notice Period"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Dropdown
              error={Boolean(formik.touched.noticePeriodUnit && formik.errors.noticePeriodUnit)}
              helperText={formik.touched.noticePeriodUnit && formik.errors.noticePeriodUnit}
              handleChange={(e) => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.noticePeriodUnit}
              name="noticePeriodUnit"
              id="noticePeriodUnit"
              label="Notice Period Unit"
              options={noticeUnitOptions}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <DatePicker
              error={
                Boolean(formik.touched.dateOfJoining && formik.errors.dateOfJoining) ||
                (formik.touched.dateOfJoining && !formik.values.dateOfJoining)
              }
              helperText={formik.touched.dateOfJoining && formik.errors.dateOfJoining}
              onChange={(date) => handleDateChange(date, 'dateOfJoining')}
              onBlur={formik.handleBlur}
              value={formik.values?.dateOfJoining}
              name="dateOfJoining"
              id="dateOfJoining"
              label="Confirmation Date*"
              fullWidth
            />
          </Grid>
        </Grid>

        <Box sx={addEmpStyle.btnContainer}>
          <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

          <Box sx={addEmpStyle.submitWrapper}>
            <Button
              size="large"
              color="info"
              variant="outlined"
              type="cancel"
              onClick={() => {
                formik.resetForm();
                handleCancel();
              }}
            >
              Cancel
            </Button>

            <Button size="large" type="submit" isDisable={false}>
              {propData.allowEdit ? 'Update' : 'Next'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

OnBoarding.defaultProps = {
  tabHeading: 'Onboarding',
  propData: {},
  handleSubmit: () => {},
  handleCancel: () => {},
  seperationOptions: [],
  noticeUnitOptions: [],
  bloodOptions: [],
  onboadringRefOptions: [],
  label: '',
};

OnBoarding.propTypes = {
  tabHeading: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  seperationOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  noticeUnitOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  bloodOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  onboadringRefOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  propData: PropTypes.shape({
    updateFormData: PropTypes.func,
    allTabsData: PropTypes.shape({
      OnBoarding: PropTypes.shape({}),
    }),
    allowEdit: PropTypes.bool,
  }),
  label: PropTypes.string,
};

export default OnBoarding;
