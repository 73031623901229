const getInitialValues = (recordDetails) => ({
  Terminate: {
    effectiveStartDate: '',
    attachment: null,
    reasonOfTransfer: '',
  },
  'Background Verified': {
    effectiveStartDate: '',
    attachment: null,
    reasonOfTransfer: '',
  },
  'Extend Probation': {
    effectiveStartDate: '',
    effectiveEndDate: '',
    reasonOfTransfer: '',
    attachment: null,
  },
  'Begin Performance Improvement': {
    effectiveStartDate: '',
    effectiveEndDate: '',
    reasonOfTransfer: '',
    attachment: null,
  },
  'End Performance Improvement': {
    effectiveStartDate: '',
    effectiveEndDate: '',
    reasonOfTransfer: '',
    attachment: null,
  },
  Confirm: {
    effectiveStartDate: '',
    attachment: null,
    reasonOfTransfer: '',
  },
  default: {
    supervisor: recordDetails?.supervisorId || '',
    department: recordDetails?.departmentId || '',
    designation: recordDetails?.designationId || '',
    location: recordDetails?.locationId || '',
    employeeLevel: recordDetails?.employeeLevelId || '',
    employeeGrade: recordDetails?.employeeGradeId || '',
    division: recordDetails?.divisionId || '',
    reasonOfTransfer: '',
  },
});

const useInitialValues = (recordDetails) => {
  return getInitialValues(recordDetails);
};

export default useInitialValues;
