export const myPayStyle = {
  tableWrapper: {
    mt: 1.5,
    mb: 2,
    'MuiTableHead-root': {
      background: 'neutral.100',
    },
    '.MuiTable-root.MuiTable-root .MuiTableCell-root': {
      border: '1px solid',
      borderColor: 'other.200',
      py: 2,
    },
  },
};

export const SalarySlipStyle = {
  salarySlipWrapperHeader: {
    mb: '3rem',
    display: 'flex',
    justifyContent: 'space-between',
    '@media screen and (max-width: 767px)': {
      flexDirection: 'column',
      h6: {
        mb: '2rem',
      },
    },
  },
  actionBtnGroup: {
    display: 'flex',
    columnGap: 1,
  },
  muiSwitch: {
    '.MuiFormControlLabel-root .MuiFormControlLabel-label': {
      fontSize: '2rem',
    },
  },
  tableWrapper: {
    '.MuiTable-root': {
      '.MuiTableHead-root .MuiTableCell-root,.MuiTableCell-root ': {
        '&:first-child': {
          pr: 0,
        },
        '&:nth-child(2)': {
          pl: 0,
          maxWidth: '4rem',
        },
      },
    },
  },
  arrearTableWrapper: {
    '.MuiTable-root': {
      mt: 2,
      '.MuiTableHead-root .MuiTableCell-root,.MuiTableCell-root ': {
        '&:first-child': {
          width: '14rem',
        },
      },
    },
  },
  slipHeaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: 2,
    flexWrap: 'wrap',
    backgroundColor: 'actions.100',
    border: '0.125rem solid',
    borderColor: 'neutral.300',
    padding: 3,
    borderRadius: '0.75rem',
  },
  slipHeaderLeft: {
    maxWidth: '23rem',
  },
  slipHeaderLogo: {
    maxWidth: '16.25rem',
    width: '33%',
  },
  slipHeaderRight: {
    maxWidth: '37rem',
    '@media screen and (max-width: 767px)': {
      width: '100%',
      maxWidth: '100%',
      mt: 2,
    },
  },
  salarySlipTableWrapper: {
    '.MuiTable-root.MuiTable-root .MuiTableCell-root': {
      border: '1px solid',
      borderColor: 'other.200',
      py: 1,
      '.MuiTable-root.MuiTable-root': {
        td: {
          '&:first-child': {
            borderLeft: 'none',
          },
          '&:last-child': {
            borderRight: 'none',
          },
          h6: {
            textTransform: 'none',
          },
        },
        '.MuiTableRow-root:first-child td': {
          borderTop: 'none',
          borderBottom: 'none',
          '&:first-child': {
            borderLeft: 'none',
          },
          '&:last-child': {
            borderRight: 'none',
          },
        },
        '.MuiTableRow-root:last-child td': {
          borderBottom: 'none',
        },
      },
    },
  },
  salarySlipTable: {
    mt: 2,
    mb: 4,
  },
  overlapTableRow: {
    '&.MuiTableRow-root:hover': {
      backgroundColor: 'transparent !important',
    },
  },
};

export const Form12BBStyle = {
  noteListWrapper: {
    margin: 0,
    padding: '12px',
  },
  uploadWrapper: {
    backgroundColor: '#F0F8FF',
    borderRadius: '8px',
    padding: '3rem 3rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
