import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import CommonList from 'components/Atoms/List/CommonList';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { onBoardingStyle } from '../style';

const Instructions = (onBoardingForm) => {
  useEffect(() => {
    const h6Elements = document.querySelectorAll('h6');
    h6Elements.forEach((element) => {
      element.style.fontSize = '2.1rem';
    });
  }, []);

  const onboardingInstructions =
    onBoardingForm?.formData?.onBoardingInstructions || onBoardingForm?.onBoardingInstructions;
  return (
    <Box px={3}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 38rem)' }}>
          {onboardingInstructions ? (
            <Box>
              {/* dangerouslySetInnerHTML is used here as with the current 
              implementation we have data returned as plain html in a string from database */}
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: onboardingInstructions }} />
            </Box>
          ) : (
            <Box>
              <Typographys variant="subtitle1">
                Please follow these guidelines to fill in details in the relevant sections as listed below:
              </Typographys>

              <Box sx={onBoardingStyle.wrapper}>
                <Typographys variant="body2" color="neutral.800" display="inline">
                  1. Basic Information:
                </Typographys>
                <Typographys variant="body2" color="neutral.600" display="inline">
                  This section contains details abouut yourself. Please provide all necessary details, including your
                  PAN, Aadhaar, UAN (if available), UHID Number (if available).
                </Typographys>
              </Box>

              <Box sx={onBoardingStyle.wrapper}>
                <Typographys variant="body2" color="neutral.800" display="inline">
                  2. Additional Information:
                </Typographys>
                <Typographys variant="body2" color="neutral.600" display="inline">
                  This section contains your personal details. All your required documents such as PAN Card, Aadhaar
                  Card etc can be uploaded here. Social fields are optional and can be filled as necessary.
                </Typographys>
              </Box>

              <Box sx={onBoardingStyle.wrapper}>
                <Typographys variant="body2" color="neutral.800" display="inline">
                  3. Education Details:
                </Typographys>
                <Typographys variant="body2" color="neutral.600" display="inline">
                  Please specify all your educational details in this section. For every educational record, please
                  specify the Registration Number and attach the relevant Educational Certificate(s). In case of
                  multiple attachments, please zip the same and attach the zip file. You can include as many educational
                  records as needed.
                </Typographys>
              </Box>

              <Box sx={onBoardingStyle.wrapper}>
                <Typographys variant="body2" color="neutral.800" display="inline">
                  4. Employment Details:
                </Typographys>
                <Typographys variant="body2" color="neutral.600" display="inline">
                  Please specify all your past employment details in this section. For every employment, please specify
                  the Commence and End dates, Employee ID, Reference Name and contact details of a person at the
                  organization (preferrably your supervisor). Please zip the following documents and attach the same for
                  each employment record:
                </Typographys>

                <CommonList
                  sx={onBoardingStyle.listWrapper}
                  listColor="neutral.600"
                  lists={[
                    '4.1. Offer Letter',
                    '4.2. Experience Certificate (in case available)',
                    '4.3. Relieving Letter or Resignation Acceptance Letter (in case available)',
                    '4.4. Last 3 PaySlips',
                  ]}
                />
              </Box>

              <Box sx={onBoardingStyle.wrapper}>
                <Typographys variant="body2" color="neutral.800" display="inline">
                  5. Dependent Details:
                </Typographys>
                <Typographys variant="body2" color="neutral.600" display="inline">
                  Please specify all your dependents and their relevant information in this section. Dependents can
                  include your spouse, children, dependent parents and siblings
                </Typographys>
              </Box>

              <Box sx={onBoardingStyle.wrapper}>
                <Typographys variant="body2" color="neutral.800" display="inline">
                  6. Address Details:
                </Typographys>
                <Typographys variant="body2" color="neutral.600" display="inline">
                  Please specify your Permanent Address and Present Address
                </Typographys>
              </Box>

              <Box sx={onBoardingStyle.wrapper}>
                <Typographys variant="body2" color="neutral.800" sx={{ mb: 1 }}>
                  NOTE:
                </Typographys>

                <Typographys variant="body2" color="neutral.800" display="inline">
                  All fields marked with * are necessary and need to be filled.
                </Typographys>

                <Typographys variant="body2" color="neutral.600" display="inline">
                  The various attachments you provide will be listed in the tab titled `ATTACHMENTS` for quick
                  reference. Should you choose to modify any attachment for any record, simply delete the required
                  attachment from the listing. This will automatically remove the document from the associated record.
                </Typographys>

                <Typographys variant="body2" color="neutral.600" sx={{ mt: 2 }}>
                  You can update your documents in multiple sittings. To ensure you dont loose any of your details,
                  please save your updates periodically. Once you have finalized all your details and are satisfied with
                  the same, you can
                  <Typographys variant="body2" color="neutral.800" component="span">
                    {' '}
                    SUBMIT{' '}
                  </Typographys>
                  the same. Please note that once you have submitted your documents, you
                  <Typographys variant="body2" color="neutral.800" component="span">
                    {' '}
                    CANNOT{' '}
                  </Typographys>
                  come back and modify the same. Hence please ensure accuracy and completeness of all your information
                  and documents before submitting.
                </Typographys>
              </Box>

              <Box sx={onBoardingStyle.wrapper}>
                <Typographys variant="body2" color="neutral.800" sx={{ mb: 1 }}>
                  Attachments:
                </Typographys>
                <Typographys variant="body2" color="neutral.600">
                  All the documents that you associate with each record (employment, education etc) will be listed in
                  the tab titled `ATTACHMENTS`
                </Typographys>
              </Box>
            </Box>
          )}
        </Box>
      </PerfectScrollbar>
    </Box>
  );
};

export default Instructions;
