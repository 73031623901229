export const tableStyle = {
  headActionTitle: {
    position: 'sticky',
    right: '0',
    backgroundColor: 'neutral.100',
    zIndex: '9',
    filter: 'drop-shadow(-2px 0px 4px rgba(9, 30, 66, 0.08))',
    width: '7rem',
    padding: 0,
  },

  actionButton: {
    position: 'sticky',
    right: '0',
    backgroundColor: 'common.white',
    filter: 'drop-shadow(-2px 0px 4px rgba(9, 30, 66, 0.08))',
    width: '7rem',
    padding: 0,
  },
};
