import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import InfoCard from 'components/Molecules/InfoCard';
import BarCharts from 'components/Organisms/Charts/BarCharts';
import Typographys from 'components/Atoms/Typography';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { validateDateElseHyphen } from 'utils/commonUtils';
import CommonCard from 'components/Molecules/CommonCard';
import LineCharts from 'components/Organisms/Charts/LineCharts';
import RangePicker from 'components/Molecules/DateRangePicker';

const RenderDateRangePicker = (handleSelect) => {
  return (
    <RangePicker
      handleSubmit={(data) => handleSelect(data)}
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typographys color="inherit" variant="body2" fontWeight={500}>
            Custom Date
          </Typographys>
          <ArrowForwardIosIcon sx={{ fontSize: '1.5rem' }} />
        </Box>
      }
    />
  );
};
const Dashboard = ({
  expenseDetails,
  chartOptions,
  chartData,
  barData,
  legendOptions,
  iconType,
  iconWidth,
  iconHeight,
  lineData,
  lineChartOptions,
  lineChartData,
  handleBarChartCustomDateFilter,
  handleLineChartCustomDateFilter,
  isTeam,
  symbol,
}) => {
  const [filterTitle, setFilterTitle] = useState({
    monthWise: 'This Month',
    categoryWise: 'This Month',
  });

  const handleLineChartFilter = (data) => {
    setFilterTitle({
      ...filterTitle,
      categoryWise: `${validateDateElseHyphen(data.startDate, 'DD MMMM YYYY')} to ${validateDateElseHyphen(
        data.endDate,
        'DD MMMM YYYY',
      )}`,
    });
    handleLineChartCustomDateFilter(data);
  };

  const handleBarChartFilter = (data) => {
    setFilterTitle({
      ...filterTitle,
      monthWise: `${validateDateElseHyphen(data.startDate, 'DD MMMM YYYY')} to ${validateDateElseHyphen(
        data.endDate,
        'DD MMMM YYYY',
      )}`,
    });
    handleBarChartCustomDateFilter(data);
  };

  const filterOptions = [
    { id: 1, name: 'This Week', onClick: () => {} },
    { id: 2, name: 'This Month', onClick: () => {} },
    { id: 3, name: 'This Year', onClick: () => {} },
    {
      id: 4,
      name: 'Custom Date',
      onClick: () => {},
      formatValue: () => RenderDateRangePicker(handleLineChartFilter),
    },
  ];

  const MonthWisefilterOptions = [
    { id: 1, name: 'This Week', onClick: () => {} },
    { id: 2, name: 'This Month', onClick: () => {} },
    { id: 3, name: 'This Year', onClick: () => {} },
    {
      id: 4,
      name: 'Custom Date',
      onClick: () => {},
      formatValue: () => RenderDateRangePicker(handleBarChartFilter),
    },
  ];

  const XAxisData = {
    dataKey: 'Month',
    value: 'Months',
    position: 'bottom',
    color: 'neutral',
    colorCode: 600,
  };

  const lineXAxisData = {
    dataKey: 'Month',
    value: 'Months',
    position: 'right',
  };

  const lineYAxisData = {
    value: 'hrs ',
    position: 'top',
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 2 }}>
        {expenseDetails.map((item) => (
          <Box sx={{ flex: 1 }}>
            <InfoCard heading={item.label} value={`${symbol || '₹'} ${item?.amount || '0'}`} />
          </Box>
        ))}
      </Box>
      {!isTeam && (
        <>
          <CommonCard
            cardHeaderTitle={isTeam ? 'Team Aggregate Expenses' : 'Month wise expenses'}
            isHeaderFilter={isTeam}
            isFooter={false}
            isSelect={false}
            headerFilterOptions={MonthWisefilterOptions}
            headerFilterLabel={filterTitle.monthWise}
            bodyContent={
              <BarCharts
                chartOptions={chartOptions}
                chartData={chartData}
                XAxisData={XAxisData}
                barData={barData}
                legendOptions={legendOptions}
                iconType={iconType}
                iconWidth={iconWidth}
                iconHeight={iconHeight}
                isLegend={isTeam}
                isLegendBottom
              />
            }
          />
          <Box sx={{ mt: 2 }}>
            <CommonCard
              cardHeaderTitle="Category wise Expenses"
              isFooter={false}
              headerFilterOptions={filterOptions}
              isSelect={false}
              headerFilterLabel={filterTitle.categoryWise}
              bodyContent={
                <LineCharts
                  chartOptions={lineChartOptions}
                  chartData={lineChartData}
                  XAxisData={lineXAxisData}
                  YAxisData={lineYAxisData}
                  lineData={lineData}
                  isLegend
                  legendOptions={legendOptions}
                  lineDot={false}
                />
              }
            />
          </Box>
        </>
      )}
    </Box>
  );
};

Dashboard.defaultProps = {
  expenseDetails: [],
  chartOptions: {},
  lineChartOptions: {},
  chartData: [],
  lineChartData: [],
  barData: [],
  lineData: [],
  legendOptions: {},
  iconType: 'circle',
  iconWidth: '1.25rem',
  iconHeight: '1.25rem',
  handleBarChartCustomDateFilter: () => {},
  handleLineChartCustomDateFilter: () => {},
  isTeam: false,
  symbol: '',
};
Dashboard.propTypes = {
  expenseDetails: PropTypes.arrayOf,
  lineChartOptions: PropTypes.shape(),
  lineChartData: PropTypes.arrayOf(PropTypes.shape()),
  lineData: PropTypes.arrayOf(PropTypes.shape()),
  iconType: PropTypes.string,
  iconWidth: PropTypes.string,
  iconHeight: PropTypes.string,
  handleBarChartCustomDateFilter: PropTypes.func,
  handleLineChartCustomDateFilter: PropTypes.func,
  isTeam: PropTypes.bool,
  chartOptions: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    maxBarSize: PropTypes.number,
  }),
  chartData: PropTypes.PropTypes.arrayOf(PropTypes.shape()),
  barData: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      dataKey: PropTypes.string,
      stackId: PropTypes.string,
      color: PropTypes.string,
      colorCode: PropTypes.number,
    }),
  ),
  legendOptions: PropTypes.shape({
    layout: PropTypes.string,
    verticalAlign: PropTypes.string,
    align: PropTypes.string,
    iconType: PropTypes.string,
    iconSize: PropTypes.string,
    iconWidth: PropTypes.string,
    iconHeight: PropTypes.string,
    legendLeftSpacing: PropTypes.string,
  }),
  symbol: PropTypes.string,
};

export default Dashboard;
