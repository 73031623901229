import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Skeleton, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import Typography from 'components/Atoms/Typography';
import useDownloadFile from 'hooks/useDownloadFile';
import OnboardingRecord from 'components/Templates/Profile/OnboardingRecord';
import useFetchRecord from './useFetchRecord';
import { modifyViewData } from './modifyViewData';

const ViewOnboardingRecord = () => {
  const [employee, fetchEmployee] = useFetchRecord();
  const navigate = useNavigate();
  const downloadFile = useDownloadFile();

  useEffect(() => {
    fetchEmployee();
  }, []);

  const { user } = useSelector((state) => {
    return state.session;
  });

  if (employee.error !== null) {
    return (
      <Typography variant="h6">
        {employee?.error?.response?.data?.message || 'Error while loading onboarding record details.'}
      </Typography>
    );
  }
  const transformedData = modifyViewData(employee, user);

  const handleAttachment = (docid, filename) => {
    downloadFile({ docid }, filename, 'restPayIndex/downloadAttachment');
  };

  return employee?.isLoading ? (
    <Box sx={{ p: 4 }}>
      <Skeleton height={70} />
      <Skeleton />
      <Skeleton />
    </Box>
  ) : (
    <OnboardingRecord
      onboardingForm={transformedData}
      cardDetails={transformedData?.cardDetails}
      attachmentData={transformedData?.attachmentData}
      handleEdit={() => navigate('/profile/onboardingrecords/edit')}
      handleAttachment={handleAttachment}
    />
  );
};

export default ViewOnboardingRecord;
