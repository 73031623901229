import { useSelector } from 'react-redux';

import { hasPermission } from 'utils/permissions';

const getActionItems = (services) => {
  return [
    {
      name: 'View',
      onClick: services.viewEmployeeRecord,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_CASE_RECORD'],
    },
    {
      name: 'Edit',
      onClick: services.modifyEmployee,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_CASE_RECORD'],
      conditional: (row) => row?.status?.props?.label === 'Approval Pending' || row?.status?.props?.label === 'Draft',
    },
    {
      name: 'Submit',
      onClick: services.submitLCRecord,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_SUBMIT_LIFECYCLE_CASE_RECORD'],
      conditional: (row) => row?.status?.props?.label === 'Draft',
    },
    {
      name: 'Delete',
      onClick: services.handleDeleteDialog,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_DELETE_CASE_RECORD'],
      conditional: (row) => row?.status?.props?.label === 'Approval Pending' || row?.status?.props?.label === 'Draft',
    },
    {
      name: 'Action Case Record',
      onClick: services.actionEmployee,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_ACTION_LIFECYCLE_CASE_RECORD'],
      conditional: (row) => row?.status?.props?.label === 'Approval Pending',
    },
  ];
};

const useActionItems = (services) => {
  const authorities = useSelector((state) => state.session.user.authorities);

  const actions = getActionItems(services);

  const handleActionItemClick = (row) => {
    const moreFilteredActionItems = actions.filter((action) => {
      const userHasPermission = hasPermission(authorities, action.permissions);
      if ((action.conditional && !action.conditional(row)) || !userHasPermission) {
        return false;
      }
      return true;
    });
    return moreFilteredActionItems;
  };

  return [handleActionItemClick];
};

export default useActionItems;
