import React, { useEffect } from 'react';
import ProfileAddressesTemplate from 'components/Templates/Profile/Addresses';
import { useThunk } from 'hooks/useThunk';
import { useSelector } from 'react-redux';
import { fetchProfileAddresses } from 'store';
import { Typography, Skeleton, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Addresses = () => {
  const navigate = useNavigate();
  const [dofetchAddresses, isLoadingAddresses, loadingAddressesError] = useThunk(fetchProfileAddresses);
  const { contactDetails } = useSelector((state) => {
    return state.profile;
  });

  useEffect(() => {
    dofetchAddresses();
  }, [dofetchAddresses]);

  const handleEdit = () => {
    navigate('/profile/addresses/addEdit');
  };

  if (isLoadingAddresses) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (loadingAddressesError !== null) {
    return (
      <Typography variant="h6">{loadingAddressesError?.message || 'Error while fetching address details'}</Typography>
    );
  }

  return <ProfileAddressesTemplate contactDetails={contactDetails} handleEdit={handleEdit} />;
};

export default Addresses;
