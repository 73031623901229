import PropTypes from 'prop-types';
import React from 'react';
import MuiTableRow from '@mui/material/TableRow';

const TableRow = ({ children, hover, selected }) => {
  return (
    <MuiTableRow hover={hover} selected={selected}>
      {children}
    </MuiTableRow>
  );
};

TableRow.propTypes = {
  hover: PropTypes.bool,
  selected: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

TableRow.defaultProps = {
  hover: false,
  selected: false,
};
export default TableRow;
