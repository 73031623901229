import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Typographys from 'components/Atoms/Typography';

const SalaryDetails = ({ amount, lastCredited }) => {
  return (
    <Box sx={{ display: 'flex', gap: 4 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', gap: 9 }}>
        <Box width="max-content">
          <Typographys variant="body2" color="neutral.800">
            Gross Salary
          </Typographys>
          <Typographys variant="h3" color="neutral.800" sx={{ mt: 1 }}>
            ₹ {amount}
          </Typographys>
        </Box>
        {!!lastCredited && (
          <Typographys variant="body2" color="neutral.600">
            Last credited on {lastCredited}
          </Typographys>
        )}
      </Box>
    </Box>
  );
};

SalaryDetails.defaultProps = {
  amount: '-',
  lastCredited: '-',
};
SalaryDetails.propTypes = {
  amount: PropTypes.string,
  lastCredited: PropTypes.string,
};

export default SalaryDetails;
