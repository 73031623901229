export const transformDashboardData = (response) => {
  if (!response.data) return {};
  const {
    activeEmpCount,
    totalEmpOnboardCount,
    totalEmpAttrCount,
    genderCountsMap,
    tenureChartData,
    barChartEmpTypeWiseAggregateHCDataColumns,
    barChartEmpTypeWiseAggregateOnboardHCData,
    barChartDeptWiseAggregateHCDataColumns,
    barChartDeptWiseAggregateHCData,
    barChartLocWiseAggregateHCDataColumns,
    barChartLocWiseAggregateHCData,

    barChartAggregateAttritionHCDataColumns,
    barChartAggregateAttritionHCData,
    barChartAggregateOnboardHCDataColumns,
    barChartAggregateOnboardHCData,
  } = response.data;

  const numOfEmployeeChartFilterOptions = [
    { id: 1, name: 'Employment Type', onClick: () => {} },
    { id: 2, name: 'Company Wide', onClick: () => {} },
    { id: 3, name: 'Department Wise', onClick: () => {} },
    { id: 4, name: 'Location Wise', onClick: () => {} },
  ];

  const monthWiseTrendFilterOptions = [
    { id: 1, name: 'Attrition', onClick: () => {} },
    { id: 2, name: 'Onboarding', onClick: () => {} },
  ];

  const empTypeWiseColumns = barChartEmpTypeWiseAggregateHCDataColumns?.map((item) => item[1]);
  const empTypeWiseData = barChartEmpTypeWiseAggregateOnboardHCData?.map((itemsArray) => {
    const dataObj = {};
    itemsArray.forEach((item, index) => {
      const key = empTypeWiseColumns[index];
      dataObj[key] = item;
    });
    return dataObj;
  });
  const deptWiseColumns = barChartDeptWiseAggregateHCDataColumns?.map((item) => item[1]);
  const deptWiseData = barChartDeptWiseAggregateHCData?.map((itemsArray) => {
    const dataObj = {};
    itemsArray.forEach((item, index) => {
      const key = deptWiseColumns[index];
      dataObj[key] = item;
    });
    return dataObj;
  });
  const locWiseColumns = barChartLocWiseAggregateHCDataColumns?.map((item) => item[1]);
  const locWiseData = barChartLocWiseAggregateHCData?.map((itemsArray) => {
    const dataObj = {};
    itemsArray.forEach((item, index) => {
      const key = locWiseColumns[index];
      dataObj[key] = item;
    });
    return dataObj;
  });

  const onboardingColumns = barChartAggregateOnboardHCDataColumns?.map((item) => item[1]);
  const onboardingData = barChartAggregateOnboardHCData?.map((itemsArray) => {
    const dataObj = {};
    itemsArray.forEach((item, index) => {
      const key = onboardingColumns[index];
      dataObj[key] = item;
    });
    return dataObj;
  });

  const attritionColumns = barChartAggregateAttritionHCDataColumns?.map((item) => item[1]);

  const attritionData = barChartAggregateAttritionHCData?.map((itemsArray) => {
    const dataObj = {};
    itemsArray.forEach((item, index) => {
      const key = attritionColumns[index];
      dataObj[key] = item;
    });
    return dataObj;
  });
  return {
    activeEmpCount,
    totalEmpOnboardCount,
    totalEmpAttrCount,
    genderData: [
      { name: 'Male', value: genderCountsMap?.male },
      { name: 'Female', value: genderCountsMap?.female },
    ],
    tenureWiseData: tenureChartData?.map((item) => ({ name: item[0], tenure: item[1] })),
    numOfEmployeeChartFilterOptions,
    empTypeWiseColumns: empTypeWiseColumns || [],
    empLineChartData: empTypeWiseData || [],
    empTypeWiseData: empTypeWiseData || [],
    deptWiseColumns: deptWiseColumns || [],
    deptWiseData: deptWiseData || [],
    locWiseColumns: locWiseColumns || [],
    locWiseData: locWiseData || [],
    monthWiseTrendFilterOptions,
    onboardingColumns: attritionColumns || [],
    onboardingData: onboardingData || [],
    attritionColumns: attritionColumns || [],
    attritionData: attritionData || [],
  };
};
