/* eslint-disable react/default-props-match-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import GraphColor from 'utils/GraphColor';
import { Box, Divider, Stack } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import PieCharts from 'components/Organisms/Charts/PieCharts';
import moment from 'moment';
import TableExtended from 'components/Molecules/TableExtended';
import { cardStyle } from '../../../Organisms/MyPay/Dashboard/style';

const PieChartsGraph = ({ data, colors, legendOptions, chartOptions, chartInfoLeft, reportee, status, reportData }) => {
  const chartData = data?.length ? data.map((elements) => ({ name: elements[0], value: elements[1] })) : [];
  return (
    <Box sx={{ backgroundColor: 'white', border: '1px solid #D5D8E1', padding: '2rem', borderRadius: '0.75rem' }}>
      <Box>
        <Typographys variant="h6" color="neutral.800">
          Expense Details for: {reportee?.fullName}
        </Typographys>
        <Divider sx={{ mt: 1, mb: 1 }} />
        <Stack direction="row" justifyContent="space-between">
          <Box>
            <Box>
              <Typographys variant="subtitle">Employee ID: </Typographys>
              <Typographys sx={{ fontWeight: '600' }} variant="h7">
                {reportee?.profile?.employeeID || '-'}
              </Typographys>
            </Box>
            <Box>
              <Typographys variant="subtitle">Date of Joining: </Typographys>
              <Typographys variant="h7">
                {reportee?.profile?.dateOfJoining
                  ? moment(reportee?.profile?.dateOfJoining).format('DD MMMM YYYY')
                  : '-'}
              </Typographys>
            </Box>
            <Box>
              <Typographys variant="subtitle">Draft: </Typographys>
              <Typographys variant="h7">
                <Typographys variant="h7">{status?.Draft || 0}</Typographys>
              </Typographys>
            </Box>
            <Box>
              <Typographys variant="subtitle">Under Review: </Typographys>
              <Typographys variant="h7">{status?.['Under Review'] || 0}</Typographys>
            </Box>
            <Box>
              <Typographys variant="subtitle">Pending Settlement: </Typographys>
              <Typographys variant="h7">{status?.['Approved Pending Payment'] || 0}</Typographys>
            </Box>
            <Box>
              <Typographys variant="subtitle">Settled: </Typographys>
              <Typographys variant="h7">
                <Typographys variant="h7">{status?.['Payment Complete'] || 0}</Typographys>
              </Typographys>
            </Box>
          </Box>
          <Box sx={{ margin: 'auto' }}>
            {!!chartData?.length && (
              <PieCharts
                data={chartData}
                colors={colors}
                legendOptions={legendOptions}
                chartOptions={chartOptions}
                chartInfoLeft={chartInfoLeft}
                isShowChartInfo={false}
                isShowCustomLegend
              />
            )}
            <Typographys variant="body2" color="neutral.800" align="center">
              Category wise details {chartData?.length ? '' : ': Not available'}
            </Typographys>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

PieChartsGraph.defaultProps = {
  data: [],
  legendOptions: {
    layout: 'vertical',
    verticalAlign: 'middle',
    align: 'right',
    legendLeftSpacing: '7.75rem',
    iconWidth: '12px',
    iconHeight: '12px',
    iconType: 'circle',
    isLegendValue: false,
  },
  chartOptions: {
    chartWidth: 450,
    chartHeight: 220,
    paddingAngle: 0,
    innerRadius: 60,
    outerRadius: 90,
    cx: '',
    cy: '',
  },
  isShowChartInfo: true,
  isShowCustomLegend: true,
  chartInfoLeft: '17rem',
  colors: GraphColor('pieChartsColors'),
};

export default PieChartsGraph;
