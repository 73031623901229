import { formDataApi } from 'api/api';

export const searchEmployeesAction = async (query) => {
  const response = await formDataApi.post(`restEmpIndex/listUsers?${query.toString()}`);
  return response?.data;
};

export const updateEmployeeSearchAction = async (bodyFormData) => {
  const response = await formDataApi.post(`restEmpIndex/updateEmployeeSearchAction`, bodyFormData);
  return response;
};

export const searchAllExpenseReports = async (bodyFormData) => {
  const response = await formDataApi.post(`restExpIndex/searchAllExpenseReports`, bodyFormData);
  return response;
};

export const myDraftExpenseReports = async (bodyFormData) => {
  const response = await formDataApi.post(`restExpIndex/myDraftExpenseReports`, bodyFormData);
  return response;
};

export const mySubmittedExpenseReports = async (bodyFormData) => {
  const response = await formDataApi.post(`restExpIndex/mySubmittedExpenseReports`, bodyFormData);
  return response;
};

export const submitReportForReview = async (bodyFormData) => {
  const response = await formDataApi.post(`restExpIndex/submitReportForReview`, bodyFormData);
  return response;
};

export const recallSubmittedExpenseReport = async (bodyFormData) => {
  const response = await formDataApi.post(`restExpIndex/recallSubmittedExpenseReport`, bodyFormData);
  return response;
};

export const myActionableExpenseReports = async (query) => {
  const response = await formDataApi.post(`restExpIndex/myActionableExpenseReports?${query?.toString()}`);
  return response;
};

export const actionExpenseReport = async (bodyFormData) => {
  const response = await formDataApi.post(`restExpIndex/actionExpenseReport`, bodyFormData);
  return response;
};

export const actionExpenseReportApproveReject = async (bodyFormData) => {
  const response = await formDataApi.post(`restExpIndex/actionExpenseReportApproveReject`, bodyFormData);
  return response;
};

export const searchAllExpenseReportsAction = async (bodyFormData) => {
  const response = await formDataApi.post(`restExpIndex/searchAllExpenseReportsAction`, bodyFormData);
  return response;
};

export const searchMyExpenseReports = async (bodyFormData) => {
  const response = await formDataApi.post(`restExpIndex/searchMyExpenseReports`, bodyFormData);
  return response;
};

export const listExpenseReports = async (status, query) => {
  if (status === 0) {
    const response = await formDataApi.post(`restExpIndex/myExpenseReports?${query?.toString()}`);
    return response;
  }
  if (status === 1) {
    const response = await formDataApi.post(`restExpIndex/myDraftExpenseReports?${query?.toString()}`);
    return response;
  }
  if (status === 2) {
    const response = await formDataApi.post(`restExpIndex/mySubmittedExpenseReports?${query?.toString()}`);
    return response;
  }
  return '';
};

export const viewExpenseReportAction = async (bodyFormData) => {
  const response = await formDataApi.post(`restExpIndex/viewExpenseReport`, bodyFormData);
  return response;
};

export const deleteExpenseReportAction = async (bodyFormData) => {
  const response = await formDataApi.post(`restExpIndex/deleteExpenseReport`, bodyFormData);
  return response;
};

export const myExpensesCreateData = async () => {
  const response = await formDataApi.get(`restExpIndex/newExpenseReport`);
  return response;
};
export const newExpenseReportAction = async (bodyFormData) => {
  const response = await formDataApi.post(`restExpIndex/newExpenseReportAction`, bodyFormData);
  return response;
};

// id: 167671
export const myExpenseDetailsData = async (bodyFormData) => {
  const response = await formDataApi.post(`restExpIndex/modifyExpenseReport`, bodyFormData);
  return response;
};

export const modifyExpenseReportAction = async (bodyFormData) => {
  const response = await formDataApi.post(`restExpIndex/modifyExpenseReportAction`, bodyFormData);
  return response;
};

export const myOrgExpenseReports = async (query) => {
  const response = await formDataApi.post(`restExpIndex/myOrgExpenseReports?${query?.toString()}`);
  return response;
};

export const searchMyOrgExpenseReports = async () => {
  const response = await formDataApi.get(`restExpIndex/searchMyOrgExpenseReports`);
  return response;
};

export const searchMyOrgExpenseReportsAction = async (args) => {
  const response = await formDataApi.post(`restExpIndex/searchMyOrgExpenseReportsAction`, args);
  return response;
};

export const myExpensesDashboardDetails = async (query) => {
  const response = await formDataApi.get(`restExpIndex/dashboard?${query.toString()}`);
  return response?.data;
};

export const searchMyExpenseReport = async () => {
  const response = await formDataApi.get(`restExpIndex/searchMyExpenseReports`);
  return response;
};

export const searchMyExpenseReportAction = async (args) => {
  const response = await formDataApi.post(`restExpIndex/searchMyExpenseReportsAction`, args);
  return response;
};

export const allExpenseReports = async (query) => {
  const response = await formDataApi.post(`restExpIndex/listExpenseReports?${query?.toString()}`);
  return response;
};

export const newExpenseReportSubmitAction = async (bodyFormData) => {
  const response = await formDataApi.post(`restExpIndex/newExpenseReportSubmitAction`, bodyFormData);
  return response;
};
