import React from 'react';
import { Box, Card, CardContent, Container, Grid, Stack, Skeleton } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import DropdownMenu from 'components/Atoms/DropdownMenu';
import Button from 'components/Atoms/Button';
import Typography from 'components/Atoms/Typography';
import EditIcon from '@mui/icons-material/Edit';
import { hasPermission } from 'utils/permissions';
import { useSelector } from 'react-redux';
import { DeleteDialog } from '../List/DeleteDialog';
import ViewPayheadDetails from './ViewPayheadDetails';
import useFetchPayhead from './useFetchPayhead';

const ViewPayhead = () => {
  const { payheadId } = useParams();
  const [payhead] = useFetchPayhead(payheadId, 'view', true);
  const navigate = useNavigate();
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasEditPermission = hasPermission(authorities, ['ROLE_BASE_PERMISSION_PAY_INDEX_UPDATE_PAYHEAD']);
  const userHasDeletePermission = hasPermission(authorities, ['ROLE_BASE_PERMISSION_PAY_INDEX_DELETE_PAYHEAD']);

  if (payhead.error !== null) {
    return (
      <Typography variant="h6">
        {payhead?.error?.response?.data?.message ||
          `Error while loading payhead details for payhead id : ${payheadId}.`}
      </Typography>
    );
  }
  return (
    <Container maxWidth="lg" sx={{ mb: 2.5 }}>
      <Grid container direction="column">
        <Grid item container justifyContent="flex-start" alignItems="center" sx={{ pb: 1.5 }}>
          <Grid item>
            <Typography variant="h6" color="text.primary">
              Payheads Details
            </Typography>
          </Grid>
        </Grid>
        <Grid item sx={{ maxWidth: '100% !important' }}>
          <Card variant="outlined" square>
            <CardContent>
              <Stack direction="row" gap={2} justifyContent="space-between" sx={{ pb: 2 }}>
                <Typography variant="h6" color="text.300">
                  {payhead.data?.pageHeading}
                </Typography>
                <Stack direction="row" gap={2} justifyContent="end">
                  {!!userHasEditPermission && (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => navigate(`/company/salary/payheads/${payheadId}/edit`)}
                      startIcon={<EditIcon fontSize="small" />}
                    >
                      Edit
                    </Button>
                  )}
                  {!!userHasDeletePermission && <DeleteDialog payheadId={payheadId} />}
                </Stack>
              </Stack>
              {payhead.isLoading ? (
                <Box sx={{ py: 4 }}>
                  <Skeleton height={42} />
                  <Skeleton />
                  <Skeleton />
                </Box>
              ) : (
                <ViewPayheadDetails payhead={payhead.data} />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ViewPayhead;
