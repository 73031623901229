export const teamAttendanceStyle = {
  cardWrapper: {
    border: '0.125rem solid',
    borderColor: 'neutral.300',
    borderRadius: '0.625rem',
    backgroundColor: 'common.white',
    padding: '2.5rem 3rem',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 1.25,

    '.MuiFormControl-root': {
      minWidth: 'max-content',

      '.MuiInputLabel-root': {
        transform: 'none',
        mt: 0,
        fontSize: '1.625rem',
        color: 'secondary.600',
        fontWeight: '500',

        '&.MuiInputLabel-shrink': {
          display: 'none',
        },
      },

      '.MuiInput-root': {
        fontSize: '13px',
        mt: 0,

        '.MuiSvgIcon-root': {
          color: 'secondary.600',
        },

        '&:before, &:after': {
          border: 'none',
        },
        '.MuiSelect-select': {
          minWidth: '32px',
          background: 'transparent',
          paddingBottom: 0,
        },
      },
    },
  },

  cardHeaderDropdown: {
    minWidth: '12rem',
  },

  cardBody: {
    display: 'flex',
    columnGap: '1.5rem',
  },
  statusCard: {
    padding: '2rem',
  },
  statusCardIconTitle: {
    display: 'flex',
    columnGap: 0.5,
  },

  teamAttendanceTableWrapper: {
    table: {
      'thead,tbody': {
        'th,td': {
          minWidth: '18rem',
          '&:first-child, &:nth-child(2), &:nth-child(3)': {
            position: 'sticky',
            zIndex: '1',
          },
          '&:first-child': {
            left: 0,
            minWidth: 'auto',
          },
          '&:last-child': {
            minWidth: 'auto',
          },
          '&:nth-child(2)': {
            left: '8.5rem',
            minWidth: '22rem',
          },
          '&:nth-child(3)': {
            minWidth: '26rem',
            left: '34.5rem',
            filter: 'drop-shadow(8px 0px 9px rgba(9, 30, 66, 0.08))',
          },
        },
      },

      thead: {
        th: {
          '&:first-child, &:nth-child(2), &:nth-child(3)': {
            backgroundColor: 'neutral.100',
          },
        },
      },

      tbody: {
        td: {
          '&:first-child, &:nth-child(2), &:nth-child(3)': {
            backgroundColor: 'common.white',
          },
        },
        'tr:hover': {
          td: {
            '&:first-child, &:nth-child(2), &:nth-child(3)': {
              backgroundColor: 'info.100',
            },
          },
        },
      },
    },
  },

  teamAttendanceGridTableWrapper: {
    table: {
      borderBottom: '0.125rem solid',
      borderColor: 'neutral.200',
      mb: 3,

      'thead,tbody': {
        'th,td': {
          '&:first-child': {
            minWidth: '18rem',
          },
        },
      },
    },
  },

  teamAttendanceGridTableHeader: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 1,
    py: 2,
    '.MuiCheckbox-root': {
      margin: '0 4px 0 0',
    },
  },

  teamAttendanceTableCardWrapper: {
    padding: '2rem 3rem',
    border: '0.125rem solid',
    borderColor: 'neutral.300',
    backgroundColor: 'common.white',
    borderRadius: '0.75rem',
  },

  teamAttendanceTableCardHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: 2,
  },
  teamAttendanceTableCardActionBtnGroup: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 2.75,
  },
};
