import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Divider, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Button from 'components/Atoms/Button';
import InputField from 'components/Atoms/InputField';
import Typographys from 'components/Atoms/Typography';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { FieldArray, getIn, Formik } from 'formik';
import DatePicker from 'components/Atoms/Datepicker';
import Attachments from 'components/Atoms/Attachments';
import * as yup from 'yup';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { genericMobileRegex, genericEmailRegex } from 'utils/commonUtils';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';

import { onBoardingStyle } from '../../../style';

const EducationHistory = ({ handleCancel, propData, label, handleSubmit }) => {
  const defaultObj = {
    institutionName: '',
    certificationName: '',
    startDate: '',
    endDate: '',
    specialization: '',
    registrationNumber: '',
    referenceName: '',
    referenceNumber: '',
    referenceEmail: '',
    comments: '',
    attachments: '',
    isNew: true,
  };
  const defaultValues = propData.allTabsData[label];

  const educationValidationSchema = yup.object().shape({
    education: yup.array().of(
      yup.object().shape({
        institutionName: yup.string().required('Required'),
        startDate: yup.string().required('Required'),
        endDate: yup.string().required('Required'),
        referenceNumber: yup.string().matches(genericMobileRegex, 'Invalid format, Please enter valid mobile number.'),
        referenceEmail: yup.string().matches(genericEmailRegex, 'Invalid format, Please enter valid email ID.'),
      }),
    ),
  });

  return (
    <Box mt={1.5}>
      <Formik
        initialValues={
          defaultValues.education.length > 0 && Object.keys(defaultValues.education[0]).length
            ? defaultValues
            : { education: [{ key: 1 }] }
        }
        onSubmit={handleSubmit}
        validateOnMount
        validationSchema={educationValidationSchema}
      >
        {(formik) => (
          <Form formik={formik} handleCancel={handleCancel} propData={propData} label={label} defaultObj={defaultObj} />
        )}
      </Formik>
    </Box>
  );
};

const Form = ({ formik, handleCancel, propData, label, defaultObj }) => {
  const [keyCount, setKeyCount] = useState(2);
  useEffect(() => {
    propData.updateFormData(formik.values, label);
  }, [formik.values, label]);

  const handleDateChange = (date, setFieldValue, fieldName) => {
    setFieldValue(fieldName, date);
  };

  const theme = useTheme();

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 59rem)' }}>
          <Box sx={{ mb: 2 }}>
            <Typographys variant="h5" color="neutral.800">
              Education/Certification History
            </Typographys>
          </Box>

          <FieldArray name="education">
            {({ push, remove }) => (
              <Box>
                {formik?.values?.education.map((e, index) => {
                  return (
                    <Accordion key={e.key} disableGutters elevation={0} square sx={onBoardingStyle.accordionWrapper}>
                      <AccordionSummary
                        aria-controls="education-content"
                        id="education-header"
                        expandIcon={<KeyboardArrowDownSharpIcon sx={{ fontSize: '3rem', color: 'neutral.800' }} />}
                      >
                        <Box sx={onBoardingStyle.deleteContainer}>
                          <Typography variant="subtitle2" color="neutral.800">
                            Record {index + 1}
                          </Typography>
                          <DeleteOutlineSharpIcon sx={{ color: 'neutral.600' }} onClick={() => remove(index)} />
                        </Box>
                      </AccordionSummary>

                      <AccordionDetails>
                        <Grid container rowSpacing={2} columnSpacing={9} pt={0} sx={onBoardingStyle.formContainer}>
                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `education[${index}].institutionName`) &&
                                  getIn(formik.errors, `education[${index}].institutionName`),
                              )}
                              helperText={
                                getIn(formik.touched, `education[${index}].institutionName`) &&
                                getIn(formik.errors, `education[${index}].institutionName`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.institutionName}
                              name={`education[${index}].institutionName`}
                              id={`education[${index}].institutionName`}
                              label="Institution Name*"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `education[${index}].certificationName`) &&
                                  getIn(formik.errors, `education[${index}].certificationName`),
                              )}
                              helperText={
                                getIn(formik.touched, `education[${index}].certificationName`) &&
                                getIn(formik.errors, `education[${index}].certificationName`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.certificationName}
                              name={`education[${index}].certificationName`}
                              id={`education[${index}].certificationName`}
                              label="Certification Name"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <DatePicker
                              error={Boolean(
                                getIn(formik.touched, `education[${index}].startDate`) &&
                                  getIn(formik.errors, `education[${index}].startDate`),
                              )}
                              helperText={
                                getIn(formik.touched, `education[${index}].startDate`) &&
                                getIn(formik.errors, `education[${index}].startDate`)
                              }
                              onChange={(data) =>
                                handleDateChange(data, formik.setFieldValue, `education[${index}].startDate`)
                              }
                              onBlur={formik.handleBlur}
                              value={e?.startDate}
                              maxDate={e?.endDate}
                              name={`education[${index}].startDate`}
                              id={`education[${index}].startDate`}
                              label="Start Date*"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <DatePicker
                              error={Boolean(
                                getIn(formik.touched, `education[${index}].endDate`) &&
                                  getIn(formik.errors, `education[${index}].endDate`),
                              )}
                              helperText={
                                getIn(formik.touched, `education[${index}].endDate`) &&
                                getIn(formik.errors, `education[${index}].endDate`)
                              }
                              onChange={(data) =>
                                handleDateChange(data, formik.setFieldValue, `education[${index}].endDate`)
                              }
                              onBlur={formik.handleBlur}
                              value={e?.endDate}
                              minDate={e?.startDate}
                              name={`education[${index}].endDate`}
                              id={`education[${index}].endDate`}
                              label="End Date*"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `education[${index}].specialization`) &&
                                  getIn(formik.errors, `education[${index}].specialization`),
                              )}
                              helperText={
                                getIn(formik.touched, `education[${index}].specialization`) &&
                                getIn(formik.errors, `education[${index}].specialization`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.specialization}
                              name={`education[${index}].specialization`}
                              id={`education[${index}].specialization`}
                              label="Specialization"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `education[${index}].registrationNumber`) &&
                                  getIn(formik.errors, `education[${index}].registrationNumber`),
                              )}
                              helperText={
                                getIn(formik.touched, `education[${index}].registrationNumber`) &&
                                getIn(formik.errors, `education[${index}].registrationNumber`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.registrationNumber}
                              name={`education[${index}].registrationNumber`}
                              id={`education[${index}].registrationNumber`}
                              label="Registration Number"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `education[${index}].referenceName`) &&
                                  getIn(formik.errors, `education[${index}].referenceName`),
                              )}
                              helperText={
                                getIn(formik.touched, `education[${index}].referenceName`) &&
                                getIn(formik.errors, `education[${index}].referenceName`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.referenceName}
                              name={`education[${index}].referenceName`}
                              id={`education[${index}].referenceName`}
                              label="Reference Name"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `education[${index}].referenceNumber`) &&
                                  getIn(formik.errors, `education[${index}].referenceNumber`),
                              )}
                              helperText={
                                getIn(formik.touched, `education[${index}].referenceNumber`) &&
                                getIn(formik.errors, `education[${index}].referenceNumber`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.referenceNumber}
                              name={`education[${index}].referenceNumber`}
                              id={`education[${index}].referenceNumber`}
                              label="Reference Phone Number"
                              type="number"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `education[${index}].referenceEmail`) &&
                                  getIn(formik.errors, `education[${index}].referenceEmail`),
                              )}
                              helperText={
                                getIn(formik.touched, `education[${index}].referenceEmail`) &&
                                getIn(formik.errors, `education[${index}].referenceEmail`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.referenceEmail}
                              name={`education[${index}].referenceEmail`}
                              id={`education[${index}].referenceEmail`}
                              label="Reference Email"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `education[${index}].comments`) &&
                                  getIn(formik.errors, `education[${index}].comments`),
                              )}
                              helperText={
                                getIn(formik.touched, `education[${index}].comments`) &&
                                getIn(formik.errors, `education[${index}].comments`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.comments}
                              name={`education[${index}].comments`}
                              id={`education[${index}].comments`}
                              label="Comments"
                              multiline
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <Attachments
                              isDeleteIcon
                              handleChange={(event) => {
                                formik.setFieldValue(`education[${index}].attachments`, event.target.files[0]);
                              }}
                              name={`education[${index}].attachments`}
                              id={`education[${index}].attachments`}
                              value={e.attachments}
                              handleDelete={() => {
                                formik.setFieldValue(`education[${index}].attachments`, '');
                              }}
                            />
                            <Grid item xs={12} md={8} mt={2}>
                              {e?.attachments?.length > 0 && (
                                <Typographys variant="caption" color="neutral.600">
                                  Previously Associated Attachment: {e?.attachments?.[0]?.fileName || ''}
                                </Typographys>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}

                <Box sx={{ mt: 3 }}>
                  <Button
                    size="medium"
                    variant="outlined"
                    type="button"
                    startIcon={<AddOutlinedIcon />}
                    onClick={() => {
                      push({ ...defaultObj, key: keyCount });
                      setKeyCount(keyCount + 1);
                    }}
                  >
                    Add Another
                  </Button>
                </Box>
              </Box>
            )}
          </FieldArray>
        </Box>
      </PerfectScrollbar>

      <Box sx={onBoardingStyle.btnContainer}>
        <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

        <Box sx={onBoardingStyle.submitWrapper}>
          <Button
            size="large"
            color="info"
            variant="outlined"
            type="button"
            onClick={() => {
              formik.resetForm();
              handleCancel();
            }}
          >
            Cancel
          </Button>

          <LoadingButton
            loading={propData.propData.loading}
            color="primary"
            size="large"
            type="submit"
            variant="contained"
          >
            Save & Continue
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

Form.defaultProps = {
  handleCancel: () => {},
  label: '',
  propData: {},
  formik: {},
  defaultObj: {},
};

Form.propTypes = {
  propData: PropTypes.shape({
    updateFormData: PropTypes.func,
    allTabsData: PropTypes.shape({
      'Education/Certification History': PropTypes.shape(),
    }),
    allowEdit: PropTypes.bool,
    propData: PropTypes.shape({
      loading: PropTypes.bool,
    }),
  }),
  handleCancel: PropTypes.func,

  label: PropTypes.string,
  formik: PropTypes.shape({
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    values: PropTypes.shape(),
    resetForm: PropTypes.func,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    setFieldValue: PropTypes.func,
  }),
  defaultObj: PropTypes.shape({}),
};

EducationHistory.defaultProps = {
  handleSubmit: () => {},
  handleCancel: () => {},
  label: 'Education/Certification History',
  propData: {
    allTabsData: {
      'Education/Certification History': {
        education: [
          {
            institutionName: '',
            certificationName: '',
            startDate: '',
            endDate: '',
            specialization: '',
            registrationNumber: '',
            referenceName: '',
            referenceNumber: '',
            referenceEmail: '',
            comments: '',
            attachments: '',
          },
        ],
      },
    },
    updateFormData: () => {},
  },
};

EducationHistory.propTypes = {
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  propData: PropTypes.shape({
    allTabsData: PropTypes.shape({}),
    updateFormData: PropTypes.func,
  }),
  label: PropTypes.string,
};

export default EducationHistory;
