import * as React from 'react';
import Paper from '@mui/material/Paper';
import Typographys from 'components/Atoms/Typography';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';

function createData(Title, DeclaredAmount, ProofSubmitted, AnnualExemptionLimit, ExemptionConsidered) {
  return { Title, DeclaredAmount, ProofSubmitted, AnnualExemptionLimit, ExemptionConsidered };
}

const rows = [
  createData('Earnings (from salary)', '₹10000', '₹10000', '₹10000', '₹10000'),
  createData('Leave Travel Allowance', '₹10000', '₹10000', '₹10000', '₹10000'),
];

const ExemptionTable = () => {
  return (
    <PerfectScrollbar>
      <Box sx={{ paddingBottom: 2 }}>
        <Typographys variant="body1">Deducation/Exemption Details</Typographys>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: 'info.200', '&>td, &>th': { border: 0 } }}>
              <TableCell>
                <Typographys variant="h6" fontWeight="600">
                  Title
                </Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="h6" fontWeight="600">
                  Declared Amount
                </Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="h6" fontWeight="600">
                  Proof Submitted
                </Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="h6" fontWeight="600">
                  Annual Exemption Limit
                </Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="h6" fontWeight="600">
                  Exemption Considered
                </Typographys>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.Title} sx={{ '&>td, &>th': { border: 0 } }}>
                <TableCell variant="body2">
                  <Typographys variant="body2">{row.Title}</Typographys>
                </TableCell>
                <TableCell>
                  <Typographys>{row.DeclaredAmount}</Typographys>
                </TableCell>
                <TableCell>
                  <Typographys>{row.ProofSubmitted}</Typographys>
                </TableCell>
                <TableCell>
                  <Typographys>{row.AnnualExemptionLimit}</Typographys>
                </TableCell>
                <TableCell>
                  <Typographys>{row.ExemptionConsidered}</Typographys>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </PerfectScrollbar>
  );
};

export default ExemptionTable;
