import { useSelector } from 'react-redux';
import { hasPermission } from 'utils/permissions';

const getActionItems = (services, userId) => {
  const id = services?.state?.expenseReportDetails?.id;
  return [
    {
      label: 'Submit Report',
      onClick: () => services.submitExpenseReport({ id }),
      id: 1,
      permissions: ['ROLE_BASE_PERMISSION_EXPENSE_INDEX_SUBMIT_MY_EXPENSE_REPORT_FOR_REVIEW'],
      conditional: (row) => row?.reportDetails?.reportStatus?.toUpperCase() === 'DRAFT',
    },
    {
      label: 'Approve/Reject',
      onClick: () => services.actionNavigation({ id }),
      id: 2,
      permissions: ['ROLE_BASE_PERMISSION_EXPENSE_INDEX_ACTION_EXPENSE_REPORT'],
      conditional: (row) =>
        row?.reportDetails?.reportStatus?.toUpperCase() === 'SUBMITTED' ||
        row?.reportDetails?.reportStatus?.toUpperCase() === 'UNDER REVIEW',
    },
    {
      label: 'Delete',
      onClick: services.handleDeleteDialog,
      id: 4,
      permissions: ['ROLE_BASE_PERMISSION_EXPENSE_INDEX_DELETE_EXPENSE_REPORT'],
      conditional: (row) => row?.reportDetails?.entityOrigin?.toUpperCase() !== 'ENTITY_SYSTEM_DEFINED',
    },
  ];
};

const useMenuItems = (services) => {
  const { user } = useSelector((state) => state?.session);
  const authorities = user?.authorities;
  const actions = getActionItems(services, user?.employeeID);

  const moreFilteredActionItems = actions.filter((action) => {
    const userHasPermission = hasPermission(authorities, action.permissions);

    if ((action.conditional && !action.conditional(services?.state?.expenseReportDetails)) || !userHasPermission) {
      return false;
    }
    return true;
  });

  return [moreFilteredActionItems];
};

export default useMenuItems;
