import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import StatusCard from 'components/Molecules/StatusCard';

const StatusCardGroup = ({ numActiveValue, numNewJoinessYTD, numExitYTD, numNotInvited }) => {
  return (
    <Grid container spacing={2} sx={{ pb: 2, pt: 2 }}>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <StatusCard heading="Active" value={numActiveValue} iconSrc="images/icons/custom-user-group.svg" />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <StatusCard heading="New Joiness YTD" value={numNewJoinessYTD} iconSrc="images/icons/hero-solid-user-add.svg" />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <StatusCard heading="EXITS YTD" value={numExitYTD} iconSrc="images/icons/hero-solid-user-remove.svg" />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <StatusCard heading="NOT Invited" value={numNotInvited} iconSrc="images/icons/unsubscribe.svg" />
      </Grid>
    </Grid>
  );
};

StatusCardGroup.defaultProps = {
  numActiveValue: 6823,
  numNewJoinessYTD: 1,
  numExitYTD: 23,
  numNotInvited: 20,
};
StatusCardGroup.propTypes = {
  numActiveValue: PropTypes.number,
  numNewJoinessYTD: PropTypes.number,
  numExitYTD: PropTypes.number,
  numNotInvited: PropTypes.number,
};

export default StatusCardGroup;
