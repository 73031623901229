import PropTypes from 'prop-types';
import React from 'react';
import { Grid, Divider } from '@mui/material';
import Typography from 'components/Atoms/Typography';

const ViewPayheadDetails = ({ payhead }) => {
  const { payheadDetails, ledgerDetails, otherDetails } = payhead;

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item container xs={8} spacing={2}>
        {payheadDetails?.map((item) => {
          return (
            <Grid item xs={6} key={item.label}>
              <Typography variant="body2">{item.label}</Typography>
              {item.value}
            </Grid>
          );
        })}
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      <Grid item container xs={8} spacing={2}>
        {ledgerDetails?.map((item) => {
          return (
            <Grid item xs={6} key={item.label}>
              <Typography variant="body2">{item.label}</Typography>
              {item.value}
            </Grid>
          );
        })}
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      <Grid item container xs={8} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body2">{otherDetails?.heading.label}</Typography>
          {otherDetails?.heading.value}
        </Grid>
        {otherDetails?.data?.map((item) => {
          return (
            <Grid item xs={6} key={item.label}>
              {item.formatValue?.(item.label)}
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

ViewPayheadDetails.propTypes = {
  payhead: PropTypes.shape({
    payheadDetails: PropTypes.arrayOf(PropTypes.node),
    ledgerDetails: PropTypes.arrayOf(PropTypes.node),
    otherDetails: PropTypes.node,
  }),
};

ViewPayheadDetails.defaultProps = {
  payhead: {
    payheadDetails: [],
    ledgerDetails: [],
    otherDetails: {
      data: [],
    },
  },
};
export default ViewPayheadDetails;
