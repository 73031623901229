import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Box, Divider, Grid, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import Typographys from 'components/Atoms/Typography';
import DatePicker from 'components/Atoms/Datepicker';
import Button from 'components/Atoms/Button';
import Dropdown from 'components/Molecules/Dropdown';
import CodeEditor from 'components/Molecules/CodeEditor';
import { empRecordActionDetailStyle } from '../style';

const NewEmployeeDetailForm = ({
  btnLabel,
  handleSubmit,
  handleCancel,
  supervisorOptions,
  departmentOptions,
  designationOptions,
  locationOptions,
  userData,
  loading,
}) => {
  const theme = useTheme();

  const NewEmployeeDetailSchema = yup.object().shape({
    effectiveStartDate: yup.mixed().required('Date is required'),
  });

  const formik = useFormik({
    initialValues: {
      effectiveStartDate: '',
      supervisor: userData?.employee?.supervisorId,
      department: userData?.employee?.departmentId,
      designation: userData?.employee?.designationId,
      location: userData?.employee?.locationId,
      notes: '',
    },
    onSubmit: async (values, helpers) => {
      try {
        handleSubmit(values);
        // helpers.resetForm();
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
    validationSchema: NewEmployeeDetailSchema,
  });
  return (
    <>
      <Typographys variant="h6" color="neutral.800">
        New Details
      </Typographys>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <Box sx={empRecordActionDetailStyle.formWrapper}>
          <Grid container rowGap={2} columnSpacing={9}>
            <Grid item xs={12} sm={6} md={4}>
              <DatePicker
                error={Boolean(formik.touched.effectiveStartDate && formik.errors.effectiveStartDate)}
                onChange={(value) => {
                  formik.setFieldValue('effectiveStartDate', Date.parse(value));
                }}
                onBlur={formik.handleBlur}
                value={formik.values.effectiveStartDate}
                name="effectiveStartDate"
                id="effectiveStartDate"
                label={`${btnLabel} Effective From*`}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Dropdown
                handleChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.supervisor}
                name="supervisor"
                id="supervisor"
                label="Supervisor"
                options={supervisorOptions}
                keyGetter={(item) => item.id}
                titleGetter={(item) => item.fullName}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Dropdown
                handleChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.department}
                name="department"
                id="department"
                label="Department"
                options={departmentOptions}
                keyGetter={(item) => item.id}
                titleGetter={(item) => item.name}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Dropdown
                handleChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.designation}
                name="designation"
                id="designation"
                label="Designation"
                options={designationOptions}
                keyGetter={(item) => item.id}
                titleGetter={(item) => item.name}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Dropdown
                handleChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.location}
                name="location"
                id="location"
                label="Location"
                options={locationOptions}
                keyGetter={(item) => item.id}
                titleGetter={(item) => item.name}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <CodeEditor
                error={Boolean(formik.touched.notes && formik.errors.notes)}
                helperText={formik.touched.notes && formik.errors.notes}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.notes}
                name="notes"
                id="notes"
                label={`${btnLabel} notes`}
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={empRecordActionDetailStyle.btnContainer}>
          <Divider sx={{ my: 2, borderColor: theme.palette.other[200] }} />
          <Box sx={empRecordActionDetailStyle.submitWrapper}>
            <Button
              size="large"
              color="info"
              variant="outlined"
              type="reset"
              onClick={() => {
                formik.resetForm();
                handleCancel();
              }}
            >
              Cancel
            </Button>
            <LoadingButton loading={loading} color="primary" size="large" type="submit" variant="contained">
              {btnLabel}
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

NewEmployeeDetailForm.defaultProps = {
  btnLabel: '',
  handleSubmit: () => {},
  handleCancel: () => {},
  supervisorOptions: [],
  departmentOptions: [],
  designationOptions: [],
  locationOptions: [],
  userData: {},
  loading: false,
};

NewEmployeeDetailForm.propTypes = {
  btnLabel: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  supervisorOptions: PropTypes.arrayOf,
  departmentOptions: PropTypes.arrayOf,
  designationOptions: PropTypes.arrayOf,
  locationOptions: PropTypes.arrayOf,
  userData: PropTypes.shape(),
  loading: PropTypes.bool,
};

export default NewEmployeeDetailForm;
