// Past employer taxes
export const PAST_EMPLOYER_TAXES_STATUS_IN_DRAFT = 'Draft';
export const PAST_EMPLOYER_TAXES_STATUS_IN_APPROVED = 'Approved';
export const PAST_EMPLOYER_TAXES_STATUS_IN_REVIEW = 'Under Review';
export const PAST_EMPLOYER_TAXES_STATUS_IN_REJECT = 'Rejected';
export const NEW_TAX_REGIME_2020 = 'New Tax Regime 2020';
export const NEW_TAX_REGIME = 'New Tax Regime';
export const REPORT_STATUS_REJECTED = 'REJECTED';
export const LOCK_STATUS_UNLOCKED = 'UNLOCKED';
export const ENTITY_ORIGIN_SYSTEM_DEFINED = 'ENTITY_SYSTEM_DEFINED';
export const SOURCING_STAGE_OFFER_LETTER_PENDING_ACCEPTANCE = 'Offer Letter-Pending Acceptance';
export const SOURCING_STAGE_OFFER_LETTER_ACCEPTED = 'Offer Letter-Accepted';
export const SOURCING_STAGE_OFFER_LETTER_REJECTED = 'Offer Letter-Rejected';
export const EMPLOYEE_PROOF_SUBMISSION_STATUS = 'Draft Proofs';
export const PROOF_SUBMISSION_STATUS = 'Proof Under Review';
export const OPEN_FOR_DECLARATION = 'Open for Declaration';
