import { createImagePlugin, createMediaEmbedPlugin, createLinkPlugin } from '@udecode/plate';
import { CONFIG } from '../config/config';
import { plateComponentUI } from '../config/plateComponentUI';
import { createMyPlugins } from '../config/typescript';

export const mediaPlugins = createMyPlugins(
  [createImagePlugin(), createMediaEmbedPlugin(), createLinkPlugin(CONFIG.link)],
  {
    components: plateComponentUI,
  },
);
