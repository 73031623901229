import PropTypes from 'prop-types';
import React from 'react';
import { Grid, Divider, Box, Button } from '@mui/material';
import Typography from 'components/Atoms/Typography';
import useDownloadFile from 'hooks/useDownloadFile';
import { isObject, isEmpty } from 'lodash';

const ViewExitTaskDetails = ({ task }) => {
  const simpleTaskInstance = task;
  const downloadFile = useDownloadFile();

  const downloadDocument = (row) => {
    downloadFile({ docid: row?.id }, row?.fileName, 'restExpIndex/downloadAttachment');
  };

  const basicDetails = [
    { label: 'Task', value: `${simpleTaskInstance?.taskName}` },
    { label: 'Description', value: `${simpleTaskInstance?.taskDescription || ''}` },
    {
      label: 'Task Owner',
      value: `${simpleTaskInstance?.owner?.fullName || ''} (${simpleTaskInstance?.owner?.profileId || ''})`,
    },
    {
      label: 'Created On',
      value: `${simpleTaskInstance?.createdOn || ''}`,
    },
    { label: 'Created By', value: `${simpleTaskInstance?.createdBy?.fullName || ''}` },
    { label: 'Due By', value: `${simpleTaskInstance?.dueBy || ''}` },
    {
      label: 'Anchor Date',
      value: `${simpleTaskInstance?.dueMarkerDate || ''}`,
    },
    { label: 'Completion Status', value: `${simpleTaskInstance?.completionStatus || ''}` },
    { label: 'Action Status', value: `${simpleTaskInstance?.actionStatus || ''}` },
    { label: 'Amount', value: `${simpleTaskInstance?.amount || ''}` },
    { label: 'Completion Percentage', value: `${simpleTaskInstance?.completionPercentage} %` },
    { label: 'Comments', value: `${simpleTaskInstance?.comments || ''}` },
  ];

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item container xs={8} spacing={2}>
        {basicDetails?.map((item) => {
          return (
            <Grid item xs={6} key={item.label}>
              <Typography variant="body2">{item.label}</Typography>
              <Typography variant="subtitle2">{item.value}</Typography>
            </Grid>
          );
        })}
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      <Grid item container xs={8} spacing={2}>
        <Grid item xs={6}>
          <Typography variant="body2">Attachment:</Typography>
          {!!(isObject(simpleTaskInstance?.attachment) && !isEmpty(simpleTaskInstance?.attachment)) && (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button
                variant="text"
                color="secondary"
                onClick={() => {
                  downloadDocument(simpleTaskInstance?.attachment);
                }}
              >
                {simpleTaskInstance?.attachment?.fileName}
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

ViewExitTaskDetails.propTypes = {
  task: PropTypes.shape({
    payheadDetails: PropTypes.arrayOf(PropTypes.node),
    ledgerDetails: PropTypes.arrayOf(PropTypes.node),
    otherDetails: PropTypes.node,
  }),
};

ViewExitTaskDetails.defaultProps = {
  task: {
    payheadDetails: [],
    ledgerDetails: [],
    otherDetails: {
      data: [],
    },
  },
};
export default ViewExitTaskDetails;
