import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, useTheme } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import Button from 'components/Atoms/Button';
import Download from '@mui/icons-material/VerticalAlignBottomOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CommonList from 'components/Atoms/List/CommonList';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { onBoardingStyle } from '../../style';

const FinalizeOnboarding = ({ handleFinalizeDownload, handleCancel, handleFinalizeAttachment, handleSubmit }) => {
  const theme = useTheme();

  return (
    <Box component="form" px={3}>
      <Typographys variant="h6" color="neutral.800">
        Joining Kit
      </Typographys>

      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 59rem)' }}>
          <Box mt={2} mb={3} sx={{ overflowX: 'hidden' }}>
            <CommonList
              sx={onBoardingStyle.listWrapper}
              listColor="neutral.600"
              lists={[
                `Once you have completed all the previous steps, please download,
             print and sign the Joining Kits using the listed provided below.`,
                `Once done, please upload the Joining Kit using the upload option in this tab and submit your 
                 documents.`,
                `Once you have submitted all your documents, the same will be verified and approved,
             if found to be in order.`,
                `If any clarifications are needed with respect to the documents submitted, you will be notified
                 on email.`,
                `You will need to Initiate your onboarding again, make the necessary changes as needed and resubmit.`,
                `You can view the verification status of your documents in the ATTACHMENTS tab.
            Once all documents have been Approved, your Onboarding Documents Submission is deemed complete.`,
              ]}
            />
          </Box>

          <Button
            size="large"
            variant="text"
            startIcon={<Download />}
            onClick={handleFinalizeDownload}
            type="button"
            color="primary"
          >
            Download Joining Kit
          </Button>

          <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

          <Typographys variant="h6" color="neutral.800">
            Attach filled joining kit
          </Typographys>

          <Box sx={{ mt: 2 }}>
            <Button
              size="large"
              variant="text"
              startIcon={<AttachFileIcon />}
              onClick={handleFinalizeAttachment}
              type="button"
              color="secondary"
            >
              Attachments
            </Button>
          </Box>
        </Box>
      </PerfectScrollbar>

      <Box sx={onBoardingStyle.btnContainer}>
        <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

        <Box sx={onBoardingStyle.submitWrapper}>
          <Button size="large" color="info" variant="outlined" type="button" onClick={handleCancel}>
            Cancel
          </Button>

          <Button size="large" type="submit" isDisable={false} onClick={handleSubmit}>
            Save & Continue
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

FinalizeOnboarding.defaultProps = {
  handleFinalizeDownload: () => {},
  handleFinalizeAttachment: () => {},
  handleCancel: () => {},
  handleSubmit: () => {},
};

FinalizeOnboarding.propTypes = {
  handleFinalizeDownload: PropTypes.func,
  handleFinalizeAttachment: PropTypes.func,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default FinalizeOnboarding;
