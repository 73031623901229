import PropTypes from 'prop-types';
import React from 'react';
import MuiTableRow from '@mui/material/TableRow';

const TableRow = ({ children, hover, selected, rowBgColor }) => {
  return (
    <MuiTableRow hover={hover} selected={selected} sx={{ bgcolor: rowBgColor || 'common.white' }}>
      {children}
    </MuiTableRow>
  );
};

TableRow.propTypes = {
  hover: PropTypes.bool,
  selected: PropTypes.bool,
  children: PropTypes.node.isRequired,
  rowBgColor: PropTypes.string,
};

TableRow.defaultProps = {
  hover: false,
  selected: false,
  rowBgColor: '',
};
export default TableRow;
