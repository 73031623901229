import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Divider } from '@mui/material';
import TitleWithText from 'components/Atoms/TitleWithText';
import Typographys from 'components/Atoms/Typography';
import Button from 'components/Atoms/Button';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { onBoardingStyle } from '../../style';

const EducationHistory = ({ educationHistory, handleAttachment }) => {
  return (
    <>
      <Typographys variant="h5" color="neutral.700">
        Education/Certification History
      </Typographys>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 44rem)' }}>
          <Box mt={2}>
            {educationHistory.map((list, i) => (
              <>
                <Typographys variant="subtitle2" align="left" color="neutral.800">
                  Record {i + 1}
                </Typographys>
                <Grid container rowSpacing={1} columnSpacing={2}>
                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Institution Name" text={list.institutionName} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Certification Name" text={list.certificationName} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Start Date" text={list.startDate} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="End Date" text={list.endDate} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Specialization" text={list.specialization} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Registration Number" text={list.registrationNumber} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Reference Name" text={list.referenceName} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Reference Phone Number" text={list.referenceNumber} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Reference Email" text={list.referenceEmail} />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={12}>
                    <TitleWithText title="Comments" text={list.comment} />
                  </Grid>

                  <Grid item xs={12}>
                    <Typographys variant="h6" color="neutral.600">
                      Attachment
                    </Typographys>

                    <Button
                      variant="text"
                      color="secondary"
                      onClick={() => handleAttachment(list?.attachmentId, list?.attachment)}
                      startIcon={<AttachFileIcon />}
                    >
                      {list?.attachment || ''}
                    </Button>
                  </Grid>
                </Grid>
                {i + 1 !== educationHistory.length && <Divider sx={onBoardingStyle.divider} />}
              </>
            ))}
          </Box>
        </Box>
      </PerfectScrollbar>
    </>
  );
};

EducationHistory.defaultProps = {
  educationHistory: [],
  handleAttachment: () => {},
};

EducationHistory.propTypes = {
  educationHistory: PropTypes.arrayOf(
    PropTypes.shape({
      institutionName: PropTypes.string,
      certificationName: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      specialization: PropTypes.string,
      registrationNumber: PropTypes.string,
      referenceName: PropTypes.string,
      referencePhone: PropTypes.string,
      registrationEmail: PropTypes.string,
      attachments: PropTypes.string,
      comments: PropTypes.string,
    }),
  ),
  handleAttachment: PropTypes.func,
};

export default EducationHistory;
