import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Typography from 'components/Atoms/Typography';
import Button from 'components/Atoms/Button';
import EditIcon from '@mui/icons-material/Edit';
import { useReactToPrint } from 'react-to-print';
import { useSelector } from 'react-redux';
import { hasPermission } from 'utils/permissions';
import DropdownMenu from 'components/Molecules/DropdownMenu';
import { REPORT_STATUS_REJECTED, LOCK_STATUS_UNLOCKED } from 'utils/SystemConfigConstants';
import ExpenseListingTable from './ExpenseListingTable';
import AuditTabs from './AuditTabs';
import ReportExpenseDetails from './ReportExpenseDetails';

const ReportDetails = ({
  reportDetails,
  handleEdit,
  actionMenuList,
  amountData,
  rows,
  page,
  handleSelect,
  roleBasedMenu,
  columns,
  auditData,
  showActions,
  showActionDialog,
  isApprove,
  handleClose,
  handleCancel,
  handleSubmit,
  onChange,
  handleAttachmentDownload,
  supportingDocuments,
}) => {
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasEditPermission = hasPermission(authorities, [
    'ROLE_BASE_PERMISSION_EXPENSE_INDEX_UPDATE_EXPENSE_REPORT',
  ]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <Box ref={componentRef}>
      <Box padding="2rem 3rem" className="report-expenses-details">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
          <Box>
            <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
              Report title: {reportDetails.title}
            </Typography>
            <Typography variant="body2" sx={{ mt: 0.5 }}>
              {reportDetails.address}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 1 }} className="no-print">
            {!!showActions && (
              <Box sx={{ display: 'flex', justifyContent: 'end', gap: 1 }}>
                {!!userHasEditPermission &&
                  reportDetails.lockStatus?.toUpperCase() === LOCK_STATUS_UNLOCKED &&
                  reportDetails.reportStatus?.toUpperCase() !== REPORT_STATUS_REJECTED && (
                    <Button startIcon={<EditIcon />} onClick={handleEdit}>
                      Edit
                    </Button>
                  )}
                {!!actionMenuList?.length && (
                  <DropdownMenu btnLabel="Actions" size="medium" menuLists={actionMenuList} />
                )}
              </Box>
            )}
            <Button onClick={handlePrint} variant="outlined">
              Print Report
            </Button>
          </Box>
        </Box>
      </Box>
      <Box>
        <ReportExpenseDetails
          reportDetails={reportDetails}
          amountData={amountData}
          showActions={showActions}
          showActionDialog={showActionDialog}
          isApprove={isApprove}
          handleClose={handleClose}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          onChange={onChange}
        />
        <ExpenseListingTable
          rows={rows}
          page={page}
          handleSelect={handleSelect}
          roleBasedMenu={roleBasedMenu}
          columns={columns}
          handleAttachmentDownload={handleAttachmentDownload}
          supportingDocuments={supportingDocuments}
        />
        <Box sx={{ mt: 2 }}>
          <AuditTabs auditData={auditData} />
        </Box>
      </Box>
    </Box>
  );
};

ReportDetails.defaultProps = {
  reportDetails: {
    title: '',
    address: '',
    employeeName: '',
    employeeId: '',
    expensePeriodStartDate: '',
    expensePeriodEndDate: '',
    department: '',
    designation: '',
    reportingManager: '',
    location: '',
    businessPurpose: '',
    reportId: '',
    reportStatus: '',
    lockStatus: '',
    settlementStatus: '',
  },
  handleEdit: () => {},
  actionMenuList: [],
  amountData: [],
  roleBasedMenu: () => {},
  rows: [],
  columns: [],
  handleSelect: () => {},
  page: '',
  auditData: [],
  showActions: true,
  showActionDialog: false,
  isApprove: true,
  handleClose: () => {},
  handleCancel: () => {},
  handleSubmit: () => {},
  onChange: () => {},
  handleAttachmentDownload: () => {},
  supportingDocuments: [],
};

ReportDetails.propTypes = {
  reportDetails: PropTypes.shape({
    title: PropTypes.string,
    address: PropTypes.string,
    employeeName: PropTypes.string,
    employeeId: PropTypes.string,
    expensePeriodStartDate: PropTypes.string,
    expensePeriodEndDate: PropTypes.string,
    department: PropTypes.string,
    designation: PropTypes.string,
    reportingManager: PropTypes.string,
    location: PropTypes.string,
    businessPurpose: PropTypes.string,
    reportId: PropTypes.string,
    reportStatus: PropTypes.string,
    settlementStatus: PropTypes.string,
    lockStatus: PropTypes.string,
  }),
  handleEdit: PropTypes.func,
  actionMenuList: PropTypes.arrayOf,
  amountData: PropTypes.arrayOf,
  roleBasedMenu: PropTypes.func,
  handleSelect: PropTypes.func,
  page: PropTypes.number,
  rows: PropTypes.arrayOf,
  columns: PropTypes.arrayOf,
  auditData: PropTypes.shape(),
  showActions: PropTypes.bool,
  showActionDialog: PropTypes.bool,
  isApprove: PropTypes.bool,
  handleClose: PropTypes.func,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  onChange: PropTypes.func,
  handleAttachmentDownload: PropTypes.func,
  supportingDocuments: PropTypes.arrayOf,
};

export default ReportDetails;
