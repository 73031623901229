const columns = [
  {
    label: 'Sl No',
    formatValue: ({ row }) => row?.index,
  },
  {
    label: 'Category',
    formatValue: ({ row }) => row?.leaveCategory?.displayName,
  },
  {
    label: 'Opening Balance',
    formatValue: ({ row }) => row?.periodOpeningBalance.toFixed(2),
  },
  {
    label: 'Accrued',
    formatValue: ({ row }) => row?.leavesAccrued,
  },
  {
    label: 'Availed',
    formatValue: ({ row }) => row?.leavesAvailed,
  },
  {
    label: 'Pending Approval',
    formatValue: ({ row }) => row?.pendingApprovalBalance?.toFixed(2),
  },
  {
    label: 'Remaining Balance',
    formatValue: ({ row }) => row?.currentBalance?.toFixed(2),
  },
];

const useColumns = () => {
  return [columns];
};

export default useColumns;
