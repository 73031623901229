import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Divider, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import InputField from 'components/Atoms/InputField';
import Typographys from 'components/Atoms/Typography';
import Dropdown from 'components/Molecules/Dropdown';
import DatePicker from 'components/Atoms/Datepicker';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Button from 'components/Atoms/Button';
import { FieldArray, getIn, Formik } from 'formik';
import PerfectScrollbar from 'react-perfect-scrollbar';
import * as yup from 'yup';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { genericMobileRegex, genericEmailRegex } from 'utils/commonUtils';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';

import { onBoardingStyle } from '../../style';

const EditDependent = ({ handleSubmit, relationOptions, genderOptions, handleCancel, propData, label }) => {
  const defaultObj = {
    gender: '',
    firstName: '',
    lastName: '',
    relation: '',
    dateOfBirth: '',
    mobileNumber: '',
    emailId: '',
    epfPerc: '',
    epsPerc: '',
    insurancePerc: '',
    otherPerc: '',
    gratuityPerc: '',
    emergencyContact: '',
  };
  const defaultValues = propData.allTabsData[label];

  const percentageValidation = yup
    .number()
    .min(0, 'Value must be greater than or equal to 0')
    .max(100, 'Value cannot be more than 100');

  const dependentValidationSchema = yup.object().shape({
    dependent: yup.array().of(
      yup.object().shape({
        firstName: yup.string().required('Required'),
        lastName: yup.string().required('Required'),
        gender: yup.string().required('Required'),
        relation: yup.string().required('Required'),
        dateOfBirth: yup.string().required('Required'),
        emailId: yup.string().matches(genericEmailRegex, 'Invalid format, Please enter valid email ID.'),
        mobileNumber: yup.string().matches(genericMobileRegex, 'Invalid format, Please enter valid mobile number.'),
        epfPerc: percentageValidation,
        epsPerc: percentageValidation,
        insurancePerc: percentageValidation,
        otherPerc: percentageValidation,
        gratuityPerc: percentageValidation,
      }),
    ),
  });
  return (
    <Box mt={1.5} px={3}>
      <Formik
        initialValues={
          defaultValues.dependent.length > 0 && Object.keys(defaultValues.dependent[0]).length
            ? defaultValues
            : { dependent: [{ key: 1 }] }
        }
        onSubmit={handleSubmit}
        validationSchema={dependentValidationSchema}
        enableReinitialize
      >
        {(formik) => (
          <Form
            formik={formik}
            handleCancel={handleCancel}
            relationOptions={relationOptions}
            genderOptions={genderOptions}
            propData={propData}
            label={label}
            defaultObj={defaultObj}
          />
        )}
      </Formik>
    </Box>
  );
};

const Form = ({ formik, handleCancel, propData, label, defaultObj, relationOptions, genderOptions }) => {
  const [keyCount, setKeyCount] = useState(2);
  useEffect(() => {
    propData.updateFormData(formik.values, label);
  }, [formik.values, label]);

  const handleDateChange = (date, setFieldValue, fieldName) => {
    setFieldValue(fieldName, date);
  };

  const theme = useTheme();

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Box sx={{ mb: 2 }}>
        <Typographys variant="h5" color="neutral.800">
          Dependent Records
        </Typographys>
      </Box>

      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 59rem)' }}>
          <FieldArray name="dependent">
            {({ push, remove }) => (
              <Box>
                {formik?.values?.dependent.map((d, index) => {
                  return (
                    <Accordion key={d.key} disableGutters elevation={0} square sx={onBoardingStyle.accordionWrapper}>
                      <AccordionSummary
                        aria-controls="visa-content"
                        id="visarecord-header"
                        expandIcon={<KeyboardArrowDownSharpIcon sx={{ fontSize: '3rem', color: 'neutral.800' }} />}
                      >
                        <Box sx={onBoardingStyle.deleteContainer}>
                          <Typography variant="subtitle2" color="neutral.800">
                            Record {index + 1}
                          </Typography>

                          <DeleteOutlineSharpIcon sx={{ color: 'neutral.600' }} onClick={() => remove(index)} />
                        </Box>
                      </AccordionSummary>

                      <AccordionDetails>
                        <Grid container rowSpacing={2} columnSpacing={9} sx={onBoardingStyle.formContainer}>
                          <Grid item xs={12} md={4}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `dependent[${index}].firstName`) &&
                                  getIn(formik.errors, `dependent[${index}].firstName`),
                              )}
                              helperText={
                                getIn(formik.touched, `dependent[${index}].firstName`) &&
                                getIn(formik.errors, `dependent[${index}].firstName`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={d.firstName}
                              name={`dependent[${index}].firstName`}
                              id={`dependent[${index}].firstName`}
                              label="First Name*"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `dependent[${index}].lastName`) &&
                                  getIn(formik.errors, `dependent[${index}].lastName`),
                              )}
                              helperText={
                                getIn(formik.touched, `dependent[${index}].lastName`) &&
                                getIn(formik.errors, `dependent[${index}].lastName`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={d.lastName}
                              name={`dependent[${index}].lastName`}
                              id={`dependent[${index}].lastName`}
                              label="Last Name*"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <Dropdown
                              error={Boolean(
                                getIn(formik.touched, `dependent[${index}].gender`) &&
                                  getIn(formik.errors, `dependent[${index}].gender`),
                              )}
                              helperText={
                                getIn(formik.touched, `dependent[${index}].gender`) &&
                                getIn(formik.errors, `dependent[${index}].gender`)
                              }
                              handleChange={(e) => {
                                formik.handleChange(e);
                              }}
                              onBlur={formik.handleBlur}
                              value={d.gender}
                              name={`dependent[${index}].gender`}
                              id={`dependent[${index}].gender`}
                              label="Gender*"
                              options={genderOptions}
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <Dropdown
                              error={Boolean(
                                getIn(formik.touched, `dependent[${index}].relation`) &&
                                  getIn(formik.errors, `dependent[${index}].relation`),
                              )}
                              helperText={
                                getIn(formik.touched, `dependent[${index}].relation`) &&
                                getIn(formik.errors, `dependent[${index}].relation`)
                              }
                              handleChange={(e) => {
                                formik.handleChange(e);
                              }}
                              onBlur={formik.handleBlur}
                              value={d.relation}
                              name={`dependent[${index}].relation`}
                              id={`dependent[${index}].relation`}
                              label="Relation*"
                              options={relationOptions}
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <DatePicker
                              error={
                                Boolean(
                                  getIn(formik.touched, `dependent[${index}].dateOfBirth`) &&
                                    getIn(formik.errors, `dependent[${index}].dateOfBirth`),
                                ) ||
                                (getIn(formik.touched, `dependent[${index}].dateOfBirth`) && !d.dateOfBirth)
                              }
                              helperText={
                                getIn(formik.touched, `dependent[${index}].dateOfBirth`) &&
                                getIn(formik.errors, `dependent[${index}].dateOfBirth`)
                              }
                              onChange={(data) =>
                                handleDateChange(data, formik.setFieldValue, `dependent[${index}].dateOfBirth`)
                              }
                              onBlur={formik.handleBlur}
                              value={d?.dateOfBirth}
                              name={`dependent[${index}].dateOfBirth`}
                              id={`dependent[${index}].dateOfBirth`}
                              label="Date Of Birth*"
                              fullWidth
                            />
                          </Grid>
                        </Grid>

                        <Typographys variant="h6" color="neutral.800">
                          Contact Details
                        </Typographys>

                        <Grid container rowSpacing={2} columnSpacing={9} sx={onBoardingStyle.formContainer}>
                          <Grid item xs={12} md={4}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `dependent[${index}].mobileNumber`) &&
                                  getIn(formik.errors, `dependent[${index}].mobileNumber`),
                              )}
                              helperText={
                                getIn(formik.touched, `dependent[${index}].mobileNumber`) &&
                                getIn(formik.errors, `dependent[${index}].mobileNumber`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={d.mobileNumber}
                              name={`dependent[${index}].mobileNumber`}
                              id={`dependent[${index}].mobileNumber`}
                              label="Mobile Number"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `dependent[${index}].emailId`) &&
                                  getIn(formik.errors, `dependent[${index}].emailId`),
                              )}
                              helperText={
                                getIn(formik.touched, `dependent[${index}].emailId`) &&
                                getIn(formik.errors, `dependent[${index}].emailId`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={d.emailId}
                              name={`dependent[${index}].emailId`}
                              id={`dependent[${index}].emailId`}
                              label="Email ID"
                              fullWidth
                            />
                          </Grid>
                        </Grid>

                        <Typographys variant="h6" color="neutral.800">
                          Nomination Details
                        </Typographys>

                        <Grid container rowSpacing={2} columnSpacing={9} sx={onBoardingStyle.formContainer}>
                          <Grid item xs={12} md={4}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `dependent[${index}].epfPerc`) &&
                                  getIn(formik.errors, `dependent[${index}].epfPerc`),
                              )}
                              helperText={
                                getIn(formik.touched, `dependent[${index}].epfPerc`) &&
                                getIn(formik.errors, `dependent[${index}].epfPerc`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={d.epfPerc}
                              name={`dependent[${index}].epfPerc`}
                              id={`dependent[${index}].epfPerc`}
                              label="EPF Nomination %"
                              type="number"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `dependent[${index}].epsPerc`) &&
                                  getIn(formik.errors, `dependent[${index}].epsPerc`),
                              )}
                              helperText={
                                getIn(formik.touched, `dependent[${index}].epsPerc`) &&
                                getIn(formik.errors, `dependent[${index}].epsPerc`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={d.epsPerc}
                              name={`dependent[${index}].epsPerc`}
                              id={`dependent[${index}].epsPerc`}
                              label="EPS Nomination %"
                              type="number"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `dependent[${index}].insurancePerc`) &&
                                  getIn(formik.errors, `dependent[${index}].insurancePerc`),
                              )}
                              helperText={
                                getIn(formik.touched, `dependent[${index}].insurancePerc`) &&
                                getIn(formik.errors, `dependent[${index}].insurancePerc`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={d.insurancePerc}
                              name={`dependent[${index}].insurancePerc`}
                              id={`dependent[${index}].insurancePerc`}
                              label="Insurance Nomination %"
                              type="number"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `dependent[${index}].otherPerc`) &&
                                  getIn(formik.errors, `dependent[${index}].otherPerc`),
                              )}
                              helperText={
                                getIn(formik.touched, `dependent[${index}].otherPerc`) &&
                                getIn(formik.errors, `dependent[${index}].otherPerc`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={d.otherPerc}
                              name={`dependent[${index}].otherPerc`}
                              id={`dependent[${index}].otherPerc`}
                              label="Others Nomination %"
                              type="number"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `dependent[${index}].gratuityPerc`) &&
                                  getIn(formik.errors, `dependent[${index}].gratuityPerc`),
                              )}
                              helperText={
                                getIn(formik.touched, `dependent[${index}].gratuityPerc`) &&
                                getIn(formik.errors, `dependent[${index}].gratuityPerc`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={d.gratuityPerc}
                              name={`dependent[${index}].gratuityPerc`}
                              id={`dependent[${index}].gratuityPerc`}
                              label="Gratuity  Nomination %"
                              type="number"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `dependent[${index}].emergencyContact`) &&
                                  getIn(formik.errors, `dependent[${index}].emergencyContact`),
                              )}
                              helperText={
                                getIn(formik.touched, `dependent[${index}].emergencyContact`) &&
                                getIn(formik.errors, `dependent[${index}].emergencyContact`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={d.emergencyContact}
                              name={`dependent[${index}].emergencyContact`}
                              id={`dependent[${index}].emergencyContact`}
                              label="Emergency Contact Reference"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
                <Box sx={{ mt: 3 }}>
                  <Button
                    size="medium"
                    variant="outlined"
                    type="button"
                    startIcon={<AddOutlinedIcon />}
                    onClick={() => {
                      push({ ...defaultObj, key: keyCount });
                      setKeyCount(keyCount + 1);
                    }}
                  >
                    Add Another
                  </Button>
                </Box>
              </Box>
            )}
          </FieldArray>
        </Box>
      </PerfectScrollbar>

      <Box sx={onBoardingStyle.btnContainer}>
        <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

        <Box sx={onBoardingStyle.submitWrapper}>
          <Button
            size="large"
            color="info"
            variant="outlined"
            type="button"
            onClick={() => {
              formik.resetForm();
              handleCancel();
            }}
          >
            Cancel
          </Button>

          <LoadingButton loading={propData.loading} color="primary" size="large" type="submit" variant="contained">
            Save & Continue
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

Form.defaultProps = {
  handleCancel: () => {},
  relationOptions: [],
  genderOptions: [],
  label: '',
  propData: {},
  formik: {},
  defaultObj: {},
};

Form.propTypes = {
  propData: PropTypes.shape({
    loading: PropTypes.bool,
    updateFormData: PropTypes.func,
    allTabsData: PropTypes.shape({
      DEPENDENT: PropTypes.shape({
        dependent: PropTypes.shape([]),
      }),
    }),
    allowEdit: PropTypes.bool,
  }),
  handleCancel: PropTypes.func,
  relationOptions: PropTypes.shape([]),

  genderOptions: PropTypes.shape([]),

  label: PropTypes.string,
  formik: PropTypes.shape({
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    values: PropTypes.shape({
      dependent: PropTypes.shape([]),
    }),
    resetForm: PropTypes.func,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    setFieldValue: PropTypes.func,
  }),
  defaultObj: PropTypes.shape({}),
};

EditDependent.defaultProps = {
  relationOptions: [],
  genderOptions: [],
  handleSubmit: () => {},
  handleCancel: () => {},
  propData: {
    allTabsData: {
      DEPENDENT: {
        dependent: [
          {
            gender: '',
            firstName: '',
            lastName: '',
            relation: '',
            dateOfBirth: '',
            mobileNumber: '',
            emailId: '',
            epfPerc: '',
            epsPerc: '',
            insurancePerc: '',
            otherPerc: '',
            gratuityPerc: '',
            emergencyContact: '',
          },
        ],
      },
    },
    updateFormData: () => {},
  },
  label: 'DEPENDENT',
};

EditDependent.propTypes = {
  relationOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  genderOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  propData: PropTypes.shape({
    allTabsData: PropTypes.shape({}),
    updateFormData: PropTypes.func,
  }),
  label: PropTypes.string,
};

export default EditDependent;
