import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import EmployeeDetailCard from 'components/Organisms/Employee/EmployeeRecord/ActionList/EmployeeDetailCard';
import NewEmployeeDetailForm from 'components/Organisms/Employee/EmployeeRecord/ActionList/NewEmployeeDetailForm';

const PromoteEmployee = ({
  employeeData,
  supervisorOptions,
  departmentOptions,
  designationOptions,
  locationOptions,
  userData,
  handleSubmit,
  handleCancel,
  loading,
  btnLabel,
}) => {
  return (
    <>
      <Box mb={2}>
        <Typographys color="neutral.800" variant="body1">
          ** Please specify the new supervisor, department, designation and location for the employee. In case there are
          no changes to any of these parameters, you can leave the default selection as is so that the changes arent
          reflected in the master. Additionally, you can also include any notes below.
        </Typographys>
      </Box>

      <EmployeeDetailCard employeeData={employeeData} />
      <Box mt={2}>
        <NewEmployeeDetailForm
          btnLabel={btnLabel}
          supervisorOptions={supervisorOptions}
          departmentOptions={departmentOptions}
          designationOptions={designationOptions}
          locationOptions={locationOptions}
          userData={userData}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          loading={loading}
        />
      </Box>
    </>
  );
};

PromoteEmployee.defaultProps = {
  employeeData: {},
  supervisorOptions: [],
  departmentOptions: [],
  designationOptions: [],
  locationOptions: [],
  handleSubmit: () => {},
  handleCancel: () => {},
  userData: {},
  loading: false,
  btnLabel: '',
};

PromoteEmployee.propTypes = {
  employeeData: PropTypes.shape(),
  supervisorOptions: PropTypes.arrayOf,
  departmentOptions: PropTypes.arrayOf,
  designationOptions: PropTypes.arrayOf,
  locationOptions: PropTypes.arrayOf,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  userData: PropTypes.shape(),
  loading: PropTypes.bool,
  btnLabel: PropTypes.string,
};

export default PromoteEmployee;
