import PropTypes from 'prop-types';
import React from 'react';
import Dialogs from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Button from 'components/Atoms/Button';
import Typography from 'components/Atoms/Typography';
import CloseIcon from '@mui/icons-material/Close';

const Dialog = ({
  isHeader,
  sx,
  maxWidth,
  dialogTitle,
  dialogDesc,
  secondaryBtn,
  primaryBtn,
  primaryBtnColor,
  bodyContent,
  handlePrimaryBtn,
  handleSecondaryBtn,
  open,
  handleClose,
  isClose,
  alignmentActionBtn,
  isPrimaryBtnDisable,
  alignTitle,
  bodyContentSx,
  descSx,
}) => {
  return (
    <Dialogs open={open} onClose={handleClose} fullWidth maxWidth={maxWidth} sx={sx}>
      {isHeader && (
        <DialogTitle component="div">
          <Box sx={isClose ? { display: 'flex', justifyContent: 'space-between' } : {}}>
            <Typography variant="h4" align={alignTitle}>
              {dialogTitle}
            </Typography>
            {isClose && (
              <Button size="medium" variant="text" color="info" onClick={handleClose}>
                <CloseIcon />
              </Button>
            )}
          </Box>
          {dialogDesc && (
            <DialogContentText component="div" sx={{ marginTop: '1rem', ...descSx }}>
              {dialogDesc}
            </DialogContentText>
          )}
        </DialogTitle>
      )}

      {bodyContent && (
        <DialogContent>
          <Box display="flex" sx={{ justifyContent: 'center', ...bodyContentSx }}>
            {bodyContent}
          </Box>
        </DialogContent>
      )}

      <DialogActions sx={{ justifyContent: alignmentActionBtn }}>
        {secondaryBtn && (
          <Button size="medium" variant="outlined" color="info" onClick={handleSecondaryBtn}>
            {secondaryBtn}
          </Button>
        )}
        {primaryBtn && (
          <Button
            size="medium"
            variant="contained"
            color={primaryBtnColor}
            onClick={handlePrimaryBtn}
            isDisable={isPrimaryBtnDisable}
          >
            {primaryBtn}
          </Button>
        )}
      </DialogActions>
    </Dialogs>
  );
};

Dialog.defaultProps = {
  isHeader: true,
  sx: {},
  maxWidth: 'xs',
  alignTitle: 'center',
  dialogTitle: '',
  dialogDesc: '',
  secondaryBtn: '',
  primaryBtn: '',
  primaryBtnColor: 'primary',
  bodyContent: null,
  handleSecondaryBtn: () => {},
  handlePrimaryBtn: () => {},
  open: true,
  handleClose: () => {},
  isClose: false,
  alignmentActionBtn: 'center',
  isPrimaryBtnDisable: false,
  bodyContentSx: {},
  descSx: {},
};

Dialog.propTypes = {
  isHeader: PropTypes.bool,
  sx: PropTypes.shape(),
  maxWidth: PropTypes.string,
  alignTitle: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  isClose: PropTypes.bool,
  dialogTitle: PropTypes.string,
  alignmentActionBtn: PropTypes.string,
  dialogDesc: PropTypes.node,
  secondaryBtn: PropTypes.string,
  primaryBtn: PropTypes.string,
  primaryBtnColor: PropTypes.string,
  handlePrimaryBtn: PropTypes.func,
  handleSecondaryBtn: PropTypes.func,
  bodyContent: PropTypes.node,
  isPrimaryBtnDisable: PropTypes.bool,
  bodyContentSx: PropTypes.shape({}),
  descSx: PropTypes.shape({}),
};

export default Dialog;
