import dayjs from 'dayjs';
import { isObject, isEmpty } from 'lodash';

const attendanceGridDataColumn = [
  {
    fieldName: `day`,
    label: 'Day',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: `firstTimeIn`,
    label: 'First Time In',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: `lastTimeOut`,
    label: 'Last Time Out',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: `duration`,
    label: 'Duration',
    formatValue: ({ row, column }) => row[column.fieldName],
  },

  {
    fieldName: `interimPunches`,
    label: 'Interim Punches',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: `attendance`,
    label: 'attendance',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: `exception`,
    label: 'exception',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: `attendanceMarking`,
    label: 'Attendance Marking',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
];

const columns = [
  {
    fieldName: `empName`,
    label: 'Name',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: `period`,
    label: 'Period',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
];

const dateFormat = (val) => (val ? dayjs(val).format('DD MMMM YYYY') : '-');

const useColumns = (doyDatesMap) => {
  const doyDates =
    isObject(doyDatesMap) && !isEmpty(doyDatesMap)
      ? Object.entries(doyDatesMap).map((key, index) => {
          return {
            fieldName: `day${index + 1}`,
            label: dateFormat(key[1]),
            formatValue: ({ row, column }) => row[column.fieldName],
          };
        })
      : [];
  const output = [...columns, ...doyDates];
  return output;
};

export { useColumns, attendanceGridDataColumn };
