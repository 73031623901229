export const dashboardLayoutStyle = {
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: '2rem',
    mb: 1.5,
  },
  wrapper: {
    borderColor: 'neutral.300',
    marginTop: '1.5rem',
    maxHeight: 'calc(100vh - 23.5rem)',
    height: 'calc(100vh - 23.5rem)',
    marginBottom: '3rem',
    width: '100%',
    overflowY: 'auto',
    position: 'relative',
  },
};
