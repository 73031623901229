import React, { useEffect, useState } from 'react';
import AddAndEditDocument from 'components/Templates/Documents/AddAndEdit';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import useToast from 'components/Provider/useToast';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import get from 'lodash/get';
import { formDataApi } from 'api/api';
import { fetchCreateDocument, fetchEditDocumentDetails } from 'store';
import { useThunk } from 'hooks/useThunk';
import { isEmpty, isObject } from 'lodash';
import DeleteDocument from 'components/Organisms/Dialog/ActionList/DeleteDocument';
import useMyDocumentService from '../List/useMyDocumentService';

const AddEditPage = () => {
  const addToast = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { user = {} } = useSelector((state) => state?.session);
  const location = useLocation();
  const { id } = useParams();
  const services = useMyDocumentService();

  const statusTypeOptions = [
    { id: 'ACTIVE', name: 'Active' },
    { id: 'INACTIVE', name: 'Inactive' },
  ];
  const defaultValues = {};

  const isEditMode = location.pathname.includes('edit') && id;

  const breadcrumbData = [
    { id: 1, name: 'My Documents', path: '/home/documents', isActive: false },
    { id: 2, name: isEditMode ? 'Edit' : 'Add', path: '', isActive: true },
  ];

  const [getCreateDocument, isLoadingCreateDocument, loadingCreateDocumentError] = useThunk(fetchCreateDocument);
  const [getEditDocumentDetails, isLoadingEditDocumentDetails, loadingEditDocumentDetailsError] =
    useThunk(fetchEditDocumentDetails);

  useEffect(() => {
    const bodyFormData = new FormData();
    bodyFormData.append('excludeVariables', 'employeesList,departmentsList');
    async function getCreateDocumentData() {
      await getCreateDocument(bodyFormData);
    }
    if (!isEditMode) {
      getCreateDocumentData();
    }
  }, [isEditMode, getCreateDocument]);

  const {
    editDocumentDetails = [],
    employeesList = {},
    projectsList = [],
    departmentsList = [],
    customerVendorList = [],
    editDocumentTypes = [],
    documentTypes = [],
    employeeGroupsList = [],
    editDocumentOwnersList = {},
    editDocumentOwnerTypes = {},
    editDocumentEmployeeDBID = null,
    documentOwnerTypes = {},
  } = useSelector((state) => state.documents);

  let documentOwnerTypeOptions = [];
  const ownerTypes = isEditMode ? editDocumentOwnerTypes : documentOwnerTypes;

  documentOwnerTypeOptions =
    isObject(ownerTypes) && !isEmpty(ownerTypes)
      ? Object.entries(ownerTypes).map((item) => ({
          id: item[1],
          name: item[1],
        }))
      : [];

  const handleOwnerOptions = async (docOwnerType) => {
    await (() => {
      if (docOwnerType === 'Employee') {
        return employeesList;
      }
      if (docOwnerType === 'Employee Group') {
        return employeeGroupsList;
      }
      if (docOwnerType === 'Department') {
        return departmentsList;
      }
      if (docOwnerType === 'Project') {
        return projectsList;
      }
      if (docOwnerType === 'Customer/Vendor') {
        return customerVendorList;
      }
      return [];
    });
  };

  const ownersList = isEditMode ? editDocumentOwnersList : employeesList;

  const ownerOptions =
    isObject(ownersList) && !isEmpty(ownersList)
      ? Object.values(ownersList).map((employee) => {
          return {
            name: employee.fullName,
            id: employee.empID,
          };
        })
      : [];

  useEffect(() => {
    if (isEditMode) {
      const { docOwnerType } = editDocumentDetails;
      const bodyFormData = new FormData();
      bodyFormData.append('id', id);
      getEditDocumentDetails(bodyFormData);
      handleOwnerOptions(docOwnerType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode, id, getEditDocumentDetails]);

  const filteredEmployee =
    isEditMode &&
    Object.keys(editDocumentDetails).length > 0 &&
    isObject(editDocumentOwnersList) &&
    !isEmpty(editDocumentOwnersList)
      ? Object.values(editDocumentOwnersList).filter((emp) => {
          return emp.id === editDocumentEmployeeDBID;
        })
      : [];

  if (isEditMode && Object.keys(editDocumentDetails).length > 0) {
    defaultValues.documentName = editDocumentDetails?.documentName;
    defaultValues.documentType = editDocumentDetails?.documentType;
    defaultValues.status = editDocumentDetails?.status;
    defaultValues.description = editDocumentDetails?.documentDescription;
    defaultValues.docVersion = editDocumentDetails?.docVersion;
    defaultValues.documentOwnerType = editDocumentDetails?.docOwnerType;
    defaultValues.isCurrentVersion = editDocumentDetails?.isCurrentVersion;
    defaultValues.owner = filteredEmployee[0].fullName;
  } else {
    defaultValues.docVersion = '1.0';
    defaultValues.fileName = '';
    defaultValues.documentName = '';
    defaultValues.documentType = '';
    defaultValues.status = '';
    defaultValues.description = '';
    defaultValues.documentOwnerType = 'Employee';
    defaultValues.owner = '';
    defaultValues.isCurrentVersion = '';
    defaultValues.attachments = '';
  }
  const handleSubmit = async (values) => {
    let response = {};
    const bodyFormData = new FormData();
    const isCurrentVersion = !isEmpty(values.isCurrentVersion) ? 'on' : '';

    const { documentOwnerType, owner } = values;

    bodyFormData.append('documentName', get(values, 'documentName', ''));
    bodyFormData.append('documentType', get(values, 'documentType', ''));
    bodyFormData.append('status', get(values, 'status', ''));
    bodyFormData.append('documentDescription', get(values, 'description', ''));
    bodyFormData.append('docVersion', get(values, 'docVersion', ''));
    bodyFormData.append('docOwnerType', get(values, 'documentOwnerType', ''));
    bodyFormData.append('ownerEmployee', user?.empDBID || '');
    bodyFormData.append('ownerEmployeeGroup', get(values, '', documentOwnerType === 'Employee Group') ? owner : '');
    bodyFormData.append('ownerDepartment', get(values, '', documentOwnerType === 'Department') ? owner : '');
    bodyFormData.append('ownerProject', get(values, '', documentOwnerType === 'Project') ? owner : '');
    bodyFormData.append('ownerCustomerVendor', get(values, '', documentOwnerType === 'Customer/Vendor') ? owner : '');
    bodyFormData.append('_isCurrentVersion', '');

    if (isCurrentVersion) {
      bodyFormData.append('isCurrentVersion', isCurrentVersion);
    }
    if (get(values, 'attachments', '')) bodyFormData.append('attachment', get(values, 'attachments', ''));
    try {
      setLoading(true);
      if (isEditMode) {
        bodyFormData.append('id', id);
        bodyFormData.append('version', editDocumentDetails?.version);
        response = await formDataApi.put('restIndex/updateDocument', bodyFormData);
      } else {
        response = await formDataApi.post('restIndex/saveDocument', bodyFormData);
      }

      const results = response?.data;
      if (results?.status !== 'ERROR') {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: results?.message || `Document ${isEditMode ? 'updated' : 'added'} successfully`,
        });
        const documentID = isEditMode ? id : results?.objID;
        navigate(`/home/documents/view/${documentID}`);
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to ${isEditMode ? 'update' : 'add new'} document`,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = () => {
    if (isEditMode) {
      services.handleDeleteDialog();
    }
  };

  const handleCancel = () => {
    navigate('/home/documents');
  };

  if (loadingCreateDocumentError !== null || (isEditMode && loadingEditDocumentDetailsError)) {
    return (
      <Typography variant="h6">
        {loadingCreateDocumentError?.message ||
          loadingEditDocumentDetailsError?.message ||
          'Error while loading document details.'}
      </Typography>
    );
  }

  return (
    <Box>
      <AddAndEditDocument
        documentOwnerEditAllowed={false}
        formLoading={isEditMode ? isLoadingEditDocumentDetails : isLoadingCreateDocument}
        loading={loading}
        breadcrumbData={breadcrumbData}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        defaultValues={defaultValues}
        statusTypeOptions={statusTypeOptions}
        documentTypeOptions={isEditMode ? editDocumentTypes : documentTypes}
        documentOwnerTypeOptions={documentOwnerTypeOptions}
        isEdit={isEditMode}
        fileName={isEditMode ? editDocumentDetails?.documentName : ''}
        handleDelete={handleDelete}
        ownerOptions={ownerOptions}
        handleOwnerOptions={handleOwnerOptions}
        entityOriginPermission={isEditMode ? editDocumentDetails?.entityOrigin : ''}
      />
      {isEditMode && editDocumentDetails !== null && (
        <DeleteDocument
          open={services.showDeleteDialog}
          handleClose={services.handleDeleteDialog}
          handleCancel={services.handleDeleteDialog}
          handleDelete={() => {
            services.deleteDocument(editDocumentDetails);
          }}
          fileName={editDocumentDetails?.fileName}
        />
      )}
    </Box>
  );
};
export default AddEditPage;
