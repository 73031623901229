import React from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import PropTypes from 'prop-types';
import EditLifeCycleRecord from 'components/Organisms/Employee/LifeCycleRecord/Edit';

const LCRecordTemplate = ({
  breadcrumbData,
  pageHeading,
  supervisorList,
  handleSubmit,
  handleCancel,
  departmentList,
  designationList,
  locationList,
  employeeLevelList,
  employeeGradeList,
  divisionList,
  recordDetails,
  changeType,
  loading,
}) => {
  return (
    <BlankTemplate pageHeading={pageHeading} isBreadcrumb breadcrumbData={breadcrumbData}>
      <EditLifeCycleRecord
        supervisorList={supervisorList}
        departmentList={departmentList}
        designationList={designationList}
        locationList={locationList}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        employeeLevelList={employeeLevelList}
        employeeGradeList={employeeGradeList}
        divisionList={divisionList}
        recordDetails={recordDetails}
        changeType={changeType}
        loading={loading}
      />
    </BlankTemplate>
  );
};

LCRecordTemplate.defaultProps = {
  pageHeading: 'Life Cycle Details: -',
  breadcrumbData: [
    { id: 1, name: 'Life Cycle Case Records', path: '/', isActive: false },
    { id: 2, name: 'Edit', isActive: true },
  ],
  recordDetails: {},
  supervisorList: [],
  departmentList: [],
  designationList: [],
  locationList: [],
  employeeLevelList: [],
  employeeGradeList: [],
  divisionList: [],
  handleSubmit: () => {},
  handleCancel: () => {},
  changeType: '',
  loading: false,
};

LCRecordTemplate.propTypes = {
  pageHeading: PropTypes.string,
  breadcrumbData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
  recordDetails: PropTypes.shape(),
  supervisorList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  departmentList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  designationList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  locationList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  employeeLevelList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  employeeGradeList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  divisionList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  changeType: PropTypes.string,
  loading: PropTypes.bool,
};

export default LCRecordTemplate;
