import {
  displayLeaveRequest,
  listMyLeaveRequests,
  listRequestPendingMyAction,
  displayMyLeaveAccounts,
  getModifyLeaveRequest,
  getLeavesCreateData,
} from 'api/leave';
import { asyncThunkWrapper } from 'utils/AsyncThunkWrapper';

const fetchMyLeaveList = asyncThunkWrapper('myLeaves/fetch', listMyLeaveRequests);
const fetchMyPendingLeaves = asyncThunkWrapper('myPendingLeaves/fetch', listRequestPendingMyAction);
const fetchMyLeaveAccounts = asyncThunkWrapper('myLeavesAccounts/fetch', displayMyLeaveAccounts);
const fetchMyLeaveDetails = asyncThunkWrapper('myLeaves/details', displayLeaveRequest);
const fetchModifyLeaveRequest = asyncThunkWrapper('myLeaves/modify', getModifyLeaveRequest);
const fetchLeavesCreateData = asyncThunkWrapper('myLeaves/createData', getLeavesCreateData);

export {
  fetchMyLeaveList,
  fetchMyPendingLeaves,
  fetchMyLeaveDetails,
  fetchMyLeaveAccounts,
  fetchModifyLeaveRequest,
  fetchLeavesCreateData,
};
