/* eslint-disable no-console */
import axios from 'axios';

// To be updated with actual URL later
// const API_URL = `http://65.108.247.166:8080`;
//export const API_URL = process.env.API_URL;
export const API_URL = `https://sandbox.hrentries.ansr.com`;

export const api = axios.create({
  baseURL: API_URL,
});

export const jsonApi = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const urlEncodedApi = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

export const formDataApi = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export const downloadApi = axios.create({
  baseURL: API_URL,
  responseType: 'blob',
  headers: {
    Accept: 'application/octet-stream',
  },
});

// Add a request interceptor to attach the Authorization header
const requestInterceptor = (config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

const responseInterceptor = (response) => {
  return response;
};

const errorInterceptor = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (error.response.status === 401) {
      if (!window.location.pathname.includes('/login')) {
        window.location.replace(`/login`);
      }
      localStorage.clear();
    }
    console.error(error.message || error.response);
  } else if (error.request) {
    // The request was made but no response was received
    console.error('Something went wrong');
  } else {
    // Something happened in setting up the request that triggered an Error
    console.error(error.message || error.response);
  }
  return Promise.reject(error);
};

[api, jsonApi, formDataApi, downloadApi, urlEncodedApi].forEach((apiFormats) => {
  apiFormats.interceptors.request.use(requestInterceptor);
  apiFormats.interceptors.response.use(responseInterceptor, errorInterceptor);
});
