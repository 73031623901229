import React from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import RecordDetails from 'components/Organisms/Employee/LifeCycleRecord/Details';

const LCRecordDetailsTemplate = ({
  pageHeading,
  breadcrumbData,
  recordDetails,
  newRecordDetails,
  auditDetails,
  handleEdit,
  menuList,
  employeeName,
  isEdit,
  creatorAttachment,
  approverAttachments,
  handleAttachmentDownload,
}) => {
  return (
    <BlankTemplate
      pageHeading={`${pageHeading}: ${employeeName || ''}`}
      isBreadcrumb
      breadcrumbData={breadcrumbData}
      padding="2rem 3rem"
    >
      <RecordDetails
        recordDetails={recordDetails}
        newRecordDetails={newRecordDetails}
        auditDetails={auditDetails}
        handleEdit={handleEdit}
        menuList={menuList}
        isEdit={isEdit}
        creatorAttachment={creatorAttachment}
        approverAttachments={approverAttachments}
        handleAttachmentDownload={handleAttachmentDownload}
      />
    </BlankTemplate>
  );
};

LCRecordDetailsTemplate.defaultProps = {
  pageHeading: 'Life Cycle Case Record Details',
  breadcrumbData: [
    { id: 1, name: 'Life Cycle Case Records', path: '/myteam/life-cycle/life-cycle-records', isActive: false },
    { id: 2, name: 'Details', path: '', isActive: true },
  ],
  recordDetails: {},
  newRecordDetails: {},
  auditDetails: [],
  handleEdit: () => {},
  menuList: [],
  employeeName: '',
  isEdit: false,
  creatorAttachment: {},
  approverAttachments: {},
  handleAttachmentDownload: () => {},
};

LCRecordDetailsTemplate.propTypes = {
  pageHeading: PropTypes.string,
  breadcrumbData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
  recordDetails: PropTypes.shape({
    changeType: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    date: PropTypes.string,
    supervisor: PropTypes.string,
    department: PropTypes.string,
    designation: PropTypes.string,
    location: PropTypes.string,
    employeeLevel: PropTypes.string,
    employeeGrade: PropTypes.string,
    division: PropTypes.string,
    fullName: PropTypes.string,
  }),
  newRecordDetails: PropTypes.shape({
    supervisor: PropTypes.string,
    department: PropTypes.string,
    designation: PropTypes.string,
    location: PropTypes.string,
    employeeLevel: PropTypes.string,
    employeeGrade: PropTypes.string,
    division: PropTypes.string,
    effectiveDate: PropTypes.string,
    effectiveEndDate: PropTypes.string,
    reasonOfTransfer: PropTypes.string,
  }),
  auditDetails: PropTypes.arrayOf([]),
  handleEdit: PropTypes.func,
  menuList: PropTypes.arrayOf([]),
  employeeName: PropTypes.string,
  isEdit: PropTypes.bool,
  creatorAttachment: PropTypes.shape(),
  approverAttachments: PropTypes.shape(),
  handleAttachmentDownload: PropTypes.func,
};

export default LCRecordDetailsTemplate;
