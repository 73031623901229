import PropTypes from 'prop-types';
import React from 'react';
import Dialog from 'components/Molecules/Dialog';
import Dropdown from 'components/Molecules/Dropdown';

const MapLifeCyclePolicy = ({
  handleComment,
  secondaryBtn,
  primaryBtn,
  primaryBtnColor,
  options,
  errorMessage,
  handleCancel,
  handleChange,
  error,
  userName,
  open,
  handleClose,
  value,
}) => {
  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      dialogTitle="Map Life Cycle Policy"
      dialogDesc={`Life Cycle for ${userName}`}
      secondaryBtn={secondaryBtn}
      primaryBtn={primaryBtn}
      handlePrimaryBtn={handleComment}
      handleSecondaryBtn={handleCancel}
      color={primaryBtnColor}
      bodyContent={
        <Dropdown
          id="employeeGroup"
          label="Please Select"
          handleChange={handleChange}
          labelId="employeeGroup"
          options={options}
          errorMessage={errorMessage}
          error={error}
          value={value}
        />
      }
    />
  );
};
MapLifeCyclePolicy.defaultProps = {
  userName: 'Uday',
  primaryBtnColor: 'primary',
  secondaryBtn: 'Cancel',
  primaryBtn: 'Add to group',
  handleComment: () => {},
  handleCancel: () => {},
  handleChange: () => {},
  error: false,
  errorMessage: 'Error Message',
  options: [
    { id: 1, name: 'Option 1', onClick: () => {} },
    { id: 2, name: 'Option 2', onClick: () => {} },
    { id: 3, name: 'Option 3', onClick: () => {} },
    { id: 4, name: 'Option 4', onClick: () => {} },
    { id: 5, name: 'Option 5', onClick: () => {} },
    { id: 6, name: 'Option 6', onClick: () => {} },
  ],
  open: true,
  handleClose: () => {},
  value: '',
};
MapLifeCyclePolicy.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  error: PropTypes.bool,
  userName: PropTypes.string,
  errorMessage: PropTypes.string,
  primaryBtnColor: PropTypes.string,
  handleChange: PropTypes.func,
  handleComment: PropTypes.func,
  handleCancel: PropTypes.func,
  secondaryBtn: PropTypes.string,
  primaryBtn: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
  value: PropTypes.string,
};
export default MapLifeCyclePolicy;
