export const chartStyle = {
  chartWrapper: {
    fontFamily: 'Inter',
    fontWeight: 400,
    letterSpacing: '0.019rem',
    position: 'relative',
    maxWidth: 'max-content',
    margin: '0 auto',

    '.legend-list': {
      fontSize: '1.75rem',
      lineHeight: '160%',
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      padding: 0,
      rowGap: '1.5rem',
      columnGap: '3rem',
      mb: 0,

      li: {
        listStyle: 'none',
        display: 'flex',
        alignItems: 'center',
        columnGap: '1rem',
        margin: '0 0.5rem',

        '.legend-icon': {
          width: '0.75rem',
          height: '1.75rem',
        },

        '.legend-label': {
          fontWeight: '600',
          fontSize: '2.25rem',
          lineHeight: '150%',
          ml: 'auto',
          color: 'neutral.800',
        },
      },
    },
  },

  axisLabel: {
    fontSize: '1.5rem',
    lineHeight: '160%',
  },

  valueBox: {
    position: 'absolute',
    top: '35%',
    left: '50%',
    transform: 'translate(-50%, -65%)',
  },
};
