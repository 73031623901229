import React from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import ProfileBankAccount from 'components/Organisms/Profile/BankAccount';

const ProfileBankAccountTemplate = ({ bankDetails, addressList, handleEdit, isEditPermissionAvailable }) => {
  return (
    <BlankTemplate pageHeading="bank Accounts" isBreadcrumb={false} padding="2rem 3rem">
      <ProfileBankAccount bankDetails={bankDetails} addressList={addressList} handleEdit={handleEdit} />
    </BlankTemplate>
  );
};

ProfileBankAccountTemplate.defaultProps = {
  bankDetails: [],
  addressList: {},
  handleEdit: () => {},
  isEditPermissionAvailable: false,
};

ProfileBankAccountTemplate.propTypes = {
  bankDetails: PropTypes.arrayOf(
    PropTypes.shape({
      accountNumber: PropTypes.string,
      accountHolderName: PropTypes.string,
      accountType: PropTypes.string,
      accountCurrency: PropTypes.string,
      accountPurpose: PropTypes.string,
      preferredTransactionType: PropTypes.string,
      bankName: PropTypes.string,
      branchName: PropTypes.string,
      ifsCode: PropTypes.string,
      swiftCode: PropTypes.string,
    }),
  ),
  addressList: PropTypes.shape({
    locationName: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    addressLine3: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    pinOrZip: PropTypes.string,
  }),
  handleEdit: PropTypes.func,
  isEditPermissionAvailable: PropTypes.bool,
};

export default ProfileBankAccountTemplate;
