import React from 'react';
import { Box, Grid, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import Button from 'components/Atoms/Button';
import { useFormik } from 'formik';
import { useTheme } from '@mui/material/styles';
import Dropdown from 'components/Molecules/Dropdown';
import * as yup from 'yup';
import PerfectScrollbar from 'react-perfect-scrollbar';
import TitleWithText from 'components/Atoms/TitleWithText';
import { taskStyle } from '../style';

const ReassignQuery = ({
  handleSubmit,
  formData,
  categoryOptions,
  topicOptions,
  currentOwnerOptions,
  handleCancel,
  queryDetails,
}) => {
  const defaultValues = formData || {
    category: '',
    topic: '',
    currentOwner: '',
    id: '',
  };

  const formScheme = yup.object().shape({
    topic: yup.string().required('Required'),
    currentOwner: yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: formScheme,
    validateOnMount: true,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const theme = useTheme();

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 38.5rem)' }}>
          <Grid container columnSpacing={9} padding="0 3rem">
            <Grid item xs={12} md={4}>
              <TitleWithText title="Title*" text={queryDetails.title} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TitleWithText title="Description" text={queryDetails.description} />
            </Grid>
          </Grid>
          <Divider sx={{ mt: 1, mb: 0.5, backgroundColor: theme.palette.neutral[200] }} />
          <Grid container rowSpacing={2} columnSpacing={2} mt={0} sx={taskStyle.formContainer}>
            <Grid item xs={12} md={4}>
              <Dropdown
                error={Boolean(formik.touched.category && formik.errors.category)}
                helperText={formik.touched.category && formik.errors.category}
                handleChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.category}
                name="category"
                id="category"
                label="Category"
                options={categoryOptions}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Dropdown
                error={Boolean(formik.touched.topic && formik.errors.topic)}
                helperText={formik.touched.topic && formik.errors.topic}
                handleChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.topic}
                name="topic"
                id="topic"
                label="Topic*"
                options={topicOptions}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Dropdown
                error={Boolean(formik.touched.currentOwner && formik.errors.currentOwner)}
                helperText={formik.touched.currentOwner && formik.errors.currentOwner}
                handleChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.currentOwner}
                name="currentOwner"
                id="currentOwner"
                label="Current Owner*"
                options={currentOwnerOptions}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </PerfectScrollbar>
      <Box sx={taskStyle.btnContainer}>
        <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />
        <Box sx={taskStyle.submitButtons}>
          <Button
            size="large"
            color="info"
            variant="outlined"
            type="cancel"
            onClick={() => {
              formik.resetForm();
              handleCancel();
            }}
          >
            Cancel
          </Button>
          <Button size="large" type="submit">
            Reassign
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

ReassignQuery.defaultProps = {
  formData: {},
  handleSubmit: () => {},
  categoryOptions: [],
  topicOptions: [],
  handleCancel: () => {},
  currentOwnerOptions: [],
  queryDetails: {},
};

ReassignQuery.propTypes = {
  formData: PropTypes.shape({
    category: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    topic: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    currentOwner: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  handleSubmit: PropTypes.func,
  categoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  topicOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  currentOwnerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  handleCancel: PropTypes.func,
  queryDetails: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default ReassignQuery;
