import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Box, useMediaQuery, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Logo from 'components/Atoms/Logo';
import { logoContainerStyle } from './style';

const LogoContainer = ({ handleLeftDrawerToggle, width, height }) => {
  const theme = useTheme();
  const leftDrawerOpened = useSelector((state) => state.customization.leftDrawerOpen);

  const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={logoContainerStyle.wrapper}>
      <Logo alt="Logo" width={width} height={height} showLoading={false} />
      <Box sx={logoContainerStyle.hamburgericonwrap}>
        <IconButton
          disableRipple
          onClick={handleLeftDrawerToggle}
          edge="start"
          aria-label="open drawer"
          sx={logoContainerStyle.hamburgericon}
        >
          {!leftDrawerOpened && !matchDownSm && <KeyboardDoubleArrowLeftIcon />}
          {matchDownSm && <KeyboardDoubleArrowRightIcon />}
        </IconButton>
      </Box>
    </Box>
  );
};

LogoContainer.defaultProps = {
  handleLeftDrawerToggle: () => {},
  width: '10.625rem',
};

LogoContainer.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
  width: PropTypes.string,
};

export default LogoContainer;
