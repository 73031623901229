import { createSlice } from '@reduxjs/toolkit';
import {
  fetchmyLeaveDashboard,
  fetchemployeeEventData,
  fetchdocumentAndProjectData,
  fetchemployeeLoginData,
  fetchTeamDashboardDetails,
  fetchSurveyDetails,
  fetchAnnouncementDetails,
} from 'store/thunks/dashboard/dashboard';

const dashboard = createSlice({
  name: 'dashboard',
  initialState: {
    homeDashboardDetails: {},
    announcementInstanceList: [],
    surveySheetInstanceList: [],
    annivEmpList: [],
    empDocuments: [],
    companyDocuments: [],
    employeeInstance: [],
    teamDashboardDetails: [],
    announcementDetails: {},
    surveySheetDetails: {},
  },
  extraReducers(builder) {
    builder.addCase(fetchmyLeaveDashboard.fulfilled, (state, action) => {
      state.homeDashboardDetails = action?.payload?.data;
    });
    builder.addCase(fetchemployeeEventData.fulfilled, (state, action) => {
      state.announcementInstanceList = action?.payload?.data?.announcementInstanceList;
      state.surveySheetInstanceList = action?.payload?.data?.surveySheetInstanceList;
      state.annivEmpList = action?.payload?.data?.annivEmpList;
    });
    builder.addCase(fetchdocumentAndProjectData.fulfilled, (state, action) => {
      state.empDocuments = action?.payload?.data?.empDocuments;
      state.companyDocuments = action?.payload?.data?.companyDocuments;
    });
    builder.addCase(fetchemployeeLoginData.fulfilled, (state, action) => {
      state.employeeInstance = action?.payload?.data?.employeeInstance;
    });
    builder.addCase(fetchTeamDashboardDetails.fulfilled, (state, action) => {
      state.teamDashboardDetails = action?.payload?.data;
    });
    builder.addCase(fetchAnnouncementDetails.fulfilled, (state, action) => {
      state.announcementDetails = action?.payload?.data?.announcementInstance;
    });
    builder.addCase(fetchSurveyDetails.fulfilled, (state, action) => {
      state.surveySheetDetails = action?.payload?.data;
    });
  },
});

export default dashboard.reducer;
