import React from 'react';
import PropTypes from 'prop-types';

import MuiTab from 'components/Molecules/Tab';
import Documents from '../Documents';
import Identifiers from '../Identifiers';
import PersonalInformation from '../PersonalInformation';

const BasicProfileTabInfo = ({
  rows,
  keyColumn,
  sortDirection,
  sortBy,
  onSortChange,
  count,
  page,
  onPageChange,
  showPagination,
  onRowClick,
  handleSelect,
  loading,

  employeeDetail,
  employeePersonalInformation,
  employeeEmergencyContactInformation,
  employeeContactDetails,
  employeeContactEmailId,
}) => {
  const initialTabs = [
    {
      label: 'Identifiers',
      onChange: () => {},
      RenderComponent: Identifiers,
      componentProps: { employeeDetail },
    },
    {
      label: 'Personal Information',
      onChange: () => {},
      RenderComponent: PersonalInformation,
      componentProps: {
        employeePersonalInformation,
        employeeEmergencyContactInformation,
        employeeContactDetails,
        employeeContactEmailId,
      },
    },

    {
      label: 'Documents',
      onChange: () => {},
      RenderComponent: Documents,
      componentProps: {
        rows,
        keyColumn,
        sortDirection,
        sortBy,
        onSortChange,
        count,
        page,
        onPageChange,
        showPagination,
        onRowClick,
        handleSelect,
        loading,
      },
    },
  ];

  return (
    <MuiTab
      key={initialTabs}
      orientation="horizontal"
      variant="scrollable"
      scrollButtons={false}
      textColor="primary"
      indicatorColor="primary"
      tabs={initialTabs}
      allowScrollButtonsMobile
      panelPadding="2rem 3rem"
    />
  );
};

BasicProfileTabInfo.defaultProps = {
  keyColumn: 'id',
  sortDirection: 'desc',
  sortBy: 'id',
  onSortChange: () => {},
  page: 1,
  onPageChange: () => {},
  showPagination: false,
  onRowClick: () => {},
  handleSelect: () => {},
  loading: false,
  rows: [],
  employeeDetail: {},
  employeePersonalInformation: {},
  employeeEmergencyContactInformation: {},
  employeeContactDetails: [],
  employeeContactEmailId: [],
};

BasicProfileTabInfo.propTypes = {
  rows: PropTypes.arrayOf,
  keyColumn: PropTypes.string,
  onSortChange: PropTypes.func,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  showPagination: PropTypes.bool,
  count: PropTypes.number.isRequired,
  onRowClick: PropTypes.func,
  handleSelect: PropTypes.func,
  loading: PropTypes.bool,

  employeeDetail: PropTypes.shape({
    panNumber: PropTypes.string,
    aadharNumber: PropTypes.string,
    uan: PropTypes.string,
    epfAccountNumber: PropTypes.string,
    esiAccountNumber: PropTypes.string,
  }),

  employeePersonalInformation: PropTypes.shape({
    bloodGroup: PropTypes.string,
    dateOfBirth: PropTypes.string,
    dateOfJoining: PropTypes.string,
    motherName: PropTypes.string,
    fatherName: PropTypes.string,
    spouseName: PropTypes.string,
    schoolGoingChildren: PropTypes.string,
    childrenHostel: PropTypes.string,
  }),
  employeeEmergencyContactInformation: PropTypes.shape({
    name: PropTypes.string,
    mobileNumber: PropTypes.string,
    relation: PropTypes.string,
    emailid: PropTypes.string,
  }),
  employeeContactDetails: PropTypes.arrayOf,
  employeeContactEmailId: PropTypes.arrayOf,
};

export default BasicProfileTabInfo;
