import { api, formDataApi } from 'api/api';

export const listPayrollCalendars = async (query) => {
  const response = await api.post(`restPayIndex/listPayrollCalendars?${query.toString()}`);
  return response?.data?.data;
};

export const searchPayrollCalendars = async (query) => {
  const response = await api.post(`restPayIndex/listPayrollCalendars?${query.toString()}`);
  return response?.data?.data;
};

export const deletePayrollCalendar = async (id) => {
  const body = new FormData();
  body.append('id', id);
  const response = await formDataApi.post('restPayIndex/deletePayrollCalendar', body);
  return response;
};

export const preCreatePayrollCalendar = async () => {
  const url = 'restPayIndex/createPayrollCalendar';

  const response = await formDataApi.get(url);
  return response;
};

export const getPayrollCalendarById = async (payrollCalendarId) => {
  const bodyFormData = new FormData();
  bodyFormData.append('id', payrollCalendarId);
  const response = await formDataApi.post('restPayIndex/displayPayrollCalendar', bodyFormData);
  return response;
};
