import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Link } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import ProgressBar from 'components/Atoms/ProgressBar';
import Button from 'components/Atoms/Button';
import DropdownMenu from 'components/Molecules/DropdownMenu';
import IconWithTitle from 'components/Atoms/IconWithTitle';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EditIcon from '@mui/icons-material/Edit';
import Avatars from 'components/Molecules/Avatar';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import TitleWithText from 'components/Atoms/TitleWithText';
import { hasPermission } from 'utils/permissions';
import { useSelector } from 'react-redux';
import { profileCardStyle } from './style';

const UserProfile = ({ employeeProfileDetail, menuLists, handleClick, isProgressBar, isActionsDropdownMenu }) => {
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasEditPermission = hasPermission(authorities, ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_USER_PROFILE']);

  return (
    <Box sx={profileCardStyle.wrapper}>
      <Box sx={{ flexShrink: 0, justifyContent: 'center' }}>
        <Avatars avatarSrc={employeeProfileDetail?.avatarSrc} sx={profileCardStyle.avatar} />
        {isProgressBar ? (
          <Box sx={{ my: 0.5 }}>
            <Typographys variant="subtitle1" color="neutral.600">
              Profile Completed
            </Typographys>
            <ProgressBar value={employeeProfileDetail?.progressBarValue} />
          </Box>
        ) : null}
      </Box>

      <Box sx={profileCardStyle.actionWrapper}>
        <Box sx={profileCardStyle.action}>
          <Typographys variant="h4">{employeeProfileDetail?.name}</Typographys>
          <Box sx={profileCardStyle.actionBtn}>
            {!!userHasEditPermission && (
              <Button startIcon={<EditIcon fontSize="2.5rem" />} onClick={handleClick}>
                Edit
              </Button>
            )}
            {isActionsDropdownMenu ? (
              <Box sx={{ ml: 1.5 }}>
                <DropdownMenu btnLabel="Actions" menuLists={menuLists} />
              </Box>
            ) : null}
          </Box>
        </Box>

        <Grid container sx={profileCardStyle?.contactInfo}>
          <Grid item sm={6} md="auto">
            <IconWithTitle
              iconElement={<LocationOnIcon fontSize="inherit" />}
              title={employeeProfileDetail?.location}
            />
          </Grid>
          <Grid item sm={6} md="auto">
            <Link sx={{ textDecoration: 'none' }} href={`mailto:${employeeProfileDetail?.email}`}>
              <IconWithTitle iconElement={<EmailIcon fontSize="inherit" />} title={employeeProfileDetail?.email} />
            </Link>
          </Grid>
          <Grid item sm={6} md="auto">
            <Link sx={{ textDecoration: 'none' }} href={`tel:${employeeProfileDetail?.mobile}`}>
              <IconWithTitle iconElement={<CallIcon fontSize="inherit" />} title={employeeProfileDetail?.mobile} />
            </Link>
          </Grid>
          {employeeProfileDetail?.phone && (
            <Grid item sm={6} md="auto">
              <IconWithTitle
                iconElement={<BusinessCenterIcon fontSize="inherit" />}
                title={employeeProfileDetail?.phone}
              />
            </Grid>
          )}
        </Grid>

        <Grid container sx={profileCardStyle.employmentInfo}>
          <Grid item sm={4} md="auto">
            <TitleWithText padding="0" title="Designation" text={employeeProfileDetail?.designation} />
          </Grid>
          <Grid item sm={4} md="auto">
            <TitleWithText padding="0" title="Department" text={employeeProfileDetail?.department} />
          </Grid>
          <Grid item sm={4} md="auto">
            <TitleWithText padding="0" title="Supervisor" text={employeeProfileDetail?.supervisor} />
          </Grid>
          <Grid item sm={4} md="auto">
            <TitleWithText padding="0" title="Employee ID" text={employeeProfileDetail?.employeeID} />
          </Grid>
          <Grid item sm={4} md="auto">
            <TitleWithText padding="0" title="Employment type" text={employeeProfileDetail?.employmentType} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

UserProfile.defaultProps = {
  employeeProfileDetail: {
    name: 'Uday Kumar',
    avatarSrc: 'images/avatar.jpg',
    progressBarValue: 67,
  },
  menuLists: [
    { id: 1, label: 'Add New', onClick: () => {} },
    { id: 2, label: 'Bulk Upload', onClick: () => {} },
    { id: 3, label: 'Send ESS invite', onClick: () => {} },
    { id: 4, label: 'Download', onClick: () => {} },
  ],
  handleClick: () => {},
  isProgressBar: true,
  isActionsDropdownMenu: true,
};

UserProfile.propTypes = {
  employeeProfileDetail: PropTypes.shape({
    name: PropTypes.string,
    avatarSrc: PropTypes.string,
    progressBarValue: PropTypes.number,
    location: PropTypes.string,
    email: PropTypes.string,
    mobile: PropTypes.string,
    phone: PropTypes.string,
    designation: PropTypes.string,
    department: PropTypes.string,
    supervisor: PropTypes.string,
    employeeID: PropTypes.string,
    employmentType: PropTypes.string,
  }),
  menuLists: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  handleClick: PropTypes.func,
  isProgressBar: PropTypes.bool,
  isActionsDropdownMenu: PropTypes.bool,
};

export default UserProfile;
