import React from 'react';
import PropTypes from 'prop-types';
import DashboardTemplate from 'components/Templates/DashboardTemplate';
import AttendanceTab from 'components/Organisms/Attendance/AttendanceTab';

const AttendanceTemplate = ({
  timelineData,

  myAttendanceColumns,
  myAttendanceRows,
  myAttendancePage,
  myAttendanceOnPageChange,
  myAttendanceSortDirection,
  myAttendanceSortBy,
  myAttendanceOnSortChange,
  myAttendanceHandleSort,
  myAttendanceLoading,
  myAttendancekeyColumn,
  myAttendanceCount,

  handleMyAttendanceActionSelect,
  handleMyAttendanceThisWeek,
  handleMyAttendanceThisMonth,
  handleMyAttendanceThisYear,
  handleMyAttendanceDateRange,
  handleFilter,
  handleAttendanceRegularize,
  handleAttendanceDownload,

  daysData,
  startTime,
  endTime,

  avgHours,
  arrivalTime,
  overtimeHrs,
  handleSelect,
  handleThisWeek,
  handleThisMonth,
  handleThisYear,
}) => {
  return (
    <DashboardTemplate pageHeading="Attendance" isBreadcrumb={false} isActionLists={false} isActionMenuLists={false}>
      <AttendanceTab
        timelineData={timelineData}
        myAttendanceRows={myAttendanceRows}
        myAttendanceColumns={myAttendanceColumns}
        myAttendanceCount={myAttendanceCount}
        myAttendancePage={myAttendancePage}
        myAttendanceSortBy={myAttendanceSortBy}
        myAttendanceSortDirection={myAttendanceSortDirection}
        myAttendanceOnSortChange={myAttendanceOnSortChange}
        myAttendanceHandleSort={myAttendanceHandleSort}
        myAttendanceOnPageChange={myAttendanceOnPageChange}
        myAttendanceLoading={myAttendanceLoading}
        myAttendancekeyColumn={myAttendancekeyColumn}
        handleFilter={handleFilter}
        handleAttendanceRegularize={handleAttendanceRegularize}
        handleAttendanceDownload={handleAttendanceDownload}
        daysData={daysData}
        startTime={startTime}
        endTime={endTime}
        avgHours={avgHours}
        arrivalTime={arrivalTime}
        overtimeHrs={overtimeHrs}
        handleSelect={handleSelect}
        handleThisWeek={handleThisWeek}
        handleThisMonth={handleThisMonth}
        handleThisYear={handleThisYear}
        handleMyAttendanceThisWeek={handleMyAttendanceThisWeek}
        handleMyAttendanceThisMonth={handleMyAttendanceThisMonth}
        handleMyAttendanceThisYear={handleMyAttendanceThisYear}
        handleMyAttendanceDateRange={handleMyAttendanceDateRange}
        handleMyAttendanceActionSelect={handleMyAttendanceActionSelect}
      />
    </DashboardTemplate>
  );
};

AttendanceTemplate.defaultProps = {
  timelineData: [],
  myAttendanceRows: [],
  myAttendanceColumns: [],
  myAttendancePage: 2,
  myAttendancekeyColumn: 'id',
  myAttendanceLoading: false,
  myAttendanceCount: 10,
  myAttendanceSortDirection: 'id',
  myAttendanceSortBy: 'Desc',
  myAttendanceOnSortChange: () => {},
  myAttendanceHandleSort: () => {},
  myAttendanceOnPageChange: () => {},

  handleFilter: () => {},
  handleAttendanceRegularize: () => {},
  handleAttendanceDownload: () => {},
  handleMyAttendanceThisWeek: () => {},
  handleMyAttendanceThisMonth: () => {},
  handleMyAttendanceThisYear: () => {},
  handleMyAttendanceDateRange: () => {},
  handleMyAttendanceActionSelect: () => {},

  avgHours: '',
  arrivalTime: '',
  overtimeHrs: '',
  handleSelect: () => {},
  handleThisWeek: () => {},
  handleThisMonth: () => {},
  handleThisYear: () => {},

  daysData: {},

  startTime: '11',
  endTime: '8',
};

AttendanceTemplate.propTypes = {
  timelineData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      desc: PropTypes.string,
      iconKey: PropTypes.string,
    }),
  ),

  myAttendanceColumns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),

  myAttendanceRows: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.string,
      timeIn: PropTypes.string,
      timeOut: PropTypes.string,
      duration: PropTypes.string,
      interimPunches: PropTypes.string,
      attendance: PropTypes.string,
      exception: PropTypes.string,
      attendanceMarking: PropTypes.string,
    }),
  ),

  myAttendancePage: PropTypes.number,
  myAttendanceCount: PropTypes.number,
  myAttendanceLoading: PropTypes.bool,
  myAttendanceOnPageChange: PropTypes.func,
  myAttendanceSortDirection: PropTypes.string,
  myAttendanceSortBy: PropTypes.string,
  myAttendanceOnSortChange: PropTypes.func,
  myAttendanceHandleSort: PropTypes.func,
  myAttendancekeyColumn: PropTypes.string,

  handleFilter: PropTypes.func,
  handleAttendanceRegularize: PropTypes.func,
  handleAttendanceDownload: PropTypes.func,
  handleMyAttendanceThisWeek: PropTypes.func,
  handleMyAttendanceThisMonth: PropTypes.func,
  handleMyAttendanceThisYear: PropTypes.func,
  handleMyAttendanceDateRange: PropTypes.func,
  handleMyAttendanceActionSelect: PropTypes.func,

  avgHours: PropTypes.string,
  arrivalTime: PropTypes.string,
  overtimeHrs: PropTypes.string,

  daysData: PropTypes.objectOf(
    PropTypes.shape({
      day: PropTypes.string,
      duration: PropTypes.shape({
        hour: PropTypes.number,
        min: PropTypes.number,
      }),

      break: PropTypes.shape({
        hour: PropTypes.number,
        min: PropTypes.number,
      }),

      overtime: PropTypes.shape({
        hour: PropTypes.number,
        min: PropTypes.number,
      }),
    }),
  ),

  startTime: PropTypes.string,
  endTime: PropTypes.string,

  handleSelect: PropTypes.func,
  handleThisWeek: PropTypes.func,
  handleThisMonth: PropTypes.func,
  handleThisYear: PropTypes.func,
};

export default AttendanceTemplate;
