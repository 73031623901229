import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/material';

import Typographys from 'components/Atoms/Typography';
import { advancedButton } from './style';

const AdvancedSearchButton = ({ onClick }) => {
  return (
    <Box position="relative" sx={advancedButton.buttonBox} onClick={onClick}>
      <SearchIcon sx={{ color: 'neutral.600' }} />
      <Typographys color="neutral.800" variant="body2">
        Advanced Search
      </Typographys>
    </Box>
  );
};

AdvancedSearchButton.defaultProps = {
  onClick: () => {},
};

AdvancedSearchButton.propTypes = {
  onClick: PropTypes.func,
};
export default AdvancedSearchButton;
