import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid } from '@mui/material';
import Dropdown from 'components/Molecules/Dropdown';
import InputField from 'components/Atoms/InputField';
import Typographys from 'components/Atoms/Typography';
import UploadProfile from 'components/Molecules/UploadProfile';
import { useTheme } from '@mui/material/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';

const BasicProfile = ({ maritalStatusOptions, propData, defaultValues, isAadharAndPanMandatory }) => {
  const formik = propData.formik;
  const theme = useTheme();

  return (
    <Box mt={1.5} px={3}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 45rem)' }}>
          <Typographys variant="h5" color="neutral.800">
            Basic Information
          </Typographys>

          <Box mt={2}>
            <Box my={2}>
              <UploadProfile
                handleChange={(event) => {
                  formik.setFieldValue(`uploadProfile`, event.target.files[0]);
                  formik.setFieldValue(`uploadProfileObjectURL`, URL.createObjectURL(event.target.files[0]));
                }}
                name="uploadProfile"
                id="uploadProfile"
                value={formik?.values?.uploadProfileObjectURL || formik?.values?.uploadProfile}
              />
            </Box>

            <Grid container rowSpacing={2} columnSpacing={9} mt={0}>
              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.fatherName && formik.errors.fatherName)}
                  helperText={formik.touched.fatherName && formik.errors.fatherName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fatherName}
                  name="fatherName"
                  id="fatherName"
                  label="Father’s Name*"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.motherName && formik.errors.motherName)}
                  helperText={formik.touched.motherName && formik.errors.motherName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.motherName}
                  name="motherName"
                  id="motherName"
                  label="Mother’s Name"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Dropdown
                  error={Boolean(formik.touched.maritalStatus && formik.errors.maritalStatus)}
                  helperText={formik.touched.maritalStatus && formik.errors.maritalStatus}
                  handleChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.maritalStatus}
                  name="maritalStatus"
                  id="maritalStatus"
                  label="Marital Status*"
                  options={maritalStatusOptions}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.spouseName && formik.errors.spouseName)}
                  helperText={formik.touched.spouseName && formik.errors.spouseName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.spouseName}
                  name="spouseName"
                  id="spouseName"
                  label="Spouce Name"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

            <Typographys variant="h5" color="neutral.800">
              Statutory identification Details
            </Typographys>

            <Grid mt={2} container rowSpacing={2} columnSpacing={9}>
              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.panNumber && formik.errors.panNumber)}
                  helperText={formik.touched.panNumber && formik.errors.panNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.panNumber}
                  name="panNumber"
                  id="panNumber"
                  disabled={defaultValues?.panNumber}
                  label={[`PAN Number ${isAadharAndPanMandatory}`]}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.aadharNumber && formik.errors.aadharNumber)}
                  helperText={formik.touched.aadharNumber && formik.errors.aadharNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.aadharNumber}
                  disabled={defaultValues?.aadharNumber}
                  name="aadharNumber"
                  id="aadharNumber"
                  label={[`Aadhaar Number ${isAadharAndPanMandatory}`]}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.uan && formik.errors.uan)}
                  helperText={formik.touched.uan && formik.errors.uan}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.uan}
                  disabled={defaultValues?.uan}
                  name="uan"
                  id="uan"
                  label="Universal Access Number(UAN)"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.uhid && formik.errors.uhid)}
                  helperText={formik.touched.uhid && formik.errors.uhid}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.uhid}
                  disabled={defaultValues?.uhid}
                  name="uhid"
                  id="uhid"
                  label="UHID Number"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.epf && formik.errors.epf)}
                  helperText={formik.touched.epf && formik.errors.epf}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.epf}
                  disabled={defaultValues?.epf}
                  name="epf"
                  id="epf"
                  label="EPF Account Number"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.eps && formik.errors.eps)}
                  helperText={formik.touched.eps && formik.errors.eps}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.eps}
                  disabled={defaultValues?.eps}
                  name="eps"
                  id="eps"
                  label="EPS Account Number"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.esi && formik.errors.esi)}
                  helperText={formik.touched.esi && formik.errors.esi}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.esi}
                  disabled={defaultValues?.esi}
                  name="esi"
                  id="esi"
                  label="ESI Account Number"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );
};

BasicProfile.defaultProps = {
  maritalStatusOptions: [],
  propData: {},
  defaultValues: {},
};

BasicProfile.propTypes = {
  maritalStatusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  propData: PropTypes.shape({
    formik: PropTypes.shape({
      errors: PropTypes.shape({
        esi: PropTypes.string,
        eps: PropTypes.string,
        epf: PropTypes.string,
        uhid: PropTypes.string,
        uan: PropTypes.string,
        aadharNumber: PropTypes.string,
        panNumber: PropTypes.string,
        spouseName: PropTypes.string,
        maritalStatus: PropTypes.string,
        motherName: PropTypes.string,
        fatherName: PropTypes.string,
      }),

      touched: PropTypes.shape({
        esi: PropTypes.string,
        eps: PropTypes.string,
        epf: PropTypes.string,
        uhid: PropTypes.string,
        uan: PropTypes.string,
        aadharNumber: PropTypes.string,
        panNumber: PropTypes.string,
        spouseName: PropTypes.string,
        maritalStatus: PropTypes.string,
        motherName: PropTypes.string,
        fatherName: PropTypes.string,
      }),

      values: PropTypes.shape({
        esi: PropTypes.string,
        eps: PropTypes.string,
        epf: PropTypes.string,
        uhid: PropTypes.string,
        uan: PropTypes.string,
        aadharNumber: PropTypes.string,
        panNumber: PropTypes.string,
        spouseName: PropTypes.string,
        maritalStatus: PropTypes.string,
        motherName: PropTypes.string,
        fatherName: PropTypes.string,
        uploadProfile: PropTypes.string,
        uploadProfileObjectURL: PropTypes.string,
      }),
      setFieldValue: PropTypes.func,
      resetForm: PropTypes.func,
      handleBlur: PropTypes.func,
      handleChange: PropTypes.func,
      handleSubmit: PropTypes.func,
    }),
  }),
  defaultValues: PropTypes.shape({}),
};

export default BasicProfile;
