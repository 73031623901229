import React, { useCallback, useEffect } from 'react';
import MyLeaveRequests from 'components/Templates/Leave/MyLeaves/MyLeaveRequests';
import { useNavigate } from 'react-router-dom';
import { Skeleton, Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMyLeaveAccounts } from 'store';
import { useThunk } from 'hooks/useThunk';
import useController from 'hooks/useController';
import usePaginationAttributes from 'hooks/use-pagination-attributes';

import { updateSelectedLeave } from 'store/slices/leaves/myLeaves';
import useColumns from './useColumns';

const MyLeavesAccounts = () => {
  const dispatch = useDispatch();
  const { pageSize } = usePaginationAttributes();
  const [columns] = useColumns();
  const [controller, handlePageChange, handleSortChange, handleFiltersApply, handleFiltersClear] = useController();
  const navigate = useNavigate();
  const [doFetchMyLeave, isLoadingMyLeaves, loadingLeavesError] = useThunk(fetchMyLeaveAccounts);
  const { myLeaveAccounts } = useSelector((state) => {
    return state.myLeaves;
  });

  const fetchLeavesAction = useCallback(() => {
    const query = new URLSearchParams({});
    if (controller) {
      query.append('sort', controller.sortBy);
      query.append('order', controller.sort);
    }
    query.append('offset', controller.page * pageSize);
    query.append('max', pageSize);
    doFetchMyLeave(query);
  }, [doFetchMyLeave, controller, pageSize]);

  useEffect(() => {
    fetchLeavesAction();
  }, [fetchLeavesAction, controller, pageSize]);

  if (loadingLeavesError !== null) {
    return <Typography variant="h6">{loadingLeavesError?.message || 'Error while loading leaves list.'}</Typography>;
  }

  return (
    <MyLeaveRequests
      loading={isLoadingMyLeaves}
      rows={myLeaveAccounts?.data}
      columns={columns}
      rowsPerPage={10}
      roleBasedMenu={() => []}
      totalRecordCount={myLeaveAccounts?.count}
      loadNextPageData={handlePageChange}
      handleSort={handleSortChange}
      page={controller.page}
      sortDirection={controller.sort}
      sortBy={controller.sortBy}
      pageHeading="My Leave Accounts"
      showActionList={false}
      actions={false}
      showFilter={false}
      handleAddRequest={() => navigate('/home/leaves/my-leave-requests/add')}
      showPagination
      count={myLeaveAccounts?.count}
    />
  );
};

export default MyLeavesAccounts;
