import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typographys from 'components/Atoms/Typography';

const EarningsTable = ({ headers, rows }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell sx={{ backgroundColor: 'info.200' }}>
                <Typographys variant="h6">{header}</Typographys>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name} sx={{ '& > td, & > th': { border: 0 } }}>
              <TableCell sx={{ width: '60%' }}>{row.title}</TableCell>
              <TableCell>{row.perPeriod}</TableCell>
              <TableCell>{row.annual}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EarningsTable;
