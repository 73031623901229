import { Box, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LCRecordEditTemplate from 'components/Templates/Employee/LifeCycleRecord/Edit';
import { get, isEmpty, isObject } from 'lodash';
import { modifyLCRecordsAction } from 'api/employees';
import useToast from 'components/Provider/useToast';
import { dateFormat } from 'utils/commonUtils';
import { useThunk } from 'hooks/useThunk';
import { hasPermission } from 'utils/permissions';
import { fetchLCModifyRecordDetails } from 'store';
import { useSelector } from 'react-redux';

const AddEditRecord = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const isEditMode = location.pathname.includes('edit');
  const { userId, changeType } = useParams();
  const addToast = useToast();
  const navigate = useNavigate();
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasEditPermission = hasPermission(authorities, ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_CASE_RECORD']);

  const breadcrumbsData = [
    { id: 1, name: 'Life Cycle Case Records', path: '/company/life-cycle/life-cycle-records', isActive: false },
    { id: 2, name: 'Edit', isActive: true },
  ];

  const [getModifyRecordDetails, isLoadingModifyRecordDetails, loadingModifyRecordDetailsError] =
    useThunk(fetchLCModifyRecordDetails);

  useEffect(() => {
    if (isEditMode) {
      const bodyFormData = new FormData();
      bodyFormData.append('id', userId);
      bodyFormData.append('type', changeType);
      getModifyRecordDetails(bodyFormData);
    }
  }, [userId, changeType, getModifyRecordDetails, isEditMode]);

  const { lcModifyRecordDetails = {} } = useSelector((state) => state?.lifeCycleRecords);

  const employeeListOptions =
    isObject(lcModifyRecordDetails?.employeeList) && !isEmpty(lcModifyRecordDetails?.employeeList)
      ? lcModifyRecordDetails?.employeeList?.map((emp) => ({ id: emp?.id, name: emp?.fullName }))
      : [];

  const updateEmployee = async (data) => {
    setLoading(true);
    const bodyFormData = new FormData();
    bodyFormData.append('supervisor', get(data, 'supervisor', ''));
    bodyFormData.append('department', get(data, 'department', ''));
    bodyFormData.append('designation', get(data, 'designation', ''));
    bodyFormData.append('location', get(data, 'location', ''));
    bodyFormData.append('empLevel', get(data, 'employeeLevel', ''));
    bodyFormData.append('empGrade', get(data, 'employeeGrade', ''));
    bodyFormData.append('division', get(data, 'division', ''));
    bodyFormData.append('effectiveStartDate', dateFormat(data?.effectiveStartDate) || '');
    if (data?.effectiveEndDate) {
      bodyFormData.append('effectiveEndDate', dateFormat(data?.effectiveEndDate) || '');
    }
    bodyFormData.append('attachment', get(data, 'attachment', ''));
    bodyFormData.append('comment', get(data, 'reasonOfTransfer', ''));

    try {
      if (isEditMode) {
        bodyFormData.append('cid', userId);
        const response = await modifyLCRecordsAction(bodyFormData);
        if (response.status === 200) {
          addToast({
            type: 'success',
            title: 'SUCCESS',
            message: response?.data?.message || `Lifecycle record updated successfully`,
          });
          navigate(`/company/life-cycle/life-cycle-records/${userId}/${changeType}/view`);
        }
      }
    } catch (err) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: err?.message || `Failed to ${isEditMode ? 'update' : 'add'} Lifecycle record`,
      });
    } finally {
      setLoading(false);
    }
  };

  if (!userHasEditPermission) {
    return <Typography variant="h6">You do not have permission to view this page.</Typography>;
  }

  if (isLoadingModifyRecordDetails) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (loadingModifyRecordDetailsError !== null) {
    return (
      <Typography variant="h6">
        {loadingModifyRecordDetailsError?.message || 'Error while loading life cycle case record details.'}
      </Typography>
    );
  }

  return (
    <Box>
      <LCRecordEditTemplate
        loading={loading}
        handleSubmit={updateEmployee}
        handleCancel={() => navigate('/company/life-cycle/life-cycle-records')}
        breadcrumbData={breadcrumbsData}
        pageHeading={`Life Cycle Details: ${lcModifyRecordDetails?.employeeInstance?.fullName}`}
        supervisorList={employeeListOptions || []}
        departmentList={lcModifyRecordDetails?.departmentsList}
        designationList={lcModifyRecordDetails?.designationsList}
        locationList={lcModifyRecordDetails?.locationsList}
        employeeLevelList={lcModifyRecordDetails?.employeeLevelsList}
        employeeGradeList={lcModifyRecordDetails?.employeeGradesList}
        divisionList={lcModifyRecordDetails?.divisionsList}
        changeType={lcModifyRecordDetails?.lifeCycleCaseRecord?.changeType || ''}
        recordDetails={{
          name: lcModifyRecordDetails?.lifeCycleCaseRecord?.createdBy?.fullName || '',
          date: lcModifyRecordDetails?.lifeCycleCaseRecord?.createdOn || '',
          status: lcModifyRecordDetails?.lifeCycleCaseRecord?.status || '',
          supervisor: lcModifyRecordDetails?.supervisor?.fullName || '',
          supervisorId: lcModifyRecordDetails?.supervisor?.id || '',
          department: lcModifyRecordDetails?.department?.name || '',
          departmentId: lcModifyRecordDetails?.department?.id || '',
          designation: lcModifyRecordDetails?.designation?.name || '',
          designationId: lcModifyRecordDetails?.designation?.id || '',
          location: lcModifyRecordDetails?.location?.name || '',
          locationId: lcModifyRecordDetails?.location?.id || '',
          employeeLevel: lcModifyRecordDetails?.employeeLevel?.name || '',
          employeeLevelId: lcModifyRecordDetails?.employeeLevel?.id || '',
          employeeGrade: lcModifyRecordDetails?.employeeGrade?.name || '',
          employeeGradeId: lcModifyRecordDetails?.employeeGrade?.id || '',
          division: lcModifyRecordDetails?.division?.name || '',
          divisionId: lcModifyRecordDetails?.division?.id || '',
          effectiveStartDate: lcModifyRecordDetails?.effectiveStartDate || '',
          effectiveEndDate: lcModifyRecordDetails?.effectiveEndDate || '',
          reasonOfTransfer: lcModifyRecordDetails?.reason || '-',
          attachmentFileName: lcModifyRecordDetails?.lifeCycleCaseRecord?.attachment?.fileName || '',
        }}
      />
    </Box>
  );
};

export default AddEditRecord;
