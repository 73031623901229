import React from 'react';
import PropTypes from 'prop-types';
import { StylesProvider, createGenerateClassName } from '@mui/styles';
import { MuiThemeProvider } from '../../design-tokens/theme/theme';
import Buttons from './TextEditorButtons';

const generateClassname = createGenerateClassName({
  productionPrefix: 'bit-text-editor-buttons',
  seed: 'bit-text-editor-buttons',
});

export const TextEditorButtons = ({ dataTestId, onCancel, onSave }) => {
  return (
    <StylesProvider generateClassName={generateClassname}>
      <MuiThemeProvider>
        <Buttons dataTestId={dataTestId} onCancel={onCancel} onSave={onSave} />
      </MuiThemeProvider>
    </StylesProvider>
  );
};

TextEditorButtons.propTypes = {
  /** Data test id, to add test cases */
  dataTestId: PropTypes.string,
  /** Callback function, invoke on cancel button click */
  onCancel: PropTypes.func.isRequired,
  /** Callback function, invoke on save button click */
  onSave: PropTypes.func.isRequired,
};
