import React from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import PropTypes from 'prop-types';
import AddEmployee from 'components/Organisms/Employee/AddEmployee';
import { Box, Skeleton } from '@mui/material';

const AddEmployeeTemplate = ({
  loading,
  onSubmit,
  handleCancel,
  allowEdit,
  employeeData,
  dropdownOptions,
  customAttributesFormik,
  metaCustomFields,
  roleMap,
}) => {
  const breadcrumbData = [
    { id: 1, name: 'Employee List', path: '/company/employee/employees-list', isActive: false },
    { id: 2, name: `${allowEdit ? 'Edit' : 'Add Employee'}`, isActive: true },
  ];

  const firstName = employeeData && employeeData['User Identity'] ? employeeData['User Identity']?.firstName : '';
  return (
    <BlankTemplate
      padding="0rem"
      pageHeading={allowEdit ? `Edit Employee Details: ${firstName}` : 'Add Employee'}
      breadcrumbData={breadcrumbData}
    >
      {loading ? (
        <Box sx={{ p: 4 }}>
          <Skeleton height={42} />
          <Skeleton />
          <Skeleton />
        </Box>
      ) : (
        <AddEmployee
          onSubmit={onSubmit}
          handleCancel={handleCancel}
          allowEdit={allowEdit}
          employeeData={employeeData}
          dropdownOptions={dropdownOptions}
          customAttributesFormik={customAttributesFormik}
          metaCustomFields={metaCustomFields}
          roleMap={roleMap}
        />
      )}
    </BlankTemplate>
  );
};

AddEmployeeTemplate.defaultProps = {
  onSubmit: () => {},
  allowEdit: false,
  employeeData: {},
  dropdownOptions: {},
  loading: false,
  roleMap: {},
};

AddEmployeeTemplate.propTypes = {
  onSubmit: PropTypes.func,
  allowEdit: PropTypes.bool,
  employeeData: PropTypes.shape({
    empName: PropTypes.string,
  }),
  dropdownOptions: PropTypes.shape({}),
  loading: PropTypes.bool,
  roleMap: PropTypes.shape({}),
};

export default AddEmployeeTemplate;
