import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { validateDateElseHyphen } from 'utils/commonUtils';
import TitleWithText from 'components/Atoms/TitleWithText';
import Typographys from 'components/Atoms/Typography';
import { additionalDetailStyle } from '../style';

const VisaDetail = ({ visaDetails }) => {
  return (
    <>
      <Typographys variant="h5" color="neutral.700">
        Visa Details
      </Typographys>
      <Box mt={2} sx={additionalDetailStyle.rowDivider}>
        {visaDetails.map((visaList) => (
          <Grid container rowSpacing={1} columnSpacing={2}>
            <Grid item xs={12} sm={4}>
              <TitleWithText title="Issuing Country" text={visaList.issuingCountry} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TitleWithText title="Place of Issue" text={visaList.placeOfIssue} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TitleWithText
                title="Valid From"
                text={validateDateElseHyphen(visaList.validFrom, 'DD MMMM, YYYY h:mm A')}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TitleWithText title="Valid To" text={validateDateElseHyphen(visaList.validTo, 'DD MMMM, YYYY h:mm A')} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TitleWithText
                title="Issue Date"
                text={validateDateElseHyphen(visaList.issueDate, 'DD MMMM, YYYY h:mm A')}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TitleWithText title="Reference Number" text={visaList.referenceNumber} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TitleWithText title="Visa Status" text={visaList.visaStatus} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TitleWithText title="Comments" text={visaList.comments} />
            </Grid>
          </Grid>
        ))}
      </Box>
    </>
  );
};

VisaDetail.defaultProps = {
  visaDetails: [],
};

VisaDetail.propTypes = {
  visaDetails: PropTypes.arrayOf(
    PropTypes.shape({
      issuingCountry: PropTypes.string,
      placeOfIssue: PropTypes.string,
      validFrom: PropTypes.string,
      validTo: PropTypes.string,
      issueDate: PropTypes.string,
      referenceNumber: PropTypes.string,
      visaStatus: PropTypes.string,
      comments: PropTypes.string,
    }),
  ),
};

export default VisaDetail;
