import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid } from '@mui/material';
import TitleWithText from 'components/Atoms/TitleWithText';
import Typographys from 'components/Atoms/Typography';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { onBoardingStyle } from '../../style';

const KeyEventDetail = ({ keyEventDetails }) => {
  return (
    <>
      <Typographys variant="h5" color="neutral.700">
        Key Life Event Details
      </Typographys>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 44rem)' }}>
          <Box mt={2}>
            {keyEventDetails.map((keyEventList, i) => (
              <>
                <Typographys variant="subtitle2" align="left" color="neutral.800">
                  Record {i + 1}
                </Typographys>
                <Grid container rowSpacing={1} columnSpacing={2}>
                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Key Life Event Details" text={keyEventList.keyEventDetail} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Event Date" text={keyEventList.eventDate} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TitleWithText title="Comments" text={keyEventList.comments} />
                  </Grid>
                </Grid>
                {i + 1 !== keyEventList.length && <Divider sx={onBoardingStyle.divider} />}
              </>
            ))}
          </Box>
        </Box>
      </PerfectScrollbar>
    </>
  );
};

KeyEventDetail.defaultProps = {
  keyEventDetails: [],
};

KeyEventDetail.propTypes = {
  keyEventDetails: PropTypes.arrayOf(
    PropTypes.shape({
      keyEventDetail: PropTypes.string,
      eventDate: PropTypes.string,
      comments: PropTypes.string,
    }),
  ),
};
export default KeyEventDetail;
