import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getDocumentsNeedToUpload } from 'store';
import { useThunk } from 'hooks/useThunk';
import UploadInvestmentProofsTemplate from 'components/Templates/MyPay/UploadInvestmentProofs';
import { uploadMyInvestmentProofsAction } from 'api/home/myPay';
import { Box, Typography, Skeleton } from '@mui/material';
import useToast from 'components/Provider/useToast';
import Dialog from 'components/Molecules/Dialog';
import { isEmpty } from 'lodash';
import { formDataApi } from 'api/api';
import { hasPermission } from 'utils/permissions';
import { NEW_TAX_REGIME, NEW_TAX_REGIME_2020, PROOF_SUBMISSION_STATUS } from 'utils/SystemConfigConstants';

const InvestmentProofPage = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [proofSubmiited, setProofSubmitted] = useState();
  const [submitSuccessDialog, setSubmitSuccessDialog] = useState(false);
  const addToast = useToast();
  const navigate = useNavigate();
  const authorities = useSelector((state) => state.session.user.authorities);

  const [getDocumentsList, isLoadingDocumentsList, loadingDocumentsListError] = useThunk(getDocumentsNeedToUpload);
  useEffect(() => {
    getDocumentsList();
  }, [getDocumentsList]);

  const { docsList } = useSelector((state) => state.uploadInvestmentProofs);

  const docsListData = docsList?.data;

  useEffect(() => {
    const taxRegime = docsListData?.employeeDeductionDeclarationInstance?.taxRegime;
    const proofSubmissionStatus = docsListData?.employeeDeductionDeclarationInstance?.proofSubmissionStatus;
    if (taxRegime === NEW_TAX_REGIME || taxRegime === NEW_TAX_REGIME_2020) {
      navigate('/home/mypay/tds-declaration');
    }
    if (proofSubmissionStatus === PROOF_SUBMISSION_STATUS) {
      navigate('/home/mypay/tds-declaration', { state: proofSubmissionStatus });
    }
  }, []);

  if (isLoadingDocumentsList) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (loadingDocumentsListError !== null) {
    return (
      <Typography variant="h6">
        {loadingDocumentsListError?.message || 'Error while fetching documents list.'}
      </Typography>
    );
  }

  const employeeDeductionDeclarationInstance = docsListData?.employeeDeductionDeclarationInstance;
  const deductionHeadsList = docsListData?.deductionHeadsList;
  const dedecHeadDeclrAmtsMap = docsListData?.dedecHeadDeclrAmtsMap;
  const secRefDetailsMap = docsListData?.secRefDetailsMap;
  const enumValues = {
    PAYROLL_OPEN_FOR_PROOF_SUBMISSION: 'Open for Proof Submission',
    PAYROLL_PROOF_SUBMISSION_DRAFT: 'Draft Proofs',
  };

  const showProofSubmission =
    employeeDeductionDeclarationInstance?.proofSubmissionStatus === enumValues.PAYROLL_PROOF_SUBMISSION_DRAFT;
  const userHasProofSubmitPermission =
    hasPermission(authorities, ['ROLE_BASE_PERMISSION_PAY_INDEX_SUBMIT_MY_PROOFS']) && showProofSubmission;

  const userhasProofUploadPermission =
    hasPermission(authorities, ['ROLE_BASE_PERMISSION_PAY_INDEX_UPLOAD_MY_INVESTMENT_PROOFS']) && showProofSubmission;

  const handleUpload = async (proofAmount, files, rowIndex) => {
    const body = new FormData();
    Object.keys(files).forEach((key) => {
      if (key === 'form12bb') {
        body.append(`form12bb`, files[key]);
      } else {
        body.append(`investmentProof[${key}]`, files[key]);
      }
    });
    body.append(`did`, docsListData?.employeeDeductionDeclarationInstance?.id);
    body.append(`eid`, docsListData?.employeeDeductionDeclarationInstance?.employee?.id);
    Object.entries(rowIndex).forEach(([key, value]) => {
      const proofAmountKey = proofAmount?.proofSubmittedAmount[key];
      if (proofAmountKey) body.append(`proofSubmittedAmount[${value}]`, proofAmountKey);
    });

    const deduction1013A = deductionHeadsList?.find((data) => data.statutoryReferenceSection === '10(13A)');
    if (!isEmpty(deduction1013A)) {
      body.append(`dheadId1013A`, deduction1013A?.id);
    }

    const dhead24 = deductionHeadsList?.find((data) => data.statutoryReferenceSection === '24');
    if (!isEmpty(dhead24)) {
      body.append(`dheadId24`, dhead24?.id);
    }

    try {
      const response = await uploadMyInvestmentProofsAction(body);
      if (response.status === 200) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.data || 'Uploaded document successfully',
        });
        navigate('/home/mypay/tds-declaration');
      }
    } catch (err) {
      addToast({ type: 'error', title: 'ERROR', message: err?.message || 'Failed to upload documents' });
    }
  };

  const formData = deductionHeadsList?.length
    ? [...deductionHeadsList]
        .sort((a, b) => {
          return a.name.localeCompare(b.name);
        })
        .map((deductionHead) => {
          const currDeclrAmt = dedecHeadDeclrAmtsMap[deductionHead?.id] || 0;
          const showItag = secRefDetailsMap && secRefDetailsMap?.[deductionHead.id];
          return {
            deductionHeadID: deductionHead.id,
            statutoryReferenceSection: deductionHead.statutoryReferenceSection,
            showItag,
            investmentHead: `${deductionHead?.name} (${deductionHead?.statutoryReferenceSection}) 
            (${deductionHead?.id})`,
            dataOriginalTitle: deductionHead?.statutoryReferenceSection,
            dataContent: secRefDetailsMap[deductionHead.id],
            actualDeclairedAmount: currDeclrAmt,
            proofSubmittedAmount: 0,
          };
        })
    : [];

  let employeeDetails = {};
  if (employeeDeductionDeclarationInstance?.declarationProofStatus) {
    if (employeeDeductionDeclarationInstance?.declarationProofStatus === enumValues.PAYROLL_OPEN_FOR_PROOF_SUBMISSION) {
      employeeDetails = {
        name: employeeDeductionDeclarationInstance?.employee?.fullName || '',
        did: employeeDeductionDeclarationInstance?.id || '',
        financialYear: employeeDeductionDeclarationInstance?.financialYear || '',
        assesmentYear: employeeDeductionDeclarationInstance?.assessmentYear || '',
        proofSubmissionStatus: employeeDeductionDeclarationInstance?.proofSubmissionStatus,
        form12BBStatus: employeeDeductionDeclarationInstance?.form12BB?.status,
        eid: docsListData?.employeeId || '',
        taxRegime: employeeDeductionDeclarationInstance?.taxRegime,
      };
    } else {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: `Warning! Investment proofs submission is not allowed at this time.
       Please contact your administrator for further details.`,
      });
    }
  }

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleSubmitSuccessDialogClose = () => {
    setSubmitSuccessDialog(false);
    navigate('/home/mypay/tds-declaration');
  };

  const handleSubmit = (isFilesEmpty) => {
    setProofSubmitted(!isFilesEmpty);
    setOpenDialog(true);
  };

  const handleSubmission = async () => {
    const bodyFormData = new FormData();
    bodyFormData.append('eid', employeeDeductionDeclarationInstance?.employee?.id);
    bodyFormData.append('did', employeeDeductionDeclarationInstance?.id);
    try {
      const response = await formDataApi.post(`restPayIndex/submitEmployeeInvestmentProofs`, bodyFormData);
      if (response?.data?.status !== 'ERROR') {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || 'Investment proofs submitted successfully',
        });
        setSubmitSuccessDialog(true);
      }
    } catch (submmitError) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: submmitError?.message || 'Failed to submit investment proof',
      });
    }
  };

  const handleCancel = () => {
    navigate('/home/mypay/tds-declaration');
  };

  return (
    <>
      <UploadInvestmentProofsTemplate
        formData={formData}
        employeeDetails={employeeDetails}
        handleUpload={handleUpload}
        userHasProofSubmitPermission={userHasProofSubmitPermission}
        userhasProofUploadPermission={userhasProofUploadPermission}
        handleCancel={handleCancel}
        handleSubmitInvestmentProof={handleSubmit}
      />
      <Dialog
        open={openDialog}
        handleClose={handleDialogClose}
        dialogTitle="Investment Proof Submission"
        dialogDesc={
          <Typography color="neutral.600" align="center" textTransform="none">
            {proofSubmiited
              ? 'After submission, you will not be able to upload anymore proofs.'
              : 'You have not uploaded any proofs yet. After submission, you will not be able to upload any proofs.'}
            <Typography color="neutral.600" align="center">
              Are you sure you want to continue?
            </Typography>
          </Typography>
        }
        secondaryBtn="Cancel"
        primaryBtn="Continue"
        handlePrimaryBtn={handleSubmission}
        handleSecondaryBtn={handleDialogClose}
      />
      <Dialog
        maxWidth="md"
        open={submitSuccessDialog}
        handleClose={handleSubmitSuccessDialogClose}
        dialogTitle="Investment Proofs submitted successfully"
        dialogDesc={
          <Typography
            color="neutral.600"
            sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', textTransform: 'none' }}
          >
            <b>Please Note:</b>
            <Typography color="neutral.600">
              <li> Upload of Form 12BB is mandatory for approving proof submissions. </li>
            </Typography>
            <Typography color="neutral.600">
              <li>
                Please make sure you have uploaded your flexible benefit proofs (If applicable) before generating Form
                12BB.
              </li>
            </Typography>
            <Typography color="neutral.600">
              <li>
                Click on ‘Go to Form 12BB’ button below to generate Form 12BB now or you can do it later by going to My
                Pay &nbsp; &nbsp; &gt; Form 12BB &gt; Generate Form 12BB.
              </li>
            </Typography>
            <Typography color="neutral.600">
              <li>
                Download your Form 12BB, add sign and upload it back from My Pay &gt; Form 12BB &gt; Upload Form 12BB.
                Go to &nbsp; &nbsp; &nbsp;Form 12BB
              </li>
            </Typography>
          </Typography>
        }
        secondaryBtn="Cancel"
        primaryBtn="Go to Form12BB"
        handlePrimaryBtn={() => navigate('/home/mypay/form-12BB')}
        handleSecondaryBtn={handleSubmitSuccessDialogClose}
      />
    </>
  );
};

export default InvestmentProofPage;
