import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Divider, useTheme } from '@mui/material';
import Button from 'components/Atoms/Button';
import Typographys from 'components/Atoms/Typography';
import InputField from 'components/Atoms/InputField';
import Dropdown from 'components/Molecules/Dropdown';
import * as yup from 'yup';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Formik, FieldArray, getIn } from 'formik';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { addEmpStyle } from '../style';

const BankAccount = ({
  tabHeading,
  handleSubmit,
  handleCancel,
  transactionTypeOptions,
  accountCurrecyOptions,
  purposeOptions,
  accountTypeOptions,
  propData,
  label,
  updateBtnText,
}) => {
  const defaultValues = propData.allTabsData[label];

  const defaultObj = {
    accountNumber: '',
    accountName: '',
    accountType: '',
    accountCurrecy: '',
    purpose: '',
    transactionType: '',
    bankName: '',
    branchName: '',
    ifsc: '',
    swiftCode: '',
    locationName: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
  };

  const initialValues = Object.keys(defaultValues.bank[0]).length
    ? defaultValues
    : {
        bank: [defaultObj],
      };

  return (
    <Box sx={{ p: '2rem 3rem' }}>
      <Typographys variant="h5" color="neutral.800">
        {tabHeading}
      </Typographys>

      <Formik
        validateOnMount
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          handleSubmit(values);
          actions.setSubmitting(false);
        }}
      >
        {(formik) => (
          <Form
            formik={formik}
            handleCancel={handleCancel}
            transactionTypeOptions={transactionTypeOptions}
            purposeOptions={purposeOptions}
            accountTypeOptions={accountTypeOptions}
            accountCurrecyOptions={accountCurrecyOptions}
            propData={propData}
            label={label}
            defaultObj={defaultObj}
            updateBtnText={updateBtnText}
          />
        )}
      </Formik>
    </Box>
  );
};

const Form = ({
  formik,
  handleCancel,
  transactionTypeOptions,
  accountTypeOptions,
  accountCurrecyOptions,
  purposeOptions,
  propData,
  label,
  defaultObj,
  updateBtnText,
}) => {
  useEffect(() => {
    propData.updateFormData(formik.values, label);
  }, [formik.values, label]);

  const theme = useTheme();

  return (
    <Box mt={1} component="form" onSubmit={formik.handleSubmit}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 53rem)' }}>
          <FieldArray name="bank">
            {({ push, remove }) => (
              <>
                {formik.values?.bank?.map((b, index) => {
                  return (
                    <Box sx={addEmpStyle.rowDivider}>
                      <Typographys variant="subtitle1" color="neutral.600">
                        Account Details
                      </Typographys>

                      <Grid
                        container
                        rowSpacing={2}
                        columnSpacing={9}
                        pt={0}
                        mt={0}
                        mb={4}
                        sx={addEmpStyle.formContainer}
                      >
                        <Grid item xs={12} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].accountNumber`) &&
                                getIn(formik.errors, `bank[${index}].accountNumber`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].accountNumber`) &&
                              getIn(formik.errors, `bank[${index}].accountNumber`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={b.accountNumber}
                            name={`bank[${index}].accountNumber`}
                            id={`bank[${index}].accountNumber`}
                            label="Account Number"
                            type="Number"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].accountName`) &&
                                getIn(formik.errors, `bank[${index}].accountName`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].accountName`) &&
                              getIn(formik.errors, `bank[${index}].accountName`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={b.accountName}
                            name={`bank[${index}].accountName`}
                            id={`bank[${index}].accountName`}
                            label="Account Name"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Dropdown
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].accountType`) &&
                                getIn(formik.errors, `bank[${index}].accountType`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].accountType`) &&
                              getIn(formik.errors, `bank[${index}].accountType`)
                            }
                            handleChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={b.accountType}
                            name={`bank[${index}].accountType`}
                            id={`bank[${index}].accountType`}
                            label="Account Type"
                            options={accountTypeOptions}
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Dropdown
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].accountCurrecy`) &&
                                getIn(formik.errors, `bank[${index}].accountCurrecy`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].accountCurrecy`) &&
                              getIn(formik.errors, `bank[${index}].accountCurrecy`)
                            }
                            handleChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={b.accountCurrecy}
                            name={`bank[${index}].accountCurrecy`}
                            id={`bank[${index}].accountCurrecy`}
                            label="Account Currency"
                            options={accountCurrecyOptions}
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Dropdown
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].purpose`) &&
                                getIn(formik.errors, `bank[${index}].purpose`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].purpose`) &&
                              getIn(formik.errors, `bank[${index}].purpose`)
                            }
                            handleChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={b.purpose}
                            name={`bank[${index}].purpose`}
                            id={`bank[${index}].purpose`}
                            label="Purpose"
                            options={purposeOptions}
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Dropdown
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].transactionType`) &&
                                getIn(formik.errors, `bank[${index}].transactionType`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].transactionType`) &&
                              getIn(formik.errors, `bank[${index}].transactionType`)
                            }
                            handleChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={b.transactionType}
                            name={`bank[${index}].transactionType`}
                            id={`bank[${index}].transactionType`}
                            label="Transaction Type"
                            options={transactionTypeOptions}
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].bankName`) &&
                                getIn(formik.errors, `bank[${index}].bankName`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].bankName`) &&
                              getIn(formik.errors, `bank[${index}].bankName`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={b.bankName}
                            name={`bank[${index}].bankName`}
                            id={`bank[${index}].bankName`}
                            label="Bank Name"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].branchName`) &&
                                getIn(formik.errors, `bank[${index}].branchName`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].branchName`) &&
                              getIn(formik.errors, `bank[${index}].branchName`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={b.branchName}
                            name={`bank[${index}].branchName`}
                            id={`bank[${index}].branchName`}
                            label="Branch Name"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].ifsc`) &&
                                getIn(formik.errors, `bank[${index}].ifsc`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].ifsc`) &&
                              getIn(formik.errors, `bank[${index}].ifsc`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={b.ifsc}
                            name={`bank[${index}].ifsc`}
                            id={`bank[${index}].ifsc`}
                            label="IFSC Code"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].swiftCode`) &&
                                getIn(formik.errors, `bank[${index}].swiftCode`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].swiftCode`) &&
                              getIn(formik.errors, `bank[${index}].swiftCode`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={b.swiftCode}
                            name={`bank[${index}].swiftCode`}
                            id={`bank[${index}].swiftCode`}
                            label="Swift Code (Optional)"
                            fullWidth
                          />
                        </Grid>
                      </Grid>

                      <Typographys variant="subtitle1" color="neutral.600">
                        Branch Address
                      </Typographys>

                      <Grid container rowSpacing={2} columnSpacing={9} mt={0} mb={4} sx={addEmpStyle.formContainer}>
                        <Grid item xs={12} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].locationName`) &&
                                getIn(formik.errors, `bank[${index}].locationName`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].locationName`) &&
                              getIn(formik.errors, `bank[${index}].locationName`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={b.locationName}
                            name={`bank[${index}].locationName`}
                            id={`bank[${index}].locationName`}
                            label="Location Name (Optional)"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].addressLine1`) &&
                                getIn(formik.errors, `bank[${index}].addressLine1`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].addressLine1`) &&
                              getIn(formik.errors, `bank[${index}].addressLine1`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={b.addressLine1}
                            name={`bank[${index}].addressLine1`}
                            id={`bank[${index}].addressLine1`}
                            label="Address Line 1"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].addressLine2`) &&
                                getIn(formik.errors, `bank[${index}].addressLine2`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].addressLine2`) &&
                              getIn(formik.errors, `bank[${index}].addressLine2`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={b.addressLine2}
                            name={`bank[${index}].addressLine2`}
                            id={`bank[${index}].addressLine2`}
                            label="Address Line 2"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].addressLine3`) &&
                                getIn(formik.errors, `bank[${index}].addressLine3`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].addressLine3`) &&
                              getIn(formik.errors, `bank[${index}].addressLine3`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={b.addressLine3}
                            name={`bank[${index}].addressLine3`}
                            id={`bank[${index}].addressLine3`}
                            label="Address Line 3"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].city`) &&
                                getIn(formik.errors, `bank[${index}].city`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].city`) &&
                              getIn(formik.errors, `bank[${index}].city`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={b.city}
                            name={`bank[${index}].city`}
                            id={`bank[${index}].city`}
                            label="City"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].state`) &&
                                getIn(formik.errors, `bank[${index}].state`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].state`) &&
                              getIn(formik.errors, `bank[${index}].state`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={b.state}
                            name={`bank[${index}].state`}
                            id={`bank[${index}].state`}
                            label="State"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].country`) &&
                                getIn(formik.errors, `bank[${index}].country`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].country`) &&
                              getIn(formik.errors, `bank[${index}].country`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={b.country}
                            name={`bank[${index}].country`}
                            id={`bank[${index}].country`}
                            label="Country"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].pincode`) &&
                                getIn(formik.errors, `bank[${index}].pincode`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].pincode`) &&
                              getIn(formik.errors, `bank[${index}].pincode`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={b.pincode}
                            name={`bank[${index}].pincode`}
                            id={`bank[${index}].pincode`}
                            label="Pincode"
                            type="number"
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })}
                <Box mt={11} mb={2.5}>
                  <Button
                    size="medium"
                    variant="outlined"
                    type="button"
                    startIcon={<AddOutlinedIcon />}
                    onClick={() => push(defaultObj)}
                  >
                    Add Another
                  </Button>
                </Box>
              </>
            )}
          </FieldArray>
        </Box>
      </PerfectScrollbar>
      <Box sx={addEmpStyle.btnContainer}>
        <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

        <Box sx={addEmpStyle.submitWrapper}>
          <Button
            size="large"
            color="info"
            variant="outlined"
            type="button"
            onClick={() => {
              formik.resetForm();
              handleCancel();
            }}
          >
            Cancel
          </Button>

          <Button size="large" type="submit" isDisable={false}>
            {propData.allowEdit ? updateBtnText : 'Next'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

Form.defaultProps = {
  handleCancel: () => {},
  transactionTypeOptions: [],
  accountTypeOptions: [],
  accountCurrecyOptions: [],
  purposeOptions: [],
  label: '',
  propData: {},
  formik: {},
  defaultObj: {},
  updateBtnText: 'Update',
};

Form.propTypes = {
  propData: PropTypes.shape({
    updateFormData: PropTypes.func,
    allTabsData: PropTypes.shape({
      'Bank Account': PropTypes.shape({
        bank: PropTypes.shape([]),
      }),
    }),
    allowEdit: PropTypes.bool,
  }),
  handleCancel: PropTypes.func,
  transactionTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  accountCurrecyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  accountTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  purposeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  label: PropTypes.string,
  formik: PropTypes.shape({
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    values: PropTypes.shape({
      bank: PropTypes.shape([]),
    }),
    resetForm: PropTypes.func,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
  }),
  defaultObj: PropTypes.shape({}),
  updateBtnText: 'Update',
};

BankAccount.defaultProps = {
  tabHeading: 'Primary Bank Details',
  propData: {},
  handleSubmit: () => {},
  handleCancel: () => {},
  transactionTypeOptions: [],
  accountTypeOptions: [],
  accountCurrecyOptions: [],
  purposeOptions: [],
  label: 'Bank Account',
  updateBtnText: PropTypes.string,
};

BankAccount.propTypes = {
  tabHeading: PropTypes.string,
  propData: PropTypes.shape({
    updateFormData: PropTypes.func,
    allTabsData: PropTypes.shape({
      'Bank Account': PropTypes.shape({
        bank: PropTypes.shape([]),
      }),
    }),
    allowEdit: PropTypes.bool,
  }),

  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,

  transactionTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  accountTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  purposeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  accountCurrecyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  label: PropTypes.string,
  updateBtnText: PropTypes.string,
};

export default BankAccount;
