import { isEmpty } from 'lodash';

const useFilters = (filtersData, optionsData) => {
  const { filters = {} } = filtersData;
  const filterFields = [
    {
      name: 'Employee Name',
      filterName: 'e',
      type: 'dropdown',
      options: optionsData?.employeesList?.length
        ? optionsData?.employeesList.map((data) => ({
            name: data.fullName,
            id: data.fullName,
          }))
        : [],
      value: filters?.e,
    },

    {
      name: 'Status',
      filterName: 'p',
      type: 'dropdown',
      options: !isEmpty(optionsData?.stages)
        ? Object.values(optionsData?.stages).map((data) => ({
            name: data,
            id: data,
          }))
        : [],
      value: filters?.p,
    },
    {
      name: 'Change Type',
      filterName: 'c',
      type: 'dropdown',
      options: !isEmpty(optionsData?.changeType)
        ? Object.values(optionsData?.changeType).map((data) => ({
            name: data,
            id: data,
          }))
        : [],
      value: filters?.c,
    },
  ];

  return { filterFields };
};

export default useFilters;
