const columns = [
  {
    fieldName: `slNo`,
    label: 'Sl No',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: `documentName`,
    label: 'Document name',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: `section`,
    label: 'Section',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
];

const holidaysColumn = [
  {
    fieldName: `slNo`,
    label: 'Sl No',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: `date`,
    label: 'Date',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: `occasion`,
    label: 'Occasion',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: `holidayType`,
    label: 'Holiday Type',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: `description`,
    label: 'Description',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
];

const useColumns = () => {
  return [columns];
};

const useHolidayColumn = () => {
  return [holidaysColumn];
};

export { useColumns, useHolidayColumn };
