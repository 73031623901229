import React from 'react';
import PropTypes from 'prop-types';
import Typographys from 'components/Atoms/Typography';
import { Box } from '@mui/material';
import Chips from 'components/Molecules/Chip';
import { statusColor } from 'utils/statusColor';
import Avatars from 'components/Molecules/Avatar';
import Checkbox from 'components/Atoms/Checkbox';
import Table from './Table';
import { teamAttendanceStyle } from '../style';

const TeamAttendanceGridTable = ({
  isCheckAttendance,
  handleSelectAttendance,
  loading,
  attendanceDataColumn,
  attendanceDataRow,
  page,
  sortDirection,
  sortBy,
  handleSort,
  count,
  onPageChange,
  onSortChange,
}) => {
  return (
    <Box sx={teamAttendanceStyle.teamAttendanceGridTableWrapper}>
      {attendanceDataRow.map((list) => (
        <Box key={list.empId}>
          <Box sx={teamAttendanceStyle.teamAttendanceGridTableHeader}>
            <Checkbox
              checked={isCheckAttendance}
              onChange={handleSelectAttendance}
              size="small"
              name="employeeChecked"
            />
            <Avatars avatarSrc={list.empImgSrc} isShortName sx={{ width: '3rem', height: '3rem' }} />
            <Box>
              <Typographys variant="h6">
                {list.empName}({list.empId}): ({list.filterDate})
              </Typographys>
            </Box>
          </Box>
          <Table
            rows={list.days.map((i) => {
              return {
                ...i,
                attendance: <Chips label={i.attendance} isShowIcon={false} color={statusColor(i.attendance)} />,
                attendanceLabel: i.attendance,
                isShowActionList: i.attendance !== 'Holiday',
              };
            })}
            loading={loading}
            columns={attendanceDataColumn}
            showCheckbox={false}
            count={count}
            handleSort={handleSort}
            sortBy={sortBy}
            sortDirection={sortDirection}
            showPagination={false}
            page={page}
            onPageChange={onPageChange}
            onSortChange={onSortChange}
          />
        </Box>
      ))}
    </Box>
  );
};

TeamAttendanceGridTable.defaultProps = {
  handleSelectAttendance: () => {},
  isCheckAttendance: false,
  loading: false,
  attendanceDataColumn: [],
  attendanceDataRow: [],
  handleSort: () => {},
  sortBy: () => {},
  sortDirection: 'asc',
  count: 10,
  page: '',
  onPageChange: () => {},
  onSortChange: () => {},
};
TeamAttendanceGridTable.propTypes = {
  handleSelectAttendance: PropTypes.func,
  isCheckAttendance: PropTypes.bool,
  loading: PropTypes.bool,
  attendanceDataColumn: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),
  attendanceDataRow: PropTypes.arrayOf(
    PropTypes.shape({
      empName: PropTypes.string,
      empImgSrc: PropTypes.string,
      empId: PropTypes.string,
      filterDate: PropTypes.string,
      days: PropTypes.arrayOf(
        PropTypes.shape({
          day: PropTypes.string,
          firstTimeIn: PropTypes.string,
          lastTimeOut: PropTypes.string,
          duration: PropTypes.string,
          interimPunches: PropTypes.string,
          attendance: PropTypes.string,
          exception: PropTypes.string,
          attendanceMarking: PropTypes.string,
        }),
      ),
    }),
  ),
  sortBy: PropTypes.func,
  handleSort: PropTypes.func,
  sortDirection: PropTypes.string,
  count: PropTypes.number,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  onSortChange: PropTypes.func,
};

export default TeamAttendanceGridTable;
