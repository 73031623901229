import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Divider } from '@mui/material';
import { validateDateElseHyphen } from 'utils/commonUtils';
import TitleWithText from 'components/Atoms/TitleWithText';
import TitleWithTextInline from 'components/Atoms/TitleWithTextInline';
import { useTheme } from '@mui/material/styles';
import Typography from 'components/Atoms/Typography';

const ReportExpenseDetails = ({
  reportDetails,
  amountData,
  showActions,
  showActionDialog,
  isApprove,
  handleClose,
  handleCancel,
  handleSubmit,
  onChange,
}) => {
  const theme = useTheme();

  return (
    <Box padding="2rem 3rem" className="report-expenses-details">
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <TitleWithTextInline title="Report ID:" text={reportDetails.reportId} />
        <TitleWithTextInline title="Report Status:" text={reportDetails.reportStatus} />
        <TitleWithTextInline title="Settlement Status:" text={reportDetails.settlementStatus} />
      </Box>

      <Divider sx={{ my: 0.5, backgroundColor: theme.palette.neutral[200] }} />

      <Grid container columnSpacing={9}>
        <Grid item xs={12} md={3}>
          <TitleWithText title="Employee name" text={reportDetails.employeeName} />
        </Grid>
        <Grid item xs={12} md={3}>
          <TitleWithText title="Employee ID" text={reportDetails.employeeId} />
        </Grid>
        <Grid item xs={12} md={3}>
          <TitleWithText
            title="Expense period start date"
            text={validateDateElseHyphen(reportDetails.expensePeriodStartDate, 'DD MMMM, YYYY')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TitleWithText
            title="Expense period end date"
            text={validateDateElseHyphen(reportDetails.expensePeriodEndDate, 'DD MMMM, YYYY')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TitleWithText title="Department" text={reportDetails.department} />
        </Grid>
        <Grid item xs={12} md={3}>
          <TitleWithText title="Designation" text={reportDetails.designation} />
        </Grid>
        <Grid item xs={12} md={3}>
          <TitleWithText title="Reporting Manager" text={reportDetails.reportingManager} />
        </Grid>
        <Grid item xs={12} md={3}>
          <TitleWithText title="Location" text={reportDetails.location} />
        </Grid>
        <Grid item xs={12} md={3}>
          <TitleWithText title="Business Purpose" text={reportDetails.businessPurpose} />
        </Grid>
      </Grid>

      <Divider
        sx={{
          mt: 0.5,
          mb: 2,
          backgroundColor: theme.palette.neutral[200],
        }}
      />

      <Box bgcolor="neutral.100" padding="2rem 3rem" borderRadius="0.75rem">
        <Grid container columnSpacing={9}>
          {amountData.map((item) => (
            <Grid item xs={12} md={4}>
              <Typography variant="body2" fontWeight={500} sx={{ mb: 1 }}>
                {item.label}
              </Typography>
              <Typography variant="h3">{item.amount}</Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Divider
        sx={{
          mt: 2.5,
          mb: 0.5,
          backgroundColor: theme.palette.neutral[200],
        }}
      />
    </Box>
  );
};

ReportExpenseDetails.defaultProps = {
  reportDetails: {
    title: '',
    address: '',
    employeeName: '',
    employeeId: '',
    expensePeriodStartDate: '',
    expensePeriodEndDate: '',
    department: '',
    designation: '',
    reportingManager: '',
    location: '',
    businessPurpose: '',
    reportId: '',
    reportStatus: '',
    settlementStatus: '',
  },
  amountData: [],
  showActions: true,
  showActionDialog: false,
  isApprove: true,
  handleClose: () => {},
  handleCancel: () => {},
  handleSubmit: () => {},
  onChange: () => {},
};

ReportExpenseDetails.propTypes = {
  reportDetails: PropTypes.shape({
    title: PropTypes.string,
    address: PropTypes.string,
    employeeName: PropTypes.string,
    employeeId: PropTypes.string,
    expensePeriodStartDate: PropTypes.string,
    expensePeriodEndDate: PropTypes.string,
    department: PropTypes.string,
    designation: PropTypes.string,
    reportingManager: PropTypes.string,
    location: PropTypes.string,
    businessPurpose: PropTypes.string,
    reportId: PropTypes.string,
    reportStatus: PropTypes.string,
    settlementStatus: PropTypes.string,
  }),
  amountData: PropTypes.arrayOf,
  showActions: PropTypes.bool,
  showActionDialog: PropTypes.bool,
  isApprove: PropTypes.bool,
  handleClose: PropTypes.func,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  onChange: PropTypes.func,
};

export default ReportExpenseDetails;
