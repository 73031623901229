import * as React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import DoneIcon from '@mui/icons-material/Done';

const Chips = ({ variant, size, color, deleteIcon, label, onClick, disabled, isShowIcon, ...props }) => {
  const renderDeleteIcon = () => {
    if (isShowIcon) {
      return deleteIcon ? <DoneIcon /> : deleteIcon;
    }
    return null;
  };

  return (
    <Chip
      variant={variant}
      size={size}
      color={color}
      deleteIcon={renderDeleteIcon()}
      label={label}
      onClick={onClick}
      disabled={disabled}
      {...props}
    />
  );
};

Chips.defaultProps = {
  label: 'Chip',
  variant: 'filled',
  size: 'medium',
  color: 'primary',
  onClick: () => {},
  deleteIcon: null,
  disabled: false,
  isShowIcon: true,
};

Chips.propTypes = {
  label: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  deleteIcon: PropTypes.node,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  isShowIcon: PropTypes.bool,
};

export default Chips;
