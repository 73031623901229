import React from 'react';
import { Box } from '@mui/material';
import Table from 'components/mui/Table';
import TableShimmer from 'components/mui/TableShimmer';
import useController from 'hooks/useController';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import useColumns from './useColumns';

const PayPeriodListing = ({ data }) => {
  const [controller, handlePageChange, handleSortChange] = useController();
  const [columns] = useColumns();
  const { payrollCalendars } = useSelector((state) => state.payrollCalendars);

  return (
    <Box>
      {!data?.length ? (
        <TableShimmer columns={columns} />
      ) : (
        <Table
          columns={columns}
          rows={data}
          keyColumn="id"
          sortDirection={controller?.sort}
          sortBy={controller?.sortBy}
          onSortChange={handleSortChange}
          page={controller?.page}
          onPageChange={handlePageChange}
          count={payrollCalendars.count}
          showPagination={false}
        />
      )}
    </Box>
  );
};

PayPeriodListing.defaultProps = {
  data: [],
};

PayPeriodListing.propTypes = {
  data: PropTypes.instanceOf(Array),
};

export default PayPeriodListing;
