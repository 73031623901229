import { Box, Divider, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import { TabContext, TabPanel } from '@mui/lab';
import { styles } from 'pages/Company/Salary/PayrollCalendar/ViewPayrollCalendar/styles';
import AdminView from './adminSalaryView';

const views = [
  {
    label: 'Admin Salary View',
    value: 'admin-salary-view',
  },
  {
    label: 'Employee Salary View',
    value: 'employee-salary-view',
  },
];

const SalaryStructureTabs = () => {
  const [view, setView] = useState('admin-salary-view');

  return (
    <TabContext value={view}>
      <Box>
        <Tabs value={view} onChange={(event, value) => setView(value)} aria-label="basic tabs example">
          {views.map((option, index) => (
            <Tab
              sx={{ ml: '16px !important', mr: '16px !important' }}
              disabled={false}
              label={option.label}
              value={option.value}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            />
          ))}
        </Tabs>
        <Divider sx={styles.divider} />
      </Box>
      <TabPanel sx={{ p: 3 }} value="admin-salary-view">
        <AdminView isAdmin />
      </TabPanel>
      <TabPanel sx={{ p: 3 }} value="employee-salary-view">
        <AdminView isAdmin={false} />
      </TabPanel>
    </TabContext>
  );
};

export default SalaryStructureTabs;
