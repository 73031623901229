import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import MuiTab from 'components/Molecules/Tab';
import UserIdentity from './UserIdentity';
import UserRole from './UserRoles';
import BasicProfile from './BasicProfile';
import AdditionalDetails from './AdditionalDetails';
import ContactDetail from './ContactDetails';
import BankAccount from './BankAccount';
import PayrollSetting from './PayrollSetting';
import ExtendedAttribute from './ExtendedAttribute';
import Timeline from './Timeline';
import AuditTrail from './AuditTrail';
import NotificationSetting from './NotificationSetting';

const EmployeeDetailTab = ({ employeeDetails }) => {
  const initialTabs = [
    {
      label: 'User Identity',
      onChange: () => {},
      RenderComponent: UserIdentity,
      componentProps: { ...employeeDetails['User Identity'] },
    },
    {
      label: 'User Roles',
      onChange: () => {},
      RenderComponent: UserRole,
      componentProps: { ...employeeDetails['User Roles'] },
    },
    {
      label: 'Basic Profile',
      onChange: () => {},
      RenderComponent: BasicProfile,
      componentProps: { ...employeeDetails['Basic Profile'] },
    },
    {
      label: 'Additional Details',
      onChange: () => {},
      RenderComponent: AdditionalDetails,
      componentProps: { ...employeeDetails['Additional Details'] },
    },
    {
      label: 'Contact Details',
      onChange: () => {},
      RenderComponent: ContactDetail,
      componentProps: { ...employeeDetails['Contact Details'] },
    },
    {
      label: 'Bank Account',
      onChange: () => {},
      RenderComponent: BankAccount,
      componentProps: { ...employeeDetails['Bank Account'] },
    },
    {
      label: 'Payroll Setting',
      onChange: () => {},
      RenderComponent: PayrollSetting,
      componentProps: { ...employeeDetails['Payroll Setting'] },
    },
    {
      label: 'Extended Attributes',
      onChange: () => {},
      RenderComponent: ExtendedAttribute,
      componentProps: { ...employeeDetails['Extended Attributes'] },
    },
    {
      label: 'Timelines',
      onChange: () => {},
      RenderComponent: employeeDetails.Timelines.RenderComponent ? employeeDetails.Timelines.RenderComponent : <div />,
      componentProps: { ...employeeDetails.Timelines },
    },
    {
      label: 'Audit Trail',
      onChange: () => {},
      RenderComponent: AuditTrail,
      componentProps: { ...employeeDetails['Audit Trail'] },
    },
    {
      label: 'Notification Setting',
      onChange: () => {},
      RenderComponent: NotificationSetting,
      componentProps: { ...employeeDetails['Notification Setting'] },
    },
  ];

  return (
    <Box sx={{ borderTop: '0.125rem solid', borderColor: 'other.200' }}>
      <MuiTab
        key={initialTabs}
        orientation="horizontal"
        variant="scrollable"
        scrollButtons
        textColor="primary"
        indicatorColor="primary"
        tabs={initialTabs}
        allowScrollButtonsMobile
      />
    </Box>
  );
};

EmployeeDetailTab.defaultProps = {
  employeeDetails: {},
};

EmployeeDetailTab.propTypes = {
  employeeDetails: PropTypes.shape({
    Timelines: PropTypes.shape({}),
    'Audit Trail': PropTypes.shape({}),
    'Notification Setting': PropTypes.shape({}),
    'Extended Attributes': PropTypes.shape({}),
    'Payroll Setting': PropTypes.shape({}),
    'Bank Account': PropTypes.shape({}),
    'Contact Details': PropTypes.shape({}),
    'Additional Details': PropTypes.shape({}),
    'Basic Profile': PropTypes.shape({}),
    'User Identity': PropTypes.shape({}),
    'User Roles': PropTypes.shape({}),
  }),
};

export default EmployeeDetailTab;
