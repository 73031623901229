import React from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell, Legend } from 'recharts';
import GraphColor from 'utils/GraphColor';
import Typographys from 'components/Atoms/Typography';
import { Box } from '@mui/material';
import CustomLegend from 'components/Molecules/CustomLegend';
import { chartStyle } from '../style';

const PieCharts = ({
  data,
  colors,
  isShowCustomLegend,
  legendOptions,
  chartOptions,
  isShowChartInfo,
  chartInfoLeft,
  isLegendTitle,
  legendTitle,
}) => {
  return (
    <Box sx={chartStyle.chartWrapper} width={chartOptions.chartWidth} height={chartOptions.chartHeight}>
      <PieChart width={chartOptions.chartWidth} height={chartOptions.chartHeight}>
        <Pie
          data={data}
          innerRadius={chartOptions.innerRadius}
          outerRadius={chartOptions.outerRadius}
          paddingAngle={chartOptions.paddingAngle}
          dataKey="value"
          stroke=""
          cx={chartOptions.cx}
          cy={chartOptions.cy}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${entry.name}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>

        {isShowCustomLegend && (
          <Legend
            layout={legendOptions.layout}
            align={legendOptions.align}
            verticalAlign={legendOptions.verticalAlign}
            content={
              <CustomLegend
                layout={legendOptions.layout}
                iconType={legendOptions.iconType}
                iconWidth={legendOptions.iconWidth}
                iconHeight={legendOptions.iconHeight}
                legendLeftSpacing={legendOptions.legendLeftSpacing}
                isLegendValue={legendOptions.isLegendValue}
                isLegendTitle={isLegendTitle}
                legendTitle={legendTitle}
              />
            }
          />
        )}
      </PieChart>

      {isShowChartInfo && (
        <Box sx={chartStyle.chartInfo} left={chartInfoLeft}>
          <Typographys variant="body2" color="neutral.800" align="center">
            Salary <br /> composition
          </Typographys>
        </Box>
      )}
    </Box>
  );
};

PieCharts.defaultProps = {
  data: [],
  colors: GraphColor('pieChartsColors'),
  isShowChartInfo: null,
  isShowCustomLegend: null,
  legendOptions: {},
  chartOptions: {},
  chartInfoLeft: '',
  isLegendTitle: false,
  legendTitle: '',
};

PieCharts.propTypes = {
  isShowChartInfo: PropTypes.bool,
  isShowCustomLegend: PropTypes.bool,
  chartInfoLeft: PropTypes.string,
  legendOptions: PropTypes.shape({
    layout: PropTypes.string,
    verticalAlign: PropTypes.string,
    align: PropTypes.string,
    iconType: PropTypes.string,
    iconSize: PropTypes.string,
    iconWidth: PropTypes.string,
    iconHeight: PropTypes.string,
    legendLeftSpacing: PropTypes.string,
    isLegendValue: PropTypes.bool,
  }),
  chartOptions: PropTypes.shape({
    chartWidth: PropTypes.number,
    chartHeight: PropTypes.number,
    paddingAngle: PropTypes.number,
    innerRadius: PropTypes.number,
    outerRadius: PropTypes.number,
    cx: PropTypes.string,
    cy: PropTypes.string,
  }),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  colors: PropTypes.arrayOf(PropTypes.string),
  isLegendTitle: PropTypes.bool,
  legendTitle: PropTypes.string,
};

export default PieCharts;
