import { useSelector } from 'react-redux';
import { commonFilteredActionItems } from 'utils/commonUtils';
import { PAST_EMPLOYER_TAXES_STATUS_IN_DRAFT } from 'utils/SystemConfigConstants';

const getActionItems = (services) => {
  return [
    {
      label: 'Submit For Review',
      onClick: services.handleSubmitDialog,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_PAY_INDEX_SUBMIT_FOR_REVIEW_PREVIOUS_EMP_TAX_DETAILS_BY_EMPLOYEE'],
      conditional: (row) => row?.status === PAST_EMPLOYER_TAXES_STATUS_IN_DRAFT,
    },
    {
      label: 'Delete',
      onClick: services.handleDeleteDialog,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_PAY_INDEX_DELETE_PREVIOUS_EMP_TAX_DETAILS_BY_EMPLOYEE'],
      conditional: (row) => row?.status === PAST_EMPLOYER_TAXES_STATUS_IN_DRAFT,
    },
  ];
};

const useMenuItems = (row, services) => {
  const actions = getActionItems(services);
  const authorities = useSelector((state) => state.session.user.authorities);
  const moreFilteredActionItems = commonFilteredActionItems(authorities, actions, row);
  return [moreFilteredActionItems];
};

export default useMenuItems;
