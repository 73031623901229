import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import ProfileEditBankAccount from 'components/Organisms/Profile/BankAccount/Edit';
import useToast from 'components/Provider/useToast';
import { getToastMessage } from 'utils/toastMessage';

const ProfileEditBankAccountTemplate = ({
  heading,
  handleSubmit,
  handleCancel,
  transactionTypeOptions,
  bankNameOptions,
  accountCurrencyOptions,
  purposeOptions,
  accountTypeOptions,
  swiftCodeTypeOptions,
  stateOptions,
  cityOptions,
  countryOptions,
  label,
  defaultValue,
  toastSuccessMessage,
  toastErrorMessage,
  showToast,
  toastType,
  loading,
}) => {
  const addToast = useToast();

  useEffect(() => {
    if (showToast) {
      const successMessage = toastSuccessMessage || getToastMessage.ProfileBankAccount()[0];
      const error = toastErrorMessage || getToastMessage.ProfileBankAccount()[1];

      const title = toastType === 'success' ? 'Success!' : 'Error!';
      const type = toastType === 'success' ? 'success' : 'error';
      const message = toastType === 'success' ? successMessage : error;
      addToast({ type, title, message });
    }
  }, [showToast, toastErrorMessage, toastSuccessMessage, toastType]);

  const breadcrumbData = [
    { id: 1, name: 'Bank Account', path: '/profile/bank-accounts', isActive: false },
    { id: 2, name: 'Edit', isActive: true },
  ];
  return (
    <BlankTemplate
      pageHeading="Edit Bank Account Details"
      isBreadcrumb
      breadcrumbData={breadcrumbData}
      padding="2rem 3rem"
    >
      <ProfileEditBankAccount
        heading={heading}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        transactionTypeOptions={transactionTypeOptions}
        bankNameOptions={bankNameOptions}
        accountCurrencyOptions={accountCurrencyOptions}
        purposeOptions={purposeOptions}
        accountTypeOptions={accountTypeOptions}
        swiftCodeTypeOptions={swiftCodeTypeOptions}
        stateOptions={stateOptions}
        cityOptions={cityOptions}
        countryOptions={countryOptions}
        label={label}
        defaultValue={defaultValue}
        loading={loading}
      />
    </BlankTemplate>
  );
};

ProfileEditBankAccountTemplate.defaultProps = {
  heading: 'Primary Bank Details',
  handleSubmit: () => {},
  handleCancel: () => {},
  transactionTypeOptions: [],
  bankNameOptions: [],
  accountTypeOptions: [],
  swiftCodeTypeOptions: [],
  stateOptions: [],
  countryOptions: [],
  accountCurrencyOptions: [],
  purposeOptions: [],
  cityOptions: [],
  label: '',
  loading: false,
  defaultValue: {},
  toastSuccessMessage: '',
  toastErrorMessage: '',
  showToast: false,
  toastType: 'success',
};

ProfileEditBankAccountTemplate.propTypes = {
  heading: PropTypes.string,

  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  loading: PropTypes.bool,
  toastType: PropTypes.oneOf(['error', 'success']),
  transactionTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  bankNameOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  accountTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  swiftCodeTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  stateOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  cityOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  countryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  purposeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  accountCurrencyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  label: PropTypes.string,
  defaultValue: PropTypes.shape({}),
  toastSuccessMessage: PropTypes.string,
  toastErrorMessage: PropTypes.string,
  showToast: PropTypes.bool,
};

export default ProfileEditBankAccountTemplate;
