import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import PropTypes from 'prop-types';
import Logo from 'components/Atoms/Logo';
import Typographys from 'components/Atoms/Typography';
import Login from 'components/Organisms/Login/index';
import { useTheme } from '@mui/material/styles';
import defaultHeroImage from '../../../assets/images/overview.png';

const LoginTemplate = ({
  handleOnSubmit,
  forgotPasswordLink,
  headingText,
  subHeading,
  heroImage = defaultHeroImage,
}) => {
  const theme = useTheme();
  return (
    <Box sx={{ flexGrow: 1, height: '100vh', p: { xs: 3, md: 0 } }}>
      <Hidden mdUp>
        <Box>
          <Logo alt="Logo" isDark imgHeight="32" imgWidth="115" />
        </Box>
      </Hidden>
      <Grid container height="100%">
        <Hidden mdDown>
          <Grid
            item
            xs={6}
            sx={{
              background: 'radial-gradient(109.43% 109.43% at 98.49% 99.08%, #2C677A 0%, #11262E 100%)',
              overflow: 'hidden',
              position: 'relative',
              padding: 6.5,
            }}
          >
            <Logo alt="Logo" isDark={false} />

            <Box mt={{ xs: 5, md: 8.5 }}>
              <Box zIndex={9} width={1} maxWidth={705}>
                <Typographys variant="h2" color="common.white">
                  {headingText}
                </Typographys>
                <Typographys variant="body2" mt={1} color="common.white">
                  {subHeading}
                </Typographys>
              </Box>

              <Box
                sx={{
                  position: 'absolute',
                  right: 0,
                  bottom: '2rem',
                  zIndex: '1',
                  maxWidth: '100%',
                  [theme.breakpoints.down(1400)]: { maxWidth: '68.75rem' },
                }}
              >
                <img src={heroImage} alt="ANSR Overview" width="100%" />
              </Box>
            </Box>
          </Grid>
        </Hidden>

        <Grid item xs={12} md={6} display="flex" alignItems="center" justifyContent="center">
          <Login handleOnSubmit={handleOnSubmit} forgotPasswordLink={forgotPasswordLink} />
        </Grid>
      </Grid>
    </Box>
  );
};

LoginTemplate.defaultProps = {
  forgotPasswordLink: '/reset',
  headingText: 'BUILDING GLOBAL TEAMS',
  subHeading: `Enabling enterprises to build and scale global teams through effective Demand planning,
    tracking and Change Management.`,
  heroImage: defaultHeroImage,
  handleOnSubmit: () => {},
};

LoginTemplate.propTypes = {
  forgotPasswordLink: PropTypes.string,
  headingText: PropTypes.string,
  subHeading: PropTypes.string,
  heroImage: PropTypes.string,
  handleOnSubmit: PropTypes.func,
};

export default LoginTemplate;
