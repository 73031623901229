import React from 'react';
import {
  FormHelperText,
  Grid,
  Stack,
  Skeleton,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/system';
import InputField from 'components/Atoms/InputField';
import DatePickers from 'components/Atoms/Datepicker';
import Button from 'components/Atoms/Button';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import Typographys from 'components/Atoms/Typography';

const BasicInfoForm = ({ isEditMode, payrollCalendar, formik, loading, handleBack }) => {
  const { defaultEmploymentTypes } = payrollCalendar?.data || {};

  if (payrollCalendar?.isLoading || !defaultEmploymentTypes?.length) {
    return (
      <Box sx={{ py: 4 }}>
        <Skeleton height={42} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }
  return (
    <Grid container spacing={4} sx={{ py: 4 }}>
      <Grid item md={12} xs={12}>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <Grid container columnSpacing={6} rowSpacing={3}>
              <Grid item xs={12}>
                <Typographys variant="h6">Basic Payroll Calendar Details</Typographys>
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  label="Name*"
                  name="name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={Boolean(formik.touched.name && formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  value={formik.values.name}
                />
              </Grid>
              <Grid item xs={4}>
                <DatePickers
                  id="Calendar Start Date"
                  label="Calendar Start Date*"
                  name="calendarStartDate"
                  value={formik.values.calendarStartDate}
                  onChange={(value) => {
                    formik.setFieldValue('calendarStartDate', dayjs(value));
                  }}
                  error={
                    Boolean(formik.touched.calendarStartDate && formik.errors.calendarStartDate) ||
                    (formik.touched.calendarStartDate && !formik.values.calendarStartDate)
                  }
                  helperText={formik.touched.startDate && formik.errors.startDate}
                  onBlur={formik.handleBlur}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <DatePickers
                  id="Calendar End Date"
                  error={Boolean(formik.touched.calendarEndDate && formik.errors.calendarEndDate)}
                  label="Calendar End Date*"
                  name="calendarEndDate"
                  value={formik.values.calendarEndDate}
                  onChange={(value) => {
                    formik.setFieldValue('calendarEndDate', dayjs(value));
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  error={Boolean(formik.touched.financialYear && formik.errors.financialYear)}
                  helperText={formik.touched.financialYear && formik.errors.financialYear}
                  label="Financial Year*"
                  name="financialYear"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.financialYear}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  error={Boolean(formik.touched.assessmentYear && formik.errors.assessmentYear)}
                  helperText={formik.touched.assessmentYear && formik.errors.assessmentYear}
                  label="Assessment Year"
                  name="assessmentYear"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.assessmentYear}
                />
              </Grid>
              <Grid item xs={12}>
                <Typographys variant="subtitle2">Applicable Employment Type*</Typographys>
              </Grid>
              <Grid item xs={8}>
                <FormControl sx={{ m: 1, minWidth: '200px' }}>
                  <InputLabel id="employementType-label">Applicable Employment Type*</InputLabel>
                  <Select
                    labelId="employementType-label"
                    error={Boolean(formik.touched.employementType && formik.errors.employementType)}
                    name="employementType"
                    id="employementType"
                    multiple
                    onChange={(event) => {
                      const {
                        target: { value },
                      } = event;
                      formik.setFieldValue('employementType', typeof value === 'string' ? value.split(',') : value);
                    }}
                    value={formik.values.employementType}
                    fullWidth
                    input={<OutlinedInput label="Name" />}
                  >
                    {defaultEmploymentTypes.map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {formik.errors.submit && (
                <Grid item xs={12}>
                  <FormHelperText error>{formik.errors.submit}</FormHelperText>
                </Grid>
              )}
              <Grid item xs={12}>
                <Stack direction="row" justifyContent={isEditMode ? 'space-between' : 'end'}>
                  {/* {isEditMode && (
                    <Button color="primary" size="large" variant="outlined">
                      Delete
                    </Button>
                  )} */}
                  <Stack direction="row" justifyContent="end" spacing={2}>
                    <Button
                      type="button"
                      size="Large"
                      variant="outlined"
                      sx={{ color: 'text.200 !important' }}
                      onClick={handleBack}
                    >
                      Cancel
                    </Button>
                    <LoadingButton loading={loading} color="primary" size="large" type="submit" variant="contained">
                      Next
                    </LoadingButton>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </div>
        </form>
      </Grid>
    </Grid>
  );
};

export default BasicInfoForm;

BasicInfoForm.propTypes = {
  payrollCalendar: PropTypes.shape(PropTypes.node),
  isEditMode: PropTypes.bool,
  formik: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.bool,
  handleBack: PropTypes.func,
};

BasicInfoForm.defaultProps = {
  payrollCalendar: {},
  isEditMode: false,
  loading: false,
  handleBack: () => {},
};
