import { useSelector } from 'react-redux';

import { hasPermission } from 'utils/permissions';

const getMenuItems = (services) => {
  return [
    {
      label: 'Add New',
      onClick: services.addNewEmployee,
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_CREATE_SECUSER'],
    },
    {
      label: 'Bulk Upload',
      onClick: services.bulkUploadEmployees,
      permissions: [''],
    },
    {
      label: 'Send Bulk ESS Invites',
      onClick: services.sendBulkESSInvite,
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_SEND_ESS_INVITE_TO_EMPLOYEE'],
    },
    {
      label: 'Download',
      onClick: services.downloadEmployees,
      permissions: [''],
    },
  ];
};

const useMenuItems = (services) => {
  const { user } = useSelector((state) => state?.session);

  const menuItems = getMenuItems(services);
  const filteredActionItem = menuItems.filter((action) => hasPermission(user.authorities, action.permissions));

  return [filteredActionItem];
};

export default useMenuItems;
