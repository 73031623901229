import { formDataApi } from 'api/api';

export const listEmployeesAction = async (query) => {
  const response = await formDataApi.get(`restEmpIndex/listUsers?${query.toString()}`);
  return response?.data;
};

export const searchEmployeesAction = async (query) => {
  const response = await formDataApi.post(`restEmpIndex/listUsers?${query.toString()}`);
  return response?.data;
};

export const updateEmployeeSearchAction = async (bodyFormData) => {
  const response = await formDataApi.post(`restEmpIndex/updateEmployeeSearchAction`, bodyFormData);
  return response;
};

export const updateEmployeeSearchActionWithQueryParam = async (query) => {
  const response = await formDataApi.post(`restEmpIndex/updateEmployeeSearchAction?${query.toString()}`);
  return response?.data;
};

export const viewEmployeesAction = async (query) => {
  const response = await formDataApi.post(`restEmpIndex/displayUser`, query);
  return response?.data;
};

export const viewMyOnboardingRecordAction = async () => {
  const response = await formDataApi.post(`restEmpIndex/viewMyOnBoardingRecords`);
  return response?.data;
};

export const deleteEmployeesAction = async (query) => {
  const response = await formDataApi.post(`restEmpIndex/deleteUser`, query);
  return response;
};

export const lockEmployeesAction = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/lockEmployee`, formData);
  return response;
};

export const submitLifeCycleRecord = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/submitLifeCycleRecord`, formData);
  return response;
};

export const approveOrRejectLifeCycleRecordAction = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/approveOrRejectLifeCycleRecordAction`, formData);
  return response;
};

export const unLockEmployeesAction = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/unlockEmployee`, formData);
  return response;
};

export const activateEmployeesAction = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/activateUser`, formData);
  return response;
};

export const deActivateEmployeesAction = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/deactivateUser`, formData);
  return response;
};

export const sendESSInviteAction = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/sendESSInviteToEmp`, formData);
  return response;
};

export const convertToCandidateAction = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/convertEmployeeToCandidate`, formData);
  return response;
};

export const preAddNoteEmployeeAction = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/addNoteEmployee?`, formData);
  return response?.data;
};

export const addNoteEmployeeAction = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/addNoteEmployeeAction`, formData);
  return response;
};

export const fetchMapLifeCyclePoliciesAction = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/listLifeCyclePolicies`, formData);
  return response;
};

export const mapEmployeeToLifeCyclePolicyAction = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/mapEmployeeToLifeCyclePolicyAction`, formData);
  return response;
};

export const fetchGenericEmployeeGroupAction = async (query) => {
  const response = await formDataApi.get(`RestGenericGroup/index?${query.toString()}`);
  return response;
};

export const mapGenericGroupEmployeeAction = async (formData) => {
  const response = await formDataApi.post(`RestGenericGroup/index`, formData);
  return response;
};

export const saveUserAction = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/saveUser`, formData);
  return response;
};

export const updateOnBoardingRecordsAction = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/updateOnBoardingRecordsAction`, formData);
  return response;
};

export const modifyUserAction = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/modifyUserAction`, formData);
  return response;
};

export const displayEmployeeTimeline = async (formData) => {
  const response = await formDataApi.put(`restEmpIndex/displayEmployeeChangeTimeLine`, formData);
  return response;
};

export const displaySalaryTimeline = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/displayEmployeeSalaryChangeOnlyTimeLine`, formData);
  return response;
};

export const displayTotalTimeline = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/displayCompleteEmployeeChangeTimeLine`, formData);
  return response;
};

/**
 * APIs related to employee lifecycle policies
 */

export const listLifeCyclePolicies = async (args) => {
  const response = await formDataApi.get(`restEmpIndex/listLifeCyclePolicies`);
  return response;
};

export const displayLifecyclePolicy = async (args) => {
  const bodyFormData = new FormData();
  bodyFormData.append('id', args.id);
  const response = await formDataApi.post(`restEmpIndex/displayLifeCyclePolicy`, bodyFormData);
  return response;
};

// return list of employees mapped to a specific lifecycle policy
export const viewEmployeesMappedToLifeCyclePolicy = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/viewEmployeesMappedToLifeCyclePolicy`, formData);
  return response;
};

// return all the employees with lifecycle policy mapped to them. Also return mapped and unmapped count
export const viewEmployeeLifeCyclePolicyMappings = async () => {
  const response = await formDataApi.post(`restEmpIndex/viewEmployeeLifeCyclePolicyMappings`);
  return response;
};

// return list of employees, mapped policy to them and all the lifecycle policies
export const bulkUpdateEmployeeLifeCycleMappings = async () => {
  const response = await formDataApi.get(`restEmpIndex/bulkUpdateEmployeeLifeCycleMappings`);
  return response;
};
/**
 *
 * @param formData is very specific id: 19805, emp_19806: 182200, emp_21789: , and include all the employees list
 * @returns
 */
export const bulkUpdateEmployeeLifeCycleMappingsAction = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/bulkUpdateEmployeeLifeCycleMappingsAction`, formData);
  return response;
};

export const updateEmployeesMappedToLifeCyclePolicyAction = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/updateEmployeesMappedToLifeCyclePolicyAction`, formData);
  return response;
};

/**
 * List of APIs related to AccessFilter Starts here
 */

export const viewAccessFilters = async (args) => {
  const bodyFormData = new FormData();
  bodyFormData.append('empID', args.empID);
  const response = await formDataApi.post(`restEmpIndex/viewAccessFilters`, bodyFormData);
  return response;
};

export const searchLifeCycleCaseRecords = async () => {
  const response = await formDataApi.get(`restEmpIndex/searchLifeCycleCaseRecords`);
  return response;
};

export const searchLifeCycleCaseRecordsAction = async (args) => {
  const response = await formDataApi.post(`restEmpIndex/searchLifeCycleCaseRecordsAction`, args);
  return response;
};

export const addAccessFilterAction = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/addAccessFilterAction`, formData);
  return response;
};

export const modifyAccessFilterAction = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/modifyAccessFilterAction`, formData);
  return response;
};
export const deleteAccessFilterAction = async (query) => {
  const response = await formDataApi.get(`restEmpIndex/deleteAccessFilter?${query.toString()}`);
  return response;
};
/*
 * APIs related to employee record actions
 */
export const confirmEmployeeData = async (query) => {
  const response = await formDataApi.get(`restEmpIndex/confirmEmployee?${query.toString()}`);
  return response?.data;
};

export const confirmEmployeeAction = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/confirmEmployeeAction`, formData);
  return response;
};

export const demoteEmployeeData = async (query) => {
  const response = await formDataApi.get(`restEmpIndex/demoteEmployee?${query.toString()}`);
  return response?.data;
};

export const demoteEmployeeAction = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/demoteEmployeeAction`, formData);
  return response;
};

export const promoteEmployeeData = async (query) => {
  const response = await formDataApi.get(`restEmpIndex/promoteEmployee?${query.toString()}`);
  return response?.data;
};

export const promoteEmployeeAction = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/promoteEmployeeAction`, formData);
  return response;
};

export const transferEmployeeData = async (query) => {
  const response = await formDataApi.get(`restEmpIndex/transferEmployee?${query.toString()}`);
  return response?.data;
};

export const transferEmployeeAction = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/transferEmployeeAction`, formData);
  return response;
};

export const terminateEmployeeData = async (query) => {
  const response = await formDataApi.get(`restEmpIndex/terminateEmployee?${query.toString()}`);
  return response?.data;
};

export const terminateEmployeeAction = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/terminateEmployeeAction`, formData);
  return response;
};

export const listLCRecordsAction = async (query) => {
  const response = await formDataApi.get(`restEmpIndex/listLifeCycleCaseRecords?${query.toString()}`);
  return response?.data;
};

export const searchLCRecordsAction = async (query) => {
  const response = await formDataApi.post(`restEmpIndex/searchLifeCycleCaseRecordsAction?${query.toString()}`);
  return response?.data;
};

export const viewLCRecordsAction = async (query) => {
  const response = await formDataApi.post(`restEmpIndex/viewLifeCycleCaseRecord`, query);
  return response?.data;
};

export const approveOrRejectLifeCycleRecord = async (query) => {
  const response = await formDataApi.post(`restEmpIndex/approveOrRejectLifeCycleRecord`, query);
  return response?.data;
};

export const modifyLCRecordsAction = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/modifyLifeCycleCaseRecordAction`, formData);
  return response;
};

export const approveRejectAction = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/actionTransferApprovalSubmissionAction`, formData);
  return response;
};

export const deleteLCRecordsAction = async (query) => {
  const response = await formDataApi.post(`restEmpIndex/deleteLifeCycleCaseRecord`, query);
  return response;
};
export const fetchEmployeeDashboardData = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/employeesDashboard`, formData);
  return response;
};
export const extendProbationData = async (query) => {
  const response = await formDataApi.get(`restEmpIndex/extendProbationEmployee?${query.toString()}`);
  return response?.data;
};

export const initiatePIPData = async (query) => {
  const response = await formDataApi.get(`restEmpIndex/beginEmployeePIP?${query.toString()}`);
  return response?.data;
};

export const addAnonymiseEmployeeAction = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/addAnonymiseEmployee`, formData);
  return response;
};

export const sendESSInviteToSelEmpsAction = async (bodyFormData) => {
  const response = await formDataApi.post(`restEmpIndex/sendESSInviteToSelEmpsAction`, bodyFormData);
  return response;
};

export const exportEmployeeDataAction = async (bodyFormData) => {
  const response = await formDataApi.post(`restReportIndex/exportEmployeeDataAction`, bodyFormData);
  return response;
};
export const verifyBackgroundData = async (query) => {
  const response = await formDataApi.get(`restEmpIndex/updateEmployeeBackgroundVerification?${query.toString()}`);
  return response?.data;
};

export const verifyBackgroundAction = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/updateEmployeeBackgroundVerificationAction`, formData);
  return response;
};

export const concludePIPData = async (query) => {
  const response = await formDataApi.get(`restEmpIndex/endEmployeePIP?${query.toString()}`);
  return response?.data;
};

export const concludeEmployeePIPAction = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/endEmployeePIPAction`, formData);
  return response;
};

export const initiateEmployeePIPAction = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/beginEmployeePIPAction`, formData);
  return response;
};

export const resetPasswordAction = async (bodyFormData) => {
  const response = await formDataApi.post(`restEmpIndex/resetPasswordAction`, bodyFormData);
  return response;
};

export const listLCRecordsPendingMyApproval = async (query) => {
  const response = await formDataApi.get(`restEmpIndex/listCaseRecordsPendingMyApproval?${query.toString()}`);
  return response?.data;
};
