import React, { useEffect } from 'react';
import MyTeamTemplate from 'components/Templates/Dashboard/MyTeamDashboard';
import { useThunk } from 'hooks/useThunk';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fetchTeamDashboardDetails } from 'store';
import { Typography, Skeleton, Box } from '@mui/material';
import { isArray, isEmpty } from 'lodash';
import { filterActionMenu } from 'utils/filterActionMenu';
import CakeIcon from '@mui/icons-material/Cake';
import CelebrationIcon from '@mui/icons-material/Celebration';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import PhoneIcon from '@mui/icons-material/Phone';
import { validateDateElseHyphen } from 'utils/commonUtils';
import useColumns from './useColumns';

const TeamDashboardPage = () => {
  const [doFetchTeamDashboardDetails, isLoadingTeamDashboard, errorTeamDashboard] = useThunk(fetchTeamDashboardDetails);
  const { authorities = [] } = useSelector((state) => state?.session?.user);
  const navigate = useNavigate();
  const [columns] = useColumns();

  useEffect(() => {
    doFetchTeamDashboardDetails();
  }, [doFetchTeamDashboardDetails]);

  const { teamDashboardDetails = {} } = useSelector((state) => state?.dashboard);
  const { user = {} } = useSelector((state) => state?.session);

  const {
    reportees = [],
    annivEmpList = [],
    companyCalendarInstance = [],
    empIDLeaveCatWiseTranMap = {},
  } = teamDashboardDetails;

  if (isLoadingTeamDashboard) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (errorTeamDashboard) {
    return (
      <Typography variant="h6">{errorTeamDashboard?.message || 'Error while fetching dashboard details.'}</Typography>
    );
  }

  const actionMenuList = [
    {
      id: 1,
      label: 'Life Cycle Case Records Pending Approval',
      onClick: () => navigate('/myteam/life-cycle/life-cycle-records-pending-approval'),
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_LIST_CASE_RECORDS_PENDING_MY_APPROVAL'],
    },
  ];

  const filteredActionList = filterActionMenu(actionMenuList, authorities);

  const teamMembers =
    isArray(reportees) && !isEmpty(reportees)
      ? reportees?.map((item) => ({
          profileImg: 'images/employeeAvatar.svg',
          profileName: item?.fullName,
          isDesignation: true,
          designation: item?.department?.name,
          emailAddressesIcon: <MarkunreadIcon fontSize="inherit" />,
          emailAddresses: item?.emailAddresses,
          mobileNumbersIcon: <PhoneIcon fontSize="inherit" />,
          mobileNumbers: item?.mobileNumbers,
        }))
      : [];

  const birthday =
    isArray(annivEmpList) && !isEmpty(annivEmpList)
      ? annivEmpList?.map((item) => ({
          profileImg: 'images/employeeAvatar.svg',
          profileName: item?.fullName || '',
          isDesignation: false,
          dateOfBirthIcon: <CakeIcon fontSize="inherit" />,
          dateOfBirth: validateDateElseHyphen(item?.dateOfBirth, 'DD MMM YYYY'),
          dateOfJoiningIcon: <CelebrationIcon fontSize="inherit" />,
          dateOfJoining: validateDateElseHyphen(item?.dateOfJoining, 'DD MMM YYYY'),
          departmentIcon: <CorporateFareIcon fontSize="inherit" />,
          department: item?.department?.name || '',
          locationIcon: <LocationOnIcon fontSize="inherit" />,
          location: item?.location?.name || '',
        }))
      : [];

  const event =
    isArray(companyCalendarInstance?.holidays) && !isEmpty(companyCalendarInstance?.holidays)
      ? companyCalendarInstance?.holidays?.map((cal) => {
          const isPublicHoliday = cal?.holidayType === 'Public Holiday';
          const holidayDate = new Date(cal?.holidayDate);

          return {
            title: cal?.occassion || '',
            allDay: true,
            start: holidayDate || '',
            end: holidayDate || '',
            color: '#215263',
            textColor: 'white',
            type: isPublicHoliday ? 'holiday' : cal?.holidayType,
            desc: cal?.holidayType || '',
          };
        })
      : [];

  const processLeaves = (input) => {
    const output = [];
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const employeeId in input) {
      const leaveTypes = Object.keys(input[employeeId]);
      // eslint-disable-next-line no-restricted-syntax
      for (const leaveType of leaveTypes) {
        input[employeeId][leaveType].forEach((leave) => {
          output.push({
            ...leave,
            type: leaveType,
          });
        });
      }
    }
    return output;
  };

  const leaveData = processLeaves(empIDLeaveCatWiseTranMap);
  const leaves =
    isArray(leaveData) && !isEmpty(leaveData)
      ? leaveData?.map((item) => {
          return {
            color: '#215263',
            textColor: 'white',
            type: 'leaves',
            allDay: true,
            title: item?.title || '',
            employeeDesignation: item?.employee?.designation || '',
            employeeName: item?.employee?.name || '',
            reason: item?.description || '',
            start: item?.startDate || '',
            end: item?.endDate || '',
            leaveType: item?.leaveType || '',
            desc: item?.description || '',
          };
        })
      : [];

  return (
    <MyTeamTemplate
      Heading={`${user?.firstName || ''} ${user?.lastName || ''}`}
      employeeList={birthday}
      teamMembersList={teamMembers}
      events={[...event, ...leaves]}
      columns={columns}
      count={0}
      actionMenuLists={filteredActionList}
    />
  );
};

export default TeamDashboardPage;
