import PropTypes from 'prop-types';
import React from 'react';
import Typographys from 'components/Atoms/Typography';
import { Box, Divider, IconButton, Link } from '@mui/material';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import Button from 'components/Atoms/Button';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { calendarStyle } from '../../style';

const MeetingDialog = ({ data }) => {
  return (
    <Box>
      <Box mb={2}>
        <Typographys variant="h4" align="left">
          {data.title}
        </Typographys>
      </Box>

      <Typographys align="left" color="neutral.600">
        {`Agenda - ${data.agendaForMeeting}`}
      </Typographys>
      <Divider sx={{ ...calendarStyle.calenderDivider, my: 2 }} />
      <Box sx={{ ...calendarStyle.deadlineButtonsWrapper, mb: 1 }}>
        <Box sx={calendarStyle.titleWithIcon}>
          <IconButton sx={{ color: 'actions.600', fontSize: '2.25rem' }}>
            <PermIdentityIcon fontSize="inherit" />
          </IconButton>
          <Box>
            <Typographys
              sx={{ textTransform: 'none' }}
              color="neutral.800"
            >{`Arranged by - ${data.arrangedBy}`}</Typographys>
          </Box>
        </Box>

        <Box sx={calendarStyle.titleWithIcon}>
          <IconButton sx={{ color: 'actions.600', fontSize: '2.25rem' }}>
            <CalendarTodayIcon fontSize="inherit" />
          </IconButton>
          <Box>
            <Typographys sx={{ textTransform: 'none' }} color="neutral.800">{`Date - ${data.meetingDate}`}</Typographys>
          </Box>
        </Box>

        <Box sx={calendarStyle.titleWithIcon}>
          <IconButton sx={{ color: 'actions.600', fontSize: '2.25rem' }}>
            <AccessTimeIcon fontSize="inherit" />
          </IconButton>
          <Box>
            <Typographys color="neutral.800">{`Time - ${data.meetingTime}`}</Typographys>
          </Box>
        </Box>
      </Box>

      <Typographys
        sx={{ textTransform: 'none', mb: 0.75 }}
        fontWeight="500"
        variant="subtitle1"
        align="left"
        color="neutral.600"
      >
        Meeting link
      </Typographys>
      <Box sx={calendarStyle.deadlineButtonsWrapper}>
        <Link
          href={data.link}
          variant="subtitle2"
          sx={{ fontWeight: 500, textTransform: 'none' }}
          color="secondary.600"
          underline="hover"
        >
          {data.link}
        </Link>
        <Box sx={calendarStyle.meetingButtonsWrapper}>
          <Button
            fullWidth={false}
            size="medium"
            variant="contained"
            color="primary"
            onClick={() => {
              data.handleJoinMeeting(data);
            }}
          >
            Join Meeting
          </Button>
          <Button
            fullWidth={false}
            size="medium"
            variant="outlined"
            color="info"
            onClick={() => {
              data.handleShareLink(data);
            }}
          >
            Share link
          </Button>
        </Box>
      </Box>
      <Divider sx={{ ...calendarStyle.calenderDivider, mt: 2 }} />
    </Box>
  );
};
MeetingDialog.defaultProps = {
  data: {},
};
MeetingDialog.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    agendaForMeeting: PropTypes.string,
    arrangedBy: PropTypes.string,
    meetingDate: PropTypes.string,
    meetingTime: PropTypes.string,
    link: PropTypes.string,
    handleJoinMeeting: PropTypes.func,
    handleShareLink: PropTypes.func,
  }),
};
export default MeetingDialog;
