import {
  createBlockquotePlugin,
  createCodeBlockPlugin,
  createHeadingPlugin,
  createParagraphPlugin,
  createBasicElementsPlugin,
  createHorizontalRulePlugin,
  createNodeIdPlugin,
  createFontColorPlugin,
  createFontBackgroundColorPlugin,
  createHighlightPlugin,
} from '@udecode/plate';
import { plateComponentUI } from '../config/plateComponentUI';
import { createMyPlugins } from '../config/typescript';

export const basicElementsPlugins = createMyPlugins(
  [
    createBlockquotePlugin(),
    createCodeBlockPlugin(),
    createHeadingPlugin(),
    createParagraphPlugin(),
    createBasicElementsPlugin(),
    createHorizontalRulePlugin(),
    createNodeIdPlugin(),
    createFontColorPlugin(),
    createFontBackgroundColorPlugin(),
    createHighlightPlugin(),
  ],
  {
    components: plateComponentUI,
  },
);
