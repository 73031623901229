import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import TitleWithText from 'components/Atoms/TitleWithText';
import Typographys from 'components/Atoms/Typography';
import ApproverTimeline from 'components/Organisms/Employee/LCPolicies/ApproverTimeline';

const LCPolicy = ({ approverDetails, approverLists, label }) => {
  return (
    <Box>
      <Grid container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={12} sm={4} md={4}>
          <TitleWithText title={`${label} Type`} text={approverDetails.approverType} />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <TitleWithText title={`${label} SLA`} text={approverDetails.approverSLA} />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <TitleWithText title={`${label} Unit`} text={approverDetails.approverUnit} />
        </Grid>
      </Grid>
      <Box my={2}>
        <Typographys variant="h5">{`${label} Approvers`}</Typographys>
      </Box>

      <ApproverTimeline approverLists={approverLists} />
    </Box>
  );
};

LCPolicy.defaultProps = {
  approverDetails: {
    approverType: 'Reporting Manager',
    approverSLA: '3.0',
    approverUnit: 'Days',
  },
  approverLists: [
    {
      id: 1,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
    {
      id: 2,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
  ],
  label: '',
};

LCPolicy.propTypes = {
  approverDetails: PropTypes.shape({
    approverType: PropTypes.string,
    approverSLA: PropTypes.string,
    approverUnit: PropTypes.string,
  }),

  approverLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      effectedBy: PropTypes.string,
    }),
  ),
  label: PropTypes.string,
};

export default LCPolicy;
