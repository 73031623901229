import * as React from 'react';
import Paper from '@mui/material/Paper';
import Typographys from 'components/Atoms/Typography';
import { Box } from '@mui/material';

const HeadsTable = ({ headers, rows, hasHeader, headsData }) => {
  return (
    <Paper sx={{ overflow: 'auto' }}>
      {hasHeader && (
        <Box sx={{ display: 'flex', p: '12px 8px', backgroundColor: 'info.200' }}>
          <Box sx={{ width: '75%' }}>
            <Typographys variant="h6">Heads</Typographys>
          </Box>
          <Box sx={{ width: '25%' }}>
            <Typographys variant="h6">Amount</Typographys>
          </Box>
        </Box>
      )}
      {headsData.map((head) => (
        <Box sx={{ display: 'flex', p: '12px 8px' }}>
          <Box sx={{ width: '75%' }}>
            <Typographys variant="body2">{head.title}</Typographys>
          </Box>
          <Box sx={{ width: '25%' }}>
            <Typographys variant="body2">{head.amount}</Typographys>
          </Box>
        </Box>
      ))}
    </Paper>
  );
};

export default HeadsTable;
