import React, { useState } from 'react';
import { noop } from 'lodash';
import Button from 'components/Atoms/Button';
import { ThreeDotsIcon } from './ThreeDotsIcon';
import Menu from './Menu';

export const IconMenu = ({ options, onClick, dataTestId }) => {
  const [anchorEl, setAnchorEl] = useState();

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onClickOption = (option) => {
    setAnchorEl(null);
    onClick(option);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Button onClick={handleClick} data-testid={`${dataTestId}-menu-button`}>
        Menu <ThreeDotsIcon />
      </Button>
      <Menu
        anchorEl={anchorEl}
        options={options}
        open={open}
        handleClose={onClose}
        onClick={onClickOption}
        width={173}
        variant="light"
      />
    </>
  );
};

IconMenu.defaultProps = {
  onClick: noop,
  options: [],
};
