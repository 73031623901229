import PropTypes from 'prop-types';
import React from 'react';
import Dialog from 'components/Molecules/Dialog';
import Typographys from 'components/Atoms/Typography';

const RemoveEmployee = ({ handleDelete, handleCancel, open, handleClose, numOfEmployee }) => {
  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      dialogTitle="Remove Employee"
      dialogDesc=<Typographys component="div" color="neutral.600" align="center">
        Are you want to remove {numOfEmployee} employees from &nbsp;
        <Typographys display="inline" color="neutral.800">
          Default Life Cycle Policy?
        </Typographys>
      </Typographys>
      secondaryBtn="Cancel"
      primaryBtn="Delete"
      handlePrimaryBtn={handleDelete}
      handleSecondaryBtn={handleCancel}
      primaryBtnColor="error"
      bodyContent={false}
    />
  );
};
RemoveEmployee.defaultProps = {
  handleDelete: () => {},
  handleCancel: () => {},
  open: true,
  handleClose: () => {},
  numOfEmployee: 2,
};
RemoveEmployee.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleDelete: PropTypes.func,
  handleCancel: PropTypes.func,
  numOfEmployee: PropTypes.number,
};
export default RemoveEmployee;
