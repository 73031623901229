export const addEmpStyle = {
  addWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: 2,
    my: 2,
  },

  removeWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    gap: 2,
    my: 2,
  },

  submitWrapper: {
    display: 'flex',
    justifyContent: 'end',
    gap: 2,
  },

  btnContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%',
    backgroundColor: 'common.white',
    zIndex: '9',
    margin: '0rem',
    padding: '0rem 3rem 2rem 3rem',
    boxSizing: 'border-box',
  },

  divWrapper: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '2rem',
  },

  formContainer: {
    width: '100%',
    maxWidth: '100%',
  },

  rowDivider: {
    '&:not(:first-child)': {
      borderTop: '0.125rem solid',
      borderColor: 'other.200',
      mt: 4,
      pt: 2,
    },
  },

  accordionWrapper: {
    '&.MuiAccordion-root': {
      borderBottom: '0.125rem solid',
      borderColor: 'other.200',

      '&:before': {
        border: 'none',
      },
    },

    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)',
    },

    '& .MuiAccordionSummary-content': {
      margin: '0',
      padding: '0 1.5rem',
    },

    '& .MuiAccordionSummary-root': {
      flexDirection: 'row-reverse',
      padding: '2rem 0',
      minHeight: 'auto',
    },

    '& .MuiAccordionDetails-root': {
      padding: '0 0rem',
    },
  },
  deleteContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
};
