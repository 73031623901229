import React, { useRef } from 'react';
import DashboardTemplate from 'components/Templates/DashboardTemplate';
import PropTypes from 'prop-types';
import TDSWorkingTemplate from 'components/Organisms/MyPay/TDSWorking';
import { useReactToPrint } from 'react-to-print';

const TDSWorkingTemplateTemplate = ({
  empName,
  empId,
  categoryColumnsData,
  categoryRowsData,
  amountData,
  standardDeduction,
  currentFinancialYear,
  taxOnEmployment,
  deductionTableData,
  totalDeductionAmount,
  hraData,
  rowEarnings,
  columnEarnings,
  rowRetirals,
  columnRetirals,
  rowDeductions,
  columnDeductions,
  rowOther,
  columnOther,
  columnExemption,
  rowExemption,
  startDate,
  endDate,
  loading,
}) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <DashboardTemplate pageHeading="TDS Workings" pageTitleVariant="h6" isBreadcrumb={false} isActionMenuLists={false}>
      <TDSWorkingTemplate
        ref={componentRef}
        empName={empName}
        empId={empId}
        handlePrint={handlePrint}
        categoryColumnsData={categoryColumnsData}
        categoryRowsData={categoryRowsData}
        amountData={amountData}
        standardDeduction={standardDeduction}
        currentFinancialYear={currentFinancialYear}
        taxOnEmployment={taxOnEmployment}
        deductionTableData={deductionTableData}
        totalDeductionAmount={totalDeductionAmount}
        hraData={hraData}
        rowEarnings={rowEarnings}
        columnEarnings={columnEarnings}
        rowRetirals={rowRetirals}
        columnRetirals={columnRetirals}
        rowDeductions={rowDeductions}
        columnDeductions={columnDeductions}
        rowOther={rowOther}
        columnOther={columnOther}
        columnExemption={columnExemption}
        rowExemption={rowExemption}
        startDate={startDate}
        endDate={endDate}
        loading={loading}
      />
    </DashboardTemplate>
  );
};

TDSWorkingTemplateTemplate.defaultProps = {
  empName: 'Abeer Kalimohan',
  empId: '1001',
  loading: false,
  categoryColumnsData: [
    {
      fieldName: 'april',
      label: 'April 2020',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'may',
      label: 'May 2020',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'june',
      label: 'June 2020',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'july',
      label: 'July 2020',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'august',
      label: 'August 2020',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'september',
      label: 'September 2020',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'october',
      label: 'October 2020',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'november',
      label: 'November 2020',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'december',
      label: 'December 2020',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'january',
      label: 'January 2021',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'february',
      label: 'February 2021',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'march',
      label: 'March 2021',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'subTotal',
      label: 'Sub Total',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
  ],

  categoryRowsData: {
    Basic: {
      id: '1',
      april: '1,80,000',
      may: '1,80,000',
      june: '1,80,000',
      july: '1,80,000',
      august: '1,80,000',
      september: '1,80,000',
      october: '1,80,000',
      november: '1,80,000',
      december: '1,80,000',
      january: '1,80,000',
      february: '1,80,000',
      march: '1,80,000',
      subTotal: '21,60,000',
    },
    Bonus: {
      id: '2',
      april: '60,000',
      may: '60,000',
      june: '60,000',
      july: '60,000',
      august: '60,000',
      september: '60,000',
      october: '60,000',
      november: '60,000',
      december: '60,000',
      january: '60,000',
      february: '60,000',
      march: '60,000',
      subTotal: '7,20,000',
    },
    "Children's Education Allowance": {
      id: '3',
      april: '4,000',
      may: '4,000',
      june: '4,000',
      july: '4,000',
      august: '4,000',
      september: '4,000',
      october: '4,000',
      november: '4,000',
      december: '4,000',
      january: '4,000',
      february: '4,000',
      march: '4,000',
      subTotal: '4,000',
    },
    "Children's Hostel Allowance": {
      id: '4',
      april: '45,904',
      may: '45,904',
      june: '45,904',
      july: '45,904',
      august: '45,904',
      september: '45,904',
      october: '45,904',
      november: '45,904',
      december: '45,904',
      january: '45,904',
      february: '45,904',
      march: '45,904',
      subTotal: '45,904',
    },
    'Conveyance Allowance': {
      id: '5',
      april: '16,627',
      may: '16,627',
      june: '16,627',
      july: '16,627',
      august: '16,627',
      september: '16,627',
      october: '16,627',
      november: '16,627',
      december: '16,627',
      january: '16,627',
      february: '16,627',
      march: '16,627',
      subTotal: '16,627',
    },
    'Dearness Allowance': {
      id: '6',
      april: '13,671',
      may: '13,671',
      june: '13,671',
      july: '13,671',
      august: '13,671',
      september: '13,671',
      october: '13,671',
      november: '13,671',
      december: '13,671',
      january: '13,671',
      february: '13,671',
      march: '13,671',
      subTotal: '13,671',
    },
    'Flat Allowance': {
      id: '7',
      april: '18,599',
      may: '18,599',
      june: '18,599',
      july: '18,599',
      august: '18,599',
      september: '18,599',
      october: '18,599',
      november: '18,599',
      december: '18,599',
      january: '18,599',
      february: '18,599',
      march: '18,599',
      subTotal: '18,599',
    },
    'Flexible Allowance': {
      id: '8',
      april: '23,340',
      may: '23,340',
      june: '23,340',
      july: '23,340',
      august: '23,340',
      september: '23,340',
      october: '23,340',
      november: '23,340',
      december: '23,340',
      january: '23,340',
      february: '23,340',
      march: '23,340',
      subTotal: '23,340',
    },
    'House Rent Allowance': {
      id: '9',
      april: '50,963',
      may: '50,963',
      june: '50,963',
      july: '50,963',
      august: '50,963',
      september: '50,963',
      october: '50,963',
      november: '50,963',
      december: '50,963',
      january: '50,963',
      february: '50,963',
      march: '50,963',
      subTotal: '50,963',
    },
    'Leave Travel Allowance*': {
      id: '10',
      april: '18,599',
      may: '18,599',
      june: '18,599',
      july: '18,599',
      august: '18,599',
      september: '18,599',
      october: '18,599',
      november: '18,599',
      december: '18,599',
      january: '18,599',
      february: '18,599',
      march: '18,599',
      subTotal: '18,599',
    },
    'Lunch Allowance': {
      id: '11',
      april: '4,339',
      may: '4,339',
      june: '4,339',
      july: '4,339',
      august: '4,339',
      september: '4,339',
      october: '4,339',
      november: '4,339',
      december: '4,339',
      january: '4,339',
      february: '4,339',
      march: '4,339',
      subTotal: '4,339',
    },
    'Medical Allowance': {
      id: '12',
      april: '43,359',
      may: '43,359',
      june: '43,359',
      july: '43,359',
      august: '43,359',
      september: '43,359',
      october: '43,359',
      november: '43,359',
      december: '43,359',
      january: '43,359',
      february: '43,359',
      march: '43,359',
      subTotal: '43,359',
    },
    'Other Allowance': {
      id: '13',
      april: '92,771',
      may: '92,771',
      june: '92,771',
      july: '92,771',
      august: '92,771',
      september: '92,771',
      october: '92,771',
      november: '92,771',
      december: '92,771',
      january: '92,771',
      february: '92,771',
      march: '92,771',
      subTotal: '92,771',
    },
    'Special Allowance': {
      id: '14',
      april: '13,671',
      may: '13,671',
      june: '13,671',
      july: '13,671',
      august: '13,671',
      september: '13,671',
      october: '13,671',
      november: '13,671',
      december: '13,671',
      january: '13,671',
      february: '13,671',
      march: '13,671',
      subTotal: '13,671',
    },
    'Telephone/Internet Allowance': {
      id: '15',
      april: '9,631',
      may: '9,631',
      june: '9,631',
      july: '9,631',
      august: '9,631',
      september: '9,631',
      october: '9,631',
      november: '9,631',
      december: '9,631',
      january: '9,631',
      february: '9,631',
      march: '9,631',
      subTotal: '9,631',
    },
    'Total Earnings': {
      id: '16',
      april: '4,339',
      may: '4,339',
      june: '4,339',
      july: '4,339',
      august: '4,339',
      september: '4,339',
      october: '4,339',
      november: '4,339',
      december: '4,339',
      january: '4,339',
      february: '4,339',
      march: '4,339',
      subTotal: '4,339',
    },
    'Loss Of Pay (days)': {
      id: '17',
      april: '10,708',
      may: '10,708',
      june: '10,708',
      july: '10,708',
      august: '10,708',
      september: '10,708',
      october: '10,708',
      november: '10,708',
      december: '10,708',
      january: '10,708',
      february: '10,708',
      march: '10,708',
      subTotal: '10,708',
    },
    'LWF - Employee Contribution': {
      id: '18',
      april: '16,415',
      may: '16,415',
      june: '16,415',
      july: '16,415',
      august: '16,415',
      september: '16,415',
      october: '16,415',
      november: '16,415',
      december: '16,415',
      january: '16,415',
      february: '16,415',
      march: '16,415',
      subTotal: '16,415',
    },
    'NPS Deduction': {
      id: '19',
      april: '198',
      may: '198',
      june: '198',
      july: '198',
      august: '198',
      september: '198',
      october: '198',
      november: '198',
      december: '198',
      january: '198',
      february: '198',
      march: '198',
      subTotal: '198',
    },
    'PF Contribution (Employee)': {
      id: '20',
      april: '875',
      may: '875',
      june: '875',
      july: '875',
      august: '875',
      september: '875',
      october: '875',
      november: '875',
      december: '875',
      january: '875',
      february: '875',
      march: '875',
      subTotal: '875',
    },
    'Professional Tax': {
      id: '21',
      april: '93,046',
      may: '93,046',
      june: '93,046',
      july: '93,046',
      august: '93,046',
      september: '93,046',
      october: '93,046',
      november: '93,046',
      december: '93,046',
      january: '93,046',
      february: '93,046',
      march: '93,046',
      subTotal: '93,046',
    },
    'Income Tax': {
      id: '22',
      april: '18,599',
      may: '18,599',
      june: '18,599',
      july: '18,599',
      august: '18,599',
      september: '18,599',
      october: '18,599',
      november: '18,599',
      december: '18,599',
      january: '18,599',
      february: '18,599',
      march: '18,599',
      subTotal: '18,599',
    },
    'Total Deductions': {
      id: '23',
      april: '50,963',
      may: '50,963',
      june: '50,963',
      july: '50,963',
      august: '50,963',
      september: '50,963',
      october: '50,963',
      november: '50,963',
      december: '50,963',
      january: '50,963',
      february: '50,963',
      march: '50,963',
      subTotal: '50,963',
    },
    'Net Earnings': {
      id: '24',
      april: '10,708',
      may: '10,708',
      june: '10,708',
      july: '10,708',
      august: '10,708',
      september: '10,708',
      october: '10,708',
      november: '10,708',
      december: '10,708',
      january: '10,708',
      february: '10,708',
      march: '10,708',
      subTotal: '10,708',
    },
  },

  amountData: {
    a: {
      old: '₹10000',
    },
    b: {
      old: '₹10000',
    },
    c: {
      old: '₹10000',
    },
    d: {
      old: '₹10000',
    },
    e: {
      old: '₹10000',
    },
    e0: {
      old: '₹10000',
    },
    e1: {
      old: '₹10000',
    },
    e2: {
      old: '₹10000',
    },
    f: {
      old: '₹10000',
    },
    g: {
      old: '₹10000',
    },
    h: {
      old: '₹10000',
    },
    i: {
      old: '₹10000',
    },
    j: {
      old: '₹10000',
    },
    k: {
      old: '₹10000',
    },
    l: {
      old: '₹10000',
    },
    m: {
      old: '₹10000',
    },
    n: {
      old: '₹10000',
    },

    o: {
      old: '₹10000',
    },
    p: {
      old: '₹10000',
    },
    q: {
      old: '₹10000',
    },
    r: {
      old: '₹10000',
    },
    s: {
      old: '₹10000',
    },
    t: {
      old: '₹10000',
    },
    u: {
      old: '₹10000',
    },
    v: {
      old: '₹10000',
    },
    w: {
      old: '₹10000',
    },
    x: {
      old: '₹10000',
    },
  },

  standardDeduction: '₹ 50,000',
  taxOnEmployment: '₹ 2,496',
  deductionTableData: [
    {
      section: '80C',
      investmentProposed: '₹10000',
      investmentActual: '₹10000',
      sectionLimit: '₹10000',
      deductionAmount: '₹10000',
    },
  ],
  totalDeductionAmount: '₹10000',

  columnEarnings: [
    {
      fieldName: 'earnings',
      columnTitle: 'Earnings',
      columnWidth: '60%',
    },
    {
      fieldName: 'perPeriodAmount',
      columnTitle: 'Per Period',
      columnWidth: '20%',
    },
    {
      fieldName: 'annualAmount',
      columnTitle: 'Annual',
      columnWidth: '20%',
    },
  ],
  rowEarnings: [
    {
      earnings: 'Earnings (from Salary)',
      annualAmount: '₹10000',
      perPeriodAmount: '₹10000',
    },
    {
      earnings: 'Benefits',
      annualAmount: '₹10000',
      perPeriodAmount: '₹10000',
    },
    {
      earnings: 'Reimbursements',
      annualAmount: '₹10000',
      perPeriodAmount: '₹10000',
    },
    {
      earnings: 'Gross Earnings',
      annualAmount: '₹10000',
      perPeriodAmount: '₹10000',
    },
  ],

  columnDeductions: [
    {
      fieldName: 'deductions',
      columnTitle: 'Deductions',
      columnWidth: '60%',
    },
    {
      fieldName: 'perPeriodAmount',
      columnTitle: 'Per Period',
      columnWidth: '20%',
    },
    {
      fieldName: 'annualAmount',
      columnTitle: 'Annual',
      columnWidth: '20%',
    },
  ],
  rowDeductions: [
    {
      deductions: 'Voluntary Deductions',
      annualAmount: '₹10000',
      perPeriodAmount: '₹10000',
    },
    {
      deductions: 'Statutory Deductions',
      annualAmount: '₹10000',
      perPeriodAmount: '₹10000',
    },
    {
      deductions: 'Loans and Advances (Deductions)',
      annualAmount: '₹10000',
      perPeriodAmount: '₹10000',
    },
    {
      deductions: 'Gross Deductions',
      annualAmount: '₹10000',
      perPeriodAmount: '₹10000',
    },
  ],

  columnRetirals: [
    {
      fieldName: 'retirals',
      columnTitle: 'Retirals',
      columnWidth: '60%',
    },
    {
      fieldName: 'perPeriodAmount',
      columnTitle: 'Per Period',
      columnWidth: '20%',
    },
    {
      fieldName: 'annualAmount',
      columnTitle: 'Annual',
      columnWidth: '20%',
    },
  ],
  rowRetirals: [
    {
      retirals: 'Employer’s Statutory Contributions',
      annualAmount: '₹10000',
      perPeriodAmount: '₹10000',
    },
    {
      retirals: 'Gratuity Contributions ',
      annualAmount: '₹10000',
      perPeriodAmount: '₹10000',
    },
  ],

  columnOther: [
    {
      fieldName: 'others',
      columnTitle: 'Others',
      columnWidth: '60%',
    },
    {
      fieldName: 'perPeriodAmount',
      columnTitle: 'Per Period',
      columnWidth: '20%',
    },
    {
      fieldName: 'annualAmount',
      columnTitle: 'Annual',
      columnWidth: '20%',
    },
  ],
  rowOther: [
    {
      others: 'Exemptions(Total)',
      annualAmount: '',
      perPeriodAmount: '₹10000',
    },
    {
      others: 'Total Taxable Income',
      annualAmount: '',
      perPeriodAmount: '₹10000',
    },
    {
      others: 'Total Deducted at Source (TDS)',
      annualAmount: '₹10000',
      perPeriodAmount: '₹10000',
    },
  ],

  hraData: [
    {
      received: '₹10000',
      basic: '₹10000',
      rentPaid: '₹0',
    },
  ],

  columnExemption: [
    {
      fieldName: 'title',
      columnTitle: 'Title',
      columnWidth: '20%',
    },
    {
      fieldName: 'declaredamount',
      columnTitle: 'Declared Amount',
      columnWidth: '20%',
    },
    {
      fieldName: 'proofsubmitted',
      columnTitle: 'Proof Submitted',
      columnWidth: '20%',
    },
    {
      fieldName: 'annualexemptionlimit',
      columnTitle: 'Annual Exemption Limit',
      columnWidth: '20%',
    },
    {
      fieldName: 'exemptionconsidered',
      columnTitle: 'Exemption Considered',
      columnWidth: '20%',
    },
  ],
  rowExemption: [
    {
      title: 'Earnings(From Salary)',
      declaredamount: '₹10000',
      proofsubmitted: '₹10000',
      annualexemptionlimit: '₹10000',
      exemptionconsidered: '₹10000',
    },
    {
      title: 'Leave Travel Allowance',
      declaredamount: '₹10000',
      proofsubmitted: '₹10000',
      annualexemptionlimit: '₹10000',
      exemptionconsidered: '₹10000',
    },
  ],
  startDate: '01-Apr-2020',
  endDate: '31-Mar-2021',
};

TDSWorkingTemplateTemplate.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  empName: PropTypes.string,
  empId: PropTypes.string,
  loading: PropTypes.bool,
  standardDeduction: PropTypes.string,
  taxOnEmployment: PropTypes.string,
  totalDeductionAmount: PropTypes.string,

  amountData: PropTypes.shape({
    a: PropTypes.shape({
      old: PropTypes.number,
    }),
    b: PropTypes.shape({
      old: PropTypes.number,
    }),
    c: PropTypes.shape({
      old: PropTypes.number,
    }),
    d: PropTypes.shape({
      old: PropTypes.number,
    }),
    e: PropTypes.shape({
      old: PropTypes.number,
    }),
    e0: PropTypes.shape({
      old: PropTypes.number,
    }),
    e1: PropTypes.shape({
      old: PropTypes.number,
    }),
    e2: PropTypes.shape({
      old: PropTypes.number,
    }),
    f: PropTypes.shape({
      old: PropTypes.number,
    }),
    g: PropTypes.shape({
      old: PropTypes.number,
    }),
    h: PropTypes.shape({
      old: PropTypes.number,
    }),
    i: PropTypes.shape({
      old: PropTypes.number,
    }),
    j: PropTypes.shape({
      old: PropTypes.number,
    }),
    k: PropTypes.shape({
      old: PropTypes.number,
    }),
    l: PropTypes.shape({
      old: PropTypes.number,
    }),
    m: PropTypes.shape({
      old: PropTypes.number,
    }),
    n: PropTypes.shape({
      old: PropTypes.number,
    }),
    o: PropTypes.shape({
      old: PropTypes.number,
    }),
    p: PropTypes.shape({
      old: PropTypes.number,
    }),
    q: PropTypes.shape({
      old: PropTypes.number,
    }),
    r: PropTypes.shape({
      old: PropTypes.number,
    }),
    s: PropTypes.shape({
      old: PropTypes.number,
    }),
    t: PropTypes.shape({
      old: PropTypes.number,
    }),
    u: PropTypes.shape({
      old: PropTypes.number,
    }),
    v: PropTypes.shape({
      old: PropTypes.number,
    }),
    w: PropTypes.shape({
      old: PropTypes.number,
    }),
    x: PropTypes.shape({
      old: PropTypes.number,
    }),
  }),

  categoryRowsData: PropTypes.shape({
    Basic: PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    Bonus: PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    "Children's Education Allowance": PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    "Children's Hostel Allowance": PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Conveyance Allowance': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Dearness Allowance': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Flat Allowance': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Flexible Allowance': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'House Rent Allowance': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Leave Travel Allowance*': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Lunch Allowance': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Medical Allowance': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Other Allowance': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Special Allowance': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Telephone/Internet Allowance': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Total Earnings': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Loss Of Pay (days)': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'LWF - Employee Contribution': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'NPS Deduction': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'PF Contribution (Employee)': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Professional Tax': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Income Tax': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Total Deductions': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Net Earnings': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
  }),

  categoryColumnsData: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),

  deductionTableData: PropTypes.arrayOf(
    PropTypes.shape({
      section: PropTypes.string,
      investmentProposed: PropTypes.string,
      investmentActual: PropTypes.string,
      sectionLimit: PropTypes.string,
      deductionAmount: PropTypes.string,
    }),
  ),

  hraData: PropTypes.arrayOf(
    PropTypes.shape({
      received: PropTypes.string,
      basic: PropTypes.string,
      rentPaid: PropTypes.string,
    }),
  ),

  rowEarnings: PropTypes.arrayOf(
    PropTypes.shape({
      earnings: PropTypes.string,
      annualAmount: PropTypes.string,
      perPeriodAmount: PropTypes.string,
    }),
  ),

  columnEarnings: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      columnTitle: PropTypes.string,
      columnWidth: PropTypes.string,
    }),
  ),

  rowRetirals: PropTypes.arrayOf(
    PropTypes.shape({
      retirals: PropTypes.string,
      annualAmount: PropTypes.string,
      perPeriodAmount: PropTypes.string,
    }),
  ),

  columnRetirals: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      columnTitle: PropTypes.string,
      columnWidth: PropTypes.string,
    }),
  ),

  rowDeductions: PropTypes.arrayOf(
    PropTypes.shape({
      deductions: PropTypes.string,
      annualAmount: PropTypes.string,
      perPeriodAmount: PropTypes.string,
    }),
  ),

  columnDeductions: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      columnTitle: PropTypes.string,
      columnWidth: PropTypes.string,
    }),
  ),

  rowOther: PropTypes.arrayOf(
    PropTypes.shape({
      others: PropTypes.string,
      annualAmount: PropTypes.string,
      perPeriodAmount: PropTypes.string,
    }),
  ),

  columnOther: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      columnTitle: PropTypes.string,
      columnWidth: PropTypes.string,
    }),
  ),

  columnExemption: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      columnTitle: PropTypes.string,
      columnWidth: PropTypes.string,
    }),
  ),

  rowExemption: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      declaredamount: PropTypes.string,
      proofsubmitted: PropTypes.string,
      annualexemptionlimit: PropTypes.string,
      exemptionconsidered: PropTypes.string,
    }),
  ),
};

export default TDSWorkingTemplateTemplate;
