import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import MuiTab from 'components/Molecules/Tab';
import LanguageIcon from '@mui/icons-material/Language';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import SchoolIcon from '@mui/icons-material/School';
import HighlightIcon from '@mui/icons-material/Highlight';
import Onboarding from './Onboarding';
import SocialData from './SocialData';
import EducationHistory from './EducationHistory';
import EmploymentHistory from './EmploymentHistory';
import VisaDetail from './VisaDetails';
import KeyEventDetail from './KeyEventDetail';
import SkillsAttainmentDetail from './SkillsAttainmentDetails';
import { additionalDetailStyle } from './style';
import DependentDetails from './DependentDetails';

const AdditionalDetail = ({
  onboardingDetails,
  socialData,
  educationHistory,
  employmentHistory,
  visaDetails,
  skillsAttainmentDetails,
  keyEventDetails,
  dependentDetails,
}) => {
  const [initialTabs, setTabs] = useState([
    {
      icon: <GroupAddIcon />,
      iconPosition: 'start',
      label: 'Onboarding',
      onChange: () => {},
      RenderComponent: Onboarding,
      componentProps: { onboardingDetails },
    },
    {
      icon: <LanguageIcon />,
      iconPosition: 'start',
      label: 'Social Data',
      onChange: () => {},
      RenderComponent: SocialData,
      componentProps: { socialData },
    },
    {
      icon: <SchoolIcon />,
      iconPosition: 'start',
      label: 'Education/Certification History',
      onChange: () => {},
      RenderComponent: EducationHistory,
      componentProps: { educationHistory },
    },
    {
      icon: <WatchLaterIcon />,
      iconPosition: 'start',
      label: 'Employment History',
      onChange: () => {},
      RenderComponent: EmploymentHistory,
      componentProps: { employmentHistory },
    },
    {
      icon: <FlightTakeoffIcon />,
      iconPosition: 'start',
      label: 'Visa Details',
      onChange: () => {},
      RenderComponent: VisaDetail,
      componentProps: { visaDetails },
    },
    {
      icon: <HighlightIcon />,
      iconPosition: 'start',
      label: 'Skills Attainment Details',
      onChange: () => {},
      RenderComponent: SkillsAttainmentDetail,
      componentProps: { skillsAttainmentDetails },
    },
    {
      icon: <EmojiEventsIcon />,
      iconPosition: 'start',
      label: 'Key Life Event Details',
      onChange: () => {},
      RenderComponent: KeyEventDetail,
      componentProps: { keyEventDetails },
    },
    {
      icon: <EmojiEventsIcon />,
      iconPosition: 'start',
      label: 'Dependents Details',
      onChange: () => {},
      RenderComponent: DependentDetails,
      componentProps: { dependentDetails },
    },
  ]);

  return (
    <Box sx={additionalDetailStyle.wrapper}>
      <MuiTab
        orientation="vertical"
        textColor="primary"
        indicatorColor="primary"
        isVertical
        panelPadding={{ xs: '2rem 0', md: '2rem 0 2rem 3rem' }}
        isDropdownMob
        tabs={initialTabs}
        setTabs={setTabs}
      />
    </Box>
  );
};

AdditionalDetail.defaultProps = {
  onboardingDetails: {},
  socialData: {},
  educationHistory: [],
  employmentHistory: [],
  visaDetails: [],
  skillsAttainmentDetails: [],
  keyEventDetails: [],
  dependentDetails: [],
};

AdditionalDetail.propTypes = {
  onboardingDetails: PropTypes.shape({}),
  socialData: PropTypes.shape({}),
  educationHistory: PropTypes.arrayOf({}),
  employmentHistory: PropTypes.arrayOf({}),
  visaDetails: PropTypes.arrayOf({}),
  skillsAttainmentDetails: PropTypes.arrayOf({}),
  keyEventDetails: PropTypes.arrayOf({}),
  dependentDetails: PropTypes.arrayOf({}),
};

export default AdditionalDetail;
