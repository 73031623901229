import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Hidden from '@mui/material/Hidden';
import ResetPassword from 'components/Organisms/ResetPassword/index';
import Logo from 'components/Atoms/Logo';

const ResetPasswordTemplate = ({ loginPageLink, handleResetPassword }) => {
  return (
    <Box sx={{ flexGrow: 1, height: '100vh', p: { xs: 3, md: 0 } }}>
      <Hidden mdUp>
        <Box>
          <Logo alt="Logo" isDark imgHeight="32" imgWidth="115" />
        </Box>
      </Hidden>

      <Hidden mdDown>
        <Box px={6.5} pt={6.5}>
          <Logo alt="Logo" isDark />
        </Box>
      </Hidden>

      <Grid container height="100%">
        <Grid item xs={12} display="flex" alignItems="center" justifyContent="center">
          <ResetPassword loginPageLink={loginPageLink} handleResetPassword={handleResetPassword} />
        </Grid>
      </Grid>
    </Box>
  );
};

ResetPasswordTemplate.defaultProps = {
  loginPageLink: '/login',
  handleResetPassword: () => {},
};

ResetPasswordTemplate.propTypes = {
  loginPageLink: PropTypes.string,
  handleResetPassword: PropTypes.func,
};

export default ResetPasswordTemplate;
