import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import ActionDropDown from 'components/Molecules/ActionDropDown';
import DateRangePicker from 'components/Molecules/DateRangePicker';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { validateDateElseHyphen } from 'utils/commonUtils';
import { attendanceStyle } from '../style';

const RenderDateRangePicker = (handleSelect) => {
  return (
    <DateRangePicker
      placement="right-start"
      handleSubmit={(data) => handleSelect(data)}
      label={
        <Box sx={attendanceStyle.dateRange}>
          <Typographys color="inherit" variant="body2">
            Custom Date
          </Typographys>
          <ArrowForwardIosIcon sx={{ fontSize: '1.5rem' }} />
        </Box>
      }
    />
  );
};

const AttendanceStats = ({
  avgHours,
  arrivalTime,
  overtimeHrs,
  handleSelect,
  handleThisWeek,
  handleThisMonth,
  handleThisYear,
  handleDateRange,
}) => {
  const [filterLabel, setFilterLabel] = useState('This Week');

  const DateChange = (data) => {
    setFilterLabel(
      `${validateDateElseHyphen(data.startDate, 'DD MMMM YYYY')} to ${validateDateElseHyphen(
        data.endDate,
        'DD MMMM YYYY',
      )}`,
    );
    handleDateRange(data);
  };

  const acionOptions = [
    { id: 1, name: 'This Week', onClick: handleThisWeek },
    { id: 2, name: 'This Month', onClick: handleThisMonth },
    { id: 3, name: 'This Year', onClick: handleThisYear },
    { id: 4, formatValue: () => RenderDateRangePicker(DateChange) },
  ];

  return (
    <Box sx={attendanceStyle.wrapper}>
      <Box sx={attendanceStyle.statWrapper}>
        <Typographys variant="h6">Attendance stats</Typographys>

        <ActionDropDown options={acionOptions} handleSelect={handleSelect} label={filterLabel} />
      </Box>

      <Grid container columnSpacing={3} mt={4.5} mb={6}>
        <Grid item xs={12} md={4}>
          <Typographys variant="overline" color="neutral.600">
            Avg hrs/day
          </Typographys>

          <Typographys variant="h6"> {avgHours} </Typographys>
        </Grid>

        <Grid item xs={12} md={4}>
          <Typographys variant="overline" color="neutral.600">
            On time arrival
          </Typographys>

          <Typographys variant="h6"> {arrivalTime} </Typographys>
        </Grid>

        <Grid item xs={12} md={4}>
          <Typographys variant="overline" color="neutral.600">
            Overtime hrs/day
          </Typographys>
          <Typographys variant="h6"> {overtimeHrs} </Typographys>
        </Grid>
      </Grid>
    </Box>
  );
};

AttendanceStats.defaultProps = {
  avgHours: '',
  arrivalTime: '',
  overtimeHrs: '',
  handleSelect: () => {},
  handleThisWeek: () => {},
  handleThisMonth: () => {},
  handleThisYear: () => {},
  handleDateRange: () => {},
};

AttendanceStats.propTypes = {
  avgHours: PropTypes.string,
  arrivalTime: PropTypes.string,
  overtimeHrs: PropTypes.string,

  handleSelect: PropTypes.func,
  handleThisWeek: PropTypes.func,
  handleThisMonth: PropTypes.func,
  handleThisYear: PropTypes.func,
  handleDateRange: PropTypes.func,
};

export default AttendanceStats;
