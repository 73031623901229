import { Box, Grid, Card, CardContent, Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useToast from 'components/Provider/useToast';
import { formDataApi } from 'api/api';
import { styles } from './styles';
import PayHeadForm from './form';
import useFetchPayhead from '../ViewPastEmployerTax/useFetchPayhead';

const AddEditPayhead = (props) => {
  const params = useParams();
  const [payheadDetails, setPayheadDetails] = useState({
    data: {},
    isLoading: false,
  });
  const location = useLocation();
  const isEditMode = location.pathname.includes('edit');
  const { id } = useParams();
  const [payhead] = useFetchPayhead(id, 'edit', isEditMode);

  const addToast = useToast();

  useEffect(() => {
    const fetchData = async () => {
      setPayheadDetails({ ...payheadDetails, isLoading: true });
      const bodyFormData = new FormData();
      bodyFormData.append('id', params.id);
      const url = 'restPayIndex/createPayhead';
      try {
        const response = await formDataApi.post(url, bodyFormData);
        if (response?.data?.data) {
          setPayheadDetails({ data: response.data.data, isLoading: false });
        }
      } catch (err) {
        addToast({ type: 'error', title: 'ERROR', message: err?.message });
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, isEditMode, addToast]);

  return (
    <Box sx={styles.growBox}>
      <Container maxWidth="lg" sx={styles.pageWrapper}>
        <Grid container direction="column" spacing={2}>
          <Grid item container justifyContent="flex-start" alignItems="center" sx={{ pb: 1.5 }}>
            <Grid item>
              <Typography variant="h6" color="text.primary">
                {isEditMode ? 'Edit Tax Details' : 'Add New Tax Details'}
              </Typography>
            </Grid>
          </Grid>
          <Grid item sx={{ pt: '0px !important' }}>
            <Card {...props}>
              <CardContent>
                <Grid item sx={{ pt: 1 }}>
                  <PayHeadForm
                    results={payhead?.data?.data?.companyPayheadInstance}
                    isEditMode={isEditMode}
                    payheadDetails={payheadDetails}
                  />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AddEditPayhead;
