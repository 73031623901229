import { hasPermission } from 'utils/permissions';
import { useSelector } from 'react-redux';

const getActionItems = (services) => {
  return [
    {
      name: 'View',
      onClick: services.viewSeparation,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_EMPLOYEE_EXIT'],
    },
    {
      name: 'Edit',
      onClick: services.editSeparation,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_EMPLOYEE_EXIT'],
      condtional: (row) => row?.empExit?.lockStatus === 'UnLocked',
    },
    {
      name: 'Action Request',
      onClick: services.handleActionRequest,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_ACTION_EMPLOYEE_EXIT'],
      condtional: (row) => row?.empExit?.resignationStatus?.toUpperCase() === 'UNDER REVIEW',
    },
    {
      name: 'Lock Details',
      onClick: services.handleLockDetailsDialog,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_FINALIZE_SPECIFIC_EMPLOYEE_EXIT'],
      condtional: (row) => row?.empExit?.resignationStatus === 'Accepted' && row?.empExit?.lockStatus === 'UnLocked',
    },
    {
      name: 'Delete',
      onClick: services.handleDeleteDialog,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_DELETE_EMPLOYEE_EXIT'],
      condtional: (row) => row?.empExit?.lockStatus === 'UnLocked',
    },
    {
      name: 'Unlock Details',
      onClick: services.handleLockDetailsDialog,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_REOPEN_SPECIFIC_EMPLOYEE_EXIT'],
      condtional: (row) => row?.empExit?.lockStatus?.toUpperCase() === 'LOCKED',
    },
    {
      name: 'View Exit TaskList',
      onClick: services.handleViewExitTaskList,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_OFFBOARDING_TASKLIST'],
      condtional: (row) => row?.empExit?.resignationStatus === 'Accepted',
    },
  ];
};

const useActionItems = (services) => {
  const authorities = useSelector((state) => state.session.user.authorities);

  const actions = getActionItems(services);

  const handleActionItemClick = (row) => {
    const moreFilteredActionItems = actions.filter((action) => {
      const userHasPermission = hasPermission(authorities, action.permissions);
      if ((action.condtional && !action.condtional(row)) || !userHasPermission) {
        return false;
      }
      return true;
    });
    return moreFilteredActionItems;
  };

  return [handleActionItemClick];
};

export default useActionItems;
