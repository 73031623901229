export const TableFilterStyle = {
  filterActionBtn: {
    display: 'flex',
    gap: '3rem',
    marginTop: '1rem',
    justifyContent: 'end',
  },
  separator: {
    backgroundColor: 'other.200',
    my: 2,
    mx: -3,
  },
};
