import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Skeleton } from '@mui/material';
import DeleteLCPolicy from 'components/Organisms/Dialog/Employee/DeleteLCPolicy';
import RemoveEmployee from 'components/Organisms/Dialog/Employee/RemoveEmployee';
import GraphWithComponentDetail from 'components/Organisms/GraphWithComponentDetail';
import GraphColor from 'utils/GraphColor';
import LCPolicyListItemWithSearch from './LCPolicyListItemWithSearch';
import LCPolicyListData from './LCPolicyListData';

const LCPoliciesTab = ({
  lcPoliciesGraphData,
  policyListItems,
  fetchMorePolicyListItems,
  handleSearch,
  handleSearchAdd,
  listFilterFields,
  listHandleFilter,
  policyName,
  empColumns,
  empTotalRecordCount,
  empLoadNextPageData,
  empHandleSort,
  empHandleRowClick,
  empPage,
  empSortBy,
  empSortDirection,
  empMinRecordsLength,
  empHandleSelect,
  empOnSelectedChange,
  handleDetails,
  handleAdd,
  handleDelete,
  handleCancel,
  openDeleteLCPolicy,
  handleClose,
  handleDeleteEmployee,
  handleCancelEmployee,
  openEmployee,
  handleCloseEmployee,
  numOfEmployee,
  handleRemoveEmployee,
  onSelectPolicy,
  allEmployees,
  policyInstance,
  handlePolicyItemClick,
  selectedItems,
  onSelect,
  onSelectAll,
  showRowSelector,
  showPagination,
  keyColumn,
  actionMenuLists,
  loading,
}) => {
  const [policyItem, setPolicyItem] = useState(policyInstance || policyListItems[0]);
  const [isDeleteLCPolicy, setIsDeleteLCPolicy] = useState(false);
  const [isRemoveEmployee, setIsRemoveEmployee] = useState(false);

  useEffect(() => {
    setPolicyItem(policyInstance);
  }, [policyInstance]);

  return (
    <Box>
      <Box px={3} py={2}>
        <GraphWithComponentDetail graphData={lcPoliciesGraphData} graphColors={GraphColor('empMappingGraph')} />
      </Box>
      <Grid container>
        <Grid item xs={12} md={4}>
          <LCPolicyListItemWithSearch
            policyListItems={policyListItems}
            fetchMorePolicyListItems={fetchMorePolicyListItems}
            handleSearch={handleSearch}
            handleAdd={handleSearchAdd}
            setPolicyItem={(selectedItem) => {
              setPolicyItem(selectedItem);
              onSelectPolicy(selectedItem);
            }}
            handlePolicyItemClick={handlePolicyItemClick}
            loading={loading}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          {loading.policyLoading ? (
            <Box sx={{ width: '100%', padding: 2 }}>
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Box>
          ) : (
            <LCPolicyListData
              listFilterFields={listFilterFields}
              listHandleFilter={listHandleFilter}
              policyName={policyName}
              policyItem={policyItem}
              allEmployees={allEmployees}
              handlePolicyItemClick={handlePolicyItemClick}
              empColumns={empColumns}
              empTotalRecordCount={empTotalRecordCount}
              empLoadNextPageData={empLoadNextPageData}
              empHandleSort={empHandleSort}
              empHandleRowClick={empHandleRowClick}
              empPage={empPage}
              empSortBy={empSortBy}
              empSortDirection={empSortDirection}
              empMinRecordsLength={empMinRecordsLength}
              empHandleSelect={empHandleSelect}
              empOnSelectedChange={empOnSelectedChange}
              handleDetails={handleDetails}
              empActionMenuLists={actionMenuLists}
              setIsRemoveEmployee={setIsRemoveEmployee}
              setIsDeleteLCPolicy={setIsDeleteLCPolicy}
              handleRemove={() => setIsRemoveEmployee(true)}
              handleAdd={(selectedEmployeeIds) => {
                handleAdd({
                  employeeIds: selectedEmployeeIds,
                  policyId: policyItem.id,
                });
              }}
              handleDelete={handleDelete}
              handleCancel={handleCancel}
              openDeleteLCPolicy={openDeleteLCPolicy}
              handleClose={handleClose}
              handleDeleteEmployee={handleDeleteEmployee}
              handleCancelEmployee={handleCancelEmployee}
              openEmployee={openEmployee}
              handleCloseEmployee={handleCloseEmployee}
              numOfEmployee={numOfEmployee}
              selectedItems={selectedItems}
              onSelect={onSelect}
              onSelectAll={onSelectAll}
              showPagination={showPagination}
              showRowSelector={showRowSelector}
              keyColumn={keyColumn}
              loading={loading}
            />
          )}
        </Grid>
      </Grid>
      <DeleteLCPolicy
        handleDelete={() => {
          handleDelete(policyItem);
        }}
        handleCancel={() => setIsDeleteLCPolicy(false)}
        open={isDeleteLCPolicy}
        handleClose={() => setIsDeleteLCPolicy(false)}
        deletePolicyName={policyItem.name || policyItem.heading}
      />
      <RemoveEmployee
        handleDelete={handleRemoveEmployee}
        handleCancel={() => setIsRemoveEmployee(false)}
        open={isRemoveEmployee}
        handleClose={() => setIsRemoveEmployee(false)}
      />
    </Box>
  );
};

LCPoliciesTab.defaultProps = {
  lcPoliciesGraphData: [
    { name: 'Invite Sent', value: 50 },
    { name: 'Pending invites', value: 8 },
    { name: 'Onboarding Initated', value: 4 },
  ],
  handleSearch: () => {},
  handleSearchAdd: () => {},
  policyListItems: [
    {
      id: 1,
      heading: 'Default Life Cycle Policy',
      subHeading: '45 employees',
      employeeList: [
        {
          id: 1,
          employeeId: 101,
          employeeName: 'Employee 1',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 102,
          employeeName: 'Employee 2',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 2,
      heading: 'Life Cycle Policy 2022',
      subHeading: '45 employees',
      employeeList: [
        {
          id: 1,
          employeeId: 103,
          employeeName: 'Employee 3',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 104,
          employeeName: 'Employee 4',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 3,
      heading: 'Updated Life Cycle Policy',
      subHeading: '45 employees',
      employeeList: [
        {
          id: 1,
          employeeId: 105,
          employeeName: 'Employee 5',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 106,
          employeeName: 'Employee 6',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
    {
      id: 4,
      heading: 'Description',
      subHeading: 'Vel Diam ultiricies diashadsajdn',
      employeeList: [
        {
          id: 1,
          employeeId: 107,
          employeeName: 'Employee 7',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Finance',
          designation: 'Base Department',
          location: 'mumbai',
        },
        {
          id: 2,
          employeeId: 108,
          employeeName: 'Employee 8',
          lcPolicy: 'Life Cycle Policy 2022',
          department: 'Services',
          designation: 'Base Department',
          location: 'mumbai',
        },
      ],
    },
  ],
  listFilterFields: [
    {
      name: 'Employee ID',
      type: 'number',
    },
    {
      name: 'Employee Name',
      type: 'string',
    },
    {
      name: 'LC Policy',
      type: 'string',
    },
    {
      name: 'Department',
      type: 'string',
    },
    {
      name: 'Designation',
      type: 'string',
    },

    {
      name: 'Location',
      type: 'string',
    },
  ],
  listHandleFilter: () => {},
  policyName: 'Default life Cycle Policy',
  empColumns: [
    {
      fieldName: 'employeeId',
      numeric: true,
      label: 'EMPLOYEE ID',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'employeeName',
      numeric: true,
      label: 'EMPLOYEE NAME',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'lcPolicy',
      numeric: false,
      label: 'LC POLICY',
      formatValue: ({ row, column }) => row[column.fieldName],
    },

    {
      fieldName: 'department',
      numeric: true,
      label: 'DEPARTMENT',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'designation',
      numeric: true,
      label: 'DESIGNATION',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'location',
      numeric: true,
      label: 'LOCATION',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
  ],
  empTotalRecordCount: 5,
  empLoadNextPageData: () => {},
  empHandleSort: () => {},
  empHandleRowClick: () => {},
  empPage: 1,
  empSortBy: '',
  empSortDirection: 'asc',
  empMinRecordsLength: 4,
  empHandleSelect: () => {},
  empOnSelectedChange: () => {},

  handleDetails: () => {},
  handleAdd: () => {},
  handleDelete: () => {},
  handleCancel: () => {},
  openDeleteLCPolicy: false,
  handleClose: () => {},
  handleDeleteEmployee: () => {},
  handleCancelEmployee: () => {},
  openEmployee: false,
  handleCloseEmployee: () => {},
  numOfEmployee: 1,
  handleRemoveEmployee: () => {},
  fetchMorePolicyListItems: () => {},
  allEmployees: [
    {
      id: 1,
      employeeId: 101,
      employeeName: 'Employee 1',
      lcPolicy: 'Life Cycle Policy 2022',
      department: 'Finance',
      designation: 'Base Department',
      location: 'mumbai',
      selected: true,
    },
    {
      id: 2,
      employeeId: 102,
      employeeName: 'Employee 2',
      lcPolicy: 'Life Cycle Policy 2022',
      department: 'Services',
      designation: 'Base Department',
      location: 'mumbai',
    },
  ],
  onSelectPolicy: () => {},
  policyInstance: { name: 'Default life Cycle Policy' },
  handlePolicyItemClick: () => {},
  loading: {},
  selectedItems: [],
  onSelect: () => {},
  onSelectAll: () => {},
  showRowSelector: false,
  showPagination: false,
  keyColumn: '',
  actionMenuLists: [
    { id: 1, label: 'Edit', onClick: () => {} },
    { id: 2, label: 'Delete', onClick: () => {} },
  ],
};

LCPoliciesTab.propTypes = {
  lcPoliciesGraphData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  policyListItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      heading: PropTypes.string,
      subHeading: PropTypes.string,
      employeeList: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          employeeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          employeeName: PropTypes.string,
          lcPolicy: PropTypes.string,
          department: PropTypes.string,
          designation: PropTypes.string,
          location: PropTypes.string,
        }),
      ),
    }),
  ),
  handleSearch: PropTypes.func,
  handleSearchAdd: PropTypes.func,
  listFilterFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  listHandleFilter: PropTypes.func,
  policyName: PropTypes.string,
  empColumns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      numeric: PropTypes.bool,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),
  empTotalRecordCount: PropTypes.number,
  empLoadNextPageData: PropTypes.func,
  empHandleSort: PropTypes.func,
  empHandleRowClick: PropTypes.func,
  empPage: PropTypes.number,
  empSortBy: PropTypes.string,
  empSortDirection: PropTypes.oneOf(['asc', 'desc']),
  empMinRecordsLength: PropTypes.number,
  empHandleSelect: PropTypes.func,
  empOnSelectedChange: PropTypes.func,
  handleDetails: PropTypes.func,
  handleAdd: PropTypes.func,
  openDeleteLCPolicy: PropTypes.bool,
  handleClose: PropTypes.func,
  handleDelete: PropTypes.func,
  handleCancel: PropTypes.func,
  handleDeleteEmployee: PropTypes.func,
  handleCancelEmployee: PropTypes.func,
  openEmployee: PropTypes.bool,
  handleCloseEmployee: PropTypes.func,
  numOfEmployee: PropTypes.number,
  handleRemoveEmployee: PropTypes.func,
  fetchMorePolicyListItems: PropTypes.func,
  allEmployees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      employeeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      employeeName: PropTypes.string,
      lcPolicy: PropTypes.string,
      department: PropTypes.string,
      designation: PropTypes.string,
      location: PropTypes.string,
      selected: true,
    }),
  ),
  onSelectPolicy: PropTypes.func,
  policyInstance: PropTypes.shape({
    name: PropTypes.string,
  }),
  handlePolicyItemClick: PropTypes.func,
  loading: PropTypes.bool,
  selectedItems: PropTypes.arrayOf,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  showRowSelector: PropTypes.bool,
  showPagination: PropTypes.bool,
  keyColumn: PropTypes.string,
  actionMenuLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
};

export default LCPoliciesTab;
