import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SalaryDetailsTemplate from 'components/Templates/MyPay/SalaryDetails';
import { Typography, Skeleton, Box } from '@mui/material';
import useFetcher from 'hooks/useFetcher';
import { getMySalaryDetails } from 'api/home/myPay';
import { hasPermission } from 'utils/permissions';
import { getConfiguredSalaryDetails } from './getConfiguredSalaryDetails';
import { getColumns } from './getColumns';

const MySalaryDetails = () => {
  const [myPayMySalaryDetails] = useFetcher(getMySalaryDetails);
  const navigate = useNavigate();
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasViewTaxWorkingPermission = hasPermission(authorities, [
    'ROLE_BASE_PERMISSION_PAY_INDEX_VIEW_MY_TAX_WORKING',
  ]);

  if (myPayMySalaryDetails.error !== null) {
    return (
      <Typography variant="h6">
        {myPayMySalaryDetails?.error?.response?.data?.message || 'Error while fetching my salary details.'}
      </Typography>
    );
  }

  const configuredSalaryDetails = getConfiguredSalaryDetails(myPayMySalaryDetails?.data);
  const columns = getColumns();

  const handleViewTaxWorking = () => {
    navigate('/home/myPay/tds-workings');
  };

  return myPayMySalaryDetails.isLoading ? (
    <Box sx={{ p: 4 }}>
      <Skeleton height={70} />
      <Skeleton />
      <Skeleton />
    </Box>
  ) : (
    <div>
      <SalaryDetailsTemplate
        employeeData={configuredSalaryDetails.employeeData}
        employeeId={configuredSalaryDetails.employeeId}
        employeeName={configuredSalaryDetails.employeeName}
        earningsColumns={columns.earningsColumns}
        earningsRows={configuredSalaryDetails.earningsRows}
        variablePayColumns={columns.variablePayColumns}
        variablePayRows={configuredSalaryDetails.variablePayRows}
        statutoryDeductionsColumns={columns.statutoryDeductionsColumns}
        statutoryDeductionsRows={configuredSalaryDetails.statutoryDeductionsRows}
        employerRetiralColumns={columns.employerRetiralColumns}
        employerRetiralRows={configuredSalaryDetails.employerRetiralRows}
        fixedComponentsColumns={columns.fixedComponentsColumns}
        fixedComponentsRows={configuredSalaryDetails.fixedComponentsRows}
        netSalaryColumns={columns.netSalaryColumns}
        netSalaryRows={configuredSalaryDetails.netSalaryRows}
        netVariablePayColumns={columns.netVariablePayColumns}
        netVariablePayRows={configuredSalaryDetails.netVariablePayRows}
        retiralColumns={columns.retiralColumns}
        retiralRows={configuredSalaryDetails.retiralRows}
        totalColumns={columns.totalColumns}
        totalRows={configuredSalaryDetails.totalRows}
        isOverTimeApplicable={configuredSalaryDetails.isOverTimeApplicable}
        handleViewTaxWorking={handleViewTaxWorking}
        benefitsRows={configuredSalaryDetails.benefitsRows}
        benifitColumns={columns.benefitsColumns}
        reimbursementsRows={configuredSalaryDetails.reimbursementsRows}
        reimbursementColumns={columns.reimbursementsColumns}
        userHasViewTaxWorkingPermission={userHasViewTaxWorkingPermission}
      />
    </div>
  );
};

export default MySalaryDetails;
