import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider } from '@mui/material';
import Table from 'components/Molecules/TableExtended';
import Typography from 'components/Atoms/Typography';
import IconWithTitle from 'components/Atoms/IconWithTitle';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useTheme } from '@mui/material/styles';

const ExpenseListingTable = ({
  rows,
  page,
  handleSelect,
  roleBasedMenu,
  columns,
  actionLabel,
  handleAttachmentDownload,
  supportingDocuments,
}) => {
  const theme = useTheme();
  return (
    <Box padding="0 3rem">
      <Typography variant="body1" fontWeight={600} sx={{ mb: 1.5 }}>
        Detailed Expenses Listing
      </Typography>
      <Table
        rows={rows}
        columns={columns}
        page={page}
        handleSelect={handleSelect}
        roleBasedMenu={roleBasedMenu}
        isDynamic
        actionLabel={actionLabel}
      />

      <Divider sx={{ mt: 0.5, mb: 2, backgroundColor: theme.palette.neutral[200] }} />

      <Box sx={{ mt: 1.5, mb: 1.5 }}>
        <Typography variant="h6" color="neutral.800">
          Attachments
        </Typography>

        <Box sx={{ mt: 1.5, width: 'max-content' }}>
          {supportingDocuments?.length > 0 ? (
            <Box>
              {supportingDocuments?.map((item, i) => (
                <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                  <span>{i + 1}.</span>
                  <IconWithTitle
                    sx={{ cursor: 'pointer', width: 'max-content' }}
                    iconElement={<AttachFileIcon fontSize="inherit" />}
                    iconColor="actions.600"
                    iconFontSize="2.5rem"
                    title={item?.fileName}
                    titleColor="neutral.800"
                    titleVariant="body1"
                    onClick={() => handleAttachmentDownload(item)}
                    isClickable
                  />
                </Box>
              ))}
            </Box>
          ) : (
            <Typography>No attached file</Typography>
          )}
        </Box>
      </Box>
      <Divider
        sx={{
          mt: 0.5,
          mb: 2,
          backgroundColor: theme.palette.neutral[200],
        }}
      />
    </Box>
  );
};

ExpenseListingTable.defaultProps = {
  roleBasedMenu: () => {},
  rows: [],
  columns: [],
  handleSelect: () => {},
  page: '',
  actionLabel: 'Download Attachment',
  handleAttachmentDownload: () => {},
  supportingDocuments: [],
};

ExpenseListingTable.propTypes = {
  roleBasedMenu: PropTypes.func,
  handleSelect: PropTypes.func,
  page: PropTypes.number,
  rows: PropTypes.arrayOf,
  columns: PropTypes.arrayOf,
  actionLabel: PropTypes.string,
  handleAttachmentDownload: PropTypes.func,
  supportingDocuments: PropTypes.arrayOf,
};

export default ExpenseListingTable;
