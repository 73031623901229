import React, { useEffect } from 'react';
import ProfileBankAccountTemplate from 'components/Templates/Profile/BankAccount';
import { useThunk } from 'hooks/useThunk';
import { useSelector } from 'react-redux';
import { fetchBankAccountDetails } from 'store';
import { useNavigate } from 'react-router-dom';
import { Typography, Skeleton, Box } from '@mui/material';

const BankAccounts = () => {
  const navigate = useNavigate();

  const [doFetchBankAccountDetails, isLoadingBankAccountDetails, loadingBankAccountDetailsError] =
    useThunk(fetchBankAccountDetails);

  const { bankAccounts } = useSelector((state) => {
    return state.profile;
  });

  useEffect(() => {
    doFetchBankAccountDetails();
  }, [doFetchBankAccountDetails]);

  const handleEdit = () => {
    navigate('/profile/bank-accounts/addEdit');
  };

  if (isLoadingBankAccountDetails) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (loadingBankAccountDetailsError) {
    return (
      <Typography variant="h6">
        {loadingBankAccountDetailsError?.message || 'Error while fetching bank details.'}
      </Typography>
    );
  }

  return (
    <ProfileBankAccountTemplate
      bankDetails={bankAccounts}
      handleEdit={handleEdit}
      isLoading={isLoadingBankAccountDetails}
    />
  );
};

export default BankAccounts;
