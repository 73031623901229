import React from 'react';
import PropTypes from 'prop-types';
import EmployeeCommonCard from 'components/Organisms/Employee/EmployeeCommonCard';
import GenderGraph from '../Charts/GenderGraph';

const GenderCard = ({ genderData, totalValue }) => {
  return (
    <EmployeeCommonCard
      cardHeaderTitle="Gender"
      isHeaderFilter={false}
      bodyContent={<GenderGraph genderData={genderData} totalValue={totalValue} />}
      isFooter={false}
      cardBodyMinHeight="35rem"
      cardBodyPadding="2rem 0 0 0"
    />
  );
};

GenderCard.defaultProps = {
  genderData: [],
  totalValue: 100,
};
GenderCard.propTypes = {
  genderData: PropTypes.arrayOf,
  totalValue: PropTypes.number,
};

export default GenderCard;
