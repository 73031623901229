import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import HomeDashboardTemplate from 'components/Templates/Dashboard/HomeDashboard';
import { API_URL } from 'api/api';
import { useThunk } from 'hooks/useThunk';
import { useSelector } from 'react-redux';
import {
  fetchmyLeaveDashboard,
  fetchemployeeEventData,
  fetchdocumentAndProjectData,
  fetchemployeeLoginData,
  fetchAnnouncementDetails,
} from 'store';
import { Typography, Skeleton, Box } from '@mui/material';
import { isObject, isArray, isEmpty } from 'lodash';
import CakeIcon from '@mui/icons-material/Cake';
import CelebrationIcon from '@mui/icons-material/Celebration';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import PhoneIcon from '@mui/icons-material/Phone';
import { validateDateElseHyphen } from 'utils/commonUtils';
import useDownloadFile from 'hooks/useDownloadFile';
import { filterActionMenu } from 'utils/filterActionMenu';
import CandidateDashboard from 'pages/home/CandidateDashboard';
import { useColumns, useHolidayColumn } from './useColumns';

const HomeDashboardPage = () => {
  const [docFilterLabel, setDocFilterLabel] = useState('My Documents');
  const [doFetchHomeDashboard, isLoadingHomeDashboard, loadingHomeDashboardError] = useThunk(fetchmyLeaveDashboard);
  const [doFetchHomeDashboardEvent, isLoadingHomeDashboardEvent, loadingHomeDashboardErrorEvent] =
    useThunk(fetchemployeeEventData);
  const [doFetchHomeDashboardDoc, isLoadingHomeDashboardDoc, loadingHomeDashboardErrorDoc] =
    useThunk(fetchdocumentAndProjectData);
  const [doFetchAnnouncementDetails, isLoadingAnnouncementDetails, loadingAnnouncementDetailsError] =
    useThunk(fetchAnnouncementDetails);
  const { authorities = [], empDBID = '', candidateID = '' } = useSelector((state) => state?.session?.user);
  const [columns] = useColumns();
  const [holidayColumn] = useHolidayColumn();
  const navigate = useNavigate();
  const downloadFile = useDownloadFile();

  const actionMenuList = [
    {
      id: 1,
      label: 'Apply for Leave',
      onClick: () => navigate('/home/leaves/my-leave-requests/add'),
      permissions: ['ROLE_BASE_PERMISSION_LEAVE_INDEX_CREATE_MY_LEAVE_REQUEST'],
    },
    {
      id: 2,
      label: 'Add Expense',
      onClick: () => navigate('/home/expenses/add'),
      permissions: ['ROLE_BASE_PERMISSION_EXPENSE_INDEX_CREATE_EXPENSE_REPORT'],
    },
  ];

  const filteredActionList = filterActionMenu(actionMenuList, authorities);

  useEffect(() => {
    if (authorities?.includes('ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_ALUMNI_DASHBOARD')) {
      navigate('/home/alumni-dashboard');
    } else if (empDBID) {
      doFetchHomeDashboard();
      doFetchHomeDashboardEvent();
      doFetchHomeDashboardDoc();
    }
  }, [doFetchHomeDashboard, doFetchHomeDashboardEvent, doFetchHomeDashboardDoc]);

  const {
    homeDashboardDetails = {},
    announcementInstanceList = [],
    surveySheetInstanceList = [],
    annivEmpList = [],
    empDocuments,
    companyDocuments = [],
  } = useSelector((state) => state?.dashboard);
  const { user = {} } = useSelector((state) => state?.session);

  const {
    thisWeekEmpIDAvgInTimeMap = [],
    thisWeekEmpIDAvgOutTimeMap = [],
    thisWeekEmpIDDurationMap = [],
    thisWeekEmpIDFullDayDifferentialMap = [],
    thisMonthEmpIDAvgInTimeMap = [],
    thisMonthEmpIDAvgOutTimeMap = [],
    thisMonthEmpIDDurationMap = [],
    thisMonthEmpIDFullDayDifferentialMap = [],
    ytdEmpIDAvgInTimeMap = [],
    ytdEmpIDAvgOutTimeMap = [],
    ytdEmpIDDurationMap = [],
    ytdEmpIDFullDayDifferentialMap = [],
    leaveAccountsMap = [],
    reportees = [],
    companyCalendarInstance = [],
    empIDLeaveCatWiseTranMap = {},
    companyConfig = {},
  } = homeDashboardDetails;

  const [attendanceData, setAttendanceStatsData] = useState([]);

  const extractHours = (value) => {
    // This regex extracts the number of hours from a string ending with "Hours;"
    const hoursMatch = value?.match(/(\d+) Hours;/);
    const minutesMatch = value?.match(/(\d+) Minutes;/);
    return hoursMatch || minutesMatch ? `${hoursMatch[1]} Hrs ${minutesMatch[1]} Min` : '';
  };

  const extractHourFromTimeString = (dateTimeString) => {
    return dateTimeString && dayjs(dateTimeString, 'DD-MMM-YYYY HH:mm:ss')?.isValid()
      ? dayjs(dateTimeString)?.format('h:m A')
      : '';
  };

  const handleAttendanceData = (filterValue) => {
    let avgInTimeMap = [];
    let avgOutTimeMap = [];
    let durationMap = [];
    let fullDayDifferentialMap = [];

    if (filterValue === 'This Week') {
      avgInTimeMap = thisWeekEmpIDAvgInTimeMap;
      avgOutTimeMap = thisWeekEmpIDAvgOutTimeMap;
      durationMap = thisWeekEmpIDDurationMap;
      fullDayDifferentialMap = thisWeekEmpIDFullDayDifferentialMap;
    } else if (filterValue === 'This Month') {
      avgInTimeMap = thisMonthEmpIDAvgInTimeMap;
      avgOutTimeMap = thisMonthEmpIDAvgOutTimeMap;
      durationMap = thisMonthEmpIDDurationMap;
      fullDayDifferentialMap = thisMonthEmpIDFullDayDifferentialMap;
    } else if (filterValue === 'This Year') {
      avgInTimeMap = ytdEmpIDAvgInTimeMap;
      avgOutTimeMap = ytdEmpIDAvgOutTimeMap;
      durationMap = ytdEmpIDDurationMap;
      fullDayDifferentialMap = ytdEmpIDFullDayDifferentialMap;
    }

    setAttendanceStatsData([
      {
        heading: 'Average In Time',
        value: extractHourFromTimeString(isObject(avgInTimeMap) ? Object?.values(avgInTimeMap)[0] ?? '' : ''),
      },
      {
        heading: 'Average Out Time',
        value: extractHourFromTimeString(isObject(avgOutTimeMap) ? Object?.values(avgOutTimeMap)[0] ?? '' : ''),
      },
      {
        heading: 'Average Hrs Worked',
        value: extractHours(isObject(durationMap) ? Object?.values(durationMap)[0] ?? '' : ''),
      },
      {
        heading: 'Required Hrs(full day)',
        value: companyConfig?.minHoursForFullDayAttendance || '7 Hours',
      },
    ]);
  };

  const companyConfigHours = {
    officeHoursStartTime: extractHourFromTimeString(companyConfig?.officeHoursStartTime) || '9:00 AM',
    officeHoursEndTime: extractHourFromTimeString(companyConfig?.officeHoursEndTime) || '6:00 AM',
  };

  const leavesData =
    isObject(leaveAccountsMap) && !isEmpty(leaveAccountsMap)
      ? Object?.entries(leaveAccountsMap)?.map(([leaveType, data]) => ({
          heading: leaveType,
          value: data?.currentBalance,
        }))
      : [];

  const companyDoc = useMemo(() => {
    if (isArray(companyDocuments) && !isEmpty(companyDocuments)) {
      return companyDocuments.map((doc, index) => ({
        slNo: index + 1,
        id: doc?.id,
        documentName: doc?.fileName,
        section: doc?.documentType,
      }));
    }
    return [];
  }, [companyDocuments]);

  const employeeDoc = useMemo(() => {
    if (isArray(empDocuments) && !isEmpty(empDocuments)) {
      return empDocuments.map((doc, index) => ({
        slNo: index + 1,
        id: doc?.id,
        documentName: doc?.fileName,
        section: doc?.documentType,
      }));
    }
    return [];
  }, [empDocuments]);

  const [DocData, setDocData] = useState([]);

  useEffect(() => {
    setDocData(employeeDoc);
  }, [employeeDoc]);

  const docFilterOption = [
    {
      name: 'My Documents',
      onClick: () => {
        setDocData(employeeDoc);
        setDocFilterLabel('My Documents');
      },
    },
    {
      name: 'Company Documents',
      onClick: () => {
        setDocData(companyDoc);
        setDocFilterLabel('Company Documents');
      },
    },
  ];

  const documentsHandleDownload = (row) => {
    downloadFile({ docid: row?.id }, row?.documentName, 'restExpIndex/downloadAttachment');
  };

  useEffect(() => {
    if (Object.keys(homeDashboardDetails).length > 0) {
      handleAttendanceData('This Week');
    }
  }, [homeDashboardDetails]);

  const handleDetails = (id) => {
    const formData = new FormData();
    formData.append('id', id);
    doFetchAnnouncementDetails(formData);
  };

  if (isLoadingHomeDashboard || isLoadingHomeDashboardEvent || isLoadingHomeDashboardDoc) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (loadingHomeDashboardError || loadingHomeDashboardErrorEvent || loadingHomeDashboardErrorDoc) {
    return (
      <Typography variant="h6">
        {loadingHomeDashboardError?.message ||
          loadingHomeDashboardErrorEvent?.message ||
          loadingHomeDashboardErrorDoc?.message ||
          'Error while fetching dashboard details.'}
      </Typography>
    );
  }

  const surveys =
    isArray(surveySheetInstanceList) && !isEmpty(surveySheetInstanceList)
      ? surveySheetInstanceList?.map((survey) => ({
          id: survey?.id,
          title: survey?.title,
          startDate: survey?.startDate,
          endDate: survey?.endDate,
          time: `${validateDateElseHyphen(survey?.startDate, 'DD MMMM YYYY')} - ${validateDateElseHyphen(
            survey?.endDate,
            'DD MMMM YYYY',
          )}`,
          unRead: false,
        }))
      : [];

  const announcement =
    isArray(announcementInstanceList) && !isEmpty(announcementInstanceList)
      ? announcementInstanceList?.map((announcements) => ({
          id: announcements?.id,
          title: announcements?.title,
          time: validateDateElseHyphen(announcements?.publishDate, 'DD MMMM YYYY'),
          unRead: false,
        }))
      : [];

  const holiday =
    isArray(companyCalendarInstance?.holidays) && !isEmpty(companyCalendarInstance?.holidays)
      ? companyCalendarInstance?.holidays?.map((holidays, index) => ({
          slNo: index + 1,
          date: validateDateElseHyphen(holidays?.holidayDate, 'dddd, DD MMMM YYYY'),
          occasion: holidays?.occassion,
          description: holidays?.description,
          holidayType: holidays?.holidayType,
        }))
      : [];

  const teamMembers =
    isArray(reportees) && !isEmpty(reportees)
      ? reportees?.map((item) => ({
          profileImg: item?.profile?.profilePic || '',
          profileName: item?.fullName,
          isDesignation: true,
          designation: item?.designation?.name || '',
          emailAddressesIcon: <MarkunreadIcon fontSize="inherit" />,
          emailAddresses: item?.emailAddresses,
          mobileNumbersIcon: <PhoneIcon fontSize="inherit" />,
          mobileNumbers: item?.mobileNumbers,
        }))
      : [];

  const birthday =
    isArray(annivEmpList) && !isEmpty(annivEmpList)
      ? annivEmpList?.map((item) => ({
          profileImg: item?.profile?.profilePic || '',
          profileName: item?.fullName || '',
          isDesignation: false,
          dateOfBirthIcon: <CakeIcon fontSize="inherit" />,
          dateOfBirth: validateDateElseHyphen(item?.dateOfBirth, 'DD MMM YYYY'),
          dateOfJoiningIcon: <CelebrationIcon fontSize="inherit" />,
          dateOfJoining: validateDateElseHyphen(item?.dateOfJoining, 'DD MMM YYYY'),
          departmentIcon: <CorporateFareIcon fontSize="inherit" />,
          department: item?.department?.name || '',
          locationIcon: <LocationOnIcon fontSize="inherit" />,
          location: item?.location?.name || '',
        }))
      : [];

  const event =
    isArray(companyCalendarInstance?.holidays) && !isEmpty(companyCalendarInstance?.holidays)
      ? companyCalendarInstance?.holidays?.map((cal) => {
          const isPublicHoliday = cal?.holidayType === 'Public Holiday';
          const holidayDate = new Date(cal?.holidayDate);

          return {
            title: cal?.occassion || '',
            allDay: true,
            start: holidayDate || '',
            end: holidayDate || '',
            color: '#215263',
            textColor: 'white',
            type: isPublicHoliday ? 'holiday' : cal?.holidayType,
            desc: cal?.holidayType || '',
          };
        })
      : [];

  function processLeaves(input) {
    const output = [];
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const employeeId in input) {
      const leaveTypes = Object.keys(input[employeeId]);
      // eslint-disable-next-line no-restricted-syntax
      for (const leaveType of leaveTypes) {
        input[employeeId][leaveType].forEach((leave) => {
          output.push({
            ...leave,
            type: leaveType,
          });
        });
      }
    }
    return output;
  }

  const leaveData = processLeaves(empIDLeaveCatWiseTranMap);
  const leaves =
    isArray(leaveData) && !isEmpty(leaveData)
      ? leaveData?.map((item) => {
          return {
            color: '#215263',
            textColor: 'white',
            type: 'leaves',
            allDay: true,
            employeeImage:
              item?.employee?.profilePic && item?.employee?.profilePic !== 'null'
                ? `${API_URL}/${item?.employee?.profilePic}`
                : '',
            title: item?.title || '',
            employeeDesignation: item?.employee?.designation || '',
            employeeName: item?.employee?.name || '',
            reason: item?.description || '',
            start: item?.startDate || '',
            end: item?.endDate || '',
            leaveType: item?.leaveType || '',
            desc: item?.description || '',
          };
        })
      : [];

  const getDuration = (durationInMilliseconds, timeFormat) => {
    if (durationInMilliseconds === 0) {
      return durationInMilliseconds;
    }
    if (timeFormat === 'hour') {
      const timeInHours = Math.round(durationInMilliseconds / 3600000);
      return timeInHours;
    }
    if (timeFormat === 'min') {
      const timeInMinutes = Math.round((durationInMilliseconds / 60000) % 60);
      return timeInMinutes;
    }
    return '';
  };

  const progressBarList = {
    duration: {
      hour: getDuration(homeDashboardDetails?.timer?.totalRunningTime || 0, 'hour'),
      min: getDuration(homeDashboardDetails?.timer?.totalRunningTime || 0, 'min'),
    },
    break: {
      hour: getDuration(homeDashboardDetails?.timer?.totalPausedTime || 0, 'hour'),
      min: getDuration(homeDashboardDetails?.timer?.totalPausedTime || 0, 'min'),
    },
    overtime: {
      hour: getDuration(homeDashboardDetails?.timer?.totalOTDuration || 0, 'hour'),
      min: getDuration(homeDashboardDetails?.timer?.totalOTDuration || 0, 'min'),
    },
  };

  return (
    <>
      {!!candidateID && <CandidateDashboard />}
      {!!empDBID && (
        <HomeDashboardTemplate
          Heading={`${user?.firstName || ''} ${user?.lastName || ''}`}
          actionMenuLists={filteredActionList}
          progressBarList={progressBarList}
          attendanceStatsData={attendanceData}
          handleAttendanceData={handleAttendanceData}
          balanceLeave={leavesData}
          employeeList={birthday}
          teamMembersList={teamMembers}
          holidaysColumn={holidayColumn}
          holidaysRow={holiday}
          documentsColumn={columns}
          documentsRow={DocData || []}
          docFilterLabel={docFilterLabel}
          documentsHandleDownload={documentsHandleDownload}
          docFilterOptions={docFilterOption}
          surveysList={surveys}
          announcementList={announcement}
          events={[...event, ...leaves]}
          handleDetails={handleDetails}
          loadingAnnouncementDetails={isLoadingAnnouncementDetails}
          loadingAnnouncementDetailsError={loadingAnnouncementDetailsError}
          companyConfigHours={companyConfigHours}
        />
      )}
    </>
  );
};

export default HomeDashboardPage;
