export const calendarStyle = {
  bigCalendar: {
    fontFamily: 'Inter',

    '.rbc-header': {
      padding: '2rem',
      textAlign: 'left',

      span: {
        fontWeight: 600,
        fontSize: '1.5rem',
        lineHeight: '3rem',
        letterSpacing: '0.063rem',
        textTransform: 'uppercase',
        color: 'neutral.600',
      },
    },

    '.rbc-date-cell': {
      textAlign: 'left',
      padding: '1.25rem 1.25rem 0',

      button: {
        fontWeight: 500,
        fontSize: '1.75rem',
        lineHeight: '3rem',
        letterSpacing: '0.063rem',
        color: 'neutral.600',
      },
    },

    '.rbc-month-row': {
      minHeight: '16.25rem',
    },

    '.rbc-off-range-bg, .rbc-header': {
      backgroundColor: 'neutral.100',
    },

    '.rbc-row-segment': {
      padding: ' 0.5rem 1.25rem 0',

      '.rbc-event': {
        padding: '0.375rem 1.25rem',
        color: 'neutral.800',
        borderRadius: '0.75rem',
        outline: 'none',

        '.rbc-event-content': {
          padding: 0,
          fontSize: '1.50rem',
          lineHeight: '2.25rem',
          fontWeight: 400,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          svg: {
            verticalAlign: 'bottom',
            mr: '0.75rem',
          },
        },
      },

      '.rbc-show-more': {
        fontSize: '1.75rem',
        lineHeight: 3,
        fontWeight: 600,
        color: 'secondary.600',
      },
    },
  },

  calendarToolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '2.25rem',
    paddingRight: '3rem',
    mb: '3rem',
  },

  calendarActionBtn: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '1rem',

    '.MuiButton-root.MuiButton-outlinedSizeMedium': {
      border: '0.125rem solid',
      backgroundColor: 'neutral.100',
      borderColor: 'neutral.600',
      color: 'neutral.600',
      width: '4.5rem',
      height: '4.5rem',
      fontSize: '3rem',
      padding: 0,

      '.MuiButton-startIcon': {
        margin: 0,
      },
    },
  },

  deadlineButtonsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 1.25,
  },

  meetingButtonsWrapper: {
    display: 'flex',
    gap: 2,
  },

  titleWithIcon: {
    display: 'flex',
    alignItems: 'center',
    gap: 1.5,
  },

  calendarInsidePopup: {
    '.MuiDialog-container': {
      '.MuiPaper-rounded': {
        maxWidth: '69rem',
        padding: '3.5rem 2.5rem 4.5rem',
      },
    },

    '.MuiDialogContent-root': {
      padding: '4.5rem 4.5rem 0 4.5rem',

      '> .MuiBox-root, > .MuiBox-root > .MuiBox-root': {
        justifyContent: 'flexStart',
        width: '100%',
      },
    },

    '.MuiDialogActions-root': {
      pt: 2,
      pb: 0,
    },
  },

  calenderDivider: {
    borderColor: 'other.200',
  },
};
