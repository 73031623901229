export const homeDashboardStyle = {
  tabCardWrapper: {
    border: '0.125rem solid',
    borderColor: 'neutral.300',
    padding: '0',
    maxHeight: '64.75rem',
    borderRadius: '6px',
    backgroundColor: 'common.white',
  },
  dot: {
    width: '0.75rem',
    height: '0.75rem',
    borderRadius: '50%',
    backgroundColor: 'error.400',
    position: 'absolute',
    right: '0',
    top: '1.25rem',
  },
};
