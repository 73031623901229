import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedModule, setLeftDrawerOpen } from 'store/slices/theme/customization';
import { miniSidebarStyle } from './style';

const NavItem = ({ item }) => {
  const customization = useSelector((state) => state.customization);
  const leftDrawerOpened = useSelector((state) => state.customization.leftDrawerOpen);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const Icon = item.icon;
  const itemIcon = <Icon stroke={1.5} sx={{ height: 20, width: 20 }} />;

  const itemHandler = (navItem) => {
    dispatch(updateSelectedModule(navItem.id));
    if (leftDrawerOpened) {
      dispatch(setLeftDrawerOpen(!leftDrawerOpened));
    }
    if (navItem.url) navigate(navItem.url);
  };

  return (
    <ListItemButton
      sx={miniSidebarStyle.wrapper}
      selected={customization.selectedModule === item.id}
      onClick={() => itemHandler(item)}
    >
      <ListItemIcon sx={{ justifyContent: 'center' }}>{itemIcon}</ListItemIcon>
      <ListItemText
        sx={{ maxWidth: '100%', whiteSpace: 'break-spaces' }}
        primary={
          <Typography variant="body2" sx={{ textAlign: 'center' }}>
            {item.title}
          </Typography>
        }
      />
    </ListItemButton>
  );
};

NavItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.node),
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default NavItem;
