import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Typography from 'components/Atoms/Typography';
import { Box, Skeleton } from '@mui/material';
import LCRecordActionTemplate from 'components/Templates/Employee/LifeCycleRecord/Action';
import useToast from 'components/Provider/useToast';
import { approveOrRejectLifeCycleRecordAction } from 'api/employees';
import { useSelector } from 'react-redux';
import { hasPermission } from 'utils/permissions';
import { useThunk } from 'hooks/useThunk';
import { fetchLCApproveRejectRecordDetails } from 'store';
import useLCRecordServices from '../List/useLCRecordServices';

const ActionLCCaseRecord = () => {
  const [loading, setLoading] = useState(false);
  const { userId, changeType } = useParams();
  const addToast = useToast();
  const navigate = useNavigate();
  const services = useLCRecordServices();

  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasActionPermission = hasPermission(authorities, [
    'ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_ACTION_LIFECYCLE_CASE_RECORD',
  ]);

  const userHasExtendProbationPermission = hasPermission(authorities, [
    'ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_EXTEND_PROBATION',
  ]);

  const breadcrumbsData = [
    { id: 1, name: 'Life Cycle Case Records', path: '/company/life-cycle/life-cycle-records', isActive: false },
    { id: 2, name: 'Action', isActive: true },
  ];

  const [getApproveRejectRecordDetails, isLoadingApproveRejectRecordDetails, loadingApproveRejectRecordDetailsError] =
    useThunk(fetchLCApproveRejectRecordDetails);

  useEffect(() => {
    if (userId && changeType) {
      const bodyFormData = new FormData();
      bodyFormData.append('id', userId);
      bodyFormData.append('type', changeType);
      getApproveRejectRecordDetails(bodyFormData);
    }
  }, [userId, changeType, getApproveRejectRecordDetails]);

  const { lcApproveRejectRecordDetails = {} } = useSelector((state) => state?.lifeCycleRecords);

  const handleCancel = () => {
    navigate('/company/life-cycle/life-cycle-records');
  };

  const handleExtendProbation = () => {
    navigate(`/company/life-cycle/life-cycle-records-pending-approval/extend-probation/${userId}`, {
      state: {
        empUserId: lcApproveRejectRecordDetails?.employeeInstance?.userId,
        recordId: lcApproveRejectRecordDetails?.lifeCycleCaseRecord?.id,
        changeType,
      },
    });
  };

  const handleApproveOrReject = async (values, type) => {
    setLoading(true);
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', userId);
      bodyFormData.append('type', changeType);
      bodyFormData.append('actType', type);
      bodyFormData.append('comment', values.notes);
      bodyFormData.append('attachment', values.attachment);
      const response = await approveOrRejectLifeCycleRecordAction(bodyFormData);
      if (response.data.status === 'SUCCESS') {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Lifecycle record ${type}ed successfully`,
        });
        navigate(`/company/life-cycle/life-cycle-records`);
      } else {
        addToast({
          type: 'error',
          title: 'ERROR',
          message: response?.data?.message || `Failed to ${type} Lifecycle record`,
        });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to ${type} Lifecycle record`,
      });
    } finally {
      setLoading(false);
    }
  };

  if (!userHasActionPermission) {
    return <Typography variant="h6">You do not have permission to view this page.</Typography>;
  }

  if (isLoadingApproveRejectRecordDetails) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (loadingApproveRejectRecordDetailsError !== null) {
    return (
      <Typography variant="h6">
        {loadingApproveRejectRecordDetailsError?.message || 'Error while loading life cycle case record details.'}
      </Typography>
    );
  }

  return (
    <LCRecordActionTemplate
      loading={loading}
      pageHeading={`Life Cycle Details: ${lcApproveRejectRecordDetails?.lifeCycleCaseRecord?.employee?.fullName || ''}`}
      breadcrumbs={breadcrumbsData}
      recordDetails={{
        name: lcApproveRejectRecordDetails?.lifeCycleCaseRecord?.createdBy?.fullName || '',
        date: lcApproveRejectRecordDetails?.lifeCycleCaseRecord?.createdOn || '',
        status: lcApproveRejectRecordDetails?.lifeCycleCaseRecord?.status || '',
        supervisor: lcApproveRejectRecordDetails?.employeeInstance?.supervisor?.fullName || '',
        department: lcApproveRejectRecordDetails?.employeeInstance?.department?.name || '',
        designation: lcApproveRejectRecordDetails?.employeeInstance?.designation?.name || '',
        location: lcApproveRejectRecordDetails?.employeeInstance?.location?.name || '',
        employeeLevel: lcApproveRejectRecordDetails?.employeeInstance?.empLevel?.name || '',
        employeeGrade: lcApproveRejectRecordDetails?.employeeInstance?.empGrade?.name || '',
        division: lcApproveRejectRecordDetails?.employeeInstance?.division?.name || '',
        changeType: lcApproveRejectRecordDetails?.lifeCycleCaseRecord?.changeType || changeType,
      }}
      newRecordDetails={{
        supervisor: lcApproveRejectRecordDetails?.supervisor?.fullName || '',
        department: lcApproveRejectRecordDetails?.department?.name || '',
        designation: lcApproveRejectRecordDetails?.designation?.name || '',
        location: lcApproveRejectRecordDetails?.location?.name || '',
        employeeLevel: lcApproveRejectRecordDetails?.employeeLevel?.name || '',
        employeeGrade: lcApproveRejectRecordDetails?.employeeGrade?.name || '',
        division: lcApproveRejectRecordDetails?.division?.name || '',
        effectiveDate: lcApproveRejectRecordDetails?.effectiveStartDate || '',
        effectiveEndDate: lcApproveRejectRecordDetails?.effectiveEndDate || '',
        reason: lcApproveRejectRecordDetails?.reason || '-',
      }}
      handleSubmit={handleApproveOrReject}
      handleCancel={handleCancel}
      creatorAttachment={lcApproveRejectRecordDetails?.creatorAttachment || {}}
      approverAttachments={lcApproveRejectRecordDetails?.lifeCycleCaseRecord?.approverAttachments || {}}
      handleAttachmentDownload={services.handleAttachment}
      handleExtendProbation={handleExtendProbation}
      userHasExtendProbationPermission={userHasExtendProbationPermission}
    />
  );
};

export default ActionLCCaseRecord;
