import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedEmployee } from 'store/slices/company/employees/employee';
import AdvanceSearch from 'components/Templates/Employee/AdvanceSearch';
import DeleteEmployee from 'components/Organisms/Dialog/ActionList/DeleteEmployee';
import AddDataFilter from 'components/Organisms/Dialog/ActionList/AddDataFilter';
import AnonymiseEmployee from 'components/Organisms/Dialog/ActionList/AnonymiseEmployee';
import DeactivateEmployee from 'components/Organisms/Dialog/ActionList/DeactivateEmployee';
import LockEmployeeRecords from 'components/Organisms/Dialog/ActionList/LockEmployeeRecords';

import usePaginationAttributes from 'hooks/use-pagination-attributes';
import useController from 'hooks/useController';
import { isEmpty } from 'lodash';
import useEmployeeServices from '../useEmployeeServices';
import useColumnsAdvancedSearch from './useColumnsAdvancedSearch';
import useActionItems from '../useActionItems';

import MapLifeCyclePolicyContainer from '../MapLifeCyclePolicy';
import MapEmployeeGroupContainer from '../MapEmplooyeGroup';
import AddNoteContainer from '../AddNote';

const AdvancedSearch = () => {
  const dispatch = useDispatch();
  const services = useEmployeeServices(() => {});
  const { pageSize } = usePaginationAttributes();
  const [columns] = useColumnsAdvancedSearch();
  const [handleActionItemClick] = useActionItems(services);
  const [controller, handlePageChange, handleSortChange] = useController();
  const { selectedEmployee } = useSelector((state) => {
    return state.employees;
  });
  const [search, setSearch] = useState(true);

  const fetchEmployees = () => {
    if (search) {
      services.handleAdvanceSearchAction();
      return;
    }
    const query = new URLSearchParams({});
    if (controller) {
      query.append('sort', controller.sortBy);
      query.append('order', controller.sort);
    }
    query.append('offset', controller.page * pageSize);
    query.append('max', pageSize);
    services.handleAdvanceSearchActionWithQuery(query);
    setSearch(true);
  };

  useEffect(() => {
    fetchEmployees();
  }, [controller, pageSize]);

  const onPageChange = (newPage) => {
    setSearch(false);
    handlePageChange(newPage);
  };

  const onSortChange = (event, columnName) => {
    setSearch(false);
    handleSortChange(event, columnName);
  };

  const employeeList = services?.dialogState?.searchData?.employeeList?.length
    ? services?.dialogState?.searchData?.employeeList?.map((item) => ({
        id: item.id,
        name: item.fullName,
      }))
    : [];

  return (
    <>
      <AdvanceSearch
        pageHeading="Advanced Employee Search"
        handleSubmit={services.handleAdvanceSearchAction}
        loading={services.isLoadingEmployees}
        columns={columns}
        rows={services.dialogState.searchData.results}
        count={services.dialogState.searchData.totalCount}
        showPagination
        onPageChange={onPageChange}
        handleSort={onSortChange}
        handleRowClick={() => {}}
        page={controller.page}
        keyColumn="id"
        showActionList
        sortBy={controller.sortBy}
        sortDirection={controller.sort}
        roleBasedMenu={handleActionItemClick}
        handleSelect={(row) => {
          dispatch(updateSelectedEmployee(row));
        }}
        onSelectedChange={() => {}}
        supervisorOptions={employeeList}
        statusOptions={services.dialogState.searchData.statusOptions}
      />
      {selectedEmployee !== null && (
        <>
          <DeleteEmployee
            open={services.dialogState.open === 'DELETE_EMPLOYEE'}
            handleClose={services.handleDialogClose}
            handleCancel={services.handleDialogClose}
            handleComment={() => {
              services.deleteEmployee(selectedEmployee);
            }}
            userName={selectedEmployee.firstName}
          />
          <AddDataFilter
            open={services.dialogState.open === 'ADD_DATA_FILTER'}
            handleClose={services.handleDialogClose}
            handleCancel={services.handleDialogClose}
            handleComment={() => {}}
            userName={selectedEmployee.firstName}
          />

          <AddNoteContainer
            open={services.dialogState.open === 'ADD_NOTES'}
            handleClose={services.handleDialogClose}
            handleCancel={services.handleDialogClose}
            handleComment={services.addNoteEmployee}
            selectedEmployee={
              !isEmpty(selectedEmployee)
                ? {
                    department: selectedEmployee?.department?.name,
                    designation: selectedEmployee?.designation?.name,
                    location: selectedEmployee?.location?.name,
                    id: selectedEmployee?.id,
                    firstName: selectedEmployee?.firstName,
                    lastName: selectedEmployee?.lastName,
                  }
                : {}
            }
          />

          <AnonymiseEmployee
            open={services.dialogState.open === 'ANONYMISE_EMPLOYEE'}
            handleClose={services.handleDialogClose}
            handleCancel={services.handleDialogClose}
            handleComment={() => {}}
            userName={selectedEmployee.firstName}
          />

          <DeactivateEmployee
            open={services.dialogState.open === 'DEACTIVATE_EMPLOYEE'}
            title="DeActivate Employee"
            desc={`Are you sure you want to deactivate ${selectedEmployee.firstName}?`}
            handleClose={services.handleDialogClose}
            handleCancel={services.handleDialogClose}
            handleComment={() => {
              services.deActivateEmployees(selectedEmployee.userID);
              fetchEmployees();
            }}
            primaryBtn="DeActivate"
            userNo={selectedEmployee.firstName}
          />
          <DeactivateEmployee
            open={services.dialogState.open === 'ACTIVATE_EMPLOYEE'}
            title="Activate Employee"
            desc={`Are you sure you want to activate ${selectedEmployee.firstName}?`}
            handleClose={services.handleDialogClose}
            handleCancel={services.handleDialogClose}
            handleComment={() => {
              services.activateEmployees(selectedEmployee.userID);
              fetchEmployees();
            }}
            primaryBtn="Activate"
            userNo={selectedEmployee.firstName}
          />

          <LockEmployeeRecords
            open={services.dialogState.open === 'LOCK_EMPLOYEE'}
            title="Lock Employee"
            desc={`Are you sure you want to lock ${selectedEmployee.firstName}?`}
            handleClose={services.handleDialogClose}
            handleCancel={services.handleDialogClose}
            handleComment={() => {
              services.lockEmployee(selectedEmployee.userId);
              fetchEmployees();
            }}
            primaryBtn="Lock"
          />
          <LockEmployeeRecords
            open={services.dialogState.open === 'UNLOCK_EMPLOYEE'}
            title="UnLock Employee"
            desc={`Are you sure you want to unlock ${selectedEmployee.firstName}?`}
            handleClose={services.handleDialogClose}
            handleCancel={services.handleDialogClose}
            handleComment={() => {
              services.unLockEmployees(selectedEmployee.userID);
              fetchEmployees();
            }}
            primaryBtn="Unlock"
          />

          <MapLifeCyclePolicyContainer
            open={services.dialogState.open === 'MAP_LIFECYCLE_POLICY'}
            handleClose={services.handleDialogClose}
            handleCancel={services.handleDialogClose}
            handleComment={services.mapEmployeeToLifeCyclePolicy}
            selectedEmployee={selectedEmployee}
          />

          <MapEmployeeGroupContainer
            open={services.dialogState.open === 'SELECT_EMPLOYEE_GROUP'}
            handleClose={services.handleDialogClose}
            handleCancel={services.handleDialogClose}
            handleComment={services.mapGenericGroupEmployee}
            selectedEmployee={selectedEmployee}
          />
        </>
      )}
    </>
  );
};

export default AdvancedSearch;
