import { makeStyles } from '@mui/styles';

const useDarkStyles = makeStyles((theme) => ({
  menuList: {
    color: `${theme.palette.colors.Spindle} !important`,
    marginBlock: '5px !important',
    fontWeight: '500 !important',
    fontSize: '14px !important',
    fontFamily: 'Montserrat',
    lineHeight: '130% !important',
    padding: '10px 12px !important',
    '&:hover': {
      background: `${theme.palette.colors.chathamsBlue} !important`,
      color: `${theme.palette.common.white} !important`,
      borderRadius: '8px !important',
    },
    '& .MuiListItemIcon-root': {
      minWidth: '16px !important',
      marginRight: '8px !important',
    },
    '&:first-child': {
      marginBlockStart: '0px !important',
    },
    '&:last-child': {
      marginBlockEnd: '0px !important',
    },
  },
  menuPaper: ({ width }) => ({
    width: `calc(${width}px - 16px) !important`,
    background: `${theme.palette.colors.cornflowerBlue} !important`,
    borderRadius: '12px !important',
    padding: '0px 8px !important',
    top: '39px !important',

    // maxHeight: 204,
    // "& .css-6hp17o-MuiList-root-MuiMenu-list": {
    //   paddingBottom: "0px !important",
    // },
    // "&::-webkit-scrollbar": {
    //   width: "5px",
    // },
    // "&::-webkit-scrollbar-track": {
    //   borderRadius: 12,
    //   background: `${theme.palette.colors.cornflowerBlue} !important`,
    // },
    // "&::-webkit-scrollbar-thumb": {
    //   borderRadius: 12,
    //   background: `${theme.palette.colors.chathamsBlue} !important`,
    // },
  }),
}));

const useLightStyles = makeStyles((theme) => ({
  menuList: {
    color: `${theme.palette.colors.colorForegroundNeutral} !important`,
    font: theme.typography.fontBodyMedium,
    padding: `${theme.space.spaceS3x} ${theme.space.spaceS4x} !important`,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      background: `${theme.palette.colors.colorBackgroundNeutral} !important`,
    },
    '& .MuiListItemIcon-root': {
      minWidth: `${theme.sizing.sizeXxs} !important`,
      marginRight: `${theme.space.spaceS2x} !important`,
      color: `${theme.palette.colors.colorForegroundNeutralLight} !important`,
    },
  },
  menuPaper: () => ({
    // width: `calc(${width}px - 16px) !important`,
    width: '257px',
    background: `${theme.palette.colors.white} !important`,
    borderRadius: `${theme.shape.radiusTiny} !important`,
    boxShadow: '0px 10px 40px -5px rgba(80, 93, 117, 0.2)',
  }),
}));

export const menuStyles = { light: useLightStyles, dark: useDarkStyles };
