import React from 'react';
import { Box } from '@mui/material';
import InputField from 'components/Atoms/InputField';

const CodeEditor = (...args) => {
  return (
    <Box>
      <InputField multiline fullWidth {...args[0]} />
    </Box>
  );
};

CodeEditor.defaultProps = {};

CodeEditor.propTypes = {};

export default CodeEditor;
