import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import TableExtended from 'components/Molecules/TableExtended';
import Typographys from 'components/Atoms/Typography';

const LeaveTransactions = ({
  loading,
  leaveTransactionsColumn,
  leaveTransactionsRow,
  page,
  sortDirection,
  sortBy,
  handleSort,
  count,
  onPageChange,
  onSortChange,
}) => {
  return (
    <>
      <Typographys variant="h6" color="neutral.800">
        Leave Transactions
      </Typographys>
      <Box mt={2}>
        <TableExtended
          loading={loading}
          rows={leaveTransactionsRow}
          columns={leaveTransactionsColumn}
          showActionList={false}
          showCheckbox={false}
          count={count}
          handleSort={handleSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
          page={page}
          onPageChange={onPageChange}
          onSortChange={onSortChange}
        />
      </Box>
    </>
  );
};

LeaveTransactions.defaultProps = {
  loading: false,
  leaveTransactionsColumn: [],
  leaveTransactionsRow: [],
  handleSort: () => {},
  sortBy: () => {},
  sortDirection: 'asc',
  count: 6,
  page: 1,
  onPageChange: () => {},
  onSortChange: () => {},
};

LeaveTransactions.propTypes = {
  loading: PropTypes.bool,
  leaveTransactionsColumn: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),
  leaveTransactionsRow: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      leavesAvailed: PropTypes.string,
      leavesAccounted: PropTypes.number,
      leavesDeducted: PropTypes.number,
    }),
  ),
  sortBy: PropTypes.func,
  handleSort: PropTypes.func,
  sortDirection: PropTypes.string,
  count: PropTypes.number,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  onSortChange: PropTypes.func,
};

export default LeaveTransactions;
