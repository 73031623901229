import * as React from 'react';
import Paper from '@mui/material/Paper';
import Typographys from 'components/Atoms/Typography';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';

const DeductionTable = ({ headers, rows, hasHeader, headsData }) => {
  return (
    <Paper
      sx={{
        border: '1px solid #CDD2DC',
        borderRadius: '4px',
      }}
    >
      <PerfectScrollbar>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ backgroundColor: 'info.200' }}>
                  <Typographys variant="body2" color="text.300">
                    S No.
                  </Typographys>
                </TableCell>

                <TableCell sx={{ backgroundColor: 'info.200' }}>
                  <Typographys variant="body2" color="text.300">
                    Section
                  </Typographys>
                </TableCell>

                <TableCell sx={{ backgroundColor: 'info.200' }}>
                  <Typographys variant="body2" color="text.300">
                    Investment (Proposed)
                  </Typographys>
                </TableCell>

                <TableCell sx={{ backgroundColor: 'info.200' }}>
                  <Typographys variant="body2" color="text.300">
                    Investment (Actual){' '}
                  </Typographys>
                </TableCell>

                <TableCell sx={{ backgroundColor: 'info.200' }}>
                  <Typographys variant="body2" color="text.300">
                    Section Limit
                  </Typographys>
                </TableCell>

                <TableCell sx={{ backgroundColor: 'info.200' }}>
                  <Typographys variant="body2" color="text.300">
                    Deduction Amount
                  </Typographys>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ '&>td, &>th': { border: 0 } }}>
                <TableCell>
                  <Typographys variant="body2">₹10000</Typographys>
                </TableCell>

                <TableCell>
                  <Typographys variant="body2">₹10000</Typographys>
                </TableCell>

                <TableCell>
                  <Typographys variant="body2">₹0</Typographys>
                </TableCell>

                <TableCell>
                  <Typographys variant="body2">₹0</Typographys>
                </TableCell>

                <TableCell>
                  <Typographys variant="body2">₹0</Typographys>
                </TableCell>

                <TableCell>
                  <Typographys variant="body2">₹0</Typographys>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </PerfectScrollbar>
      <Box sx={{ display: 'flex', p: '12px 8px', border: ' 1px solid #CDD2DC' }}>
        <Box sx={{ flexBasis: '25%' }}>
          <Typographys variant="subtitle2">Total Deductions:</Typographys>
        </Box>
        <Box sx={{ flexBasis: '75%' }}>
          <Typographys variant="subtitle2">₹10000</Typographys>
        </Box>
      </Box>
    </Paper>
  );
};

export default DeductionTable;
