import React from 'react';
import PropTypes from 'prop-types';
import EmployeeCommonCard from 'components/Organisms/Employee/EmployeeCommonCard';
import TenureWiseGraph from 'components/Organisms/Employee/Dashboard/Charts/TenureWiseGraph';

const TenureWiseCountCard = ({ tenureWiseData }) => {
  return (
    <EmployeeCommonCard
      cardHeaderTitle="Tenure Wise headcount"
      isHeaderFilter={false}
      bodyContent={<TenureWiseGraph tenureWiseData={tenureWiseData} />}
      isFooter={false}
      cardBodyPadding="2rem 0 2rem 0"
    />
  );
};

TenureWiseCountCard.defaultProps = {
  tenureWiseData: [],
};
TenureWiseCountCard.propTypes = {
  tenureWiseData: PropTypes.arrayOf,
};

export default TenureWiseCountCard;
