import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Typography from 'components/Atoms/Typography';
import TableExtended from 'components/Molecules/TableExtended';
import Button from 'components/Atoms/Button';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ActionDropDown from 'components/Molecules/ActionDropDown';

const DocumentsTab = ({
  loading,
  documentsColumn,
  documentsRow,
  sortDirection,
  sortBy,
  onSortChange,
  count,
  page,
  onPageChange,
  handleDownload,
  docFilterLabel,
  docFilterOptions,
  handleDocSelect,
}) => {
  return (
    <PerfectScrollbar>
      <Box sx={{ minHeight: '56.5rem', maxHeight: '56.5rem' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2.5 }}>
          <Typography variant="h6" color="neutral.800">
            Documents
          </Typography>
          <Box>
            <ActionDropDown label={docFilterLabel} options={docFilterOptions} handleSelect={handleDocSelect} />
          </Box>
        </Box>
        {!documentsRow?.length ? (
          <Typography>No data available</Typography>
        ) : (
          <TableExtended
            loading={loading}
            columns={documentsColumn}
            rows={documentsRow.map((item) => {
              return {
                ...item,
                documentName: (
                  <Button
                    variant="text"
                    color="secondary"
                    onClick={() => handleDownload(item)}
                    size="small"
                    startIcon={<AttachFileIcon />}
                  >
                    {item.documentName}
                  </Button>
                ),
              };
            })}
            showCheckbox={false}
            sortDirection={sortDirection}
            sortBy={sortBy}
            onSortChange={onSortChange}
            showActionList={false}
          />
        )}
      </Box>
    </PerfectScrollbar>
  );
};

DocumentsTab.defaultProps = {
  loading: false,
  documentsColumn: [],
  documentsRow: [],
  sortDirection: 'asc',
  sortBy: 'id',
  onSortChange: () => {},
  count: 7,
  page: null,
  onPageChange: () => {},
  handleDownload: () => {},
  docFilterLabel: '',
  docFilterOptions: [],
  handleDocSelect: () => {},
};
DocumentsTab.propTypes = {
  loading: PropTypes.bool,
  documentsColumn: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),
  documentsRow: PropTypes.arrayOf(
    PropTypes.shape({
      slNo: PropTypes.number,
      documentName: PropTypes.string,
      section: PropTypes.string,
    }),
  ),

  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  count: PropTypes.number,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  onSortChange: PropTypes.func,
  handleDownload: PropTypes.func,

  docFilterLabel: PropTypes.string,
  docFilterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  handleDocSelect: PropTypes.func,
};

export default DocumentsTab;
