import React from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import AdvanceSearch from 'components/Organisms/Employee/AdvanceSearch';
import PropTypes from 'prop-types';

const AdvanceSearchTemplate = ({
  pageHeading,
  handleSubmit,
  statusOptions,
  supervisorOptions,
  loading,
  rows,
  columns,
  count,
  showActionList,
  handleSort,
  handleRowClick,
  page,
  onPageChange,
  sortBy,
  sortDirection,
  handleSelect,
  showPagination,
  keyColumn,
  roleBasedMenu,
  handleSearch,
  searchCount,
}) => {
  const breadcrumbData = [
    { id: 1, name: 'Employee List', path: '/company/employee/employees-list', isActive: false },
    { id: 2, name: 'Advanced Employee Search', path: '/advancedemployeesearch', isActive: true },
  ];

  return (
    <BlankTemplate pageHeading={pageHeading} isBreadcrumb breadcrumbData={breadcrumbData}>
      <AdvanceSearch
        handleSubmit={handleSubmit}
        statusOptions={statusOptions}
        supervisorOptions={supervisorOptions}
        loading={loading}
        columns={columns}
        rows={rows}
        count={count}
        showPagination={showPagination}
        onPageChange={onPageChange}
        handleSort={handleSort}
        handleRowClick={handleRowClick}
        page={page}
        keyColumn={keyColumn}
        showActionList={showActionList}
        sortBy={sortBy}
        sortDirection={sortDirection}
        roleBasedMenu={roleBasedMenu}
        handleSelect={handleSelect}
        handleSearch={handleSearch}
        searchCount={count}
      />
    </BlankTemplate>
  );
};

AdvanceSearchTemplate.defaultProps = {
  pageHeading: 'Advanced Employee Search',
  handleSubmit: () => {},
  statusOptions: [],
  supervisorOptions: [],
  loading: false,
  rows: [],
  columns: [],
  showActionList: false,
  count: 10,
  handleSort: () => {},
  handleRowClick: () => {},
  page: 0,
  sortBy: '',
  sortDirection: 'asc',
  handleSelect: () => {},
  showPagination: false,
  keyColumn: '',
  roleBasedMenu: () => {},
  onPageChange: () => {},
  handleSearch: () => {},
  searchCount: '',
};

AdvanceSearchTemplate.defaultProps = {
  handleSubmit: () => {},
  statusOptions: [],
  supervisorOptions: [],
};

AdvanceSearchTemplate.propTypes = {
  pageHeading: PropTypes.string,
  handleSubmit: PropTypes.func,
  statusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  supervisorOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  loading: PropTypes.bool,
  rows: PropTypes.arrayOf,
  columns: PropTypes.arrayOf,
  showActionList: PropTypes.bool,
  count: PropTypes.number,
  handleSort: PropTypes.func,
  handleRowClick: PropTypes.func,
  page: PropTypes.number,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  handleSelect: PropTypes.func,
  showPagination: PropTypes.bool,
  keyColumn: PropTypes.string,
  roleBasedMenu: PropTypes.func,
  onPageChange: PropTypes.func,
  handleSearch: PropTypes.func,
  searchCount: PropTypes.number,
};

export default AdvanceSearchTemplate;
