import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import AddQuery from 'components/Organisms/TaskAndQueries/AddQuery';
import useToast from 'components/Provider/useToast';
import { getToastMessage } from 'utils/toastMessage';

const AddQueryTemplate = ({
  handleSubmit,
  initialValues,
  categoryOptions,
  topicOptions,
  handleDeleteQuery,
  isEdit,
  handleCancel,
  pageHeading,
  toastSuccessMessage,
  toastErrorMessage,
  showToast,
  toastType,
  loading,
  draftLoading,
  breadcrumbData,
  handleTopicOptions,
}) => {
  const addToast = useToast();
  const [isDelete, setIsDelete] = useState(false);

  useEffect(() => {
    if (showToast) {
      const successMessage = toastSuccessMessage || getToastMessage.TaskAndQueries(isEdit, isDelete)[0];
      const error = toastErrorMessage || getToastMessage.TaskAndQueries(isEdit, isDelete)[1];

      const title = toastType === 'success' ? 'Success!' : 'Error!';
      const type = toastType === 'success' ? 'success' : 'error';
      const message = toastType === 'success' ? successMessage : error;
      addToast({ type, title, message });
    }
  }, [showToast, toastErrorMessage, toastSuccessMessage, toastType]);

  return (
    <BlankTemplate pageHeading={pageHeading} breadcrumbData={breadcrumbData} padding="2rem 3rem">
      <AddQuery
        handleSubmit={(data) => {
          setIsDelete(false);
          handleSubmit(data);
        }}
        initialValues={initialValues}
        categoryOptions={categoryOptions}
        topicOptions={topicOptions}
        handleDeleteQuery={handleDeleteQuery}
        isEdit={isEdit}
        handleCancel={handleCancel}
        loading={loading}
        draftLoading={draftLoading}
        handleTopicOptions={handleTopicOptions}
      />
    </BlankTemplate>
  );
};

AddQueryTemplate.defaultProps = {
  initialValues: {},
  handleSubmit: () => {},
  categoryOptions: [],
  topicOptions: {},
  isEdit: false,
  handleDeleteQuery: () => {},
  handleCancel: () => {},
  pageHeading: '',
  toastSuccessMessage: '',
  toastErrorMessage: '',
  showToast: false,
  toastType: 'success',
  loading: false,
  draftLoading: false,
  breadcrumbData: [],
  handleTopicOptions: () => {},
};

AddQueryTemplate.propTypes = {
  initialValues: PropTypes.shape({}),
  handleSubmit: PropTypes.func,
  categoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  topicOptions: PropTypes.shape({}),
  isEdit: PropTypes.bool,
  handleDeleteQuery: PropTypes.func,
  handleCancel: PropTypes.func,
  pageHeading: PropTypes.string,
  toastSuccessMessage: PropTypes.string,
  toastErrorMessage: PropTypes.string,
  showToast: PropTypes.bool,
  toastType: PropTypes.oneOf(['error', 'success']),
  loading: PropTypes.bool,
  draftLoading: PropTypes.bool,
  breadcrumbData: PropTypes.arrayOf({}),
  handleTopicOptions: PropTypes.func,
};

export default AddQueryTemplate;
