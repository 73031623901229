import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import Button from 'components/Atoms/Button';
import EditIcon from '@mui/icons-material/Edit';
import { validateDateElseHyphen } from 'utils/commonUtils';
import TitleWithText from 'components/Atoms/TitleWithText';
import Typographys from 'components/Atoms/Typography';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import NoRecordsMessage from 'components/Organisms/CommonPageUtils/NoRecordsFound';
import { hasPermission } from 'utils/permissions';
import { useSelector } from 'react-redux';
import { profileStyle } from '../style';

const ProfileEmploymentHistory = ({ employmentHistory, handleEdit, handleAttachment }) => {
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasEditPermission = hasPermission(authorities, [
    'ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_MY_EMPLOYMENT_HISTORY',
  ]);

  return (
    <>
      <Box sx={profileStyle.actionBtn}>
        {!!userHasEditPermission && (
          <Button startIcon={<EditIcon fontSize="2.5rem" />} onClick={handleEdit}>
            {employmentHistory.length > 0 ? 'Edit' : 'Add'}
          </Button>
        )}
      </Box>
      {employmentHistory.length > 0 ? (
        <Box mt={2} sx={profileStyle.mainContainer}>
          {employmentHistory.map((employmentList) => (
            <Box sx={profileStyle.formDivider}>
              <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TitleWithText title="Employer Name" text={employmentList.employerName} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TitleWithText title="Employer Address" text={employmentList.employerAddress} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TitleWithText
                    title="Commence Date"
                    text={validateDateElseHyphen(employmentList.commenceDate, 'DD MMMM YYYY')}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TitleWithText
                    title="End Date"
                    text={validateDateElseHyphen(employmentList.endDate, 'DD MMMM YYYY')}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TitleWithText title="Reason for Leaving" text={employmentList.reasonForLeaving} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TitleWithText title="Employee ID" text={employmentList.employeeID} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TitleWithText title="Start Designation" text={employmentList.startDesignation} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TitleWithText title="End Designation" text={employmentList.endDesignation} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TitleWithText title="Reference Contact Name" text={employmentList.referenceName} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TitleWithText title="Reference Contact Designation" text={employmentList.referenceDesignation} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TitleWithText title="Reference Contact Phone" text={employmentList.referencePhone} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TitleWithText title="Reference Contact Email" text={employmentList.referenceEmail} />
                </Grid>
                <Grid item xs={12} mt={3} sx={profileStyle.attachFile}>
                  <Typographys variant="h6" color="neutral.800">
                    Attachments
                  </Typographys>
                  <Button
                    variant="text"
                    color="secondary"
                    onClick={() =>
                      handleAttachment(employmentList?.experienceLetterId, employmentList?.experienceLetter)
                    }
                    startIcon={<AttachFileIcon />}
                  >
                    {employmentList?.experienceLetter || ''}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Box>
      ) : (
        <NoRecordsMessage width="20rem" height="20rem" />
      )}
    </>
  );
};

ProfileEmploymentHistory.defaultProps = {
  employmentHistory: [],
  handleEdit: () => {},
  handleAttachment: () => {},
};

ProfileEmploymentHistory.propTypes = {
  employmentHistory: PropTypes.arrayOf,
  handleEdit: PropTypes.func,
  handleAttachment: PropTypes.func,
};

export default ProfileEmploymentHistory;
