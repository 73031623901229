import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { deletePayHead } from 'api/payhead';
import DropdownMenu from 'components/Atoms/DropdownMenu';
import useToast from 'components/Provider/useToast';
import { styles } from './styles';
import useFetchPayhead from '../ViewPayhead/useFetchPayhead';

export const DeleteDialog = ({ payheadId }) => {
  const [openDialog, setOPenDialog] = useState(false);
  const [action, setAction] = useState('');
  const [payhead] = useFetchPayhead(payheadId);
  const addToast = useToast();

  const navigate = useNavigate();

  const handleDialogClose = () => {
    setOPenDialog(false);
  };

  const handleDelete = async () => {
    try {
      const response = await deletePayHead(payheadId);
      if (response.status === 200) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Payhead deleted successfully`,
        });
        navigate('/company/salary/payheads');
      }
    } catch (err) {
      addToast({ type: 'error', title: 'ERROR', message: err?.message || 'Failed to delete payhead' });
    }
    setOPenDialog(false);
  };

  const handleMenuItemClick = () => {
    setOPenDialog(true);
    setAction({
      title: 'Delete Payhead',
      confirmation: `Are you sure you want to delete Payhead - 
      ${payhead?.data?.data?.companyPayheadInstance?.displayName}?`,
      callback: handleDelete,
    });
  };

  const renderDialogContent = (actionInput) => (
    <DialogContent sx={styles.tableMenuDialog}>
      <Box sx={styles.tableMenuDialog}>
        <DialogTitle>{actionInput.title}</DialogTitle>
        {actionInput.confirmation && <DialogContentText>{actionInput.confirmation}</DialogContentText>}
      </Box>
      {actionInput.children}
      <DialogActions sx={{ marginTop: '2.5rem', justifyContent: 'center' }}>
        <Button size="medium" variant="outlined" color="info" onClick={handleDialogClose}>
          Cancel
        </Button>
        <Button size="medium" variant="contained" color="error" onClick={actionInput.callback}>
          Delete
        </Button>
      </DialogActions>
    </DialogContent>
  );

  return (
    <>
      <DropdownMenu
        menuLists={[
          {
            id: 1,
            label: 'Delete',
            onClick: () => {
              handleMenuItemClick();
              setOPenDialog(true);
            },
          },
        ]}
      />
      <Dialog open={openDialog} onClose={() => setOPenDialog(false)}>
        {renderDialogContent(action)}
      </Dialog>
    </>
  );
};

DeleteDialog.propTypes = {
  payheadId: PropTypes.number.isRequired,
};
