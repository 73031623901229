export const sizeXxxs = '12px';

export const sizeXxs = '16px';

export const sizeXs = '20px';

export const sizeS = '24px';

export const sizeM = '32px';

export const sizeL = '40px';

export const sizeXl = '64px';

export const sizeXxl = '80px';
