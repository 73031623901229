import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import InitiateSeparation from 'components/Organisms/Profile/InitiateSeparation';
import useToast from 'components/Provider/useToast';
import { getToastMessage } from 'utils/toastMessage';

const InitiateSeparationTemplate = ({
  employeeDetails,
  separationData,
  handleSubmit,
  handleCancel,
  acceptingManagerOptions,
  isEdit,
  isBreadcrumb,
  pageHeading,
  breadcrumbData,
  toastSuccessMessage,
  toastErrorMessage,
  showToast,
  toastType,
  selectedTab,
  loading,
}) => {
  const addToast = useToast();

  useEffect(() => {
    if (showToast) {
      const successMessage = toastSuccessMessage || getToastMessage.InitiateSeparation(employeeDetails.employeeName)[0];
      const error = toastErrorMessage || getToastMessage.InitiateSeparation(employeeDetails.employeeName)[1];

      const title = toastType === 'success' ? 'Success!' : 'Error!';
      const type = toastType === 'success' ? 'success' : 'error';
      const message = toastType === 'success' ? successMessage : error;
      addToast({ type, title, message });
    }
  }, [showToast, toastErrorMessage, toastSuccessMessage, toastType]);

  return (
    <BlankTemplate
      pageHeading={pageHeading}
      breadcrumbData={breadcrumbData}
      isBreadcrumb={isBreadcrumb}
      padding="1rem 2rem"
    >
      <InitiateSeparation
        employeeDetails={employeeDetails}
        separationData={separationData}
        handleSubmit={handleSubmit}
        acceptingManagerOptions={acceptingManagerOptions}
        handleCancel={handleCancel}
        isEdit={isEdit}
        selectedTab={selectedTab}
        loading={loading}
      />
    </BlankTemplate>
  );
};

InitiateSeparationTemplate.defaultProps = {
  employeeDetails: {},
  separationData: {},
  handleSubmit: () => {},
  handleCancel: () => {},
  isEdit: false,
  acceptingManagerOptions: [],
  isBreadcrumb: false,
  pageHeading: '',
  breadcrumbData: [],
  toastSuccessMessage: '',
  toastErrorMessage: '',
  showToast: false,
  toastType: 'success',
  selectedTab: 0,
  loading: false,
};

InitiateSeparationTemplate.propTypes = {
  employeeDetails: PropTypes.shape({
    employeeName: PropTypes.string,
    separationType: PropTypes.string,
    noticePeriod: PropTypes.string,
    lastWorkingDay: PropTypes.string,
    resignationSubmissionDate: PropTypes.string,
  }),
  separationData: PropTypes.shape({}),
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  isEdit: PropTypes.bool,
  acceptingManagerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  isBreadcrumb: PropTypes.bool,
  pageHeading: PropTypes.string,
  breadcrumbData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
  toastSuccessMessage: PropTypes.string,
  toastErrorMessage: PropTypes.string,
  showToast: PropTypes.bool,
  toastType: PropTypes.oneOf(['error', 'success']),
  selectedTab: PropTypes.number,
  loading: PropTypes.bool,
};

export default InitiateSeparationTemplate;
