import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Atoms/Button';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Divider, useTheme } from '@mui/material';
import MuiTab from 'components/Molecules/Tab';
import { hasPermission } from 'utils/permissions';
import { useSelector } from 'react-redux';
import Instructions from './Instructions';
import Forms from './Forms';
import Attachments from './Attachments';
import { onBoardingStyle } from './style';

const ProfileOnboardingRecords = ({ handleEdit, onboardingForm, attachmentData, handleAttachment, cardDetails }) => {
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasEditPermission = hasPermission(authorities, [
    'ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_MY_ONBOARDING_RECORDS',
  ]);
  const initialTabs = [
    {
      label: 'Instructions',
      onChange: () => {},
      RenderComponent: Instructions,
      componentProps: { ...onboardingForm },
    },
    {
      label: 'Forms',
      onChange: () => {},
      RenderComponent: Forms,
      componentProps: { onboardingDetails: onboardingForm, ...cardDetails, handleAttachment },
    },
    {
      label: 'Attachments/Documents',
      onChange: () => {},
      RenderComponent: Attachments,
      componentProps: { ...attachmentData, handleAttachment },
    },
  ];
  const theme = useTheme();
  return (
    <Box>
      <Box sx={onBoardingStyle.editWrapper}>
        {!!userHasEditPermission && (
          <Button onClick={handleEdit} startIcon={<EditIcon fontSize="2.5rem" />}>
            Edit
          </Button>
        )}
      </Box>

      <Divider sx={{ backgroundColor: theme.palette.neutral[200] }} />

      <Box>
        <MuiTab
          key={initialTabs}
          orientation="horizontal"
          variant="scrollable"
          scrollButtons={false}
          textColor="primary"
          indicatorColor="primary"
          tabs={initialTabs}
          allowScrollButtonsMobile
          panelPadding="2rem 0rem"
        />
      </Box>
    </Box>
  );
};

ProfileOnboardingRecords.defaultProps = {
  handleEdit: () => {},
  onboardingForm: {},
  attachmentData: {},
  handleAttachment: () => {},
};

ProfileOnboardingRecords.propTypes = {
  handleEdit: PropTypes.func,
  onboardingForm: PropTypes.shape({
    Forms: PropTypes.shape({}),
  }),

  attachmentData: PropTypes.shape({}),
  handleAttachment: PropTypes.func,
};

export default ProfileOnboardingRecords;
