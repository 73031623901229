import React from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import MyExpensesReport from 'components/Organisms/MyExpenses/MyExpensesReportsTabs';

const MyExpensesReportTemplate = ({ handleAdd, expenseReports, onTabChange, loading, showFilter }) => {
  return (
    <BlankTemplate pageHeading="My Expenses" isBreadcrumb={false} padding="0">
      <MyExpensesReport
        loading={loading}
        handleAdd={handleAdd}
        expenseReports={expenseReports}
        onTabChange={onTabChange}
        showFilter={showFilter}
      />
    </BlankTemplate>
  );
};

MyExpensesReportTemplate.defaultProps = {
  expenseReports: {},
  handleAdd: () => {},
  loading: false,
  showFilter: false,
};

MyExpensesReportTemplate.propTypes = {
  expenseReports: PropTypes.shape({}),
  handleAdd: PropTypes.func,
  loading: PropTypes.bool,
  showFilter: PropTypes.bool,
};

export default MyExpensesReportTemplate;
