import React, { useCallback, useEffect } from 'react';
import List from 'components/Templates/MyPay/PastEmployerTaxes/List';
import { useThunk } from 'hooks/useThunk';
import { fetchPreviousEmploymentTaxesList } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { updateSelectedEmploymentTax } from 'store/slices/home/myPay';
import Dialog from 'components/Molecules/Dialog';
import Typography from 'components/Atoms/Typography';
import useController from 'hooks/useController';
import usePaginationAttributes from 'hooks/use-pagination-attributes';
import { getListApiFormat } from 'utils/ApiPayloadFormat';
import { hasPermission } from 'utils/permissions';
import {
  PAST_EMPLOYER_TAXES_STATUS_IN_DRAFT,
  PAST_EMPLOYER_TAXES_STATUS_IN_REVIEW,
  PAST_EMPLOYER_TAXES_STATUS_IN_APPROVED,
  PAST_EMPLOYER_TAXES_STATUS_IN_REJECT,
} from 'utils/SystemConfigConstants';
import useColumns from './useColumns';
import useActionItems from './useActionItems';
import useService from './useService';

const PreviousEmploymentTaxesList = () => {
  const dispatch = useDispatch();
  const [columns] = useColumns();
  const services = useService();
  const [controller, handlePageChange, handleSortChange, handleFiltersApply, handleFiltersClear] = useController();
  const { pageSize } = usePaginationAttributes();
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasViewPermission = hasPermission(authorities, [
    'ROLE_BASE_PERMISSION_PAY_INDEX_VIEW_PREVIOUS_EMP_TAX_DETAILS_BY_EMPLOYEE',
  ]);
  const userHasAddPermission = hasPermission(authorities, [
    'ROLE_BASE_PERMISSION_PAY_INDEX_CREATE_PREVIOUS_EMP_TAX_DETAILS_BY_EMPLOYEE',
  ]);
  const userHasListPermission = hasPermission(authorities, [
    'ROLE_BASE_PERMISSION_PAY_INDEX_LIST_PREVIOUS_EMP_TAX_DETAILS_BY_EMPLOYEE',
  ]);

  const [
    getPreviousEmploymentTaxesList,
    isLoadingPreviousEmploymentTaxesList,
    loadingPreviousEmploymentTaxesListError,
  ] = useThunk(fetchPreviousEmploymentTaxesList);

  const fetchPreviousEmploymentTaxesListAction = useCallback(async () => {
    const queryData = getListApiFormat(controller, pageSize);
    getPreviousEmploymentTaxesList(queryData);
  }, [controller, pageSize, getPreviousEmploymentTaxesList]);

  useEffect(() => {
    fetchPreviousEmploymentTaxesListAction();
  }, [fetchPreviousEmploymentTaxesListAction, pageSize, services?.refreshPage]);

  const { previousEmploymentTaxes = {}, selectedEmploymentTax } = useSelector((state) => state?.myPay);

  const [handleActionItemClick] = useActionItems(services);

  if (loadingPreviousEmploymentTaxesListError !== null) {
    return (
      <Typography variant="h6">
        {loadingPreviousEmploymentTaxesListError?.message || 'Error while loading previous employment tax list.'}
      </Typography>
    );
  }

  if (!userHasListPermission) {
    return <Typography variant="h6">You do not have permission to view previous employment tax list.</Typography>;
  }

  return (
    <Box>
      <List
        loading={isLoadingPreviousEmploymentTaxesList}
        columns={columns}
        rows={previousEmploymentTaxes?.taxDetailsPreviousEmployerIndiaInstanceList}
        count={previousEmploymentTaxes?.taxDetailsPreviousEmployerIndiaInstanceCount}
        showPagination
        onPageChange={handlePageChange}
        onSortChange={handleSortChange}
        handleRowClick={(row) =>
          ((userHasViewPermission && row?.status === PAST_EMPLOYER_TAXES_STATUS_IN_DRAFT) ||
            row?.status === PAST_EMPLOYER_TAXES_STATUS_IN_REVIEW ||
            row?.status === PAST_EMPLOYER_TAXES_STATUS_IN_APPROVED ||
            row?.status === PAST_EMPLOYER_TAXES_STATUS_IN_REJECT) &&
          services?.viewPastEmploymentTax(row)
        }
        page={controller?.page}
        keyColumn="id"
        showActionList
        sortBy={controller?.sortBy}
        sortDirection={controller?.sort}
        roleBasedMenu={handleActionItemClick}
        handleSelect={(row) => dispatch(updateSelectedEmploymentTax(row))}
        onSelectedChange={() => {}}
        handleAdd={services?.addPastEmploymentTax}
        pageHeading="Past Employer Taxes"
        showAddNew={userHasAddPermission}
      />

      {selectedEmploymentTax !== null && (
        <Dialog
          open={services?.showDeleteDialog}
          handleClose={services?.handleDeleteDialog}
          dialogTitle="Delete Past Employer Tax"
          dialogDesc={
            <Typography variant="body2" color="neutral.600" align="center">
              Are you sure you want to delete the entry for
              <Typography color="neutral.800" sx={{ display: 'inline', pl: 1 }}>
                {selectedEmploymentTax?.employerName}
              </Typography>
              ?
            </Typography>
          }
          handlePrimaryBtn={() => services?.deletePastEmploymentTax(selectedEmploymentTax)}
          primaryBtnColor="error"
          primaryBtn="Delete"
          secondaryBtn="Cancel"
          handleSecondaryBtn={services?.handleDeleteDialog}
          bodyContent={false}
        />
      )}
      {selectedEmploymentTax !== null && (
        <Dialog
          open={services?.showSubmitDialog}
          handleClose={services?.handleSubmitDialog}
          dialogTitle="Submit Past Employer Tax"
          dialogDesc={
            <Typography variant="body2" color="neutral.600" align="center">
              Are you sure you want to submit the entry for
              <Typography color="neutral.800" sx={{ display: 'inline', pl: 1 }}>
                {selectedEmploymentTax?.employerName}
              </Typography>
              ?
            </Typography>
          }
          handlePrimaryBtn={() => services?.submitPastEmploymentTax(selectedEmploymentTax)}
          primaryBtnColor="primary"
          primaryBtn="Submit"
          secondaryBtn="Cancel"
          handleSecondaryBtn={services?.handleSubmitDialog}
          bodyContent={false}
        />
      )}
    </Box>
  );
};
export default PreviousEmploymentTaxesList;
