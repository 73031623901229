import { validateDateElseHyphen } from 'utils/commonUtils';
import usePaginationAttributes from 'hooks/use-pagination-attributes';

const getColumns = (pageSize) => [
  {
    fieldName: 'id',
    numeric: true,
    label: 'Sl No',
    type: 'number',
    formatValue: ({ _row, _column, _formik, index, page }) => page * pageSize + (index + 1),
  },
  {
    fieldName: 'ownerID',
    numeric: true,
    label: 'Document Owner',
    type: 'string',
    formatValue: ({ row }) => row?.documentOwner,
  },
  {
    fieldName: 'documentName',
    numeric: false,
    label: 'Document Name',
    type: 'string',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'documentType',
    numeric: false,
    label: 'Document Type',
    type: 'string',
    formatValue: ({ row, column }) => row[column.fieldName],
  },

  {
    fieldName: 'docVersion',
    numeric: false,
    label: 'Document Version',
    type: 'string',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'dateCreated',
    numeric: false,
    label: 'Date Created',
    type: 'string',
    formatValue: ({ row, column }) => validateDateElseHyphen(row[column.fieldName], 'DD MMMM, YYYY h:mm A'),
  },
  {
    fieldName: 'documentDescription',
    numeric: false,
    label: 'Document Description',
    type: 'string',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'status',
    numeric: false,
    label: 'Status',
    type: 'string',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'docOwnerType',
    numeric: false,
    label: 'Document Distribution Type',
    type: 'string',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'publicationStatus',
    numeric: false,
    label: 'Publication Status',
    type: 'string',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
];

const useColumns = () => {
  const { pageSize } = usePaginationAttributes();
  const columns = getColumns(pageSize);
  return [columns];
};

export default useColumns;
