import React from 'react';
import { Typography } from '@mui/material';
import { PayheadTableMenu } from './PayheadTableMenu';

const columns = [
  {
    fieldName: 'id',
    name: 'i',
    label: 'Id',
    type: 'number',
    formatValue: ({ row, column }) => {
      return <Typography variant="body2">{row[column.fieldName]}</Typography>;
    },
  },
  {
    fieldName: 'displayName',
    name: 'dn',
    label: 'Name',
    type: 'string',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'payheadType',
    name: 'pt',
    label: 'Payhead Type',
    type: 'string',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'exemptionLevel',
    name: 'el',
    label: 'Exemption Level',
    type: 'string',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'exemptionType',
    name: 'et',
    label: 'Exemption Type',
    type: 'string',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'currency',
    name: 'cr',
    label: 'Currency',
    type: 'string',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'maxExemptionLimitPA',
    name: 'me',
    label: 'Max Exemption Limit (per Annum)',
    type: 'number',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'calculationType',
    name: 'ct',
    label: 'Calculation Type',
    type: 'string',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'compensationType',
    name: 'cm',
    label: 'Compensation Type',
    type: 'string',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'action',
    name: 'action',
    label: 'Actions',
    sticky: true,
    filter: true,
    formatValue: ({ row, column, route, action }) => (
      <PayheadTableMenu row={row} column={column} route={route} deleteAction={action} />
    ),
  },
];

const useColumns = () => {
  return [columns];
};

export default useColumns;
