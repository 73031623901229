import React from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import ReportAction from 'components/Organisms/MyExpenses/ReportAction';

const ReportActionTemplate = ({
  pageHeading,
  breadcrumbData,
  reportDetails,
  rows,
  page,
  handleSelect,
  roleBasedMenu,
  columns,
  handleCancel,
  handleSubmit,
  totalClaimAmount,
  totalApprovedAmount,
}) => {
  return (
    <BlankTemplate pageHeading={pageHeading} breadcrumbData={breadcrumbData} padding="0">
      <ReportAction
        reportDetails={reportDetails}
        rows={rows}
        page={page}
        handleSelect={handleSelect}
        roleBasedMenu={roleBasedMenu}
        columns={columns}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        totalClaimAmount={totalClaimAmount}
        totalApprovedAmount={totalApprovedAmount}
      />
    </BlankTemplate>
  );
};

ReportActionTemplate.defaultProps = {
  pageHeading: '',
  breadcrumbData: [],
  reportDetails: {
    title: '',
    employeeName: '',
    employeeId: '',
    expensePeriodStartDate: '',
    expensePeriodEndDate: '',
    department: '',
    designation: '',
    reportingManager: '',
    location: '',
    businessPurpose: '',
    reportId: '',
    reportStatus: '',
    settlementStatus: '',
  },
  roleBasedMenu: () => {},
  rows: [],
  columns: [],
  handleSelect: () => {},
  page: '',
  handleCancel: () => {},
  handleSubmit: () => {},
  totalClaimAmount: 0,
  totalApprovedAmount: 0,
};

ReportActionTemplate.propTypes = {
  pageHeading: PropTypes.string,
  breadcrumbData: PropTypes.arrayOf,
  reportDetails: PropTypes.shape({
    title: PropTypes.string,
    employeeName: PropTypes.string,
    employeeId: PropTypes.string,
    expensePeriodStartDate: PropTypes.string,
    expensePeriodEndDate: PropTypes.string,
    department: PropTypes.string,
    designation: PropTypes.string,
    reportingManager: PropTypes.string,
    location: PropTypes.string,
    businessPurpose: PropTypes.string,
    reportId: PropTypes.string,
    reportStatus: PropTypes.string,
    settlementStatus: PropTypes.string,
  }),
  roleBasedMenu: PropTypes.func,
  handleSelect: PropTypes.func,
  page: PropTypes.number,
  rows: PropTypes.arrayOf,
  columns: PropTypes.arrayOf,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  totalClaimAmount: PropTypes.string,
  totalApprovedAmount: PropTypes.string,
};

export default ReportActionTemplate;
