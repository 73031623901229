import React from 'react';
import Table from 'components/Molecules/TableExtended';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Button from 'components/Atoms/Button';
import AddIcon from '@mui/icons-material/Add';
import FilterButton from 'components/Organisms/TableFilter/FilterButton';
import NoRecordsMessage from 'components/Organisms/CommonPageUtils/NoRecordsFound';

const ListingTable = ({
  rows,
  columns,
  roleBasedMenu,
  page,
  sortDirection,
  sortBy,
  handleSelect,
  handleAddRequest,
  actions,
  handleFilter,
  showFilter,
  onSortChange,
  onPageChange,
  showPagination,
  onRowClick,
  showRowSelector,
  selectedItems,
  onSelect,
  onSelectAll,
  isDynamic,
  showActionList,
  actionOptions,
  count,
  filterFields,
  addButtonText,
  handleFiltersClear,
  loading,
  showAddButton,
}) => {
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: `${showFilter ? 'space-between' : 'end'}` }}>
        {showFilter && (
          <FilterButton
            handleFiltersClear={handleFiltersClear}
            handleSubmit={handleFilter}
            attributeOptions={filterFields}
          />
        )}

        {actions && showAddButton && (
          <Button size="Medium" variant="contained" color="primary" onClick={handleAddRequest} startIcon={<AddIcon />}>
            {addButtonText}
          </Button>
        )}
      </Box>
      <Box mt={2}>
        {!loading && rows.length === 0 ? (
          <NoRecordsMessage width="20rem" height="20rem" />
        ) : (
          <Table
            loading={loading}
            columns={columns}
            rows={rows}
            sortDirection={sortDirection}
            sortBy={sortBy}
            onSortChange={onSortChange}
            count={count}
            page={page}
            onPageChange={onPageChange}
            showPagination={showPagination}
            onRowClick={onRowClick}
            showRowSelector={showRowSelector}
            selectedItems={selectedItems}
            onSelect={onSelect}
            onSelectAll={onSelectAll}
            isDynamic={isDynamic}
            showActionList={showActionList}
            roleBasedMenu={roleBasedMenu}
            actionOptions={actionOptions}
            handleSelect={handleSelect}
          />
        )}
      </Box>
    </Box>
  );
};

ListingTable.defaultProps = {
  rows: [],
  columns: [],
  handleAddRequest: () => {},
  handleSelect: () => {},
  sortBy: () => {},
  page: '',
  sortDirection: '',
  roleBasedMenu: () => {},
  actions: true,
  handleFilter: () => {},
  showFilter: true,
  onSortChange: () => {},
  onPageChange: () => {},
  showPagination: false,
  onRowClick: () => {},
  selectedItems: [],
  showRowSelector: false,
  onSelect: () => {},
  onSelectAll: () => {},
  isDynamic: true,
  showActionList: true,
  actionOptions: [],
  filterFields: [],
  addButtonText: 'Add New',
  handleFiltersClear: () => {},
  loading: false,
  showAddButton: true,
  count: 0,
};

ListingTable.propTypes = {
  addButtonText: PropTypes.string,
  handleAddRequest: PropTypes.func,
  handleSelect: PropTypes.func,
  sortBy: PropTypes.func,
  page: PropTypes.number,
  sortDirection: PropTypes.string,
  columns: PropTypes.arrayOf,
  rows: PropTypes.arrayOf,
  roleBasedMenu: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  actions: PropTypes.bool,
  handleFilter: PropTypes.bool,
  showFilter: PropTypes.bool,
  onSortChange: PropTypes.func,
  onPageChange: PropTypes.func,
  showPagination: PropTypes.bool,
  count: PropTypes.number,
  onRowClick: PropTypes.func,
  selectedItems: PropTypes.arrayOf,
  showRowSelector: PropTypes.bool,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  isDynamic: PropTypes.bool,
  showActionList: PropTypes.bool,
  actionOptions: PropTypes.arrayOf,
  filterFields: PropTypes.arrayOf,
  handleFiltersClear: PropTypes.func,
  loading: PropTypes.bool,
  showAddButton: PropTypes.bool,
};
export default ListingTable;
