import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import CustomLegend from 'components/Molecules/CustomLegend';
import { chartStyle } from 'components/Organisms/Charts/style';

const BarCharts = ({
  chartOptions,
  chartData,
  XAxisData,
  YAxisData,
  barData,
  isLegend,
  legendOptions,
  isLegendBottom,
}) => {
  const theme = useTheme();

  return (
    <Box sx={chartStyle.chartWrapper}>
      <ResponsiveContainer width="100%" height={chartOptions.height}>
        <BarChart
          width={chartOptions.width}
          height={chartOptions.height}
          data={chartData}
          maxBarSize={chartOptions.maxBarSize}
          margin={{ top: 40, bottom: 40 }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey={XAxisData.dataKey}
            axisLine={{ stroke: theme.palette.other[200] }}
            tick={{ fill: theme.palette.neutral[600] }}
            tickLine={{ stroke: theme.palette.common.white }}
            style={chartStyle.axisLabel}
            label={{
              value: XAxisData.value,
              position: XAxisData.position,
              fill: XAxisData?.color ? theme.palette[XAxisData.color][XAxisData.colorCode] : theme.palette.neutral[800],
            }}
          />
          <YAxis
            axisLine={false}
            tick={{ fill: theme.palette.neutral[600] }}
            tickLine={{ stroke: theme.palette.common.white }}
            style={chartStyle.axisLabel}
            label={{
              value: YAxisData.value,
              position: YAxisData.position,
              offset: 20,
              fill: theme.palette.neutral[800],
            }}
            angle={-45}
          />
          <Tooltip />
          {barData.map((data) => (
            <Bar
              name={data.name}
              dataKey={data.dataKey}
              stackId={data.stackId}
              fill={theme.palette[data.color][data.colorCode]}
            />
          ))}
          {isLegend && (
            <Legend
              layout={legendOptions.layout}
              verticalAlign={legendOptions.verticalAlign}
              align={legendOptions.align}
              iconSize={legendOptions.iconSize}
              iconType={legendOptions.iconType}
              content={
                <CustomLegend
                  layout={legendOptions.layout}
                  iconType={legendOptions.iconType}
                  iconWidth={legendOptions.iconWidth}
                  iconHeight={legendOptions.iconHeight}
                  legendLeftSpacing={legendOptions.legendLeftSpacing}
                  isLegendBottom={isLegendBottom}
                />
              }
            />
          )}
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

BarCharts.defaultProps = {
  chartOptions: {},
  chartData: [],
  barData: [],
  XAxisData: {},
  YAxisData: {},
  isLegend: true,
  legendOptions: {},
  isLegendBottom: false,
};
BarCharts.propTypes = {
  chartOptions: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    maxBarSize: PropTypes.number,
  }),
  chartData: PropTypes.PropTypes.arrayOf(PropTypes.shape()),
  barData: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      dataKey: PropTypes.string,
      stackId: PropTypes.string,
      color: PropTypes.string,
      colorCode: PropTypes.number,
    }),
  ),
  XAxisData: PropTypes.shape({
    dataKey: PropTypes.string,
    value: PropTypes.string,
    position: PropTypes.string,
    color: PropTypes.string,
    colorCode: PropTypes.number,
  }),
  YAxisData: PropTypes.shape({
    value: PropTypes.string,
    position: PropTypes.string,
  }),
  isLegend: PropTypes.bool,
  legendOptions: PropTypes.shape({
    layout: PropTypes.string,
    verticalAlign: PropTypes.string,
    align: PropTypes.string,
    iconType: PropTypes.string,
    iconSize: PropTypes.string,
    iconWidth: PropTypes.string,
    iconHeight: PropTypes.string,
    legendLeftSpacing: PropTypes.string,
  }),
  isLegendBottom: PropTypes.bool,
};

export default BarCharts;
