import { createSlice } from '@reduxjs/toolkit';
import {
  fetchMyDocumentList,
  fetchMyDocumentDetails,
  fetchCreateDocument,
  fetchTeamDocumentList,
  fetchCompanyDocumentList,
  fetchSearchMyDocumentList,
  fetchSearchTeamDocumentList,
  fetchSearchCompanyDocumentList,
  fetchEditDocumentDetails,
} from 'store/thunks/documents/documents';

export const updateDocumentsList = (state, data = [], count = 0, ownerObj = [], documentTypes = []) => {
  state.documents.data = data;
  state.documents.count = count;
  state.docOwnerList = ownerObj;
  state.documentTypes = documentTypes;
  return state;
};

const documents = createSlice({
  name: 'documents',
  initialState: {
    selectedDocument: null,
    selectedDocuments: [],
    documents: {
      count: 0,
      data: [],
    },
    docOwnerList: [],
    documentDetails: [],
    documentOwnerDetails: [],
    employeesList: [],
    projectsList: [],
    departmentsList: [],
    customerVendorList: [],
    documentTypes: [],
    employeeGroupsList: [],
    documentOwnerTypes: [],
    editDocumentDetails: {},
    editDocumentTypes: [],
    editDocumentOwnerTypes: [],
    editDocumentOwnersList: {},
    editDocumentEmployeeDBID: null,
  },
  reducers: {
    updateSelectedDocument(state, action) {
      state.selectedDocument = action.payload;
    },
    updateSelectedDocuments(state, action) {
      const { isChecked, rowId } = action.payload;

      if (isChecked) {
        state.selectedDocuments.push(rowId);
        return;
      }
      state.selectedDocuments = state.selectedDocuments.filter((selectedRow) => selectedRow !== rowId);
    },
    updateAllSelectedDocuments(state, action) {
      const { isChecked } = action.payload;
      state.selectedDocuments = [];
      if (isChecked) {
        state.documents.data.map((row) => state.selectedDocuments.push(row.id));
        return;
      }
      state.selectedDocuments = [];
    },
  },

  extraReducers(builder) {
    builder.addCase(fetchMyDocumentList.fulfilled, (state, action) => {
      const {
        documentInstanceList = [],
        documentInstanceCount,
        ownerObjsMap = [],
        documentTypes = [],
      } = action.payload;
      return updateDocumentsList(state, documentInstanceList, documentInstanceCount, ownerObjsMap, documentTypes);
    });
    builder.addCase(fetchMyDocumentDetails.fulfilled, (state, action) => {
      const { documentInstance, docOwner } = action.payload;
      state.documentDetails = documentInstance;
      state.documentOwnerDetails = docOwner;
    });
    builder.addCase(fetchCreateDocument.fulfilled, (state, action) => {
      const {
        employeesList = [],
        projectsList = [],
        departmentsList = [],
        customerVendorList = [],
        documentTypes = [],
        employeeGroupsList = [],
        documentOwnerTypes = [],
      } = action.payload.data;
      state.employeesList = employeesList;
      state.projectsList = projectsList;
      state.departmentsList = departmentsList;
      state.customerVendorList = customerVendorList;
      state.documentTypes = documentTypes;
      state.employeeGroupsList = employeeGroupsList;
      state.documentOwnerTypes = documentOwnerTypes;
    });
    builder.addCase(fetchTeamDocumentList.fulfilled, (state, action) => {
      const {
        documentInstanceList = [],
        documentInstanceCount,
        ownerObjsMap = [],
        documentTypes = [],
      } = action.payload;
      state.documents.data = documentInstanceList;
      state.documents.count = documentInstanceCount;
      state.docOwnerList = ownerObjsMap;
      state.documentTypes = documentTypes;
    });
    builder.addCase(fetchCompanyDocumentList.fulfilled, (state, action) => {
      const {
        documentInstanceList = [],
        documentInstanceCount,
        ownerObjsMap = [],
        documentTypes = [],
      } = action.payload;
      state.documents.data = documentInstanceList;
      state.documents.count = documentInstanceCount;
      state.docOwnerList = ownerObjsMap;
      state.documentTypes = documentTypes;
    });
    builder.addCase(fetchSearchMyDocumentList.fulfilled, (state, action) => {
      const { results = [], totalCount, ownerObjsMap = [], documentTypes = [] } = action.payload;
      return updateDocumentsList(state, results, totalCount, ownerObjsMap, documentTypes);
    });
    builder.addCase(fetchSearchTeamDocumentList.fulfilled, (state, action) => {
      const { results = [], totalCount, ownerObjsMap = [], documentTypes = [] } = action.payload;
      return updateDocumentsList(state, results, totalCount, ownerObjsMap, documentTypes);
    });
    builder.addCase(fetchSearchCompanyDocumentList.fulfilled, (state, action) => {
      const { results = [], totalCount, ownerObjsMap = [], documentTypes = [] } = action.payload;
      return updateDocumentsList(state, results, totalCount, ownerObjsMap, documentTypes);
    });
    builder.addCase(fetchEditDocumentDetails.fulfilled, (state, action) => {
      const {
        documentInstance = [],
        documentTypes = [],
        documentOwnerTypes = {},
        employeesList = {},
        employeeDBID = null,
      } = action.payload;
      state.editDocumentDetails = documentInstance;
      state.editDocumentTypes = documentTypes;
      state.editDocumentOwnerTypes = documentOwnerTypes;
      state.editDocumentOwnersList = employeesList;
      state.editDocumentEmployeeDBID = employeeDBID;
    });
  },
});

export const { updateSelectedDocument, updateSelectedDocuments, updateAllSelectedDocuments } = documents.actions;

export default documents.reducer;
