import React, { useState } from 'react';
import { Box, Divider } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import Alert from 'components/Atoms/Alert';
import SalaryStructureCard from './card';
import Table from './table';
import {
  earnings,
  benefits,
  deductions,
  gratuity,
  otherDeductions,
  reimbursements,
  variables,
  fixedComponents,
  netSalary,
  netVariable,
  retiral,
  total,
} from './earningsData';

const AdminView = ({ isAdmin }) => {
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const handleAlert = (message) => {
    setAlertMessage(message);
    setIsOpenAlert(true);
  };

  const menuLists = [
    { id: 1, label: 'Pay structure details', onClick: () => {} },
    { id: 2, label: 'Mark as current', onClick: () => handleAlert('Salary structure successfully marked as current') },
    { id: 3, label: 'Re-sync pay structure', onClick: () => {} },
    { id: 4, label: 'Salary timeline', onClick: () => {} },
    { id: 5, label: 'Remove duplicates', onClick: () => handleAlert('Duplicates removed') },
    { id: 6, label: 'Tax working', onClick: () => {} },
    { id: 7, label: 'Detailed tax workings', onClick: () => {} },
    { id: 8, label: 'View employee', onClick: () => {} },
    { id: 9, label: 'Modify employee', onClick: () => {} },
    { id: 10, label: 'Audit log', onClick: () => {} },
    { id: 11, label: 'Delete', onClick: () => {} },
  ];

  return (
    <>
      <SalaryStructureCard isAdmin={isAdmin} menuLists={menuLists} />
      <Box sx={{ pt: 2 }}>
        <Table rows={earnings} headers={['Earnings', 'Per Period', 'Annual']} />
      </Box>
      {isAdmin && (
        <>
          <Box sx={{ pt: 2 }}>
            <Table rows={benefits} headers={['Benefits', 'Per Period', 'Annual']} />
          </Box>
          <Box sx={{ pt: 2 }}>
            <Table rows={reimbursements} headers={['Reimbursements', 'Per Period', 'Annual']} />
          </Box>
          <Box sx={{ pt: 2 }}>
            <Table rows={variables} headers={['Variable Pay - Witholdings', 'Per Period', 'Annual']} />
          </Box>
          <Box sx={{ pt: 2 }}>
            <Table rows={deductions} headers={['Deductions', 'Per Period', 'Annual']} />
          </Box>
          <Box sx={{ pt: 2 }}>
            <Table rows={otherDeductions} headers={['Other Deductions', 'Per Period', 'Annual']} />
          </Box>
          <Box sx={{ pt: 2 }}>
            <Table rows={gratuity} headers={['Gratuity', 'Per Period', 'Annual']} />
          </Box>
        </>
      )}
      <Divider sx={{ my: 2 }} />
      <Box
        sx={{
          border: '1px solid #CDD2DC',
          borderRadius: '4px',
        }}
      >
        <Table rows={fixedComponents} headers={['Net Salary', 'Per Period', 'Annual']} />
        <Box sx={{ pt: 2 }}>
          <Table rows={netSalary} headers={['Net Variable Pay', 'Per Period', 'Annual']} />
        </Box>
        <Box sx={{ pt: 2 }}>
          <Table rows={netVariable} headers={['Retiral', 'Per Period', 'Annual']} />
        </Box>
        <Box sx={{ pt: 2 }}>
          <Table rows={retiral} headers={['Fixed Components', 'Per Period', 'Annual']} />
        </Box>
      </Box>
      <Box
        sx={{
          border: '1px solid #CDD2DC',
          borderRadius: '4px',
          mt: 2,
        }}
      >
        <Table rows={total} headers={['Total', 'Per Period', 'Annual']} />
      </Box>
      {isAdmin && (
        <>
          <Divider sx={{ my: 2 }} />
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ width: '62%' }}>
              <Typographys variant="h6">Over Time Applicablity</Typographys>
            </Box>
            <Box>
              <Typographys variant="h6">No</Typographys>
            </Box>
          </Box>
        </>
      )}
      {isOpenAlert && (
        <Box sx={{ position: 'fixed', top: 70, right: -8 }}>
          <Alert
            severity="success"
            titleText="Success!"
            message={alertMessage}
            closeLabel={false}
            isOpen={isOpenAlert}
            onClose={() => setIsOpenAlert(false)}
          />
        </Box>
      )}
    </>
  );
};

export default AdminView;
