import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import InputField from 'components/Atoms/InputField';
import Divider from 'components/Atoms/Divider';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { myExpensesStyle } from 'components/Organisms/MyExpenses/style';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import Typography from 'components/Atoms/Typography';
import Table from 'components/Molecules/TableExtended';
import ReportActionDetails from './ReportActionDetails';

const ReportAction = ({
  reportDetails,
  rows,
  page,
  handleSelect,
  roleBasedMenu,
  columns,
  handleCancel,
  handleSubmit,
  totalClaimAmount,
  totalApprovedAmount,
}) => {
  const initialValues = {
    totalClaimAmount,
    totalApprovedAmount,
    expenseRecord:
      rows?.length > 0 &&
      rows.map((row) => ({
        id: row?.id,
        expenseCategoryId: row?.expenseCategoryId,
        expensedAmount: row?.expensedAmount,
        approvedAmount: row?.expensedAmount,
        approvalNotes: row?.approvalNotes || '',
      })),
    approvalNotes: '',
    actionType: 'Approve',
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnMount
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {(formik) => (
        <Box component="form" onSubmit={formik.handleSubmit}>
          <PerfectScrollbar>
            <Box sx={{ maxHeight: 'calc(100vh - 40rem)' }}>
              <ReportActionDetails reportDetails={reportDetails} formik={formik} />
              <Box padding="0 3rem">
                <Typography variant="body1" fontWeight={600} sx={{ mb: 1.5 }}>
                  Detailed Expenses Listing
                </Typography>
                <Table
                  rows={rows}
                  columns={columns}
                  page={page}
                  handleSelect={handleSelect}
                  roleBasedMenu={roleBasedMenu}
                  formik={formik}
                />
                <Divider sx={{ mt: 0.5, mb: 2 }} />
              </Box>

              <Box sx={{ mt: 2 }}>
                <Box padding="0 3rem">
                  <InputField
                    error={Boolean(formik.touched.approvalNotes && formik.errors.approvalNotes)}
                    helperText={formik.touched.approvalNotes && formik.errors.approvalNotes}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values?.approvalNotes}
                    name="approvalNotes"
                    id="approvalNotes"
                    label="Approval Notes"
                    multiline
                    fullWidth
                  />
                </Box>
              </Box>
            </Box>
          </PerfectScrollbar>
          <Divider sx={{ mt: 3, mb: 2 }} />
          <Box sx={myExpensesStyle.btnContainer}>
            <Box sx={myExpensesStyle.submitWrapper}>
              <Button
                size="large"
                color="info"
                variant="outlined"
                type="cancel"
                onClick={() => {
                  formik.resetForm();
                  handleCancel();
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                size="large"
                variant="outlined"
                onClick={() => {
                  handleSubmit({ ...formik.values, actionType: 'Reject' });
                }}
              >
                Reject & Close
              </LoadingButton>
              <LoadingButton
                size="large"
                variant="outlined"
                onClick={() => {
                  handleSubmit({ ...formik.values, actionType: 'Revert' });
                }}
              >
                Reject & Revert
              </LoadingButton>
              <LoadingButton color="primary" size="large" type="submit" variant="contained">
                Approve
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      )}
    </Formik>
  );
};

ReportAction.defaultProps = {
  reportDetails: {
    title: '',
    employeeName: '',
    employeeId: '',
    expensePeriodStartDate: '',
    expensePeriodEndDate: '',
    department: '',
    designation: '',
    reportingManager: '',
    location: '',
    businessPurpose: '',
    reportId: '',
    reportStatus: '',
    settlementStatus: '',
  },
  roleBasedMenu: () => {},
  rows: [],
  columns: [],
  handleSelect: () => {},
  page: '',
  handleCancel: () => {},
  handleSubmit: () => {},
  totalClaimAmount: 0,
  totalApprovedAmount: 0,
};

ReportAction.propTypes = {
  reportDetails: PropTypes.shape({
    title: PropTypes.string,
    employeeName: PropTypes.string,
    employeeId: PropTypes.string,
    expensePeriodStartDate: PropTypes.string,
    expensePeriodEndDate: PropTypes.string,
    department: PropTypes.string,
    designation: PropTypes.string,
    reportingManager: PropTypes.string,
    location: PropTypes.string,
    businessPurpose: PropTypes.string,
    reportId: PropTypes.string,
    reportStatus: PropTypes.string,
    settlementStatus: PropTypes.string,
  }),
  roleBasedMenu: PropTypes.func,
  handleSelect: PropTypes.func,
  page: PropTypes.number,
  rows: PropTypes.arrayOf,
  columns: PropTypes.arrayOf,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  totalClaimAmount: PropTypes.string,
  totalApprovedAmount: PropTypes.string,
};

export default ReportAction;
