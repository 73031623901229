import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { validateDateElseHyphen } from 'utils/commonUtils';
import TitleWithText from 'components/Atoms/TitleWithText';

const UserIdentity = ({ userDetails }) => {
  return (
    <Grid sx={{ paddingLeft: '24px' }} container rowSpacing={1} columnSpacing={2}>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText title="Gender" text={userDetails.gender} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText title="Marital Status" text={userDetails.maritalStatus} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText title="Date Of Birth" text={validateDateElseHyphen(userDetails.dob, 'MMMM DD, YYYY')} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText title="Date Of Joining" text={validateDateElseHyphen(userDetails.doj, 'MMMM DD, YYYY')} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText title="Employment Status" text={userDetails.employmentStatus} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText title="Father’s Name" text={userDetails.fatherName} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText title="Mother’s Name" text={userDetails.motherName} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText title="Spouse Name" text={userDetails.spouseName} />
      </Grid>
    </Grid>
  );
};

UserIdentity.defaultProps = {
  userDetails: {
    gender: 'Male',
    maritalStatus: 'UnMarried',
    dob: '07/06/1960',
    doj: '04/06/2017',
    employmentStatus: 'Full Time',
    fatherName: 'Vidyasagar',
    motherName: 'Snehalatha',
    spouseName: '-',
  },
};

UserIdentity.propTypes = {
  userDetails: PropTypes.shape({
    gender: PropTypes.string,
    maritalStatus: PropTypes.string,
    dob: PropTypes.string,
    doj: PropTypes.string,
    employmentStatus: PropTypes.string,
    fatherName: PropTypes.string,
    motherName: PropTypes.string,
    spouseName: PropTypes.string,
  }),
};

export default UserIdentity;
