import { useEffect, useReducer } from 'react';
import FormData from 'form-data';
import { formDataApi } from 'api/api';

import { convertPayheadData } from './converter';

const initialState = {
  isLoading: false,
  data: {},
  error: null,
};

const reducer = (state, action) => {
  const { type, payload } = action;
  let newState;
  switch (type) {
    case 'pending': {
      newState = { ...state, isLoading: true };
      break;
    }
    case 'resolved': {
      newState = { ...state, isLoading: false, data: payload };
      break;
    }
    case 'rejected': {
      newState = { ...state, isLoading: false, error: payload };
      break;
    }
    default: {
      newState = state;
    }
  }
  return newState;
};

const useFetchPayhead = (payheadId, page, isEditMode = true) => {
  const [payhead, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    const fetchPayheadById = async () => {
      dispatch({ type: 'pending' });
      const bodyFormData = new FormData();
      bodyFormData.append('id', payheadId);
      try {
        const response = await formDataApi.post('restPayIndex/displayPayhead', bodyFormData);
        if (response.data) {
          const transformedData = page === 'view' ? convertPayheadData(response.data) : response.data;
          dispatch({ type: 'resolved', payload: transformedData });
        }
      } catch (error) {
        dispatch({ type: 'rejected', payload: error });
      }
    };
    if (isEditMode) {
      fetchPayheadById();
    }
  }, [payheadId, page]);

  return [payhead];
};

export default useFetchPayhead;
