import React from 'react';
import PropTypes from 'prop-types';
import MuiAlert from '@mui/material/Alert';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Typographys from 'components/Atoms/Typography';

const Alert = ({ icon, isOpen, titleText, message, isClose, closeLabel, onClose, severity }) => {
  let actionContent = null;

  if (isClose) {
    actionContent = (
      <IconButton color="neutral.600" size="small" onClick={onClose}>
        {closeLabel && (
          <Typographys variant="caption" mr={1} color="neutral.800">
            {closeLabel}
          </Typographys>
        )}
        <Box fontSize="1.75rem" lineHeight={0}>
          <CloseIcon fontSize="inherit" />
        </Box>
      </IconButton>
    );
  }

  return (
    <MuiAlert
      variant="outlined"
      open={isOpen}
      icon={icon}
      severity={severity}
      iconMapping={{
        error: <ErrorOutlineOutlinedIcon fontSize="inherit" />,
        warning: <WarningAmberOutlinedIcon fontSize="inherit" />,
        info: <InfoOutlinedIcon fontSize="inherit" />,
        success: <CheckCircleOutlineOutlinedIcon fontSize="inherit" />,
      }}
      action={actionContent}
    >
      {titleText && (
        <Box mb={0.5}>
          <Typographys color="neutral.800" variant="alertTitle">
            {titleText}!
          </Typographys>
        </Box>
      )}

      <Typographys variant="body2" color="neutral.800">
        {message}
      </Typographys>
    </MuiAlert>
  );
};

Alert.defaultProps = {
  severity: 'error',
  isOpen: false,
  onClose: () => {},
  closeLabel: 'Close',
  titleText: 'Title',
  message: 'message',
  isClose: false,
  icon: '',
};

Alert.propTypes = {
  isOpen: PropTypes.bool,
  message: PropTypes.string,
  closeLabel: PropTypes.string,
  onClose: PropTypes.func,
  icon: PropTypes.string,
  titleText: PropTypes.string,
  severity: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
  isClose: PropTypes.bool,
};

export default Alert;
