import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { useFilters } from 'hooks/use-filters';
import { X as XIcon } from 'assets/icons/x';
import { FilterDialogItem } from './filter-dialog-item';

export const FilterDialog = (props) => {
  const { open, onClose, properties, onApply, onClear, ...other } = props;

  const {
    addFilter,
    clearFilters,
    filters,
    handleOperatorChange,
    handlePropertyChange,
    handleValueChange,
    removeFilter,
  } = useFilters(properties);

  const handleFiltersClear = () => {
    clearFilters();
    onClear?.();
    onClose?.();
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          maxWidth: 500,
          width: '100%',
        },
      }}
      {...other}
    >
      <DialogTitle
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          display: 'flex',
        }}
      >
        <Typography color="textPrimary" variant="inherit">
          Filter
        </Typography>
        <IconButton onClick={onClose} size="small">
          <XIcon fontSize="small" sx={{ color: 'text.primary' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {filters.map((filter, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={index}>
            <FilterDialogItem
              filter={filter}
              index={index}
              onAddFilter={addFilter}
              onRemoveFilter={removeFilter}
              onPropertyChange={handlePropertyChange}
              onValueChange={handleValueChange}
              properties={properties}
            />
            {filters.length > index + 1 && (
              <Divider
                sx={{
                  my: 2,
                  '& .MuiDivider-wrapper': {
                    p: 0,
                  },
                }}
                textAlign="left"
              >
                <Chip color="primary" label="AND" size="small" />
              </Divider>
            )}
          </Fragment>
        ))}
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleFiltersClear} variant="text">
          Reset
        </Button>
        <Button
          color="primary"
          onClick={() => {
            onApply?.(filters);
            onClose?.();
          }}
          variant="contained"
        >
          Filter
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FilterDialog.defaultProps = {
  open: false,
};

FilterDialog.propTypes = {
  onApply: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  operators: PropTypes.instanceOf(Array).isRequired,
  properties: PropTypes.instanceOf(Array).isRequired,
};
