import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Divider, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Button from 'components/Atoms/Button';
import InputField from 'components/Atoms/InputField';
import Typographys from 'components/Atoms/Typography';
import UploadProfile from 'components/Molecules/UploadProfile';
import Dropdown from 'components/Molecules/Dropdown';
import DatePicker from 'components/Atoms/Datepicker';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { onBoardingStyle } from '../../style';

const BasicProfile = ({
  maritalStatusOptions,
  bloodGroupOptions,
  locationOptions,
  genderOptions,
  onboardingReferenceOptions,
  handleSubmit,
  handleCancel,
  propData,
  label,
}) => {
  const OnboardingSchema = yup.object().shape({
    gender: yup.string().required('Required'),
    dateOfBirth: yup.string().required('Required'),
    dateOfJoining: yup.string().required('Required'),
    fatherName: yup.string().required('Required'),
    maritalStatus: yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues: propData.allTabsData[label],
    validateOnMount: true,
    validationSchema: OnboardingSchema,
    onSubmit: handleSubmit,
  });

  const handleDateChange = (date, fieldName) => {
    formik.setFieldValue(fieldName, date);
  };

  useEffect(() => {
    propData.updateFormData(formik.values, label);
  }, [formik.values, label]);

  const theme = useTheme();

  return (
    <Box mt={1.5} px={3} component="form" onSubmit={formik.handleSubmit}>
      <Typographys variant="h5" color="neutral.800">
        Basic Information
      </Typographys>

      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 59rem)' }}>
          <Box my={2}>
            <UploadProfile
              handleChange={(event) => {
                formik.setFieldValue(`uploadProfile`, event.target.files[0]);
                formik.setFieldValue(`uploadProfileObjectURL`, URL.createObjectURL(event.target.files[0]));
              }}
              name="uploadProfile"
              id="uploadProfile"
              value={formik?.values?.uploadProfileObjectURL || formik?.values?.uploadProfile}
            />
          </Box>

          <Grid container rowSpacing={2} columnSpacing={9} sx={onBoardingStyle.formContainer}>
            <Grid item xs={12} md={4}>
              <Dropdown
                error={Boolean(formik.touched.gender && formik.errors.gender)}
                helperText={formik.touched.gender && formik.errors.gender}
                handleChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik?.values?.gender}
                disabled={propData?.initialBasicProfileData?.gender}
                name="gender"
                id="gender"
                label="Gender*"
                options={genderOptions}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.fatherName && formik.errors.fatherName)}
                helperText={formik.touched.fatherName && formik.errors.fatherName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik?.values?.fatherName}
                name="fatherName"
                id="fatherName"
                label="Father’s Name*"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.motherName && formik.errors.motherName)}
                helperText={formik.touched.motherName && formik.errors.motherName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik?.values?.motherName}
                name="motherName"
                id="motherName"
                label="Mother’s Name"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Dropdown
                error={Boolean(formik.touched.maritalStatus && formik.errors.maritalStatus)}
                helperText={formik.touched.maritalStatus && formik.errors.maritalStatus}
                handleChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik?.values?.maritalStatus}
                name="maritalStatus"
                id="maritalStatus"
                label="Marital Status*"
                options={maritalStatusOptions}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <InputField
                error={Boolean(formik.touched.spouseName && formik.errors.spouseName)}
                helperText={formik.touched.spouseName && formik.errors.spouseName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik?.values?.spouseName}
                name="spouseName"
                id="spouseName"
                label="Spouse Name"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <DatePicker
                error={
                  Boolean(formik.touched.dateOfBirth && formik.errors.dateOfBirth) ||
                  (formik.touched.dateOfBirth && !formik?.values?.dateOfBirth)
                }
                helperText={formik.touched.dateOfBirth && formik.errors.dateOfBirth}
                onChange={(date) => handleDateChange(date, 'dateOfBirth')}
                onBlur={formik.handleBlur}
                value={formik?.values?.dateOfBirth}
                disabled={propData?.initialBasicProfileData?.dateOfBirth}
                name="dateOfBirth"
                id="dateOfBirth"
                label="Date Of Birth*"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <DatePicker
                error={
                  Boolean(formik.touched.dateOfJoining && formik.errors.dateOfJoining) ||
                  (formik.touched.dateOfJoining && !formik?.values?.dateOfJoining)
                }
                helperText={formik.touched.dateOfJoining && formik.errors.dateOfJoining}
                onChange={(date) => handleDateChange(date, 'dateOfJoining')}
                onBlur={formik.handleBlur}
                value={formik?.values?.dateOfJoining}
                disabled={propData?.initialBasicProfileData?.dateOfJoining}
                name="dateOfJoining"
                id="dateOfJoining"
                label="Date of Joining*"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Dropdown
                error={Boolean(formik.touched.location && formik.errors.location)}
                helperText={formik.touched.location && formik.errors.location}
                handleChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik?.values?.location}
                name="location"
                id="location"
                label="Location"
                options={locationOptions}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Dropdown
                error={Boolean(formik.touched.bloodGroup && formik.errors.bloodGroup)}
                helperText={formik.touched.bloodGroup && formik.errors.bloodGroup}
                handleChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik?.values?.bloodGroup}
                name="bloodGroup"
                id="bloodGroup"
                label="Blood Group"
                options={bloodGroupOptions}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Dropdown
                error={Boolean(formik.touched.onboardingReference && formik.errors.onboardingReference)}
                helperText={formik.touched.onboardingReference && formik.errors.onboardingReference}
                handleChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik?.values?.onboardingReference}
                name="onboardingReference"
                id="onboardingReference"
                label="Onboarding Reference"
                options={onboardingReferenceOptions}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </PerfectScrollbar>

      <Box sx={onBoardingStyle.btnContainer}>
        <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

        <Box sx={onBoardingStyle.submitWrapper}>
          <Button
            size="large"
            color="info"
            variant="outlined"
            type="button"
            onClick={() => {
              formik.resetForm();
              handleCancel();
            }}
          >
            Cancel
          </Button>
          <LoadingButton loading={propData.loading} color="primary" size="large" type="submit" variant="contained">
            Save & Continue
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

BasicProfile.defaultProps = {
  maritalStatusOptions: [],
  bloodGroupOptions: [],
  locationOptions: [],
  genderOptions: [],
  onboardingReferenceOptions: [],
  handleSubmit: () => {},
  handleCancel: () => {},
  propData: {
    updateFormData: () => {},
    allTabsData: {},
    loading: false,
  },
  label: 'BASIC PROFILE',
};

BasicProfile.propTypes = {
  propData: PropTypes.shape({
    updateFormData: PropTypes.func,
    allTabsData: PropTypes.shape({}),
    loading: PropTypes.bool,
  }),

  label: PropTypes.string,
  maritalStatusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  bloodGroupOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  locationOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  genderOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  onboardingReferenceOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default BasicProfile;
