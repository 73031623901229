import PropTypes from 'prop-types';
import React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { actionListStyle } from './style';
import Typographys from '../../Atoms/Typography';

const ActionList = ({ ariaLabel, options, dynamicMenuOptions, data, handleSelect, label, labelColor }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    handleSelect(data);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (option) => {
    handleClose();
    option.onClick(data);
  };
  return (
    <div>
      <IconButton size="large" aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon sx={{ fontSize: '3rem' }} />
      </IconButton>
      <Menu
        MenuListProps={{ 'aria-labelledby': { ariaLabel } }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={actionListStyle.wrapper}
      >
        {label && (
          <Typographys
            variant="caption"
            fontWeight={600}
            color={labelColor}
            sx={{ display: 'block', textTransform: 'uppercase', px: 2, py: 1 }}
          >
            {label}
          </Typographys>
        )}
        {dynamicMenuOptions
          ? options.map((option) => (
              <MenuItem
                key={option.name}
                onClick={() => handleMenuItemClick(option)}
                sx={{ color: option?.color ? option.color : 'neutral.800' }}
              >
                {option.name}
              </MenuItem>
            ))
          : options.map((option) => (
              <MenuItem key={option.name} onClick={() => handleMenuItemClick(option)}>
                {option.name}
              </MenuItem>
            ))}
      </Menu>
    </div>
  );
};
ActionList.defaultProps = {
  ariaLabel: 'Action Menu',
  options: [],
  handleSelect: () => {},
  dynamicMenuOptions: () => {},
  data: {},
  label: '',
  labelColor: 'secondary.600',
};
ActionList.propTypes = {
  handleSelect: PropTypes.func,
  dynamicMenuOptions: PropTypes.func,
  ariaLabel: PropTypes.string,
  data: PropTypes.shape({}),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  label: PropTypes.string,
  labelColor: PropTypes.string,
};
export default ActionList;
