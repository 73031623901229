import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { validateDateElseHyphen } from 'utils/commonUtils';
import TitleWithText from 'components/Atoms/TitleWithText';
import Typographys from 'components/Atoms/Typography';
import { additionalDetailStyle } from '../style';

const DependentDetails = ({ dependentDetails }) => {
  return (
    <>
      <Typographys variant="h5" color="neutral.700">
        Dependents and Nomination Details
      </Typographys>
      <Box mt={2} sx={additionalDetailStyle.rowDivider}>
        {dependentDetails.map((dependent) => (
          <Grid container rowSpacing={1} columnSpacing={2}>
            <Grid item xs={12} sm={4}>
              <TitleWithText title="First Name" text={dependent.firstName} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TitleWithText title="Last Name" text={dependent.lastName} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TitleWithText title="Gender" text={dependent.gender} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TitleWithText title="Relation" text={dependent.relation} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TitleWithText
                title="Date of Birth"
                text={validateDateElseHyphen(dependent.dateOfBirth, 'DD MMMM YYYY')}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TitleWithText title="Mobile Number" text={dependent.mobileNumber} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TitleWithText title="Email" text={dependent.emailId} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TitleWithText title="Blood Group" text={dependent.bloodGroup} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TitleWithText title="EPF Nomination %" text={dependent.epfPerc} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TitleWithText title="EPS Nomination %" text={dependent.epsPerc} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TitleWithText title="Insurance Nomination %" text={dependent.insurancePerc} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TitleWithText title="Others Nomination %" text={dependent.otherPerc} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TitleWithText title="Gratuity  Nomination %" text={dependent.gratuityPerc} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TitleWithText title="Emergency Contact Reference" text={dependent.emergencyContact} />
            </Grid>
          </Grid>
        ))}
      </Box>
    </>
  );
};

DependentDetails.defaultProps = {
  dependentDetails: [],
};

DependentDetails.propTypes = {
  dependentDetails: PropTypes.arrayOf(
    PropTypes.shape({
      gender: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      relation: PropTypes.string,
      dateOfBirth: PropTypes.string,
      mobileNumber: PropTypes.string,
      emailId: PropTypes.string,
      epfPerc: PropTypes.string,
      epsPerc: PropTypes.string,
      insurancePerc: PropTypes.string,
      otherPerc: PropTypes.string,
      gratuityPerc: PropTypes.string,
      emergencyContact: PropTypes.string,
      bloodGroup: PropTypes.string,
    }),
  ),
};

export default DependentDetails;
