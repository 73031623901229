import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Calendar from 'components/Molecules/Calendar';
import CommonCard from 'components/Molecules/CommonCard';
import TabCard from './TabCard';

const MyTeamDashboard = ({
  employeeList,
  teamMembersList,
  holidaysLoading,
  holidaysColumn,
  holidaysRow,
  holidaysSortDirection,
  holidaysSortBy,
  holidaysOnSortChange,
  documentsLoading,
  documentsColumn,
  documentsRow,
  documentsSortDirection,
  documentsSortBy,
  documentsOnSortChange,
  documentsCount,
  documentsPage,
  documentsOnPageChange,
  documentsHandleDownload,
  docFilterLabel,
  docFilterOptions,
  handleDocSelect,
  calendarYear,
  events,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={12}>
        <TabCard
          employeeList={employeeList}
          teamMembersList={teamMembersList}
          holidaysLoading={holidaysLoading}
          holidaysColumn={holidaysColumn}
          holidaysRow={holidaysRow}
          holidaysSortDirection={holidaysSortDirection}
          holidaysSortBy={holidaysSortBy}
          holidaysOnSortChange={holidaysOnSortChange}
          documentsLoading={documentsLoading}
          documentsColumn={documentsColumn}
          documentsRow={documentsRow}
          documentsSortDirection={documentsSortDirection}
          documentsSortBy={documentsSortBy}
          documentsOnSortChange={documentsOnSortChange}
          documentsCount={documentsCount}
          documentsPage={documentsPage}
          documentsOnPageChange={documentsOnPageChange}
          documentsHandleDownload={documentsHandleDownload}
          docFilterLabel={docFilterLabel}
          docFilterOptions={docFilterOptions}
          handleDocSelect={handleDocSelect}
        />
      </Grid>
      <Grid item xs={12} lg={12}>
        <CommonCard
          cardHeaderTitle={`Calendar - FY ${calendarYear}`}
          isHeaderFilter={false}
          isFooter={false}
          isSelect={false}
          cardBodyPadding="3rem 0 0 0"
          bodyContent={<Calendar events={events} />}
        />
      </Grid>
    </Grid>
  );
};

MyTeamDashboard.defaultProps = {
  employeeList: [],
  teamMembersList: [],
  holidaysLoading: false,
  holidaysColumn: [],
  holidaysRow: [],
  holidaysSortDirection: 'asc',
  holidaysSortBy: 'id',
  holidaysOnSortChange: () => {},
  documentsLoading: false,
  documentsColumn: [],
  documentsRow: [],
  documentsSortDirection: 'asc',
  documentsSortBy: 'id',
  documentsOnSortChange: () => {},
  documentsCount: 7,
  documentsPage: 0,
  documentsOnPageChange: () => {},
  documentsHandleDownload: () => {},
  docFilterLabel: '',
  docFilterOptions: [],
  handleDocSelect: () => {},
  calendarYear: '',
  events: [],
};
MyTeamDashboard.propTypes = {
  employeeList: PropTypes.arrayOf,
  teamMembersList: PropTypes.arrayOf(
    PropTypes.shape({
      profileImg: PropTypes.string,
      profileName: PropTypes.string,
      emailId: PropTypes.string,
      contactNumber: PropTypes.number,
      event: PropTypes.string,
    }),
  ),
  holidaysLoading: PropTypes.bool,
  holidaysColumn: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),
  holidaysRow: PropTypes.arrayOf(
    PropTypes.shape({
      slNo: PropTypes.number,
      date: PropTypes.date,
      occasion: PropTypes.string,
    }),
  ),
  holidaysSortDirection: PropTypes.string,
  holidaysSortBy: PropTypes.string,
  holidaysOnSortChange: PropTypes.func,
  documentsLoading: PropTypes.bool,
  documentsColumn: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),
  documentsRow: PropTypes.arrayOf(
    PropTypes.shape({
      slNo: PropTypes.number,
      documentName: PropTypes.string,
      section: PropTypes.string,
    }),
  ),
  documentsSortDirection: PropTypes.string,
  documentsSortBy: PropTypes.string,
  documentsOnSortChange: PropTypes.func,
  documentsCount: PropTypes.number,
  documentsPage: PropTypes.number,
  documentsOnPageChange: PropTypes.func,
  documentsHandleDownload: PropTypes.func,
  docFilterLabel: PropTypes.string,
  docFilterOptions: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, onClick: PropTypes.func })),
  handleDocSelect: PropTypes.func,
  calendarYear: PropTypes.string,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      start: PropTypes.string,
      end: PropTypes.string,
      color: PropTypes.string,
      textColor: PropTypes.string,
      border: PropTypes.string,
      type: PropTypes.string,
      data: PropTypes.shape({
        title: PropTypes.string,
        employeeName: PropTypes.string,
        birthdayDate: PropTypes.string,
        employeeImage: PropTypes.string,
        employeeDesignation: PropTypes.string,
      }),
    }),
  ),
};

export default MyTeamDashboard;
