import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Dialog from 'components/Molecules/Dialog';
import InputField from 'components/Atoms/InputField/index';
import { Box } from '@mui/material';

const SubmitDialog = ({
  open,
  handleClose,
  handleCancel,
  handleSubmit,
  name,
  title = 'Submit Leave',
  action = 'submit',
  primaryButtonLabel,
}) => {
  const [note, setNote] = useState('');

  return (
    <Dialog
      open={open}
      handleClose={handleCancel}
      dialogTitle={title}
      dialogDesc={`Are you sure you want to ${action} ${name}?`}
      secondaryBtn="Cancel"
      primaryBtn={primaryButtonLabel || 'Submit'}
      handleSecondaryBtn={handleCancel}
      handlePrimaryBtn={() => handleSubmit({ note })}
      primaryBtnColor="primary"
      bodyContent={
        <Box sx={{ width: '100%' }}>
          <InputField
            multiline
            name="comment"
            required
            fullWidth
            id="comment"
            label="Comment"
            autoComplete="comment"
            onChange={(e) => setNote(e.target.value)}
          />
        </Box>
      }
    />
  );
};

SubmitDialog.defaultProps = {
  open: true,
  handleClose: () => {},
  handleCancel: () => {},
  title: '',
  handleSubmit: () => {},
  name: '',
  primaryButtonLabel: '',
  action: '',
};

SubmitDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleCancel: PropTypes.func,
  title: PropTypes.string,
  handleSubmit: PropTypes.func,
  name: PropTypes.string,
  primaryButtonLabel: PropTypes.string,
  action: PropTypes.string,
};

export default SubmitDialog;
