import {
  createBoldPlugin,
  createCodePlugin,
  createItalicPlugin,
  createStrikethroughPlugin,
  createSubscriptPlugin,
  createSuperscriptPlugin,
  createUnderlinePlugin,
  createSelectOnBackspacePlugin,
} from '@udecode/plate';
import { CONFIG } from '../config/config';
import { plateComponentUI } from '../config/plateComponentUI';
import { createMyPlugins } from '../config/typescript';

export const basicMarksPlugins = createMyPlugins(
  [
    createBoldPlugin(),
    createCodePlugin(),
    createItalicPlugin(),
    createStrikethroughPlugin(),
    createSubscriptPlugin(),
    createSuperscriptPlugin(),
    createUnderlinePlugin(),
    createSelectOnBackspacePlugin(CONFIG.selectOnBackspace),
  ],
  {
    components: plateComponentUI,
  },
);
