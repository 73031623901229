import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, useTheme } from '@mui/material';
import Button from 'components/Atoms/Button';
import { validateDateElseHyphen } from 'utils/commonUtils';
import EditIcon from '@mui/icons-material/Edit';
import TitleWithText from 'components/Atoms/TitleWithText';
import Divider from '@mui/material/Divider';
import AuditTrailCard from 'components/Organisms/Employee/EmployeeRecord/AuditTrailCard';
import Typographys from 'components/Atoms/Typography';
import DropdownMenu from 'components/Molecules/DropdownMenu';
import IconWithTitle from 'components/Atoms/IconWithTitle';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const QueryDetails = ({
  reportDetails,
  handleEdit,
  actionMenuList,
  isRecall,
  handleRecall,
  handleAttachmentDownload,
  showEdit,
  showActions,
  auditLogData,
}) => {
  const theme = useTheme();
  return (
    <Box>
      {!!showActions && (
        <Box padding="0rem 0rem 2rem" sx={{ display: 'flex', justifyContent: 'end', gap: 1 }}>
          {isRecall ? (
            <Button onClick={handleRecall}>Recall</Button>
          ) : (
            <>
              {!!showEdit && (
                <Button startIcon={<EditIcon />} onClick={handleEdit}>
                  Edit
                </Button>
              )}

              <DropdownMenu btnLabel="Actions" size="medium" menuLists={actionMenuList} />
            </>
          )}
        </Box>
      )}

      <Grid container columnSpacing={9}>
        <Grid item xs={12} md={4}>
          <TitleWithText title="Query Number" text={reportDetails.queryNumber} />
        </Grid>

        <Grid item xs={12} md={4}>
          <TitleWithText title="Title" text={reportDetails.title} />
        </Grid>

        <Grid item xs={12} md={4}>
          <TitleWithText title="Resolution Status" text={reportDetails.resolutionStatus} />
        </Grid>

        <Grid item xs={12} md={4}>
          <TitleWithText title="Opened By" text={reportDetails.openedBy} />
        </Grid>

        <Grid item xs={12} md={4}>
          <TitleWithText title="Closed By" text={reportDetails.closedBy} />
        </Grid>

        <Grid item xs={12} md={4}>
          <TitleWithText title="Source Type" text={reportDetails.sourceType} />
        </Grid>

        <Grid item xs={12} md={4}>
          <TitleWithText
            title="Open Date"
            text={validateDateElseHyphen(reportDetails.openDate, 'DD MMMM, YYYY h:mm A')}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TitleWithText
            title="Close Date"
            text={validateDateElseHyphen(reportDetails.closeDate, 'DD MMMM, YYYY h:mm A')}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TitleWithText
            title="Submission Date"
            text={validateDateElseHyphen(reportDetails.submissionDate, 'DD MMMM, YYYY h:mm A')}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TitleWithText title="Current Owner" text={reportDetails.currentOwner} />
        </Grid>
        <Grid item xs={12} md={4}>
          <TitleWithText title="Category" text={reportDetails.category} />
        </Grid>
        <Grid item xs={12} md={4}>
          <TitleWithText title="Topic" text={reportDetails.topic} />
        </Grid>
        <Grid item xs={12} md={12}>
          <TitleWithText title="Description" text={reportDetails.description} />
        </Grid>
      </Grid>

      <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

      <Box>
        <Typographys variant="h6" color="neutral.800">
          Attachment
        </Typographys>

        <Box sx={{ mt: 1.5, width: 'max-content' }}>
          {reportDetails?.fileName ? (
            <IconWithTitle
              sx={{ cursor: 'pointer', width: 'max-content' }}
              iconElement={<AttachFileIcon fontSize="inherit" />}
              iconColor="actions.600"
              iconFontSize="2.5rem"
              title={reportDetails?.fileName}
              titleColor="neutral.800"
              titleVariant="body1"
              onClick={() => handleAttachmentDownload(reportDetails?.id, reportDetails?.fileName)}
              isClickable
            />
          ) : (
            <Typographys>No attached file</Typographys>
          )}
        </Box>
      </Box>
      <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />
      <Typographys variant="h6" color="neutral.800">
        Audit Log
      </Typographys>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2.5 }}>
        {auditLogData?.length > 0 &&
          auditLogData?.map((item) => (
            <AuditTrailCard
              avatarSrc={item?.avatarSrc}
              description={item?.description}
              date={item?.date}
              content={item?.content}
            />
          ))}
      </Box>
    </Box>
  );
};

QueryDetails.defaultProps = {
  reportDetails: {
    queryNumber: '',
    title: '',
    resolutionStatus: '',
    openedBy: '',
    closedBy: '',
    openDate: '',
    closeDate: '',
    submissionDate: '',
    currentOwner: '',
    category: '',
    topic: '',
    description: '',
    fileName: '',
    id: '',
    sourceType: '',
  },
  handleEdit: () => {},
  actionMenuList: [],
  isRecall: false,
  handleRecall: () => {},
  handleAttachmentDownload: () => {},
  showEdit: true,
  showActions: true,
  auditLogData: [],
};

QueryDetails.propTypes = {
  reportDetails: PropTypes.shape({
    queryNumber: PropTypes.string,
    title: PropTypes.string,
    resolutionStatus: PropTypes.string,
    openedBy: PropTypes.string,
    closedBy: PropTypes.string,
    openDate: PropTypes.string,
    closeDate: PropTypes.string,
    submissionDate: PropTypes.string,
    currentOwner: PropTypes.string,
    category: PropTypes.string,
    topic: PropTypes.string,
    description: PropTypes.string,
    fileName: PropTypes.string,
    id: PropTypes.number,
    sourceType: PropTypes.string,
  }),
  handleEdit: PropTypes.func,
  actionMenuList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  isRecall: PropTypes.bool,
  handleRecall: PropTypes.func,
  handleAttachmentDownload: PropTypes.func,
  showEdit: PropTypes.bool,
  showActions: PropTypes.bool,
  auditLogData: PropTypes.arrayOf(
    PropTypes.shape({
      avatarSrc: PropTypes.string,
      name: PropTypes.string,
      date: PropTypes.string,
    }),
  ),
};

export default QueryDetails;
