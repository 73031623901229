export const taskStyle = {
  actionBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  formContainer: {
    width: '100%',
    maxWidth: '100%',
    mt: 0,
    mb: 2.5,
  },

  formDivider: {
    '&:not(:first-child)': {
      borderTop: '0.125rem solid',
      borderColor: 'other.200',
      mt: 4,
      pt: 4,
    },
  },

  textDivider: {
    '&:not(:first-child)': {
      borderTop: '0.125rem solid',
      borderColor: 'other.200',
      mt: 2.5,
      pt: 2.5,
    },
  },

  addButton: {
    my: 3,
  },

  btnContainer: {
    position: 'absolute',
    right: '0rem',
    bottom: '0',
    backgroundColor: 'common.white',
    zIndex: '9',
    boxSizing: 'border-box',
    width: '100%',
    padding: '0rem 3rem 3.25rem 3rem',
  },

  submitWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 2,
  },

  submitButtons: {
    display: 'flex',
    justifyContent: 'end',
    gap: 2,
    flex: 1,
  },
};
