import React from 'react';
import PropTypes from 'prop-types';
import TableExtended from 'components/Molecules/TableExtended';
import Chips from 'components/Molecules/Chip';
import NoRecordsMessage from 'components/Organisms/CommonPageUtils/NoRecordsFound';
import Button from 'components/Atoms/Button';
import { Box } from '@mui/material';

const Documents = ({
  rows,
  keyColumn,
  sortDirection,
  sortBy,
  onSortChange,
  count,
  page,
  onPageChange,
  showPagination,
  onRowClick,
  handleSelect,
  loading,
}) => {
  const columns = [
    {
      fieldName: 'slNo',
      numeric: true,
      label: 'Sl No',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'documentName',
      label: 'Document name',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'documentType',
      label: 'Document type',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'section',
      label: 'Section',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'documentCreated',
      label: 'Document Created',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'documentVersion',
      label: 'Document Version',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'documentDescription',
      label: 'Document Description',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'documentStatus',
      label: 'Document Status',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'documentDistributionType',
      label: 'Document Distribution Type',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'documentOwner',
      label: 'Document Owner',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'publicationStatus',
      label: 'Publication Status',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
  ];
  return (
    <Box mt={2}>
      {rows.length > 0 ? (
        <TableExtended
          columns={columns}
          rows={rows.map((i) => {
            return {
              ...i,
              documentStatus: (
                <Chips
                  label={i.documentStatus}
                  isShowIcon={false}
                  color={i.documentStatus === 'Active' ? 'success' : 'warning'}
                />
              ),
              documentName: (
                <Button variant="text" color="secondary" onClick={() => {}}>
                  {i.documentName}
                </Button>
              ),
            };
          })}
          keyColumn={keyColumn}
          sortDirection={sortDirection}
          sortBy={sortBy}
          onSortChange={onSortChange}
          count={count}
          page={page}
          onPageChange={onPageChange}
          showPagination={showPagination}
          onRowClick={onRowClick}
          handleSelect={handleSelect}
          loading={loading}
          showCheckbox={false}
          showActionList={false}
        />
      ) : (
        <NoRecordsMessage width="20rem" height="20rem" />
      )}
    </Box>
  );
};

Documents.defaultProps = {
  keyColumn: 'id',
  sortDirection: 'desc',
  sortBy: 'id',
  onSortChange: () => {},
  page: 1,
  onPageChange: () => {},
  showPagination: false,
  onRowClick: () => {},
  handleSelect: () => {},
  loading: false,
  rows: [],
};

Documents.propTypes = {
  rows: PropTypes.arrayOf,
  keyColumn: PropTypes.string,
  onSortChange: PropTypes.func,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  showPagination: PropTypes.bool,
  count: PropTypes.number.isRequired,
  onRowClick: PropTypes.func,
  handleSelect: PropTypes.func,
  loading: PropTypes.bool,
};

export default Documents;
