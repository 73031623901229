import React from 'react';
import PropTypes from 'prop-types';
import EmployeeCommonCard from 'components/Organisms/Employee/EmployeeCommonCard';
import NumOfEmpChartGridWise from '../Charts/NumOfEmpChartGridWise';
import NumOfEmpChartLevelWise from '../Charts/NumOfEmpChartLevelWise';

const NumOfEmployeeCard = ({
  numOfEmployeeFilterOptions,
  numOfEmployeeFilterHandleChange,
  empData,
  empChartLevelWiseData,
}) => {
  return (
    <EmployeeCommonCard
      cardHeaderTitle="Numbers of Employee"
      minWidthHeaderDropdown="13rem"
      headerFilterId="numOfEmployeeFilter"
      headerFilterLabel="Grade Wise"
      headerFilterOptions={numOfEmployeeFilterOptions}
      headerFilterHandleChange={numOfEmployeeFilterHandleChange}
      cardBodyPadding="2rem 0 2rem 2rem"
      bodyContent={
        <>
          <NumOfEmpChartGridWise empData={empData} />
          <NumOfEmpChartLevelWise empChartLevelWiseData={empChartLevelWiseData} />
        </>
      }
      isFooter={false}
    />
  );
};

NumOfEmployeeCard.defaultProps = {
  numOfEmployeeFilterOptions: [],
  numOfEmployeeFilterHandleChange: () => {},
  empData: [],
  empChartLevelWiseData: [],
};
NumOfEmployeeCard.propTypes = {
  numOfEmployeeFilterOptions: PropTypes.arrayOf,
  numOfEmployeeFilterHandleChange: PropTypes.func,
  empData: PropTypes.arrayOf,
  empChartLevelWiseData: PropTypes.arrayOf,
};

export default NumOfEmployeeCard;
