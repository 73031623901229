import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Divider } from '@mui/material';
import Button from 'components/Atoms/Button';
import EditIcon from '@mui/icons-material/Edit';
import TitleWithText from 'components/Atoms/TitleWithText';
import Typographys from 'components/Atoms/Typography';
import DropdownMenu from 'components/Molecules/DropdownMenu';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useTheme } from '@mui/material/styles';
import { validateDateElseHyphen } from 'utils/commonUtils';
import { hasPermission } from 'utils/permissions';
import { useSelector } from 'react-redux';
import { profileStyle } from '../style';

const DocumentDetails = ({ documentDetails, handleEdit, menuList, handleAttachment, documentOwnerName }) => {
  const theme = useTheme();
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasEditPermission = hasPermission(authorities, ['ROLE_BASE_PERMISSION_INDEX_UPDATE_DOCUMENT']);

  return (
    <Box>
      <Box sx={profileStyle.actionBtn}>
        {!!userHasEditPermission && (
          <Button startIcon={<EditIcon fontSize="2.5rem" />} onClick={handleEdit}>
            Edit
          </Button>
        )}
        <DropdownMenu btnLabel="Action" size="medium" menuLists={menuList} />
      </Box>
      <Box mt={4}>
        <Box sx={profileStyle.otherDivider}>
          <Grid container rowSpacing={2} columnSpacing={4}>
            <Grid item xs={12} sm={6} lg={4}>
              <TitleWithText title="Document Name" text={documentDetails?.documentName} />
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <TitleWithText
                title="Document Description"
                text={documentDetails?.documentDescription ? documentDetails?.documentDescription : '-'}
              />
            </Grid>
          </Grid>

          <Divider sx={{ my: 1, backgroundColor: theme.palette.neutral[200] }} />

          <Grid container rowSpacing={2} columnSpacing={4}>
            <Grid item xs={12} sm={6} lg={4}>
              <TitleWithText title="Document Type" text={documentDetails?.documentType} />
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <TitleWithText title="Created By" text={documentDetails?.creatingEmployee?.fullName} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TitleWithText
                title="Date Created"
                text={validateDateElseHyphen(documentDetails?.dateCreated, 'DD MMMM YYYY')}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <TitleWithText title="Last Modified By" text={documentDetails?.modifiedByEmployee?.fullName} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TitleWithText
                title="Last Modified Date"
                text={validateDateElseHyphen(documentDetails?.dateLastModified, 'DD MMMM YYYY')}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <TitleWithText title="Status" text={documentDetails?.status} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TitleWithText title="Doc Version" text={documentDetails?.docVersion} />
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <TitleWithText title="Document Distribution Type" text={documentDetails?.docOwnerType} />
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <TitleWithText title="Is Current Version" text={documentDetails?.isCurrentVersion ? 'True' : 'False'} />
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <TitleWithText title="Document Owner" text={documentOwnerName} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TitleWithText title="Publication Status" text={documentDetails?.publicationStatus} />
            </Grid>
          </Grid>

          <Divider sx={{ my: 1, mb: 3, backgroundColor: theme.palette.neutral[200] }} />

          <Box>
            <Typographys variant="h6" color="neutral.600">
              Attachment
            </Typographys>
            <Button
              variant="text"
              color="secondary"
              onClick={() => handleAttachment({ id: documentDetails?.id, fileName: documentDetails?.fileName })}
              startIcon={<AttachFileIcon />}
            >
              {documentDetails?.fileName || ''}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

DocumentDetails.defaultProps = {
  documentDetails: [],
  handleEdit: () => {},
  handleAttachment: () => {},
  menuList: [
    { label: 'Submit', onClick: () => {} },
    { label: 'Download', onClick: () => {} },
    { label: 'Unpublish', onClick: () => {} },
    { label: 'Delete', onClick: () => {}, color: 'error.400' },
  ],
  documentOwnerName: '',
};

DocumentDetails.propTypes = {
  handleAttachment: PropTypes.func,
  documentDetails: PropTypes.arrayOf,
  handleEdit: PropTypes.func,
  menuList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  documentOwnerName: PropTypes.string,
};

export default DocumentDetails;
