import { useSelector } from 'react-redux';

import { hasPermission } from 'utils/permissions';

const getActionItems = (services) => {
  const id = services.state.queryDetails?.id;
  return [
    {
      label: 'View',
      onClick: () => services.viewQueryService({ id }),
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_QUER_INDEX_VIEW_QUERY'],
    },
    {
      label: 'Escalate',
      onClick: () => services.escalateQueryNavigationService({ id }),
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_QUER_INDEX_ESCALATE_QUERY'],
    },
    {
      label: 'Modify',
      onClick: () => services.modifyQueryNavigationService({ id }),
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_QUER_INDEX_UPDATE_QUERY'],
      condtional: (row) =>
        row?.resolutionStatus?.toUpperCase() === 'DRAFT' || row?.resolutionStatus?.toUpperCase() === 'REOPENED',
    },
    {
      label: 'Delete',
      onClick: () => () => services.handleOpenDialog(true),
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_QUER_INDEX_DELETE_QUERY'],
      condtional: (row) =>
        row?.resolutionStatus?.toUpperCase() === 'DRAFT' || row?.resolutionStatus?.toUpperCase() === 'REOPENED',
    },
    {
      label: 'Submit',
      onClick: () => services.submitQueryService({ id }),
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_QUER_INDEX_SUBMIT_QUERY'],
      condtional: (row) =>
        row?.resolutionStatus?.toUpperCase() === 'DRAFT' || row?.resolutionStatus?.toUpperCase() === 'REOPENED',
    },
    {
      label: 'Action',
      onClick: () => services.actionQueryNavigationService({ id }),
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_QUER_INDEX_ACTION_QUERY'],
      condtional: (row) => row?.resolutionStatus?.toUpperCase() === 'UNDER REVIEW',
    },
    {
      label: 'Revert',
      onClick: () => services.revertQueryService({ id }),
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_QUER_INDEX_REVERT_QUERY'],
      condtional: (row) => row?.resolutionStatus?.toUpperCase() === 'UNDER REVIEW',
    },
    {
      label: 'Reassign',
      onClick: () => services.reassignQueryNavigationService({ id }),
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_QUER_INDEX_REASSIGN_QUERY'],
      condtional: (row) => row?.resolutionStatus?.toUpperCase() === 'UNDER REVIEW',
    },

    {
      label: 'Reopen',
      onClick: () => services.reopenQueryNavigationService({ id }),
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_QUER_INDEX_REOPEN_QUERY'],
      condtional: (row) =>
        [
          'Closed - Resolved',
          'Closed - Unresolved',
          'Closed - Duplicate',
          'Closed - Rejected',
          'Closed - Incorrect',
        ].includes(row?.resolutionStatus),
    },
  ];
};

const useMenuItems = (services) => {
  const authorities = useSelector((state) => state.session.user.authorities);

  const actions = getActionItems(services);

  const moreFilteredActionItems = actions.filter((action) => {
    const userHasPermission = hasPermission(authorities, action.permissions);
    if ((action.condtional && !action.condtional(services.state.queryDetails?.reportDetails)) || !userHasPermission) {
      return false;
    }
    return true;
  });

  return [moreFilteredActionItems];
};

export default useMenuItems;
