export const DateRangeCalendarStyle = {
  calendarWrapper: {
    zIndex: '1300',
  },

  calendarContent: {
    backgroundColor: 'common.white',
    borderWidth: '0.25rem',
    borderStyle: 'solid',
    borderColor: 'other.200',
    zIndex: '1301',
    margin: '3rem',
    borderRadius: '0.5rem',
    maxWidth: '43.5rem',
    padding: '2.5rem',
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'end',
    mt: 2,
  },

  label: {
    width: 'auto',
    maxWidth: 'max-content',
  },
};
