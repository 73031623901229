export const searchBoxStyle = {
  searchWrapper: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'common.white',
    borderColor: 'neutral.300',
    borderWidth: '0.125rem',
    borderStyle: 'solid',
    borderRadius: '0.5rem',
    maxWidth: '23.5rem',
    pr: 2.25,
    pl: 2,
    boxSizing: 'border-box',

    '& .MuiSvgIcon-root': {
      fontSize: '2.5rem',
      color: 'neutral.600',
    },

    '& .MuiOutlinedInput-root': {
      paddingLeft: 1,

      '& .MuiOutlinedInput-input': {
        fontSize: '1.75rem',
        padding: 0,
        color: 'neutral.700',
        fontWeight: 400,
        lineHeight: 1.6,
        letterSpacing: '0.019rem',
        py: 1.75,

        '&.MuiInputBase-inputSizeSmall': {
          py: 0.87,
        },
      },

      '& .MuiOutlinedInput-input::placeholder': {
        color: 'neutral.600',
        opacity: 1,
      },

      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
  },
  searchFilterWrapper: {
    fontFamily: 'Inter',
    borderColor: 'neutral.300',
    backgroundColor: 'neutral.100',
    borderWidth: '0.125rem',
    borderStyle: 'solid',
    maxWidth: '23.5rem',
    boxSizing: 'border-box',
    fontSize: '1.75rem',
    color: 'neutral.700',
    fontWeight: 400,
    lineHeight: 1.6,
    letterSpacing: '0.019rem',
    position: 'absolute',
    zIndex: 10,
    width: '100%',
  },
};
