import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { updateOpenedMenu } from 'store/slices/theme/customization';

const NavItem = ({ item, level }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const customization = useSelector((state) => state.customization);

  const Icon = item.icon;
  const itemIcon = item.icon ? (
    <Icon stroke={1.5} size="1.3rem" />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
        height: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
      }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  );

  const itemHandler = (id) => {
    dispatch(updateOpenedMenu(id));
  };

  // active menu item on page load
  useEffect(() => {
    const currentIndex = pathname.toString().toLowerCase().includes(item?.url?.toLowerCase());
    // todo -- make sure its not breaking due to item.url
    if (currentIndex) {
      dispatch(updateOpenedMenu(item.id));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <ListItemButton
      component={Link}
      to={item.url}
      sx={{
        mb: 0.5,
        alignItems: 'flex-start',
        backgroundColor: level > 1 ? 'transparent' : 'inherit',
        py: level > 1 ? 1 : 1.25,
        pl: `${level * 3}rem`,
      }}
      selected={customization?.isOpen?.findIndex((id) => id === item.id) > -1}
      onClick={() => itemHandler(item.id)}
    >
      <ListItemIcon
        sx={{
          my: 'auto',
          minWidth: !item?.icon ? 18 : 36,
          color: customization?.isOpen?.findIndex((id) => id === item.id) > -1 ? 'primary.300' : 'neutral.600',
        }}
      >
        {itemIcon}
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            variant="body2"
            color={customization?.isOpen?.findIndex((id) => id === item.id) > -1 ? 'primary.300' : 'neutral.600'}
          >
            {item.title}
          </Typography>
        }
      />
    </ListItemButton>
  );
};

NavItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.node),
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  level: PropTypes.number,
};
NavItem.defaultProps = {
  level: 1,
};

export default NavItem;
