import React, { useEffect, useState } from 'react';
import AttendanceDashboard from 'components/Templates/Dashboard/MyTeam/Attendance';
import { Typography, Skeleton, Box } from '@mui/material';
import { getTeamAttendanceTableRowData } from 'api/attendance';
import { hasPermission } from 'utils/permissions';
import { useSelector } from 'react-redux';
import { useColumns, attendanceGridDataColumn } from './useColumns';
import { getAttendanceTableDataRow, getAttendanceGridDataRow } from './getConfiguredAttendanceData';

const AttendanceDashboardPage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const authorities = useSelector((state) => state.session.user.authorities);

  const menuItems = [
    {
      label: 'Regularize Attendance',
      permissions: ['ROLE_BASE_PERMISSION_ATT_INDEX_BULK_REGULARIZE_ATTENDANCE_EXCEPTIONS'],
      onClick: () => {},
    },
    { label: 'Download Attendance', permissions: [], onClick: () => {} },
  ];

  const actionableMenuItems = menuItems.filter((item) => hasPermission(authorities, item.permissions));

  const [selectedAttendanceStatus, setSelectedAttendanceStatus] = useState('This Week');
  const [attendanceTableDataRow, setAttendanceTableDataRow] = useState([]);

  useEffect(() => {
    setLoading(true);
    let attendanceDetails = [];
    const fetchData = async () => {
      try {
        if (selectedAttendanceStatus === 'This Week') {
          attendanceDetails = await getTeamAttendanceTableRowData('myTeamAttendanceThisWeek');
        }
        if (selectedAttendanceStatus === 'This Month') {
          attendanceDetails = await getTeamAttendanceTableRowData('myTeamAttendanceThisMonth');
        }
        if (selectedAttendanceStatus === 'This Year') {
          attendanceDetails = await getTeamAttendanceTableRowData('myTeamAttendanceThisYear');
        }
        if (attendanceDetails.status === 'SUCCESS') {
          setAttendanceTableDataRow(attendanceDetails?.data);
          setLoading(false);
        } else {
          setError(error);
          setLoading(false);
        }
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedAttendanceStatus]);

  const teamAttendanceTableRowData = getAttendanceTableDataRow(attendanceTableDataRow);
  const attendanceGridDataRow = getAttendanceGridDataRow(attendanceTableDataRow);
  const columns = useColumns(attendanceTableDataRow?.doyDatesMap);

  const attendanceStatusHandleChange = (val) => {
    setSelectedAttendanceStatus(val);
  };

  if (error !== null) {
    return <Typography variant="h6">{error?.message || 'Error while fetching team attendance details.'}</Typography>;
  }

  return loading ? (
    <Box sx={{ p: 4 }}>
      <Skeleton height={70} />
      <Skeleton />
      <Skeleton />
    </Box>
  ) : (
    <AttendanceDashboard
      isStatusUp
      todayAttendanceStatus={false}
      actionMenuLists={actionableMenuItems}
      attendanceStatusHandleChange={attendanceStatusHandleChange}
      attendanceTableLoading={loading}
      attendanceTableDataColumn={columns}
      attendanceTableDataRow={teamAttendanceTableRowData}
      attendanceGridDataColumn={attendanceGridDataColumn}
      attendanceGridDataRow={attendanceGridDataRow}
    />
  );
};

export default AttendanceDashboardPage;
