import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, IconButton } from '@mui/material';
import DropdownMenu from 'components/Molecules/DropdownMenu';
import SearchBox from 'components/Molecules/SearchBox';
import Button from 'components/Atoms/Button';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AdvancedSearchButton from 'components/Molecules/AdvancedSearchButton';
import { filterBarStyle } from './style';

const EmployeeFilterBar = ({ onClickActive, onClickDisable, menuList, handleAdvanceSearch }) => {
  const [advanceSearch, setAdvanceSearch] = useState(false);
  return (
    <Grid container columnSpacing={1} sx={{ display: 'flex', alignItems: 'center' }}>
      <Grid item>
        <Button size="medium" onClick={handleAdvanceSearch}>
          Search
        </Button>
      </Grid>

      <Grid item sx={{ marginLeft: 'auto' }}>
        <DropdownMenu btnLabel="Actions" size="medium" menuLists={menuList} />
      </Grid>
    </Grid>
  );
};

EmployeeFilterBar.defaultProps = {
  onClickActive: () => {},
  onClickDisable: () => {},
  menuList: [
    { label: 'Add New', onClick: () => {} },
    { label: 'Bulk Upload', onClick: () => {} },
    { label: 'Send Bulk ESS Invites', onClick: () => {} },
    { label: 'Download', onClick: () => {} },
  ],
};

EmployeeFilterBar.propTypes = {
  onClickActive: PropTypes.func,
  onClickDisable: PropTypes.func,
  menuList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
};

export default EmployeeFilterBar;
