import React from 'react';
import { Box, Grid, Divider, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Typographys from 'components/Atoms/Typography';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import Button from 'components/Atoms/Button';
import InputField from 'components/Atoms/InputField/index';
import DatePicker from 'components/Atoms/Datepicker';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Attachments from 'components/Atoms/Attachments';
import * as yup from 'yup';
import { Formik, FieldArray, getIn } from 'formik';
import Typography from '@mui/material/Typography';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { profileStyle } from '../../style';

const ProfileEditVisaDetails = ({ handleSubmit, handleCancel, defaultValue, loading }) => {
  const defaultObj = {
    issuingCountry: '',
    placeOfIssue: '',
    validFrom: '',
    validTo: '',
    issueDate: '',
    referenceNumber: '',
    status: '',
    comments: '',
    attachments: '',
    isNew: true,
  };

  const employeeSchema = yup.object().shape({
    visa: yup.array().of(
      yup.object().shape({
        issuingCountry: yup.string().required('Required'),
        validFrom: yup.string().required('Required'),
        validTo: yup.string().required('Required'),
        issueDate: yup.string().required('Required'),
      }),
    ),
  });

  return (
    <Box>
      <Formik
        validationSchema={employeeSchema}
        validateOnMount
        initialValues={defaultValue}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(formik) => <Form formik={formik} handleCancel={handleCancel} defaultObj={defaultObj} loading={loading} />}
      </Formik>
    </Box>
  );
};

const Form = ({ formik, handleCancel, defaultObj, loading }) => {
  const handleDateChange = (date, setFieldValue, label) => {
    setFieldValue(label, date);
  };

  const theme = useTheme();
  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 40rem)' }} mt={2}>
          <FieldArray name="visa">
            {({ push, remove }) => (
              <>
                <Box>
                  {formik.values.visa.map((e, index) => {
                    return (
                      <Box sx={profileStyle.formDivider}>
                        <Box sx={profileStyle.deleteContainer}>
                          <Typography variant="subtitle2" color="neutral.800">
                            Record {index + 1}
                          </Typography>
                          <Button
                            startIcon={<DeleteForeverIcon />}
                            onClick={(event) => {
                              event.preventDefault();
                              remove(index);
                            }}
                            variant="outlined"
                          >
                            Delete
                          </Button>
                        </Box>
                        <Grid container rowSpacing={2} columnSpacing={2} sx={profileStyle.formContainer}>
                          <Grid item xs={12} md={4}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `visa[${index}].issuingCountry`) &&
                                  getIn(formik.errors, `visa[${index}].issuingCountry`),
                              )}
                              helperText={
                                getIn(formik.touched, `visa[${index}].issuingCountry`) &&
                                getIn(formik.errors, `visa[${index}].issuingCountry`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.issuingCountry}
                              name={`visa[${index}].issuingCountry`}
                              id={`visa[${index}].issuingCountry`}
                              label="Issuing Country*"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `visa[${index}].placeOfIssue`) &&
                                  getIn(formik.errors, `visa[${index}].placeOfIssue`),
                              )}
                              helperText={
                                getIn(formik.touched, `visa[${index}].placeOfIssue`) &&
                                getIn(formik.errors, `visa[${index}].placeOfIssue`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e?.placeOfIssue}
                              name={`visa[${index}].placeOfIssue`}
                              id={`visa[${index}].placeOfIssue`}
                              label="Place of Issue"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `visa[${index}].status`) &&
                                  getIn(formik.errors, `visa[${index}].status`),
                              )}
                              helperText={
                                getIn(formik.touched, `visa[${index}].status`) &&
                                getIn(formik.errors, `visa[${index}].status`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e?.status}
                              name={`visa[${index}].status`}
                              id={`visa[${index}].status`}
                              label="Visa Status"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <DatePicker
                              error={
                                Boolean(
                                  getIn(formik.touched, `visa[${index}].validFrom`) &&
                                    getIn(formik.errors, `visa[${index}].validFrom`),
                                ) ||
                                (getIn(formik.touched, `visa[${index}].validFrom`) && !e.validFrom)
                              }
                              helperText={
                                getIn(formik.touched, `visa[${index}].validFrom`) &&
                                getIn(formik.errors, `visa[${index}].validFrom`)
                              }
                              onChange={(date) =>
                                handleDateChange(date, formik.setFieldValue, `visa[${index}].validFrom`)
                              }
                              onBlur={formik.handleBlur}
                              value={e?.validFrom}
                              name={`visa[${index}].validFrom`}
                              id={`visa[${index}].validFrom`}
                              label="Valid From*"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <DatePicker
                              error={
                                Boolean(
                                  getIn(formik.touched, `visa[${index}].validTo`) &&
                                    getIn(formik.errors, `visa[${index}].validTo`),
                                ) ||
                                (getIn(formik.touched, `visa[${index}].validTo`) && !e.validTo)
                              }
                              helperText={
                                getIn(formik.touched, `visa[${index}].validTo`) &&
                                getIn(formik.errors, `visa[${index}].validTo`)
                              }
                              onChange={(date) =>
                                handleDateChange(date, formik.setFieldValue, `visa[${index}].validTo`)
                              }
                              onBlur={formik.handleBlur}
                              minDate={formik?.values?.visa[index].validFrom}
                              value={e?.validTo}
                              name={`visa[${index}].validTo`}
                              id={`visa[${index}].validTo`}
                              label="Valid To*"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <DatePicker
                              error={
                                Boolean(
                                  getIn(formik.touched, `visa[${index}].issueDate`) &&
                                    getIn(formik.errors, `visa[${index}].issueDate`),
                                ) ||
                                (getIn(formik.touched, `visa[${index}].issueDate`) && !e.issueDate)
                              }
                              helperText={
                                getIn(formik.touched, `visa[${index}].issueDate`) &&
                                getIn(formik.errors, `visa[${index}].issueDate`)
                              }
                              onChange={(date) =>
                                handleDateChange(date, formik.setFieldValue, `visa[${index}].issueDate`)
                              }
                              onBlur={formik.handleBlur}
                              maxDate={formik?.values?.visa[index].validFrom}
                              value={e?.issueDate}
                              name={`visa[${index}].issueDate`}
                              id={`visa[${index}].issueDate`}
                              label="Issue Date*"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `visa[${index}].referenceNumber`) &&
                                  getIn(formik.errors, `visa[${index}].referenceNumber`),
                              )}
                              helperText={
                                getIn(formik.touched, `visa[${index}].referenceNumber`) &&
                                getIn(formik.errors, `visa[${index}].referenceNumber`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e?.referenceNumber}
                              name={`visa[${index}].referenceNumber`}
                              id={`visa[${index}].referenceNumber`}
                              label="Reference Number"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `visa[${index}].comments`) &&
                                  getIn(formik.errors, `visa[${index}].comments`),
                              )}
                              helperText={
                                getIn(formik.touched, `visa[${index}].comments`) &&
                                getIn(formik.errors, `visa[${index}].comments`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e?.comments}
                              name={`visa[${index}].comments`}
                              id={`visa[${index}].comments`}
                              label="Comments"
                              fullWidth
                              multiline
                            />
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <Attachments
                              isDeleteIcon
                              handleChange={(event) => {
                                formik.setFieldValue(`visa[${index}].attachments`, event.target.files[0]);
                              }}
                              name={`visa[${index}].attachments`}
                              id={`visa[${index}].attachments`}
                              value={e?.attachments}
                              handleDelete={() => {
                                formik.setFieldValue(`visa[${index}].attachments`, '');
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={8} mt={2}>
                            {e?.attachment && (
                              <Typographys variant="caption" color="neutral.600">
                                Previously Associated Attachment: {e?.attachment}
                              </Typographys>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })}
                </Box>

                <Box sx={{ mt: 3 }}>
                  <Button
                    size="medium"
                    variant="outlined"
                    type="button"
                    startIcon={<AddOutlinedIcon />}
                    onClick={() => push(defaultObj)}
                  >
                    Add Visa Record
                  </Button>
                </Box>
              </>
            )}
          </FieldArray>
        </Box>
      </PerfectScrollbar>

      <Box sx={profileStyle.btnContainer}>
        <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

        <Box sx={profileStyle.submitWrapper}>
          <Button
            size="large"
            color="info"
            variant="outlined"
            type="cancel"
            onClick={() => {
              handleCancel();
            }}
          >
            Cancel
          </Button>
          <LoadingButton loading={loading} color="primary" size="large" type="submit" variant="contained">
            Update
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

Form.defaultProps = {
  handleCancel: () => {},
  formik: {},
  defaultObj: {},
  loading: false,
};

Form.propTypes = {
  handleCancel: PropTypes.func,
  formik: PropTypes.shape({
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    values: PropTypes.shape({
      visa: PropTypes.shape([]),
    }),
    resetForm: PropTypes.func,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    setFieldValue: PropTypes.func,
  }),
  defaultObj: PropTypes.shape({}),
  loading: PropTypes.bool,
};

ProfileEditVisaDetails.defaultProps = {
  handleSubmit: () => {},
  handleCancel: () => {},
  defaultValue: {
    visa: [
      {
        placeOfIssue: '',
        validFrom: '',
        validTo: '',
        issueDate: '',
        referenceNumber: '',
        status: '',
        comments: '',
        attachment: '',
      },
    ],
  },
  loading: false,
};

ProfileEditVisaDetails.propTypes = {
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,

  defaultValue: PropTypes.shape({}),
  loading: PropTypes.bool,
};

export default ProfileEditVisaDetails;
