import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import TitleWithText from 'components/Atoms/TitleWithText';
import Typographys from 'components/Atoms/Typography';
import { additionalDetailStyle } from '../style';

const KeyEventDetail = ({ keyEventDetails }) => {
  return (
    <>
      <Typographys variant="h5" color="neutral.700">
        Key Life Event Details
      </Typographys>
      <Box mt={2} sx={additionalDetailStyle.rowDivider}>
        {keyEventDetails.map((keyEventList) => (
          <Grid container rowSpacing={1} columnSpacing={2}>
            <Grid item xs={12} sm={4}>
              <TitleWithText title="Key Life Event Details" text={keyEventList.keyEventDetail} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TitleWithText title="Event Date" text={keyEventList.eventDate} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TitleWithText title="Comments" text={keyEventList.comments} />
            </Grid>
          </Grid>
        ))}
      </Box>
    </>
  );
};

KeyEventDetail.defaultProps = {
  keyEventDetails: [],
};

KeyEventDetail.propTypes = {
  keyEventDetails: PropTypes.arrayOf(
    PropTypes.shape({
      keyEventDetail: PropTypes.string,
      eventDate: PropTypes.string,
      comments: PropTypes.string,
    }),
  ),
};
export default KeyEventDetail;
