const getActionItems = (services) => {
  return [
    {
      name: 'Download',
      onClick: services.downloadDocument,
      icon: '',
      permissions: [],
    },
  ];
};

const useDownloadAction = (services) => {
  const actions = getActionItems(services);

  const handleActionItemClick = () => {
    return actions;
  };

  return [handleActionItemClick];
};

export default useDownloadAction;
