export const stepperStyles = {
  stepButton: {
    '& .MuiStepLabel-label': {
      color: 'neutral.400',
      fontSize: '1.5rem',
      textTransform: 'uppercase',
      fontWeight: 600,
    },

    '& .MuiStepLabel-label.Mui-active': {
      color: 'secondary.600',
      fontWeight: 600,
    },

    '& .MuiStepLabel-label.Mui-completed': {
      color: 'secondary.600',
      fontWeight: 600,
    },
  },

  '& .MuiStepConnector-root': {
    minWidth: '2.5rem',
  },

  '& .MuiStepConnector-line': {
    borderTopWidth: '0.250rem',
    borderColor: 'neutral.400',
  },

  '& .MuiStepConnector-root.Mui-active .MuiStepConnector-line': {
    borderColor: 'secondary.600',
  },

  '& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line': {
    borderColor: 'secondary.600',
  },

  wrapper: {
    borderColor: 'neutral.300',
    marginTop: '1.5rem',
    overFlow: 'hidden',
  },

  stepWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },

  stepBtnWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 2,
    '& .MuiButton-root': {
      minWidth: '12.5rem',
    },
  },

  submitButtons: {
    display: 'flex',
    gap: 2,
    justifyContent: 'end',
    flex: 1,
  },

  contentWrapper: {
    marginTop: '3rem',
  },

  btnWrapper: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%',
    backgroundColor: 'common.white',
    zIndex: '9',
    margin: '0rem',
    padding: '0rem 3rem 2rem 3rem',
    boxSizing: 'border-box',
  },
};
