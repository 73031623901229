import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, FormControl, FormHelperText } from '@mui/material';

const CheckBox = ({ label, name, color, size, onChange, checked, error, helperText, disabled, ...other }) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);
  const handleChange = (event) => {
    setIsChecked(event.target.checked);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <FormControl>
      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked}
            onChange={handleChange}
            name={name}
            color={color}
            size={size}
            disabled={disabled}
            error={!!error}
            {...other}
          />
        }
        label={label && label}
      />
      {error && helperText && <FormHelperText>{helperText} </FormHelperText>}
    </FormControl>
  );
};

CheckBox.defaultProps = {
  label: '',
  name: 'name',
  color: 'info',
  size: 'medium',
  checked: false,
  onChange: () => {},
  disabled: false,
  error: false,
  helperText: '',
};

CheckBox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

export default CheckBox;
