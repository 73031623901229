export const policyGraphStyle = {
  wrapper: {
    borderWidth: '0.125rem',
    borderStyle: 'solid',
    borderColor: 'neutral.300',
    borderRadius: '0.75rem',
    padding: '3rem 4.5rem',
    color: 'actions.600',

    '@media screen and (max-width: 767px)': {
      '& .MuiGrid-grid-xs-12.MuiGrid-grid-sm-2': {
        justifyContent: 'center',
        display: 'flex',
        marginBottom: '2rem',
      },

      '& .MuiTypography-h1': {
        fontSize: '4rem',
      },

      '& .MuiTypography-h6': {
        fontSize: '1.75rem',
        fontWeight: '400',
      },
    },
  },

  inviteCard: {
    display: 'flex',
    justifyContent: 'flex-start',
  },

  pendingCard: {
    borderWidth: '0 0.125rem',
    borderStyle: 'solid',
    borderColor: 'other.200',
  },
  onboardingCard: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};
