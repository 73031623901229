import { validateDateElseHyphen, validateNumberElseHyphen } from 'utils/commonUtils';

const columns = [
  {
    fieldName: 'expenseCategory',
    name: '',
    label: 'Expense Category',
    formatValue: ({ row }) => row?.expenseCategory?.displayName,
  },
  {
    fieldName: 'billNumber',
    name: '',
    label: 'Bill Number',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'merchant',
    name: '',
    label: 'Vendor',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'expensedDate',
    name: '',
    label: 'Date',
    formatValue: ({ row, column }) => validateDateElseHyphen(row[column.fieldName], 'DD MMMM YYYY'),
  },
  {
    fieldName: 'expensedCurrency',
    name: '',
    label: 'Expensed Currency',
    formatValue: ({ row }) => row?.expensedCurrency?.isoCode,
  },
  {
    fieldName: 'expensedAmount',
    name: '',
    label: 'Expensed Amount',
    formatValue: ({ row, column }) =>
      `${row?.expensedCurrency?.symbol || ''} ${validateNumberElseHyphen(row[column.fieldName])}`,
  },
  {
    fieldName: 'expensedExchangeRate',
    name: '',
    label: 'Exchange Rate',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'mileageStart',
    name: '',
    label: 'Mileage Start',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'mileageEnd',
    name: '',
    label: 'Mileage End',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'paymentMethod',
    name: '',
    label: 'Payment Method',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'isReimburseable',
    name: '',
    label: 'To Be Reimbursed',
    formatValue: ({ row, column }) => (row[column.fieldName] ? 'True' : 'False'),
  },
];

const useColumns = () => {
  return [columns];
};

export default useColumns;
