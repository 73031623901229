import dayjs from 'dayjs';
import { isObject, isEmpty } from 'lodash';

const getDateformat = (val) => (val ? dayjs(val).format('DD/MMM/YY') : '-');

const getAttendanceTableDataRow = (attendanceRecords) => {
  const { empIDNameMap = [], empDBIDOrgIDMap = [], doyDatesMap = [], doyAttRec = [] } = attendanceRecords;
  const nameAndPeriod =
    isObject(empIDNameMap) && !isEmpty(empIDNameMap)
      ? Object.entries(empIDNameMap).map((item) => {
          const empDBIDOrgID = empDBIDOrgIDMap[item[0]];
          const mergedItem = {
            empName: item[1] || '',
            empImgSrc: 'images/employeeAvatar.svg',
            status: '-',
            empId: empDBIDOrgID || '',
            period: `${getDateformat(attendanceRecords?.startDate)} - ${getDateformat(attendanceRecords?.endDate)}`,
          };

          if (isObject(doyDatesMap) && !isEmpty(doyDatesMap)) {
            Object.entries(doyDatesMap).map(([key], index) => {
              return Object.assign(mergedItem, { [`day${index + 1}`]: doyAttRec[key] ? 'Present' : '-' });
            });
          }
          return mergedItem || {};
        })
      : [];
  return nameAndPeriod;
};

const listDateformat = (val) => (val ? dayjs(val).format('dddd, MMMM D, YYYY') : '-');

const getListDaysData = (attendanceRecords, empID) => {
  const {
    doyDatesMap = [],
    doyAttRec = {},
    doyTimeInDayMap = {},
    doyTimeOutDayMap = {},
    doyDurationMap = {},
    doyRunningDurationMap = {},
  } = attendanceRecords;
  return isObject(doyDatesMap) && !isEmpty(doyDatesMap)
    ? Object.entries(doyDatesMap).map(([key, value]) => {
        return {
          day: listDateformat(value),
          firstTimeIn: doyTimeInDayMap[key] ? doyTimeInDayMap[key][empID] : '-',
          lastTimeOut: doyTimeOutDayMap[key] ? doyTimeOutDayMap[key][empID] : '-',
          duration: doyDurationMap[key] ? doyDurationMap[key][empID] : '-',
          interimPunches: doyRunningDurationMap[key] ? doyRunningDurationMap[key][empID] : '-',
          attendance: doyAttRec[key] ? doyAttRec[key][empID]?.attendanceState : '-',
          exception: doyAttRec[key] ? doyAttRec[key][empID]?.exception : 'No Records',
          attendanceMarking: doyAttRec[key] ? doyAttRec[key][empID]?.regularizationComments : '-',
        };
      })
    : [];
};

const getAttendanceGridDataRow = (attendanceRecords) => {
  const { empIDNameMap = [], empDBIDOrgIDMap = [] } = attendanceRecords;
  return isObject(empIDNameMap) && !isEmpty(empIDNameMap)
    ? Object.entries(empIDNameMap).map((item) => {
        const empDBIDOrgID = empDBIDOrgIDMap[item[0]];
        const mergedItem = {
          empName: item[1] || '',
          empImgSrc: 'images/employeeAvatar.svg',
          status: '-',
          empId: empDBIDOrgID || '',
          filterDate: `${getDateformat(attendanceRecords?.startDate)} - ${getDateformat(attendanceRecords?.endDate)}`,
          days: getListDaysData(attendanceRecords, item[0]),
        };
        return mergedItem || {};
      })
    : [];
};

export { getAttendanceTableDataRow, getAttendanceGridDataRow };
