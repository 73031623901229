import { isEmpty } from 'lodash';

export const formatOptions = (response) => {
  const {
    maritalStatus,
    bloodGroupTypes,
    genderOptions,
    onboardingRefTypes,
    relationOptions,
    transactionTypes,
    currenciesList,
    accountTypes,
    lifeEventOptions,
    skillLevelOptions,
    bankPurposeOptions,
    locationOptions,
    relationshipMap,
    employeeProfileInstance,
  } = response.data;

  return {
    BasicProfile: {
      maritalStatusOptions: maritalStatus ? Object.values(maritalStatus).map((item) => ({ id: item, name: item })) : [],
      bloodGroupOptions: bloodGroupTypes
        ? Object.values(bloodGroupTypes).map((item) => ({ id: item, name: item }))
        : [],
      locationOptions: locationOptions?.length
        ? locationOptions?.map((item) => ({ id: item.name, name: item.name }))
        : [],
      genderOptions: genderOptions ? Object.values(genderOptions).map((item) => ({ id: item, name: item })) : [],
      onboardingReferenceOptions: onboardingRefTypes
        ? Object.values(onboardingRefTypes).map((item) => ({ id: item, name: item }))
        : [],
    },

    Dependent: {
      genderOptions: genderOptions ? Object.values(genderOptions).map((item) => ({ id: item, name: item })) : [],

      relationOptions: relationshipMap ? Object.values(relationshipMap).map((item) => ({ id: item, name: item })) : [],
    },

    BankAccount: {
      transactionTypeOptions: transactionTypes
        ? Object.values(transactionTypes).map((item) => ({ id: item, name: item }))
        : [],

      accountCurrencyOptions: currenciesList?.length
        ? currenciesList?.map((item) => ({ id: item.name, name: item.name }))
        : [],

      purposeOptions: bankPurposeOptions
        ? Object.values(bankPurposeOptions)?.map((item) => ({ id: item, name: item }))
        : [],

      accountTypeOptions: accountTypes ? Object.values(accountTypes).map((item) => ({ id: item, name: item })) : [],
    },

    AdditionalDetail: {
      skillOptions: response?.data?.skills?.length
        ? response?.data?.skills.map((item) => ({ id: item.id, name: item.fullName }))
        : [],

      skillLevelOptions: skillLevelOptions
        ? Object.values(skillLevelOptions).map((item) => ({ id: item, name: item }))
        : [],

      lifeEventOptions: lifeEventOptions
        ? Object.values(lifeEventOptions).map((item) => ({ id: item, name: item }))
        : [],
    },
  };
};
