import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import BlankTemplate from 'components/Templates/BlankTemplate';
import ProfileLifeEventDetails from 'components/Organisms/Profile/LifeEventDetails';
import { useThunk } from 'hooks/useThunk';
import { fetchLifeEventDetails } from 'store';
import { useNavigate } from 'react-router-dom';
import { Box, Skeleton, Typography } from '@mui/material';

const ProfileLifeEventDetailsPage = () => {
  const navigate = useNavigate();
  const [getLifeEventDetails, isLoadingLifeEventDetails, loadingLifeEventDetailsError] =
    useThunk(fetchLifeEventDetails);

  useEffect(() => {
    const query = new URLSearchParams({});
    getLifeEventDetails(query);
  }, [getLifeEventDetails]);

  const { lifeEventDetails } = useSelector((state) => {
    return state.profile;
  });

  const handleEdit = () => {
    navigate(`/profile/life-event-details/edit`);
  };

  if (loadingLifeEventDetailsError !== null) {
    return (
      <Typography variant="h6">
        {loadingLifeEventDetailsError?.message || 'Error while loading life event details.'}
      </Typography>
    );
  }

  return isLoadingLifeEventDetails ? (
    <Box sx={{ p: 4 }}>
      <Skeleton height={70} />
      <Skeleton />
      <Skeleton />
    </Box>
  ) : (
    <BlankTemplate pageHeading="LifeEvent Details" isBreadcrumb={false} padding="2rem 3rem">
      <ProfileLifeEventDetails lifeEventDetails={lifeEventDetails} handleEdit={handleEdit} />
    </BlankTemplate>
  );
};

export default ProfileLifeEventDetailsPage;
