import * as React from 'react';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import Typographys from 'components/Atoms/Typography';
import { Box } from '@mui/material';
import { CompareTDSStyle } from 'components/Organisms/MyPay/CompareTDS/style';
import { validateNumberElseHyphen } from '../../../../../utils/commonUtils';

const HraTable = ({ hraData, basicPerc }) => {
  return (
    <Paper sx={CompareTDSStyle.hraTableWrapper}>
      <Box sx={{ display: 'flex', p: '12px 8px', backgroundColor: 'actions.100' }}>
        <Box sx={{ flexBasis: '20%' }}>
          <Typographys variant="body2">HRA Received</Typographys>
        </Box>
        <Box sx={{ flexBasis: '20%' }}>
          <Typographys variant="body2">{basicPerc}% of Basic</Typographys>
        </Box>
        <Box sx={{ flexBasis: '20%' }}>
          <Typographys variant="body2">{`Rent paid >10% Basic`}</Typographys>
        </Box>
        <Box sx={{ flexBasis: '40%' }} />
      </Box>

      {hraData.map((data) => (
        <Box key={data.received} sx={CompareTDSStyle.hraTableRow}>
          <Box sx={{ width: '20%' }}>
            <Typographys variant="body2" color="neutral.600">
              {data.received === '-' ? '0' : data.received}
            </Typographys>
          </Box>
          <Box sx={{ width: '20%' }}>
            <Typographys variant="body2" color="neutral.600">
              {validateNumberElseHyphen(data.basic)}
            </Typographys>
          </Box>
          <Box sx={{ width: '20%' }}>
            <Typographys variant="body2" color="neutral.600">
              {data.rentPaid === '-' ? '0' : data.rentPaid}
            </Typographys>
          </Box>
          <Box sx={{ width: '40%' }} />
        </Box>
      ))}
    </Paper>
  );
};

HraTable.defaultProps = {
  hraData: [],
  basicPerc: 50,
};

HraTable.propTypes = {
  hraData: PropTypes.arrayOf(
    PropTypes.shape({
      received: PropTypes.string,
      basic: PropTypes.string,
      rentPaid: PropTypes.string,
    }),
  ),
  basicPerc: PropTypes.string,
};
export default HraTable;
