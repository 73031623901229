import { api, formDataApi } from 'api/api';

export const listMyLeaveRequests = async (query) => {
  const response = await api.post(`restLeaveIndex/listMyLeaveRequests?${query.toString()}`);
  return response?.data;
};

export const listRequestPendingMyAction = async (query) => {
  const response = await api.post(`restLeaveIndex/listRequestPendingMyAction?${query.toString()}`);
  return response?.data;
};

export const listMyLeaveRequestsAction = async (formData) => {
  const response = await api.post(`restLeaveIndex/listMyLeaveRequestsAction`, formData);
  return response?.data;
};

export const displayMyLeaveAccounts = async (formData) => {
  const response = await api.post(`restLeaveIndex/displayMyLeaveAccounts`, formData);
  return response?.data;
};

export const saveMyLeaveRequest = async (formData) => {
  const response = await formDataApi.post(`restLeaveIndex/saveMyLeaveRequest`, formData);
  return response;
};

export const displayLeaveRequest = async (formData) => {
  const response = await api.post(`restLeaveIndex/displayLeaveRequest`, formData);
  return response?.data;
};

export const modifyLeaveRequestAction = async (formData) => {
  const response = await formDataApi.post(`restLeaveIndex/modifyLeaveRequestAction`, formData);
  return response;
};

export const deleteLeaveRequest = async (formData) => {
  const response = await formDataApi.post(`restLeaveIndex/deleteLeaveRequest`, formData);
  return response;
};

export const submitLeaveRequest = async (formData) => {
  const response = await formDataApi.post(`restLeaveIndex/submitLeaveRequest`, formData);
  return response;
};

export const approveLeaveRequest = async (formData) => {
  const response = await formDataApi.post(`restLeaveIndex/approveLeaveRequestAction`, formData);
  return response;
};

export const rejectLeaveRequest = async (formData) => {
  const response = await formDataApi.post(`restLeaveIndex/rejectLeaveRequestAction`, formData);
  return response;
};

export const revertToDraftLeaveRequest = async (formData) => {
  const response = await formDataApi.post(`restLeaveIndex/revertToDraftLeaveRequestAction`, formData);
  return response;
};

export const getLeavesCreateData = async () => {
  const response = await formDataApi.get(`restLeaveIndex/myNewLeaveRequest`);
  return response;
};

export const getModifyLeaveRequest = async (bodyFormData) => {
  const response = await formDataApi.post('restLeaveIndex/modifyLeaveRequest', bodyFormData);
  return response;
};
