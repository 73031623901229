import React from 'react';
import PropTypes from 'prop-types';
import { Box, Paper } from '@mui/material';
import MuiTab from 'components/Molecules/Tab';
import announcementsIcon from 'assets/icons/announcements.svg';
import surveysIcon from 'assets/icons/surveys.svg';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import AnnouncementsTab from './AnnouncementsTab';
import { homeDashboardStyle } from '../style';

const SurveysAndAnnouncementsCard = ({
  surveysList,
  announcementList,
  handleDetails,
  loadingAnnouncementDetails,
  loadingAnnouncementDetailsError,
}) => {
  const isSurveyCompleted = (survey) => {
    const today = dayjs();
    const surveyEndDate = dayjs(survey?.endDate, 'DD-MMM-YYYY HH:mm:ss');
    return surveyEndDate.isBefore(today);
  };

  const checkAllSurveysCompleted = () => {
    const allCompleted = surveysList.every(isSurveyCompleted);
    return allCompleted ? 0 : 1;
  };

  const completedStatus = surveysList?.length > 0 && announcementList?.length > 0 && checkAllSurveysCompleted();

  const initialTabs = [
    {
      label: <>Surveys {surveysList.find((survey) => survey.unRead) && <Box sx={homeDashboardStyle.dot} />}</>,
      RenderComponent: AnnouncementsTab,
      componentProps: {
        iconSrc: surveysIcon,
        lists: surveysList,
        handleDetails,
      },
    },
    {
      label: (
        <>
          Announcements{' '}
          {announcementList.find((announcement) => announcement.unRead) && <Box sx={homeDashboardStyle.dot} />}
        </>
      ),
      RenderComponent: AnnouncementsTab,
      componentProps: {
        iconSrc: announcementsIcon,
        lists: announcementList,
        handleDetails,
        isAnnouncements: true,
        loadingAnnouncementDetails,
        loadingAnnouncementDetailsError,
      },
    },
  ];

  const selectedTabCondition =
    // eslint-disable-next-line no-nested-ternary
    isEmpty(surveysList) && isEmpty(announcementList) ? 1 : announcementList?.length > 0 ? 1 : 0;

  return (
    <Paper variant="outline" sx={{ border: '0.125rem solid', borderColor: 'neutral.300', padding: '0' }}>
      <Box sx={{ minHeight: '64.75rem', maxHeight: '64.75rem' }}>
        <MuiTab
          variant="scrollable"
          textColor="primary"
          indicatorColor="primary"
          panelPadding="2rem"
          tabs={initialTabs}
          selectedTab={completedStatus ? 0 : selectedTabCondition}
        />
      </Box>
    </Paper>
  );
};

SurveysAndAnnouncementsCard.defaultProps = {
  surveysList: [],
  announcementList: [],
  handleDetails: () => {},
  loadingAnnouncementDetails: false,
  loadingAnnouncementDetailsError: {
    message: '',
  },
};

SurveysAndAnnouncementsCard.propTypes = {
  surveysList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      desc: PropTypes.string,
      time: PropTypes.string,
      unRead: PropTypes.bool,
    }),
  ),
  announcementList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      desc: PropTypes.string,
      time: PropTypes.string,
      unRead: PropTypes.bool,
    }),
  ),
  handleDetails: PropTypes.func,
  loadingAnnouncementDetails: PropTypes.bool,
  loadingAnnouncementDetailsError: PropTypes.shape({
    message: PropTypes.string,
  }),
};

export default SurveysAndAnnouncementsCard;
