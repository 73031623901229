import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Divider, Grid, List, ListItem, ListItemText } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useTheme } from '@mui/material/styles';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { myPayStyle, Form12BBStyle } from '../style';
import ClaimsDetails from './ClaimsDetails';

const Form12BB = ({ employeeData, claimsDetailsData, landlordInfo, handlePrint }, ref) => {
  const theme = useTheme();

  let claimDetailsAsPerTaxRegimeJSX = '';
  if (employeeData?.taxRegime === 'old') {
    claimDetailsAsPerTaxRegimeJSX = (
      <>
        <Box>
          <Typographys variant="body2" color="neutral.600">
            I,
            <Typographys color="neutral.800" component="span">
              {employeeData?.fullName}
            </Typographys>
            , do hereby certify that following amount have been paid by me directly during the financial year{' '}
            {employeeData?.financialYear}, which qualify for exemption/deduction under Income Tax Act 1961. I enclose
            herewith xerox copies of receipts/Certificates/ and other relevant proofs.
          </Typographys>
          <Divider
            sx={{
              mt: '.75rem',
              mb: 3,
              backgroundColor: theme.palette.neutral[200],
            }}
          />
        </Box>
        <Box>
          <ClaimsDetails claimsDetailsData={claimsDetailsData} landlordInfo={landlordInfo} />
        </Box>
      </>
    );
  } else if (employeeData?.taxRegime === 'new') {
    claimDetailsAsPerTaxRegimeJSX = (
      <Box mb={4.5}>
        <Typographys variant="body1" color="neutral.800">
          I, {employeeData?.fullName}, wish to exercise the option to make TDS at concessional rates under section{' '}
          <strong>115BAC </strong>
          from my Salaries <strong>without</strong> claiming any deduction under chapter VIA (like 80C for PF,LIC etc,
          80 D, 80 DD, 80E, 80U, interest on housing loan, exempt HRA/LTA) and Standard deduction/Prof. tax on
          employment.
        </Typographys>
      </Box>
    );
  }

  return (
    <>
      <Box sx={Form12BBStyle.noteListWrapper}>
        <Typographys variant="h5" component="div" sx={{ mb: '10px' }}>
          Note:
        </Typographys>
        <List>
          <ListItem>
            <ListItemText
              primary={
                <Typographys variant="body1">
                  <b>i.</b> Please make sure you have uploaded your flexible benefit proofs (If applicable) before
                  generating Form 12BB.
                </Typographys>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Typographys variant="body1">
                  <b>ii.</b> You can download your Form 12BB after generation and sign it.
                </Typographys>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Typographys variant="body1">
                  <b>iii.</b> To upload the signed Form 12BB, go to My Pay &gt; Form 12BB &gt; Upload Form 12BB.
                </Typographys>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Typographys variant="body1">
                  <b>iv.</b> FORM 12BB upload is mandatory for approving investment proof submissions.
                </Typographys>
              }
            />
          </ListItem>
        </List>
      </Box>
      <Box mt={2} mb={9} className="form12bb" ref={ref}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            <Typographys variant="h6" color="neutral.800">
              Form 12BB
            </Typographys>
            <Typographys variant="body2" color="neutral.600">
              (See rule 26C)
            </Typographys>
          </Box>
          <Box className="no-print">
            <Button
              color="secondary"
              size="large"
              type="submit"
              variant="contained"
              startIcon={<LocalPrintshopIcon />}
              onClick={handlePrint}
            >
              Print
            </Button>
          </Box>
        </Box>
        <Box mt={4}>
          <Typographys variant="h6" color="neutral.800" align="center">
            Statement showing particulars of claims by an employee for deduction of tax under section 192
          </Typographys>
        </Box>
        <Box sx={myPayStyle.tableWrapper}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell valign="top">
                  <Typographys variant="body2">1</Typographys>
                </TableCell>
                <TableCell valign="top">
                  <Typographys variant="body2">Name and address of employee</Typographys>
                </TableCell>
                <TableCell valign="top">
                  <Typographys variant="body2">{employeeData?.fullName}</Typographys>
                  <Typographys variant="body2">{employeeData?.fullAddress}</Typographys>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typographys variant="body2">2</Typographys>
                </TableCell>
                <TableCell>
                  <Typographys variant="body2">Permanent Account Number (PAN) of employee</Typographys>
                </TableCell>
                <TableCell>
                  <Typographys variant="body2">{employeeData?.pan}</Typographys>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typographys variant="body2">3</Typographys>
                </TableCell>
                <TableCell>
                  <Typographys variant="body2">Financial Year</Typographys>
                </TableCell>
                <TableCell>
                  <Typographys variant="body2">{employeeData?.financialYear}</Typographys>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
        {claimDetailsAsPerTaxRegimeJSX}
        <Box my={2}>
          <Typographys variant="h6" color="neutral.800" align="center">
            VERIFICATION
          </Typographys>
        </Box>
        <Box mb={4.5}>
          <Typographys variant="body1" color="neutral.800">
            I, {employeeData?.fullName}, son/daughter/wife ________________________ of{' '}
            {employeeData?.fatherOrHusbandsName} do hereby certify that the information given above is complete and
            correct.
          </Typographys>
        </Box>
        <Box>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box mb={1.5}>
                <Typographys variant="body1" color="neutral.800">
                  Place :
                </Typographys>
              </Box>
              <Typographys variant="body1" color="neutral.800">
                Full Name: {employeeData?.fullName}
              </Typographys>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mb={1.5}>
                <Typographys variant="body1" color="neutral.800">
                  Date :
                </Typographys>
              </Box>
              <Typographys variant="body1" color="neutral.800">
                Designation : {employeeData?.designation}
              </Typographys>
            </Grid>
          </Grid>
        </Box>
        <Box mt={9} display="inline-block">
          <Divider
            sx={{
              mb: 0.5,
              backgroundColor: theme.palette.neutral[200],
              width: 'auto',
            }}
          />
          <Typographys variant="caption" color="neutral.800">
            (Signature of the employee)
          </Typographys>
        </Box>
        <Box />
      </Box>
    </>
  );
};

Form12BB.defaultProps = {
  employeeData: {},
  claimsDetailsData: {},
  landlordInfo: [],
  handlePrint: () => {},
};

Form12BB.propTypes = {
  employeeData: {},
  claimsDetailsData: PropTypes.shape,
  landlordInfo: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
  ),
  handlePrint: PropTypes.func,
};

export default forwardRef(Form12BB);
