import React from 'react';
import PropTypes from 'prop-types';
import EmployeeCommonCard from 'components/Organisms/Employee/EmployeeCommonCard';
import ProfileListItem from 'components/Molecules/ProfileListItem';
import { useTheme } from '@mui/material/styles';
import { Box } from '@material-ui/core';

const ExitsCard = ({ exitNum, exitProfileLists, exitFilterOptions, exitFilterHandleChange }) => {
  const theme = useTheme();
  return (
    <EmployeeCommonCard
      cardHeaderTitle={`Exits (${exitNum})`}
      headerFilterId="exitFilter"
      headerFilterLabel="Week"
      headerFilterOptions={exitFilterOptions}
      headerFilterHandleChange={exitFilterHandleChange}
      cardBodyMinHeight="40rem"
      bodyContent={exitProfileLists.map((list) => (
        <Box padding="1.25rem 2rem" border="0.125rem solid" borderColor={theme.palette.neutral[200]} borderTop="none">
          <ProfileListItem
            userName={list.userName}
            designation={list.designation}
            avatarSrc={list.avatarSrc}
            date={list.date}
          />
        </Box>
      ))}
      footerLink="#"
      footerLinkLabel="View Details"
    />
  );
};

ExitsCard.defaultProps = {
  exitNum: 6,
  exitProfileLists: [],
  exitFilterOptions: [],
  exitFilterHandleChange: () => {},
};
ExitsCard.propTypes = {
  exitNum: PropTypes.number,
  exitProfileLists: PropTypes.arrayOf,
  exitFilterOptions: PropTypes.arrayOf,
  exitFilterHandleChange: PropTypes.func,
};

export default ExitsCard;
