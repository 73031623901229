import React from 'react';
import { Chip, Typography } from '@mui/material';
import { validateDateElseHyphen } from 'utils/commonUtils';

const columns = [
  {
    fieldName: 'leaveType',
    name: '',
    label: 'Employee ID',
    formatValue: ({ row, column }) => <Typography variant="body2">{row?.employee?.id}</Typography>,
  },
  {
    fieldName: 'leaveType',
    name: '',
    label: 'Employee',
    formatValue: ({ row, column }) => <Typography variant="body2">{row?.employee?.fullName}</Typography>,
  },
  {
    fieldName: 'displayName',
    name: '',
    label: 'Leave Category',
    formatValue: ({ row, column }) => <Typography variant="body2">{row?.leaveCategory?.displayName}</Typography>,
  },
  {
    fieldName: 'periodStartDate',
    name: '',
    label: 'Period Start Date',
    formatValue: ({ row, column }) => (
      <Typography variant="body2">{validateDateElseHyphen(row[column.fieldName], 'DD MMMM, YYYY h:mm A')}</Typography>
    ),
  },
  {
    fieldName: 'periodEndDate',
    name: '',
    label: 'Period End Date',
    formatValue: ({ row, column }) => (
      <Typography variant="body2">{validateDateElseHyphen(row[column.fieldName], 'DD MMMM, YYYY h:mm A')}</Typography>
    ),
  },
  {
    fieldName: 'periodOpeningBalance',
    name: '',
    label: 'Period Opening Balance',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'leavesAccrued',
    name: '',
    label: 'Leaves Accrued',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'leavesAvailed',
    name: '',
    label: 'Leaves Availed',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'pendingApprovalBalance',
    name: '',
    label: 'Pending Approval',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'currentBalance',
    name: '',
    label: 'Current Balance',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
];

const useColumns = () => {
  return [columns];
};

export default useColumns;
