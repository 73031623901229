import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import MuiTab from 'components/Molecules/Tab';
import GraphWithComponentDetail from 'components/Organisms/GraphWithComponentDetail';
import GraphColor from 'utils/GraphColor';
import EmployeeMapped from './Mapped';
import { policyListTabStyle } from '../style';
import EmployeeUnmapped from './Unmapped';

const EmployeeMappingTab = ({
  empMappingGraphData,
  empMappedFilterFields,
  empMappedHandleFilter,
  empMappedRows,
  empMappedTotalRecordCount,
  empMappedLoadNextPageData,
  empMappedHandleSort,
  empMappedHandleRowClick,
  empMappedPage,
  empMappedSortBy,
  empMappedSortDirection,
  empMappedHandleCancel,
  empMappedHandleApplyChanges,
  empUnMappedFilterFields,
  empUnMappedHandleFilter,
  empUnMappedRows,
  empUnMappedTotalRecordCount,
  empUnMappedLoadNextPageData,
  empUnMappedHandleSort,
  empUnMappedHandleRowClick,
  empUnMappedPage,
  empUnMappedSortBy,
  empUnMappedSortDirection,
  empUnMappedHandleCancel,
  empUnMappedHandleApplyChanges,
}) => {
  const policyListTab = [
    {
      label: 'Mapped',
      onChange: () => {},
      RenderComponent: EmployeeMapped,
      componentProps: {
        filterFields: empMappedFilterFields,
        handleFilter: empMappedHandleFilter,
        rows: empMappedRows,
        totalRecordCount: empMappedTotalRecordCount,
        loadNextPageData: empMappedLoadNextPageData,
        handleSort: empMappedHandleSort,
        handleRowClick: empMappedHandleRowClick,
        page: empMappedPage,
        sortBy: empMappedSortBy,
        sortDirection: empMappedSortDirection,
        handleCancel: empMappedHandleCancel,
        handleApplyChanges: empMappedHandleApplyChanges,
      },
    },
    {
      label: 'Unmapped',
      onChange: () => {},
      RenderComponent: EmployeeUnmapped,
      componentProps: {
        filterFields: empUnMappedFilterFields,
        handleFilter: empUnMappedHandleFilter,
        rows: empUnMappedRows,
        totalRecordCount: empUnMappedTotalRecordCount,
        loadNextPageData: empUnMappedLoadNextPageData,
        handleSort: empUnMappedHandleSort,
        handleRowClick: empUnMappedHandleRowClick,
        page: empUnMappedPage,
        sortBy: empUnMappedSortBy,
        sortDirection: empUnMappedSortDirection,
        handleCancel: empUnMappedHandleCancel,
        handleApplyChanges: empUnMappedHandleApplyChanges,
      },
    },
  ];
  return (
    <Box>
      <Box px={3} py={2}>
        <GraphWithComponentDetail graphData={empMappingGraphData} graphColors={GraphColor('empMappingGraph')} />
      </Box>

      <Box sx={policyListTabStyle.employeeMappingWrapper}>
        <MuiTab textColor="primary" indicatorColor="primary" tabs={policyListTab} panelPadding="0" />
      </Box>
    </Box>
  );
};
EmployeeMappingTab.defaultProps = {
  empMappingGraphData: [
    { name: 'Invite Sent', value: 50 },
    { name: 'Pending invites', value: 8 },
    { name: 'Onboarding Initated', value: 4 },
  ],
  empMappedFilterFields: [
    {
      name: 'Employee Id',
      type: 'number',
    },
    {
      name: 'Employee Name',
      type: 'string',
    },
    {
      name: 'Lc Policy',
      type: 'number',
    },
    {
      name: 'Status',
      type: 'string',
    },
    {
      name: 'Department',
      type: 'string',
    },
    {
      name: 'Designation',
      type: 'string',
    },
    {
      name: 'Location',
      type: 'string',
    },
    {
      name: 'Employment Type',
      type: 'string',
    },
  ],
  empMappedHandleFilter: () => {},
  empMappedRows: [],
  empMappedTotalRecordCount: 5,
  empMappedLoadNextPageData: () => {},
  empMappedHandleSort: () => {},
  empMappedHandleRowClick: () => {},
  empMappedPage: 1,
  empMappedSortBy: '',
  empMappedSortDirection: 'asc',
  empMappedHandleCancel: () => {},
  empMappedHandleApplyChanges: () => {},
  empUnMappedFilterFields: [
    {
      name: 'Employee Id',
      type: 'number',
    },
    {
      name: 'Employee Name',
      type: 'string',
    },
    {
      name: 'Lc Policy',
      type: 'number',
    },
    {
      name: 'Status',
      type: 'string',
    },
    {
      name: 'Department',
      type: 'string',
    },
    {
      name: 'Designation',
      type: 'string',
    },
    {
      name: 'Location',
      type: 'string',
    },
    {
      name: 'Employment Type',
      type: 'string',
    },
  ],
  empUnMappedHandleFilter: () => {},
  empUnMappedRows: [
    {
      id: '1',
      employeeId: 1,
      employeeName: 'Sachin',
      lcPolicy: [
        { id: 0, name: 'Default Life Cycle Policy' },
        { id: 1, name: 'Life Cycle Policy 2022' },
        { id: 2, name: 'Updated Life Cycle Policy' },
        { id: 3, name: 'Description' },
      ],
      status: 'UnMapped',
      department: 'IT',
      designation: 'Design Head',
      location: 'Mumbai',
      employmentType: 'Permanent',
    },
    {
      id: '2',
      employeeId: 2,
      employeeName: 'Sachin',
      lcPolicy: [
        { id: 0, name: 'Default Life Cycle Policy' },
        { id: 1, name: 'Life Cycle Policy 2022' },
        { id: 2, name: 'Updated Life Cycle Policy' },
        { id: 3, name: 'Description' },
      ],
      status: 'UnMapped',
      department: 'IT',
      designation: 'Design Head',
      location: 'Mumbai',
      employmentType: 'Permanent',
    },
    {
      id: '3',
      employeeId: 3,
      employeeName: 'Sachin',
      lcPolicy: [
        { id: 0, name: 'Default Life Cycle Policy' },
        { id: 1, name: 'Life Cycle Policy 2022' },
        { id: 2, name: 'Updated Life Cycle Policy' },
        { id: 3, name: 'Description' },
      ],
      status: 'UnMapped',
      department: 'IT',
      designation: 'Design Head',
      location: 'Mumbai',
      employmentType: 'Permanent',
    },
    {
      id: '4',
      employeeId: 4,
      employeeName: 'Sachin',
      lcPolicy: [
        { id: 0, name: 'Default Life Cycle Policy' },
        { id: 1, name: 'Life Cycle Policy 2022' },
        { id: 2, name: 'Updated Life Cycle Policy' },
        { id: 3, name: 'Description' },
      ],
      status: 'UnMapped',
      department: 'IT',
      designation: 'Design Head',
      location: 'Mumbai',
      employmentType: 'Permanent',
    },
  ],
  empUnMappedTotalRecordCount: 5,
  empUnMappedLoadNextPageData: () => {},
  empUnMappedHandleSort: () => {},
  empUnMappedHandleRowClick: () => {},
  empUnMappedPage: 1,
  empUnMappedSortBy: '',
  empUnMappedSortDirection: 'asc',
  empUnMappedHandleCancel: () => {},
  empUnMappedHandleApplyChanges: () => {},
};

EmployeeMappingTab.propTypes = {
  empMappingGraphData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  empMappedFilterFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  empMappedHandleFilter: PropTypes.func,
  empMappedRows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      employeeId: PropTypes.number,
      employeeName: PropTypes.string,
      payStructure: PropTypes.node,
      status: PropTypes.string,
      gender: PropTypes.string,
      department: PropTypes.string,
      designation: PropTypes.string,
      location: PropTypes.string,
      employmentType: PropTypes.string,
    }),
  ),
  empMappedTotalRecordCount: PropTypes.number,
  empMappedLoadNextPageData: PropTypes.func,
  empMappedHandleSort: PropTypes.func,
  empMappedHandleRowClick: PropTypes.func,
  empMappedPage: PropTypes.number,
  empMappedSortBy: PropTypes.string,
  empMappedSortDirection: PropTypes.string,
  empMappedHandleCancel: PropTypes.func,
  empMappedHandleApplyChanges: PropTypes.func,
  empUnMappedFilterFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  empUnMappedHandleFilter: PropTypes.func,
  empUnMappedRows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      employeeId: PropTypes.number,
      employeeName: PropTypes.string,
      lcPolicy: PropTypes.string,
      userName: PropTypes.string,
      status: PropTypes.string,
      gender: PropTypes.string,
      department: PropTypes.string,
      designation: PropTypes.string,
      location: PropTypes.string,
      employmentType: PropTypes.string,
    }),
  ),
  empUnMappedTotalRecordCount: PropTypes.number,
  empUnMappedLoadNextPageData: PropTypes.func,
  empUnMappedHandleSort: PropTypes.func,
  empUnMappedHandleRowClick: PropTypes.func,
  empUnMappedPage: PropTypes.number,
  empUnMappedSortBy: PropTypes.string,
  empUnMappedSortDirection: PropTypes.string,
  empUnMappedHandleCancel: PropTypes.func,
  empUnMappedHandleApplyChanges: PropTypes.func,
};

export default EmployeeMappingTab;
