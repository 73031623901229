import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import MuiTab from 'components/Molecules/Tab';
import CakeIcon from '@mui/icons-material/Cake';
import CelebrationIcon from '@mui/icons-material/Celebration';
import PeopleIcon from '@mui/icons-material/People';
import BirthdaysTab from './BirthdaysTab';
import AnniversariesTab from './AnniversariesTab';
import TeamMembersTab from './TeamMembersTab';
import { homeDashboardStyle } from '../style';

const TabCard = ({ employeeList, teamMembersList }) => {
  const initialTabs = [
    {
      icon: <CakeIcon />,
      iconPosition: 'start',
      label: 'Birthdays',
      RenderComponent: BirthdaysTab,
      componentProps: {
        employeeList,
      },
    },
    {
      icon: <CelebrationIcon />,
      iconPosition: 'start',
      label: 'Anniversaries',
      RenderComponent: AnniversariesTab,
      componentProps: {
        employeeList,
      },
    },
    {
      icon: <PeopleIcon />,
      iconPosition: 'start',
      label: 'Team Members',
      RenderComponent: TeamMembersTab,
      componentProps: {
        teamMembersList,
      },
    },
  ];

  return (
    <Box sx={homeDashboardStyle.tabCardWrapper}>
      <MuiTab
        sx={{
          '.MuiTabs-vertical': {
            '& .MuiTabs-flexContainerVertical': {
              minWidth: '22rem !important',
              maxWidth: '25rem !important',
            },
          },
        }}
        orientation="vertical"
        textColor="primary"
        indicatorColor="primary"
        isVertical
        panelPadding="2.5rem 3rem"
        tabs={initialTabs}
      />
    </Box>
  );
};

TabCard.defaultProps = {
  employeeList: [],
  teamMembersList: [],
};

TabCard.propTypes = {
  employeeList: PropTypes.arrayOf(
    PropTypes.shape({
      profileImg: PropTypes.string,
      profileName: PropTypes.string,
      emailId: PropTypes.string,
      contactNumber: PropTypes.number,
      event: PropTypes.string,
    }),
  ),
  teamMembersList: PropTypes.arrayOf(
    PropTypes.shape({
      profileImg: PropTypes.string,
      profileName: PropTypes.string,
      emailId: PropTypes.string,
      contactNumber: PropTypes.number,
      event: PropTypes.string,
    }),
  ),
};

export default TabCard;
