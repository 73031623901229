import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Divider } from '@mui/material';
import Button from 'components/Atoms/Button';
import EditIcon from '@mui/icons-material/Edit';
import TitleWithText from 'components/Atoms/TitleWithText';
import Typography from 'components/Atoms/Typography';
import DropdownMenu from 'components/Molecules/DropdownMenu';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useTheme } from '@mui/material/styles';
import AuditTrailCard from 'components/Organisms/Employee/EmployeeRecord/AuditTrailCard';
import { profileStyle } from 'components/Organisms/Document/style';

const View = ({ taxDetails, handleEdit, menuList, handleAttachment, auditLogLists, isEditAction }) => {
  const theme = useTheme();

  return (
    <Box>
      <Box sx={profileStyle.actionBtn}>
        {!!isEditAction && (
          <Button startIcon={<EditIcon fontSize="2.5rem" />} onClick={handleEdit}>
            Edit
          </Button>
        )}
        {!!menuList?.length > 0 && <DropdownMenu btnLabel="Action" size="medium" menuLists={menuList} />}
      </Box>
      <Box mt={4}>
        <Box sx={profileStyle.otherDivider}>
          <Grid container rowSpacing={2} columnSpacing={4}>
            <Grid item xs={12} sm={6} lg={4}>
              <TitleWithText title="Employer Name" text={taxDetails?.employerName || '-'} />
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <TitleWithText title="Status" text={taxDetails?.status || '-'} />
            </Grid>

            <Grid item xs={12}>
              <TitleWithText title="Employer Address" text={taxDetails?.employerAddress || '-'} />
            </Grid>
          </Grid>

          <Divider sx={{ my: 1, backgroundColor: theme.palette.neutral[200] }} />

          <Grid container rowSpacing={2} columnSpacing={4}>
            <Grid item xs={12} sm={6} lg={4}>
              <TitleWithText title="Employer TAN" text={taxDetails?.employerTAN || '-'} />
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <TitleWithText
                title="Income Chargeable Under Head Salaries"
                text={taxDetails?.incomeChargeableUnderHeadSalaries}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TitleWithText title="Exemptions Under Section 10" text={taxDetails?.exemptionsUnderSection10} />
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <TitleWithText title="Deductions Under Section 16" text={taxDetails?.deductionsUnderSection16} />
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <TitleWithText title="Deduction Under Chapter VIA" text={taxDetails?.deductionUnderChapterVIA} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TitleWithText title="Net Tax Payable" text={taxDetails?.netTaxPayable} />
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <TitleWithText title="Net Tax Deducted" text={taxDetails?.netTaxDeducted} />
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <TitleWithText title="Net Professional Tax Paid" text={taxDetails?.netProfessionalTaxPaid} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TitleWithText title="Net PFC contributed" text={taxDetails?.netPFContributed} />
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <TitleWithText title="Tax Payable Refundable" text={taxDetails?.taxPayableRefundable} />
            </Grid>
          </Grid>

          <Divider sx={{ my: 1, mb: 3, backgroundColor: theme.palette.neutral[200] }} />

          <Box>
            <Typography variant="body1" fontWeight={600} sx={{ mb: 1.5, padding: '0' }}>
              Attachment
            </Typography>
            {taxDetails?.attachment ? (
              <Button
                variant="text"
                color="secondary"
                onClick={() =>
                  handleAttachment({ id: taxDetails?.attachment?.id, fileName: taxDetails?.attachment?.fileName })
                }
                startIcon={<AttachFileIcon />}
              >
                {taxDetails?.attachment?.fileName || ''}
              </Button>
            ) : (
              <Typography>No attached file</Typography>
            )}
          </Box>

          {!!auditLogLists?.length > 0 && (
            <>
              <Divider sx={{ my: 1, mb: 3, backgroundColor: theme.palette.neutral[200] }} />
              <Box>
                <Typography variant="body1" fontWeight={600} sx={{ mb: 3, padding: '0' }}>
                  Audit Log
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                  {auditLogLists
                    ?.sort((a, b) => new Date(b?.date) - new Date(a?.date))
                    .map((list) => (
                      <Box key={list?.date}>
                        <AuditTrailCard
                          avatarSrc={list?.avatarSrc}
                          content={list?.content}
                          date={list?.date}
                          description={list?.description}
                        />
                      </Box>
                    ))}
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

View.defaultProps = {
  taxDetails: {},
  handleEdit: () => {},
  handleAttachment: () => {},
  menuList: [],
  auditLogLists: {},
  isEditAction: false,
};

View.propTypes = {
  handleAttachment: PropTypes.func,
  taxDetails: PropTypes.shape,
  handleEdit: PropTypes.func,
  menuList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  auditLogLists: PropTypes.shape,
  isEditAction: PropTypes.bool,
};

export default View;
