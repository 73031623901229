import React from 'react';
import PropTypes from 'prop-types';
import EmployeeCommonCard from 'components/Organisms/Employee/EmployeeCommonCard';
import ProfileListItem from 'components/Molecules/ProfileListItem';
import { useTheme } from '@mui/material/styles';
import { Box } from '@material-ui/core';

const NewHiresCard = ({ hireNum, hireProfileLists, hireFilterOptions, hireFilterHandleChange }) => {
  const theme = useTheme();
  return (
    <EmployeeCommonCard
      cardHeaderTitle={`New Hires (${hireNum})`}
      headerFilterId="hireFilter"
      headerFilterLabel="Week"
      headerFilterOptions={hireFilterOptions}
      headerFilterHandleChange={hireFilterHandleChange}
      cardBodyMinHeight="40rem"
      bodyContent={hireProfileLists.map((list) => (
        <Box padding="1.25rem 2rem" border="0.125rem solid" borderColor={theme.palette.neutral[200]} borderTop="none">
          <ProfileListItem
            userName={list.userName}
            designation={list.designation}
            avatarSrc={list.avatarSrc}
            date={list.date}
          />
        </Box>
      ))}
      footerLink="#"
      footerLinkLabel="View Details"
    />
  );
};

NewHiresCard.defaultProps = {
  hireNum: 6,
  hireProfileLists: [],
  hireFilterOptions: [],
  hireFilterHandleChange: () => {},
};
NewHiresCard.propTypes = {
  hireNum: PropTypes.number,
  hireProfileLists: PropTypes.arrayOf,
  hireFilterOptions: PropTypes.arrayOf,
  hireFilterHandleChange: PropTypes.func,
};

export default NewHiresCard;
