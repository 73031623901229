import * as designTokens from './color';

export const colors = {
  selago: '#F3F8FE',
  mariner: '#346BD1',
  shipCove: '#6C86B5',
  rhino: '#314363',
  royalBlue: '#2F70E8',
  waikawaGray: '#556E9A',
  mandy: '#E04C66',
  toryBlue: '#154CB3',
  cornflowerBlue: '#051A3E',
  Spindle: '#AFC4E8',
  chathamsBlue: '#1B3D78',
  white: '#FFFFFF',
  punch: '#dc3545',
  baliHai: '#8494b1',
  azure: '#355FA6',
  spindle: '#C9DAEE',
  poloBlue: '#7795C9',
  kashmirBlue: '#516EA2',
  downriver: '#0A1D40',
  royalBlue1: '#2A6DEC',
  selago1: '#ECF2FD',
  aliceBlue: '#EFF5FC',
  weldonBlue: '#7E98BA',
  marinerBlue: '#346BD1',
  cyanBlue: '#7694BC',
  paleNavyBlue: '#ADBDD8',
  rockBlue: '#91a1bf',
  catalinaBlue: '#263857',
  darkCatalinaBlue: '#1F3764',
  royalBlue2: '#4A7CDA',
  spindleGray: '#B6C0D1',
  solitudeGray: '#E8ECF2',
  lightSolitudeGray: '#E0E8F7',
  shipCoveGray: '#8494B1',
  midnightExpressBlue: '#0D1E3A',
  sapphireBlue: '#142A51',
  gulfBlue: '#314363',
  redOrange: '#f44336',
  amaranthRed: '#DC3545',
  whiteLilac: '#DEE5EE',
  hawkesBlue: '#D2DCE9',
  pantoneBlue: '#EBF0F6',
  cabaretRed: '#E04C66',
  lavender: '#E1E7F2',
  signalBlue: '#25406D',
  glaucous: '#6c86b5',
  radicalRed: '#FF1C5C',
  cardinalRed: '#C21044',
  carnationPink: '#FF98CC',
  paleVioletRed: '#DE5896',
  mayaBlue: '#68D6FF',
  bleuDeFrance: '#307CE7',
  aeroBlue: '#8eb2f4',
  bluetifulBlue: '#3a79ee',
  tumbleweedBrown: '#E1A68F',
  watusiOrange: '#F0C7B7',
  feldsparRed: '#CD8E74',
  selagoBlue: '#F3F8FE',
  rhinoBlue: '#314363',
  portGoreBlue: '#3c4e6f',
  shipCoveBlue: '#8494b1',
  poloBlue1: '#869DC5',
  biscay: '#193566',
  sanMarino: '#4C6FAB',
  danube: '#6E8FC8',
  solitude: '#E0EBFF',
  cornflower: '#90AEE3',
  sanMarino1: '#4265A3',
  moonRaker: '#D6E3F8',
  selago2: '#E7EEFC',
  denim: '#1156D9',
  ceriseRed: '#DA3754',
  poloBlue2: '#7694CB',
  steelBlue: '#4571BC',
  aliceBlue1: '#F0F7FF',
  catskillWhite: '#EFF3F8',
  slateGray: '#6F7A8E',
  downriver1: '#0E2A5A',
  dodgerBlue: '#216EFF',
  hawkesBlue1: '#E2EBFC',
  eastBay: '#425D8C',
  azure1: '#3763B6',
  zumthor: '#E6EEFF',
  anakiwa: '#A1C1FF',
  cobalt: '#0741AE',
  royalBlue3: '#2066E4',
  denim1: '#1855C4',
  toryBlue1: '#184FB1',
  slateGray1: '#6A778E',
  selago3: '#EAF1FD',
  tropicalBlue: '#C2D6F8',
  downriver2: '#0A224C',
  heather: '#BDC4D1',
  pigeonPost: '#A7BBDF',
  zircon: '#F8FAFF',
  spindle1: '#C5D3EC',
  lavenderBlush: '#FFEEF1',
  cardinal: '#D11B3B',
  zumthor1: '#EAF1FF',
  danube1: '#7696cf',
  ...designTokens,
};
