import { validateDateElseHyphen } from 'utils/commonUtils';
import { flatten } from 'lodash';
import { formDataApi, API_URL } from 'api/api';

const handleDocumentAttachment = async ({ docId }) => {
  const body = new FormData();
  body.append('docid', docId);
  const response = await formDataApi.post('restEmpIndex/downloadAttachment', body);
  return response;
};

export const modifyViewData = (response, user) => {
  const { employeeProfileInstance = {}, employeeInstance = {}, dependents = [], secUserInstance = {} } = response.data;
  const bankAccounts = employeeProfileInstance?.bankAccountDetails;
  const educationalDocs = employeeProfileInstance?.educationRecords?.length
    ? flatten(
        employeeProfileInstance?.educationRecords.map((record) =>
          record.documents.map((doc) => ({
            section: 'Education',
            record: doc?.documentName,
            attachment: doc?.documentName,
            docId: doc?.id,
          })),
        ),
      )
    : [];

  const employerDocs = employeeProfileInstance?.pastEmploymentRecords?.length
    ? flatten(
        employeeProfileInstance?.pastEmploymentRecords.map((record) =>
          record.documents.map((doc) => ({
            section: 'Past Employment',
            record: doc?.documentName,
            attachment: doc?.documentName,
            docId: doc?.id,
          })),
        ),
      )
    : [];

  const visaDocs = employeeProfileInstance?.visaRecords?.length
    ? flatten(
        employeeProfileInstance?.visaRecords.map((record) =>
          record.documents.map((doc) => ({
            section: 'VISA Details',
            record: doc?.documentName,
            attachment: doc?.documentName,
            docId: doc?.id,
          })),
        ),
      )
    : [];

  const skillDocs = employeeProfileInstance?.skillLevelAttainmentRecords?.length
    ? flatten(
        employeeProfileInstance?.skillLevelAttainmentRecords.map((record) =>
          record.documents.map((doc) => ({
            section: 'Skill Attainment Details',
            record: doc?.documentName,
            attachment: doc?.documentName,
            docId: doc?.id,
          })),
        ),
      )
    : [];

  const otherDocs = employeeProfileInstance?.documents?.length
    ? flatten(
        employeeProfileInstance?.documents.map((doc) => ({
          section: doc?.documentType,
          record: doc?.documentName,
          attachment: doc?.documentName,
          docId: doc?.id,
        })),
      )
    : [];

  return {
    onBoardingInstructions: secUserInstance?.company?.config?.onBoardingInstructions || '',
    'Basic Profile': {
      gender: employeeInstance?.gender || '',
      maritalStatus: employeeInstance?.maritalStatus || '',
      dob: validateDateElseHyphen(employeeProfileInstance?.dateOfBirth, 'DD MMMM YYYY'),
      doj: validateDateElseHyphen(employeeProfileInstance?.dateOfJoining, 'DD MMMM YYYY'),
      employmentStatus: employeeProfileInstance?.employee?.employmentStatus || '-',
      fatherName: employeeProfileInstance?.fathersName || '-',
      motherName: employeeProfileInstance?.mothersName || '-',
      spouseName: employeeProfileInstance?.spouseName || '-',
      location: employeeProfileInstance?.employee?.location || '-',
      supervisor: employeeProfileInstance?.employee?.supervisor || '-',
      bloodGroup: employeeProfileInstance?.bloodGroup || '-',
      onboardingReference: employeeProfileInstance?.onboardingReference || '-',
    },
    'Statutory Identification Details': {
      panNumber: employeeInstance?.panNumberIndia || '-',
      aadharNumber: employeeInstance?.aadhaarNumberIndia || '-',
      uan: employeeInstance?.uanNumberIndia || '-',
      uhid: employeeInstance?.uhidAccountNumberIndia || '-',
    },
    'Additional Details': {
      socialData: {
        skypeID: employeeInstance?.skypeID || '-',
        facebookID: employeeInstance?.facebookID || '-',
        linkedinID: employeeInstance?.linkedinID || '-',
        googlePlusID: employeeInstance?.googlePlusID || '-',
        twitterID: employeeInstance?.twitterID || '-',
        instagramID: employeeInstance?.instagramID || '-',
      },
      educationHistory: employeeProfileInstance?.educationRecords?.length
        ? employeeProfileInstance?.educationRecords.map((education) => ({
            institutionName: education?.institutionName || '-',
            certificationName: education?.certificationName || '-',
            startDate: validateDateElseHyphen(education?.startDate, 'DD MMMM YYYY'),
            endDate: validateDateElseHyphen(education?.endDate, 'DD MMMM YYYY'),
            specialization: education?.specialization || '-',
            registrationNumber: education?.registrationNumber || '-',
            referenceName: education?.referenceName || '-',
            referenceNumber: education?.referencePhoneNumber || '-',
            referenceEmail: education?.referenceEmail || '-',
            comment: education?.comments || '-',
            attachment: education?.documents.length > 0 ? education?.documents[0]?.documentName : '',
            attachmentId: education?.documents.length > 0 ? education?.documents[0]?.id : '',
          }))
        : [{}],
      employmentHistory: employeeProfileInstance?.pastEmploymentRecords?.length
        ? employeeProfileInstance?.pastEmploymentRecords.map((employer) => ({
            employerName: employer.employerName || '-',
            employerAddress: employer.employerAddress || '-',
            commenceDate: validateDateElseHyphen(employer.employmentCommenceDate, 'DD MMMM YYYY'),
            endDate: validateDateElseHyphen(employer.employmentEndDate, 'DD MMMM YYYY'),
            reasonForLeaving: employer.reasonForLeaving || '-',
            employeeID: employer.employeeID || '-',
            startDesignation: employer.startDesignation || '-',
            endDesignation: employer.endDesignation || '-',
            referenceName: employer.referenceContactName || '-',
            referenceDesignation: employer.referenceContactDesignation || '-',
            referencePhone: employer.referenceContactPhone || '-',
            referenceEmail: employer.referenceContactEmail || '-',
            attachment: employer?.documents.length > 0 ? employer?.documents[0]?.documentName : '',
            attachmentId: employer?.documents.length > 0 ? employer?.documents[0]?.id : '',
          }))
        : [{}],
      visaData: {
        visaDetails: employeeProfileInstance?.visaRecords?.length
          ? employeeProfileInstance?.visaRecords.map((visaRecord) => ({
              issuingCountry: visaRecord.issuingCountry || '-',
              placeOfIssue: visaRecord.placeOfIssue || '-',
              validFrom: validateDateElseHyphen(visaRecord.validFrom, 'DD MMMM YYYY'),
              validTo: validateDateElseHyphen(visaRecord.validTo, 'DD MMMM YYYY'),
              issueDate: validateDateElseHyphen(visaRecord.issueDate, 'DD MMMM YYYY'),
              referenceNumber: visaRecord.referenceNumber || '-',
              visaStatus: visaRecord.status || '-',
              comments: '', // not coming in api
              attachment: visaRecord?.documents.length > 0 ? visaRecord?.documents[0]?.documentName : '',
              attachmentId: visaRecord?.documents.length > 0 ? visaRecord?.documents[0]?.id : '',
            }))
          : [
              {
                issuingCountry: '',
                placeOfIssue: '',
                validFrom: '',
                validTo: '',
                issueDate: '',
                referenceNumber: '',
                visaStatus: '',
                comments: '',
                attachment: '',
              },
            ],
        passportDetails: [
          {
            passportNumber: employeeProfileInstance?.passportNumber || '-',
            passportIssuePlace: employeeProfileInstance?.passportPlaceOfIssue || '-',
            passportIssueDate: validateDateElseHyphen(employeeProfileInstance?.passportIssueDate, 'DD MMMM YYYY'),
            passportExpiryDate: validateDateElseHyphen(employeeProfileInstance?.passportExpiryDate, 'DD MMMM YYYY'),
            passportCountry: employeeProfileInstance?.passportCountry || '-',
            passportGivenName: employeeProfileInstance?.passportNameGiven || '-',
          },
        ],
      },
      skillsAttainmentDetails: employeeProfileInstance?.skillLevelAttainmentRecords?.length
        ? employeeProfileInstance?.skillLevelAttainmentRecords.map((record) => ({
            skill: record?.skill?.name || '-',
            level: record?.level || '-',
            comments: record?.comments || '-',
            attachment: record?.documents.length > 0 ? record?.documents[0]?.documentName : '',
            attachmentId: record?.documents.length > 0 ? record?.documents[0]?.id : '',
          }))
        : [
            {
              skill: '-',
              level: '-',
              comments: '-',
              attachment: '-',
            },
          ],
    },
    'Contact Details': {
      contactDetails: {
        mobile: employeeInstance?.mobileNumbers?.length ? employeeInstance.mobileNumbers.join(' ') : '-',
        email: employeeInstance?.emailAddresses?.length ? employeeInstance.emailAddresses.join(' ') : '-',
        emergencyContactName: employeeProfileInstance?.dependents?.length
          ? employeeProfileInstance.dependents[0].emergencyContactReference
          : '-',
        emergencyContactRelation: employeeProfileInstance?.dependents?.length
          ? employeeProfileInstance.dependents[0].relationship
          : '-',
        emergencyContactMobile: employeeProfileInstance?.dependents?.length
          ? employeeProfileInstance.dependents[0]?.contactInfo?.mobileNumber
          : '-',
        emergencyContactEmail: employeeProfileInstance?.dependents?.length
          ? employeeProfileInstance.dependents[0]?.contactInfo?.emailAddress
          : '-',
        permanentLocation: employeeProfileInstance?.permanentHomeAddress?.locationName || '-',
        permanentAddress1: employeeProfileInstance?.permanentHomeAddress?.addressLine1 || '-',
        permanentAddress2: employeeProfileInstance?.permanentHomeAddress?.addressLine2 || '-',
        permanentAddress3: employeeProfileInstance?.permanentHomeAddress?.addressLine3 || '-',
        permanentCity: employeeProfileInstance?.permanentHomeAddress?.city || '-',
        permanentState: employeeProfileInstance?.permanentHomeAddress?.state || '-',
        permanentCountry: employeeProfileInstance?.permanentHomeAddress?.country || '-',
        permanentPinOrZip: employeeProfileInstance?.permanentHomeAddress?.pinOrZip || '-',
        presentLocation: employeeProfileInstance?.presentCommunicationAddress?.locationName || '-',
        presentAddress1: employeeProfileInstance?.presentCommunicationAddress?.addressLine1 || '-',
        presentAddress2: employeeProfileInstance?.presentCommunicationAddress?.addressLine2 || '-',
        presentAddress3: employeeProfileInstance?.presentCommunicationAddress?.addressLine3 || '-',
        presentCity: employeeProfileInstance?.presentCommunicationAddress?.city || '-',
        presentState: employeeProfileInstance?.presentCommunicationAddress?.state || '-',
        presentCountry: employeeProfileInstance?.presentCommunicationAddress?.country || '-',
        presentPinOrZip: employeeProfileInstance?.presentCommunicationAddress?.pinOrZip || '-',
        officeLocation: employeeProfileInstance?.officeAddress?.locationName || '-',
        officeAddress1: employeeProfileInstance?.officeAddress?.addressLine1 || '-',
        officeAddress2: employeeProfileInstance?.officeAddress?.addressLine2 || '-',
        officeAddress3: employeeProfileInstance?.officeAddress?.addressLine3 || '-',
        officeCity: employeeProfileInstance?.officeAddress?.city || '-',
        officeState: employeeProfileInstance?.officeAddress?.state || '-',
        officeCountry: employeeProfileInstance?.officeAddress?.country || '-',
        officePinOrZip: employeeProfileInstance?.officeAddress?.pinOrZip || '-',
      },
    },
    'Bank Account': {
      bankDetails: bankAccounts?.length
        ? bankAccounts.map((bank) => ({
            accountNumber: bank.accountNumber || '-',
            accountName: bank.accountHolderName || '-',
            accountType: bank.accountType || '-',
            accountCurrency: bank.currencyId || '-',
            purpose: bank.accountPurpose || '-',
            transactionType: bank.preferredTransactionType || '-',
            bankName: bank.bankName || '-',
            branchName: bank.bankBranch || '-',
            IFSCCode: bank.ifsCode || '-',
            swiftCode: bank.swiftCode || '-',
            bankAddress: {
              locationName: bank?.bankAddress?.locationName || '-',
              addressLine1: bank?.bankAddress?.addressLine1 || '-',
              addressLine2: bank?.bankAddress?.addressLine2 || '-',
              addressLine3: bank?.bankAddress?.addressLine3 || '-',
              city: bank?.bankAddress?.city || '-',
              state: bank?.bankAddress?.state || '-',
              country: bank?.bankAddress?.country || '-',
              pinOrZip: bank?.bankAddress?.pinOrZip || '-',
            },
          }))
        : [{}],
      addressList: bankAccounts?.length
        ? {
            location: bankAccounts[0]?.bankAddress?.locationName || '-',
            address1: bankAccounts[0]?.bankAddress?.addressLine1 || '-',
            address2: bankAccounts[0]?.bankAddress?.addressLine2 || '-',
            address3: bankAccounts[0]?.bankAddress?.addressLine3 || '-',
            address4: bankAccounts[0]?.bankAddress?.city || '-',
          }
        : {
            location: '-',
            address1: '-',
            address2: '-',
            address3: '-',
            address4: '-',
          },
    },
    Dependents: {
      dependentsDetails: dependents?.length
        ? dependents.map((dependent) => ({
            id: dependent?.id || '',
            firstName: dependent?.contactInfo?.firstName || '-',
            lastName: dependent?.contactInfo?.lastName || '-',
            gender: dependent?.contactInfo?.gender || '-',
            relation: dependent?.relationship || '-',
            dob: validateDateElseHyphen(dependent?.dateOfBirth, 'DD MMMM YYYY'),
            mobileNumber: dependent?.contactInfo?.mobileNumber || '-',
            emailAddress: dependent?.contactInfo?.emailAddress || '-',
            epfPerc: dependent?.epfNominationPercentage || '-',
            epsPerc: dependent?.epsNominationPercentage || '-',
            insurancePerc: dependent?.insuranceNominationPercentage || '-',
            otherPerc: dependent?.othersNominationPercentage || '-',
            gratuityPerc: dependent?.gratuityNominationPercentage || '-',
            emergencyContact: dependent?.emergencyContactReference || '-',
          }))
        : [{}],
    },
    attachmentData: {
      attachmentDataColumns: [
        {
          fieldName: 'section',
          name: 'section',
          label: 'Section',
          formatValue: ({ row, column }) => row[column.fieldName],
        },
        {
          fieldName: 'record',
          name: '',
          label: 'Relevant Record',
          formatValue: ({ row, column }) => row[column.fieldName],
        },
        {
          fieldName: 'attachment',
          name: '',
          label: 'Attachment',
          formatValue: ({ row, column }) => row[column.fieldName],
        },
      ],
      attachmentDataRow: [...educationalDocs, ...visaDocs, ...otherDocs, ...employerDocs, ...skillDocs],
      page: 1,
      count: 100,
      handleDocumentAttachment,
    },
    cardDetails: {
      profileDetail: {
        name: employeeProfileInstance?.employee?.fullName || '',
        avatarSrc: user?.profilePic && user?.profilePic !== 'null' ? `${API_URL}/${user?.profilePic}` : '',
        progressBarValue: '',
      },

      contactInfo: {
        location: employeeInstance?.location?.name || '-',
        email: employeeInstance?.emailAddresses?.length ? employeeInstance.emailAddresses.join(' ') : '-',
        mobile: employeeInstance?.mobileNumbers?.length ? employeeInstance.mobileNumbers.join(' ') : '-',
        phone: employeeInstance?.telephoneNumbers?.length ? employeeInstance.telephoneNumbers.join(' ') : '-',
      },

      employmentInfo: {
        designation: employeeProfileInstance?.employee?.designation?.name || '-',
        department: employeeProfileInstance?.employee?.department?.name || '-',
        supervisor: employeeProfileInstance?.employee?.supervisor?.fullName || '-',
        employeeID: employeeProfileInstance?.employeeID || '-',
        employmentType: employeeInstance?.employmentType || '-',
      },
    },
    isLoading: false,
  };
};
