import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Popper } from '@mui/material';
import Button from 'components/Atoms/Button';
import DatePicker from 'components/Atoms/Datepicker';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { DateRangeCalendarStyle } from './style';

const DateRangePicker = ({ handleSubmit, id, label, defaultValues, placement }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Schema = yup.object().shape({
    startDate: yup.string().required('Required'),
    endDate: yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues: defaultValues || {
      startDate: '',
      endDate: '',
    },
    validateOnMount: true,
    validationSchema: Schema,
    onSubmit: (data) => {
      handleSubmit(data);
      handleClose();
    },
  });

  const handleDateChange = (date, fieldName) => {
    formik.setFieldValue(fieldName, date);
  };

  return (
    <Box>
      <Box sx={DateRangeCalendarStyle.label} aria-describedby={id} onClick={handleClick}>
        {label}
      </Box>
      <Popper sx={DateRangeCalendarStyle.calendarWrapper} placement={placement} id={id} open={open} anchorEl={anchorEl}>
        <Box component="form" onSubmit={formik.handleSubmit} container sx={DateRangeCalendarStyle.calendarContent}>
          <Grid container columnSpacing={2} mt={0}>
            <Grid item xs={12} md={6}>
              <DatePicker
                error={
                  Boolean(formik.touched.startDate && formik.errors.startDate) ||
                  (formik.touched.startDate && !formik.values.startDate)
                }
                helperText={formik.touched.startDate && formik.errors.startDate}
                onChange={(date) => handleDateChange(date, 'startDate')}
                onBlur={formik.handleBlur}
                value={formik.values?.startDate}
                name="startDate"
                id="startDate"
                label="Start Date"
                format="DD/MM/YYYY"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DatePicker
                error={
                  Boolean(formik.touched.endDate && formik.errors.endDate) ||
                  (formik.touched.endDate && !formik.values.endDate)
                }
                helperText={formik.touched.endDate && formik.errors.endDate}
                onChange={(date) => handleDateChange(date, 'endDate')}
                onBlur={formik.handleBlur}
                value={formik.values?.endDate}
                name="endDate"
                id="endDate"
                label="End Date"
                format="DD/MM/YYYY"
                minDate={formik.values.startDate}
                disabled={!formik.values.startDate}
                fullWidth
              />
            </Grid>
          </Grid>
          <Box sx={DateRangeCalendarStyle.buttonContainer}>
            <Button size="large" type="submit">
              Select
            </Button>
          </Box>
        </Box>
      </Popper>
    </Box>
  );
};

DateRangePicker.defaultProps = {
  id: 'datepicker',
  label: 'Label',
  defaultValues: {},
  handleSubmit: () => {},
  placement: 'left-end',
};

DateRangePicker.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  defaultValues: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
  handleSubmit: PropTypes.func,
  placement: PropTypes.string,
};

export default DateRangePicker;
