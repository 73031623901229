import { formDataApi } from 'api/api';

/*
 * APIs related to documents
 */
export const myDocumentsListData = async (query) => {
  const response = await formDataApi.get(`restIndex/listMyDocuments?${query.toString()}`);
  return response?.data;
};

export const myDocumentsDelete = async (formData) => {
  const response = await formDataApi.put('restIndex/deleteDocument', formData);
  return response?.data;
};

export const myDocumentsDetailsData = async (query) => {
  const response = await formDataApi.get(`restIndex/displayDocumentDetails?${query.toString()}`);
  return response?.data;
};

export const myDocumentsCreateData = async (formData) => {
  const response = await formDataApi.put('restIndex/createDocument', formData);
  return response;
};

export const myDocumentPublish = async (formData) => {
  const response = await formDataApi.put('restIndex/publishDocument', formData);
  return response?.data;
};

export const myDocumentUnPublish = async (formData) => {
  const response = await formDataApi.put('restIndex/unPublishDocument', formData);
  return response?.data;
};

export const teamDocumentListData = async (query) => {
  const response = await formDataApi.get(`restIndex/listMyOrgDocuments?${query.toString()}`);
  return response?.data;
};

export const searchMyDocumentsAction = async (args) => {
  const response = await formDataApi.post(`restIndex/searchMyDocumentsAction`, args);
  return response?.data;
};

export const searchTeamDocumentsAction = async (args) => {
  const response = await formDataApi.post(`restIndex/searchMyOrgDocumentsAction`, args);
  return response?.data;
};

export const companyDocumentListData = async (query) => {
  const response = await formDataApi.get(`restIndex/listAllDocuments?${query.toString()}`);
  return response?.data;
};

export const searchCompanyDocumentsAction = async (args) => {
  const response = await formDataApi.post(`restIndex/searchAllDocumentsAction`, args);
  return response?.data;
};

export const editDocument = async (formData) => {
  const response = await formDataApi.put('restIndex/editDocument', formData);
  return response?.data;
};
