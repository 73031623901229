import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import CommonList from 'components/Atoms/List/CommonList';

const instuctionList = [
  'Laptop/Computer/Mobile/Other device to your IT Department',
  'Company owned property such as Car/Bike/Scooter to be handed over with all relevant paper work to the operations department',
  'Borrowed Books/Periodicals etc back to the Library',
  'Access card to your Manager/HR',
  'All relevant separations forms duely filled and submitted to the HR',
];

const Instructions = () => {
  return (
    <Box>
      <Typographys variant="body1" fontWeight="500" sx={{ mb: 1 }}>
        Please fill in all the relevant details (such as your last working day etc) in the Separation Request. Once
        everything is finalized after discussions with your manager and HR, please submit your Separation Request.
      </Typographys>
      <Typographys variant="body2" sx={{ mb: 1 }}>
        NOTE: Your Separations process does not start officially until you have raised a Separation Request on the
        portal and submitted the same.
      </Typographys>
      <Typographys color="neutral.600" variant="body2" sx={{ mb: 1 }}>
        Please submit all your expense reimbursement claims well before your Last Working Day so that in case of any
        clarifications, the same can be addressed in time. <br /> If you are residing in a company owned property,
        kindly vacate the same before your Last Working Day and obtain a clearance certificate for the same from your
        operations team. The same will need to be submitted to your HR as part of your Separations Process on your Last
        Working Day.
      </Typographys>
      <Typographys color="neutral.600" variant="body2" sx={{ mb: 1 }}>
        On your Last Working Day, please hand over all the company assets as follows:
      </Typographys>
      <CommonList lists={instuctionList} listVariant="body2" listColor="neutral.600" />
      <Typographys color="neutral.600" variant="body2" sx={{ mb: 1 }}>
        Please submit all hard copies of clearances and forms to HR as part of the exit process on the Last Working Day.
        Once all process work is completed, your HR will issue a clearance certificate. You will eventually receive your
        Relieving Letter and Full and Final Settlement as per the company policy.
      </Typographys>
    </Box>
  );
};

Instructions.defaultProps = {};

Instructions.propTypes = {};

export default Instructions;
