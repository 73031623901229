import React from 'react';
import { Box, Card, CardContent, Container, Grid, Stack, Skeleton } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import Button from 'components/Atoms/Button';
import Typography from 'components/Atoms/Typography';
import EditIcon from '@mui/icons-material/Edit';
import { hasPermission } from 'utils/permissions';
import { useSelector } from 'react-redux';
import useFetchPayrollCalendar from './useFetchPayrollCalendar';
import { DeleteDialog } from '../List/DeleteDialog';
import ViewTabs from './Tabs';

const ViewPayhead = () => {
  const { id } = useParams();
  const [payrollCalendar] = useFetchPayrollCalendar(id);
  const navigate = useNavigate();
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasEditPermisson = hasPermission(authorities, ['ROLE_BASE_PERMISSION_PAY_INDEX_UPDATE_PAYROLL_CALENDAR']);
  const userHasDeletePermisson = hasPermission(authorities, ['ROLE_BASE_PERMISSION_PAY_INDEX_DELETE_PAYROLL_CALENDAR']);

  if (payrollCalendar.error !== null) {
    return (
      <Typography variant="h6">
        {payrollCalendar?.error?.response?.data?.message || `Error while loading calendar details for id : ${id}.`}
      </Typography>
    );
  }
  return (
    <Container maxWidth="lg">
      <Grid container direction="column">
        <Grid item container justifyContent="flex-start" alignItems="center" sx={{ pb: 1.5 }}>
          <Grid item>
            <Typography variant="h6" color="text.primary">
              Payroll Calendar Details
            </Typography>
          </Grid>
        </Grid>
        <Grid item sx={{ maxWidth: '100% !important' }}>
          <Card variant="outlined" square>
            <CardContent>
              <Stack direction="row" gap={2} justifyContent="space-between" sx={{ pb: 2 }}>
                <Typography variant="h6" color="text.300">
                  {`Payroll Calendar Details - ${payrollCalendar?.data?.data?.payrollCalendarInstance?.name}`}
                </Typography>
                <Stack direction="row" gap={2} justifyContent="end">
                  {!!userHasEditPermisson && (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => navigate(`/company/salary/payrollCalendar/${id}/edit`)}
                      startIcon={<EditIcon fontSize="small" />}
                    >
                      Edit
                    </Button>
                  )}
                  {!!userHasDeletePermisson && <DeleteDialog payrollCalendarId={id} />}
                </Stack>
              </Stack>
              {payrollCalendar.isLoading ? (
                <Box sx={{ py: 4 }}>
                  <Skeleton height={42} />
                  <Skeleton />
                  <Skeleton />
                </Box>
              ) : (
                <ViewTabs payrollCalendar={payrollCalendar?.data?.data?.payrollCalendarInstance} />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ViewPayhead;
