import React from 'react';
import PropTypes from 'prop-types';
import InputField from 'components/Atoms/InputField';
import { Box } from '@mui/material';
import { tdsStyle } from 'components/Organisms/MyPay/TDS/View/style';
import Typographys from 'components/Atoms/Typography';
import ToolTip from 'components/Atoms/ToolTip';
import ChooseFile from 'components/Atoms/ChooseFile';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from 'components/Atoms/Button';
import TableExtended from 'components/Molecules/TableExtended';

const InvestmentDeclarationForm = ({ formik, handleFile, sortDirection, sortBy, onSortChange }) => {
  const investmentDeclarationColumn = [
    {
      fieldName: `slNo`,
      label: 'Sl No',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `investmentHead`,
      label: 'INVESTMENT/DEDUCTION HEAD',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `annualDeclaredAmt`,
      label: 'Annual Declared Amount',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
  ];
  return (
    <TableExtended
      loading={false}
      rows={formik?.values?.investmentDec.map((i, index) => {
        return {
          ...i,
          slNo: index + 1,
          investmentHead: (
            <Box sx={tdsStyle.textWithTooltip}>
              <Typographys variant="body2">{i?.title}</Typographys>
              <ToolTip tooltipTitle={i?.tooltipTitle} title={i?.tooltip} placement="top" />
            </Box>
          ),
          annualDeclaredAmt: (
            <InputField
              value={i?.amount}
              name={`investmentDec[${index}].amount`}
              id={`investmentDec[${index}].amount`}
              type="number"
              label="Amount"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              fullWidth
            />
          ),
        };
      })}
      columns={investmentDeclarationColumn}
      showActionList={false}
      showCheckbox={false}
      sortDirection={sortDirection}
      sortBy={sortBy}
      onSortChange={onSortChange}
    />
  );
};
InvestmentDeclarationForm.defaultProps = {
  formik: {},
  handleFile: () => {},
  sortDirection: 'desc',
  sortBy: 'id',
  onSortChange: () => {},
};
InvestmentDeclarationForm.propTypes = {
  formik: PropTypes.shape({
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
  }),
  handleFile: PropTypes.func,
  onSortChange: PropTypes.func,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
};

export default InvestmentDeclarationForm;
