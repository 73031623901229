import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import TableExtended from 'components/Molecules/TableExtended';
import Chips from 'components/Molecules/Chip';
import FilterButton from 'components/Organisms/TableFilter/FilterButton';
import NoRecordsMessage from 'components/Organisms/CommonPageUtils/NoRecordsFound';
import { hasPermission } from 'utils/permissions';
import { useSelector } from 'react-redux';
import { lifeCycleRecordStyle } from './style';

const LifeCycleRecord = ({
  handleFilter,
  filterFields,
  loading,
  lcRecordRows,
  lcRecordColumns,
  keyColumn,
  lcRecordTotalRecordCount,
  lcRecordLoadNextPageData,
  lcRecordHandleSort,
  lcRecordHandleRowClick,
  lcRecordPage,
  lcRecordSortBy,
  lcRecordSortDirection,
  lcRecordHandleSelect,
  lcRecordOnSelectedChange,
  actionMenuLists,
  handleFiltersClear,
  showPagination,
  isFilter,
}) => {
  return (
    <Box sx={lifeCycleRecordStyle.wrapper}>
      <Box sx={{ mb: 2 }}>
        {!!isFilter && (
          <FilterButton
            handleSubmit={handleFilter}
            attributeOptions={filterFields}
            handleFiltersClear={handleFiltersClear}
          />
        )}
      </Box>
      <Box sx={lifeCycleRecordStyle.bodySection}>
        {!loading && lcRecordRows.length === 0 ? (
          <NoRecordsMessage width="20rem" height="20rem" />
        ) : (
          <TableExtended
            loading={loading}
            rows={lcRecordRows.map((item) => ({
              ...item,
              status: <Chips label={item?.status} color="success" />,
            }))}
            columns={lcRecordColumns}
            keyColumn={keyColumn}
            sortDirection={lcRecordSortDirection}
            sortBy={lcRecordSortBy}
            onSortChange={lcRecordHandleSort}
            count={lcRecordTotalRecordCount}
            page={lcRecordPage}
            onPageChange={lcRecordLoadNextPageData}
            showPagination={showPagination}
            onRowClick={lcRecordHandleRowClick}
            showRowSelector
            onSelect={lcRecordOnSelectedChange}
            showActionList
            roleBasedMenu={actionMenuLists}
            handleSelect={lcRecordHandleSelect}
          />
        )}
      </Box>
    </Box>
  );
};

LifeCycleRecord.defaultProps = {
  filterFields: [],
  handleFilter: () => {},
  loading: false,
  lcRecordRows: [],
  lcRecordColumns: [],
  keyColumn: '',
  lcRecordTotalRecordCount: 5,
  lcRecordLoadNextPageData: () => {},
  lcRecordHandleSort: () => {},
  lcRecordHandleRowClick: () => {},
  lcRecordPage: 1,
  lcRecordSortBy: '',
  lcRecordSortDirection: 'asc',
  lcRecordHandleSelect: () => {},
  lcRecordOnSelectedChange: () => {},
  actionMenuLists: () => {},
  handleFiltersClear: () => {},
  showPagination: false,
  isFilter: false,
};

LifeCycleRecord.propTypes = {
  filterFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  handleFilter: PropTypes.func,
  loading: PropTypes.bool,
  lcRecordRows: PropTypes.arrayOf,
  lcRecordColumns: PropTypes.arrayOf,
  keyColumn: PropTypes.string,
  lcRecordTotalRecordCount: PropTypes.number,
  lcRecordLoadNextPageData: PropTypes.func,
  lcRecordHandleSort: PropTypes.func,
  lcRecordHandleRowClick: PropTypes.func,
  lcRecordPage: PropTypes.number,
  lcRecordSortBy: PropTypes.string,
  lcRecordSortDirection: PropTypes.oneOf(['asc', 'desc']),
  lcRecordHandleSelect: PropTypes.func,
  lcRecordOnSelectedChange: PropTypes.func,
  actionMenuLists: PropTypes.arrayOf,
  handleFiltersClear: PropTypes.func,
  showPagination: PropTypes.bool,
  isFilter: PropTypes.bool,
};

export default LifeCycleRecord;
