import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import OfferAcceptanceTemplate from 'components/Templates/Dashboard/CandidateDashboard/OfferAcceptance';
import CandidateDashboardTemplate from 'components/Templates/Dashboard/CandidateDashboard';
import Dialog from 'components/Molecules/Dialog';
import {
  SOURCING_STAGE_OFFER_LETTER_PENDING_ACCEPTANCE,
  SOURCING_STAGE_OFFER_LETTER_REJECTED,
  SOURCING_STAGE_OFFER_LETTER_ACCEPTED,
} from 'utils/SystemConfigConstants';
import useServices from './useServices';

const CandidateDashboard = () => {
  const services = useServices();
  const [formData, setFormData] = useState('');

  const {
    sourcingStage = '',
    fullName = '',
    candidateID = '',
    firstName = '',
    lastName = '',
    designation = '',
    companyName = '',
    dateOfJoining = '',
    isOfferAccepted,
    fileName = '',
  } = useSelector((state) => state.session.user);

  const candidateData = {
    fullName,
    candidateID,
    firstName,
    lastName,
    designation,
    companyName,
    dateOfJoining,
    isOfferAccepted,
  };

  const handleSubmit = async (values) => setFormData(values);
  useEffect(() => {}, [services?.refreshPage]);

  return (
    <>
      {sourcingStage === SOURCING_STAGE_OFFER_LETTER_PENDING_ACCEPTANCE && (
        <OfferAcceptanceTemplate
          pageHeading="Offer acceptance"
          candidateData={candidateData}
          handleDownload={() => services?.handleAttachment(candidateID, fileName)}
          handleSubmit={handleSubmit}
          setDialog={services?.setOpenDialog}
        />
      )}
      {isOfferAccepted && sourcingStage === SOURCING_STAGE_OFFER_LETTER_ACCEPTED && (
        <CandidateDashboardTemplate
          pageHeading={`Hello ${fullName}`}
          candidateData={candidateData}
          handleDownload={() => services?.handleAttachment(candidateID, fileName)}
          contentTxt1="Thank you for accepting the offer. Please connect with your HR for"
          contentTxt2="assistance on further course of action."
        />
      )}
      {!isOfferAccepted && sourcingStage === SOURCING_STAGE_OFFER_LETTER_REJECTED && (
        <CandidateDashboardTemplate
          pageHeading={`Hello ${fullName}`}
          candidateData={candidateData}
          handleDownload={() => services?.handleAttachment(candidateID, fileName)}
          contentTxt1="Thank you for considering our offer. Please connect with your HR"
          contentTxt2="if you have any queries."
        />
      )}

      {sourcingStage === SOURCING_STAGE_OFFER_LETTER_PENDING_ACCEPTANCE && services?.openDialog === 'accept' && (
        <Dialog
          open={services?.openDialog}
          handleClose={services?.handleDialogClose}
          dialogTitle="Confirm Job offer"
          dialogDesc="You are about to accept the offer. Please confirm if so."
          secondaryBtn="Cancel"
          primaryBtn="Yes, Confirm"
          handlePrimaryBtn={() => services?.handleOffer(formData, candidateID)}
          handleSecondaryBtn={services?.handleDialogClose}
        />
      )}

      {sourcingStage === SOURCING_STAGE_OFFER_LETTER_PENDING_ACCEPTANCE && services?.openDialog === 'reject' && (
        <Dialog
          open={services?.openDialog}
          handleClose={services?.handleDialogClose}
          dialogTitle="Reject Job offer"
          dialogDesc="If you reject it, you won't be able to accept the same later. HR may extend another offer which might differ from what you are offered now."
          secondaryBtn="Cancel"
          primaryBtn="Reject offer"
          handlePrimaryBtn={() => services?.handleOffer(formData, candidateID)}
          handleSecondaryBtn={services?.handleDialogClose}
        />
      )}
    </>
  );
};

export default CandidateDashboard;
