import React from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import PropTypes from 'prop-types';
import EmployeeRecord from 'components/Organisms/Employee/EmployeeRecord';

const EmployeeRecordTemplate = ({ pageHeading, employeeProfileDetail, employeeDetails }) => {
  const breadcrumbData = [
    { id: 1, name: 'Employee List', path: '/company/employee/employees-list', isActive: false },
    { id: 2, name: 'Employee Records', isActive: true },
  ];
  return (
    <BlankTemplate pageHeading={pageHeading} isBreadcrumb breadcrumbData={breadcrumbData} padding="0">
      <EmployeeRecord employeeProfileDetail={employeeProfileDetail} employeeDetails={employeeDetails} />
    </BlankTemplate>
  );
};

EmployeeRecordTemplate.defaultProps = {
  pageHeading: 'Employee Record',
  employeeProfileDetail: {},
  employeeDetails: {},
};

EmployeeRecordTemplate.propTypes = {
  pageHeading: PropTypes.string,

  employeeProfileDetail: PropTypes.shape({}),
  employeeDetails: PropTypes.shape({}),
};

export default EmployeeRecordTemplate;
