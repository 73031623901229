export const profileStyle = {
  tableWrapper: {
    mt: 1.5,
    mb: 2,
    'MuiTableHead-root': {
      background: 'neutral.100',
    },
    '.MuiTable-root.MuiTable-root .MuiTableCell-root': {
      border: '1px solid',
      borderColor: 'other.200',
      py: 2,

      '.MuiTable-root.MuiTable-root': {
        margin: '-2rem',
        width: 'calc(100% + 4rem)',
      },
    },
  },

  filterWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    mt: 2.5,
  },

  listActionButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  actionBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
  },

  formContainer: {
    width: '100%',
    maxWidth: '100%',
    mt: 0,
    mb: 2.5,
  },

  formDivider: {
    '&:not(:first-child)': {
      borderTop: '0.125rem solid',
      borderColor: 'other.200',
      mt: 4,
      pt: 4,
    },
  },

  textDivider: {
    '&:not(:first-child)': {
      borderTop: '0.125rem solid',
      borderColor: 'other.200',
      mt: 2.5,
      pt: 2.5,
    },
  },

  addButton: {
    my: 3,
  },

  btnContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%',
    backgroundColor: 'common.white',
    zIndex: '9',
    margin: '0rem',
    padding: '0rem 3rem 2rem 3rem',
    boxSizing: 'border-box',
  },

  submitWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 2,
  },
  currentVersionCheckbox: {
    mb: 2,
  },
};
