export const loginStyle = {
  wrapper: {
    width: 1,
    maxWidth: '44.375rem',
    display: 'flex',
    margin: 'auto',
    justifyContent: 'center',
    flexDirection: 'column',
  },
};
