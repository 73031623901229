import React from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell } from 'recharts';
import GraphColor from 'utils/GraphColor';

const PieCharts = ({ width, height, innerRadius, outerRadius, paddingAngle, data, colors, children }) => {
  return (
    <PieChart width={width} height={height}>
      <Pie
        data={data}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        paddingAngle={paddingAngle}
        dataKey="value"
        stroke=""
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${entry.name}`} fill={colors[index % colors.length]} />
        ))}
      </Pie>
      {children}
    </PieChart>
  );
};

PieCharts.defaultProps = {
  width: 150,
  height: 150,
  paddingAngle: 0,
  innerRadius: 50,
  outerRadius: 70,
  data: [
    { name: 'Group A', value: 400 },
    { name: 'Group B', value: 300 },
    { name: 'Group C', value: 300 },
    { name: 'Group D', value: 200 },
  ],
  colors: GraphColor('pieCharts'),
  children: '',
};

PieCharts.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  innerRadius: PropTypes.number,
  outerRadius: PropTypes.number,
  paddingAngle: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  colors: PropTypes.arrayOf({}),
  children: PropTypes.node,
};

export default PieCharts;
