/* eslint-disable consistent-return */
import { API_URL } from 'api/api';
import { ObjectToFormData } from './ObjectToFormData';

const downloadFile = async (formpostObject, filename, subURL) => {
  const myHeaders = new Headers();
  myHeaders.append('accept', 'application/json, text/plain, */*');
  const token = localStorage.getItem('token');
  myHeaders.append('authorization', `Bearer ${token}`);
  const formData = ObjectToFormData(formpostObject);
  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formData,
  };

  const res = await fetch(`${API_URL}/${subURL}`, requestOptions);
  const response = { status: res.status, ok: res.ok };

  try {
    if (!res.ok) {
      const errorData = await res.json();
      response.message = errorData?.message || res.statusText;
    } else {
      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
      const successData = await res.json();
      response.message = successData?.message || 'Download successful';
    }
    return response;
  } catch (error) {
    response.message = 'An error occurred during download';
    return response;
  }
};

export default downloadFile;
