import React from 'react';
import PropTypes from 'prop-types';
import InfoCardWithIcon from 'components/Molecules/InfoCardWithIcon';
import { Box } from '@mui/material';
import Typography from 'components/Atoms/Typography';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import WorkIcon from '@mui/icons-material/Work';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import OfferDashboardMsg from 'assets/images/OfferDashboardMsg.svg';
import { validateElseHyphen } from 'utils/commonUtils';
import { candidateDashboardStyle } from './style';

const CandidateDashboard = ({ candidateData, handleDownload, contentTxt1, contentTxt2 }) => {
  return (
    <>
      <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 2 }}>
        <InfoCardWithIcon
          label="DATE OF JOINING"
          value={validateElseHyphen(candidateData?.dateOfJoining)}
          icon={<EventAvailableIcon sx={{ color: 'white' }} />}
        />
        <InfoCardWithIcon
          label="DESIGNATION"
          value={validateElseHyphen(candidateData?.designation)}
          icon={<WorkIcon sx={{ color: 'white' }} />}
        />
        <InfoCardWithIcon
          label="Offer"
          value="&nbsp;"
          icon={<CheckBoxIcon sx={{ color: 'white' }} />}
          isButton
          handleDownload={handleDownload}
        />
      </Box>
      <Box sx={candidateDashboardStyle.card}>
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
          <Box
            sx={candidateDashboardStyle.contentWrapper}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            {!!contentTxt1 && <Typography sx={candidateDashboardStyle.contentTxt}>{contentTxt1}</Typography>}
            {!!contentTxt2 && <Typography sx={candidateDashboardStyle.contentTxt}>{contentTxt2}</Typography>}
          </Box>
          <Box
            component="img"
            display="block"
            sx={candidateDashboardStyle.contentImg}
            alt="error 403"
            src={OfferDashboardMsg}
          />
        </Box>
      </Box>
    </>
  );
};

CandidateDashboard.defaultProps = {
  candidateData: {},
  handleDownload: () => {},
  contentTxt1: '',
  contentTxt2: '',
};
CandidateDashboard.propTypes = {
  candidateData: PropTypes.shape({
    dateOfJoining: PropTypes.string,
    designation: PropTypes.string,
  }),
  handleDownload: PropTypes.func,
  contentTxt1: PropTypes.string,
  contentTxt2: PropTypes.string,
};

export default CandidateDashboard;
