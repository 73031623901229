import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import MuiTab from 'components/Molecules/Tab';
import LCPolicyBasicDetail from './LCPolicyBasicDetail';
import LCPolicy from './LCPolicy';

const LCPolicyDetails = ({
  transferApproverDetails,
  transferApproverLists,
  promotionApproverDetails,
  promotionApproverLists,
  demotionApproverDetails,
  demotionApproverLists,
  confirmationAApproverDetails,
  confirmationApproverLists,
  terminationApproverDetails,
  terminationApproverLists,
  probationExtensionApproverDetails,
  probationExtensionApproverLists,
  pipInitiationApproverDetails,
  pipInitiationApproverLists,
  pipConclusionApproverDetails,
  pipConclusionApproverLists,
  bgVerificationApproverDetails,
  bgVerificationApproverLists,
  policyDetails,
  menuLists,
  handleClick,
}) => {
  const policyDetailsTab = [
    {
      label: 'Transfer',
      onChange: () => {},
      RenderComponent: LCPolicy,
      componentProps: {
        approverDetails: transferApproverDetails,
        approverLists: transferApproverLists,
        label: 'Transfer',
      },
    },
    {
      label: 'Promotion',
      onChange: () => {},
      RenderComponent: LCPolicy,
      componentProps: {
        approverDetails: promotionApproverDetails,
        approverLists: promotionApproverLists,
        label: 'Promotion',
      },
    },
    {
      label: 'Demotion',
      onChange: () => {},
      RenderComponent: LCPolicy,
      componentProps: {
        approverDetails: demotionApproverDetails,
        approverLists: demotionApproverLists,
        label: 'Demotion',
      },
    },
    {
      label: 'Confirmation',
      onChange: () => {},
      RenderComponent: LCPolicy,
      componentProps: {
        approverDetails: confirmationAApproverDetails,
        approverLists: confirmationApproverLists,
        label: 'Confirmation',
      },
    },
    {
      label: 'Termination',
      onChange: () => {},
      RenderComponent: LCPolicy,
      componentProps: {
        approverDetails: terminationApproverDetails,
        approverLists: terminationApproverLists,
        label: 'Termination',
      },
    },
    {
      label: 'Probation Extension',
      onChange: () => {},
      RenderComponent: LCPolicy,
      componentProps: {
        approverDetails: probationExtensionApproverDetails,
        approverLists: probationExtensionApproverLists,
        label: 'Probation Extension',
      },
    },
    {
      label: 'PIP Initiation',
      onChange: () => {},
      RenderComponent: LCPolicy,
      componentProps: {
        approverDetails: pipInitiationApproverDetails,
        approverLists: pipInitiationApproverLists,
        label: 'PIP Initiation',
      },
    },
    {
      label: 'PIP Conclusion',
      onChange: () => {},
      RenderComponent: LCPolicy,
      componentProps: {
        approverDetails: pipConclusionApproverDetails,
        approverLists: pipConclusionApproverLists,
        label: 'PIP Conclusion',
      },
    },
    {
      label: 'Background Verification',
      onChange: () => {},
      RenderComponent: LCPolicy,
      componentProps: {
        approverDetails: bgVerificationApproverDetails,
        approverLists: bgVerificationApproverLists,
        label: 'Background Verification',
      },
    },
  ];
  return (
    <Box>
      <Box p={3} pt={2}>
        <LCPolicyBasicDetail policyDetails={policyDetails} menuLists={menuLists} handleClick={handleClick} />
      </Box>

      <Box minHeight="66.5rem" sx={{ borderTop: '0.125rem solid', borderColor: 'other.200' }}>
        <MuiTab
          orientation="horizontal"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          textColor="primary"
          indicatorColor="primary"
          tabs={policyDetailsTab}
          panelPadding="1rem 3rem 3rem"
        />
      </Box>
    </Box>
  );
};
LCPolicyDetails.defaultProps = {
  policyDetails: {
    name: 'Default Life Cycle Policy',
    status: true,
    createdOn: '22-Feb-2023',
    createdBy: 'Setup Admin(0)',
    defaultPolicyAdministrator: 'Aarzoo Khurana (T292)',
    description: 'Vel diam ultircies dignismems augue',
  },
  menuLists: [{ id: 1, label: 'Delete', onClick: () => {} }],
  handleClick: () => {},
  transferApproverDetails: {
    approverType: 'Reporting Manager',
    approverSLA: '3.0',
    approverUnit: 'Days',
  },
  transferApproverLists: [
    {
      id: 1,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
    {
      id: 2,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
  ],
  promotionApproverDetails: {
    approverType: 'Reporting Manager',
    approverSLA: '3.0',
    approverUnit: 'Days',
  },
  promotionApproverLists: [
    {
      id: 1,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
    {
      id: 2,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
  ],
  demotionApproverDetails: {
    approverType: 'Reporting Manager',
    approverSLA: '3.0',
    approverUnit: 'Days',
  },
  demotionApproverLists: [
    {
      id: 1,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
    {
      id: 2,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
  ],
  confirmationAApproverDetails: {
    approverType: 'Reporting Manager',
    approverSLA: '3.0',
    approverUnit: 'Days',
  },
  confirmationApproverLists: [
    {
      id: 1,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
    {
      id: 2,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
  ],
  terminationApproverDetails: {
    approverType: 'Reporting Manager',
    approverSLA: '3.0',
    approverUnit: 'Days',
  },
  terminationApproverLists: [
    {
      id: 1,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
    {
      id: 2,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
  ],
  probationExtensionApproverDetails: {
    approverType: 'Reporting Manager',
    approverSLA: '3.0',
    approverUnit: 'Days',
  },
  probationExtensionApproverLists: [
    {
      id: 1,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
    {
      id: 2,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
  ],
  pipInitiationApproverDetails: {
    approverType: 'Reporting Manager',
    approverSLA: '3.0',
    approverUnit: 'Days',
  },
  pipInitiationApproverLists: [
    {
      id: 1,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
    {
      id: 2,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
  ],
  pipConclusionApproverDetails: {
    approverType: 'Reporting Manager',
    approverSLA: '3.0',
    approverUnit: 'Days',
  },
  pipConclusionApproverLists: [
    {
      id: 1,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
    {
      id: 2,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
  ],
  bgVerificationApproverDetails: {
    approverType: 'Reporting Manager',
    approverSLA: '3.0',
    approverUnit: 'Days',
  },
  bgVerificationApproverLists: [
    {
      id: 1,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
    {
      id: 2,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
  ],
};

LCPolicyDetails.propTypes = {
  policyDetails: PropTypes.shape({
    name: PropTypes.string,
    status: PropTypes.string,
    createdOn: PropTypes.string,
    createdBy: PropTypes.string,
    defaultPolicyAdministrator: PropTypes.string,
    description: PropTypes.string,
  }),
  menuLists: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  handleClick: PropTypes.func,

  transferApproverDetails: PropTypes.shape({
    approverType: PropTypes.string,
    approverSLA: PropTypes.string,
    approverUnit: PropTypes.string,
  }),
  transferApproverLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      effectedBy: PropTypes.string,
    }),
  ),
  promotionApproverDetails: PropTypes.shape({
    approverType: PropTypes.string,
    approverSLA: PropTypes.string,
    approverUnit: PropTypes.string,
  }),
  promotionApproverLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      effectedBy: PropTypes.string,
    }),
  ),
  demotionApproverDetails: PropTypes.shape({
    approverType: PropTypes.string,
    approverSLA: PropTypes.string,
    approverUnit: PropTypes.string,
  }),
  demotionApproverLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      effectedBy: PropTypes.string,
    }),
  ),
  confirmationAApproverDetails: PropTypes.shape({
    approverType: PropTypes.string,
    approverSLA: PropTypes.string,
    approverUnit: PropTypes.string,
  }),
  confirmationApproverLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      effectedBy: PropTypes.string,
    }),
  ),
  terminationApproverDetails: PropTypes.shape({
    approverType: PropTypes.string,
    approverSLA: PropTypes.string,
    approverUnit: PropTypes.string,
  }),
  terminationApproverLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      effectedBy: PropTypes.string,
    }),
  ),
  probationExtensionApproverDetails: PropTypes.shape({
    approverType: PropTypes.string,
    approverSLA: PropTypes.string,
    approverUnit: PropTypes.string,
  }),
  probationExtensionApproverLists: PropTypes.arrayOf,
  pipInitiationApproverDetails: PropTypes.shape({
    approverType: PropTypes.string,
    approverSLA: PropTypes.string,
    approverUnit: PropTypes.string,
  }),
  pipInitiationApproverLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      effectedBy: PropTypes.string,
    }),
  ),
  pipConclusionApproverDetails: PropTypes.shape({
    approverType: PropTypes.string,
    approverSLA: PropTypes.string,
    approverUnit: PropTypes.string,
  }),
  pipConclusionApproverLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      effectedBy: PropTypes.string,
    }),
  ),
  bgVerificationApproverDetails: PropTypes.shape({
    approverType: PropTypes.string,
    approverSLA: PropTypes.string,
    approverUnit: PropTypes.string,
  }),
  bgVerificationApproverLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      effectedBy: PropTypes.string,
    }),
  ),
};
export default LCPolicyDetails;
