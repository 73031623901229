import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import TitleWithText from 'components/Atoms/TitleWithText';

const EpfChallanDetails = ({ epfChallanDetails }) => {
  return (
    <>
      <Typographys variant="h6" color="text.300">
        EPF Remittance break up details (to be used in case of an EPF challan)
      </Typographys>
      <Grid container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="EPF Employee's Share" text={epfChallanDetails.epfEEShareRemittedIndia} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="EPF Employer's Share" text={epfChallanDetails.epfERShareRemittedIndia} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="EPS Contribution (Remitted)" text={epfChallanDetails.epsERShareRemittedIndia} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText
            title="EDLI Scheme Contribution (Remitted)"
            text={epfChallanDetails.edliERShareRemittedIndia}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="EPF Admin Charges (Remitted)" text={epfChallanDetails.epfAdminChargeRemittedIndia} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="EDLI Admin Charges (Remitted)" text={epfChallanDetails.edliAdminChargeRemittedIndia} />
        </Grid>
      </Grid>
    </>
  );
};

EpfChallanDetails.defaultProps = {
  epfChallanDetails: {
    epfEEShareRemittedIndia: 'Male',
    epfERShareRemittedIndia: 'UnMarried',
    epsERShareRemittedIndia: '07/06/1960',
    edliERShareRemittedIndia: '04/06/2017',
    epfAdminChargeRemittedIndia: 'Full Time',
    edliAdminChargeRemittedIndia: 'Snehalatha',
  },
};

EpfChallanDetails.propTypes = {
  epfChallanDetails: PropTypes.shape({
    epfEEShareRemittedIndia: PropTypes.string,
    epfERShareRemittedIndia: PropTypes.string,
    epsERShareRemittedIndia: PropTypes.string,
    edliERShareRemittedIndia: PropTypes.string,
    epfAdminChargeRemittedIndia: PropTypes.string,
    edliAdminChargeRemittedIndia: PropTypes.string,
  }),
};

export default EpfChallanDetails;
