import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'components/Molecules/Dropdown';
import { Box } from '@mui/material';
import Button from 'components/Atoms/Button';

import { useFormik } from 'formik';
import * as yup from 'yup';
import { passwordResetStyle } from './style';

const PasswordReset = ({ employeeOptions, handleSubmit }) => {
  const SignupSchema = yup.object().shape({
    selectEmployee: yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      selectEmployee: '',
    },
    validationSchema: SignupSchema,
    validateOnMount: false,
    onSubmit: handleSubmit,
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={passwordResetStyle.wrap}>
      <Dropdown
        error={Boolean(formik.touched.selectEmployee && formik.errors.selectEmployee)}
        handleChange={(e) => {
          formik.handleChange(e);
        }}
        onBlur={formik.handleBlur}
        value={formik.values.selectEmployee}
        name="selectEmployee"
        id="selectEmployee"
        label="Select Employee"
        autoComplete="Select Employee"
        options={employeeOptions}
        fullWidth
      />

      <Box sx={passwordResetStyle.button}>
        <Button width="auto" size="large" type="submit" isDisable={!formik.dirty || Object.keys(formik.errors).length}>
          Reset Password
        </Button>
      </Box>
    </Box>
  );
};

PasswordReset.defaultProps = {
  employeeOptions: [
    { id: 1, name: 'Sharad Deshpande', onClick: () => {} },
    { id: 2, name: 'Sachin Metha', onClick: () => {} },
    { id: 3, name: 'Arijit Savant', onClick: () => {} },
    { id: 4, name: 'Rahul Roy', onClick: () => {} },
    { id: 5, name: 'Ishana Shyamalan', onClick: () => {} },
    { id: 6, name: 'Ishana Shyamalan', onClick: () => {} },
  ],
  handleSubmit: () => {},
};

PasswordReset.propTypes = {
  handleSubmit: PropTypes.func,
  employeeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
};

export default PasswordReset;
