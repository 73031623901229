import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import UserProfile from 'components/Organisms/UserProfile';
import BasicProfileTabInfo from './BasicProfileTabInfo';

const BasicProfile = ({
  employeeProfileDetail,
  rows,
  keyColumn,
  sortDirection,
  sortBy,
  onSortChange,
  count,
  page,
  onPageChange,
  showPagination,
  onRowClick,
  handleSelect,
  loading,
  handleEdit,
  employeeDetail,
  employeePersonalInformation,
  employeeEmergencyContactInformation,
  employeeContactDetails,
  employeeContactEmailId,
}) => {
  return (
    <Box>
      <Box p={3}>
        <UserProfile
          employeeProfileDetail={employeeProfileDetail}
          isProgressBar={false}
          isActionsDropdownMenu={false}
          handleClick={handleEdit}
        />
      </Box>

      <Box minHeight="66.5rem">
        <BasicProfileTabInfo
          rows={rows}
          keyColumn={keyColumn}
          sortDirection={sortDirection}
          sortBy={sortBy}
          onSortChange={onSortChange}
          count={count}
          page={page}
          onPageChange={onPageChange}
          showPagination={showPagination}
          onRowClick={onRowClick}
          handleSelect={handleSelect}
          loading={loading}
          employeeDetail={employeeDetail}
          employeePersonalInformation={employeePersonalInformation}
          employeeEmergencyContactInformation={employeeEmergencyContactInformation}
          employeeContactDetails={employeeContactDetails}
          employeeContactEmailId={employeeContactEmailId}
        />
      </Box>
    </Box>
  );
};
BasicProfile.defaultProps = {
  employeeProfileDetail: {},
  keyColumn: 'id',
  sortDirection: 'desc',
  sortBy: 'id',
  onSortChange: () => {},
  page: 1,
  onPageChange: () => {},
  showPagination: false,
  onRowClick: () => {},
  handleSelect: () => {},
  loading: false,
  rows: [],
  handleEdit: () => {},
  employeeDetail: {},
  employeePersonalInformation: {},
  employeeEmergencyContactInformation: {},
  employeeContactDetails: [],
  employeeContactEmailId: [],
};

BasicProfile.propTypes = {
  employeeProfileDetail: PropTypes.arrayOf(PropTypes.shape({})),
  rows: PropTypes.arrayOf,
  keyColumn: PropTypes.string,
  onSortChange: PropTypes.func,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  showPagination: PropTypes.bool,
  count: PropTypes.number.isRequired,
  onRowClick: PropTypes.func,
  handleSelect: PropTypes.func,
  loading: PropTypes.bool,
  handleEdit: PropTypes.func,
  employeeDetail: PropTypes.shape({
    panNumber: PropTypes.string,
    aadharNumber: PropTypes.string,
    uan: PropTypes.string,
    epfAccountNumber: PropTypes.string,
    esiAccountNumber: PropTypes.string,
  }),

  employeePersonalInformation: PropTypes.shape({
    bloodGroup: PropTypes.string,
    dateOfBirth: PropTypes.string,
    dateOfJoining: PropTypes.string,
    motherName: PropTypes.string,
    fatherName: PropTypes.string,
    spouseName: PropTypes.string,
    schoolGoingChildren: PropTypes.string,
    childrenHostel: PropTypes.string,
  }),
  employeeEmergencyContactInformation: PropTypes.shape({
    name: PropTypes.string,
    mobileNumber: PropTypes.string,
    relation: PropTypes.string,
    emailid: PropTypes.string,
  }),
  employeeContactDetails: PropTypes.arrayOf,
  employeeContactEmailId: PropTypes.arrayOf,
};
export default BasicProfile;
