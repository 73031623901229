export const passwordResetStyle = {
  wrap: {
    width: '47.5rem',
  },
  alertBox: {
    position: 'fixed',
    right: '0',
    top: '9rem',
    width: '42.25rem',
    // transform: `${isOpenAlert ? 'translateX(0)' : 'translateX(100%)'}`,
    transition: 'ease-in-out .3s all',
  },
  button: {
    marginTop: '2rem',
  },
};
