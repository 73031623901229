import React from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import PropTypes from 'prop-types';
import SeparationList from 'components/Organisms/Employee/Company/Separation/SeparationList';

const SeparationTemplate = ({
  loading,
  onSortChange,
  rows,
  page,
  sortDirection,
  sortBy,
  handleSelect,
  onSelect,
  onSelectAll,
  count,
  selectedItems,
  showPagination,
  onPageChange,
  handleSort,
  handleRowClick,
  keyColumn,
  showActionList,
  menuList,
  roleBasedMenu,
  showRowSelector,
  showActions,
  columns,
  actionOptions,
  handleFilter,
  checkboxColor,
  pageHeading,
  rowsPerPage,
  loadNextPageData,
  employeeOptions,
  handleSubmit,
  showInitiateSeparation,
}) => {
  return (
    <BlankTemplate pageHeading={pageHeading} isBreadcrumb={false} padding="2rem 3rem">
      <SeparationList
        rows={rows}
        loading={loading}
        onSortChange={onSortChange}
        columns={columns}
        count={count}
        selectedItems={selectedItems}
        onSelect={onSelect}
        onSelectAll={onSelectAll}
        showRowSelector={showRowSelector}
        showPagination={showPagination}
        onPageChange={onPageChange}
        handleSort={handleSort}
        handleRowClick={handleRowClick}
        page={page}
        keyColumn={keyColumn}
        roleBasedMenu={roleBasedMenu}
        handleSelect={handleSelect}
        showActionList={showActionList}
        sortBy={sortBy}
        sortDirection={sortDirection}
        rowsPerPage={rowsPerPage}
        checkboxColor={checkboxColor}
        showActions={showActions}
        loadNextPageData={loadNextPageData}
        handleFilter={handleFilter}
        menuList={menuList}
        actionOptions={actionOptions}
        employeeOptions={employeeOptions}
        handleSubmit={handleSubmit}
        showInitiateSeparation={showInitiateSeparation}
      />
    </BlankTemplate>
  );
};

SeparationTemplate.defaultProps = {
  loading: false,
  onSortChange: () => {},
  roleBasedMenu: () => {},
  rows: [],
  columns: [],
  handleSelect: () => {},
  onSelect: () => {},
  onSelectAll: () => {},
  sortBy: () => {},
  page: '',
  sortDirection: 'asc',
  menuList: [
    { label: 'Bulk Delete', onClick: () => {} },
    { label: 'Bulk Lock Details', onClick: () => {} },
    { label: 'Bulk Accept', onClick: () => {} },
  ],
  showRowSelector: true,
  showActions: true,
  showInitiateSeparation: true,
  actionOptions: [],
  handleFilter: () => {},
  count: 10,
  selectedItems: [],
  showPagination: false,
  onPageChange: (page) => {},
  handleSort: () => {},
  handleRowClick: () => {},
  keyColumn: '',
  showActionList: false,
  checkboxColor: '',
  pageHeading: 'Separations',
  rowsPerPage: 10,
  loadNextPageData: () => {},
  handleSubmit: () => {},
  employeeOptions: [{ id: 302980, name: 'Harry' }],
};

SeparationTemplate.propTypes = {
  loading: PropTypes.bool,
  onSortChange: PropTypes.func,
  roleBasedMenu: PropTypes.func,
  handleSelect: PropTypes.func,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  sortBy: PropTypes.func,
  page: PropTypes.number,
  sortDirection: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
      numeric: PropTypes.bool,
      formatValue: PropTypes.func,
    }),
  ),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      employeeId: PropTypes.string,
      employee: PropTypes.string,
      resignationStatus: PropTypes.string,
      relievingDate: PropTypes.string,
      submissionDate: PropTypes.string,
      acceptanceDate: PropTypes.string,
    }),
  ),

  menuList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  showRowSelector: PropTypes.bool,
  showActions: PropTypes.bool,
  showInitiateSeparation: PropTypes.bool,
  actionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  handleFilter: PropTypes.func,
  count: PropTypes.number,
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      employeeId: PropTypes.string,
      employee: PropTypes.string,
      resignationStatus: PropTypes.string,
      relievingDate: PropTypes.string,
      submissionDate: PropTypes.string,
      acceptanceDate: PropTypes.string,
    }),
  ),

  showActionList: PropTypes.bool,
  handleSort: PropTypes.func,
  handleRowClick: PropTypes.func,
  showPagination: PropTypes.bool,
  onPageChange: PropTypes.func,
  keyColumn: PropTypes.string,
  checkboxColor: PropTypes.string,
  pageHeading: PropTypes.string,
  rowsPerPage: PropTypes.number,
  loadNextPageData: PropTypes.func,
  handleSubmit: PropTypes.func,
  employeeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
};

export default SeparationTemplate;
