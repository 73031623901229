import { useEffect, useReducer } from 'react';
import FormData from 'form-data';
import { formDataApi } from 'api/api';

const initialState = {
  isLoading: false,
  data: {},
  error: null,
};

const reducer = (state, action) => {
  const { type, payload } = action;
  let newState;
  switch (type) {
    case 'pending': {
      newState = { ...state, isLoading: true };
      break;
    }
    case 'resolved': {
      newState = { ...state, isLoading: false, data: payload };
      break;
    }
    case 'rejected': {
      newState = { ...state, isLoading: false, error: payload };
      break;
    }
    default: {
      newState = state;
    }
  }
  return newState;
};

const useFetchTask = (taskId) => {
  const [task, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const fetchTaskById = async () => {
      dispatch({ type: 'pending' });
      const bodyFormData = new FormData();
      bodyFormData.append('id', taskId);
      try {
        const response = await formDataApi.post('restEmpIndex/displayTask', bodyFormData);
        if (response.data) {
          dispatch({ type: 'resolved', payload: response.data });
        }
      } catch (error) {
        dispatch({ type: 'rejected', payload: error });
      }
    };
    fetchTaskById();
  }, [taskId]);

  return [task];
};

export default useFetchTask;
