import { useCallback } from 'react';
import downloadFile from 'utils/downloadFile';
import useToast from 'components/Provider/useToast';

const useDownloadFile = () => {
  const addToast = useToast();

  const handleDownload = useCallback(
    async (id, filename, apiEndpoint) => {
      const response = await downloadFile(id, filename, apiEndpoint);
      if (response?.ok) {
        addToast({
          type: 'success',
          title: 'Success',
          message: `${filename || ''} downloaded successfully.`,
        });
      }

      if (response?.ok !== undefined && !response?.ok) {
        addToast({
          type: 'error',
          title: 'ERROR',
          message: response?.message || `${filename || ''} Download failed.`,
        });
      }
    },
    [addToast],
  );

  return handleDownload;
};

export default useDownloadFile;
