import { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import FormData from 'form-data';
import { deleteLCRecordsAction, submitLifeCycleRecord } from 'api/employees';
import useDownloadFile from 'hooks/useDownloadFile';
import useToast from 'components/Provider/useToast';

const useLCRecordServices = () => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const navigate = useNavigate();
  const addToast = useToast();
  const downloadFile = useDownloadFile();

  const handleDeleteDialog = () => {
    setShowDeleteDialog(!showDeleteDialog);
  };

  const modifyEmployee = (row) => {
    navigate(`/company/life-cycle/life-cycle-records/${row?.id}/${row?.changeType}/edit`);
  };

  const viewEmployeeRecord = (row) => {
    navigate(`/company/life-cycle/life-cycle-records/${row?.id}/${row?.changeType}/view`);
  };

  const actionEmployee = (row) => {
    navigate(`/company/life-cycle/life-cycle-records/${row?.id}/${row?.changeType}/action`);
  };

  const deleteRecords = async (employee, viewPage) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', employee.id);
      const response = await deleteLCRecordsAction(bodyFormData);
      if (response.status === 200) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Lifecycle record deleted successfully`,
        });
        if (viewPage) {
          navigate('/company/life-cycle/life-cycle-records');
        } else {
          setRefreshPage(!refreshPage);
        }
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to delete Lifecycle record`,
      });
    } finally {
      handleDeleteDialog(false);
    }
  };
  const submitLCRecord = async (row) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', row?.id);
      bodyFormData.append('type', row?.changeType);
      const response = await submitLifeCycleRecord(bodyFormData);
      if (response.data.status === 'SUCCESS') {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Lifecycle record submitted successfully`,
        });
        setRefreshPage(!refreshPage);
      } else {
        addToast({
          type: 'error',
          title: 'ERROR',
          message: response?.data?.message || `Failed to submit Lifecycle record`,
        });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to submit Lifecycle record`,
      });
    }
  };

  const handleAttachment = (docid, filename) => {
    downloadFile({ docid }, filename, 'restExpIndex/downloadAttachment');
  };

  return {
    viewEmployeeRecord,
    deleteRecords,
    showDeleteDialog,
    handleDeleteDialog,
    submitLCRecord,
    modifyEmployee,
    actionEmployee,
    handleAttachment,
    refreshPage,
  };
};

export default useLCRecordServices;
