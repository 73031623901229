import store from 'store';
import home from './home';
import myTeam from './my-team';
import company from './company';
// import help from './help';
// import projects from './project';
// import quickLinks from './quick-links';
// import reports from './reports';
// import settings from './settings';
import profile from './profile';

const menus = [home, myTeam, company, profile];

const menuItems = {
  items: menus,
};

export default menuItems;
