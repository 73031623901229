import React from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import PropTypes from 'prop-types';
import AddEmployee from 'components/Organisms/Employee/AddEmployee';
import { Box, Skeleton } from '@mui/material';
import SalaryStructureTabs from './Tabs';

const EmployeeSalaryStructure = ({ loading, breadcrumbData, onSubmit, dropdownOptions }) => {
  return (
    <BlankTemplate padding="0rem" pageHeading="Salary Structure" breadcrumbData={breadcrumbData}>
      {loading ? (
        <Box sx={{ py: 4 }}>
          <Skeleton height={42} />
          <Skeleton />
          <Skeleton />
        </Box>
      ) : (
        <SalaryStructureTabs />
      )}
    </BlankTemplate>
  );
};

EmployeeSalaryStructure.defaultProps = {
  breadcrumbData: [
    { id: 1, name: 'Employee List', path: '/company/employee/employees-list', isActive: false },
    {
      id: 2,
      name: 'Employee Records',
      path: '/company/employee/employees-list/records/salaryStructure',
      isActive: false,
    },
    {
      id: 3,
      name: 'Salary Structure',
      path: '/company/employee/employees-list/records/salaryStructure',
      isActive: true,
    },
  ],
  onSubmit: (data) => {
    console.log(data);
  },
  dropdownOptions: {},
};

EmployeeSalaryStructure.propTypes = {
  onSubmit: PropTypes.func,
  breadcrumbData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
  dropdownOptions: PropTypes.shape({}),
};

export default EmployeeSalaryStructure;
