import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, Formik, Form } from 'formik';
import { Box, Grid, Divider, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import InputField from 'components/Atoms/InputField/index';
import DatePicker from 'components/Atoms/Datepicker';
import Typographys from 'components/Atoms/Typography';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Button from 'components/Atoms/Button';
import { myExpensesStyle } from 'components/Organisms/MyExpenses/style';
import { useTheme } from '@mui/material/styles';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import ExpenseRecord from './ExpenseRecord';

const AddReport = ({
  initialValues,
  expenseReportId,
  totalExpensedAmount,
  reimbursableAmount,
  expenseCategoryOptions,
  currencyOptions,
  paymentMethodOptions,
  projectsOptions,
  merchantOptions,
  handleCancel,
  handleDraft,
  handleSubmit,
  isEdit,
  loading,
  draftLoading,
}) => {
  const theme = useTheme();

  const ReportSchema = Yup.object().shape({
    reportName: Yup.string().required('Required'),
    startDate: Yup.date().required('Required'),
    endDate: Yup.date().required('Required'),
    expenseRecord: Yup.array()
      .of(
        Yup.object().shape({
          recordDate: Yup.mixed().required('Required'),
          amount: Yup.number().required('Required').min(0, 'Amount cannot be negative.'),
          expenseCategoryId: Yup.mixed().required('Required'),
          exchangeRate: Yup.number().min(1, 'Exchange rate cannot be negative or 0.'),
        }),
      )
      .min(1, 'At least one expense record must be added.'),
  });

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={initialValues}
      validationSchema={ReportSchema}
      onSubmit={(data) => {
        data.actionType = 'submit';
        handleSubmit(data);
      }}
    >
      {(formik) => (
        <Form component="form" onSubmit={formik.handleSubmit}>
          {formik.errors.expenseRecord && typeof formik.errors.expenseRecord === 'string' && (
            <Alert severity="error" sx={{ marginBottom: '1.8rem' }}>
              {formik.errors.expenseRecord}
            </Alert>
          )}

          <PerfectScrollbar>
            <Box sx={{ maxHeight: 'calc(100vh - 40rem)' }}>
              <Grid container rowSpacing={2} columnSpacing={2}>
                {!!isEdit && (
                  <Grid item xs={12} md={12}>
                    <Typographys variant="subtitle2" color="neutral.800">
                      Expense Report ID: {expenseReportId}
                    </Typographys>
                  </Grid>
                )}
                <Grid item xs={12} md={4}>
                  <InputField
                    error={Boolean(formik.touched.reportName && formik.errors.reportName)}
                    helperText={formik.touched.reportName && formik.errors.reportName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.reportName}
                    name="reportName"
                    id="reportName"
                    label="Name *"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <InputField
                    error={Boolean(formik.touched.businessPurpose && formik.errors.businessPurpose)}
                    helperText={formik.touched.businessPurpose && formik.errors.businessPurpose}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.businessPurpose}
                    name="businessPurpose"
                    id="businessPurpose"
                    label="Business Purpose"
                    fullWidth
                    multiline
                    inputProps={{ sx: { minHeight: '12rem' } }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typographys variant="caption" color="neutral.800">
                    Expense Report Period *
                  </Typographys>
                </Grid>
                <Grid item xs={12} md={4}>
                  <DatePicker
                    error={
                      Boolean(formik.touched.startDate && formik.errors.startDate) ||
                      (formik.touched.startDate && !formik.values.startDate)
                    }
                    formik={formik}
                    helperText={formik.touched.startDate && formik.errors.startDate}
                    onChange={(date) => {
                      formik.setFieldValue('startDate', date);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values?.startDate ? dayjs(formik.values?.startDate, 'MM/DD/YYYY') : ''}
                    name="startDate"
                    id="startDate"
                    label="Start date"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DatePicker
                    error={
                      Boolean(formik.touched.endDate && formik.errors.endDate) ||
                      (formik.touched.endDate && !formik.values.endDate)
                    }
                    formik={formik}
                    helperText={formik.touched.endDate && formik.errors.endDate}
                    onChange={(date) => formik.setFieldValue('endDate', date)}
                    onBlur={formik.handleBlur}
                    value={formik.values?.endDate ? dayjs(formik.values?.endDate, 'MM/DD/YYYY') : ''}
                    name="endDate"
                    id="endDate"
                    label="End date"
                    minDate={dayjs(formik.values?.startDate)}
                    disabled={!formik.values?.startDate}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Box mt={2}>
                <FieldArray name="expenseRecord">
                  {({ push, remove }) => (
                    <ExpenseRecord
                      totalExpensedAmount={totalExpensedAmount}
                      reimbursableAmount={reimbursableAmount}
                      formik={formik}
                      expenseCategoryOptions={expenseCategoryOptions}
                      currencyOptions={currencyOptions}
                      paymentMethodOptions={paymentMethodOptions}
                      projectsOptions={projectsOptions}
                      merchantOptions={merchantOptions}
                      push={push}
                      remove={remove}
                      isEdit={isEdit}
                    />
                  )}
                </FieldArray>
              </Box>
            </Box>
          </PerfectScrollbar>
          <Divider sx={{ mt: 3, mb: 2, borderColor: theme.palette.other[200] }} />
          <Box sx={myExpensesStyle.btnContainer}>
            <Box sx={myExpensesStyle.submitWrapper}>
              <Button
                size="large"
                color="info"
                variant="outlined"
                type="cancel"
                onClick={() => {
                  formik.resetForm();
                  handleCancel();
                }}
              >
                Cancel
              </Button>
              {!isEdit && (
                <LoadingButton
                  size="large"
                  variant="outlined"
                  onClick={async () => {
                    formik.setTouched({
                      reportName: true,
                      startDate: true,
                      endDate: true,
                      expenseRecord: formik.values.expenseRecord.map(() => ({
                        recordDate: true,
                        amount: true,
                      })),
                    });
                    const errors = await formik.validateForm();
                    formik.setFieldValue('actionType', 'draft');
                    const allFieldsValid = Object.keys(errors).length === 0;
                    if (allFieldsValid) handleSubmit(formik.values);
                  }}
                  loading={draftLoading}
                >
                  Save as Draft
                </LoadingButton>
              )}
              <LoadingButton color="primary" loading={loading} size="large" type="submit" variant="contained">
                {isEdit ? 'Update' : 'Create'}
              </LoadingButton>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

AddReport.defaultProps = {
  expenseReportId: 31,
  totalExpensedAmount: 0,
  reimbursableAmount: 0,
  initialValues: {},
  expenseCategoryOptions: [],
  currencyOptions: [],
  paymentMethodOptions: [],
  projectsOptions: [],
  merchantOptions: [],
  handleCancel: () => {},
  handleDraft: () => {},
  handleSubmit: () => {},
  isEdit: false,
  loading: false,
  draftLoading: false,
};

AddReport.propTypes = {
  expenseReportId: PropTypes.number,
  totalExpensedAmount: PropTypes.number,
  reimbursableAmount: PropTypes.number,
  initialValues: PropTypes.shape({
    reportName: PropTypes.string,
    businessPurpose: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    expenseRecord: PropTypes.arrayOf(
      PropTypes.shape({
        allocations: PropTypes.arrayOf(PropTypes.shape()),
      }),
    ),
  }),
  expenseCategoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  currencyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  paymentMethodOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  projectsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  merchantOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  handleCancel: PropTypes.func,
  handleDraft: PropTypes.func,
  handleSubmit: PropTypes.func,
  isEdit: PropTypes.func,
  loading: PropTypes.bool,
  draftLoading: PropTypes.bool,
};

export default AddReport;
