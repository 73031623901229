import { api, formDataApi } from 'api/api';

export const listChallans = async (query) => {
  const response = await api.post(`restPayIndex/listChallans?${query.toString()}`);
  return response?.data?.data;
};

export const searchChallans = async (query) => {
  const response = await api.post(`restPayIndex/listChallans?${query.toString()}`);
  return response?.data?.data;
};

export const deleteChallan = async (id) => {
  const body = new FormData();
  body.append('id', id);
  const response = await formDataApi.post('restPayIndex/deleteChallan', body);
  return response;
};
export const lockChallan = async (id) => {
  const body = new FormData();
  body.append('chid', id);
  const response = await formDataApi.post('restPayIndex/lockChallanAllocation', body);
  return response;
};

export const unLockChallan = async (id) => {
  const body = new FormData();
  body.append('chid', id);
  const response = await formDataApi.post('restPayIndex/unlockChallanAllocation', body);
  return response;
};

// eslint-disable-next-line consistent-return
export const fetchChallanById = async (args) => {
  if (args.isEditMode) {
    const body = new FormData();
    body.append('id', args.challanId);
    const response = await formDataApi.post('restPayIndex/displayChallan', body);
    return response;
  }
};

export const fetchChallanAllocationById = async (args) => {
  const body = new FormData();
  body.append('id', args.challanId);
  const response = await formDataApi.post('restPayIndex/displayChallan', body);
  return response;
};
