import React from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import ReportDetails from 'components/Organisms/MyExpenses/ReportDetails';

const ReportDetailsTemplate = ({
  reportDetails,
  handleEdit,
  actionMenuList,
  amountData,
  rows,
  page,
  handleSelect,
  roleBasedMenu,
  columns,
  auditData,
  showActions,
  showActionDialog,
  isApprove,
  handleClose,
  handleCancel,
  handleSubmit,
  onChange,
  breadcrumbData,
  handleAttachmentDownload,
  supportingDocuments,
}) => {
  return (
    <BlankTemplate pageHeading breadcrumbData={breadcrumbData} padding="0">
      <ReportDetails
        reportDetails={reportDetails}
        handleEdit={handleEdit}
        actionMenuList={actionMenuList}
        amountData={amountData}
        rows={rows}
        page={page}
        handleSelect={handleSelect}
        roleBasedMenu={roleBasedMenu}
        columns={columns}
        auditData={auditData}
        showActions={showActions}
        showActionDialog={showActionDialog}
        isApprove={isApprove}
        handleClose={handleClose}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        onChange={onChange}
        handleAttachmentDownload={handleAttachmentDownload}
        supportingDocuments={supportingDocuments}
      />
    </BlankTemplate>
  );
};

ReportDetailsTemplate.defaultProps = {
  reportDetails: {
    title: '',
    address: '',
    employeeName: '',
    employeeId: '',
    expensePeriodStartDate: '',
    expensePeriodEndDate: '',
    department: '',
    designation: '',
    reportingManager: '',
    location: '',
    businessPurpose: '',
    reportId: '',
    reportStatus: '',
    settlementStatus: '',
  },
  handleEdit: () => {},
  actionMenuList: [],
  amountData: [],
  roleBasedMenu: () => {},
  rows: [],
  columns: [],
  handleSelect: () => {},
  page: '',
  auditData: [],
  showActions: true,
  showActionDialog: false,
  isApprove: true,
  handleClose: () => {},
  handleCancel: () => {},
  handleSubmit: () => {},
  onChange: () => {},
  breadcrumbData: [],
  handleAttachmentDownload: () => {},
  supportingDocuments: [],
};

ReportDetailsTemplate.propTypes = {
  reportDetails: PropTypes.shape({
    title: PropTypes.string,
    address: PropTypes.string,
    employeeName: PropTypes.string,
    employeeId: PropTypes.string,
    expensePeriodStartDate: PropTypes.string,
    expensePeriodEndDate: PropTypes.string,
    department: PropTypes.string,
    designation: PropTypes.string,
    reportingManager: PropTypes.string,
    location: PropTypes.string,
    businessPurpose: PropTypes.string,
    reportId: PropTypes.string,
    reportStatus: PropTypes.string,
    settlementStatus: PropTypes.string,
  }),
  handleEdit: PropTypes.func,
  actionMenuList: PropTypes.arrayOf,
  amountData: PropTypes.arrayOf,
  roleBasedMenu: PropTypes.func,
  handleSelect: PropTypes.func,
  page: PropTypes.number,
  rows: PropTypes.arrayOf,
  columns: PropTypes.arrayOf,
  auditData: PropTypes.shape(),
  showActions: PropTypes.bool,
  showActionDialog: PropTypes.bool,
  isApprove: PropTypes.bool,
  handleClose: PropTypes.func,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  onChange: PropTypes.func,
  breadcrumbData: PropTypes.arrayOf,
  handleAttachmentDownload: PropTypes.func,
  supportingDocuments: PropTypes.arrayOf,
};

export default ReportDetailsTemplate;
