import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Divider, useTheme } from '@mui/material';
import { Formik, Form } from 'formik';
import Button from 'components/Atoms/Button';
import DatePicker from 'components/Atoms/Datepicker';
import Dropdown from 'components/Molecules/Dropdown';
import Checkbox from 'components/Atoms/Checkbox';
import InputField from 'components/Atoms/InputField';
import TitleWithText from 'components/Atoms/TitleWithText';
import * as yup from 'yup';
import PerfectScrollbar from 'react-perfect-scrollbar';
import dayjs from 'dayjs';
import { separationStyle } from '../style';

const InitiateEmployeeSeparation = ({
  handleSubmit,
  employeeSeparationInstance,
  seperationOptions,
  managerOptions,
  exitInterviewerOptions,
}) => {
  const separationSchema = yup.object().shape({
    relievingDate: yup.date().required('Required'),
    exitInterviewDate: yup.date().required('Required'),
    seperationType: yup.string().required('Required'),
    resAcceptManager: yup.string().required('Required'),
    exitInterviewer: yup.string().required('Required'),
  });

  const initialValues = {
    seperationType: '',
    relievingDate: '',
    resAcceptManager: '',
    exitInterviewDate: '',
    exitInterviewer: '',
    resReasons: '',
    eligibleForRehire: '',
    resSubmissionDate: dayjs(),
    resAcceptanceDate: dayjs(),
  };

  const theme = useTheme();

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={initialValues}
      validationSchema={separationSchema}
      onSubmit={(data) => handleSubmit(data)}
    >
      {(formik) => (
        <Form component="form" onSubmit={formik.handleSubmit}>
          <PerfectScrollbar>
            <Box sx={{ maxHeight: 'calc(100vh - 41rem)' }}>
              <Grid container rowSpacing={2} columnSpacing={4} mt={0}>
                <Grid item xs={12} md={4}>
                  <TitleWithText
                    title="Employee name"
                    text={`${employeeSeparationInstance?.fullName || ''} (${
                      employeeSeparationInstance?.profile?.employeeID || ''
                    })`}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <TitleWithText
                    title="Notice Period"
                    text={`${employeeSeparationInstance?.profile?.noticePeriod || ''} ${
                      employeeSeparationInstance?.profile?.noticePeriodTimeUnit || '-'
                    }`}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <DatePicker
                    error={
                      Boolean(formik.touched.resSubmissionDate && formik.errors.resSubmissionDate) ||
                      (formik.touched.resSubmissionDate && !formik.values.resSubmissionDate)
                    }
                    helperText={formik.touched.resSubmissionDate && formik.errors.resSubmissionDate}
                    onChange={(date) => formik.setFieldValue('resSubmissionDate', date)}
                    onBlur={formik.handleBlur}
                    value={formik.values?.resSubmissionDate ? dayjs(formik.values?.resSubmissionDate) : null}
                    name="resSubmissionDate"
                    id="resSubmissionDate"
                    label="Resignation Submission Date"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <DatePicker
                    error={
                      Boolean(formik.touched.resAcceptanceDate && formik.errors.resAcceptanceDate) ||
                      (formik.touched.resAcceptanceDate && !formik.values.resAcceptanceDate)
                    }
                    helperText={formik.touched.resAcceptanceDate && formik.errors.resAcceptanceDate}
                    onChange={(date) => formik.setFieldValue('resAcceptanceDate', date)}
                    onBlur={formik.handleBlur}
                    value={formik.values?.resAcceptanceDate ? dayjs(formik.values?.resAcceptanceDate) : null}
                    name="resAcceptanceDate"
                    id="resAcceptanceDate"
                    label="Resignation Acceptance Date"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <DatePicker
                    error={
                      Boolean(formik.touched.relievingDate && formik.errors.relievingDate) ||
                      (formik.touched.relievingDate && !formik.values.relievingDate)
                    }
                    helperText={formik.touched.relievingDate && formik.errors.relievingDate}
                    onChange={(date) => formik.setFieldValue('relievingDate', date)}
                    onBlur={formik.handleBlur}
                    value={formik.values?.relievingDate ? dayjs(formik.values?.relievingDate) : null}
                    name="relievingDate"
                    id="relievingDate"
                    label="Requested Relieving Date*"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Dropdown
                    error={Boolean(formik.touched.seperationType && formik.errors.seperationType)}
                    helperText={formik.touched.seperationType && formik.errors.seperationType}
                    handleChange={(e) => {
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values?.seperationType}
                    name="seperationType"
                    id="seperationType"
                    label="Separation Type*"
                    options={seperationOptions}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Dropdown
                    error={Boolean(formik.touched.resAcceptManager && formik.errors.resAcceptManager)}
                    helperText={formik.touched.resAcceptManager && formik.errors.resAcceptManager}
                    handleChange={(e) => {
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values?.resAcceptManager}
                    name="resAcceptManager"
                    id="resAcceptManager"
                    label="Resignation Accepting Manager*"
                    options={managerOptions}
                    keyGetter={(item) => item.id}
                    titleGetter={(item) => item.fullName}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <DatePicker
                    error={
                      Boolean(formik.touched.exitInterviewDate && formik.errors.exitInterviewDate) ||
                      (formik.touched.exitInterviewDate && !formik.values.exitInterviewDate)
                    }
                    helperText={formik.touched.exitInterviewDate && formik.errors.exitInterviewDate}
                    onChange={(date) => formik.setFieldValue('exitInterviewDate', date)}
                    onBlur={formik.handleBlur}
                    value={formik.values?.exitInterviewDate ? dayjs(formik.values?.exitInterviewDate) : null}
                    name="exitInterviewDate"
                    id="exitInterviewDate"
                    label="Exit Interviewer Date*"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Dropdown
                    error={Boolean(formik.touched.exitInterviewer && formik.errors.exitInterviewer)}
                    helperText={formik.touched.exitInterviewer && formik.errors.exitInterviewer}
                    handleChange={(e) => {
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values?.exitInterviewer}
                    name="exitInterviewer"
                    id="exitInterviewer"
                    label="Exit Interviewer*"
                    options={exitInterviewerOptions}
                    keyGetter={(item) => item.id}
                    titleGetter={(item) => item.fullName}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Checkbox
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    checked={formik.values?.eligibleForRehire}
                    color="secondary"
                    name="eligibleForRehire"
                    label="Eligible For Rehire"
                  />
                </Grid>
              </Grid>

              <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

              <Grid item xs={12} md={12}>
                <InputField
                  error={Boolean(formik.touched.resReasons && formik.errors.resReasons)}
                  helperText={formik.touched.resReasons && formik.errors.resReasons}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values?.resReasons}
                  name="resReasons"
                  id="resReasons"
                  label="Resignation Reasons"
                  multiline
                  fullWidth
                />
              </Grid>
            </Box>
          </PerfectScrollbar>

          <Box sx={separationStyle.buttonWrapper}>
            <Divider sx={separationStyle.Divider} />
            <Box sx={separationStyle.flexWrapper}>
              <Button size="large" type="submit">
                Initiate Separation
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

InitiateEmployeeSeparation.defaultProps = {
  handleSubmit: () => {},
  employeeSeparationInstance: {},
  seperationOptions: [{ id: 'Retirement', name: 'Retirement' }],
  managerOptions: [{ empID: 'EMP-190-ANSR', empStatus: 'ACTIVE', fullName: 'Adithya Kumar', id: '309911' }],
  exitInterviewerOptions: [{ empID: 'EMP-190-ANSR', empStatus: 'ACTIVE', fullName: 'Adithya Kumar', id: '309911' }],
};

InitiateEmployeeSeparation.propTypes = {
  handleSubmit: PropTypes.func,
  employeeSeparationInstance: PropTypes.shape({
    fullName: PropTypes.string,
    profile: PropTypes.shape({
      noticePeriod: PropTypes.string,
      noticePeriodTimeUnit: PropTypes.string,
      employeeID: PropTypes.string,
    }),
  }),
  seperationOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
  managerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      empID: PropTypes.string,
      empStatus: PropTypes.string,
      fullName: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
  exitInterviewerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      empID: PropTypes.string,
      empStatus: PropTypes.string,
      fullName: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
};

export default InitiateEmployeeSeparation;
