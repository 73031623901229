import { validateDateElseHyphen, validateNumberElseHyphen } from 'utils/commonUtils';

const columns = [
  {
    fieldName: 'expenseReportID',
    name: '',
    label: 'Expense Report ID',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'employee',
    name: '',
    label: 'Employee',
    formatValue: ({ row }) => row?.employee?.fullName,
  },
  {
    fieldName: 'displayName',
    name: '',
    label: 'Report Name',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'totalExpensedAmount',
    name: '',
    label: 'Expensed Amount',
    formatValue: ({ row, column }) =>
      `${row?.settlementCurrency?.symbol} ${validateNumberElseHyphen(row[column.fieldName])}`,
  },
  {
    fieldName: 'totalAmountToBeReimbursed',
    name: '',
    label: 'Reimbursable Amount',
    formatValue: ({ row, column }) =>
      `${row?.settlementCurrency?.symbol} ${validateNumberElseHyphen(row[column.fieldName])}`,
  },
  {
    fieldName: 'totalApprovedAmount',
    name: '',
    label: 'Approved Amount',
    formatValue: ({ row, column }) =>
      `${row?.settlementCurrency?.symbol} ${validateNumberElseHyphen(row[column.fieldName])}`,
  },
  {
    fieldName: 'businessPurpose',
    name: '',
    label: 'Business Purpose',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'startDate',
    name: '',
    label: 'Start Date',
    formatValue: ({ row, column }) => {
      return validateDateElseHyphen(row[column.fieldName], 'MMM DD, YYYY');
    },
  },
  {
    fieldName: 'endDate',
    name: '',
    label: 'End Date',
    formatValue: ({ row, column }) => {
      return validateDateElseHyphen(row[column.fieldName], 'MMM DD, YYYY');
    },
  },
  {
    fieldName: 'reportStatus',
    name: '',
    label: 'Report Status',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
];

const useColumns = () => {
  return [columns];
};

export default useColumns;
