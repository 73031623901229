export const bulkUploadStyle = {
  chooseFileWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 1,
    borderWidth: 0.125,
    borderStyle: 'solid',
    borderColor: 'other.200',
    borderRadius: 0.5,
    overflow: 'hidden',
    boxSizing: 'border-box',
  },
  typographyWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
};
