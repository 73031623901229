import { createSlice } from '@reduxjs/toolkit';

const payrollCalendars = createSlice({
  name: 'payrollCalendars',
  initialState: {
    selectedPayrollCalendars: [],
    payrollCalendars: {
      count: 0,
      data: [],
      states: {},
      extendedDef: {},
    },
  },
  reducers: {
    updatePayrollCalendars(state, action) {
      const { payrollCalendarInstanceList = [], payrollCalendarInstanceCount } = action.payload;
      state.payrollCalendars.data = payrollCalendarInstanceList;
      state.payrollCalendars.count = payrollCalendarInstanceCount;
    },
    updateSelectedPayrollCalendars(state, action) {
      const { isChecked, rowId } = action.payload;

      if (isChecked) {
        state.selectedPayrollCalendars.push(rowId);
        return;
      }
      state.selectedPayrollCalendars = state.selectedPayrollCalendars.filter((selectedRow) => selectedRow !== rowId);
    },
    updateAllSelectedPayrollCalendars(state, action) {
      const { isChecked } = action.payload;
      state.selectedPayrollCalendars = [];
      if (isChecked) {
        state.payrollCalendars.data.map((row) => state.selectedPayrollCalendars.push(row.id));
        return;
      }
      state.selectedPayrollCalendars = [];
    },
    setBulkUpdatePayheadIds(state, action) {
      state.bulkUpdatePayrollCalendars = action.payload;
    },
  },
});

export const { updatePayrollCalendars, updateSelectedPayrollCalendars, updateAllSelectedPayrollCalendars } =
  payrollCalendars.actions;

export default payrollCalendars.reducer;
