import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { persistReducer } from 'redux-persist';
// project imports
import payrollCalendars from './slices/company/salary/payrollCalendars';
import customization from './slices/theme/customization';
import session from './slices/user';
import payheads from './slices/company/salary/payheads';
import employees from './slices/company/employees/employee';
import profile from './slices/profile/profile';
import compareTDS from './slices/home/myPay/compareTDS';
import salarySlip from './slices/home/myPay/salarySlip';
import uploadInvestmentProofs from './slices/home/myPay/uploadInvestmentProofs';
import challans from './slices/company/salary/challans';
import pastEmployerTaxes from './slices/company/salary/pastEmployerTaxes';
import documents from './slices/documents/documents';
import myLeaves from './slices/leaves/myLeaves';
import separations from './slices/company/employees/separations';
import dashboard from './slices/dashboard/dashboard';
import expenses from './slices/expenses/expenses';
import queries from './slices/queries/queries';
import lifeCycleRecords from './slices/company/lifeCycleRecords';
import myPay from './slices/home/myPay';

const combinedReducers = combineReducers({
  payheads,
  payrollCalendars,
  customization,
  session,
  employees,
  challans,
  profile,
  compareTDS,
  salarySlip,
  uploadInvestmentProofs,
  pastEmployerTaxes,
  documents,
  myLeaves,
  separations,
  dashboard,
  expenses,
  queries,
  lifeCycleRecords,
  myPay,
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'challans',
    'compareTDS',
    'customization',
    'documents',
    'employees',
    'expenses',
    'myLeaves',
    'pastEmployerTaxes',
    'payheads',
    'payrollCalendars',
    'profile',
    'separations',
    'queries',
    'dashboard',
    'salarySlip',
    'uploadInvestmentProofs',
    'lifeCycleRecords',
    'myPay',
  ],
};

const rootReducer = (state, action) => {
  if (action.type === 'session/logout') {
    Object.keys(state).forEach((key) => {
      storage.removeItem(`persist:${key}`);
    });
    state = undefined;
  }
  return combinedReducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export * from './thunks/company/employees';
export * from './thunks/company/Salary/challan';
export * from './thunks/home/myPay/salarySlip';
export * from './thunks/documents/documents';
export * from './thunks/leaves/myLeaves';
export * from './thunks/company/separations';
export * from './thunks/dashboard/dashboard';
export * from './thunks/home/myPay/compareTDS';
export * from './thunks/expenses/expenses';
export * from './thunks/profile/profile';
export * from './thunks/home/myPay/uploadInvestmentProofs';
export * from './thunks/queries/queries';
export * from './thunks/company/lifeCycleRecords';
export * from './thunks/home/myPay';
export default store;
