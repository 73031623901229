import PropTypes from 'prop-types';
import React from 'react';
import Dialog from 'components/Molecules/Dialog';
import { calendarStyle } from '../style';
import BirthdayDialog from './BirthdayDialog';
import LeaveDialog from './LeaveDialog';
import DeadlineDialog from './DeadlineDialog';
import MeetingDialog from './MeetingDialog';
import TaskDialog from './TaskDialog';

function getDialogContent(type, data) {
  if (type === 'birthday') {
    return <BirthdayDialog data={data} />;
  }

  if (type === 'leaves') {
    return <LeaveDialog data={data} />;
  }

  if (type === 'deadline') {
    return <DeadlineDialog data={data} />;
  }

  if (type === 'meeting') {
    return <MeetingDialog data={data} />;
  }

  if (type === 'task') {
    return <TaskDialog data={data} />;
  }

  return <div />;
}

const CalendarDialog = ({ handleCancel, open, handleClose, data, type }) => {
  return (
    <Dialog
      open={open}
      isHeader={false}
      handleClose={handleClose}
      maxWidth="sm"
      dialogDesc={false}
      primaryBtn="Cancel"
      handlePrimaryBtn={handleCancel}
      bodyContent={getDialogContent(type, data)}
      alignmentActionBtn="end"
      sx={calendarStyle.calendarInsidePopup}
    />
  );
};
CalendarDialog.defaultProps = {
  open: true,
  handleClose: () => {},
  handleCancel: () => {},
  data: {},
  type: '',
};
CalendarDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleCancel: PropTypes.func,
  data: PropTypes.shape({
    title: PropTypes.string,
    employeeName: PropTypes.string,
    employeeImage: PropTypes.string,
    employeeDesignation: PropTypes.string,
    birthdayDate: PropTypes.string,
    deadlineDate: PropTypes.string,
    link: PropTypes.string,
    handleViewSurvey: PropTypes.func,
    leaveDuration: PropTypes.string,
    leaveType: PropTypes.string,
    leaveReason: PropTypes.string,
    agendaForMeeting: PropTypes.string,
    arrangedBy: PropTypes.string,
    meetingDate: PropTypes.string,
    meetingTime: PropTypes.string,
    handleJoinMeeting: PropTypes.func,
    handleShareLink: PropTypes.func,
    description: PropTypes.string,
    queryAddedBy: PropTypes.string,
    handleViewDetails: PropTypes.func,
  }),
  type: PropTypes.string,
};
export default CalendarDialog;
