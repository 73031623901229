const badgeColor = (status) => {
  const statusMap = {
    login: 'success.300',
    logout: 'actions.500',
    leave: 'error.300',
  };

  return statusMap[status] || 'success.300';
};

export { badgeColor };
