import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import TitleWithText from 'components/Atoms/TitleWithText';
import Typographys from 'components/Atoms/Typography';
import { contactDetailStyle } from './style';

const ContactDetail = ({ contactDetails, emergencyContactDetails }) => {
  return (
    <Grid container columnSpacing={8} sx={contactDetailStyle.wrapper}>
      <Grid item xs={12} sm={6}>
        <Grid container rowSpacing={1} columnSpacing={1}>
          <Grid item xs={12} mb={1}>
            <Typographys variant="h5" color="neutral.700">
              Contact Details
            </Typographys>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TitleWithText title="Mobile Number" text={contactDetails.mobile || '-'} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TitleWithText title="Email Address" text={contactDetails.email || '-'} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid container rowSpacing={1} columnSpacing={1}>
          <Grid item xs={12} mb={1}>
            <Typographys variant="h5" color="neutral.700">
              Emergency Contact
            </Typographys>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TitleWithText title="Name" text={contactDetails?.emergencyContactName} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TitleWithText title="Relation" text={contactDetails?.emergencyContactRelation} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TitleWithText title="Mobile Number" text={contactDetails?.emergencyContactMobile} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TitleWithText title="Email ID" text={contactDetails?.emergencyContactEmail} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid container flexDirection="column">
          <Grid item>
            <Box mt={6} mb={2}>
              <Typographys variant="h5" color="neutral.700">
                Permanent Residential Address
              </Typographys>
            </Box>
          </Grid>
          <Grid item>
            <TitleWithText title="Location Name (Optional)" text={contactDetails.permanentLocation || '-'} />
          </Grid>
          <Grid item>
            <Box mb={0.5} px={3}>
              <Typographys color="neutral.600" variant="body2">
                Address
              </Typographys>
            </Box>
            <Box px={3}>
              {contactDetails.permanentAddress1 &&
              contactDetails.permanentAddress2 &&
              contactDetails.permanentAddress3 &&
              contactDetails.permanentAddress4 ? (
                <>
                  <Typographys color="neutral.700" variant="body2">
                    {contactDetails.permanentAddress1 || '-'}
                  </Typographys>
                  <Typographys color="neutral.700" variant="body2">
                    {contactDetails.permanentAddress2 || '-'}
                  </Typographys>
                  <Typographys color="neutral.700" variant="body2">
                    {contactDetails.permanentAddress3 || '-'}
                  </Typographys>
                  <Typographys color="neutral.700" variant="body2">
                    {contactDetails.permanentAddress4 || '-'}
                  </Typographys>
                </>
              ) : (
                <Typographys color="neutral.700" variant="body2">
                  -
                </Typographys>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid container flexDirection="column">
          <Grid item>
            <Box mt={6} mb={2}>
              <Typographys variant="h5" color="neutral.700">
                Present Communication Address
              </Typographys>
            </Box>
          </Grid>
          <Grid item>
            <TitleWithText title="Location Name (Optional)" text={contactDetails.presentLocation || '-'} />
          </Grid>
          <Grid item>
            <Box mb={0.5} px={3}>
              <Typographys color="neutral.600" variant="body2">
                Address
              </Typographys>
            </Box>
            <Box px={3}>
              {contactDetails.presentAddress1 &&
              contactDetails.presentAddress2 &&
              contactDetails.presentAddress3 &&
              contactDetails.presentAddress4 ? (
                <>
                  <Typographys color="neutral.700" variant="body2">
                    {contactDetails.presentAddress1 || '-'}
                  </Typographys>
                  <Typographys color="neutral.700" variant="body2">
                    {contactDetails.presentAddress2 || '-'}
                  </Typographys>
                  <Typographys color="neutral.700" variant="body2">
                    {contactDetails.presentAddress3 || '-'}
                  </Typographys>
                  <Typographys color="neutral.700" variant="body2">
                    {contactDetails.presentAddress4 || '-'}
                  </Typographys>
                </>
              ) : (
                <Typographys color="neutral.700" variant="body2">
                  -
                </Typographys>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid container flexDirection="column">
          <Grid item>
            <Box mt={6} mb={2}>
              <Typographys variant="h5" color="neutral.700">
                Office Address
              </Typographys>
            </Box>
          </Grid>
          <Grid item>
            <TitleWithText title="Location Name (Optional)" text={contactDetails.officeLocation || '-'} />
          </Grid>
          <Grid item>
            <Box mb={0.5}>
              <Typographys color="neutral.600" variant="body2">
                Address
              </Typographys>
            </Box>
            {contactDetails.officeAddress1 &&
            contactDetails.officeAddress2 &&
            contactDetails.officeAddress3 &&
            contactDetails.officeAddress4 ? (
              <>
                <Typographys color="neutral.700" variant="body2">
                  {contactDetails.officeAddress1 || '-'}
                </Typographys>
                <Typographys color="neutral.700" variant="body2">
                  {contactDetails.officeAddress2 || '-'}
                </Typographys>
                <Typographys color="neutral.700" variant="body2">
                  {contactDetails.officeAddress3 || '-'}
                </Typographys>
                <Typographys color="neutral.700" variant="body2">
                  {contactDetails.officeAddress4 || '-'}
                </Typographys>
              </>
            ) : (
              <Typographys color="neutral.700" variant="body2">
                -
              </Typographys>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ContactDetail.defaultProps = {
  contactDetails: {
    mobile: '',
    email: '',
    maritalStatus: '',
    emergencyContactName: '',
    emergencyContactRelation: '',
    emergencyContactMobile: '',
    emergencyContactEmail: '',
    permanentLocation: '',
    permanentAddress1: '',
    permanentAddress2: '',
    permanentAddress3: '',
    permanentAddress4: '',
    presentLocation: '',
    presentAddress1: '',
    presentAddress2: '',
    presentAddress3: '',
    presentAddress4: '',
    officeLocation: '',
    officeAddress1: '',
    officeAddress2: '',
    officeAddress3: '',
    officeAddress4: '',
  },
  emergencyContactDetails: {
    firstName: '',
    relation: '',
    mobileNumber: '',
    emailId: '',
  },
};

ContactDetail.propTypes = {
  contactDetails: PropTypes.shape({
    mobile: PropTypes.string,
    email: PropTypes.string,
    maritalStatus: PropTypes.string,
    emergencyContactName: PropTypes.string,
    emergencyContactRelation: PropTypes.string,
    emergencyContactMobile: PropTypes.string,
    emergencyContactEmail: PropTypes.string,
    permanentLocation: PropTypes.string,
    permanentAddress1: PropTypes.string,
    permanentAddress2: PropTypes.string,
    permanentAddress3: PropTypes.string,
    permanentAddress4: PropTypes.string,
    presentLocation: PropTypes.string,
    presentAddress1: PropTypes.string,
    presentAddress2: PropTypes.string,
    presentAddress3: PropTypes.string,
    presentAddress4: PropTypes.string,
    officeLocation: PropTypes.string,
    officeAddress1: PropTypes.string,
    officeAddress2: PropTypes.string,
    officeAddress3: PropTypes.string,
    officeAddress4: PropTypes.string,
  }),
  emergencyContactDetails: PropTypes.shape({
    firstName: PropTypes.string,
    relation: PropTypes.string,
    mobileNumber: PropTypes.string,
    emailId: PropTypes.string,
  }),
};

export default ContactDetail;
