import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Dialog from 'components/Molecules/Dialog';
import Typographys from 'components/Atoms/Typography';
import { Box } from '@mui/material';
import ChooseButton from 'components/Atoms/ChooseFile';
import Button from 'components/Atoms/Button';
import Image from 'mui-image';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ExcelIcon from 'assets/images/icons/excel.svg';
import { CircularProgress } from '@material-ui/core';
import { bulkUploadStyle } from './style';

const BulkUploadDocuments = ({
  handleProceed,
  handleCancel,
  open,
  isLoading,
  isError,
  dialogTitle,
  handleChooseFile,
  handleClick,
}) => {
  const [fileName, setFileName] = useState('');

  const handleChange = (event) => {
    handleChooseFile(event);
    const file = event.target.files[0];
    setFileName(file.name);
  };

  return (
    <Dialog
      open={open}
      dialogTitle={dialogTitle}
      dialogDesc={
        <Typographys sx={{ textTransform: 'none' }} variant="body2" color="neutral.600" align="center">
          Select the option you prefer
        </Typographys>
      }
      primaryBtn="Cancel"
      secondaryBtn="Proceed"
      handlePrimaryBtn={handleProceed}
      handleCancel={handleCancel}
      primaryBtnColor="primary"
      isPrimaryBtnDisable={!fileName}
      bodyContent={
        <Box>
          <Box
            sx={{
              ...bulkUploadStyle.chooseFileWrapper,
              py: fileName ? 3 : 1.5,
            }}
          >
            <Image alt="excel-icon" width="40" height="40" src={ExcelIcon} />
            {!fileName && !isError && (
              <Typographys variant="body2" color="neutral.600" align="center">
                Bulk Upload with excel file
              </Typographys>
            )}
            <ChooseButton size="small" handleChange={handleChange} />
          </Box>
          {fileName && isLoading && !isError && (
            <Box sx={{ ...bulkUploadStyle.typographyWrapper, mt: 2.25, gap: 1.25 }}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress size={20} />
              </Box>
              <Typographys variant="subtitle2">{fileName}</Typographys>
            </Box>
          )}
          {fileName && !isLoading && !isError && (
            <Box sx={{ ...bulkUploadStyle.typographyWrapper, mt: 2, gap: 1.25 }}>
              <CheckCircleOutlineIcon color="success" />
              <Typographys>{fileName}</Typographys>
            </Box>
          )}
          {isError && (
            <Box sx={{ ...bulkUploadStyle.typographyWrapper, mt: 2 }}>
              <Typographys color="error.400" variant="subtitle1">
                The file format is not supported.
              </Typographys>
            </Box>
          )}
          <Box sx={{ display: 'flex', gap: 1, mt: 2.5 }}>
            <Typographys variant="body2" color="neutral.600" align="center">
              To download the sample excel file
            </Typographys>
            <Button onClick={handleClick} size="small" variant="text" color="secondary">
              Click here
            </Button>
          </Box>
        </Box>
      }
    />
  );
};
BulkUploadDocuments.defaultProps = {
  dialogTitle: '',
  handleChooseFile: () => {},
  handleClick: () => {},
  isLoading: false,
  isError: false,
  handleProceed: () => {},
  handleCancel: () => {},
  open: true,
};
BulkUploadDocuments.propTypes = {
  handleChooseFile: PropTypes.func,
  dialogTitle: PropTypes.string,
  handleClick: () => {},
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  open: PropTypes.bool,
  handleProceed: PropTypes.func,
  handleCancel: PropTypes.func,
};
export default BulkUploadDocuments;
