import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import VerticalTimeline from 'components/Organisms/VerticalTimeline';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import PauseCircleOutlinedIcon from '@mui/icons-material/PauseCircleOutlined';
import TimelapseOutlinedIcon from '@mui/icons-material/TimelapseOutlined';
import TimerOffOutlinedIcon from '@mui/icons-material/TimerOffOutlined';
import PerfectScrollbar from 'react-perfect-scrollbar';

const AttendanceTimer = ({ timelineData }) => {
  const icons = {
    intime: <TimerOutlinedIcon />,
    pause: <PauseCircleOutlinedIcon />,
    restart: <TimelapseOutlinedIcon />,
    close: <TimerOffOutlinedIcon />,
  };

  return (
    <Box px={3}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 47rem)' }}>
          <Typographys variant="h6"> Timings </Typographys>

          <Box sx={{ mt: 4.5 }}>
            <VerticalTimeline timelineData={timelineData} icons={icons} />
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );
};

AttendanceTimer.defaultProps = {
  timelineData: [],
};

AttendanceTimer.propTypes = {
  timelineData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      desc: PropTypes.string,
      iconKey: PropTypes.string,
    }),
  ),
};

export default AttendanceTimer;
