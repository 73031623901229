import { hasPermission } from 'utils/permissions';
import { useSelector } from 'react-redux';

const getActionItems = (services) => {
  return [
    {
      name: 'View',
      onClick: services.viewOffboardingList,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_DISPLAY_TASK'],
    },
    {
      name: 'Edit',
      onClick: services.editOffboardingList,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_TASK'],
      condtional: (row) => row?.completionStatus === 'Open',
    },
  ];
};

const useActionItems = (services) => {
  const authorities = useSelector((state) => state.session.user.authorities);

  const actions = getActionItems(services);

  const handleActionItemClick = (row) => {
    const moreFilteredActionItems = actions.filter((action) => {
      const userHasPermission = hasPermission(authorities, action.permissions);
      if ((action.condtional && !action.condtional(row)) || !userHasPermission) {
        return false;
      }
      return true;
    });
    return moreFilteredActionItems;
  };

  return [handleActionItemClick];
};

export default useActionItems;
