import React from 'react';
import PropTypes from 'prop-types';
import DashboardTemplate from 'components/Templates/DashboardTemplate';
import HomeDashboard from 'components/Organisms/Dashboard/HomeDashboard';

const HomeDashboardTemplate = ({
  Heading,
  actionMenuLists,
  attendanceStatsData,
  progressBarList,
  handleFilterDateChange,
  balanceLeave,
  employeeList,
  teamMembersList,
  holidaysLoading,
  holidaysColumn,
  holidaysRow,
  holidaysSortDirection,
  holidaysSortBy,
  holidaysOnSortChange,
  documentsLoading,
  documentsColumn,
  documentsRow,
  documentsSortDirection,
  documentsSortBy,
  documentsOnSortChange,
  documentsCount,
  documentsPage,
  documentsOnPageChange,
  documentsHandleDownload,
  docFilterLabel,
  docFilterOptions,
  handleDocSelect,
  surveysList,
  announcementList,
  calendarYear,
  events,
  handleAttendanceData,
  handleDetails,
  loadingAnnouncementDetails,
  loadingAnnouncementDetailsError,
  companyConfigHours,
}) => {
  return (
    <DashboardTemplate pageHeading={`Hello ${Heading}`} actionMenuLists={actionMenuLists}>
      <HomeDashboard
        attendanceStatsData={attendanceStatsData}
        handleAttendanceData={handleAttendanceData}
        progressBarList={progressBarList}
        handleFilterDateChange={handleFilterDateChange}
        balanceLeave={balanceLeave}
        employeeList={employeeList}
        teamMembersList={teamMembersList}
        holidaysLoading={holidaysLoading}
        holidaysColumn={holidaysColumn}
        holidaysRow={holidaysRow}
        holidaysSortDirection={holidaysSortDirection}
        holidaysSortBy={holidaysSortBy}
        holidaysOnSortChange={holidaysOnSortChange}
        documentsLoading={documentsLoading}
        documentsColumn={documentsColumn}
        documentsRow={documentsRow}
        documentsSortDirection={documentsSortDirection}
        documentsSortBy={documentsSortBy}
        documentsOnSortChange={documentsOnSortChange}
        documentsCount={documentsCount}
        documentsPage={documentsPage}
        documentsOnPageChange={documentsOnPageChange}
        documentsHandleDownload={documentsHandleDownload}
        docFilterLabel={docFilterLabel}
        docFilterOptions={docFilterOptions}
        handleDocSelect={handleDocSelect}
        surveysList={surveysList}
        announcementList={announcementList}
        calendarYear={calendarYear}
        events={events}
        handleDetails={handleDetails}
        loadingAnnouncementDetails={loadingAnnouncementDetails}
        loadingAnnouncementDetailsError={loadingAnnouncementDetailsError}
        companyConfigHours={companyConfigHours}
      />
    </DashboardTemplate>
  );
};

HomeDashboardTemplate.defaultProps = {
  Heading: '',
  actionMenuLists: [],
  attendanceStatsData: [],
  handleAttendanceData: () => {},
  progressBarList: {},
  handleFilterDateChange: () => {},
  balanceLeave: [],
  employeeList: [],
  teamMembersList: [],
  holidaysLoading: false,
  holidaysColumn: [],
  holidaysRow: [],
  holidaysSortDirection: 'asc',
  holidaysSortBy: 'id',
  holidaysOnSortChange: () => {},
  documentsLoading: false,
  documentsColumn: [],
  documentsRow: [],
  documentsSortDirection: 'asc',
  documentsSortBy: 'id',
  documentsOnSortChange: () => {},
  documentsCount: 7,
  documentsPage: 0,
  documentsOnPageChange: () => {},
  documentsHandleDownload: () => {},
  docFilterLabel: '',
  docFilterOptions: [],
  handleDocSelect: () => {},
  surveysList: [],
  announcementList: [],
  calendarYear: '',
  events: [],
  handleDetails: () => {},
  loadingAnnouncementDetails: false,
  loadingAnnouncementDetailsError: {
    message: '',
  },
};

HomeDashboardTemplate.propTypes = {
  Heading: PropTypes.string,
  actionMenuLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  attendanceStatsData: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  handleAttendanceData: PropTypes.func,
  progressBarList: PropTypes.shape(
    PropTypes.shape({
      duration: PropTypes.shape({
        hour: PropTypes.number,
        min: PropTypes.number,
      }),
      break: PropTypes.shape({
        hour: PropTypes.number,
        min: PropTypes.number,
      }),
      overtime: PropTypes.shape({
        hour: PropTypes.number,
        min: PropTypes.number,
      }),
    }),
  ),
  handleFilterDateChange: PropTypes.func,
  balanceLeave: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  employeeList: PropTypes.arrayOf,
  teamMembersList: PropTypes.arrayOf(
    PropTypes.shape({
      profileImg: PropTypes.string,
      profileName: PropTypes.string,
      emailId: PropTypes.string,
      contactNumber: PropTypes.number,
      event: PropTypes.string,
    }),
  ),
  holidaysLoading: PropTypes.bool,
  holidaysColumn: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),
  holidaysRow: PropTypes.arrayOf(
    PropTypes.shape({
      slNo: PropTypes.number,
      date: PropTypes.date,
      occasion: PropTypes.string,
    }),
  ),
  holidaysSortDirection: PropTypes.string,
  holidaysSortBy: PropTypes.string,
  holidaysOnSortChange: PropTypes.func,
  documentsLoading: PropTypes.bool,
  documentsColumn: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),
  documentsRow: PropTypes.arrayOf(
    PropTypes.shape({
      slNo: PropTypes.number,
      documentName: PropTypes.string,
      section: PropTypes.string,
    }),
  ),
  documentsSortDirection: PropTypes.string,
  documentsSortBy: PropTypes.string,
  documentsOnSortChange: PropTypes.func,
  documentsCount: PropTypes.number,
  documentsPage: PropTypes.number,
  documentsOnPageChange: PropTypes.func,
  documentsHandleDownload: PropTypes.func,
  docFilterLabel: PropTypes.string,
  docFilterOptions: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, onClick: PropTypes.func })),
  handleDocSelect: PropTypes.func,
  surveysList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      desc: PropTypes.string,
      time: PropTypes.string,
      unRead: PropTypes.bool,
    }),
  ),
  announcementList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      desc: PropTypes.string,
      time: PropTypes.string,
      unRead: PropTypes.bool,
    }),
  ),
  calendarYear: PropTypes.string,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      start: PropTypes.string,
      end: PropTypes.string,
      color: PropTypes.string,
      textColor: PropTypes.string,
      border: PropTypes.string,
      type: PropTypes.string,
      data: PropTypes.shape({
        title: PropTypes.string,
        employeeName: PropTypes.string,
        birthdayDate: PropTypes.string,
        employeeImage: PropTypes.string,
        employeeDesignation: PropTypes.string,
      }),
    }),
  ),
  handleDetails: PropTypes.func,
  loadingAnnouncementDetails: PropTypes.bool,
  loadingAnnouncementDetailsError: PropTypes.shape({
    message: PropTypes.string,
  }),
};

export default HomeDashboardTemplate;
