import React from 'react';
import PropTypes from 'prop-types';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import { Box } from '@mui/material';
import Typographys from 'components/Atoms/Typography';

const ApproverTimeline = ({ dotColor, approverLists }) => {
  return (
    <Timeline>
      {approverLists.map((list) => (
        <TimelineItem>
          <TimelineContent>
            <Box pt={1}>
              <Typographys variant="subtitle2" color="neutral.700">
                Approver {list.id}
              </Typographys>
            </Box>
          </TimelineContent>
          <TimelineSeparator>
            <TimelineDot color={dotColor}>
              <Typographys variant="subtitle2" fontWeight={600} color="secondary.500">
                {list.id}
              </Typographys>
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Box display="grid" flexDirection="column" rowGap={1}>
              <Typographys variant="h6" color="neutral.700">
                {list.name}
              </Typographys>
              <Box>
                <Typographys variant="body2" color="neutral.600" display="inline">
                  Effected By:&nbsp;
                </Typographys>
                <Typographys variant="body2" color="secondary.600" display="inline">
                  {`${list.effectedBy}`}
                </Typographys>
              </Box>
            </Box>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};
ApproverTimeline.defaultProps = {
  dotColor: 'secondary',
  approverLists: [
    {
      id: 1,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
    {
      id: 2,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
  ],
};

ApproverTimeline.propTypes = {
  dotColor: PropTypes.string,
  approverLists: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      time: PropTypes.string,
      title: PropTypes.string,
      desc: PropTypes.string,
      note: PropTypes.string,
    }),
  ),
};
export default ApproverTimeline;
