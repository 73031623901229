import { useEffect, useReducer } from 'react';
import { getPayrollCalendarById } from 'api/payrollCalendar';

const initialState = {
  isLoading: false,
  data: {},
  error: null,
};

const reducer = (state, action) => {
  const { type, payload } = action;
  let newState;
  switch (type) {
    case 'pending': {
      newState = { ...state, isLoading: true };
      break;
    }
    case 'resolved': {
      newState = { ...state, isLoading: false, data: payload };
      break;
    }
    case 'rejected': {
      newState = { ...state, isLoading: false, error: payload };
      break;
    }
    default: {
      newState = state;
    }
  }
  return newState;
};

const useFetchPayrollCalendar = (payrollCalendarId) => {
  const [payrollCalendar, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const fetchPayrollCalendarById = async () => {
      if (!payrollCalendarId) {
        return;
      }

      dispatch({ type: 'pending' });
      try {
        const response = await getPayrollCalendarById(payrollCalendarId);
        if (response.data) {
          const transformedData = response.data;
          dispatch({ type: 'resolved', payload: transformedData });
        }
      } catch (error) {
        dispatch({ type: 'rejected', payload: error });
      }
    };
    fetchPayrollCalendarById();
  }, [payrollCalendarId]);

  if (!payrollCalendarId) {
    return [null];
  }

  return [payrollCalendar];
};

export default useFetchPayrollCalendar;
