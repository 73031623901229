import React from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import UploadInvestmentProofs from 'components/Organisms/MyPay/UploadProofs';

const UploadInvestmentProofsTemplate = ({
  formData,
  handleCancel,
  handleUpload,
  employeeDetails,
  handleDelete,
  handleDeleteFile,
  toastSuccessMessage,
  toastErrorMessage,
  showToast,
  toastType,
  handleSubmitInvestmentProof,
  userHasProofSubmitPermission,
  userhasProofUploadPermission,
}) => {
  return (
    <BlankTemplate pageHeading="Upload Investment Proofs" isBreadcrumb={false} padding="2rem 3rem">
      <UploadInvestmentProofs
        formData={formData}
        handleCancel={handleCancel}
        handleUpload={handleUpload}
        employeeDetails={employeeDetails}
        handleDelete={handleDelete}
        handleDeleteFile={handleDeleteFile}
        handleSubmitInvestmentProof={handleSubmitInvestmentProof}
        userHasProofSubmitPermission={userHasProofSubmitPermission}
        userhasProofUploadPermission={userhasProofUploadPermission}
      />
    </BlankTemplate>
  );
};

UploadInvestmentProofsTemplate.defaultProps = {
  formData: [],
  employeeDetails: {},
  handleCancel: () => {},
  handleUpload: () => {},
  handleDelete: () => {},
  handleDeleteFile: () => {},
  toastSuccessMessage: '',
  toastErrorMessage: '',
  showToast: false,
  toastType: 'success',
};

UploadInvestmentProofsTemplate.propTypes = {
  formData: PropTypes.arrayOf,
  employeeDetails: PropTypes.shape({}),
  handleCancel: PropTypes.func,
  handleUpload: PropTypes.func,
  handleDelete: PropTypes.func,
  handleDeleteFile: PropTypes.func,
  toastSuccessMessage: PropTypes.string,
  toastErrorMessage: PropTypes.string,
  showToast: PropTypes.bool,
  toastType: PropTypes.oneOf(['error', 'success']),
};

export default UploadInvestmentProofsTemplate;
