import { createSlice } from '@reduxjs/toolkit';

import {
  fetchBankAccountDetails,
  fetchDependents,
  fetchEducationalHistoryDetails,
  fetchEmploymentHistory,
  fetchSkillDetails,
  fetchLifeEventDetails,
  fetchVisaDetails,
  fetchMyExitDetails,
  fetchDisplayEmpExitDetails,
  fetchBasicProfileDetails,
  fetchProfileAddresses,
  fetchMyExitWithdrawDetails,
  fetchModifyEmployeeExitDetails,
} from 'store/thunks/profile/profile';

const profile = createSlice({
  name: 'profile',
  initialState: {
    profile: {
      count: 0,
      data: [],
      states: {},
      extendedDef: {},
    },
    visaDetails: [],
    employeeProfileInstance: [],
    companyConfig: {},
    bankAccounts: [],
    bankPurposeOptions: {},
    currencies: [],
    transactionTypes: [],
    accountTypes: [],
    employeeProfileInstanceDetails: {},
    employeeInstanceDetails: {},
    maritalStatusOptionsData: [],
    bloodGroupOptionsData: [],
    skillDetails: [],
    lifeEventDetails: [],
    lifeEventOptions: [],
    eventType: [],
    employmentHistoryDetails: [],
    educationHistoryDetails: [],
    dependentsData: [],
    genderOptions: {},
    relationshipMap: {},
    contactReference: {},
    exitDetails: [],
    displayExitDetails: [],
    contactDetails: { officeAddress: {}, permanentHomeAddress: {}, presentCommunicationAddress: {} },
    skillAttainmentLevels: [],
    skillsList: [],
    myExitWithdrawDetails: [],
    modifyEmployeeExitDetails: [],
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchBankAccountDetails.fulfilled, (state, action) => {
      state.bankAccounts = action.payload.data.bankAccounts;
      state.currencies = action.payload.data.currencies;
      state.transactionTypes = action.payload.data.transactionTypes;
      state.accountTypes = action.payload.data.accountTypes;
      state.bankPurposeOptions = action.payload.data.bankPurposeOptions;
    });
    builder.addCase(fetchBasicProfileDetails.fulfilled, (state, action) => {
      state.employeeProfileInstanceDetails = action.payload.data?.employeeProfileInstance;
      state.employeeInstanceDetails = action.payload.data?.employeeInstance;
      state.maritalStatusOptionsData = action.payload.data?.maritalStatus;
      state.bloodGroupOptionsData = action.payload.data?.bloodGroupTypes;
      state.companyConfig = action.payload.data?.companyConfig;
    });
    builder.addCase(fetchSkillDetails.fulfilled, (state, action) => {
      state.skillDetails = action.payload.data?.skillRecords;
      state.skillAttainmentLevels = action.payload.data?.skillAttainmentLevels;
      state.skillsList = action.payload.data?.skillsList;
      state.employeeProfileInstance = action.payload?.data?.employeeProfileInstance;
    });
    builder.addCase(fetchLifeEventDetails.fulfilled, (state, action) => {
      state.lifeEventDetails = action.payload?.eventRecords;
      state.eventType = action.payload?.eventType;
      state.employeeProfileInstance = action.payload?.employeeProfileInstance;
    });
    builder.addCase(fetchEmploymentHistory.fulfilled, (state, action) => {
      state.employmentHistoryDetails = action.payload.data.pastEmployeeRecords;
    });
    builder.addCase(fetchEducationalHistoryDetails.fulfilled, (state, action) => {
      state.educationHistoryDetails = action.payload.data.educationRecords;
    });
    builder.addCase(fetchDependents.fulfilled, (state, action) => {
      state.dependentsData = action.payload.data.dependents;
      state.genderOptions = action.payload.data.genderOptions;
      state.relationshipMap = action.payload.data.relationOptions;
      state.contactReference = action.payload.data.contactReference;
    });
    builder.addCase(fetchMyExitDetails.fulfilled, (state, action) => {
      state.exitDetails = action.payload;
    });
    builder.addCase(fetchDisplayEmpExitDetails.fulfilled, (state, action) => {
      state.displayExitDetails = action.payload;
    });
    builder.addCase(fetchVisaDetails.fulfilled, (state, action) => {
      const { visaRecords = [], employeeProfileInstance = [] } = action.payload;
      state.visaDetails = visaRecords;
      state.employeeProfileInstance = employeeProfileInstance;
    });
    builder.addCase(fetchProfileAddresses.fulfilled, (state, action) => {
      state.contactDetails.permanentHomeAddress = action.payload.data?.employeeProfileInstance?.permanentHomeAddress;
      state.contactDetails.presentCommunicationAddress =
        action.payload.data?.employeeProfileInstance?.presentCommunicationAddress;
      state.contactDetails.officeAddress = action.payload.data?.employeeProfileInstance?.officeAddress;
    });
    builder.addCase(fetchMyExitWithdrawDetails.fulfilled, (state, action) => {
      state.myExitWithdrawDetails = action.payload;
    });
    builder.addCase(fetchModifyEmployeeExitDetails.fulfilled, (state, action) => {
      state.modifyEmployeeExitDetails = action.payload;
    });
  },
});

export default profile.reducer;
