import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Divider, useTheme, TextField, Autocomplete } from '@mui/material';
import { validateDateElseHyphen } from 'utils/commonUtils';
import TitleWithText from 'components/Atoms/TitleWithText';
import Typography from 'components/Atoms/Typography';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import { SurveyStyle } from '../style';

const setCheckBoxState = (values) => {
  return values?.length > 0
    ? values.map((item) => ({
        id: item.id,
        gradingScale: item.gradingScale,
        question: item.question,
        gradingScaleVal: '',
      }))
    : [];
};

const SurveysDetails = ({ loading, handleSubmit, surveySheetDetails }) => {
  const [initialValues, setInitialValues] = useState([]);
  const theme = useTheme();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues || [],
    validateOnMount: true,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    const formData = setCheckBoxState(surveySheetDetails?.surveyQuestions);
    setInitialValues(formData);
  }, []);

  return (
    <Box>
      <Grid container columnSpacing={9}>
        <Grid item xs={12} md={4}>
          <TitleWithText title="Title" text={surveySheetDetails?.title || '-'} />
        </Grid>

        <Grid item xs={12} md={4}>
          <TitleWithText title="Brief Description" text={surveySheetDetails?.briefDescription || '-'} />
        </Grid>

        <Grid item xs={12} md={4}>
          <TitleWithText
            title="Survey Period"
            text={`${validateDateElseHyphen(surveySheetDetails?.startDate, 'MMMM DD, YYYY')} - ${validateDateElseHyphen(
              surveySheetDetails?.endDate,
              'MMMM DD, YYYY',
            )}`}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TitleWithText
            title="Employee"
            text={`${surveySheetDetails?.employee?.fullName || '-'} (${
              surveySheetDetails?.employee?.profile?.employeeID || '-'
            })`}
          />
        </Grid>
      </Grid>

      <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />
      <Typography variant="h6" color="neutral.800">
        List of Questions
      </Typography>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <Box sx={SurveyStyle.boxWrapper}>
          {formik?.values?.length > 0 &&
            formik?.values?.map((item, index) => (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6" color="neutral.800">
                      Question: #{index + 1}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={2}>
                        <Typography variant="body1" sx={SurveyStyle.questionsTitle}>
                          Question
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="body2">{item?.question}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={2}>
                        <Typography variant="body1" sx={SurveyStyle.questionsTitle}>
                          Rating
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Autocomplete
                          autoComplete
                          onChange={(event, value) => {
                            if (value !== undefined) {
                              formik.values[index].gradingScaleVal = value?.id;
                            }
                          }}
                          value={formik.value?.[index]}
                          id={`questions-${index}`}
                          options={item?.gradingScale?.surveyGrades || []}
                          getOptionLabel={(option) => option?.name}
                          style={{ width: 500 }}
                          renderInput={(params) => <TextField {...params} label="Select rating" variant="outlined" />}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </Box>
        <LoadingButton loading={loading} color="primary" size="large" type="submit" variant="contained">
          Submit
        </LoadingButton>
      </Box>
    </Box>
  );
};

SurveysDetails.defaultProps = {
  loading: false,
  surveySheetDetails: {},
  handleSubmit: () => {},
};

SurveysDetails.propTypes = {
  loading: PropTypes.bool,
  surveySheetDetails: PropTypes.shape(),
  handleSubmit: PropTypes.func,
};

export default SurveysDetails;
