/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import { tdsStyle } from 'components/Organisms/MyPay/TDS/View/style';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Button from 'components/Atoms/Button';
import ChooseFile from 'components/Atoms/ChooseFile';
import DeleteIcon from '@mui/icons-material/Delete';
import InvestmentDeclarationForm from './Form';
import InvestmentDeclarationData from './InvestmentDeclarationData';

const InvestmentDeclarations = ({
  allowEdit,
  form12bAttachment,
  downloadForm12bAttachment,
  declarationDetails,
  investmentDeclarations,
  formik,
  downloadAttachment,
  handleFile,
  investmentDeclarationFormSortDirection,
  investmentDeclarationFormSortBy,
  investmentDeclarationFormOnSortChange,
  investmentDeclarationDataSortDirection,
  investmentDeclarationDataSortBy,
  investmentDeclarationDataOnSortChange,
}) => {
  return (
    <Box>
      <Box sx={tdsStyle.msgWrapper}>
        <Typographys variant="body2" color="neutral.800">
          FORM 12BB (Signed and scanned copy) (mandatory for finalizing proof submissions) **
        </Typographys>

        <Box sx={tdsStyle.msgActionBtn}>
          {!allowEdit &&
            (!form12bAttachment?.id ? (
              <Typographys variant="subtitle1" color="neutral.600">
                Form 12BB submission pending. Please submit the same along with your proofs when requested by your
                administrator.
              </Typographys>
            ) : (
              <Button
                variant="text"
                color="secondary"
                onClick={() => downloadForm12bAttachment(form12bAttachment?.id, form12bAttachment?.fileName)}
                startIcon={<AttachFileIcon />}
              >
                {form12bAttachment?.fileName}
              </Button>
            ))}
        </Box>
      </Box>

      {allowEdit && (
        <Box>
          {!form12bAttachment?.id && (
            <Typographys variant="subtitle1" color="neutral.600">
              Form 12BB submission pending. Please submit the same along with your proofs when requested by your
              administrator.
            </Typographys>
          )}
          <Typographys variant="caption" color="neutral.600">
            ** You can download your Form 12BB from My Pay {`->`} Form 12BB on the main Menu.
          </Typographys>
        </Box>
      )}

      <Box mt={2} sx={tdsStyle.investmentDeclarationTable}>
        {allowEdit ? (
          <InvestmentDeclarationForm
            handleFile={handleFile}
            formik={formik}
            sortDirection={investmentDeclarationFormSortDirection}
            sortBy={investmentDeclarationFormSortBy}
            onSortChange={investmentDeclarationFormOnSortChange}
            investmentDeclarations={investmentDeclarations}
          />
        ) : (
          <Box mt={3.5}>
            <InvestmentDeclarationData
              formik={formik}
              investmentDeclarations={investmentDeclarations}
              declarationDetails={declarationDetails}
              downloadAttachment={downloadAttachment}
              sortDirection={investmentDeclarationDataSortDirection}
              sortBy={investmentDeclarationDataSortBy}
              onSortChange={investmentDeclarationDataOnSortChange}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
InvestmentDeclarations.defaultProps = {
  declarationDetails: [],
  form12bAttachment: '',
  downloadForm12bAttachment: () => {},
  downloadAttachment: () => {},
  allowEdit: false,
  formik: {
    handleChange: () => {},
    handleBlur: () => {},
    values: {
      property: [],
      houseRent: [],
      investmentDec: [],
    },
    error: {},
  },
  handleFile: () => {},
  investmentDeclarationFormSortDirection: 'desc',
  investmentDeclarationFormSortBy: 'id',
  investmentDeclarationFormOnSortChange: () => {},
  investmentDeclarationDataSortDirection: 'desc',
  investmentDeclarationDataSortBy: 'id',
  investmentDeclarationDataOnSortChange: () => {},
};
InvestmentDeclarations.propTypes = {
  declarationDetails: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      proof: PropTypes.string,
    }),
  ),
  form12bAttachment: PropTypes.string,
  downloadForm12bAttachment: PropTypes.func,
  downloadAttachment: PropTypes.func,
  allowEdit: PropTypes.bool,
  formik: PropTypes.shape({
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
  }),
  handleFile: PropTypes.func,
  investmentDeclarationFormSortDirection: PropTypes.string,
  investmentDeclarationFormSortBy: PropTypes.string,
  investmentDeclarationFormOnSortChange: PropTypes.func,
  investmentDeclarationDataSortDirection: PropTypes.string,
  investmentDeclarationDataSortBy: PropTypes.string,
  investmentDeclarationDataOnSortChange: PropTypes.func,
};

export default InvestmentDeclarations;
