import React, { useCallback, useEffect, useState } from 'react';
import { Box, Card, CardContent, Container, Grid, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TableExtended from 'components/Molecules/TableExtended';
import useController from 'hooks/useController';
import DropdownMenu from 'components/Atoms/DropdownMenu';
import { useDispatch, useSelector } from 'react-redux';
import usePaginationAttributes from 'hooks/use-pagination-attributes';
import { updatePayrollCalendars } from 'store/slices/company/salary/payrollCalendars';
import { listPayrollCalendars, searchPayrollCalendars } from 'api/payrollCalendar';
import NoRecordsMessage from 'components/Organisms/CommonPageUtils/NoRecordsFound';
import useColumns from './useColumns';
import { styles } from './styles';
import { PayrollCalendarFilters } from './payheadFilter';

const PayrollCalendarsList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [controller, handlePageChange, handleSortChange, handleFiltersApply, handleFiltersClear] = useController();
  const [columns] = useColumns();
  const { payrollCalendars } = useSelector((state) => state.payrollCalendars);
  const { pageSize } = usePaginationAttributes();

  const fetchData = useCallback(
    async (searched, filters, sort, sortBy, page) => {
      setLoading(true);
      try {
        if (searched) {
          const query = new URLSearchParams({});
          if (filters.length) {
            filters.forEach((filter) => query.append(filter.property, filter.value));
          }
          query.append('all', 'true');
          const response = await searchPayrollCalendars(query);
          if (response?.data?.data) {
            dispatch({ type: 'resolved', payload: response?.data?.data });
          }
        } else {
          const query = new URLSearchParams({});
          // if (sortBy) {
          //   query.append('sort', sortBy);
          //   query.append('order', sort);
          // }
          query.append('offset', page * pageSize);
          query.append('max', pageSize);
          const response = await listPayrollCalendars(query);
          if (response) {
            dispatch(updatePayrollCalendars(response));
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, pageSize],
  );

  useEffect(() => {
    if (fetchData)
      fetchData(controller?.searched, controller?.filters, controller?.sort, controller?.sortBy, controller?.page);
  }, [controller, fetchData]);

  return (
    <Box sx={styles.growBox}>
      <Container maxWidth="lg">
        <Grid container direction="column">
          <Grid item container justifyContent="flex-start" alignItems="center" sx={{ pb: 1.5 }}>
            <Grid item>
              <Typography variant="h6" color="text.primary">
                Payroll Calendar
              </Typography>
            </Grid>
          </Grid>
          <Grid item sx={{ maxWidth: '100% !important' }}>
            <Card>
              <CardContent>
                <Stack direction="row" justifyContent="end" alignItems="center" sx={{ pb: 2 }}>
                  <PayrollCalendarFilters
                    disabled={loading}
                    filters={controller.filters}
                    onFiltersApply={handleFiltersApply}
                    onFiltersClear={handleFiltersClear}
                  />
                  <DropdownMenu
                    menuLists={[
                      {
                        id: 1,
                        label: 'Add new',
                        onClick: () => {
                          navigate('/company/salary/payrollCalendar/add');
                        },
                      },
                    ]}
                  />
                </Stack>

                {!loading && payrollCalendars?.data.length === 0 ? (
                  <NoRecordsMessage width="20rem" height="20rem" />
                ) : (
                  <TableExtended
                    loading={loading}
                    columns={columns}
                    rows={payrollCalendars.data}
                    keyColumn="id"
                    sortDirection={controller?.sort}
                    sortBy={controller?.sortBy}
                    onSortChange={handleSortChange}
                    page={controller?.page}
                    onPageChange={handlePageChange}
                    count={payrollCalendars.count}
                    showPagination
                    onRowClick={(row) => navigate(`/company/salary/payrollCalendar/view/${row.id}`)}
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default PayrollCalendarsList;
