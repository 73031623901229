import React from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import PropTypes from 'prop-types';
import Form12BBUpload from 'components/Organisms/MyPay/Form12BB/Upload';

const Form12BBUploadTemplate = ({ formData, handleSubmit, handleAttachment, loading }) => {
  return (
    <BlankTemplate pageHeading="Form 12BB" isBreadcrumb={false} padding="2rem 3rem">
      <Form12BBUpload
        formData={formData}
        handleSubmit={handleSubmit}
        handleAttachment={handleAttachment}
        loading={loading}
      />
    </BlankTemplate>
  );
};

Form12BBUploadTemplate.defaultProps = {
  formData: {},
  handleSubmit: () => {},
  handleAttachment: () => {},
  loading: false,
};

Form12BBUploadTemplate.propTypes = {
  formData: PropTypes.shape,
  handleSubmit: PropTypes.func,
  handleAttachment: PropTypes.func,
  loading: PropTypes.bool,
};

export default Form12BBUploadTemplate;
