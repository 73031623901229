import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Typography from 'components/Atoms/Typography';
import Button from 'components/Atoms/Button';
import DownloadIcon from '@mui/icons-material/Download';

const InfoCardWithIcon = ({ label, value, icon, isButton, handleDownload }) => {
  return (
    <Box
      bgcolor="white"
      border="1px solid"
      borderColor="neutral.300"
      padding="2rem"
      borderRadius="0.75rem"
      width="32.87%"
    >
      <Box display="flex" alignItems="start" justifyContent="space-between">
        <Box display="flex" alignItems="center" justifyContent="left">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgcolor="secondary.600"
            borderRadius="50%"
            padding="2.5rem"
          >
            {icon}
          </Box>
          <Box paddingLeft="2rem" marginTop="1rem">
            {!!label && (
              <Typography
                variant="caption"
                fontWeight={600}
                color="secondary.500"
                sx={{ textTransform: 'uppercase', letterSpacing: '0.15rem' }}
              >
                {label}
              </Typography>
            )}
            {!!value && (
              <Typography variant="h6" color="neutral.800" sx={{ mt: 0.5 }}>
                {value}
              </Typography>
            )}
          </Box>
        </Box>
        {!!isButton && (
          <Box sx={{ mt: 2.5 }}>
            <Button
              startIcon={<DownloadIcon fontSize="2.5rem" />}
              size="small"
              color="primary"
              variant="contained"
              onClick={handleDownload}
            >
              Download
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

InfoCardWithIcon.defaultProps = {
  label: '',
  value: '',
  icon: '',
  isButton: false,
  handleDownload: () => {},
};

InfoCardWithIcon.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.string,
  isButton: PropTypes.bool,
  handleDownload: PropTypes.func,
};

export default InfoCardWithIcon;
