import React from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import PropTypes from 'prop-types';
import ViewOffboarding from 'components/Organisms/Employee/Company/Separation/ViewOffboarding';

const ViewOffboardingTemplate = ({
  pageHeading,
  handleLog,
  handleEdit,
  handleDelete,
  handleAccept,
  exitDetails,
  loading,
  tables,
  columns,
  count,
  selectedItems,
  onSelect,
  onSelectAll,
  keyColumn,
  roleBasedMenu,
}) => {
  const breadcrumbData = [
    { id: 1, name: 'Separation', path: '/myteam/separations', isActive: false },
    { id: 2, name: 'Exit task list', isActive: true },
  ];

  return (
    <BlankTemplate pageHeading={pageHeading} breadcrumbData={breadcrumbData}>
      <ViewOffboarding
        handleAccept={handleAccept}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        handleLog={handleLog}
        exitDetails={exitDetails}
        {...{ loading, tables, columns, count, selectedItems, onSelect, onSelectAll, keyColumn, roleBasedMenu }}
      />
    </BlankTemplate>
  );
};

ViewOffboardingTemplate.defaultProps = {
  pageHeading: 'OffBoarding CheckList',
  handleLog: () => {},
  handleEdit: () => {},
  handleAccept: () => {},
  handleDelete: () => {},
  exitDetails: {},
};

ViewOffboardingTemplate.propTypes = {
  pageHeading: PropTypes.string,
  exitDetails: PropTypes.shape({}),
  handleLog: PropTypes.func,
  handleEdit: PropTypes.func,
  handleAccept: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default ViewOffboardingTemplate;
