import { createSlice } from '@reduxjs/toolkit';

const payheads = createSlice({
  name: 'payheads',
  initialState: {
    selectedPayheads: [],
    payheads: {
      count: 0,
      data: [],
      states: {},
      extendedDef: {},
    },
  },
  reducers: {
    updatePayheads(state, action) {
      const { companyPayheadInstanceList = [], companyPayheadInstanceCount } = action.payload;
      state.payheads.data = companyPayheadInstanceList;
      state.payheads.count = companyPayheadInstanceCount;
    },
    updateSelectedPayheads(state, action) {
      const { isChecked, rowId } = action.payload;

      if (isChecked) {
        state.selectedPayheads.push(rowId);
        return;
      }
      state.selectedPayheads = state.selectedPayheads.filter((selectedRow) => selectedRow !== rowId);
    },
    updateAllSelectedPayheads(state, action) {
      const { isChecked } = action.payload;
      state.selectedPayheads = [];
      if (isChecked) {
        state.payheads.data.map((row) => state.selectedPayheads.push(row.id));
        return;
      }
      state.selectedPayheads = [];
    },
    setBulkUpdatePayheadIds(state, action) {
      state.bulkUpdatePayheads = action.payload;
    },
  },
});

export const { updatePayheads, updateSelectedPayheads, updateAllSelectedPayheads } = payheads.actions;

export default payheads.reducer;
