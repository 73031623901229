import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import LeaveDetailsTab from './LeaveDetailsTab';
import LeaveHighlightCard from './LeaveHighlightCard';

const MyLeaveDashboard = ({ totalLeaveTransactions, leaveData, leaveTabData, onTabChange, selectedTab }) => {
  return (
    <>
      <Box mb={2}>
        <LeaveHighlightCard totalLeaveTransactions={totalLeaveTransactions} leaveData={leaveData} />
      </Box>

      <LeaveDetailsTab
        totalLeaveTransactions={totalLeaveTransactions}
        leaveData={leaveData}
        leaveTabData={leaveTabData}
        onTabChange={onTabChange}
        selectedTab={selectedTab}
      />
    </>
  );
};

MyLeaveDashboard.defaultProps = {
  totalLeaveTransactions: 0,
  leaveData: [],
  onTabChange: () => {},
  leaveTabData: [],
  selectedTab: 0,
};

MyLeaveDashboard.propTypes = {
  totalLeaveTransactions: PropTypes.number,
  leaveData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
      openingBalance: PropTypes.number,
      leavesAvailed: PropTypes.number,
      currentBalance: PropTypes.number,
      transactions: PropTypes.number,
      pendingApprovalBalance: PropTypes.number,
    }),
  ),
  onTabChange: PropTypes.func,
  leaveTabData: PropTypes.arrayOf(
    PropTypes.shape({
      openingLeave: PropTypes.number,
      leavesAvailed: PropTypes.number,
      transactions: PropTypes.number,
      pendingApproval: PropTypes.number,
      remainingBalance: PropTypes.number,
      loading: PropTypes.bool,
      leaveTransactionsColumn: PropTypes.arrayOf(
        PropTypes.shape({
          fieldName: PropTypes.string,
          label: PropTypes.string,
          formatValue: PropTypes.func,
        }),
      ),
      leaveTransactionsRow: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          startDate: PropTypes.string,
          endDate: PropTypes.string,
          leavesAvailed: PropTypes.string,
          leavesAccounted: PropTypes.number,
          leavesDeducted: PropTypes.number,
        }),
      ),
      page: PropTypes.number,
      sortDirection: PropTypes.string,
      sortBy: PropTypes.func,
      handleSort: PropTypes.func,
      count: PropTypes.number,
      onPageChange: PropTypes.func,
      onSortChange: PropTypes.func,
    }),
  ),
  selectedTab: PropTypes.number,
};

export default MyLeaveDashboard;
