import ApartmentIcon from '@mui/icons-material/Apartment';
import PersonIcon from '@mui/icons-material/Person';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';

const company = {
  id: 'company',
  title: 'Company',
  icon: ApartmentIcon,
  type: 'group',
  url: '',
  children: [
    {
      id: 'company_employee',
      title: 'Employee',
      icon: PersonIcon,
      type: 'collapse',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_LIST_SECUSER'],
      children: [
        {
          id: 'companyEmployeesList',
          title: 'Employees List',
          icon: FeaturedPlayListIcon,
          type: 'item',
          url: 'company/employee/employees-list',
          permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_LIST_SECUSER'],
        },
      ],
    },
    {
      id: 'lifecycle_records',
      title: 'Lifecycle Records',
      icon: PersonIcon,
      type: 'collapse',
      permissions: [
        'ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_LIST_CASE_RECORDS',
        'ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_LIST_CASE_RECORDS_PENDING_MY_APPROVAL',
      ],
      children: [
        {
          id: 'lifeCycleCaseRecords',
          title: 'Life Cycle Case Records List',
          icon: LocalPoliceIcon,
          type: 'item',
          url: '/company/life-cycle/life-cycle-records',
          permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_LIST_CASE_RECORDS'],
        },
        {
          id: 'lifeCycleCaseRecordsPendingApproval',
          title: 'Life Cycle Case Records Pending Approval',
          icon: LocalPoliceIcon,
          type: 'item',
          url: '/company/life-cycle/life-cycle-records-pending-approval',
          permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_LIST_CASE_RECORDS_PENDING_MY_APPROVAL'],
        },
      ],
    },
    {
      id: 'salary',
      title: 'Salary',
      icon: LocalAtmIcon,
      type: 'collapse',
      permissions: [
        'ROLE_BASE_PERMISSION_PAY_INDEX_LIST_PAYHEADS',
        'ROLE_BASE_PERMISSION_PAY_INDEX_LIST_PAYROLL_CALENDARS',
      ],
      children: [
        {
          id: 'payHeads',
          title: 'Pay Heads',
          icon: ApartmentIcon,
          type: 'item',
          url: '/company/salary/payheads',
          permissions: ['ROLE_BASE_PERMISSION_PAY_INDEX_LIST_PAYHEADS'],
        },
        // TODO: to be added later
        // {
        //   id: 'paystructures',
        //   title: 'Pay Structures',
        //   icon: DeviceHubIcon,
        //   type: 'item',
        //   url: '/company/salary/paystructures',
        // },
        {
          id: 'payrollCalendars',
          title: 'Payroll Calendars',
          icon: CalendarMonthIcon,
          type: 'item',
          url: '/company/salary/payrollCalendar',
          permissions: ['ROLE_BASE_PERMISSION_PAY_INDEX_LIST_PAYROLL_CALENDARS'],
        },
        // TODO: to be added later
        // {
        //   id: 'challan',
        //   title: 'Challan',
        //   icon: AttachMoneyIcon,
        //   type: 'item',
        //   url: '/company/salary/challans',
        // },
        // {
        //   id: 'loansadvances',
        //   title: 'Loans and Advances',
        //   icon: SwapHorizIcon,
        //   type: 'item',
        //   url: '/company/salary/loans',
        // },
      ],
    },
    {
      id: 'listDocuments',
      title: 'Documents List',
      icon: InsertDriveFileIcon,
      type: 'item',
      url: 'company/documents',
      permissions: ['ROLE_BASE_PERMISSION_INDEX_LIST_ALL_DOCUMENTS'],
    },
    {
      id: 'listExpenses',
      title: 'Expenses List',
      icon: LocalAtmIcon,
      type: 'item',
      url: 'company/expenses',
      permissions: ['ROLE_BASE_PERMISSION_EXPENSE_INDEX_LIST_ALL_EXPENSE_REPORTS'],
    },
    {
      id: 'listQueries',
      title: 'Queries List',
      icon: HelpCenterIcon,
      type: 'item',
      url: '/company/queries',
      permissions: ['ROLE_BASE_PERMISSION_QUER_INDEX_LIST_QUERRIES'],
    },
  ],
};

export default company;
