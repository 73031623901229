export const typographyVariants = {
  MCaptions600: 'MCaptions600',
  MCaptions700: 'MCaptions700',
  MSmallBody500: 'MSmallBody500',
  MSmallBody600: 'MSmallBody600',
  MSmallBody700: 'MSmallBody700',
  MBody500: 'MBody500',
  MBody600: 'MBody600',
  MBody700: 'MBody700',
  MH4600: 'MH4600',
  MH4700: 'MH4700',
  MH3500: 'MH3500',
  MH3600: 'MH3600',
  MH3700: 'MH3700',
  MH2500: 'MH2500',
  MH2600: 'MH2600',
  MH2700: 'MH2700',
  MH1500: 'MH1500',
  MH1600: 'MH1600',
  MH1700: 'MH1700',
  MToothNumber: 'MToothNumber',
};
