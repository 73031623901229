export const profileCardStyle = {
  wrapper: {
    display: 'flex',
    backgroundColor: 'actions.100',

    p: 3,
    borderRadius: '0.75rem',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
    },
  },
  avatar: {
    width: '12.625rem',
    height: '12.625rem',
    justifyContent: 'center',
  },
  actionWrapper: {
    width: '100%',
    pl: 7,
    '@media (max-width: 600px)': {
      pl: 0,
    },
  },
  action: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 4,
    '@media (max-width: 600px)': {
      flexDirection: 'column',
    },
  },
  actionBtn: {
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      mt: 1.5,
    },
  },
  contactInfo: {
    justifyContent: 'space-between',
    columnGap: 1,
    '@media (max-width: 900px)': {
      rowGap: 1.5,
      columnGap: 0,
    },
    '@media (max-width: 600px)': {
      flexDirection: 'column',
    },
  },
  employmentInfo: {
    justifyContent: 'space-between',
    columnGap: 1,
    borderTop: '0.25rem solid',
    borderColor: 'other.200',
    mt: 2,
    pt: 0.75,
    '@media (max-width: 900px)': {
      rowGap: 1.5,
      columnGap: 0,
      justifyContent: 'flex-start',
    },
    '@media (max-width: 600px)': {
      flexDirection: 'column',
    },
  },
};
