import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Chips from 'components/Molecules/Chip';
import Typographys from 'components/Atoms/Typography';
import TableExtended from 'components/Molecules/TableExtended';
import ActionDropDown from 'components/Molecules/ActionDropDown';
import DateRangePicker from 'components/Molecules/DateRangePicker';
import NoRecordsMessage from 'components/Organisms/CommonPageUtils/NoRecordsFound';
import DropdownMenu from 'components/Molecules/DropdownMenu';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { attendanceStatus, attendanceRowColor } from 'utils/statusColor';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { validateDateElseHyphen } from 'utils/commonUtils';
import { hasPermission } from 'utils/permissions';
import { useSelector } from 'react-redux';
import { attendanceStyle } from '../style';

const RenderDateRangePicker = (handleSelect) => {
  return (
    <DateRangePicker
      placement="left-start"
      handleSubmit={(data) => handleSelect(data)}
      label={
        <Box sx={attendanceStyle.dateRange}>
          <Typographys color="inherit" variant="body2">
            Custom Date
          </Typographys>
          <ArrowForwardIosIcon sx={{ fontSize: '1.5rem' }} />
        </Box>
      }
    />
  );
};

const MyAttendance = ({
  myAttendanceColumns,
  myAttendanceRows,
  myAttendancePage,
  myAttendanceOnPageChange,
  myAttendanceSortDirection,
  myAttendanceSortBy,
  myAttendanceOnSortChange,
  myAttendanceHandleSort,
  myAttendanceLoading,
  myAttendancekeyColumn,
  myAttendanceCount,
  handleAttendanceRegularize,
  handleAttendanceDownload,
  handleMyAttendanceActionSelect,
  handleMyAttendanceThisWeek,
  handleMyAttendanceThisMonth,
  handleMyAttendanceThisYear,
  handleMyAttendanceDateRange,
}) => {
  const [filterLabel, setFilterLabel] = useState('This Week');
  const authorities = useSelector((state) => state.session.user.authorities);

  const DateChange = (data) => {
    setFilterLabel(
      `${validateDateElseHyphen(data.startDate, 'DD MMMM YYYY')} to ${validateDateElseHyphen(
        data.endDate,
        'DD MMMM YYYY',
      )}`,
    );
    handleMyAttendanceDateRange(data);
  };

  const actionOptions = [
    {
      id: 1,
      name: 'This Week',
      onClick: handleMyAttendanceThisWeek,
      permissions: ['ROLE_BASE_PERMISSION_ATT_INDEX_MY_ATTENDANCE_THIS_WEEK'],
    },
    {
      id: 2,
      name: 'This Month',
      onClick: handleMyAttendanceThisMonth,
      permissions: ['ROLE_BASE_PERMISSION_ATT_INDEX_MY_ATTENDANCE_THIS_MONTH'],
    },
    { id: 3, name: 'This Year', onClick: handleMyAttendanceThisYear, permissions: [] },
    {
      id: 4,
      formatValue: () => RenderDateRangePicker(DateChange),
      permissions: ['ROLE_BASE_PERMISSION_ATT_INDEX_MY_ATTENDANCE_CUSTOM_PERIOD'],
    },
  ];

  const menuItems = [
    {
      id: 1,
      label: 'Regularize Attendance',
      onClick: handleAttendanceRegularize,
      permissions: ['ROLE_BASE_PERMISSION_ATT_INDEX_BULK_REGULARIZE_ATTENDANCE_EXCEPTIONS'],
    },
    { id: 2, label: 'Download Attendance', onClick: handleAttendanceDownload, permissions: [] },
  ];

  const filteredActionOptions = actionOptions.filter((option) => hasPermission(authorities, option.permissions));
  const actionableMenuItems = menuItems.filter((item) => hasPermission(authorities, item.permissions));

  return (
    <Box px={3}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 47rem)' }}>
          <Box sx={attendanceStyle.actionWrapper}>
            <ActionDropDown
              options={filteredActionOptions}
              handleSelect={handleMyAttendanceActionSelect}
              label={filterLabel}
            />

            <DropdownMenu menuLists={actionableMenuItems} btnLabel="Actions" color="primary" />
          </Box>

          {!myAttendanceLoading && myAttendanceRows.length === 0 ? (
            <NoRecordsMessage width="20rem" height="20rem" />
          ) : (
            <TableExtended
              loading={myAttendanceLoading}
              rows={
                myAttendanceRows &&
                myAttendanceRows.map((i) => {
                  return {
                    ...i,
                    attendance: (
                      <Chips label={i.attendance} isShowIcon={false} color={attendanceStatus(i.attendance)} />
                    ),
                    rowColor: attendanceRowColor(i.attendance),
                  };
                })
              }
              columns={myAttendanceColumns}
              showActionList={false}
              showRowSelector={false}
              page={myAttendancePage}
              onPageChange={myAttendanceOnPageChange}
              keyColumn={myAttendancekeyColumn}
              count={myAttendanceCount}
              sortDirection={myAttendanceSortDirection}
              sortBy={myAttendanceSortBy}
              handleSort={myAttendanceHandleSort}
              onSortChange={myAttendanceOnSortChange}
            />
          )}
        </Box>
      </PerfectScrollbar>
    </Box>
  );
};

MyAttendance.defaultProps = {
  myAttendanceRows: [],
  myAttendanceColumns: [],
  myAttendancePage: 1,
  myAttendancekeyColumn: 'id',
  myAttendanceLoading: false,
  myAttendanceCount: 10,
  myAttendanceSortDirection: 'id',
  myAttendanceSortBy: 'Desc',
  myAttendanceOnSortChange: () => {},
  myAttendanceHandleSort: () => {},

  handleAttendanceRegularize: () => {},
  handleAttendanceDownload: () => {},
  handleMyAttendanceThisWeek: () => {},
  handleMyAttendanceThisMonth: () => {},
  handleMyAttendanceThisYear: () => {},
  handleMyAttendanceDateRange: () => {},
  handleMyAttendanceActionSelect: () => {},
  myAttendanceOnPageChange: () => {},
};

MyAttendance.propTypes = {
  myAttendanceColumns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),

  myAttendanceRows: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.string,
      timeIn: PropTypes.string,
      timeOut: PropTypes.string,
      duration: PropTypes.string,
      interimPunches: PropTypes.string,
      attendance: PropTypes.string,
      exception: PropTypes.string,
      attendanceMarking: PropTypes.string,
    }),
  ),

  myAttendancePage: PropTypes.number,
  myAttendanceCount: PropTypes.number,
  myAttendanceLoading: PropTypes.bool,
  myAttendanceOnPageChange: PropTypes.func,
  myAttendanceSortDirection: PropTypes.string,
  myAttendanceSortBy: PropTypes.string,
  myAttendanceOnSortChange: PropTypes.func,
  myAttendanceHandleSort: PropTypes.func,
  myAttendancekeyColumn: PropTypes.string,
  handleAttendanceRegularize: PropTypes.func,
  handleAttendanceDownload: PropTypes.func,

  handleMyAttendanceThisWeek: PropTypes.func,
  handleMyAttendanceThisMonth: PropTypes.func,
  handleMyAttendanceThisYear: PropTypes.func,
  handleMyAttendanceDateRange: PropTypes.func,
  handleMyAttendanceActionSelect: PropTypes.func,
};

export default MyAttendance;
