/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import React, { isValidElement } from 'react';
import { TableContainer, TableBody } from '@mui/material';
import MuiTable from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Checkbox from 'components/Atoms/Checkbox';
import ActionList from 'components/Molecules/ActionList';
import PerfectScrollbar from 'react-perfect-scrollbar';

// project import
import { tableStyle } from 'components/Molecules/Table/style';
import TableHead from 'components/Molecules/TableExtended/TableHead';
import TableCell from 'components/Molecules/TableExtended/TableCell';
import TableShimmer from 'components/Molecules/TableExtended/TableShimmer';
import TablePagination from 'components/Molecules/TableExtended/TablePagination';
import TableRow from './TableRow';

const Table = (props) => {
  const {
    columns,
    rows,
    keyColumn,
    sortDirection,
    sortBy,
    onSortChange,
    count,
    page,
    onPageChange,
    showPagination,
    onRowClick,
    showRowSelector,
    selectedItems,
    onSelect,
    onSelectAll,
    isDynamic = true,
    showActionList,
    roleBasedMenu,
    actionOptions,
    handleSelect,
    loading,
  } = props;
  const rowColor = {
    Holiday: 'info.100',
    Absent: 'error.100',
    Present: 'common.100',
  };
  return (
    <>
      <PerfectScrollbar>
        <TableContainer sx={{ overflowX: 'unset' }} component={Paper}>
          <MuiTable size="small">
            <TableHead
              rows={rows}
              columns={columns}
              sortDirection={sortDirection}
              sortBy={sortBy}
              onSortChange={onSortChange}
              selectedItems={selectedItems}
              showRowSelector={showRowSelector}
              onSelectAll={onSelectAll}
              keyColumn={keyColumn}
              showActionList={showActionList}
            />
            {!loading ? (
              <TableBody>
                {rows.map((row, index) => {
                  return (
                    <TableRow key={`${row[keyColumn]}_${index}`} hover rowBgColor={rowColor[row.attendanceLabel]}>
                      {showRowSelector && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="secondary"
                            checked={!!selectedItems.find((selectedEmployee) => selectedEmployee === row[keyColumn])}
                            onChange={(event) => onSelect(event, row[keyColumn])}
                          />
                        </TableCell>
                      )}
                      {columns.map((column) => {
                        return (
                          <TableCell row={row} onRowClick={onRowClick} key={column.label}>
                            {isValidElement(row[column.fieldName])
                              ? row[column.fieldName]
                              : column.formatValue?.({ row, column })}
                            {}
                          </TableCell>
                        );
                      })}
                      {showActionList && row?.isShowActionList && (
                        <TableCell sticky align="center" sx={tableStyle.actionButton}>
                          <ActionList
                            options={isDynamic ? roleBasedMenu(row) : actionOptions}
                            data={row}
                            handleSelect={handleSelect}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <TableBody>
                <TableShimmer columns={columns.length + 1} />
              </TableBody>
            )}
          </MuiTable>
        </TableContainer>
      </PerfectScrollbar>
      {showPagination && <TablePagination count={count} page={page} onPageChange={onPageChange} />}
    </>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.node).isRequired,
  rows: PropTypes.arrayOf(PropTypes.node).isRequired,
  keyColumn: PropTypes.string,
  onSortChange: PropTypes.func,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  showPagination: PropTypes.bool,
  count: PropTypes.number.isRequired,
  onRowClick: PropTypes.func,
  selectedItems: PropTypes.arrayOf,
  showRowSelector: PropTypes.bool,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  isDynamic: PropTypes.bool,
  showActionList: PropTypes.bool,
  handleSelect: PropTypes.func,
  roleBasedMenu: PropTypes.func,
  actionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      userRole: PropTypes.arrayOf,
      onClick: PropTypes.func,
    }),
  ),
  loading: PropTypes.bool,
};

Table.defaultProps = {
  keyColumn: 'id',
  sortDirection: 'desc',
  sortBy: 'id',
  onSortChange: () => {},
  page: 1,
  onPageChange: () => {},
  showPagination: false,
  onRowClick: () => {},
  selectedItems: [],
  showRowSelector: false,
  onSelect: () => {},
  onSelectAll: () => {},
  isDynamic: true,
  showActionList: false,
  roleBasedMenu: () => {},
  handleSelect: () => {},
  actionOptions: [],
  loading: false,
};
export default Table;
