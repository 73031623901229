import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Skeleton } from '@mui/material';
import ReportAction from 'components/Templates/MyExpenses/ReportAction';
import useServices from '../List/useServices';
import useColumns from './useColumns';

const ActionExpenses = () => {
  const { id } = useParams();
  const breadcrumbData = [
    { id: 1, name: 'Company Expenses', path: '/company/expenses', isActive: false },
    { id: 2, name: 'Action', isActive: true },
  ];

  const services = useServices();
  const [columns] = useColumns();
  useEffect(() => {
    services.fetchExpenseReportAction(id);
  }, [id]);

  if (services?.state?.expenseReportDetails?.isLoading) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }
  return (
    <ReportAction
      reportDetails={services?.state?.expenseReportDetails?.reportDetails}
      columns={columns}
      rows={services?.state?.expenseReportDetails?.rows}
      amountData={services?.state?.expenseReportDetails?.amountData}
      handleCancel={services?.listNavigation}
      handleSubmit={services?.handleActionSubmit}
      breadcrumbData={breadcrumbData}
      totalClaimAmount={services?.state?.expenseReportDetails?.totalClaimAmount}
      totalApprovedAmount={services?.state?.expenseReportDetails?.totalApprovedAmount}
    />
  );
};

export default ActionExpenses;
