import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Divider, useTheme } from '@mui/material';
import { Formik, FieldArray, getIn } from 'formik';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import * as yup from 'yup';
import Button from 'components/Atoms/Button';
import { genericMobileRegex, genericEmailRegex } from 'utils/commonUtils';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import Typographys from 'components/Atoms/Typography';
import Typography from '@mui/material/Typography';
import InputField from 'components/Atoms/InputField';
import Attachments from 'components/Atoms/Attachments';
import DatePicker from 'components/Atoms/Datepicker';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { addEmpStyle } from '../../style';

const EducationHistory = ({
  tabHeading,
  handleSubmit,
  handleCancel,
  propData,
  label,
  updateBtnText,
  commentLabelText,
  updateCommentLabel,
}) => {
  const defaultValues = propData.allTabsData[label];
  const educationSchema = yup.object().shape({
    education: yup.array().of(
      yup.object().shape({
        institutionName: yup.string().required('Required'),
        startDate: yup.string().required('Required'),
        endDate: yup.string().required('Required'),
        referenceNumber: yup.string().matches(genericMobileRegex, 'Invalid format, Please enter valid mobile number.'),
        referenceEmail: yup.string().matches(genericEmailRegex, 'Invalid format, Please enter valid email ID.'),
      }),
    ),
  });
  const defaultObj = {
    institutionName: '',
    certificationName: '',
    startDate: '',
    endDate: '',
    specialization: '',
    registrationNumber: '',
    referenceName: '',
    referenceNumber: '',
    referenceEmail: '',
    clientNotes: '',
    attachment: '',
  };
  const initialValues =
    defaultValues.education.length > 0 && Object.keys(defaultValues.education[0]).length
      ? defaultValues
      : {
          education: [defaultObj],
        };

  return (
    <Box mt={1.5}>
      <Typographys variant="h5" color="neutral.800">
        {tabHeading}
      </Typographys>

      <Formik
        validationSchema={educationSchema}
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          handleSubmit(values);
          actions.setSubmitting(false);
        }}
      >
        {(formik) => (
          <Form
            formik={formik}
            handleCancel={handleCancel}
            label={label}
            propData={propData}
            defaultObj={defaultObj}
            updateBtnText={updateBtnText}
            commentLabelText={commentLabelText}
            updateCommentLabel={updateCommentLabel}
          />
        )}
      </Formik>
    </Box>
  );
};

const Form = ({
  formik,
  handleCancel,
  label,
  propData,
  defaultObj,
  updateBtnText,
  commentLabelText,
  updateCommentLabel,
}) => {
  const theme = useTheme();
  const [openBulkUploadDialog, setOpenBulkUploadDialog] = useState(false);

  const handleDateChange = (date, setFieldValue, label1) => {
    setFieldValue(label1, date);
  };
  useEffect(() => {
    propData.updateFormData(formik.values, label);
  }, [formik.values, label]);
  return (
    <Box mt={2} component="form" onSubmit={formik.handleSubmit}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 53rem)' }}>
          <FieldArray name="education">
            {({ push, remove }) => (
              <Box>
                {formik.values.education.map((e, index) => {
                  return (
                    <Accordion key={e.key} disableGutters elevation={0} square sx={addEmpStyle.accordionWrapper}>
                      <AccordionSummary
                        aria-controls="employment-content"
                        id="employment-header"
                        expandIcon={<KeyboardArrowDownSharpIcon sx={{ fontSize: '3rem', color: 'neutral.800' }} />}
                      >
                        <Box sx={addEmpStyle.deleteContainer}>
                          <Typography variant="subtitle2" color="neutral.800">
                            Record {index + 1}
                          </Typography>
                          <DeleteOutlineSharpIcon sx={{ color: 'neutral.600' }} onClick={() => remove(index)} />
                        </Box>
                      </AccordionSummary>

                      <AccordionDetails>
                        <Grid container rowSpacing={2} columnSpacing={9} mt={0} sx={addEmpStyle.formContainer}>
                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `education[${index}].institutionName`) &&
                                  getIn(formik.errors, `education[${index}].institutionName`),
                              )}
                              helperText={
                                getIn(formik.touched, `education[${index}].institutionName`) &&
                                getIn(formik.errors, `education[${index}].institutionName`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.institutionName}
                              name={`education[${index}].institutionName`}
                              id={`education[${index}].institutionName`}
                              label="Institution Name"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `education[${index}].certificationName`) &&
                                  getIn(formik.errors, `education[${index}].certificationName`),
                              )}
                              helperText={
                                getIn(formik.touched, `education[${index}].certificationName`) &&
                                getIn(formik.errors, `education[${index}].certificationName`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.certificationName}
                              name={`education[${index}].certificationName`}
                              id={`education[${index}].certificationName`}
                              label="Certification Name"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <DatePicker
                              error={
                                Boolean(
                                  getIn(formik.touched, `education[${index}].startDate`) &&
                                    getIn(formik.errors, `education[${index}].startDate`),
                                ) ||
                                (getIn(formik.touched, `education[${index}].startDate`) && !e.startDate)
                              }
                              helperText={
                                getIn(formik.touched, `education[${index}].startDate`) &&
                                getIn(formik.errors, `education[${index}].startDate`)
                              }
                              onChange={(data) =>
                                handleDateChange(data, formik.setFieldValue, `education[${index}].startDate`)
                              }
                              onBlur={formik.handleBlur}
                              value={e?.startDate}
                              name={`education[${index}].startDate`}
                              id={`education[${index}].startDate`}
                              label="Start Date"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <DatePicker
                              error={
                                Boolean(
                                  getIn(formik.touched, `education[${index}].endDate`) &&
                                    getIn(formik.errors, `education[${index}].endDate`),
                                ) ||
                                (getIn(formik.touched, `education[${index}].endDate`) && !e.endDate)
                              }
                              helperText={
                                getIn(formik.touched, `education[${index}].endDate`) &&
                                getIn(formik.errors, `education[${index}].endDate`)
                              }
                              onChange={(data) =>
                                handleDateChange(data, formik.setFieldValue, `education[${index}].endDate`)
                              }
                              onBlur={formik.handleBlur}
                              value={e?.endDate}
                              name={`education[${index}].endDate`}
                              id={`education[${index}].endDate`}
                              label="End Date"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `education[${index}].specialization`) &&
                                  getIn(formik.errors, `education[${index}].specialization`),
                              )}
                              helperText={
                                getIn(formik.touched, `education[${index}].specialization`) &&
                                getIn(formik.errors, `education[${index}].specialization`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.specialization}
                              name={`education[${index}].specialization`}
                              id={`education[${index}].specialization`}
                              label="Specialization"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `education[${index}].registrationNumber`) &&
                                  getIn(formik.errors, `education[${index}].registrationNumber`),
                              )}
                              helperText={
                                getIn(formik.touched, `education[${index}].registrationNumber`) &&
                                getIn(formik.errors, `education[${index}].registrationNumber`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.registrationNumber}
                              name={`education[${index}].registrationNumber`}
                              id={`education[${index}].registrationNumber`}
                              label="Registration Number"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `education[${index}].referenceName`) &&
                                  getIn(formik.errors, `education[${index}].referenceName`),
                              )}
                              helperText={
                                getIn(formik.touched, `education[${index}].referenceName`) &&
                                getIn(formik.errors, `education[${index}].referenceName`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.referenceName}
                              name={`education[${index}].referenceName`}
                              id={`education[${index}].referenceName`}
                              label="Reference Name"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `education[${index}].referenceNumber`) &&
                                  getIn(formik.errors, `education[${index}].referenceNumber`),
                              )}
                              helperText={
                                getIn(formik.touched, `education[${index}].referenceNumber`) &&
                                getIn(formik.errors, `education[${index}].referenceNumber`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.referenceNumber}
                              name={`education[${index}].referenceNumber`}
                              id={`education[${index}].referenceNumber`}
                              label="Reference Phone Number"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `education[${index}].referenceEmail`) &&
                                  getIn(formik.errors, `education[${index}].referenceEmail`),
                              )}
                              helperText={
                                getIn(formik.touched, `education[${index}].referenceEmail`) &&
                                getIn(formik.errors, `education[${index}].referenceEmail`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.referenceEmail}
                              name={`education[${index}].referenceEmail`}
                              id={`education[${index}].referenceEmail`}
                              label="Reference Email"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `education[${index}].clientNotes`) &&
                                  getIn(formik.errors, `education[${index}].clientNotes`),
                              )}
                              helperText={
                                getIn(formik.touched, `education[${index}].clientNotes`) &&
                                getIn(formik.errors, `education[${index}].clientNotes`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.clientNotes}
                              name={`education[${index}].clientNotes`}
                              id={`education[${index}].clientNotes`}
                              label={updateCommentLabel ? commentLabelText : 'Client Notes'}
                              multiline
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Attachments
                              isDeleteIcon
                              handleChange={(event) => {
                                formik.setFieldValue(`education[${index}].attachment`, event.target.files[0]);
                              }}
                              name="attachment"
                              id="attachment"
                              value={e.attachment}
                              handleDelete={() => {
                                formik.setFieldValue(`education[${index}].attachment`, '');
                              }}
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}

                <Box sx={addEmpStyle.addWrapper}>
                  <Button
                    size="medium"
                    variant="outlined"
                    startIcon={<AddOutlinedIcon />}
                    onClick={() => push(defaultObj)}
                    type="button"
                  >
                    Add Another
                  </Button>
                </Box>
              </Box>
            )}
          </FieldArray>
        </Box>
      </PerfectScrollbar>

      <Box sx={addEmpStyle.btnContainer}>
        <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

        <Box sx={addEmpStyle.submitWrapper}>
          <Button
            size="large"
            color="info"
            variant="outlined"
            type="button"
            onClick={() => {
              formik.resetForm();
              handleCancel();
            }}
          >
            Cancel
          </Button>

          <Button size="large" type="submit" isDisable={false}>
            {propData.allowEdit ? updateBtnText : 'Next'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

Form.defaultProps = {
  handleCancel: () => {},
  propData: {},
  formik: {},
  defaultObj: {},
  label: 'Education/Certification History',
  updateBtnText: 'Update',
  commentLabelText: '',
  updateCommentLabel: false,
};

Form.propTypes = {
  handleCancel: PropTypes.func,
  propData: PropTypes.shape({
    updateFormData: PropTypes.func,
    allTabsData: PropTypes.shape({
      'Education/Certification History': PropTypes.shape({}),
    }),
    allowEdit: PropTypes.bool,
  }),
  label: PropTypes.string,
  formik: PropTypes.shape({
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    values: PropTypes.shape({
      education: PropTypes.shape([]),
    }),
    resetForm: PropTypes.func,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    setFieldValue: PropTypes.func,
  }),
  defaultObj: PropTypes.shape({}),
  updateBtnText: PropTypes.string,
  commentLabelText: PropTypes.string,
  updateCommentLabel: PropTypes.bool,
};

EducationHistory.defaultProps = {
  tabHeading: 'Education/Certification History',
  handleSubmit: () => {},
  handleCancel: () => {},
  propData: {},
  label: 'Education/Certification History',
  updateBtnText: 'Update',
  commentLabelText: '',
  updateCommentLabel: false,
};

EducationHistory.propTypes = {
  tabHeading: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  propData: PropTypes.shape({
    updateFormData: PropTypes.func,
    allTabsData: PropTypes.shape({
      'Education/Certification History': PropTypes.shape({
        education: PropTypes.shape([]),
      }),
    }),
    allowEdit: PropTypes.bool,
  }),
  label: PropTypes.string,
  updateBtnText: PropTypes.string,
  commentLabelText: PropTypes.string,
  updateCommentLabel: PropTypes.bool,
};

export default EducationHistory;
