import React from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import DocumentList from 'components/Organisms/Document/DocList';

const DocumentListTemplate = ({
  loading,
  rows,
  columns,
  count,
  selectedItems,
  rowsPerPage,
  totalRecordCount,
  loadNextPageData,
  handleSort,
  handleRowClick,
  page,
  sortDirection,
  sortBy,
  handleSelect,
  onSelect,
  onSelectAll,
  showPagination,
  onPageChange,
  handleFilter,
  handleAdd,
  menuList,
  keyColumn,
  showActionList,
  roleBasedMenu,
  checkboxColor,
  pageHeading,
  showRowSelector,
  showActions,
  showAddNew,
  filterFields,
  actionOptions,
  handleFiltersClear,
  showFilter,
  onSortChange,
}) => {
  return (
    <BlankTemplate pageHeading={pageHeading} isBreadcrumb={false} padding="2rem 3rem">
      <DocumentList
        rows={rows}
        loading={loading}
        columns={columns}
        count={count}
        selectedItems={selectedItems}
        onSelect={onSelect}
        onSelectAll={onSelectAll}
        showRowSelector={showRowSelector}
        showPagination={showPagination}
        onPageChange={onPageChange}
        handleSort={handleSort}
        handleRowClick={handleRowClick}
        page={page}
        keyColumn={keyColumn}
        roleBasedMenu={roleBasedMenu}
        handleSelect={handleSelect}
        showActionList={showActionList}
        sortBy={sortBy}
        sortDirection={sortDirection}
        rowsPerPage={rowsPerPage}
        checkboxColor={checkboxColor}
        showActions={showActions}
        showAddNew={showAddNew}
        totalRecordCount={totalRecordCount}
        loadNextPageData={loadNextPageData}
        handleFilter={handleFilter}
        handleAdd={handleAdd}
        menuList={menuList}
        filterFields={filterFields}
        actionOptions={actionOptions}
        handleFiltersClear={handleFiltersClear}
        showFilter={showFilter}
        onSortChange={onSortChange}
      />
    </BlankTemplate>
  );
};

DocumentListTemplate.defaultProps = {
  filterFields: [],
  loading: false,
  pageHeading: '',
  roleBasedMenu: () => {},
  checkboxColor: '',
  rows: [],
  columns: [],
  count: 10,
  selectedItems: [],
  handleFilter: () => {},
  handleSelect: () => {},
  onSelect: () => {},
  onSelectAll: () => {},
  sortBy: () => {},
  handleSort: () => {},
  handleRowClick: () => {},
  onPageChange: () => {},
  keyColumn: '',
  showPagination: false,
  rowsPerPage: '',
  loadNextPageData: '',
  showActionList: false,
  page: '',
  sortDirection: 'asc',
  totalRecordCount: 6,
  handleAdd: () => {},
  menuList: [
    { label: 'Bulk Upload', onClick: () => {} },
    { label: 'Bulk Publish', onClick: () => {} },
  ],
  showRowSelector: false,
  showActions: false,
  showAddNew: false,
  actionOptions: [],
  handleFiltersClear: () => {},
  showFilter: false,
  onSortChange: () => {},
};

DocumentListTemplate.propTypes = {
  filterFields: PropTypes.arrayOf,
  loading: PropTypes.bool,
  pageHeading: PropTypes.string,
  roleBasedMenu: PropTypes.func,
  checkboxColor: PropTypes.string,
  handleFilter: PropTypes.func,
  handleSelect: PropTypes.func,
  onSelect: PropTypes.func,
  count: PropTypes.number,
  selectedItems: PropTypes.arrayOf,
  showPagination: PropTypes.bool,
  onPageChange: PropTypes.func,
  onSelectAll: PropTypes.func,
  keyColumn: PropTypes.string,
  sortBy: PropTypes.func,
  handleSort: PropTypes.func,
  handleRowClick: PropTypes.func,
  rowsPerPage: PropTypes.number,
  totalRecordCount: PropTypes.number,
  loadNextPageData: PropTypes.number,
  page: PropTypes.number,
  showActionList: PropTypes.bool,
  sortDirection: PropTypes.string,
  rows: PropTypes.arrayOf,
  columns: PropTypes.arrayOf,
  handleAdd: PropTypes.func,
  menuList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  showRowSelector: PropTypes.bool,
  showActions: PropTypes.bool,
  showAddNew: PropTypes.bool,
  actionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  handleFiltersClear: PropTypes.func,
  showFilter: PropTypes.bool,
  onSortChange: PropTypes.func,
};

export default DocumentListTemplate;
