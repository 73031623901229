export const policyListItemStyle = {
  item: {
    padding: '1.5rem 3rem',
    display: 'flex',
    flexDirection: 'column',
    rowGap: 0.5,
    cursor: 'pointer',
    borderBottom: '0.125rem solid ',
    borderColor: 'other.100',
  },
};
