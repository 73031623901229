import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React from 'react';
import PropTypes from 'prop-types';

const DatePickers = ({
  id,
  name,
  label,
  value,
  size,
  disabled,
  error,
  onChange,
  format,
  minDate,
  maxDate,
  helperText,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        id={id}
        value={value === '' ? null : value}
        name={name}
        size={size}
        disabled={disabled}
        error={error}
        onChange={onChange}
        label={label}
        format={format}
        minDate={minDate}
        maxDate={maxDate}
        slotProps={{
          textField: {
            helperText: error && helperText,
          },
        }}
      />
    </LocalizationProvider>
  );
};

DatePickers.defaultProps = {
  label: 'Label Text',
  name: 'name',
  id: 'datepicker',
  size: 'medium',
  disabled: false,
  error: false,
  onChange: () => {},
  value: null,
  helperText: 'Required',
  format: 'MM/DD/YYYY',
  minDate: '',
  maxDate: '',
};

DatePickers.propTypes = {
  id: PropTypes.string,
  value: PropTypes.shape(),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  helperText: PropTypes.string,
  format: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
};

export default DatePickers;
