import React from 'react';
import ExpenseDashboardTemplate from 'components/Templates/Expenses/Dashboard';
import { useNavigate } from 'react-router-dom';
import useFetchDashboard from './useFetchDashboard';

const ExpenseDashboard = () => {
  const navigate = useNavigate();
  const [{ data, isLoading, error }] = useFetchDashboard();

  const handleAction = () => {
    navigate(`/myteam/expenses/actionable-reports`);
  };

  return (
    <ExpenseDashboardTemplate
      expenseDetails={[
        {
          amount: data?.statusWiseTotalsMap?.Draft,
          label: 'Draft',
        },
        {
          amount: data?.statusWiseTotalsMap?.['Under Review'],
          label: 'Under Review',
        },
        {
          amount: data?.statusWiseTotalsMap?.['Approved Pending Payment'],
          label: 'Pending Settlement',
        },
        {
          amount: data?.statusWiseTotalsMap?.['Payment Complete'],
          label: 'Settled',
        },
      ]}
      chartData={data}
      isTeam={window.location.pathname.includes('myteam')}
      actionBtnLabel="Report Pending My Action"
      handleAction={handleAction}
    />
  );
};

export default ExpenseDashboard;
