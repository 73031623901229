import React from 'react';
import { Box, Card, CardContent, Container, Grid, Stack, Skeleton } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import DropdownMenu from 'components/Atoms/DropdownMenu';
import Button from 'components/Atoms/Button';
import Typography from 'components/Atoms/Typography';
import EditIcon from '@mui/icons-material/Edit';
import ViewPayheadDetails from './ViewPayheadDetails';
import useFetchPayhead from './useFetchPayhead';

const ViewPayhead = () => {
  const { pastEmployerTaxId } = useParams();
  const [payhead] = useFetchPayhead(pastEmployerTaxId, '', true);
  const navigate = useNavigate();

  if (payhead.error !== null) {
    return (
      <Typography variant="h6">
        {payhead?.error?.response?.data?.message || `Error while loading Tax details for id : ${pastEmployerTaxId}.`}
      </Typography>
    );
  }
  return (
    <Container maxWidth="lg">
      <Grid container direction="column">
        <Grid item container justifyContent="flex-start" alignItems="center" sx={{ pb: 1.5 }}>
          <Grid item>
            <Typography variant="h6" color="text.primary">
              Payheads Details
            </Typography>
          </Grid>
        </Grid>
        <Grid item sx={{ maxWidth: '100% !important' }}>
          <Card variant="outlined" square>
            <CardContent>
              <Stack direction="row" gap={2} justifyContent="space-between" sx={{ pb: 2 }}>
                <Typography variant="h6" color="text.300">
                  {payhead.data?.pageHeading}
                </Typography>
                <Stack direction="row" gap={2} justifyContent="end">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => navigate(`/company/salary/payheads/${pastEmployerTaxId}/edit`)}
                    startIcon={<EditIcon fontSize="small" />}
                  >
                    Edit
                  </Button>
                  <DropdownMenu
                    menuLists={[
                      {
                        id: 1,
                        label: 'Edit',
                        onClick: () => {
                          navigate(`/company/salary/payheads/${pastEmployerTaxId}/edit`);
                        },
                      },
                      { id: 2, label: 'Delete', onClick: () => {} },
                      {
                        id: 3,
                        label: 'Add new',
                        onClick: () => {
                          navigate(`/company/salary/payheads/add`);
                        },
                      },
                    ]}
                  />
                </Stack>
              </Stack>
              {payhead.isLoading ? (
                <Box sx={{ py: 4 }}>
                  <Skeleton height={42} />
                  <Skeleton />
                  <Skeleton />
                </Box>
              ) : (
                <ViewPayheadDetails payhead={payhead.data} />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ViewPayhead;
