import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import TitleWithText from 'components/Atoms/TitleWithText';
import Typographys from 'components/Atoms/Typography';
import { bankDetailStyle } from './style';
import { profileStyle } from '../../../../Profile/style';

const BankAccount = ({ bankDetails, addressList }) => {
  return (
    <Box sx={{ paddingLeft: '24px', marginTop: '24px' }}>
      <Typographys variant="h5" color="neutral.700">
        Account Details
      </Typographys>
      <Box mt={2} sx={bankDetailStyle.rowDivider}>
        {bankDetails.map((list) => (
          <>
            <Grid container rowSpacing={1} columnSpacing={2} sx={bankDetailStyle.wrapper}>
              <Grid item xs={12} sm={4} md={3}>
                <TitleWithText title="Account Number" text={list.accountNumber} />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <TitleWithText title="Account Name" text={list.accountName} />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <TitleWithText title="Account Type" text={list.accountType} />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <TitleWithText title="Account Currency" text={list.accountCurrency?.name} />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <TitleWithText title="Account Purpose" text={list.purpose} />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <TitleWithText title="Transaction type" text={list.transactionType} />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <TitleWithText title="Bank Name" text={list.bankName} />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <TitleWithText title="Branch name" text={list.branchName} />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <TitleWithText title="IFSC Code" text={list.IFSCCode} />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <TitleWithText title="Swift Code (Optional)" text={list.swiftCode} />
              </Grid>
            </Grid>
            <Grid item xs={12} mt={3.5}>
              <Typographys variant="h5" color="neutral.700">
                Branch Address
              </Typographys>
            </Grid>

            <Grid item xs={12}>
              <TitleWithText title="Location Name (Optional)" />
              <Typographys color="neutral.700" variant="body2">
                {list?.locationName}
              </Typographys>
            </Grid>

            <Grid item xs={12}>
              <Typographys color="neutral.600" variant="body2">
                Address
              </Typographys>

              <Typographys color="neutral.700" variant="body2">
                {list?.addressLine1}
              </Typographys>

              <Typographys color="neutral.700" variant="body2">
                {list?.addressLine2}
              </Typographys>

              <Typographys color="neutral.700" variant="body2">
                {list?.addressLine3}
              </Typographys>

              <Typographys color="neutral.700" variant="body2">
                <Box component="div" sx={profileStyle.addressDiv}>
                  {`${list?.city},`}
                </Box>

                <Box component="div" sx={profileStyle.addressDiv}>
                  {list?.state}
                </Box>

                <Box component="div" sx={profileStyle.addressDiv}>
                  {list?.country}
                </Box>

                <Box component="div" sx={profileStyle.addressDiv}>
                  {list?.pinOrZip}
                </Box>
              </Typographys>
            </Grid>
          </>
        ))}
      </Box>
    </Box>
  );
};

BankAccount.defaultProps = {
  bankDetails: [],
  addressList: {},
};

BankAccount.propTypes = {
  bankDetails: PropTypes.arrayOf(
    PropTypes.shape({
      accountNumber: PropTypes.string,
      accountName: PropTypes.string,
      accountType: PropTypes.string,
      accountCurrency: PropTypes.string,
      purpose: PropTypes.string,
      transactionType: PropTypes.string,
      bankName: PropTypes.string,
      branchName: PropTypes.string,
      IFSCCode: PropTypes.string,
      swiftCode: PropTypes.string,
    }),
  ),
  addressList: PropTypes.shape({
    location: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    address3: PropTypes.string,
    address4: PropTypes.string,
  }),
};

export default BankAccount;
