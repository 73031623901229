import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import CreateLeaveRequest from 'components/Organisms/Leave/MyLeaves/MyLeaveRequests/CreateLeaveRequest';
import useToast from 'components/Provider/useToast';
import { getToastMessage } from 'utils/toastMessage';

const CreateLeaveRequestTemplate = ({
  handleSubmit,
  preModifyData,
  leaveFormData,
  leaveCategoryOptions,
  rows,
  columns,
  handleDraft,
  handleDelete,
  handleCancel,
  isEdit,
  leaveTypeOptions,
  showNumberOfHoursField,
  toastSuccessMessage,
  toastErrorMessage,
  showToast,
  toastType,
  loading,
  breadcrumbData,
  pageHeading,
}) => {
  const [toastMessageType, setToastMessageType] = useState('');
  const addToast = useToast();

  useEffect(() => {
    if (showToast) {
      const successMessage = toastSuccessMessage || getToastMessage.MyLeaves(isEdit, toastMessageType)[0];
      const error = toastErrorMessage || getToastMessage.MyLeaves(isEdit, toastMessageType)[1];

      const title = toastType === 'success' ? 'Success!' : 'Error!';
      const type = toastType === 'success' ? 'success' : 'error';
      const message = toastType === 'success' ? successMessage : error;
      addToast({ type, title, message });
    }
  }, [showToast, toastErrorMessage, toastSuccessMessage, toastType]);

  return (
    <BlankTemplate
      sx={{ maxHeight: 'calc(100vh - 28rem)' }}
      pageHeading={pageHeading}
      breadcrumbData={breadcrumbData}
      padding="2rem 3rem"
    >
      <CreateLeaveRequest
        preModifyData={preModifyData}
        rows={rows}
        columns={columns}
        handleSubmit={(data) => {
          setToastMessageType('');
          handleSubmit(data);
        }}
        leaveFormData={leaveFormData}
        leaveCategoryOptions={leaveCategoryOptions}
        handleDraft={(data) => {
          setToastMessageType('draft');
          handleDraft(data);
        }}
        handleDelete={handleDelete}
        handleCancel={handleCancel}
        isEdit={isEdit}
        showNumberOfHoursField={showNumberOfHoursField}
        leaveTypeOptions={leaveTypeOptions}
        loading={loading}
      />
    </BlankTemplate>
  );
};

CreateLeaveRequestTemplate.defaultProps = {
  leaveFormData: {},
  handleSubmit: () => {},
  leaveCategoryOptions: [],
  rows: [],
  columns: [],
  isEdit: false,
  handleDraft: () => {},
  handleDelete: () => {},
  handleCancel: () => {},
  showNumberOfHoursField: false,
  toastSuccessMessage: '',
  toastErrorMessage: '',
  showToast: false,
  toastType: 'success',
  loading: false,
  breadcrumbData: [],
  pageHeading: '',
};

CreateLeaveRequestTemplate.propTypes = {
  leaveFormData: PropTypes.shape({}),
  handleSubmit: PropTypes.func,
  leaveCategoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  columns: PropTypes.arrayOf,
  rows: PropTypes.arrayOf,
  isEdit: PropTypes.bool,
  handleDraft: PropTypes.func,
  handleDelete: PropTypes.func,
  handleCancel: PropTypes.func,
  showNumberOfHoursField: PropTypes.bool,
  toastSuccessMessage: PropTypes.string,
  toastErrorMessage: PropTypes.string,
  showToast: PropTypes.bool,
  toastType: PropTypes.oneOf(['error', 'success']),
  loading: PropTypes.bool,
  breadcrumbData: PropTypes.arrayOf({}),
  pageHeading: PropTypes.string,
};

export default CreateLeaveRequestTemplate;
