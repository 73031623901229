export const dropdownStyle = {
  dropdown: {
    '& .MuiInputLabel-root': {
      fontSize: '1.75rem',
      fontWeight: 400,
      lineHeight: 1.6,
      letterSpacing: '0.019rem',
      mt: 0.5,

      '&.Mui-focused': {
        color: 'primary.300',
      },
    },
    '& .MuiOutlinedInput-root': {
      '& .MuiSelect-outlined': {
        py: '1.625rem',
        px: 2,
        fontSize: '1.75rem',
        fontWeight: 400,
        lineHeight: 1.67,
        letterSpacing: '0.019rem',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'neutral.600',
      },
      '& .MuiSelect-icon': {
        fontSize: '2.5rem',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'primary.300',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderWidth: '0.125rem',
        borderColor: 'primary.300',
      },
    },
  },
  menuItem: {
    '&:hover': {
      backgroundColor: 'common.white',
      color: 'primary.300',
    },
    '&.Mui-selected': {
      backgroundColor: 'action.300',
      color: 'neutral.800',
    },
  },
};
