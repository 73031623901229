import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { validateDateElseHyphen } from 'utils/commonUtils';
import TitleWithText from 'components/Atoms/TitleWithText';
import Typographys from 'components/Atoms/Typography';
import IconWithTitle from 'components/Atoms/IconWithTitle';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import NoRecordsMessage from 'components/Organisms/CommonPageUtils/NoRecordsFound';
import { additionalDetailStyle } from '../style';

const EducationHistory = ({ educationHistory }) => {
  return (
    <>
      <Typographys variant="h5" color="neutral.700">
        Education/Certification History
      </Typographys>
      <Box mt={2} sx={additionalDetailStyle.rowDivider}>
        {!educationHistory?.length ? (
          <NoRecordsMessage />
        ) : (
          educationHistory?.map((educationList) => (
            <Grid container rowSpacing={1} columnSpacing={2}>
              <Grid item xs={12} sm={4}>
                <TitleWithText title="Institution Name" text={educationList?.institutionName} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TitleWithText title="Certification Name" text={educationList?.certificationName} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TitleWithText
                  title="Start Date"
                  text={validateDateElseHyphen(educationList?.startDate, 'DD MMMM, YYYY h:mm A')}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TitleWithText
                  title="End Date"
                  text={validateDateElseHyphen(educationList?.endDate, 'DD MMMM, YYYY h:mm A')}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TitleWithText title="Specialization" text={educationList?.specialization} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TitleWithText title="Registration Number" text={educationList?.registrationNumber} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TitleWithText title="Reference Name" text={educationList?.referenceName} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TitleWithText title="Reference Phone Number" text={educationList?.referencePhone} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TitleWithText title="Registration Email" text={educationList?.registrationEmail} />
              </Grid>
              <Grid item xs={12} mt={4}>
                <Typographys variant="h6" color="neutral.600">
                  Attachments
                </Typographys>
                <Box sx={{ mt: 2, pl: 1 }}>
                  <IconWithTitle
                    iconElement={<AttachFileIcon fontSize="inherit" />}
                    iconColor="actions.600"
                    iconFontSize="2.5rem"
                    title={educationList?.certificateFile}
                    titleColor="neutral.800"
                    titleVariant="subtitle2"
                  />
                </Box>
              </Grid>
            </Grid>
          ))
        )}
      </Box>
    </>
  );
};

EducationHistory.defaultProps = {
  educationHistory: [],
};

EducationHistory.propTypes = {
  educationHistory: PropTypes.arrayOf(
    PropTypes.shape({
      institutionName: PropTypes.string,
      certificationName: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      specialization: PropTypes.string,
      registrationNumber: PropTypes.string,
      referenceName: PropTypes.string,
      referencePhone: PropTypes.string,
      registrationEmail: PropTypes.string,
      certificateFile: PropTypes.string,
    }),
  ),
};

export default EducationHistory;
