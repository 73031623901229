import PropTypes from 'prop-types';
import React from 'react';
import Dialog from 'components/Molecules/Dialog';

const DeleteSeparation = ({
  handleComment,
  handleCancel,
  primaryBtn,
  secondaryBtn,
  primaryBtnColor,
  open,
  handleClose,
}) => {
  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      dialogTitle="Delete Resignation?"
      dialogDesc="Are you sure you want to delete this resignation?"
      primaryBtn={primaryBtn}
      secondaryBtn={secondaryBtn}
      handleSecondaryBtn={handleCancel}
      handleCancel={handleCancel}
      primaryBtnColor={primaryBtnColor}
      bodyContent={false}
      handlePrimaryBtn={handleComment}
    />
  );
};
DeleteSeparation.defaultProps = {
  primaryBtnColor: 'error',
  primaryBtn: 'Delete',
  secondaryBtn: 'Cancel',
  handleComment: () => {},
  handleCancel: () => {},
  open: true,
  handleClose: () => {},
};
DeleteSeparation.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  primaryBtnColor: PropTypes.string,
  handleComment: PropTypes.func,
  handleCancel: PropTypes.func,
  primaryBtn: PropTypes.string,
  secondaryBtn: PropTypes.string,
};
export default DeleteSeparation;
