export const timelineStyle = {
  wrapper: {
    '& .MuiTimelineItem-root': {
      '& .MuiTimelineContent-root': {
        width: '10%',
        flex: 'auto',
        minWidth: '14rem',
        padding: '0 2rem 6rem',
      },

      '& .MuiTimelineDot-root': {
        '&.MuiTimelineDot-filledSecondary': {
          backgroundColor: 'neutral.200',
          color: 'actions.600',
        },
      },
    },
  },
};
