import React, { useEffect, useState } from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import SkillEditDetails from 'components/Organisms/Profile/SkillDetails/Edit';
import { useThunk } from 'hooks/useThunk';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fetchSkillDetails } from 'store';
import { Typography, Skeleton, Box } from '@mui/material';
import useToast from 'components/Provider/useToast';
import { formDataApi } from 'api/api';

const breadcrumbData = [
  { id: 1, name: 'Skill Details', path: '/profile/skills-details', isActive: false },
  { id: 2, name: 'Edit', path: '/edit', isActive: true },
];

const ProfileEditSkillDetailsTemplate = () => {
  const addToast = useToast();
  const navigate = useNavigate();
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [doFetchSkillDetails, isLoadingSkillDetails, loadingSkillDetailsError] = useThunk(fetchSkillDetails);

  const { skillDetails, employeeProfileInstance, skillAttainmentLevels, skillsList } = useSelector(
    (state) => state.profile,
  );
  useEffect(() => {
    const query = new URLSearchParams({});
    doFetchSkillDetails(query);
  }, [doFetchSkillDetails, isInEditMode]);

  const editOptions = {
    defaultValue: {
      skillData: skillDetails?.map((item) => ({
        skill: item?.skill?.id,
        level: item?.level,
        comments: item?.comments,
        attachment: item?.documents[0]?.fileName || '',
        isNew: false,
      })),
    },
    levelOptions: skillAttainmentLevels
      ? Object.values(skillAttainmentLevels).map((item) => ({ id: item, name: item }))
      : [],

    skillLevelOptions: skillsList
      ? Object.values(skillsList).map((item) => ({
          id: item?.id,
          name: item?.name,
        }))
      : [],
  };

  // eslint-disable-next-line consistent-return
  const configureFormDataAndUpdate = async (skillData) => {
    const formData = new FormData();
    formData.append('id', employeeProfileInstance?.employee?.userId);
    skillData.forEach((payload) => {
      const index = skillData.indexOf(payload);
      formData.append(`skillLevelAttainmentRecords[${index}].skill`, payload?.skill || '');
      formData.append(`skillLevelAttainmentRecords[${index}].level`, payload?.level || '');
      formData.append(`skillLevelAttainmentRecords[${index}].comments`, payload?.comments || '');
      if (payload?.attachments) {
        formData.append(`skillLevelAttainmentRecords[${index}].attachment`, payload?.attachments);
      } else if (!payload?.isNew) {
        formData.append(`skillLevelAttainmentRecords[${index}].id`, skillDetails[index]?.id);
      }
    });

    formData.append(`skillRecordCtr`, skillData?.length);
    return formData;
  };

  const handleCancel = () => {
    navigate('/profile/skills-details');
  };

  const handleSubmit = async (e) => {
    const bodyFormData = await configureFormDataAndUpdate(e?.skillData);

    try {
      setLoading(true);
      const response = await formDataApi.post(`restEmpIndex/updateSkillAttainmentAction`, bodyFormData);
      if (response?.data?.status !== 'ERROR') {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || 'Skill details updated successfully',
        });
        setIsInEditMode(false);
      } else if (response?.data?.status === 'ERROR') {
        addToast({
          type: 'error',
          title: 'ERROR',
          message: response?.data?.message || 'Failed to update skill details',
        });
        setIsInEditMode(false);
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || 'Failed to update skill details',
      });
    } finally {
      navigate('/profile/skills-details');
      setLoading(false);
    }
  };

  if (isLoadingSkillDetails) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (loadingSkillDetailsError) {
    return (
      <Typography variant="h6">{loadingSkillDetailsError?.message || 'Error while fetching skill details.'}</Typography>
    );
  }

  return (
    <BlankTemplate
      pageHeading="Edit Skill Details"
      isBreadcrumb
      breadcrumbData={breadcrumbData}
      padding="0 3rem 2rem 3rem"
    >
      <SkillEditDetails
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        defaultValue={editOptions.defaultValue}
        levelOptions={editOptions.levelOptions}
        skillLevelOptions={editOptions.skillLevelOptions}
        loading={loading}
      />
    </BlankTemplate>
  );
};

ProfileEditSkillDetailsTemplate.defaultProps = {
  handleSubmit: () => {},
  handleCancel: () => {},
  defaultValue: {
    skillData: [
      {
        skill: '',
        level: '',
        comments: '',
        attachment: '',
      },
    ],
  },
  levelOptions: [],
  skillLevelOptions: [],
};

export default ProfileEditSkillDetailsTemplate;
