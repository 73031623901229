import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import AuditTrailCard from 'components/Organisms/Employee/EmployeeRecord/AuditTrailCard';
import { auditTrailStyle } from './style';

const AuditTrail = ({ auditTrailLists }) => {
  return (
    <Box sx={{ paddingLeft: '24px', marginTop: '24px' }}>
      {auditTrailLists.map((list) => (
        <Box sx={auditTrailStyle.auditTrailCard}>
          <AuditTrailCard
            avatarSrc={list?.avatarSrc}
            description={list?.description}
            content={list?.content}
            date={list?.date}
          />
        </Box>
      ))}
    </Box>
  );
};

AuditTrail.defaultProps = {
  auditTrailLists: [
    {
      avatarSrc: 'images/user.jpg',
      name: 'Uday kumar',
      description: 'Uday Kumar Updated the status.',
      date: '07 May 2020 00:03:21 IST',
    },
    {
      avatarSrc: 'images/user.jpg',
      name: 'Uday kumar',
      description: 'Uday Kumar Updated the status.',
      date: '07 May 2020 00:03:21 IST',
    },
    {
      avatarSrc: 'images/user.jpg',
      name: 'Uday kumar',
      description: 'Uday Kumar Updated the status.',
      date: '07 May 2020 00:03:21 IST',
    },
  ],
};

AuditTrail.propTypes = {
  auditTrailLists: PropTypes.arrayOf(
    PropTypes.shape({
      avatarSrc: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      date: PropTypes.string,
    }),
  ),
};

export default AuditTrail;
