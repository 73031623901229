import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import useToast from 'components/Provider/useToast';
import FormData from 'form-data';
import dayjs from 'dayjs';
import { fetchCreateExpense, fetchMyExpenseDetails } from 'store';
import { useThunk } from 'hooks/useThunk';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import get from 'lodash/get';

import { newExpenseReportAction, modifyExpenseReportAction, newExpenseReportSubmitAction } from 'api/expenses';
import AddReportTemplate from 'components/Templates/MyExpenses/AllReports/AddReport';

const formatPreCreateResponse = (response) => {
  if (!response) {
    return {};
  }

  const {
    projectsList = [],
    vendorsList = [],
    currenciesList = [],
    companyExpenseCategoriesMap = {},
    paymentMethod = {},
  } = response;

  const retObj = {
    projectsOptions: projectsList?.map((item) => ({ id: item.id, name: item.name, onClick: () => {} })),
    merchantOptions: vendorsList?.map((item) => ({ id: item.id, name: item.name, onClick: () => {} })),
    currencyOptions: currenciesList?.map((item) => ({ id: item.id, name: item.name, onClick: () => {} })),
    expenseCategoryOptions: Object.keys(companyExpenseCategoriesMap)?.map((item) => ({
      id: companyExpenseCategoriesMap[item].id,
      name: companyExpenseCategoriesMap[item].displayName,
      onClick: () => {},
    })),
    paymentMethodOptions: Object.keys(paymentMethod)?.map((item) => ({
      id: paymentMethod[item],
      name: paymentMethod[item],
      onClick: () => {},
    })),
  };

  return retObj;
};

const AddNewReport = () => {
  const addToast = useToast();
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const isEditMode = location.pathname.includes('edit') && id;
  const [loading, setLoading] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);

  const breadcrumbData = [
    { id: 1, name: 'My Expenses', path: '/home/expenses', isActive: false },
    { id: 2, name: isEditMode ? 'Edit' : 'Add', isActive: true },
  ];

  const [getCreateExpense, isLoadingCreateExpense, loadingCreateExpenseError] = useThunk(fetchCreateExpense);
  const [getExpenseDetails, isLoadingExpenseDetails, loadingExpenseDetailsError] = useThunk(fetchMyExpenseDetails);

  useEffect(() => {
    if (isEditMode) {
      const bodyFormData = new FormData();
      bodyFormData.append('id', id);
      getExpenseDetails(bodyFormData);
    } else {
      const getCreateExpenseData = async () => {
        const query = new URLSearchParams({});
        await getCreateExpense(query);
      };
      getCreateExpenseData();
    }
  }, [isEditMode, id, getExpenseDetails, getCreateExpense]);

  const { createExpenseData, myExpenseDetails } = useSelector((state) => state?.expenses);
  const resData = isEditMode ? myExpenseDetails : createExpenseData;
  const formattedResult = formatPreCreateResponse(resData);

  const expensesMileageRate = get(myExpenseDetails, 'expensesMileageRate', '');
  const expenseReportID = get(myExpenseDetails, 'employeeExpenseReportInstance.expenseReportID', '');
  const totalExpensedAmount = get(myExpenseDetails, 'employeeExpenseReportInstance.totalExpensedAmount', 0);
  const reimbursableAmount = get(myExpenseDetails, 'employeeExpenseReportInstance.totalAmountToBeReimbursed', 0);
  const startDate = get(myExpenseDetails, 'employeeExpenseReportInstance.startDate', '');
  const endDate = get(myExpenseDetails, 'employeeExpenseReportInstance.endDate', '');
  const initialValues = {
    reportName: get(myExpenseDetails, 'employeeExpenseReportInstance.displayName', ''),
    businessPurpose: get(myExpenseDetails, 'employeeExpenseReportInstance.businessPurpose', ''),
    startDate: startDate ? dayjs(startDate, 'DD-MMM-YYYY HH:mm:ss').format('MM/DD/YYYY') : '',
    endDate: endDate ? dayjs(endDate, 'DD-MMM-YYYY HH:mm:ss').format('MM/DD/YYYY') : '',
    reportingCurrencyId: get(
      myExpenseDetails,
      'employeeExpenseReportInstance?.reportingCurrency?.id',
      myExpenseDetails?.defaultCurrency?.id || createExpenseData?.defaultCurrency?.id,
    ),
    expenseRecord:
      get(myExpenseDetails, 'employeeExpenseReportInstance.expenseRecords', [])?.map((record) => ({
        expenseCategoryId: record?.expenseCategoryId || '',
        expensedCurrencyId: record?.expensedCurrencyId || '',
        recordDate: record?.expensedDate || '',
        amount: record?.expensedAmount || '',
        billNumber: record?.billNumber || '',
        paymentMethod: record?.paymentMethod || '',
        isReimburseable: record?.isReimburseable,
        supportingDocuments: [{ fileName: record?.supportingDocuments?.[0]?.fileName }],
        exchangeRate: record?.expensedExchangeRate || '',
      })) || [],
    defaultCurrency: get(myExpenseDetails, 'defaultCurrency.symbol', ''),
    expensesMileageRate,
  };

  const initialValuesWhenNewExpense = {
    reportName: '',
    businessPurpose: '',
    startDate: '',
    endDate: '',
    expenseRecord: [],
    reimbursed: 'on',
    reportingCurrencyId: get(
      myExpenseDetails,
      'employeeExpenseReportInstance.reportingCurrency?.id',
      myExpenseDetails?.defaultCurrency?.id || createExpenseData?.defaultCurrency?.id,
    ),
    defaultCurrency: get(createExpenseData, 'defaultCurrency.symbol', ''),
  };

  const createExpenseReport = async (data) => {
    setLoading(true);
    setDraftLoading(true);
    try {
      const bodyFormData = new FormData();
      const body = {};
      body.displayName = data?.reportName;
      body.businessPurpose = data?.businessPurpose;
      body.startDate = dayjs(data?.startDate).format('MM/DD/YYYY');
      body.endDate = dayjs(data?.endDate).format('MM/DD/YYYY');
      body.totalExpensedAmount =
        data?.totalExpensedAmount || get(myExpenseDetails, 'employeeExpenseReportInstance.totalExpensedAmount', 0);
      body.totalAmountToBeReimbursed =
        data?.reimbursableAmount || get(myExpenseDetails, 'employeeExpenseReportInstance.totalAmountToBeReimbursed', 0);
      body.expensesMileageRate = expensesMileageRate || 0.0;
      if (data?.expenseRecord?.length > 0) {
        data?.expenseRecord?.forEach((item, index) => {
          body[`expenseRecords[${index}].expenseCategory.id`] = item?.expenseCategoryId || '';
          body[`expenseRecords[${index}].expensedAmount`] =
            item?.amount || initialValues?.expenseRecord[index]?.expensedAmount;
          body[`expenseRecords[${index}].expensedDate`] = item?.recordDate
            ? dayjs(item?.recordDate).format('MM/DD/YYYY')
            : dayjs(initialValues?.expenseRecord[index]?.expensedDate).format('MM/DD/YYYY');
          body[`expenseRecords[${index}].expensedCurrency.id`] =
            item?.expensedCurrencyId || get(myExpenseDetails, 'defaultCurrency.id', '');
          body[`expenseRecords[${index}].billNumber`] = item?.billNumber || '';
          body[`expenseRecords[${index}].paymentMethod`] = item?.paymentMethod || '';
          body[`expenseRecords[${index}].isReimburseable`] =
            item?.reimbursed || (initialValues?.expenseRecord[index]?.isReimburseable === false ? 'off' : 'on');
          body[`expenseRecords[${index}].mileageStart`] = get(
            myExpenseDetails,
            `employeeExpenseReportInstance.expenseRecords?.[${index}]?.mileageStart`,
            '',
          );
          body[`expenseRecords[${index}].mileageEnd`] = get(
            myExpenseDetails,
            `employeeExpenseReportInstance.expenseRecords?.[${index}]?.mileageEnd`,
            '',
          );
          body[`expenseRecords[${index}].expensedExchangeRate`] = item?.exchangeRate || '';
          body[`expenseRecords[${index}].merchant.id`] = item?.merchant || '';
          body[`expenseRecords[${index}].allocations_Counter`] = index;
          body[`expenseRecords[${index}].allocType`] = get(
            myExpenseDetails,
            `employeeExpenseReportInstance.expenseRecords?.[${index}]?.allocationMethod`,
            'Amount',
          );
          item?.allocations?.forEach((allocationItem, allocationIndex) => {
            body[`expenseRecords[${index}].allocations[${allocationIndex}].allocatedAmount`] =
              allocationItem?.allocation || '';
            body[`expenseRecords[${index}].allocations[${allocationIndex}].project.id`] =
              allocationItem?.projects || '';
          });
          body[`expenseRecords[${index}].isAllocatedToMultipleProjects`] = item?.allocations > 1;
          if (item?.expenseRecordAttachments || item?.isNew) {
            body[`expenseRecords[${index}].attachment`] = item?.expenseRecordAttachments || '';
          } else {
            body[`expenseRecords[${index}].deleteAttachment`] = false;
            body[`expenseRecords[${index}].supportingDocID`] =
              get(myExpenseDetails, 'employeeExpenseReportInstance.expenseRecords', [])?.[index]
                ?.supportingDocuments?.[0]?.id || '';
          }
        });
      }
      body.entryRowsCtr = data?.expenseRecord?.length || 0;
      body.numRowsCtrId = data?.expenseRecord?.length || 0;
      if (isEditMode) {
        body.id = id;
      }
      Object.keys(body).forEach((key) => {
        bodyFormData.append(key, body[key]);
      });

      let apiCall = '';
      if (isEditMode) {
        apiCall = modifyExpenseReportAction;
      } else if (data?.actionType === 'submit') {
        apiCall = newExpenseReportSubmitAction;
      } else {
        apiCall = newExpenseReportAction;
      }

      const response = await apiCall(bodyFormData);
      if (response.status === 200 && response.data) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Expense report ${isEditMode ? 'updated' : 'added'} successfully`,
        });
      }
      if (isEditMode) {
        navigate(`/home/expenses/${id}/view`);
      } else {
        navigate(`/home/expenses/${response?.data?.data?.objID || response?.data?.data?.expenseId}/view`);
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to ${isEditMode ? 'update' : 'add'} expense report`,
      });
    } finally {
      setLoading(false);
      setDraftLoading(false);
    }
  };

  if ((isEditMode && loadingExpenseDetailsError !== null) || loadingCreateExpenseError !== null) {
    return <Typography variant="h6">Error while loading current details</Typography>;
  }

  return (
    <AddReportTemplate
      loading={loading}
      draftLoading={draftLoading}
      formLoading={isEditMode ? isLoadingExpenseDetails : isLoadingCreateExpense}
      pageHeading={isEditMode ? 'Edit Expense Report' : 'Add Expense Report'}
      expenseReportId={expenseReportID}
      initialValues={isEditMode ? initialValues : initialValuesWhenNewExpense}
      totalExpensedAmount={isEditMode ? totalExpensedAmount : 0}
      reimbursableAmount={isEditMode ? reimbursableAmount : 0}
      expenseCategoryOptions={formattedResult?.expenseCategoryOptions}
      currencyOptions={formattedResult?.currencyOptions}
      paymentMethodOptions={formattedResult?.paymentMethodOptions}
      handleNewExpenseRecord={() => {}}
      handleDelete={() => {}}
      handleAddProject={() => {}}
      handleDeleteProject={() => {}}
      handleAttachments={() => {}}
      projectsOptions={formattedResult?.projectsOptions}
      merchantOptions={formattedResult?.merchantOptions}
      handleCancel={() => navigate('/home/expenses')}
      handleDraft={() => {}}
      handleSubmit={createExpenseReport}
      breadcrumbData={breadcrumbData}
      isEdit={isEditMode}
    />
  );
};

export default AddNewReport;
