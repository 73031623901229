export const TableStyle = {
  SalaryTableHeader: {
    display: 'flex',
    p: '12px 8px',
    backgroundColor: 'info.200',
  },

  SalaryTableRows: {
    display: 'flex',
    p: '1rem',
  },
};
