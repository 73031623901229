import { useReducer } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormData from 'form-data';
import useToast from 'components/Provider/useToast';
import fileDownload from 'js-file-download';
import {
  lockEmployeesAction,
  unLockEmployeesAction,
  activateEmployeesAction,
  deActivateEmployeesAction,
  convertToCandidateAction,
  sendESSInviteAction,
  preAddNoteEmployeeAction,
  addNoteEmployeeAction,
  deleteEmployeesAction,
  mapEmployeeToLifeCyclePolicyAction,
  mapGenericGroupEmployeeAction,
  updateEmployeeSearchAction,
  updateEmployeeSearchActionWithQueryParam,
  sendESSInviteToSelEmpsAction,
  exportEmployeeDataAction,
} from 'api/employees';

const dialogsInitState = {
  open: null,
  searchData: {
    values: {},
    statusOptions: [
      { id: 'ACTIVE', name: 'ACTIVE' },
      { id: 'INACTIVE', name: 'INACTIVE' },
    ],
    results: [],
    employeeList: [],
    totalCount: 0,
    searched: false,
  },
};

const reducer = (state, action) => {
  const { type, payload } = action;
  let newState;
  switch (type) {
    case 'OPEN_DIALOG': {
      newState = { ...state, open: payload };
      break;
    }
    case 'SET_EMPLOYEE_SEARCH_DATA': {
      const { results = [], totalCount = 0, employeeList = [], searched = false } = payload;
      newState = { ...state, searchData: { ...state.searchData, results, employeeList, totalCount, searched } };
      break;
    }
    case 'SET_SEARCH_VALUES': {
      newState = { ...state, searchData: { ...state.searchData, values: payload } };
      break;
    }
    default: {
      newState = state;
    }
  }
  return newState;
};

const useEmployeeServices = (fetchEmployeesAction) => {
  const { selectedEmployees } = useSelector((state) => {
    return state.employees;
  });
  const navigate = useNavigate();

  const [dialogState, dispatchDialog] = useReducer(reducer, dialogsInitState);
  const addToast = useToast();

  const handleOpenDialog = (payload) => {
    dispatchDialog({ type: 'OPEN_DIALOG', payload });
  };

  const handleDialogClose = () => {
    dispatchDialog({ type: 'OPEN_DIALOG', payload: null });
  };

  const viewEmployee = (row) => {
    // here we used two userId because there is difference in data in list and advance serach
    const userId = row.userID || row.userId;
    navigate(`/company/employee/view/${userId}`);
  };

  const modifyEmployee = (row) => {
    const userId = row.userID || row.userId;
    navigate(`/company/employee/employees-list/${userId}/edit`);
  };

  const modifyEmployeeWithoutRole = (row) => {
    navigate(`/company/employee/${row.id}/edit-without-role`);
  };

  const employeePerformanceSettings = (row) => {
    navigate(`/company/employee/${row.id}/performance-settings`);
  };
  const employeeAccessFilters = (row) => {
    navigate(`/company/employee/access-filters/${row.id}`);
  };
  const viewOnboardingRecords = (row) => {
    navigate(`/company/employee/${row.id}/view-onboarding-records`);
  };
  const deleteEmployee = async (employee) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', employee.userID);
      bodyFormData.append('_method', 'PUT');
      const response = await deleteEmployeesAction(bodyFormData);

      if (response?.data?.status?.toLowerCase?.() === 'error') {
        throw new Error(response?.data?.message || `Failed to delete employee`);
      }

      if (response.status === 200) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Employee deleted successfully`,
        });
        fetchEmployeesAction();
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.response?.data?.message || `Failed to delete employee`,
      });
    } finally {
      handleDialogClose();
    }
  };
  const lockEmployee = async (employeeId) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', employeeId);
      const response = await lockEmployeesAction(bodyFormData);
      if (response.status === 200) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Employee locked successfully`,
        });
        fetchEmployeesAction();
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error.message || `Failed to lock employee`,
      });
    } finally {
      handleDialogClose();
    }
  };

  const unLockEmployees = async (employeeId) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', employeeId);
      const response = await unLockEmployeesAction(bodyFormData);
      if (response.status === 200) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Employee unlocked successfully`,
        });
        fetchEmployeesAction();
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error.message || `Failed to unlock employee`,
      });
    } finally {
      handleDialogClose();
    }
  };

  const activateEmployees = async (employeeId) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', employeeId);
      const response = await activateEmployeesAction(bodyFormData);
      if (response.status === 200) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Employee activated successfully`,
        });
        fetchEmployeesAction();
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error.message || `Failed to activate employee`,
      });
    } finally {
      handleDialogClose();
    }
  };

  const deActivateEmployees = async (employeeId) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', employeeId);
      const response = await deActivateEmployeesAction(bodyFormData);
      if (response.status === 200) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Employee deactivated successfully`,
        });
        fetchEmployeesAction();
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error.message || `Failed to deactivate employee`,
      });
    } finally {
      handleDialogClose();
    }
  };

  const convertToCandidate = async (row) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', row.id);
      const response = await convertToCandidateAction(bodyFormData);
      if (response.status === 200) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Employee converted to candidate successfully`,
        });
        fetchEmployeesAction();
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error.message || `Failed to convert employee into candidate`,
      });
    } finally {
      handleDialogClose();
    }
  };

  const sendESSInvite = async (row) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', row.id);
      const response = await sendESSInviteAction(bodyFormData);
      if (response.status === 200) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `ESS invite sent successfully`,
        });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error.message || `Failed to send ESS invite`,
      });
    }
  };

  const preAddNoteEmployee = async (employeeId) => {
    const bodyFormData = new FormData();
    bodyFormData.append('id', employeeId);
    await preAddNoteEmployeeAction(bodyFormData);
    // fetchEmployeesAction();
  };

  const addNoteEmployee = async (employeeId, notes) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('empID', employeeId);
      bodyFormData.append('notes', notes);
      const response = await addNoteEmployeeAction(bodyFormData);
      if (response.status === 200) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Note added successfully`,
        });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error.message || `Failed to add note`,
      });
    } finally {
      handleDialogClose();
    }
  };

  const mapEmployeeToLifeCyclePolicy = async (id, pid) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', id);
      bodyFormData.append('pid', pid);
      const response = await mapEmployeeToLifeCyclePolicyAction(bodyFormData);
      if (response.status === 200) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Employee mapped successfully`,
        });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error.message || `Failed to map employee`,
      });
    } finally {
      handleDialogClose();
    }
  };

  const mapGenericGroupEmployee = async (id, gid) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', id);
      bodyFormData.append('gid', gid);
      const response = await mapGenericGroupEmployeeAction(bodyFormData);
      if (response.status === 200) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Group mapped successfully`,
        });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error.message || `Failed to map group`,
      });
    } finally {
      handleDialogClose();
    }
  };

  const handleAdvanceSearch = () => {
    navigate(`/company/employee/employees-list/advanced-search`);
  };

  const handleAdvanceSearchAction = async (values) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('q', values?.employeeName || '');
      bodyFormData.append('qu', values?.userName || '');
      bodyFormData.append('empID', values?.employeeId || '');
      bodyFormData.append('dept', values?.department || '');
      bodyFormData.append('loc', values?.location || '');
      bodyFormData.append('desgn', values?.designation || '');
      bodyFormData.append('dojStartDate', values?.dateOfJoining || '');
      // bodyFormData.apeend('dojEndDate', '');
      bodyFormData.append('dolStartDate', values?.dateOfLeaving || '');
      // bodyFormData.apeend('dolEndDate', '');
      bodyFormData.append('status', values?.employmentStatus || 'ACTIVE');
      bodyFormData.append('empType', values?.employmentType || '');
      bodyFormData.append('supervisorID', values?.supervisor || '');

      const response = await updateEmployeeSearchAction(bodyFormData);
      if (response.status === 200) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Employee searched successfully`,
        });
        dispatchDialog({ type: 'SET_EMPLOYEE_SEARCH_DATA', payload: response.data });
        dispatchDialog({ type: 'SET_SEARCH_VALUES', payload: values });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error.message || `Failed to search employee`,
      });
    }
  };
  const handleAdvanceSearchActionWithQuery = async (query) => {
    try {
      const response = await updateEmployeeSearchActionWithQueryParam(query);
      if (response.status === 200) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Employee searched successfully`,
        });
        dispatchDialog({ type: 'SET_EMPLOYEE_SEARCH_DATA', payload: response.data });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error.message || `Failed to search employee`,
      });
    }
  };

  const addNewEmployee = () => {
    navigate(`/company/employee/add`);
  };

  const sendBulkESSInvite = async () => {
    const bodyFormData = new FormData();
    selectedEmployees.forEach((item) => {
      bodyFormData.append(`emp_${item}`, 'on');
    });
    try {
      const response = await sendESSInviteToSelEmpsAction(bodyFormData);
      if (response.status === 200) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Bulk ESS invites sent successfully`,
        });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error.message || `Failed to send ESS invites`,
      });
    }
  };

  const downloadEmployees = async () => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('allEmployees', 'on');
      const response = await exportEmployeeDataAction(bodyFormData);

      if (response.status === 200 && response.data) {
        fileDownload(new Blob([response.data]), 'EmployeeInformation.xlsx');
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error.message || `Failed to download employee data`,
      });
    }
  };

  const bulkUploadEmployees = () => {
    console.log('entry ento bulk upload employees');
  };

  return {
    dialogState,
    deleteEmployee,
    viewEmployee,
    lockEmployee,
    unLockEmployees,
    activateEmployees,
    deActivateEmployees,
    convertToCandidate,
    sendESSInvite,
    preAddNoteEmployee,
    addNoteEmployee,
    mapEmployeeToLifeCyclePolicy,
    mapGenericGroupEmployee,
    modifyEmployee,
    modifyEmployeeWithoutRole,
    employeePerformanceSettings,
    viewOnboardingRecords,
    employeeAccessFilters,
    handleAdvanceSearch,
    handleAdvanceSearchAction,
    handleAdvanceSearchActionWithQuery,
    handleOpenDialog,
    handleDialogClose,
    addNewEmployee,
    sendBulkESSInvite,
    downloadEmployees,
    bulkUploadEmployees,
  };
};

export default useEmployeeServices;
