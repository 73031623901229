import React from 'react';
import { Typography } from '@mui/material';
import { validateDateElseHyphen } from 'utils/commonUtils';
import { PayrollCalendarTableMenu } from './PayrollCalendarTableMenu';

const columns = [
  {
    fieldName: 'id',
    name: 'i',
    label: 'Id',
    type: 'number',
    formatValue: ({ row, column }) => {
      return <Typography variant="body2">{row[column.fieldName]}</Typography>;
    },
  },
  {
    fieldName: 'name',
    name: 'dn',
    label: 'Name',
    type: 'string',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'status',
    name: 'pt',
    label: 'Status',
    type: 'string',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'calendarStartDate',
    name: 'el',
    label: 'Calendar Start Date',
    type: 'string',
    formatValue: ({ row, column }) => (
      <Typography variant="body2">{validateDateElseHyphen(row[column.fieldName], 'DD MMMM, YYYY h:mm A')}</Typography>
    ),
  },
  {
    fieldName: 'calendarEndDate',
    name: 'et',
    label: 'Calendar End Date',
    type: 'string',
    formatValue: ({ row, column }) => (
      <Typography variant="body2">{validateDateElseHyphen(row[column.fieldName], 'DD MMMM, YYYY h:mm A')}</Typography>
    ),
  },
  {
    fieldName: 'financialYear',
    name: 'cr',
    label: 'Financial Year',
    type: 'string',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'assessmentYear',
    name: 'me',
    label: 'Assessment Year',
    type: 'number',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'payRunPeriodicity',
    name: 'ct',
    label: 'Payrun Periodicity',
    type: 'string',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'lockStatus',
    name: 'cm',
    label: 'Lock Status',
    type: 'string',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'firstProcessedPayPeriodStartDate',
    name: 'fps',
    label: 'First Processed Pay Period Start Date',
    type: 'string',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'action',
    name: 'action',
    label: 'Actions',
    sticky: true,
    filter: true,
    formatValue: ({ row, column, route, action }) => (
      <PayrollCalendarTableMenu row={row} column={column} route={route} deleteAction={action} />
    ),
  },
];

const useColumns = () => {
  return [columns];
};

export default useColumns;
