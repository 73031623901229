import dayjs from 'dayjs';
import { isObject, isEmpty, isArray } from 'lodash';

const getLeaveData = (leavesDashboardData) => {
  const input = leavesDashboardData?.leaveAccountSpecificChartsMap;
  return isObject(input) && !isEmpty(input)
    ? Object.entries(input).map(([key]) => {
        return {
          name: leavesDashboardData?.leaveAccountsMap[key]?.leaveCategory?.displayName,
          value: leavesDashboardData?.leaveAccountsMap[key]?.periodOpeningBalance,
          openingBalance: leavesDashboardData?.leaveAccountsMap[key]?.periodOpeningBalance,
          currentBalance: leavesDashboardData?.leaveAccountsMap[key]?.currentBalance,
        };
      })
    : [];
};

const getUserLeaveTransactionsData = (key, employeeID, empIDLeaveCatWiseTranMap = []) => {
  const empIDLeaveCatWiseData = empIDLeaveCatWiseTranMap?.[employeeID];
  if (
    isObject(empIDLeaveCatWiseData) &&
    !isEmpty(empIDLeaveCatWiseData) &&
    Object.keys(empIDLeaveCatWiseData).includes(key)
  ) {
    const leaveTransactionsRow = empIDLeaveCatWiseData[key];
    return isArray(leaveTransactionsRow) && !isEmpty(leaveTransactionsRow)
      ? leaveTransactionsRow.map((item) => ({
          id: 1,
          startDate: item.startDate ? dayjs(item?.startDate).format('DD MMM YYYY') : '-',
          endDate: item.endDate ? dayjs(item?.endDate).format('DD MMM YYYY') : '-',
          leavesAvailed: `${item?.leaveAvailed} ${item?.unit}`,
          leavesAccounted: item?.leavesAccountedAsLOP,
          leavesDeducted: item?.leavesDeductedFromSalaryAsLOP,
        }))
      : [];
  }
  return [];
};

export { getLeaveData, getUserLeaveTransactionsData };
