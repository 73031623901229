import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { CssBaseline, ThemeProvider } from '@mui/material';

// project import
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { createTheme } from './theme';
import store from './store';
import App from './App';
import 'reactflow/dist/style.css';

const container = document.getElementById('root');
const root = createRoot(container);
const persistor = persistStore(store);

const theme = createTheme({ mode: 'light', direction: 'ltr' });

root.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </PersistGate>
    </ReduxProvider>
  </React.StrictMode>,
);
