import React from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import PropTypes from 'prop-types';
import InitiateEmployee from 'components/Organisms/Employee/Company/Separation/InitiateEmployee';

const InitiateEmployeeTemplate = ({
  handleSubmit,
  handleCancel,
  seperationOptions,
  employeeOptions,
  resStatusOptions,
  managerOptions,
  interviewerOptions,
  pageHeading,
  allowEdit,
  defaultValues,
  breadcrumbData,
  disableButton,
}) => {
  return (
    <BlankTemplate pageHeading={pageHeading} breadcrumbData={breadcrumbData}>
      <InitiateEmployee
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        seperationOptions={seperationOptions}
        employeeOptions={employeeOptions}
        resStatusOptions={resStatusOptions}
        managerOptions={managerOptions}
        interviewerOptions={interviewerOptions}
        allowEdit={allowEdit}
        defaultValues={defaultValues}
        disableButton={disableButton}
      />
    </BlankTemplate>
  );
};

InitiateEmployeeTemplate.defaultProps = {
  pageHeading: 'Initiate Employee Exit Details',
  handleSubmit: () => {},
  handleCancel: () => {},
  defaultValues: {},
  seperationOptions: [],
  employeeOptions: [],
  resStatusOptions: [],
  managerOptions: [],
  interviewerOptions: [],
  allowEdit: false,
  breadcrumbData: [
    { id: 1, name: 'Separation', path: '/myteam/separations', isActive: false },
    { id: 2, name: 'Initiate', path: '/initiate', isActive: true },
  ],
  disableButton: false,
};

InitiateEmployeeTemplate.propTypes = {
  pageHeading: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  defaultValues: PropTypes.shape({}),
  seperationOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),

  employeeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),

  resStatusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),

  managerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),

  interviewerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  allowEdit: PropTypes.bool,
  breadcrumbData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      path: PropTypes.string,
      isActive: PropTypes.bool,
    }),
  ),
  disableButton: PropTypes.bool,
};

export default InitiateEmployeeTemplate;
