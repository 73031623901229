import { useEffect, useReducer } from 'react';
import { formDataApi } from 'api/api';

const initialState = {
  isLoading: false,
  data: {},
  error: null,
};

const reducer = (state, action) => {
  const { type, payload } = action;
  let newState;
  switch (type) {
    case 'pending': {
      newState = { ...state, isLoading: true };
      break;
    }
    case 'resolved': {
      newState = { ...state, isLoading: false, data: payload };
      break;
    }
    case 'rejected': {
      newState = { ...state, isLoading: false, error: payload };
      break;
    }
    default: {
      newState = state;
    }
  }
  return newState;
};

const useFetchDashboardByCategory = () => {
  const [payhead, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    const fetchPayheadById = async () => {
      dispatch({ type: 'pending' });
      try {
        const response = await formDataApi.post('restReportIndex/expenseReportsByCategoryAction');
        if (response.data) {
          dispatch({ type: 'resolved', payload: response?.data });
        }
      } catch (error) {
        dispatch({ type: 'rejected', payload: error });
      }
    };
    fetchPayheadById();
  }, []);

  return [payhead];
};

export default useFetchDashboardByCategory;
