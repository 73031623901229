import { createSlice } from '@reduxjs/toolkit';
import { fetchChallans } from 'store/thunks/company/Salary/challan';

const challans = createSlice({
  name: 'challans',
  initialState: {
    selectedChallans: [],
    challans: {
      count: 0,
      data: [],
      states: {},
      extendedDef: {},
    },
  },
  reducers: {
    updateSelectedChallans(state, action) {
      const { isChecked, rowId } = action.payload;

      if (isChecked) {
        state.selectedChallans.push(rowId);
        return;
      }
      state.selectedChallans = state.selectedChallans.filter((selectedRow) => selectedRow !== rowId);
    },
    updateAllSelectedChallans(state, action) {
      const { isChecked } = action.payload;
      state.selectedChallans = [];
      if (isChecked) {
        state.challans.data.map((row) => state.selectedChallans.push(row.id));
        return;
      }
      state.selectedChallans = [];
    },
    setBulkUpdateChallanIds(state, action) {
      state.bulkUpdateChallans = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchChallans.fulfilled, (state, action) => {
      const { challanInstanceList = [], challanInstanceCount } = action.payload;
      state.challans.data = challanInstanceList;
      state.challans.count = challanInstanceCount;
    });
  },
});

export const { updateSelectedChallans, updateAllSelectedChallans, setBulkUpdateChallanIds } = challans.actions;

export default challans.reducer;
