import React, { useState, useEffect, useCallback, createContext } from 'react';
import Alert from 'components/Atoms/Alert';
import { ThemeProvider } from '@mui/material';
import { createTheme } from 'theme';

const ToastContext = createContext();
export default ToastContext;

let id = 1;

export const AlertContextProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  useEffect(() => {
    if (toasts.length > 0) {
      const timer = setTimeout(() => setToasts((prevToasts) => prevToasts.slice(1)), 3000);

      return () => clearTimeout(timer);
    }
    return () => {};
  }, [toasts]);

  const addToast = useCallback(
    ({ type, title, message }) => {
      setToasts((ts) => [...ts, { id, severity: type, titleText: title, message }]);
      id += 1;
    },
    [setToasts],
  );

  const theme = createTheme({ mode: 'light', direction: 'ltr' });

  return (
    <ThemeProvider theme={theme}>
      <ToastContext.Provider value={addToast}>
        {children}
        <div style={{ position: 'fixed', top: 10, right: 10, maxWidth: '40%' }}>
          {toasts.map((toast) => {
            return (
              <Alert
                key={toast.id}
                severity={toast.severity}
                titleText={toast.titleText}
                message={toast.message}
                isOpen
              />
            );
          })}
        </div>
      </ToastContext.Provider>
    </ThemeProvider>
  );
};
