import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Grid, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Button from 'components/Atoms/Button';
import InputField from 'components/Atoms/InputField/index';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Typographys from 'components/Atoms/Typography';
import { profileStyle } from 'components/Organisms/Profile/style';
import Hidden from '@mui/material/Hidden';
import Logo from 'components/Atoms/Logo';
import defaultHeroImage from 'assets/images/overview.png';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useToast from 'components/Provider/useToast';
import { formDataApi } from 'api/api';

const EditPassword = () => {
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();
  const rCode = searchParams.get('r');
  const addToast = useToast();
  const [loading, setLoading] = useState(false);

  const EditPasswordSchema = yup.object().shape({
    newPassword: yup.string().required('Required'),
    reEnterNewPassword: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], 'Password not matched')
      .required('Required'),
  });

  const resetPassword = async (data) => {
    const bodyFormData = new FormData();
    bodyFormData.append('newPasswd', data.newPassword);
    bodyFormData.append('newPasswd2', data.reEnterNewPassword);
    bodyFormData.append('r', rCode);

    try {
      setLoading(true);
      const response = await formDataApi.post(`authRest/resetMyPasswordAction`, bodyFormData);
      if (response?.data?.status !== 'ERROR') {
        addToast({ type: 'success', title: 'SUCCESS', message: response?.data?.message });
        navigate('/login');
      } else if (response?.data?.status === 'ERROR') {
        addToast({ type: 'error', title: 'ERROR', message: response?.data?.message });
      }
    } catch (error) {
      addToast({ type: 'error', title: 'ERROR', message: error?.message });
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      reEnterNewPassword: '',
    },
    validateOnMount: true,
    onSubmit: (data) => {
      resetPassword(data);
    },
    validationSchema: EditPasswordSchema,
  });
  const theme = useTheme();
  return (
    <Box sx={{ flexGrow: 1, height: '100vh', p: { xs: 3, md: 0 } }}>
      <Hidden mdUp>
        <Box>
          <Logo alt="Logo" isDark imgHeight="32" imgWidth="115" />
        </Box>
      </Hidden>
      <Grid container height="100%">
        <Hidden mdDown>
          <Grid
            item
            xs={6}
            sx={{
              background: 'radial-gradient(109.43% 109.43% at 98.49% 99.08%, #2C677A 0%, #11262E 100%)',
              overflow: 'hidden',
              position: 'relative',
              padding: 6.5,
            }}
          >
            <Logo alt="Logo" isDark={false} />
            <Box mt={{ xs: 5, md: 8.5 }}>
              <Box zIndex={9} width={1} maxWidth={604}>
                <Typographys variant="h2" color="common.white">
                  BUILDING GLOBAL TEAMS
                </Typographys>
                <Typographys variant="body2" mt={1} color="common.white">
                  Enabling enterprises to build and scale global teams through effective Demand planning, tracking and
                  Change Management.
                </Typographys>
              </Box>

              <Box
                sx={{
                  position: 'absolute',
                  right: 0,
                  bottom: '-1.25rem',
                  zIndex: '1',
                  maxWidth: '83.5rem',
                  paddingLeft: 6.5,
                  [theme.breakpoints.down(1400)]: { maxWidth: '68.75rem' },
                }}
              >
                <img src={defaultHeroImage} alt="ANSR Overview" width="100%" />
              </Box>
            </Box>
          </Grid>
        </Hidden>

        <Grid item xs={12} md={6} display="flex" alignItems="center" justifyContent="center">
          <Box component="form" onSubmit={formik.handleSubmit} mt={1.5}>
            <Grid sx={{ flexDirection: 'column !important' }} container>
              <Typographys variant="h2">Reset your password</Typographys>
              <Grid item sx={{ maxWidth: '100% !important', flexBasis: 1, p: 0 }} mt={4} xs={12} md={4}>
                <InputField
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.newPassword}
                  name="newPassword"
                  id="newPassword"
                  label="New Password"
                  autoComplete="New Password"
                  fullWidth
                />
              </Grid>
              <Grid item sx={{ maxWidth: '100% !important', flexBasis: 1, p: 0 }} mt={2} xs={12} md={4}>
                <InputField
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.reEnterNewPassword}
                  name="reEnterNewPassword"
                  id="reEnterNewPassword"
                  label="Re-Enter New Password"
                  autoComplete="Re-Enter New Password"
                  fullWidth
                  type="password"
                />
              </Grid>
            </Grid>

            {Boolean(Object.keys(formik.errors).length) && formik.values.newPassword && (
              <Grid sx={{ maxWidth: '370px !important' }} item xs={12} md={12} mt={2}>
                <Typographys variant="body2" mr={1} color="error">
                  {formik.values.newPassword && !formik.values.reEnterNewPassword
                    ? 'Please enter identical password in both boxes'
                    : 'Given passwords do not match! Please enter identical password in both boxes'}
                </Typographys>
              </Grid>
            )}

            <Box gap={2} mt={3}>
              <Box sx={profileStyle.submitWrapper}>
                <Button size="large" type="button" color="info" variant="outlined" onClick={() => navigate('/login')}>
                  Cancel
                </Button>
                <Button size="large" type="submit">
                  Reset Password
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditPassword;
