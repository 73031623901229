import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';
import { Box } from '@mui/system';
import Typographys from 'components/Atoms/Typography';
import { avatarStyle } from './style';

const Avatars = ({
  isIcon,
  iconSrc,
  iconWidth,
  iconHeight,
  iconAlt,
  avatarSrc,
  onClick,
  variant,
  sx,
  name,
  isName,
  isShortName,
  badge,
  badgeColor,
  badgeTop,
  badgeBottom,
  badgeLeft,
  badgeRight,
}) => {
  const stringAvatar = (avatarName) => {
    const splitAvatar = avatarName.split(' ');
    return splitAvatar.reduce((acc, curr) => acc + curr[0], '');
  };
  return (
    <Box sx={avatarStyle.wrapper}>
      <Box sx={avatarStyle.nameWrapper}>
        <Avatar src={avatarSrc} variant={variant} onClick={onClick} sx={sx}>
          {isShortName && <Typographys variant="avatarLetter">{stringAvatar(name)} </Typographys>}
          {isIcon && <img src={iconSrc} width={iconWidth} height={iconHeight} alt={iconAlt} />}
        </Avatar>

        {badge && (
          <Box
            sx={avatarStyle.badge}
            bgcolor={badgeColor}
            top={badgeTop}
            bottom={badgeBottom}
            left={badgeLeft}
            right={badgeRight}
          />
        )}
      </Box>

      {isName && (
        <Box ml={1} opacity={80}>
          <Typographys color="common.black" variant="subtitle2">
            {name}
          </Typographys>
        </Box>
      )}
    </Box>
  );
};
Avatars.defaultProps = {
  avatarSrc: '',
  onClick: () => {},
  variant: 'circular',
  sx: {},
  isShortName: false,
  isName: false,
  name: 'Kent Dodds',
  isIcon: false,
  iconSrc: '/icons/delete.svg',
  iconWidth: 16,
  iconHeight: 20,
  iconAlt: 'email',
  badge: false,
  badgeColor: 'success.400',
  badgeTop: '',
  badgeBottom: '',
  badgeLeft: '',
  badgeRight: '',
};
Avatars.propTypes = {
  variant: PropTypes.string,
  avatarSrc: PropTypes.string,
  sx: PropTypes.shape({}),
  onClick: PropTypes.func,
  isName: PropTypes.bool,
  isShortName: PropTypes.bool,
  name: PropTypes.string,
  isIcon: PropTypes.bool,
  iconSrc: PropTypes.string,
  iconWidth: PropTypes.number,
  iconHeight: PropTypes.number,
  iconAlt: PropTypes.string,
  badge: PropTypes.bool,
  badgeColor: PropTypes.string,
  badgeTop: PropTypes.string,
  badgeBottom: PropTypes.string,
  badgeLeft: PropTypes.string,
  badgeRight: PropTypes.string,
};
export default Avatars;
