import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Divider, Grid, List, ListItem, ListItemText } from '@mui/material';
import Typography from 'components/Atoms/Typography';
import AttachmentsUpload from 'components/Atoms/AttachmentsUpload';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import PerfectScrollbar from 'react-perfect-scrollbar';
import TitleWithTextInline from 'components/Atoms/TitleWithTextInline';
import * as yup from 'yup';
import { taskStyle } from 'components/Organisms/TaskAndQueries/style';
import { Form12BBStyle } from '../../style';

const Form12BBUpload = ({ formData, handleSubmit, handleAttachment, loading }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const theme = useTheme();

  const initialValues = {
    attachments: '',
  };

  const handleAttachmentCheck = () => {
    if (initialValues?.attachments === '') {
      setIsSubmitted(true);
    } else {
      setIsSubmitted(false);
    }
  };

  const Form12BBUploadSchema = yup.object().shape({
    attachments: yup.mixed().required('Required'),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Form12BBUploadSchema,
    validateOnMount: true,
    onSubmit: handleSubmit,
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={{ margin: '-2rem -3rem' }}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 24rem)' }}>
          <Box sx={Form12BBStyle.uploadWrapper}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6">FORM 12BB (Mandatory For Finalizing Proof Submissions) **</Typography>
              </Grid>
              <Grid item xs={12}>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                    <TitleWithTextInline title="Submission Status:" text={formData?.id ? 'Submitted' : 'Pending'} />
                    <TitleWithTextInline title="Form 12BB status:" text={formData?.status} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <>
              <Box sx={{ textAlign: 'center' }}>
                <AttachmentsUpload
                  isDeleteIcon
                  handleChange={(event) => {
                    formik.setFieldValue(`attachments`, event.target.files[0]);
                  }}
                  name="attachments"
                  id="attachments"
                  title="Choose File"
                  value={formik.values.attachments}
                  handleDelete={() => {
                    formik.setFieldValue(`attachments`, '');
                  }}
                />
                {!formik.values?.attachments && isSubmitted && (
                  <Typography variant="caption" color="red">
                    Please attach a document*
                  </Typography>
                )}
              </Box>
              {!formData?.id && !formik.values?.attachments && !isSubmitted && (
                <Typography variant="subtitle2" color="neutral.600" sx={{ width: '150px' }}>
                  No file chosen
                </Typography>
              )}
              {!!formData?.id && !formik.values?.attachments && (
                <Button
                  variant="text"
                  color="secondary"
                  onClick={() => handleAttachment(formData?.id, formData?.documentName)}
                >
                  {formData?.documentName}
                </Button>
              )}
            </>
          </Box>
          <Box sx={Form12BBStyle.noteListWrapper}>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant="body1">
                      <b>a.</b> You can download your Form 12BB from My Pay &gt; Generate Form12 BB on the main Menu
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant="body1">
                      <b>b.</b> FORM 12BB upload is mandatory for approving investment proof submissions
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Box>
        </Box>
      </PerfectScrollbar>
      <Box sx={taskStyle.btnContainer}>
        <Divider sx={{ my: 2, borderColor: theme.palette.other[200] }} />
        <Box sx={taskStyle.submitButtons}>
          <Button
            size="large"
            color="info"
            variant="outlined"
            type="reset"
            onClick={() => {
              formik.resetForm();
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            color="primary"
            size="large"
            type="submit"
            variant="contained"
            onClick={handleAttachmentCheck}
          >
            Upload Form 12BB
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

Form12BBUpload.defaultProps = {
  formData: {},
  handleSubmit: () => {},
  handleAttachment: () => {},
  loading: false,
};

Form12BBUpload.propTypes = {
  formData: PropTypes.shape,
  handleSubmit: PropTypes.func,
  handleAttachment: PropTypes.func,
  loading: PropTypes.bool,
};

export default Form12BBUpload;
