export const resetPasswordStyle = {
  wrapper: {
    width: 1,
    maxWidth: '60.25rem',
    display: 'flex',
    margin: 'auto',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  formWrapper: {
    width: 1,
    maxWidth: '44.375rem',
    margin: 'auto',
    mt: 6.5,
  },
  formbutton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    my: 4.5,
  },
  formtext: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 0.5,
  },
};
