import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { getFormInitialValues } from './getFormInitialValues';

export const useCustomAttributesFormik = (metaCustomFieldDefinitions, customObjectInstance, handleSubmit) => {
  const [initialValues, setInitialValues] = useState({});

  const getInitialValues = () => {
    return getFormInitialValues(
      metaCustomFieldDefinitions.metaCustomFieldDefinitions,
      customObjectInstance?.customFieldValues,
    );
  };

  useEffect(() => {
    if (getInitialValues && metaCustomFieldDefinitions?.metaCustomFieldDefinitions?.length) {
      const data = getInitialValues();
      setInitialValues(data);
    }
  }, [metaCustomFieldDefinitions?.metaCustomFieldDefinitions]);

  const customAttributesFormik = useFormik({
    enableReinitialize: true,
    initialValues,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values, helpers) => {
      try {
        handleSubmit(values);
        // helpers.resetForm();
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
      } catch (err) {
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });

  return { customAttributesFormik, metaCustomFieldDefinitions };
};
