import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, useTheme } from '@mui/material';
import MuiTab from 'components/Molecules/Tab';
import ProfileCard from 'components/Organisms/Profile/OnboardingRecords/ProfileCard';
import StatutoryIdentification from 'components/Organisms/Profile/OnboardingRecords/StatutoryIdentification';
import BasicProfile from 'components/Organisms/Profile/OnboardingRecords/BasicProfile';
import ContactDetail from 'components/Organisms/Profile/OnboardingRecords/ContactDetails';
import BankAccount from 'components/Organisms/Profile/OnboardingRecords/BankAccount';
import AdditionalDetail from '../AdditionalDetails';
import Dependents from '../Dependents';

const Forms = ({ profileDetail, contactInfo, employmentInfo, onboardingDetails, handleAttachment }) => {
  const initialTabs = [
    {
      label: 'Basic Profile',
      onChange: () => {},
      RenderComponent: BasicProfile,
      componentProps: { details: onboardingDetails['Basic Profile'] },
    },
    {
      label: 'Statutory Identification Details',
      onChange: () => {},
      RenderComponent: StatutoryIdentification,
      componentProps: { identificationDetail: onboardingDetails['Statutory Identification Details'] },
    },
    {
      label: 'Additional Details',
      onChange: () => {},
      RenderComponent: AdditionalDetail,
      componentProps: { ...onboardingDetails['Additional Details'], handleAttachment },
    },
    {
      label: 'Contact Details',
      onChange: () => {},
      RenderComponent: ContactDetail,
      componentProps: { ...onboardingDetails['Contact Details'] },
    },
    {
      label: 'Dependents',
      onChange: () => {},
      RenderComponent: Dependents,
      componentProps: { ...onboardingDetails.Dependents },
    },
    {
      label: 'Bank Account',
      onChange: () => {},
      RenderComponent: BankAccount,
      componentProps: { ...onboardingDetails['Bank Account'] },
    },
  ];

  const theme = useTheme();

  return (
    <Box>
      <Box px={3}>
        <ProfileCard profileDetail={profileDetail} contactInfo={contactInfo} employmentInfo={employmentInfo} />
      </Box>

      <Divider sx={{ mt: 2, backgroundColor: theme.palette.neutral[200] }} />

      <Box>
        <MuiTab
          key={initialTabs}
          orientation="horizontal"
          variant="scrollable"
          scrollButtons={false}
          textColor="primary"
          indicatorColor="primary"
          tabs={initialTabs}
          allowScrollButtonsMobile
          panelPadding="2rem 0rem"
        />
      </Box>
    </Box>
  );
};

Forms.defaultProps = {
  handleAttachment: () => {},
  profileDetail: {
    name: '',
    avatarSrc: '',
  },

  contactInfo: {
    location: '',
    email: '',
    mobile: '',
    phone: '',
  },

  employmentInfo: {
    designation: '',
    department: '',
    supervisor: '',
    employeeID: '',
    employmentType: '',
  },

  onboardingDetails: {},
};

Forms.propTypes = {
  handleAttachment: PropTypes.func,
  profileDetail: PropTypes.shape({
    name: PropTypes.string,
    avatarSrc: PropTypes.string,
    location: PropTypes.string,
    email: PropTypes.string,
    mobile: PropTypes.string,
    phone: PropTypes.string,
    designation: PropTypes.string,
    department: PropTypes.string,
    supervisor: PropTypes.string,
    employeeID: PropTypes.string,
    employmentType: PropTypes.string,
  }),

  contactInfo: PropTypes.shape({
    location: PropTypes.string,
    email: PropTypes.string,
    mobile: PropTypes.string,
    phone: PropTypes.string,
  }),

  employmentInfo: PropTypes.shape({
    designation: PropTypes.string,
    department: PropTypes.string,
    supervisor: PropTypes.string,
    employeeID: PropTypes.string,
    employmentType: PropTypes.string,
  }),

  onboardingDetails: PropTypes.shape({
    'Basic Profile': PropTypes.shape({}),
    'Statutory Identification Details': PropTypes.shape({}),
    'Contact Details': PropTypes.shape({}),
    'Additional Details': PropTypes.shape({}),
    Dependents: PropTypes.shape({}),
    'Bank Account': PropTypes.shape({}),
    AdditionalDetail: PropTypes.shape({}),
  }),
};

export default Forms;
