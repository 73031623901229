import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Typographys from 'components/Atoms/Typography';

const LeaveBalanceList = ({ title, listData }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}>
      <Typographys variant="subtitle2" align="center" color="neutral.800">
        {title}
      </Typographys>
      {listData.map((item) => (
        <Typographys variant="subtitle2" align="center" color="neutral.600">
          {title === 'OPENING BALANCE' ? item?.openingBalance : item?.currentBalance}
        </Typographys>
      ))}
    </Box>
  );
};

LeaveBalanceList.defaultProps = {
  title: 'OPENING BALANCE',
  listData: [],
};
LeaveBalanceList.propTypes = {
  title: PropTypes.string,
  listData: PropTypes.arrayOf(
    PropTypes.shape({
      openingBalance: PropTypes.number,
      currentBalance: PropTypes.number,
    }),
  ),
};

export default LeaveBalanceList;
