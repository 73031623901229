import { createSlice } from '@reduxjs/toolkit';
import {
  fetchLCRecordDetails,
  fetchLCModifyRecordDetails,
  fetchLCApproveRejectRecordDetails,
} from 'store/thunks/company/lifeCycleRecords';

const lifeCycleRecords = createSlice({
  name: 'lifeCycleRecords',
  initialState: {
    lcRecordDetails: {},
    lcModifyRecordDetails: {},
    lcApproveRejectRecordDetails: {},
  },

  extraReducers(builder) {
    builder.addCase(fetchLCRecordDetails.fulfilled, (state, action) => {
      state.lcRecordDetails = action.payload;
    });
    builder.addCase(fetchLCModifyRecordDetails.fulfilled, (state, action) => {
      state.lcModifyRecordDetails = action.payload;
    });
    builder.addCase(fetchLCApproveRejectRecordDetails.fulfilled, (state, action) => {
      state.lcApproveRejectRecordDetails = action.payload;
    });
  },
});

export default lifeCycleRecords.reducer;
