import React from 'react';
import PropTypes from 'prop-types';
import { Box, Link } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import Dropdown from 'components/Molecules/Dropdown';
import { employeeCommonCardStyle } from './style';

const EmployeeCommonCard = ({
  cardHeaderTitle,
  isHeaderFilter,
  minWidthHeaderDropdown,
  headerFilterId,
  headerFilterLabel,
  headerFilterOptions,
  headerFilterHandleChange,
  bodyContent,
  isFooter,
  footerLink,
  footerLinkLabel,
  FooterContent,
  cardBodyMinHeight,
  cardBodyPadding,
}) => {
  return (
    <Box sx={employeeCommonCardStyle.card}>
      <Box sx={employeeCommonCardStyle.cardHeader}>
        <Typographys variant="h6" color="neutral.800">
          {cardHeaderTitle}
        </Typographys>
        {isHeaderFilter && (
          <Box minWidth={minWidthHeaderDropdown}>
            <Dropdown
              variant="standard"
              id={headerFilterId}
              name={headerFilterId}
              label={headerFilterLabel}
              labelId={headerFilterId}
              options={headerFilterOptions}
              handleChange={headerFilterHandleChange}
            />
          </Box>
        )}
      </Box>
      <Box padding={cardBodyPadding} minHeight={cardBodyMinHeight} sx={employeeCommonCardStyle.cardBody}>
        {bodyContent}
      </Box>
      {isFooter && (
        <Box sx={employeeCommonCardStyle.cardFooter}>
          <Link href={footerLink}>{footerLinkLabel}</Link> {FooterContent}
        </Box>
      )}
    </Box>
  );
};

EmployeeCommonCard.defaultProps = {
  cardHeaderTitle: 'New Hires',
  isHeaderFilter: true,
  minWidthHeaderDropdown: '6rem',
  headerFilterId: 'week',
  headerFilterLabel: 'Week',
  headerFilterOptions: [
    { id: 1, name: 'Option 1', onClick: () => {} },
    { id: 2, name: 'Option 2', onClick: () => {} },
    { id: 3, name: 'Option 3', onClick: () => {} },
  ],
  headerFilterHandleChange: () => {},
  bodyContent: '',
  isFooter: true,
  footerLink: '#',
  footerLinkLabel: 'View Details',
  FooterContent: '',
  cardBodyMinHeight: '20rem',
  cardBodyPadding: '0',
};
EmployeeCommonCard.propTypes = {
  cardHeaderTitle: PropTypes.string,
  isHeaderFilter: PropTypes.bool,
  minWidthHeaderDropdown: PropTypes.string,
  headerFilterId: PropTypes.string,
  headerFilterLabel: PropTypes.string,
  headerFilterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  headerFilterHandleChange: PropTypes.func,
  bodyContent: PropTypes.node,
  isFooter: PropTypes.bool,
  footerLink: PropTypes.string,
  footerLinkLabel: PropTypes.string,
  FooterContent: PropTypes.node,
  cardBodyMinHeight: PropTypes.string,
  cardBodyPadding: PropTypes.string,
};

export default EmployeeCommonCard;
