import React from 'react';
import { useParams } from 'react-router-dom';
import EmployeeRecordTemplate from 'components/Templates/Employee/EmployeeRecord';
import { Typography, Skeleton, Box } from '@mui/material';
import { hasPermission } from 'utils/permissions';
import { useSelector } from 'react-redux';
import useFetchEmployee from './useFetchEmployee';
import useEmployeeTabs from './useEmployeeTabs';
import { convertEmployeeData } from './converter';

const ViewEmployee = () => {
  const { userId } = useParams();

  const [employee] = useFetchEmployee(userId);
  const transformedData = convertEmployeeData(employee);
  const employeeDetailTab = useEmployeeTabs(transformedData);

  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasViewPermission = hasPermission(authorities, ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_SECUSER']);

  if (employee.error !== null) {
    return (
      <Typography variant="h6">
        {employee?.error?.response?.data?.message || 'Error while loading employee details.'}
      </Typography>
    );
  }

  if (!userHasViewPermission) {
    return <Typography variant="h6">You do not have permission to view employee details.</Typography>;
  }
  return employee.isLoading ? (
    <Box sx={{ p: 4 }}>
      <Skeleton height={70} />
      <Skeleton />
      <Skeleton />
    </Box>
  ) : (
    <EmployeeRecordTemplate
      employeeProfileDetail={transformedData.employeeProfileDetail}
      employeeDetails={employeeDetailTab}
    />
  );
};

export default ViewEmployee;
