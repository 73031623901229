import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import { Box, ListItemButton, ListItemText } from '@mui/material';
import InputField from 'components/Atoms/InputField';
import { searchBoxStyle } from './style';

const SearchBox = ({ id, name, size, type, handleChange, placeholder, searchResult }) => {
  const [options, setOption] = useState(searchResult);
  const [searchValue, setSearchValue] = useState('');
  const onChange = (e) => {
    const searchText = e.target.value;
    handleChange(searchText);
    setSearchValue(searchText);
    setOption(searchResult.filter((value) => value.label.includes(searchText)));
  };
  return (
    <Box position="relative">
      <Box sx={searchBoxStyle.searchWrapper}>
        <SearchIcon />
        <InputField
          id={id}
          name={name}
          type={type}
          size={size}
          value={searchValue}
          onChange={onChange}
          placeholder={placeholder}
        />
      </Box>
      {searchValue !== '' && (
        <Box sx={searchBoxStyle.searchFilterWrapper}>
          {options.length === 0 && <ListItemText sx={{ px: 2, py: 1 }} primary="No Result" />}
          {options.map((result) => (
            <ListItemButton onClick={() => result.onClick(result)}>
              <ListItemText primary={result.label} />
            </ListItemButton>
          ))}
        </Box>
      )}
    </Box>
  );
};

SearchBox.defaultProps = {
  id: 'search',
  type: 'text',
  name: 'search',
  size: 'normal',
  handleChange: () => {},
  placeholder: 'Advanced Search',
  searchResult: [
    {
      label: 'Employee ID',
      onClick: () => {},
    },
    {
      label: 'User Name',
      onClick: () => {},
    },
    {
      label: 'Status',
      onClick: () => {},
    },
  ],
};

SearchBox.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  handleChange: PropTypes.func,
  searchResult: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  placeholder: PropTypes.string,
};
export default SearchBox;
