const columns = [
  {
    fieldName: 'employeeID',
    label: 'Employee ID',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'employeeName',
    label: 'Employee Name',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'inTime',
    label: 'In Time',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'closeTime',
    label: 'Close Time',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'lastPausedTime',
    label: 'Last paused time',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'lastRestartTime',
    label: 'Last restart time',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'timerState',
    label: 'Timer State',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
];

const useColumns = () => {
  return [columns];
};

export default useColumns;
