import {
  listPreviousEmploymentTaxes,
  fetchCreatePreviousEmploymentTaxDetails,
  fetchEditPreviousEmploymentTaxDetails,
  fetchPreviousEmploymentTaxDetails,
} from 'api/home/myPay';
import { asyncThunkWrapper } from 'utils/AsyncThunkWrapper';

const fetchPreviousEmploymentTaxesList = asyncThunkWrapper('previousEmploymentTaxes/list', listPreviousEmploymentTaxes);

const previousEmploymentTaxDetailsCreate = asyncThunkWrapper(
  'previousEmploymentTaxes/create',
  fetchCreatePreviousEmploymentTaxDetails,
);

const previousEmploymentTaxDetailsEdit = asyncThunkWrapper(
  'previousEmploymentTaxes/edit',
  fetchEditPreviousEmploymentTaxDetails,
);

const viewPreviousEmploymentTaxDetails = asyncThunkWrapper(
  'previousEmploymentTaxes/view',
  fetchPreviousEmploymentTaxDetails,
);

export {
  fetchPreviousEmploymentTaxesList,
  previousEmploymentTaxDetailsCreate,
  previousEmploymentTaxDetailsEdit,
  viewPreviousEmploymentTaxDetails,
};
