export const profileStyle = {
  actionBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  formContainer: {
    width: '100%',
    maxWidth: '100%',
    mb: 2.5,
  },

  formDivider: {
    '&:not(:first-child)': {
      borderTop: '0.125rem solid',
      borderColor: 'other.200',
      mt: 4,
      pt: 4,
    },
  },

  textDivider: {
    '&:not(:first-child)': {
      borderTop: '0.125rem solid',
      borderColor: 'other.200',
      mt: 2.5,
      pt: 2.5,
    },
  },

  otherDivider: {
    '&:not(:first-child)': {
      borderTop: '0.125rem solid',
      borderColor: 'other.200',
      mt: 4,
      pt: 2.5,
    },
  },

  addButton: {
    my: 3,
  },

  btnContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%',
    backgroundColor: 'common.white',
    zIndex: '9',
    margin: '0rem',
    padding: '0rem 3rem 2rem 3rem',
    boxSizing: 'border-box',
  },

  submitWrapper: {
    display: 'flex',
    justifyContent: 'end',
    gap: 2,
  },
  addWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: 2,
    my: 2,
  },
  divWrapper: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '2rem',
  },
};
