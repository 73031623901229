export const myExpensesStyle = {
  expenseRecordWrapper: {
    backgroundColor: 'neutral.100',
    p: 3,
    borderRadius: '0.75rem',
  },

  expenseRecordForm: {
    background: 'white',
    p: 3,
    borderRadius: '0.75rem',
    mt: 2,
  },

  newExpenseBtn: {
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'flex-start',
    '.MuiButton-root.MuiButton-outlined': {
      background: 'transparent',
    },
  },

  newExpenseActionBtnGroup: {
    justifyContent: 'flex-end',
    display: 'flex',
    columnGap: '1.5rem',
    mb: 1,
  },

  actionDeleteIcon: {
    fontSize: '3rem',
    color: 'neutral.600',
    cursor: 'pointer',
  },

  addBoxIcon: {
    fontSize: '4.8rem',
    color: 'secondary.600',
    cursor: 'pointer',
    pl: 1.5,
  },

  deleteIcon: {
    fontSize: '4.8rem',
    color: 'neutral.600',
    cursor: 'pointer',
    pl: 1.5,
  },

  iconGrid: {
    display: 'flex',
    alignItems: 'center',
  },

  submitWrapper: {
    display: 'flex',
    justifyContent: 'end',
    gap: 2,
  },

  btnContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%',
    paddingRight: 0,
    marginRight: 0,
    backgroundColor: 'common.white',
    zIndex: '9',
    margin: '0rem',
    padding: '0rem 3rem 3rem 3rem',
  },
};

export const expensesStyle = {
  actionBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    px: 3,
    my: 2,
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    mt: 2,
  },
  tabWrapper: {
    borderTop: '0.125rem solid',
    borderColor: 'other.200',
    mt: 2.5,
  },
};
