import React, { useEffect } from 'react';
import ExpensesDashboard from 'components/Templates/Expenses/Dashboard';
import { useThunk } from 'hooks/useThunk';
import { fetchMyExpensesDashboardDetails } from 'store';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Skeleton, Typography } from '@mui/material';

const ExpensesDashboardPage = () => {
  const navigate = useNavigate();

  const [getExpensesDashboard, isLoadingExpensesDashboard, loadingExpensesDashboardError] = useThunk(
    fetchMyExpensesDashboardDetails,
  );

  useEffect(() => {
    const query = new URLSearchParams({});
    getExpensesDashboard(query);
  }, [getExpensesDashboard]);

  const {
    barChartAggregateExpensesDataColumns,
    barChartAggregateExpensesData,
    aggregateExpensedAmountsStatus,
    barChartAggregateCatWiseExpensesDataColumns,
    barChartAggregateCatWiseExpensesData,
    defaultCurrency,
  } = useSelector((state) => state.expenses);

  const colorCode = ['600', '500', '400', '300', '300', '200', '100'];
  const lineColor = ['secondary', 'primary'];

  const barData = barChartAggregateExpensesDataColumns
    .filter((column) => column[0] !== 'string' && column[1] !== 'Month')
    .map((column, i) => ({
      name: column[1],
      dataKey: column[1].replaceAll(' ', ''),
      stackId: 'Aggregate',
      color: 'secondary',
      colorCode: colorCode[i],
    }));

  const expenseDetails = Object.entries(aggregateExpensedAmountsStatus)
    .filter(([key]) => {
      return key !== 'Submitted';
    })
    .map(([key, value]) => {
      let keyName = '';
      if (key === 'Approved Pending Payment') {
        keyName = 'Pending Settlements';
      } else if (key === 'Payment Complete') {
        keyName = 'Settled';
      } else {
        keyName = key;
      }
      return { label: keyName, amount: value };
    });

  const chartData = barChartAggregateExpensesData.map((data) => ({
    ...barChartAggregateExpensesDataColumns.reduce((acc, cur, i) => {
      const keyName = cur[1].replaceAll(' ', '');
      acc[keyName] = data[i];
      return acc;
    }, {}),
  }));

  const lineData = barChartAggregateCatWiseExpensesDataColumns.map((data, i) => ({
    name: data[1],
    dataKey: data[1].replaceAll(' ', ''),
    type: 'monotone',
    color: lineColor[Math.floor(Math.random() * lineColor.length)],
    colorCode: colorCode[i],
  }));

  const lineChartData = barChartAggregateCatWiseExpensesData.map((data) => ({
    ...barChartAggregateCatWiseExpensesDataColumns.reduce((acc, cur, i) => {
      const keyName = cur[1].replaceAll(' ', '');
      acc[keyName] = data[i];
      return acc;
    }, {}),
  }));

  const handleAction = () => {
    navigate(`/home/expenses/add`);
  };

  if (isLoadingExpensesDashboard) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (loadingExpensesDashboardError !== null) {
    return (
      <Typography variant="h6">
        {loadingExpensesDashboardError?.message || 'Error while fetching expense details.'}
      </Typography>
    );
  }

  return (
    <ExpensesDashboard
      pageHeading="Expenses"
      isActionMenuLists={false}
      isActionButton
      actionLabel="Add Expense Report"
      handleAction={handleAction}
      expenseDetails={expenseDetails}
      chartData={chartData}
      barData={barData}
      lineData={lineData}
      lineChartData={lineChartData}
      symbol={defaultCurrency?.symbol}
    />
  );
};

export default ExpensesDashboardPage;
