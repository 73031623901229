export const profileStyle = {
  actionBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  formContainer: {
    width: '100%',
    maxWidth: '100%',
    mb: 2.5,
  },

  formDivider: {
    '&:not(:first-child)': {
      borderTop: '0.125rem solid',
      borderColor: 'other.200',
      mt: 4,
      pt: 4,
    },
  },

  textDivider: {
    '&:not(:first-child)': {
      borderTop: '0.125rem solid',
      borderColor: 'other.200',
      mt: 2.5,
      pt: 2.5,
    },
  },

  otherDivider: {
    '&:not(:first-child)': {
      borderTop: '0.125rem solid',
      borderColor: 'other.200',
      mt: 4,
      pt: 2.5,
    },
  },

  addButton: {
    my: 3,
  },

  btnContainer: {
    position: 'fixed',
    right: '0rem',
    bottom: '3.625rem',
    backgroundColor: 'common.white',
    zIndex: '9',
    margin: '0rem 3.25rem',
    width: 'calc(100% - 49.5rem)',
    padding: '0rem 3rem 3.25rem 3rem',
  },

  submitWrapper: {
    display: 'flex',
    justifyContent: 'end',
    gap: 2,
  },
  deleteContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '5rem',
    marginBottom: '2rem',
  },
};
