import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import Button from 'components/Atoms/Button';
import EditIcon from '@mui/icons-material/Edit';
import TitleWithText from 'components/Atoms/TitleWithText';
import NoRecordsMessage from 'components/Organisms/CommonPageUtils/NoRecordsFound';
import { validateDateElseHyphen } from 'utils/commonUtils';
import { hasPermission } from 'utils/permissions';
import { useSelector } from 'react-redux';
import { profileStyle } from './style';

const ProfileLifeEventDetails = ({ lifeEventDetails, handleEdit }) => {
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasEditPermission = hasPermission(authorities, ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_MY_EVENTS']);

  return (
    <>
      <Box sx={profileStyle.actionBtn}>
        {!!userHasEditPermission && (
          <Button startIcon={<EditIcon fontSize="2.5rem" />} onClick={handleEdit}>
            {lifeEventDetails.length > 0 ? 'Edit' : 'Add'}
          </Button>
        )}
      </Box>

      {lifeEventDetails.length > 0 ? (
        <Box>
          {lifeEventDetails?.map((detail) => (
            <Box sx={profileStyle.formDivider}>
              <Grid container marginTop={0} rowSpacing={1} columnSpacing={2}>
                <Grid item xs={12} sm={4} lg={5}>
                  <TitleWithText title="Key Life Event Details" text={detail?.eventType || '-'} />
                </Grid>
                <Grid item xs={12} sm={4} lg={5}>
                  <TitleWithText title="Event Date" text={validateDateElseHyphen(detail?.eventDate, 'DD MMMM YYYY')} />
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                  <TitleWithText title="Comments" text={detail?.comments || '-'} />
                </Grid>
              </Grid>
            </Box>
          ))}
        </Box>
      ) : (
        <NoRecordsMessage width="20rem" height="20rem" />
      )}
    </>
  );
};

ProfileLifeEventDetails.defaultProps = {
  lifeEventDetails: [],
  handleEdit: () => {},
};

ProfileLifeEventDetails.propTypes = {
  lifeEventDetails: PropTypes.arrayOf,
  handleEdit: PropTypes.func,
};

export default ProfileLifeEventDetails;
