import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import MuiTab from 'components/Molecules/Tab';
import CakeIcon from '@mui/icons-material/Cake';
import CelebrationIcon from '@mui/icons-material/Celebration';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import BirthdaysTab from './BirthdaysTab';
import AnniversariesTab from './AnniversariesTab';
import TeamMembersTab from './TeamMembersTab';
import HolidaysTab from './HolidaysTab';
import DocumentsTab from './DocumentsTab';
import { homeDashboardStyle } from '../style';

const TabCard = ({
  employeeList,
  teamMembersList,
  holidaysLoading,
  holidaysColumn,
  holidaysRow,
  holidaysSortDirection,
  holidaysSortBy,
  holidaysOnSortChange,
  documentsLoading,
  documentsColumn,
  documentsRow,
  documentsSortDirection,
  documentsSortBy,
  documentsOnSortChange,
  documentsCount,
  documentsPage,
  documentsOnPageChange,
  documentsHandleDownload,
  docFilterLabel,
  docFilterOptions,
  handleDocSelect,
}) => {
  const initialTabs = [
    {
      icon: <CakeIcon />,
      iconPosition: 'start',
      label: 'Birthdays',
      RenderComponent: BirthdaysTab,
      componentProps: {
        employeeList,
      },
    },
    {
      icon: <CelebrationIcon />,
      iconPosition: 'start',
      label: 'Anniversaries',
      RenderComponent: AnniversariesTab,
      componentProps: {
        employeeList,
      },
    },
    {
      icon: <PeopleIcon />,
      iconPosition: 'start',
      label: 'Team Members',
      RenderComponent: TeamMembersTab,
      componentProps: {
        teamMembersList,
      },
    },
    {
      icon: <BeachAccessIcon />,
      iconPosition: 'start',
      label: 'Holidays',
      RenderComponent: HolidaysTab,
      componentProps: {
        loading: holidaysLoading,
        holidaysColumn,
        holidaysRow,
        sortDirection: holidaysSortDirection,
        sortBy: holidaysSortBy,
        onSortChange: holidaysOnSortChange,
      },
    },

    {
      icon: <AssignmentIcon />,
      iconPosition: 'start',
      label: 'Documents',
      RenderComponent: DocumentsTab,
      componentProps: {
        loading: documentsLoading,
        documentsColumn,
        documentsRow,
        sortDirection: documentsSortDirection,
        sortBy: documentsSortBy,
        onSortChange: documentsOnSortChange,
        count: documentsCount,
        page: documentsPage,
        onPageChange: documentsOnPageChange,
        handleDownload: documentsHandleDownload,
        docFilterLabel,
        docFilterOptions,
        handleDocSelect,
      },
    },
  ];

  return (
    <Box sx={homeDashboardStyle.tabCardWrapper}>
      <MuiTab
        sx={{
          '.MuiTabs-vertical': {
            '& .MuiTabs-flexContainerVertical': {
              minWidth: '22rem !important',
              maxWidth: '25rem !important',
            },
          },
        }}
        orientation="vertical"
        textColor="primary"
        indicatorColor="primary"
        isVertical
        panelPadding="2.5rem 3rem"
        tabs={initialTabs}
      />
    </Box>
  );
};

TabCard.defaultProps = {
  employeeList: [],
  teamMembersList: [],
  holidaysLoading: false,
  holidaysColumn: [],
  holidaysRow: [],
  holidaysSortDirection: 'asc',
  holidaysSortBy: 'id',
  holidaysOnSortChange: () => {},
  documentsLoading: false,
  documentsColumn: [],
  documentsRow: [],
  documentsSortDirection: 'asc',
  documentsSortBy: 'id',
  documentsOnSortChange: () => {},
  documentsCount: 7,
  documentsPage: 0,
  documentsOnPageChange: () => {},
  documentsHandleDownload: () => {},
  docFilterLabel: '',
  docFilterOptions: [],
  handleDocSelect: () => {},
};

TabCard.propTypes = {
  employeeList: PropTypes.arrayOf(
    PropTypes.shape({
      profileImg: PropTypes.string,
      profileName: PropTypes.string,
      emailId: PropTypes.string,
      contactNumber: PropTypes.number,
      event: PropTypes.string,
    }),
  ),
  teamMembersList: PropTypes.arrayOf(
    PropTypes.shape({
      profileImg: PropTypes.string,
      profileName: PropTypes.string,
      emailId: PropTypes.string,
      contactNumber: PropTypes.number,
      event: PropTypes.string,
    }),
  ),
  holidaysLoading: PropTypes.bool,
  holidaysColumn: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),
  holidaysRow: PropTypes.arrayOf(
    PropTypes.shape({
      slNo: PropTypes.number,
      date: PropTypes.date,
      occasion: PropTypes.string,
    }),
  ),
  holidaysSortDirection: PropTypes.string,
  holidaysSortBy: PropTypes.string,
  holidaysOnSortChange: PropTypes.func,
  documentsLoading: PropTypes.bool,
  documentsColumn: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),
  documentsRow: PropTypes.arrayOf(
    PropTypes.shape({
      slNo: PropTypes.number,
      documentName: PropTypes.string,
      section: PropTypes.string,
    }),
  ),
  documentsSortDirection: PropTypes.string,
  documentsSortBy: PropTypes.string,
  documentsOnSortChange: PropTypes.func,
  documentsCount: PropTypes.number,
  documentsPage: PropTypes.number,
  documentsOnPageChange: PropTypes.func,
  documentsHandleDownload: PropTypes.func,
  docFilterLabel: PropTypes.string,
  docFilterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  handleDocSelect: PropTypes.func,
};

export default TabCard;
