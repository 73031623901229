import React from 'react';
import PropTypes from 'prop-types';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import Typographys from 'components/Atoms/Typography';
import { timelineStyle } from './style';

const VerticalTimeline = ({ isIcon, icons, dotColor, timelineData }) => {
  return (
    <Timeline sx={timelineStyle.wrapper}>
      {timelineData &&
        timelineData.map((list) => (
          <TimelineItem key={list.title}>
            <TimelineSeparator>
              {isIcon && <TimelineDot color={dotColor}>{icons[list.iconKey] ? icons[list.iconKey] : null}</TimelineDot>}
              <TimelineConnector />
            </TimelineSeparator>

            <TimelineContent>
              <Box display="grid" flexDirection="column" rowGap={1}>
                <Typographys variant="h6">{list.title}</Typographys>
                <Typographys variant="body2">{dayjs(list.desc).format('DD MMMM, YYYY h:mm A')}</Typographys>
              </Box>
            </TimelineContent>
          </TimelineItem>
        ))}
    </Timeline>
  );
};

VerticalTimeline.defaultProps = {
  dotColor: 'secondary',
  isIcon: true,
  icons: {},
  timelineData: [],
};

VerticalTimeline.propTypes = {
  isIcon: PropTypes.bool,
  icons: PropTypes.objectOf(PropTypes.node),
  dotColor: PropTypes.string,
  timelineData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      desc: PropTypes.string,
      iconKey: PropTypes.string,
    }),
  ),
};

export default VerticalTimeline;
