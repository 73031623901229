import { useRef, useState } from 'react';

export const usePopover = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = (event) => {
    event?.preventDefault?.();
    event?.stopPropagation?.();
    setOpen(true);
  };

  const handleClose = (event) => {
    event?.preventDefault?.();
    event?.stopPropagation?.();
    setOpen(false);
  };

  return [anchorRef, open, handleOpen, handleClose];
};
