import React, { useState } from 'react';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import Button from 'components/Atoms/Button';
import InputField from 'components/Atoms/InputField';
import Typographys from 'components/Atoms/Typography';
import { CircularProgress } from '@mui/material';
import { loginStyle } from './style';

const LoginComponent = ({ formHeading, handleOnSubmit, forgotPasswordLink }) => {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: '',
      password: '',
      submit: null,
    },
    onSubmit: async (values, helpers) => {
      try {
        setLoading(true);
        const { username, password } = values;
        await handleOnSubmit(username, password);
        helpers.resetForm();
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <Box sx={loginStyle.wrapper}>
      <Typographys variant="h2" align="center" color="neutral.700">
        {formHeading}
      </Typographys>

      <Box mt={{ xs: 5.5, md: 9 }} component="form" onSubmit={formik.handleSubmit}>
        <InputField
          error={Boolean(formik.touched.username && formik.errors.username)}
          helperText={formik.touched.username && formik.errors.username}
          name="username"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.username}
          required
          fullWidth
          id="username"
          placeholder="Email ID"
          label="Email ID"
          InputLabelProps={{ shrink: true }}
          autoFocus
        />

        <Box mt={4.5}>
          <InputField
            error={Boolean(formik.touched.password && formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.password}
            required
            fullWidth
            name="password"
            placeholder="Password"
            label="Password"
            InputLabelProps={{ shrink: true }}
            type="password"
            id="password"
          />
        </Box>

        <Box mt={1.5} mb={4.5} align="right">
          <Link
            href={forgotPasswordLink}
            variant="subtitle2"
            sx={{ fontWeight: 500 }}
            color="secondary.600"
            underline="hover"
          >
            Forgot password?
          </Link>
        </Box>

        <Button type="submit" fullWidth size="ExtraLarge">
          {loading ? <CircularProgress size="3rem" /> : 'Login'}
        </Button>
      </Box>
    </Box>
  );
};

LoginComponent.defaultProps = {
  formHeading: 'Hello again',
  handleOnSubmit: () => {},
  forgotPasswordLink: '',
};

LoginComponent.propTypes = {
  formHeading: PropTypes.string,
  handleOnSubmit: PropTypes.func,
  forgotPasswordLink: PropTypes.string,
};

export default LoginComponent;
