import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { commonChartStyle } from '../style';

const TenureWiseGraph = ({ tenureWiseData }) => {
  const theme = useTheme();
  return (
    <Box sx={commonChartStyle.chartWrapper}>
      <ResponsiveContainer width="100%" height={250}>
        <BarChart
          width={1000}
          height={250}
          data={tenureWiseData}
          maxBarSize={32}
          margin={{
            top: 40,
            right: 30,
            left: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey="name"
            axisLine={{ stroke: theme.palette.other[200] }}
            tick={{ fill: theme.palette.neutral[600] }}
            tickLine={{ stroke: theme.palette.common.white }}
            style={commonChartStyle.axisLabel}
          />
          <YAxis
            axisLine={false}
            tick={{ fill: theme.palette.neutral[600] }}
            tickLine={{ stroke: theme.palette.common.white }}
            style={commonChartStyle.axisLabel}
            label={{ value: 'People', position: 'top', offset: 20, fill: theme.palette.neutral[800] }}
          />
          <Tooltip />

          <Bar name="Grade 4" dataKey="tenure" stackId="grade" fill={theme.palette.secondary[600]} />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

TenureWiseGraph.defaultProps = {
  tenureWiseData: [],
};
TenureWiseGraph.propTypes = {
  tenureWiseData: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      tenure: PropTypes.number,
    }),
  ),
};

export default TenureWiseGraph;
