export const rows = [
  {
    category: 'Basic',
    'April 2020': 180000,
    'May 2020': 56000,
    'June 2020': 30000,
    'July 2020': 1000,
    'August 2020': 3000,
    'September 2020': 99000,
    'October 2020': 73000,
    'November 2020': 50000,
    'December 2020': 3000,
    'January 2021': 89000,
    'February 2021': 82000,
    'March 2021': 42000,
  },
  {
    category: 'Bonus',
    'April 2020': 180000,
    'May 2020': 56000,
    'June 2020': 30000,
    'July 2020': 1000,
    'August 2020': 3000,
    'September 2020': 99000,
    'October 2020': 73000,
    'November 2020': 50000,
    'December 2020': 3000,
    'January 2021': 89000,
    'February 2021': 82000,
    'March 2021': 42000,
  },
  {
    category: `Children's Education Allowance`,
    'April 2020': 180000,
    'May 2020': 56000,
    'June 2020': 30000,
    'July 2020': 1000,
    'August 2020': 3000,
    'September 2020': 99000,
    'October 2020': 73000,
    'November 2020': 50000,
    'December 2020': 3000,
    'January 2021': 89000,
    'February 2021': 82000,
    'March 2021': 42000,
  },
  {
    category: `Children's Education Allowance`,
    'April 2020': 180000,
    'May 2020': 56000,
    'June 2020': 30000,
    'July 2020': 1000,
    'August 2020': 3000,
    'September 2020': 99000,
    'October 2020': 73000,
    'November 2020': 50000,
    'December 2020': 3000,
    'January 2021': 89000,
    'February 2021': 82000,
    'March 2021': 42000,
  },
  {
    category: `Conveyance Allowance`,
    'April 2020': 180000,
    'May 2020': 56000,
    'June 2020': 30000,
    'July 2020': 1000,
    'August 2020': 3000,
    'September 2020': 99000,
    'October 2020': 73000,
    'November 2020': 50000,
    'December 2020': 3000,
    'January 2021': 89000,
    'February 2021': 82000,
    'March 2021': 42000,
  },
  {
    category: 'Dearness Allowance',
    'April 2020': 180000,
    'May 2020': 56000,
    'June 2020': 30000,
    'July 2020': 1000,
    'August 2020': 3000,
    'September 2020': 99000,
    'October 2020': 73000,
    'November 2020': 50000,
    'December 2020': 3000,
    'January 2021': 89000,
    'February 2021': 82000,
    'March 2021': 42000,
  },
  {
    category: 'Flat Allowance',
    'April 2020': 180000,
    'May 2020': 56000,
    'June 2020': 30000,
    'July 2020': 1000,
    'August 2020': 3000,
    'September 2020': 99000,
    'October 2020': 73000,
    'November 2020': 50000,
    'December 2020': 3000,
    'January 2021': 89000,
    'February 2021': 82000,
    'March 2021': 42000,
  },
  {
    category: 'Flexible Allowance',
    'April 2020': 180000,
    'May 2020': 56000,
    'June 2020': 30000,
    'July 2020': 1000,
    'August 2020': 3000,
    'September 2020': 99000,
    'October 2020': 73000,
    'November 2020': 50000,
    'December 2020': 3000,
    'January 2021': 89000,
    'February 2021': 82000,
    'March 2021': 42000,
  },
  {
    category: 'House Rent Allowance',
    'April 2020': 180000,
    'May 2020': 56000,
    'June 2020': 30000,
    'July 2020': 1000,
    'August 2020': 3000,
    'September 2020': 99000,
    'October 2020': 73000,
    'November 2020': 50000,
    'December 2020': 3000,
    'January 2021': 89000,
    'February 2021': 82000,
    'March 2021': 42000,
  },
  {
    category: 'Leave Travel Allowance*',
    'April 2020': 180000,
    'May 2020': 56000,
    'June 2020': 30000,
    'July 2020': 1000,
    'August 2020': 3000,
    'September 2020': 99000,
    'October 2020': 73000,
    'November 2020': 50000,
    'December 2020': 3000,
    'January 2021': 89000,
    'February 2021': 82000,
    'March 2021': 42000,
  },
  {
    category: 'Lunch Allowance',
    'April 2020': 180000,
    'May 2020': 56000,
    'June 2020': 30000,
    'July 2020': 1000,
    'August 2020': 3000,
    'September 2020': 99000,
    'October 2020': 73000,
    'November 2020': 50000,
    'December 2020': 3000,
    'January 2021': 89000,
    'February 2021': 82000,
    'March 2021': 42000,
  },
  {
    category: 'Medical Allowance',
    'April 2020': 180000,
    'May 2020': 56000,
    'June 2020': 30000,
    'July 2020': 1000,
    'August 2020': 3000,
    'September 2020': 99000,
    'October 2020': 73000,
    'November 2020': 50000,
    'December 2020': 3000,
    'January 2021': 89000,
    'February 2021': 82000,
    'March 2021': 42000,
  },
  {
    category: 'Other Allowance',
    'April 2020': 180000,
    'May 2020': 56000,
    'June 2020': 30000,
    'July 2020': 1000,
    'August 2020': 3000,
    'September 2020': 99000,
    'October 2020': 73000,
    'November 2020': 50000,
    'December 2020': 3000,
    'January 2021': 89000,
    'February 2021': 82000,
    'March 2021': 42000,
  },
  {
    category: 'Special Allowance',
    'April 2020': 180000,
    'May 2020': 56000,
    'June 2020': 30000,
    'July 2020': 1000,
    'August 2020': 3000,
    'September 2020': 99000,
    'October 2020': 73000,
    'November 2020': 50000,
    'December 2020': 3000,
    'January 2021': 89000,
    'February 2021': 82000,
    'March 2021': 42000,
  },
  {
    category: 'Telephone/Internet Allowance',
    'April 2020': 180000,
    'May 2020': 56000,
    'June 2020': 30000,
    'July 2020': 1000,
    'August 2020': 3000,
    'September 2020': 99000,
    'October 2020': 73000,
    'November 2020': 50000,
    'December 2020': 3000,
    'January 2021': 89000,
    'February 2021': 82000,
    'March 2021': 42000,
  },
  {
    category: 'Total Earnings',
    'April 2020': 180000,
    'May 2020': 56000,
    'June 2020': 30000,
    'July 2020': 1000,
    'August 2020': 3000,
    'September 2020': 99000,
    'October 2020': 73000,
    'November 2020': 50000,
    'December 2020': 3000,
    'January 2021': 89000,
    'February 2021': 82000,
    'March 2021': 42000,
  },
  {
    category: 'Loss Of Pay (days)',
    'April 2020': 180000,
    'May 2020': 56000,
    'June 2020': 30000,
    'July 2020': 1000,
    'August 2020': 3000,
    'September 2020': 99000,
    'October 2020': 73000,
    'November 2020': 50000,
    'December 2020': 3000,
    'January 2021': 89000,
    'February 2021': 82000,
    'March 2021': 42000,
  },
  {
    category: 'LWF - Employee Contribution',
    'April 2020': 180000,
    'May 2020': 56000,
    'June 2020': 30000,
    'July 2020': 1000,
    'August 2020': 3000,
    'September 2020': 99000,
    'October 2020': 73000,
    'November 2020': 50000,
    'December 2020': 3000,
    'January 2021': 89000,
    'February 2021': 82000,
    'March 2021': 42000,
  },
  {
    category: 'NPS Deduction',
    'April 2020': 180000,
    'May 2020': 56000,
    'June 2020': 30000,
    'July 2020': 1000,
    'August 2020': 3000,
    'September 2020': 99000,
    'October 2020': 73000,
    'November 2020': 50000,
    'December 2020': 3000,
    'January 2021': 89000,
    'February 2021': 82000,
    'March 2021': 42000,
  },
  {
    category: 'PF Contribution (Employee)',
    'April 2020': 180000,
    'May 2020': 56000,
    'June 2020': 30000,
    'July 2020': 1000,
    'August 2020': 3000,
    'September 2020': 99000,
    'October 2020': 73000,
    'November 2020': 50000,
    'December 2020': 3000,
    'January 2021': 89000,
    'February 2021': 82000,
    'March 2021': 42000,
  },
  {
    category: 'Professional Tax',
    'April 2020': 180000,
    'May 2020': 56000,
    'June 2020': 30000,
    'July 2020': 1000,
    'August 2020': 3000,
    'September 2020': 99000,
    'October 2020': 73000,
    'November 2020': 50000,
    'December 2020': 3000,
    'January 2021': 89000,
    'February 2021': 82000,
    'March 2021': 42000,
  },
  {
    category: 'Income Tax',
    'April 2020': 180000,
    'May 2020': 56000,
    'June 2020': 30000,
    'July 2020': 1000,
    'August 2020': 3000,
    'September 2020': 99000,
    'October 2020': 73000,
    'November 2020': 50000,
    'December 2020': 3000,
    'January 2021': 89000,
    'February 2021': 82000,
    'March 2021': 42000,
  },
  {
    category: 'Total Deductions',
    'April 2020': 180000,
    'May 2020': 56000,
    'June 2020': 30000,
    'July 2020': 1000,
    'August 2020': 3000,
    'September 2020': 99000,
    'October 2020': 73000,
    'November 2020': 50000,
    'December 2020': 3000,
    'January 2021': 89000,
    'February 2021': 82000,
    'March 2021': 42000,
  },
  {
    category: 'Typography (body1)',
    'April 2020': 180000,
    'May 2020': 56000,
    'June 2020': 30000,
    'July 2020': 1000,
    'August 2020': 3000,
    'September 2020': 99000,
    'October 2020': 73000,
    'November 2020': 50000,
    'December 2020': 3000,
    'January 2021': 89000,
    'February 2021': 82000,
    'March 2021': 42000,
  },
];
