import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import EditProfileDependents from 'components/Organisms/Profile/Dependents/Edit';
import useToast from 'components/Provider/useToast';
import { getToastMessage } from 'utils/toastMessage';

const EditProfileDependentTemplate = ({
  handleSubmit,
  handleCancel,
  genderOptions,
  relationOptions,
  defaultValue,
  referenceOptions,
  toastSuccessMessage,
  toastErrorMessage,
  showToast,
  toastType,
  loading,
}) => {
  const breadcrumbData = [
    { id: 1, name: 'Dependents', path: '/profile/dependents', isActive: false },
    { id: 2, name: 'Edit', isActive: true },
  ];

  const addToast = useToast();

  useEffect(() => {
    if (showToast) {
      const successMessage = toastSuccessMessage || getToastMessage.ProfileDependants()[0];
      const error = toastErrorMessage || getToastMessage.ProfileDependants()[1];

      const title = toastType === 'success' ? 'Success!' : 'Error!';
      const type = toastType === 'success' ? 'success' : 'error';
      const message = toastType === 'success' ? successMessage : error;
      addToast({ type, title, message });
    }
  }, [showToast, toastErrorMessage, toastSuccessMessage, toastType]);
  return (
    <BlankTemplate pageHeading="Edit Dependents" isBreadcrumb breadcrumbData={breadcrumbData} padding="2rem 3rem">
      <EditProfileDependents
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        genderOptions={genderOptions}
        relationOptions={relationOptions}
        referenceOptions={referenceOptions}
        defaultValue={defaultValue}
        loading={loading}
      />
    </BlankTemplate>
  );
};

EditProfileDependentTemplate.defaultProps = {
  handleSubmit: () => {},
  handleCancel: () => {},
  genderOptions: [],
  relationOptions: [],
  referenceOptions: [],
  defaultValue: {},
  toastSuccessMessage: '',
  toastErrorMessage: '',
  showToast: false,
  toastType: 'success',
  loading: false,
};

EditProfileDependentTemplate.propTypes = {
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,

  genderOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  relationOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  referenceOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  defaultValue: PropTypes.shape({}),
  toastSuccessMessage: PropTypes.string,
  toastErrorMessage: PropTypes.string,
  showToast: PropTypes.bool,
  toastType: PropTypes.oneOf(['error', 'success']),
  loading: PropTypes.bool,
};

export default EditProfileDependentTemplate;
