import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import MuiTab from 'components/Molecules/Tab';
import UserIdentity from 'components/Organisms/Employee/AddEmployee/UserIdentity';
import UserRole from 'components/Organisms/Employee/AddEmployee/UserRoles';
import BasicProfile from 'components/Organisms/Employee/AddEmployee/BasicProfile';
import ExtendedAttributes from 'components/Organisms/Employee/AddEmployee/ExtendedAttributes';
import NotificationSetting from 'components/Organisms/Employee/AddEmployee/NotificationSetting';
import AdditionalDetails from 'components/Organisms/Employee/AddEmployee/AdditionalDetails';
import ContactDetails from 'components/Organisms/Employee/AddEmployee/ContactDetails';
import BankAccount from 'components/Organisms/Employee/AddEmployee/BankAccount';
import PayrollSetting from 'components/Organisms/Employee/AddEmployee/PayrollSetting';
import getHandleSubmit from 'utils/HandleTab';

const AddEmployee = ({
  onSubmit,
  allowEdit,
  employeeData,
  dropdownOptions,
  customAttributesFormik,
  metaCustomFields,
  roleMap,
  handleCancel,
}) => {
  const [allTabsData, setAllTabsData] = useState({
    'User Identity': allowEdit
      ? employeeData['User Identity']
      : {
          employeeId: dropdownOptions?.employeeId,
          employeeIdEnable: dropdownOptions?.employeeIdEnable,
        },
    'User Roles': allowEdit ? employeeData['User Roles'] : {},
    'Basic Profile': allowEdit ? employeeData['Basic Profile'] : {},
    'Additional Details': allowEdit
      ? employeeData['Additional Details']
      : {
          OnBoarding: {},
          'Social Data': {},
          'Education/Certification History': { education: [{}] },
          'Employment History': { employment: [{}] },
          'Visa Details': {},
          'Skill Attainment Details': { skillDetails: [{}] },
          'Key Life Event Details': { keylifeEvent: [{}] },
          'Dependents and Nomination Details': { dependent: [{}] },
        },
    'Contact Details': allowEdit ? employeeData['Contact Details'] : {},
    'Bank Account': allowEdit ? employeeData['Bank Account'] : { bank: [{}] },
    'Payroll Setting': allowEdit ? employeeData['Payroll Setting'] : {},
    'Notification Setting': allowEdit ? employeeData['Notification Setting'] : {},
    'Extended Attributes': allowEdit ? employeeData['Extended Attributes'] : {},
  });

  const [initialTabs, setTabs] = useState([
    {
      label: 'User Identity',
      onChange: () => {},
      isDisabled: 0,
      RenderComponent: UserIdentity,
      componentProps: { groupOptions: dropdownOptions.userRoleGroupOptions, handleCancel },
    },
    {
      label: 'User Roles',
      onChange: () => {},
      isDisabled: !allowEdit,
      RenderComponent: UserRole,
      componentProps: { userRoleOptions: dropdownOptions.userRoleOptions, handleCancel },
    },
    {
      label: 'Basic Profile',
      onChange: () => {},
      isDisabled: !allowEdit,
      RenderComponent: BasicProfile,
      componentProps: {
        genderOptions: dropdownOptions.basicProfileGenderOptions,
        maritalOptions: dropdownOptions.basicProfileMaritalOptions,
        empTypeOptions: dropdownOptions.basicProfileEmpTypeOptions,
        empStatusOptions: dropdownOptions.basicProfileEmpStatusOptions,
        supervisorOptions: dropdownOptions.basicProfileSupervisorOptions,
        locationOptions: dropdownOptions.basicProfileLocationOptions,
        designationOptions: dropdownOptions.basicProfileDesignationOptions,
        departmentOptions: dropdownOptions.basicProfileDepartmentOptions,
        groupOptions: dropdownOptions.basicProfileGroupOptions,
        handleCancel,
      },
    },
    {
      label: 'Additional Details',
      onChange: () => {},
      isDisabled: !allowEdit,
      RenderComponent: AdditionalDetails,
      componentProps: { dropdownOptions: dropdownOptions.additionalDetailOptions, handleCancel },
    },

    {
      label: 'Contact Details',
      onChange: () => {},
      isDisabled: !allowEdit,
      RenderComponent: ContactDetails,
      componentProps: { handleCancel },
    },
    {
      label: 'Bank Account',
      onChange: () => {},
      isDisabled: !allowEdit,
      RenderComponent: BankAccount,
      componentProps: {
        transactionTypeOptions: dropdownOptions.bankTansactionTypeOptions,
        accountTypeOptions: dropdownOptions.bankAccountTypeOptions,
        accountCurrecyOptions: dropdownOptions.bankAccountCurrecyOptions,
        purposeOptions: dropdownOptions.bankPurposeOptions,
        handleCancel,
      },
    },
    {
      label: 'Payroll Setting',
      onChange: () => {},
      isDisabled: !allowEdit,
      RenderComponent: PayrollSetting,
      componentProps: {
        taxJurisdictionOptions: dropdownOptions.payrollTaxJurisdictionOptions,
        welfareJurisdictionOptions: dropdownOptions.payrollWelfareJurisdictionOptions,
        epfContributionOptions: dropdownOptions.payrollEpfContributionOptions,
        employeeContributionOptions: dropdownOptions.payrollEmployeeContributionOptions,
        panValidatedByOptions: dropdownOptions.payrollPanValidatedByOptions,
        salaryAccountOptions: dropdownOptions.payrollSalaryAccountOptions,
        reimbursementAccOptions: dropdownOptions.payrollReimbursementAccOptions,
        handleCancel,
      },
    },
    {
      label: 'Notification Setting',
      onChange: () => {},
      isDisabled: !allowEdit,
      RenderComponent: NotificationSetting,
      componentProps: { handleCancel },
    },
    {
      label: 'Extended Attributes',
      onChange: () => {},
      isDisabled: !allowEdit,
      RenderComponent: ExtendedAttributes,
      componentProps: {
        customAttributesFormik,
        metaCustomFields,
        handleCancel,
      },
    },
  ]);
  function updateFormData(data, label) {
    if (allowEdit) {
      const finalData = {
        ...allTabsData,
        [label]: data,
      };
      setAllTabsData(finalData);
    }
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <MuiTab
        key={initialTabs}
        orientation="horizontal"
        variant="scrollable"
        scrollButtons
        textColor="primary"
        indicatorColor="primary"
        tabs={initialTabs}
        handleSubmit={(label, data, index) => {
          getHandleSubmit(label)(data, { setTabs, setAllTabsData, selectedIndex: index });
          if (index === initialTabs.length - 1 || allowEdit) {
            const finalData = {
              ...allTabsData,
              [label]: data,
            };
            onSubmit(finalData);
          }
        }}
        propData={{ allTabsData, allowEdit, updateFormData, customAttributesFormik, metaCustomFields, roleMap }}
        disableChangingTabs={allowEdit}
      />
    </Box>
  );
};

AddEmployee.defaultProps = {
  onSubmit: () => {},
  allowEdit: false,
  employeeData: {},
  roleMap: {},
  dropdownOptions: {
    userRoleGroupOptions: [],
    basicProfileEmpTypeOptions: [],
    basicProfileEmpStatusOptions: [],
    basicProfileGroupOptions: [],
    basicProfileSupervisorOptions: [],
    basicProfileLocationOptions: [],
    basicProfileDepartmentOptions: [],
    basicProfileDesignationOptions: [],
    basicProfileMaritalOptions: [],
    basicProfileGenderOptions: [],
    additionalDetailOptions: {
      noticeUnitOptions: [],
      bloodOptions: [],
      onboadringRefOptions: [],
      onboardingSeperationOptions: [],
      skillOptions: [],
      skillLevelOptions: [],
      lifeEventOptions: [],
      genderOptions: [],
      relationOptions: [],
    },
    bankTansactionTypeOptions: [],
    bankAccountTypeOptions: [],
    bankAccountCurrecyOptions: [],
    bankPurposeOptions: [],
    payrollTaxJurisdictionOptions: [],
    payrollWelfareJurisdictionOptions: [],
    payrollEpfContributionOptions: [],
    payrollEmployeeContributionOptions: [],
    payrollPanValidatedByOptions: [],
    payrollSalaryAccountOptions: [],
    payrollReimbursementAccOptions: [],
    extendedLegalEntityOptions: [],
    extendedCategoryOptions: [],
    extendedDivisionOptions: [],
    employeeId: '',
    employeeIdEnable: false,
  },
};

AddEmployee.propTypes = {
  onSubmit: PropTypes.func,
  allowEdit: PropTypes.bool,
  roleMap: PropTypes.shape({}),
  employeeData: PropTypes.shape({
    'User Identity': PropTypes.shape({}),
    'User Roles': PropTypes.shape({}),
    'Basic Profile': PropTypes.shape({}),
    'Additional Details': PropTypes.shape({}),
    'Contact Details': PropTypes.shape({}),
    'Bank Account': PropTypes.shape({}),
    'Payroll Setting': PropTypes.shape({}),
    'Notification Setting': PropTypes.shape({}),
    'Extended Attributes': PropTypes.shape({}),
  }),
  dropdownOptions: PropTypes.shape({
    extendedDivisionOptions: PropTypes.shape([]),
    extendedCategoryOptions: PropTypes.shape([]),
    extendedLegalEntityOptions: PropTypes.shape([]),
    payrollReimbursementAccOptions: PropTypes.shape([]),
    payrollSalaryAccountOptions: PropTypes.shape([]),
    payrollPanValidatedByOptions: PropTypes.shape([]),
    payrollEmployeeContributionOptions: PropTypes.shape([]),
    payrollEpfContributionOptions: PropTypes.shape([]),
    payrollWelfareJurisdictionOptions: PropTypes.shape([]),
    payrollTaxJurisdictionOptions: PropTypes.shape([]),
    bankTansactionTypeOptions: PropTypes.shape([]),
    bankAccountTypeOptions: PropTypes.shape([]),
    bankAccountCurrecyOptions: PropTypes.shape([]),
    bankPurposeOptions: PropTypes.shape([]),
    userRoleGroupOptions: PropTypes.shape([]),
    basicProfileEmpTypeOptions: PropTypes.shape([]),
    basicProfileEmpStatusOptions: PropTypes.shape([]),
    basicProfileGroupOptions: PropTypes.shape([]),
    basicProfileSupervisorOptions: PropTypes.shape([]),
    basicProfileLocationOptions: PropTypes.shape([]),
    basicProfileDepartmentOptions: PropTypes.shape([]),
    basicProfileDesignationOptions: PropTypes.shape([]),
    basicProfileMaritalOptions: PropTypes.shape([]),
    basicProfileGenderOptions: PropTypes.shape([]),
    additionalDetailOptions: PropTypes.shape({}),
    employeeId: PropTypes.string,
    employeeIdEnable: PropTypes.bool,
  }),
};

export default AddEmployee;
