import dayjs from 'dayjs';

const filterByDuration = (rows, daysInduration) => {
  const durationInTimeStamp = dayjs(
    Object.values(daysInduration).reduce((a, b) => {
      return a > b ? a : b;
    }),
  ).unix();

  const filteredArray = rows.filter((row) => {
    return dayjs(row.day).unix() <= durationInTimeStamp;
  });
  return filteredArray;
};

const filterByDurationCustomDate = (rows, startDate, endDate) => {
  const filteredArray = rows.filter((row) => {
    return dayjs(startDate).unix() <= dayjs(row.day).unix() <= dayjs(endDate).unix;
  });
  return filteredArray;
};

const getMyAttendanceRows = (dayAttendanceArray, selectedDuration, customStartEndDate) => {
  const rows =
    dayAttendanceArray?.data?.doyAttRec &&
    Object.entries(dayAttendanceArray?.data?.doyAttRec).map(([recordId, record]) => ({
      id: recordId,
      day: dayjs(record?.attendanceForDate).format('ddd, D MMM YYYY') || '-',
      timeIn: dayjs(record?.firstInTime).format('h:mm a') || '-',
      timeOut: dayjs(record?.lastOutTime).format('h:mm a') || '-',
      duration: dayAttendanceArray?.data?.doyDurationMap?.[`${recordId}`] || '-',
      interimPunches: '-', // currently we dont have this in api, can be changed when api is fixed
      attendance: dayAttendanceArray?.data?.doyAttendanceStateMap?.[`${recordId}`] || '-',
      exception:
        dayAttendanceArray?.data?.doyExceptionMap?.[`${recordId}`] !== null
          ? dayAttendanceArray?.data?.doyExceptionMap?.[`${recordId}`]
          : 'No Records',
      attendanceMarking: record?.attendanceMarkingProcess || '-',
    }));

  if (selectedDuration === 'This Week' && rows !== undefined) {
    return filterByDuration(rows, dayAttendanceArray?.data?.thisWeekDoyDatesMap);
  }

  if (selectedDuration === 'This Month' && rows !== undefined) {
    return filterByDuration(rows, dayAttendanceArray?.data?.thisMonthDoyDatesMap);
  }

  if (selectedDuration === 'This Year' && rows !== undefined) {
    return filterByDuration(rows, dayAttendanceArray?.data?.ytdDoyDatesMap);
  }

  if (selectedDuration === 'Custom date' && rows !== undefined) {
    const startDateTimeStamp = dayjs(customStartEndDate.startDate).format('ddd, D MMM YYYY');
    const endDateTimeStamp = dayjs(customStartEndDate.endDate).format('ddd, D MMM YYYY');
    return filterByDurationCustomDate(rows, startDateTimeStamp, endDateTimeStamp);
  }

  return rows;
};

const getTimeLineData = (dayAttendanceArray) => {
  const pauseTimeMapping =
    (dayAttendanceArray?.pausedIntervals &&
      Object.keys(dayAttendanceArray?.pausedIntervals)
        .sort()
        .map((pausedTime, index) => ({
          title: `Pause ${index + 1}`,
          desc: pausedTime,
          iconKey: 'pause',
        }))) ||
    [];
  const restartTimeMapping =
    (dayAttendanceArray?.runningIntervals &&
      Object.keys(dayAttendanceArray?.runningIntervals).map((restartTime) => ({
        title: 'Restart Time',
        desc: restartTime,
        iconKey: 'restart',
      }))) ||
    [];

  const sortedTimeLine = [...pauseTimeMapping, ...restartTimeMapping].sort(
    (timer1, timer2) => dayjs(timer1.desc).unix() - dayjs(timer2.desc).unix(),
  );

  return sortedTimeLine;
};

const getWeeklyDaysData = (dayAttendanceArray) => {
  const weeklyDaysData =
    dayAttendanceArray?.data?.doyAttRec &&
    Object.entries(dayAttendanceArray?.data?.doyAttRec).map(([recordId, record]) => ({
      day: dayjs(dayAttendanceArray?.data?.doyDatesMap?.[`${recordId}`]).format('dddd'),
      duration: {
        hour: (record?.totalRunningTime !== 0 && dayjs(record?.totalRunningTime).format('H')) || 0,
        min: (record?.totalRunningTime !== 0 && dayjs(record?.totalRunningTime).format('m')) || 0,
      },
      break: {
        hour: (record?.totalRunningTime !== 0 && dayjs(record?.totalPausedTime).format('H')) || 0,
        min: (record?.totalRunningTime !== 0 && dayjs(record?.totalPausedTime).format('m')) || 0,
      },
      overtime: {
        hour: (record?.totalRunningTime !== 0 && dayjs(record?.totalOTTime).format('H')) || 0,
        min: (record?.totalRunningTime !== 0 && dayjs(record?.totalOTTime).format('m')) || 0,
      },
    }));

  return { ...weeklyDaysData };
};

const getConfiguredAttendanceData = (attendanceRecords, duration, customStartEndDate) => {
  const attendanceRecordProps = {
    myAttendanceRows: getMyAttendanceRows(attendanceRecords, duration, customStartEndDate),
    timelineData: [
      { title: 'In time', desc: attendanceRecords?.data?.timer?.initTime, iconKey: 'intime' },
      ...getTimeLineData(attendanceRecords?.data?.timer),
      {
        title: 'Close time',
        desc: attendanceRecords?.data?.timer?.closeTime,
        iconKey: 'close',
      },
    ],
    daysData: getWeeklyDaysData(attendanceRecords),
  };
  return attendanceRecordProps;
};

const getAvgAttendanceDetails = (attendanceRecords, selectedDuration) => {
  const avgDurations = { avgHours: '', arrivalTime: '', overtimeHrs: '' };
  const employeeId = attendanceRecords?.data?.timer?.employeeId;

  if (selectedDuration === 'This Week') {
    avgDurations.avgHours = attendanceRecords?.data?.thisWeekEmpIDDurationMap?.[`${employeeId}`];
    avgDurations.arrivalTime = dayjs(attendanceRecords?.data?.thisWeekEmpIDAvgInTimeMap?.[`${employeeId}`]).format(
      'HH:mm:ss',
    );
    avgDurations.overtimeHrs =
      attendanceRecords?.data?.thisWeekEmpIDDifferentialIsShortFallMap?.[`${employeeId}`] === false
        ? attendanceRecords?.data?.thisWeekEmpIDFullDayDifferentialMap?.[`${employeeId}`]
        : 0;
  }
  if (selectedDuration === 'This Month') {
    avgDurations.avgHours = attendanceRecords?.data?.thisMonthEmpIDDurationMap?.[`${employeeId}`];
    avgDurations.arrivalTime = dayjs(attendanceRecords?.data?.thisMonthEmpIDAvgInTimeMap?.[`${employeeId}`]).format(
      'HH:mm:ss',
    );
    avgDurations.overtimeHrs =
      attendanceRecords?.data?.thisMonthEmpIDDifferentialIsShortFallMap?.[`${employeeId}`] === false
        ? attendanceRecords?.data?.thisMonthEmpIDFullDayDifferentialMap?.[`${employeeId}`]
        : 0;
  }
  if (selectedDuration === 'This Year') {
    avgDurations.avgHours = attendanceRecords?.data?.ytdEmpIDDurationMap?.[`${employeeId}`];
    avgDurations.arrivalTime = dayjs(attendanceRecords?.data?.ytdEmpIDAvgInTimeMap?.[`${employeeId}`]).format(
      'HH:mm:ss',
    );
    avgDurations.overtimeHrs =
      attendanceRecords?.data?.ytdEmpIDDifferentialIsShortFallMap?.[`${employeeId}`] === false
        ? attendanceRecords?.data?.ytdEmpIDFullDayDifferentialMap?.[`${employeeId}`]
        : 0;
  }

  return avgDurations;
};

export { getConfiguredAttendanceData, getAvgAttendanceDetails };
