export const hasPermission = (userPermissions, requiredPermissions) => {
  return requiredPermissions?.every((requiredPermission) =>
    userPermissions?.some((userPermission) => userPermission === requiredPermission),
  );
};

export const hasAnyPermission = (userPermissions, requiredPermissions) => {
  return requiredPermissions?.some((requiredPermission) =>
    userPermissions?.some((userPermission) => userPermission === requiredPermission),
  );
};

// userPermissions?.some((userPermission) => userPermission.authority === requiredPermission
