export const menuStyles = {
  menu: {
    '& .MuiMenu-paper': {
      minWidth: '100%',
      maxWidth: '100%',
      borderColor: 'neutral.300',
      borderWidth: '0.125rem',
      borderStyle: 'solid',
      boxShadow: '0 3px 5px -1px rgba(9, 30, 66, 0.08)',
      borderRadius: '0.625rem',
      mt: '0.5rem',
      '& .MuiList-root': {
        pb: 0,
      },

      '& .MuiMenuItem-root': {
        fontWeight: 400,
        fontSize: '1.75rem',
        lineHeight: '160%',
        letterSpacing: '0.019rem',
        padding: '0.75rem 2rem',
        color: 'neutral.700',
      },
    },
  },
};
