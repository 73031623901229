import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import MuiTab from 'components/Molecules/Tab';
import Instructions from '../Instructions';
import Forms from '../Forms/Edit';
import Attachments from '../Attachments/Edit';

const OnboardingRecords = ({
  onboardingForm,
  attachmentEditData,
  handleDocumentAttachment,
  handleSubmit,
  handleCancel,
  handleFinalizeDownload,
  handleFinalizeAttachment,
  loading,
}) => {
  const initialTabs = [
    {
      label: 'Instructions',
      onChange: () => {},
      RenderComponent: Instructions,
      componentProps: { ...onboardingForm },
    },
    {
      label: 'Forms',
      onChange: () => {},
      RenderComponent: Forms,
      componentProps: {
        ...onboardingForm,
        onboardingForm,
        handleSubmitForm: handleSubmit,
        handleCancel,
        handleFinalizeDownload,
        handleFinalizeAttachment,
        loading,
      },
    },
    {
      label: 'Attachments/Documents',
      onChange: () => {},
      RenderComponent: Attachments,
      componentProps: { ...attachmentEditData, handleDocumentAttachment },
    },
  ];

  return (
    <Box>
      <MuiTab
        key={initialTabs}
        orientation="horizontal"
        variant="scrollable"
        scrollButtons={false}
        textColor="primary"
        indicatorColor="primary"
        tabs={initialTabs}
        allowScrollButtonsMobile
        panelPadding="2rem 0rem"
        disableChangingTabs
      />
    </Box>
  );
};

OnboardingRecords.defaultProps = {
  onboardingForm: {
    dropdownOptions: {},
    formData: {},
  },

  attachmentEditData: {
    attachmentEditDataColumns: [],
    attachmentEditDataRow: [],
  },

  handleDocumentAttachment: () => {},
  handleSubmit: () => {},
  handleCancel: () => {},
  handleFinalizeDownload: () => {},
  handleFinalizeAttachment: () => {},
  loading: false,
};

OnboardingRecords.propTypes = {
  loading: PropTypes.bool,
  onboardingForm: PropTypes.shape({
    dropdownOptions: PropTypes.shape({
      BasicProfile: PropTypes.shape({
        maritalStatusOptions: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            name: PropTypes.string,
          }),
        ),

        bloodGroupOptions: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            name: PropTypes.string,
          }),
        ),

        locationOptions: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            name: PropTypes.string,
          }),
        ),

        genderOptions: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            name: PropTypes.string,
          }),
        ),
      }),

      Dependent: PropTypes.shape({
        relationOptions: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            name: PropTypes.string,
          }),
        ),

        genderOptions: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            name: PropTypes.string,
          }),
        ),
      }),

      BankAccount: PropTypes.shape({
        transactionTypeOptions: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            name: PropTypes.string,
          }),
        ),

        accountTypeOptions: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            name: PropTypes.string,
          }),
        ),

        purposeOptions: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            name: PropTypes.string,
          }),
        ),

        accountCurrencyOptions: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            name: PropTypes.string,
          }),
        ),
      }),

      AdditionalDetail: PropTypes.shape({
        levelOptions: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            name: PropTypes.string,
          }),
        ),

        skillOptions: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            name: PropTypes.string,
          }),
        ),

        lifeEventOptions: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            name: PropTypes.string,
          }),
        ),
      }),
    }),

    formData: PropTypes.shape({
      'BASIC PROFILE': PropTypes.shape({
        gender: PropTypes.string,
        fatherName: PropTypes.string,
        motherName: PropTypes.string,
        maritalstatus: PropTypes.string,
        spouseName: PropTypes.string,
        dateOfBirth: PropTypes.string,
        dateOfJoining: PropTypes.string,
        location: PropTypes.string,
        bloodGroup: PropTypes.string,
        onboardingReference: PropTypes.string,
        uploadProfile: PropTypes.string,
      }),

      'STATUTORY IDENTIFICATION': PropTypes.shape({
        panNumber: PropTypes.string,
        aadharNumber: PropTypes.string,
        uanNumber: PropTypes.string,
        uhidNumber: PropTypes.string,
      }),

      'ADDITIONAL DETAILS': PropTypes.shape({
        'Education/Certification History': PropTypes.shape({
          institutionName: PropTypes.string,
          certificationName: PropTypes.string,
          startDate: PropTypes.string,
          endDate: PropTypes.string,
          specialization: PropTypes.string,
          registrationNumber: PropTypes.string,
          referenceName: PropTypes.string,
          referenceNumber: PropTypes.string,
          referenceEmail: PropTypes.string,
          comments: PropTypes.string,
          attachments: PropTypes.string,
        }),

        'Employment History': PropTypes.shape({
          employeeName: PropTypes.string,
          employerAddress: PropTypes.string,
          employmentCommenceDate: PropTypes.string,
          employmentEndDate: PropTypes.string,
          reasonForLeaving: PropTypes.string,
          employeeId: PropTypes.string,
          startDesignation: PropTypes.string,
          endDesignation: PropTypes.string,
          referenceContactName: PropTypes.string,
          referenceContactDesignation: PropTypes.string,
          referenceContactPhone: PropTypes.string,
          referenceContactEmail: PropTypes.string,
          attachment: PropTypes.string,
        }),

        'Social Data': PropTypes.shape({
          skype: PropTypes.string,
          facebook: PropTypes.string,
          linkedin: PropTypes.string,
          googlePlus: PropTypes.string,
          twitter: PropTypes.string,
          instagram: PropTypes.string,
        }),

        'Visa Details': PropTypes.shape({
          passportNumber: PropTypes.string,
          passportIssueDate: PropTypes.string,
          passportExpDate: PropTypes.string,
          passportIssuePlace: PropTypes.string,
          passportCountry: PropTypes.string,
          passportGivenName: PropTypes.string,
          passportAttachments: PropTypes.string,
          visa: PropTypes.arrayOf(
            PropTypes.shape({
              issuePlace: PropTypes.string,
              validFrom: PropTypes.string,
              validTo: PropTypes.string,
              issueDate: PropTypes.string,
              referenceNumber: PropTypes.string,
              visaStatus: PropTypes.string,
              comments: PropTypes.string,
              attachment: PropTypes.string,
            }),
          ),
        }),

        'Skill Attainment Details': PropTypes.shape({
          eventDetails: PropTypes.string,
          eventDate: PropTypes.string,
          comments: PropTypes.string,
          attachments: PropTypes.string,
        }),

        'Key Life Event Details': PropTypes.shape({
          keyLifeEventDetails: PropTypes.string,
          eventDate: PropTypes.string,
          comments: PropTypes.string,
        }),
      }),

      'CONTACT DETAILS': PropTypes.shape({
        mobileNumber: PropTypes.arrayOf(PropTypes.string),
        emailAddress: PropTypes.arrayOf(PropTypes.string),
        name: PropTypes.string,
        relation: PropTypes.string,
        emergencyMobile: PropTypes.string,
        emergencyEmail: PropTypes.string,
        permanentLocation: PropTypes.string,
        permanentAddressLine1: PropTypes.string,
        permanentAddressLine2: PropTypes.string,
        permanentAddressLine3: PropTypes.string,
        permanentCity: PropTypes.string,
        permanentState: PropTypes.string,
        permanentCountry: PropTypes.string,
        permanentPincode: PropTypes.number,
        currentLocation: PropTypes.string,
        currentAddressLine1: PropTypes.string,
        currentAddressLine2: PropTypes.string,
        currentAddressLine3: PropTypes.string,
        currentCity: PropTypes.string,
        currentState: PropTypes.string,
        currentCountry: PropTypes.string,
        currentPincode: PropTypes.number,
        ofcLocationName: PropTypes.string,
        ofcAddressLine1: PropTypes.string,
        ofcAddressLine2: PropTypes.string,
        ofcAddressLine3: PropTypes.string,
        ofcCity: PropTypes.string,
        ofcState: PropTypes.string,
        ofcCountry: PropTypes.string,
        ofcPincode: PropTypes.number,
        sameAddress: PropTypes.string,
      }),

      DEPENDENT: PropTypes.shape({
        gender: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        relation: PropTypes.string,
        dateOfBirth: PropTypes.string,
        mobileNumber: PropTypes.string,
        emailId: PropTypes.string,
        epfPerc: PropTypes.string,
        epsPerc: PropTypes.string,
        insurancePerc: PropTypes.string,
        otherPerc: PropTypes.string,
        gratuityPerc: PropTypes.string,
        emergencyContact: PropTypes.string,
      }),

      'Bank Account': PropTypes.shape({
        accountNumber: PropTypes.string,
        accountName: PropTypes.string,
        accountType: PropTypes.string,
        accountCurrency: PropTypes.string,
        purpose: PropTypes.string,
        transactionType: PropTypes.string,
        bankName: PropTypes.string,
        branchName: PropTypes.string,
        ifsc: PropTypes.string,
        swiftCode: PropTypes.string,
        locationName: PropTypes.string,
        addressLine1: PropTypes.string,
        addressLine2: PropTypes.string,
        addressLine3: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        country: PropTypes.string,
        pincode: PropTypes.number,
      }),
    }),
  }),

  attachmentEditData: PropTypes.shape({
    attachmentEditDataColumns: PropTypes.arrayOf(
      PropTypes.shape({
        fieldName: PropTypes.string,
        label: PropTypes.string,
        formatValue: PropTypes.func,
      }),
    ),

    attachmentEditDataRow: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        slNo: PropTypes.string,
        documentName: PropTypes.string,
        section: PropTypes.string,
        relevantRecord: PropTypes.string,
        approvalStatus: PropTypes.string,
        attachment: PropTypes.string,
      }),
    ),

    attachmentEditSortBy: PropTypes.string,
    attachmentEditSortDirection: PropTypes.string,
    attachmentEditCount: PropTypes.number,
    attachmentEditPage: PropTypes.number,
    attachmentEditOnPageChange: PropTypes.func,
    attachmentEditOnSortChange: PropTypes.func,
    attachmentEditHandleDocument: PropTypes.func,
  }),

  handleDocumentAttachment: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  handleFinalizeDownload: PropTypes.func,
  handleFinalizeAttachment: PropTypes.func,
};

export default OnboardingRecords;
