import PropTypes from 'prop-types';
import React from 'react';
import Dialog from 'components/Molecules/Dialog';
import Typographys from 'components/Atoms/Typography';

const DeleteDocument = ({ handleCancel, primaryBtn, secondaryBtn, fileName, open, handleClose, handleDelete }) => {
  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      dialogTitle="Delete Document"
      dialogDesc={
        <Typographys variant="body2" color="neutral.600" align="center">
          Are you sure you want to delete
          <Typographys color="neutral.800" sx={{ display: 'inline', pl: 1 }}>
            {fileName}
          </Typographys>
          ?
        </Typographys>
      }
      handlePrimaryBtn={handleDelete}
      primaryBtnColor="error"
      primaryBtn={primaryBtn}
      secondaryBtn={secondaryBtn}
      handleSecondaryBtn={handleCancel}
      bodyContent={false}
    />
  );
};
DeleteDocument.defaultProps = {
  handleDelete: () => {},
  fileName: 'Offer_Letter_10001_Aachamn_Kabir.doc?',
  primaryBtn: 'Delete',
  secondaryBtn: 'Cancel',
  handleCancel: () => {},
  open: true,
  handleClose: () => {},
};
DeleteDocument.propTypes = {
  fileName: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleDelete: PropTypes.func,
  handleCancel: PropTypes.func,
  primaryBtn: PropTypes.string,
  secondaryBtn: PropTypes.string,
};
export default DeleteDocument;
