import React from 'react';
import PropTypes from 'prop-types';
import Dashboard from 'components/Organisms/MyPay/Dashboard';
import GraphColor from 'utils/GraphColor';
import DashboardTemplate from 'components/Templates/DashboardTemplate';

const MyPayDashboardTemplate = ({
  actionMenuLists,
  grossAmount,
  lastCredited,
  salaryDetails,
  data,
  colors,
  legendOptions,
  chartOptions,
  chartInfoLeft,
  dataWiseDetails,
  chartOptionsWiseDetails,
  dataWiseAggregates,
  chartOptionsWiseAggregates,
}) => {
  return (
    <DashboardTemplate pageHeading="My Pay" actionMenuLists={actionMenuLists}>
      <Dashboard
        grossAmount={grossAmount}
        lastCredited={lastCredited}
        salaryDetails={salaryDetails}
        data={data}
        colors={colors}
        legendOptions={legendOptions}
        chartOptions={chartOptions}
        chartInfoLeft={chartInfoLeft}
        dataWiseDetails={dataWiseDetails}
        chartOptionsWiseDetails={chartOptionsWiseDetails}
        dataWiseAggregates={dataWiseAggregates}
        chartOptionsWiseAggregates={chartOptionsWiseAggregates}
      />
    </DashboardTemplate>
  );
};

MyPayDashboardTemplate.defaultProps = {
  actionMenuLists: [],
  grossAmount: '-',
  lastCredited: '-',
  salaryDetails: [],

  data: [],
  colors: GraphColor('pieChartsColors'),
  legendOptions: {
    layout: 'vertical',
    verticalAlign: 'middle',
    align: 'right',
    legendLeftSpacing: '40rem',
    iconWidth: '12px',
    iconHeight: '12px',
    iconType: 'circle',
  },
  chartOptions: {
    chartWidth: 880,
    chartHeight: 450,
    paddingAngle: 0,
    innerRadius: 60,
    outerRadius: 90,
    cx: 90,
    cy: '',
  },
  chartInfoLeft: '6rem',

  dataWiseDetails: [],
  chartOptionsWiseDetails: {
    chartWidth: 500,
    chartHeight: 340,
    paddingAngle: 0,
    innerRadius: 60,
    outerRadius: 90,
    cx: 100,
    cy: '',
  },

  dataWiseAggregates: [],
  chartOptionsWiseAggregates: {
    chartWidth: 600,
    chartHeight: 340,
    paddingAngle: 0,
    innerRadius: 60,
    outerRadius: 90,
    cx: 100,
    cy: '',
  },
};

MyPayDashboardTemplate.propTypes = {
  actionMenuLists: PropTypes.arrayOf(PropTypes.shape({})),
  grossAmount: PropTypes.string,
  lastCredited: PropTypes.string,
  salaryDetails: PropTypes.arrayOf(PropTypes.shape({})),

  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    }),
  ),

  dataWiseDetails: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  chartOptionsWiseDetails: PropTypes.shape({}),

  dataWiseAggregates: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  chartOptionsWiseAggregates: PropTypes.shape({}),
  colors: PropTypes.arrayOf(PropTypes.string),
  legendOptions: PropTypes.shape(),
  chartOptions: PropTypes.shape(),
  chartInfoLeft: PropTypes.string,
};

export default MyPayDashboardTemplate;
