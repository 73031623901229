import React from 'react';
import PropTypes from 'prop-types';
import DashboardTemplate from 'components/Templates/DashboardTemplate';
import MyLeaveDashboard from 'components/Organisms/Leaves/MyLeaves/Dashboard';

const MyLeaveDashboardTemplate = ({
  isActionButton,
  handleAction,
  actionLabel,
  totalLeaveTransactions,
  leaveData,
  leaveTabData,
  onTabChange,
  selectedTab,
  isActionMenuLists,
}) => {
  return (
    <DashboardTemplate
      pageHeading="Dashboard"
      isActionButton={isActionButton}
      handleAction={handleAction}
      actionLabel={actionLabel}
      isActionMenuLists={isActionMenuLists}
    >
      <MyLeaveDashboard
        totalLeaveTransactions={totalLeaveTransactions}
        leaveData={leaveData}
        leaveTabData={leaveTabData}
        onTabChange={onTabChange}
        selectedTab={selectedTab}
      />
    </DashboardTemplate>
  );
};

MyLeaveDashboardTemplate.defaultProps = {
  isActionMenuLists: false,
  isActionButton: true,
  handleAction: () => {},
  actionLabel: 'Add Leave Request',
  totalLeaveTransactions: 0,
  leaveData: [],
  onTabChange: () => {},
  leaveTabData: [],
  selectedTab: 0,
};

MyLeaveDashboardTemplate.propTypes = {
  isActionMenuLists: PropTypes.bool,
  isActionButton: PropTypes.bool,
  handleAction: PropTypes.func,
  actionLabel: PropTypes.string,
  totalLeaveTransactions: PropTypes.number,
  leaveData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
      openingBalance: PropTypes.number,
      leavesAvailed: PropTypes.number,
      currentBalance: PropTypes.number,
      transactions: PropTypes.number,
      pendingApprovalBalance: PropTypes.number,
    }),
  ),
  onTabChange: PropTypes.func,
  leaveTabData: PropTypes.arrayOf(
    PropTypes.shape({
      openingLeave: PropTypes.number,
      leavesAvailed: PropTypes.number,
      transactions: PropTypes.number,
      pendingApproval: PropTypes.number,
      remainingBalance: PropTypes.number,
      loading: PropTypes.bool,
      leaveTransactionsColumn: PropTypes.arrayOf(
        PropTypes.shape({
          fieldName: PropTypes.string,
          label: PropTypes.string,
          formatValue: PropTypes.func,
        }),
      ),
      leaveTransactionsRow: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          startDate: PropTypes.string,
          endDate: PropTypes.string,
          leavesAvailed: PropTypes.string,
          leavesAccounted: PropTypes.number,
          leavesDeducted: PropTypes.number,
        }),
      ),
      page: PropTypes.number,
      sortDirection: PropTypes.string,
      sortBy: PropTypes.func,
      handleSort: PropTypes.func,
      count: PropTypes.number,
      onPageChange: PropTypes.func,
      onSortChange: PropTypes.func,
    }),
  ),
  selectedTab: PropTypes.number,
};

export default MyLeaveDashboardTemplate;
