import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useSelector } from 'react-redux';
import { hasPermission } from 'utils/permissions';
import NavItem from '../NavItem';

const NavCollapse = ({ menu, level }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleClick = () => {
    setOpen(!open);
    setSelected(!selected ? menu.id : null);
  };

  const { pathname } = useLocation();

  const checkOpenForParent = useCallback(
    (child, id) => {
      child.forEach((item) => {
        if (item.url === pathname) {
          setOpen(true);
          setSelected(id);
        }
      });
    },
    [pathname],
  );

  const authorities = useSelector((state) => state.session.user.authorities);

  // menu collapse for sub-levels
  useEffect(() => {
    setOpen(false);
    setSelected(null);
    if (menu.children) {
      menu.children.forEach((item) => {
        if (item.children?.length) {
          checkOpenForParent(item.children, menu.id);
        }
        if (item?.url === pathname || pathname?.toLowerCase().includes(item?.url?.toLowerCase())) {
          setSelected(menu.id);
          setOpen(true);
        }
      });
    }
  }, [pathname, menu.children, checkOpenForParent, menu.id]);

  // menu collapse & item
  const menus = menu.children?.map((item) => {
    const menuPermission = hasPermission(authorities, item.permissions);
    switch (item.type) {
      case 'collapse':
        return menuPermission ? <NavCollapse key={item.id} menu={item} level={level + 1} /> : null;
      case 'item':
        return menuPermission ? <NavItem key={item.id} item={item} level={level + 1} /> : null;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  const Icon = menu.icon;
  const menuIcon = menu.icon ? (
    <Icon
      strokeWidth={1.5}
      fontSize="2.25"
      sx={{ my: 'auto', color: selected === menu.id ? 'primary.300' : 'neutral.600' }}
    />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: selected === menu.id ? 8 : 6,
        height: selected === menu.id ? 8 : 6,
      }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  );

  return (
    <>
      <ListItemButton
        sx={{
          mb: 0.5,
          alignItems: 'flex-start',
          backgroundColor: 'transparent',
          py: level > 1 ? 1 : 1.25,
          pl: `${level * 3}rem`,
        }}
        onClick={handleClick}
      >
        <ListItemIcon sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 36 }}>{menuIcon}</ListItemIcon>
        <ListItemText
          primary={
            <Typography
              variant="body2"
              color={selected === menu.id ? 'primary.300' : 'neutral.600'}
              sx={{ my: 'auto' }}
            >
              {menu.title}
            </Typography>
          }
        />
        {open ? (
          <KeyboardArrowUpIcon
            stroke={1.5}
            sx={{ my: 'auto', fontSize: '2.125rem', color: selected === menu.id ? 'primary.300' : 'neutral.600' }}
          />
        ) : (
          <KeyboardArrowDownIcon
            stroke={1.5}
            sx={{ my: 'auto', fontSize: '2.125rem', color: selected === menu.id ? 'primary.300' : 'neutral.600' }}
          />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={{
            position: 'relative',
            '&:after': {
              content: "''",
              position: 'absolute',
              left: '4rem',
              top: 0,
              height: '100%',
              width: '0.125rem',
              opacity: 1,
            },
          }}
        >
          {menus}
        </List>
      </Collapse>
    </>
  );
};

NavCollapse.propTypes = {
  menu: PropTypes.shape({
    id: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.node),
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  level: PropTypes.number,
};

NavCollapse.defaultProps = {
  level: 1,
};

export default NavCollapse;
