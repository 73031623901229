import { Navigate, Outlet } from 'react-router-dom';
import React from 'react';
import MainLayout from 'layouts/MainLayout';
import RoleBasedGuard from 'components/guards/RoleBasedGaurd';
import Login from 'pages/Login';
import ResetPassword from 'pages/ResetPassword';
import ViewPayhead from 'pages/Company/Salary/Payhead/ViewPayhead';
import PayheadsList from 'pages/Company/Salary/Payhead/List';
import AddEditPayhead from 'pages/Company/Salary/Payhead/AddEdit';
import ViewEmployee from 'pages/Company/employee/ViewEmployee';
import ViewPayrollCalendar from 'pages/Company/Salary/PayrollCalendar/ViewPayrollCalendar';
import PayrollCalendarsList from 'pages/Company/Salary/PayrollCalendar/List';
import AddEditPayrollCalendar from 'pages/Company/Salary/PayrollCalendar/AddEdit';
import EmployeesList from 'pages/Company/employee/List';
import ViewChallan from 'pages/Company/Salary/Challan/View';
import ChallansList from 'pages/Company/Salary/Challan/List';
import AddEditChallan from 'pages/Company/Salary/Challan/AddEdit';
import UnderDevelopment from 'pages/UnderDevelopment';
import ListLifeCyclePolicies from 'pages/Company/employee/company-setup/lifecyclepolicies/List';
import ViewAllocation from 'pages/Company/Salary/Challan/ViewAllocation';
import AllocateChallan from 'pages/Company/Salary/Challan/AllocateChallan';
import { TextEditorSample } from 'components/text-editor/TextEditorSample';
import AddEditEmployee from 'pages/Company/employee/AddEdit';
import EmployeeSalaryStructure from 'pages/Company/employee/employeeRecords/salaryStructure';
import TaxWorking from 'pages/Company/employee/employeeRecords/salaryStructure/taxWorking';
import ViewPastEmployerTax from 'pages/Company/Salary/PastEmployerTax/ViewPastEmployerTax';
import PastEmployerTaxsList from 'pages/Company/Salary/PastEmployerTax/List';
import AddEditPastEmployerTax from 'pages/Company/Salary/PastEmployerTax/AddEdit';
import PromoteEmployee from 'pages/Company/employee/ViewEmployee/PromoteEmployee';
import DemoteEmployee from 'pages/Company/employee/ViewEmployee/DemoteEmployee';
import ConfirmEmployee from 'pages/Company/employee/ViewEmployee/confirmEmployee';
import ViewLifecyclePolicy from 'pages/Company/employee/company-setup/lifecyclepolicies/ViewLifecyclePolicy';
import AccessFilter from 'pages/Company/employee/AccessFilter';
import AdvancedSearch from 'pages/Company/employee/List/AdvancedSearch';
import ExtendProbation from 'pages/Company/employee/ViewEmployee/ExtendProbation';
import TerminateEmployee from 'pages/Company/employee/ViewEmployee/TerminateEmployee';
import TransferEmployee from 'pages/Company/employee/ViewEmployee/TransferEmployee';
import ListLCRecords from 'pages/Company/Lifecycle/LCCaseRecords/List/List';
import VerifyBackground from 'pages/Company/employee/ViewEmployee/VerifyBackground';
import Dashboard from 'pages/Company/employee/Dashboard';
import InitiatePIP from 'pages/Company/employee/ViewEmployee/InitiatePIP';
import ConcludePIP from 'pages/Company/employee/ViewEmployee/ConcludePIP';
import VisaDetails from 'pages/Profile/VisaDetails';
import EditVisaDetails from 'pages/Profile/VisaDetails/EditVisaDetails';
import MyDocumentList from 'pages/home/Documents/List';
import MyDocumentDetails from 'pages/home/Documents/Details';
import SkillDetails from 'pages/Profile/SkillDetails';
import EditSkillDetails from 'pages/Profile/SkillDetails/EditSkillDetails';
import LCCaseRecordDetails from 'pages/Company/Lifecycle/LCCaseRecords/Details';
import ActionLCCaseRecord from 'pages/Company/Lifecycle/LCCaseRecords/Action';
import AddEditRecord from 'pages/Company/Lifecycle/LCCaseRecords/AddEdit';
import PasswordReset from 'pages/Company/employee/company-setup/passwordReset';
import BankAccounts from 'pages/Profile/BankAccounts';
import LifeEventDetails from 'pages/Profile/LifeEventDetails';
import EditLifeEventDetails from 'pages/Profile/LifeEventDetails/EditLifeEventDetails';
import ModifyPassword from 'pages/Profile/ModifyPassword';
import MyLeavesList from 'pages/home/Leaves/Requests/list';
import EmploymentHistoryDetails from 'pages/Profile/EmploymentHistory';
import EmploymentHistoryAddEdit from 'pages/Profile/EmploymentHistory/AddEdit';
import EducationalHistoryDetails from 'pages/Profile/EducationHistory';
import EducationHistoryAddEdit from 'pages/Profile/EducationHistory/AddEdit';
import AddEditDependents from 'pages/Profile/Dependents/AddEdit';
import Dependents from 'pages/Profile/Dependents';
import MyDocumentAddEdit from 'pages/home/Documents/AddEdit';
import EditPassword from 'pages/ResetPassword/newPassword';
import ListMyExpenses from 'pages/home/expenses/MyExpenses/List';
import ListMyTeamExpenses from 'pages/Myteam/Expenses/TeamExpenses/List';
import AddNewReport from 'pages/home/expenses/MyExpenses/AddNewReport';
import ListExpenseCategories from 'pages/home/expenses/ExpenseCategories/List';
import AddEditExpenseCategory from 'pages/home/expenses/ExpenseCategories/AddEdit';
import TeamPendingReports from 'pages/Myteam/Expenses/PendingMyActions/List';
import SalarySlipPage from 'pages/home/MyPay/SalarySlipList';
import SalarySlipDetailPage from 'pages/home/MyPay/SalarySlipDetails';
import MyExpensesDashboard from 'pages/home/expenses/Dashboard';
import BasicProfile from 'pages/Profile/BasicProfile';
import BasicProfileAddEdit from 'pages/Profile/BasicProfile/AddEdit';
import TeamDocumentAddEdit from 'pages/Myteam/Documents/AddEdit';
import TeamDocumentList from 'pages/Myteam/Documents/List';
import TeamDocumentDetails from 'pages/Myteam/Documents/Details';
import AddEditBankAccounts from 'pages/Profile/BankAccounts/AddEdit';
import AddEditMyLeave from 'pages/home/Leaves/Requests/AddEdit';
import ViewMyExpenses from 'pages/home/expenses/MyExpenses/View';
import MyLeavesAccounts from 'pages/home/Leaves/leaveAccounts';
import LeaveDetailsPage from 'pages/home/Leaves/Requests/Details';
import TeamLeavesList from 'pages/Myteam/Leaves/Requests/list';
import ViewOnboardingRecord from 'pages/Profile/OnboardingRecords/View';
import HomeDashboardPage from 'pages/home/HomeDashboard';
import InitiateSeparation from 'pages/home/Separation/InitiateSeparation';
import WithdrawSeparation from 'pages/home/Separation/WithdrawSeparation';
import ViewSeparation from 'pages/home/Separation/ViewSeparation';
import EditOnboardingPage from 'pages/Profile/OnboardingRecords/Edit';
import ListMyQueries from 'pages/home/MyQueries/List';
import ViewQueries from 'pages/home/MyQueries/View';
import AddQuery from 'pages/home/MyQueries/AddQuery';
import ReassignQuery from 'pages/home/MyQueries/ReassignQuery';
import ActionQuery from 'pages/home/MyQueries/ActionQuery';
import EscalateQuery from 'pages/home/MyQueries/EscalateQuery';
import ListMyActionableQueries from 'pages/home/MyActionableQueries/List';
import ViewActionableQueries from 'pages/home/MyActionableQueries/View';
import AddActionableQuery from 'pages/home/MyActionableQueries/AddQuery';
import ReassignActionableQuery from 'pages/home/MyActionableQueries/ReassignQuery';
import ActionActionableQuery from 'pages/home/MyActionableQueries/ActionQuery';
import EscalateActionableQuery from 'pages/home/MyActionableQueries/EscalateQuery';
import Separations from 'pages/Myteam/Separations/List';
import SeparationsDetails from 'pages/Myteam/Separations/Details';
import SeparationsAddEdit from 'pages/Myteam/Separations/AddEdit';
import InitiateEmployeeSeparation from 'pages/Myteam/Separations/InitiateEmployeeSeparation';
import CompareTDSPage from 'pages/home/MyPay/CompareTDS';
import Addresses from 'pages/Profile/Addresses';
import AddEditAddress from 'pages/Profile/Addresses/AddEdit';
import ViewTDSPage from 'pages/home/MyPay/DeclarationForTDS';
import Form12BBPage from 'pages/home/MyPay/Form12BB';
import AttendanceDashboard from 'pages/home/Attendance/Dashboard';
import TeamExpenseDashboard from 'pages/Myteam/Expenses/Dashboard';
import Reportees from 'pages/home/MyReporting/Reportees';
import MySalaryDetails from 'pages/home/MyPay/MySalaryDetails';
import MyPayDashboard from 'pages/home/MyPay/Dashboard';
import TdsWorkings from 'pages/home/MyPay/TDSWorkings';
import MyLeaveDashboard from 'pages/home/Leaves/Dashboard';
import InvestmentProofPage from 'pages/home/MyPay/InvestmentProofs';
import MyReporting from 'pages/home/MyReporting';
import TeamAttendanceDashboard from 'pages/Myteam/Attendance/Dashboard';
import CompanyDocumentList from 'pages/Company/Documents/List';
import CompanyDocumentDetails from 'pages/Company/Documents/Details';
import CompanyDocumentAddEdit from 'pages/Company/Documents/AddEdit';
import CompanyListQueries from 'pages/Company/Queries/List';
import CompanyViewQueries from 'pages/Company/Queries/View';
import CompanyAddQuery from 'pages/Company/Queries/AddQuery';
import CompanyReassignQuery from 'pages/Company/Queries/ReassignQuery';
import CompanyActionQuery from 'pages/Company/Queries/ActionQuery';
import CompanyEscalateQuery from 'pages/Company/Queries/EscalateQuery';
import TeamAddNewReport from 'pages/Myteam/Expenses/TeamExpenses/AddNewReport';
import ViewTeamExpenses from 'pages/Myteam/Expenses/TeamExpenses/View';
import { AlertContextProvider } from 'components/Provider/AlertToastContext';
import CompanyListExpenses from 'pages/Company/Expenses/List';
import CompanyViewExpenses from 'pages/Company/Expenses/View';
import CompanyAddNewReport from 'pages/Company/Expenses/AddNewReport';
import EmployeeActionRequestPage from 'pages/Myteam/Separations/ActionRequest';
import TeamPendingAction from 'pages/Myteam/Leaves/PendingAction/list';
import TeamLeavesAccounts from 'pages/Myteam/Leaves/leaveAccounts';
import TeamLeavesAddEdit from 'pages/Myteam/Leaves/Requests/AddEdit';
import TeamLeavesDetails from 'pages/Myteam/Leaves/Requests/Details';
import ExitTaskList from 'pages/Myteam/Separations/ExitTaskList';
import EditSeparation from 'pages/home/Separation/EditSeparation';
import ActionTeamExpenses from 'pages/Myteam/Expenses/TeamExpenses/Action';
import ViewExitTask from 'pages/Myteam/Separations/ExitTaskList/ViewExitTask';
import AddEditPageExitTask from 'pages/Myteam/Separations/ExitTaskList/AddEdit';
import TeamPendingReportsView from 'pages/Myteam/Expenses/PendingMyActions/View';
import TeamPendingReportsAddEdit from 'pages/Myteam/Expenses/PendingMyActions/AddEdit';
import TeamPendingReportAction from 'pages/Myteam/Expenses/PendingMyActions/Action';
import ListLCRecordsPendingApproval from 'pages/Company/Lifecycle/LCRecordsPendingApproval/List';
import DetailsLCCaseRecordPendingApproval from 'pages/Company/Lifecycle/LCRecordsPendingApproval/Details';
import ActionLCCaseRecordPendingApproval from 'pages/Company/Lifecycle/LCRecordsPendingApproval/Action';
import AddEditRecordPendingApproval from 'pages/Company/Lifecycle/LCRecordsPendingApproval/AddEdit';
import ViewSurveys from 'pages/home/Surveys/View';
import ActionCompanyExpenses from 'pages/Company/Expenses/Action';
import TeamDashboard from 'pages/Myteam/Dashboard';
import LeaveActionPages from 'pages/EmailActionPages/LeaveActionPages';
import Form12BBUploadPage from 'pages/home/MyPay/Form12BB/Upload';
import ListPastEmployerTaxes from 'pages/home/MyPay/PastEmployerTaxes/List';
import CreatePastEmployerTaxes from 'pages/home/MyPay/PastEmployerTaxes/AddEdit';
import ViewPastEmployerTaxes from 'pages/home/MyPay/PastEmployerTaxes/View';
import LCCRecordExtendProbationPage from 'pages/Company/Lifecycle/LCRecordsPendingApproval/ExtendProbation';
import AlumniDashboardPage from 'pages/home/AlumniDashboard';

// sample blog import to be removed
// Underdevelopment page please do not remove it until development is complete

const routes = (isLoggedIn) => {
  return [
    {
      path: '',
      element: isLoggedIn ? <MainLayout /> : <Navigate to="/login" />,
      children: [
        {
          path: 'home',
          children: [
            {
              path: '',
              element: (
                <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_SENTINEL_EMP']}>
                  <HomeDashboardPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'dashboard',
              element: (
                <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_SENTINEL_EMP']}>
                  <HomeDashboardPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'alumni-dashboard',
              element: (
                <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_SENTINEL_EMP']}>
                  <AlumniDashboardPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'attendance',
              children: [
                {
                  path: '',
                  element: (
                    <RoleBasedGuard
                      type="some"
                      permissions={[
                        'ROLE_BASE_PERMISSION_ATT_INDEX_DASHBOARD',
                        'ROLE_BASE_PERMISSION_ATT_INDEX_MY_ATTENDANCE_THIS_WEEK',
                        'ROLE_BASE_PERMISSION_ATT_INDEX_MY_ATTENDANCE_THIS_MONTH',
                        'ROLE_BASE_PERMISSION_ATT_INDEX_MY_ATTENDANCE_CUSTOM_PERIOD',
                        'ROLE_BASE_PERMISSION_ATT_INDEX_BULK_REGULARIZE_ATTENDANCE_EXCEPTIONS',
                        'ROLE_BASE_PERMISSION_ATT_INDEX_VIEW_MY_TIMER',
                      ]}
                    >
                      <AttendanceDashboard />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'dashboard',
                  element: (
                    <RoleBasedGuard
                      type="some"
                      permissions={[
                        'ROLE_BASE_PERMISSION_ATT_INDEX_DASHBOARD',
                        'ROLE_BASE_PERMISSION_ATT_INDEX_MY_ATTENDANCE_THIS_WEEK',
                        'ROLE_BASE_PERMISSION_ATT_INDEX_MY_ATTENDANCE_THIS_MONTH',
                        'ROLE_BASE_PERMISSION_ATT_INDEX_MY_ATTENDANCE_CUSTOM_PERIOD',
                        'ROLE_BASE_PERMISSION_ATT_INDEX_BULK_REGULARIZE_ATTENDANCE_EXCEPTIONS',
                        'ROLE_BASE_PERMISSION_ATT_INDEX_VIEW_MY_TIMER',
                      ]}
                    >
                      <AttendanceDashboard />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: 'mypay',
              children: [
                {
                  path: '',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_PAY_INDEX_DASHBOARD']}>
                      <MyPayDashboard />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'dashboard',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_PAY_INDEX_DASHBOARD']}>
                      <MyPayDashboard />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'past-employer-taxes',
                  children: [
                    {
                      path: '',
                      element: (
                        <RoleBasedGuard
                          permissions={['ROLE_BASE_PERMISSION_PAY_INDEX_LIST_PREVIOUS_EMP_TAX_DETAILS_BY_EMPLOYEE']}
                        >
                          <ListPastEmployerTaxes />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: 'add',
                      element: (
                        <RoleBasedGuard
                          permissions={['ROLE_BASE_PERMISSION_PAY_INDEX_CREATE_PREVIOUS_EMP_TAX_DETAILS_BY_EMPLOYEE']}
                        >
                          <CreatePastEmployerTaxes />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: 'edit/:id',
                      element: (
                        <RoleBasedGuard
                          permissions={['ROLE_BASE_PERMISSION_PAY_INDEX_UPDATE_PREVIOUS_EMP_TAX_DETAILS_BY_EMPLOYEE']}
                        >
                          <CreatePastEmployerTaxes />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: 'view/:id',
                      element: (
                        <RoleBasedGuard
                          permissions={['ROLE_BASE_PERMISSION_PAY_INDEX_VIEW_PREVIOUS_EMP_TAX_DETAILS_BY_EMPLOYEE']}
                        >
                          <ViewPastEmployerTaxes />
                        </RoleBasedGuard>
                      ),
                    },
                  ],
                },
                {
                  path: 'my-salary-details',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_PAY_INDEX_VIEW_MY_SALARY_DETAILS']}>
                      <MySalaryDetails />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'salary-slips',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_PAY_INDEX_LIST_MY_SALARY_SLIPS']}>
                      <SalarySlipPage />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'salary-slips-details/:id',
                  element: (
                    <RoleBasedGuard permissions={[]}>
                      <SalarySlipDetailPage />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'form-12BB',
                  children: [
                    {
                      path: '',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_PAY_INDEX_VIEW_MY_FORM_12BB']}>
                          <Form12BBPage />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: 'upload',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_PAY_INDEX_UPLOAD_MY_FORM_12BB']}>
                          <Form12BBUploadPage />
                        </RoleBasedGuard>
                      ),
                    },
                  ],
                },
                {
                  path: 'tds-declaration',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_PAY_INDEX_VIEW_MY_DECLARATION']}>
                      <ViewTDSPage />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'tds-declaration/edit',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_PAY_INDEX_UPDATE_MY_DECLARATION']}>
                      <ViewTDSPage />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'tds-workings',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_PAY_INDEX_VIEW_MY_TAX_WORKING']}>
                      <TdsWorkings />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'compare-tds',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_PAY_INDEX_ASSESS_EMP_REGIMEWISE_TDS']}>
                      <CompareTDSPage />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'investment-proofs',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_PAY_INDEX_UPLOAD_MY_INVESTMENT_PROOFS']}>
                      <InvestmentProofPage />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: 'expenses',
              children: [
                {
                  path: '',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EXPENSE_INDEX_LIST_MY_EXPENSE_REPORTS']}>
                      <ListMyExpenses />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'dashboard',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EXPENSE_INDEX_DASHBOARD']}>
                      <MyExpensesDashboard />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ':id/view',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EXPENSE_INDEX_VIEW_EXPENSE_REPORT']}>
                      <ViewMyExpenses />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'add',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EXPENSE_INDEX_CREATE_EXPENSE_REPORT']}>
                      <AddNewReport />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ':id/edit',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EXPENSE_INDEX_UPDATE_EXPENSE_REPORT']}>
                      <AddNewReport />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'expense-categories',
                  children: [
                    {
                      path: '',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EXPENSE_INDEX_EXPENSE_CATEGORIES_LIST']}>
                          <ListExpenseCategories />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: 'add',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EXPENSE_INDEX_CREATE_EXPENSE_CATEGORY']}>
                          <AddEditExpenseCategory />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: ':id/edit',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EXPENSE_INDEX_UPDATE_EXPENSE_CATEGORY']}>
                          <AddEditExpenseCategory />
                        </RoleBasedGuard>
                      ),
                    },
                  ],
                },
              ],
            },
            {
              path: 'leaves',
              children: [
                {
                  path: '',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_LEAVE_INDEX_DASHBOARD']}>
                      <MyLeaveDashboard />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'dashboard',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_LEAVE_INDEX_DASHBOARD']}>
                      <MyLeaveDashboard />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'my-leave-requests',
                  children: [
                    {
                      path: '',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_LEAVE_INDEX_LIST_MY_LEAVE_REQUESTS']}>
                          <MyLeavesList />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: 'add',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_LEAVE_INDEX_CREATE_MY_LEAVE_REQUEST']}>
                          <AddEditMyLeave />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: ':id/edit',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_LEAVE_INDEX_UPDATE_LEAVE_REQUEST']}>
                          <AddEditMyLeave />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: ':id/view',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_LEAVE_INDEX_VIEW_LEAVE_REQUEST']}>
                          <LeaveDetailsPage />
                        </RoleBasedGuard>
                      ),
                    },
                  ],
                },
                {
                  path: 'my-leave-accounts',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_LEAVE_INDEX_DISPLAY_MY_LEAVE_ACCOUNTS']}>
                      <MyLeavesAccounts />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: 'documents',
              children: [
                {
                  path: '',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_INDEX_LIST_DOCUMENTS']}>
                      <MyDocumentList />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'add',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_INDEX_CREATE_DOCUMENT']}>
                      <MyDocumentAddEdit />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'edit/:id',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_INDEX_UPDATE_DOCUMENT']}>
                      <MyDocumentAddEdit />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'view/:id',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_INDEX_VIEW_DOCUMENT']}>
                      <MyDocumentDetails />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: 'queries',
              children: [
                {
                  path: '',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_QUER_INDEX_LIST_MY_QUERRIES']}>
                      <ListMyQueries />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ':id/view',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_QUER_INDEX_VIEW_QUERY']}>
                      <ViewQueries />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ':id/edit',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_QUER_INDEX_UPDATE_QUERY']}>
                      <AddQuery />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'add',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_QUER_INDEX_CREATE_QUERY']}>
                      <AddQuery />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ':id/reassign',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_QUER_INDEX_REASSIGN_QUERY']}>
                      <ReassignQuery />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ':id/action',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_QUER_INDEX_ACTION_QUERY']}>
                      <ActionQuery />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ':id/reopen',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_QUER_INDEX_REOPEN_QUERY']}>
                      <ActionQuery />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ':id/escalate',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_QUER_INDEX_ESCALATE_QUERY']}>
                      <EscalateQuery />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: 'actionable-queries',
              children: [
                {
                  path: '',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_QUER_INDEX_LIST_MY_ACTIONABLE_QUERRIES']}>
                      <ListMyActionableQueries />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ':id/view',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_QUER_INDEX_VIEW_QUERY']}>
                      <ViewActionableQueries />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ':id/edit',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_QUER_INDEX_UPDATE_QUERY']}>
                      <AddActionableQuery />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'add',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_QUER_INDEX_CREATE_QUERY']}>
                      <AddActionableQuery />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ':id/reassign',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_QUER_INDEX_REASSIGN_QUERY']}>
                      <ReassignActionableQuery />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ':id/action',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_QUER_INDEX_ACTION_QUERY']}>
                      <ActionActionableQuery />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ':id/reopen',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_QUER_INDEX_REOPEN_QUERY']}>
                      <ActionActionableQuery />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ':id/escalate',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_QUER_INDEX_ESCALATE_QUERY']}>
                      <EscalateActionableQuery />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: 'surveys',
              children: [
                {
                  path: 'view/:id',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_SENTINEL_EMP']}>
                      <ViewSurveys />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: 'myReporting',
              element: (
                <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_MY_UPWARD_HIERARCHY']}>
                  <MyReporting />
                </RoleBasedGuard>
              ),
              children: [
                {
                  path: ':id',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_EMP_UPWARD_HIERARCHY']}>
                      <MyReporting />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: 'reportees',
              children: [
                {
                  path: ':id',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_LIST_EMPLOYEE_REPORTEES']}>
                      <Reportees />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: 'separation',
              children: [
                {
                  path: 'initiate-separation',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_CREATE_MY_EMPLOYEE_EXIT']}>
                      <InitiateSeparation />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'view-separation',
                  children: [
                    {
                      path: '',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_MY_EXIT_DETAILS']}>
                          <ViewSeparation />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: 'edit/:id',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_EMPLOYEE_EXIT']}>
                          <EditSeparation />
                        </RoleBasedGuard>
                      ),
                    },
                  ],
                },
                {
                  path: 'withdraw-separation',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_WITHDRAW_MY_EMPLOYEE_EXIT']}>
                      <WithdrawSeparation />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: 'myteam',
          children: [
            {
              path: 'dashboard',
              element: (
                <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_SENTINEL_EMP_TEAM']}>
                  <TeamDashboard />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'attendance',
              children: [
                {
                  path: '',
                  element: (
                    <RoleBasedGuard
                      type="some"
                      permissions={[
                        'ROLE_BASE_PERMISSION_ATT_INDEX_DASHBOARD',
                        'ROLE_BASE_PERMISSION_ATT_INDEX_MY_TEAM_ATTENDANCE_THIS_WEEK',
                        'ROLE_BASE_PERMISSION_ATT_INDEX_MY_TEAM_ATTENDANCE_THIS_MONTH',
                        'ROLE_BASE_PERMISSION_ATT_INDEX_MY_TEAM_ATTENDANCE_CUSTOM_PERIOD',
                        'ROLE_BASE_PERMISSION_ATT_INDEX_BULK_REGULARIZE_ATTENDANCE_EXCEPTIONS',
                      ]}
                    >
                      <TeamAttendanceDashboard />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'dashboard',
                  element: (
                    <RoleBasedGuard
                      type="some"
                      permissions={[
                        'ROLE_BASE_PERMISSION_ATT_INDEX_DASHBOARD',
                        'ROLE_BASE_PERMISSION_ATT_INDEX_MY_TEAM_ATTENDANCE_THIS_WEEK',
                        'ROLE_BASE_PERMISSION_ATT_INDEX_MY_TEAM_ATTENDANCE_THIS_MONTH',
                        'ROLE_BASE_PERMISSION_ATT_INDEX_MY_TEAM_ATTENDANCE_CUSTOM_PERIOD',
                        'ROLE_BASE_PERMISSION_ATT_INDEX_BULK_REGULARIZE_ATTENDANCE_EXCEPTIONS',
                      ]}
                    >
                      <TeamAttendanceDashboard />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            // {
            //   path: 'employee',
            //   children: [],
            // },
            {
              path: 'separations',
              children: [
                {
                  path: '',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_LIST_EMPLOYEE_EXITS']}>
                      <Separations />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'exit-list/:id',
                  children: [
                    {
                      path: '',
                      element: (
                        <RoleBasedGuard permissions={[]}>
                          <ExitTaskList />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: 'view/:taskId',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_DISPLAY_TASK']}>
                          <ViewExitTask />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: 'edit/:taskId',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_TASK']}>
                          <AddEditPageExitTask />
                        </RoleBasedGuard>
                      ),
                    },
                  ],
                },
                {
                  path: 'view/:id',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_EMPLOYEE_EXIT']}>
                      <SeparationsDetails />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'action-request/:id',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_ACTION_EMPLOYEE_EXIT']}>
                      <EmployeeActionRequestPage />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'add',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_CREATE_EMPLOYEE_EXIT']}>
                      <SeparationsAddEdit />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'edit/:id',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_EMPLOYEE_EXIT']}>
                      <SeparationsAddEdit />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'initiate-employee-separation/:id',
                  element: <InitiateEmployeeSeparation />,
                },
              ],
            },
            {
              path: 'expenses',
              children: [
                {
                  path: 'dashboard',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EXPENSE_INDEX_DASHBOARD']}>
                      <TeamExpenseDashboard />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: '',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EXPENSE_INDEX_LIST_MY_ORG_EXPENSE_REPORTS']}>
                      <ListMyTeamExpenses />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ':id/view',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EXPENSE_INDEX_VIEW_EXPENSE_REPORT']}>
                      <ViewTeamExpenses />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'add',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EXPENSE_INDEX_CREATE_EXPENSE_REPORT']}>
                      <TeamAddNewReport />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ':id/edit',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EXPENSE_INDEX_UPDATE_EXPENSE_REPORT']}>
                      <TeamAddNewReport />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'action/:id',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EXPENSE_INDEX_ACTION_EXPENSE_REPORT']}>
                      <ActionTeamExpenses />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'actionable-reports',
                  children: [
                    {
                      path: '',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EXPENSE_INDEX_MY_EXPENSE_REPORTS_LIST']}>
                          <TeamPendingReports />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: ':id/view',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EXPENSE_INDEX_VIEW_EXPENSE_REPORT']}>
                          <TeamPendingReportsView />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: ':id/edit',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EXPENSE_INDEX_UPDATE_EXPENSE_REPORT']}>
                          <TeamPendingReportsAddEdit />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: 'action/:id',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EXPENSE_INDEX_ACTION_EXPENSE_REPORT']}>
                          <TeamPendingReportAction />
                        </RoleBasedGuard>
                      ),
                    },
                  ],
                },
              ],
            },
            {
              path: 'leaves',
              children: [
                {
                  path: '',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_LEAVE_INDEX_DASHBOARD']}>
                      <MyLeaveDashboard />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'dashboard',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_LEAVE_INDEX_DASHBOARD']}>
                      <MyLeaveDashboard />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'team-leave-requests',
                  children: [
                    {
                      path: '',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_LEAVE_INDEX_LIST_MY_TEAM_LEAVE_REQUESTS']}>
                          <TeamLeavesList />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: 'add',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_LEAVE_INDEX_CREATE_MY_LEAVE_REQUEST']}>
                          <TeamLeavesAddEdit />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: ':id/edit',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_LEAVE_INDEX_UPDATE_LEAVE_REQUEST']}>
                          <TeamLeavesAddEdit />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: ':id/view',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_LEAVE_INDEX_VIEW_LEAVE_REQUEST']}>
                          <TeamLeavesDetails />
                        </RoleBasedGuard>
                      ),
                    },
                  ],
                },
                {
                  path: 'team-leave-accounts',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_LEAVE_INDEX_DISPLAY_MY_TEAM_LEAVE_ACCOUNTS']}>
                      <TeamLeavesAccounts />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'requests-pending-action',
                  children: [
                    {
                      path: '',
                      element: (
                        <RoleBasedGuard
                          permissions={['ROLE_BASE_PERMISSION_LEAVE_INDEX_LIST_REQUESTS_PENDING_MY_ACTION']}
                        >
                          <TeamPendingAction />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: ':id/view',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_LEAVE_INDEX_VIEW_LEAVE_REQUEST']}>
                          <TeamLeavesDetails />
                        </RoleBasedGuard>
                      ),
                    },
                  ],
                },
              ],
            },
            {
              path: 'documents',
              children: [
                {
                  path: '',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_INDEX_LIST_MY_ORG_DOCUMENTS']}>
                      <TeamDocumentList />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'add',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_INDEX_CREATE_DOCUMENT']}>
                      <TeamDocumentAddEdit />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'edit/:id',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_INDEX_UPDATE_DOCUMENT']}>
                      <TeamDocumentAddEdit />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'view/:id',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_INDEX_VIEW_DOCUMENT']}>
                      <TeamDocumentDetails />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: 'life-cycle',
              children: [
                {
                  path: 'life-cycle-records-pending-approval',
                  element: (
                    <RoleBasedGuard
                      permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_LIST_CASE_RECORDS_PENDING_MY_APPROVAL']}
                    >
                      <ListLCRecordsPendingApproval />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: 'company',
          children: [
            {
              path: 'employee',
              children: [
                {
                  path: 'dashboard',
                  element: (
                    <RoleBasedGuard permissions={[]}>
                      <Dashboard />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'employees-list',
                  children: [
                    {
                      path: '',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_LIST_SECUSER']}>
                          <EmployeesList />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: 'records/salaryStructure/taxWorking',
                      element: (
                        <RoleBasedGuard permissions={[]}>
                          <TaxWorking />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: 'records/salaryStructure',
                      element: (
                        <RoleBasedGuard permissions={[]}>
                          <EmployeeSalaryStructure />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: ':id/edit',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_EDIT_SECUSER']}>
                          <AddEditEmployee />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: 'add',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_CREATE_SECUSER']}>
                          <AddEditEmployee />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: 'advanced-search',
                      element: (
                        <RoleBasedGuard permissions={[]}>
                          <AdvancedSearch />
                        </RoleBasedGuard>
                      ),
                    },
                  ],
                },
                {
                  path: ':id/edit',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_EDIT_SECUSER']}>
                      <AddEditEmployee />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'add',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_CREATE_SECUSER']}>
                      <AddEditEmployee />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'view/:userId',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_SECUSER']}>
                      <ViewEmployee />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'access-filters/:userId',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_ACCESS_FILTERS']}>
                      <AccessFilter />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'confirm-employee/:userId',
                  element: (
                    <RoleBasedGuard permissions={[]}>
                      <ConfirmEmployee />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'demote-employee/:userId',
                  element: (
                    <RoleBasedGuard permissions={[]}>
                      <DemoteEmployee />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'promote-employee/:userId',
                  element: (
                    <RoleBasedGuard permissions={[]}>
                      <PromoteEmployee />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'transfer-employee/:userId',
                  element: (
                    <RoleBasedGuard permissions={[]}>
                      <TransferEmployee />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'terminate-employee/:userId',
                  element: (
                    <RoleBasedGuard permissions={[]}>
                      <TerminateEmployee />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'extend-probation/:userId',
                  element: (
                    <RoleBasedGuard permissions={[]}>
                      <ExtendProbation />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'verify-background/:userId',
                  element: (
                    <RoleBasedGuard permissions={[]}>
                      <VerifyBackground />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'initiate-pip/:userId',
                  element: (
                    <RoleBasedGuard permissions={[]}>
                      <InitiatePIP />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'conclude-pip/:userId',
                  element: (
                    <RoleBasedGuard permissions={[]}>
                      <ConcludePIP />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'company-setup',
                  children: [
                    {
                      path: 'lifecyle-policies',
                      element: (
                        <RoleBasedGuard permissions={[]}>
                          <ListLifeCyclePolicies />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: 'lifecyle-policies/view/:policyId',
                      element: (
                        <RoleBasedGuard permissions={[]}>
                          <ViewLifecyclePolicy />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: 'password-reset',
                      element: (
                        <RoleBasedGuard permissions={[]}>
                          <PasswordReset />
                        </RoleBasedGuard>
                      ),
                    },
                  ],
                },
              ],
            },
            {
              path: 'life-cycle',
              children: [
                {
                  path: 'life-cycle-records',
                  children: [
                    {
                      path: '',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_LIST_CASE_RECORDS']}>
                          <ListLCRecords />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: ':userId/:changeType/edit',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_CASE_RECORD']}>
                          <AddEditRecord />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: ':userId/:changeType/action',
                      element: (
                        <RoleBasedGuard
                          permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_ACTION_LIFECYCLE_CASE_RECORD']}
                        >
                          <ActionLCCaseRecord />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: ':userId/:changeType/view',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_CASE_RECORD']}>
                          <LCCaseRecordDetails />
                        </RoleBasedGuard>
                      ),
                    },
                  ],
                },
                {
                  path: 'life-cycle-records-pending-approval',
                  children: [
                    {
                      path: '',
                      element: (
                        <RoleBasedGuard
                          permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_LIST_CASE_RECORDS_PENDING_MY_APPROVAL']}
                        >
                          <ListLCRecordsPendingApproval />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: ':userId/:changeType/edit',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_CASE_RECORD']}>
                          <AddEditRecordPendingApproval />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: ':userId/:changeType/action',
                      element: (
                        <RoleBasedGuard
                          permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_ACTION_LIFECYCLE_CASE_RECORD']}
                        >
                          <ActionLCCaseRecordPendingApproval />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: ':userId/:changeType/view',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_CASE_RECORD']}>
                          <DetailsLCCaseRecordPendingApproval />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: 'extend-probation/:userId',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_EXTEND_PROBATION']}>
                          <LCCRecordExtendProbationPage />
                        </RoleBasedGuard>
                      ),
                    },
                  ],
                },
              ],
            },
            {
              path: 'salary',
              children: [
                {
                  path: 'payheads',
                  children: [
                    {
                      path: 'view/:payheadId',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_PAY_INDEX_VIEW_PAYHEAD']}>
                          <ViewPayhead />
                        </RoleBasedGuard>
                      ),
                    },

                    {
                      path: ':id/edit',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_PAY_INDEX_UPDATE_PAYHEAD']}>
                          <AddEditPayhead />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: 'add',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_PAY_INDEX_CREATE_PAYHEAD']}>
                          <AddEditPayhead />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: '',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_PAY_INDEX_LIST_PAYHEADS']}>
                          <PayheadsList />
                        </RoleBasedGuard>
                      ),
                    },
                  ],
                },
                {
                  path: 'pastEmployerTaxes',
                  children: [
                    {
                      path: 'view/:pastEmployerTaxId',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_PAY_INDEX_VIEW_PREVIOUS_EMP_TAX_DETAILS']}>
                          <ViewPastEmployerTax />
                        </RoleBasedGuard>
                      ),
                    },

                    {
                      path: ':id/edit',
                      element: (
                        <RoleBasedGuard
                          permissions={['ROLE_BASE_PERMISSION_PAY_INDEX_UPDATE_PREVIOUS_EMP_TAX_DETAILS']}
                        >
                          <AddEditPastEmployerTax />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: 'add',
                      element: (
                        <RoleBasedGuard
                          permissions={['ROLE_BASE_PERMISSION_PAY_INDEX_CREATE_PREVIOUS_EMP_TAX_DETAILS']}
                        >
                          <AddEditPastEmployerTax />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: '',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_PAY_INDEX_LIST_PREVIOUS_EMP_TAX_DETAILS']}>
                          <PastEmployerTaxsList />
                        </RoleBasedGuard>
                      ),
                    },
                  ],
                },
                {
                  path: 'loans',
                  children: [
                    {
                      path: 'view/:id',
                      element: (
                        <RoleBasedGuard permissions={[]}>
                          <ViewPayhead />
                        </RoleBasedGuard>
                      ),
                    },

                    {
                      path: ':id/edit',
                      element: (
                        <RoleBasedGuard permissions={[]}>
                          <AddEditPayhead />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: 'add',
                      element: (
                        <RoleBasedGuard permissions={[]}>
                          <AddEditPayhead />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: '',
                      element: (
                        <RoleBasedGuard permissions={[]}>
                          <PayheadsList />
                        </RoleBasedGuard>
                      ),
                    },
                  ],
                },
                {
                  path: 'payrollCalendar',
                  children: [
                    {
                      path: 'view/:id',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_PAY_INDEX_VIEW_PAYROLL_CALENDAR']}>
                          <ViewPayrollCalendar />
                        </RoleBasedGuard>
                      ),
                    },

                    {
                      path: ':id/edit',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_PAY_INDEX_UPDATE_PAYROLL_CALENDAR']}>
                          <AddEditPayrollCalendar />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: 'add',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_PAY_INDEX_CREATE_PAYROLL_CALENDAR']}>
                          <AddEditPayrollCalendar />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: '',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_PAY_INDEX_LIST_PAYROLL_CALENDARS']}>
                          <PayrollCalendarsList />
                        </RoleBasedGuard>
                      ),
                    },
                  ],
                },
                {
                  path: 'challans',
                  children: [
                    {
                      path: 'view/:challanId',
                      element: (
                        <RoleBasedGuard permissions={[]}>
                          <ViewChallan />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: 'view-allocations/:challanId',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_PAY_INDEX_VIEW_CHALLAN']}>
                          <ViewAllocation />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: ':id/edit',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_PAY_INDEX_UPDATE_CHALLAN']}>
                          <AddEditChallan />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: 'add',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_PAY_INDEX_CREATE_CHALLAN']}>
                          <AddEditChallan />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: '',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_PAY_INDEX_LIST_CHALLANS']}>
                          <ChallansList />
                        </RoleBasedGuard>
                      ),
                    },
                    {
                      path: 'allocate-challan/:challanId',
                      element: (
                        <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_PAY_INDEX_ALLOCATE_CHALLAN']}>
                          <AllocateChallan />
                        </RoleBasedGuard>
                      ),
                    },
                  ],
                },
              ],
            },
            {
              path: 'documents',
              children: [
                {
                  path: '',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_INDEX_LIST_ALL_DOCUMENTS']}>
                      <CompanyDocumentList />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'add',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_INDEX_CREATE_DOCUMENT']}>
                      <CompanyDocumentAddEdit />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'edit/:id',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_INDEX_UPDATE_DOCUMENT']}>
                      <CompanyDocumentAddEdit />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'view/:id',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_INDEX_VIEW_DOCUMENT']}>
                      <CompanyDocumentDetails />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: 'expenses',
              children: [
                {
                  path: '',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EXPENSE_INDEX_LIST_ALL_EXPENSE_REPORTS']}>
                      <CompanyListExpenses />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ':id/view',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EXPENSE_INDEX_VIEW_EXPENSE_REPORT']}>
                      <CompanyViewExpenses />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'add',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EXPENSE_INDEX_CREATE_EXPENSE_REPORT']}>
                      <CompanyAddNewReport />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ':id/edit',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EXPENSE_INDEX_UPDATE_EXPENSE_REPORT']}>
                      <CompanyAddNewReport />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'action/:id',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EXPENSE_INDEX_ACTION_EXPENSE_REPORT']}>
                      <ActionCompanyExpenses />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: 'queries',
              children: [
                {
                  path: '',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_QUER_INDEX_LIST_QUERRIES']}>
                      <CompanyListQueries />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ':id/view',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_QUER_INDEX_VIEW_QUERY']}>
                      <CompanyViewQueries />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ':id/edit',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_QUER_INDEX_UPDATE_QUERY']}>
                      <CompanyAddQuery />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'add',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_QUER_INDEX_CREATE_QUERY']}>
                      <CompanyAddQuery />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ':id/reassign',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_QUER_INDEX_REASSIGN_QUERY']}>
                      <CompanyReassignQuery />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ':id/action',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_QUER_INDEX_ACTION_QUERY']}>
                      <CompanyActionQuery />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ':id/reopen',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_QUER_INDEX_REOPEN_QUERY']}>
                      <CompanyActionQuery />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ':id/escalate',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_QUER_INDEX_ESCALATE_QUERY']}>
                      <CompanyEscalateQuery />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: 'profile',
          children: [
            {
              path: 'basic-profile',
              children: [
                {
                  path: '',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_USER_PROFILE']}>
                      <BasicProfile />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'edit',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_USER_PROFILE']}>
                      <BasicProfileAddEdit />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: 'bank-accounts',
              children: [
                {
                  path: '',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_BANK_ACCOUNTS']}>
                      <BankAccounts />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'addEdit',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_BANK_ACCOUNTS']}>
                      <AddEditBankAccounts />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: 'addresses',
              children: [
                {
                  path: '',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_ADDRESS']}>
                      <Addresses />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'addEdit',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_ADDRESS']}>
                      <AddEditAddress />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: 'dependents',
              children: [
                {
                  path: '',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_DEPENDENTS']}>
                      <Dependents />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'addEdit',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_DEPENDENTS']}>
                      <AddEditDependents />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: 'employment-history',
              children: [
                {
                  path: '',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_MY_EMPLOYMENT_HISTORY']}>
                      <EmploymentHistoryDetails />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'addEdit',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_MY_EMPLOYMENT_HISTORY']}>
                      <EmploymentHistoryAddEdit />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: 'education-history',
              children: [
                {
                  path: '',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_MY_EDUCATION_HISTORY']}>
                      <EducationalHistoryDetails />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'addEdit',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_MY_EDUCATION_HISTORY']}>
                      <EducationHistoryAddEdit />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: 'visa-details',
              children: [
                {
                  path: '',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_MY_VISA_DETAILS']}>
                      <VisaDetails />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'edit',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_MY_VISA_DETAILS']}>
                      <EditVisaDetails />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: 'skills-details',
              children: [
                {
                  path: '',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_MY_SKILLS']}>
                      <SkillDetails />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'edit',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_MY_SKILLS']}>
                      <EditSkillDetails />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: 'life-event-details',
              children: [
                {
                  path: '',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_MY_EVENTS']}>
                      <LifeEventDetails />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'edit',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_MY_EVENTS']}>
                      <EditLifeEventDetails />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: 'onboardingrecords',
              children: [
                {
                  path: 'edit',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_MY_ONBOARDING_RECORDS']}>
                      <EditOnboardingPage />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: '',
                  element: (
                    <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_MY_ONBOARDING_RECORDS']}>
                      <ViewOnboardingRecord />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: 'modify-password',
              element: (
                <RoleBasedGuard permissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_CHANGE_MY_PASSWORD']}>
                  <ModifyPassword />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: '*',
          element: (
            <RoleBasedGuard permissions={[]}>
              <UnderDevelopment />
            </RoleBasedGuard>
          ),
        },
        {
          path: '/',
          children: [
            {
              path: 'dashboard',
              element: <RoleBasedGuard permissions={[]}>Home dashboard</RoleBasedGuard>,
            },
          ],
        },
      ],
    },
    {
      path: '/',
      element: !isLoggedIn ? (
        <AlertContextProvider>
          <Outlet />
        </AlertContextProvider>
      ) : (
        <Navigate to="/home/dashboard" />
      ),
      children: [
        { path: 'restIndex/resetMyPassword', element: <EditPassword /> },
        {
          path: 'restLeaveIndex/approveExternalLeaveRequestAction',
          element: <LeaveActionPages requestType="approve" />,
        },
        { path: 'restLeaveIndex/rejectExternalLeaveRequestAction', element: <LeaveActionPages requestType="reject" /> },
        { path: 'login', element: <Login /> },
        { path: 'reset', element: <ResetPassword /> },
        { path: '/', element: <Navigate to="/login" /> },
      ],
    },
  ];
};

export default routes;
