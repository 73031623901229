import { createAsyncThunk } from '@reduxjs/toolkit';

export const asyncThunkWrapper = (type, apiRequest) => {
  const finalResponse = createAsyncThunk(type, async (query, { rejectWithValue }) => {
    try {
      const response = await apiRequest(query);
      return response;
    } catch (err) {
      if (!err?.response) {
        throw err;
      }
      return rejectWithValue(err?.response?.data);
    }
  });
  return finalResponse;
};
