import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import MuiTab from 'components/Molecules/Tab';
import Typography from 'components/Atoms/Typography';
import AuditTrail from './AuditTrail';

const AuditTabs = ({ auditData }) => {
  const initialTabs = [
    {
      label: 'Approval Trail',
      onChange: () => {},
      RenderComponent: AuditTrail,
      componentProps: {
        auditTrailLists: auditData.approvalAuditTrailsList,
      },
    },
    {
      label: 'Audit Trail',
      onChange: () => {},
      RenderComponent: AuditTrail,
      componentProps: {
        auditTrailLists: auditData.auditTrailsList,
      },
    },
  ];

  return (
    <Box>
      <Typography variant="body1" fontWeight={600} sx={{ mb: 1.5, padding: '0rem 3rem' }}>
        Audit Log
      </Typography>
      <MuiTab
        key={initialTabs}
        orientation="horizontal"
        variant="scrollable"
        textColor="primary"
        indicatorColor="primary"
        tabs={initialTabs}
        allowScrollButtonsMobile
        panelPadding="2rem 3rem"
      />
    </Box>
  );
};

AuditTabs.defaultProps = {
  auditData: {},
};

AuditTabs.propTypes = {
  auditData: PropTypes.shape({
    approvalAuditTrailsList: PropTypes.arrayOf,
    auditTrailsList: PropTypes.arrayOf,
  }),
};

export default AuditTabs;
