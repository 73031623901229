import React, { useEffect } from 'react';
import SeparationsEmpExitDetails from 'components/Templates/Employee/Company/Separation/ExitDetailsPerEmployee';
import DeleteSeparation from 'components/Organisms/Dialog/ActionList/DeleteSeparation';
import { useThunk } from 'hooks/useThunk';
import { fetchEmployeeExitDetails } from 'store';
import { useSelector } from 'react-redux';
import { API_URL } from 'api/api';
import { Box, Skeleton, Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { validateDateElseHyphen } from 'utils/commonUtils';
import useSeparationsService from '../List/useSeparationsService';

const EmployeeExitDetailsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const services = useSeparationsService();

  const validateDateFormat = (val) => validateDateElseHyphen(val, 'DD MMMM YYYY');

  const [getEmployeeExitDetails, isLoadingEmployeeExitDetails, loadingEmployeeExitDetailsError] =
    useThunk(fetchEmployeeExitDetails);

  useEffect(() => {
    const query = new URLSearchParams({});
    query.append('id', id);
    getEmployeeExitDetails(query);
  }, [id, getEmployeeExitDetails]);

  const { employeeDetails, employeeProfileInstance, commentsMap, commentsMapList } = useSelector(
    (state) => state.separations,
  );

  const exitDetails = {
    // Basic Details:
    employeeName: employeeDetails?.employee?.fullName || '-',
    employeeID: employeeProfileInstance?.employeeID || '-',
    separationType: employeeProfileInstance?.seperationType || '-',
    noticePeriod: `${employeeProfileInstance?.noticePeriod || '0'} ${
      employeeProfileInstance?.noticePeriodTimeUnit || ''
    }`,
    resignationStatus: employeeDetails?.resignationStatus,
    submissionDate: validateDateFormat(employeeDetails?.resignationSubmissionDate),
    lastWorkingDayAsPerPolicy: validateDateFormat(employeeDetails?.systemComputedLWD),
    requestedRelievingDate: validateDateFormat(employeeDetails?.requestedRelievingDate),
    supervisorApprovedLWD: validateDateFormat(employeeDetails?.supervisorApprovedLWD),
    resignationReasons: employeeDetails?.resignationReasons || '-',
    dateOfLeaving: validateDateFormat(employeeProfileInstance?.dateOfLeaving),
    // Supervisor Approval Details:
    acceptingManager: employeeDetails?.resignationAcceptingManager?.fullName || '-',
    acceptanceDate: validateDateFormat(employeeDetails?.resignationAcceptanceDate),
    resignationAcceptanceComments: employeeDetails?.resignationAcceptanceComments || '-',
    // HR Approval Details:
    hrResignationAcceptedBy: employeeDetails?.hrResignationAcceptedBy?.fullName || '-',
    hrResignationAcceptanceDate: validateDateFormat(employeeDetails?.hrResignationAcceptanceDate),
    hrAcceptanceComments: employeeDetails?.hrAcceptanceComments || '-',
    // Other Details:
    interviewDate: validateDateFormat(employeeDetails?.exitInterviewDate),
    interviewer: employeeDetails?.exitInterviewer?.fullName || '-',
    isFinalSettlementCompleted: employeeDetails?.employee?.profile?.isFinalSettlementCompleted ? 'Yes' : 'No',
    settlementDate: validateDateFormat(employeeProfileInstance?.finalSettlementDate),
    eligibleRehire: employeeDetails?.eligibleForRehire ? 'Yes' : 'No',
    relievingDate: validateDateFormat(employeeDetails?.approvedLWD),
  };

  const getActionCreatorDetails = (comment) => {
    const commentsActionCreatorInstance = Object.keys(commentsMap)?.find((item) => item.includes(comment?.id));

    return {
      fullName: commentsMap?.[`${commentsActionCreatorInstance}`]?.fullName || '',
      avatarSrc: `${API_URL}/${commentsMap?.[`${commentsActionCreatorInstance}`]?.profile?.profilePic}`,
    };
  };

  const auditLogData = commentsMapList?.map((item) => ({
    avatarSrc: getActionCreatorDetails(item)?.avatarSrc,
    description: getActionCreatorDetails(item)?.fullName,
    content: item?.content?.split('<br />'),
    date: item?.createdOn,
  }));

  const handleEdit = () => {
    navigate(`/myteam/separations/edit/${id}`);
  };

  if (isLoadingEmployeeExitDetails) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (loadingEmployeeExitDetailsError !== null) {
    return (
      <Typography variant="h6">
        {loadingEmployeeExitDetailsError?.message || 'Error while loading employee exit details.'}
      </Typography>
    );
  }

  return (
    <Box>
      <SeparationsEmpExitDetails
        handleDelete={services.handleDeleteDialog}
        handleEdit={handleEdit}
        exitDetails={exitDetails}
        auditLogData={auditLogData}
        lockStatus={employeeDetails?.lockStatus}
        loading={isLoadingEmployeeExitDetails}
        pageHeading="Employee Exit Details"
      />
      {employeeDetails !== null && employeeDetails?.lockStatus === 'UnLocked' && (
        <DeleteSeparation
          open={services.showDeleteDialog}
          handleClose={services.handleDeleteDialog}
          handleCancel={services.handleDeleteDialog}
          handleComment={() => {
            services.deleteSeparation(employeeDetails?.id);
          }}
        />
      )}
    </Box>
  );
};

export default EmployeeExitDetailsPage;
