import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import Button from 'components/Atoms/Button';
import EditIcon from '@mui/icons-material/Edit';
import TitleWithText from 'components/Atoms/TitleWithText';
import NoRecordsMessage from 'components/Organisms/CommonPageUtils/NoRecordsFound';
import Typographys from 'components/Atoms/Typography';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { hasPermission } from 'utils/permissions';
import { useSelector } from 'react-redux';
import { profileStyle } from '../style';

const ProfileBankAccount = ({ bankDetails, handleEdit, isEditPermissionAvailable }) => {
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasEditPermission = hasPermission(authorities, [
    'ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_BANK_ACCOUNTS',
  ]);

  return (
    <>
      {isEditPermissionAvailable && (
        <Box sx={profileStyle.actionBtn}>
          {!!userHasEditPermission && (
            <Button startIcon={<EditIcon fontSize="2.5rem" />} onClick={handleEdit}>
              {bankDetails.length > 0 ? 'Edit' : 'Add'}
            </Button>
          )}
        </Box>
      )}

      {bankDetails.length > 0 ? (
        <PerfectScrollbar>
          <Box sx={{ maxHeight: 'calc(100vh - 38rem)' }}>
            <Box mt={2}>
              {bankDetails?.map((list) => (
                <Box sx={profileStyle.formDivider}>
                  <Typographys variant="h5" color="neutral.700">
                    Account Details
                  </Typographys>
                  <Grid container rowSpacing={1} columnSpacing={2}>
                    <Grid item xs={12} sm={4} md={3}>
                      <TitleWithText title="Account Number" text={list?.accountNumber} />
                    </Grid>

                    <Grid item xs={12} sm={4} md={3}>
                      <TitleWithText title="Account Name" text={list?.accountHolderName} />
                    </Grid>

                    <Grid item xs={12} sm={4} md={3}>
                      <TitleWithText title="Account Type" text={list?.accountType} />
                    </Grid>

                    <Grid item xs={12} sm={4} md={3}>
                      <TitleWithText title="Account Currency" text={list?.currency?.name} />
                    </Grid>

                    <Grid item xs={12} sm={4} md={3}>
                      <TitleWithText title="Purpose" text={list?.accountPurpose} />
                    </Grid>

                    <Grid item xs={12} sm={4} md={3}>
                      <TitleWithText title="Transaction type" text={list?.preferredTransactionType} />
                    </Grid>

                    <Grid item xs={12} sm={4} md={3}>
                      <TitleWithText title="Bank Name" text={list?.bankName} />
                    </Grid>

                    <Grid item xs={12} sm={4} md={3}>
                      <TitleWithText title="Branch name" text={list?.bankBranch} />
                    </Grid>

                    <Grid item xs={12} sm={4} md={3}>
                      <TitleWithText title="IFSC Code" text={list?.ifsCode} />
                    </Grid>

                    <Grid item xs={12} sm={4} md={3}>
                      <TitleWithText title="Swift Code (Optional)" text={list?.swiftCode} />
                    </Grid>
                  </Grid>
                  {list?.bankAddress && (
                    <Box>
                      <Grid item xs={12} mt={3.5}>
                        <Typographys variant="h5" color="neutral.700">
                          Branch Address
                        </Typographys>
                      </Grid>

                      <Grid item xs={12}>
                        <TitleWithText title="Location Name (Optional)" />
                        <Typographys color="neutral.700" variant="body2">
                          {list?.bankAddress?.locationName}
                        </Typographys>
                      </Grid>

                      <Grid item xs={12}>
                        <Typographys color="neutral.600" variant="body2">
                          Address
                        </Typographys>

                        <Typographys color="neutral.700" variant="body2">
                          {list?.bankAddress?.addressLine1}
                        </Typographys>

                        <Typographys color="neutral.700" variant="body2">
                          {list?.bankAddress?.addressLine2}
                        </Typographys>

                        <Typographys color="neutral.700" variant="body2">
                          {list?.bankAddress?.addressLine3}
                        </Typographys>

                        <Typographys color="neutral.700" variant="body2">
                          <Box component="div" sx={profileStyle.addressDiv}>
                            {list?.bankAddress?.city}
                          </Box>

                          <Box component="div" sx={profileStyle.addressDiv}>
                            {list?.bankAddress?.state}
                          </Box>

                          <Box component="div" sx={profileStyle.addressDiv}>
                            {list?.bankAddress?.country}
                          </Box>

                          <Box component="div" sx={profileStyle.addressDiv}>
                            {list?.bankAddress?.pinOrZip}
                          </Box>
                        </Typographys>
                      </Grid>
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        </PerfectScrollbar>
      ) : (
        <NoRecordsMessage width="20rem" height="20rem" />
      )}
    </>
  );
};

ProfileBankAccount.defaultProps = {
  bankDetails: [],
  addressList: {},
  handleEdit: () => {},
  isEditPermissionAvailable: true,
};

ProfileBankAccount.propTypes = {
  bankDetails: PropTypes.arrayOf(
    PropTypes.shape({
      accountNumber: PropTypes.string,
      accountHolderName: PropTypes.string,
      accountType: PropTypes.string,
      accountCurrency: PropTypes.string,
      accountPurpose: PropTypes.string,
      preferredTransactionType: PropTypes.string,
      bankName: PropTypes.string,
      branchName: PropTypes.string,
      ifsCode: PropTypes.string,
      swiftCode: PropTypes.string,
    }),
  ),
  addressList: PropTypes.shape({
    locationName: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    addressLine3: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    state: PropTypes.string,
    pinOrZip: PropTypes.string,
  }),
  handleEdit: PropTypes.func,
  isEditPermissionAvailable: PropTypes.bool,
};

export default ProfileBankAccount;
