import { createSlice } from '@reduxjs/toolkit';

const customization = createSlice({
  name: 'customization',
  initialState: {
    isOpen: [],
    leftDrawerOpen: false,
    selectedModule: 'home',
  },
  reducers: {
    setLeftDrawerOpen(state, action) {
      state.leftDrawerOpen = action.payload;
    },
    updateOpenedMenu(state, action) {
      state.isOpen = [action.payload];
    },
    updateSelectedModule(state, action) {
      state.selectedModule = action.payload;
    },
  },
});

export const { setLeftDrawerOpen, updateOpenedMenu, updateSelectedModule } = customization.actions;

export default customization.reducer;
