import React from 'react';
import { Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PropTypes from 'prop-types';
import Typography from '../Typography';
import { attachmentStyle } from '../Attachments/style';

const AttachmentsUpload = ({
  handleChange,
  value,
  name,
  id,
  handleDownloadFile,
  handleDelete,
  isDeleteIcon,
  accept,
  wrapperStyles = {},
  title,
}) => {
  return (
    <Box sx={{ ...attachmentStyle.wrapper, ...wrapperStyles }}>
      <Box>
        <Button
          variant="contained"
          color="primary"
          component="label"
          sx={{ mx: 5, width: 'max-content', marginLeft: '3rem', marginRight: '3rem' }}
          startIcon={<CloudUploadIcon />}
        >
          <Typography variant="body1" color="secondary.100">
            {title || 'Upload File'}
          </Typography>
          <input
            name={name}
            id={id}
            hidden
            accept={accept}
            multiple
            type="file"
            onChange={handleChange}
            style={{ cursor: 'pointer' }}
          />
        </Button>
      </Box>
      {(value?.name || value?.fileName) && (
        <Box sx={{ display: 'flex', paddingLeft: '0.2rem', paddingRight: '2rem' }}>
          <Button
            sx={{ fontWeight: '500', maxWidth: '100px' }}
            variant="text"
            color="secondary"
            onClick={() => {
              handleDownloadFile(value);
            }}
          >
            {value?.name || value?.fileName}
          </Button>
          {isDeleteIcon && <DeleteIcon size={18} onClick={handleDelete} color="action" />}
        </Box>
      )}
    </Box>
  );
};

AttachmentsUpload.defaultProps = {
  isDeleteIcon: false,
  handleDelete: () => {},
  handleDownloadFile: () => {},
  handleChange: () => {},
  value: '',
  name: '',
  id: '',
  accept: '',
  wrapperStyles: {},
  title: 'Upload File',
};

AttachmentsUpload.propTypes = {
  isDeleteIcon: PropTypes.bool,
  handleDelete: PropTypes.func,
  handleDownloadFile: PropTypes.func,
  handleChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  accept: PropTypes.string,
  wrapperStyles: PropTypes.shape({}),
  title: PropTypes.string,
};

export default AttachmentsUpload;
