import { formDataApi } from 'api/api';

export const getmyLeaveDashboard = async () => {
  const response = await formDataApi.get(`restSentinel/myLeaveDashboard`);
  return response;
};

export const getemployeeEventData = async () => {
  const response = await formDataApi.get(`restSentinel/employeeEventData`);
  return response;
};

export const getdocumentAndProjectData = async () => {
  const response = await formDataApi.get(`restSentinel/documentAndProjectData`);
  return response;
};

export const getProfileSalaryDetails = async () => {
  const response = await formDataApi.get(`/restSentinel/mySalaryDashboard`);
  return response;
};

export const getMyLeavesDashboardData = async () => {
  const response = await formDataApi.get('restSentinel/myLeaveDashboard');
  return response;
};

export const getemployeeLoginData = async () => {
  const response = await formDataApi.get('RestSentinel/employeeLoginDetails');
  return response;
};

export const getTeamDashboardDetails = async () => {
  const response = await formDataApi.get('restSentinel/manTeamGen');
  return response;
};

export const getAnnouncementDetails = async (formData) => {
  const response = await formDataApi.post('restUtilIndex/displayAnnouncement', formData);
  return response;
};

export const getSurveyDetails = async (formData) => {
  const response = await formDataApi.post('restSurveyIndex/completeSurvey', formData);
  return response;
};

export const completeSurveyAction = async (formData) => {
  const response = await formDataApi.post('restSurveyIndex/completeSurveyAction', formData);
  return response;
};
