import { useSelector } from 'react-redux';

import { hasPermission } from 'utils/permissions';

const getActionItems = (services) => {
  return [
    {
      id: 1,
      label: 'Delete',
      onClick: services.handleDeletePolicy,
      icon: '',
      permissions: [],
    },
  ];
};

const useActionItems = (services) => {
  const { user } = useSelector((state) => state?.session);

  const actions = getActionItems(services);

  // const filteredActionItem = actions.filter((action) => hasPermission([], action.permissions));

  return [actions];
};

export default useActionItems;
