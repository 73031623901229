import React from 'react';
import { Typography } from '@mui/material';

const columns = [
  {
    fieldName: 'SN',
    name: 'i',
    label: 'SN.',
    type: 'number',
    formatValue: ({ row, column }) => {
      return <Typography variant="body2">{row[column.fieldName]}</Typography>;
    },
  },
  {
    fieldName: 'empId',
    name: 'dn',
    label: 'EMP ID',
    type: 'string',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'empName',
    name: 'dn',
    label: 'EMPLOYEE NAME',
    type: 'string',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'salarySlip',
    name: 'pt',
    label: 'SALARY SLIP',
    type: 'string',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'amountDeducted',
    name: 'el',
    label: 'AMOUNT DEDUCTED FROM SALARY',
    type: 'string',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
  {
    fieldName: 'modeOfPayment',
    name: 'et',
    label: 'AMOUNT REMITTED',
    type: 'string',
    formatValue: ({ row, column }) => <Typography variant="body2">{row[column.fieldName]}</Typography>,
  },
];

const useAllocationColumns = () => {
  return [columns];
};

export default useAllocationColumns;
