import React from 'react';
import PropTypes from 'prop-types';
import PieCharts from 'components/Organisms/Charts/PieCharts';
import GraphColor from 'utils/GraphColor';

const LeaveChart = ({ leaveData, colors }) => {
  const legendOptions = {
    layout: 'vertical',
    verticalAlign: 'middle',
    align: 'right',
    legendLeftSpacing: '3rem',
    iconWidth: '12px',
    iconHeight: '12px',
    iconType: 'circle',
  };
  const chartOptions = {
    chartWidth: 360,
    chartHeight: 175,
    paddingAngle: 0,
    innerRadius: 60,
    outerRadius: 82,
    cx: '',
    cy: '',
  };
  return (
    <PieCharts
      data={leaveData}
      colors={colors}
      legendOptions={legendOptions}
      chartOptions={chartOptions}
      isShowCustomLegend
      isLegendTitle
      legendTitle="Type"
    />
  );
};

LeaveChart.defaultProps = {
  leaveData: [],
  colors: GraphColor('leaveChart'),
};
LeaveChart.propTypes = {
  leaveData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  colors: PropTypes.arrayOf(PropTypes.string),
};

export default LeaveChart;
