import { createSlice } from '@reduxjs/toolkit';
import {
  fetchEmployees,
  searchEmployees,
  viewEmployees,
  deleteEmployees,
  deActivateEmployees,
  activateEmployees,
  lockEmployees,
  unLockEmployees,
  convertToCandidate,
  sendESSInvite,
  fetchTransferEmployee,
  fetchPromoteEmployee,
  fetchDemoteEmployee,
  fetchConfirmEmployee,
  fetchExtendProbation,
  fetchTerminateEmployee,
  fetchVerifyBackground,
  deleteLCRecords,
  fetchLCRecords,
  searchLCRecords,
  viewLCRecords,
  fetchInitiatePIP,
  fetchConcludePIP,
  fetchLCRecordsPendingMyApproval,
} from 'store/thunks/company/employees';

const employees = createSlice({
  name: 'employees',
  initialState: {
    selectedEmployee: null,
    selectedEmployees: [],
    employees: {
      count: 0,
      data: [],
    },
    selectedLCRecord: null,
    selectedLCRecords: [],
    lifeCycleCaseRecords: {
      count: 0,
      data: [],
    },
    transferEmployee: [],
    promoteEmployee: [],
    demoteEmployee: [],
    confirmEmployee: [],
    extendProbation: [],
    terminateEmployee: [],
    verifyBackground: [],
    initiatePIP: [],
    concludePIP: [],
  },
  reducers: {
    updateSelectedEmployees(state, action) {
      const { isChecked, rowId } = action.payload;

      if (isChecked) {
        state.selectedEmployees.push(rowId);
        return;
      }
      state.selectedEmployees = state.selectedEmployees.filter((selectedRow) => selectedRow !== rowId);
    },
    updateAllSelectedEmployees(state, action) {
      const { isChecked } = action.payload;
      state.selectedEmployees = [];
      if (isChecked) {
        state.employees.data.map((row) => state.selectedEmployees.push(row.id));
        return;
      }
      state.selectedEmployees = [];
    },
    updateSelectedEmployee(state, action) {
      state.selectedEmployee = action.payload;
    },
    updateSelectedLCRecords(state, action) {
      const { isChecked, rowId } = action.payload;

      if (isChecked) {
        state.selectedLCRecords.push(rowId);
        return;
      }
      state.selectedLCRecords = state.selectedLCRecords.filter((selectedRow) => selectedRow !== rowId);
    },
    updateAllSelectedLCRecords(state, action) {
      const { isChecked } = action.payload;
      state.selectedLCRecords = [];
      if (isChecked) {
        state.lifeCycleCaseRecords.data.map((row) => state.selectedLCRecords.push(row.id));
        return;
      }
      state.selectedLCRecords = [];
    },
    updateSelectedLCRecord(state, action) {
      state.selectedLCRecord = action.payload;
    },
    updateLCRecords(state, action) {
      const { lifeCycleCaseRecordsInstanceList = [], totalCount } = action.payload;
      state.lifeCycleCaseRecords.data = lifeCycleCaseRecordsInstanceList;
      state.lifeCycleCaseRecords.count = totalCount;
    },
  },

  extraReducers(builder) {
    builder.addCase(fetchEmployees.fulfilled, (state, action) => {
      const { EmployeeInstanceList = [], EmployeeInstanceCount } = action.payload;
      state.employees.data = EmployeeInstanceList;
      state.employees.count = EmployeeInstanceCount;
    });
    builder.addCase(searchEmployees.fulfilled, (state, action) => {});
    builder.addCase(viewEmployees.fulfilled, (state, action) => {});
    builder.addCase(deleteEmployees.fulfilled, (state, action) => {});
    builder.addCase(unLockEmployees.fulfilled, (state, action) => {});
    builder.addCase(lockEmployees.fulfilled, (state, action) => {});
    builder.addCase(activateEmployees.fulfilled, (state, action) => {});
    builder.addCase(deActivateEmployees.fulfilled, (state, action) => {});
    builder.addCase(convertToCandidate.fulfilled, (state, action) => {});
    builder.addCase(sendESSInvite.fulfilled, (state, action) => {});
    builder.addCase(fetchConfirmEmployee.fulfilled, (state, action) => {
      const { employeeInstance = [] } = action.payload;
      state.confirmEmployee = employeeInstance;
    });
    builder.addCase(fetchDemoteEmployee.fulfilled, (state, action) => {
      state.demoteEmployee = action.payload;
    });
    builder.addCase(fetchPromoteEmployee.fulfilled, (state, action) => {
      state.promoteEmployee = action.payload;
    });
    builder.addCase(fetchTransferEmployee.fulfilled, (state, action) => {
      state.transferEmployee = action.payload;
    });
    builder.addCase(fetchTerminateEmployee.fulfilled, (state, action) => {
      const { employeeInstance = [] } = action.payload;
      state.terminateEmployee = employeeInstance;
    });
    builder.addCase(fetchLCRecords.fulfilled, (state, action) => {
      const { lifeCycleCaseRecordInstanceList = [], lifeCycleCaseRecordInstanceCount } = action.payload;
      state.lifeCycleCaseRecords.data = lifeCycleCaseRecordInstanceList;
      state.lifeCycleCaseRecords.count = lifeCycleCaseRecordInstanceCount;
    });
    builder.addCase(searchLCRecords.fulfilled, (state, action) => {});
    builder.addCase(viewLCRecords.fulfilled, (state, action) => {});
    builder.addCase(deleteLCRecords.fulfilled, (state, action) => {});
    builder.addCase(fetchExtendProbation.fulfilled, (state, action) => {
      state.extendProbation = action.payload;
    });
    builder.addCase(fetchVerifyBackground.fulfilled, (state, action) => {
      const { employeeInstance = [] } = action.payload;
      state.verifyBackground = employeeInstance;
    });
    builder.addCase(fetchInitiatePIP.fulfilled, (state, action) => {
      state.initiatePIP = action.payload;
    });
    builder.addCase(fetchConcludePIP.fulfilled, (state, action) => {
      state.concludePIP = action.payload;
    });
    builder.addCase(fetchLCRecordsPendingMyApproval.fulfilled, (state, action) => {
      const { lifeCycleCaseRecordInstanceList = [], lifeCycleCaseRecordInstanceCount } = action.payload;
      state.lifeCycleCaseRecords.data = lifeCycleCaseRecordInstanceList;
      state.lifeCycleCaseRecords.count = lifeCycleCaseRecordInstanceCount;
    });
  },
});

export const {
  updateSelectedEmployee,
  updateSelectedEmployees,
  updateAllSelectedEmployees,
  updateSelectedLCRecords,
  updateAllSelectedLCRecords,
  updateSelectedLCRecord,
  updateLCRecords,
} = employees.actions;

export default employees.reducer;
