import React, { useEffect, useState } from 'react';
import EmployeeExitDetailsTemplate from 'components/Templates/Profile/InitiateSeparation/EmployeeExitDetails';
import { Box, Skeleton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fetchDisplayEmpExitDetails } from 'store';
import { API_URL } from 'api/api';
import { useThunk } from 'hooks/useThunk';
import useToast from 'components/Provider/useToast';
import { validateElseHyphen, validateDateElseHyphen } from 'utils/commonUtils';
import Dialog from 'components/Molecules/Dialog';
import { hasPermission } from 'utils/permissions';
import { myExitWithdrawDetails } from 'api/profile';

const ViewSeparation = () => {
  const addToast = useToast();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const authorities = useSelector((state) => state?.session?.user?.authorities);
  const userHasWithdrawPermission = hasPermission(authorities, [
    'ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_WITHDRAW_MY_EMPLOYEE_EXIT',
  ]);

  const [displayEmpExitDetails, isLoadingDisplayEmpExitDetails, loadingErrorDisplayEmpExitDetails] =
    useThunk(fetchDisplayEmpExitDetails);

  const { displayExitDetails } = useSelector((state) => {
    return state.profile;
  });

  useEffect(() => {
    const query = new URLSearchParams({});
    displayEmpExitDetails(query);
  }, [displayEmpExitDetails]);

  if (isLoadingDisplayEmpExitDetails) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (loadingErrorDisplayEmpExitDetails) {
    return (
      <Typography variant="h6">
        {loadingErrorDisplayEmpExitDetails?.message || 'Error while fetching employee exit details.'}
      </Typography>
    );
  }

  const {
    employeeProfileInstance = {},
    employeeExitInstance = {},
    employeeInstance = {},
    commentsMap = {},
    commentsMapList = {},
  } = displayExitDetails;
  const handleEdit = () => {
    navigate(`/home/separation/view-separation/edit/${employeeExitInstance?.id}`);
  };

  let exitDetails = {};

  let auditLogData = [];

  if (displayExitDetails?.status === 'WARNING' || displayExitDetails?.status !== 'SUCCESS') {
    return <h3>{displayExitDetails.message}</h3>;
  }

  const getActionCreatorDetails = (comment) => {
    const commentsActionCreatorInstance = Object.keys(commentsMap)?.find((item) => item.includes(comment?.id));

    return {
      fullName: commentsMap?.[`${commentsActionCreatorInstance}`]?.fullName || '',
      avatarSrc: `${API_URL}/${commentsMap?.[`${commentsActionCreatorInstance}`]?.profile?.profilePic}`,
    };
  };

  if (displayExitDetails?.status === 'SUCCESS') {
    exitDetails = {
      employeeName: `${employeeProfileInstance?.employee?.fullName} (${employeeProfileInstance?.employeeID})`,
      separationType: validateElseHyphen(employeeProfileInstance?.seperationType),
      noticePeriod: `${employeeProfileInstance?.noticePeriod || '0'} ${
        employeeProfileInstance?.noticePeriodTimeUnit || ''
      }`,
      resignationStatus: validateElseHyphen(employeeExitInstance?.resignationStatus),
      submissionDate: validateDateElseHyphen(employeeExitInstance?.resignationSubmissionDate, 'MMM DD, YYYY'),
      lastWorkingDay: validateDateElseHyphen(employeeExitInstance?.systemComputedLWD, 'MMM DD, YYYY'),
      requestedRelievingDate: validateDateElseHyphen(employeeExitInstance?.requestedRelievingDate, 'MMM DD, YYYY'),
      approvedRelievingDate: validateDateElseHyphen(employeeExitInstance?.approvedLWD, 'MMM DD, YYYY'),
      resignationReasons: validateElseHyphen(employeeExitInstance?.resignationReasons),
      resignationAcceptingSupervisor: validateElseHyphen(employeeExitInstance?.resignationAcceptingManager?.fullName),
      supervisorResignationAcceptanceDate: validateDateElseHyphen(
        employeeExitInstance?.resignationAcceptanceDate,
        'MMM DD, YYYY',
      ),
      resignationAcceptingHR: validateElseHyphen(employeeInstance?.exitDetails?.resignationAcceptingManager?.fullName),
      HRResignationAcceptanceDate: validateDateElseHyphen(
        employeeExitInstance?.hrResignationAcceptanceDate,
        'MMM DD, YYYY',
      ),
      exitInterviewDate: validateDateElseHyphen(employeeExitInstance?.exitInterviewDate, 'MMM DD, YYYY'),
      exitInterviewer: validateElseHyphen(employeeExitInstance?.exitInterviewer?.fullName),
    };

    auditLogData = commentsMapList?.map((item) => ({
      avatarSrc: getActionCreatorDetails(item)?.avatarSrc,
      description: getActionCreatorDetails(item)?.fullName,
      content: item?.content?.split('<br />'),
      date: item?.createdOn,
    }));
  } else {
    addToast({
      type: 'error',
      title: 'ERROR',
      message: 'Something went wrong, try again',
    });
  }

  const handleWithdrawSeparationButton = () => {
    setOpenDialog(true);
  };

  const handleYesButton = async () => {
    const data = await myExitWithdrawDetails();
    if (data?.status === 'SUCCESS') {
      addToast({
        type: 'success',
        title: 'SUCCESS',
        message: data?.message || 'Successfully actioned employee exit.',
      });
      navigate('/home/separation/initiate-separation');
    } else {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: data?.message || 'Something went wrong, try again',
      });
    }

    setOpenDialog(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <Box>
      <EmployeeExitDetailsTemplate
        exitDetails={exitDetails}
        handleEdit={handleEdit}
        auditLogData={auditLogData}
        handleWithdrawSeparation={handleWithdrawSeparationButton}
      />
      <Dialog
        open={openDialog}
        handleClose={handleDialogClose}
        dialogTitle="Confirm Withdraw Separation"
        bodyContent="Are you sure to withdraw the separation request?"
        secondaryBtn="No"
        primaryBtn="Yes"
        handlePrimaryBtn={handleYesButton}
        handleSecondaryBtn={handleDialogClose}
      />
    </Box>
  );
};

export default ViewSeparation;
