import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, useTheme } from '@mui/material';
import Typography from 'components/Atoms/Typography';
import Divider from '@mui/material/Divider';
import { useFormik } from 'formik';
import Button from 'components/Atoms/Button';
import DownloadIcon from '@mui/icons-material/Download';
import CodeEditor from 'components/Molecules/CodeEditor';
import { validateElseHyphen } from 'utils/commonUtils';
import { empRecordActionDetailStyle } from 'components/Organisms/Employee/EmployeeRecord/ActionList/style';

const OfferAcceptance = ({ candidateData, handleDownload, handleSubmit, setDialog }) => {
  const theme = useTheme();

  const formik = useFormik({
    initialValues: { comments: '' },
    onSubmit: handleSubmit,
  });

  return (
    <Box sx={{ padding: '2rem 3rem' }}>
      <Box>
        <Typography color="neutral.600" variant="body2" sx={{ mb: 1.5 }}>
          Dear <b>{candidateData?.fullName}</b>,
          <br />
          <br />
          <b>Hearty Congratulations!!</b> We are thrilled to offer you the role of
          <b> {validateElseHyphen(candidateData?.designation)}</b> at
          <b> {validateElseHyphen(candidateData?.companyName)}</b> pursuant to our conversations thus far. You can
          download your offer using the &#39;Download Offer&#39; link below. Should you have any questions regarding
          your offer, please feel free to get in touch with your HR partner.
          <br />
          <br />
          As a next step, we request you to accept the offer by selecting the &#39;Accept&#39; offer button below. We
          look forward to your acceptance and having you onboard on an exciting journey ahead!
        </Typography>
        <br />
        <Typography color="neutral.600" variant="body2">
          <b>Team HR</b>
        </Typography>
        {candidateData?.companyName && (
          <Typography color="neutral.600" variant="body2">
            <b>{candidateData?.companyName || ''}</b>
          </Typography>
        )}
        <Box sx={{ mt: 2.5 }}>
          <Button
            startIcon={<DownloadIcon fontSize="2.5rem" />}
            size="large"
            color="primary"
            variant="outlined"
            onClick={handleDownload}
          >
            Download Offer
          </Button>
        </Box>
        <Divider sx={{ mt: 2.5, mb: 2.5 }} />
        <Box component="form" onSubmit={formik.handleSubmit}>
          <Grid item sm={12}>
            <CodeEditor
              error={Boolean(formik.touched.comments && formik.errors.comments)}
              helperText={formik.touched.comments && formik.errors.comments}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.comments}
              name="comments"
              id="comments"
              label="Comments"
            />
          </Grid>
        </Box>
        <Box sx={empRecordActionDetailStyle.btnContainer}>
          <Divider sx={{ my: 2, borderColor: theme.palette.other[200] }} />
          <Box sx={empRecordActionDetailStyle.submitWrapper}>
            <Button
              size="large"
              color="primary"
              variant="outlined"
              type="button"
              onClick={() => {
                setDialog('reject');
                formik.setFieldValue('offerType', 'reject');
                formik.handleSubmit();
              }}
            >
              Reject offer
            </Button>
            <Button
              size="large"
              color="primary"
              variant="contained"
              type="submit"
              onClick={() => {
                setDialog('accept');
                formik.setFieldValue('offerType', 'accept');
                formik.handleSubmit();
              }}
            >
              Accept offer
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

OfferAcceptance.defaultProps = {
  candidateData: {
    fullName: '',
    designation: '',
    companyName: '',
  },
  handleDownload: () => {},
  handleSubmit: () => {},
  setDialog: () => {},
};
OfferAcceptance.propTypes = {
  candidateData: PropTypes.shape({
    fullName: PropTypes.string,
    designation: PropTypes.string,
    companyName: PropTypes.string,
  }),
  handleDownload: PropTypes.func,
  handleSubmit: PropTypes.func,
  setDialog: PropTypes.func,
};

export default OfferAcceptance;
