import React from 'react';
import PropTypes from 'prop-types';
import { StylesProvider, createGenerateClassName } from '@mui/styles';
import { MuiThemeProvider } from '../../design-tokens/theme/theme';
import Header from './TextEditorHeader';

const generateClassname = createGenerateClassName({
  productionPrefix: 'bit-text-editor-header',
  seed: 'bit-text-editor-header',
});

export const TextEditorHeader = ({ avatar, onSelectMenuOption, name, date, showMenuButton, options, dataTestId }) => {
  return (
    <StylesProvider generateClassName={generateClassname}>
      <MuiThemeProvider>
        <Header
          name={name}
          avatar={avatar}
          date={date}
          onSelectMenuOption={onSelectMenuOption}
          showMenuButton={showMenuButton}
          options={options}
          dataTestId={dataTestId}
        />
      </MuiThemeProvider>
    </StylesProvider>
  );
};

TextEditorHeader.defaultProps = {
  showMenuButton: false,
};

TextEditorHeader.propTypes = {
  /** Avatar */
  avatar: PropTypes.string,
  /** Name */
  name: PropTypes.string.isRequired,
  /** Latest date on which note was update */
  date: PropTypes.string,
  /** Callback function, invoke on select option from menu */
  onSelectMenuOption: PropTypes.func,
  /** Will be true, if want to show menu button */
  showMenuButton: PropTypes.bool,
  /** Menu options */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
      isIcon: PropTypes.bool,
      img: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ),
  /** Data test id to perform testing */
  dataTestId: PropTypes.string,
};
