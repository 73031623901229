import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, ButtonBase } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import TimerProgressBar from 'components/Molecules/TimerProgressBar';
import { attendanceStyle } from '../style';

const AttendanceTimerStats = ({ daysData, startTime, endTime }) => {
  const [selectedDay, setSelectedDay] = useState(new Date().getDay());

  const onClickDay = (day) => {
    setSelectedDay(day);
  };

  const selectedProgressarData = daysData[selectedDay];

  return (
    <Box sx={attendanceStyle.wrapper}>
      <Box sx={attendanceStyle.timerWrapper}>
        <Typographys variant="h6">Timer</Typographys>

        <Box sx={attendanceStyle.daysWrapper}>
          {daysData &&
            Object.keys(daysData).map((dayKey) => {
              const { day } = daysData[dayKey];
              const dayNumber = parseInt(dayKey, 10);
              const isActiveDay = dayNumber === selectedDay;
              return (
                <ButtonBase>
                  <Box
                    sx={{
                      ...attendanceStyle.day,
                      ...(isActiveDay && attendanceStyle.activeDay),
                    }}
                    key={day}
                    onClick={() => {
                      onClickDay(dayNumber);
                    }}
                  >
                    <Typographys variant="avatarLetter"> {day.substring(0, 1)} </Typographys>
                  </Box>
                </ButtonBase>
              );
            })}
        </Box>
      </Box>

      <Box sx={attendanceStyle.timerWrapper}>
        <Box mt={3.5}>
          <Typographys variant="body2">
            Today ({startTime} AM - {endTime} PM)
          </Typographys>
        </Box>
      </Box>

      <Box mt={3}>{selectedProgressarData && <TimerProgressBar progressBarList={selectedProgressarData} />}</Box>
    </Box>
  );
};

AttendanceTimerStats.defaultProps = {
  daysData: {},
  startTime: '',
  endTime: '',
};

AttendanceTimerStats.propTypes = {
  daysData: PropTypes.objectOf(
    PropTypes.shape({
      day: PropTypes.string,
      duration: PropTypes.shape({
        hour: PropTypes.number,
        min: PropTypes.number,
      }),

      break: PropTypes.shape({
        hour: PropTypes.number,
        min: PropTypes.number,
      }),

      overtime: PropTypes.shape({
        hour: PropTypes.number,
        min: PropTypes.number,
      }),
    }),
  ),

  startTime: PropTypes.string,
  endTime: PropTypes.string,
};

export default AttendanceTimerStats;
