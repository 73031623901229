import {
  myDocumentsListData,
  myDocumentsDetailsData,
  myDocumentsCreateData,
  teamDocumentListData,
  companyDocumentListData,
  searchMyDocumentsAction,
  searchTeamDocumentsAction,
  searchCompanyDocumentsAction,
  editDocument,
} from 'api/documents';
import { asyncThunkWrapper } from 'utils/AsyncThunkWrapper';

const fetchMyDocumentList = asyncThunkWrapper('documents/fetch', myDocumentsListData);
const fetchMyDocumentDetails = asyncThunkWrapper('documents/details', myDocumentsDetailsData);
const fetchCreateDocument = asyncThunkWrapper('documents/createDocument', myDocumentsCreateData);
const fetchTeamDocumentList = asyncThunkWrapper('documents/teamDocumentList', teamDocumentListData);
const fetchCompanyDocumentList = asyncThunkWrapper('documents/companyDocumentList', companyDocumentListData);
const fetchSearchMyDocumentList = asyncThunkWrapper('documents/searchMyDocumentList', searchMyDocumentsAction);
const fetchSearchTeamDocumentList = asyncThunkWrapper('documents/searchTeamDocumentList', searchTeamDocumentsAction);
const fetchSearchCompanyDocumentList = asyncThunkWrapper(
  'documents/searchCompanyDocumentList',
  searchCompanyDocumentsAction,
);
const fetchEditDocumentDetails = asyncThunkWrapper('myDocuments/editDocumentList', editDocument);

export {
  fetchMyDocumentList,
  fetchMyDocumentDetails,
  fetchCreateDocument,
  fetchTeamDocumentList,
  fetchCompanyDocumentList,
  fetchSearchMyDocumentList,
  fetchSearchTeamDocumentList,
  fetchSearchCompanyDocumentList,
  fetchEditDocumentDetails,
};
