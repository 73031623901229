import React, { useEffect, useState } from 'react';
import TableExtended from 'components/Molecules/TableExtended';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Typographys from 'components/Atoms/Typography';
import Button from 'components/Atoms/Button';
import DropdownMenu from 'components/Molecules/DropdownMenu';
import IconWithTitle from 'components/Atoms/IconWithTitle';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AddIcon from '@mui/icons-material/Add';
import DeleteLCPolicy from 'components/Organisms/Dialog/Employee/DeleteLCPolicy';
import RemoveEmployee from 'components/Organisms/Dialog/Employee/RemoveEmployee';
import FilterButton from 'components/Organisms/TableFilter/FilterButton';
import { policyListStyle } from './style';

const LCPolicyListData = ({
  listFilterFields,
  listHandleFilter,
  empColumns,
  empTotalRecordCount,
  empLoadNextPageData,
  empHandleSort,
  empHandleRowClick,
  empPage,
  empSortBy,
  empSortDirection,
  empHandleSelect,
  empOnSelectedChange,
  allEmployees,
  policyItem,
  handleDetails,
  empActionMenuLists,
  handleAdd,
  handleDelete,
  handleCancel,
  openDeleteLCPolicy,
  handleClose,
  handleDeleteEmployee,
  handleCancelEmployee,
  openEmployee,
  handleCloseEmployee,
  numOfEmployee,
  setIsDeleteLCPolicy,
  selectedItems,
  onSelect,
  onSelectAll,
  showPagination,
  showRowSelector,
  keyColumn,
  loading,
}) => {
  const [isDisable, setIsDisable] = useState(true);
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  useEffect(() => {
    setIsDisable(!selectedEmployees.length);
  }, [selectedEmployees]);

  return (
    <>
      <Box sx={policyListStyle.wrapper}>
        <Box sx={policyListStyle.actionBar}>
          <Typographys variant="h6">{policyItem.heading || policyItem.name}</Typographys>

          <Box display="flex" ml="auto" columnGap={2}>
            <Button size="medium" onClick={() => handleDetails(policyItem)}>
              View Details
            </Button>
            <DropdownMenu
              btnLabel="Action"
              size="medium"
              minWidth="9.5rem"
              maxWidth="9.5rem"
              menuLists={empActionMenuLists}
              setIsDeleteLCPolicy={setIsDeleteLCPolicy}
            />
          </Box>
        </Box>

        <IconWithTitle
          iconElement={<CalendarTodayIcon fontSize="inherit" />}
          title="From Jul 2022 - Today"
          columnGap={1}
          titleColor="neutral.600"
          titleVariant="body1"
        />
        <Box mt={2} sx={policyListStyle.actionBar}>
          <Box display="flex" alignItems="center" columnGap={2}>
            <Typographys variant="h5">Employee List</Typographys>
            <FilterButton listHandleFilter={listHandleFilter} attributeOptions={listFilterFields} />
          </Box>

          <Box display="flex" ml="auto" columnGap={3}>
            <LoadingButton
              loading={loading.changePolicyLoading}
              size="medium"
              variant="outlined"
              onClick={() => handleAdd(selectedEmployees.map((emp) => emp.id))}
              startIcon={<AddIcon />}
            >
              Change Policy Mapping
            </LoadingButton>
          </Box>
        </Box>

        <TableExtended
          loading={loading.empLoading}
          rows={allEmployees}
          columns={empColumns}
          showActionList={false}
          setIsDisable={setIsDisable}
          totalRecordCount={empTotalRecordCount}
          loadNextPageData={empLoadNextPageData}
          handleSort={empHandleSort}
          handleRowClick={empHandleRowClick}
          page={empPage}
          sortBy={empSortBy}
          sortDirection={empSortDirection}
          handleSelect={empHandleSelect}
          selectedItems={selectedItems}
          onSelect={onSelect}
          onSelectAll={onSelectAll}
          showRowSelector={showRowSelector}
          showPagination={showPagination}
          keyColumn={keyColumn}
          onSelectedChange={(d) => {
            empOnSelectedChange(d);
            setSelectedEmployees(d);
          }}
        />
      </Box>

      <DeleteLCPolicy
        handleDelete={() => handleDelete(policyItem)}
        handleCancel={handleCancel}
        open={openDeleteLCPolicy}
        handleClose={handleClose}
      />

      <RemoveEmployee
        handleDelete={() => handleDeleteEmployee(selectedEmployees.map((emp) => emp.id))}
        handleCancel={handleCancelEmployee}
        open={openEmployee}
        handleClose={handleCloseEmployee}
        numOfEmployee={numOfEmployee}
      />
    </>
  );
};
LCPolicyListData.defaultProps = {
  listFilterFields: [
    {
      name: 'Employee ID',
      type: 'number',
    },
    {
      name: 'Employee Name',
      type: 'string',
    },
    {
      name: 'LC Policy',
      type: 'string',
    },
    {
      name: 'Department',
      type: 'string',
    },
    {
      name: 'Designation',
      type: 'string',
    },

    {
      name: 'Location',
      type: 'string',
    },
  ],
  listHandleFilter: () => {},
  policyItem: {},
  empColumns: [
    {
      fieldName: 'employeeId',
      numeric: true,
      label: 'EMPLOYEE ID',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'employeeName',
      numeric: true,
      label: 'EMPLOYEE NAME',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'lcPolicy',
      numeric: false,
      label: 'LC POLICY',
      formatValue: ({ row, column }) => row[column.fieldName],
    },

    {
      fieldName: 'department',
      numeric: true,
      label: 'DEPARTMENT',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'designation',
      numeric: true,
      label: 'DESIGNATION',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'location',
      numeric: true,
      label: 'LOCATION',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
  ],
  empTotalRecordCount: 5,
  empLoadNextPageData: () => {},
  empHandleSort: () => {},
  empHandleRowClick: () => {},
  empPage: 1,
  empSortBy: '',
  empSortDirection: 'asc',
  empHandleSelect: () => {},
  empOnSelectedChange: () => {},
  empActionMenuLists: [
    { id: 1, label: 'Edit', onClick: () => {} },
    { id: 2, label: 'Delete', onClick: () => {} },
  ],

  handleDetails: () => {},
  handleAdd: () => {},
  handleDelete: () => {},
  handleCancel: () => {},
  openDeleteLCPolicy: false,
  handleClose: () => {},
  handleDeleteEmployee: () => {},
  handleCancelEmployee: () => {},
  openEmployee: false,
  handleCloseEmployee: () => {},
  numOfEmployee: 1,
  setIsDeleteLCPolicy: () => {},
  allEmployees: [
    {
      id: 1,
      employeeId: 101,
      employeeName: 'Employee 1',
      lcPolicy: 'Life Cycle Policy 2022',
      department: 'Finance',
      designation: 'Base Department',
      location: 'mumbai',
      selected: true,
    },
    {
      id: 2,
      employeeId: 102,
      employeeName: 'Employee 2',
      lcPolicy: 'Life Cycle Policy 2022',
      department: 'Services',
      designation: 'Base Department',
      location: 'mumbai',
    },
  ],
  loading: false,
  selectedItems: [],
  onSelect: () => {},
  onSelectAll: () => {},
  showRowSelector: false,
  showPagination: false,
  keyColumn: '',
};
LCPolicyListData.propTypes = {
  listFilterFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  listHandleFilter: PropTypes.func,
  policyItem: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    active: PropTypes.bool,
    employeeList: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        employeeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        employeeName: PropTypes.string,
        lcPolicy: PropTypes.string,
        department: PropTypes.string,
        designation: PropTypes.string,
        location: PropTypes.string,
      }),
    ),
  }),
  empColumns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),
  empActionMenuLists: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  empTotalRecordCount: PropTypes.number,
  empLoadNextPageData: PropTypes.func,
  empHandleSort: PropTypes.func,
  empHandleRowClick: PropTypes.func,
  empPage: PropTypes.number,
  empSortBy: PropTypes.string,
  empSortDirection: PropTypes.oneOf(['asc', 'desc']),
  empHandleSelect: PropTypes.func,
  empOnSelectedChange: PropTypes.func,
  handleDetails: PropTypes.func,
  handleAdd: PropTypes.func,
  openDeleteLCPolicy: PropTypes.bool,
  handleClose: PropTypes.func,
  handleDelete: PropTypes.func,
  handleCancel: PropTypes.func,
  handleDeleteEmployee: PropTypes.func,
  handleCancelEmployee: PropTypes.func,
  openEmployee: PropTypes.bool,
  handleCloseEmployee: PropTypes.func,
  numOfEmployee: PropTypes.number,
  setIsDeleteLCPolicy: PropTypes.func,
  allEmployees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      employeeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      employeeName: PropTypes.string,
      lcPolicy: PropTypes.string,
      department: PropTypes.string,
      designation: PropTypes.string,
      location: PropTypes.string,
      selected: true,
    }),
  ),
  loading: PropTypes.bool,
  selectedItems: PropTypes.arrayOf,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  showRowSelector: PropTypes.bool,
  showPagination: PropTypes.bool,
  keyColumn: PropTypes.string,
};
export default LCPolicyListData;
