import { salarySlipList, salarySlipDetails, consolidatedSalarySlipDetails } from 'api/home/myPay';
import { asyncThunkWrapper } from 'utils/AsyncThunkWrapper';

const fetchSalarySlipList = asyncThunkWrapper('salarySlipList/fetch', salarySlipList);
const fetchSalarySlipDetails = asyncThunkWrapper('salarySlipDetails/fetch', salarySlipDetails);
const fetchConsolidatedSalarySlipDetails = asyncThunkWrapper(
  'consolidatedSalarySlipDetails/fetch',
  consolidatedSalarySlipDetails,
);

export { fetchSalarySlipList, fetchSalarySlipDetails, fetchConsolidatedSalarySlipDetails };
