import { api, formDataApi } from 'api/api';

export const listPastEmployertaxes = async (query) => {
  const response = await api.post(`restPayIndex/listPayheads?${query.toString()}`);
  return response?.data?.data;
};

export const searchPastEmployertaxes = async (query) => {
  const response = await api.post(`restPayIndex/listPayheads?${query.toString()}`);
  return response?.data?.data;
};

export const deletePastEmployertax = async (id) => {
  const body = new FormData();
  body.append('id', id);
  const response = await formDataApi.post('restPayIndex/deletePastEmployertax', body);
  return response;
};
