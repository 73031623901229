import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import TitleWithText from 'components/Atoms/TitleWithText';

const UserRole = ({ userRoles }) => {
  return (
    <Grid sx={{ paddingLeft: '24px' }} container rowSpacing={1} columnSpacing={2}>
      <Grid item xs={12} sm={4} md={3}>
        <TitleWithText title="Roles Granted" text={userRoles} />
      </Grid>
    </Grid>
  );
};

UserRole.defaultProps = {
  userRoles: 'ADMINISTRATOR',
};

UserRole.propTypes = {
  userRoles: PropTypes.string,
};

export default UserRole;
