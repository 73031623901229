import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid } from '@mui/material';
import TitleWithText from 'components/Atoms/TitleWithText';
import Typographys from 'components/Atoms/Typography';
import Button from 'components/Atoms/Button';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { onBoardingStyle } from '../../style';

const SkillsAttainmentDetail = ({ skillsAttainmentDetails, handleAttachment }) => {
  return (
    <>
      <Typographys variant="h5" color="neutral.700">
        Skills Attainment Details
      </Typographys>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 44rem)' }}>
          <Box mt={2}>
            {skillsAttainmentDetails.map((list, i) => (
              <>
                <Typographys variant="subtitle2" align="left" color="neutral.800">
                  Record {i + 1}
                </Typographys>
                <Grid container rowSpacing={1} columnSpacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TitleWithText title="Skill" text={list.skill} />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TitleWithText title="Level" text={list.level} />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TitleWithText title="Comments" text={list.comments} />
                  </Grid>

                  <Grid item xs={12}>
                    <Typographys variant="h6" color="neutral.600">
                      Attachment
                    </Typographys>

                    <Button
                      variant="text"
                      color="secondary"
                      onClick={() => handleAttachment(list?.attachmentId, list?.attachment)}
                      startIcon={<AttachFileIcon />}
                    >
                      {list?.attachment || ''}
                    </Button>
                  </Grid>
                </Grid>
                {i + 1 !== skillsAttainmentDetails.length && <Divider sx={onBoardingStyle.divider} />}
              </>
            ))}
          </Box>
        </Box>
      </PerfectScrollbar>
    </>
  );
};

SkillsAttainmentDetail.defaultProps = {
  skillsAttainmentDetails: [],
  handleAttachment: () => {},
};

SkillsAttainmentDetail.propTypes = {
  skillsAttainmentDetails: PropTypes.arrayOf(
    PropTypes.shape({
      skill: PropTypes.string,
      level: PropTypes.string,
      comments: PropTypes.string,
      attachments: PropTypes.string,
    }),
  ),
  handleAttachment: PropTypes.func,
};
export default SkillsAttainmentDetail;
