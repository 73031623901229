import { useReducer } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

const initialState = {
  isLoading: false,
  data: {},
  error: null,
};

const reducer = (state, action) => {
  const { type, payload } = action;
  let newState;
  switch (type) {
    case 'pending': {
      newState = { ...state, isLoading: true };
      break;
    }
    case 'resolved': {
      newState = { ...state, isLoading: false, data: payload };
      break;
    }
    case 'rejected': {
      newState = { ...state, isLoading: false, error: payload };
      break;
    }
    default: {
      newState = state;
    }
  }
  return newState;
};

const useFetcher = (fetcher, args) => {
  const [items, dispatch] = useReducer(reducer, initialState);

  useDeepCompareEffect(() => {
    const fetchItems = async () => {
      dispatch({ type: 'pending' });
      try {
        const response = await fetcher(args);
        if (response.data) {
          dispatch({ type: 'resolved', payload: response.data });
        }
      } catch (error) {
        dispatch({ type: 'rejected', payload: error });
      }
    };
    fetchItems();
  }, [fetcher, args]);

  return [items];
};

export default useFetcher;
