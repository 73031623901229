import React, { useEffect, useState } from 'react';
import PasswordResetTemplate from 'components/Templates/Employee/PasswordReset';
import { formDataApi } from 'api/api';
import useToast from 'components/Provider/useToast';
import Alert from 'components/Atoms/Alert';
import Typographys from 'components/Atoms/Typography';
import { Box } from '@mui/material';
import { passwordResetStyle } from 'components/Organisms/Employee/PasswordReset/style';
import { resetPasswordAction } from 'api/employees';

const PasswordReset = () => {
  const [preResetOption, setPreResetOption] = useState([]);
  const addToast = useToast();
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const preModifyurl = 'restEmpIndex/resetPassword';
      try {
        setPreResetOption({ ...preResetOption, isLoading: true });

        const body = new FormData();
        const response = await formDataApi.post(preModifyurl, body);
        if (response?.data?.employeeList?.length) {
          const options = response?.data?.employeeList.map((emp) => ({
            id: emp.id,
            name: emp.fullName,
          }));
          setPreResetOption(options);
        }
      } catch (err) {
        console.log(err);
        addToast({
          type: 'error',
          title: 'ERROR',
          message: err.message || `Something went wrong`,
        });
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResetPassword = async (data) => {
    const body = {
      empID: data.selectEmployee,
    };
    try {
      const bodyFormData = new FormData();
      Object.keys(body).forEach((key) => {
        if (body[key]) {
          bodyFormData.append(key, body[key]);
        }
      });

      const response = await resetPasswordAction(bodyFormData);
      if (response.status === 200) {
        setIsOpenAlert(true);
        setAlertMessage(response?.data?.message);
      }
    } catch (err) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: err.message || `Failed to update password`,
      });
    }
  };

  return (
    <>
      <PasswordResetTemplate employeeOptions={preResetOption} handleSubmit={handleResetPassword} />;
      {isOpenAlert && (
        <Box sx={passwordResetStyle.alertBox}>
          <Alert
            severity="success"
            titleText="Success!"
            message={
              <Typographys color="neutral.800" component="div">
                {alertMessage}
              </Typographys>
            }
            closeLabel={false}
            onClose={() => setIsOpenAlert(false)}
          />
        </Box>
      )}
    </>
  );
};

export default PasswordReset;
