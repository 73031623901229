import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Button from 'components/Atoms/Button';
import InputField from 'components/Atoms/InputField';
import Dropdown from 'components/Molecules/Dropdown';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Typographys from 'components/Atoms/Typography';
import CheckBox from 'components/Atoms/Checkbox';
import Attachments from 'components/Atoms/Attachments';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTheme } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { hasPermission } from 'utils/permissions';
import { useSelector } from 'react-redux';
import { profileStyle } from '../style';

const AddAndEditDocument = ({
  handleSubmit,
  handleCancel,
  defaultValues,
  statusTypeOptions,
  documentTypeOptions,
  documentOwnerTypeOptions,
  isEdit,
  handleDelete,
  ownerOptions,
  handleOwnerOptions,
  loading,
  entityOriginPermission,
  documentOwnerEditAllowed,
}) => {
  const [isSubmited, setIsSubmited] = useState(false);
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasDeletePermission = hasPermission(authorities, ['ROLE_BASE_PERMISSION_INDEX_DELETE_DOCUMENT']);

  const dependentSchema = yup.object().shape({
    documentName: yup.string().required('Required'),
    documentType: yup.string().required('Required'),
    status: yup.string().required('Required'),
    docVersion: yup.string().required('Required'),
    documentOwnerType: yup.string(),
    attachments: defaultValues?.fileName === '' && yup.mixed().required('Required'),
    owner: yup.string(),
  });

  const formik = useFormik({
    initialValues: defaultValues,
    validateOnMount: true,
    validationSchema: dependentSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const theme = useTheme();
  const handleAttachmentCheck = () => {
    if (defaultValues?.fileName === '' && defaultValues?.attachments === '') {
      setIsSubmited(true);
    } else {
      setIsSubmited(false);
    }
  };

  return (
    <Box sx={{ py: 1 }}>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <PerfectScrollbar>
          <Box sx={{ maxHeight: 'calc(100vh - 48.3rem)' }}>
            <Grid container rowSpacing={2} columnSpacing={2} sx={profileStyle.formContainer}>
              <Grid item xs={12} sm={6} md={4}>
                <InputField
                  error={Boolean(formik.touched.documentName && formik.errors.documentName)}
                  helperText={formik.touched.documentName && formik.errors.documentName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  label="Document Name*"
                  value={formik.values.documentName}
                  name="documentName"
                  id="documentName"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Dropdown
                  error={Boolean(formik.touched.documentType && formik.errors.documentType)}
                  helperText={formik.touched.documentType && formik.errors.documentType}
                  handleChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  label="Document Type*"
                  value={formik.values.documentType}
                  name="documentType"
                  id="documentType"
                  options={documentTypeOptions}
                  keyGetter={(item) => item.name}
                  titleGetter={(item) => item.name}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Dropdown
                  error={Boolean(formik.touched.status && formik.errors.status)}
                  helperText={formik.touched.status && formik.errors.status}
                  handleChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  label="Status*"
                  value={formik.values.status}
                  name="status"
                  id="status"
                  options={statusTypeOptions}
                  keyGetter={(item) => item.id}
                  titleGetter={(item) => item.name}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <InputField
                  error={Boolean(formik.touched.description && formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  label="Description"
                  value={formik.values.description}
                  name="description"
                  id="description"
                  fullWidth
                  multiline
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InputField
                  error={Boolean(formik.touched.docVersion && formik.errors.docVersion)}
                  helperText={formik.touched.docVersion && formik.errors.docVersion}
                  onChange={formik.handleChange}
                  label="Doc Version*"
                  onBlur={formik.handleBlur}
                  value={formik.values.docVersion}
                  name="docVersion"
                  id="docVersion"
                  fullWidth
                />
              </Grid>

              {documentOwnerEditAllowed && (
                <Grid item xs={12} sm={6} md={4}>
                  <Dropdown
                    error={Boolean(formik.touched.documentOwnerType && formik.errors.documentOwnerType)}
                    helperText={formik.touched.documentOwnerType && formik.errors.documentOwnerType}
                    handleChange={(e) => {
                      formik.handleChange(e);
                      handleOwnerOptions(e?.target?.value);
                    }}
                    onBlur={formik.handleBlur}
                    label="Document Owner Type"
                    options={documentOwnerTypeOptions}
                    value={formik.values.documentOwnerType}
                    name="documentOwnerType"
                    id="documentOwnerType"
                    keyGetter={(item) => item.id}
                    titleGetter={(item) => item.name}
                    fullWidth
                  />
                </Grid>
              )}
              {['Employee', 'Employee Group', 'Department', 'Project', 'Customer/Vendor'].includes(
                formik.values.documentOwnerType,
              ) &&
                documentOwnerEditAllowed && (
                  <Grid item xs={12} sm={6} md={4}>
                    <Dropdown
                      error={Boolean(formik.touched.owner && formik.errors.owner)}
                      helperText={formik.touched.owner && formik.errors.owner}
                      handleChange={(e) => {
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      label="Owner"
                      options={ownerOptions}
                      value={formik.values.owner}
                      name="owner"
                      id="owner"
                      keyGetter={(item) => item.name}
                      titleGetter={(item) => item.name || item.fullName || item.groupName}
                      fullWidth
                    />
                  </Grid>
                )}
            </Grid>

            <Box sx={{ marginBottom: '1.5rem' }}>
              <CheckBox
                label="Is Current Version"
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                checked={formik.values.isCurrentVersion}
                color="secondary"
                name="isCurrentVersion"
              />
            </Box>
            <Box sx={{ display: 'flex', mb: 2, mt: 2 }}>
              <Attachments
                isDeleteIcon
                handleChange={(event) => {
                  formik.setFieldValue(`attachments`, event.target.files[0]);
                }}
                name="attachments"
                id="attachments"
                value={formik.values.attachments}
                handleDelete={() => {
                  formik.setFieldValue(`attachments`, '');
                }}
              />
            </Box>
            <Box>
              {!formik.values?.attachments && isSubmited && (
                <Typographys variant="caption" color="red">
                  Please attach a document*
                </Typographys>
              )}
            </Box>

            {isEdit && (
              <Typographys variant="caption" color="neutral.600">
                Previously Associated Attachment: {defaultValues?.fileName}
              </Typographys>
            )}
          </Box>
        </PerfectScrollbar>

        <Box sx={profileStyle.btnContainer}>
          <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />
          <Box sx={profileStyle.submitWrapper}>
            {!!(entityOriginPermission === 'ENTITY_USER_DEFINED' && userHasDeletePermission && isEdit) && (
              <Button
                color="error"
                variant="outlined"
                startIcon={<DeleteIcon fontSize="2.5rem" />}
                size="large"
                type="button"
                onClick={handleDelete}
              >
                Delete
              </Button>
            )}
            <Box sx={{ display: 'flex', flex: '1', justifyContent: 'end', gap: 2 }}>
              <Button
                size="large"
                color="info"
                variant="outlined"
                type="cancel"
                onClick={() => {
                  formik.resetForm();
                  handleCancel();
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                color="primary"
                loading={loading}
                size="large"
                type="submit"
                variant="contained"
                onClick={handleAttachmentCheck}
              >
                {isEdit ? 'Update' : 'Add'}
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

AddAndEditDocument.defaultProps = {
  handleSubmit: () => {},
  handleCancel: () => {},
  documentTypeOptions: [],
  statusTypeOptions: [],
  documentOwnerTypeOptions: [],
  defaultValues: {},
  isEdit: false,
  handleDelete: () => {},
  ownerOptions: [],
  handleOwnerOptions: () => {},
  loading: false,
  entityOriginPermission: '',
  documentOwnerEditAllowed: false,
};

AddAndEditDocument.propTypes = {
  documentOwnerEditAllowed: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  documentOwnerTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  documentTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  statusTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  defaultValues: PropTypes.shape({}),
  isEdit: PropTypes.bool,
  handleDelete: PropTypes.func,
  ownerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  handleOwnerOptions: PropTypes.func,
  loading: PropTypes.bool,
  entityOriginPermission: PropTypes.string,
};

export default AddAndEditDocument;
