import { useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_URL } from 'api/api';
import useToast from 'components/Provider/useToast';
import useDownloadFile from 'hooks/useDownloadFile';
import { validateNumberElseHyphen } from 'utils/commonUtils';
import {
  listExpenseReports,
  submitReportForReview,
  deleteExpenseReportAction,
  myOrgExpenseReports,
  myActionableExpenseReports,
  actionExpenseReport,
  viewExpenseReportAction,
  recallSubmittedExpenseReport,
} from 'api/expenses';

const initialState = {
  open: false,
  selectedTab: 0,
  selectedExpense: null,
  listExpensesReport: {
    loading: false,
    data: {},
    error: null,
  },
  expenseReportDetails: {},
  isApprove: false,
  approveRejectComment: '',
};

const formatExpenseReportDetails = (response) => {
  if (!response.data) {
    return {};
  }
  const getActionCreatorDetails = (comment) => {
    const commentsActionCreatorInstance =
      response?.data?.commentsMap &&
      Object.keys(response?.data?.commentsMap)?.find((item) => item?.includes(comment?.id));

    return {
      fullName: response?.data?.commentsMap?.[`${commentsActionCreatorInstance}`]?.fullName || '',
      avatarSrc: `${API_URL}/${response?.data?.commentsMap?.[`${commentsActionCreatorInstance}`]?.profile?.profilePic}`,
    };
  };

  const getApproverDetails = (comment) => {
    const approverInstance =
      response?.data?.approverActionsMap &&
      Object.keys(response?.data?.approverActionsMap)?.find((item) => item?.includes(comment?.id));

    return {
      fullName: response?.data?.approverActionsMap?.[`${approverInstance}`]?.fullName || '',
      avatarSrc: `${API_URL}/${response?.data?.approverActionsMap?.[`${approverInstance}`]?.profile?.profilePic}`,
    };
  };
  const {
    employeeExpenseReportInstance = {},
    supportingDocuments = [],
    commentsMapList = [],
    approverActionsMapList = [],
    defaultCurrency = {},
    department = {},
    designation = {},
    location = {},
    supervisor = {},
  } = response.data;
  return {
    id: employeeExpenseReportInstance?.id,
    reportDetails: {
      title: employeeExpenseReportInstance?.displayName || '-',
      address: employeeExpenseReportInstance?.company?.companyName || '-',
      employeeName: employeeExpenseReportInstance?.employee?.fullName || '-',
      employeeId: employeeExpenseReportInstance?.employee?.profile?.employeeID || '-',
      expensePeriodStartDate: employeeExpenseReportInstance?.startDate || '-',
      expensePeriodEndDate: employeeExpenseReportInstance?.endDate || '-',
      department: department?.name || '-',
      designation: designation?.name || '-',
      reportingManager: supervisor?.fullName || '-',
      location: location?.name || '-',
      businessPurpose: employeeExpenseReportInstance?.businessPurpose || '-',
      reportId: employeeExpenseReportInstance?.expenseReportID || '-',
      reportStatus: employeeExpenseReportInstance?.reportStatus || '',
      lockStatus: employeeExpenseReportInstance?.lockStatus || '',
      settlementStatus: employeeExpenseReportInstance?.settlementStatus || '-',
    },
    rows: employeeExpenseReportInstance?.expenseRecords || [],
    amountData: [
      {
        label: 'Total Expensed Amount',
        amount: `${defaultCurrency?.symbol} ${validateNumberElseHyphen(
          employeeExpenseReportInstance?.totalExpensedAmount,
        )}`,
      },
      {
        label: 'Reimbursable Amount',
        amount: `${defaultCurrency?.symbol} ${validateNumberElseHyphen(
          employeeExpenseReportInstance?.totalAmountToBeReimbursed,
        )}`,
      },
      {
        label: 'Approved Amount',
        amount: `${defaultCurrency?.symbol} ${validateNumberElseHyphen(
          employeeExpenseReportInstance?.totalApprovedAmount,
        )}`,
      },
    ],
    supportingDocuments: supportingDocuments || [],
    auditData: {
      auditTrailsList: commentsMapList
        ?.map((item) => ({
          avatarSrc: getActionCreatorDetails(item)?.avatarSrc,
          description: getActionCreatorDetails(item)?.fullName,
          content: item?.content?.split('<br />'),
          date: item?.createdOn,
        }))
        .sort((a, b) => new Date(b.date) - new Date(a.date)),
      approvalAuditTrailsList: approverActionsMapList
        ?.map((item) => ({
          avatarSrc: getApproverDetails(item)?.avatarSrc,
          description: getApproverDetails(item)?.fullName,
          content: item?.actionComments.split('<br />'),
          date: item?.actionedOn,
        }))
        .sort((a, b) => new Date(b.date) - new Date(a.date)),
    },
  };
};

const reducer = (state, action) => {
  const { type, payload } = action;
  let newState;
  switch (type) {
    case 'FETCH_EXPENSE_REPORT_PENDING': {
      newState = { ...state, listExpensesReport: { loading: true } };
      break;
    }
    case 'FETCH_EXPENSE_REPORT': {
      newState = { ...state, listExpensesReport: { ...state.listExpensesReport, loading: false, data: payload } };
      break;
    }
    case 'FETCH_EXPENSE_REPORT_REJECT': {
      newState = { ...state, listExpensesReport: { ...state.listExpensesReport, loading: false, error: payload } };
      break;
    }
    case 'UPDATE_SELECTED_TAB': {
      newState = { ...state, selectedTab: payload };
      break;
    }
    case 'UPDATE_SELECTED_EXPENSE': {
      newState = { ...state, selectedExpense: payload };
      break;
    }
    case 'OPEN_CLOSE_DIALOG': {
      newState = { ...state, open: payload };
      break;
    }
    case 'SET_APPROVE_REJECT_COMMENT': {
      newState = { ...state, approveRejectComment: payload };
      break;
    }
    case 'SET_IS_APPROVE': {
      newState = { ...state, isApprove: payload };
      break;
    }
    case 'SET_EXPENSE_REPORT_DETAILS': {
      newState = { ...state, expenseReportDetails: payload };
      break;
    }
    case 'OPEN_DIALOG': {
      newState = { ...state, open: payload };
      break;
    }
    case 'REFRESH_PAGE': {
      newState = { ...state, refreshPage: payload };
      break;
    }
    default: {
      newState = state;
    }
  }
  return newState;
};

const useServices = () => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const addToast = useToast();
  const downloadFile = useDownloadFile();
  const handleDeleteDialog = () => {
    setShowDeleteDialog(!showDeleteDialog);
  };

  const updateSelectedExpense = (payload) => {
    dispatch({ type: 'UPDATE_SELECTED_EXPENSE', payload });
  };

  const fetchExpenseReport = async (tab, query) => {
    try {
      dispatch({ type: 'FETCH_EXPENSE_REPORT_PENDING', payload: null });
      dispatch({ type: 'UPDATE_SELECTED_TAB', payload: tab });
      const response = await listExpenseReports(tab, query);
      if (response.status === 200 && response.data) {
        dispatch({ type: 'FETCH_EXPENSE_REPORT', payload: response.data });
      }
    } catch (error) {
      dispatch({ type: 'FETCH_EXPENSE_REPORT_REJECT', payload: error });
    }
  };

  const fetchUpdatedExpenseReport = async (updatedList) => {
    try {
      dispatch({ type: 'FETCH_EXPENSE_REPORT_PENDING', payload: null });
      if (updatedList) {
        dispatch({ type: 'FETCH_EXPENSE_REPORT', payload: updatedList });
      }
    } catch (error) {
      dispatch({ type: 'FETCH_EXPENSE_REPORT_REJECT', payload: error });
    }
  };

  const addExpenseReport = () => {
    navigate(`/home/expenses/add`);
  };
  const viewExpenseReport = (row) => {
    navigate(`/home/expenses/${row.id}/view`);
  };

  const modifyExpenseReport = (row) => {
    navigate(`/home/expenses/${row.id}/edit`);
  };

  const deleteExpenseReport = async (row) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', row.id);
      const response = await deleteExpenseReportAction(bodyFormData);
      if (response.status === 200 && response.data) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.data?.message,
        });
        if (row?.view) {
          navigate(-1);
        } else {
          fetchExpenseReport(state.selectedTab);
        }
      }
    } catch (error) {
      addToast({ type: 'error', title: 'ERROR', message: error?.message });
    } finally {
      handleDeleteDialog(false);
    }
  };

  const submitExpenseReport = async (row) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', row.id);
      const response = await submitReportForReview(bodyFormData);
      if (response.status === 200 && response.data) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message,
        });
        if (row?.view) {
          dispatch({ type: 'REFRESH_PAGE', payload: !state?.refreshPage });
        } else {
          fetchExpenseReport(state.selectedTab);
        }
      }
    } catch (error) {
      addToast({ type: 'error', title: 'ERROR', message: error?.message });
    }
  };

  const fetchMyOrgExpenseReports = async (query) => {
    try {
      dispatch({ type: 'FETCH_EXPENSE_REPORT_PENDING', payload: null });
      const response = await myOrgExpenseReports(query);
      if (response.status === 200 && response.data) {
        dispatch({ type: 'FETCH_EXPENSE_REPORT', payload: response.data });
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message,
        });
      }
    } catch (error) {
      dispatch({ type: 'FETCH_EXPENSE_REPORT_REJECT', payload: error });
      addToast({ type: 'error', title: 'ERROR', message: error?.message });
    }
  };

  const fetchUpdatedMyOrgExpenseReports = async (updatedList) => {
    try {
      dispatch({ type: 'FETCH_EXPENSE_REPORT_PENDING', payload: null });
      if (updatedList) {
        dispatch({ type: 'FETCH_EXPENSE_REPORT', payload: updatedList });
      }
    } catch (error) {
      dispatch({ type: 'FETCH_EXPENSE_REPORT_REJECT', payload: error });
      addToast({ type: 'error', title: 'ERROR', message: error?.message });
    }
  };

  const fetchMyActionableExpenseReports = async (query) => {
    try {
      dispatch({ type: 'FETCH_EXPENSE_REPORT_PENDING', payload: null });
      const response = await myActionableExpenseReports(query);
      if (response.status === 200 && response.data) {
        dispatch({ type: 'FETCH_EXPENSE_REPORT', payload: response.data });
      }
    } catch (error) {
      dispatch({ type: 'FETCH_EXPENSE_REPORT_REJECT', payload: error });
      addToast({ type: 'error', title: 'ERROR', message: error?.message });
    }
  };
  const recallExpenseReport = async (id) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', id);
      const response = await recallSubmittedExpenseReport(bodyFormData);
      if (response.status === 200 && response.data) {
        addToast({ type: 'success', title: 'SUCCESS', message: response?.data?.message });
        navigate('/home/expenses');
      } else {
        addToast({ type: 'error', title: 'ERROR', message: response?.data?.message });
      }
    } catch (error) {
      addToast({ type: 'error', title: 'ERROR', message: error?.message });
    }
  };

  const onChangeApproveRejectComment = (event) => {
    dispatch({ type: 'SET_APPROVE_REJECT_COMMENT', payload: event.target.value });
  };
  const approveRejectExpenseReport = (open, isApprove) => {
    dispatch({ type: 'OPEN_CLOSE_DIALOG', payload: open });
    dispatch({ type: 'SET_IS_APPROVE', payload: isApprove });
  };

  const handleSelectedReport = (selectedReport) => {
    dispatch({ type: 'SET_EXPENSE_REPORT_DETAILS', payload: selectedReport });
  };
  const approveRejectExpenseReportAction = async () => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', state.expenseReportDetails?.id || state?.selectedExpense?.id);
      const response = await actionExpenseReport(bodyFormData);
      if (response.status === 200 && response.data) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message,
        });
        fetchExpenseReport(state.selectedTab);
      }
    } catch (error) {
      addToast({ type: 'error', title: 'ERROR', message: error?.message });
    } finally {
      dispatch({ type: 'OPEN_CLOSE_DIALOG', payload: false });
    }
  };

  const fetchExpenseReportDetails = async (id) => {
    try {
      dispatch({ type: 'SET_EXPENSE_REPORT_DETAILS', payload: { isLoading: true } });
      const bodyFormData = new FormData();
      bodyFormData.append('id', id);
      const response = await viewExpenseReportAction(bodyFormData);
      if (response.status === 200 && response.data) {
        const formattedResponse = formatExpenseReportDetails(response.data);
        dispatch({ type: 'SET_EXPENSE_REPORT_DETAILS', payload: formattedResponse });
      }
    } catch (error) {
      dispatch({ type: 'SET_EXPENSE_REPORT_DETAILS', payload: { isLoading: false } });
      addToast({ type: 'error', title: 'ERROR', message: error?.message });
    }
  };

  const downloadDocument = (row, filename) => {
    downloadFile({ docid: row?.id }, row?.fileName || filename, 'restExpIndex/downloadAttachment');
  };

  return {
    state,
    fetchExpenseReport,
    fetchUpdatedExpenseReport,
    addExpenseReport,
    viewExpenseReport,
    modifyExpenseReport,
    deleteExpenseReport,
    submitExpenseReport,
    recallExpenseReport,
    approveRejectExpenseReport,
    fetchMyOrgExpenseReports,
    fetchUpdatedMyOrgExpenseReports,
    fetchMyActionableExpenseReports,
    onChangeApproveRejectComment,
    approveRejectExpenseReportAction,
    handleSelectedReport,
    fetchExpenseReportDetails,
    downloadDocument,
    showDeleteDialog,
    handleDeleteDialog,
    updateSelectedExpense,
  };
};

export default useServices;
