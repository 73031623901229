import React, { useEffect } from 'react';
import { useThunk } from 'hooks/useThunk';
import { fetchMyLeaveDetails } from 'store';
import { useSelector } from 'react-redux';
import { Box, Typography, Skeleton } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import LeaveRequestDetails from 'components/Templates/Leave/MyLeaves/MyLeaveRequests/LeaveRequestDetails';
import Dialog from 'components/Molecules/Dialog';
import useDownloadFile from 'hooks/useDownloadFile';
import { hasPermission } from 'utils/permissions';
import useLeaveServices from '../list/useLeaveServices';
import useMenuItems from './useMenuItems';

const LeaveDetailsPage = () => {
  const navigate = useNavigate();
  const downloadFile = useDownloadFile();
  const { id } = useParams();
  const leaveServices = useLeaveServices();
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasViewPermission = hasPermission(authorities, ['ROLE_BASE_PERMISSION_LEAVE_INDEX_VIEW_LEAVE_REQUEST']);
  const [getLeaveDetails, isLoadingDetails, loadingDetailsError] = useThunk(fetchMyLeaveDetails);
  const { myLeaveDetails, loading } = useSelector((state) => state?.myLeaves);

  useEffect(() => {
    const query = new URLSearchParams({});
    query.append('id', id);
    getLeaveDetails(query);
  }, [id, getLeaveDetails, loading]);

  const handleEdit = () => {
    navigate(`/home/leaves/my-leave-requests/${id}/edit`);
  };

  const [menuItems] = useMenuItems(myLeaveDetails, leaveServices);
  const handleCloseDialog = () => leaveServices.dispatchDialog({ type: 'Close' });

  const breadcrumbData = [
    { id: 1, name: 'My Leave Request', path: '/home/leaves/my-leave-requests', isActive: false },
    { id: 2, name: 'Details', isActive: true },
  ];

  const handleAttachment = (docid, filename) => {
    downloadFile({ docid }, filename, 'restPayIndex/downloadAttachment');
  };

  if (loadingDetailsError !== null) {
    return <Typography variant="h6">{loadingDetailsError?.message || 'Error while loading leave details.'}</Typography>;
  }

  if (!userHasViewPermission) {
    return <Typography variant="h6">You do not have permission to view leave request details</Typography>;
  }

  if (isLoadingDetails) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={42} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  return (
    <Box>
      <LeaveRequestDetails
        pageHeading="Leave request details"
        breadcrumbData={breadcrumbData}
        handleAttachment={handleAttachment}
        leaveDetails={{
          employeeId: myLeaveDetails?.employeeId,
          employeeName: myLeaveDetails?.employee?.fullName || '-',
          leaveCategory: myLeaveDetails?.leaveCategory?.displayName || '-',
          leaveType: myLeaveDetails?.leaveType || '-',
          startDate: myLeaveDetails?.startDate,
          endDate: myLeaveDetails?.endDate,
          numberOfDays: myLeaveDetails?.numberOfDays || '-',
          numberOfHours: myLeaveDetails?.numberOfHours || '-',
          createdOn: myLeaveDetails?.createdOn,
          createdBy: myLeaveDetails?.createdBy?.fullName || '-',
          lastModifiedOn: myLeaveDetails?.lastModifiedOn,
          lastModifiedBy: myLeaveDetails?.lastModifiedBy?.fullName || '-',
          approvalDate: myLeaveDetails?.approvalDate,
          approvedBy: myLeaveDetails?.approvedBy?.fullName || '-',
          requestState: myLeaveDetails?.requestState || '-',
          description: myLeaveDetails?.description || '-',
          reviewComments: myLeaveDetails?.reviewComments || '-',
          attachment: myLeaveDetails?.attachment?.fileName,
          attachmentId: myLeaveDetails?.attachmentId,
        }}
        handleEdit={handleEdit}
        actionMenuList={menuItems}
      />
      {myLeaveDetails !== null && (
        <>
          <Dialog
            open={leaveServices.dialogState.open === 'Delete'}
            dialogTitle="Delete Leave"
            dialogDesc={`Are you sure you want to delete ${myLeaveDetails?.leaveType}?`}
            primaryBtn="Delete"
            secondaryBtn="Cancel"
            primaryBtnColor="error"
            handleSecondaryBtn={handleCloseDialog}
            bodyContent={false}
            handlePrimaryBtn={() => leaveServices.deleteLeave(myLeaveDetails?.id, false)}
            handleClose={handleCloseDialog}
          />

          <Dialog
            open={leaveServices.dialogState.open === 'Submit'}
            dialogTitle="Submit Leave"
            dialogDesc={`Are you sure you want to submit ${myLeaveDetails?.leaveType}?`}
            primaryBtn="Submit"
            secondaryBtn="Cancel"
            handleSecondaryBtn={handleCloseDialog}
            bodyContent={false}
            handlePrimaryBtn={() => leaveServices.submitLeave({ id: myLeaveDetails?.id, refresh: true })}
            handleClose={handleCloseDialog}
          />

          <Dialog
            open={leaveServices.dialogState.open === 'Cancel'}
            dialogTitle="Cancel Leave Request"
            dialogDesc={`Are you sure you want to cancel ${myLeaveDetails?.leaveType}?`}
            primaryBtn="Confirm"
            secondaryBtn="Cancel"
            handleSecondaryBtn={handleCloseDialog}
            bodyContent={false}
            handlePrimaryBtn={() => leaveServices.cancelLeave(myLeaveDetails?.id, myLeaveDetails?.requestState, true)}
            handleClose={handleCloseDialog}
          />
        </>
      )}
    </Box>
  );
};

export default LeaveDetailsPage;
