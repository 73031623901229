const columns = [
  {
    fieldName: 'id',
    numeric: true,
    label: 'EMPLOYEE ID',
    disableSorting: true,
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'fullName',
    numeric: true,
    label: 'EMPLOYEE NAME',
    disableSorting: true,
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'lcPolicy',
    numeric: false,
    label: 'LC POLICY',
    disableSorting: true,
    formatValue: ({ row, column }) => row[column.fieldName]?.name,
  },

  {
    fieldName: 'department',
    numeric: true,
    label: 'DEPARTMENT',
    disableSorting: true,
    formatValue: ({ row, column }) => row[column.fieldName]?.name,
  },
  {
    fieldName: 'designation',
    numeric: true,
    label: 'DESIGNATION',
    disableSorting: true,
    formatValue: ({ row, column }) => row[column.fieldName]?.name,
  },
  {
    fieldName: 'location',
    numeric: true,
    label: 'LOCATION',
    disableSorting: true,
    formatValue: ({ row, column }) => row[column.fieldName]?.name,
  },
];

const useEmployeeColumns = () => {
  return [columns];
};

export default useEmployeeColumns;
