import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'components/Molecules/Dropdown';
import InputField from 'components/Atoms/InputField';
import { Box } from '@mui/material';
import TableExtended from 'components/Molecules/TableExtended';

const HouseRentDeclarationsForm = ({ cityOption, formik, sortDirection, sortBy, onSortChange }) => {
  const houseRentDataColumn = [
    {
      fieldName: `slNo`,
      label: 'Sl No',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `period`,
      label: 'PERIOD',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `landlordName`,
      label: 'Landlord Name',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `landlordPan`,
      label: 'Landlord Pan',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `landlordAddress`,
      label: 'Landlord Address',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `city`,
      label: 'city of rented accommodation',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `rentPaid`,
      label: 'Rent Paid/Payable',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
  ];
  return (
    <Box mb={13.5}>
      <TableExtended
        loading={false}
        rows={formik.values.houseRent.map((i, index) => {
          return {
            ...i,
            landlordName: (
              <InputField
                value={formik.values.houseRent[index].landlordName}
                name={`houseRent[${index}].landlordName`}
                id={`houseRent[${index}].landlordName`}
                label="Landlord Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
              />
            ),
            landlordPan: (
              <InputField
                value={formik.values.houseRent[index].landlordPan}
                name={`houseRent[${index}].landlordPan`}
                id={`houseRent[${index}].landlordPan`}
                label="Landlord PAN"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
              />
            ),
            landlordAddress: (
              <InputField
                value={formik.values.houseRent[index].landlordAddress}
                name={`houseRent[${index}].landlordAddress`}
                id={`houseRent[${index}].landlordAddress`}
                label="Landlord Address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
              />
            ),
            city: (
              <Dropdown
                label="Select City"
                size="small"
                value={formik.values.houseRent[index].city}
                options={cityOption}
                name={`houseRent[${index}].city`}
                id={`houseRent[${index}].city`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                keyGetter={(item) => item.name}
                titleGetter={(item) => item.name}
              />
            ),
            rentPaid: (
              <InputField
                value={formik.values.houseRent[index].rentPaid}
                name={`houseRent[${index}].rentPaid`}
                id={`houseRent[${index}].rentPaid`}
                type="number"
                label="Amount"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
              />
            ),
          };
        })}
        columns={houseRentDataColumn}
        showActionList={false}
        showCheckbox={false}
        sortDirection={sortDirection}
        sortBy={sortBy}
        onSortChange={onSortChange}
      />
    </Box>
  );
};
HouseRentDeclarationsForm.defaultProps = {
  cityOption: [],
  formik: {},
  sortDirection: 'desc',
  sortBy: 'id',
  onSortChange: () => {},
};
HouseRentDeclarationsForm.propTypes = {
  cityOption: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, name: PropTypes.string, onClick: PropTypes.func }),
  ),
  formik: PropTypes.shape({
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
  }),
  onSortChange: PropTypes.func,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
};

export default HouseRentDeclarationsForm;
