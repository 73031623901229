import React, { useCallback, useEffect, useState } from 'react';
import TaskAndQueriesTemplate from 'components/Templates/TaskAndQueries';
import useController from 'hooks/useController';
import usePaginationAttributes from 'hooks/use-pagination-attributes';
import { Box } from '@mui/material';
import DeleteQuery from 'components/Organisms/Dialog/ActionList/TaskAndQueries/DeleteQuery';
import { hasPermission } from 'utils/permissions';
import { useSelector } from 'react-redux';
import { searchQuery } from 'api/queries';
import { postListApiFormat, getListApiFormat } from 'utils/ApiPayloadFormat';
import useFetcher from 'hooks/useFetcher';
import useServices from '../common/useServices';
import useFilters from '../common/useFilters';
import useColumns from '../common/useColumns';
import useActionItems from '../common/useActionItems';

const ListMyQueries = () => {
  const { pageSize } = usePaginationAttributes();
  const [columns] = useColumns();
  const services = useServices();
  const [controller, handlePageChange, handleSortChange, handleFiltersApply, handleFiltersClear] = useController();
  const [filterOptions] = useFetcher(searchQuery);
  const authorities = useSelector((state) => state.session.user.authorities);
  const filterService = useFilters(controller, filterOptions?.data?.data);

  const [roleBasedMenu] = useActionItems(services);

  const userHasCreatePermission = hasPermission(authorities, ['ROLE_BASE_PERMISSION_QUER_INDEX_CREATE_QUERY']);

  const fetchQueriesList = useCallback(async () => {
    if (controller?.filters && Object.keys(controller?.filters).length > 0) {
      const formData = postListApiFormat(controller, pageSize);
      services.searchQueriesService(formData);
    } else {
      const queryData = getListApiFormat(controller, pageSize);
      services.listQueriesService(queryData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controller, pageSize]);

  useEffect(() => {
    fetchQueriesList();
  }, [fetchQueriesList, controller, pageSize]);

  return (
    <Box>
      <TaskAndQueriesTemplate
        pageHeading="All List Queries"
        rows={services.state.listQueries.data?.data?.queryInstanceList}
        columns={columns}
        roleBasedMenu={roleBasedMenu}
        page={controller?.page || 0}
        sortDirection={controller.sort}
        sortBy={controller.sortBy}
        handleAddRequest={services.createQueryNavigationService}
        handleFilter={(data) => handleFiltersApply(data)}
        showFilter
        onSortChange={handleSortChange}
        onPageChange={handlePageChange}
        showPagination
        showRowSelector={false}
        isDynamic
        showActionList
        count={
          controller?.filters && Object.keys(controller?.filters).length > 0
            ? services.state.listQueries.data?.data?.totalCount
            : services.state.listQueries.data?.data?.queryInstanceCount
        }
        filterFields={filterService?.filterFields}
        handleFiltersClear={handleFiltersClear}
        showAddButton={userHasCreatePermission}
        loading={services.state?.listQueries?.loading}
      />
      {!!services?.state?.open && (
        <DeleteQuery
          open={!!services?.state?.open}
          handleClose={() => services.handleOpenDialog(false)}
          handleCancel={() => services.handleOpenDialog(false)}
          handleDelete={() => {
            services?.deleteQueryService(services?.state?.queryDetails?.id);
          }}
          queryTitle={services?.state?.queryDetails?.title}
        />
      )}
    </Box>
  );
};

export default ListMyQueries;
