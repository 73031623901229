import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import routes from 'routes';
import './index.css';
import { useSelector } from 'react-redux';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const App = () => {
  const { empDBID = '', candidateID = '' } = useSelector((state) => state?.session?.user);
  const isLoggedIn = empDBID || candidateID;
  const routing = createBrowserRouter(routes(isLoggedIn));

  return <RouterProvider router={routing} />;
};

export default App;
