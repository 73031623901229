import { createSlice } from '@reduxjs/toolkit';

import { getDocumentsNeedToUpload } from 'store/thunks/home/myPay/uploadInvestmentProofs';

const uploadInvestmentProofs = createSlice({
  name: 'uploadInvestmentProofs',
  initialState: {
    docsList: [],
  },
  extraReducers(builder) {
    builder.addCase(getDocumentsNeedToUpload.fulfilled, (state, action) => {
      state.docsList = action.payload;
    });
  },
});

export default uploadInvestmentProofs.reducer;
