import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, MenuItem, InputLabel, Select, FormHelperText } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { dropdownStyle } from './style';

const Dropdown = ({
  id,
  name,
  label,
  variant,
  handleChange,
  labelId,
  options,
  fullWidth,
  size,
  helperText,
  disabled,
  error,
  value,
  keyGetter = (item) => item.id,
  titleGetter = (item) => item.name,
  ...other
}) => {
  const menuProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    getcontentanchorel: null,
  };
  const handleSelectChange = (event) => {
    handleChange(event);
  };

  return (
    <FormControl variant={variant} error={error} fullWidth={fullWidth} size={size} sx={dropdownStyle.dropdown}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        id={id}
        name={name}
        onChange={handleSelectChange}
        IconComponent={ExpandMoreIcon}
        label={label}
        MenuProps={menuProps}
        displayEmpty
        disabled={disabled}
        value={value}
        sx={dropdownStyle.select}
        {...other}
      >
        {options.map((option) => (
          <MenuItem sx={dropdownStyle.menuItem} key={keyGetter(option)} value={keyGetter(option)}>
            {titleGetter(option)}
          </MenuItem>
        ))}
      </Select>
      {error && helperText && <FormHelperText>{helperText} </FormHelperText>}
    </FormControl>
  );
};
Dropdown.defaultProps = {
  id: '',
  name: '',
  label: '',
  value: '',
  handleChange: () => {},
  labelId: '',
  options: [
    { id: 1, name: 'Option 1', onClick: () => {} },
    { id: 2, name: 'Option 2', onClick: () => {} },
    { id: 3, name: 'Option 3', onClick: () => {} },
    { id: 4, name: 'Option 4', onClick: () => {} },
    { id: 5, name: 'Option 5', onClick: () => {} },
    { id: 6, name: 'Option 6', onClick: () => {} },
  ],
  helperText: '',
  variant: 'outlined',
  size: 'small',
  fullWidth: true,
  disabled: false,
  error: false,

  keyGetter: (item) => item.id,
  titleGetter: (item) => item.name,
};
Dropdown.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  labelId: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  helperText: PropTypes.string,
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  keyGetter: PropTypes.func,
  titleGetter: PropTypes.func,
};
export default Dropdown;
