import React from 'react';
import { Box, Card, CardContent, Container, Grid, Stack, Skeleton, IconButton, Divider } from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import { useParams, useNavigate } from 'react-router-dom';
import DropdownMenu from 'components/Atoms/DropdownMenu';
import Button from 'components/Atoms/Button';
import Typography from 'components/Atoms/Typography';
import EditIcon from '@mui/icons-material/Edit';
import useFetcher from 'hooks/useFetcher';
import { fetchChallanById } from 'api/challan';
import { convertChallanData } from './converter';

import BasicChallanDetails from '../components/BasicChallanDetails';
import TdsPaymentDetails from '../components/TdsPaymentDetails';
import EpfChallanDetails from '../components/EpfChallanDetails';

const ViewChallan = () => {
  const { challanId } = useParams();
  const navigate = useNavigate();

  const args = {
    challanId,
    isEditMode: true,
  };
  const [challan] = useFetcher(fetchChallanById, args);

  const flatChallanData = convertChallanData(challan.data);

  if (challan.error !== null) {
    return (
      <Typography variant="h6">
        {challan?.error?.response?.data?.message ||
          `Error while loading payhead details for challan id : ${challanId}.`}
      </Typography>
    );
  }
  return (
    <Container maxWidth="lg">
      <Grid container direction="column">
        <Grid item container justifyContent="flex-start" alignItems="center" sx={{ pb: 1.5 }}>
          <Grid item>
            <Typography variant="h6" color="text.primary">
              Challan Details
            </Typography>
          </Grid>
        </Grid>
        <Grid item sx={{ maxWidth: '100% !important' }}>
          <Card variant="outlined" square>
            <CardContent>
              <Stack direction="row" gap={2} justifyContent="space-between" sx={{ pb: 2 }}>
                <IconButton color="neutral.700">
                  {flatChallanData?.challanDeatils?.lockStatus?.toUpperCase() === 'UnLocked' ? (
                    <LockOpenIcon />
                  ) : (
                    <LockIcon />
                  )}
                </IconButton>
                <Stack direction="row" gap={2} justifyContent="end">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => navigate(`/company/salary/challans/${challanId}/edit`)}
                    startIcon={<EditIcon fontSize="small" />}
                  >
                    Edit
                  </Button>
                  <DropdownMenu
                    menuLists={[
                      {
                        id: 1,
                        label: 'Edit',
                        onClick: () => {
                          navigate(`/company/salary/challans/${challanId}/edit`);
                        },
                      },
                      { id: 2, label: 'Delete', onClick: () => {} },
                      {
                        id: 3,
                        label: 'Add new',
                        onClick: () => {
                          navigate(`/company/salary/challans/add`);
                        },
                      },
                    ]}
                  />
                </Stack>
              </Stack>
              {challan.isLoading ? (
                <Box sx={{ py: 4 }}>
                  <Skeleton height={42} />
                  <Skeleton />
                  <Skeleton />
                </Box>
              ) : (
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <BasicChallanDetails challanDeatils={flatChallanData.challanDeatils} />
                  </Grid>
                  {flatChallanData?.challanDeatils?.challanType === 'TDS+Challan' && (
                    <>
                      <Grid item>
                        <Divider />
                      </Grid>
                      <Grid>
                        <TdsPaymentDetails tdsPaymentDeatils={flatChallanData.tdsPaymentDeatils} />
                      </Grid>
                    </>
                  )}
                  {flatChallanData?.challanDeatils?.challanType === 'EPF Challan' && (
                    <>
                      <Grid item>
                        <Divider />
                      </Grid>
                      <Grid item>
                        <EpfChallanDetails epfChallanDetails={flatChallanData.epfChallanDetails} />
                      </Grid>
                    </>
                  )}
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ViewChallan;
