import React, { useEffect } from 'react';
import { Box, Grid, Divider, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import Button from 'components/Atoms/Button';
import Typographys from 'components/Atoms/Typography';
import InputField from 'components/Atoms/InputField';
import Dropdown from 'components/Molecules/Dropdown';
import DatePicker from 'components/Atoms/Datepicker';
import { useFormik } from 'formik';
import UploadProfile from 'components/Molecules/UploadProfile';
import * as yup from 'yup';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { validatePANNumber, validateAadhaarNumber, validateUanNumber, validateUHIDNumber } from 'utils/commonUtils';
import { addEmpStyle } from '../style';

const BasicProfile = ({
  tabHeading,
  subHeading,
  handleSubmit,
  handleCancel,
  empTypeOptions,
  maritalOptions,
  empStatusOptions,
  supervisorOptions,
  genderOptions,
  locationOptions,
  groupOptions,
  departmentOptions,
  designationOptions,
  propData,
  label,
}) => {
  const defaultValues = propData.allTabsData['Basic Profile'];

  const SignupSchema = yup.object().shape({
    gender: yup.string().required('Required'),
    fatherName: yup.string().required('Required'),
    dateOfBirth: yup.string().required('Required'),
    dateOfJoining: yup.string().required('Required'),
    employmentType: yup.string().required('Required'),
    employmentStatus: yup.string().required('Required'),
    designation: yup.string().required('Required'),
    department: yup.string().required('Required'),
    panNumber: yup
      .string()
      .required('Required')
      .transform((val) => validatePANNumber(val, true)),
    aadharNumber: yup
      .string()
      .required('Required')
      .transform((val) => validateAadhaarNumber(val, true)),
    uan: yup.string().transform((val) => validateUanNumber(val)),
    uhid: yup.string().transform((val) => validateUHIDNumber(val)),
  });

  const formik = useFormik({
    initialValues: Object.keys(defaultValues).length
      ? defaultValues
      : {
          gender: '',
          fatherName: '',
          Password: '',
          motherName: '',
          maritalStatus: '',
          spouceName: '',
          dateOfBirth: '',
          dateOfJoining: '',
          employmentType: 'Candidate',
          employmentStatus: 'ACTIVE',
          designation: '',
          department: '',
          supervisor: '',
          location: '',
          uploadProfile: '',
          groups: '',
          panNumber: '',
          aadharNumber: '',
          uan: '',
          uhid: '',
          epf: '',
          eps: '',
          esi: '',
        },

    validationSchema: SignupSchema,
    validateOnMount: true,
    onSubmit: handleSubmit,
  });

  const handleDateOfBirthChange = (date) => {
    formik.setFieldValue('dateOfBirth', date);
  };

  const handleDateOfJoiningChange = (date) => {
    formik.setFieldValue('dateOfJoining', date);
  };

  useEffect(() => {
    propData.updateFormData(formik.values, label);
  }, [formik.values, label]);

  const theme = useTheme();
  return (
    <Box sx={{ p: '2rem 3rem' }}>
      <Box mt={2} component="form" onSubmit={formik.handleSubmit}>
        <PerfectScrollbar>
          <Box sx={{ maxHeight: 'calc(100vh - 53rem)' }}>
            <Typographys variant="h5" color="neutral.800">
              {tabHeading}
            </Typographys>
            <Box my={2}>
              <UploadProfile
                handleChange={(event) => {
                  formik.setFieldValue(`uploadProfile`, event.target.files[0]);
                }}
                name="uploadProfile"
                id="uploadProfile"
                value={formik.values.uploadProfile}
              />
            </Box>

            <Grid container rowSpacing={2} columnSpacing={9} mt={0} sx={addEmpStyle.formContainer}>
              <Grid item xs={12} md={4}>
                <Dropdown
                  error={Boolean(formik.touched.gender && formik.errors.gender)}
                  helperText={formik.touched.gender && formik.errors.gender}
                  handleChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.gender}
                  name="gender"
                  id="gender"
                  label="Gender*"
                  options={genderOptions}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.fatherName && formik.errors.fatherName)}
                  helperText={formik.touched.fatherName && formik.errors.fatherName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fatherName}
                  name="fatherName"
                  id="fatherName"
                  label="Father’s Name*"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.motherName && formik.errors.motherName)}
                  helperText={formik.touched.motherName && formik.errors.motherName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.motherName}
                  name="motherName"
                  id="motherName"
                  label="Mother’s Name"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Dropdown
                  error={Boolean(formik.touched.maritalStatus && formik.errors.maritalStatus)}
                  helperText={formik.touched.maritalStatus && formik.errors.maritalStatus}
                  handleChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.maritalStatus}
                  name="maritalStatus"
                  id="maritalStatus"
                  label="Marital Status"
                  options={maritalOptions}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.spouceName && formik.errors.spouceName)}
                  helperText={formik.touched.spouceName && formik.errors.spouceName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.spouceName}
                  name="spouceName"
                  id="spouceName"
                  label="Spouce Name"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <DatePicker
                  error={
                    Boolean(formik.touched.dateOfBirth && formik.errors.dateOfBirth) ||
                    (formik.touched.dateOfBirth && !formik.values.dateOfBirth)
                  }
                  helperText={formik.touched.dateOfBirth && formik.errors.dateOfBirth}
                  onChange={handleDateOfBirthChange}
                  onBlur={formik.handleBlur}
                  value={formik.values?.dateOfBirth}
                  name="dateOfBirth"
                  id="dateOfBirth"
                  label="Date Of Birth*"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <DatePicker
                  error={
                    Boolean(formik.touched.dateOfJoining && formik.errors.dateOfJoining) ||
                    (formik.touched.dateOfJoining && !formik.values.dateOfJoining)
                  }
                  helperText={formik.touched.dateOfJoining && formik.errors.dateOfJoining}
                  onChange={handleDateOfJoiningChange}
                  onBlur={formik.handleBlur}
                  value={formik.values?.dateOfJoining}
                  name="dateOfJoining"
                  id="dateOfJoining"
                  label="Date of Joining*"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Dropdown
                  error={Boolean(formik.touched.employmentType && formik.errors.employmentType)}
                  helperText={formik.touched.employmentType && formik.errors.employmentType}
                  handleChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.employmentType}
                  name="employmentType"
                  id="employmentType"
                  label="Employment Type*"
                  options={empTypeOptions}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Dropdown
                  error={Boolean(formik.touched.employmentStatus && formik.errors.employmentStatus)}
                  helperText={formik.touched.employmentStatus && formik.errors.employmentStatus}
                  handleChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.employmentStatus}
                  name="employmentStatus"
                  id="employmentStatus"
                  label="Employment Status*"
                  options={empStatusOptions}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Dropdown
                  error={Boolean(formik.touched.groups && formik.errors.groups)}
                  helperText={formik.touched.groups && formik.errors.groups}
                  handleChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.groups}
                  name="groups"
                  id="groups"
                  label="Groups"
                  options={groupOptions}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Dropdown
                  error={Boolean(formik.touched.designation && formik.errors.designation)}
                  helperText={formik.touched.designation && formik.errors.designation}
                  handleChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.designation}
                  name="designation"
                  id="designation"
                  label="Designation*"
                  options={designationOptions}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Dropdown
                  error={Boolean(formik.touched.department && formik.errors.department)}
                  helperText={formik.touched.department && formik.errors.department}
                  handleChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.department}
                  name="department"
                  id="department"
                  label="Department*"
                  options={departmentOptions}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Dropdown
                  error={Boolean(formik.touched.supervisor && formik.errors.supervisor)}
                  helperText={formik.touched.supervisor && formik.errors.supervisor}
                  handleChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.supervisor}
                  name="supervisor"
                  id="supervisor"
                  label="Supervisor"
                  options={supervisorOptions}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Dropdown
                  error={Boolean(formik.touched.location && formik.errors.location)}
                  helperText={formik.touched.location && formik.errors.location}
                  handleChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.location}
                  name="location"
                  id="location"
                  label="Location"
                  options={locationOptions}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

            <Typographys variant="h5" color="neutral.800">
              {subHeading}
            </Typographys>

            <Grid mt={2} container rowSpacing={2} columnSpacing={9} sx={addEmpStyle.formContainer}>
              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.panNumber && formik.errors.panNumber)}
                  helperText={formik.touched.panNumber && formik.errors.panNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.panNumber}
                  name="panNumber"
                  id="panNumber"
                  label="PAN Number*"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.aadharNumber && formik.errors.aadharNumber)}
                  helperText={formik.touched.aadharNumber && formik.errors.aadharNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.aadharNumber}
                  name="aadharNumber"
                  id="aadharNumber"
                  label="Aadhaar Number*"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.uan && formik.errors.uan)}
                  helperText={formik.touched.uan && formik.errors.uan}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.uan}
                  name="uan"
                  id="uan"
                  label="Universal Access Number(UAN)"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.uhid && formik.errors.uhid)}
                  helperText={formik.touched.uhid && formik.errors.uhid}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.uhid}
                  name="uhid"
                  id="uhid"
                  label="UHID Number"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.epf && formik.errors.epf)}
                  helperText={formik.touched.epf && formik.errors.epf}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.epf}
                  name="epf"
                  id="epf"
                  label="EPF Account Number"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.eps && formik.errors.eps)}
                  helperText={formik.touched.eps && formik.errors.eps}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.eps}
                  name="eps"
                  id="eps"
                  label="EPS Account Number"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  error={Boolean(formik.touched.esi && formik.errors.esi)}
                  helperText={formik.touched.esi && formik.errors.esi}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.esi}
                  name="esi"
                  id="esi"
                  label="ESI Account Number"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
        </PerfectScrollbar>

        <Box sx={addEmpStyle.btnContainer}>
          <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

          <Box sx={addEmpStyle.submitWrapper}>
            <Button
              size="large"
              color="info"
              variant="outlined"
              type="cancel"
              onClick={() => {
                formik.resetForm();
                handleCancel();
              }}
            >
              Cancel
            </Button>

            <Button size="large" type="submit" isDisable={false}>
              {propData.allowEdit ? 'Update' : 'Next'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

BasicProfile.defaultProps = {
  tabHeading: 'Basic Information',
  propData: {},
  subHeading: 'Statutory Identification Details',
  handleSubmit: () => {},
  handleCancel: () => {},
  empStatusOptions: [],
  genderOptions: [],
  maritalOptions: [],
  groupOptions: [],
  supervisorOptions: [],
  departmentOptions: [],
  locationOptions: [],
  designationOptions: [],
  empTypeOptions: [],
  label: '',
};

BasicProfile.propTypes = {
  tabHeading: PropTypes.string,
  propData: PropTypes.shape({
    updateFormData: PropTypes.func,
    allTabsData: PropTypes.shape({
      'Basic Profile': PropTypes.shape({}),
    }),
    allowEdit: PropTypes.bool,
  }),
  subHeading: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  genderOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  maritalOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  empTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  empStatusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  groupOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  supervisorOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  locationOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  designationOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  departmentOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  label: PropTypes.string,
};

export default BasicProfile;
