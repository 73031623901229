import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, useTheme } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from 'components/Atoms/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import NextImg from 'assets/images/icons/default.svg';
import DoneImg from 'assets/images/icons/done.svg';
import CurrentImg from 'assets/images/icons/current.svg';

import { stepperStyles } from './style';

const HorizontalStepper = ({
  steps,
  lastStepBtn,
  handleComplete,
  onNext,
  navigateToHome,
  formikInstance,
  validationEnabled,
  disableTabClick,
  isButtonVisible,
  isEdit,
  handleDelete,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});

  const handleStep = (step) => {
    setActiveStep(step);
  };

  const totalSteps = () => {
    return steps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    const newCompleted = { ...completed };
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);

    if (isLastStep()) {
      handleComplete();
    } else {
      if (validationEnabled) {
        formikInstance.validateForm(formikInstance.values);
      }
      if (
        (validationEnabled && formikInstance && Object.keys(formikInstance.errors).length) ||
        (validationEnabled && formikInstance && !Object.keys(formikInstance.values).length)
      ) {
        return;
      }

      onNext();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStepIcon = (index, isStepCompleted) => {
    if (isStepCompleted) {
      return <img src={DoneImg} alt="Done" />;
    }
    if (index === activeStep) {
      return <img src={CurrentImg} alt="Current" />;
    }
    return <img src={NextImg} alt="Next" />;
  };

  const theme = useTheme();
  return (
    <Box sx={stepperStyles.wrapper} variant="outlined">
      <Box sx={stepperStyles.stepWrapper}>
        <Stepper nonLinear activeStep={activeStep} sx={stepperStyles}>
          {steps.map(({ label }, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton
                sx={stepperStyles.stepButton}
                onClick={() => {
                  if (!disableTabClick) {
                    handleStep(index);
                  }
                }}
                icon={getStepIcon(index, completed[index])}
                disableTouchRipple
              >
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Box>

      <Box sx={stepperStyles.contentWrapper}>{steps[activeStep].content}</Box>

      {isButtonVisible && (
        <Box sx={stepperStyles.btnWrapper}>
          <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

          <Box gap={2} sx={stepperStyles.stepBtnWrapper}>
            {isEdit && (
              <Button
                color="error"
                variant="outlined"
                startIcon={<DeleteIcon fontSize="2.5rem" />}
                size="large"
                type="submit"
                onClick={handleDelete}
              >
                Delete
              </Button>
            )}
            <Box sx={stepperStyles.submitButtons}>
              <Button
                size="large"
                color="info"
                variant="outlined"
                type="cancel"
                onClick={() => {
                  if (activeStep) {
                    handleBack();
                  } else {
                    navigateToHome();
                  }
                }}
              >
                Cancel
              </Button>
              <Button size="large" type="submit" onClick={handleNext}>
                {isLastStep() ? lastStepBtn : 'Next'}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

HorizontalStepper.defaultProps = {
  lastStepBtn: '',
  handleComplete: () => {},
  steps: [],
  onNext: () => {},
  navigateToHome: () => {},
  formikInstance: {},
  validationEnabled: false,
  disableTabClick: true,
  isButtonVisible: true,
  isEdit: false,
  handleDelete: () => {},
};

HorizontalStepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    }),
  ),
  lastStepBtn: PropTypes.string,
  handleComplete: PropTypes.func,
  navigateToHome: PropTypes.func,
  onNext: PropTypes.func,
  formikInstance: PropTypes.shape({
    validateForm: PropTypes.func,
    values: PropTypes.shape({}),
    errors: PropTypes.shape({}),
  }),
  validationEnabled: PropTypes.bool,
  disableTabClick: PropTypes.bool,
  isButtonVisible: PropTypes.bool,
  isEdit: PropTypes.bool,
  handleDelete: PropTypes.func,
};

export default HorizontalStepper;
