import React from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import LifeCycleRecord from 'components/Organisms/Employee/LifeCycleRecord';

const LCRecordTemplate = ({
  pageHeading,
  filterFields,
  handleFilter,
  lcRecordRows,
  lcRecordColumns,
  lcRecordRowsPerPage,
  lcRecordTotalRecordCount,
  lcRecordLoadNextPageData,
  lcRecordHandleSort,
  lcRecordHandleRowClick,
  lcRecordPage,
  lcRecordSortBy,
  lcRecordSortDirection,
  lcRecordHandleSelect,
  lcRecordOnSelectedChange,
  actionMenuLists,
  handleFiltersClear,
  isLoadingEmployees,
  showPagination,
  isFilter,
}) => {
  return (
    <BlankTemplate pageHeading={pageHeading} isBreadcrumb={false} padding="2rem 3rem">
      <LifeCycleRecord
        loading={isLoadingEmployees}
        filterFields={filterFields}
        handleFilter={handleFilter}
        lcRecordRows={lcRecordRows}
        lcRecordColumns={lcRecordColumns}
        lcRecordRowsPerPage={lcRecordRowsPerPage}
        lcRecordTotalRecordCount={lcRecordTotalRecordCount}
        lcRecordLoadNextPageData={lcRecordLoadNextPageData}
        lcRecordHandleSort={lcRecordHandleSort}
        lcRecordHandleRowClick={lcRecordHandleRowClick}
        lcRecordPage={lcRecordPage}
        lcRecordSortBy={lcRecordSortBy}
        lcRecordSortDirection={lcRecordSortDirection}
        lcRecordHandleSelect={lcRecordHandleSelect}
        lcRecordOnSelectedChange={lcRecordOnSelectedChange}
        actionMenuLists={actionMenuLists}
        handleFiltersClear={handleFiltersClear}
        showPagination={showPagination}
        isFilter={isFilter}
      />
    </BlankTemplate>
  );
};

LCRecordTemplate.defaultProps = {
  pageHeading: 'List Life Cycle Case Records',
  filterFields: [],
  handleFilter: () => {},
  lcRecordRows: [],
  lcRecordColumns: [],
  lcRecordRowsPerPage: 5,
  lcRecordTotalRecordCount: 5,
  lcRecordLoadNextPageData: () => {},
  lcRecordHandleSort: () => {},
  lcRecordHandleRowClick: () => {},
  lcRecordPage: 1,
  lcRecordSortBy: '',
  lcRecordSortDirection: 'asc',
  lcRecordHandleSelect: () => {},
  lcRecordOnSelectedChange: () => {},
  actionMenuLists: [
    { name: 'Edit', onClick: () => {} },
    { name: 'Delete', onClick: () => {} },
    { name: 'Submit', onClick: () => {} },
  ],
  isLoadingEmployees: false,
  handleFiltersClear: () => {},
  showPagination: false,
  isFilter: false,
};

LCRecordTemplate.propTypes = {
  isLoadingEmployees: PropTypes.bool,
  pageHeading: PropTypes.string,
  filterFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  handleFilter: PropTypes.func,
  lcRecordRows: PropTypes.arrayOf,
  lcRecordColumns: PropTypes.arrayOf,
  lcRecordRowsPerPage: PropTypes.number,
  lcRecordTotalRecordCount: PropTypes.number,
  lcRecordLoadNextPageData: PropTypes.func,
  lcRecordHandleSort: PropTypes.func,
  lcRecordHandleRowClick: PropTypes.func,
  lcRecordPage: PropTypes.number,
  lcRecordSortBy: PropTypes.string,
  lcRecordSortDirection: PropTypes.oneOf(['asc', 'desc']),
  lcRecordHandleSelect: PropTypes.func,
  lcRecordOnSelectedChange: PropTypes.func,
  actionMenuLists: PropTypes.arrayOf,
  handleFiltersClear: PropTypes.func,
  showPagination: PropTypes.bool,
  isFilter: PropTypes.bool,
};

export default LCRecordTemplate;
