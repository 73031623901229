import React, { useEffect } from 'react';
import { Box, Grid, Divider, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import Button from 'components/Atoms/Button';
import Typographys from 'components/Atoms/Typography';
import InputField from 'components/Atoms/InputField/index';
import DatePicker from 'components/Atoms/Datepicker';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Formik, FieldArray, getIn } from 'formik';
import Dropdown from 'components/Molecules/Dropdown';
import PerfectScrollbar from 'react-perfect-scrollbar';
import * as yup from 'yup';
import { genericEmailRegex, genericMobileRegex } from 'utils/commonUtils';
import { addEmpStyle } from '../../style';

const DependentAndNominatationDetails = ({
  tabHeading,
  handleSubmit,
  handleCancel,
  propData,
  label,
  updateBtnText,
  genderOptions,
  relationOptions,
  bloodOptions,
}) => {
  const defaultObj = {
    gender: '',
    firstName: '',
    lastName: '',
    relation: '',
    dateOfBirth: '',
    mobileNumber: '',
    emailId: '',
    epfPerc: '',
    epsPerc: '',
    insurancePerc: '',
    otherPerc: '',
    gratuityPerc: '',
    emergencyContact: '',
    bloodGroup: '',
  };

  const defaultValues =
    propData && propData.allTabsData && propData.allTabsData[label]
      ? propData.allTabsData[label]
      : { dependent: [defaultObj] };
  const initialValues = defaultValues?.dependent?.length ? defaultValues : { dependent: [defaultObj] };

  const percentageValidation = yup
    .number()
    .min(0, 'Value must be greater than or equal to 0')
    .max(100, 'Value cannot be more than 100');

  const dependentValidationSchema = yup.object().shape({
    dependent: yup.array().of(
      yup.object().shape({
        firstName: yup.string().required('Required'),
        lastName: yup.string().required('Required'),
        gender: yup.string().required('Required'),
        relation: yup.string().required('Required'),
        dateOfBirth: yup.string().required('Required'),
        emailId: yup.string().matches(genericMobileRegex, 'Invalid format, Please enter valid mobile number.'),
        mobileNumber: yup.string().matches(genericEmailRegex, 'Invalid format, Please enter valid email ID.'),
        epfPerc: percentageValidation,
        epsPerc: percentageValidation,
        insurancePerc: percentageValidation,
        otherPerc: percentageValidation,
        gratuityPerc: percentageValidation,
        bloodGroup: yup.string().required('Required'),
      }),
    ),
  });

  return (
    <Box mt={1.5}>
      <Typographys variant="h5" color="neutral.800">
        {tabHeading}
      </Typographys>
      <Formik
        initialValues={initialValues}
        validationSchema={dependentValidationSchema}
        onSubmit={(values, actions) => {
          handleSubmit(values);
          actions.setSubmitting(false);
        }}
      >
        {(formik) => (
          <Form
            formik={formik}
            handleCancel={handleCancel}
            propData={propData}
            label={label}
            defaultObj={defaultObj}
            updateBtnText={updateBtnText}
            genderOptions={genderOptions}
            relationOptions={relationOptions}
            bloodOptions={bloodOptions}
          />
        )}
      </Formik>
    </Box>
  );
};

const Form = ({
  formik,
  handleCancel,
  propData,
  label,
  defaultObj,
  updateBtnText,
  genderOptions,
  relationOptions,
  bloodOptions,
}) => {
  useEffect(() => {
    propData.updateFormData(formik.values, label);
  }, [formik.values, label]);

  const handleDateChange = (date, setFieldValue, label1) => {
    setFieldValue(label1, date);
  };

  const theme = useTheme();
  return (
    <Box mt={2} component="form" onSubmit={formik.handleSubmit}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 48.3rem)' }}>
          <FieldArray name="dependent">
            {({ push }) => (
              <>
                {formik.values.dependent.map((d, index) => {
                  return (
                    <Box sx={addEmpStyle.rowDivider}>
                      <Grid container mb={3} rowSpacing={2} columnSpacing={9} mt={0} sx={addEmpStyle.formContainer}>
                        <Grid item xs={12} md={6}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `dependent[${index}].firstName`) &&
                                getIn(formik.errors, `dependent[${index}].firstName`),
                            )}
                            helperText={
                              getIn(formik.touched, `dependent[${index}].firstName`) &&
                              getIn(formik.errors, `dependent[${index}].firstName`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={d.firstName}
                            name={`dependent[${index}].firstName`}
                            id={`dependent[${index}].firstName`}
                            label="First Name*"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `dependent[${index}].lastName`) &&
                                getIn(formik.errors, `dependent[${index}].lastName`),
                            )}
                            helperText={
                              getIn(formik.touched, `dependent[${index}].lastName`) &&
                              getIn(formik.errors, `dependent[${index}].lastName`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={d.lastName}
                            name={`dependent[${index}].lastName`}
                            id={`dependent[${index}].lastName`}
                            label="Last Name*"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Dropdown
                            error={Boolean(
                              getIn(formik.touched, `dependent[${index}].gender`) &&
                                getIn(formik.errors, `dependent[${index}].gender`),
                            )}
                            helperText={
                              getIn(formik.touched, `dependent[${index}].gender`) &&
                              getIn(formik.errors, `dependent[${index}].gender`)
                            }
                            handleChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={d.gender}
                            name={`dependent[${index}].gender`}
                            id={`dependent[${index}].gender`}
                            label="Gender*"
                            options={genderOptions}
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Dropdown
                            error={Boolean(
                              getIn(formik.touched, `dependent[${index}].relation`) &&
                                getIn(formik.errors, `dependent[${index}].relation`),
                            )}
                            helperText={
                              getIn(formik.touched, `dependent[${index}].relation`) &&
                              getIn(formik.errors, `dependent[${index}].relation`)
                            }
                            handleChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={d.relation}
                            name={`dependent[${index}].relation`}
                            id={`dependent[${index}].relation`}
                            label="Relation*"
                            options={relationOptions}
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <DatePicker
                            error={
                              Boolean(
                                getIn(formik.touched, `dependent[${index}].dateOfBirth`) &&
                                  getIn(formik.errors, `dependent[${index}].dateOfBirth`),
                              ) ||
                              (getIn(formik.touched, `dependent[${index}].dateOfBirth`) && !d.dateOfBirth)
                            }
                            helperText={
                              getIn(formik.touched, `dependent[${index}].dateOfBirth`) &&
                              getIn(formik.errors, `dependent[${index}].dateOfBirth`)
                            }
                            onChange={(data) =>
                              handleDateChange(data, formik.setFieldValue, `dependent[${index}].dateOfBirth`)
                            }
                            onBlur={formik.handleBlur}
                            value={d?.dateOfBirth}
                            name={`dependent[${index}].dateOfBirth`}
                            id={`dependent[${index}].dateOfBirth`}
                            label="Date Of Birth*"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Dropdown
                            error={Boolean(
                              getIn(formik.touched, `dependent[${index}].bloodGroup`) &&
                                getIn(formik.errors, `dependent[${index}].bloodGroup`),
                            )}
                            helperText={
                              getIn(formik.touched, `dependent[${index}].bloodGroup`) &&
                              getIn(formik.errors, `dependent[${index}].bloodGroup`)
                            }
                            handleChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={d.bloodGroup}
                            name={`dependent[${index}].bloodGroup`}
                            id={`dependent[${index}].bloodGroup`}
                            label="Blood group*"
                            options={bloodOptions}
                            fullWidth
                          />
                        </Grid>
                      </Grid>

                      <Typographys variant="h6" color="neutral.800">
                        Contact Details
                      </Typographys>

                      <Grid container mt={1} mb={3} rowSpacing={2} columnSpacing={9} sx={addEmpStyle.formContainer}>
                        <Grid item xs={12} md={6}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `dependent[${index}].mobileNumber`) &&
                                getIn(formik.errors, `dependent[${index}].mobileNumber`),
                            )}
                            helperText={
                              getIn(formik.touched, `dependent[${index}].mobileNumber`) &&
                              getIn(formik.errors, `dependent[${index}].mobileNumber`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={d.mobileNumber}
                            name={`dependent[${index}].mobileNumber`}
                            id={`dependent[${index}].mobileNumber`}
                            label="Mobile Number"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `dependent[${index}].emailId`) &&
                                getIn(formik.errors, `dependent[${index}].emailId`),
                            )}
                            helperText={
                              getIn(formik.touched, `dependent[${index}].emailId`) &&
                              getIn(formik.errors, `dependent[${index}].emailId`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={d.emailId}
                            name={`dependent[${index}].emailId`}
                            id={`dependent[${index}].emailId`}
                            label="Email ID"
                            fullWidth
                          />
                        </Grid>
                      </Grid>

                      <Typographys variant="h6" color="neutral.800">
                        Nomination Details
                      </Typographys>

                      <Grid container rowSpacing={2} mt={1} columnSpacing={9} sx={addEmpStyle.formContainer}>
                        <Grid item xs={12} md={6}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `dependent[${index}].epfPerc`) &&
                                getIn(formik.errors, `dependent[${index}].epfPerc`),
                            )}
                            helperText={
                              getIn(formik.touched, `dependent[${index}].epfPerc`) &&
                              getIn(formik.errors, `dependent[${index}].epfPerc`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={d.epfPerc}
                            name={`dependent[${index}].epfPerc`}
                            id={`dependent[${index}].epfPerc`}
                            label="EPF Nomination %"
                            type="number"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `dependent[${index}].epsPerc`) &&
                                getIn(formik.errors, `dependent[${index}].epsPerc`),
                            )}
                            helperText={
                              getIn(formik.touched, `dependent[${index}].epsPerc`) &&
                              getIn(formik.errors, `dependent[${index}].epsPerc`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={d.epsPerc}
                            name={`dependent[${index}].epsPerc`}
                            id={`dependent[${index}].epsPerc`}
                            label="EPS Nomination %"
                            type="number"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `dependent[${index}].insurancePerc`) &&
                                getIn(formik.errors, `dependent[${index}].insurancePerc`),
                            )}
                            helperText={
                              getIn(formik.touched, `dependent[${index}].insurancePerc`) &&
                              getIn(formik.errors, `dependent[${index}].insurancePerc`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={d.insurancePerc}
                            name={`dependent[${index}].insurancePerc`}
                            id={`dependent[${index}].insurancePerc`}
                            label="Insurance Nomination %"
                            type="number"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `dependent[${index}].otherPerc`) &&
                                getIn(formik.errors, `dependent[${index}].otherPerc`),
                            )}
                            helperText={
                              getIn(formik.touched, `dependent[${index}].otherPerc`) &&
                              getIn(formik.errors, `dependent[${index}].otherPerc`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={d.otherPerc}
                            name={`dependent[${index}].otherPerc`}
                            id={`dependent[${index}].otherPerc`}
                            label="Others Nomination %"
                            type="number"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `dependent[${index}].gratuityPerc`) &&
                                getIn(formik.errors, `dependent[${index}].gratuityPerc`),
                            )}
                            helperText={
                              getIn(formik.touched, `dependent[${index}].gratuityPerc`) &&
                              getIn(formik.errors, `dependent[${index}].gratuityPerc`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={d.gratuityPerc}
                            name={`dependent[${index}].gratuityPerc`}
                            id={`dependent[${index}].gratuityPerc`}
                            label="Gratuity  Nomination %"
                            type="number"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `dependent[${index}].emergencyContact`) &&
                                getIn(formik.errors, `dependent[${index}].emergencyContact`),
                            )}
                            helperText={
                              getIn(formik.touched, `dependent[${index}].emergencyContact`) &&
                              getIn(formik.errors, `dependent[${index}].emergencyContact`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={d.emergencyContact}
                            name={`dependent[${index}].emergencyContact`}
                            id={`dependent[${index}].emergencyContact`}
                            label="Emergency Contact Reference"
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })}
                <Box sx={{ mt: 2 }}>
                  <Button
                    size="medium"
                    variant="outlined"
                    type="button"
                    startIcon={<AddOutlinedIcon />}
                    onClick={() => push(defaultObj)}
                  >
                    Add Another
                  </Button>
                </Box>
              </>
            )}
          </FieldArray>
        </Box>
      </PerfectScrollbar>

      <Box sx={addEmpStyle.btnContainer}>
        <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

        <Box sx={addEmpStyle.submitWrapper}>
          <Button
            size="large"
            color="info"
            variant="outlined"
            type="cancel"
            onClick={() => {
              formik.resetForm();
              handleCancel();
            }}
          >
            Cancel
          </Button>

          <Button size="large" type="submit" isDisable={false}>
            {propData.allowEdit ? updateBtnText : 'Next'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

Form.defaultProps = {
  handleCancel: () => {},
  relationOptions: [],
  genderOptions: [],
  label: '',
  propData: {},
  formik: {},
  defaultObj: {},
  bloodOptions: [],
};

Form.propTypes = {
  propData: PropTypes.shape({
    loading: PropTypes.bool,
    updateFormData: PropTypes.func,
    allTabsData: PropTypes.shape({
      DEPENDENT: PropTypes.shape({
        dependent: PropTypes.shape([]),
      }),
    }),
    allowEdit: PropTypes.bool,
  }),
  handleCancel: PropTypes.func,
  relationOptions: PropTypes.shape([]),
  genderOptions: PropTypes.shape([]),
  bloodOptions: PropTypes.shape([]),
  label: PropTypes.string,
  formik: PropTypes.shape({
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    values: PropTypes.shape({
      dependent: PropTypes.shape([]),
    }),
    resetForm: PropTypes.func,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    setFieldValue: PropTypes.func,
  }),
  defaultObj: PropTypes.shape({}),
};

DependentAndNominatationDetails.defaultProps = {
  tabHeading: 'Dependents and Nomination Details',
  handleSubmit: () => {},
  handleCancel: () => {},
  propData: {},
  label: 'Dependents and Nomination Details',
  updateBtnText: 'Update',
  genderOptions: [],
  relationOptions: [],
  bloodOptions: [],
};

DependentAndNominatationDetails.propTypes = {
  tabHeading: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,

  relationOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  genderOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  bloodOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  propData: PropTypes.shape({
    updateFormData: PropTypes.func,
    allTabsData: PropTypes.shape({
      'Dependents and Nomination Details': PropTypes.shape({
        dependent: PropTypes.shape([]),
      }),
    }),
    allowEdit: PropTypes.bool,
  }),
  label: PropTypes.string,
  updateBtnText: PropTypes.string,
};

export default DependentAndNominatationDetails;
