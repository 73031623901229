export const TDSWorkingStyle = {
  sectionHeadingWithBtn: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardStyle: {
    backgroundColor: 'common.white',
    borderRadius: '0.75rem',
    padding: '3rem',
    border: 'solid 1px',
    borderColor: 'neutral.300',
  },

  categoryTableWrapper: {
    '.MuiTable-root': {
      '.MuiTableRow-root': {
        '.MuiTableCell-root': {
          p: 2,
          svg: {
            display: 'none',
          },
          '&:first-child': {
            minWidth: '30rem',
          },

          '.MuiTypography-root': {
            fontWeight: '400',
          },
          '&:last-child': {
            '.MuiTypography-body2': {
              fontWeight: '500',
              color: 'neutral.800',
              fontSize: '2rem',
            },
          },
        },

        '&:nth-last-child(-n + 2)': {
          backgroundColor: 'neutral.100',
          lineHeight: '3rem',
          fontWeight: '500',
          color: 'neutral.800',
          '.MuiTypography-subtitle2,.MuiTypography-body2': {
            fontSize: '2rem',
          },
        },
      },
    },
  },

  hraTableWrapper: {
    overflow: 'auto',
    border: '0.125rem solid',
    borderColor: 'other.200',
    borderRadius: '0.5rem',
  },

  hraTableRow: {
    display: 'flex',
    p: '1rem',
  },

  deductionTableWrapper: {
    border: '0.125rem solid',
    borderColor: 'other.200',
    borderRadius: '0.5rem',
    '.MuiTable-root': {
      '.MuiTableRow-root': {
        '.MuiTableCell-root': {
          p: 1,
        },
      },
      '.MuiTableHead-root .MuiTableCell-root': {
        p: '1rem !important',
        textTransform: 'capitalize',
      },
    },
  },

  deductionTableRows: {
    '&>td, &>th': {
      border: 0,
    },
  },

  deductionTableFooter: {
    display: 'flex',
    p: '1rem',
    borderTop: '0.125rem solid',
    borderColor: 'other.200',
  },

  headsTableHeader: {
    display: 'flex',
    p: '12px 8px',
    backgroundColor: 'info.200',
  },

  headsTableRows: {
    display: 'flex',
    p: '1rem',
  },

  headsTableSubTitleRows: {
    display: 'flex',
    p: '0.5rem 1rem',
  },
  headsTableSubTitleColumn: {
    '> p': {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },

  SalaryTableHeader: {
    display: 'flex',
    p: '12px 8px',
    backgroundColor: 'info.200',
  },

  SalaryTableRows: {
    display: 'flex',
    p: '1rem',
  },

  SalaryTableSubTitleRows: {
    display: 'flex',
    p: '0.5rem 1rem',
  },
  SalaryTableSubTitleColumn: {
    '> p': {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
};
