export const styles = {
  dividerWrapper: {
    width: '100%',
  },
  divider: {
    borderColor: 'text.disabled !important',
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
  },
  checkboxWrapper: {
    display: 'flex',
  },
  typography: {
    fontSize: 14,
  },
  header: {
    marginBottom: '20px',
  },
  input: {
    height: '48px',
    fontSize: '12px',
    fontWeight: '450',
    color: 'black',
  },
};
