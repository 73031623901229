import { createSlice } from '@reduxjs/toolkit';

const pastEmployerTaxes = createSlice({
  name: 'pastEmployerTaxes',
  initialState: {
    selectedPastEmployerTaxes: [],
    pastEmployerTaxes: {
      count: 0,
      data: [],
      states: {},
      extendedDef: {},
    },
  },
  reducers: {
    updatePastEmployerTaxes(state, action) {
      const { companyPayheadInstanceList = [], companyPayheadInstanceCount } = action.payload;
      state.pastEmployerTaxes.data = companyPayheadInstanceList;
      state.pastEmployerTaxes.count = companyPayheadInstanceCount;
    },
    updateSelectedPastEmployerTaxes(state, action) {
      const { isChecked, rowId } = action.payload;

      if (isChecked) {
        state.selectedPastEmployerTaxes.push(rowId);
        return;
      }
      state.selectedPastEmployerTaxes = state.selectedPastEmployerTaxes.filter((selectedRow) => selectedRow !== rowId);
    },
    updateAllSelectedPastEmployerTaxes(state, action) {
      const { isChecked } = action.payload;
      state.selectedPastEmployerTaxes = [];
      if (isChecked) {
        state.pastEmployerTaxes.data.map((row) => state.selectedPastEmployerTaxes.push(row.id));
        return;
      }
      state.selectedPastEmployerTaxes = [];
    },
    setBulkUpdatePaastEmployerTaxIds(state, action) {
      state.bulkUpdatePastEmployerTaxes = action.payload;
    },
  },
});

export const { updatePastEmployerTaxes, updateSelectedPastEmployerTaxes, updateAllSelectedPastEmployerTaxes } =
  pastEmployerTaxes.actions;

export default pastEmployerTaxes.reducer;
