import React, { useCallback, useEffect } from 'react';
import MyLeaveRequests from 'components/Templates/Leave/MyLeaves/MyLeaveRequests';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMyLeaveList } from 'store';
import { useThunk } from 'hooks/useThunk';
import useController from 'hooks/useController';
import usePaginationAttributes from 'hooks/use-pagination-attributes';
import Dialog from 'components/Molecules/Dialog';
import { updateSelectedLeave, updateMyLeaves } from 'store/slices/leaves/myLeaves';
import { listMyLeaveRequestsAction } from 'api/leave';
import { postListApiFormat, getListApiFormat } from 'utils/ApiPayloadFormat';
import useToast from 'components/Provider/useToast';
import { hasPermission } from 'utils/permissions';
import useColumns from './useColumns';
import useActionItems from './useActionItems';
import useLeaveServices from './useLeaveServices';
import useFilters from './useFilters';

const MyLeavesList = () => {
  const dispatch = useDispatch();
  const { pageSize } = usePaginationAttributes();
  const [columns] = useColumns();
  const [controller, handlePageChange, handleSortChange, handleFiltersApply, handleFiltersClear] = useController();
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasViewPermission = hasPermission(authorities, ['ROLE_BASE_PERMISSION_LEAVE_INDEX_VIEW_LEAVE_REQUEST']);
  const userHasCreateLeavePermission = hasPermission(authorities, [
    'ROLE_BASE_PERMISSION_LEAVE_INDEX_CREATE_MY_LEAVE_REQUEST',
  ]);
  const navigate = useNavigate();
  const addToast = useToast();
  const [doFetchMyLeave, isLoadingMyLeaves, loadingLeavesError] = useThunk(fetchMyLeaveList);
  const { selectedMyLeave, myLeaves, loading } = useSelector((state) => state?.myLeaves);

  const filterService = useFilters(controller, myLeaves);

  const fetchLeavesAction = useCallback(async () => {
    if (controller?.filters && Object.keys(controller?.filters).length > 0) {
      const formData = postListApiFormat(controller, pageSize);
      const response = await listMyLeaveRequestsAction(formData);
      if (response.status === 200 || response.message === 'Successfully done listMyLeaveRequestsAction') {
        dispatch(updateMyLeaves(response));
      } else {
        addToast({ type: 'error', title: 'ERROR', message: response?.message });
      }
    } else {
      const queryData = getListApiFormat(controller, pageSize);
      doFetchMyLeave(queryData);
    }
  }, [doFetchMyLeave, controller, pageSize]);

  useEffect(() => {
    fetchLeavesAction();
  }, [fetchLeavesAction, controller, pageSize, loading]);

  const leaveServices = useLeaveServices(fetchLeavesAction);
  const [menuList] = useActionItems(leaveServices);
  const handleCloseDialog = () => leaveServices.dispatchDialog({ type: 'Close' });

  if (loadingLeavesError !== null) {
    return <Typography variant="h6">{loadingLeavesError?.message || 'Error while loading leaves list.'}</Typography>;
  }

  return (
    <>
      <MyLeaveRequests
        loading={isLoadingMyLeaves}
        rows={myLeaves?.data}
        columns={columns}
        rowsPerPage={10}
        roleBasedMenu={menuList}
        count={myLeaves?.count}
        loadNextPageData={handlePageChange}
        handleSort={handleSortChange}
        page={controller.page}
        sortDirection={controller.sort}
        sortBy={controller.sortBy}
        pageHeading="My Leave Requests"
        handleSelect={(row) => {
          dispatch(updateSelectedLeave(row));
        }}
        handleRowClick={(row) => !!userHasViewPermission && leaveServices.viewLeave(row)}
        handleAddRequest={() => navigate('/home/leaves/my-leave-requests/add')}
        handleFiltersClear={handleFiltersClear}
        userHasCreateLeavePermission={userHasCreateLeavePermission}
        filterFields={filterService?.filterFields}
        handleFilter={(data) => handleFiltersApply(data)}
        showFilter
        showPagination
      />
      {selectedMyLeave !== null && (
        <>
          <Dialog
            open={leaveServices.dialogState.open === 'Delete'}
            dialogTitle="Delete Leave"
            dialogDesc={`Are you sure you want to delete ${selectedMyLeave?.leaveType}?`}
            primaryBtn="Delete"
            secondaryBtn="Cancel"
            primaryBtnColor="error"
            handleSecondaryBtn={handleCloseDialog}
            bodyContent={false}
            handlePrimaryBtn={() => leaveServices.deleteLeave(selectedMyLeave?.id, true)}
            handleClose={handleCloseDialog}
          />

          <Dialog
            open={leaveServices.dialogState.open === 'Submit'}
            dialogTitle="Submit Leave"
            dialogDesc={`Are you sure you want to submit ${selectedMyLeave?.leaveType}?`}
            primaryBtn="Submit"
            secondaryBtn="Cancel"
            handleSecondaryBtn={handleCloseDialog}
            bodyContent={false}
            handlePrimaryBtn={() => leaveServices.submitLeave({ id: selectedMyLeave?.id, refresh: true })}
            handleClose={handleCloseDialog}
          />

          <Dialog
            open={leaveServices.dialogState.open === 'Cancel'}
            dialogTitle="Cancel Leave Request"
            dialogDesc={`Are you sure you want to cancel ${selectedMyLeave?.leaveType}?`}
            primaryBtn="Confirm"
            secondaryBtn="Cancel"
            handleSecondaryBtn={handleCloseDialog}
            bodyContent={false}
            handlePrimaryBtn={() => leaveServices.cancelLeave(selectedMyLeave?.id, selectedMyLeave?.requestState, true)}
            handleClose={handleCloseDialog}
          />
        </>
      )}
    </>
  );
};

export default MyLeavesList;
