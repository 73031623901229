/* eslint-disable array-callback-return */
import dayjs from 'dayjs';

export const formatBody = ({ data, formattedRecord, record }) => {
  const educationFormikData = data?.education;

  const setValue = (fieldValue, valueFromAPI) => {
    return fieldValue || fieldValue === '' ? fieldValue : valueFromAPI;
  };

  const educationBody = {};
  if (educationFormikData?.length) {
    educationFormikData.map((educationData, index) => {
      educationBody[`educationRecords[${index}].institutionName`] = educationData?.institutionName || '';
      educationBody[`educationRecords[${index}].certificationName`] = educationData?.certificationName || '';
      educationBody[`educationRecords[${index}].startDate`] = educationData.startDate
        ? dayjs(educationData.startDate).format('MM/DD/YYYY')
        : '';
      educationBody[`educationRecords[${index}].endDate`] = educationData.endDate
        ? dayjs(educationData.endDate).format('MM/DD/YYYY')
        : '';
      educationBody[`educationRecords[${index}].specialization`] = educationData?.specialization || '';
      educationBody[`educationRecords[${index}].registrationNumber`] = educationData?.registrationNumber || '';
      educationBody[`educationRecords[${index}].referenceName`] = educationData?.referenceName || '';
      educationBody[`educationRecords[${index}].referencePhoneNumber`] = educationData?.referenceNumber || '';
      educationBody[`educationRecords[${index}].referenceEmail`] = educationData?.referenceEmail || '';
      educationBody[`educationRecords[${index}].comments`] = educationData?.comments || '';
      if (educationData?.attachments && !(educationData?.attachments instanceof Array)) {
        educationBody[`educationRecords[${index}].attachment`] = educationData?.attachments || '';
      } else if (!educationData?.isNew) {
        educationBody[`educationRecords[${index}].id`] =
          formattedRecord?.['ADDITIONAL DETAILS']?.['Education/Certification History']?.education?.[index]?.id || '';
      }
    });
    educationBody.eduRecordCtr = educationFormikData?.length;
  } else {
    formattedRecord?.['ADDITIONAL DETAILS']?.['Education/Certification History']?.education.map(
      (educationData, index) => {
        educationBody[`educationRecords[${index}].institutionName`] = educationData?.institutionName || '';
        educationBody[`educationRecords[${index}].certificationName`] = educationData?.certificationName || '';
        educationBody[`educationRecords[${index}].startDate`] = educationData.startDate
          ? dayjs(educationData.startDate).format('MM/DD/YYYY')
          : '';
        educationBody[`educationRecords[${index}].endDate`] = educationData.endDate
          ? dayjs(educationData.endDate).format('MM/DD/YYYY')
          : '';
        educationBody[`educationRecords[${index}].specialization`] = educationData?.specialization || '';
        educationBody[`educationRecords[${index}].registrationNumber`] = educationData?.registrationNumber || '';
        educationBody[`educationRecords[${index}].referenceName`] = educationData?.referenceName || '';
        educationBody[`educationRecords[${index}].referencePhoneNumber`] = educationData?.referenceNumber || '';
        educationBody[`educationRecords[${index}].referenceEmail`] = educationData?.referenceEmail || '';
        educationBody[`educationRecords[${index}].comments`] = educationData?.comments || '';
        educationBody[`educationRecords[${index}].id`] =
          formattedRecord?.['ADDITIONAL DETAILS']?.['Education/Certification History']?.education?.[index]?.id || '';
      },
    );
    educationBody.eduRecordCtr =
      formattedRecord['ADDITIONAL DETAILS']['Education/Certification History'].education?.length;
  }

  const employmentFormikData = data?.employment;
  const employmentBody = {};
  if (employmentFormikData?.length) {
    employmentFormikData.map((employment, index) => {
      employmentBody[`pastEmploymentRecords[${index}].employerName`] = employment?.employerName || '';
      employmentBody[`pastEmploymentRecords[${index}].employerAddress`] = employment?.employerAddress || '';
      employmentBody[`pastEmploymentRecords[${index}].employmentCommenceDate`] = employment.employmentCommenceDate
        ? dayjs(employment.employmentCommenceDate).format('MM/DD/YYYY')
        : '';
      employmentBody[`pastEmploymentRecords[${index}].employmentEndDate`] = employment.employmentEndDate
        ? dayjs(employment.employmentEndDate).format('MM/DD/YYYY')
        : '';
      employmentBody[`pastEmploymentRecords[${index}].reasonForLeaving`] = employment?.reasonForLeaving || '';
      employmentBody[`pastEmploymentRecords[${index}].employeeID`] = employment?.employeeId || '';
      employmentBody[`pastEmploymentRecords[${index}].startDesignation`] = employment?.startDesignation || '';
      employmentBody[`pastEmploymentRecords[${index}].endDesignation`] = employment?.endDesignation || '';
      employmentBody[`pastEmploymentRecords[${index}].referenceContactName`] = employment?.referenceContactName || '';
      employmentBody[`pastEmploymentRecords[${index}].referenceContactDesignation`] =
        employment.referenceContactDesignation;
      employmentBody[`pastEmploymentRecords[${index}].referenceContactPhone`] = employment?.referenceContactPhone;
      employmentBody[`pastEmploymentRecords[${index}].referenceContactEmail`] = employment?.referenceContactEmail;
      if (employment?.attachments && !(employment?.attachments instanceof Array)) {
        employmentBody[`pastEmploymentRecords[${index}].attachment`] = employment?.attachments || '';
      } else if (!employment?.isNew) {
        employmentBody[`pastEmploymentRecords[${index}].id`] =
          formattedRecord?.['ADDITIONAL DETAILS']['Employment History'].employment?.[index]?.id || '';
      }
    });
    employmentBody.empRecordCtr = employmentFormikData?.length;
  } else {
    formattedRecord?.['ADDITIONAL DETAILS']?.['Employment History']?.employment.map((employment, index) => {
      employmentBody[`pastEmploymentRecords[${index}].employerName`] = employment?.employerName || '';
      employmentBody[`pastEmploymentRecords[${index}].employerAddress`] = employment?.employerAddress || '';
      employmentBody[`pastEmploymentRecords[${index}].employmentCommenceDate`] = employment.employmentCommenceDate
        ? dayjs(employment.employmentCommenceDate).format('MM/DD/YYYY')
        : '';
      employmentBody[`pastEmploymentRecords[${index}].employmentEndDate`] = employment.employmentEndDate
        ? dayjs(employment.employmentEndDate).format('MM/DD/YYYY')
        : '';
      employmentBody[`pastEmploymentRecords[${index}].reasonForLeaving`] = employment?.reasonForLeaving || '';
      employmentBody[`pastEmploymentRecords[${index}].employeeID`] = employment?.employeeId || '';
      employmentBody[`pastEmploymentRecords[${index}].startDesignation`] = employment?.startDesignation || '';
      employmentBody[`pastEmploymentRecords[${index}].endDesignation`] = employment?.endDesignation || '';
      employmentBody[`pastEmploymentRecords[${index}].referenceContactName`] = employment?.referenceContactName || '';
      employmentBody[`pastEmploymentRecords[${index}].referenceContactDesignation`] =
        employment?.referenceContactDesignation || '';
      employmentBody[`pastEmploymentRecords[${index}].referenceContactPhone`] = employment?.referenceContactPhone || '';
      employmentBody[`pastEmploymentRecords[${index}].referenceContactEmail`] = employment?.referenceContactEmail || '';
      employmentBody[`pastEmploymentRecords[${index}].id`] =
        formattedRecord?.['ADDITIONAL DETAILS']['Employment History'].employment?.[index]?.id || '';
    });
    employmentBody.empRecordCtr = formattedRecord['ADDITIONAL DETAILS']['Employment History'].employment?.length;
  }
  const visaFormikData = data?.visa;
  const visaBody = {};
  if (visaFormikData?.length) {
    visaFormikData.map((visa, index) => {
      visaBody[`visaRecords[${index}].issuingCountry`] = visa?.issuingCountry || '';
      visaBody[`visaRecords[${index}].placeOfIssue`] = visa?.placeOfIssue || '';
      visaBody[`visaRecords[${index}].validFrom`] = visa?.validFrom ? dayjs(visa?.validFrom).format('MM/DD/YYYY') : '';
      visaBody[`visaRecords[${index}].validTo`] = visa?.validTo ? dayjs(visa?.validTo).format('MM/DD/YYYY') : '';
      visaBody[`visaRecords[${index}].issueDate`] = visa?.issueDate ? dayjs(visa?.issueDate).format('MM/DD/YYYY') : '';
      visaBody[`visaRecords[${index}].referenceNumber`] = visa?.referenceNumber || '';
      visaBody[`visaRecords[${index}].status`] = visa?.visaStatus || '';
      visaBody[`visaRecords[${index}].comments`] = visa?.comments || '';
      if (visa?.attachments && !(visa?.attachments instanceof Array)) {
        visaBody[`visaRecords[${index}].attachment`] = visa?.attachments || '';
      } else if (!visa?.isNew) {
        visaBody[`visaRecords[${index}].id`] =
          formattedRecord?.['ADDITIONAL DETAILS']['Visa Details'].visa?.[index]?.id || '';
      }
    });
    visaBody.visaRecordCtr = visaFormikData?.length;
  } else {
    formattedRecord?.['ADDITIONAL DETAILS']?.['Visa Details']?.visa.map((visa, index) => {
      visaBody[`visaRecords[${index}].issuingCountry`] = visa?.issuingCountry || '';
      visaBody[`visaRecords[${index}].placeOfIssue`] = visa?.placeOfIssue || '';
      visaBody[`visaRecords[${index}].validFrom`] = visa?.validFrom ? dayjs(visa?.validFrom).format('MM/DD/YYYY') : '';
      visaBody[`visaRecords[${index}].validTo`] = visa?.validTo ? dayjs(visa?.validTo).format('MM/DD/YYYY') : '';
      visaBody[`visaRecords[${index}].issueDate`] = visa?.issueDate ? dayjs(visa?.issueDate).format('MM/DD/YYYY') : '';
      visaBody[`visaRecords[${index}].referenceNumber`] = visa?.referenceNumber || '';
      visaBody[`visaRecords[${index}].status`] = visa?.visaStatus || '';
      visaBody[`visaRecords[${index}].comments`] = visa?.comments || '';
      visaBody[`visaRecords[${index}].id`] =
        formattedRecord?.['ADDITIONAL DETAILS']['Visa Details'].visa?.[index]?.id || '';
    });
    visaBody.visaRecordCtr = formattedRecord['ADDITIONAL DETAILS']['Visa Details'].visa?.length;
  }
  const dependentFormikData = data?.dependent;
  const dependentBody = {};
  if (dependentFormikData?.length) {
    dependentFormikData.map((dependent, index) => {
      dependentBody[`dependentDetails[${index}].contactInfo.firstName`] = dependent?.firstName || '';
      dependentBody[`dependentDetails[${index}].contactInfo.lastName`] = dependent?.lastName || '';
      dependentBody[`dependentDetails[${index}].contactInfo.gender`] = dependent?.gender || '';
      dependentBody[`dependentDetails[${index}].dateOfBirth`] = dependent?.dateOfBirth
        ? dayjs(dependent?.dateOfBirth).format('MM/DD/YYYY')
        : '';
      dependentBody[`dependentDetails[${index}].relationship`] = dependent?.relation || '';
      dependentBody[`dependentDetails[${index}].contactInfo.emailAddress`] = dependent?.emailId || '';
      dependentBody[`dependentDetails[${index}].contactInfo.mobileNumber`] = dependent?.mobileNumber || '';
      dependentBody[`dependentDetails[${index}].epfNominationPercentage`] = dependent?.epfPerc || '0.0';
      dependentBody[`dependentDetails[${index}].epsNominationPercentage`] = dependent?.epsPerc || '0.0';
      dependentBody[`dependentDetails[${index}].insuranceNominationPercentage`] = dependent?.insurancePerc || '0.0';
      dependentBody[`dependentDetails[${index}].othersNominationPercentage`] = dependent?.otherPerc || '0.0';
      dependentBody[`dependentDetails[${index}].gratuityNominationPercentage`] = dependent?.gratuityPerc || '0.0';
      dependentBody[`dependentDetails[${index}].emergencyContactReference`] = dependent?.emergencyContact || '';
    });
    dependentBody.dependentsCtr = dependentFormikData?.length;
  } else {
    (formattedRecord?.DEPENDENT?.dependent || [])?.map((dependent, index) => {
      dependentBody[`dependentDetails[${index}].contactInfo.firstName`] = dependent?.firstName || '';
      dependentBody[`dependentDetails[${index}].contactInfo.lastName`] = dependent?.lastName || '';
      dependentBody[`dependentDetails[${index}].contactInfo.gender`] = dependent?.gender || '';
      dependentBody[`dependentDetails[${index}].dateOfBirth`] = dependent?.dateOfBirth
        ? dayjs(dependent?.dateOfBirth).format('MM/DD/YYYY')
        : '';
      dependentBody[`dependentDetails[${index}].relationship`] = dependent?.relation || '';
      dependentBody[`dependentDetails[${index}].contactInfo.emailAddress`] = dependent?.contactInfo?.emailId || '';
      dependentBody[`dependentDetails[${index}].contactInfo.mobileNumber`] = dependent?.contactInfo?.mobileNumber || '';
      dependentBody[`dependentDetails[${index}].epfNominationPercentage`] = dependent?.epfPerc || '0.0';
      dependentBody[`dependentDetails[${index}].epsNominationPercentage`] = dependent?.epsPerc || '0.0';
      dependentBody[`dependentDetails[${index}].insuranceNominationPercentage`] = dependent?.insurancePerc || '0.0';
      dependentBody[`dependentDetails[${index}].othersNominationPercentage`] = dependent?.otherPerc || '0.0';
      dependentBody[`dependentDetails[${index}].gratuityNominationPercentage`] = dependent?.gratuityPerc || '0.0';
      dependentBody[`dependentDetails[${index}].emergencyContactReference`] = dependent?.emergencyContact || '';
    });
    dependentBody.dependentsCtr = formattedRecord.DEPENDENT.dependent?.length;
  }

  const emailAddressBody = {};
  if (data?.emailAddress?.length) {
    data?.emailAddress.map((email, index) => {
      emailAddressBody[`employee.emailAddresses[${index}]`] = email;
    });
    emailAddressBody.counter_emailAddresses = data?.emailAddress?.length;
  } else {
    (formattedRecord?.['CONTACT DETAILS']?.emailAddress || []).map((email, index) => {
      emailAddressBody[`employee.emailAddresses[${index}]`] = email;
    });
    emailAddressBody.counter_emailAddresses = formattedRecord?.['CONTACT DETAILS']?.emailAddress?.length;
  }

  const mobileNumberBody = {};
  if (data?.mobileNumber?.length) {
    data?.mobileNumber.map((email, index) => {
      mobileNumberBody[`employee.mobileNumbers[${index}]`] = email;
    });
    mobileNumberBody.counter_mobileNumbers = data?.mobileNumber?.length;
  } else {
    (formattedRecord?.['CONTACT DETAILS']?.mobileNumber || []).map((email, index) => {
      mobileNumberBody[`employee.mobileNumbers[${index}]`] = email;
    });
    mobileNumberBody.counter_mobileNumbers = formattedRecord?.['CONTACT DETAILS']?.mobileNumber?.length;
  }

  const bankFormikData = data?.bank;
  const bankBody = {};
  if (bankFormikData?.length) {
    bankFormikData.map((bank, index) => {
      bankBody[`bankAccountDetails[${index}].accountNumber`] = bank?.accountNumber || '';
      bankBody[`bankAccountDetails[${index}].accountType`] = bank?.accountType || '';
      bankBody[`bankAccountDetails[${index}].accountPurpose`] = bank?.purpose || '';
      bankBody[`bankAccountDetails[${index}].currency.id`] = bank?.accountCurrecy || '';
      bankBody[`bankAccountDetails[${index}].accountHolderName`] = bank?.accountName || '';
      bankBody[`bankAccountDetails[${index}].bankName`] = bank?.bankName || '';
      bankBody[`bankAccountDetails[${index}].bankBranch`] = bank?.branchName || '';
      bankBody[`bankAccountDetails[${index}].ifsCode`] = bank?.ifsc || '';
      bankBody[`bankAccountDetails[${index}].swiftCode`] = bank?.swiftCode || '';
      bankBody[`bankAccountDetails[${index}].preferredTransactionType`] = bank?.transactionType || '';

      bankBody[`bankAddress[${index}].locationName`] = bank?.locationName || '';
      bankBody[`bankAddress[${index}].addressLine1`] = bank?.addressLine1 || '';
      bankBody[`bankAddress[${index}].addressLine2`] = bank?.addressLine2 || '';
      bankBody[`bankAddress[${index}].addressLine3`] = bank?.addressLine3 || '';
      bankBody[`bankAddress[${index}].city`] = bank?.city || '';
      bankBody[`bankAddress[${index}].state`] = bank?.state || '';
      bankBody[`bankAddress[${index}].country`] = bank?.country || '';
      bankBody[`bankAddress[${index}].pinOrZip`] = bank?.pincode || '';
    });
    bankBody.bankAccntCtr = bankFormikData?.length;
  } else {
    (formattedRecord?.['Bank Account']?.bank || []).map((bankData, index) => {
      bankBody[`bankAccountDetails[${index}].accountNumber`] = bankData?.accountNumber || '';
      bankBody[`bankAccountDetails[${index}].accountType`] = bankData?.accountType || '';
      bankBody[`bankAccountDetails[${index}].accountPurpose`] = bankData?.purpose || '';
      bankBody[`bankAccountDetails[${index}].currency.id`] = bankData?.accountCurrecy || '';
      bankBody[`bankAccountDetails[${index}].accountHolderName`] = bankData?.accountName || '';
      bankBody[`bankAccountDetails[${index}].bankName`] = bankData?.bankName || '';
      bankBody[`bankAccountDetails[${index}].bankBranch`] = bankData?.branchName || '';
      bankBody[`bankAccountDetails[${index}].ifsCode`] = bankData?.ifsc || '';
      bankBody[`bankAccountDetails[${index}].swiftCode`] = bankData?.swiftCode || '';
      bankBody[`bankAccountDetails[${index}].preferredTransactionType`] = bankData.transactionType || '';

      bankBody[`bankAddress[${index}].locationName`] = bankData?.locationName || '';
      bankBody[`bankAddress[${index}].addressLine1`] = bankData?.addressLine1 || '';
      bankBody[`bankAddress[${index}].addressLine2`] = bankData?.addressLine2 || '';
      bankBody[`bankAddress[${index}].addressLine3`] = bankData?.addressLine3 || '';
      bankBody[`bankAddress[${index}].city`] = bankData?.city || '';
      bankBody[`bankAddress[${index}].state`] = bankData?.state || '';
      bankBody[`bankAddress[${index}].country`] = bankData?.country || '';
      bankBody[`bankAddress[${index}].pinOrZip`] = bankData?.pincode || '';
    });
    bankBody.bankAccntCtr = formattedRecord?.['Bank Account']?.bank?.length;
  }
  const newData = {
    id: record?.data?.secUserInstance?.id,
    username: record?.data?.secUserInstance?.username || '',
    actType: 'save',
    isDeleteStep: 0,
    'employee.gender': setValue(data.gender, formattedRecord['BASIC PROFILE'].gender),
    'profile.fathersName': setValue(data.fatherName, formattedRecord['BASIC PROFILE'].fatherName),
    'employee.employmentStatus': setValue(data.employmentStatus, formattedRecord['BASIC PROFILE'].employmentStatus),
    'profile.mothersName': setValue(data.motherName, formattedRecord['BASIC PROFILE'].motherName),
    'employee.maritalStatus': setValue(data.maritalStatus, formattedRecord['BASIC PROFILE'].maritalStatus),
    'profile.spouseName': setValue(data.spouseName, formattedRecord['BASIC PROFILE'].spouseName),
    'employee.location.id': setValue(data.location, formattedRecord['BASIC PROFILE'].location),
    'profile.bloodGroup': setValue(data.bloodGroup, formattedRecord['BASIC PROFILE'].bloodGroup),
    'profile.onboardingReference': setValue(
      data.onboardingReference,
      formattedRecord['BASIC PROFILE'].onboardingReference,
    ),
    'employee.panNumberIndia': setValue(data.panNumber, formattedRecord['STATUTORY IDENTIFICATION'].panNumber),
    'employee.aadhaarNumberIndia': setValue(
      data.aadharNumber,
      formattedRecord['STATUTORY IDENTIFICATION'].aadharNumber,
    ),
    'employee.uanNumberIndia': setValue(data.uanNumber, formattedRecord['STATUTORY IDENTIFICATION'].uanNumber),
    'employee.uhidAccountNumberIndia': setValue(
      data.uhidNumber,
      formattedRecord['STATUTORY IDENTIFICATION'].uhidNumber,
    ),
    'employee.skypeID': setValue(data.skype, formattedRecord['ADDITIONAL DETAILS']['Social Data'].skype),
    'employee.facebookID': setValue(data.facebook, formattedRecord['ADDITIONAL DETAILS']['Social Data'].facebook),
    'employee.linkedinID': setValue(data.linkedin, formattedRecord['ADDITIONAL DETAILS']['Social Data'].linkedin),
    'employee.googlePlusID': setValue(data.googlePlus, formattedRecord['ADDITIONAL DETAILS']['Social Data'].googlePlus),
    'employee.twitterID': setValue(data.twitter, formattedRecord['ADDITIONAL DETAILS']['Social Data'].twitter),
    'employee.instagramID': setValue(data.instagram, formattedRecord['ADDITIONAL DETAILS']['Social Data'].instagram),
    'profile.passportNumber': setValue(
      data.passportNumber,
      formattedRecord['ADDITIONAL DETAILS']['Social Data'].linkedin,
    ),
    'profile.passportIssueDate': data.passportIssueDate
      ? dayjs(data.passportIssueDate).format('MM/DD/YYYY')
      : '' || dayjs(formattedRecord['ADDITIONAL DETAILS']['Visa Details'].passportIssueDate).format('MM/DD/YYYY'),
    'profile.passportExpiryDate': data.passportExpDate
      ? dayjs(data.passportExpDate).format('MM/DD/YYYY')
      : '' || dayjs(formattedRecord['ADDITIONAL DETAILS']['Visa Details'].passportExpDate).format('MM/DD/YYYY'),
    'profile.passportPlaceOfIssue': setValue(
      data.passportIssuePlace,
      formattedRecord['ADDITIONAL DETAILS']['Visa Details'].passportIssuePlace,
    ),
    'profile.passportCountry': setValue(
      data.passportCountry,
      formattedRecord['ADDITIONAL DETAILS']['Visa Details'].passportCountry,
    ),
    'profile.passportNameGiven': setValue(
      data.passportGivenName,
      formattedRecord['ADDITIONAL DETAILS']['Visa Details'].passportGivenName,
    ),
    ...educationBody,
    ...employmentBody,
    ...visaBody,
    // Contact details
    'permanentHomeAddress.locationName': setValue(
      data?.permanentLocation,
      formattedRecord['CONTACT DETAILS'].permanentLocation,
    ),
    'permanentHomeAddress.addressLine1': setValue(
      data?.permanentAddressLine1,
      formattedRecord['CONTACT DETAILS'].permanentAddressLine1,
    ),
    'permanentHomeAddress.addressLine2': setValue(
      data?.permanentAddressLine2,
      formattedRecord['CONTACT DETAILS'].permanentAddressLine2,
    ),
    'permanentHomeAddress.addressLine3': setValue(
      data?.permanentAddressLine3,
      formattedRecord['CONTACT DETAILS'].permanentAddressLine3,
    ),
    'permanentHomeAddress.city': setValue(data?.permanentCity, formattedRecord['CONTACT DETAILS'].permanentCity),
    'permanentHomeAddress.state': setValue(data?.permanentState, formattedRecord['CONTACT DETAILS'].permanentState),
    'permanentHomeAddress.country': setValue(
      data?.permanentCountry,
      formattedRecord['CONTACT DETAILS'].permanentCountry,
    ),
    'permanentHomeAddress.pinOrZip': setValue(
      data?.permanentPincode,
      formattedRecord['CONTACT DETAILS'].permanentPincode,
    ),

    'officeAddress.locationName': setValue(data?.ofcLocationName, formattedRecord['CONTACT DETAILS'].ofcLocationName),
    'officeAddress.addressLine1': setValue(data?.ofcAddressLine1, formattedRecord['CONTACT DETAILS'].ofcAddressLine1),
    'officeAddress.addressLine2': setValue(data?.ofcAddressLine2, formattedRecord['CONTACT DETAILS'].ofcAddressLine2),
    'officeAddress.addressLine3': setValue(data?.ofcAddressLine3, formattedRecord['CONTACT DETAILS'].ofcAddressLine3),
    'officeAddress.city': setValue(data?.ofcCity, formattedRecord['CONTACT DETAILS'].ofcCity),
    'officeAddress.state': setValue(data?.ofcState, formattedRecord['CONTACT DETAILS'].ofcState),
    'officeAddress.country': setValue(data?.ofcCountry, formattedRecord['CONTACT DETAILS'].ofcCountry),
    'officeAddress.pinOrZip': setValue(data?.ofcPincode, formattedRecord['CONTACT DETAILS'].ofcPincode),

    'presentCommunicationAddress.locationName': setValue(
      data?.currentLocation,
      formattedRecord['CONTACT DETAILS'].currentLocation,
    ),
    'presentCommunicationAddress.addressLine1': setValue(
      data?.currentAddressLine1,
      formattedRecord['CONTACT DETAILS'].currentAddressLine1,
    ),
    'presentCommunicationAddress.addressLine2': setValue(
      data?.currentAddressLine2,
      formattedRecord['CONTACT DETAILS'].currentAddressLine2,
    ),
    'presentCommunicationAddress.addressLine3': setValue(
      data?.currentAddressLine3,
      formattedRecord['CONTACT DETAILS'].currentAddressLine3,
    ),
    'presentCommunicationAddress.city': setValue(data?.currentCity, formattedRecord['CONTACT DETAILS'].currentCity),
    'presentCommunicationAddress.state': setValue(data?.currentState, formattedRecord['CONTACT DETAILS'].currentState),
    'presentCommunicationAddress.country': setValue(
      data?.currentCountry,
      formattedRecord['CONTACT DETAILS'].currentCountry,
    ),
    'presentCommunicationAddress.pinOrZip': setValue(
      data?.currentPincode,
      formattedRecord['CONTACT DETAILS'].currentPincode,
    ),
    'profile.pic': data?.uploadProfile || '',
    ...emailAddressBody,
    ...mobileNumberBody,
    ...dependentBody,
    ...bankBody,
  };
  return newData;
};
