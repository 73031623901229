import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import EmployeeProfileCard from './EmployeeProfileCard';
import EmployeeDetailTab from './EmployeeRecordTab';

const EmployeeRecord = ({ employeeProfileDetail, employeeDetails }) => {
  return (
    <Box>
      <Box p={3}>
        <EmployeeProfileCard employeeProfileDetail={employeeProfileDetail} employeeDetails={employeeDetails} />
      </Box>

      <Box minHeight="66.5rem">
        <EmployeeDetailTab employeeDetails={employeeDetails} />
      </Box>
    </Box>
  );
};
EmployeeRecord.defaultProps = {
  employeeProfileDetail: {},
  employeeDetails: {},
};

EmployeeRecord.propTypes = {
  employeeProfileDetail: PropTypes.arrayOf(PropTypes.shape({})),
  employeeDetails: PropTypes.shape({}),
};
export default EmployeeRecord;
