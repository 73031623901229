export const chartStyle = {
  chartLegendWrapper: {
    position: 'relative',
  },

  legendList: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 0,
    rowGap: '0.5rem',
    columnGap: '3rem',
    m: 0,
    maxWidth: 'max-content',
    margin: '0 auto',

    li: {
      listStyle: 'none',
      display: 'flex',
      alignItems: 'center',
      columnGap: '1rem',
      margin: '0 0.5rem',
      whiteSpace: 'nowrap',

      '.MuiTypography-root': {
        display: 'flex',
        alignItems: 'center',
      },
    },
  },

  legendIcon: {
    display: 'inline-block',
    mr: '1.5rem',
  },

  legendLabel: {
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '1.75rem',
    lineHeight: 1.6,
    letterSpacing: 0.15,
  },

  legendValue: {
    fontWeight: '600',
    fontSize: '2.25rem',
    lineHeight: '150%',
    ml: 'auto',
    color: 'neutral.800',
  },

  axisLabel: {
    fontSize: '1.5rem',
    lineHeight: '160%',
  },

  valueBox: {
    position: 'absolute',
    top: '35%',
    left: '50%',
    transform: 'translate(-50%, -65%)',
  },
};
