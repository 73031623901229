import React from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import ReassignQuery from 'components/Organisms/TaskAndQueries/ReassignQuery';

const ReassignQueryTemplate = ({
  handleSubmit,
  formData,
  categoryOptions,
  topicOptions,
  currentOwnerOptions,
  handleCancel,
  queryDetails,
}) => {
  const breadcrumbData = [
    { id: 1, name: 'My Queries', path: '/myQueries', isActive: false },
    { id: 2, name: 'Reassign query', isActive: true },
  ];
  return (
    <BlankTemplate pageHeading="Reassign query" breadcrumbData={breadcrumbData} padding="2rem 3rem">
      <ReassignQuery
        handleSubmit={handleSubmit}
        formData={formData}
        categoryOptions={categoryOptions}
        topicOptions={topicOptions}
        currentOwnerOptions={currentOwnerOptions}
        handleCancel={handleCancel}
        queryDetails={queryDetails}
      />
    </BlankTemplate>
  );
};

ReassignQueryTemplate.defaultProps = {
  formData: {},
  handleSubmit: () => {},
  categoryOptions: [],
  topicOptions: [],
  handleCancel: () => {},
  currentOwnerOptions: [],
  queryDetails: {},
};

ReassignQueryTemplate.propTypes = {
  formData: PropTypes.shape({
    category: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    topic: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    currentOwner: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  handleSubmit: PropTypes.func,
  categoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  topicOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  currentOwnerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  handleCancel: PropTypes.func,
  queryDetails: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default ReassignQueryTemplate;
