import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import SalarySlipDetail from 'components/Organisms/MyPay/SalarySlips/Details';
import useDownloadFile from 'hooks/useDownloadFile';
import { useReactToPrint } from 'react-to-print';
import BlankTemplate from '../../../BlankTemplate';

const SalarySlipDetailTemplate = ({
  paySlipDate,
  companyName,
  companyAddress,
  companyLogo,
  payslipIssueDate,
  salarySlipData,
  loading,
  arrearDataColumn,
  arrearDataRow,
  sortDirection,
  sortBy,
  onSortChange,
  count,
  page,
  onPageChange,
  consolidatedSalarySlipsFlag,
  salarySlipIdForDownloadWhenConsolidatedSalarySlip,
}) => {
  const downloadFile = useDownloadFile();
  const breadcrumbData = [
    { id: 1, name: 'Salary Slip', path: '/home/mypay/salary-slips', isActive: false },
    { id: 2, name: 'Salary Slip Details', isActive: true },
  ];
  const componentRef = useRef();
  const { id: paramId } = useParams();

  const id = consolidatedSalarySlipsFlag ? salarySlipIdForDownloadWhenConsolidatedSalarySlip : paramId;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleDownload = () => {
    downloadFile({ id }, 'SalarSlip.pdf', 'restPayIndex/printMySalaryDetailsPDF');
  };

  return (
    <BlankTemplate pageHeading="Salary Slip Details" breadcrumbData={breadcrumbData} isBreadcrumb padding="0">
      <SalarySlipDetail
        ref={componentRef}
        paySlipDate={paySlipDate}
        handleDownload={handleDownload}
        handlePrint={handlePrint}
        companyName={companyName}
        companyAddress={companyAddress}
        companyLogo={companyLogo}
        payslipIssueDate={payslipIssueDate}
        salarySlipData={salarySlipData}
        loading={loading}
        arrearDataColumn={arrearDataColumn}
        arrearDataRow={arrearDataRow}
        sortDirection={sortDirection}
        sortBy={sortBy}
        onSortChange={onSortChange}
        count={count}
        page={page}
        onPageChange={onPageChange}
        consolidatedSalarySlipsFlag={consolidatedSalarySlipsFlag}
      />
    </BlankTemplate>
  );
};

SalarySlipDetailTemplate.defaultProps = {
  paySlipDate: '',
  companyName: '',
  companyAddress: '',
  companyLogo: '',
  payslipIssueDate: 'MM/DD/YYYY',
  salarySlipData: {},
  loading: false,
  arrearDataColumn: [],
  arrearDataRow: [],
  sortDirection: 'asc',
  sortBy: () => {},
  onSortChange: () => {},
  count: 10,
  page: null,
  onPageChange: () => {},
};

SalarySlipDetailTemplate.propTypes = {
  paySlipDate: PropTypes.string,
  companyName: PropTypes.string,
  companyAddress: PropTypes.string,
  companyLogo: PropTypes.string,
  payslipIssueDate: PropTypes.string,
  salarySlipData: PropTypes.shape,
  loading: PropTypes.shape,
  arrearDataRow: PropTypes.arrayOf,
  arrearDataColumn: PropTypes.arrayOf,
  sortBy: PropTypes.func,
  sortDirection: PropTypes.string,
  count: PropTypes.number,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  onSortChange: PropTypes.func,
};

export default SalarySlipDetailTemplate;
