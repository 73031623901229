export const empRecordActionDetailStyle = {
  formWrapper: {
    mt: 2,
  },

  btnContainer: {
    position: 'fixed',
    right: '0rem',
    bottom: '3.625rem',
    backgroundColor: 'common.white',
    zIndex: '9',
    margin: '0rem 3.25rem',
    width: 'calc(100% - 49.5rem)',
    padding: '0rem 3rem 3.25rem 3rem',
  },

  submitWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: '2rem',
  },
};
