import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, useTheme } from '@mui/material';
import Typography from 'components/Atoms/Typography';
import Avatar from '@mui/material/Avatar';
import IconWithTitle from 'components/Atoms/IconWithTitle';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import { API_URL } from 'api/api';
import { profileWithInformationStyle } from './style';

const IconWithTitleWrapper = ({ iconElement, title }) => {
  return (
    <IconWithTitle
      iconElement={iconElement || <MarkunreadIcon fontSize="inherit" />}
      iconColor="neutral.600"
      iconFontSize="2rem"
      title={title || ''}
      titleColor="neutral.600"
      titleVariant="caption"
    />
  );
};

const ProfileWithInformation = ({
  profileImg,
  profileName,
  isDesignation,
  designation,
  iconElements = [],
  titles = [],
}) => {
  const theme = useTheme();

  const filteredInfoData = iconElements
    .map((iconElement, index) => ({
      iconElement,
      title: titles[index],
    }))
    .filter((item) => !!item.iconElement && !!item.title);

  return (
    <Box sx={profileWithInformationStyle.profileWrapper}>
      {profileImg && profileImg !== 'null' ? (
        <Avatar alt={profileName?.toUpperCase() || 'Avatar'} src={`${API_URL}/${profileImg}`} />
      ) : (
        <Avatar>{profileName?.toUpperCase()?.charAt(0)}</Avatar>
      )}
      <Box sx={profileWithInformationStyle.profileContent}>
        <Typography variant="subtitle2" color="neutral.800" sx={{ textTransform: 'capitalize' }}>
          {profileName}
        </Typography>
        {isDesignation && (
          <Typography variant="body2" color="neutral.600">
            {designation}
          </Typography>
        )}

        {filteredInfoData.length > 0 && (
          <Box sx={profileWithInformationStyle.profileInfo}>
            {filteredInfoData.map((item, index) => (
              <>
                {!!index && (
                  <Divider orientation="vertical" sx={{ height: '2.5rem', borderColor: theme.palette.other[200] }} />
                )}
                <IconWithTitleWrapper iconElement={item.iconElement} title={item.title} />
              </>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

ProfileWithInformation.defaultProps = {
  profileImg: '',
  profileName: '',
  isDesignation: false,
  designation: '',
  iconElements: [],
  titles: [],
};
ProfileWithInformation.propTypes = {
  profileImg: PropTypes.string,
  profileName: PropTypes.string,
  isDesignation: PropTypes.bool,
  designation: PropTypes.string,
  iconElements: PropTypes.arrayOf(),
  titles: PropTypes.arrayOf(),
};

export default ProfileWithInformation;
