import { useTheme } from '@mui/material';

const statusColor = (status) => {
  const statusMap = {
    Active: 'success',
    Pending: 'warning',
    Rejected: 'error',
    Holiday: 'secondary',
    Absent: 'error',
  };

  return statusMap[status] || 'success';
};

const attendanceStatus = (attendance) => {
  const attendanceMap = {
    present: 'success',
    holiday: 'secondary',
    absent: 'error',
    remote: 'success',
    'on duty': 'success',
  };

  return attendanceMap[attendance.toLowerCase()] || 'success';
};

const attendanceRowColor = (attendance) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const theme = useTheme();

  const attendanceRowMap = {
    holiday: theme.palette.info[100],
    absent: theme.palette.error[100],
  };

  return attendanceRowMap[attendance.toLowerCase()] || '';
};

const resignationStatusColor = (status) => {
  const statusMap = {
    Accepted: 'success',
    'Under Review': 'warning',
    Rejected: 'error',
  };

  return statusMap[status] || 'success';
};

export { statusColor, attendanceStatus, attendanceRowColor, resignationStatusColor };
