import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import Avatars from 'components/Molecules/Avatar';
import { statusCardStyle } from './style';

const StatusCard = ({
  heading,
  headingColor,
  value,
  valueColor,
  boxBackground,
  iconSrc,
  iconWidth,
  iconHeight,
  boxWidth,
  boxHeight,
}) => {
  return (
    <Box sx={statusCardStyle.wrapper}>
      <Avatars
        variant="circular"
        isShortName={false}
        isIcon
        iconSrc={iconSrc}
        iconWidth={iconWidth}
        iconHeight={iconHeight}
        sx={{ bgcolor: boxBackground, width: boxWidth, height: boxHeight }}
      />
      <Box>
        <Typographys variant="overline" color={headingColor}>
          {heading}
        </Typographys>
        <Typographys variant="h6" color={valueColor}>
          {value}
        </Typographys>
      </Box>
    </Box>
  );
};

StatusCard.defaultProps = {
  heading: 'Active',
  headingColor: 'secondary.600',
  value: '6823',
  valueColor: 'neutral.800',
  boxBackground: 'secondary.600',
  iconSrc: 'images/icons/custom-user-group.svg',
  iconWidth: 24,
  iconHeight: 24,
  boxWidth: '7rem',
  boxHeight: '7rem',
};
StatusCard.propTypes = {
  heading: PropTypes.string,
  headingColor: PropTypes.string,
  value: PropTypes.number,
  valueColor: PropTypes.number,
  boxBackground: PropTypes.string,
  iconSrc: PropTypes.string,
  iconWidth: PropTypes.string,
  iconHeight: PropTypes.string,
  boxWidth: PropTypes.string,
  boxHeight: PropTypes.string,
};

export default StatusCard;
