import React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typographys from 'components/Atoms/Typography';
import { commonListStyle } from './style';

const CommonList = ({ lists, listVariant, listColor, ...props }) => {
  return (
    <List sx={commonListStyle.wrapper} disablePadding {...props}>
      {lists.map((list) => (
        <ListItem disablePadding>
          <ListItemText
            key={list}
            primary={
              <Typographys variant={listVariant} mr={1} color={listColor}>
                {list}
              </Typographys>
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

CommonList.defaultProps = {
  lists: [],
  listVariant: 'body2',
  listColor: 'neutral.800',
};

CommonList.propTypes = {
  lists: PropTypes.arrayOf(PropTypes.string),
  listVariant: PropTypes.string,
  listColor: PropTypes.string,
};

export default CommonList;
