import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useToast from 'components/Provider/useToast';

import ActionQueryTemplate from 'components/Templates/TaskAndQueries/ActionQuery';
import useServices from '../common/useServices';
import useMenuItems from '../View/useMenuItems';

const ActionQuery = () => {
  const addToast = useToast();
  const location = useLocation();
  const { id } = useParams();
  const isReopen = location.pathname.includes('reopen');
  const services = useServices();
  const [handleActionItemClick] = useMenuItems(services);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!isReopen) await services.actionQueryService(id);
        else await services.reopenQueryService(id);
      } catch (error) {
        addToast({ type: 'error', title: 'ERROR', message: error?.message });
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReopen, id]);
  return (
    <ActionQueryTemplate
      handleSubmit={!isReopen ? services.actionQueryActionService : services.reopenQueryActionService}
      reportDetails={services.state.queryDetails?.queryDetails}
      handleEdit={() => services.modifyQueryNavigationService({ id })}
      actionMenuList={handleActionItemClick}
      isRecall={false}
      handleRecall={() => {}}
      handleAttachmentDownload={services.downloadDocument}
      showEdit
      formData={services.state.queryDetails.formData}
      resolutionStatusOptions={services.state.queryDetails?.resolutionStatusOptions}
      handleCancel={services.handleReassignCancel}
      isReopen={isReopen}
      auditLogData={[]}
    />
  );
};

export default ActionQuery;
