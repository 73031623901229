const columns = [
  {
    fieldName: `startDate`,
    label: 'Start date',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: `endDate`,
    label: 'End date',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: `leavesAvailed`,
    label: 'Leaves availed',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: `leavesAccounted`,
    label: 'Leaves accounted as LOP',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: `leavesDeducted`,
    label: 'Leaves deducted as LOP',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
];

const useColumns = () => {
  return [columns];
};

export default useColumns;
