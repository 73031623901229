import { Box, Grid, Card, CardContent, Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { formDataApi } from 'api/api';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import Step from '@mui/material/Step';
import StepConnector from '@mui/material/StepConnector';
import { preCreatePayrollCalendar } from 'api/payrollCalendar';
import useToast from 'components/Provider/useToast';
import BlankTemplate from 'components/Templates/BlankTemplate';
import BasicInfoForm from './basicInfoForm';
import useFetchPayrollCalendar from '../ViewPayrollCalendar/useFetchPayrollCalendar';
import PayPeriodForm from './payPeriodForm';

const steps = ['BASIC INFO', 'PAY PERIOD SETUP'];
const createPayloadMap = {
  name: 'name',
  calendarStartDate: 'calendarStartDate',
  calendarEndDate: 'calendarEndDate',
  financialYear: 'financialYear',
  assessmentYear: 'assessmentYear',
  payRunPeriodicity: 'payRunPeriodicity',
  firstProcessedPayPeriodStartDate: 'firstProcessedPPStartDate',
  lockStatus: 'lockStatus',
  status: 'status',
  payDateOffsetFromPayPeriodEndDate: 'payDateOffsetFromEndDate',
  attendanceCutOffOffsetFromPayPeriodEndDate: 'attendanceCutoffFromPeriodDate',
  payChangesCutOffOffsetFromPayPeriodEndDate: 'payChangedFromPeriodDate',
  payRunFinalApprovalOffsetFromPayPeriodEndDate: 'finalPayrunFromPeriodDate',
  applicableEmployeeTypes: 'employementType',
  payRunOffsetFromPayPeriodEndDate: 'payRunOffsetFromPayPeriodEndDate',
};

const AddEditPayrollCalendar = (props) => {
  const params = useParams();
  const [payrollCalendar, setPayrollCalendarDetails] = useState({
    data: {},
    isLoading: false,
  });
  const location = useLocation();
  const isEditMode = location.pathname.includes('edit');
  const { id } = useParams();
  const [payrollCalendarEditData] = useFetchPayrollCalendar(id);
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const payhead = payrollCalendarEditData?.data?.data?.payrollCalendarInstance;
  const addToast = useToast();

  const handleCustomDetails = async (details) => {
    try {
      setLoading(true);
      const query = new FormData();
      Object.keys(createPayloadMap).forEach((key) => {
        if (details[createPayloadMap[key]]) {
          if (key === 'applicableEmployeeTypes' && details[createPayloadMap[key]]?.length) {
            details[createPayloadMap[key]].forEach((item) => {
              query.append(key, item);
            });
          } else if (
            key === 'calendarStartDate' ||
            key === 'calendarEndDate' ||
            key === 'firstProcessedPayPeriodStartDate'
          ) {
            query.append(key, dayjs(details[createPayloadMap[key]]).format('MM/DD/YYYY'));
          } else query.append(key, details[createPayloadMap[key]]);
        }
      });
      if (isEditMode) {
        query.append('id', payhead?.id);
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const pair of query.entries()) {
        // eslint-disable-next-line no-console
        console.log(`${pair[0]}, ${pair[1]},  ${typeof pair[1]}`);
      }
      const pathToCall = isEditMode ? 'restPayIndex/modifyPayrollCalendarAction' : 'restPayIndex/savePayrollCalendar';
      const response = await formDataApi.post(pathToCall, query);
      if (response.status === 200) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Payroll calendar ${isEditMode ? 'updated' : 'added'} successfully`,
        });
        navigate('/company/salary/payrollCalendar');
      } else {
        addToast({
          type: 'error',
          title: 'ERROR',
          message: response?.data?.message || `Failed to ${isEditMode ? 'update' : 'add'} payroll calendar`,
        });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to ${isEditMode ? 'update' : 'add'} payroll calendar`,
      });
    } finally {
      setLoading(false);
    }
  };

  const basicDetailsFormik = useFormik({
    initialValues: {
      // Basic info data
      name: payhead?.name ?? '',
      calendarStartDate: payhead?.calendarStartDate ? dayjs(payhead?.calendarStartDate) : '',
      calendarEndDate: payhead?.calendarEndDate ? dayjs(payhead?.calendarEndDate) : '',
      financialYear: payhead?.financialYear ?? '',
      assessmentYear: payhead?.assessmentYear ?? '',
      employementType: payhead?.applicableEmployeeTypes ?? [],
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Name is required'),
      calendarEndDate: Yup.string().required('End Date is required'),
      financialYear: Yup.string().required('Financial Year is required'),
      employementType: Yup.array().min(1, 'Employement type is required'),
    }),
    onSubmit: async (_values, helpers) => {
      try {
        setActiveStep(activeStep + 1);
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
      }
    },
  });

  const payPeriodFormik = useFormik({
    initialValues: {
      // Pay preiod data
      payRunPeriodicity: payhead?.payRunPeriodicity ?? '',
      lockStatus: payhead?.lockStatus ?? '',
      status: payhead?.status ?? '',
      firstProcessedPPStartDate: payhead?.firstProcessedPayPeriodStartDate
        ? dayjs(payhead?.firstProcessedPayPeriodStartDate)
        : '',
      payDateOffsetFromEndDate: payhead?.payDateOffsetFromPayPeriodEndDate ?? '',
      attendanceCutoffFromPeriodDate: payhead?.attendanceCutOffOffsetFromPayPeriodEndDate ?? '',
      payChangedFromPeriodDate: payhead?.payChangesCutOffOffsetFromPayPeriodEndDate ?? '',
      finalPayrunFromPeriodDate: payhead?.payRunFinalApprovalOffsetFromPayPeriodEndDate ?? '',
      payRunOffsetFromPayPeriodEndDate: payhead?.payRunOffsetFromPayPeriodEndDate ?? '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      payRunPeriodicity: Yup.string().required('Periodicity is required'),
      lockStatus: Yup.string().required('Lock status is required'),
      status: Yup.string().required('Status is required'),
      payChangedFromPeriodDate: Yup.string().required('Pay Changes Cutoff Offset From Period End Date is required'),
      payRunOffsetFromPayPeriodEndDate: Yup.string().required('Payrun Offset From Period End Date is required'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        helpers.setSubmitting(false);

        handleCustomDetails({ ...values, ...basicDetailsFormik.values });
        helpers.setStatus({ success: true });
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      setPayrollCalendarDetails({ ...payrollCalendar, isLoading: true });

      try {
        const response = await preCreatePayrollCalendar();
        if (response?.data?.data) {
          setPayrollCalendarDetails({ data: response.data.data, isLoading: false });
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'ERROR',
          message: err?.message || `Failed to fetch details`,
        });
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, isEditMode]);

  const breadcrumbData = [
    { id: 1, name: 'Payroll Calendar', path: '/company/salary/payrollCalendar', isActive: false },
    { id: 2, name: `${isEditMode ? 'Edit Payroll Calendar' : 'Add Payroll Calendar'}`, isActive: true },
  ];

  return (
    <BlankTemplate
      pageHeading={isEditMode ? 'Edit Payroll Calendar' : 'Add Payroll Calendar'}
      breadcrumbData={breadcrumbData}
      padding="2rem 3rem"
    >
      <Grid item sx={{ pt: '0px !important' }}>
        <Card {...props}>
          <CardContent>
            <Grid item sx={{ pt: 1 }} justifyContent="center">
              <Stepper sx={{ justifyContent: 'center' }} connector={<StepConnector />} activeStep={activeStep}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel sx={{ display: 'flex', flexDirection: 'column' }}>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              {activeStep === 0 && (
                <BasicInfoForm
                  isEditMode={isEditMode}
                  payrollCalendar={payrollCalendar}
                  formik={basicDetailsFormik}
                  handleBack={() => {
                    basicDetailsFormik.resetForm();
                  }}
                />
              )}
              {activeStep === 1 && (
                <PayPeriodForm
                  loading={loading}
                  isEditMode={isEditMode}
                  payrollCalendar={payrollCalendar}
                  formik={payPeriodFormik}
                  handleCancel={() => setActiveStep(0)}
                />
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </BlankTemplate>
  );
};

export default AddEditPayrollCalendar;
