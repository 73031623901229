import React from 'react';
import PropTypes from 'prop-types';
import MuiDivider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';

const Divider = ({ sx }) => {
  const theme = useTheme();
  return (
    <MuiDivider
      sx={{
        my: 2,
        backgroundColor: theme.palette.neutral[200],
        ...sx,
      }}
    />
  );
};

Divider.defaultProps = {
  sx: {},
};

Divider.propTypes = {
  sx: PropTypes.shape({}),
};

export default Divider;
