/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import { Button, FormHelperText, Grid, Divider, Stack, MenuItem } from '@mui/material';
import React from 'react';
import DynamicField from './Field';

const DynamicForm = ({ formik, formFields, primaryButton, handlePrimaryButtonClick, isModify }) => {
  return (
    <div>
      <Grid container spacing={3}>
        {formFields?.length ? (
          formFields.map((field, index) => (
            <Grid item xs={4} key={index}>
              <DynamicField field={field} formik={formik} />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            No custom fields available
          </Grid>
        )}
        {formik.errors.submit && (
          <Grid item xs={12}>
            <FormHelperText error>{formik.errors.submit}</FormHelperText>
          </Grid>
        )}
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="end">
            {/*! !secondaryButton && !isModify && (
                <Button
                  color='primary'
                  size='large'
                  variant='text'
                  onClick={handleSecondaryButtonClick}
                  sx={{
                    mr: 2,
                  }}
                >
                  {secondaryButton}
                </Button>
                ) */}
            {!!primaryButton && (
              <Button
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                onClick={handlePrimaryButtonClick}
                sx={{ ':hover': { backgroundColor: 'secondary.main' } }}
              >
                {primaryButton}
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};

export default DynamicForm;
