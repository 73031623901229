import { viewLCRecordsAction, approveOrRejectLifeCycleRecord } from 'api/employees';
import { modifyLCRecordsDetails } from 'api/lifeCycleCaseRecords';
import { asyncThunkWrapper } from 'utils/AsyncThunkWrapper';

const fetchLCRecordDetails = asyncThunkWrapper('LCRecords/details', viewLCRecordsAction);
const fetchLCModifyRecordDetails = asyncThunkWrapper('LCRecords/modifyDetails', modifyLCRecordsDetails);
const fetchLCApproveRejectRecordDetails = asyncThunkWrapper(
  'LCRecords/approveRejectDetails',
  approveOrRejectLifeCycleRecord,
);

export { fetchLCRecordDetails, fetchLCModifyRecordDetails, fetchLCApproveRejectRecordDetails };
