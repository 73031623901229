import { useSelector } from 'react-redux';

import { hasPermission } from 'utils/permissions';

const getActionItems = (services) => {
  return [
    {
      name: 'Modify',
      onClick: services.modifyAccessFilter,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_ACCESS_FILTER'],
    },
    {
      name: 'Delete',
      onClick: services.deleteAccessFilter,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_DELETE_ACCESS_FILTER'],
    },
  ];
};

const useActionItems = (services) => {
  const { user } = useSelector((state) => state?.session);

  const actions = getActionItems(services);

  const filteredActionItem = actions.filter((action) =>
    hasPermission(
      [
        'ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_ACCESS_FILTER',
        'ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_DELETE_ACCESS_FILTER',
      ],
      action.permissions,
    ),
  );

  const handleActionItemClick = (row) => {
    return filteredActionItem;
  };

  return [handleActionItemClick];
};

export default useActionItems;
