import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { searchStyle } from './style';

const CustomizedInputBase = ({ placeholder, isShowIcon, handleTextChange, searchResult, handleSelection }) => {
  const [showInput, setShowInput] = useState(false);
  const theme = useTheme();
  const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Paper component="form" sx={searchStyle.searchBar}>
      {isShowIcon && (
        <IconButton
          type="button"
          sx={searchStyle.iconButton}
          aria-label="search"
          onClick={matchDownSm ? () => setShowInput(true) : () => null}
        >
          <SearchIcon />
        </IconButton>
      )}

      {!matchDownSm || showInput ? (
        <Box sx={searchStyle.searchInput}>
          <InputBase
            placeholder={placeholder}
            inputProps={{ 'aria-label': 'search' }}
            onChange={handleTextChange}
            sx={{ flex: 1 }}
          />

          <IconButton
            type="button"
            sx={{ p: '0', color: 'neutral.600' }}
            aria-label="search"
            onClick={() => {
              setShowInput(false);
            }}
          >
            {matchDownSm ? <CloseIcon /> : ''}
          </IconButton>

          {searchResult.length > 0
            ? searchResult.map((item) => (
                <Box
                  onClick={() => {
                    handleSelection();
                  }}
                  sx={searchStyle.searchResult}
                >
                  {item}
                </Box>
              ))
            : ''}
        </Box>
      ) : null}
    </Paper>
  );
};

CustomizedInputBase.defaultProps = {
  placeholder: 'Search',
  isShowIcon: true,
  handleTextChange: () => {},
  searchResult: [],
  handleSelection: () => {},
};

CustomizedInputBase.propTypes = {
  isShowIcon: PropTypes.bool,
  placeholder: PropTypes.string,
  handleTextChange: PropTypes.func,
  searchResult: PropTypes.arrayOf(PropTypes.node),
  handleSelection: PropTypes.func,
};

export default CustomizedInputBase;
