/* eslint-disable no-return-assign */
/* eslint-disable no-sequences */
export const getFormInitialValues = (formFields = [], customeFields = []) => {
  if (!formFields?.length) return {};
  const initialValues = {};
  formFields.forEach((field) => {
    if (field.fieldDataType === 'String') {
      initialValues[field.fieldLabel] = '';
    } else if (field.fieldDataType === 'Date') {
      initialValues[field.fieldLabel] = null;
    } else if (field.fieldDataType === 'Double' || field.fieldDataType === 'Integer') {
      initialValues[field.fieldLabel] = '';
    } else if (field.fieldDataType === 'Boolean') {
      initialValues[field.fieldLabel] = 'true';
    } else if (field.fieldDataType === 'Single Selection List' || field.fieldDataType === 'Multiple Selection List') {
      initialValues[field.fieldLabel] = '';
    } else if (field.fieldDataType === 'File Attachment') {
      initialValues[field.fieldLabel] = null;
    }
  });
  const customValues = customeFields.reduce((obj, item) => ((obj[item.fieldLabel] = item.fieldValue), obj), {});
  const finalValues = { ...initialValues, ...customValues };
  return finalValues;
};
