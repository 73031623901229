import {
  getmyLeaveDashboard,
  getemployeeEventData,
  getdocumentAndProjectData,
  getemployeeLoginData,
  getTeamDashboardDetails,
  getSurveyDetails,
  getAnnouncementDetails,
} from 'api/dashboard';
import { asyncThunkWrapper } from 'utils/AsyncThunkWrapper';

const fetchmyLeaveDashboard = asyncThunkWrapper('myLeaveDashboard/fetch', getmyLeaveDashboard);
const fetchemployeeEventData = asyncThunkWrapper('employeeEventData/fetch', getemployeeEventData);
const fetchdocumentAndProjectData = asyncThunkWrapper('documentAndProjectData/fetch', getdocumentAndProjectData);
const fetchemployeeLoginData = asyncThunkWrapper('employeeLoginData/fetch', getemployeeLoginData);
const fetchTeamDashboardDetails = asyncThunkWrapper('teamDashboardDetails/fetch', getTeamDashboardDetails);
const fetchAnnouncementDetails = asyncThunkWrapper('announcementDetails/fetch', getAnnouncementDetails);
const fetchSurveyDetails = asyncThunkWrapper('surveyDetails/fetch', getSurveyDetails);

export {
  fetchmyLeaveDashboard,
  fetchemployeeEventData,
  fetchdocumentAndProjectData,
  fetchemployeeLoginData,
  fetchTeamDashboardDetails,
  fetchAnnouncementDetails,
  fetchSurveyDetails,
};
