import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Typographys from 'components/Atoms/Typography';
import { Box } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { calendarStyle } from '../style';

const Toolbar = ({ view, onView, onNavigate, label }) => {
  return (
    <Box sx={calendarStyle.calendarToolbar}>
      <Box>
        <Button
          variant="outlined"
          onClick={() => {
            onView('month');
          }}
          color={view === 'month' ? 'primary' : 'info'}
          style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
        >
          Month
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            onView('week');
          }}
          color={view === 'week' ? 'primary' : 'info'}
          style={{ borderRadius: 0 }}
        >
          Week
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            onView('day');
          }}
          color={view === 'day' ? 'primary' : 'info'}
          style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
        >
          Day
        </Button>
      </Box>
      <Typographys variant="h4">{label}</Typographys>
      <Box sx={calendarStyle.calendarActionBtn}>
        <Button
          variant="outlined"
          onClick={() => onNavigate('PREV')}
          color="info"
          fontSize="small"
          startIcon={<ChevronLeftIcon />}
        />
        <Button
          variant="outlined"
          onClick={() => onNavigate('NEXT')}
          color="info"
          fontSize="small"
          startIcon={<ChevronRightIcon />}
        />
      </Box>
    </Box>
  );
};
Toolbar.defaultProps = {
  view: '',
  onView: () => {},
  onNavigate: () => {},
  label: '',
};
Toolbar.propTypes = {
  view: PropTypes.string,
  onView: PropTypes.func,
  onNavigate: PropTypes.func,
  label: PropTypes.string,
};
export default Toolbar;
