import {
  withProps,
  BlockquoteElement,
  createPlateUI,
  ImageElement,
  MediaEmbedElement,
  ELEMENT_BLOCKQUOTE,
  ELEMENT_IMAGE,
  ELEMENT_MEDIA_EMBED,
} from '@udecode/plate';
import { withStyledDraggables } from './withStyledDraggables';

export const plateComponentUI = withStyledDraggables(
  createPlateUI({
    [ELEMENT_BLOCKQUOTE]: BlockquoteElement,
    [ELEMENT_IMAGE]: withProps(ImageElement, {
      styles: {
        root: {
          height: '50%',
          width: '50%',
        },
      },
    }),
    [ELEMENT_MEDIA_EMBED]: withProps(MediaEmbedElement, {
      styles: {
        root: {
          height: '50%',
          width: '50%',
        },
      },
    }),
  }),
);
