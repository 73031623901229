import React, { useEffect } from 'react';
import { useThunk } from 'hooks/useThunk';
import { useSelector } from 'react-redux';
import { fetchEducationalHistoryDetails } from 'store';
import { useNavigate } from 'react-router-dom';
import { Typography, Skeleton, Box } from '@mui/material';
import ProfileEducationalHistoryTemplate from 'components/Templates/Profile/EducationalHistory';
import useDownloadFile from 'hooks/useDownloadFile';
import { validateDateElseHyphen } from 'utils/commonUtils';

const EducationalHistoryDetails = () => {
  const navigate = useNavigate();
  const downloadFile = useDownloadFile();

  const [doFetchEducationHistoryDetails, isLoadingEducationHistoryDetails, loadingEducationHistoryDetailsError] =
    useThunk(fetchEducationalHistoryDetails);

  const { educationHistoryDetails } = useSelector((state) => {
    return state.profile;
  });

  useEffect(() => {
    doFetchEducationHistoryDetails();
  }, [doFetchEducationHistoryDetails]);

  const handleEdit = () => {
    navigate('/profile/education-history/addEdit');
  };

  const handleAttachment = (docid, filename) => {
    downloadFile({ docid }, filename, 'restPayIndex/downloadAttachment');
  };

  const EducationHistoryList =
    educationHistoryDetails &&
    educationHistoryDetails?.map((education) => ({
      institutionName: education?.institutionName || '-',
      certificationName: education?.certificationName || '-',
      startDate: validateDateElseHyphen(education?.startDate, 'DD MMMM YYYY'),
      endDate: validateDateElseHyphen(education?.endDate, 'DD MMMM YYYY'),
      specialization: education?.specialization || '-',
      registrationNumber: education?.registrationNumber,
      referenceName: education?.referenceName || '-',
      referencePhone: education?.referencePhoneNumber || '-',
      registrationEmail: education?.referenceEmail || '-',
      comment: education?.comments || '-',
      certificateFile: education?.documents.length > 0 ? education?.documents[0].fileName : '',
      certificateFileId: education?.documents.length > 0 ? education?.documents[0].id : '',
    }));

  if (isLoadingEducationHistoryDetails) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (loadingEducationHistoryDetailsError) {
    return (
      <Typography variant="h6">
        {loadingEducationHistoryDetailsError?.message || 'Error while fetching education history details.'}
      </Typography>
    );
  }

  return (
    <ProfileEducationalHistoryTemplate
      handleEdit={handleEdit}
      educationHistory={EducationHistoryList}
      handleAttachment={handleAttachment}
    />
  );
};

export default EducationalHistoryDetails;
