import React, { useEffect, useState } from 'react';
import { Box, Grid, Divider, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import Button from 'components/Atoms/Button';
import Typographys from 'components/Atoms/Typography';
import InputField from 'components/Atoms/InputField/index';
import Dropdown from 'components/Molecules/Dropdown';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Attachments from 'components/Atoms/Attachments';
import { Formik, FieldArray, getIn } from 'formik';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { addEmpStyle } from '../../style';

const SkillAttainmentDetails = ({
  tabHeading,
  handleSubmit,
  handleCancel,
  skillOptions,
  levelOptions,
  propData,
  label,
  updateBtnText,
}) => {
  const defaultObj = {
    skill: '',
    level: '',
    comments: '',
    attachment: '',
  };

  const defaultValues =
    propData && propData.allTabsData && propData.allTabsData[label]
      ? propData.allTabsData[label]
      : { skillDetails: [defaultObj] };

  return (
    <Box mt={1.5}>
      <Typographys variant="h5" color="neutral.800">
        {tabHeading}
      </Typographys>
      <Formik
        initialValues={defaultValues}
        onSubmit={(values, actions) => {
          handleSubmit(values);
          actions.setSubmitting(false);
        }}
      >
        {(formik) => (
          <Form
            formik={formik}
            handleCancel={handleCancel}
            skillOptions={skillOptions}
            levelOptions={levelOptions}
            propData={propData}
            label={label}
            defaultObj={defaultObj}
            updateBtnText={updateBtnText}
          />
        )}
      </Formik>
    </Box>
  );
};

const Form = ({ formik, handleCancel, skillOptions, levelOptions, propData, label, defaultObj, updateBtnText }) => {
  useEffect(() => {
    propData.updateFormData(formik.values, label);
  }, [formik.values, label]);
  const theme = useTheme();
  const [openBulkUploadDialog, setOpenBulkUploadDialog] = useState(false);

  return (
    <Box mt={2} component="form" onSubmit={formik.handleSubmit}>
      <FieldArray name="skillDetails">
        {({ push, remove }) => (
          <Box>
            <PerfectScrollbar>
              <Box sx={{ maxHeight: 'calc(100vh - 55.3rem)' }}>
                {formik.values.skillDetails.map((s, index) => {
                  return (
                    <Box sx={addEmpStyle.rowDivider}>
                      <Box sx={addEmpStyle.removeWrapper}>
                        <Button
                          size="medium"
                          variant="outlined"
                          type="button"
                          startIcon={<DeleteOutlineIcon />}
                          onClick={() => remove(index)}
                        >
                          Delete
                        </Button>
                      </Box>

                      <Grid container rowSpacing={2} columnSpacing={9} mt={0} sx={addEmpStyle.formContainer}>
                        <Grid item xs={12} md={6}>
                          <Dropdown
                            error={Boolean(
                              getIn(formik.touched, `skillDetails[${index}].skill`) &&
                                getIn(formik.errors, `skillDetails[${index}].skill`),
                            )}
                            helperText={
                              getIn(formik.touched, `skillDetails[${index}].skill`) &&
                              getIn(formik.errors, `skillDetails[${index}].skill`)
                            }
                            handleChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={s.skill}
                            name={`skillDetails[${index}].skill`}
                            id={`skillDetails[${index}].skill`}
                            label="Skill"
                            options={skillOptions}
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Dropdown
                            error={Boolean(
                              getIn(formik.touched, `skillDetails[${index}].level`) &&
                                getIn(formik.errors, `skillDetails[${index}].level`),
                            )}
                            helperText={
                              getIn(formik.touched, `skillDetails[${index}].level`) &&
                              getIn(formik.errors, `skillDetails[${index}].level`)
                            }
                            handleChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={s.level}
                            name={`skillDetails[${index}].level`}
                            id={`skillDetails[${index}].level`}
                            label="Level"
                            options={levelOptions}
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `skillDetails[${index}].comments`) &&
                                getIn(formik.errors, `skillDetails[${index}].comments`),
                            )}
                            helperText={
                              getIn(formik.touched, `skillDetails[${index}].comments`) &&
                              getIn(formik.errors, `skillDetails[${index}].comments`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={s.comments}
                            name={`skillDetails[${index}].comments`}
                            id={`skillDetails[${index}].comments`}
                            label="Comments"
                            multiline
                            fullWidth
                          />
                        </Grid>
                      </Grid>

                      <Box sx={{ mt: 2 }}>
                        <Attachments
                          isDeleteIcon
                          handleChange={(event) => {
                            formik.setFieldValue(`skillDetails[${index}].attachment`, event.target.files[0]);
                          }}
                          name={`skillDetails[${index}].attachment`}
                          id={`skillDetails[${index}].attachment`}
                          value={s.attachment}
                          handleDelete={() => {
                            formik.setFieldValue(`skillDetails[${index}].attachment`, '');
                          }}
                        />
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </PerfectScrollbar>

            <Box sx={addEmpStyle.addWrapper}>
              <Button
                size="medium"
                variant="outlined"
                type="button"
                startIcon={<AddOutlinedIcon />}
                onClick={() => push(defaultObj)}
              >
                Add Another
              </Button>
            </Box>
          </Box>
        )}
      </FieldArray>

      <Box sx={addEmpStyle.btnContainer}>
        <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

        <Box sx={addEmpStyle.submitWrapper}>
          <Button
            size="large"
            color="info"
            variant="outlined"
            type="cancel"
            onClick={() => {
              formik.resetForm();
              handleCancel();
            }}
          >
            Cancel
          </Button>

          <Button size="large" type="submit" isDisable={false}>
            {propData.allowEdit ? updateBtnText : 'Next'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

Form.defaultProps = {
  propData: {},
  handleCancel: () => {},
  skillOptions: [],
  levelOptions: [],
  formik: {},
  defaultObj: {},
  label: 'Skill Attainment Details',
  updateBtnText: 'Update',
};

Form.propTypes = {
  propData: PropTypes.shape({
    updateFormData: PropTypes.func,
    allTabsData: PropTypes.shape({
      'Skill Attainment Details': PropTypes.shape({
        skillDetails: PropTypes.shape([]),
      }),
    }),
    allowEdit: PropTypes.bool,
  }),
  handleCancel: PropTypes.func,
  skillOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  levelOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  label: PropTypes.string,
  formik: PropTypes.shape({
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    values: PropTypes.shape({
      skillDetails: PropTypes.shape([]),
    }),
    resetForm: PropTypes.func,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    setFieldValue: PropTypes.func,
  }),
  defaultObj: PropTypes.shape({}),
  updateBtnText: PropTypes.string,
};

SkillAttainmentDetails.defaultProps = {
  tabHeading: 'Skill Attainment Details',
  propData: {},
  handleSubmit: () => {},
  handleCancel: () => {},
  skillOptions: [],
  levelOptions: [],
  label: 'Skill Attainment Details',
  updateBtnText: 'Update',
};

SkillAttainmentDetails.propTypes = {
  tabHeading: PropTypes.string,
  propData: PropTypes.shape({
    updateFormData: PropTypes.func,
    allTabsData: PropTypes.shape({
      'Skill Attainment Details': PropTypes.shape({
        skillDetails: PropTypes.shape([]),
      }),
    }),
    allowEdit: PropTypes.bool,
  }),

  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  skillOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  levelOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  label: PropTypes.string,
  updateBtnText: PropTypes.string,
};

export default SkillAttainmentDetails;
