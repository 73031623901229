import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FormHelperText, Grid, Divider, Stack, Skeleton, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/system';
import { formDataApi } from 'api/api';
import InputField from 'components/Atoms/InputField';
import DatePickers from 'components/Atoms/Datepicker';
import Button from 'components/Atoms/Button';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import moment from 'moment';
import Typographys from 'components/Atoms/Typography';
import useToast from 'components/Provider/useToast';
import TdsForm from './TdsForm';
import EpfForm from './EpfForm';

const createPayloadMap = {
  challanType: 'challanType',
  periodStartDate: 'periodStartDate',
  periodEndDate: 'periodEndDate',
  amountDue: 'due',
  amountPaid: 'paid',
  dateOfPayment: 'dateOfPayment',
  modeOfPayment: 'modeOfPayment',
  bankName: 'bankName',
  branchName: 'branchName',
  branchCode: 'branchCode',
  paidByPerson: 'paidByPerson',
  paymentTransactionReference: 'paymentTransactionRef',
  challanCode: 'challanCode',
  description: 'description',
  incomeTaxComponent: 'incomeTax',
  surchargeComponent: 'surcharge',
  eduCessComponent: 'cess',
  interest: 'interest',
  fees: 'fees',
  penaltyOthers: 'penalty',
  others: 'others',
  lastTotalTaxPaid: 'lastTotalPaidTax',
  totalChallanAmountDeposited: 'totalAmountDeposited',
  lastBSRCode: 'lastChallanBSRCode',
  lastPaymentDepositDate: 'LastChallanPaymentDate',
  lastChallanSerialNumber: 'LastChallanSerialNumber',
  interestApportioned: 'interestApportioned',
  voucherNumber: 'voucherNumber',
  voucherDate: 'voucherDate',
  chequeDDNumber: 'chequeDDNumber',
  deductionDate: 'deductionDate',
  minorHeadCode: 'minorheadCode',
  acknowledgementNumber: 'ackNumber',
  latePaymentFee: 'lastPaymentFee',
  epfEEShareRemittedIndia: 'epfEmployeeShare',
  epfERShareRemittedIndia: 'epfEmployerShare',
  epsERShareRemittedIndia: 'EPSContibRemmited',
  edliAdminChargeRemittedIndia: 'EDLIAdminCharges',
  epfAdminChargeRemittedIndia: 'EPFAdminCharges',
  edliERShareRemittedIndia: 'EDLISchemeContrib',
  'payrollCalendar.id': 'payrollCalendar',
  'payPeriod.id': 'payPeriod',
};

const Form = ({ isEditMode, results, calendars, payheadDetails }) => {
  const navigate = useNavigate();
  const { challanTypesIndia, paymentModes } = payheadDetails;
  const addToast = useToast();
  const [loading, setLoading] = useState(false);

  const handleCustomDetails = async (details) => {
    try {
      setLoading(true);
      const query = new FormData();
      Object.keys(createPayloadMap).forEach((key) => {
        if (
          details[createPayloadMap[key]] ||
          (!details[createPayloadMap[key]] && details[createPayloadMap[key]] === false)
        ) {
          if (
            key === 'periodStartDate' ||
            key === 'periodEndDate' ||
            key === 'dateOfPayment' ||
            key === 'voucherDate' ||
            key === 'deductionDate' ||
            key === 'lastPaymentDepositDate'
          ) {
            query.append(key, dayjs(details[createPayloadMap[key]]).format('MM/DD/YYYY'));
          } else if (typeof details[createPayloadMap[key]] === 'boolean') {
            query.append(key, details[createPayloadMap[key]] ? 'on' : '');
          } else query.append(key, details[createPayloadMap[key]]);
        }
      });
      if (isEditMode) {
        query.append('id', results?.id);
      }
      const pathToCall = isEditMode ? 'restPayIndex/modifyChallanAction' : 'restPayIndex/saveChallan';
      const response = await formDataApi.post(pathToCall, query);
      if (response.status === 200) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Challan ${isEditMode ? 'updated' : 'added'} successfully`,
        });
        navigate('/company/salary/challans');
      } else {
        addToast({
          type: 'error',
          title: 'ERROR',
          message: response?.data?.message || `Failed to ${isEditMode ? 'update' : 'add'} challan`,
        });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to ${isEditMode ? 'update' : 'add'} challan`,
      });
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      challanType: results?.challanType ?? '',
      payrollCalendar: results?.payPeriod?.payrollCalendar?.id ?? '',
      payPeriod: results?.payPeriod?.id ?? '',
      periodStartDate: results?.payPeriod?.payPeriodStartDate ? dayjs(results?.payPeriod?.payPeriodStartDate) : '',
      periodEndDate: results?.payPeriod?.payPeriodEndDate ? dayjs(results?.payPeriod?.payPeriodEndDate) : '',
      due: results?.amountDue ?? '',
      paid: results?.amountPaid ?? '',
      dateOfPayment: results?.dateOfPayment ?? '',
      modeOfPayment: results?.modeOfPayment ?? '',
      bankName: results?.bankName ?? '',
      branchName: results?.branchName ?? '',
      branchCode: results?.branchCode ?? '',
      paidByPerson: results?.paidByPerson ?? '',
      paymentTransactionRef: results?.paymentTransactionReference ?? '',
      challanCode: results?.challanCode ?? '',
      description: results?.description ?? '',
      incomeTax: results?.incomeTaxComponent ?? '',
      surcharge: results?.surchargeComponent ?? '',
      cess: results?.eduCessComponent ?? '',
      fees: results?.fees ?? '',
      interest: results?.interest ?? '',
      penalty: results?.penaltyOthers ?? '',
      others: results?.others ?? '',
      lastTotalPaidTax: results?.lastTotalTaxPaid ?? '',
      totalAmountDeposited: results?.totalChallanAmountDeposited ?? '',
      lastChallanBSRCode: results?.lastBSRCode ?? '',
      LastChallanPaymentDate: results?.lastPaymentDepositDate ? dayjs(results?.lastPaymentDepositDate) : '',
      LastChallanSerialNumber: results?.lastChallanSerialNumber ?? '',
      interestApportioned: results?.interestApportioned ?? '',
      voucherNumber: results?.voucherNumber ?? '',
      voucherDate: results?.voucherDate ? dayjs(results?.voucherDate) : '',
      deductionDate: results?.deductionDate ? dayjs(results?.deductionDate) : '',
      chequeDDNumber: results?.chequeDDNumber ?? '',
      minorheadCode: results?.minorHeadCode ?? '',
      ackNumber: results?.acknowledgementNumber ?? '',
      lastPaymentFee: results?.latePaymentFee ?? '',
      epfEmployeeShare: results?.epfEEShareRemittedIndia ?? '',
      epfEmployerShare: results?.epfERShareRemittedIndia ?? '',
      EPSContibRemmited: results?.epsERShareRemittedIndia ?? '',
      EDLISchemeContrib: results?.edliERShareRemittedIndia ?? '',
      EPFAdminCharges: results?.epfAdminChargeRemittedIndia ?? '',
      EDLIAdminCharges: results?.edliAdminChargeRemittedIndia ?? '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      challanType: Yup.string().required('Required'),
      payrollCalendar: Yup.mixed().required('Required'),
      payPeriod: Yup.mixed().required('Required'),
      due: Yup.string().required('Required'),
      paid: Yup.string().required('Required'),
      dateOfPayment: Yup.string().required('Required'),
      modeOfPayment: Yup.string().required('Required'),
      challanCode: Yup.string().required('Required'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        handleCustomDetails(values);
        // helpers.resetForm();
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
      } catch (err) {
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });

  if (!calendars?.length || !challanTypesIndia?.length || !payheadDetails) {
    return (
      <Box sx={{ py: 4 }}>
        <Skeleton height={42} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }
  const currentCalendarPPs = calendars.length
    ? calendars
        .find((calendar) => calendar.id === formik.values.payrollCalendar)
        ?.payPeirods.sort((a, b) => a.id - b.id)
    : null;

  const currentPayPeriod = currentCalendarPPs?.length
    ? currentCalendarPPs.find((pp) => pp.id === formik.values.payPeriod)
    : null;

  return (
    <Grid container spacing={4} sx={{ py: 4 }}>
      <Grid item md={12} xs={12}>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <Grid container columnSpacing={6} rowSpacing={3}>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  error={Boolean(formik.touched.challanType && formik.errors.challanType)}
                  helperText={formik.touched.challanType && formik.errors.challanType}
                  label="Challan Type"
                  name="challanType"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  select
                  value={formik.values.challanType}
                >
                  {challanTypesIndia.map((statusItem) => (
                    <MenuItem key={statusItem} value={statusItem}>
                      {statusItem}
                    </MenuItem>
                  ))}
                </InputField>
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  error={Boolean(formik.touched.payrollCalendar && formik.errors.payrollCalendar)}
                  helperText={formik.touched.payrollCalendar && formik.errors.payrollCalendar}
                  label="Payroll Calendar"
                  name="payrollCalendar"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  select
                  value={formik.values.payrollCalendar}
                >
                  {calendars.map((statusItem) => (
                    <MenuItem key={statusItem?.id} value={statusItem?.id}>
                      {statusItem?.name}
                    </MenuItem>
                  ))}
                </InputField>
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  disabled={!formik?.values?.payrollCalendar}
                  error={Boolean(formik.touched.payPeriod && formik.errors.payPeriod)}
                  helperText={formik.touched.payPeriod && formik.errors.payPeriod}
                  label="Pay Period"
                  name="payPeriod"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  select
                  value={formik.values.payPeriod}
                >
                  {currentCalendarPPs ? (
                    currentCalendarPPs.map((statusItem, index) => (
                      <MenuItem key={statusItem?.id} value={statusItem?.id}>
                        PayPeriod {index + 1}: ({moment(statusItem?.payPeriodStartDate).format('DD MMMM YYYY')} -{' '}
                        {moment(statusItem?.payPeriodEndDate).format('DD MMMM YYYY')})
                      </MenuItem>
                    ))
                  ) : (
                    <div />
                  )}
                </InputField>
              </Grid>
              <Grid item xs={4}>
                <DatePickers
                  id="Period Start Date"
                  error={Boolean(formik.touched.periodStartDate && formik.errors.periodStartDate)}
                  label="Period Start Date"
                  name="periodStartDate"
                  disabled
                  value={currentPayPeriod?.payPeriodStartDate ? dayjs(currentPayPeriod?.payPeriodStartDate) : ''}
                  onChange={(value) => {
                    formik.setFieldValue('periodStartDate', Date.parse(value));
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <DatePickers
                  id="Period End Date"
                  error={Boolean(formik.touched.periodEndDate && formik.errors.periodEndDate)}
                  label="Period End Date"
                  name="periodEndDate"
                  disabled
                  value={currentPayPeriod?.payPeriodEndDate ? dayjs(currentPayPeriod?.payPeriodEndDate) : ''}
                  onChange={(value) => {
                    formik.setFieldValue('periodEndDate', Date.parse(value));
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider flexItem orientation="horizontal" />{' '}
              </Grid>
              <Grid item xs={12}>
                <Typographys variant="subtitle1"> Bank Challan Details</Typographys>
              </Grid>
              <Grid item xs={4} sx={{ pt: '1.25rem !important' }}>
                <InputField
                  fullWidth
                  error={Boolean(formik.touched.due && formik.errors.due)}
                  helperText={formik.touched.due && formik.errors.due}
                  value={formik.values.due}
                  name="due"
                  label="Amount Due"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={4} sx={{ pt: '1.25rem !important' }}>
                <InputField
                  fullWidth
                  error={Boolean(formik.touched.paid && formik.errors.paid)}
                  helperText={formik.touched.paid && formik.errors.paid}
                  value={formik.values.paid}
                  name="paid"
                  label="Amount Paid"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={4} sx={{ pt: '1.25rem !important' }}>
                <InputField
                  fullWidth
                  label="Date Of Payment *"
                  name="dateOfPayment"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={Boolean(formik.touched.dateOfPayment && formik.errors.dateOfPayment)}
                  helperText={formik.touched.dateOfPayment && formik.errors.dateOfPayment}
                  value={formik.values.dateOfPayment}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  label="Mode Of Payment *"
                  error={Boolean(formik.touched.modeOfPayment && formik.errors.modeOfPayment)}
                  helperText={formik.touched.modeOfPayment && formik.errors.modeOfPayment}
                  name="modeOfPayment"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.modeOfPayment}
                  select
                >
                  {paymentModes.map((statusItem) => (
                    <MenuItem key={statusItem} value={statusItem}>
                      {statusItem}
                    </MenuItem>
                  ))}
                </InputField>
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  label="Bank Name"
                  error={Boolean(formik.touched.bankName && formik.errors.bankName)}
                  helperText={formik.touched.bankName && formik.errors.bankName}
                  name="bankName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.bankName}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  label="Branch Name"
                  error={Boolean(formik.touched.branchName && formik.errors.branchName)}
                  helperText={formik.touched.branchName && formik.errors.branchName}
                  name="branchName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.branchName}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  label="Branch Code"
                  error={Boolean(formik.touched.branchCode && formik.errors.branchCode)}
                  helperText={formik.touched.branchCode && formik.errors.branchCode}
                  value={formik.values.branchCode}
                  name="branchCode"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  label="Paid By Person"
                  error={Boolean(formik.touched.paidByPerson && formik.errors.paidByPerson)}
                  helperText={formik.touched.paidByPerson && formik.errors.paidByPerson}
                  value={formik.values.paidByPerson}
                  name="paidByPerson"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  label="Payment Transaction Reference"
                  error={Boolean(formik.touched.paymentTransactionRef && formik.errors.paymentTransactionRef)}
                  helperText={formik.touched.paymentTransactionRef && formik.errors.paymentTransactionRef}
                  value={formik.values.paymentTransactionRef}
                  name="paymentTransactionRef"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  label="Challan Code *"
                  error={Boolean(formik.touched.challanCode && formik.errors.challanCode)}
                  helperText={formik.touched.challanCode && formik.errors.challanCode}
                  value={formik.values.challanCode}
                  name="challanCode"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  fullWidth
                  label="Exemption Statute Reference"
                  size="small"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: formik.values.excemptionStatueReference,
                  }}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  multiline
                  name="excemptionStatueReference"
                  rows={1}
                  value={formik.values.excemptionStatueReference}
                  error={Boolean(formik.touched.excemptionStatueReference && formik.errors.excemptionStatueReference)}
                />
              </Grid>
              {formik.values.challanType === 'TDS Challan' && <TdsForm formik={formik} />}
              {formik.values.challanType === 'EPF Challan' && <EpfForm formik={formik} />}
              {formik.errors.submit && (
                <Grid item xs={12}>
                  <FormHelperText error>{formik.errors.submit}</FormHelperText>
                </Grid>
              )}
              <Grid item xs={12}>
                <Stack direction="row" justifyContent={isEditMode ? 'space-between' : 'end'}>
                  {/* {isEditMode && (
                    <Button color="primary" size="large" variant="outlined">
                      Delete
                    </Button>
                  )} */}
                  <Stack direction="row" justifyContent="end" spacing={2}>
                    <Button size="Large" variant="outlined" sx={{ color: 'text.200 !important' }}>
                      Cancel
                    </Button>
                    <LoadingButton loading={loading} color="primary" size="large" type="submit" variant="contained">
                      {isEditMode ? 'Update' : 'Create'}
                    </LoadingButton>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </div>
        </form>
      </Grid>
    </Grid>
  );
};

export default Form;

Form.propTypes = {
  isEditMode: PropTypes.bool,
  results: PropTypes.instanceOf(Object),
};

Form.defaultProps = {
  isEditMode: false,
  results: {},
};
