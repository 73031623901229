export const fontFamily = 'Montserrat, sans-serif';

export const fontWeightMedium = '500';

export const fontWeightSemibold = '600';

export const fontWeightBold = '700';

export const fontSizeH1 = '32px';

export const fontSizeH2 = '25px';

export const fontSizeH3 = '22px';

export const fontSizeH4 = '16px';

export const fontSizeBody = '14px';

export const fontSizeBodySmall = '12px';

export const fontH1Bold = 'normal normal 700 32px/40px Montserrat, sans-serif';

export const fontH1Semibold = 'normal normal 600 32px/40px Montserrat, sans-serif';

export const fontH1Medium = 'normal normal 500 32px/40px Montserrat, sans-serif';

export const fontH2Bold = 'normal normal 700 25px/32px Montserrat, sans-serif';

export const fontH2Semibold = 'normal normal 600 25px/32px Montserrat, sans-serif';

export const fontH2Medium = 'normal normal 500 25px/32px Montserrat, sans-serif';

export const fontH3Bold = 'normal normal 700 22px/28px Montserrat, sans-serif';

export const fontH3Semibold = 'normal normal 600 22px/28px Montserrat, sans-serif';

export const fontH3Medium = 'normal normal 500 22px/28px Montserrat, sans-serif';

export const fontH4Bold = 'normal normal 700 16px/24px Montserrat, sans-serif';

export const fontH4Semibold = 'normal normal 600 16px/24px Montserrat, sans-serif';

export const fontH4Medium = 'normal normal 500 16px/24px Montserrat, sans-serif';

export const fontBodyBold = 'normal normal 700 14px/20px Montserrat, sans-serif';

export const fontBodySemibold = 'normal normal 600 14px/20px Montserrat, sans-serif';

export const fontBodyMedium = 'normal normal 500 14px/20px Montserrat, sans-serif';

export const fontBodySmallBold = 'normal normal 700 12px/16px Montserrat, sans-serif';

export const fontBodySmallSemibold = 'normal normal 600 12px/16px Montserrat, sans-serif';

export const fontBodySmallMedium = 'normal normal 500 12px/16px Montserrat, sans-serif';

export const fontLineHeightH1 = '40px';

export const fontLineHeightH2 = '32px';

export const fontLineHeightH3 = '28px';

export const fontLineHeightH4 = '24px';

export const fontLineHeightBody = '20px';

export const fontLineHeightBodySmall = '16px';
