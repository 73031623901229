import PropTypes from 'prop-types';
import React from 'react';
import Dialog from 'components/Molecules/Dialog';
import Typographys from 'components/Atoms/Typography';

const DeleteExpensesReport = ({ handleDelete, handleCancel, open, handleClose, reportName }) => {
  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      dialogTitle="Delete Report"
      dialogDesc={
        <Typographys
          sx={{ textTransform: 'none', maxWidth: '42.5rem', m: '0 auto 1rem' }}
          component="div"
          color="neutral.600"
          align="center"
        >
          Are you sure you want to delete the report -
          <Typographys color="neutral.600" sx={{ display: 'inline', pl: 1, fontWeight: 'bold' }}>
            {reportName} ?
          </Typographys>
        </Typographys>
      }
      primaryBtn="Delete"
      secondaryBtn="Cancel"
      handlePrimaryBtn={handleDelete}
      handleSecondaryBtn={handleCancel}
      primaryBtnColor="error"
      bodyContent={false}
    />
  );
};
DeleteExpensesReport.defaultProps = {
  reportName: '',
  handleDelete: () => {},
  handleCancel: () => {},
  open: true,
  handleClose: () => {},
};
DeleteExpensesReport.propTypes = {
  reportName: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleDelete: PropTypes.func,
  handleCancel: PropTypes.func,
};
export default DeleteExpensesReport;
