import React, { useEffect } from 'react';
import { Box, Grid, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import Button from 'components/Atoms/Button';
import Typographys from 'components/Atoms/Typography';
import { useFormik } from 'formik';
import { useTheme } from '@mui/material/styles';
import Checkbox from 'components/Atoms/Checkbox';
import { isEmpty } from 'lodash';
import { addEmpStyle } from '../style';

const UserRoles = ({ userRoleOptions, tabHeading, handleSubmit, handleCancel, propData, label }) => {
  const defaultValues = propData.allTabsData?.['User Roles'];
  const initialValues = isEmpty(defaultValues)
    ? userRoleOptions.reduce((accumulator, item) => {
        accumulator[item.id] = false;
        return accumulator;
      }, {})
    : defaultValues;
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: handleSubmit,
  });

  const theme = useTheme();

  useEffect(() => {
    propData.updateFormData(formik.values, label);
  }, [formik.values, label]);

  return (
    <Box sx={{ p: '2rem 3rem' }}>
      <Typographys variant="h5" color="neutral.800">
        {tabHeading}
      </Typographys>

      <Box mt={2} component="form" onSubmit={formik.handleSubmit}>
        <Box sx={{ maxHeight: 'calc(100vh - 41.3rem)' }}>
          <Grid container rowSpacing={2} columnSpacing={9} sx={addEmpStyle.formContainer}>
            {userRoleOptions.map(({ id, name }) => {
              return (
                <Grid key={id} item xs={12} md={3}>
                  <Checkbox
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    key={formik.values?.[id]}
                    checked={formik.values?.[id]}
                    color="secondary"
                    name={[id]}
                    label={[name]}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <Box sx={addEmpStyle.btnContainer}>
          <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

          <Box sx={addEmpStyle.submitWrapper}>
            <Button
              size="large"
              color="info"
              variant="outlined"
              type="cancel"
              onClick={() => {
                formik.resetForm();
                handleCancel();
              }}
            >
              Cancel
            </Button>

            <Button size="large" type="submit" isDisable={false}>
              {propData.allowEdit ? 'Update' : 'Next'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

UserRoles.defaultProps = {
  tabHeading: 'Select Applicable Roles',
  propData: {},
  handleSubmit: () => {},
  handleCancel: () => {},
  label: '',
};

UserRoles.propTypes = {
  tabHeading: PropTypes.string,
  propData: PropTypes.shape({
    updateFormData: PropTypes.func,
    allTabsData: PropTypes.shape({
      'User Roles': PropTypes.shape({}),
    }),
    allowEdit: PropTypes.bool,
    roleMap: PropTypes.shape({}),
  }),
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  label: PropTypes.string,
};

export default UserRoles;
