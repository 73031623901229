import React from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import PropTypes from 'prop-types';
import BasicProfile from 'components/Organisms/Profile/BasicProfile/View';

const BasicProfileTemplate = ({
  employeeProfileDetail,
  rows,
  keyColumn,
  sortDirection,
  sortBy,
  onSortChange,
  count,
  page,
  onPageChange,
  showPagination,
  onRowClick,
  handleSelect,
  loading,
  employeeDetail,
  employeePersonalInformation,
  employeeEmergencyContactInformation,
  employeeContactDetails,
  employeeContactEmailId,
  handleEdit,
}) => {
  return (
    <BlankTemplate padding="0rem" pageHeading="Basic Profile" isBreadcrumb={false}>
      <BasicProfile
        employeeProfileDetail={employeeProfileDetail}
        rows={rows}
        keyColumn={keyColumn}
        sortDirection={sortDirection}
        sortBy={sortBy}
        onSortChange={onSortChange}
        count={count}
        page={page}
        onPageChange={onPageChange}
        showPagination={showPagination}
        onRowClick={onRowClick}
        handleSelect={handleSelect}
        loading={loading}
        employeeDetail={employeeDetail}
        employeePersonalInformation={employeePersonalInformation}
        employeeEmergencyContactInformation={employeeEmergencyContactInformation}
        employeeContactDetails={employeeContactDetails}
        employeeContactEmailId={employeeContactEmailId}
        handleEdit={handleEdit}
      />
    </BlankTemplate>
  );
};

BasicProfileTemplate.defaultProps = {
  employeeProfileDetail: {},
  keyColumn: 'id',
  sortDirection: 'desc',
  sortBy: 'id',
  onSortChange: () => {},
  page: 1,
  onPageChange: () => {},
  showPagination: false,
  onRowClick: () => {},
  handleSelect: () => {},
  handleEdit: () => {},
  loading: false,
  rows: [],

  employeeDetail: {},

  employeePersonalInformation: {},

  employeeEmergencyContactInformation: {},
  employeeContactDetails: [],
  employeeContactEmailId: [],
};

BasicProfileTemplate.propTypes = {
  employeeProfileDetail: PropTypes.arrayOf(PropTypes.shape({})),
  rows: PropTypes.arrayOf,
  keyColumn: PropTypes.string,
  onSortChange: PropTypes.func,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  showPagination: PropTypes.bool,
  count: PropTypes.number.isRequired,
  onRowClick: PropTypes.func,
  handleSelect: PropTypes.func,
  loading: PropTypes.bool,

  employeeDetail: PropTypes.shape({
    panNumber: PropTypes.string,
    aadharNumber: PropTypes.string,
    uan: PropTypes.string,
    epfAccountNumber: PropTypes.string,
    esiAccountNumber: PropTypes.string,
  }),
  handleEdit: PropTypes.func,

  employeePersonalInformation: PropTypes.shape({
    bloodGroup: PropTypes.string,
    dateOfBirth: PropTypes.string,
    dateOfJoining: PropTypes.string,
    motherName: PropTypes.string,
    fatherName: PropTypes.string,
    spouseName: PropTypes.string,
    schoolGoingChildren: PropTypes.string,
    childrenHostel: PropTypes.string,
  }),
  employeeEmergencyContactInformation: PropTypes.shape({
    name: PropTypes.string,
    mobileNumber: PropTypes.string,
    relation: PropTypes.string,
    emailid: PropTypes.string,
  }),
  employeeContactDetails: PropTypes.shape([]),
  employeeContactEmailId: PropTypes.shape([]),
};

export default BasicProfileTemplate;
