import React from 'react';
import { Box, Grid, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import Button from 'components/Atoms/Button';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { LoadingButton } from '@mui/lab';
import InputField from 'components/Atoms/InputField/index';
import { useTheme } from '@mui/material/styles';
import DatePicker from 'components/Atoms/Datepicker';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import * as yup from 'yup';
import { Formik, FieldArray, getIn } from 'formik';
import Dropdown from 'components/Molecules/Dropdown';
import Typography from '@mui/material/Typography';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import dayjs from 'dayjs';
import { profileStyle } from '../style';

const ProfileLifeEventDetailsEdit = ({ handleSubmit, handleCancel, defaultValue, lifeEventOptions, loading }) => {
  const defaultObj = {
    eventType: lifeEventOptions?.[0]?.name || '',
    eventDate: '',
    comments: '',
  };

  const employeeSchema = yup.object().shape({
    lifeEvents: yup
      .array()
      .of(
        yup.object().shape({
          eventDate: yup.string().required('Required'),
        }),
      )
      .min(1, 'Required'),
  });

  return (
    <Box>
      <Formik
        validationSchema={employeeSchema}
        validateOnMount
        initialValues={defaultValue}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(formik) => (
          <Form
            formik={formik}
            handleCancel={handleCancel}
            defaultObj={defaultObj}
            lifeEventOptions={lifeEventOptions}
            loading={loading}
          />
        )}
      </Formik>
    </Box>
  );
};

const Form = ({ formik, handleCancel, defaultObj, lifeEventOptions, loading }) => {
  const handleDateChange = (date, setFieldValue, label) => {
    setFieldValue(label, date);
  };

  const theme = useTheme();

  const validateDate = (value) => (value ? dayjs(value) : null);

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 40rem)' }} mt={2}>
          <FieldArray name="lifeEvents">
            {({ push, remove }) => (
              <>
                <Box>
                  {formik.values.lifeEvents?.map((e, index) => {
                    return (
                      <Box sx={profileStyle.formDivider}>
                        <Box sx={profileStyle.deleteContainer}>
                          <Typography variant="subtitle2" color="neutral.800">
                            Record {index + 1}
                          </Typography>
                          <Button
                            startIcon={<DeleteForeverIcon />}
                            onClick={(event) => {
                              event.preventDefault();
                              remove(index);
                            }}
                            variant="outlined"
                          >
                            Delete
                          </Button>
                        </Box>
                        <Grid container rowSpacing={2} columnSpacing={2} sx={profileStyle.formContainer}>
                          <Grid item xs={12} md={4}>
                            <Dropdown
                              error={Boolean(
                                getIn(formik.touched, `lifeEvents[${index}].eventType`) &&
                                  getIn(formik.errors, `lifeEvents[${index}].eventType`),
                              )}
                              helperText={
                                getIn(formik.touched, `lifeEvents[${index}].eventType`) &&
                                getIn(formik.errors, `lifeEvents[${index}].eventType`)
                              }
                              handleChange={(b) => {
                                formik.handleChange(b);
                              }}
                              onBlur={formik.handleBlur}
                              value={e?.eventType}
                              name={`lifeEvents[${index}].eventType`}
                              id={`lifeEvents[${index}].eventType`}
                              label="Key Life Event Details"
                              options={lifeEventOptions}
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <DatePicker
                              error={Boolean(
                                getIn(formik.touched, `lifeEvents[${index}].eventDate`) &&
                                  getIn(formik.errors, `lifeEvents[${index}].eventDate`),
                              )}
                              helperText={
                                getIn(formik.touched, `lifeEvents[${index}].eventDate`) &&
                                getIn(formik.errors, `lifeEvents[${index}].eventDate`)
                              }
                              onChange={(date) =>
                                handleDateChange(date, formik.setFieldValue, `lifeEvents[${index}].eventDate`)
                              }
                              onBlur={formik.handleBlur}
                              value={validateDate(e?.eventDate)}
                              name={`lifeEvents[${index}].eventDate`}
                              id={`lifeEvents[${index}].eventDate`}
                              label="Event Date*"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `lifeEvents[${index}].comments`) &&
                                  getIn(formik.errors, `lifeEvents[${index}].comments`),
                              )}
                              helperText={
                                getIn(formik.touched, `lifeEvents[${index}].comments`) &&
                                getIn(formik.errors, `lifeEvents[${index}].comments`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e?.comments}
                              name={`lifeEvents[${index}].comments`}
                              id={`lifeEvents[${index}].comments`}
                              label="Comments"
                              fullWidth
                              multiline
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })}
                </Box>

                <Box sx={{ mt: 3 }}>
                  <Button
                    size="medium"
                    variant="outlined"
                    type="button"
                    startIcon={<AddOutlinedIcon />}
                    onClick={() => push(defaultObj)}
                  >
                    Add Event Record
                  </Button>
                </Box>
              </>
            )}
          </FieldArray>
        </Box>
      </PerfectScrollbar>

      <Box sx={profileStyle.btnContainer}>
        <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

        <Box sx={profileStyle.submitWrapper}>
          <Button
            size="large"
            color="info"
            variant="outlined"
            type="cancel"
            onClick={() => {
              formik.resetForm();
              handleCancel();
            }}
          >
            Cancel
          </Button>
          <LoadingButton loading={loading} color="primary" size="large" type="submit" variant="contained">
            Update
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

Form.defaultProps = {
  handleCancel: () => {},
  formik: {},
  defaultObj: {},
  lifeEventOptions: [],
};

Form.propTypes = {
  handleCancel: PropTypes.func,
  formik: PropTypes.shape({
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    values: PropTypes.shape({
      lifeEvents: PropTypes.shape([]),
    }),
    resetForm: PropTypes.func,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    setFieldValue: PropTypes.func,
  }),
  defaultObj: PropTypes.shape({}),
  lifeEventOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
};

ProfileLifeEventDetailsEdit.defaultProps = {
  handleSubmit: () => {},
  handleCancel: () => {},
  defaultValue: {
    lifeEvents: [
      {
        eventType: '',
        eventDate: '',
        comments: '',
      },
    ],
  },
  lifeEventOptions: [],
  loading: false,
};

ProfileLifeEventDetailsEdit.propTypes = {
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  defaultValue: PropTypes.shape({}),
  lifeEventOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
};

export default ProfileLifeEventDetailsEdit;
