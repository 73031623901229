import { makeStyles } from '@mui/styles';
import { v4 as uuid } from 'uuid';

const uid = uuid();

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      marginTop: '3rem',
      padding: `${theme.space.spaceSZero} 3rem 3rem`,
    },
  }),
  {
    classNamePrefix: 'textEditorPlate',
    name: 'textEditorPlate',
    generateId: uid,
    index: uid,
  },
);
