export const avatarStyle = {
  wrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },

  nameWrapper: {
    position: 'relative',
    display: 'inline-block',
  },

  shortName: {
    fontSize: '2rem',
    lineHeight: '2.5rem',
    fontWeight: 600,
  },

  badge: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: '1rem',
    height: '1rem',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: '0.25rem',
    borderStyle: 'solid',
    borderColor: 'common.white',
    padding: 0,
    borderRadius: '50%',
  },
};
