import React from 'react';

export const ThreeDotsIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.07418 5.03722C8.72879 5.03722 9.25945 4.50656 9.25945 3.85196C9.25945 3.19735 8.72879 2.66669 8.07418 2.66669C7.41958 2.66669 6.88892 3.19735 6.88892 3.85196C6.88892 4.50656 7.41958 5.03722 8.07418 5.03722Z"
        fill="currentColor"
      />
      <path
        d="M8.07418 9.18633C8.72879 9.18633 9.25945 8.65567 9.25945 8.00106C9.25945 7.34646 8.72879 6.8158 8.07418 6.8158C7.41958 6.8158 6.88892 7.34646 6.88892 8.00106C6.88892 8.65567 7.41958 9.18633 8.07418 9.18633Z"
        fill="currentColor"
      />
      <path
        d="M8.07418 13.3334C8.72879 13.3334 9.25945 12.8027 9.25945 12.1481C9.25945 11.4935 8.72879 10.9628 8.07418 10.9628C7.41958 10.9628 6.88892 11.4935 6.88892 12.1481C6.88892 12.8027 7.41958 13.3334 8.07418 13.3334Z"
        fill="currentColor"
      />
    </svg>
  );
};
