import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import EmployeeDetailCard from 'components/Organisms/Employee/EmployeeRecord/ActionList/EmployeeDetailCard';
import EffectiveDateForm from 'components/Organisms/Employee/EmployeeRecord/ActionList/EffectiveDateForm';

const VerifyBackground = ({ label, btnLabel, employeeData, handleSubmit, handleCancel }) => {
  return (
    <>
      <EmployeeDetailCard employeeData={employeeData} />
      <Box mt={2}>
        <EffectiveDateForm
          heading="**Please specify background verification notes as needed below."
          label={label}
          btnLabel={btnLabel}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
        />
      </Box>
    </>
  );
};

VerifyBackground.defaultProps = {
  label: 'Background Verification',
  btnLabel: 'Update',
  employeeData: {},
  handleSubmit: () => {},
  handleCancel: () => {},
};

VerifyBackground.propTypes = {
  label: PropTypes.string,
  btnLabel: PropTypes.string,
  employeeData: PropTypes.shape(),
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default VerifyBackground;
