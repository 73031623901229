import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { validateDateElseHyphen } from 'utils/commonUtils';
import TitleWithText from 'components/Atoms/TitleWithText';
import Typographys from 'components/Atoms/Typography';
import IconWithTitle from 'components/Atoms/IconWithTitle';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import NoRecordsMessage from 'components/Organisms/CommonPageUtils/NoRecordsFound';
import { additionalDetailStyle } from '../style';

const EmploymentHistory = ({ sectionHeading, employmentHistory }) => {
  return (
    <>
      <Typographys variant="h5" color="neutral.700">
        {sectionHeading}
      </Typographys>
      <Box mt={2} sx={additionalDetailStyle.rowDivider}>
        {!employmentHistory?.length ? (
          <NoRecordsMessage />
        ) : (
          employmentHistory?.map((employmentList) => (
            <Grid container rowSpacing={1} columnSpacing={2}>
              <Grid item xs={12} sm={4}>
                <TitleWithText title="Employer Name" text={employmentList?.employerName} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TitleWithText title="Employer Address" text={employmentList?.employerAddress} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TitleWithText
                  title="Employment Commence Date"
                  text={validateDateElseHyphen(employmentList?.commenceDate, 'DD MMMM, YYYY h:mm A')}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TitleWithText
                  title="Employment End Date"
                  text={validateDateElseHyphen(employmentList?.endDate, 'DD MMMM, YYYY h:mm A')}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TitleWithText title="Reason for Leaving" text={employmentList?.reasonForLeaving} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TitleWithText title="Employee ID" text={employmentList?.employeeID} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TitleWithText title="Start Designation" text={employmentList?.startDesignation} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TitleWithText title="End Designation" text={employmentList?.endDesignation} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TitleWithText title="Reference Contact Name" text={employmentList?.referenceName} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TitleWithText title="Reference Contact Designation" text={employmentList?.referenceDesignation} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TitleWithText title="Reference Contact Phone" text={employmentList?.referencePhone} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TitleWithText title="Reference Contact Email" text={employmentList?.referenceEmail} />
              </Grid>
              <Grid item xs={12} mt={4}>
                <Typographys variant="h6" color="neutral.600">
                  Attachments
                </Typographys>
                <Box sx={{ mt: 2, pl: 1 }}>
                  <IconWithTitle
                    iconElement={<AttachFileIcon fontSize="inherit" />}
                    iconColor="actions.600"
                    iconFontSize="2.5rem"
                    title={employmentList?.experienceLetter}
                    titleColor="neutral.800"
                    titleVariant="subtitle2"
                  />
                </Box>
              </Grid>
            </Grid>
          ))
        )}
      </Box>
    </>
  );
};

EmploymentHistory.defaultProps = {
  sectionHeading: 'Employment History',
  employmentHistory: [],
};

EmploymentHistory.propTypes = {
  sectionHeading: PropTypes.string,
  employmentHistory: PropTypes.arrayOf(
    PropTypes.shape({
      employerName: PropTypes.string,
      employerAddress: PropTypes.string,
      commenceDate: PropTypes.string,
      endDate: PropTypes.string,
      reasonForLeaving: PropTypes.string,
      employeeID: PropTypes.string,
      startDesignation: PropTypes.string,
      endDesignation: PropTypes.string,
      referenceName: PropTypes.string,
      referenceDesignation: PropTypes.string,
      referencePhone: PropTypes.string,
      referenceEmail: PropTypes.string,
      experienceLetter: PropTypes.string,
    }),
  ),
};

export default EmploymentHistory;
