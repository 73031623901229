import React, { useState } from 'react';
import ProfileEditAddressesTemplate from 'components/Templates/Profile/Addresses/Edit';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useToast from 'components/Provider/useToast';
import { addEditProfileAddresses } from 'api/profile';

const AddEditAddress = () => {
  const navigate = useNavigate();
  const addToast = useToast();
  const [loading, setLoading] = useState(false);
  const { contactDetails } = useSelector((state) => {
    return state.profile;
  });

  const handleCancel = () => {
    navigate('/profile/addresses');
  };

  const defaultValue = {
    permanentLocation: contactDetails?.permanentHomeAddress?.locationName || '',
    permanentAddressLine1: contactDetails?.permanentHomeAddress?.addressLine1,
    permanentAddressLine2: contactDetails?.permanentHomeAddress?.addressLine2 || '',
    permanentAddressLine3: contactDetails?.permanentHomeAddress?.addressLine3 || '',
    permanentCity: contactDetails?.permanentHomeAddress?.city,
    permanentState: contactDetails?.permanentHomeAddress?.state,
    permanentCountry: contactDetails?.permanentHomeAddress?.country,
    permanentPincode: contactDetails?.permanentHomeAddress?.pinOrZip,
    currentLocation: contactDetails?.presentCommunicationAddress?.locationName || '',
    currentAddressLine1: contactDetails?.presentCommunicationAddress?.addressLine1,
    currentAddressLine2: contactDetails?.presentCommunicationAddress?.addressLine2 || '',
    currentAddressLine3: contactDetails?.presentCommunicationAddress?.addressLine3 || '',
    currentCity: contactDetails?.presentCommunicationAddress?.city,
    currentState: contactDetails?.presentCommunicationAddress?.state,
    currentCountry: contactDetails?.presentCommunicationAddress?.country,
    currentPincode: contactDetails?.presentCommunicationAddress?.pinOrZip,
    ofcLocationName: contactDetails?.officeAddress?.locationName || '',
    ofcAddressLine1: contactDetails?.officeAddress?.addressLine1,
    ofcAddressLine2: contactDetails?.officeAddress?.addressLine2 || '',
    ofcAddressLine3: contactDetails?.officeAddress?.addressLine3 || '',
    ofcCity: contactDetails?.officeAddress?.city,
    ofcState: contactDetails?.officeAddress?.state,
    ofcCountry: contactDetails?.officeAddress?.country,
    ofcPincode: contactDetails?.officeAddress?.pinOrZip,
  };

  const getDataSet = (address) => ({
    'permanentHomeAddress.locationName': address?.permanentLocation || '',
    'permanentHomeAddress.addressLine1': address?.permanentAddressLine1,
    'permanentHomeAddress.addressLine2': address?.permanentAddressLine2 || '',
    'permanentHomeAddress.addressLine3': address?.permanentAddressLine3 || '',
    'permanentHomeAddress.city': address?.permanentCity,
    'permanentHomeAddress.state': address?.permanentState,
    'permanentHomeAddress.country': address?.permanentCountry,
    'permanentHomeAddress.pinOrZip': address?.permanentPincode,
    'presentCommunicationAddress.locationName': address?.currentLocation || '',
    'presentCommunicationAddress.addressLine1': address?.currentAddressLine1,
    'presentCommunicationAddress.addressLine2': address?.currentAddressLine2 || '',
    'presentCommunicationAddress.addressLine3': address?.currentAddressLine3 || '',
    'presentCommunicationAddress.city': address?.currentCity,
    'presentCommunicationAddress.state': address?.currentState,
    'presentCommunicationAddress.country': address?.currentCountry,
    'presentCommunicationAddress.pinOrZip': address?.currentPincode,
    'officeAddress.locationName': address?.ofcLocationName || '',
    'officeAddress.addressLine1': address?.ofcAddressLine1,
    'officeAddress.addressLine2': address?.ofcAddressLine2 || '',
    'officeAddress.addressLine3': address?.ofcAddressLine3 || '',
    'officeAddress.city': address?.ofcCity,
    'officeAddress.state': address?.ofcState,
    'officeAddress.country': address?.ofcCountry,
    'officeAddress.pinOrZip': address?.ofcPincode,
  });

  const configureFormDataAndUpdate = async (address) => {
    const formData = new FormData();
    const dataSet = getDataSet(address);
    Object.keys(dataSet).forEach((data) => {
      formData.append(data, dataSet[data]);
    });
    return formData;
  };

  const handleSubmit = async (formikData) => {
    const bodyFormData = await configureFormDataAndUpdate(formikData);

    try {
      setLoading(true);
      const response = await addEditProfileAddresses(bodyFormData);
      if (response?.data?.status !== 'ERROR') {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || 'Addresses details updated successfully',
        });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || 'Failed to update Addresses details',
      });
    } finally {
      navigate('/profile/addresses');
      setLoading(false);
    }
  };

  return (
    <ProfileEditAddressesTemplate
      defaultValue={defaultValue}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      loading={loading}
    />
  );
};

export default AddEditAddress;
