import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Typographys from 'components/Atoms/Typography';
import TitleWithText from 'components/Atoms/TitleWithText';
import Button from 'components/Atoms/Button';

const UserSalaryDetails = ({
  employeeData,
  employeeId,
  handleViewTaxWorking,
  employeeName,
  userHasViewTaxWorkingPermission,
}) => {
  const theme = useTheme();
  return (
    <Box sx={{ background: theme.palette.actions[100], padding: '2rem 3rem', borderRadius: '0.75rem' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typographys variant="h4">
          {employeeName} ({employeeId})
        </Typographys>
        {!!userHasViewTaxWorkingPermission && <Button onClick={handleViewTaxWorking}>View Tax Working</Button>}
      </Box>
      <Divider sx={{ borderColor: theme.palette.other[200], margin: ' 2rem 0 2.5rem 0' }} />
      <Grid container columnSpacing={1} rowSpacing={1}>
        <Grid item xs={6} sm={3} md={3}>
          <TitleWithText padding="0" title="Date of Joining" text={employeeData?.joiningDate} />
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          <TitleWithText padding="0" title="Effective From" text={employeeData?.effectiveFrom} />
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          <TitleWithText padding="0" title="Effective To" text={employeeData?.effectiveTo} />
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          <TitleWithText padding="0" title="CTC Based" text={employeeData?.CTCBased} />
        </Grid>
      </Grid>
      <Divider sx={{ borderColor: theme.palette.other[200], margin: ' 2rem 0 2.5rem 0' }} />
      <Grid container columnSpacing={1} rowSpacing={1}>
        <Grid item xs={6} sm={3} md={3}>
          <TitleWithText padding="0" title="Salary Structure Status" text={employeeData?.salaryStructureStatus} />
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          <TitleWithText padding="0" title="Pay Structure" text={employeeData?.payStructure} />
        </Grid>
      </Grid>
    </Box>
  );
};

UserSalaryDetails.defaultProps = {
  employeeData: {},
  employeeId: '',
  employeeName: '',
  handleViewTaxWorking: () => {},
};

UserSalaryDetails.propTypes = {
  employeeData: PropTypes.shape({
    joiningDate: PropTypes.string,
    effectiveFrom: PropTypes.string,
    effectiveTo: PropTypes.string,
    CTCBased: PropTypes.string,
    salaryStructureStatus: PropTypes.string,
    payStructure: PropTypes.string,
  }),
  employeeId: PropTypes.string,
  employeeName: PropTypes.string,
  handleViewTaxWorking: PropTypes.func,
};

export default UserSalaryDetails;
