import React from 'react';
import Breadcrumb from 'components/Molecules/Breadcrumbs';
import PropTypes from 'prop-types';
import { Box, Paper } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import { blankLayoutStyle } from './style';

const BlankTemplate = ({ isBreadcrumb, breadcrumbData, children, pageHeading, padding, sx }) => {
  return (
    <Box>
      {isBreadcrumb && (
        <Box mb={1.5}>
          <Breadcrumb breadcrumbs={breadcrumbData} />
        </Box>
      )}

      <Typographys variant="h6" color="neutral.700">
        {pageHeading}
      </Typographys>

      <Paper sx={{ ...blankLayoutStyle.wrapper, ...sx }} variant="outlined">
        <Box padding={padding}>{children}</Box>
      </Paper>
    </Box>
  );
};

BlankTemplate.defaultProps = {
  pageHeading: 'Enter Heading',
  isBreadcrumb: true,
  breadcrumbData: [
    { id: 1, name: 'Dashboard', path: '/', isActive: false },
    { id: 2, name: 'Base', path: '/', isActive: true },
  ],
  children: null,
  padding: '3rem',
};

BlankTemplate.propTypes = {
  pageHeading: PropTypes.string,
  isBreadcrumb: PropTypes.bool,
  children: PropTypes.node,
  breadcrumbData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
  padding: PropTypes.string,
};

export default BlankTemplate;
