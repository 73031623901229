import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Typography, useTheme } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Button from 'components/Atoms/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { tdsStyle } from 'components/Organisms/MyPay/TDS/View/style';
import TDSBasicDetails from './TDSBasicDetails';
import TDSTabs from './TDSTabs';

const TDSDetails = ({
  allowEdit,
  menuLists,
  basicDetails,
  handleEdit,
  regimeType,
  regimeOptions,
  cityOption,
  houseRentDataRow,
  houseRentFormSortDirection,
  houseRentFormSortBy,
  houseRentFormOnSortChange,
  houseRentTableSortDirection,
  houseRentTableSortBy,
  houseRentTableOnSortChange,
  otherIncomeDetails,
  form12bAttachment,
  investmentDeclarations,
  downloadForm12bAttachment,
  declarationDetails,
  downloadAttachment,
  handleFile,
  investmentDeclarationFormSortDirection,
  investmentDeclarationFormSortBy,
  investmentDeclarationFormOnSortChange,
  investmentDeclarationDataSortDirection,
  investmentDeclarationDataSortBy,
  investmentDeclarationDataOnSortChange,
  auditLogData,
  handleSearch,
  dateOption,
  startDate,
  endDate,
  propertyTypeOption,
  handleSubmit,
  handleCancel,
  propertyData,
  propertyList,
  updateButtonDisabled,
  employeeDeductionDeclarationInstance,
  userHasProofSubmitPermission,
  isNewTaxRegime,
  isEditMode,
}) => {
  const theme = useTheme();

  const MyPaySchema = Yup.object().shape({
    property: Yup.array().of(
      Yup.object().shape({
        propertyType: Yup.string().required('Required'),
        loanAmount: Yup.string().required('Required'),
        lettableValue: Yup.string().when('propertyType', (propertyType) => {
          if (propertyTypeOption.find((o) => o.id === Number(propertyType[0]))?.name === 'LetOut Property') {
            return Yup.string().required('Required');
          }
          return Yup.string();
        }),
        taxPaidToLocalAuthorities: Yup.string().when('propertyType', (propertyType) => {
          if (propertyTypeOption.find((o) => o.id === Number(propertyType[0]))?.name === 'LetOut Property') {
            return Yup.string().required('Required');
          }
          return Yup.string();
        }),
        interestPaidOnHousingLoan: Yup.string().when('propertyType', (propertyType) => {
          if (propertyTypeOption.find((o) => o.id === Number(propertyType[0]))?.name === 'LetOut Property') {
            return Yup.string().required('Required');
          }
          return Yup.string();
        }),
      }),
    ),
    regime: Yup.mixed().required('Please select a regime*'),
  });

  if (allowEdit && employeeDeductionDeclarationInstance?.declarationStatus !== 'Open for Declaration') {
    return (
      <Box sx={{ p: 2 }}>
        <Typography>You do not have permissions to view this page</Typography>
      </Box>
    );
  }

  return (
    <Formik
      initialValues={{
        regime: regimeOptions.find((r) => r?.name?.toLowerCase() === regimeType?.toLowerCase())?.name,
        houseRent:
          houseRentDataRow &&
          houseRentDataRow.map((row) => ({
            slNo: row.slNo,
            period: row.period,
            landlordName: row.landlordName,
            landlordPan: row.landlordPan,
            landlordAddress: row.landlordAddress,
            city: cityOption.find((r) => r.name === row.city)?.name,
            rentPaid: row.rentPaid,
          })),
        investmentDec:
          investmentDeclarations &&
          investmentDeclarations.map((row) => ({
            id: row.id,
            title: row.title,
            tooltip: row.tooltip,
            statutoryReferenceSection: row.statutoryReferenceSection,
            amount: row.currDeclrAmt,
            proof: row.proof,
          })),
        incomeOtherSources: otherIncomeDetails?.otherSources,
        property: propertyData,
        form12B: form12bAttachment,
      }}
      validationSchema={MyPaySchema}
      validateOnMount
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formik) => (
        <Box component="form" onSubmit={formik.handleSubmit}>
          <PerfectScrollbar>
            <Box sx={{ maxHeight: 'calc(100vh - 33rem)' }}>
              <TDSBasicDetails
                allowEdit={isEditMode}
                menuLists={menuLists}
                basicDetails={basicDetails}
                handleEdit={handleEdit}
                employeeDeductionDeclarationInstance={employeeDeductionDeclarationInstance}
                isNewTaxRegime={isNewTaxRegime}
              />
              <TDSTabs
                formik={formik}
                regimeType={regimeType}
                allowEdit={allowEdit}
                regimeOptions={regimeOptions}
                houseRentDataRow={houseRentDataRow}
                houseRentFormSortDirection={houseRentFormSortDirection}
                houseRentFormSortBy={houseRentFormSortBy}
                houseRentFormOnSortChange={houseRentFormOnSortChange}
                houseRentTableSortDirection={houseRentTableSortDirection}
                houseRentTableSortBy={houseRentTableSortBy}
                houseRentTableOnSortChange={houseRentTableOnSortChange}
                cityOption={cityOption}
                otherIncomeDetails={otherIncomeDetails}
                propertyList={propertyList}
                investmentDeclarations={investmentDeclarations}
                form12bAttachment={form12bAttachment}
                downloadForm12bAttachment={downloadForm12bAttachment}
                declarationDetails={declarationDetails}
                downloadAttachment={downloadAttachment}
                handleFile={downloadAttachment}
                investmentDeclarationFormSortDirection={investmentDeclarationFormSortDirection}
                investmentDeclarationFormSortBy={investmentDeclarationFormSortBy}
                investmentDeclarationFormOnSortChange={investmentDeclarationFormOnSortChange}
                investmentDeclarationDataSortDirection={investmentDeclarationDataSortDirection}
                investmentDeclarationDataSortBy={investmentDeclarationDataSortBy}
                investmentDeclarationDataOnSortChange={investmentDeclarationDataOnSortChange}
                auditLogData={auditLogData}
                handleSearch={handleSearch}
                dateOption={dateOption}
                startDate={startDate}
                endDate={endDate}
                propertyTypeOption={propertyTypeOption}
                employeeDeductionDeclarationInstance={employeeDeductionDeclarationInstance}
              />
            </Box>
          </PerfectScrollbar>
          {allowEdit && (
            <Box sx={tdsStyle.btnContainer}>
              <Divider sx={{ my: 2, borderColor: theme.palette.other[200] }} />
              <Box sx={tdsStyle.submitWrapper}>
                <Button
                  size="large"
                  color="info"
                  variant="outlined"
                  onClick={(event) => {
                    event.preventDefault();
                    formik.resetForm();
                    handleCancel();
                  }}
                >
                  Cancel
                </Button>

                <Button size="large" type="submit" isDisable={updateButtonDisabled}>
                  Update
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Formik>
  );
};

TDSDetails.defaultProps = {
  allowEdit: false,
  basicDetails: {},
  handleEdit: () => {},
  menuLists: [],
  regimeType: '',
  regimeOptions: [],
  cityOption: [],
  houseRentDataRow: [],
  houseRentFormSortDirection: 'desc',
  houseRentFormSortBy: 'id',
  houseRentFormOnSortChange: () => {},
  houseRentTableSortDirection: 'desc',
  houseRentTableSortBy: 'id',
  houseRentTableOnSortChange: () => {},
  otherIncomeDetails: {},
  form12bAttachment: '',
  downloadForm12bAttachment: () => {},
  declarationDetails: [],
  downloadAttachment: () => {},
  handleFile: () => {},
  investmentDeclarationFormSortDirection: 'desc',
  investmentDeclarationFormSortBy: 'id',
  investmentDeclarationFormOnSortChange: () => {},
  investmentDeclarationDataSortDirection: 'desc',
  investmentDeclarationDataSortBy: 'id',
  investmentDeclarationDataOnSortChange: () => {},
  auditLogData: [],
  handleSearch: () => {},
  dateOption: [],
  startDate: '',
  endDate: '',
  handleSubmit: () => {},
  handleCancel: () => {},
  propertyTypeOption: [],
  propertyData: [],
  updateButtonDisabled: false,
};

TDSDetails.propTypes = {
  allowEdit: PropTypes.bool,
  basicDetails: PropTypes.shape({
    employeeName: PropTypes.string,
    employeeID: PropTypes.string,
    designation: PropTypes.string,
    department: PropTypes.string,
    financialYear: PropTypes.string,
    assessmentYear: PropTypes.string,
    panNumber: PropTypes.string,
    aadhaarNumber: PropTypes.string,
    dateOfJoining: PropTypes.string,
    submissionsDeclaration: PropTypes.string,
    submissionsStatus: PropTypes.string,
    submissionsActionOwner: PropTypes.string,
    submissionsActionOwnerId: PropTypes.string,
    submissionAllowed: PropTypes.string,
    proofSubmissionStatus: PropTypes.string,
    proofSubmissionActionOwner: PropTypes.string,
    proofSubmissionActionOwnerId: PropTypes.string,
    approvedAmountStatus: PropTypes.string,
  }),
  handleEdit: PropTypes.func,
  menuLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  regimeType: PropTypes.string,
  regimeOptions: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, name: PropTypes.string, onClick: PropTypes.func }),
  ),
  houseRentDataRow: PropTypes.arrayOf(
    PropTypes.shape({
      slNo: PropTypes.number,
      period: PropTypes.string,
      landlordName: PropTypes.string,
      landlordPan: PropTypes.string,
      landlordAddress: PropTypes.string,
      city: PropTypes.string,
      rentPaid: PropTypes.number,
    }),
  ),
  houseRentFormSortDirection: PropTypes.string,
  houseRentFormSortBy: PropTypes.string,
  houseRentFormOnSortChange: PropTypes.func,
  houseRentTableSortDirection: PropTypes.string,
  houseRentTableSortBy: PropTypes.string,
  houseRentTableOnSortChange: PropTypes.func,
  cityOption: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, name: PropTypes.string, onClick: PropTypes.func }),
  ),
  otherIncomeDetails: PropTypes.shape({
    otherSources: PropTypes.number,
    propertyType: PropTypes.string,
    propertyName: PropTypes.string,
    address: PropTypes.string,
    constructionCompletedOn: PropTypes.string,
    loanAmount: PropTypes.number,
    loanBorrowedOn: PropTypes.string,
    lenderName: PropTypes.string,
    lenderPAN: PropTypes.string,
    isJointlyOwnedProperty: PropTypes.string,
    emiInterest1Year: PropTypes.number,
    emiInterest2Year: PropTypes.number,
    lettableValue: PropTypes.number,
    taxPaidToLocalAuthorities: PropTypes.number,
    standardDeductionOnLettableValue: PropTypes.number,
    interestPaidOnHousingLoan: PropTypes.number,
    netIncomeFromHouse: PropTypes.number,
  }),
  form12bAttachment: PropTypes.string,
  downloadForm12bAttachment: PropTypes.func,
  declarationDetails: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      proof: PropTypes.string,
    }),
  ),
  downloadAttachment: PropTypes.func,
  handleFile: PropTypes.func,
  investmentDeclarationFormSortDirection: PropTypes.string,
  investmentDeclarationFormSortBy: PropTypes.string,
  investmentDeclarationFormOnSortChange: PropTypes.func,
  investmentDeclarationDataSortDirection: PropTypes.string,
  investmentDeclarationDataSortBy: PropTypes.string,
  investmentDeclarationDataOnSortChange: PropTypes.func,
  auditLogData: PropTypes.arrayOf(
    PropTypes.shape(
      PropTypes.shape({
        id: PropTypes.num,
        auditLog: PropTypes.shape({
          avatarSrc: PropTypes.string,
          name: PropTypes.string,
          date: PropTypes.string,
        }),

        updatedLog: PropTypes.shape({
          updatedName: PropTypes.string,
          updatedDate: PropTypes.string,
        }),
        handleLog: PropTypes.func,
        handleEdit: PropTypes.func,
        handleAccept: PropTypes.func,
        handleDelete: PropTypes.func,
        rowsPerPage: PropTypes.num,
        totalRecordCount: PropTypes.num,
        minimumRecordsLength: PropTypes.num,
        auditLogRows: PropTypes.arrayOf(
          PropTypes.shape({
            fieldName: PropTypes.string,
            oldValue: PropTypes.string,
            newValue: PropTypes.string,
          }),
        ),
      }),
    ),
  ),
  dateOption: PropTypes.arrayOf(
    PropTypes.shape(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string, onClick: PropTypes.func })),
  ),
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  handleSearch: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  propertyTypeOption: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })),
  propertyData: PropTypes.arrayOf(
    PropTypes.shape({
      propertyType: PropTypes.num,
      propertyName: PropTypes.string,
      address: PropTypes.string,
      constructionCompletedOn: PropTypes.string,
      loanAmount: PropTypes.number,
      loanBorrowedOn: PropTypes.string,
      lenderName: PropTypes.string,
      lenderPAN: PropTypes.string,
      jointlyOwnedProperty: PropTypes.string,
      coBorrower1Name: PropTypes.string,
      coBorrower1Pan: PropTypes.string,
      coBorrower1Relationship: PropTypes.string,
      coBorrower2Name: PropTypes.string,
      coBorrower2Pan: PropTypes.string,
      coBorrower2Relationship: PropTypes.string,
      coBorrower3Name: PropTypes.string,
      coBorrower3Pan: PropTypes.string,
      coBorrower3Relationship: PropTypes.string,
      emiInterest1Year: PropTypes.number,
      emiInterest2Year: PropTypes.number,
      percentageOfInterestRepaid: PropTypes.string,
      interest: PropTypes.number,
      lettableValue: PropTypes.number,
      taxPaidToLocalAuthorities: PropTypes.number,
      standardDeductionOnLettableValue: PropTypes.number,
      interestPaidOnHousingLoan: PropTypes.number,
      netIncomeFromHouse: PropTypes.number,
      declarationProperty: PropTypes.string,
    }),
  ),
  updateButtonDisabled: PropTypes.bool,
};

export default TDSDetails;
