export const advancedButton = {
  buttonBox: {
    display: ' inline-flex',
    gap: '1.25rem',
    cursor: 'pointer',
    alignItems: 'center',
    boxShadow: '0px 3px 5px -1px rgba(9, 30, 66, 0.08);',
    border: '1px solid',
    borderColor: 'neutral.300',
    borderRadius: '5px;',
    padding: '1.75rem 3rem',
    backgroundColor: 'common.white',
    whiteSpace: 'pre',
  },
};
