export const timerProgressBarStyle = {
  progressBarWrapper: {
    width: '100%',
    display: 'flex',
    borderRadius: '1rem',
    overflow: 'hidden',
  },

  indicatorWrapper: {
    display: 'flex',
    gap: 1.5,
    alignItems: 'center',
    mt: 3,
  },

  timeIndicator: {
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '50%',
  },
};
