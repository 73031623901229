import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import BlankTemplate from 'components/Templates/BlankTemplate';
import ProfileSkillDetails from 'components/Organisms/Profile/SkillDetails';
import { useThunk } from 'hooks/useThunk';
import { fetchSkillDetails } from 'store';
import { useNavigate } from 'react-router-dom';
import useDownloadFile from 'hooks/useDownloadFile';
import { Box, Skeleton, Typography } from '@mui/material';

const ProfileLifeEventDetailsPage = () => {
  const navigate = useNavigate();
  const downloadFile = useDownloadFile();
  const [getSkillDetails, isLoadingSkillDetails, loadingSkillDetailsError] = useThunk(fetchSkillDetails);

  useEffect(() => {
    const query = new URLSearchParams({});
    getSkillDetails(query);
  }, [getSkillDetails]);

  const { skillDetails } = useSelector((state) => {
    return state.profile;
  });

  const handleEdit = () => {
    navigate(`/profile/skills-details/edit`);
  };

  if (loadingSkillDetailsError !== null) {
    return (
      <Typography variant="h6">{loadingSkillDetailsError?.message || 'Error while loading skill details.'}</Typography>
    );
  }

  const handleAttachment = (docid, filename) => {
    downloadFile({ docid }, filename, 'restPayIndex/downloadAttachment');
  };

  const skillDetailsList =
    skillDetails &&
    skillDetails?.map((skillDetail) => ({
      skill: skillDetail?.skill?.name || '-',
      level: skillDetail?.level || '-',
      comments: skillDetail?.comments || '-',
      attachments: skillDetail?.documents.length > 0 ? skillDetail?.documents[0].documentName : '',
      attachmentsId: skillDetail?.documents.length > 0 ? skillDetail?.documents[0].id : '',
    }));

  return isLoadingSkillDetails ? (
    <Box sx={{ p: 4 }}>
      <Skeleton height={70} />
      <Skeleton />
      <Skeleton />
    </Box>
  ) : (
    <BlankTemplate pageHeading="Skill Details" isBreadcrumb={false} padding="2rem 3rem">
      <ProfileSkillDetails
        skillDetails={skillDetailsList}
        handleEdit={handleEdit}
        handleAttachment={handleAttachment}
      />
    </BlankTemplate>
  );
};

export default ProfileLifeEventDetailsPage;
