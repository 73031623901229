import React, { useState } from 'react';
import { changeMyPasswordAction } from 'api/profile';
import EditPassword from 'components/Organisms/Profile/EditPassword';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from 'store/slices/user';
import useToast from 'components/Provider/useToast';
import { Typography } from '@mui/material';
import { hasPermission } from 'utils/permissions';

const ModifyPassword = () => {
  const addToast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [handleUpdatePassword, setHandleUpdatePassword] = useState(false);

  const session = useSelector((state) => state?.session);
  const loggedInUserID = session?.user?.empDBID;
  const authorities = session.user.authorities;
  const userHasViewPermisson = hasPermission(authorities, ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_CHANGE_MY_PASSWORD']);
  const handleSubmit = async (userInputNewPwd) => {
    if (loggedInUserID) {
      const args = {
        id: loggedInUserID,
        newPasswd: userInputNewPwd,
        newPasswd2: userInputNewPwd,
      };
      try {
        const response = await changeMyPasswordAction(args);
        if (response?.psswdChanged) {
          dispatch(logout());
          localStorage.removeItem('persist:root');
          localStorage.removeItem('token');
          addToast({ type: 'success', title: 'SUCCESS', message: 'Password updated successfully' });
          navigate('/login');
        } else {
          setHandleUpdatePassword(false);
          addToast({ type: 'error', title: 'ERROR', message: response.message || 'Something went wrong, try again' });
        }
      } catch (error) {
        setHandleUpdatePassword(false);
        addToast({
          type: 'error',
          title: 'ERROR',
          message: error?.message || 'Failed to update the password',
        });
      }
    }
  };

  if (!userHasViewPermisson) {
    return <Typography variant="h6">You do not have permission to Modify Password</Typography>;
  }

  return <EditPassword handleSubmit={handleSubmit} {...{ handleUpdatePassword, setHandleUpdatePassword }} />;
};

export default ModifyPassword;
