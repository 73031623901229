import { createSlice } from '@reduxjs/toolkit';

const session = createSlice({
  name: 'session',
  initialState: {
    user: {
      userDBId: null,
    },
    chatSessionExpired: false,
  },
  reducers: {
    updateUser(state, action) {
      state.user = action.payload;
    },
    logout(state) {
      // eslint-disable-next-line no-unused-vars
      state = {};
    },
    updateName(state, action) {
      const { firstName, lastName } = action.payload;
      state.user.firstName = firstName;
      state.user.lastName = lastName;
    },
    setChatSessionExpired(state, action) {
      state.chatSessionExpired = action.payload;
    },
  },
});

export const { updateUser, logout, updateName, setChatSessionExpired } = session.actions;

export default session.reducer;
