import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid, Skeleton, useTheme } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import IconWithTitleAndDesc from 'components/Molecules/IconWithTitleAndDesc';
import Typography from 'components/Atoms/Typography';
import TitleWithTextInline from 'components/Atoms/TitleWithTextInline';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Dialog from 'components/Molecules/Dialog';
import { validateDateElseHyphen } from 'utils/commonUtils';

const DialogDescription = ({ loadingAnnouncementDetails, loadingAnnouncementDetailsError }) => {
  const theme = useTheme();
  const { announcementDetails = {} } = useSelector((state) => state?.dashboard);
  if (loadingAnnouncementDetails) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={38} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (loadingAnnouncementDetailsError) {
    return (
      <Typography variant="h6">
        {loadingAnnouncementDetailsError?.message || 'Error while fetching announcement details.'}
      </Typography>
    );
  }

  return (
    <Box sx={{ background: theme.palette.actions[100], padding: '2rem 3rem', borderRadius: '0.75rem' }}>
      <Grid container columnSpacing={1} rowSpacing={1}>
        <Grid item xs={12} md={12}>
          <TitleWithTextInline textAlign="left" padding="0" title="Title:" text={announcementDetails?.title} />
        </Grid>
        <Grid item xs={12} md={12}>
          <TitleWithTextInline
            textAlign="left"
            padding="0"
            title="Type:"
            text={announcementDetails?.announcementType}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TitleWithTextInline
            textAlign="left"
            padding="0"
            title="Date:"
            text={validateDateElseHyphen(announcementDetails?.publishDate, 'MMMM DD, YYYY')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TitleWithTextInline textAlign="left" padding="0" title="Message:" text={announcementDetails?.message} />
        </Grid>
      </Grid>
    </Box>
  );
};

const AnnouncementsTab = ({
  iconSrc,
  lists,
  handleDetails,
  isAnnouncements,
  loadingAnnouncementDetails,
  loadingAnnouncementDetailsError,
}) => {
  const navigate = useNavigate();
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);
  const theme = useTheme();
  const handlePageDetails = (id) => {
    if (isAnnouncements) {
      setShowDetailsDialog(!showDetailsDialog);
      handleDetails(id);
    } else {
      navigate(`/home/surveys/view/${id}`);
    }
  };
  return (
    <PerfectScrollbar>
      <Box sx={{ minHeight: '48.5rem', maxHeight: '48.5rem' }}>
        {!lists?.length ? (
          <Typography>No data available</Typography>
        ) : (
          lists?.map((list) => (
            <>
              <IconWithTitleAndDesc
                iconSrc={iconSrc}
                title={list.title}
                desc={list.desc}
                time={list.time}
                buttonText={list.buttonText}
                badge={list.unRead}
                isButton
                handleDetails={() => handlePageDetails(list?.id)}
              />
              <Divider sx={{ my: 2, borderColor: theme.palette.other[200] }} />
            </>
          ))
        )}
      </Box>

      {!!isAnnouncements && (
        <Dialog
          open={showDetailsDialog}
          dialogTitle="Announcement Details:"
          alignTitle="left"
          dialogDesc={
            <DialogDescription
              loadingAnnouncementDetails={loadingAnnouncementDetails}
              loadingAnnouncementDetailsError={loadingAnnouncementDetailsError}
            />
          }
          secondaryBtn="Close"
          handleSecondaryBtn={() => setShowDetailsDialog(!showDetailsDialog)}
          alignmentActionBtn
          handleClose={() => setShowDetailsDialog(!showDetailsDialog)}
        />
      )}
    </PerfectScrollbar>
  );
};

AnnouncementsTab.defaultProps = {
  iconSrc: '',
  lists: [],
  handleDetails: () => {},
  isAnnouncements: false,
  loadingAnnouncementDetails: false,
  loadingAnnouncementDetailsError: {
    message: '',
  },
};
AnnouncementsTab.propTypes = {
  iconSrc: PropTypes.string,
  lists: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      desc: PropTypes.string,
      time: PropTypes.string,
      isNotification: PropTypes.bool,
      buttonText: 'View Details',
    }),
  ),
  handleDetails: PropTypes.func,
  isAnnouncements: PropTypes.bool,
  loadingAnnouncementDetails: PropTypes.bool,
  loadingAnnouncementDetailsError: PropTypes.shape({
    message: PropTypes.string,
  }),
};

DialogDescription.defaultProps = {
  loadingAnnouncementDetails: false,
  loadingAnnouncementDetailsError: {
    message: '',
  },
};
DialogDescription.propTypes = {
  loadingAnnouncementDetails: PropTypes.bool,
  loadingAnnouncementDetailsError: PropTypes.shape({
    message: PropTypes.string,
  }),
};

export default AnnouncementsTab;
