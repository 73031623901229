import { useSelector } from 'react-redux';

import { hasPermission } from 'utils/permissions';

const getActionItems = (services) => {
  return [
    {
      name: 'View',
      onClick: services.viewQueryService,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_QUER_INDEX_VIEW_QUERY'],
    },
    {
      name: 'Escalate',
      onClick: services.escalateQueryNavigationService,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_QUER_INDEX_ESCALATE_QUERY'],
    },
    {
      name: 'Modify',
      onClick: services.modifyQueryNavigationService,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_QUER_INDEX_UPDATE_QUERY'],
      conditional: (row) =>
        row?.resolutionStatus?.toUpperCase() === 'DRAFT' || row?.resolutionStatus?.toUpperCase() === 'REOPENED',
    },
    {
      name: 'Delete',
      onClick: (row) => services.handleOpenDialog(true, { ...row }),
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_QUER_INDEX_DELETE_QUERY'],
      conditional: (row) =>
        row?.resolutionStatus?.toUpperCase() === 'DRAFT' || row?.resolutionStatus?.toUpperCase() === 'REOPENED',
    },
    {
      name: 'Submit',
      onClick: (row) => services.submitQueryService(row?.id),
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_QUER_INDEX_SUBMIT_QUERY'],
      conditional: (row) =>
        row?.resolutionStatus?.toUpperCase() === 'DRAFT' || row?.resolutionStatus?.toUpperCase() === 'REOPENED',
    },
    {
      name: 'Action',
      onClick: (row) => services.actionQueryNavigationService({ id: row?.id }),
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_QUER_INDEX_ACTION_QUERY'],
      conditional: (row) => row?.resolutionStatus?.toUpperCase() === 'UNDER REVIEW',
    },
    {
      name: 'Revert',
      onClick: (row) => services.revertQueryService(row),
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_QUER_INDEX_REVERT_QUERY'],
      conditional: (row) => row?.resolutionStatus?.toUpperCase() === 'UNDER REVIEW',
    },
    {
      name: 'Reopen',
      onClick: services.reopenQueryNavigationService,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_QUER_INDEX_REOPEN_QUERY'],
      conditional: (row) =>
        [
          'Closed - Resolved',
          'Closed - Unresolved',
          'Closed - Duplicate',
          'Closed - Rejected',
          'Closed - Incorrect',
        ].includes(row?.resolutionStatus) &&
        row?.currentEmployee &&
        row?.currentEmployee?.id === row?.queryInstance?.openedBy?.id,
    },
  ];
};

const useActionItems = (employeeServices) => {
  const authorities = useSelector((state) => state.session.user.authorities);

  const actions = getActionItems(employeeServices);

  const handleActionItemClick = (row) => {
    const moreFilteredActionItems = actions.filter((action) => {
      const userHasPermission = hasPermission(authorities, action.permissions);
      if ((action.conditional && !action.conditional(row)) || !userHasPermission) {
        return false;
      }
      return true;
    });
    return moreFilteredActionItems;
  };

  return [handleActionItemClick];
};

export default useActionItems;
