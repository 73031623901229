import { useSelector } from 'react-redux';
import { hasPermission } from 'utils/permissions';

const getActionItems = (services) => {
  return [
    {
      name: 'View',
      onClick: services.viewDocument,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_INDEX_VIEW_DOCUMENT'],
    },
    {
      name: 'Edit',
      onClick: services.editDocument,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_INDEX_UPDATE_DOCUMENT'],
    },
    {
      name: 'Download',
      onClick: services.handleAttachment,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_INDEX_DOWNLOAD_DOCUMENT'],
    },
    {
      name: 'Publish',
      onClick: services.handlePublishDocument,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_INDEX_PUBLISH_DOCUMENT'],
      conditional: (row) => row?.entityOrigin === 'ENTITY_USER_DEFINED' && row?.publicationStatus !== 'Pubished',
    },
    {
      name: 'Unpublish',
      onClick: services.handleUnPublishDocument,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_INDEX_UNPUBLISH_DOCUMENT'],
      conditional: (row) => row?.entityOrigin === 'ENTITY_USER_DEFINED' && row?.publicationStatus === 'Pubished',
    },
    {
      name: 'Delete',
      onClick: services.handleDeleteDialog,
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_INDEX_DELETE_DOCUMENT'],
      conditional: (row) => row?.entityOrigin === 'ENTITY_USER_DEFINED',
    },
  ];
};

const useActionItems = (services) => {
  const actions = getActionItems(services);
  const authorities = useSelector((state) => state.session.user.authorities);

  const handleActionItemClick = (row) => {
    const moreFilteredActionItems = actions.filter((action) => {
      const userHasPermission = hasPermission(authorities, action.permissions);
      if ((action.conditional && !action.conditional(row)) || !userHasPermission) {
        return false;
      }
      return true;
    });
    return moreFilteredActionItems;
  };

  return [handleActionItemClick];
};

export default useActionItems;
