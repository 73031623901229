import React, { useState } from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Button from 'components/Atoms/Button';
import InputField from 'components/Atoms/InputField';
import Typographys from 'components/Atoms/Typography';
import { resetPasswordStyle } from './style';

const ResetPasswordComponent = ({ loginPageLink, handleResetPassword }) => {
  const [username, setUsername] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    handleResetPassword(username);
  };

  return (
    <Box sx={resetPasswordStyle.wrapper}>
      <Typographys variant="h2" align="center" color="neutral.700">
        Reset your password
      </Typographys>

      <Typographys align="center" variant="body1" mx={3}>
        Enter your registered username and we’ll send you instructions on how to reset your password.
      </Typographys>

      <Box sx={resetPasswordStyle.formWrapper} component="form" noValidate>
        <InputField
          required
          fullWidth
          id="username"
          label="User Name"
          name="username"
          autoFocus
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        />

        <Box sx={resetPasswordStyle.formbutton}>
          <Button onClick={handleSubmit} fullWidth size="ExtraLarge">
            Send instructions
          </Button>
        </Box>

        <Box sx={resetPasswordStyle.formtext}>
          <Typographys variant="body1"> Go back to </Typographys>
          <Button href={loginPageLink} color="secondary" variant="text">
            Login page
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

ResetPasswordComponent.defaultProps = {
  handleResetPassword: () => {},
};

ResetPasswordComponent.propTypes = {
  loginPageLink: PropTypes.string.isRequired,
  handleResetPassword: PropTypes.func,
};

export default ResetPasswordComponent;
