import { asyncThunkWrapper } from 'utils/AsyncThunkWrapper';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { myExpensesDashboardDetails, myExpenseDetailsData, myExpensesCreateData } from 'api/expenses';

const fetchMyExpensesDashboardDetails = asyncThunkWrapper('expenses/dashboard', myExpensesDashboardDetails);

const fetchMyExpenseDetails = createAsyncThunk('myExpenses/details', async (query) => {
  const response = await myExpenseDetailsData(query);
  return response;
});

const fetchCreateExpense = createAsyncThunk('myExpenses/createExpense', async (query) => {
  const response = await myExpensesCreateData(query);
  return response;
});

export { fetchMyExpensesDashboardDetails, fetchCreateExpense, fetchMyExpenseDetails };
