import { formDataApi, urlEncodedApi, api } from 'api/api';

/*
 * APIs related to employee separations
 */
export const employeeExitsListData = async (query) => {
  const response = await formDataApi.get(`restEmpIndex/listTeamMemberExits?${query.toString()}`);
  return response?.data;
};

export const employeeExitsDelete = async (formData) => {
  const response = await urlEncodedApi.put(`restEmpIndex/deleteEmployeeExit`, formData);
  return response?.data;
};

export const employeeExitslockDetails = async (formData) => {
  const response = await urlEncodedApi.put(`restEmpIndex/reOpenFinalizedSpecificEmpExit`, formData);
  return response?.data;
};

export const employeeExitDetailsData = async (query) => {
  const response = await formDataApi.get(`restEmpIndex/displayEmployeeExit?${query.toString()}`);
  return response?.data;
};

export const initiateEmpExitData = async (query) => {
  const response = await formDataApi.get(`restEmpIndex/initiateEmpExit?${query.toString()}`);
  return response?.data;
};

export const actionEmployeeExitAction = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/actionEmployeeExitAction`, formData);
  return response?.data;
};

export const actionEmployeeExit = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/actionEmployeeExit`, formData);
  return response?.data;
};

export const viewOffBoardingCheckList = async (formData) => {
  const response = await api.get('/restEmpIndex/viewOffBoardingCheckList', {
    params: formData,
  });
  return response?.data;
};

export const modifyOffBoardingCheckListTask = async (formData) => {
  const response = await api.get('/restEmpIndex/modifyTask', {
    params: formData,
  });

  return response?.data;
};

export const modifyOffBoardingCheckListTaskAction = async (formData) => {
  const response = await api.get('/restEmpIndex/modifyTaskAction', {
    params: formData,
  });

  return response?.data;
};

export const initiateSpecificEmpExit = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/initiateSpecificEmpExit`, formData);
  return response?.data;
};

export const saveSpecificEmpExit = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/saveSpecificEmpExit`, formData);
  return response?.data;
};
