import * as React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';

const ChipWithStatus = ({ size, onClick, isActive, activeTxt, inACtiveTxt }) => {
  return (
    <Chip
      variant="filled"
      color="success"
      size={size}
      label={isActive ? activeTxt : inACtiveTxt}
      onClick={onClick}
      disabled={!isActive}
    />
  );
};

ChipWithStatus.defaultProps = {
  size: 'medium',
  isActive: true,
  activeTxt: 'Active',
  inACtiveTxt: 'Inactive',
  onClick: () => {},
};

ChipWithStatus.propTypes = {
  size: PropTypes.string,
  isActive: PropTypes.bool,
  activeTxt: PropTypes.string,
  inACtiveTxt: PropTypes.string,
  onClick: PropTypes.func,
};
export default ChipWithStatus;
