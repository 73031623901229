import PropTypes from 'prop-types';
import React from 'react';
import Typographys from 'components/Atoms/Typography';
import { Box, Divider, IconButton } from '@mui/material';
import Avatars from 'components/Molecules/Avatar';
import CakeIcon from '@mui/icons-material/Cake';
import { calendarStyle } from '../../style';

const BirthdayDialog = ({ data }) => {
  return (
    <Box>
      <Box mb={2}>
        <Typographys variant="h4" align="left">
          {data.title}
        </Typographys>
      </Box>
      <Avatars isName name={data.employeeName} avatarSrc={data.employeeImage} />
      <Typographys sx={{ mt: 2 }} variant="body2" align="left" color="neutral.600">
        {data.employeeDesignation}
      </Typographys>
      <Divider sx={{ ...calendarStyle.calenderDivider, my: 2 }} />
      <Box sx={{ ...calendarStyle.deadlineButtonsWrapper, mb: 1 }}>
        <Box sx={calendarStyle.titleWithIcon}>
          <IconButton sx={{ color: 'actions.600', fontSize: '2.25rem' }}>
            <CakeIcon fontSize="inherit" />
          </IconButton>
          <Box>
            <Typographys sx={{ textTransform: 'none' }} color="neutral.800">
              {data.birthdayDate}
            </Typographys>
          </Box>
        </Box>
      </Box>
      <Divider sx={{ ...calendarStyle.calenderDivider, mt: 2 }} />
    </Box>
  );
};
BirthdayDialog.defaultProps = {
  data: {},
};
BirthdayDialog.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    employeeName: PropTypes.string,
    employeeImage: PropTypes.string,
    employeeDesignation: PropTypes.string,
    birthdayDate: PropTypes.string,
  }),
};
export default BirthdayDialog;
