import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import TitleWithText from 'components/Atoms/TitleWithText';

const EmployeeSeparationDetails = ({ employeeDetails }) => {
  return (
    <Box>
      <Grid container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Employee*" text={employeeDetails.employeeName} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Separation Type" text={employeeDetails.separationType} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Notice Period" text={employeeDetails.noticePeriod} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Last Working Day (Per Policy)" text={employeeDetails.lastWorkingDay} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Resignation Submission Date" text={employeeDetails.resignationSubmissionDate} />
        </Grid>
      </Grid>
    </Box>
  );
};

EmployeeSeparationDetails.defaultProps = {
  employeeDetails: {},
};

EmployeeSeparationDetails.propTypes = {
  employeeDetails: PropTypes.shape({
    employeeName: PropTypes.string,
    separationType: PropTypes.string,
    noticePeriod: PropTypes.string,
    lastWorkingDay: PropTypes.string,
    resignationSubmissionDate: PropTypes.string,
  }),
};

export default EmployeeSeparationDetails;
