import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Divider } from '@mui/material';
import TitleWithText from 'components/Atoms/TitleWithText';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Table from 'components/Molecules/TableExtended';
import Typographys from 'components/Atoms/Typography';
import NoRecordsMessage from 'components/Organisms/CommonPageUtils/NoRecordsFound';
import { separationStyle } from '../style';

const ViewOffboarding = ({ exitDetails, loading, tables, columns, selectedItems, keyColumn, roleBasedMenu }) => {
  return (
    <Box>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 36rem)' }}>
          <Grid mt={2} container columnSpacing={9}>
            <Grid item xs={12} md={4}>
              <TitleWithText title="Employee" text={exitDetails.employeeName} />
            </Grid>

            <Grid item xs={12} md={4}>
              <TitleWithText title="Employee ID" text={exitDetails.employeeId} />
            </Grid>

            <Grid item xs={12} md={4}>
              <TitleWithText title="Supervisor" text={exitDetails.supervisor} />
            </Grid>

            <Grid item xs={12} md={4}>
              <TitleWithText title="Date of Joining" text={exitDetails.dateOfJoining} />
            </Grid>

            <Grid item xs={12} md={4}>
              <TitleWithText title="Date of Leaving" text={exitDetails.dateOfLeaving} />
            </Grid>

            <Grid item xs={12} md={4}>
              <TitleWithText title="List Completion Status" text={exitDetails.listCompletionStatus} />
            </Grid>
          </Grid>
          <Box sx={{ mr: 1 }}>
            {tables?.length ? (
              <>
                <Divider sx={separationStyle.Divider} />
                {tables?.map((table) => {
                  return (
                    table?.details?.length > 0 && (
                      <Box sx={{ mt: 4 }}>
                        <Typographys variant="body1" color="secondary.500">
                          Task List Name : {table?.taskListName}
                        </Typographys>
                        <Box mt={2}>
                          <Table
                            loading={loading}
                            rows={table?.details}
                            columns={columns}
                            count={table?.details?.length}
                            selectedItems={selectedItems}
                            showRowSelector={false}
                            showPagination={false}
                            keyColumn={keyColumn}
                            roleBasedMenu={roleBasedMenu}
                            showActionList
                            isDynamic
                          />
                        </Box>
                        <Divider sx={separationStyle.Divider} />
                      </Box>
                    )
                  );
                })}
              </>
            ) : (
              <>
                <Divider sx={separationStyle.Divider} />
                <Box sx={{ mt: 4 }}>
                  <Typographys variant="body1" fontWeight={600}>
                    Tasks List:
                  </Typographys>
                  <NoRecordsMessage />
                </Box>
              </>
            )}
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );
};

export default ViewOffboarding;
