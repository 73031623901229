import PropTypes from 'prop-types';
import React from 'react';
import Typographys from 'components/Atoms/Typography';
import { Box, Divider, Link } from '@mui/material';
import Button from 'components/Atoms/Button';
import { calendarStyle } from '../../style';

const DeadlineDialog = ({ data }) => {
  return (
    <Box>
      <Box mb={2}>
        <Typographys variant="h4" align="left">
          {data.title}
        </Typographys>
      </Box>

      <Typographys align="left" color="neutral.600">
        {data.deadlineDate}
      </Typographys>
      <Divider sx={{ ...calendarStyle.calenderDivider, my: 1.25 }} />
      <Typographys
        sx={{ textTransform: 'none', mb: 0.75 }}
        fontWeight="500"
        variant="subtitle1"
        align="left"
        color="neutral.600"
      >
        Survey link
      </Typographys>
      <Box sx={calendarStyle.deadlineButtonsWrapper}>
        <Link
          href={data.link}
          variant="subtitle2"
          sx={{ fontWeight: 500, textTransform: 'none' }}
          color="secondary.600"
          underline="hover"
        >
          {data.link}
        </Link>

        <Button
          fullWidth={false}
          size="medium"
          variant="contained"
          color="primary"
          onClick={() => {
            data.handleViewSurvey(data);
          }}
        >
          View survey
        </Button>
      </Box>
      <Divider sx={{ ...calendarStyle.calenderDivider, mt: 2 }} />
    </Box>
  );
};
DeadlineDialog.defaultProps = {
  data: {},
};
DeadlineDialog.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    deadlineDate: PropTypes.string,
    link: PropTypes.string,
    handleViewSurvey: PropTypes.func,
  }),
};
export default DeadlineDialog;
