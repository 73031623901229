import React from 'react';
import { resetPassword } from 'api/login';
import useToast from 'components/Provider/useToast';
import ResetPasswordTemplate from '../../components/Templates/ResetPassword';

const ResetPassword = () => {
  const addToast = useToast();

  const handleForgotPassword = async (username) => {
    try {
      const data = new FormData();
      data.append('u', username);
      const response = await resetPassword(data);
      if (response?.status === 'SUCCESS') {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.message || 'Password reset successfully',
        });
      } else {
        addToast({
          type: 'error',
          title: 'ERROR',
          message: response?.message || 'Something went wrong, try again',
        });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || 'Failed to reset password',
      });
    }
  };

  return <ResetPasswordTemplate handleResetPassword={handleForgotPassword} />;
};

export default ResetPassword;
