import {
  listEmployeesAction,
  searchEmployeesAction,
  lockEmployeesAction,
  unLockEmployeesAction,
  activateEmployeesAction,
  deActivateEmployeesAction,
  sendESSInviteAction,
  convertToCandidateAction,
  viewEmployeesAction,
  transferEmployeeData,
  promoteEmployeeData,
  demoteEmployeeData,
  confirmEmployeeData,
  extendProbationData,
  terminateEmployeeData,
  verifyBackgroundData,
  deleteLCRecordsAction,
  listLCRecordsAction,
  searchLCRecordsAction,
  viewLCRecordsAction,
  initiatePIPData,
  concludePIPData,
  listLCRecordsPendingMyApproval,
} from 'api/employees';
import { asyncThunkWrapper } from 'utils/AsyncThunkWrapper';

const fetchEmployees = asyncThunkWrapper('employees/fetch', listEmployeesAction);
const searchEmployees = asyncThunkWrapper('employees/search', searchEmployeesAction);
const viewEmployees = asyncThunkWrapper('employees/view', viewEmployeesAction);
const deleteEmployees = asyncThunkWrapper('employees/delete', listEmployeesAction);
const lockEmployees = asyncThunkWrapper('employees/lock', lockEmployeesAction);
const unLockEmployees = asyncThunkWrapper('employees/unLock', unLockEmployeesAction);
const activateEmployees = asyncThunkWrapper('employees/activate', activateEmployeesAction);
const deActivateEmployees = asyncThunkWrapper('employees/deActivate', deActivateEmployeesAction);
const sendESSInvite = asyncThunkWrapper('employees/sendESSInvite', sendESSInviteAction);
const convertToCandidate = asyncThunkWrapper('employees/convertToCandidate', convertToCandidateAction);
const fetchConfirmEmployee = asyncThunkWrapper('employees/confirmEmployee', confirmEmployeeData);
const fetchDemoteEmployee = asyncThunkWrapper('employees/demoteEmployee', demoteEmployeeData);
const fetchPromoteEmployee = asyncThunkWrapper('employees/promoteEmployee', promoteEmployeeData);
const fetchTransferEmployee = asyncThunkWrapper('employees/transferEmployee', transferEmployeeData);
const fetchTerminateEmployee = asyncThunkWrapper('employees/terminateEmployee', terminateEmployeeData);
const fetchLCRecords = asyncThunkWrapper('lcRecords/fetch', listLCRecordsAction);
const searchLCRecords = asyncThunkWrapper('lcRecords/search', searchLCRecordsAction);
const deleteLCRecords = asyncThunkWrapper('lcRecords/delete', deleteLCRecordsAction);
const viewLCRecords = asyncThunkWrapper('lcRecords/view', viewLCRecordsAction);
const fetchExtendProbation = asyncThunkWrapper('employees/extendProbation', extendProbationData);
const fetchVerifyBackground = asyncThunkWrapper('employees/verifyBackground', verifyBackgroundData);
const fetchInitiatePIP = asyncThunkWrapper('employees/initiatePIP', initiatePIPData);
const fetchConcludePIP = asyncThunkWrapper('employees/concludePIP', concludePIPData);
const fetchLCRecordsPendingMyApproval = asyncThunkWrapper(
  'lcRecordsPendingMyApproval/fetch',
  listLCRecordsPendingMyApproval,
);

export {
  fetchEmployees,
  lockEmployees,
  unLockEmployees,
  activateEmployees,
  deActivateEmployees,
  sendESSInvite,
  convertToCandidate,
  searchEmployees,
  viewEmployees,
  deleteEmployees,
  fetchTransferEmployee,
  fetchPromoteEmployee,
  fetchDemoteEmployee,
  fetchConfirmEmployee,
  fetchExtendProbation,
  fetchTerminateEmployee,
  fetchVerifyBackground,
  fetchLCRecords,
  searchLCRecords,
  deleteLCRecords,
  viewLCRecords,
  fetchInitiatePIP,
  fetchConcludePIP,
  fetchLCRecordsPendingMyApproval,
};
