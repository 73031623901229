import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import TitleWithText from 'components/Atoms/TitleWithText';
import Typographys from 'components/Atoms/Typography';
import useDownloadFile from 'hooks/useDownloadFile';
import { additionalDetailStyle } from '../style';

const SkillsAttainmentDetail = ({ skillsAttainmentDetails }) => {
  const downloadFile = useDownloadFile();

  const handleAttachment = (docid, filename) => {
    downloadFile({ docid }, filename, 'restPayIndex/downloadAttachment');
  };

  return (
    <>
      <Typographys variant="h5" color="neutral.700">
        Skills Attainment Details
      </Typographys>
      <Box mt={2} sx={additionalDetailStyle.rowDivider}>
        {skillsAttainmentDetails.map((skillsList) => (
          <Grid container rowSpacing={1} columnSpacing={2}>
            <Grid item xs={12} sm={4}>
              <TitleWithText title="Skill" text={skillsList.skill} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TitleWithText title="Level" text={skillsList.level} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TitleWithText title="Comments" text={skillsList.comments} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TitleWithText title="Attachments" text="">
                {skillsList.documents?.length
                  ? skillsList.documents?.map((document) => (
                      <Box
                        sx={{ textDecoration: 'underline' }}
                        onClick={() => handleAttachment(document.id, document.fileName)}
                      >
                        {document.fileName}
                      </Box>
                    ))
                  : '-'}
              </TitleWithText>
            </Grid>
          </Grid>
        ))}
      </Box>
    </>
  );
};

SkillsAttainmentDetail.defaultProps = {
  skillsAttainmentDetails: [],
};

SkillsAttainmentDetail.propTypes = {
  skillsAttainmentDetails: PropTypes.arrayOf(
    PropTypes.shape({
      skill: PropTypes.string,
      level: PropTypes.string,
      comments: PropTypes.string,
    }),
  ),
};
export default SkillsAttainmentDetail;
