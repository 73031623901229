import { isEmpty } from 'lodash';

const useFilters = (filtersData, optionsData) => {
  const { filters = {} } = filtersData;
  const filterFields = [
    {
      name: 'Report Name',
      filterName: 'q',
      type: 'string',
      value: filters?.q,
    },
    {
      name: 'Report Id',
      filterName: 'expID',
      type: 'string',
      value: filters?.expID,
    },
    {
      name: 'Report Status',
      filterName: 'reportStatus',
      type: 'dropdown',
      options: !isEmpty(optionsData?.reportStatus)
        ? Object.values(optionsData?.reportStatus).map((data) => ({
            name: data,
            id: data,
          }))
        : [],
      value: filters?.reportStatus,
    },
    {
      name: 'Expense Categories',
      filterName: 'category.id',
      type: 'dropdown',
      options: optionsData?.categoriesList?.length
        ? optionsData?.categoriesList.map((data) => ({ name: data.displayName, id: data.id }))
        : [],
      value: filters?.['category.id'],
    },
    {
      name: 'Report Start Date',
      filterName: 'startDate',
      type: 'date',
      value: filters?.startDate,
    },
    {
      name: 'Report End Date',
      filterName: 'endDate',
      type: 'date',
      value: filters?.endDate,
    },
  ];

  return { filterFields };
};

export default useFilters;
