export const cardStyle = {
  card: {
    backgroundColor: 'white',
    border: '1px solid #D5D8E1',
    padding: '3.5rem',
    borderRadius: '0.75rem',
    display: 'flex',
    flex: '1',
  },
};
