const useFilters = (filtersData, optionsData) => {
  const { filters = {} } = filtersData;
  const filterFields = [
    {
      name: 'Start Date',
      filterName: 'startDate',
      type: 'date',
      value: filters?.startDate,
    },
    {
      name: 'End Date',
      filterName: 'endDate',
      type: 'date',
      value: filters?.endDate,
    },
    {
      name: 'Leave Category',
      filterName: 'leaveCategoryIDs',
      type: 'dropdown',
      options: optionsData?.leaveCategories?.length
        ? optionsData?.leaveCategories.map((data) => ({
            name: data.displayName,
            id: data.id,
          }))
        : [],
      value: filters?.leaveCategoryIDs,
    },
  ];

  return { filterFields };
};

export default useFilters;
