export const lifeCycleRecordStyle = {
  headerSection: {
    columnGap: 1,
    rowGap: 2,
    mb: 2,
  },

  submitWrapper: {
    display: 'flex',
    columnGap: '2rem',
    justifyContent: 'flex-end',
  },

  dataTable: {
    border: '0.125rem solid ',
    borderColor: 'neutral.300',
    borderRadius: '0.75rem',
    '@media screen and (max-width: 600px)': {
      overflowX: 'scroll',
      mt: 2,
    },
  },

  dataRowHead: {
    backgroundColor: 'neutral.100',
    boxShadow: 'inset 0px -1px 0px #E1E3EA',
    '> .MuiGrid-item': {
      p: 2,
      '.MuiTypography-caption': {
        color: 'neutral.600',
        fontWeight: 600,
        lineHeight: '3rem',
        letterSpacing: '0.063rem',
      },
    },
    '@media screen and (max-width: 600px)': {
      minWidth: '75rem',
    },
  },

  dataRow: {
    boxShadow: 'inset 0px -1px 0px #E1E3EA',
    alignItems: 'center',
    '&:last-child': {
      boxShadow: 'none',

      '> .MuiGrid-item': {
        p: '1rem 2rem',
      },

      '.MuiFormControl-root': {
        '.MuiOutlinedInput-notchedOutline': {
          border: '0.125rem solid ',
          borderColor: 'neutral.600',
        },
      },

      '& .MuiInputBase-sizeSmall input': {
        padding: '1.25rem 1.5rem',
      },
    },
    '> .MuiGrid-item': {
      p: 2,
      '.MuiTypography-root': {
        color: 'neutral.700',
      },
    },

    '.MuiFormControl-root  .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '.MuiFormControl-root .MuiOutlinedInput-root .MuiSelect-outlined , input': {
      p: 0,
    },
    '.MuiInputLabel-shrink': {
      opacity: 0,
    },
    '.MuiInputLabel-root': {
      transform: 'none',
      color: 'neutral.800',
    },

    '@media screen and (max-width: 600px)': {
      minWidth: '75rem',

      '> .MuiGrid-item': {
        '&:nth-child(2)': {
          py: 0,
        },
      },
      '&:last-child': {
        '> .MuiGrid-item': {
          p: '2rem',
          '&:nth-child(2)': {
            py: 0,
          },
        },
      },
    },
  },

  btnContainer: {
    position: 'fixed',
    right: '0rem',
    bottom: '3.625rem',
    backgroundColor: 'common.white',
    zIndex: '9',
    margin: '0rem 3.25rem',
    width: 'calc(100% - 49.5rem)',
    padding: '0rem 3rem 3.25rem 3rem',
  },

  Divider: {
    my: 2,
    backgroundColor: 'neutral.200',
  },

  actionBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
  },
};
