const columns = [
  {
    fieldName: 'firstName',
    name: '',
    label: 'First Name',
    formatValue: ({ row, column }) => {
      return row?.[column.fieldName];
    },
  },
  {
    fieldName: 'lastName',
    name: '',
    label: 'Last Name',
    formatValue: ({ row, column }) => row?.[column.fieldName],
  },
  {
    fieldName: 'employeeID',
    name: '',
    label: 'Employee ID',
    formatValue: ({ row, column }) => row?.profile?.[column.fieldName],
  },
  {
    fieldName: 'user',
    name: '',
    label: 'User Name',
    formatValue: ({ row, column }) => row?.[column.fieldName]?.username,
  },
  {
    fieldName: 'employmentStatus',
    name: '',
    label: 'Status',
    formatValue: ({ row, column }) => row?.[column.fieldName],
  },
  {
    fieldName: 'gender',
    name: '',
    label: 'Gender',
    formatValue: ({ row, column }) => row?.[column.fieldName],
  },
  {
    fieldName: 'department',
    name: '',
    label: 'Department',
    formatValue: ({ row, column }) => row?.[column.fieldName]?.name,
  },
  {
    fieldName: 'designation',
    name: '',
    label: 'Designation',
    formatValue: ({ row, column }) => row?.[column.fieldName]?.name,
  },
  {
    fieldName: 'location',
    name: '',
    label: 'Location',
    formatValue: ({ row, column }) => row?.[column.fieldName]?.name,
  },
  {
    fieldName: 'employmentType',
    name: '',
    label: 'Employment Type',
    formatValue: ({ row, column }) => row?.[column.fieldName],
  },
  {
    fieldName: 'supervisor',
    name: '',
    label: 'Supervisor',
    formatValue: ({ row, column }) => row?.[column.fieldName]?.fullName,
  },
];

const useColumnsAdvancedSearch = () => {
  return [columns];
};

export default useColumnsAdvancedSearch;
