export const chartStyle = {
  chartWrapper: {
    position: 'relative',
    fontFamily: 'Inter',
    fontWeight: 400,
    lineHeight: '160%',
    fontSize: '1.5rem',
    letterSpacing: '0.019rem',
  },

  chartInfo: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },

  legend: {
    fontSize: '1.75rem',
  },
};
