import React from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import PropTypes from 'prop-types';
import ProfileOnboardingRecords from 'components/Organisms/Profile/OnboardingRecords';

const OnboardingRecordTemplate = ({ handleEdit, onboardingForm, attachmentData, cardDetails, handleAttachment }) => {
  return (
    <BlankTemplate pageHeading="Onboarding Records" isBreadcrumb={false} padding="0">
      <ProfileOnboardingRecords
        handleEdit={handleEdit}
        onboardingForm={onboardingForm}
        attachmentData={attachmentData}
        cardDetails={cardDetails}
        handleAttachment={handleAttachment}
      />
    </BlankTemplate>
  );
};

OnboardingRecordTemplate.defaultProps = {
  onboardingForm: {},
  attachmentData: {},
  handleEdit: () => {},
  handleAttachment: () => {},
};

OnboardingRecordTemplate.propTypes = {
  onboardingForm: PropTypes.shape({
    Forms: PropTypes.shape({}),
  }),

  attachmentData: PropTypes.shape({}),
  handleEdit: PropTypes.func,
  handleAttachment: PropTypes.func,
};

export default OnboardingRecordTemplate;
