import React, { useState } from 'react';
import { Tabs, Tab, Box, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import { tabStyle } from './style';

const TabPanel = ({ children, value, index }) => {
  return (
    <Box role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`}>
      {value === index && <Box>{children}</Box>}
    </Box>
  );
};

const MuiTab = ({
  isVertical,
  orientation,
  variant,
  scrollButtons,
  allowScrollButtonsMobile,
  textColor,
  indicatorColor,
  tabs,
  panelPadding,
  propData,
  handleSubmit,
  disableChangingTabs,
  onTabChange,
  selectedTab,
  sx,
}) => {
  const [value, setValue] = useState(selectedTab);
  const handleChange = (event, selected) => {
    setValue(selected);
    onTabChange(selected);
  };

  return (
    <Box sx={{ ...tabStyle.tabWrapper, ...sx }} flexDirection={isVertical ? 'row' : 'column'}>
      <Box sx={{ flexShrink: 0 }}>
        <Tabs
          orientation={orientation}
          value={value}
          variant={variant}
          scrollButtons={scrollButtons}
          allowScrollButtonsMobile={allowScrollButtonsMobile}
          textColor={textColor}
          indicatorColor={indicatorColor}
          onChange={handleChange}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.label}
              iconPosition={tab.iconPosition}
              disabled={tab.isDisabled}
              icon={tab.icon}
              label={tab.label}
            />
          ))}
        </Tabs>
      </Box>
      {isVertical && <Divider role="presentation" sx={tabStyle.divider} />}
      <Box sx={tabStyle.tabPanelWrapper} padding={panelPadding}>
        {tabs.map((tab) => {
          const { RenderComponent, componentProps } = tab;
          return (
            <TabPanel key={tab.label} value={value} index={tabs.indexOf(tab)}>
              <RenderComponent
                {...componentProps}
                propData={propData}
                label={tab.label}
                handleSubmit={(data) => {
                  handleSubmit(tab.label, data, tabs.indexOf(tab));
                  if (!disableChangingTabs && tabs.indexOf(tab) < tabs.length - 1) {
                    setValue((prev) => prev + 1);
                  }
                }}
              />
            </TabPanel>
          );
        })}
      </Box>
    </Box>
  );
};

MuiTab.defaultProps = {
  orientation: 'horizontal',
  variant: 'fullWidth',
  scrollButtons: false,
  allowScrollButtonsMobile: false,
  textColor: 'primary',
  indicatorColor: 'primary',
  isVertical: false,
  tabs: [],
  handleSubmit: () => {},
  disableChangingTabs: false,
  propData: [],
  onTabChange: () => {},
  selectedTab: 0,
  panelPadding: '',
  sx: {},
};

MuiTab.propTypes = {
  orientation: PropTypes.string,
  variant: PropTypes.string,
  scrollButtons: PropTypes.bool,
  allowScrollButtonsMobile: PropTypes.bool,
  textColor: PropTypes.string,
  indicatorColor: PropTypes.string,
  isVertical: PropTypes.bool,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      iconPosition: PropTypes.string,
      isDisabled: PropTypes.bool,
      icon: PropTypes.node,
      label: PropTypes.node,
      RenderComponent: PropTypes.elementType,
      componentProps: PropTypes.objectOf,
    }),
  ),
  propData: PropTypes.arrayOf(PropTypes.shape({})),
  handleSubmit: PropTypes.func,
  disableChangingTabs: PropTypes.bool,
  onTabChange: PropTypes.func,
  selectedTab: PropTypes.number,
  panelPadding: PropTypes.string,
  sx: PropTypes.shape({}),
};

export default MuiTab;
