import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import Button from 'components/Atoms/Button';
import { validateDateElseHyphen } from 'utils/commonUtils';
import EditIcon from '@mui/icons-material/Edit';
import TitleWithText from 'components/Atoms/TitleWithText';
import Divider from '@mui/material/Divider';
import AuditTrailCard from 'components/Organisms/Employee/EmployeeRecord/AuditTrailCard';
import Typographys from 'components/Atoms/Typography';
import DropdownMenu from 'components/Molecules/DropdownMenu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useSelector } from 'react-redux';
import { hasPermission } from 'utils/permissions';
import { separationStyle } from '../../style';

const EmployeeExitDetails = ({
  exitDetails,
  handleEdit,
  auditLogData,
  updatedLog,
  handleView,
  rows,
  columns,
  rowsPerPage,
  totalRecordCount,
  minimumRecordsLength,
  handleWithdrawSeparation,
}) => {
  const authorities = useSelector((state) => state?.session?.user?.authorities);
  const userHasEditPermission = hasPermission(authorities, [
    'ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_EMPLOYEE_EXIT',
  ]);
  const userHasWithdrawPermission = hasPermission(authorities, [
    'ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_WITHDRAW_MY_EMPLOYEE_EXIT',
  ]);

  return (
    <Box>
      <Box padding={2} sx={{ display: 'flex', justifyContent: 'end', gap: 1 }}>
        {!!handleEdit && !!userHasEditPermission && (
          <Button startIcon={<EditIcon />} onClick={handleEdit}>
            Edit
          </Button>
        )}
      </Box>

      <Grid container columnSpacing={9}>
        <Grid item xs={12} md={3}>
          <TitleWithText title="Employee" text={exitDetails?.employeeName} />
        </Grid>

        <Grid item xs={12} md={3}>
          <TitleWithText title="Separation Type" text={exitDetails?.separationType} />
        </Grid>

        <Grid item xs={12} md={3}>
          <TitleWithText title="Notice Period" text={exitDetails?.noticePeriod} />
        </Grid>

        <Grid item xs={12} md={3}>
          <TitleWithText title="Resignation Status" text={exitDetails?.resignationStatus} />
        </Grid>

        <Grid item xs={12} md={3}>
          <TitleWithText title="Resig. Submission Date" text={exitDetails?.submissionDate} />
        </Grid>

        <Grid item xs={12} md={3}>
          <TitleWithText title="Last Working Day (Per Policy)" text={exitDetails?.lastWorkingDay} />
        </Grid>

        <Grid item xs={12} md={3}>
          <TitleWithText title="Requested Relieving Date" text={exitDetails?.requestedRelievingDate} />
        </Grid>

        <Grid item xs={12} md={3}>
          <TitleWithText title="Approved Relieving Date" text={exitDetails?.approvedRelievingDate} />
        </Grid>
      </Grid>

      <Divider sx={separationStyle.Divider} />

      <Box mt={2}>
        <TitleWithText title="Resignation Reasons" text={exitDetails?.resignationReasons} />
      </Box>

      <Divider sx={separationStyle.Divider} />

      <Typographys variant="h6">Supervisor Approval Details </Typographys>
      <Grid mt={1.5} container columnSpacing={9}>
        <Grid item xs={12} md={3}>
          <TitleWithText title="Resignation Accepting Supervisor" text={exitDetails?.resignationAcceptingSupervisor} />
        </Grid>
        <Grid item xs={12} md={3}>
          <TitleWithText
            title="Supervisor Resignation Acceptance Date"
            text={exitDetails?.supervisorResignationAcceptanceDate}
          />
        </Grid>
      </Grid>

      <Divider sx={separationStyle.Divider} />

      <Typographys variant="h6">HR Approval Details</Typographys>
      <Grid mt={2} container columnSpacing={9}>
        <Grid item xs={12} md={3}>
          <TitleWithText title="Resignation Accepting HR Manager" text={exitDetails?.resignationAcceptingHR} />
        </Grid>
        <Grid item xs={12} md={3}>
          <TitleWithText
            title="HR Manager Resignation Acceptance Date"
            text={exitDetails?.HRResignationAcceptanceDate}
          />
        </Grid>
      </Grid>

      <Divider sx={separationStyle.Divider} />

      <Typographys variant="h6">Other Details</Typographys>
      <Grid mt={2} container columnSpacing={9}>
        <Grid item xs={12} md={3}>
          <TitleWithText
            title="Exit Interview Date"
            text={validateDateElseHyphen(exitDetails?.exitInterviewDate, 'DD MMMM YYYY')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TitleWithText title="Exit Interviewer" text={exitDetails?.exitInterviewer} />
        </Grid>
      </Grid>

      <Divider sx={separationStyle.Divider} />
      <Typographys variant="h6" color="neutral.800">
        Audit Log
      </Typographys>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2.5 }}>
        {auditLogData?.length > 0 &&
          auditLogData?.map((item) => (
            <AuditTrailCard
              avatarSrc={item?.avatarSrc}
              description={item?.description}
              date={item?.date}
              content={item?.content}
            />
          ))}
      </Box>
      <Divider sx={separationStyle.Divider} />
      {!!userHasWithdrawPermission && (
        <Button startIcon={<ExitToAppIcon />} onClick={handleWithdrawSeparation}>
          Withdraw Separation
        </Button>
      )}
    </Box>
  );
};

EmployeeExitDetails.defaultProps = {
  exitDetails: {
    employeeName: '',
    separationType: '',
    noticePeriod: '',
    resignationStatus: '',
    submissionDate: '',
    lastWorkingDay: '',
    requestedRelievingDate: '',
    approvedRelievingDate: 'null',
    resignationReasons: '',
    resignationAcceptingSupervisor: '',
    supervisorResignationAcceptanceDate: '',
    resignationAcceptingHR: '',
    HRResignationAcceptanceDate: '',
    exitInterviewDate: '',
    exitInterviewer: '',
    avatarSrc: '',
    name: '',
    date: '',
  },
  handleEdit: () => {},
  auditLogData: [],
  updatedLog: {},
  handleView: () => {},
  rowsPerPage: null,
  totalRecordCount: null,
  minimumRecordsLength: null,
  rows: [],
  columns: [],
  handleWithdrawSeparation: () => {},
};

EmployeeExitDetails.propTypes = {
  exitDetails: PropTypes.shape({
    employeeName: PropTypes.string,
    separationType: PropTypes.string,
    noticePeriod: PropTypes.string,
    resignationStatus: PropTypes.string,
    submissionDate: PropTypes.string,
    lastWorkingDay: PropTypes.string,
    requestedRelievingDate: PropTypes.string,
    approvedRelievingDate: PropTypes.string,
    resignationReasons: PropTypes.string,
    resignationAcceptingSupervisor: PropTypes.string,
    supervisorResignationAcceptanceDate: PropTypes.string,
    resignationAcceptingHR: PropTypes.string,
    HRResignationAcceptanceDate: PropTypes.string,
    exitInterviewDate: PropTypes.string,
    exitInterviewer: PropTypes.string,
    avatarSrc: PropTypes.string,
    name: PropTypes.string,
    date: PropTypes.string,
  }),
  handleEdit: PropTypes.func,
  handleWithdrawSeparation: PropTypes.func,

  auditLogData: PropTypes.arrayOf(
    PropTypes.shape({
      avatarSrc: PropTypes.string,
      description: PropTypes.string,
      date: PropTypes.string,
    }),
  ),

  updatedLog: PropTypes.shape({
    updatedName: PropTypes.string,
    updatedDate: PropTypes.string,
  }),

  handleView: PropTypes.func,
  rowsPerPage: PropTypes.number,
  totalRecordCount: PropTypes.number,
  minimumRecordsLength: PropTypes.number,
  rows: PropTypes.arrayOf,
  columns: PropTypes.arrayOf,
};

export default EmployeeExitDetails;
