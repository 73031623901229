import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import QueryDetails from 'components/Templates/TaskAndQueries/QueryDetails';
import DeleteQuery from 'components/Organisms/Dialog/ActionList/TaskAndQueries/DeleteQuery';
import { Box, Skeleton, Typography } from '@mui/material';
import { hasPermission } from 'utils/permissions';
import { useSelector } from 'react-redux';
import { useThunk } from 'hooks/useThunk';
import { fetchViewQueryDetails } from 'store';
import useServices from '../common/useServices';
import useMenuItems from './useMenuItems';

const ViewQueries = () => {
  const breadcrumbData = [
    { id: 1, name: 'My Actionable Queries', path: '/home/actionable-queries', isActive: false },
    { id: 2, name: 'Query Details', isActive: true },
  ];

  const { id } = useParams();
  const services = useServices();
  const navigate = useNavigate();
  const [handleActionItemClick] = useMenuItems(services);
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasEditPermission = hasPermission(authorities, ['ROLE_BASE_PERMISSION_QUER_INDEX_UPDATE_QUERY']);

  const [getQueryDetails, isLoadingQueryDetails, loadingQueryDetailsError] = useThunk(fetchViewQueryDetails);
  const { viewQueryDetails = {} } = useSelector((state) => state?.queries);

  const queryDetails = {
    queryNumber: viewQueryDetails?.queryInstance?.queryNumber,
    title: viewQueryDetails?.queryInstance?.title,
    resolutionStatus: viewQueryDetails?.queryInstance?.resolutionStatus,
    openedBy: viewQueryDetails?.queryInstance?.openedBy?.fullName,
    closedBy: viewQueryDetails?.queryInstance?.closedBy?.fullName,
    openDate: viewQueryDetails?.queryInstance?.openDate,
    closeDate: viewQueryDetails?.queryInstance?.closeDate,
    submissionDate: viewQueryDetails?.queryInstance?.submissionDate,
    currentOwner: viewQueryDetails?.queryInstance?.currentOwner?.fullName,
    category: `${viewQueryDetails?.queryInstance?.category?.name || ''} (${
      viewQueryDetails?.queryInstance?.category?.categoryCode || ''
    })`,
    topic: `${viewQueryDetails?.queryInstance?.topic?.name || ''} (${
      viewQueryDetails?.queryInstance?.topic?.topicCode || ''
    })`,
    description: viewQueryDetails?.queryInstance?.description,
    fileName: viewQueryDetails?.queryInstance?.attachment?.documentName,
    id: viewQueryDetails?.queryInstance?.attachment?.id,
  };

  useEffect(() => {
    const query = new URLSearchParams({});
    query.append('id', id);
    getQueryDetails(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, getQueryDetails]);

  if (isLoadingQueryDetails) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (loadingQueryDetailsError !== null) {
    return (
      <Typography variant="h6">{loadingQueryDetailsError?.message || 'Error while loading query details.'}</Typography>
    );
  }

  return (
    <Box>
      <QueryDetails
        breadcrumbData={breadcrumbData}
        reportDetails={queryDetails}
        handleEdit={() => services.modifyQueryNavigationService({ id })}
        actionMenuList={handleActionItemClick}
        handleAttachmentDownload={services.downloadDocument}
        showEdit={
          !!userHasEditPermission &&
          (viewQueryDetails?.queryInstance?.resolutionStatus?.toUpperCase() === 'DRAFT' ||
            viewQueryDetails?.queryInstance?.resolutionStatus?.toUpperCase() === 'REOPENED')
        }
        auditLogData={[]}
      />
      {services?.state?.open && (
        <DeleteQuery
          open={services?.state?.open}
          handleClose={() => services.handleOpenDialog(false)}
          handleCancel={() => services.handleOpenDialog(false)}
          handleDelete={() => {
            services?.deleteQueryService(services?.state?.queryDetails?.id);
            navigate('/home/actionable-queries');
          }}
          queryTitle={services?.state?.queryDetails?.title}
        />
      )}
    </Box>
  );
};

export default ViewQueries;
