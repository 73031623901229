import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import TitleWithText from 'components/Atoms/TitleWithText';

const ExtendedAttribute = ({ extendedAttribute }) => {
  return (
    <Grid sx={{ paddingLeft: '24px', marginTop: '8px' }} container rowSpacing={1} columnSpacing={2}>
      <Grid item xs={12} sm={4}>
        <TitleWithText title="Legal Entity" text={extendedAttribute?.legalEntity} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TitleWithText title="Division" text={extendedAttribute?.division} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TitleWithText title="Category" text={extendedAttribute?.category} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TitleWithText title="Grade" text={`${extendedAttribute?.symbol || '₹'} ${extendedAttribute?.grade || '-'}`} />
      </Grid>
    </Grid>
  );
};

ExtendedAttribute.defaultProps = {
  extendedAttribute: {
    legalEntity: '',
    division: '',
    category: '',
    grade: '',
    symbol: '₹',
  },
};

ExtendedAttribute.propTypes = {
  extendedAttribute: PropTypes.shape({
    legalEntity: PropTypes.string,
    division: PropTypes.string,
    category: PropTypes.string,
    grade: PropTypes.string,
  }),
};

export default ExtendedAttribute;
