import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import MuiTab from 'components/Molecules/Tab';
import LanguageIcon from '@mui/icons-material/Language';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import SchoolIcon from '@mui/icons-material/School';
import HighlightIcon from '@mui/icons-material/Highlight';
import SocialData from '../SocialData/Edit';
import EmploymentHistory from '../EmploymentHistory/Edit';
import KeyLifeEventDetails from '../KeyLifeEvents/Edit';
import SkillAttainmentDetails from '../SkillsAttainmentDetails/Edit';
import EducationHistory from '../EducationHistory/Edit';
import VisaDetail from '../VisaDetails/Edit';

const AdditionalDetail = ({ handleSubmit, propData, label, dropdownOptions, handleCancel }) => {
  const defaultValues = propData.allTabsData[label];
  const [allTabsData, setAllTabsData] = useState(defaultValues);
  const [initialTabs, setTabs] = useState([
    {
      icon: <LanguageIcon />,
      iconPosition: 'start',
      label: 'Social Data',
      onChange: () => {},
      isDisabled: 0,
      RenderComponent: SocialData,
      componentProps: { handleCancel: () => handleCancel('Social Data'), updateBtnText: 'Save & Continue' },
    },
    {
      icon: <SchoolIcon />,
      iconPosition: 'start',
      label: 'Education/Certification History',
      onChange: () => {},
      isDisabled: 0,
      RenderComponent: EducationHistory,
      componentProps: {
        handleCancel: () => handleCancel('Education/Certification History'),
        updateCommentLabel: true,
        commentLabelText: 'Comments',
      },
    },

    {
      icon: <WatchLaterIcon />,
      iconPosition: 'start',
      label: 'Employment History',
      onChange: () => {},
      isDisabled: 0,
      RenderComponent: EmploymentHistory,
      componentProps: {
        handleCancel: () => handleCancel('Employment History'),
      },
    },
    {
      icon: <FlightTakeoffIcon />,
      iconPosition: 'start',
      label: 'Visa Details',
      onChange: () => {},
      isDisabled: 0,
      RenderComponent: VisaDetail,
      componentProps: { handleCancel: () => handleCancel('Visa Details') },
    },
  ]);

  function updateFormData(data, label1) {
    const finalData = {
      ...allTabsData,
      [label1]: data,
    };
    setAllTabsData(finalData);
    propData.updateFormData(finalData, label);
  }

  return (
    <Box sx={{ display: 'flex', padding: '2rem 3rem' }}>
      <MuiTab
        orientation="vertical"
        textColor="primary"
        indicatorColor="primary"
        isVertical
        panelPadding="0 3rem"
        tabs={initialTabs}
        setTabs={setTabs}
        setAllTabsData={setAllTabsData}
        propData={{ allTabsData, allowEdit: true, updateFormData, propData }}
        handleSubmit={(label1, data) => {
          handleSubmit({ label: label1, data });
        }}
        disableChangingTabs
      />
    </Box>
  );
};

AdditionalDetail.defaultProps = {
  handleSubmit: () => {},
  propData: {
    loading: false,
    updateFormData: () => {},
    allTabsData: {
      'ADDITIONAL DETAILS': {
        'Social Data': {},
        'Education/Certification History': { education: [{}] },
        'Employment History': { employment: [{}] },
        'Visa Details': { visa: [{}] },
        'Skill Attainment Details': { skillDetails: [{}] },
        'Key Life Event Details': { keyLifeEvent: [{}] },
      },
    },
  },
  label: 'ADDITIONAL DETAILS',
  dropdownOptions: {},
  handleCancel: () => {},
};

AdditionalDetail.propTypes = {
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,

  propData: PropTypes.shape({
    loading: PropTypes.bool,
    updateFormData: PropTypes.func,
    allTabsData: PropTypes.shape({
      'ADDITIONAL DETAILS': PropTypes.shape({}),
    }),
  }),

  label: PropTypes.string,

  dropdownOptions: PropTypes.shape({
    levelOptions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        name: PropTypes.string,
      }),
    ),

    skillOptions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        name: PropTypes.string,
      }),
    ),

    lifeEventOptions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        name: PropTypes.string,
      }),
    ),
  }),
};

export default AdditionalDetail;
