import React from 'react';
import { Box, Typography } from '@mui/material';
import NoRecordsSVG from 'assets/images/NoRecords.svg';

const NoRecordsMessage = (width, height, altText) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        width: { width },
        height: { height },
        marginTop: '5rem',
      }}
    >
      <Box
        component="img"
        sx={{
          display: 'block',
          maxWidth: '18rem',
          marginBottom: '3rem',
        }}
        alt={altText}
        src={NoRecordsSVG}
      />
      <Typography>No Records Found.</Typography>
    </Box>
  );
};

export default NoRecordsMessage;
