import React from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import PropTypes from 'prop-types';

import EmployeeList from 'components/Organisms/Employee/EmployeeList';

const EmployeeListTemplate = ({
  loading,
  rows,
  columns,
  count,
  showActionList,
  handleSort,
  handleRowClick,
  page,
  onPageChange,
  sortBy,
  sortDirection,
  handleSelect,
  selectedItems,
  onSelect,
  onSelectAll,
  showRowSelector,
  showPagination,
  keyColumn,
  pageHeading,
  roleBasedMenu,
  onClickAddNew,
  onClickBulkUpload,
  onClickSendESSInvite,
  onClickDownload,
  onClickActive,
  onClickDisable,
  handleAdvanceSearch,
  menuList,
}) => {
  return (
    <BlankTemplate isBreadcrumb={false} pageHeading={pageHeading} padding="0">
      <EmployeeList
        loading={loading}
        columns={columns}
        rows={rows}
        count={count}
        selectedItems={selectedItems}
        onSelect={onSelect}
        onSelectAll={onSelectAll}
        showRowSelector={showRowSelector}
        showPagination={showPagination}
        onPageChange={onPageChange}
        handleSort={handleSort}
        handleRowClick={handleRowClick}
        page={page}
        keyColumn={keyColumn}
        roleBasedMenu={roleBasedMenu}
        handleSelect={handleSelect}
        showActionList={showActionList}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onClickAddNew={onClickAddNew}
        onClickBulkUpload={onClickBulkUpload}
        onClickSendESSInvite={onClickSendESSInvite}
        onClickDownload={onClickDownload}
        onClickActive={onClickActive}
        onClickDisable={onClickDisable}
        handleAdvanceSearch={handleAdvanceSearch}
        menuList={menuList}
      />
    </BlankTemplate>
  );
};

EmployeeListTemplate.defaultProps = {
  loading: false,
  rows: [],
  columns: [],
  showActionList: false,
  count: 10,
  handleSort: () => {},
  handleRowClick: () => {},
  page: 0,
  sortBy: '',
  sortDirection: 'asc',
  handleSelect: () => {},
  selectedItems: [],
  onSelect: () => {},
  onSelectAll: () => {},
  showRowSelector: false,
  showPagination: false,
  keyColumn: '',
  pageHeading: 'Employee List',
  roleBasedMenu: (row) => {},
  handleAdvanceSearch: () => {},
  onClickAddNew: () => {},
  onClickBulkUpload: () => {},
  onClickSendESSInvite: () => {},
  onClickDownload: () => {},
  onClickActive: () => {},
  onClickDisable: () => {},
  onPageChange: () => {},
};

EmployeeListTemplate.propTypes = {
  loading: PropTypes.bool,
  rows: PropTypes.arrayOf,
  columns: PropTypes.arrayOf,
  showActionList: PropTypes.bool,
  count: PropTypes.number,
  handleSort: PropTypes.func,
  handleRowClick: PropTypes.func,
  page: PropTypes.number,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  handleSelect: PropTypes.func,
  selectedItems: PropTypes.arrayOf,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  showRowSelector: PropTypes.bool,
  showPagination: PropTypes.bool,
  keyColumn: PropTypes.string,
  pageHeading: PropTypes.string,
  roleBasedMenu: PropTypes.func,
  handleAdvanceSearch: PropTypes.func,
  onClickAddNew: PropTypes.func,
  onClickBulkUpload: PropTypes.func,
  onClickSendESSInvite: PropTypes.func,
  onClickDownload: PropTypes.func,
  onClickActive: PropTypes.func,
  onClickDisable: PropTypes.func,
  onPageChange: PropTypes.func,
};

export default EmployeeListTemplate;
