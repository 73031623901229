import React, { useEffect, useState } from 'react';
import AddAndEditTask from 'components/Templates/Employee/Company/Separation/ViewOffboardingTemplate/AddAndEdit';
import { Box, Typography } from '@mui/material';
import useToast from 'components/Provider/useToast';
import { useNavigate, useParams } from 'react-router-dom';
import get from 'lodash/get';
import dayjs from 'dayjs';
import { modifyOffBoardingCheckListTask, modifyOffBoardingCheckListTaskAction } from 'api/employees/separations';

const AddEditPageExitTask = () => {
  const addToast = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { id, taskId } = useParams();
  const [preModifyData, setPreModifyData] = useState({
    data: {},
    isLoading: true,
  });

  const statusTypeOptions = [
    { id: 'ACTIVE', name: 'Active' },
    { id: 'INACTIVE', name: 'Inactive' },
  ];
  const defaultValues = {};

  const breadcrumbData = [
    {
      id: 1,
      name: 'Offboarding task',
      path: `/myteam/separations/exit-list/${id}/view/${taskId}`,
      isActive: false,
    },
    { id: 2, name: 'Edit', path: '', isActive: true },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setPreModifyData({ ...preModifyData, isLoading: true });
      try {
        const body = new URLSearchParams();
        body.append('id', taskId);
        const response = await modifyOffBoardingCheckListTask(body);
        if ((response?.data?.status || response?.status) !== 'ERROR') {
          const result = {};
          result.taskName = response?.simpleTaskInstance?.taskName;
          result.taskDescription = response?.simpleTaskInstance?.taskDescription;
          result.dueBy = response?.simpleTaskInstance?.dueBy;
          result.owner = response?.simpleTaskInstance?.ownerId;
          result.dueMarkerDate = response?.simpleTaskInstance?.dueMarkerDate;
          result.taskBeginDate = response?.simpleTaskInstance?.taskBeginDate;
          result.taskCompletionDate = response?.simpleTaskInstance?.taskCompletionDate;
          result.taskCompletionPercentage = response?.simpleTaskInstance?.completionPercentage;
          result.taskCompletionDate = response?.simpleTaskInstance?.taskCompletionDate;
          result.amount = response?.simpleTaskInstance?.amount;
          result.taskCompletionStatus = response?.simpleTaskInstance?.completionStatus;
          result.action = response?.simpleTaskInstance?.actionStatus;
          result.comments = response?.simpleTaskInstance?.comments;
          result.employeeList = response?.employeeList?.length
            ? response?.employeeList?.map((emp) => ({ id: emp.id, name: emp.fullNameWithID }))
            : [];
          result.id = response?.simpleTaskInstance?.id;
          result.simpleTaskStatusOptions = response?.simpleTaskStatus?.length
            ? response?.simpleTaskStatus.map((item) => ({ id: item, name: item }))
            : [];
          result.taskActionOptions = response?.taskAction?.length
            ? response?.taskAction.map((item) => ({ id: item, name: item }))
            : [];

          setPreModifyData(result);
        } else {
          setPreModifyData({
            isLoading: false,
            message: response.message || response?.data?.message || `Something went wrong`,
          });
          addToast({
            type: 'error',
            title: 'ERROR',
            message: response.message || response?.data?.message || `Something went wrong`,
          });
        }
      } catch (err) {
        setPreModifyData({ isLoading: false });
        addToast({
          type: 'error',
          title: 'ERROR',
          message: err.message || `Something went wrong`,
        });
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSubmit = async (values) => {
    const bodyFormData = new URLSearchParams();
    bodyFormData.append('id', taskId);
    bodyFormData.append('owningTaskListId', preModifyData.id);
    bodyFormData.append('taskName', get(values, 'taskName', ''));
    bodyFormData.append('taskDescription', get(values, 'taskDescription', ''));
    bodyFormData.append('owner.id', get(values, 'owner', ''));
    bodyFormData.append('dueBy', values.dueBy ? dayjs(values.dueBy).format('MM/DD/YYYY') : '');
    bodyFormData.append('dueMarkerDate', values.dueBy ? dayjs(values.dueMarkerDate).format('MM/DD/YYYY') : '');
    bodyFormData.append('taskBeginDate', values.dueBy ? dayjs(values.taskBeginDate).format('MM/DD/YYYY') : '');
    bodyFormData.append(
      'taskCompletionDate',
      values.dueBy ? dayjs(values.taskCompletionDate).format('MM/DD/YYYY') : '',
    );

    bodyFormData.append('amount', get(values, 'amount', ''));
    bodyFormData.append('actionStatus', get(values, 'action', ''));
    bodyFormData.append('completionStatus', get(values, 'taskCompletionStatus', ''));
    bodyFormData.append('completionPercentage', get(values, 'taskCompletionPercentage', ''));
    bodyFormData.append('comments', get(values, 'comments', ''));
    bodyFormData.append('attachment', get(values, 'attachments', ''));
    try {
      setLoading(true);
      const response = await modifyOffBoardingCheckListTaskAction(bodyFormData);
      const results = response?.data;
      if (results?.status !== 'ERROR') {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: results?.message || `Task updated successfully`,
        });
        navigate(`/myteam/separations/exit-list/${id}/view/${taskId}`);
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to update task`,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(`/myteam/separations/exit-list/${id}/view/${taskId}`);
  };

  if (preModifyData?.message) {
    return (
      <Typography variant="h6">{preModifyData?.message || 'Error while loading current task details.'}</Typography>
    );
  }
  return (
    <Box>
      <AddAndEditTask
        formLoading={preModifyData.isLoading}
        loading={preModifyData.isLoading}
        breadcrumbData={breadcrumbData}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        defaultValues={defaultValues}
        statusTypeOptions={statusTypeOptions}
        isEdit
        handleOwnerOptions={() => {}}
        preModifyData={preModifyData}
      />
    </Box>
  );
};
export default AddEditPageExitTask;
