import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Divider } from '@mui/material';
import Button from 'components/Atoms/Button';
import EditIcon from '@mui/icons-material/Edit';
import TitleWithText from 'components/Atoms/TitleWithText';
import Chips from 'components/Molecules/Chip';
import DeleteIcon from '@mui/icons-material/Delete';
import AuditTrailCard from 'components/Organisms/Employee/EmployeeRecord/AuditTrailCard';
import Typographys from 'components/Atoms/Typography';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { resignationStatusColor } from 'utils/statusColor';
import { hasPermission } from 'utils/permissions';
import { useSelector } from 'react-redux';
import { separationStyle } from '../style';

const EmployeeExitDetails = ({ exitDetails, handleEdit, handleDelete, auditLogData, lockStatus }) => {
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasEditPermission =
    hasPermission(authorities, ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_EMPLOYEE_EXIT']) &&
    lockStatus === 'UnLocked';
  const userHasDeletePermission =
    hasPermission(authorities, ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_DELETE_EMPLOYEE_EXIT']) &&
    lockStatus === 'UnLocked';
  return (
    <Box>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 36rem)' }}>
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            {!!userHasEditPermission && (
              <Button startIcon={<EditIcon />} onClick={handleEdit}>
                Edit
              </Button>
            )}
          </Box>

          <Grid mt={2} container columnSpacing={9}>
            <Grid item xs={12} md={4}>
              <TitleWithText title="Employee" text={`${exitDetails.employeeName} (${exitDetails.employeeID})`} />
            </Grid>

            <Grid item xs={12} md={4}>
              <TitleWithText title="Separation Type" text={exitDetails.separationType} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TitleWithText title="Notice Period" text={exitDetails.noticePeriod} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TitleWithText title="Resig. Submission Date" text={exitDetails.submissionDate} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TitleWithText title="Last Working Day (Per Policy)" text={exitDetails.lastWorkingDayAsPerPolicy} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TitleWithText title="Requested Relieving Date" text={exitDetails.requestedRelievingDate} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TitleWithText title="Manager Approved Last Working Day" text={exitDetails.supervisorApprovedLWD} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TitleWithText title="Resignation Reasons" text={exitDetails.resignationReasons} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TitleWithText title="Approved Relieving Date" text={exitDetails.dateOfLeaving} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TitleWithText
                title="Resignation Status"
                text={
                  <Chips
                    label={exitDetails.resignationStatus}
                    color={resignationStatusColor(exitDetails?.resignationStatus)}
                  />
                }
              />
            </Grid>
          </Grid>
          <Divider sx={separationStyle.Divider} />
          <Grid mt={2} container columnSpacing={9}>
            <Grid item xs={12} md={4}>
              <TitleWithText title="Resignation Accepting Supervisor" text={exitDetails.acceptingManager} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TitleWithText title="Supervisor Resignation Acceptance Date" text={exitDetails.acceptanceDate} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TitleWithText title="Supervisor Comments" text={exitDetails.resignationAcceptanceComments} />
            </Grid>
          </Grid>
          <Divider sx={separationStyle.Divider} />
          <Grid mt={2} container columnSpacing={9}>
            <Grid item xs={12} md={4}>
              <TitleWithText title="Resignation Accepting HR Manager" text={exitDetails.hrResignationAcceptedBy} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TitleWithText
                title="HR Manager Resignation Acceptance Date"
                text={exitDetails.hrResignationAcceptanceDate}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TitleWithText title="HR Manager Comments" text={exitDetails.hrAcceptanceComments} />
            </Grid>
          </Grid>
          <Divider sx={separationStyle.Divider} />
          <Grid mt={2} container columnSpacing={9}>
            <Grid item xs={12} md={4}>
              <TitleWithText title="Exit Interview Date" text={exitDetails.interviewDate} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TitleWithText title="Exit Interviewer" text={exitDetails.interviewer} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TitleWithText title="Is Final Settlement Complete?" text={exitDetails.isFinalSettlementCompleted} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TitleWithText title="Final Settlement Date" text={exitDetails.settlementDate} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TitleWithText title="Eligible for Rehire?" text={exitDetails.eligibleRehire} />
            </Grid>
          </Grid>
          <Divider sx={separationStyle.Divider} />
          <Typographys variant="h6" color="neutral.800">
            Audit Log
          </Typographys>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2.5 }}>
            {auditLogData?.length > 0 &&
              auditLogData?.map((item) => (
                <AuditTrailCard
                  avatarSrc={item?.avatarSrc}
                  description={item?.description}
                  date={item?.date}
                  content={item?.content}
                />
              ))}
          </Box>
        </Box>
      </PerfectScrollbar>
      {!!userHasDeletePermission && (
        <Box sx={separationStyle.buttonWrapper}>
          <Divider sx={separationStyle.Divider} />
          <Button
            size="large"
            startIcon={<DeleteIcon />}
            color="error"
            variant="outlined"
            type="cancel"
            onClick={handleDelete}
            justifyContent="flex-start"
          >
            Delete
          </Button>
        </Box>
      )}
    </Box>
  );
};

EmployeeExitDetails.defaultProps = {
  exitDetails: {
    employeeName: 'Uday Kumar',
    employeeID: '001',
    separationType: 'Resignation',
    noticePeriod: '1 month',
    resignationStatus: 'In Process',
    submissionDate: '12 May 2024',
    lastWorkingDayAsPerPolicy: '12 June 2024',
    requestedRelievingDate: '12 June 2024',
    supervisorApprovedLWD: '12 June 2024',
    resignationReasons: 'Better Opportunities',
    dateOfLeaving: '12 June 2024',
    acceptingManager: 'Manager Kumar',
    acceptanceDate: '13 may 2024',
    resignationAcceptanceComments: 'Accepted',
    hrResignationAcceptedBy: 'HR Kumar',
    hrResignationAcceptanceDate: '14 May 2024',
    hrAcceptanceComments: 'Accepted By HR',
    interviewDate: '13 May 2024',
    interviewer: 'HR Kumar',
    isFinalSettlementCompleted: 'No',
    settlementDate: '20 June 2024',
    eligibleRehire: 'Yes',
    relievingDate: '12 June 2024',
    resignationStatusColor: 'success',
    avatarSrc: '',
    name: 'Uday Kumar',
    date: '20 May 2024',
  },
  handleEdit: () => {},
  handleDelete: () => {},

  auditLogData: [],
  lockStatus: 'Locked',
};

EmployeeExitDetails.propTypes = {
  exitDetails: PropTypes.shape({
    employeeName: PropTypes.string,
    employeeID: PropTypes.string,
    separationType: PropTypes.string,
    noticePeriod: PropTypes.string,
    resignationStatus: PropTypes.string,
    submissionDate: PropTypes.string,
    lastWorkingDayAsPerPolicy: PropTypes.string,
    requestedRelievingDate: PropTypes.string,
    supervisorApprovedLWD: PropTypes.string,
    resignationReasons: PropTypes.string,
    dateOfLeaving: PropTypes.string,
    acceptingManager: PropTypes.string,
    acceptanceDate: PropTypes.string,
    resignationAcceptanceComments: PropTypes.string,
    hrResignationAcceptedBy: PropTypes.string,
    hrResignationAcceptanceDate: PropTypes.string,
    hrAcceptanceComments: PropTypes.string,
    interviewDate: PropTypes.string,
    interviewer: PropTypes.string,
    isFinalSettlementCompleted: PropTypes.string,
    settlementDate: PropTypes.string,
    eligibleRehire: PropTypes.string,
    relievingDate: PropTypes.string,
    resignationStatusColor: PropTypes.string,
    avatarSrc: PropTypes.string,
    name: PropTypes.string,
    date: PropTypes.string,
  }),

  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,

  auditLogData: PropTypes.arrayOf(
    PropTypes.shape({
      updatedLog: PropTypes.shape({
        updatedName: PropTypes.string,
        updatedDate: PropTypes.string,
      }),

      handleView: PropTypes.func,
      rowsPerPage: PropTypes.number,
      totalRecordCount: PropTypes.number,

      auditLogRows: PropTypes.shape({
        fieldName: PropTypes.string,
        oldValue: PropTypes.string,
        newValue: PropTypes.string,
      }),
    }),
  ),
  lockStatus: PropTypes.string,
};

export default EmployeeExitDetails;
