export const edTableComputedData = (entries, lineEntriesMap, valuesMap, actionType, colType, payHeadWiseYtdTotals) => {
  const colData = [];
  let isNotArrears;

  Object.keys(entries)
    .sort()
    .forEach((payheadName) => {
      const salarySlipLineEntryInstance = lineEntriesMap[payheadName];
      const payheadValue = valuesMap[payheadName];
      if (actionType === 'earning') {
        isNotArrears =
          salarySlipLineEntryInstance &&
          !salarySlipLineEntryInstance.payhead.isInternalPayhead &&
          !salarySlipLineEntryInstance.payhead.name.includes('Arrears');
      } else {
        isNotArrears = salarySlipLineEntryInstance && !salarySlipLineEntryInstance.payhead.name.includes('Arrears');
      }
      if (payheadValue && isNotArrears) {
        if (salarySlipLineEntryInstance?.payhead?.displayOnSalarySlip) {
          let entryVal;
          if (colType === 'heading') {
            entryVal = salarySlipLineEntryInstance.payhead.name;
          } else if (colType === 'data column') {
            entryVal = payheadValue;
          } else if (colType === 'data zero') {
            entryVal = 0;
          } else if (colType === 'data total') {
            entryVal = payHeadWiseYtdTotals[payheadName];
          }
          colData.push(entryVal);
        }
      }
    });
  return colData;
};
