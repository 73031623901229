import React from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import ProfileEmploymentHistory from 'components/Organisms/Profile/EmploymentHistory';

const ProfileEmploymentHistoryTemplate = ({ employmentHistory, handleEdit, handleAttachment }) => {
  return (
    <BlankTemplate pageHeading="Employment History" isBreadcrumb={false} padding="2rem 3rem">
      <ProfileEmploymentHistory
        employmentHistory={employmentHistory}
        handleEdit={handleEdit}
        handleAttachment={handleAttachment}
      />
    </BlankTemplate>
  );
};

ProfileEmploymentHistoryTemplate.defaultProps = {
  employmentHistory: [],
  handleEdit: () => {},
  handleAttachment: () => {},
};

ProfileEmploymentHistoryTemplate.propTypes = {
  employmentHistory: PropTypes.arrayOf,
  handleEdit: PropTypes.func,
  handleAttachment: PropTypes.func,
};

export default ProfileEmploymentHistoryTemplate;
