export const chartStyle = {
  chartWrapper: {
    fontFamily: 'Inter',
    fontWeight: 400,
    lineHeight: '160%',
    fontSize: '1.5rem',
    letterSpacing: '0.019rem',

    '.recharts-legend-wrapper': {
      right: 0,
    },

    '.legend-wrapper': {
      fontSize: '1.75rem',
      lineHeight: '160%',
      display: 'grid',
      gridTemplateColumns: 'auto',
      padding: 0,
      rowGap: '2rem',
      color: 'neutral.800',
      transform: 'translateX(5rem)',

      li: {
        listStyle: 'none',
        display: 'flex',
        alignItems: 'center',
        columnGap: '1.5rem',

        '.legend-icon': {
          width: '0.75rem',
          height: '1.75rem',
        },
      },
    },
  },

  legend: {
    fontSize: '1.75rem',
    right: 0,
  },
};
