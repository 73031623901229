import PropTypes from 'prop-types';
import React from 'react';
import MuiTableHead from '@mui/material/TableHead';

// project import

import TableRow from '../TableRow';
import TableCell from '../TableCell';
import TableSortLabel from '../TableSortLabel';

const TableHead = ({ columns, sortDirection, sortBy, onSortChange }) => {
  return (
    <MuiTableHead>
      <TableRow>
        {columns.map((column) => (
          <TableCell sticky={column.sticky} key={column.label}>
            <TableSortLabel
              disableSorting={column?.disableSorting}
              active={sortBy === column.fieldName}
              direction={sortBy === column.fieldName ? sortDirection : 'desc'}
              onClick={(event) => onSortChange(event, column.fieldName)}
            >
              {column.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
};

TableHead.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.node).isRequired,
  sortDirection: PropTypes.string,
  sortBy: PropTypes.string,
  onSortChange: PropTypes.func,
};

TableHead.defaultProps = {
  sortDirection: 'desc',
  sortBy: 'id',
  onSortChange: () => {},
};
export default TableHead;
