import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import EmployeeDetailCard from 'components/Organisms/Employee/EmployeeRecord/ActionList/EmployeeDetailCard';
import StartEndDateForm from 'components/Organisms/Employee/EmployeeRecord/ActionList/StartEndDateForm';

const ConcludePIP = ({ label, btnLabel, employeeData, handleSubmit, handleCancel, loading }) => {
  return (
    <>
      <EmployeeDetailCard employeeData={employeeData} />
      <Box mt={2}>
        <StartEndDateForm
          heading="**Please specify any confirmation notes as needed below."
          label={label}
          btnLabel={btnLabel}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          loading={loading}
        />
      </Box>
    </>
  );
};

ConcludePIP.defaultProps = {
  label: 'PIP',
  btnLabel: 'Conclude PIP',
  employeeData: {},
  handleSubmit: () => {},
  handleCancel: () => {},
  loading: false,
};

ConcludePIP.propTypes = {
  label: PropTypes.string,
  btnLabel: PropTypes.string,
  employeeData: PropTypes.shape(),
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  loading: PropTypes.bool,
};

export default ConcludePIP;
