import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Button from 'components/Atoms/Button';
import InputField from 'components/Atoms/InputField';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Typography from 'components/Atoms/Typography';
import Attachments from 'components/Atoms/Attachments';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ToolTip from 'components/Atoms/ToolTip';
import { useTheme } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { profileStyle } from 'components/Organisms/Document/style';

const AddAndEdit = ({ handleSubmit, handleCancel, defaultValues, isEdit, loading, draftLoading, handleDelete }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const dependentSchema = yup.object().shape({
    employerName: yup.string().required('Required'),
    employerTAN: yup.string().required('Required'),
    incomeChargeableUnderHeadSalaries: yup.string().required('Required'),
    exemptionsUnderSection10: yup.string().required('Required'),
    deductionsUnderSection16: yup.string().required('Required'),
    deductionUnderChapterVIA: yup.string().required('Required'),
    netTaxPayable: yup.string().required('Required'),
    netTaxDeducted: yup.string().required('Required'),
    netProfessionalTaxPaid: yup.string().required('Required'),
    netPFContributed: yup.string().required('Required'),
    taxPayableRefundable: yup.string().required('Required'),
    attachments: defaultValues?.attachments === '' && yup.mixed().required('Required'),
  });

  const formik = useFormik({
    initialValues: defaultValues,
    validateOnMount: true,
    validationSchema: dependentSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const theme = useTheme();
  const handleAttachmentCheck = () => {
    if (defaultValues?.attachments === '') {
      setIsSubmitted(true);
    } else {
      setIsSubmitted(false);
    }
  };

  return (
    <Box sx={{ py: 1 }}>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <PerfectScrollbar>
          <Box sx={{ maxHeight: 'calc(100vh - 40.5rem)' }}>
            <Grid container rowSpacing={2} columnSpacing={2} sx={profileStyle.formContainer}>
              <Grid item xs={12} sm={6} md={4}>
                <InputField
                  error={Boolean(formik.touched.employerName && formik.errors.employerName)}
                  helperText={formik.touched.employerName && formik.errors.employerName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  label="Employer Name*"
                  value={formik.values.employerName}
                  name="employerName"
                  id="employerName"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <InputField
                  error={Boolean(formik.touched.employerTAN && formik.errors.employerTAN)}
                  helperText={formik.touched.employerTAN && formik.errors.employerTAN}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  label="Employer TAN*"
                  value={formik.values.employerTAN}
                  name="employerTAN"
                  id="employerTAN"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <InputField
                  error={Boolean(formik.touched.employerAddress && formik.errors.employerAddress)}
                  helperText={formik.touched.employerAddress && formik.errors.employerAddress}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  label="Employer Address"
                  value={formik.values.employerAddress}
                  name="employerAddress"
                  id="employerAddress"
                  fullWidth
                  multiline
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InputField
                  error={Boolean(
                    formik.touched.incomeChargeableUnderHeadSalaries && formik.errors.incomeChargeableUnderHeadSalaries,
                  )}
                  helperText={
                    formik.touched.incomeChargeableUnderHeadSalaries && formik.errors.incomeChargeableUnderHeadSalaries
                  }
                  onChange={formik.handleChange}
                  label="Income Chargeable Under Head Salaries*"
                  onBlur={formik.handleBlur}
                  value={formik.values.incomeChargeableUnderHeadSalaries}
                  name="incomeChargeableUnderHeadSalaries"
                  id="incomeChargeableUnderHeadSalaries"
                  type="number"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <InputField
                  error={Boolean(formik.touched.exemptionsUnderSection10 && formik.errors.exemptionsUnderSection10)}
                  helperText={formik.touched.exemptionsUnderSection10 && formik.errors.exemptionsUnderSection10}
                  onChange={formik.handleChange}
                  label="Exemptions Under Section10*"
                  onBlur={formik.handleBlur}
                  value={formik.values.exemptionsUnderSection10}
                  name="exemptionsUnderSection10"
                  id="exemptionsUnderSection10"
                  type="number"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <InputField
                  error={Boolean(formik.touched.deductionsUnderSection16 && formik.errors.deductionsUnderSection16)}
                  helperText={formik.touched.deductionsUnderSection16 && formik.errors.deductionsUnderSection16}
                  onChange={formik.handleChange}
                  label="Deductions Under Section16*"
                  onBlur={formik.handleBlur}
                  value={formik.values.deductionsUnderSection16}
                  name="deductionsUnderSection16"
                  id="deductionsUnderSection16"
                  type="number"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <InputField
                  error={Boolean(formik.touched.deductionUnderChapterVIA && formik.errors.deductionUnderChapterVIA)}
                  helperText={formik.touched.deductionUnderChapterVIA && formik.errors.deductionUnderChapterVIA}
                  onChange={formik.handleChange}
                  label="Deduction Under Chapter VIA*"
                  onBlur={formik.handleBlur}
                  value={formik.values.deductionUnderChapterVIA}
                  name="deductionUnderChapterVIA"
                  id="deductionUnderChapterVIA"
                  type="number"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <InputField
                  error={Boolean(formik.touched.netTaxPayable && formik.errors.netTaxPayable)}
                  helperText={formik.touched.netTaxPayable && formik.errors.netTaxPayable}
                  onChange={formik.handleChange}
                  label="Net Tax Payable*"
                  onBlur={formik.handleBlur}
                  value={formik.values.netTaxPayable}
                  name="netTaxPayable"
                  id="netTaxPayable"
                  type="number"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <InputField
                  error={Boolean(formik.touched.netTaxDeducted && formik.errors.netTaxDeducted)}
                  helperText={formik.touched.netTaxDeducted && formik.errors.netTaxDeducted}
                  onChange={formik.handleChange}
                  label="Net Tax Deducted*"
                  onBlur={formik.handleBlur}
                  value={formik.values.netTaxDeducted}
                  name="netTaxDeducted"
                  id="netTaxDeducted"
                  type="number"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <InputField
                  error={Boolean(formik.touched.netProfessionalTaxPaid && formik.errors.netProfessionalTaxPaid)}
                  helperText={formik.touched.netProfessionalTaxPaid && formik.errors.netProfessionalTaxPaid}
                  onChange={formik.handleChange}
                  label="Net Professional Tax Paid*"
                  onBlur={formik.handleBlur}
                  value={formik.values.netProfessionalTaxPaid}
                  name="netProfessionalTaxPaid"
                  id="netProfessionalTaxPaid"
                  type="number"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <InputField
                  error={Boolean(formik.touched.netPFContributed && formik.errors.netPFContributed)}
                  helperText={formik.touched.netPFContributed && formik.errors.netPFContributed}
                  onChange={formik.handleChange}
                  label="Net PF Contributed*"
                  onBlur={formik.handleBlur}
                  value={formik.values.netPFContributed}
                  name="netPFContributed"
                  id="netPFContributed"
                  type="number"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <InputField
                  error={Boolean(formik.touched.taxPayableRefundable && formik.errors.taxPayableRefundable)}
                  helperText={formik.touched.taxPayableRefundable && formik.errors.taxPayableRefundable}
                  onChange={formik.handleChange}
                  label="Tax Payable Refundable*"
                  onBlur={formik.handleBlur}
                  value={formik.values.taxPayableRefundable}
                  name="taxPayableRefundable"
                  id="taxPayableRefundable"
                  type="number"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', alignItems: 'start', mb: 0, mt: 2 }}>
              <ToolTip
                tooltipTitle="Attachment"
                title="Please attach applicable proof eg: Form 16, Provisional Form 16, Tax Statement, Payslips. Please attach zip file in case there are multiple files to be attached as proof"
              >
                <HelpOutlineIcon />
              </ToolTip>
              <Attachments
                title="Attachments*"
                isAttachIcon={false}
                isDeleteIcon
                handleChange={(event) => {
                  formik.setFieldValue(`attachments`, event.target.files[0]);
                }}
                name="attachments"
                id="attachments"
                value={formik.values.attachments}
                handleDelete={() => {
                  formik.setFieldValue(`attachments`, '');
                }}
              />
            </Box>
            <Box>
              {!formik.values?.attachments && isSubmitted && (
                <Typography variant="caption" color="red">
                  Please attach a document
                </Typography>
              )}
            </Box>

            {!!isEdit && (
              <Typography variant="caption" color="neutral.600">
                Previously Associated Attachment: {defaultValues?.attachments}
              </Typography>
            )}
          </Box>
        </PerfectScrollbar>

        <Box sx={profileStyle.btnContainer}>
          <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />
          <Box sx={profileStyle.submitWrapper}>
            {!!isEdit && defaultValues?.deleteBtnStatus && (
              <Box sx={{ mr: 'auto' }}>
                <Button
                  size="large"
                  startIcon={<DeleteIcon />}
                  color="error"
                  variant="outlined"
                  type="button"
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              </Box>
            )}
            <Box sx={{ display: 'flex', flex: '1', justifyContent: 'end', gap: 2 }}>
              <Button
                size="large"
                color="info"
                variant="outlined"
                type="button"
                onClick={() => {
                  formik.resetForm();
                  handleCancel();
                }}
              >
                Cancel
              </Button>
              {!isEdit && (
                <LoadingButton
                  size="large"
                  variant="outlined"
                  type="submit"
                  loading={draftLoading}
                  onClick={() => {
                    handleAttachmentCheck();
                    formik.setFieldValue('draft', true);
                  }}
                >
                  Save as Draft
                </LoadingButton>
              )}
              <LoadingButton
                size="large"
                color="primary"
                variant="contained"
                type="submit"
                loading={loading}
                onClick={handleAttachmentCheck}
              >
                {isEdit ? 'Update' : 'Save & Submit'}
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

AddAndEdit.defaultProps = {
  handleSubmit: () => {},
  handleCancel: () => {},
  defaultValues: {},
  isEdit: false,
  handleDelete: () => {},
  loading: false,
  draftLoading: false,
};

AddAndEdit.propTypes = {
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  defaultValues: PropTypes.shape,
  isEdit: PropTypes.bool,
  handleDelete: PropTypes.func,
  loading: PropTypes.bool,
  draftLoading: PropTypes.bool,
};

export default AddAndEdit;
