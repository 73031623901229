import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider } from '@mui/material';
import TitleWithText from 'components/Atoms/TitleWithText';
import Typographys from 'components/Atoms/Typography';

const BasicChallanDetails = ({ challanDeatils }) => {
  return (
    <>
      <Typographys variant="h6" color="text.300">
        Challan Details: {challanDeatils.challanType}
      </Typographys>
      <Grid container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Challan Type" text={challanDeatils.challanType} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Payroll Calendar" text={challanDeatils.payrollCalendar} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Pay Period" text={challanDeatils.payPeriod} />
        </Grid>
      </Grid>
      <Divider sx={{ my: '2rem' }} />
      <Typographys variant="h6" color="text.300">
        Bank Challan Details
      </Typographys>
      <Grid container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Period Start Date" text={challanDeatils.periodStartDate} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Period End Date" text={challanDeatils.periodEndDate} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Amount Due" text={challanDeatils.amountDue} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Amount Paid" text={challanDeatils.amountPaid} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Date Of Payment" text={challanDeatils.dateOfPayment} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Mode Of Payment" text={challanDeatils.modeOfPayment} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Bank Name" text={challanDeatils.bankName} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Branch Name" text={challanDeatils.branchName} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Branch Code" text={challanDeatils.branchCode} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Paid By Person" text={challanDeatils.paidByPerson} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Payment Transaction Reference" text={challanDeatils.paymentTransactionReference} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Challan Code" text={challanDeatils.challanCode} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TitleWithText title="Description" text={challanDeatils.description} />
        </Grid>
      </Grid>
    </>
  );
};

BasicChallanDetails.defaultProps = {
  challanDeatils: {
    challanType: 'Male',
    payrollCalendar: 'UnMarried',
    payPeriod: '07/06/1960',
    periodStartDate: '04/06/2017',
    periodEndDate: 'Full Time',
    amountDue: 'Vidyasagar',
    amountPaid: 'Snehalatha',
    dateOfPayment: '-',
    modeOfPayment: 'Mumbai',
    bankName: 'AIVpDUAFTA',
    branchName: '123456789128',
    branchCode: '100002345878886',
    paidByPerson: 'AJHTUH5654TH',
    paymentTransactionReference: '34567124552',
    challanCode: '6787659223',
    description: '890898723239',
  },
};

BasicChallanDetails.propTypes = {
  challanDeatils: PropTypes.shape({
    challanType: PropTypes.string,
    payrollCalendar: PropTypes.string,
    payPeriod: PropTypes.string,
    periodStartDate: PropTypes.string,
    periodEndDate: PropTypes.string,
    amountDue: PropTypes.string,
    amountPaid: PropTypes.string,
    dateOfPayment: PropTypes.string,
    modeOfPayment: PropTypes.string,
    bankName: PropTypes.string,
    branchName: PropTypes.string,
    branchCode: PropTypes.string,
    paidByPerson: PropTypes.string,
    paymentTransactionReference: PropTypes.string,
    challanCode: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default BasicChallanDetails;
