import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Typographys from '../Typography';
import { ChooseFileStyle } from './style';

const ChooseFile = ({ handleChange, showNoFileTitle, value, name, id }) => {
  return (
    <Box sx={ChooseFileStyle.wrapper}>
      <Button size="small" variant="outlined" color="info" sx={ChooseFileStyle.attachFile}>
        <Typographys variant="subtitle2" color="primary.300" align="center">
          Choose File
        </Typographys>
        <Box sx={ChooseFileStyle.attachInput}>
          <input
            name={name}
            id={id}
            type="file"
            accept="all/*"
            multiple
            onChange={handleChange}
            style={{ cursor: 'pointer', opacity: 0 }}
          />
        </Box>
      </Button>
      {value?.name ? (
        <Typographys variant="body2">{value?.name}</Typographys>
      ) : (
        showNoFileTitle && <Typographys variant="body2">No file chosen</Typographys>
      )}
    </Box>
  );
};
ChooseFile.defaultProps = {
  handleChange: () => {},
  value: '',
  name: '',
  id: '',
  showNoFileTitle: false,
};
ChooseFile.propTypes = {
  handleChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  showNoFileTitle: PropTypes.bool,
};
export default ChooseFile;
