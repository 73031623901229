import { useState } from 'react';
import useToast from 'components/Provider/useToast';
import { myDocumentsDelete, myDocumentPublish, myDocumentUnPublish } from 'api/documents';
import get from 'lodash/get';
import { useNavigate } from 'react-router-dom';
import fileDownload from 'js-file-download';

const useMyDocumentService = () => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [refreshDocumentList, setRefreshDocumentList] = useState(false);
  const addToast = useToast();
  const navigate = useNavigate();

  const viewDocument = (row) => {
    const id = get(row, 'id', '');
    navigate(`/company/documents/view/${id}`);
  };

  const downloadDocument = (row) => {
    fileDownload(new Blob([row?.documentURL]), row?.fileName);
  };

  const editDocument = (row) => {
    const id = get(row, 'id', '');
    navigate(`/company/documents/edit/${id}`);
  };

  const handleDeleteDialog = () => {
    setShowDeleteDialog(!showDeleteDialog);
  };

  const deleteDocument = async (row) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', get(row, 'id', ''));
      const response = await myDocumentsDelete(bodyFormData);
      if (response.status === 'SUCCESS') {
        addToast({ type: 'success', title: 'SUCCESS', message: response?.message });
        setRefreshDocumentList(!refreshDocumentList);
      } else {
        addToast({ type: 'error', title: 'ERROR', message: response?.message });
      }
    } catch (error) {
      addToast({ type: 'error', title: 'ERROR', message: error?.message });
    } finally {
      handleDeleteDialog(false);
    }
  };

  const handlePublishDocument = async (row) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', get(row, 'id', ''));
      const response = await myDocumentPublish(bodyFormData);
      if (response.status !== 'ERROR') {
        addToast({ type: 'success', title: 'SUCCESS', message: response?.message });
        setRefreshDocumentList(!refreshDocumentList);
      }
    } catch (error) {
      addToast({ type: 'error', title: 'ERROR', message: error?.message });
    }
  };

  const handleUnPublishDocument = async (row) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', get(row, 'id', ''));
      const response = await myDocumentUnPublish(bodyFormData);
      if (response.status !== 'ERROR') {
        addToast({ type: 'success', title: 'SUCCESS', message: response?.message });
        setRefreshDocumentList(!refreshDocumentList);
      }
    } catch (error) {
      addToast({ type: 'error', title: 'ERROR', message: error?.message });
    }
  };

  return {
    deleteDocument,
    showDeleteDialog,
    handleDeleteDialog,
    refreshDocumentList,
    handlePublishDocument,
    handleUnPublishDocument,
    viewDocument,
    editDocument,
    downloadDocument,
  };
};

export default useMyDocumentService;
