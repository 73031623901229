import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import AddReport from 'components/Organisms/MyExpenses/AllReports/AddReport';
import useToast from 'components/Provider/useToast';
import { getToastMessage } from 'utils/toastMessage';
import { Box, Skeleton } from '@mui/material';

const AddReportTemplate = ({
  pageHeading,
  expenseReportId,
  initialValues,
  totalExpensedAmount,
  reimbursableAmount,
  expenseCategoryOptions,
  currencyOptions,
  paymentMethodOptions,
  handleNewExpenseRecord,
  handleDelete,
  handleAddProject,
  handleDeleteProject,
  handleAttachments,
  projectsOptions,
  merchantOptions,
  handleCancel,
  handleDraft,
  handleSubmit,
  toastSuccessMessage,
  toastErrorMessage,
  showToast,
  toastType,
  isEdit,
  loading,
  breadcrumbData,
  formLoading,
  draftLoading,
}) => {
  const [toastMessageType, setToastMessageType] = useState('');
  const addToast = useToast();

  useEffect(() => {
    if (showToast) {
      const successMessage = toastSuccessMessage || getToastMessage.MyExpenses(isEdit, toastMessageType)[0];
      const error = toastErrorMessage || getToastMessage.MyExpenses(isEdit, toastMessageType)[1];

      const title = toastType === 'success' ? 'Success!' : 'Error!';
      const type = toastType === 'success' ? 'success' : 'error';
      const message = toastType === 'success' ? successMessage : error;
      addToast({ type, title, message });
    }
  }, [showToast, toastErrorMessage, toastSuccessMessage, toastType]);

  return (
    <BlankTemplate pageHeading={pageHeading} isBreadcrumb breadcrumbData={breadcrumbData} padding="2rem 3rem">
      {formLoading ? (
        <Box sx={{ p: 4 }}>
          <Skeleton height={42} />
          <Skeleton />
          <Skeleton />
        </Box>
      ) : (
        <AddReport
          expenseReportId={expenseReportId}
          initialValues={initialValues}
          totalExpensedAmount={totalExpensedAmount}
          reimbursableAmount={reimbursableAmount}
          expenseCategoryOptions={expenseCategoryOptions}
          currencyOptions={currencyOptions}
          paymentMethodOptions={paymentMethodOptions}
          handleNewExpenseRecord={handleNewExpenseRecord}
          handleDelete={handleDelete}
          handleAddProject={handleAddProject}
          handleDeleteProject={handleDeleteProject}
          handleAttachments={handleAttachments}
          projectsOptions={projectsOptions}
          merchantOptions={merchantOptions}
          handleCancel={handleCancel}
          handleSubmit={(data) => {
            setToastMessageType('');
            handleSubmit(data);
          }}
          handleDraft={(data) => {
            setToastMessageType('draft');
            handleDraft(data);
          }}
          isEdit={isEdit}
          loading={loading}
          draftLoading={draftLoading}
        />
      )}
    </BlankTemplate>
  );
};

AddReportTemplate.defaultProps = {
  pageHeading: '',
  expenseReportId: 31,
  initialValues: {},
  totalExpensedAmount: 0,
  reimbursableAmount: 0,
  expenseCategoryOptions: [],
  currencyOptions: [],
  paymentMethodOptions: [],
  handleNewExpenseRecord: () => {},
  handleDelete: () => {},
  handleAddProject: () => {},
  handleDeleteProject: () => {},
  handleAttachments: () => {},
  projectsOptions: [],
  merchantOptions: [],
  handleCancel: () => {},
  handleDraft: () => {},
  handleSubmit: () => {},
  toastSuccessMessage: '',
  toastErrorMessage: '',
  showToast: false,
  toastType: 'success',
  isEdit: false,
  loading: false,
  breadcrumbData: [],
  formLoading: false,
  draftLoading: false,
};

AddReportTemplate.propTypes = {
  pageHeading: PropTypes.string,
  expenseReportId: PropTypes.number,
  initialValues: PropTypes.shape({
    reportName: PropTypes.string,
    businessPurpose: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    expenseRecord: PropTypes.arrayOf(
      PropTypes.shape({
        allocations: PropTypes.arrayOf(PropTypes.shape()),
      }),
    ),
  }),
  totalExpensedAmount: PropTypes.number,
  reimbursableAmount: PropTypes.number,
  expenseCategoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  currencyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  paymentMethodOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  handleNewExpenseRecord: PropTypes.func,
  handleDelete: PropTypes.func,
  handleAddProject: PropTypes.func,
  handleDeleteProject: PropTypes.func,
  handleAttachments: PropTypes.func,
  projectsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  merchantOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  handleCancel: PropTypes.func,
  handleDraft: PropTypes.func,
  toastSuccessMessage: PropTypes.string,
  toastErrorMessage: PropTypes.string,
  showToast: PropTypes.bool,
  toastType: PropTypes.oneOf(['error', 'success']),
  handleSubmit: PropTypes.func,
  isEdit: PropTypes.bool,
  loading: PropTypes.bool,
  breadcrumbData: PropTypes.arrayOf,
  formLoading: PropTypes.bool,
  draftLoading: PropTypes.bool,
};

export default AddReportTemplate;
