import React, { useRef } from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import PropTypes from 'prop-types';
import Form12BB from 'components/Organisms/MyPay/Form12BB';
import { useReactToPrint } from 'react-to-print';

const Form12BBTemplate = ({ employeeData, claimsDetailsData, landlordInfo }) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Form_12BB',
  });

  return (
    <BlankTemplate pageHeading="Form 12BB" isBreadcrumb={false} padding="2rem 3rem">
      <Form12BB
        employeeData={employeeData}
        claimsDetailsData={claimsDetailsData}
        landlordInfo={landlordInfo}
        ref={componentRef}
        handlePrint={handlePrint}
      />
    </BlankTemplate>
  );
};

Form12BBTemplate.defaultProps = {
  employeeData: {},
  claimsDetailsData: {},
  landlordInfo: [],
};

Form12BBTemplate.propTypes = {
  employeeData: {},
  claimsDetailsData: PropTypes.shape,
  landlordInfo: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
  ),
};

export default Form12BBTemplate;
