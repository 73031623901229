import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import ProfileEditEducationalHistory from 'components/Organisms/Profile/EducationalHistory/Edit';
import useToast from 'components/Provider/useToast';
import { getToastMessage } from 'utils/toastMessage';

const ProfileEditEducationalHistoryTemplate = ({
  handleSubmit,
  handleCancel,
  defaultValue,
  toastSuccessMessage,
  toastErrorMessage,
  showToast,
  toastType,
  loading,
}) => {
  const breadcrumbData = [
    { id: 1, name: 'Educational History', path: '/profile/education-history', isActive: false },
    { id: 2, name: 'Edit', isActive: true },
  ];

  const addToast = useToast();

  useEffect(() => {
    if (showToast) {
      const successMessage = toastSuccessMessage || getToastMessage.ProfileEducationHistory()[0];
      const error = toastErrorMessage || getToastMessage.ProfileEducationHistory()[1];

      const title = toastType === 'success' ? 'Success!' : 'Error!';
      const type = toastType === 'success' ? 'success' : 'error';
      const message = toastType === 'success' ? successMessage : error;
      addToast({ type, title, message });
    }
  }, [showToast, toastErrorMessage, toastSuccessMessage, toastType]);

  return (
    <BlankTemplate
      pageHeading="Edit Education History"
      isBreadcrumb
      breadcrumbData={breadcrumbData}
      padding="0 3rem 2rem 3rem"
    >
      <ProfileEditEducationalHistory
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        defaultValue={defaultValue}
        loading={loading}
      />
    </BlankTemplate>
  );
};

ProfileEditEducationalHistoryTemplate.defaultProps = {
  handleSubmit: () => {},
  handleCancel: () => {},
  defaultValue: {
    education: [
      {
        institutionName: '',
        certificationName: '',
        startDate: '',
        endDate: '',
        specialization: '',
        registrationNumber: '',
        referenceName: '',
        referenceNumber: '',
        referenceEmail: '',
        comments: '',
        attachment: '',
      },
    ],
  },
  toastSuccessMessage: '',
  toastErrorMessage: '',
  showToast: false,
  toastType: 'success',
  loading: false,
};

ProfileEditEducationalHistoryTemplate.propTypes = {
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  defaultValue: PropTypes.shape({}),
  toastSuccessMessage: PropTypes.string,
  toastErrorMessage: PropTypes.string,
  showToast: PropTypes.bool,
  toastType: PropTypes.oneOf(['error', 'success']),
  loading: PropTypes.bool,
};

export default ProfileEditEducationalHistoryTemplate;
