import React from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import QueryDetails from 'components/Organisms/TaskAndQueries/QueryDetails';

const QueryDetailsTemplate = ({
  breadcrumbData,
  reportDetails,
  handleEdit,
  actionMenuList,
  isRecall,
  handleRecall,
  handleAttachmentDownload,
  showEdit,
  auditLogData,
}) => {
  return (
    <BlankTemplate pageHeading={false} breadcrumbData={breadcrumbData} padding="2rem 3rem">
      <QueryDetails
        reportDetails={reportDetails}
        handleEdit={handleEdit}
        actionMenuList={actionMenuList}
        isRecall={isRecall}
        handleRecall={handleRecall}
        handleAttachmentDownload={handleAttachmentDownload}
        showEdit={showEdit}
        auditLogData={auditLogData}
      />
    </BlankTemplate>
  );
};

QueryDetailsTemplate.defaultProps = {
  breadcrumbData: [
    { id: 1, name: 'My Queries', path: '/home/queries', isActive: false },
    { id: 2, name: 'Query Details', isActive: true },
  ],
  reportDetails: {
    queryNumber: '',
    title: '',
    resolutionStatus: '',
    openedBy: '',
    closedBy: '',
    openDate: '',
    closeDate: '',
    submissionDate: '',
    currentOwner: '',
    category: '',
    topic: '',
    description: '',
    attachments: '',
  },
  handleEdit: () => {},
  actionMenuList: [],
  isRecall: false,
  handleRecall: () => {},
  handleAttachmentDownload: () => {},
  showEdit: true,
  auditLogData: [],
};

QueryDetailsTemplate.propTypes = {
  breadcrumbData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
  reportDetails: PropTypes.shape({
    queryNumber: PropTypes.string,
    title: PropTypes.string,
    resolutionStatus: PropTypes.string,
    openedBy: PropTypes.string,
    closedBy: PropTypes.string,
    openDate: PropTypes.string,
    closeDate: PropTypes.string,
    submissionDate: PropTypes.string,
    currentOwner: PropTypes.string,
    category: PropTypes.string,
    topic: PropTypes.string,
    description: PropTypes.string,
    attachments: PropTypes.string,
  }),
  handleEdit: PropTypes.func,
  actionMenuList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  isRecall: PropTypes.bool,
  handleRecall: PropTypes.func,
  handleAttachmentDownload: PropTypes.func,
  showEdit: PropTypes.bool,
  auditLogData: PropTypes.arrayOf(
    PropTypes.shape({
      avatarSrc: PropTypes.string,
      name: PropTypes.string,
      date: PropTypes.string,
    }),
  ),
};

export default QueryDetailsTemplate;
