import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import TitleWithText from 'components/Atoms/TitleWithText';
import Typographys from 'components/Atoms/Typography';
import InfoIcon from '@mui/icons-material/Info';
import ToolTip from 'components/Atoms/ToolTip';
import CheckWithTitle from 'components/Atoms/CheckWithTitle';
import { payOutStyle } from './style';

const PayrollSetting = ({ payrollSetting }) => {
  return (
    <Box sx={{ paddingLeft: '24px' }}>
      <Grid container spacing={1.5} md={10}>
        <Grid item xs={12} sm={5} lg={5} xl={4}>
          <CheckWithTitle
            iconColor="secondary.600"
            iconFontSize="3rem"
            columnSpacing={1.5}
            title="Is Disable"
            titleVariant="subtitle1"
            titleColor="neutral.700"
            isChecked={payrollSetting.isDisable}
          />
          <TitleWithText title="Number of School going Children" text={payrollSetting.numOfChildInSchool} />
        </Grid>
        <Grid item xs={12} sm={5} lg={5} xl={4}>
          <CheckWithTitle
            iconColor="secondary.600"
            iconFontSize="3rem"
            columnSpacing={1.5}
            title="Is International Worker"
            titleVariant="subtitle1"
            titleColor="neutral.700"
            isChecked={payrollSetting.isInternationalWorker}
          />
          <TitleWithText title="Number of Children in Hostel" text={payrollSetting.numOfChildInSchool} />
        </Grid>
      </Grid>

      <Box sx={payOutStyle.separator1}>
        <Typographys variant="subtitle1" color="neutral.600">
          EPF, ESI, PT & LWF Applicability
        </Typographys>
        <Box sx={payOutStyle.tooltip}>
          <ToolTip placement="top" title="This section pertains to the Employee’s EPF, ESI, PT and LWF settings.">
            <InfoIcon fontSize="2rem" />
          </ToolTip>
        </Box>
      </Box>
      <Grid container rowSpacing={3} columnSpacing={2} lg={12} xl={10} pl={1.5}>
        <Grid item xs={12} sm={6} md={3}>
          <CheckWithTitle
            iconColor="secondary.600"
            iconFontSize="3rem"
            columnSpacing={1.5}
            title="Is Eligible For EPF"
            titleVariant="body2"
            titleColor="neutral.700"
            isChecked={payrollSetting.isEligibleEPF}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CheckWithTitle
            iconColor="secondary.600"
            iconFontSize="3rem"
            columnSpacing={1.5}
            title="Is Eligible For ESI"
            titleVariant="body2"
            titleColor="neutral.700"
            isChecked={payrollSetting.isEligibleESI}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CheckWithTitle
            iconColor="secondary.600"
            iconFontSize="3rem"
            columnSpacing={1.5}
            title="Is Eligible For EPS"
            titleVariant="body2"
            isChecked={payrollSetting.isEligibleEPS}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CheckWithTitle
            iconColor="secondary.600"
            iconFontSize="3rem"
            columnSpacing={1.5}
            title="Is Eligible For EDLI"
            titleVariant="body2"
            titleColor="neutral.700"
            isChecked={payrollSetting.isEligibleEDLI}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <CheckWithTitle
            iconColor="secondary.600"
            iconFontSize="3rem"
            columnSpacing={1.5}
            title="Is Professional Tax Applicable"
            titleVariant="body2"
            isChecked={payrollSetting.isEligibleProfessionalTax}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <CheckWithTitle
            iconColor="secondary.600"
            iconFontSize="3rem"
            columnSpacing={1.5}
            title="Is Labour Welfare Fund Contributions Applicable"
            iconTextAlign="flex-start"
            titleVariant="body2"
            titleColor="neutral.700"
            isChecked={payrollSetting.isEligibleLabourWelfareFund}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CheckWithTitle
            iconColor="secondary.600"
            iconFontSize="3rem"
            columnSpacing={1.5}
            title="Is Located in Metro?"
            titleVariant="body2"
            titleColor="neutral.700"
            isChecked={payrollSetting.isLocatedInMetro}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <CheckWithTitle
            iconColor="secondary.600"
            iconFontSize="3rem"
            columnSpacing={1.5}
            title="Has Joined EPF Prior to 1st Sept 2014?"
            titleVariant="body2"
            titleColor="neutral.700"
            isChecked={payrollSetting.isInternationalWorker}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1.5} md={10} mt={2.5}>
        <Grid item xs={12} sm={6} md={4} display="flex">
          <Box sx={payOutStyle.textBox}>
            <TitleWithText
              title="Default Professional Tax Jurisdiction"
              text={payrollSetting.professionalTaxJurisdiction}
            />
          </Box>
          <Box sx={payOutStyle.tooltip}>
            <ToolTip placement="top" title="The state where Professional Tax  is applicable for the employee">
              <InfoIcon fontSize="2rem" />
            </ToolTip>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4} display="flex">
          <Box sx={payOutStyle.textBox}>
            <TitleWithText
              title="Default Labour Welfare Fund Jurisdiction"
              text={payrollSetting.labourWelfareFundJurisdiction}
            />
          </Box>
          <Box sx={payOutStyle.tooltip}>
            <ToolTip placement="top" title="The state where LWF is applicable for the employee">
              <InfoIcon fontSize="2rem" />
            </ToolTip>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4} display="flex">
          <Box sx={payOutStyle.textBox}>
            <TitleWithText title="ESI Dispensary" text={payrollSetting.esiDispensary} />
          </Box>
          <Box sx={payOutStyle.tooltip}>
            <ToolTip placement="top" title="My Tooltip Content">
              <InfoIcon fontSize="2rem" />
            </ToolTip>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4} display="flex">
          <Box sx={payOutStyle.textBox}>
            <TitleWithText title="Date of Enrolling in EPF" text={payrollSetting.dateEnrollingEPF} />
          </Box>
          <Box sx={payOutStyle.tooltip}>
            <ToolTip placement="top" title="The date when the employee got enrolled in the EPF scheme">
              <InfoIcon fontSize="2rem" />
            </ToolTip>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4} display="flex">
          <Box sx={payOutStyle.textBox}>
            <TitleWithText title="Employee EPF Contribution Level" text={payrollSetting.epfContribution} />
          </Box>
          <Box sx={payOutStyle.tooltip}>
            <ToolTip
              placement="top"
              title="The Employee's EPF contribution can be:  1) Statutorily mandated level of 12% of EPF Gross, 
              2) Above the mandated 12% of EPF Gross, 3) Upto Max Threshold Limit, 4) None"
            >
              <InfoIcon fontSize="2rem" />
            </ToolTip>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4} display="flex">
          <Box sx={payOutStyle.textBox}>
            <TitleWithText title="Date of Leaving EPF" text={payrollSetting.dolEPF} />
          </Box>
          <Box sx={payOutStyle.tooltip}>
            <ToolTip
              placement="top"
              title="The date when the employee left the EPF scheme (usually the last working day of the employee)"
            >
              <InfoIcon fontSize="2rem" />
            </ToolTip>
          </Box>
        </Grid>
      </Grid>

      <Box sx={payOutStyle.separator2}>
        <Typographys variant="subtitle1" color="neutral.600">
          PAN Validation Status & Flat TDS Applicability
        </Typographys>
        <Box sx={payOutStyle.tooltip}>
          <ToolTip
            placement="top"
            title="This section pertains to the Employee’s PAN, Its Validation Status, 
            date it was validated and by whom. In addition, you can also specify if this employee is to be 
            treated differently (Flat TDS applicability) for Income Tax computations."
          >
            <InfoIcon fontSize="2rem" />
          </ToolTip>
        </Box>
      </Box>
      <Grid container spacing={1.5} lg={9} xl={7} pl={1.5}>
        <Grid item xs={12} sm={5} display="flex">
          <CheckWithTitle
            iconColor="secondary.600"
            iconFontSize="3rem"
            columnSpacing={1.5}
            title="Is PAN Valid?*"
            titleVariant="subtitle1"
            titleColor="neutral.700"
            isChecked={payrollSetting.isPANValid}
          />
          <Box sx={payOutStyle.tooltip}>
            <ToolTip
              placement="top"
              title="Specify if the PAN provided by Employee  is Valid. If yes, select this checkbox, 
              else  leave it unchecked."
            >
              <InfoIcon fontSize="2rem" />
            </ToolTip>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} display="flex">
          <CheckWithTitle
            iconColor="secondary.600"
            iconFontSize="3rem"
            columnSpacing={1.5}
            title="Is PAN Validated with IT Department?*"
            titleVariant="subtitle1"
            titleColor="neutral.700"
            isChecked={payrollSetting.isPANValidatedWithITDepartment}
          />
          <Box sx={payOutStyle.tooltip}>
            <ToolTip
              placement="top"
              title="Select this in case the PAN number of the employee has been validated with the Income 
              Tax Department"
            >
              <InfoIcon fontSize="2rem" />
            </ToolTip>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={5} display="flex">
          <Box sx={payOutStyle.textBox}>
            <TitleWithText title="PAN Validated By" text={payrollSetting.panValidatedBy} />
          </Box>
          <Box sx={payOutStyle.tooltip}>
            <ToolTip placement="top" title="Specify the employee who validated this employee PAN">
              <InfoIcon fontSize="2rem" />
            </ToolTip>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={5} display="flex">
          <Box sx={payOutStyle.textBox}>
            <TitleWithText title="Date When PAN Was Validated" text={payrollSetting.panValidateDate} />
          </Box>
          <Box sx={payOutStyle.tooltip}>
            <ToolTip placement="top" title="The date when the employee PAN was validated">
              <InfoIcon fontSize="2rem" />
            </ToolTip>
          </Box>
        </Grid>
        <Grid item xs={12} display="flex">
          <CheckWithTitle
            iconColor="secondary.600"
            iconFontSize="3rem"
            columnSpacing={1.5}
            title="Is Employee To Be Taxed At Flat TDS Rate"
            titleVariant="subtitle1"
            titleColor="neutral.700"
            isChecked={payrollSetting.isEmployeeTaxedTDSRate}
          />
          <Box sx={payOutStyle.tooltip}>
            <ToolTip
              placement="top"
              title="Select this in case the employee is eligible for Flat TDS rate. This is valid only for  
              specific cases. If unsure about applicability of flat TDS, please leave this unchecked"
            >
              <InfoIcon fontSize="2rem" />
            </ToolTip>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={5} display="flex">
          <Box sx={payOutStyle.textBox}>
            <TitleWithText title="Rate of Flat TDS" text={payrollSetting.tds} />
          </Box>
          <Box sx={payOutStyle.tooltip}>
            <ToolTip placement="top" title="Specify the rate at which Flat TDS is to be applied for this employee">
              <InfoIcon fontSize="2rem" />
            </ToolTip>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={5} display="flex">
          <Box sx={payOutStyle.textBox}>
            <TitleWithText title="Flat TDS Computation Basis" text={payrollSetting.panFlat} />
          </Box>
          <Box sx={payOutStyle.tooltip}>
            <ToolTip
              placement="top"
              title="Flat TDS can be computed either on the basis of actual earnings during each period or 
              on the basis of projected earnings for the rest of the financial year as well."
            >
              <InfoIcon fontSize="2rem" />
            </ToolTip>
          </Box>
        </Grid>
      </Grid>

      <Box sx={payOutStyle.separator3}>
        <Typographys variant="subtitle1" color="neutral.600">
          Company Salary & Expense Reimbursement Account
        </Typographys>
        <Box sx={payOutStyle.tooltip}>
          <ToolTip
            placement="top"
            title="These are the mapped Company Bank accounts for salaries disbursal and expense reimbursements."
          >
            <InfoIcon fontSize="2rem" />
          </ToolTip>
        </Box>
      </Box>
      <Grid container spacing={1.5} lg={9} xl={7}>
        <Grid item xs={12} sm={6} md={6} display="flex">
          <Box sx={payOutStyle.textBox}>
            <TitleWithText title="Company Salary Account" text={payrollSetting.companySAccount} />
          </Box>
          <Box sx={payOutStyle.tooltip}>
            <ToolTip placement="top" title="The Company Bank Account from which this employee's Salary will be paid.">
              <InfoIcon fontSize="2rem" />
            </ToolTip>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} display="flex">
          <Box sx={payOutStyle.textBox}>
            <TitleWithText title="Expense Reimbursement A/c" text={payrollSetting.expenseReimbursementAc} />
          </Box>
          <Box sx={payOutStyle.tooltip}>
            <ToolTip
              placement="top"
              title="The Company Bank Account from which this employee's Expense Reimbursements will be paid."
            >
              <InfoIcon fontSize="2rem" />
            </ToolTip>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

PayrollSetting.defaultProps = {
  payrollSetting: {
    isDisable: false,
    isInternationalWorker: true,
    isEligibleEPF: false,
    isEligibleESI: true,
    isEligibleEPS: true,
    isEligibleEDLI: false,
    isEligibleProfessionalTax: true,
    isEligibleLabourWelfareFund: true,
    isLocatedInMetro: false,
    isJoinedEPFSept2014: true,
    numOfChildInSchool: '-',
    numOfChildInHostel: 2,
    professionalTaxJurisdiction: 'Karnataka',
    labourWelfareFundJurisdiction: 'Karnataka',
    esiDispensary: '-',
    dateEnrollingEPF: '24/May/2023',
    epfContribution: 'Partially',
    dolEPF: 'NA',
    isPANValid: false,
    isPANValidatedWithITDepartment: true,
    panValidatedBy: 'Aachaman Kabir',
    panValidateDate: '24/May/2023',
    isEmployeeTaxedTDSRate: false,
    tds: 2,
    panFlat: '24/May/2023',
    companySAccount: 'Default (Kotak Mahindra Bank)',
    expenseReimbursementAc: 'Default (Kotak Mahindra Bank)',
  },
};

PayrollSetting.propTypes = {
  payrollSetting: PropTypes.shape({
    isDisable: PropTypes.bool,
    isInternationalWorker: PropTypes.bool,
    isEligibleEPF: PropTypes.bool,
    isEligibleESI: PropTypes.bool,
    isEligibleEPS: PropTypes.bool,
    isEligibleEDLI: PropTypes.bool,
    isEligibleProfessionalTax: PropTypes.bool,
    isEligibleLabourWelfareFund: PropTypes.bool,
    isLocatedInMetro: PropTypes.bool,
    isJoinedEPFSept2014: PropTypes.bool,
    numOfChildInSchool: PropTypes.number,
    numOfChildInHostel: PropTypes.number,
    professionalTaxJurisdiction: PropTypes.string,
    labourWelfareFundJurisdiction: PropTypes.string,
    esiDispensary: PropTypes.string,
    dateEnrollingEPF: PropTypes.string,
    epfContribution: PropTypes.string,
    isPANValid: PropTypes.bool,
    isPANValidatedWithITDepartment: PropTypes.bool,
    dolEPF: PropTypes.string,
    panValidatedBy: PropTypes.string,
    panValidateDate: PropTypes.string,
    isEmployeeTaxedTDSRate: PropTypes.bool,
    tds: PropTypes.number,
    panFlat: PropTypes.string,
    companySAccount: PropTypes.string,
    expenseReimbursementAc: PropTypes.string,
  }),
};

export default PayrollSetting;
