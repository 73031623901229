import React from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import ProfileEducationalHistory from 'components/Organisms/Profile/EducationalHistory';

const ProfileEducationalHistoryTemplate = ({ educationHistory, handleEdit, handleAttachment }) => {
  return (
    <BlankTemplate pageHeading="Educational History" isBreadcrumb={false} padding="2rem 3rem">
      <ProfileEducationalHistory
        educationHistory={educationHistory}
        handleEdit={handleEdit}
        handleAttachment={handleAttachment}
      />
    </BlankTemplate>
  );
};

ProfileEducationalHistoryTemplate.defaultProps = {
  educationHistory: [],
  handleEdit: () => {},
  handleAttachment: () => {},
};

ProfileEducationalHistoryTemplate.propTypes = {
  educationHistory: PropTypes.arrayOf,
  handleEdit: PropTypes.func,
  handleAttachment: PropTypes.func,
};

export default ProfileEducationalHistoryTemplate;
