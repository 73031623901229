import { formDataApi } from 'api/api';

export const visaDetailsData = async () => {
  const response = await formDataApi.get('restEmpIndex/visaDetails');
  return response?.data;
};

export const getProfileBankDetails = async () => {
  const response = await formDataApi.get(`restEmpIndex/bankAccounts`);
  return response;
};

export const skillDetailsData = async () => {
  const response = await formDataApi.get('restEmpIndex/skillAttainmentDetails');
  return response;
};

export const getemploymentHistoryDetails = async () => {
  const response = await formDataApi.get(`restEmpIndex/employmentHistory`);
  return response;
};

export const updateEmploymentHistory = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/updateEmploymentHistoryAction`, formData);
  return response;
};

export const getProfileAddresses = async () => {
  const response = await formDataApi.get(`restEmpIndex/address`);
  return response;
};

export const addEditProfileAddresses = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/updateAddressAction`, formData);
  return response;
};

// restEmpIndex/updateAddressAction

export const getEducationHistoryDetails = async () => {
  const response = await formDataApi.get(`restEmpIndex/educationalHistory`);
  return response;
};

export const updateEducationHistoryDetails = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/UpdateEducationalHistoryAction`, formData);
  return response;
};

export const getDependentDetails = async () => {
  const response = await formDataApi.get(`restEmpIndex/dependents`);
  return response;
};

export const getBasicprofileDetails = async () => {
  const response = await formDataApi.get(`restEmpIndex/profile`);
  return response;
};

export const addEditBasicProfileDetails = async (formData) => {
  const response = await formDataApi.post(`restEmpIndex/updateProfileAction`, formData);
  return response;
};

// Profile > Modify Password
export const changeMyPasswordAction = async (args) => {
  const body = new FormData();
  body.append('newPasswd', args.newPasswd);
  body.append('newPasswd2', args.newPasswd2);
  const response = await formDataApi.post('restEmpIndex/changeMyPasswordAction', body);
  return response?.data;
};

// Profile > Initiate Separation
export const getMyExitDetails = async (args) => {
  const response = await formDataApi.get(`restEmpIndex/initiateMyExit`);
  return response?.data;
};

export const saveMyExitDetails = async (body) => {
  const response = await formDataApi.post(`restEmpIndex/saveMyExit`, body);
  return response?.data;
};

export const modifyEmpExitDetails = async (body) => {
  const response = await formDataApi.put(`restEmpIndex/modifyEmployeeExitAction`, body);
  return response?.data;
};

export const saveEmpExit = async (body) => {
  const response = await formDataApi.post(`restEmpIndex/saveEmpExit`, body);
  return response?.data;
};

export const updatelifeeventDetailsData = async () => {
  const response = await formDataApi.get('restEmpIndex/updateEventDetails');
  return response?.data;
};

export const lifeeventDetailsData = async () => {
  const response = await formDataApi.get('restEmpIndex/eventDetails');
  return response?.data;
};

export const myExitDetailsData = async () => {
  const response = await formDataApi.get('restEmpIndex/displayMyExit');
  return response?.data;
};

export const myExitWithdrawDetails = async () => {
  const response = await formDataApi.get('restEmpIndex/withDrawMyExit');
  return response?.data;
};

export const modifyEmployeeExitDetails = async (body) => {
  const response = await formDataApi.post('restEmpIndex/modifyEmployeeExit', body);
  return response?.data;
};
