import React from 'react';
import PropTypes from 'prop-types';
import MuiButton from '@mui/material/Button';

const Button = ({ color, href, startIcon, endIcon, onClick, children, isDisable, size, variant, fullWidth, type }) => {
  return (
    <MuiButton
      size={size}
      variant={variant}
      disabled={isDisable}
      startIcon={startIcon}
      endIcon={endIcon}
      fullWidth={fullWidth}
      href={href}
      color={color}
      onClick={onClick}
      type={type}
    >
      {children}
    </MuiButton>
  );
};

Button.defaultProps = {
  startIcon: '',
  endIcon: '',
  href: '',
  variant: 'contained',
  isDisable: false,
  children: 'Button Text',
  fullWidth: false,
  onClick: () => {},
  size: 'medium',
  color: 'primary',
  type: '',
};

Button.propTypes = {
  children: PropTypes.node,
  size: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  isDisable: PropTypes.bool,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  fullWidth: PropTypes.bool,
  href: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
};

export default Button;
