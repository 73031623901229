import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { actionListStyle } from 'components/Molecules/ActionList/style';
import Typographys from 'components/Atoms/Typography';
import { Box } from '@mui/material';

const ActionDropDown = ({ ariaLabel, options, handleSelect, label, labelColor, iconColor }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [actionLabel, setActionLabel] = useState(label);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    handleSelect();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (option) => {
    handleClose();
    setActionLabel(option.name);
    option.onClick(option);
  };

  useEffect(() => {
    setActionLabel(label);
  }, [label]);

  return (
    <Box>
      <Box
        sx={{ display: 'flex', alignItems: 'center', gap: 0.5, cursor: 'pointer' }}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Typographys variant="caption" fontWeight={500} color={labelColor}>
          {actionLabel}
        </Typographys>
        <KeyboardArrowDownIcon fontSize="small" sx={{ color: iconColor }} />
      </Box>

      <Menu
        MenuListProps={{ 'aria-labelledby': { ariaLabel } }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={actionListStyle.wrapper}
      >
        {options &&
          options.map((option) => {
            return option?.formatValue ? (
              <MenuItem key={option.name}>{option.formatValue()}</MenuItem>
            ) : (
              <MenuItem key={option.name} onClick={() => handleMenuItemClick(option)}>
                {option.name}
              </MenuItem>
            );
          })}
      </Menu>
    </Box>
  );
};

ActionDropDown.defaultProps = {
  ariaLabel: 'Action Menu',
  options: [],
  handleSelect: () => {},
  label: 'Dropdown',
  labelColor: 'secondary.600',
  iconColor: 'secondary.600',
};

ActionDropDown.propTypes = {
  handleSelect: PropTypes.func,
  ariaLabel: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      onClick: PropTypes.func,
      formatValue: PropTypes.func,
    }),
  ),
  label: PropTypes.string,
  labelColor: PropTypes.string,
  iconColor: PropTypes.string,
};

export default ActionDropDown;
