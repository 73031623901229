export const onBoardingStyle = {
  wrapper: {
    mt: '2rem',
    overflowX: 'hidden',
  },

  dformDivider: {
    '&:not(:first-child)': {
      borderTop: '0.125rem solid',
      borderColor: 'other.200',
      mt: 4,
      pt: 3,
    },
  },
  addressDiv: {
    display: 'inline',
    mr: '0.5rem',
  },

  formContainer: {
    width: '100%',
    maxWidth: '100%',
    mt: 0,
    mb: 2.5,
  },

  btnContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%',
    backgroundColor: 'common.white',
    zIndex: '9',
    margin: '0rem',
    padding: '0rem 3rem 2rem 3rem',
    boxSizing: 'border-box',
  },

  submitWrapper: {
    display: 'flex',
    justifyContent: 'end',
    gap: 2,
  },

  listWrapper: {
    '& .MuiListItem-root': {
      paddingLeft: '1.5rem',
      margin: '0.5rem 0.75rem',
      '&:after': {
        content: "''",
        position: 'absolute',
        left: '0',
        top: '1.5rem',
        height: '5px',
        width: '5px',
        opacity: 1,
        borderRadius: '1rem',
        backgroundColor: 'neutral.600',
      },
    },
  },

  dependentDivider: {
    '&:not(:first-child)': {
      borderTop: '0.125rem solid',
      borderColor: 'other.200',
      mt: 2.5,
      pt: 2.5,
    },
  },

  rowDivider: {
    '.MuiGrid-container:not(:first-child)': {
      borderTop: '0.125rem solid',
      borderColor: 'other.200',
      mt: 3,
      pt: 2,
    },
  },

  editWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    my: 2,
    px: 3,
  },

  divider: {
    borderColor: 'other.200',
    my: 2.5,
  },

  accordionWrapper: {
    '&.MuiAccordion-root': {
      borderBottom: '0.125rem solid',
      borderColor: 'other.200',

      '&:before': {
        border: 'none',
      },
    },

    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)',
    },

    '& .MuiAccordionSummary-content': {
      margin: '0',
      padding: '0 1.5rem',
    },

    '& .MuiAccordionSummary-root': {
      flexDirection: 'row-reverse',
      padding: '2rem 0',
      minHeight: 'auto',
    },

    '& .MuiAccordionDetails-root': {
      padding: '0 0rem',
    },
  },

  deleteContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },

  contactWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 1.5,
    mb: '1.5rem',
  },

  deleteBox: {
    cursor: 'pointer',
    height: '3rem',
    width: '3rem',
  },
};
