import { useSelector } from 'react-redux';
import { hasPermission } from 'utils/permissions';

const getActionItems = (services, row) => {
  return [
    {
      label: 'Delete',
      onClick: () => services.handleOpenDialog('Delete'),
      icon: '',
      color: 'error.400',
      permissions: ['ROLE_BASE_PERMISSION_LEAVE_INDEX_DELETE_LEAVE_REQUEST'],
      conditional: (item) => item?.lockStatus === 'UnLocked' && item?.requestState === 'Draft',
    },
    {
      label: 'Submit',
      onClick: () => services.handleOpenDialog('Submit'),
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_LEAVE_INDEX_SUBMIT_LEAVE_REQUEST'],
      conditional: (item) => item?.requestState === 'Draft',
    },
    {
      label: 'Cancel',
      onClick: () => services.handleOpenDialog('Cancel'),
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_LEAVE_INDEX_SUBMIT_LEAVE_REQUEST'],
      conditional: (item) => item?.requestState === 'Pending Approval' || item?.requestState === 'Approved',
    },
    {
      label: 'Edit',
      onClick: () => services.modifyLeave(row),
      icon: '',
      permissions: ['ROLE_BASE_PERMISSION_LEAVE_INDEX_UPDATE_LEAVE_REQUEST'],
      conditional: (item) => item?.lockStatus === 'UnLocked' && item?.requestState === 'Draft',
    },
  ];
};

const useMenuItems = (row, services) => {
  const actions = getActionItems(services, row);
  const authorities = useSelector((state) => state.session.user.authorities);

  const moreFilteredActionItems = actions.filter((action) => {
    const userHasPermission = hasPermission(authorities, action.permissions);
    if ((action.conditional && !action.conditional(row)) || !userHasPermission) {
      return false;
    }
    return true;
  });
  return [moreFilteredActionItems];
};

export default useMenuItems;
