import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { validateDateElseHyphen } from 'utils/commonUtils';
import Typographys from 'components/Atoms/Typography';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Button from 'components/Atoms/Button';
import EditIcon from '@mui/icons-material/Edit';
import TitleWithText from 'components/Atoms/TitleWithText';
import DropdownMenu from 'components/Molecules/DropdownMenu';

const LeaveRequestDetails = ({ leaveDetails, handleEdit, actionMenuList, handleAttachment, isEdit }) => {
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'end', gap: 1 }}>
        {!isEdit && (
          <Button startIcon={<EditIcon />} onClick={handleEdit}>
            Edit
          </Button>
        )}
        {!!actionMenuList?.length > 0 && <DropdownMenu btnLabel="Actions" size="medium" menuLists={actionMenuList} />}
      </Box>

      <Grid container columnSpacing={9}>
        <Grid item xs={12} md={4}>
          <TitleWithText title="Employee ID" text={leaveDetails.employeeId} />
        </Grid>
        <Grid item xs={12} md={4}>
          <TitleWithText title="Employee" text={leaveDetails.employeeName} />
        </Grid>
        <Grid item xs={12} md={4}>
          <TitleWithText title="Leave Category" text={leaveDetails.leaveCategory} />
        </Grid>
        <Grid item xs={12} md={4}>
          <TitleWithText title="Leave Type" text={leaveDetails.leaveType} />
        </Grid>{' '}
        <Grid item xs={12} md={4}>
          <TitleWithText title="Start Date" text={validateDateElseHyphen(leaveDetails.startDate, 'DD MMMM YYYY')} />
        </Grid>{' '}
        <Grid item xs={12} md={4}>
          <TitleWithText title="End Date" text={validateDateElseHyphen(leaveDetails.endDate, 'DD MMMM YYYY')} />
        </Grid>{' '}
        <Grid item xs={12} md={4}>
          <TitleWithText title="Number Of Days" text={leaveDetails.numberOfDays} />
        </Grid>{' '}
        <Grid item xs={12} md={4}>
          <TitleWithText title="Number Of Hours" text={leaveDetails.numberOfHours} />
        </Grid>{' '}
        <Grid item xs={12} md={4}>
          <TitleWithText
            title="Created On"
            text={validateDateElseHyphen(leaveDetails.createdOn, 'DD MMMM, YYYY h:mm A')}
          />
        </Grid>{' '}
        <Grid item xs={12} md={4}>
          <TitleWithText title="Created By" text={leaveDetails.createdBy} />
        </Grid>{' '}
        <Grid item xs={12} md={4}>
          <TitleWithText
            title="Last Modified On"
            text={validateDateElseHyphen(leaveDetails.lastModifiedOn, 'DD MMMM, YYYY h:mm A')}
          />
        </Grid>{' '}
        <Grid item xs={12} md={4}>
          <TitleWithText title="Last Modified By" text={leaveDetails.lastModifiedBy} />
        </Grid>{' '}
        <Grid item xs={12} md={4}>
          <TitleWithText title="Approved By" text={leaveDetails.approvedBy} />
        </Grid>{' '}
        <Grid item xs={12} md={4}>
          <TitleWithText
            title="Approval Date"
            text={validateDateElseHyphen(leaveDetails.approvalDate, 'DD MMMM, YYYY h:mm A')}
          />
        </Grid>{' '}
        <Grid item xs={12} md={4}>
          <TitleWithText title="Request State" text={leaveDetails.requestState} />
        </Grid>{' '}
        <Grid item xs={12} md={12}>
          <TitleWithText title="Description" text={leaveDetails.description} />
        </Grid>
        <Grid item xs={12} md={12}>
          <TitleWithText title="Review Comments" text={leaveDetails.reviewComments} />
        </Grid>
        <Grid item xs={12} mt={3} mb={1}>
          <Typographys variant="h6" color="neutral.800">
            Attachment Proof
          </Typographys>
          <Button
            variant="text"
            color="secondary"
            onClick={() => handleAttachment(leaveDetails.attachmentId, leaveDetails.attachment)}
            startIcon={<AttachFileIcon />}
          >
            {leaveDetails.attachment || ''}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

LeaveRequestDetails.defaultProps = {
  leaveDetails: {
    employeeId: '',
    employeeName: '',
    leaveCategory: '',
    leaveType: '',
    startDate: '',
    endDate: '',
    numberOfDays: '',
    numberOfHours: '',
    createdOn: '',
    createdBy: '',
    lastModifiedOn: '',
    lastModifiedBy: '',
    approvalDate: '',
    approvedBy: '',
    requestState: '',
    description: '    ',
    reviewComments: '',
    attachment: '',
  },
  handleEdit: () => {},
  actionMenuList: [],
  handleAttachment: () => {},
  isEdit: true,
};

LeaveRequestDetails.propTypes = {
  leaveDetails: PropTypes.shape({
    employeeId: PropTypes.string,
    employeeName: PropTypes.string,
    leaveCategory: PropTypes.string,
    leaveType: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    numberOfDays: PropTypes.string,
    numberOfHours: PropTypes.string,
    createdOn: PropTypes.string,
    createdBy: PropTypes.string,
    lastModifiedOn: PropTypes.string,
    lastModifiedBy: PropTypes.string,
    approvalDate: PropTypes.string,
    approvedBy: PropTypes.string,
    requestState: PropTypes.string,
    description: PropTypes.string,
    reviewComments: PropTypes.string,
    attachment: PropTypes.string,
  }),
  handleEdit: PropTypes.func,
  actionMenuList: PropTypes.arrayOf,
  handleAttachment: PropTypes.func,
  isEdit: PropTypes.bool,
};

export default LeaveRequestDetails;
