const useFilters = (filtersData, optionsData) => {
  const { filters = {} } = filtersData;
  const filterFields = [
    {
      name: 'Document Type',
      filterName: 'docType',
      type: 'dropdown',
      options: optionsData?.length ? optionsData?.map((data) => ({ name: data.name, id: data.name })) : [],
      value: filters?.docType,
    },
    {
      name: 'Document Name',
      filterName: 'docName',
      type: 'string',
      value: filters?.docName,
    },
    {
      name: 'Creation Start Date',
      filterName: 'startDate',
      type: 'date',
      value: filters?.startDate,
    },
    {
      name: 'Creation End Date',
      filterName: 'endDate',
      type: 'date',
      value: filters?.endDate,
    },
  ];

  return { filterFields };
};

export default useFilters;
