import dayjs from 'dayjs';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { hasPermission } from 'utils/permissions';

export const genericMobileRegex = /^(?:(?:\+|\d{1,4}[\s-]?)?(?:\(\d{1,}\)|\d{1,4})[\s-]?)?(\d{10,})$/;
export const genericEmailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const aadhaarRegex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
export const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

export const validateInputValue = (val, maxLength) => {
  if (!val || val.length === 0) {
    return true;
  }
  return val.length === maxLength;
};

export const validatePANNumber = (panNumber, required) => {
  if (required && !panNumber) {
    return new yup.ValidationError('Required', 'panNumber');
  }
  const validateLength = validateInputValue(panNumber, 10);
  if (!validateLength) {
    return new yup.ValidationError('PAN Number length should be 10', 'panNumber');
  }

  const isValidFormat = panRegex.test(panNumber);

  if (!isValidFormat) {
    return new yup.ValidationError('Invalid format, PAN should be in the format ABCTY1234D', 'panNumber');
  }
  return panNumber;
};

export const validateAadhaarNumber = (aadhaarNumber, required) => {
  if (required && !aadhaarNumber) {
    return new yup.ValidationError('Required', 'aadhaarNumber');
  }
  const validateLength = validateInputValue(aadhaarNumber, 12);
  if (!validateLength) {
    return new yup.ValidationError('Aadhaar Number length should be 12');
  }

  if (!/^\d+$/.test(aadhaarNumber)) {
    return new yup.ValidationError('Aadhaar Number should be numeric');
  }

  const isValidFormat = aadhaarRegex.test(aadhaarNumber);

  if (!isValidFormat) {
    return new yup.ValidationError('Invalid format, Please enter valid 12 digit Aadhaar number');
  }
  return aadhaarNumber;
};

export const validateUanNumber = (uanNumber) => {
  const validateLength = validateInputValue(uanNumber, 12);
  if (!validateLength) {
    return new yup.ValidationError('UAN Number length should be 12');
  }
  return uanNumber;
};

export const validateUHIDNumber = (uhidNumber) => {
  const validateLength = validateInputValue(uhidNumber, 14);
  if (!validateLength) {
    return new yup.ValidationError('UHID Number length should be 14');
  }

  if (!/^\d+$/.test(uhidNumber)) {
    return new yup.ValidationError('UHID Number should be numeric');
  }
  return uhidNumber;
};

export const validateElseHyphen = (val) => {
  if (val) {
    return val;
  }
  return '-';
};

export const validateDateElseHyphen = (val, format = 'MM/DD/YYYY') => {
  if (val) {
    return dayjs(val).format(format);
  }
  return '-';
};

export const validateNumberElseHyphen = (input) => {
  if ((!input && input === 0) || input) {
    return Number(input).toLocaleString('en-IN');
  }
  return '-';
};

export const dateFormat = (val, format = 'MM/DD/YYYY') => {
  if (val) {
    return dayjs(val).format(format);
  }
  return '';
};

export const commonFilteredActionItems = (authorities, actions, row) => {
  const moreFilteredActionItems = actions.filter((action) => {
    const userHasPermission = hasPermission(authorities, action?.permissions);
    if ((action?.conditional && !action?.conditional(row)) || !userHasPermission) {
      return false;
    }
    return true;
  });
  return moreFilteredActionItems;
};
