import React, { useEffect } from 'react';
import { Box, Grid, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import Button from 'components/Atoms/Button';
import Typographys from 'components/Atoms/Typography';
import InputField from 'components/Atoms/InputField/index';
import { useFormik } from 'formik';
import { useTheme } from '@mui/material/styles';
import { addEmpStyle } from '../../style';

const SocialData = ({ tabHeading, handleSubmit, handleCancel, propData, label, updateBtnText }) => {
  const defaultValues = propData.allTabsData?.['Social Data'] || {
    skype: '',
    facebook: '',
    linkedin: '',
    googlePlus: '',
    twitter: '',
    instagram: '',
  };

  const formik = useFormik({
    initialValues: defaultValues,

    validateOnMount: true,
    onSubmit: handleSubmit,
  });
  useEffect(() => {
    propData.updateFormData(formik.values, label);
  }, [formik.values, label, propData]);

  const theme = useTheme();
  return (
    <Box mt={1.5}>
      <Typographys variant="h5" color="neutral.800">
        {tabHeading}
      </Typographys>

      <Box mt={2} component="form" onSubmit={formik.handleSubmit}>
        <Grid container rowSpacing={2} columnSpacing={9} mt={0} sx={addEmpStyle.formContainer}>
          <Grid item xs={12} md={6}>
            <InputField
              error={Boolean(formik.touched.skype && formik.errors.skype)}
              helperText={formik.touched.skype && formik.errors.skype}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.skype}
              name="skype"
              id="skype"
              label="Skype ID"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <InputField
              error={Boolean(formik.touched.facebook && formik.errors.facebook)}
              helperText={formik.touched.facebook && formik.errors.facebook}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.facebook}
              name="facebook"
              id="facebook"
              label="Facebook ID"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <InputField
              error={Boolean(formik.touched.linkedin && formik.errors.linkedin)}
              helperText={formik.touched.linkedin && formik.errors.linkedin}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.linkedin}
              name="linkedin"
              id="linkedin"
              label="Linkedin ID"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <InputField
              error={Boolean(formik.touched.googlePlus && formik.errors.googlePlus)}
              helperText={formik.touched.googlePlus && formik.errors.googlePlus}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.googlePlus}
              name="googlePlus"
              id="googlePlus"
              label="GooglePlus ID"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <InputField
              error={Boolean(formik.touched.twitter && formik.errors.twitter)}
              helperText={formik.touched.twitter && formik.errors.twitter}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.twitter}
              name="twitter"
              id="twitter"
              label="Twitter ID"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <InputField
              error={Boolean(formik.touched.instagram && formik.errors.instagram)}
              helperText={formik.touched.instagram && formik.errors.instagram}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.instagram}
              name="instagram"
              id="instagram"
              label="Instagram ID"
              fullWidth
            />
          </Grid>
        </Grid>

        <Box sx={addEmpStyle.btnContainer}>
          <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

          <Box sx={addEmpStyle.submitWrapper}>
            <Button
              size="large"
              color="info"
              variant="outlined"
              type="cancel"
              onClick={() => {
                formik.resetForm();
                handleCancel();
              }}
            >
              Cancel
            </Button>

            <Button size="large" type="submit" isDisable={false}>
              {propData.allowEdit ? updateBtnText : 'Next'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

SocialData.defaultProps = {
  tabHeading: 'Social Data',
  propData: {},
  handleSubmit: () => {},
  handleCancel: () => {},
  label: 'Social Data',
  updateBtnText: 'Update',
};

SocialData.propTypes = {
  tabHeading: PropTypes.string,
  propData: PropTypes.shape({
    updateFormData: PropTypes.func,
    allTabsData: PropTypes.shape({
      'Social Data': PropTypes.shape({}),
    }),
    allowEdit: PropTypes.bool,
  }),
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  label: PropTypes.string,
  updateBtnText: PropTypes.string,
};

export default SocialData;
