import React from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import PropTypes from 'prop-types';
import Reportee from 'components/Organisms/Reportee';

const ReporteeTemplate = ({
  reporteeList,
  loading,
  handleReporteeClick,
  handleUpwardHierarchyClick,
  breadcrumbData,
  headingName,
}) => {
  return (
    <BlankTemplate padding="0rem" pageHeading={`Reportees - ${headingName}`} breadcrumbData={breadcrumbData}>
      <Reportee
        reporteeList={reporteeList}
        loading={loading}
        handleReporteeClick={handleReporteeClick}
        handleUpwardHierarchyClick={handleUpwardHierarchyClick}
      />
    </BlankTemplate>
  );
};

ReporteeTemplate.defaultProps = {
  reporteeList: {},
  loading: false,
  handleReporteeClick: () => {},
  handleUpwardHierarchyClick: () => {},
  breadcrumbData: [],
  headingName: '',
};

ReporteeTemplate.propTypes = {
  reporteeList: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  handleReporteeClick: PropTypes.func,
  handleUpwardHierarchyClick: PropTypes.func,
  breadcrumbData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
  headingName: PropTypes.string,
};

export default ReporteeTemplate;
