import React from 'react';
import PropTypes from 'prop-types';
import { Box, List, ListItem, ListItemText } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import { chartStyle } from './style';

const CustomLegend = ({
  payload,
  layout,
  isLegendTitle,
  legendTitle,
  iconType,
  iconWidth,
  iconHeight,
  legendLeftSpacing,
  isLegendValue,
  legendItemWidth,
  isLegendBottom,
}) => (
  <Box sx={{ position: 'relative', bottom: isLegendBottom ? '-3rem' : '0' }}>
    <Box sx={chartStyle.chartLegendWrapper} ml={`${layout === 'vertical' ? legendLeftSpacing : '0'}`}>
      {isLegendTitle && (
        <Box mb={2}>
          <Typographys variant="subtitle2" color="neutral.800">
            {legendTitle}
          </Typographys>
        </Box>
      )}
      <List
        sx={{
          ...chartStyle.legendList,
          flexDirection: layout === 'vertical' ? 'column' : 'row',
        }}
        disablePadding
      >
        {payload.map((entry) => (
          <ListItem disablePadding sx={{ width: legendItemWidth }}>
            <ListItemText
              key={entry}
              primary={
                <>
                  <Box
                    sx={{
                      ...chartStyle.legendIcon,
                      borderRadius: iconType === 'circle' ? '50%' : 0,
                      width: iconWidth,
                      height: iconHeight,
                      backgroundColor: entry.color,
                    }}
                    component="span"
                  />
                  <Box sx={chartStyle.legendLabel} component="span">
                    {entry.payload.name}
                  </Box>
                  {isLegendValue && (
                    <Box sx={chartStyle.legendValue} component="span">
                      {entry.payload.value}
                    </Box>
                  )}
                </>
              }
            />
          </ListItem>
        ))}
      </List>
    </Box>
  </Box>
);

CustomLegend.defaultProps = {
  isLegendValue: false,
  payload: [],
  layout: 'horizontal',
  isLegendTitle: false,
  legendTitle: '',
  iconType: 'circle',
  iconWidth: '12px',
  iconHeight: '12px',
  legendLeftSpacing: '5rem',
  legendItemWidth: 'auto',
  isLegendBottom: false,
};

CustomLegend.propTypes = {
  isLegendValue: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number,
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  layout: PropTypes.string,
  isLegendTitle: PropTypes.bool,
  legendTitle: PropTypes.string,
  iconType: PropTypes.string,
  iconWidth: PropTypes.string,
  iconHeight: PropTypes.string,
  legendLeftSpacing: PropTypes.string,
  legendItemWidth: PropTypes.string,
  isLegendBottom: PropTypes.bool,
};

export default CustomLegend;
