const columns = [
  {
    fieldName: 'filterType',
    name: '',
    label: 'Filter Type',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'filterSubType',
    name: '',
    label: 'Filter Sub Type',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'allowedLocations',
    name: '',
    label: 'Allowed Entities',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'status',
    name: '',
    label: 'Status',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'createdOn',
    name: '',
    label: 'Created On',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'createdBy',
    name: '',
    label: 'Created By',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'lastModifiedOn',
    name: '',
    label: 'Last Modified On',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'lastModifiedBy',
    name: '',
    label: 'Last Modified  By',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
];

const useColumns = () => {
  return [columns];
};

export default useColumns;
