import { useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useToast from 'components/Provider/useToast';
import useDownloadFile from 'hooks/useDownloadFile';
import { validateNumberElseHyphen } from 'utils/commonUtils';
import { API_URL } from 'api/api';
import {
  submitReportForReview,
  deleteExpenseReportAction,
  myOrgExpenseReports,
  myActionableExpenseReports,
  actionExpenseReport,
  actionExpenseReportApproveReject,
  viewExpenseReportAction,
} from 'api/expenses';
import { ObjectToFormData } from 'utils/ObjectToFormData';

const initialState = {
  open: false,
  selectedTab: 0,
  listExpensesReport: {
    loading: false,
    data: {},
    error: null,
  },
  expenseReportDetails: {},
  isApprove: false,
  approveRejectComment: '',
};

const formatExpenseReportDetails = (response) => {
  if (!response.data) {
    return {};
  }
  const getActionCreatorDetails = (comment) => {
    const commentsActionCreatorInstance =
      response?.data?.commentsMap &&
      Object.keys(response?.data?.commentsMap)?.find((item) => item?.includes(comment?.id));

    return {
      fullName: response?.data?.commentsMap?.[`${commentsActionCreatorInstance}`]?.fullName || '',
      avatarSrc: `${API_URL}/${response?.data?.commentsMap?.[`${commentsActionCreatorInstance}`]?.profile?.profilePic}`,
    };
  };

  const getApproverDetails = (comment) => {
    const approverInstance =
      response?.data?.approverActionsMap &&
      Object.keys(response?.data?.approverActionsMap)?.find((item) => item?.includes(comment?.id));

    return {
      fullName: response?.data?.approverActionsMap?.[`${approverInstance}`]?.fullName || '',
      avatarSrc: `${API_URL}/${response?.data?.approverActionsMap?.[`${approverInstance}`]?.profile?.profilePic}`,
    };
  };
  const {
    employeeExpenseReportInstance = {},
    supportingDocuments = [],
    commentsMapList = [],
    approverActionsMapList = [],
    defaultCurrency = {},
    department = {},
    designation = {},
    location = {},
    supervisor = {},
  } = response.data;
  return {
    id: employeeExpenseReportInstance?.id,
    reportDetails: {
      title: employeeExpenseReportInstance?.displayName || '-',
      address: employeeExpenseReportInstance?.company?.companyName || '-',
      employeeName: employeeExpenseReportInstance?.employee?.fullName || '-',
      employeeId: employeeExpenseReportInstance?.employee?.profile?.employeeID || '-',
      expensePeriodStartDate: employeeExpenseReportInstance?.startDate || '-',
      expensePeriodEndDate: employeeExpenseReportInstance?.endDate || '-',
      department: department?.name || '-',
      designation: designation?.name || '-',
      reportingManager: supervisor?.fullName || '-',
      location: location?.name || '-',
      businessPurpose: employeeExpenseReportInstance?.businessPurpose || '-',
      reportId: employeeExpenseReportInstance?.expenseReportID || '-',
      reportStatus: employeeExpenseReportInstance?.reportStatus || '-',
      settlementStatus: employeeExpenseReportInstance?.settlementStatus || '-',
    },
    rows: employeeExpenseReportInstance?.expenseRecords || [],
    amountData: [
      {
        label: 'Total Expensed Amount',
        amount: `${defaultCurrency?.symbol} ${validateNumberElseHyphen(
          employeeExpenseReportInstance?.totalExpensedAmount,
        )}`,
      },
      {
        label: 'Reimbursable Amount',
        amount: `${defaultCurrency?.symbol} ${validateNumberElseHyphen(
          employeeExpenseReportInstance?.totalAmountToBeReimbursed,
        )}`,
      },
      {
        label: 'Approved Amount',
        amount: `${defaultCurrency?.symbol} ${validateNumberElseHyphen(
          employeeExpenseReportInstance?.totalApprovedAmount,
        )}`,
      },
    ],
    supportingDocuments: supportingDocuments || [],
    auditData: {
      auditTrailsList: commentsMapList?.map((item) => ({
        avatarSrc: getActionCreatorDetails(item)?.avatarSrc,
        description: getActionCreatorDetails(item)?.fullName,
        content: item?.content?.split('<br />'),
        date: item?.createdOn,
      })),
      approvalAuditTrailsList: approverActionsMapList?.map((item) => ({
        avatarSrc: getApproverDetails(item)?.avatarSrc,
        description: getApproverDetails(item)?.fullName,
        content: item?.actionComments.split('<br />'),
        date: item?.actionedOn,
      })),
    },
    reportStatus: employeeExpenseReportInstance?.reportStatus,
    employee: { id: employeeExpenseReportInstance?.employee?.id },
    entityOrigin: employeeExpenseReportInstance?.entityOrigin,
  };
};

const formatExpenseReportAction = (response) => {
  if (!response?.data) {
    return {};
  }
  const { expenseReportInstance = {} } = response.data;
  return {
    id: expenseReportInstance?.id,
    reportDetails: {
      title: expenseReportInstance?.displayName,
      address: '',
      employeeName: expenseReportInstance?.employee?.fullName,
      employeeId: expenseReportInstance?.employee?.id,
      expensePeriodStartDate: expenseReportInstance?.startDate,
      expensePeriodEndDate: expenseReportInstance?.endDate,
      department: '',
      designation: '',
      reportingManager: '',
      location: '',
      businessPurpose: expenseReportInstance?.businessPurpose,
      reportId: expenseReportInstance?.expenseReportID,
      reportStatus: expenseReportInstance?.reportStatus,
      settlementStatus: expenseReportInstance?.settlementStatus,
      currency: expenseReportInstance?.settlementCurrency?.symbol || '₹',
    },
    rows: expenseReportInstance?.expenseRecords || [],
    totalClaimAmount: validateNumberElseHyphen(expenseReportInstance?.totalExpensedAmount),
    totalApprovedAmount: expenseReportInstance?.totalExpensedAmount || 0,
  };
};

const reducer = (state, action) => {
  const { type, payload } = action;
  let newState;
  switch (type) {
    case 'FETCH_EXPENSE_REPORT_PENDING': {
      newState = { ...state, listExpensesReport: { loading: true } };
      break;
    }
    case 'FETCH_EXPENSE_REPORT': {
      newState = { ...state, listExpensesReport: { ...state.listExpensesReport, loading: false, data: payload } };
      break;
    }
    case 'FETCH_EXPENSE_REPORT_REJECT': {
      newState = { ...state, listExpensesReport: { ...state.listExpensesReport, loading: false, error: payload } };
      break;
    }
    case 'UPDATE_SELECTED_TAB': {
      newState = { ...state, selectedTab: payload };
      break;
    }
    case 'OPEN_CLOSE_DIALOG': {
      newState = { ...state, open: payload };
      break;
    }
    case 'SET_APPROVE_REJECT_COMMENT': {
      newState = { ...state, approveRejectComment: payload };
      break;
    }
    case 'SET_IS_APPROVE': {
      newState = { ...state, isApprove: payload };
      break;
    }
    case 'SET_EXPENSE_REPORT_DETAILS': {
      newState = { ...state, expenseReportDetails: payload };
      break;
    }
    case 'REFRESH_PAGE': {
      newState = { ...state, refreshPage: payload };
      break;
    }
    default: {
      newState = state;
    }
  }
  return newState;
};

const useServices = () => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const addToast = useToast();
  const downloadFile = useDownloadFile();

  const handleDeleteDialog = () => {
    setShowDeleteDialog(!showDeleteDialog);
  };

  const listNavigation = () => {
    navigate(`/myteam/expenses`);
  };
  const addExpenseReport = () => {
    navigate(`/myteam/expenses/add`);
  };
  const viewExpenseReport = (row) => {
    navigate(`/myteam/expenses/${row.id}/view`);
  };

  const modifyExpenseReport = (row) => {
    navigate(`/myteam/expenses/${row.id}/edit`);
  };

  const actionNavigation = (row) => {
    navigate(`/myteam/expenses/action/${row.id}`);
  };

  const deleteExpenseReport = async (row) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', row?.id);
      const response = await deleteExpenseReportAction(bodyFormData);
      if (response.status === 200 && response.data) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message,
        });
        if (row?.view) {
          navigate(-1);
        } else {
          dispatch({ type: 'REFRESH_PAGE', payload: !state?.refreshPage });
        }
      }
    } catch (error) {
      addToast({ type: 'error', title: 'ERROR', message: error?.message });
    } finally {
      handleDeleteDialog(false);
    }
  };

  const submitExpenseReport = async (row) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', row.id);
      const response = await submitReportForReview(bodyFormData);
      if (response.status === 200 && response.data) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message,
        });
        dispatch({ type: 'REFRESH_PAGE', payload: !state?.refreshPage });
      }
    } catch (error) {
      addToast({ type: 'error', title: 'ERROR', message: error?.message });
    }
  };

  const fetchMyOrgExpenseReports = async (query) => {
    try {
      dispatch({ type: 'FETCH_EXPENSE_REPORT_PENDING', payload: null });
      const response = await myOrgExpenseReports(query);
      if (response.status === 200 && response.data) {
        dispatch({ type: 'FETCH_EXPENSE_REPORT', payload: response.data });
      }
    } catch (error) {
      dispatch({ type: 'FETCH_EXPENSE_REPORT_REJECT', payload: error });
      addToast({ type: 'error', title: 'ERROR', message: error?.message });
    }
  };

  const fetchUpdatedMyOrgExpenseReports = async (updatedList) => {
    try {
      dispatch({ type: 'FETCH_EXPENSE_REPORT_PENDING', payload: null });
      if (updatedList) {
        dispatch({ type: 'FETCH_EXPENSE_REPORT', payload: updatedList });
      }
    } catch (error) {
      dispatch({ type: 'FETCH_EXPENSE_REPORT_REJECT', payload: error });
      addToast({ type: 'error', title: 'ERROR', message: error?.message });
    }
  };

  const fetchMyActionableExpenseReports = async (query) => {
    try {
      dispatch({ type: 'FETCH_EXPENSE_REPORT_PENDING', payload: null });
      const response = await myActionableExpenseReports(query);
      if (response.status === 200 && response.data) {
        dispatch({ type: 'FETCH_EXPENSE_REPORT', payload: response.data });
      }
    } catch (error) {
      dispatch({ type: 'FETCH_EXPENSE_REPORT_REJECT', payload: error });
      addToast({ type: 'error', title: 'ERROR', message: error?.message });
    }
  };

  const handleSelectedReport = (selectedReport) => {
    dispatch({ type: 'SET_EXPENSE_REPORT_DETAILS', payload: selectedReport });
  };

  const onChangeApproveRejectComment = (event) => {
    dispatch({ type: 'SET_APPROVE_REJECT_COMMENT', payload: event.target.value });
  };
  const approveRejectExpenseReport = (open, isApprove, row) => {
    handleSelectedReport(row);
    dispatch({ type: 'OPEN_CLOSE_DIALOG', payload: open });
    dispatch({ type: 'SET_IS_APPROVE', payload: isApprove });
  };

  const fetchExpenseReportAction = async (id) => {
    try {
      dispatch({ type: 'SET_EXPENSE_REPORT_DETAILS', payload: { isLoading: true } });
      const bodyFormData = new FormData();
      bodyFormData.append('id', id);
      const response = await actionExpenseReport(bodyFormData);
      if (response.status === 200 && response.data) {
        const formattedResponse = formatExpenseReportAction(response?.data);
        dispatch({ type: 'SET_EXPENSE_REPORT_DETAILS', payload: formattedResponse });
      }
    } catch (error) {
      dispatch({ type: 'SET_EXPENSE_REPORT_DETAILS', payload: { isLoading: false } });
      addToast({ type: 'error', title: 'ERROR', message: error?.message });
    }
  };

  const handleActionSubmit = async (values) => {
    try {
      const body = {
        id: state?.expenseReportDetails?.id,
        version: 1,
        actType: values?.actionType,
        totalApprovedAmount: values?.totalApprovedAmount,
        finalApprovalNotes: values?.approvalNotes,
        entryRowsCtr: values?.expenseRecord?.length || 0,
      };
      (values?.expenseRecord || []).forEach((item, index) => {
        body[`expenseRecords[${index}].expenseCategory`] = item?.expenseCategoryId;
        body[`expenseRecords[${index}].expensedAmount`] = item?.expensedAmount;
        body[`expenseRecords[${item?.id}].approvedAmount`] = item?.approvedAmount;
        body[`expenseRecords[${item?.id}].approvalNotes`] = item?.approvalNotes;
      });
      const bodyFormData = ObjectToFormData(body);
      const response = await actionExpenseReportApproveReject(bodyFormData);
      if (response.status === 200 && response.data) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message,
        });
        listNavigation();
      } else {
        addToast({ type: 'error', title: 'ERROR', message: response?.data?.message });
      }
    } catch (error) {
      addToast({ type: 'error', title: 'ERROR', message: error?.response?.data?.message });
    } finally {
      dispatch({ type: 'OPEN_CLOSE_DIALOG', payload: false });
    }
  };

  const fetchExpenseReportDetails = async (id) => {
    try {
      dispatch({ type: 'SET_EXPENSE_REPORT_DETAILS', payload: { isLoading: true } });
      const bodyFormData = new FormData();
      bodyFormData.append('id', id);
      const response = await viewExpenseReportAction(bodyFormData);
      if (response.status === 200 && response.data) {
        const formattedResponse = formatExpenseReportDetails(response.data);
        dispatch({ type: 'SET_EXPENSE_REPORT_DETAILS', payload: formattedResponse });
      }
    } catch (error) {
      dispatch({ type: 'SET_EXPENSE_REPORT_DETAILS', payload: { isLoading: false } });
      addToast({ type: 'error', title: 'ERROR', message: error?.message });
    }
  };

  const downloadDocument = async (row, filename) => {
    downloadFile({ docid: row?.id }, row?.fileName || filename, 'restExpIndex/downloadAttachment');
  };

  return {
    state,
    addExpenseReport,
    viewExpenseReport,
    modifyExpenseReport,
    deleteExpenseReport,
    submitExpenseReport,
    approveRejectExpenseReport,
    fetchMyOrgExpenseReports,
    fetchUpdatedMyOrgExpenseReports,
    fetchMyActionableExpenseReports,
    onChangeApproveRejectComment,
    handleActionSubmit,
    handleSelectedReport,
    fetchExpenseReportDetails,
    downloadDocument,
    actionNavigation,
    fetchExpenseReportAction,
    listNavigation,
    showDeleteDialog,
    handleDeleteDialog,
  };
};

export default useServices;
