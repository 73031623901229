import React from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import PropTypes from 'prop-types';
import EmployeeActionRequest from 'components/Organisms/Employee/Company/Separation/ActionRequest';

const EmpActionRequestTemplate = ({
  pageHeading,
  handleLog,
  handleApprove,
  handleReject,
  exitDetails,
  defaultValues,
  handleSubmit,
}) => {
  const breadcrumbData = [
    { id: 1, name: 'Separations', path: '/myteam/separations', isActive: false },
    { id: 2, name: 'Action Employee Separation Request', isActive: true },
  ];

  return (
    <BlankTemplate pageHeading={pageHeading} breadcrumbData={breadcrumbData}>
      <EmployeeActionRequest
        handleApprove={handleApprove}
        handleReject={handleReject}
        handleLog={handleLog}
        exitDetails={exitDetails}
        defaultValues={defaultValues}
        handleSubmit={handleSubmit}
      />
    </BlankTemplate>
  );
};

EmpActionRequestTemplate.defaultProps = {
  pageHeading: 'Action Employee Separation Request',
  handleLog: () => {},
  handleApprove: () => {},
  handleReject: () => {},
  exitDetails: {},
  defaultValues: {},
  handleSubmit: () => {},
};

EmpActionRequestTemplate.propTypes = {
  pageHeading: PropTypes.string,
  exitDetails: PropTypes.shape({}),
  handleLog: PropTypes.func,
  handleApprove: PropTypes.func,
  handleReject: PropTypes.func,
  defaultValues: PropTypes.shape({}),
  handleSubmit: PropTypes.func,
};

export default EmpActionRequestTemplate;
