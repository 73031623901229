import * as React from 'react';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import Typographys from 'components/Atoms/Typography';
import { Box } from '@mui/material';
import { TDSWorkingStyle } from 'components/Organisms/MyPay/TDSWorking/style';

const HeadsTable = ({ hasHeader, headsData }) => {
  return (
    <Paper sx={{ overflow: 'auto' }}>
      {hasHeader && (
        <Box sx={TDSWorkingStyle.headsTableHeader}>
          <Box sx={{ width: '80%' }}>
            <Typographys variant="h6">Heads</Typographys>
          </Box>
          <Box sx={{ width: '20%' }}>
            <Typographys variant="h6">Amount</Typographys>
          </Box>
        </Box>
      )}

      {headsData.map((head) =>
        head.type === 'subtitle' ? (
          <Box key={head.title} sx={TDSWorkingStyle.headsTableSubTitleRows}>
            <Box sx={{ width: '80%' }}>
              <Typographys variant="caption" color="neutral.600">
                {head.title}
              </Typographys>
            </Box>
          </Box>
        ) : (
          <Box key={head.title} sx={TDSWorkingStyle.headsTableRows}>
            <Box sx={{ width: '80%' }}>
              <Box sx={TDSWorkingStyle.headsTableSubTitleColumn}>
                <Box sx={{ padding: head?.padding ? head.padding : '0' }}>
                  <Typographys variant="body2" color={head?.color ? head.color : 'neutral.800'}>
                    {head.title}{' '}
                    {head.subTitle && (
                      <Typographys component="span" variant="body2" color="neutral.600">
                        {head.subTitle}
                      </Typographys>
                    )}
                  </Typographys>
                </Box>
              </Box>
            </Box>
            <Box sx={{ width: '20%' }}>
              <Typographys variant="body2">{head.oldRegimeAmount}</Typographys>
            </Box>
          </Box>
        ),
      )}
    </Paper>
  );
};

HeadsTable.defaultProps = {
  headsData: [],
  hasHeader: false,
};

HeadsTable.propTypes = {
  headsData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      oldRegimeAmount: PropTypes.string,
      newRegimeAmount: PropTypes.string,
    }),
  ),
  hasHeader: PropTypes.bool,
};

export default HeadsTable;
