import React, { useEffect } from 'react';
import { useThunk } from 'hooks/useThunk';
import { useSelector } from 'react-redux';
import { fetchEmploymentHistory } from 'store';
import { useNavigate } from 'react-router-dom';
import { Typography, Skeleton, Box } from '@mui/material';
import ProfileEmploymentHistoryTemplate from 'components/Templates/Profile/EmploymentHistory';
import useDownloadFile from 'hooks/useDownloadFile';

const EmploymentHistoryDetails = () => {
  const navigate = useNavigate();
  const downloadFile = useDownloadFile();

  const [doFetchEmploymentHistoryDetails, isLoadingEmploymentHistoryDetails, loadingEmploymentHistoryDetailsError] =
    useThunk(fetchEmploymentHistory);

  const { employmentHistoryDetails } = useSelector((state) => {
    return state.profile;
  });

  useEffect(() => {
    doFetchEmploymentHistoryDetails();
  }, [doFetchEmploymentHistoryDetails]);
  const handleEdit = () => {
    navigate('/profile/employment-history/addEdit');
  };

  const handleAttachment = (docid, filename) => {
    downloadFile({ docid }, filename, 'restPayIndex/downloadAttachment');
  };

  const EmpHistoryList =
    employmentHistoryDetails &&
    employmentHistoryDetails?.map((employment) => ({
      employerName: employment?.employerName || '-',
      employerAddress: employment?.employerAddress || '-',
      commenceDate: employment?.employmentCommenceDate || '',
      endDate: employment?.employmentEndDate || '',
      reasonForLeaving: employment?.reasonForLeaving || '-',
      employeeID: employment?.employeeID || '-',
      startDesignation: employment?.startDesignation || '-',
      endDesignation: employment?.endDesignation || '-',
      referenceName: employment?.referenceContactName || '-',
      referenceDesignation: employment?.referenceContactDesignation || '-',
      referencePhone: employment?.referenceContactPhone || '-',
      referenceEmail: employment?.referenceContactEmail || '-',
      experienceLetter: employment?.documents.length > 0 ? employment?.documents[0].fileName : '',
      experienceLetterId: employment?.documents.length > 0 ? employment?.documents[0].id : '',
    }));

  if (isLoadingEmploymentHistoryDetails) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (loadingEmploymentHistoryDetailsError) {
    return (
      <Typography variant="h6">
        {loadingEmploymentHistoryDetailsError?.message || 'Error while fetching employement history details.'}
      </Typography>
    );
  }

  return (
    <ProfileEmploymentHistoryTemplate
      handleEdit={handleEdit}
      employmentHistory={EmpHistoryList}
      handleAttachment={handleAttachment}
    />
  );
};

export default EmploymentHistoryDetails;
