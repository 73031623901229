import React, { useEffect } from 'react';
import InitiateEmployeeSeparationTemplate from 'components/Templates/Employee/Company/Separation/InitiateEmployeeSeparation';
import { useSelector } from 'react-redux';
import { Typography, Box } from '@mui/material';
import useToast from 'components/Provider/useToast';
import { useNavigate, useParams } from 'react-router-dom';
import get from 'lodash/get';
import { fetchInitiateSpecificEmpExit } from 'store';
import { useThunk } from 'hooks/useThunk';
import dayjs from 'dayjs';
import { saveSpecificEmpExit } from 'api/employees/separations';
import { isEmpty, isObject } from 'lodash';

const InitiateEmployeeSeparation = () => {
  const addToast = useToast();
  const navigate = useNavigate();
  const { id } = useParams();

  const breadcrumbData = [
    { id: 1, name: 'Separation', path: '/myteam/separations', isActive: false },
    {
      id: 2,
      name: 'Initiate Employee Separation',
      path: `/myteam/separations/initiate-employee-separation/${id}`,
      isActive: true,
    },
  ];

  const validateDateFormat = (val) => (val ? dayjs(val).format('MM/DD/YYYY') : '');

  const [
    getInitiateEmployeeSeparationDetails,
    isLoadingInitiateEmployeeSeparationDetails,
    loadingInitiateEmployeeSeparationDetailsError,
  ] = useThunk(fetchInitiateSpecificEmpExit);

  useEffect(() => {
    const bodyFormData = new FormData();
    bodyFormData.append('id', id);
    getInitiateEmployeeSeparationDetails(bodyFormData);
  }, []);

  const {
    employeeInstance = {},
    separationTypes = {},
    empList = {},
  } = useSelector((state) => state?.separations?.employeeSeparation);

  const seperationOptions =
    isObject(separationTypes) && !isEmpty(separationTypes)
      ? Object.entries(separationTypes).map((item) => ({
          id: item[1],
          name: item[1],
        }))
      : [];

  const handleSubmit = async (values) => {
    const bodyFormData = new FormData();
    const eligibleForRehire = values.eligibleForRehire ? 'on' : '';
    bodyFormData.append('employee.id', id);
    bodyFormData.append('profile.seperationType', get(values, 'seperationType', ''));
    bodyFormData.append('requestedRelievingDate', validateDateFormat(values?.relievingDate));
    bodyFormData.append('resignationSubmissionDate', validateDateFormat(values?.resSubmissionDate));
    bodyFormData.append('resignationAcceptanceDate', validateDateFormat(values?.resAcceptanceDate));
    bodyFormData.append('resignationAcceptingManager.id', get(values, 'resAcceptManager', ''));
    bodyFormData.append('exitInterviewDate', validateDateFormat(values?.exitInterviewDate));
    bodyFormData.append('exitInterviewer.id', get(values, 'exitInterviewer', ''));
    bodyFormData.append('eligibleForRehire', eligibleForRehire);
    bodyFormData.append('resignationReasons', get(values, 'resReasons', ''));
    try {
      const response = await saveSpecificEmpExit(bodyFormData);
      if (response?.status === 'SUCCESS') {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.message || `Employee separation initiated successfully`,
        });
        navigate(`/myteam/separations/view/${response?.results?.id}`);
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to initiate employee separation`,
      });
    }
  };

  if (loadingInitiateEmployeeSeparationDetailsError !== null) {
    return (
      <Typography variant="h6">
        {loadingInitiateEmployeeSeparationDetailsError?.message || 'Error while loading initiate employee separation.'}
      </Typography>
    );
  }

  return (
    <Box>
      <InitiateEmployeeSeparationTemplate
        pageHeading="Initiate Employee Separation"
        handleSubmit={handleSubmit}
        breadcrumbData={breadcrumbData}
        employeeSeparationInstance={employeeInstance}
        seperationOptions={seperationOptions}
        managerOptions={empList}
        exitInterviewerOptions={empList}
        loading={isLoadingInitiateEmployeeSeparationDetails}
      />
    </Box>
  );
};
export default InitiateEmployeeSeparation;
