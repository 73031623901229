import { useState } from 'react';

const useSelection = (rows) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const handleSelect = (event, rowId) => {
    setSelectedRows((prevSelectedRows) => {
      if (event.target.checked) {
        return [...prevSelectedRows, rowId];
      }

      return prevSelectedRows.filter((selectedRow) => selectedRow !== rowId);
    });
  };

  const handleClearSelected = () => {
    setSelectedRows([]);
  };

  const handleSelectAll = (event, keyColumn) => {
    if (event.target.checked) {
      setSelectedRows(rows.map((row) => row[keyColumn]));
      return;
    }

    handleClearSelected();
  };

  return [selectedRows, handleSelect, handleSelectAll, handleClearSelected];
};

export default useSelection;
