import { useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import {
  saveQuery,
  modifyQueryAction,
  deleteQuery,
  submitQuery,
  actionQuery,
  actionQueryAction,
  recallQuery,
  escalateQuery,
  escalateQueryAction,
  revertQuery,
  reassignQuery,
  reassignQueryAction,
  reopenQuery,
  reopenQueryAction,
  searchQueryAction,
  saveSubmitQuery,
} from 'api/queries';
import useToast from 'components/Provider/useToast';
import useDownloadFile from 'hooks/useDownloadFile';

const initialState = {
  open: false,
  selectedTab: 0,
  listQueries: {
    loading: false,
    data: {},
    error: null,
  },
  queryDetails: {},
  isApprove: false,
  comment: '',
};

const formatEscalateDetails = (response) => {
  if (!response.data) {
    return {};
  }

  const { queryInstance = {}, commentsMap = {} } = response.data;
  return {
    queryDetails: {
      queryNumber: queryInstance?.queryNumber,
      title: queryInstance?.title,
      resolutionStatus: queryInstance?.resolutionStatus,
      openedBy: queryInstance?.openedBy?.fullName,
      closedBy: queryInstance?.closedBy?.fullName,
      openDate: queryInstance?.openDate,
      closeDate: queryInstance?.closeDate,
      submissionDate: queryInstance?.submissionDate,
      currentOwner: queryInstance?.currentOwner?.fullName,
      category: queryInstance?.category?.name,
      topic: queryInstance?.topic?.name,
      description: queryInstance?.description,
      attachments: queryInstance?.attachment,
      fileName: queryInstance?.attachment?.documentName,
      id: queryInstance?.attachment?.id,
      comments: queryInstance?.comments,
    },
    commentsMap,
    formData: { comments: '' },
  };
};

const formatPreCreateResponse = (response) => {
  if (!response.data) {
    return {};
  }

  const { queryInstance, employeesList = [], categoriesList = [], catTopicsMap = {} } = response.data;

  const retObj = {
    categoryOptions: !isEmpty(categoriesList)
      ? Object.keys(categoriesList)?.map((item) => ({
          id: categoriesList[item].id,
          name: categoriesList[item].name,
          onClick: () => {},
        }))
      : [],

    topicOptions: catTopicsMap,

    currentOwnerOptions: employeesList?.map((item) => ({
      id: item.id,
      name: item.fullName,
      onClick: () => {},
    })),
    resolutionStatusOptions: [
      { id: 'Draft', name: 'Draft' },
      { id: 'Under Review', name: 'Under Review' },
      { id: 'Closed - Resolved', name: 'Closed - Resolved' },
      { id: 'Closed - Unresolved', name: 'Closed - Unresolved' },
      { id: 'Closed - Duplicate', name: 'Closed - Duplicate' },
      { id: 'Closed - Rejected', name: 'Closed - Rejected' },
      { id: 'Closed - Incorrect', name: 'Closed - Incorrect' },
      { id: 'Unknown', name: 'Unknown' },
    ],
    formData: {
      id: queryInstance?.id,
      title: queryInstance?.title,
      category: queryInstance?.categoryId,
      topic: queryInstance?.topicId,
      description: queryInstance?.description,
      attachment: queryInstance?.attachment?.documentName,
      currentOwner: queryInstance?.currentOwnerId,
      resolutionStatus: queryInstance?.resolutionStatus,
      comments: '',
    },
    queryDetails: {
      queryNumber: queryInstance?.queryNumber,
      title: queryInstance?.title,
      resolutionStatus: queryInstance?.resolutionStatus,
      openedBy: queryInstance?.openedBy?.fullName,
      closedBy: queryInstance?.closedBy?.fullName,
      openDate: queryInstance?.openDate,
      closeDate: queryInstance?.closeDate,
      submissionDate: queryInstance?.submissionDate,
      currentOwner: queryInstance?.currentOwner?.fullName,
      category: queryInstance?.category?.name,
      topic: queryInstance?.topic?.name,
      description: queryInstance?.description,
      attachments: queryInstance?.attachment?.documentName,
      fileName: queryInstance?.attachment?.documentName,
      id: queryInstance?.attachment?.id,
    },
  };

  return retObj;
};

const reducer = (state, action) => {
  const { type, payload } = action;
  let newState;
  switch (type) {
    case 'FETCH_QUERY_PENDING': {
      newState = { ...state, listQueries: { loading: true } };
      break;
    }
    case 'FETCH_QUERY': {
      newState = { ...state, listQueries: { ...state.listQueries, loading: false, data: payload } };
      break;
    }
    case 'FETCH_QUERY_REJECT': {
      newState = { ...state, listQueries: { ...state.listQueries, loading: false, error: payload } };
      break;
    }
    case 'OPEN_CLOSE_DIALOG': {
      newState = { ...state, open: payload };
      break;
    }
    case 'SET_COMMENT': {
      newState = { ...state, comment: payload };
      break;
    }
    case 'SET_QUERY_DETAILS': {
      newState = { ...state, queryDetails: payload };
      break;
    }
    case 'SET_TOPIC_OPTIONS': {
      newState = { ...state, topicOptions: payload };
      break;
    }
    default: {
      newState = state;
    }
  }
  return newState;
};

const useServices = () => {
  const navigate = useNavigate();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const addToast = useToast();
  const downloadFile = useDownloadFile();

  const createQueryNavigationService = () => {
    navigate(`/home/queries/add`);
  };

  const viewQueryService = (row) => {
    navigate(`/home/queries/${row.id}/view`);
  };

  const handleDeleteDialog = () => {
    setShowDeleteDialog(!showDeleteDialog);
  };

  const modifyQueryNavigationService = (row) => {
    navigate(`/home/queries/${row.id}/edit`);
  };

  const actionQueryNavigationService = async (row) => {
    navigate(`/home/queries/${row.id}/action`);
  };

  const escalateQueryNavigationService = (row) => {
    navigate(`/home/queries/${row.id}/escalate`);
  };

  const reassignQueryNavigationService = (row) => {
    navigate(`/home/queries/${row.id}/reassign`);
  };

  const handleCancel = () => {
    navigate(`/home/queries`);
  };
  const reopenQueryNavigationService = (row) => {
    navigate(`/home/queries/${row.id}/reopen`);
  };

  const onChangeComment = (event) => {
    dispatch({ type: 'SET_COMMENT', payload: event.target.value });
  };

  const saveQueryService = async (isEditMode, formData) => {
    let response;
    const bodyFormData = new FormData();
    try {
      bodyFormData.append('title', get(formData, 'title', ''));
      bodyFormData.append('category.id', get(formData, 'category', ''));
      bodyFormData.append('topic.id', get(formData, 'topic', ''));
      bodyFormData.append('description', get(formData, 'description', ''));
      bodyFormData.append('attachment', get(formData, 'attachment', ''));
      bodyFormData.append(`topic.id_${formData.category}`, get(formData, 'topic', ''));
      if (isEditMode) {
        bodyFormData.append('id', get(formData, 'id', ''));
        response = await modifyQueryAction(bodyFormData);
      } else {
        response = await saveQuery(bodyFormData);
      }
      if (response.status === 200 && response.data?.status !== 'ERROR') {
        const queryId = isEditMode ? formData?.id : response.data?.queryInstance?.id;
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Query saved successfully`,
        });
        viewQueryService({ id: queryId });
      }
      if (response.data?.status === 'ERROR') {
        addToast({
          type: 'error',
          title: 'ERROR',
          message: response.data?.message || `Failed to save Query`,
        });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to save Query`,
      });
    }
  };

  const saveSubmitQueryService = async (formData) => {
    let response;
    const bodyFormData = new FormData();
    try {
      bodyFormData.append('title', get(formData, 'title', ''));
      bodyFormData.append('category.id', get(formData, 'category', ''));
      bodyFormData.append('topic.id', get(formData, 'topic', ''));
      bodyFormData.append('description', get(formData, 'description', ''));
      bodyFormData.append('attachment', get(formData, 'attachment', ''));
      bodyFormData.append(`topic.id_${formData.category}`, get(formData, 'topic', ''));
      response = await saveSubmitQuery(bodyFormData);
      if (response.status === 200 && response.data) {
        const queryId = response.data?.queryInstance?.id;
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Query saved successfully`,
        });
        viewQueryService({ id: queryId });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to save Query`,
      });
    }
  };

  const handleOpenDialog = (payload, row) => {
    dispatch({ type: 'OPEN_CLOSE_DIALOG', payload });
    dispatch({ type: 'SET_QUERY_DETAILS', payload: row });
  };

  const deleteQueryService = async (id) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', id);
      const response = await deleteQuery(bodyFormData);
      if (response.status === 200 && response.data) {
        handleOpenDialog(false);
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Query deleted successfully`,
        });
      }
    } catch (error) {
      handleOpenDialog(false);
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to delete the query`,
      });
    } finally {
      handleOpenDialog(false);
    }
  };

  const submitQueryService = async (id) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', id);
      const response = await submitQuery(bodyFormData);
      if (response.status === 200 && response.data) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Query submitted successfully`,
        });
        viewQueryService({ id });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to submit Query`,
      });
    }
  };

  const actionQueryService = async (id) => {
    try {
      dispatch({ type: 'SET_QUERY_DETAILS', payload: { isLoading: true } });
      const bodyFormData = new FormData();
      bodyFormData.append('id', id);
      const response = await actionQuery(bodyFormData);
      if (response.status === 200 && response.data) {
        const formattedResponse = formatPreCreateResponse(response.data);
        dispatch({ type: 'SET_QUERY_DETAILS', payload: formattedResponse });
      }
    } catch (error) {
      dispatch({ type: 'SET_QUERY_DETAILS', payload: { isLoading: false } });
      throw error;
    }
  };

  const actionQueryActionService = async (formik) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', formik.id);
      bodyFormData.append('resolutionStatus', formik.resolutionStatus);
      bodyFormData.append('comments', formik.comments);
      const response = await actionQueryAction(bodyFormData);
      if (response.status === 200 && response.data) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Query fetched successfully`,
        });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to fetch Query`,
      });
    }
  };

  const recallQueryService = async ({ id }) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', id);
      const response = await recallQuery(bodyFormData);
      if (response.status === 200 && response.data) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Query recalled successfully`,
        });
        navigate('/home/queries');
      } else {
        addToast({
          type: 'error',
          title: 'ERROR',
          message: response?.data?.message || `Failed to recall Query`,
        });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to recall Query`,
      });
    }
  };

  const escalateQueryService = async (id) => {
    try {
      dispatch({ type: 'SET_QUERY_DETAILS', payload: { isLoading: true } });
      const bodyFormData = new FormData();
      bodyFormData.append('id', id);
      const response = await escalateQuery(bodyFormData);
      if (response.status === 200 && response.data) {
        const formattedResponse = formatEscalateDetails(response.data);
        dispatch({ type: 'SET_QUERY_DETAILS', payload: formattedResponse });
      }
    } catch (error) {
      dispatch({ type: 'SET_QUERY_DETAILS', payload: { isLoading: false } });
      throw error;
    }
  };

  const escalateQueryActionService = async (row) => {
    console.log(row);
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', row.id);
      bodyFormData.append('comments', row.comment);
      const response = await escalateQueryAction(bodyFormData);
      if (response.status === 200 && response.data) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Query escalated successfully`,
        });
        viewQueryService({ id: row?.id });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to escalate Query`,
      });
    }
  };

  const revertQueryService = async (row) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', get(row, 'id', ''));
      const response = await revertQuery(bodyFormData);
      if (response.status === 200 && response.data) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Query reverted successfully`,
        });
        viewQueryService({ id: row?.id });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to revert Query`,
      });
    }
  };

  const reassignQueryService = async (id) => {
    try {
      dispatch({ type: 'SET_QUERY_DETAILS', payload: { isLoading: true } });
      const bodyFormData = new FormData();
      bodyFormData.append('id', id);
      const response = await reassignQuery(bodyFormData);
      if (response.status === 200 && response.data) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Query reassigned successfully`,
        });
        const formattedResponse = formatPreCreateResponse(response.data);
        dispatch({ type: 'SET_QUERY_DETAILS', payload: formattedResponse });
      }
    } catch (error) {
      dispatch({ type: 'SET_QUERY_DETAILS', payload: { isLoading: false } });
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to reassign Query`,
      });
    }
  };

  const reassignQueryActionService = async (formik) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', formik.id);
      bodyFormData.append('category.id', formik.category);
      bodyFormData.append('topic.id', formik.topic);
      bodyFormData.append('topicidtemp', 30546);
      bodyFormData.append(`topic.id_${formik.category}`, formik.topic);
      const response = await reassignQueryAction(bodyFormData);
      if (response.status === 200 && response.data) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Query reassigned successfully`,
        });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to reassign Query`,
      });
    }
  };

  const reopenQueryService = async (id) => {
    try {
      dispatch({ type: 'SET_QUERY_DETAILS', payload: { isLoading: true } });
      const bodyFormData = new FormData();
      bodyFormData.append('id', id);
      const response = await reopenQuery(bodyFormData);
      if (response.status === 200 && response.data) {
        const formattedResponse = formatPreCreateResponse(response.data);
        dispatch({ type: 'SET_QUERY_DETAILS', payload: formattedResponse });
      }
    } catch (error) {
      dispatch({ type: 'SET_QUERY_DETAILS', payload: { isLoading: false } });
      throw error;
    }
  };

  const reopenQueryActionService = async (event) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', event?.id);
      const response = await reopenQueryAction(bodyFormData);
      if (response.status === 200 && response.data) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Query reopened successfully`,
        });
      } else {
        addToast({
          type: 'warning',
          title: 'WARNING',
          message: response?.data?.message || `Failed to reopen Query`,
        });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to reopen Query`,
      });
    } finally {
      navigate('/home/queries');
    }
  };

  const downloadDocument = (id, filename) => {
    downloadFile({ docid: id }, filename, 'restApi/downloadDocumentAttachment');
  };

  const searchQueriesService = async (query) => {
    try {
      dispatch({ type: 'FETCH_QUERY_PENDING', payload: { loading: true } });
      const response = await searchQueryAction(query);
      dispatch({ type: 'FETCH_QUERY_PENDING', payload: { loading: false } });
      if (response.status === 200 && response.data) {
        dispatch({ type: 'FETCH_QUERY', payload: response.data });
      }
    } catch (error) {
      dispatch({ type: 'FETCH_QUERY_REJECT', payload: error });
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to list Query`,
      });
    }
  };

  return {
    state,
    createQueryNavigationService,
    saveQueryService,
    saveSubmitQueryService,
    viewQueryService,
    modifyQueryNavigationService,
    deleteQueryService,
    submitQueryService,
    actionQueryNavigationService,
    actionQueryService,
    actionQueryActionService,
    recallQueryService,
    escalateQueryNavigationService,
    escalateQueryService,
    escalateQueryActionService,
    revertQueryService,
    reassignQueryNavigationService,
    reassignQueryService,
    reassignQueryActionService,
    handleCancel,
    reopenQueryNavigationService,
    reopenQueryService,
    reopenQueryActionService,
    downloadDocument,
    handleOpenDialog,
    onChangeComment,
    searchQueriesService,
    showDeleteDialog,
    handleDeleteDialog,
  };
};

export default useServices;
