import { createSlice } from '@reduxjs/toolkit';

import {
  fetchPreviousEmploymentTaxesList,
  previousEmploymentTaxDetailsCreate,
  previousEmploymentTaxDetailsEdit,
  viewPreviousEmploymentTaxDetails,
} from 'store/thunks/home/myPay';

const myPay = createSlice({
  name: 'myPay',
  initialState: {
    selectedEmploymentTax: null,
    previousEmploymentTaxes: [],
    createPreviousEmploymentTaxDetails: {},
    editPreviousEmploymentTaxDetails: {},
    displayPreviousEmploymentTaxDetails: {},
  },
  reducers: {
    updateSelectedEmploymentTax(state, action) {
      state.selectedEmploymentTax = action.payload;
    },
  },

  extraReducers(builder) {
    builder.addCase(fetchPreviousEmploymentTaxesList.fulfilled, (state, action) => {
      state.previousEmploymentTaxes = action.payload;
    });
    builder.addCase(previousEmploymentTaxDetailsCreate.fulfilled, (state, action) => {
      state.createPreviousEmploymentTaxDetails = action.payload;
    });
    builder.addCase(previousEmploymentTaxDetailsEdit.fulfilled, (state, action) => {
      state.editPreviousEmploymentTaxDetails = action.payload;
    });
    builder.addCase(viewPreviousEmploymentTaxDetails.fulfilled, (state, action) => {
      state.displayPreviousEmploymentTaxDetails = action.payload;
    });
  },
});

export const { updateSelectedEmploymentTax } = myPay.actions;

export default myPay.reducer;
