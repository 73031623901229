import React from 'react';
import { Typography } from '@mui/material';

const fixedColumns = [
  {
    fieldName: 'category',
    name: 'category',
    label: 'Category',
    type: 'string',
    formatValue: ({ row, column }) => {
      return <Typography variant="body2">{row[column.fieldName]}</Typography>;
    },
  },
];

const useColumns = (rows) => {
  // Generate columns for the keys except "category"
  const columns = [...fixedColumns];
  const keys = Object.keys(rows[0]).filter((key) => key !== 'category');
  keys.forEach((key) => {
    const column = {
      fieldName: key,
      name: key,
      label: key,
      type: 'number',
      formatValue: ({ row, columnKey }) => {
        return <Typography variant="body2">{row[key]}</Typography>;
      },
    };
    columns.push(column);
  });

  return [columns];
};

export default useColumns;
