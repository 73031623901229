export const timelineStyle = {
  timelineWrapper: {
    '.MuiInputLabel-root': {
      color: 'neutral.600',
    },
    '.MuiButton-root': {
      '&.MuiButton-outlinedInfo': {
        '&:hover': {
          color: 'neutral.600',
          background: 'transparent',
        },
      },
    },

    '& .MuiTimeline-root': {
      '& .MuiTimelineItem-root': {
        '@media screen and (max-width: 600px)': {
          '.MuiTimelineContent-root': {
            minWidth: '12rem',
            padding: '0 1.5rem 2rem ',

            '&:first-child': {
              pl: 0,
            },

            '&:last-child': {
              pr: 0,
            },
          },
          '&:last-child': {
            '.MuiTimelineConnector-root': {
              display: 'none',
            },
          },
        },
      },
    },
  },
  timelineHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: '2rem',

    '@media screen and (max-width: 600px)': {
      flexDirection: 'column',
      rowGap: '2rem',
      '> .MuiBox-root': {
        width: '100%',
        justifyContent: 'flex-start',
      },
    },
  },
};
