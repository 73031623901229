import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import MuiTab from 'components/Molecules/Tab';
import { isArray, isEmpty } from 'lodash';
import LeaveDetails from './LeaveDetails';
import { leaveDashboardStyle } from '../style';

const LeaveDetailsTab = ({ leaveTabData, onTabChange, selectedTab }) => {
  const policyListTab =
    isArray(leaveTabData) && !isEmpty(leaveTabData)
      ? leaveTabData.map((item) => ({
          label: item?.name,
          onChange: () => {},
          RenderComponent: LeaveDetails,
          componentProps: {
            openingLeave: item?.openingBalance,
            leavesAvailed: item?.leavesAvailed,
            transactions: item?.transactions,
            pendingApproval: item?.pendingApprovalBalance,
            remainingBalance: item?.currentBalance,
            loading: item?.loading,
            leaveTransactionsColumn: item?.leaveTransactionsColumn,
            leaveTransactionsRow: item?.leaveTransactionsRow,
            page: item?.page,
            sortDirection: item?.sortDirection,
            sortBy: item?.sortBy,
            handleSort: item?.handleSort,
            count: item?.count,
            onPageChange: item?.onPageChange,
            onSortChange: item?.onSortChange,
          },
        }))
      : [];
  return (
    <Box sx={leaveDashboardStyle.leaveDetailsTabWrapper}>
      <MuiTab
        textColor="primary"
        variant="scrollable"
        indicatorColor="primary"
        tabs={policyListTab}
        panelPadding="3.5rem 3rem"
        onTabChange={onTabChange}
        selectedTab={selectedTab}
      />
    </Box>
  );
};

LeaveDetailsTab.defaultProps = {
  onTabChange: () => {},
  leaveTabData: [],
  selectedTab: 0,
};

LeaveDetailsTab.propTypes = {
  onTabChange: PropTypes.func,
  leaveTabData: PropTypes.arrayOf(
    PropTypes.shape({
      openingLeave: PropTypes.number,
      leavesAvailed: PropTypes.number,
      transactions: PropTypes.number,
      pendingApproval: PropTypes.number,
      remainingBalance: PropTypes.number,
      loading: PropTypes.bool,
      leaveTransactionsColumn: PropTypes.arrayOf(
        PropTypes.shape({
          fieldName: PropTypes.string,
          label: PropTypes.string,
          formatValue: PropTypes.func,
        }),
      ),
      leaveTransactionsRow: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          startDate: PropTypes.string,
          endDate: PropTypes.string,
          leavesAvailed: PropTypes.string,
          leavesAccounted: PropTypes.number,
          leavesDeducted: PropTypes.number,
        }),
      ),
      page: PropTypes.number,
      sortDirection: PropTypes.string,
      sortBy: PropTypes.func,
      handleSort: PropTypes.func,
      count: PropTypes.number,
      onPageChange: PropTypes.func,
      onSortChange: PropTypes.func,
    }),
  ),
  selectedTab: PropTypes.number,
};

export default LeaveDetailsTab;
