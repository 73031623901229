import React from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import CustomLegend from 'components/Molecules/CustomLegend';
import { commonChartStyle } from '../style';

const NumOfEmpChartLevelWise = ({ empChartLevelWiseData }) => {
  const theme = useTheme();
  return (
    <Box sx={commonChartStyle.chartWrapper}>
      <ResponsiveContainer width="100%" height={350}>
        <LineChart
          width={730}
          height={350}
          data={empChartLevelWiseData}
          margin={{ top: 40, right: 70, left: 0, bottom: 40 }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey="month"
            axisLine={{ stroke: theme.palette.other[200] }}
            tick={{ fill: theme.palette.neutral[600] }}
            tickLine={{ stroke: theme.palette.common.white }}
            style={commonChartStyle.axisLabel}
            label={{ value: 'Months', position: 'right', offset: 20, fill: theme.palette.neutral[800] }}
          />
          <YAxis
            axisLine={false}
            tick={{ fill: theme.palette.neutral[600] }}
            tickLine={{ stroke: theme.palette.common.white }}
            tickSize={20}
            style={commonChartStyle.axisLabel}
            label={{ value: 'People', position: 'top', offset: 20, fill: theme.palette.neutral[800] }}
          />
          <Tooltip />

          <Legend verticalAlign="bottom" align="center" iconSize="12" iconType="circle" content={<CustomLegend />} />
          <Line type="monotone" name="Grade4" dataKey="Grade4" stroke={theme.palette.secondary[600]} strokeWidth={2} />
          <Line type="monotone" name="Grade3" dataKey="Grade3" stroke={theme.palette.secondary[400]} strokeWidth={2} />
          <Line type="monotone" name="Grade2" dataKey="Grade2" stroke={theme.palette.secondary[300]} strokeWidth={2} />
          <Line type="monotone" name="Grade1" dataKey="Grade1" stroke={theme.palette.secondary[200]} strokeWidth={2} />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

NumOfEmpChartLevelWise.defaultProps = {
  empChartLevelWiseData: [],
};
NumOfEmpChartLevelWise.propTypes = {
  empChartLevelWiseData: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      month: PropTypes.string,
      Grade1: PropTypes.number,
      Grade2: PropTypes.number,
      Grade3: PropTypes.number,
      Grade4: PropTypes.number,
    }),
  ),
};

export default NumOfEmpChartLevelWise;
