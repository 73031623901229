import React from 'react';
import { Box, Grid, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import Button from 'components/Atoms/Button';
import InputField from 'components/Atoms/InputField/index';
import { LoadingButton } from '@mui/lab';
import Typographys from 'components/Atoms/Typography';
import { useTheme } from '@mui/material/styles';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Attachments from 'components/Atoms/Attachments';
import { Formik, FieldArray, getIn } from 'formik';
import Typography from '@mui/material/Typography';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Dropdown from 'components/Molecules/Dropdown';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { profileStyle } from '../../style';

const ProfileEditSkillDetails = ({
  handleSubmit,
  handleCancel,
  levelOptions,
  skillLevelOptions,
  defaultValue,
  loading,
}) => {
  const defaultObj = {
    skill: skillLevelOptions?.[0]?.id || '',
    level: levelOptions?.[0]?.name || '',
    comments: '',
    attachments: '',
    isNew: true,
  };
  return (
    <Box mt={1.5}>
      <Formik
        initialValues={defaultValue}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(formik) => (
          <Form
            formik={formik}
            handleCancel={handleCancel}
            defaultObj={defaultObj}
            levelOptions={levelOptions}
            skillLevelOptions={skillLevelOptions}
            loading={loading}
          />
        )}
      </Formik>
    </Box>
  );
};
const Form = ({ formik, handleCancel, levelOptions, skillLevelOptions, defaultObj, loading }) => {
  const theme = useTheme();

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 40rem)' }} mt={2}>
          <FieldArray name="skillData">
            {({ push, remove }) => (
              <Box>
                {formik.values.skillData.map((e, index) => {
                  return (
                    <Box sx={profileStyle.formDivider}>
                      <Box sx={profileStyle.deleteContainer}>
                        <Typography variant="subtitle2" color="neutral.800">
                          Record {index + 1}
                        </Typography>
                        <Button
                          startIcon={<DeleteForeverIcon />}
                          onClick={(event) => {
                            event.preventDefault();
                            remove(index);
                          }}
                          variant="outlined"
                        >
                          Delete
                        </Button>
                      </Box>
                      <Grid container rowSpacing={2} columnSpacing={2} mt={0} sx={profileStyle.formContainer}>
                        <Grid item xs={12} md={4}>
                          <Dropdown
                            error={Boolean(
                              getIn(formik.touched, `skillData[${index}].skill`) &&
                                getIn(formik.errors, `skillData[${index}].skill`),
                            )}
                            helperText={
                              getIn(formik.touched, `skillData[${index}].skill`) &&
                              getIn(formik.errors, `skillData[${index}].skill`)
                            }
                            handleChange={(b) => {
                              formik.handleChange(b);
                            }}
                            onBlur={formik.handleBlur}
                            value={e?.skill}
                            name={`skillData[${index}].skill`}
                            id={`skillData[${index}].skill`}
                            label="Skill"
                            options={skillLevelOptions}
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Dropdown
                            error={Boolean(
                              getIn(formik.touched, `skillData[${index}].level`) &&
                                getIn(formik.errors, `skillData[${index}].level`),
                            )}
                            helperText={
                              getIn(formik.touched, `skillData[${index}].level`) &&
                              getIn(formik.errors, `skillData[${index}].level`)
                            }
                            handleChange={(b) => {
                              formik.handleChange(b);
                            }}
                            onBlur={formik.handleBlur}
                            value={e?.level}
                            name={`skillData[${index}].level`}
                            id={`skillData[${index}].level`}
                            label="Level"
                            options={levelOptions}
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `skillData[${index}].comments`) &&
                                getIn(formik.errors, `skillData[${index}].comments`),
                            )}
                            helperText={
                              getIn(formik.touched, `skillData[${index}].comments`) &&
                              getIn(formik.errors, `skillData[${index}].comments`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={e?.comments}
                            name={`skillData[${index}].comments`}
                            id={`skillData[${index}].comments`}
                            label="Comments"
                            multiline
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Attachments
                          isDeleteIcon
                          handleChange={(event) => {
                            formik.setFieldValue(`skillData[${index}].attachments`, event.target.files[0]);
                          }}
                          name={`skillData[${index}].attachments`}
                          id={`skillData[${index}].attachments`}
                          value={e?.attachments}
                          handleDelete={() => {
                            formik.setFieldValue(`skillData[${index}].attachments`, '');
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={8} mt={2}>
                        {e?.attachment && (
                          <Typographys variant="caption" color="neutral.600">
                            Previously Associated Attachment: {e?.attachment}
                          </Typographys>
                        )}
                      </Grid>
                    </Box>
                  );
                })}
                <Box sx={profileStyle.addWrapper}>
                  <Button
                    size="medium"
                    variant="outlined"
                    type="button"
                    startIcon={<AddOutlinedIcon />}
                    onClick={() => push(defaultObj)}
                  >
                    Add Skill Record
                  </Button>
                </Box>
              </Box>
            )}
          </FieldArray>
        </Box>
      </PerfectScrollbar>

      <Box sx={profileStyle.btnContainer}>
        <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

        <Box sx={profileStyle.submitWrapper}>
          <Button
            size="large"
            color="info"
            variant="outlined"
            type="cancel"
            onClick={() => {
              formik.resetForm();
              handleCancel();
            }}
          >
            Cancel
          </Button>
          <LoadingButton loading={loading} color="primary" size="large" type="submit" variant="contained">
            Update
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

Form.defaultProps = {
  handleCancel: () => {},
  formik: {},
  defaultObj: {},
  levelOptions: [],
  skillLevelOptions: [],
  loading: false,
};

Form.propTypes = {
  handleCancel: PropTypes.func,
  loading: PropTypes.bool,
  formik: PropTypes.shape({
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    values: PropTypes.shape({
      skillData: PropTypes.shape([]),
    }),
    resetForm: PropTypes.func,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    setFieldValue: PropTypes.func,
  }),
  defaultObj: PropTypes.shape({}),
  levelOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  skillLevelOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
};

ProfileEditSkillDetails.defaultProps = {
  handleSubmit: () => {},
  handleCancel: () => {},
  defaultValue: {
    skillData: [
      {
        skill: '',
        level: '',
        comments: '',
        attachment: '',
      },
    ],
  },
  levelOptions: [],
  skillLevelOptions: [],
  loading: false,
};

ProfileEditSkillDetails.propTypes = {
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  defaultValue: PropTypes.shape({}),
  levelOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  skillLevelOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
};

export default ProfileEditSkillDetails;
