import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import CustomLegend from 'components/Molecules/CustomLegend';
import { commonChartStyle } from '../style';

const NumOfEmpChartGridWise = ({ empData }) => {
  const theme = useTheme();
  return (
    <Box sx={commonChartStyle.chartWrapper}>
      <ResponsiveContainer width="100%" height={350}>
        <BarChart
          width={1000}
          height={350}
          data={empData}
          maxBarSize={48}
          margin={{
            top: 40,
            right: 60,
            left: 0,
            bottom: 40,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey="month"
            axisLine={{ stroke: theme.palette.other[200] }}
            tick={{ fill: theme.palette.neutral[600] }}
            tickLine={{ stroke: theme.palette.common.white }}
            style={commonChartStyle.axisLabel}
            label={{ value: 'Months', position: 'right', fill: theme.palette.neutral[800] }}
          />
          <YAxis
            axisLine={false}
            tick={{ fill: theme.palette.neutral[600] }}
            tickLine={{ stroke: theme.palette.common.white }}
            style={commonChartStyle.axisLabel}
            label={{ value: 'People', position: 'top', offset: 20, fill: theme.palette.neutral[800] }}
          />
          <Tooltip />

          <Bar name="Grade 4" dataKey="Grade4" stackId="grade" fill={theme.palette.secondary[600]} />
          <Bar name="Grade 3" dataKey="Grade3" stackId="grade" fill={theme.palette.secondary[400]} />
          <Bar name="Grade 2" dataKey="Grade2" stackId="grade" fill={theme.palette.secondary[300]} />
          <Bar name="Grade 1" dataKey="Grade1" stackId="grade" fill={theme.palette.secondary[200]} />
          <Legend verticalAlign="bottom" align="center" iconSize="12" iconType="circle" content={<CustomLegend />} />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

NumOfEmpChartGridWise.defaultProps = {
  empData: [],
};
NumOfEmpChartGridWise.propTypes = {
  empData: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      month: PropTypes.string,
      Grade1: PropTypes.number,
      Grade2: PropTypes.number,
      Grade3: PropTypes.number,
      Grade4: PropTypes.number,
    }),
  ),
};

export default NumOfEmpChartGridWise;
