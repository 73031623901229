import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEmployees } from 'store';
import { Typography } from '@mui/material';
import {
  updateSelectedEmployee,
  updateSelectedEmployees,
  updateAllSelectedEmployees,
} from 'store/slices/company/employees/employee';
import { useThunk } from 'hooks/useThunk';
import { useNavigate } from 'react-router-dom';

import EmployeeList from 'components/Templates/Employee/EmployeeList';
import DeleteEmployee from 'components/Organisms/Dialog/ActionList/DeleteEmployee';
import AddDataFilter from 'components/Organisms/Dialog/ActionList/AddDataFilter';
import AnonymiseEmployee from 'components/Organisms/Dialog/ActionList/AnonymiseEmployee';
import DeactivateEmployee from 'components/Organisms/Dialog/ActionList/DeactivateEmployee';
import LockEmployeeRecords from 'components/Organisms/Dialog/ActionList/LockEmployeeRecords';

import useController from 'hooks/useController';
import usePaginationAttributes from 'hooks/use-pagination-attributes';
import { hasPermission } from 'utils/permissions';
import useColumns from './useColumns';
import useActionItems from './useActionItems';
import useEmployeeServices from './useEmployeeServices';
import useMenuItems from './useMenuItems';

import MapLifeCyclePolicyContainer from './MapLifeCyclePolicy';
import MapEmployeeGroupContainer from './MapEmplooyeGroup';
import AddNoteContainer from './AddNote';

const EmployeesList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageSize } = usePaginationAttributes();
  const [columns] = useColumns();
  const [controller, handlePageChange, handleSortChange, handleFiltersApply, handleFiltersClear] = useController();
  const [doFetchEmployees, isLoadingEmployees, loadingEmployeesError] = useThunk(fetchEmployees);
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasViewPermission = hasPermission(authorities, ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_LIST_SECUSER']);
  const userHasViewSecUserPermission = hasPermission(authorities, ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_SECUSER']);

  const { selectedEmployee, selectedEmployees, employees } = useSelector((state) => {
    return state.employees;
  });

  const fetchEmployeesAction = useCallback(() => {
    const query = new URLSearchParams({});
    if (controller) {
      query.append('sort', controller.sortBy);
      query.append('order', controller.sort);
    }
    query.append('offset', controller.page * pageSize);
    query.append('max', pageSize);
    doFetchEmployees(query);
  }, [doFetchEmployees, controller, pageSize]);

  useEffect(() => {
    fetchEmployeesAction();
  }, [fetchEmployeesAction, controller, pageSize]);

  const employeeServices = useEmployeeServices(fetchEmployeesAction);
  const [handleActionItemClick] = useActionItems(employeeServices);
  const [menuItems] = useMenuItems(employeeServices);

  const handleSelect = (event, rowId) => {
    dispatch(updateSelectedEmployees({ isChecked: event.target.checked, rowId }));
  };

  const handleSelectAll = (event) => {
    dispatch(updateAllSelectedEmployees({ isChecked: event.target.checked }));
  };

  if (loadingEmployeesError) {
    return (
      <Typography variant="h6">{loadingEmployeesError?.message || 'Error while loading employee list.'}</Typography>
    );
  }

  if (!userHasViewPermission) {
    return <Typography variant="h6">You do not have permission to view employee list.</Typography>;
  }

  return (
    <>
      <EmployeeList
        loading={isLoadingEmployees}
        columns={columns}
        rows={employees.data}
        count={employees.count}
        selectedItems={selectedEmployees}
        onSelect={handleSelect}
        onSelectAll={handleSelectAll}
        showRowSelector
        showPagination
        onPageChange={handlePageChange}
        handleSort={handleSortChange}
        handleRowClick={(row) => {
          if (userHasViewSecUserPermission) {
            navigate(`/company/employee/view/${row?.userID}`);
          }
        }}
        page={controller.page}
        keyColumn="id"
        showActionList
        sortBy={controller.sortBy}
        sortDirection={controller.sort}
        roleBasedMenu={handleActionItemClick}
        handleSelect={(row) => {
          dispatch(updateSelectedEmployee(row));
        }}
        onSelectedChange={() => {}}
        handleAdvanceSearch={employeeServices.handleAdvanceSearch}
        menuList={menuItems}
      />
      {selectedEmployee !== null && (
        <>
          <DeleteEmployee
            open={employeeServices.dialogState.open === 'DELETE_EMPLOYEE'}
            handleClose={employeeServices.handleDialogClose}
            handleCancel={employeeServices.handleDialogClose}
            handleComment={() => {
              employeeServices.deleteEmployee(selectedEmployee);
            }}
            userName={selectedEmployee.firstName}
          />
          <AddDataFilter
            open={employeeServices.dialogState.open === 'ADD_DATA_FILTER'}
            handleClose={employeeServices.handleDialogClose}
            handleCancel={employeeServices.handleDialogClose}
            handleComment={() => {}}
            userName={selectedEmployee.firstName}
          />

          <AddNoteContainer
            open={employeeServices.dialogState.open === 'ADD_NOTES'}
            handleClose={employeeServices.handleDialogClose}
            handleCancel={employeeServices.handleDialogClose}
            handleComment={employeeServices.addNoteEmployee}
            selectedEmployee={selectedEmployee}
          />

          <AnonymiseEmployee
            open={employeeServices.dialogState.open === 'ANONYMISE_EMPLOYEE'}
            handleClose={employeeServices.handleDialogClose}
            handleCancel={employeeServices.handleDialogClose}
            handleComment={() => {}}
            userName={selectedEmployee.firstName}
          />

          <DeactivateEmployee
            open={employeeServices.dialogState.open === 'DEACTIVATE_EMPLOYEE'}
            title="DeActivate Employee"
            desc={`Are you sure you want to deactivate ${selectedEmployee.firstName}?`}
            handleClose={employeeServices.handleDialogClose}
            handleCancel={employeeServices.handleDialogClose}
            handleComment={() => {
              employeeServices.deActivateEmployees(selectedEmployee.userID);
            }}
            primaryBtn="DeActivate"
            userNo={selectedEmployee.firstName}
          />
          <DeactivateEmployee
            open={employeeServices.dialogState.open === 'ACTIVATE_EMPLOYEE'}
            title="Activate Employee"
            desc={`Are you sure you want to activate ${selectedEmployee.firstName}?`}
            handleClose={employeeServices.handleDialogClose}
            handleCancel={employeeServices.handleDialogClose}
            handleComment={() => {
              employeeServices.activateEmployees(selectedEmployee.userID);
            }}
            primaryBtn="Activate"
            userNo={selectedEmployee.firstName}
          />

          <LockEmployeeRecords
            open={employeeServices.dialogState.open === 'LOCK_EMPLOYEE'}
            title="Lock Employee"
            desc={`Are you sure you want to lock ${selectedEmployee.firstName}?`}
            handleClose={employeeServices.handleDialogClose}
            handleCancel={employeeServices.handleDialogClose}
            handleComment={() => {
              employeeServices.lockEmployee(selectedEmployee.userID);
            }}
            primaryBtn="Lock"
          />
          <LockEmployeeRecords
            open={employeeServices.dialogState.open === 'UNLOCK_EMPLOYEE'}
            title="UnLock Employee"
            desc={`Are you sure you want to unlock ${selectedEmployee.firstName}?`}
            handleClose={employeeServices.handleDialogClose}
            handleCancel={employeeServices.handleDialogClose}
            handleComment={() => {
              employeeServices.unLockEmployees(selectedEmployee.userID);
            }}
            primaryBtn="Unlock"
          />

          <MapLifeCyclePolicyContainer
            open={employeeServices.dialogState.open === 'MAP_LIFECYCLE_POLICY'}
            handleClose={employeeServices.handleDialogClose}
            handleCancel={employeeServices.handleDialogClose}
            handleComment={employeeServices.mapEmployeeToLifeCyclePolicy}
            selectedEmployee={selectedEmployee}
          />

          <MapEmployeeGroupContainer
            open={employeeServices.dialogState.open === 'SELECT_EMPLOYEE_GROUP'}
            handleClose={employeeServices.handleDialogClose}
            handleCancel={employeeServices.handleDialogClose}
            handleComment={employeeServices.mapGenericGroupEmployee}
            selectedEmployee={selectedEmployee}
          />
        </>
      )}
    </>
  );
};

export default EmployeesList;
