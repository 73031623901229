import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Typographys from 'components/Atoms/Typography';
import UserSalaryDetails from './UserSalaryDetails';
import SalaryDetailsTable from './SalaryDetailsTable';

const SalaryDetails = ({
  employeeData,
  employeeId,
  handleViewTaxWorking,
  earningsRows,
  earningsColumns,
  variablePayRows,
  variablePayColumns,

  statutoryDeductionsColumns,
  statutoryDeductionsRows,
  employerRetiralColumns,
  employerRetiralRows,
  fixedComponentsColumns,
  fixedComponentsRows,
  netSalaryColumns,
  netSalaryRows,
  netVariablePayColumns,
  netVariablePayRows,
  retiralColumns,
  retiralRows,
  totalColumns,
  totalRows,
  employeeName,
  isOverTimeApplicable,
  benefitsRows,
  benifitColumns,
  reimbursementsRows,
  reimbursementColumns,
  userHasViewTaxWorkingPermission,
}) => {
  const theme = useTheme();
  return (
    <Box>
      <UserSalaryDetails
        employeeData={employeeData}
        employeeId={employeeId}
        employeeName={employeeName}
        handleViewTaxWorking={handleViewTaxWorking}
        userHasViewTaxWorkingPermission={userHasViewTaxWorkingPermission}
      />

      {!!earningsRows?.length && (
        <Box mt={2}>
          <SalaryDetailsTable rows={earningsRows} isIndexNo columns={earningsColumns} />
        </Box>
      )}
      {!!benefitsRows?.length && (
        <Box mt={2}>
          <SalaryDetailsTable
            rows={benefitsRows}
            isIndexNo
            indexNo={earningsRows.length + 1}
            columns={benifitColumns}
          />
        </Box>
      )}
      {!!reimbursementsRows?.length && (
        <Box mt={2}>
          <SalaryDetailsTable
            rows={reimbursementsRows}
            isIndexNo
            indexNo={earningsRows.length + benefitsRows.length + 1}
            columns={reimbursementColumns}
          />
          <Divider sx={{ borderColor: theme.palette.other[200], margin: ' 3rem 0 2rem 0' }} />
        </Box>
      )}

      {!!variablePayRows?.length && (
        <Box mt={2}>
          <SalaryDetailsTable
            rows={variablePayRows}
            isIndexNo
            columns={variablePayColumns}
            indexNo={earningsRows.length + benefitsRows.length + reimbursementsRows.length + 1}
          />
          <Divider sx={{ borderColor: theme.palette.other[200], margin: ' 3rem 0 2rem 0' }} />
        </Box>
      )}
      {!!statutoryDeductionsRows?.length && (
        <Box mt={2}>
          <SalaryDetailsTable
            rows={statutoryDeductionsRows}
            columns={statutoryDeductionsColumns}
            isIndexNo
            indexNo={earningsRows.length + benefitsRows.length + variablePayRows.length + reimbursementsRows.length + 1}
          />
          <Divider sx={{ borderColor: theme.palette.other[200], margin: ' 3rem 0 2rem 0' }} />
        </Box>
      )}
      {!!employerRetiralRows?.length && (
        <Box mt={2}>
          <SalaryDetailsTable
            rows={employerRetiralRows}
            columns={employerRetiralColumns}
            isIndexNo
            indexNo={
              earningsRows.length +
              benefitsRows.length +
              variablePayRows.length +
              reimbursementsRows.length +
              statutoryDeductionsRows.length +
              1
            }
          />
          <Divider sx={{ borderColor: theme.palette.other[200], margin: ' 3rem 0 2rem 0' }} />
        </Box>
      )}

      <Box sx={{ border: 'solid 1px #D5D8E1', borderRadius: '0.5rem' }}>
        {!!fixedComponentsRows?.length && (
          <Box>
            <SalaryDetailsTable rows={fixedComponentsRows} columns={fixedComponentsColumns} />
          </Box>
        )}
        {!!netSalaryRows?.length && (
          <Box mt={2}>
            <SalaryDetailsTable rows={netSalaryRows} columns={netSalaryColumns} />
          </Box>
        )}
        {!!netVariablePayRows?.length && (
          <Box mt={2}>
            <SalaryDetailsTable rows={netVariablePayRows} columns={netVariablePayColumns} />
          </Box>
        )}
        {!!retiralRows?.length && (
          <Box mt={2} mb={2}>
            <SalaryDetailsTable rows={retiralRows} columns={retiralColumns} />
          </Box>
        )}
      </Box>

      {!!totalRows?.length && (
        <Box sx={{ border: 'solid 1px #D5D8E1', borderRadius: '0.5rem' }} mt={2} pb={2}>
          <SalaryDetailsTable rows={totalRows} columns={totalColumns} />
        </Box>
      )}
      <Box mt={2}>
        <Divider sx={{ borderColor: theme.palette.other[200], margin: ' 3rem 0 2rem 0' }} />
        <Typographys variant="h6" fontWeight={600} sx={{ display: 'block', textTransform: 'uppercase' }}>
          OverTime Applicability (Derived from PayStructure): {isOverTimeApplicable}
        </Typographys>
        <Divider sx={{ borderColor: theme.palette.other[200], margin: ' 2rem 0 0rem 0' }} />
      </Box>
    </Box>
  );
};

SalaryDetails.defaultProps = {
  employeeData: {},
  employeeId: '',
  employeeName: '',
  handleViewTaxWorking: () => {},
  earningsRows: [],
  earningsColumns: [],

  variablePayRows: [],
  variablePayColumns: [],

  statutoryDeductionsColumns: [],
  statutoryDeductionsRows: [],

  employerRetiralColumns: [],
  employerRetiralRows: [],

  fixedComponentsColumns: [],
  fixedComponentsRows: [],

  netSalaryColumns: [],
  netSalaryRows: [],

  netVariablePayColumns: [],
  netVariablePayRows: [],

  retiralColumns: [],
  retiralRows: [],

  totalColumns: [],
  totalRows: [],
  isOverTimeApplicable: '',
};

SalaryDetails.propTypes = {
  employeeData: PropTypes.shape({
    joiningDate: PropTypes.string,
    effectiveFrom: PropTypes.string,
    effectiveTo: PropTypes.string,
    CTCBased: PropTypes.string,
    salaryStructureStatus: PropTypes.string,
    payStructure: PropTypes.string,
  }),
  employeeId: PropTypes.string,
  employeeName: PropTypes.string,
  handleViewTaxWorking: PropTypes.func,

  earningsRows: PropTypes.arrayOf(
    PropTypes.shape({
      earnings: PropTypes.string,
      subLabel: PropTypes.string,
      annualAmount: PropTypes.string,
      perPeriodAmount: PropTypes.string,
    }),
  ),
  earningsColumns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      columnTitle: PropTypes.string,
      columnWidth: PropTypes.string,
    }),
  ),

  variablePayRows: PropTypes.arrayOf(
    PropTypes.shape({
      variablePay: PropTypes.string,
      subLabel: PropTypes.string,
      annualAmount: PropTypes.string,
      perPeriodAmount: PropTypes.string,
    }),
  ),
  variablePayColumns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      columnTitle: PropTypes.string,
      columnWidth: PropTypes.string,
    }),
  ),

  statutoryDeductionsColumns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      columnTitle: PropTypes.string,
      columnWidth: PropTypes.string,
    }),
  ),
  statutoryDeductionsRows: PropTypes.arrayOf(
    PropTypes.shape({
      statutoryDeductions: PropTypes.string,
      subLabel: PropTypes.string,
      annualAmount: PropTypes.string,
      perPeriodAmount: PropTypes.string,
    }),
  ),

  employerRetiralColumns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      columnTitle: PropTypes.string,
      columnWidth: PropTypes.string,
    }),
  ),
  employerRetiralRows: PropTypes.arrayOf(
    PropTypes.shape({
      employerRetiral: PropTypes.string,
      subLabel: PropTypes.string,
      annualAmount: PropTypes.string,
      perPeriodAmount: PropTypes.string,
    }),
  ),

  fixedComponentsColumns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      columnTitle: PropTypes.string,
      columnWidth: PropTypes.string,
    }),
  ),
  fixedComponentsRows: PropTypes.arrayOf(
    PropTypes.shape({
      fixedComponents: PropTypes.string,
      subLabel: PropTypes.string,
      annualAmount: PropTypes.string,
      perPeriodAmount: PropTypes.string,
    }),
  ),

  netSalaryColumns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      columnTitle: PropTypes.string,
      columnWidth: PropTypes.string,
    }),
  ),
  netSalaryRows: PropTypes.arrayOf(
    PropTypes.shape({
      netSalary: PropTypes.string,
      subLabel: PropTypes.string,
      annualAmount: PropTypes.string,
      perPeriodAmount: PropTypes.string,
    }),
  ),

  netVariablePayColumns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      columnTitle: PropTypes.string,
      columnWidth: PropTypes.string,
    }),
  ),
  netVariablePayRows: PropTypes.arrayOf(
    PropTypes.shape({
      netVariablePay: PropTypes.string,
      subLabel: PropTypes.string,
      annualAmount: PropTypes.string,
      perPeriodAmount: PropTypes.string,
    }),
  ),

  retiralColumns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      columnTitle: PropTypes.string,
      columnWidth: PropTypes.string,
    }),
  ),
  retiralRows: PropTypes.arrayOf(
    PropTypes.shape({
      retiral: PropTypes.string,
      subLabel: PropTypes.string,
      annualAmount: PropTypes.string,
      perPeriodAmount: PropTypes.string,
    }),
  ),

  totalColumns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      columnTitle: PropTypes.string,
      columnWidth: PropTypes.string,
    }),
  ),
  totalRows: PropTypes.arrayOf(
    PropTypes.shape({
      total: PropTypes.string,
      subLabel: PropTypes.string,
      annualAmount: PropTypes.string,
      perPeriodAmount: PropTypes.string,
    }),
  ),
  isOverTimeApplicable: PropTypes.string,
};

export default SalaryDetails;
