export const policyListWithSearchStyle = {
  wrapper: {
    borderStyle: 'solid ',
    borderWidth: '0.125rem 0.125rem 0 0',
    borderColor: 'neutral.300',
    height: '100%',
    backgroundColor: 'common.white',
  },
  searchWrapper: {
    display: 'flex',
    columnGap: 1,
    padding: '2rem 3rem',
    borderBottom: '0.125rem solid',
    borderColor: 'neutral.300',

    '> div': {
      width: '100%',
      '> div': {
        maxWidth: '100%',
      },
    },
  },
  // scroll: {
  //   height: '500px',
  //   overflow: 'auto',
  // },
  scroll: {
    '& ::-webkit-scrollbar': {
      display: 'none',
    },
  },
};
