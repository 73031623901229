import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { SalarySlipStyle } from '../../../style';
import { validateNumberElseHyphen } from '../../../../../../utils/commonUtils';

const SalarySlipTable = ({ salarySlipData }) => {
  return (
    <TableContainer component={Paper} sx={SalarySlipStyle.salarySlipTableWrapper}>
      <Typographys variant="h6" align="center">
        {salarySlipData.ssDate}
      </Typographys>
      <PerfectScrollbar>
        <Table sx={SalarySlipStyle.salarySlipTable}>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typographys variant="h6">Employee Name</Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="body1">{salarySlipData.employeeName}</Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="h6">Employee ID</Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="body1">{salarySlipData.employeeID}</Typographys>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typographys variant="h6">Designation</Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="body1">{salarySlipData.designation}</Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="h6">Location</Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="body1">{salarySlipData.location}</Typographys>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typographys variant="h6">Bank</Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="body1">{salarySlipData.bank}</Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="h6">Bank A/C no</Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="body1">{salarySlipData.bankAccountNo}</Typographys>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typographys variant="h6">PAN Number</Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="body1">{salarySlipData.pan}</Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="h6">UAN</Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="body1">{salarySlipData.uan}</Typographys>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typographys variant="h6">PF No</Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="body1">{salarySlipData.pf}</Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="h6">ESI No</Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="body1">{salarySlipData.esi}</Typographys>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typographys variant="h6">Date of Joining</Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="body1">{salarySlipData.dateOfJoining}</Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="h6">Date of Leaving</Typographys>
              </TableCell>
              <TableCell>
                <Typographys variant="body1">{salarySlipData.dateOfLeaving}</Typographys>
              </TableCell>
            </TableRow>
            <TableRow sx={SalarySlipStyle.overlapTableRow}>
              <TableCell className="nestedTable" colSpan={4}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typographys variant="h6">
                          Working Days: {Number(salarySlipData.workingDays).toFixed(1)}
                        </Typographys>
                      </TableCell>
                      <TableCell width="33%">
                        <Typographys variant="h6">LOP: {Number(salarySlipData.lop).toFixed(1)}</Typographys>
                      </TableCell>
                      <TableCell>
                        <Typographys variant="h6">Paid Days: {Number(salarySlipData.paidDays).toFixed(1)}</Typographys>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableCell>
            </TableRow>
            <TableRow sx={SalarySlipStyle.overlapTableRow}>
              <TableCell className="nestedTable" colSpan={4}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell />
                      <TableCell colSpan={2}>
                        <Typographys variant="h6" align="center">
                          Current Month
                        </Typographys>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <Typographys variant="h6" align="center">
                          Year to Date
                        </Typographys>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typographys variant="h6" align="center">
                          Heading
                        </Typographys>
                      </TableCell>
                      <TableCell>
                        <Typographys variant="h6">Earnings</Typographys>
                      </TableCell>
                      <TableCell>
                        <Typographys variant="h6">Deductions</Typographys>
                      </TableCell>
                      <TableCell>
                        <Typographys variant="h6">Earnings</Typographys>
                      </TableCell>
                      <TableCell>
                        <Typographys variant="h6">Deductions</Typographys>
                      </TableCell>
                    </TableRow>
                    {salarySlipData.edTableData.map((val, ind) => {
                      return (
                        <TableRow>
                          <TableCell>
                            <Typographys variant="h6">{val[0]}</Typographys>
                          </TableCell>
                          <TableCell>
                            <Typographys variant="body1" align="right">
                              ₹ {validateNumberElseHyphen(val[1])}
                            </Typographys>
                          </TableCell>
                          <TableCell>
                            <Typographys variant="body1" align="right">
                              ₹ {validateNumberElseHyphen(val[2])}
                            </Typographys>
                          </TableCell>
                          <TableCell>
                            <Typographys variant="body1" align="right">
                              ₹ {validateNumberElseHyphen(val[3])}
                            </Typographys>
                          </TableCell>
                          <TableCell>
                            <Typographys variant="body1" align="right">
                              ₹ {validateNumberElseHyphen(val[4])}
                            </Typographys>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {/*
                    Below code snippet is kept for the reference of the design
                    <TableRow> 
                      <TableCell>
                        <Typographys variant="h6">Basic</Typographys>
                      </TableCell>
                      <TableCell>
                        <Typographys variant="body1" align="right">
                          {salarySlipData.basicMonthEarnings}
                        </Typographys>
                      </TableCell>
                      <TableCell>
                        <Typographys variant="body1" align="right">
                          {salarySlipData.basicMonthDeductions}
                        </Typographys>
                      </TableCell>
                      <TableCell>
                        <Typographys variant="body1" align="right">
                          {salarySlipData.basicYearEarnings}
                        </Typographys>
                      </TableCell>
                      <TableCell>
                        <Typographys variant="body1" align="right">
                          {salarySlipData.basicYearDeductions}
                        </Typographys>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typographys variant="h6">House Rent Allowance</Typographys>
                      </TableCell>
                      <TableCell>
                        <Typographys variant="body1" align="right">
                          {salarySlipData.hraMonthEarnings}
                        </Typographys>
                      </TableCell>
                      <TableCell>
                        <Typographys variant="body1" align="right">
                          {salarySlipData.hraMonthDeductions}
                        </Typographys>
                      </TableCell>
                      <TableCell>
                        <Typographys variant="body1" align="right">
                          {salarySlipData.hraYearEarnings}
                        </Typographys>
                      </TableCell>
                      <TableCell>
                        <Typographys variant="body1" align="right">
                          {salarySlipData.hraYearDeductions}
                        </Typographys>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typographys variant="h6">Dressing Allowance</Typographys>
                      </TableCell>
                      <TableCell>
                        <Typographys variant="body1" align="right">
                          {salarySlipData.dressingAllowanceMonthEarnings}
                        </Typographys>
                      </TableCell>
                      <TableCell>
                        <Typographys variant="body1" align="right">
                          {salarySlipData.dressingAllowanceMonthDeductions}
                        </Typographys>
                      </TableCell>
                      <TableCell>
                        <Typographys variant="body1" align="right">
                          {salarySlipData.dressingAllowanceYearEarnings}
                        </Typographys>
                      </TableCell>
                      <TableCell>
                        <Typographys variant="body1" align="right">
                          {salarySlipData.dressingAllowanceYearDeductions}
                        </Typographys>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typographys variant="h6">ESIC (Employees Contribution)</Typographys>
                      </TableCell>
                      <TableCell>
                        <Typographys variant="body1" align="right">
                          {salarySlipData.esiMonthEarnings}
                        </Typographys>
                      </TableCell>
                      <TableCell>
                        <Typographys variant="body1" align="right">
                          {salarySlipData.esiMonthDeductions}
                        </Typographys>
                      </TableCell>
                      <TableCell>
                        <Typographys variant="body1" align="right">
                          {salarySlipData.esiYearEarnings}
                        </Typographys>
                      </TableCell>
                      <TableCell>
                        <Typographys variant="body1" align="right">
                          {salarySlipData.esiYearDeductions}
                        </Typographys>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typographys variant="h6">PF Contribution(Employee)</Typographys>
                      </TableCell>
                      <TableCell>
                        <Typographys variant="body1" align="right">
                          {salarySlipData.pfMonthEarnings}
                        </Typographys>
                      </TableCell>
                      <TableCell>
                        <Typographys variant="body1" align="right">
                          {salarySlipData.pfMonthDeductions}
                        </Typographys>
                      </TableCell>
                      <TableCell>
                        <Typographys variant="body1" align="right">
                          {salarySlipData.pfYearEarnings}
                        </Typographys>
                      </TableCell>
                      <TableCell>
                        <Typographys variant="body1" align="right">
                          {salarySlipData.pfYearDeductions}
                        </Typographys>
                      </TableCell>
                    </TableRow>
                    */}
                    <TableRow>
                      <TableCell>
                        <Typographys variant="h6">Total</Typographys>
                      </TableCell>
                      <TableCell>
                        <Typographys variant="h6">
                          ₹ {validateNumberElseHyphen(salarySlipData.edTableTotalData[0])}
                        </Typographys>
                      </TableCell>
                      <TableCell>
                        <Typographys variant="h6">
                          ₹ {validateNumberElseHyphen(salarySlipData.edTableTotalData[1])}
                        </Typographys>
                      </TableCell>
                      <TableCell>
                        <Typographys variant="h6">
                          ₹ {validateNumberElseHyphen(salarySlipData.edTableTotalData[2])}
                        </Typographys>
                      </TableCell>
                      <TableCell>
                        <Typographys variant="h6">
                          ₹ {validateNumberElseHyphen(salarySlipData.edTableTotalData[3])}
                        </Typographys>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </PerfectScrollbar>
      <Typographys variant="h5">
        Net Salary (Earnings - Deductions) = ₹{' '}
        {salarySlipData.netSalary ? validateNumberElseHyphen(salarySlipData.netSalary) : 0}
      </Typographys>
      <Box mt={1}>
        <Typographys variant="subtitle2" color="neutral.600">
          Net Salary (in words): {salarySlipData.netSalaryInWord}
        </Typographys>
      </Box>
      <Box mt={4.5}>
        <Typographys variant="body2" color="neutral.600">
          * This is a computer generated payslip and does not require any signature or stamp *
        </Typographys>
      </Box>
    </TableContainer>
  );
};

SalarySlipTable.defaultProps = {
  salarySlipData: {},
};
SalarySlipTable.propTypes = {
  salarySlipData: PropTypes.shape,
};

export default SalarySlipTable;
