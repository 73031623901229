import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Logout from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from 'api/login';
import { logout, setChatSessionExpired } from 'store/slices/user';
import useToast from 'components/Provider/useToast';
import { API_URL } from 'api/api';
import { hasAnyPermission } from 'utils/permissions';
import LogoutDialog from './LogoutDialog';
import { actionListStyle } from './style';

const AccountMenu = () => {
  const [loading, setLoading] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const addToast = useToast();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state?.session);

  const authorities = user?.authorities;
  const userHasViewPermission = hasAnyPermission(authorities, [
    'ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_USER_PROFILE',
    'ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_USER_PROFILE',
  ]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleProfileClick = () => {
    navigate('/profile/basic-profile');
  };

  const handleChatBotSession = () => {
    const dfMessenger = document.querySelector('df-messenger');
    if (dfMessenger) {
      dfMessenger.startNewSession({ retainHistory: false });
      sessionStorage.removeItem('chatSession');
      dispatch(setChatSessionExpired(true));
    }
  };

  const handleConfirmBtn = async () => {
    try {
      setLoading(true);
      const response = await logoutUser();
      if (response.status === 200) {
        setLoading(false);
        handleChatBotSession();
        setOpenDialog(!openDialog);
        dispatch(logout());
        localStorage.removeItem('token');
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || 'logged out successfully',
        });
        navigate('/login');
      } else {
        setOpenDialog(!openDialog);
        addToast({
          type: 'error',
          title: 'ERROR',
          message: response?.message || 'Failed to logged out',
        });
      }
    } catch (error) {
      setOpenDialog(!openDialog);
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || 'Failed to logged out',
      });
    } finally {
      setOpenDialog(!openDialog);
      setLoading(false);
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title={`${user?.firstName} ${user?.lastName}`}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar
              alt={user?.firstName || 'Avatar'}
              src={user?.profilePic && user?.profilePic !== 'null' ? `${API_URL}/${user?.profilePic}` : user?.firstName}
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{ paper: actionListStyle.wrapper }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {!!userHasViewPermission && (
          <>
            <MenuItem onClick={handleProfileClick}>
              <PersonIcon sx={{ marginRight: 2 }} /> Profile
            </MenuItem>
            <Divider />
          </>
        )}
        {/* TODO: To be enabled once UI for FAQ and Fullscreen is developed */}
        {/* <MenuItem onClick={handleClose}>
          <HelpOutlineIcon sx={{ marginRight: 2 }} /> FAQs
        </MenuItem> */}
        {/* <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <FullscreenIcon fontSize="small" />
          </ListItemIcon>
          Fullscreen
        </MenuItem> */}
        <MenuItem onClick={handleLogoutDialog}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      {openDialog && (
        <LogoutDialog open={openDialog} handleConfirmBtn={handleConfirmBtn} handleClose={handleLogoutDialog} />
      )}
    </>
  );
};
export default AccountMenu;
