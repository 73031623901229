import React from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import PropTypes from 'prop-types';
import PasswordReset from 'components/Organisms/Employee/PasswordReset';
import Typographys from 'components/Atoms/Typography';
import { Box, Skeleton } from '@mui/material';

const PasswordResetTemplate = ({ pageHeading, employeeOptions, handleSubmit, alertTitleText, alertMessage }) => {
  return (
    <BlankTemplate pageHeading={pageHeading} isBreadcrumb={false}>
      {employeeOptions.isLoading ? (
        <Box sx={{ width: '100%', padding: 2 }}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Box>
      ) : (
        <PasswordReset
          employeeOptions={employeeOptions}
          handleSubmit={handleSubmit}
          alertTitleText={alertTitleText}
          alertMessage={alertMessage}
        />
      )}
    </BlankTemplate>
  );
};

PasswordResetTemplate.defaultProps = {
  pageHeading: 'Password Reset',
  alertTitleText: 'Success!',
  alertMessage: (
    <Typographys color="neutral.800" component="div">
      An email containing details of the new reset password has been sent to
      <Typographys color="neutral.800" fontWeight="600">
        sharad@hrentries.com
      </Typographys>
    </Typographys>
  ),
  employeeOptions: [
    { id: 1, name: 'Sharad Deshpande', onClick: () => {} },
    { id: 2, name: 'Sachin Metha', onClick: () => {} },
    { id: 3, name: 'Arijit Savant', onClick: () => {} },
    { id: 4, name: 'Rahul Roy', onClick: () => {} },
    { id: 5, name: 'Ishana Shyamalan', onClick: () => {} },
    { id: 6, name: 'Ishana Shyamalan', onClick: () => {} },
  ],
  handleSubmit: () => {},
};

PasswordResetTemplate.propTypes = {
  pageHeading: PropTypes.string,
  alertTitleText: PropTypes.string,
  alertMessage: PropTypes.node,
  handleSubmit: PropTypes.func,
  employeeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
};

export default PasswordResetTemplate;
