import * as React from 'react';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import Typographys from 'components/Atoms/Typography';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { TDSWorkingStyle } from 'components/Organisms/MyPay/TDSWorking/style';

const DeductionTable = ({ deductionTableData, totalDeductionAmount }) => {
  return (
    <Paper sx={TDSWorkingStyle.deductionTableWrapper}>
      <PerfectScrollbar>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typographys variant="body2">S No.</Typographys>
                </TableCell>

                <TableCell>
                  <Typographys variant="body2">Section</Typographys>
                </TableCell>

                <TableCell>
                  <Typographys variant="body2">Investment (Proposed)</Typographys>
                </TableCell>
                <TableCell>
                  <Typographys variant="body2">Investment (Actual)</Typographys>
                </TableCell>

                <TableCell>
                  <Typographys variant="body2">Section Limit</Typographys>
                </TableCell>

                <TableCell>
                  <Typographys variant="body2">Deduction Amount</Typographys>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {deductionTableData.map((data, index) => (
                <TableRow key={data.section} sx={TDSWorkingStyle.deductionTableRows}>
                  <TableCell>
                    <Typographys variant="body2" color="neutral.600">
                      {index + 1}
                    </Typographys>
                  </TableCell>
                  <TableCell>
                    <Typographys variant="body2" color="neutral.600">
                      {data.section}
                    </Typographys>
                  </TableCell>
                  <TableCell>
                    <Typographys variant="body2" color="neutral.600">
                      {data.investmentProposed}
                    </Typographys>
                  </TableCell>
                  <TableCell>
                    <Typographys variant="body2" color="neutral.600">
                      {data.investmentActual}
                    </Typographys>
                  </TableCell>
                  <TableCell>
                    <Typographys variant="body2" color="neutral.600">
                      {data.sectionLimit}
                    </Typographys>
                  </TableCell>
                  <TableCell>
                    <Typographys variant="body2" color="neutral.600">
                      {data.deductionAmount}
                    </Typographys>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PerfectScrollbar>

      <Box sx={TDSWorkingStyle.deductionTableFooter}>
        <Box sx={{ flexBasis: '20%' }}>
          <Typographys variant="subtitle2">Total Deductions:</Typographys>
        </Box>
        <Box sx={{ flexBasis: '80%' }}>
          <Typographys variant="subtitle2">{totalDeductionAmount}</Typographys>
        </Box>
      </Box>
    </Paper>
  );
};

DeductionTable.defaultProps = {
  deductionTableData: [],
  totalDeductionAmount: '',
};

DeductionTable.propTypes = {
  deductionTableData: PropTypes.arrayOf(
    PropTypes.shape({
      section: PropTypes.string,
      investmentProposed: PropTypes.string,
      investmentActual: PropTypes.string,
      sectionLimit: PropTypes.string,
      deductionAmount: PropTypes.string,
    }),
  ),
  totalDeductionAmount: PropTypes.string,
};

export default DeductionTable;
