export const payOutStyle = {
  textBox: {
    width: '100%',
  },
  separator1: {
    display: 'flex',
    alignItems: 'center',
    mt: 4,
    pb: 2.5,
  },
  separator2: {
    display: 'flex',
    alignItems: 'center',
    borderTop: '0.125rem solid',
    borderColor: 'other.200',
    mt: 3,
    pt: 2,
    pb: 2.75,
  },
  separator3: {
    display: 'flex',
    alignItems: 'center',
    borderTop: '0.125rem solid',
    borderColor: 'other.200',
    mt: 3,
    pt: 2,
    pb: 2.75,
  },
  tooltip: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1rem',
    '& .MuiBox-root': {
      display: 'flex',
      alignItems: 'center',
    },
  },
};
