import React from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import PropTypes from 'prop-types';
import LCPolicyDetails from 'components/Organisms/Employee/LCPolicies/Details';

const LCPolicyDetailsTemplate = ({
  breadcrumbData,
  pageHeading,
  policyDetails,
  menuLists,
  handleClick,
  transferApproverDetails,
  transferApproverLists,
  promotionApproverDetails,
  promotionApproverLists,
  demotionApproverDetails,
  demotionApproverLists,
  confirmationAApproverDetails,
  confirmationApproverLists,
  terminationApproverDetails,
  terminationApproverLists,
  probationExtensionApproverDetails,
  probationExtensionApproverLists,
  pipInitiationApproverDetails,
  pipInitiationApproverLists,
  pipConclusionApproverDetails,
  pipConclusionApproverLists,
  bgVerificationApproverDetails,
  bgVerificationApproverLists,
}) => {
  return (
    <BlankTemplate pageHeading={pageHeading} isBreadcrumb breadcrumbData={breadcrumbData} padding="0">
      <LCPolicyDetails
        policyDetails={policyDetails}
        menuLists={menuLists}
        handleClick={handleClick}
        transferApproverDetails={transferApproverDetails}
        transferApproverLists={transferApproverLists}
        promotionApproverDetails={promotionApproverDetails}
        promotionApproverLists={promotionApproverLists}
        demotionApproverDetails={demotionApproverDetails}
        demotionApproverLists={demotionApproverLists}
        confirmationAApproverDetails={confirmationAApproverDetails}
        confirmationApproverLists={confirmationApproverLists}
        terminationApproverDetails={terminationApproverDetails}
        terminationApproverLists={terminationApproverLists}
        probationExtensionApproverDetails={probationExtensionApproverDetails}
        probationExtensionApproverLists={probationExtensionApproverLists}
        pipInitiationApproverDetails={pipInitiationApproverDetails}
        pipInitiationApproverLists={pipInitiationApproverLists}
        pipConclusionApproverDetails={pipConclusionApproverDetails}
        pipConclusionApproverLists={pipConclusionApproverLists}
        bgVerificationApproverDetails={bgVerificationApproverDetails}
        bgVerificationApproverLists={bgVerificationApproverLists}
      />
    </BlankTemplate>
  );
};

LCPolicyDetailsTemplate.defaultProps = {
  pageHeading: 'Default life Cycle Policy',
  breadcrumbData: [
    { id: 1, name: 'Life Cycle Policies', path: '/LCPolicyList', isActive: false },
    { id: 2, name: 'View Details', isActive: true },
  ],
  policyDetails: {
    name: 'Default Life Cycle Policy',
    status: true,
    createdOn: '22-Feb-2023',
    createdBy: 'Setup Admin(0)',
    defaultPolicyAdministrator: 'Aarzoo Khurana (T292)',
    description: 'Vel diam ultircies dignismems augue',
  },
  menuLists: [{ id: 1, label: 'Delete', onClick: () => {} }],
  handleClick: () => {},
  transferApproverDetails: {
    approverType: 'Reporting Manager',
    approverSLA: '3.0',
    approverUnit: 'Days',
  },
  transferApproverLists: [
    {
      id: 1,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
    {
      id: 2,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
  ],
  promotionApproverDetails: {
    approverType: 'Reporting Manager',
    approverSLA: '3.0',
    approverUnit: 'Days',
  },
  promotionApproverLists: [
    {
      id: 1,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
    {
      id: 2,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
  ],
  demotionApproverDetails: {
    approverType: 'Reporting Manager',
    approverSLA: '3.0',
    approverUnit: 'Days',
  },
  demotionApproverLists: [
    {
      id: 1,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
    {
      id: 2,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
  ],
  confirmationAApproverDetails: {
    approverType: 'Reporting Manager',
    approverSLA: '3.0',
    approverUnit: 'Days',
  },
  confirmationApproverLists: [
    {
      id: 1,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
    {
      id: 2,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
  ],
  terminationApproverDetails: {
    approverType: 'Reporting Manager',
    approverSLA: '3.0',
    approverUnit: 'Days',
  },
  terminationApproverLists: [
    {
      id: 1,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
    {
      id: 2,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
  ],
  probationExtensionApproverDetails: {
    approverType: 'Reporting Manager',
    approverSLA: '3.0',
    approverUnit: 'Days',
  },
  probationExtensionApproverLists: [
    {
      id: 1,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
    {
      id: 2,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
  ],
  pipInitiationApproverDetails: {
    approverType: 'Reporting Manager',
    approverSLA: '3.0',
    approverUnit: 'Days',
  },
  pipInitiationApproverLists: [
    {
      id: 1,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
    {
      id: 2,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
  ],
  pipConclusionApproverDetails: {
    approverType: 'Reporting Manager',
    approverSLA: '3.0',
    approverUnit: 'Days',
  },
  pipConclusionApproverLists: [
    {
      id: 1,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
    {
      id: 2,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
  ],
  bgVerificationApproverDetails: {
    approverType: 'Reporting Manager',
    approverSLA: '3.0',
    approverUnit: 'Days',
  },
  bgVerificationApproverLists: [
    {
      id: 1,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
    {
      id: 2,
      name: 'Abhijeet Tiwari (T330)',
      effectedBy: 'Aachman kabir(1001)',
    },
  ],
};

LCPolicyDetailsTemplate.propTypes = {
  pageHeading: PropTypes.string,
  breadcrumbData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
  policyDetails: PropTypes.shape({
    name: PropTypes.string,
    status: PropTypes.string,
    createdOn: PropTypes.string,
    createdBy: PropTypes.string,
    defaultPolicyAdministrator: PropTypes.string,
    description: PropTypes.string,
  }),
  menuLists: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  handleClick: PropTypes.func,
  transferApproverDetails: PropTypes.shape({
    approverType: PropTypes.string,
    approverSLA: PropTypes.number,
    approverUnit: PropTypes.string,
  }),
  transferApproverLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      effectedBy: PropTypes.string,
    }),
  ),
  promotionApproverDetails: PropTypes.shape({
    approverType: PropTypes.string,
    approverSLA: PropTypes.number,
    approverUnit: PropTypes.string,
  }),
  promotionApproverLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      effectedBy: PropTypes.string,
    }),
  ),
  demotionApproverDetails: PropTypes.shape({
    approverType: PropTypes.string,
    approverSLA: PropTypes.number,
    approverUnit: PropTypes.string,
  }),
  demotionApproverLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      effectedBy: PropTypes.string,
    }),
  ),
  confirmationAApproverDetails: PropTypes.shape({
    approverType: PropTypes.string,
    approverSLA: PropTypes.number,
    approverUnit: PropTypes.string,
  }),
  confirmationApproverLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      effectedBy: PropTypes.string,
    }),
  ),
  terminationApproverDetails: PropTypes.shape({
    approverType: PropTypes.string,
    approverSLA: PropTypes.number,
    approverUnit: PropTypes.string,
  }),
  terminationApproverLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      effectedBy: PropTypes.string,
    }),
  ),
  probationExtensionApproverDetails: PropTypes.shape({
    approverType: PropTypes.string,
    approverSLA: PropTypes.number,
    approverUnit: PropTypes.string,
  }),
  probationExtensionApproverLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      effectedBy: PropTypes.string,
    }),
  ),
  pipInitiationApproverDetails: PropTypes.shape({
    approverType: PropTypes.string,
    approverSLA: PropTypes.number,
    approverUnit: PropTypes.string,
  }),
  pipInitiationApproverLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      effectedBy: PropTypes.string,
    }),
  ),
  pipConclusionApproverDetails: PropTypes.shape({
    approverType: PropTypes.string,
    approverSLA: PropTypes.number,
    approverUnit: PropTypes.string,
  }),
  pipConclusionApproverLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      effectedBy: PropTypes.string,
    }),
  ),
  bgVerificationApproverDetails: PropTypes.shape({
    approverType: PropTypes.string,
    approverSLA: PropTypes.number,
    approverUnit: PropTypes.string,
  }),
  bgVerificationApproverLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      effectedBy: PropTypes.string,
    }),
  ),
};

export default LCPolicyDetailsTemplate;
