import React from 'react';
import { Typography, Skeleton, Box } from '@mui/material';
import useFetcher from 'hooks/useFetcher';
import { getProfileSalaryDetails } from 'api/dashboard';
import { useNavigate } from 'react-router-dom';
import MyPayDashboardTemplate from 'components/Templates/MyPay/Dashboard';
import { getDashboardPayDetails } from './getDashboardPayDetails';

const MyPayDashboard = () => {
  const [myDashboardDetails] = useFetcher(getProfileSalaryDetails);
  const configuredData = getDashboardPayDetails(myDashboardDetails);
  const navigate = useNavigate();

  if (myDashboardDetails.error !== null) {
    return (
      <Typography variant="h6">
        {myDashboardDetails?.error?.response?.data?.message || 'Error while fetching salary dashboard details.'}
      </Typography>
    );
  }

  const actionMenuLists = [
    {
      id: 1,
      label: 'View Salary Structure',
      onClick: () => {
        navigate('/home/mypay/my-salary-details');
      },
    },
    {
      id: 2,
      label: 'View TDS Workings',
      onClick: () => {
        navigate('/home/mypay/tds-workings');
      },
    },
    {
      id: 3,
      label: 'View Declaration',
      onClick: () => {
        navigate('/home/mypay/tds-declaration');
      },
    },
  ];

  return myDashboardDetails.isLoading ? (
    <Box sx={{ p: 4 }}>
      <Skeleton height={70} />
      <Skeleton />
      <Skeleton />
    </Box>
  ) : (
    <MyPayDashboardTemplate
      salaryDetails={configuredData.salaryDetails}
      grossAmount={configuredData.grossAmount}
      dataWiseDetails={configuredData.dataWiseDetails}
      dataWiseAggregates={configuredData.dataWiseAggregates}
      data={configuredData.data}
      lastCredited={configuredData.lastCredited}
      actionMenuLists={actionMenuLists}
    />
  );
};

export default MyPayDashboard;
