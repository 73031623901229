import { makeStyles } from '@mui/styles';
import { getStyleWithImportant } from 'components/text-editor/getStyleWithImportant';
import { v4 as uuid } from 'uuid';

const uid = uuid();

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    authorContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    image: {
      borderRadius: theme.shape.radiusRound,
      height: theme.sizing.sizeS,
      width: theme.sizing.sizeS,
      objectFit: 'content',
    },
    label: {
      color: theme.palette.colors.colorForegroundNeutral,
      marginLeft: getStyleWithImportant(theme.space.spaceS2x),
    },
    date: {
      color: theme.palette.colors.colorForegroundNeutralLight,
      lineHeight: theme.space.spaceS4x,
      marginRight: getStyleWithImportant(theme.space.spaceS2x),
    },
    dateAndMenuContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
  {
    classNamePrefix: 'textEditorHeader',
    name: 'textEditorHeader',
    generateId: uid,
    index: uid,
  },
);
