import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import Button from 'components/Atoms/Button';
import EditIcon from '@mui/icons-material/Edit';
import TitleWithText from 'components/Atoms/TitleWithText';
import NoRecordsMessage from 'components/Organisms/CommonPageUtils/NoRecordsFound';
import Typographys from 'components/Atoms/Typography';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { hasPermission } from 'utils/permissions';
import { useSelector } from 'react-redux';
import { profileStyle } from './style';

const ProfileSkillsDetails = ({ skillDetails, handleEdit, handleAttachment }) => {
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasEditPermission = hasPermission(authorities, ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_UPDATE_MY_SKILLS']);

  return (
    <>
      <Box sx={profileStyle.actionBtn}>
        {!!userHasEditPermission && (
          <Button startIcon={<EditIcon fontSize="2.5rem" />} onClick={handleEdit}>
            {skillDetails.length > 0 ? 'Edit' : 'Add'}
          </Button>
        )}
      </Box>

      {skillDetails.length > 0 ? (
        <Box mt={4}>
          {skillDetails.map((skillDetail) => (
            <Box sx={profileStyle.formDivider}>
              <Grid container rowSpacing={2} columnSpacing={4}>
                <Grid item xs={12} sm={4} lg={3}>
                  <TitleWithText title="Skill" text={skillDetail.skill} />
                </Grid>

                <Grid item xs={12} sm={4} lg={3}>
                  <TitleWithText title="Level" text={skillDetail.level} />
                </Grid>

                <Grid item xs={12} sm={12} lg={12}>
                  <TitleWithText title="Comments" text={skillDetail.comments} />
                </Grid>

                <Grid item xs={12} mt={3}>
                  <Typographys variant="h6" color="neutral.800">
                    Attachment
                  </Typographys>
                  <Button
                    variant="text"
                    color="secondary"
                    onClick={() => handleAttachment(skillDetail?.attachmentsId, skillDetail?.attachments)}
                    startIcon={<AttachFileIcon />}
                  >
                    {skillDetail?.attachments || ''}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Box>
      ) : (
        <NoRecordsMessage width="20rem" height="20rem" />
      )}
    </>
  );
};

ProfileSkillsDetails.defaultProps = {
  skillDetails: [],
  handleEdit: () => {},
  handleAttachment: () => {},
};

ProfileSkillsDetails.propTypes = {
  skillDetails: PropTypes.arrayOf,
  handleEdit: PropTypes.func,
  handleAttachment: PropTypes.func,
};

export default ProfileSkillsDetails;
