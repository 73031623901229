import React, { useCallback, useEffect } from 'react';
import TaskAndQueriesTemplate from 'components/Templates/TaskAndQueries';
import useController from 'hooks/useController';
import usePaginationAttributes from 'hooks/use-pagination-attributes';
import { Box, Typography } from '@mui/material';
import DeleteQuery from 'components/Organisms/Dialog/ActionList/TaskAndQueries/DeleteQuery';
import { hasPermission } from 'utils/permissions';
import { postListApiFormat, getListApiFormat } from 'utils/ApiPayloadFormat';
import { useSelector } from 'react-redux';
import { useThunk } from 'hooks/useThunk';
import { fetchMyQueriesList } from 'store';
import useServices from '../common/useServices';
import useFilters from '../common/useFilters';
import useColumns from '../common/useColumns';
import useActionItems from '../common/useActionItems';

const ListMyQueries = () => {
  const { pageSize } = usePaginationAttributes();
  const [columns] = useColumns();
  const services = useServices();
  const [controller, handlePageChange, handleSortChange, handleFiltersApply, handleFiltersClear] = useController();

  const [getQueriesList, isLoadingQueriesList, loadingQueriesListError] = useThunk(fetchMyQueriesList);

  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasCreatePermission = hasPermission(authorities, ['ROLE_BASE_PERMISSION_QUER_INDEX_CREATE_QUERY']);
  const userHasFilterQueryPermission = hasPermission(authorities, ['ROLE_BASE_PERMISSION_QUER_INDEX_SEARCH_QUERY']);

  const [roleBasedMenu] = useActionItems(services);

  const { queries = {} } = useSelector((state) => state?.queries);

  const filterService = useFilters(controller, queries);

  const queryOwnerId = queries?.currentEmployee?.id || '';

  const filtersExist = controller?.filters && Object.keys(controller?.filters).length > 0;

  const fetchQueriesListAction = useCallback(async () => {
    if (filtersExist) {
      const formData = postListApiFormat(controller, pageSize);
      formData.append('openedBy', queryOwnerId);
      services.searchQueriesService(formData);
    } else {
      const queryData = getListApiFormat(controller, pageSize);
      getQueriesList(queryData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getQueriesList, controller, pageSize]);

  useEffect(() => {
    fetchQueriesListAction();
  }, [fetchQueriesListAction, controller, pageSize]);

  if (loadingQueriesListError !== null) {
    return (
      <Typography variant="h6">{loadingQueriesListError?.message || 'Error while loading my queries list.'}</Typography>
    );
  }

  const filteredData = services?.state?.listQueries?.data?.data;
  return (
    <Box>
      <TaskAndQueriesTemplate
        loading={filtersExist ? services?.state?.listQueries?.loading : isLoadingQueriesList}
        pageHeading="My Queries"
        rows={filtersExist ? filteredData?.queryInstanceList : queries?.data || []}
        columns={columns}
        roleBasedMenu={roleBasedMenu}
        page={controller?.page || 0}
        sortDirection={controller.sort}
        sortBy={controller.sortBy}
        handleAddRequest={services.createQueryNavigationService}
        handleFilter={(data) => handleFiltersApply(data)}
        showFilter={!!userHasFilterQueryPermission}
        onSortChange={handleSortChange}
        onPageChange={handlePageChange}
        showPagination
        showRowSelector={false}
        isDynamic
        showActionList
        count={filtersExist ? filteredData?.totalCount : queries?.count || 0}
        filterFields={filterService?.filterFields}
        handleFiltersClear={handleFiltersClear}
        showAddButton={userHasCreatePermission}
      />
      {services?.state?.open && (
        <DeleteQuery
          open={services?.state?.open}
          handleClose={() => services.handleOpenDialog(false)}
          handleCancel={() => services.handleOpenDialog(false)}
          handleDelete={() => {
            services?.deleteQueryService(services?.state?.queryDetails?.id);
          }}
          queryTitle={services?.state?.queryDetails?.title}
        />
      )}
    </Box>
  );
};

export default ListMyQueries;
