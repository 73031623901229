import React from 'react';
import PropTypes from 'prop-types';
import { Box, LinearProgress } from '@mui/material';
import Typographys from '../Typography';

const ProgressBar = ({ value, isLabel, variant, align, labelColor, progressBarColor }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ width: '100%' }}>
        <LinearProgress variant="determinate" value={value} color={progressBarColor} />
      </Box>
      {isLabel && (
        <Box sx={{ width: '100%', mt: 0.5 }}>
          <Typographys variant={variant} align={align} color={labelColor}>{`${value}%`}</Typographys>
        </Box>
      )}
    </Box>
  );
};

ProgressBar.defaultProps = {
  progressBarColor: 'secondary',
  value: 50,
  isLabel: true,
  variant: 'subtitle1',
  align: 'center',
  labelColor: 'neutral.600',
};

ProgressBar.propTypes = {
  progressBarColor: PropTypes.string,
  value: PropTypes.number,
  isLabel: PropTypes.bool,
  variant: PropTypes.string,
  align: PropTypes.string,
  labelColor: PropTypes.string,
};

export default ProgressBar;
