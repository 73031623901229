import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import Avatars from 'components/Molecules/Avatar';
import { profileCardStyle } from './style';

const ProfileListItem = ({ userName, designation, avatarSrc, date, badge, badgeColor }) => {
  return (
    <Box sx={profileCardStyle.profileCard}>
      <Avatars
        variant="circular"
        name={userName}
        isShortName
        avatarSrc={avatarSrc}
        badge={badge}
        badgeColor={badgeColor}
      />
      <Box sx={profileCardStyle.profileCardContent}>
        <Box>
          <Typographys variant="subtitle2" color="neutral.700">
            {userName}
          </Typographys>
          <Typographys variant="caption" color="neutral.600">
            {designation}
          </Typographys>
        </Box>
        <Box>
          <Typographys variant="caption" color="neutral.600">
            {date}
          </Typographys>
        </Box>
      </Box>
    </Box>
  );
};

ProfileListItem.defaultProps = {
  userName: 'Brooklyn Simmons',
  designation: 'Graphic designer',
  avatarSrc: 'images/employeeAvatar.svg',
  date: 'Oct 24',
  badge: false,
  badgeColor: 'success.400',
};

ProfileListItem.propTypes = {
  userName: PropTypes.string,
  designation: PropTypes.string,
  avatarSrc: PropTypes.string,
  date: PropTypes.string,
  badge: PropTypes.bool,
  badgeColor: PropTypes.string,
};

export default ProfileListItem;
