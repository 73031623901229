import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Typographys from 'components/Atoms/Typography';

const CommonFilledCard = ({
  heading,
  headingColor,
  headingMaxWidth,
  bottomSpace,
  value,
  valueColor,
  boxBackground,
  boxPadding,
  boxBorderRadius,
  boxBorder,
  boxBorderColor,
  minimumHeight,
}) => {
  return (
    <Box
      sx={{
        bgcolor: boxBackground,
        padding: boxPadding,
        borderRadius: boxBorderRadius,
        border: boxBorder,
        borderColor: boxBorderColor,
        minHeight: minimumHeight, // Use the prop for minHeight
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box maxWidth={headingMaxWidth} mb={bottomSpace}>
        <Typographys variant="overline" color={headingColor} display="block">
          {heading}
        </Typographys>
      </Box>
      <Typographys variant="h6" color={valueColor}>
        {value}
      </Typographys>
    </Box>
  );
};

CommonFilledCard.defaultProps = {
  heading: 'OPENING BALANCE',
  headingColor: 'neutral.600',
  headingMaxWidth: '100%',
  bottomSpace: '0',
  value: '24',
  valueColor: 'neutral.800',
  boxBackground: 'info.200',
  boxPadding: '2rem',
  boxBorderRadius: '0.75rem',
  boxBorder: '',
  boxBorderColor: '',
  minimumHeight: {},
};
CommonFilledCard.propTypes = {
  heading: PropTypes.string,
  headingColor: PropTypes.string,
  headingMaxWidth: PropTypes.string,
  bottomSpace: PropTypes.string,
  value: PropTypes.number,
  valueColor: PropTypes.string,
  boxBackground: PropTypes.string,
  boxPadding: PropTypes.string,
  boxBorderRadius: PropTypes.string,
  boxBorder: PropTypes.string,
  boxBorderColor: PropTypes.string,
  minimumHeight: PropTypes.shape({}),
};

export default CommonFilledCard;
