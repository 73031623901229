import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Divider, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Button from 'components/Atoms/Button';
import Typographys from 'components/Atoms/Typography';
import InputField from 'components/Atoms/InputField';
import Dropdown from 'components/Molecules/Dropdown';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import * as yup from 'yup';
import { Formik, FieldArray, getIn } from 'formik';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Typography from '@mui/material/Typography';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { profileStyle } from '../../style';

const ProfileEditBankAccount = ({
  heading,
  handleSubmit,
  handleCancel,
  transactionTypeOptions,
  accountCurrencyOptions,
  purposeOptions,
  accountTypeOptions,
  defaultValue,
  loading,
}) => {
  const defaultObj = {
    accountNumber: '',
    accountName: '',
    accountType: '',
    accountCurrency: '',
    purpose: '',
    transactionType: '',
    bankName: '',
    branchName: '',
    ifsc: '',
    swiftCode: '',
    locationName: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
  };

  const BankAccountSchema = yup.object().shape({
    bank: yup.array().of(
      yup.object().shape({
        accountNumber: yup
          .string()
          .matches(/^[0-9]{9,18}$/, 'Invalid Account Number')
          .required('Required'),
        accountName: yup.string().required('Required'),
        accountType: yup.string().required('Required'),
        bankName: yup.string().required('Required'),
        branchName: yup.string().required('Required'),
        accountCurrency: yup.mixed().required('Required'),
        ifsc: yup
          .string()
          .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, 'Invalid IFSC Code')
          .required('Required'),
        addressLine1: yup.string(),
        city: yup.string(),
        country: yup.string(),
        state: yup.string(),
        pincode: yup.string().matches(/^[0-9]{5,6}$/, 'Invalid Pin Code'),
      }),
    ),
  });

  return (
    <Box>
      <Typographys variant="h5" color="neutral.800">
        {heading}
      </Typographys>
      <Formik
        validationSchema={BankAccountSchema}
        validateOnMount
        initialValues={defaultValue}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(formik) => (
          <Form
            formik={formik}
            handleCancel={handleCancel}
            transactionTypeOptions={transactionTypeOptions}
            purposeOptions={purposeOptions}
            accountTypeOptions={accountTypeOptions}
            accountCurrencyOptions={accountCurrencyOptions}
            defaultObj={defaultObj}
            loading={loading}
          />
        )}
      </Formik>
    </Box>
  );
};

const Form = ({
  formik,
  handleCancel,
  transactionTypeOptions,
  accountTypeOptions,
  accountCurrencyOptions,
  purposeOptions,
  loading,
  defaultObj,
}) => {
  const theme = useTheme();
  return (
    <Box mt={1} component="form" onSubmit={formik.handleSubmit}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 44rem)' }}>
          <FieldArray name="bank">
            {({ push, remove }) => (
              <>
                {formik.values.bank.map((b, index) => {
                  return (
                    <Box sx={profileStyle.formDivider}>
                      <Box sx={profileStyle.deleteContainer}>
                        <Typography variant="subtitle2" color="neutral.800">
                          Record {index + 1}
                        </Typography>
                        <Button
                          startIcon={<DeleteForeverIcon />}
                          onClick={(event) => {
                            event.preventDefault();
                            remove(index);
                          }}
                          variant="outlined"
                        >
                          Delete
                        </Button>
                      </Box>
                      <Typographys variant="subtitle1" color="neutral.800">
                        Account Details
                      </Typographys>

                      <Grid container rowSpacing={2} columnSpacing={2} sx={profileStyle.formContainer}>
                        <Grid item xs={12} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].accountNumber`) &&
                                getIn(formik.errors, `bank[${index}].accountNumber`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].accountNumber`) &&
                              getIn(formik.errors, `bank[${index}].accountNumber`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={b.accountNumber}
                            name={`bank[${index}].accountNumber`}
                            id={`bank[${index}].accountNumber`}
                            label="Account Number*"
                            type="Number"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].accountName`) &&
                                getIn(formik.errors, `bank[${index}].accountName`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].accountName`) &&
                              getIn(formik.errors, `bank[${index}].accountName`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={b.accountName}
                            name={`bank[${index}].accountName`}
                            id={`bank[${index}].accountName`}
                            label="Account Name*"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Dropdown
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].accountType`) &&
                                getIn(formik.errors, `bank[${index}].accountType`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].accountType`) &&
                              getIn(formik.errors, `bank[${index}].accountType`)
                            }
                            handleChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={b.accountType}
                            name={`bank[${index}].accountType`}
                            id={`bank[${index}].accountType`}
                            label="Account Type*"
                            options={accountTypeOptions}
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Dropdown
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].accountCurrency`) &&
                                getIn(formik.errors, `bank[${index}].accountCurrency`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].accountCurrency`) &&
                              getIn(formik.errors, `bank[${index}].accountCurrency`)
                            }
                            handleChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={b.accountCurrency}
                            name={`bank[${index}].accountCurrency`}
                            id={`bank[${index}].accountCurrency`}
                            label="Account Currency*"
                            options={accountCurrencyOptions}
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Dropdown
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].purpose`) &&
                                getIn(formik.errors, `bank[${index}].purpose`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].purpose`) &&
                              getIn(formik.errors, `bank[${index}].purpose`)
                            }
                            handleChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={b.purpose}
                            name={`bank[${index}].purpose`}
                            id={`bank[${index}].purpose`}
                            label="Purpose"
                            options={purposeOptions}
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Dropdown
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].transactionType`) &&
                                getIn(formik.errors, `bank[${index}].transactionType`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].transactionType`) &&
                              getIn(formik.errors, `bank[${index}].transactionType`)
                            }
                            handleChange={(e) => {
                              formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={b.transactionType}
                            name={`bank[${index}].transactionType`}
                            id={`bank[${index}].transactionType`}
                            label="Transaction Type"
                            options={transactionTypeOptions}
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].bankName`) &&
                                getIn(formik.errors, `bank[${index}].bankName`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].bankName`) &&
                              getIn(formik.errors, `bank[${index}].bankName`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={b.bankName}
                            name={`bank[${index}].bankName`}
                            id={`bank[${index}].bankName`}
                            label="Bank Name*"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].branchName`) &&
                                getIn(formik.errors, `bank[${index}].branchName`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].branchName`) &&
                              getIn(formik.errors, `bank[${index}].branchName`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={b.branchName}
                            name={`bank[${index}].branchName`}
                            id={`bank[${index}].branchName`}
                            label="Branch Name*"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].ifsc`) &&
                                getIn(formik.errors, `bank[${index}].ifsc`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].ifsc`) &&
                              getIn(formik.errors, `bank[${index}].ifsc`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={b.ifsc}
                            name={`bank[${index}].ifsc`}
                            id={`bank[${index}].ifsc`}
                            label="IFSC Code*"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].swiftCode`) &&
                                getIn(formik.errors, `bank[${index}].swiftCode`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].swiftCode`) &&
                              getIn(formik.errors, `bank[${index}].swiftCode`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={b.swiftCode}
                            name={`bank[${index}].swiftCode`}
                            id={`bank[${index}].swiftCode`}
                            label="Swift Code (Optional)"
                            fullWidth
                          />
                        </Grid>
                      </Grid>

                      <Typographys variant="subtitle1" color="neutral.800">
                        Branch Address
                      </Typographys>

                      <Grid container rowSpacing={2} columnSpacing={2} sx={profileStyle.formContainer}>
                        <Grid item xs={12} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].locationName`) &&
                                getIn(formik.errors, `bank[${index}].locationName`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].locationName`) &&
                              getIn(formik.errors, `bank[${index}].locationName`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={b.locationName}
                            name={`bank[${index}].locationName`}
                            id={`bank[${index}].locationName`}
                            label="Location Name (Optional)"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].addressLine1`) &&
                                getIn(formik.errors, `bank[${index}].addressLine1`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].addressLine1`) &&
                              getIn(formik.errors, `bank[${index}].addressLine1`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={b.addressLine1}
                            name={`bank[${index}].addressLine1`}
                            id={`bank[${index}].addressLine1`}
                            label="Address Line 1"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].addressLine2`) &&
                                getIn(formik.errors, `bank[${index}].addressLine2`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].addressLine2`) &&
                              getIn(formik.errors, `bank[${index}].addressLine2`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={b.addressLine2}
                            name={`bank[${index}].addressLine2`}
                            id={`bank[${index}].addressLine2`}
                            label="Address Line 2"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].addressLine3`) &&
                                getIn(formik.errors, `bank[${index}].addressLine3`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].addressLine3`) &&
                              getIn(formik.errors, `bank[${index}].addressLine3`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={b.addressLine3}
                            name={`bank[${index}].addressLine3`}
                            id={`bank[${index}].addressLine3`}
                            label="Address Line 3"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].city`) &&
                                getIn(formik.errors, `bank[${index}].city`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].city`) &&
                              getIn(formik.errors, `bank[${index}].city`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={b.city}
                            name={`bank[${index}].city`}
                            id={`bank[${index}].city`}
                            label="City"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].state`) &&
                                getIn(formik.errors, `bank[${index}].state`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].state`) &&
                              getIn(formik.errors, `bank[${index}].state`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={b.state}
                            name={`bank[${index}].state`}
                            id={`bank[${index}].state`}
                            label="State"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].country`) &&
                                getIn(formik.errors, `bank[${index}].country`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].country`) &&
                              getIn(formik.errors, `bank[${index}].country`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={b.country}
                            name={`bank[${index}].country`}
                            id={`bank[${index}].country`}
                            label="Country"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <InputField
                            error={Boolean(
                              getIn(formik.touched, `bank[${index}].pincode`) &&
                                getIn(formik.errors, `bank[${index}].pincode`),
                            )}
                            helperText={
                              getIn(formik.touched, `bank[${index}].pincode`) &&
                              getIn(formik.errors, `bank[${index}].pincode`)
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={b.pincode}
                            name={`bank[${index}].pincode`}
                            id={`bank[${index}].pincode`}
                            label="Pincode"
                            type="number"
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })}
                <Box sx={profileStyle.addButton}>
                  <Button
                    size="medium"
                    variant="outlined"
                    type="button"
                    startIcon={<AddOutlinedIcon />}
                    onClick={() => push(defaultObj)}
                  >
                    Add Bank Details
                  </Button>
                </Box>
              </>
            )}
          </FieldArray>
        </Box>
      </PerfectScrollbar>

      <Box sx={profileStyle.btnContainer}>
        <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />
        <Box sx={profileStyle.submitWrapper}>
          <Button
            size="large"
            color="info"
            variant="outlined"
            type="button"
            onClick={() => {
              formik.resetForm();
              handleCancel();
            }}
          >
            Cancel
          </Button>
          <LoadingButton loading={loading} color="primary" size="large" type="submit" variant="contained">
            Update
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

Form.defaultProps = {
  handleCancel: () => {},
  transactionTypeOptions: [],
  accountTypeOptions: [],
  accountCurrencyOptions: [],
  purposeOptions: [],
  formik: {},
  loading: false,
  defaultObj: {},
};

Form.propTypes = {
  handleCancel: PropTypes.func,
  loading: PropTypes.bool,
  transactionTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  accountCurrencyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  accountTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  purposeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  formik: PropTypes.shape({
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    values: PropTypes.shape({
      bank: PropTypes.shape([]),
    }),
    resetForm: PropTypes.func,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
  }),
  defaultObj: PropTypes.shape({}),
};

ProfileEditBankAccount.defaultProps = {
  heading: 'Primary bank Details',
  handleSubmit: () => {},
  handleCancel: () => {},
  transactionTypeOptions: [],
  accountTypeOptions: [],
  accountCurrencyOptions: [],
  purposeOptions: [],
  loading: false,
  defaultValue: {
    bank: [
      {
        accountNumber: '',
        accountName: '',
        accountType: '',
        accountCurrency: '',
        purpose: '',
        transactionType: '',
        bankName: '',
        branchName: '',
        ifsc: '',
        swiftCode: '',
        locationName: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        city: '',
        state: '',
        country: '',
        pincode: '',
      },
    ],
  },
};

ProfileEditBankAccount.propTypes = {
  heading: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  loading: PropTypes.bool,

  transactionTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  accountTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  purposeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  accountCurrencyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  defaultValue: PropTypes.shape({}),
};

export default ProfileEditBankAccount;
