import { useReducer } from 'react';
import { useParams } from 'react-router-dom';
import FormData from 'form-data';
import useToast from 'components/Provider/useToast';
import { addAccessFilterAction, modifyAccessFilterAction, deleteAccessFilterAction } from 'api/employees';

const initialPolicyState = {
  refresPage: false,
  open: false,
  openDeleteDialog: false,
  isModify: false,
  initialValues: {
    id: null,
    filterStatus: '',
    filtertype: '',
    filtersubtype: '',
    allowedentities: '',
  },
};

const reducer = (state, action) => {
  const { type, payload } = action;
  let newState;
  switch (type) {
    case 'OPEN_DIALOG': {
      newState = { ...state, open: payload };
      break;
    }
    case 'SET_INITIAL_VALUES': {
      const { id, filterStatus, filtertype, filtersubtype, allowedentities, isModify } = payload;
      newState = {
        ...state,
        initialValues: {
          ...state.initialValues,
          id,
          filterStatus,
          filtertype,
          filtersubtype,
          allowedentities,
        },
        isModify,
      };
      break;
    }
    case 'OPEN_DELETE_DIALOG': {
      newState = { ...state, openDeleteDialog: payload };
      break;
    }
    case 'REFRESH_PAGE': {
      newState = { ...state, refreshPage: payload };
      break;
    }
    default:
      newState = state;
  }

  return newState;
};
const useServices = () => {
  const [state, dispatch] = useReducer(reducer, initialPolicyState);
  const addToast = useToast();
  const { userId } = useParams();

  const resetInitialValues = () => {
    dispatch({
      type: 'SET_INITIAL_VALUES',
      payload: {
        id: null,
        filterStatus: '',
        filtertype: '',
        filtersubtype: '',
        allowedentities: '',
        isModify: false,
      },
    });
  };

  const handleOpenDialog = (dialogState) => {
    resetInitialValues();
    dispatch({ type: 'OPEN_DIALOG', payload: dialogState });
  };

  const handleOpenDeleteDialog = (dialogState) => {
    dispatch({ type: 'OPEN_DELETE_DIALOG', payload: dialogState });
  };

  const addAccessFilter = async (values) => {
    let response;
    const bodyFormData = new FormData();
    try {
      dispatch({ type: 'REFRESH_PAGE', payload: false });
      dispatch({ type: 'OPEN_DIALOG', payload: true });
      bodyFormData.append('status', values.filterStatus);
      bodyFormData.append('filterType', values.filtertype);
      bodyFormData.append('filterSubType', values.filtersubtype);
      bodyFormData.append('allowedEntities', values.allowedentities);
      if (state.isModify) {
        bodyFormData.append('id', values.id);
        modifyAccessFilterAction(bodyFormData);
      } else {
        bodyFormData.append('empID', userId);
        response = await addAccessFilterAction(bodyFormData);
      }

      if (response.status === 200 && response.data) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Employee filter added successfully`,
        });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error.message || `Failed to add filter`,
      });
    } finally {
      dispatch({ type: 'OPEN_DIALOG', payload: false });
      dispatch({ type: 'REFRESH_PAGE', payload: true });
    }
  };
  const modifyAccessFilter = async (values) => {
    dispatch({
      type: 'SET_INITIAL_VALUES',
      payload: {
        id: values.id,
        filterStatus: values.status,
        filtertype: values.filterType,
        filtersubtype: values.filterSubType,
        allowedentities: values.allowedLocationsId[0],
        isModify: true,
      },
    });
    dispatch({ type: 'OPEN_DIALOG', payload: true });
  };
  const preDeleteAccessFilter = async (values) => {
    dispatch({ type: 'OPEN_DELETE_DIALOG', payload: true });
  };

  const deleteAccessFilter = async (row) => {
    try {
      dispatch({ type: 'REFRESH_PAGE', payload: false });
      resetInitialValues();
      const query = new URLSearchParams({});
      query.append('id', row.id);
      const response = await deleteAccessFilterAction(query);
      if (response.data) {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Employee filter deleted successfully`,
        });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error.message || `Failed to delete filter`,
      });
    } finally {
      dispatch({ type: 'OPEN_DELETE_DIALOG', payload: false });
      dispatch({ type: 'REFRESH_PAGE', payload: true });
    }
  };

  return {
    state,
    addAccessFilter,
    handleOpenDialog,
    modifyAccessFilter,
    preDeleteAccessFilter,
    handleOpenDeleteDialog,
    modifyAccessFilterAction,
    deleteAccessFilter,
  };
};

export default useServices;
