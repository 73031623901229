import React from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import ProfileAddresses from 'components/Organisms/Profile/Addresses';

const ProfileAddressesTemplate = ({ contactDetails, handleEdit }) => {
  return (
    <BlankTemplate pageHeading="Addresses" isBreadcrumb={false} padding="2rem 3rem">
      <ProfileAddresses contactDetails={contactDetails} handleEdit={handleEdit} />
    </BlankTemplate>
  );
};

ProfileAddressesTemplate.defaultProps = {
  contactDetails: {},
  handleEdit: () => {},
};

const GenericAddressPropTypes = {
  locationName: PropTypes.string,
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  addressLine3: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  country: PropTypes.string,
  pinOrZip: PropTypes.number,
};

ProfileAddressesTemplate.propTypes = {
  contactDetails: PropTypes.arrayOf(
    PropTypes.shape({
      officeAddress: PropTypes.shape(GenericAddressPropTypes),
      permanentHomeAddress: PropTypes.shape(GenericAddressPropTypes),
      presentCommunicationAddress: PropTypes.shape(GenericAddressPropTypes),
    }),
  ),
  handleEdit: PropTypes.func,
};

export default ProfileAddressesTemplate;
