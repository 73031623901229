export const employeeCommonCardStyle = {
  card: {
    border: '0.125rem solid',
    borderColor: 'neutral.300',
    borderRadius: '0.625rem',
  },
  cardHeader: {
    p: '2.5rem 4.5rem 2.5rem 3rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '0.125rem solid',
    borderColor: 'neutral.300',

    '.MuiFormControl-root': {
      minWidth: 'max-content',

      '.MuiInputLabel-root': {
        transform: 'none',
        mt: 0,
        fontSize: '13px',
        color: 'secondary.600',

        '&.MuiInputLabel-shrink': {
          display: 'none',
        },
      },

      '.MuiInput-root': {
        fontSize: '13px',
        mt: 0,

        '.MuiSvgIcon-root': {
          color: 'secondary.600',
        },

        '&:before, &:after': {
          border: 'none',
        },
        '.MuiSelect-select': {
          minWidth: '32px',
          background: 'transparent',
          paddingBottom: 0,
        },
      },
    },
  },

  cardBody: {},
  cardFooter: {
    p: '1rem 3.5rem',
    justifyContent: 'flex-end',
    display: 'flex',

    '.MuiLink-root': {
      fontFamily: 'Inter',
      fontWeight: '500',
      fontSize: '13px',
      lineHeight: '22px',
      letterSpacing: '0.15px',
      color: '#215263',
      textDecoration: 'none',
    },
  },
};
