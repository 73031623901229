import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import SchoolIcon from '@mui/icons-material/School';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import HighlightIcon from '@mui/icons-material/Highlight';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const profile = {
  id: 'profile',
  title: 'Profile',
  icon: PersonIcon,
  type: 'group',
  url: '',
  children: [
    {
      id: 'basicProfile',
      title: 'Basic profile',
      icon: PersonIcon,
      type: 'item',
      url: '/profile/basic-profile',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_USER_PROFILE'],
    },
    {
      id: 'bankAccounts',
      title: 'Bank Accounts',
      icon: AccountBalanceIcon,
      type: 'item',
      url: '/profile/bank-accounts',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_BANK_ACCOUNTS'],
    },
    {
      id: 'addresses',
      title: 'Addresses',
      icon: LocationOnIcon,
      type: 'item',
      url: '/profile/addresses',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_ADDRESS'],
    },
    {
      id: 'dependents',
      title: 'Dependents',
      icon: ShuffleIcon,
      type: 'item',
      url: '/profile/dependents',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_DEPENDENTS'],
    },
    {
      id: 'employmentHistory',
      title: 'Employment History',
      icon: AccessTimeFilledIcon,
      type: 'item',
      url: '/profile/employment-history',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_MY_EMPLOYMENT_HISTORY'],
    },
    {
      id: 'educationalHistory',
      title: 'Educational History',
      icon: SchoolIcon,
      type: 'item',
      url: '/profile/education-history',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_MY_EDUCATION_HISTORY'],
    },
    {
      id: 'visaDetails',
      title: 'Visa Details',
      icon: FlightTakeoffIcon,
      type: 'item',
      url: '/profile/visa-details',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_MY_VISA_DETAILS'],
    },
    {
      id: 'skillsDetails',
      title: 'Skills Details',
      icon: HighlightIcon,
      type: 'item',
      url: '/profile/skills-details',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_MY_SKILLS'],
    },
    {
      id: 'lifeEventDetails',
      title: 'Life Event Details',
      icon: EmojiEventsIcon,
      type: 'item',
      url: '/profile/life-event-details',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_MY_EVENTS'],
    },
    {
      id: 'onboardingRecords',
      title: 'Onboarding Records',
      icon: PersonAddIcon,
      type: 'item',
      url: '/profile/onboardingrecords',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_MY_ONBOARDING_RECORDS'],
    },
    {
      id: 'modifyPassword',
      title: 'Modify Password',
      icon: VpnKeyIcon,
      type: 'item',
      url: '/profile/modify-password',
      permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_CHANGE_MY_PASSWORD'],
    },
  ],
};

export default profile;
