import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import LeaveRequestForm from './LeaveRequestForm';
import LeaveBalanceTable from '../LeaveBalance/LeaveBalanceTable';

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const CreateLeaveRequest = ({
  handleSubmit,
  leaveFormData,
  leaveCategoryOptions,
  leaveTypeOptions,
  preModifyData,
  rows,
  columns,
  handleDraft,
  handleDelete,
  handleCancel,
  isEdit,
  showNumberOfHoursField,
  loading,
}) => {
  return (
    <Box>
      <Box bgcolor="info.200" padding="2rem" borderRadius="0.75rem" sx={{ mb: 2 }}>
        <Typographys color="neutral.800" fontWeight="500" variant="body2">
          Note
        </Typographys>
        <Typographys color="neutral.600" variant="body2">
          Please specify the Category under which you would like to avail leaves, the specific start and end dates
          between which you would like to take leave. The number of days will be auto computed. If you are taking a
          partial day off (half day), please specify the start date and end date to be the same, and also specify the
          partial number of hours in the Hours field. <br />
          <Box component="span" color="neutral.800" fontSize={14} fontWeight={500}>
            Working Days:
          </Box>
          {preModifyData?.workingDaysList?.length
            ? preModifyData?.workingDaysList?.map((day) => daysOfWeek[day]).join(', ')
            : ''}
          <Box sx={{ ml: 2 }} component="span" color="neutral.800" fontSize={14} fontWeight={500}>
            Standard Hours Per Working Day:
          </Box>
          {preModifyData?.standardWorkingHoursPerDay} Hours
        </Typographys>
      </Box>
      {!!rows?.length && <LeaveBalanceTable rows={rows} columns={columns} />}
      <Box sx={{ mt: 2 }}>
        <LeaveRequestForm
          preModifyData={preModifyData}
          leaveTypeOptions={leaveTypeOptions}
          handleSubmit={handleSubmit}
          leaveFormData={leaveFormData}
          leaveCategoryOptions={leaveCategoryOptions}
          handleDraft={handleDraft}
          handleDelete={handleDelete}
          handleCancel={handleCancel}
          isEdit={isEdit}
          showNumberOfHoursField={showNumberOfHoursField}
          loading={loading}
        />
      </Box>
    </Box>
  );
};

CreateLeaveRequest.defaultProps = {
  leaveFormData: {},
  handleSubmit: () => {},
  leaveCategoryOptions: [],
  rows: [],
  columns: [],
  isEdit: false,
  handleDraft: () => {},
  handleDelete: () => {},
  handleCancel: () => {},
  showNumberOfHoursField: false,
  loading: false,
};

CreateLeaveRequest.propTypes = {
  leaveFormData: PropTypes.shape({}),
  handleSubmit: PropTypes.func,
  leaveCategoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  columns: PropTypes.arrayOf,
  rows: PropTypes.arrayOf,
  isEdit: PropTypes.bool,
  handleDraft: PropTypes.func,
  handleDelete: PropTypes.func,
  handleCancel: PropTypes.func,
  showNumberOfHoursField: PropTypes.bool,
  loading: PropTypes.bool,
};

export default CreateLeaveRequest;
