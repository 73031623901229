import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMyPendingLeaves } from 'store';
import { useThunk } from 'hooks/useThunk';
import useController from 'hooks/useController';
import usePaginationAttributes from 'hooks/use-pagination-attributes';
import { formDataApi } from 'api/api';
import { Typography, Skeleton, Box } from '@mui/material';

import { updateMyTeamLeave } from 'store/slices/leaves/myLeaves';
import TeamLeavesTemplate from 'components/Templates/Leave/TeamLeaves';
import { isArray, isEmpty } from 'lodash';
import dayjs from 'dayjs';
import useToast from 'components/Provider/useToast';
import useColumns from './useColumns';
import useActionItems from '../Requests/list/useActionItems';
import useLeaveServices from '../Requests/list/useLeaveServices';

const TeamLeavesAccounts = () => {
  const dispatch = useDispatch();
  const { pageSize } = usePaginationAttributes();
  const [columns] = useColumns();
  const [preFormData, setPreFormData] = useState({
    data: {},
    isLoading: false,
  });
  const [loadingMyTeamLeavesAccounts, setLoadingMyTeamLeavesAccounts] = useState(true);
  const [controller, handlePageChange, handleSortChange, handleFiltersApply, handleFiltersClear] = useController();
  const [doFetchMyLeave, isLoadingMyLeaves, loadingLeavesError] = useThunk(fetchMyPendingLeaves);
  const { selectedMyLeave, selectedMyLeaves, myTeamLeaves, teamLeaveAccounts } = useSelector((state) => {
    return state.myLeaves;
  });
  const addToast = useToast();

  const fetchLeavesAction = useCallback(() => {
    const query = new URLSearchParams({});
    if (controller) {
      query.append('sort', controller.sortBy);
      query.append('order', controller.sort);
    }
    query.append('offset', controller.page * pageSize);
    query.append('max', pageSize);
    doFetchMyLeave(query);
  }, [doFetchMyLeave, controller, pageSize]);

  useEffect(() => {
    const fetchData = async () => {
      setPreFormData({ ...preFormData, isLoading: true });

      const url = 'restLeaveIndex/listMyTeamLeaveRequests';
      try {
        const response = await formDataApi.get(url);
        if (response?.data) {
          const modifiedData = {
            leaveCategoryOptions: response?.data?.leaveCategories.map((data) => ({
              id: data.id,
              name: data.name,
            })),
            leaveRequestStateOptions: Object.values(response?.data?.leaveRequestStates).map((data) => ({
              id: data,
              name: data,
            })),
            hierarchyLevelOptions: response?.data?.hierarchyLevel.map((data) => ({
              id: data,
              name: data,
            })),
          };
          setPreFormData(modifiedData);
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTeamLeaves = async (data) => {
    const bodyObj = {
      startDate: data.startDate ? dayjs(data.startDate).format('MM/DD/YYYY') : '',
      endDate: data.endDate ? dayjs(data.endDate).format('MM/DD/YYYY') : '',
      requestState: data.leaveRequestStates,
      hierarchLevel: data.hierarchyLevel,
      leaveCategoryIDs: data.leaveCategory,
    };

    const body = new FormData();

    Object.keys(bodyObj).forEach((key) => {
      if (bodyObj[key] && !(isArray(bodyObj[key]) && isEmpty(bodyObj[key]))) {
        body.append(key, bodyObj[key]);
      }
    });
    setLoadingMyTeamLeavesAccounts(true);
    const url = 'restLeaveIndex/displayLeaveAccountsForMyTeamAction';
    const response = await formDataApi.post(url, body);
    setLoadingMyTeamLeavesAccounts(false);
    if (response?.data?.leaveRequestInstanceCount === 0) {
      addToast({
        type: 'success',
        title: 'SUCCESS',
        message: 'No records exist',
      });
    }
    dispatch(updateMyTeamLeave(response.data));
  };

  const leaveServices = useLeaveServices(fetchLeavesAction);
  const [menuList] = useActionItems(leaveServices);
  if (isLoadingMyLeaves) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (loadingLeavesError !== null) {
    return <Typography variant="h6">{loadingLeavesError?.message || 'Error while loading leaves list.'}</Typography>;
  }

  return (
    <TeamLeavesTemplate
      leaveCategoryOptions={preFormData?.leaveCategoryOptions}
      leaveRequestStateOptions={preFormData?.leaveRequestStateOptions}
      hierarchyLevelOptions={preFormData?.hierarchyLevelOptions}
      handleSubmit={getTeamLeaves}
      showList={teamLeaveAccounts?.length}
      rows={teamLeaveAccounts}
      columns={columns}
      rowsPerPage={100}
      roleBasedMenu={menuList}
      totalRecordCount={myTeamLeaves?.count}
      loadNextPageData={handlePageChange}
      handleSort={handleSortChange}
      page={controller.page}
      sortDirection={controller.sort}
      sortBy={controller.sortBy}
      showActionList={false}
      pageHeading="Team Leave Accounts"
      loading={loadingMyTeamLeavesAccounts}
      count={teamLeaveAccounts?.length}
    />
  );
};

export default TeamLeavesAccounts;
