import React from 'react';
import Typography from 'components/Atoms/Typography';
import LCPolicyListsTemplate from 'components/Templates/Employee/LCPolicies/List';
import { listLifeCyclePolicies } from 'api/employees';

import useFetcher from 'hooks/useFetcher';

import { formatListLifeCyclePolicies } from '../utils/converter';
import usePolicyServices from '../common/usePolicyServices';
import useEmployeeColumns from './useEmployeeColumns';

const ListLifeCyclePolicies = () => {
  const [empColumns] = useEmployeeColumns();
  const [lifecyclePolicies] = useFetcher(listLifeCyclePolicies, {});
  const policyServices = usePolicyServices();

  if (lifecyclePolicies.error !== null) {
    return (
      <Typography variant="h6">
        {lifecyclePolicies?.error?.response?.data?.message || 'Error while loading list lifecycle policies list.'}
      </Typography>
    );
  }
  // if (lifecyclePolicies.isLoading) {
  //   return <Typography variant="h6">Loading lifecycle policies</Typography>;
  // }

  const policyActionMenuList = [
    { id: 1, label: 'Edit', onClick: policyServices.handleEditLCPolicy },
    { id: 2, label: 'Delete', onClick: policyServices.handleOpenDeletePolicyDialog },
  ];

  return (
    <LCPolicyListsTemplate
      loading={{ ...policyServices.policyState.loading, policyLoading: lifecyclePolicies.isLoading }}
      empColumns={empColumns}
      lcPoliciesGraphData={[
        { name: 'Invite Sent', value: 50 },
        { name: 'Pending invites', value: 8 },
        { name: 'Onboarding Initated', value: 4 },
      ]}
      policyListItems={formatListLifeCyclePolicies(lifecyclePolicies?.data?.lifeCycleInstanceList) || []}
      handleSearch={policyServices.handlePolicySearch}
      handleSearchAdd={policyServices.handleSearchAdd}
      handleDetails={policyServices.handleDetails}
      policyName="Policy Name"
      allEmployees={policyServices.policyState.allEmployees}
      mappedEmployees={[]}
      handlePolicyItemClick={policyServices.handlePolicyItemClick}
      selectedItems={policyServices.selectedEmployees}
      onSelect={policyServices.handleSelect}
      onSelectAll={policyServices.handleSelectAll}
      showRowSelector
      showPagination="false"
      keyColumn="id"
      actionMenuLists={policyActionMenuList}
      openDeleteLCPolicy={policyServices.policyState.openDeletePolicyDialog}
      handleDelete={policyServices.handleDeletePolicy}
      handleClose={policyServices.handleOpenDeletePolicyDialog}
      handleSelect={() => {}}
      onSelectedChange={() => {}}
      onTabChange={policyServices.handleTabChange}
      bulkUpdateColumns={empColumns}
      bulkUpdateRows={policyServices.policyState.allEmployees}
      lcPolicies={policyServices.policyState.lcPolicies}
      handleAdd={policyServices.updatePolicyMappingToPolicyMapping}
      changePolicyHandleClick={policyServices.changePolicyHandleClick}
    />
  );
};

export default ListLifeCyclePolicies;
