import React, { useState } from 'react';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { DropzoneArea } from 'react-mui-dropzone';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import FormData from 'form-data';
import useToast from 'components/Provider/useToast';
import { formDataApi } from 'api/api';
import { LoadingButton } from '@mui/lab';

export const BulkUploadDialog = ({ openBulkUploadDialog, onResult, isBulkUpdate }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [files, setFiles] = useState('');
  const [loading, setLoading] = useState(false);

  const addToast = useToast();

  const handleFiles = (inputFiles) => {
    setFiles(inputFiles[0]);
  };

  const validate = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('dataFile', files);
      const response = await formDataApi.post(`/loadPositionDataAction`, formData);
      if (response.data?.status !== 'ERROR') {
        onResult(true);
      } else {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message,
        });
        // dispatch(updatePositions(response.data));
        // navigate('/positions/preview');
      }
    } catch (error) {
      console.log(error);
      addToast({ type: 'error', title: 'ERROR', message: error?.message });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Dialog open={openBulkUploadDialog} fullWidth maxWidth="sm" onClose={() => onResult(null)}>
      <DialogTitle>Bulk {isBulkUpdate ? 'Update' : 'Upload'} Pay Heads</DialogTitle>
      <DialogContent>
        <Grid container justifyContent="center" alignItems="center" mt={2}>
          <Grid item xs={12}>
            <DropzoneArea
              dropzoneText="Drag and drop a file here or click"
              filesLimit={1}
              onChange={handleFiles}
              showAlerts={false}
              showPreviews
              showPreviewsInDropzone={false}
              useChipsForPreview
              previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
              previewText="Selected files"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={loading} variant="contained" color="primary" onClick={validate}>
          {isBulkUpdate ? 'Update' : 'Upload'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
