import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Typographys from 'components/Atoms/Typography';

const IconWithTitle = ({
  iconElement,
  iconColor,
  iconFontSize,
  titleColor,
  titleVariant,
  title,
  columnSpacing,
  onClick,
  isClickable,
  onTextClick,
}) => {
  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', cursor: isClickable ? 'pointer' : 'default' }}
      columnSpacing={columnSpacing}
      onClick={onClick}
    >
      <IconButton sx={{ color: iconColor, fontSize: iconFontSize }}>{iconElement}</IconButton>
      <Box ml={columnSpacing}>
        <Typographys onClick={onTextClick} color={titleColor} variant={titleVariant}>
          {title}
        </Typographys>
      </Box>
    </Box>
  );
};

IconWithTitle.defaultProps = {
  onTextClick: () => {},
  iconElement: <LocationOnIcon fontSize="inherit" />,
  iconColor: 'actions.600',
  iconFontSize: '2.25rem',
  title: '',
  titleColor: 'neutral.800',
  titleVariant: 'body2',
  onClick: () => {},
  columnSpacing: 0.5,
  isClickable: false,
};

IconWithTitle.propTypes = {
  onTextClick: PropTypes.func,
  iconElement: PropTypes.node,
  iconColor: PropTypes.string,
  iconFontSize: PropTypes.string,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  titleVariant: PropTypes.string,
  onClick: PropTypes.func,
  columnSpacing: PropTypes.number,
  isClickable: PropTypes.bool,
};

export default IconWithTitle;
