import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { palette } from '../component-variant/palette';
import { MuiTypographyTheme as MuiTypography } from '../component-variant/typography';
import { breakPointsValues } from '../../breakpoints';
import { shape as shapeFromDesignToken } from '../../shape';
import { border as borderFromDesignToken } from '../../border';
import { shadows } from '../../shadows';
import { spacing } from '../../spacing';
import { sizing } from '../../sizing';
import { typography } from '../../typography';

export const theme = createTheme({
  palette: palette,
  breakpoints: {
    values: breakPointsValues,
  },
  components: {
    MuiTypography,
  },
  shape: { ...shapeFromDesignToken },
  border: { ...borderFromDesignToken },
  shadows,
  space: { ...spacing },
  sizing,
  typography,
});

export const withThemeProvider = (Component) => {
  return (props) => (
    <ThemeProvider theme={theme}>
      <Component {...props} />
    </ThemeProvider>
  );
};

export const MuiThemeProvider = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
