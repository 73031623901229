import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import ActionQueryTemplate from 'components/Templates/TaskAndQueries/ActionQuery';
import { Box, Skeleton, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import useServices from '../common/useServices';
import useMenuItems from '../View/useMenuItems';

const ActionQuery = () => {
  const location = useLocation();
  const { id } = useParams();
  const isReopen = location.pathname.includes('reopen');
  const services = useServices();
  const [handleActionItemClick] = useMenuItems(services);
  const [actionResult, setActionResult] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!isReopen) {
          await services.actionQueryService(id);
        } else {
          await services.reopenQueryService(id);
        }
      } catch (error) {
        setActionResult(error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReopen, id]);

  if (services?.state?.queryDetails?.isLoading) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (!isEmpty(actionResult)) {
    return isReopen ? (
      <Typography variant="h6">{actionResult?.response?.data?.warning || 'Failed to reopen the query.'}</Typography>
    ) : (
      <Typography variant="h6">
        {actionResult?.response?.data?.warning || 'The query failed to be actioned.'}
      </Typography>
    );
  }

  return (
    <ActionQueryTemplate
      handleSubmit={!isReopen ? services.actionQueryActionService : services.reopenQueryActionService}
      reportDetails={services.state.queryDetails?.queryDetails}
      handleEdit={() => services.modifyQueryNavigationService({ id })}
      actionMenuList={handleActionItemClick}
      isRecall={false}
      handleRecall={() => {}}
      handleAttachmentDownload={services.downloadDocument}
      showEdit
      formData={services.state.queryDetails.formData}
      resolutionStatusOptions={services.state.queryDetails?.resolutionStatusOptions}
      handleCancel={services.handleCancel}
      isReopen={isReopen}
      auditLogData={[]}
    />
  );
};

export default ActionQuery;
