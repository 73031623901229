export const uploadStyle = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',

    '& .MuiTypography-body2': {
      marginLeft: '1.25rem',
      color: 'neutral.800',
    },

    '& .MuiButton-text': {
      ':hover': {
        textDecoration: 'none',
      },
    },
  },

  uploadIcon: {
    backgroundColor: 'neutral.100',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'neutral.400',
    width: '9.75rem',
    height: '9.75rem',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    ':hover': {
      backgroundColor: 'neutral.200',
    },
  },

  preview: {
    position: 'absolute',
    left: 0,
    right: 0,
    width: '9.5rem',
    height: '9.5rem',

    img: {
      maxWidth: '9.75rem',
      maxHeight: '9.75rem',
      width: '100%',
      height: '100%',
      borderRadius: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      objectFit: 'contain',
      backgroundColor: 'common.white',
      overflow: 'hidden',
    },
  },
};
