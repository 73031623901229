import React, { useEffect, useState } from 'react';
import EmpActionRequestTemplate from 'components/Templates/Employee/Company/Separation/EmpActionRequest';
import { useThunk } from 'hooks/useThunk';
import get from 'lodash/get';
import { fetchActionRequestDetails } from 'store';
import { useSelector } from 'react-redux';
import { Box, Skeleton, Typography } from '@mui/material';
import useToast from 'components/Provider/useToast';
import { useParams, useNavigate } from 'react-router-dom';
import { actionEmployeeExitAction } from 'api/employees/separations';
import { validateDateElseHyphen, validateElseHyphen } from 'utils/commonUtils';
import useSeparationsService from '../List/useSeparationsService';

const EmployeeActionRequestPage = () => {
  const [disableButton, setDisableButton] = useState(false);
  const { id } = useParams();
  const addToast = useToast();
  const services = useSeparationsService();
  const navigate = useNavigate();

  const [getEmployeeActionRequestDetails, isLoadingEmployeeActionRequest, loadingEmployeeActionrequestError] =
    useThunk(fetchActionRequestDetails);

  useEffect(() => {
    const bodyFormData = new FormData();
    bodyFormData.append('id', id);
    getEmployeeActionRequestDetails(bodyFormData);
  }, [id, getEmployeeActionRequestDetails]);

  const defaultValues = {};

  const {
    employeeActionRequestDetails = [],
    employeeActionRequestProfileInstance = [],
    currentSupervisor = false,
    employeeSeparationRequest = '',
  } = useSelector((state) => state.separations);

  const exitDetails = {
    employeeName: employeeActionRequestDetails?.employee?.fullName,
    separationType: employeeActionRequestProfileInstance?.seperationType,
    relievingDate: validateDateElseHyphen(employeeActionRequestDetails?.requestedRelievingDate, 'MM/DD/YYYY'),
    interviewDate: validateDateElseHyphen(employeeActionRequestDetails?.exitInterviewDate, 'MM/DD/YYYY'),
    interviewer: employeeActionRequestDetails?.exitInterviewer?.fullName,
    resignationReasons: validateElseHyphen(employeeActionRequestDetails?.resignationReasons),
    resignationStatus: validateElseHyphen(employeeActionRequestDetails?.resignationStatus),
    supervisor: validateElseHyphen(employeeActionRequestDetails?.resignationAcceptingManager?.fullName),
    supervisorAcceptanceDate: validateDateElseHyphen(
      employeeActionRequestDetails?.resignationAcceptanceDate,
      'MM/DD/YYYY',
    ),
    supervisorComments: validateElseHyphen(employeeActionRequestDetails?.resignationAcceptanceComments),
    asPerPolicyLWD: validateDateElseHyphen(employeeActionRequestDetails?.systemComputedLWD, 'MM/DD/YYYY'),
    noticePeriod: `${validateElseHyphen(employeeActionRequestProfileInstance?.noticePeriod)} ${validateElseHyphen(
      employeeActionRequestProfileInstance?.noticePeriodTimeUnit,
    )}`,
    resignationSubmissionDate: validateDateElseHyphen(
      employeeActionRequestDetails?.resignationSubmissionDate,
      'MM/DD/YYYY',
    ),
  };

  defaultValues.approvedLastWorkingDay = validateDateElseHyphen(
    employeeActionRequestDetails?.approvedLWD,
    'MM/DD/YYYY',
  );

  const handleSubmit = async (values) => {
    const eligibleForRehire = values.eligibleForRehire ? 'on' : '';
    const bodyFormData = new FormData();
    bodyFormData.append('opType', employeeSeparationRequest);
    bodyFormData.append('resignationAcceptanceComments', get(values, 'comments', ''));
    bodyFormData.append('id', id);
    bodyFormData.append(
      'approvedLWD',
      get(values, 'approvedLastWorkingDay', '') ||
        validateDateElseHyphen(employeeActionRequestDetails?.approvedLWD, 'MM/DD/YYYY'),
    );
    bodyFormData.append('resignationStatus', employeeActionRequestDetails?.resignationStatus);
    bodyFormData.append('version', 7);
    bodyFormData.append('isCurrActorSupervsior', currentSupervisor);
    bodyFormData.append('eligibleForRehire', eligibleForRehire);

    try {
      setDisableButton(true);

      const response = await actionEmployeeExitAction(bodyFormData);
      if (response?.status === 'SUCCESS') {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.message || 'Action Request Successful',
        });
        navigate(`/myteam/separations/view/${id}`);
      } else {
        addToast({
          type: 'error',
          title: 'ERROR',
          message: response?.message || 'Action Request Unsuccessful',
        });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to ${employeeSeparationRequest}ed request`,
      });
    } finally {
      setDisableButton(false);
    }
  };

  if (isLoadingEmployeeActionRequest) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (loadingEmployeeActionrequestError !== null) {
    return (
      <Typography variant="h6">
        {loadingEmployeeActionrequestError?.message || 'Error while loading employee action request.'}
      </Typography>
    );
  }

  return (
    <Box>
      <EmpActionRequestTemplate
        exitDetails={exitDetails}
        loading={isLoadingEmployeeActionRequest}
        pageHeading="Action Employee Separation Request"
        handleApprove={() => services.employeeExitActionRequest('Approve', exitDetails)}
        handleReject={() => services.employeeExitActionRequest('Reject', exitDetails)}
        defaultValues={defaultValues}
        handleSubmit={handleSubmit}
        disableButton={disableButton}
      />
    </Box>
  );
};

export default EmployeeActionRequestPage;
