/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Calendar, momentLocalizer, Navigate, ToolbarProps } from 'react-big-calendar';
import { Box, Button, Stack } from '@mui/material';
import Typography from '../Typography';

const localizer = momentLocalizer(moment);

const CustomToolbar = (props) => {
  const { onView, onNavigate, date, view } = props;

  const [viewState, setViewState] = useState('month');

  const goToDayView = () => {
    onView('day');
    setViewState('day');
  };
  const goToWeekView = () => {
    onView('week');
    setViewState('week');
  };
  const goToMonthView = () => {
    onView('month');
    setViewState('month');
  };

  const goToBack = () => {
    onNavigate(Navigate.PREVIOUS);
  };

  const goToNext = () => {
    onNavigate(Navigate.NEXT);
  };

  return (
    <Box sx={{ mb: '24px !important' }}>
      <Stack justifyContent="space-between" direction="row">
        <Box>
          <Button
            sx={{
              borderRadius: '0 !important',
              color: view !== 'month' && '#ccc !important',
              borderColor: view !== 'month' && '#ccc !important',
            }}
            variant="outlined"
            onClick={goToMonthView}
          >
            Month
          </Button>
          <Button
            sx={{
              borderRadius: '0 !important',
              color: view !== 'week' && '#ccc !important',
              borderColor: view !== 'week' && '#ccc !important',
            }}
            variant="outlined"
            onClick={goToWeekView}
          >
            Week
          </Button>
          <Button
            sx={{
              borderRadius: '0 !important',
              color: view !== 'day' && '#ccc !important',
              borderColor: view !== 'day' && '#ccc !important',
            }}
            variant="outlined"
            onClick={goToDayView}
          >
            Day
          </Button>
        </Box>
        <Typography variant="h4">
          {view === 'month' ? moment(date).format('MMM, YYYY') : moment(date).format('DD MMMM YYYY')}
        </Typography>
        <Stack direction="row" gap={1}>
          <Button variant="outlined" onClick={goToBack}>
            &#8249;
          </Button>
          <Button variant="outlined" onClick={goToNext}>
            &#8250;
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

const CustomCalendar = ({ state, selectDate, selectEvent, events = [] }) => {
  const eventStyleGetter = (event, start, end, isSelected) => {
    return {
      style: { backgroundColor: event.color },
    };
  };
  const [defaultDate, setDefaultDate] = useState(new Date());

  return (
    <div id="calendar-section">
      <div id="calendar-wrapper">
        <Calendar
          localizer={localizer}
          events={events}
          date={defaultDate}
          onNavigate={(date) => setDefaultDate(date)}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 700 }}
          components={{ toolbar: CustomToolbar }}
          eventPropGetter={eventStyleGetter}
        />
      </div>
    </div>
  );
};

export default CustomCalendar;
