import React from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import PropTypes from 'prop-types';
import InitiateEmployeeSeparation from 'components/Organisms/Employee/Company/Separation/InitiateEmployeeSeparation';
import { Box, Skeleton } from '@mui/material';

const InitiateEmployeeSeparationTemplate = ({
  handleSubmit,
  pageHeading,
  breadcrumbData,
  employeeSeparationInstance,
  seperationOptions,
  managerOptions,
  exitInterviewerOptions,
  loading,
}) => {
  return (
    <BlankTemplate pageHeading={pageHeading} breadcrumbData={breadcrumbData}>
      {loading ? (
        <Box sx={{ p: 4 }}>
          <Skeleton height={42} />
          <Skeleton />
          <Skeleton />
        </Box>
      ) : (
        <InitiateEmployeeSeparation
          handleSubmit={handleSubmit}
          employeeSeparationInstance={employeeSeparationInstance}
          seperationOptions={seperationOptions}
          managerOptions={managerOptions}
          exitInterviewerOptions={exitInterviewerOptions}
        />
      )}
    </BlankTemplate>
  );
};

InitiateEmployeeSeparationTemplate.defaultProps = {
  pageHeading: 'Initiate Employee Exit Details',
  handleSubmit: () => {},
  breadcrumbData: [],
  employeeSeparationInstance: {},
  seperationOptions: [{ id: 'Retirement', name: 'Retirement' }],
  managerOptions: [{ empID: 'EMP-190-ANSR', empStatus: 'ACTIVE', fullName: 'Adithya Kumar', id: '309911' }],
  exitInterviewerOptions: [{ empID: 'EMP-190-ANSR', empStatus: 'ACTIVE', fullName: 'Adithya Kumar', id: '309911' }],
  loading: false,
};

InitiateEmployeeSeparationTemplate.propTypes = {
  pageHeading: PropTypes.string,
  handleSubmit: PropTypes.func,
  employeeSeparationInstance: PropTypes.shape({
    fullName: PropTypes.string,
    profile: PropTypes.shape({
      noticePeriod: PropTypes.string,
    }),
  }),
  breadcrumbData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      path: PropTypes.string,
      isActive: PropTypes.bool,
    }),
  ),
  seperationOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
  managerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      empID: PropTypes.string,
      empStatus: PropTypes.string,
      fullName: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
  exitInterviewerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      empID: PropTypes.string,
      empStatus: PropTypes.string,
      fullName: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
};

export default InitiateEmployeeSeparationTemplate;
