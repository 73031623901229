/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FormHelperText, Grid, Divider, Stack, Skeleton, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/system';
import { urlEncodedApi } from 'api/api';
import InputField from 'components/Atoms/InputField';
import DatePickers from 'components/Atoms/Datepicker';
import CheckBox from 'components/Atoms/Checkbox';
import Button from 'components/Atoms/Button';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import useToast from 'components/Provider/useToast';
import { styles } from './styles';

const createPayloadMap = {
  displayName: 'name',
  payheadType: 'payheadType',
  'currency.id': 'currency',
  exemptionAllowanceType: 'exemptionAllownaceType',
  exemptionLevel: 'excemptionLevel',
  maxExemptionLimitPA: 'maxExcemptionLimit',
  exemptionStatuteReference: 'excemptionStatueReference',
  glName: 'generalLedgerName',
  glCode: 'generalLedgerCode',
  costCentreCode: 'costCentreCode',
  compensationType: 'compensationType',
  calculationType: 'calculationType',
  calculationFormula: 'calculationFormula',
  exemptionEffectiveDate: 'excemptionEffectiveDate',
  exempt: 'excempt',
  taxable: 'taxable',
  impactsGratuity: 'impactsGratuity',
  isPartOfEmployeeCTC: 'partOfCTCForEmployee',
  isPartOfCTC: 'partOfCTCForCompany',
  displayOnSalaryStructure: 'displayOnSalaryStructure',
  displayOnSalarySlip: 'displayOnPS',
  isLOPApplicable: 'isLopApplicable',
  isSSInclusionMandatory: 'MandatryInclInSalStruct',
};

const PayHeadForm = ({ isEditMode, payheadDetails, results }) => {
  const navigate = useNavigate();
  const {
    compansationTypes,
    companyPayheads,
    currenciesList,
    exemptionAllowanceTypes,
    exemptionLevelTypes,
    payrollCalculationTypes,
  } = payheadDetails?.data || {};

  const [loading, setLoading] = useState(false);
  const addToast = useToast();

  const handleCustomDetails = async (details) => {
    try {
      setLoading(true);
      const query = new URLSearchParams({});
      Object.keys(createPayloadMap).forEach((key) => {
        if (
          details[createPayloadMap[key]] ||
          (!details[createPayloadMap[key]] && details[createPayloadMap[key]] === false)
        ) {
          if (key === 'exemptionEffectiveDate') {
            query.append(key, dayjs(details[createPayloadMap[key]]).format('MM/DD/YYYY'));
          } else if (typeof details[createPayloadMap[key]] === 'boolean') {
            query.append(key, details[createPayloadMap[key]] ? 'on' : '');
          } else query.append(key, details[createPayloadMap[key]]);
        }
      });
      if (isEditMode) {
        query.append('id', results?.id);
      }
      const pathToCall = isEditMode ? 'restPayIndex/modifyPayheadAction' : 'restPayIndex/savePayhead';
      const response = await urlEncodedApi.post(pathToCall, query);
      if (response?.data?.status !== 'ERROR') {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || `Employer Tax ${isEditMode ? 'updated' : 'added'} successfully`,
        });
        navigate('/company/salary/payheads');
      } else {
        addToast({
          type: 'error',
          title: 'ERROR',
          message: response?.data?.message || `Failed to ${isEditMode ? 'update' : 'add'} Employer Tax`,
        });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || `Failed to ${isEditMode ? 'update' : 'add'} Employer Tax`,
      });
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      employeeName: results?.displayName ?? '',
      employerName: results?.payheadType ?? '',
      employerTan: results?.currencyId ?? '',
      employerAddress: results?.exemptionAllowanceType ?? '',
      incomeChargeableUnderHeadSalaries: results?.exemptionLevel ?? '',
      exemptionsUnderSection10: results?.maxExemptionLimitPA ?? '',
      deductionsUnderSection16: results?.exemptionStatuteReference ?? '',
      deductionUnderChapterVIA: results?.glName ?? '',
      netTaxPayable: results?.glCode ?? '',
      netTaxDeducted: results?.costCentreCode ?? '',
      netProfessionalTaxPaid: results?.compensationType ?? '',
      netPFContributed: results?.calculationType ?? '',
      taxPayableRefundable: results?.calculationFormula ?? '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      employeeName: Yup.string().required('Required'),
      employerName: Yup.string().required('Required'),
      employerTan: Yup.string().required('Required'),
      incomeChargeableUnderHeadSalaries: Yup.string().required('Required'),
      exemptionsUnderSection10: Yup.string().required('Required'),
      deductionsUnderSection16: Yup.string().required('Required'),
      deductionUnderChapterVIA: Yup.string().required('Required'),
      netTaxPayable: Yup.string().required('Required'),
      netTaxDeducted: Yup.string().required('Required'),
      netProfessionalTaxPaid: Yup.string().required('Required'),
      netPFContributed: Yup.string().required('Required'),
      taxPayableRefundable: Yup.string().required('Required'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        handleCustomDetails(values);
        // helpers.resetForm();
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
      } catch (err) {
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });

  if (
    payheadDetails?.isLoading ||
    !(
      compansationTypes?.length &&
      companyPayheads?.length &&
      currenciesList?.length &&
      exemptionAllowanceTypes?.length &&
      exemptionLevelTypes?.length &&
      payrollCalculationTypes?.length
    )
  ) {
    return (
      <Box sx={{ py: 4 }}>
        <Skeleton height={42} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  return (
    <Grid container spacing={4}>
      <Grid item md={12} xs={12}>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <Grid container columnSpacing={6} rowSpacing={3}>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  error={Boolean(formik.touched.employeeName && formik.errors.employeeName)}
                  helperText={formik.touched.employeeName && formik.errors.employeeName}
                  label="Employee Name"
                  name="employeeName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  select
                  value={formik.values.employeeName}
                >
                  {companyPayheads.map((statusItem) => (
                    <MenuItem key={statusItem?.id} value={statusItem?.id}>
                      {statusItem?.nameWithType}
                    </MenuItem>
                  ))}
                </InputField>
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  error={Boolean(formik.touched.employerName && formik.errors.employerName)}
                  helperText={formik.touched.employerName && formik.errors.employerName}
                  label="Employer Name"
                  name="employerName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.employerName}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  error={Boolean(formik.touched.employerTan && formik.errors.employerTan)}
                  helperText={formik.touched.employerTan && formik.errors.employerTan}
                  label="Employer TAN"
                  name="employerTan"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.employerTan}
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  fullWidth
                  label="Employer Address"
                  size="small"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: formik.values.employerAddress,
                  }}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  multiline
                  name="employerAddress"
                  rows={1}
                  value={formik.values.employerAddress}
                  error={Boolean(formik.touched.employerAddress && formik.errors.employerAddress)}
                />
              </Grid>
              <Grid item xs={12} sx={styles.dividerWrapper}>
                <Divider flexItem orientation="horizontal" sx={styles.divider} />{' '}
              </Grid>

              <Grid item xs={4}>
                <InputField
                  error={Boolean(
                    formik.touched.incomeChargeableUnderHeadSalaries && formik.errors.incomeChargeableUnderHeadSalaries,
                  )}
                  helperText={
                    formik.touched.incomeChargeableUnderHeadSalaries && formik.errors.incomeChargeableUnderHeadSalaries
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.incomeChargeableUnderHeadSalaries}
                  name="incomeChargeableUnderHeadSalaries"
                  id="incomeChargeableUnderHeadSalaries"
                  label="Income Chargeable Under Head Salaries *"
                  type="number"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  error={Boolean(formik.touched.exemptionsUnderSection10 && formik.errors.exemptionsUnderSection10)}
                  helperText={formik.touched.exemptionsUnderSection10 && formik.errors.exemptionsUnderSection10}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.exemptionsUnderSection10}
                  name="exemptionsUnderSection10"
                  id="exemptionsUnderSection10"
                  label="Exemptions Under Section10 *"
                  type="number"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  error={Boolean(formik.touched.deductionsUnderSection16 && formik.errors.deductionsUnderSection16)}
                  helperText={formik.touched.deductionsUnderSection16 && formik.errors.deductionsUnderSection16}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.deductionsUnderSection16}
                  name="deductionsUnderSection16"
                  id="deductionsUnderSection16"
                  label="Deductions Under Section16 *"
                  type="number"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  error={Boolean(formik.touched.deductionUnderChapterVIA && formik.errors.deductionUnderChapterVIA)}
                  helperText={formik.touched.deductionUnderChapterVIA && formik.errors.deductionUnderChapterVIA}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.deductionUnderChapterVIA}
                  name="deductionUnderChapterVIA"
                  id="deductionUnderChapterVIA"
                  label="Deduction Under Chapter VIA *"
                  type="number"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  error={Boolean(formik.touched.netTaxPayable && formik.errors.netTaxPayable)}
                  helperText={formik.touched.netTaxPayable && formik.errors.netTaxPayable}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.netTaxPayable}
                  name="netTaxPayable"
                  id="netTaxPayable"
                  label="Net Tax Payable *"
                  type="number"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  error={Boolean(formik.touched.netTaxDeducted && formik.errors.netTaxDeducted)}
                  helperText={formik.touched.netTaxDeducted && formik.errors.netTaxDeducted}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.netTaxDeducted}
                  name="netTaxDeducted"
                  id="netTaxDeducted"
                  label="Net Tax Deducted *"
                  type="number"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  error={Boolean(formik.touched.netProfessionalTaxPaid && formik.errors.netProfessionalTaxPaid)}
                  helperText={formik.touched.netProfessionalTaxPaid && formik.errors.netProfessionalTaxPaid}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.netProfessionalTaxPaid}
                  name="netProfessionalTaxPaid"
                  id="netProfessionalTaxPaid"
                  label="Net Professional Tax Paid *"
                  type="number"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  error={Boolean(formik.touched.netPFContributed && formik.errors.netPFContributed)}
                  helperText={formik.touched.netPFContributed && formik.errors.netPFContributed}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.netPFContributed}
                  name="netPFContributed"
                  id="netPFContributed"
                  label="Net PF Contributed *"
                  type="number"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  error={Boolean(formik.touched.taxPayableRefundable && formik.errors.taxPayableRefundable)}
                  helperText={formik.touched.taxPayableRefundable && formik.errors.taxPayableRefundable}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.taxPayableRefundable}
                  name="taxPayableRefundable"
                  id="taxPayableRefundable"
                  label="Tax Payable Refundable *"
                  type="number"
                  fullWidth
                />
              </Grid>
              {formik.errors.submit && (
                <Grid item xs={12}>
                  <FormHelperText error>{formik.errors.submit}</FormHelperText>
                </Grid>
              )}
              <Grid item xs={12}>
                <Stack direction="row" justifyContent={isEditMode ? 'space-between' : 'end'}>
                  {/* {isEditMode && (
                    <Button color="primary" size="large" variant="outlined">
                      Delete
                    </Button>
                  )} */}
                  <Stack direction="row" justifyContent="end" spacing={2}>
                    <Button size="Large" variant="outlined" sx={{ color: 'text.200 !important' }}>
                      Cancel
                    </Button>
                    <LoadingButton loading={loading} color="primary" size="large" type="submit" variant="contained">
                      {isEditMode ? 'Update' : 'Create'}
                    </LoadingButton>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </div>
        </form>
      </Grid>
    </Grid>
  );
};

export default PayHeadForm;

PayHeadForm.propTypes = {
  payheadDetails: PropTypes.shape(PropTypes.node),
  isEditMode: PropTypes.bool,
  results: PropTypes.instanceOf(Object),
};

PayHeadForm.defaultProps = {
  payheadDetails: {},
  isEditMode: false,
  results: {},
};
