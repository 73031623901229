import { api, formDataApi } from 'api/api';

export const listPayHeads = async (query) => {
  const response = await api.post(`restPayIndex/listPayheads?${query.toString()}`);
  return response?.data?.data;
};

export const searchPayHeads = async (query) => {
  const response = await api.post(`restPayIndex/listPayheads?${query.toString()}`);
  return response?.data?.data;
};

export const deletePayHead = async (id) => {
  const body = new FormData();
  body.append('id', id);
  const response = await formDataApi.post('restPayIndex/deletePayhead', body);
  return response;
};
