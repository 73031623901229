import React, { useCallback, useEffect, useState } from 'react';
import SeparationTemplate from 'components/Templates/Employee/Company/Separation/SeparationList';
import { useThunk } from 'hooks/useThunk';
import useToast from 'components/Provider/useToast';
import { fetchEmployeeExitsList } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { initiateSpecificEmpExit } from 'api/employees/separations';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  updateSelectedEmployee,
  updateSelectedEmployees,
  updateAllSelectedEmployees,
} from 'store/slices/company/employees/separations';
import DeleteSeparation from 'components/Organisms/Dialog/ActionList/DeleteSeparation';
import LockEmployee from 'components/Organisms/Dialog/ActionList/Company/LockEmployee';
import useController from 'hooks/useController';
import usePaginationAttributes from 'hooks/use-pagination-attributes';
import { FilterDialog } from 'components/mui/FilterDialog';
import { hasPermission } from 'utils/permissions';
import { isEmpty, isArray } from 'lodash';
import useColumns from './useColumns';
import useActionItems from './useActionItems';
import useSeparationsService from './useSeparationsService';

const EmployeeExitsListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addToast = useToast();
  const [columns] = useColumns();
  const services = useSeparationsService();
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [initiateSeparation, setInitiateSeparation] = useState(false);
  const [employeeSeparationId, setEmployeeSeparationId] = useState();
  const [controller, handlePageChange, handleSortChange, handleFiltersApply, handleFiltersClear] = useController();
  const { pageSize } = usePaginationAttributes();
  const baseProperties = columns.filter((column) => column.type);

  const [getEmployeeExitsList, isLoadingEmployeeExitsList, loadingEmployeeExitsListError] =
    useThunk(fetchEmployeeExitsList);

  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasInitiateSeparationPermission = hasPermission(authorities, [
    'ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_CREATE_SPECIFIC_EMPLOYEE_EXIT',
  ]);

  const fetchEmployeeExitsListAction = useCallback(() => {
    const query = new URLSearchParams({});
    if (controller) {
      query.append('sort', controller.sortBy);
      query.append('order', controller.sort);
    }
    query.append('offset', controller.page * pageSize);
    query.append('max', pageSize);
    getEmployeeExitsList(query);
  }, [getEmployeeExitsList, controller, pageSize, services.refreshEmployeeExitsList]);

  useEffect(() => {
    fetchEmployeeExitsListAction();
  }, [fetchEmployeeExitsListAction, controller, pageSize]);

  useEffect(() => {
    if (initiateSeparation) {
      navigate(`/myteam/separations/initiate-employee-separation/${employeeSeparationId}`);
    }
  }, [initiateSeparation]);

  const { selectedEmployee, selectedEmployees, employees, empList } = useSelector((state) => state.separations);

  const handleSelect = (event, rowId) => {
    dispatch(updateSelectedEmployees({ isChecked: event.target.checked, rowId }));
  };

  const handleSelectAll = (event) => {
    dispatch(updateAllSelectedEmployees({ isChecked: event.target.checked }));
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [handleActionItemClick] = useActionItems(services);

  const handleFilter = () => {
    setShowFilterDialog(!showFilterDialog);
  };

  if (loadingEmployeeExitsListError !== null) {
    return (
      <Typography variant="h6">
        {loadingEmployeeExitsListError?.message || 'Error while loading employees exit list.'}
      </Typography>
    );
  }

  const filteredEmployee =
    isArray(empList) && !isEmpty(empList)
      ? empList.map((item) => ({
          id: item.id,
          name: item.fullName,
        }))
      : [];

  const handleSubmit = async (formData) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('id', formData?.employee);
      const response = await initiateSpecificEmpExit(bodyFormData);
      if (response?.status === 'SUCCESS') {
        navigate(`/myteam/separations/initiate-employee-separation/${formData?.employee}`);
        services.handleInitiateSeparation(response);
        setInitiateSeparation(true);
        setEmployeeSeparationId(formData?.employee);
      } else {
        addToast({ type: 'error', title: 'ERROR', message: response?.message });
      }
    } catch (error) {
      addToast({ type: 'error', title: 'ERROR', message: error?.message });
    }
  };

  return (
    <Box>
      <SeparationTemplate
        loading={isLoadingEmployeeExitsList}
        columns={columns}
        rows={employees?.data}
        count={employees?.count}
        selectedItems={selectedEmployees}
        onSelect={handleSelect}
        onSelectAll={handleSelectAll}
        showRowSelector={false}
        showPagination
        onPageChange={handlePageChange}
        handleSort={handleSortChange}
        handleRowClick={() => {}}
        page={controller?.page}
        keyColumn="id"
        showActionList
        sortBy={controller.sortBy}
        sortDirection={controller.sort}
        roleBasedMenu={handleActionItemClick}
        handleSelect={(row) => {
          dispatch(updateSelectedEmployee(row));
        }}
        onSelectedChange={() => {}}
        handleFilter={handleFilter}
        pageHeading="Separations"
        showInitiateSeparation={userHasInitiateSeparationPermission}
        // TODO: Make showActions true when add functionality for Bulk Delete, Bulk Lock Details and Bulk Accept
        showActions={false}
        employeeOptions={filteredEmployee}
        handleSubmit={handleSubmit}
      />
      {selectedEmployee !== null && (
        <>
          <DeleteSeparation
            open={services.showDeleteDialog}
            handleClose={services.handleDeleteDialog}
            handleCancel={services.handleDeleteDialog}
            handleComment={() => {
              services.deleteSeparation(selectedEmployee?.empExit?.id);
            }}
          />
          <LockEmployee
            open={services.showLockDetailsDialog}
            handleClose={services.handleLockDetailsDialog}
            handleCancel={services.handleLockDetailsDialog}
            handleComment={() => {
              services.lockDetailsSeparation(selectedEmployee);
            }}
          />
        </>
      )}
      {showFilterDialog && (
        <FilterDialog
          onApply={handleFiltersApply}
          onClear={handleFiltersClear}
          onClose={() => setShowFilterDialog(!showFilterDialog)}
          open={showFilterDialog}
          properties={baseProperties}
        />
      )}
    </Box>
  );
};

export default EmployeeExitsListPage;
