import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import TabCard from './TabCard';

const AlumniDashboard = ({
  configuredFnfDetails,
  configuredTDSData,
  configuredSalaryDetails,
  salaryDetailsColumns,
  salarySlipDataColumn,
  salarySlipDataRow,
  onSalarySlipRowRowClick,
  configuredDocumentDetails,
  userHasViewFnFStatementPermission,
  userHasPrintSalarySlipPermission,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={12}>
        <TabCard
          configuredFnfDetails={configuredFnfDetails}
          configuredTDSData={configuredTDSData}
          configuredSalaryDetails={configuredSalaryDetails}
          salaryDetailsColumns={salaryDetailsColumns}
          salarySlipDataColumn={salarySlipDataColumn}
          salarySlipDataRow={salarySlipDataRow}
          onSalarySlipRowRowClick={onSalarySlipRowRowClick}
          configuredDocumentDetails={configuredDocumentDetails}
          userHasViewFnFStatementPermission={userHasViewFnFStatementPermission}
          userHasPrintSalarySlipPermission={userHasPrintSalarySlipPermission}
        />
      </Grid>
    </Grid>
  );
};

AlumniDashboard.defaultProps = {
  userHasViewFnFStatementPermission: false,
  userHasPrintSalarySlipPermission: false,
  configuredFnfDetails: {},
  configuredSalaryDetails: {},
  salaryDetailsColumns: {},
  salarySlipDataColumn: [],
  salarySlipDataRow: [],
  onSalarySlipRowRowClick: () => {},
  configuredDocumentDetails: {},
  configuredTDSData: {},
};
AlumniDashboard.propTypes = {
  userHasViewFnFStatementPermission: PropTypes.bool,
  userHasPrintSalarySlipPermission: PropTypes.bool,
  configuredFnfDetails: PropTypes.shape({}),
  configuredSalaryDetails: PropTypes.shape({}),
  salaryDetailsColumns: PropTypes.shape({}),
  salarySlipDataColumn: PropTypes.arrayOf({}),
  salarySlipDataRow: PropTypes.arrayOf({}),
  onSalarySlipRowRowClick: PropTypes.func,
  configuredDocumentDetails: PropTypes.shape({}),
  configuredTDSData: PropTypes.shape({}),
};

export default AlumniDashboard;
