import React from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Tooltip } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import { toolTipStyle } from './style';

const ToolTip = ({ maxWidth, children, tooltipTitle, title, placement }) => {
  return (
    <Tooltip
      title={
        <Box maxWidth={maxWidth}>
          {tooltipTitle && (
            <Typographys variant="overline" display="block">
              {tooltipTitle}
            </Typographys>
          )}
          {title}
        </Box>
      }
      arrow
      placement={placement}
    >
      <Box sx={toolTipStyle.wrapper} maxWidth={maxWidth}>
        {children}
      </Box>
    </Tooltip>
  );
};

ToolTip.defaultProps = {
  children: <InfoIcon fontSize="2rem" />,
  tooltipTitle: '',
  title: 'My Tooltip Content',
  placement: 'bottom',
  maxWidth: '27rem',
};

ToolTip.propTypes = {
  tooltipTitle: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.element,
  maxWidth: PropTypes.string,
  placement: PropTypes.oneOf([
    'bottom-end',
    'bottom-start',
    'bottom',
    'left-end',
    'left-start',
    'left',
    'right-end',
    'right-start',
    'right',
    'top-end',
    'top-start',
    'top',
  ]),
};

export default ToolTip;
