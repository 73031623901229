import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

const InputField = ({
  id,
  name,
  label,
  type,
  maxRows,
  error,
  helperText,
  placeholder,
  defaultValue,
  variant,
  size,
  disabled,
  multiline,
  description,
  onChange,
  fullWidth,
  ...other
}) => {
  let inputProps = {};
  if (multiline) {
    inputProps = { sx: { minHeight: '10rem' } };
  } else if (description) {
    inputProps = { sx: { minHeight: '5rem' } };
  }
  return (
    <TextField
      id={id}
      name={name}
      size={size}
      label={label}
      type={type}
      variant={variant}
      placeholder={placeholder}
      fullWidth={fullWidth}
      defaultValue={defaultValue}
      disabled={disabled}
      error={error}
      multiline={multiline}
      description={description}
      maxRows={maxRows}
      onChange={onChange}
      helperText={error && helperText && helperText}
      inputProps={inputProps}
      {...other}
    />
  );
};
InputField.defaultProps = {
  id: '',
  name: 'name',
  label: '',
  type: 'text',
  variant: 'outlined',
  size: 'medium',
  placeholder: '',
  defaultValue: '',
  disabled: false,
  error: false,
  multiline: false,
  description: false,
  maxRows: 10,
  onChange: () => {},
  fullWidth: false,
  helperText: '',
};
InputField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  multiline: PropTypes.bool,
  description: PropTypes.bool,
  maxRows: PropTypes.number,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
};
export default InputField;
