import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Divider, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import Button from 'components/Atoms/Button';
import DatePicker from 'components/Atoms/Datepicker';
import Dropdown from 'components/Molecules/Dropdown';
import Checkbox from 'components/Atoms/Checkbox';
import InputField from 'components/Atoms/InputField';
import * as yup from 'yup';
import PerfectScrollbar from 'react-perfect-scrollbar';
import dayjs from 'dayjs';
import { separationStyle } from '../style';

const EditSeparation = ({
  handleSubmit,
  handleCancel,
  seperationOptions,
  employeeOptions,
  resStatusOptions,
  managerOptions,
  interviewerOptions,
  defaultValues,
}) => {
  const SignupSchema = yup.object().shape({
    employee: yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: SignupSchema,
    validateOnMount: true,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const handleDateChange = (date, fieldName) => {
    formik.setFieldValue(fieldName, date);
  };

  const theme = useTheme();

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 41rem)' }}>
          <Grid container rowSpacing={2} columnSpacing={9} mt={0}>
            <Grid item xs={12} md={4}>
              <Dropdown
                error={Boolean(formik.touched.employee && formik.errors.employee)}
                helperText={formik.touched.employee && formik.errors.employee}
                handleChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values?.employee}
                name="employee"
                id="employee"
                label="Employee*"
                options={employeeOptions}
                keyGetter={(item) => item.id}
                titleGetter={(item) => item.fullName}
                fullWidth
                disabled
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Dropdown
                error={Boolean(formik.touched.seperationType && formik.errors.seperationType)}
                helperText={formik.touched.seperationType && formik.errors.seperationType}
                handleChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values?.seperationType}
                name="seperationType"
                id="seperationType"
                label="Separation Type"
                options={seperationOptions}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <DatePicker
                error={Boolean(formik.touched.relievingDate && formik.errors.relievingDate)}
                helperText={formik.touched.relievingDate && formik.errors.relievingDate}
                onChange={(date) => handleDateChange(date, 'relievingDate')}
                onBlur={formik.handleBlur}
                value={formik.values?.relievingDate ? dayjs(formik.values?.relievingDate) : null}
                name="relievingDate"
                id="relievingDate"
                label="Relieving Date"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <DatePicker
                error={Boolean(formik.touched.finalSettlementDate && formik.errors.finalSettlementDate)}
                helperText={formik.touched.finalSettlementDate && formik.errors.finalSettlementDate}
                onChange={(date) => handleDateChange(date, 'finalSettlementDate')}
                onBlur={formik.handleBlur}
                value={formik.values?.finalSettlementDate ? dayjs(formik.values?.finalSettlementDate) : null}
                name="finalSettlementDate"
                id="finalSettlementDate"
                label="Final Settlement Date"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <DatePicker
                error={Boolean(formik.touched.ResSubmissionDate && formik.errors.ResSubmissionDate)}
                helperText={formik.touched.resSubmissionDate && formik.errors.resSubmissionDate}
                onChange={(date) => handleDateChange(date, 'resSubmissionDate')}
                onBlur={formik.handleBlur}
                value={formik.values?.resSubmissionDate ? dayjs(formik.values?.resSubmissionDate) : null}
                name="resSubmissionDate"
                id="resSubmissionDate"
                label="Resignation Submission Date"
                fullWidth
                disabled
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <DatePicker
                error={Boolean(formik.touched.resAcceptDate && formik.errors.resAcceptDate)}
                helperText={formik.touched.resAcceptDate && formik.errors.resAcceptDate}
                onChange={(date) => handleDateChange(date, 'resAcceptDate')}
                onBlur={formik.handleBlur}
                value={formik.values?.resAcceptDate ? dayjs(formik.values?.resAcceptDate) : null}
                name="resAcceptDate"
                id="resAcceptDate"
                label="Resignation Acceptance Date"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Dropdown
                error={Boolean(formik.touched.resAcceptManager && formik.errors.resAcceptManager)}
                helperText={formik.touched.resAcceptManager && formik.errors.resAcceptManager}
                handleChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values?.resAcceptManager}
                name="resAcceptManager"
                id="resAcceptManager"
                label="Resignation Accepting Manager"
                options={managerOptions}
                keyGetter={(item) => item.id}
                titleGetter={(item) => item.fullName}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Dropdown
                error={Boolean(formik.touched.resStatus && formik.errors.resStatus)}
                helperText={formik.touched.resStatus && formik.errors.resStatus}
                handleChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values?.resStatus}
                name="resStatus"
                id="resStatus"
                label="Resignation Status"
                options={resStatusOptions}
                fullWidth
              />
            </Grid>
          </Grid>

          <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

          <Grid container rowSpacing={2} columnSpacing={9} alignItems="center">
            <Grid item xs={12} md={4}>
              <DatePicker
                error={Boolean(formik.touched.exitInterviewDate && formik.errors.exitInterviewDate)}
                helperText={formik.touched.exitInterviewDate && formik.errors.exitInterviewDate}
                onChange={(date) => handleDateChange(date, 'exitInterviewDate')}
                onBlur={formik.handleBlur}
                value={formik.values?.exitInterviewDate ? dayjs(formik.values?.exitInterviewDate) : null}
                name="exitInterviewDate"
                id="exitInterviewDate"
                label="Exit Interview Date"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Dropdown
                error={Boolean(formik.touched.exitInterviewer && formik.errors.exitInterviewer)}
                helperText={formik.touched.exitInterviewer && formik.errors.exitInterviewer}
                handleChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values?.exitInterviewer}
                name="exitInterviewer"
                id="exitInterviewer"
                label="Exit Interviewer"
                options={interviewerOptions}
                keyGetter={(item) => item.id}
                titleGetter={(item) => item.fullName}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Checkbox
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                checked={formik.values?.eligibleForRehire}
                color="secondary"
                name="eligibleForRehire"
                label="Eligible For Rehire"
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <InputField
                error={Boolean(formik.touched.resReasons && formik.errors.resReasons)}
                helperText={formik.touched.resReasons && formik.errors.resReasons}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values?.resReasons}
                name="resReasons"
                id="resReasons"
                label="Resignation Reasons"
                multiline
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </PerfectScrollbar>

      <Box sx={separationStyle.buttonWrapper}>
        <Divider sx={separationStyle.Divider} />

        <Box sx={separationStyle.flexWrapper}>
          <Button
            size="large"
            color="info"
            variant="outlined"
            type="cancel"
            onClick={() => {
              formik.resetForm();
              handleCancel();
            }}
          >
            Cancel
          </Button>

          <Button size="large" type="submit">
            Update
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

EditSeparation.defaultProps = {
  handleSubmit: () => {},
  handleCancel: () => {},
  defaultValues: {},
  seperationOptions: [],
  employeeOptions: [],
  resStatusOptions: [],
  managerOptions: [],
  interviewerOptions: [],
};

EditSeparation.propTypes = {
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  defaultValues: PropTypes.shape({}),
  seperationOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),

  employeeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),

  resStatusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),

  managerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),

  interviewerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
};

export default EditSeparation;
