const getColumns = () => [
  {
    fieldName: 'employerName',
    numeric: false,
    label: 'Employer Name',
    type: 'string',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'status',
    numeric: false,
    label: 'Status',
    type: 'string',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'employerTAN',
    numeric: false,
    label: 'Employer TAN',
    type: 'string',
    formatValue: ({ row }) => row?.employerTAN,
  },
  {
    fieldName: 'employerAddress',
    numeric: false,
    label: 'Employer Address',
    type: 'string',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'incomeChargeableUnderHeadSalaries',
    numeric: false,
    label: 'Income Chargeable Under Head Salaries',
    type: 'string',
    formatValue: ({ row, column }) => row[column.fieldName],
  },

  {
    fieldName: 'deductionsUnderSection16',
    numeric: false,
    label: 'Deductions Under Section 16',
    type: 'string',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'deductionUnderChapterVIA',
    numeric: false,
    label: 'Deduction Under Chapter VIA',
    type: 'string',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'netTaxPayable',
    numeric: false,
    label: 'Net Tax Payable',
    type: 'string',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'netTaxDeducted',
    numeric: false,
    label: 'Net Tax Deducted',
    type: 'string',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'netProfessionalTaxPaid',
    numeric: false,
    label: 'Net Professional Tax Paid',
    type: 'string',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'netPFContributed',
    numeric: false,
    label: 'Net PF Contributions',
    type: 'string',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
  {
    fieldName: 'taxPayableRefundable',
    numeric: false,
    label: 'Net Tax Refundable',
    type: 'string',
    formatValue: ({ row, column }) => row[column.fieldName],
  },
];

const useColumns = () => {
  const columns = getColumns();
  return [columns];
};

export default useColumns;
