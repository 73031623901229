import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import CheckWithTitle from 'components/Atoms/CheckWithTitle';

const NotificationSetting = ({ notificationLists }) => {
  return (
    <Grid sx={{ paddingLeft: '24px', marginTop: '16px' }} container rowSpacing={2}>
      {notificationLists.map((list) => (
        <Grid item xs={12} key={list.title} mb={1}>
          <CheckWithTitle
            iconColor="secondary.600"
            iconFontSize="3rem"
            columnSpacing={1.5}
            title={list.title}
            titleVariant="subtitle1"
            titleColor="neutral.700"
            isChecked={list.status}
          />
        </Grid>
      ))}
    </Grid>
  );
};

NotificationSetting.defaultProps = {
  notificationLists: [
    { title: 'Notify Me upon Expense Report Updates', status: true },
    { title: 'Notify Me upon Attendance Exception Occurance', status: false },
  ],
};

NotificationSetting.propTypes = {
  notificationLists: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      status: PropTypes.bool,
    }),
  ),
};

export default NotificationSetting;
