import React from 'react';
import PropTypes from 'prop-types';
import MuiTypography from '@mui/material/Typography';

import { MuiThemeProvider } from '../../design-tokens/theme/theme';
import { typographyVariants } from '../../design-tokens/theme/constants/variants/typography';

export const Typography = ({ children, component, variant, dataTestId, ...props }) => {
  return (
    <MuiThemeProvider>
      <MuiTypography data-testid={dataTestId} component={component} variant={variant} {...props}>
        {children}
      </MuiTypography>
    </MuiThemeProvider>
  );
};

Typography.propTypes = {
  children: PropTypes.string,
  component: PropTypes.oneOf(['label', 'p', 'a', 'span']),
  dataTestId: PropTypes.string,
  variant: PropTypes.oneOf([
    'body1',
    'body2',
    'button',
    'caption',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'inherit',
    'overline',
    'subtitle1',
    'subtitle2',
    ...Object.values(typographyVariants),
  ]),
};

Typography.defaultProps = {
  component: 'label',
  variant: typographyVariants.MBody600,
  dataTestId: 'muiTypography',
};
