import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import TableExtended from 'components/Molecules/TableExtended';
import Typographys from 'components/Atoms/Typography';
import { tdsStyle } from 'components/Organisms/MyPay/TDS/View/style';
import HouseRentDeclarationsForm from './Form';

const HouseRentDeclarations = ({
  houseRentDataRow,
  allowEdit,
  cityOption,
  formik,
  houseRentFormSortDirection,
  houseRentFormSortBy,
  houseRentFormOnSortChange,
  houseRentTableSortDirection,
  houseRentTableSortBy,
  houseRentTableOnSortChange,
}) => {
  const houseRentDataColumn = [
    {
      fieldName: `slNo`,
      label: 'Sl No',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `period`,
      label: 'PERIOD',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `landlordName`,
      label: 'Landlord Name',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `landlordPan`,
      label: 'Landlord Pan',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `landlordAddress`,
      label: 'Landlord Address',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `city`,
      label: 'city of rented accommodation',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: `rentPaid`,
      label: 'Rent Paid/Payable',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
  ];
  return (
    <Box sx={tdsStyle.houseRentWrapper}>
      <Typographys variant="subtitle1" color="neutral.800">
        NOTE: Please fill in the details below only if you are currently staying/have stayed/going to stay in a rental
        accommodation for any part of the current Financial Year:
      </Typographys>
      <Box mt={2} sx={tdsStyle.houseRentTableWrapper}>
        {allowEdit ? (
          <HouseRentDeclarationsForm
            formik={formik}
            cityOption={cityOption}
            showCheckbox={false}
            sortDirection={houseRentFormSortDirection}
            sortBy={houseRentFormSortBy}
            onSortChange={houseRentFormOnSortChange}
          />
        ) : (
          <TableExtended
            loading={false}
            rows={houseRentDataRow}
            columns={houseRentDataColumn}
            showActionList={false}
            showCheckbox={false}
            sortDirection={houseRentTableSortDirection}
            sortBy={houseRentTableSortBy}
            onSortChange={houseRentTableOnSortChange}
          />
        )}
      </Box>
    </Box>
  );
};
HouseRentDeclarations.defaultProps = {
  houseRentDataRow: [],
  cityOption: [],
  allowEdit: true,
  formik: {},
  houseRentFormSortDirection: 'desc',
  houseRentFormSortBy: 'id',
  houseRentFormOnSortChange: () => {},
  houseRentTableSortDirection: 'desc',
  houseRentTableSortBy: 'id',
  houseRentTableOnSortChange: () => {},
};
HouseRentDeclarations.propTypes = {
  houseRentDataRow: PropTypes.arrayOf(
    PropTypes.shape({
      slNo: PropTypes.number,
      period: PropTypes.string,
      landlordName: PropTypes.string,
      landlordPan: PropTypes.string,
      landlordAddress: PropTypes.string,
      city: PropTypes.string,
      rentPaid: PropTypes.number,
    }),
  ),
  cityOption: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, name: PropTypes.string, onClick: PropTypes.func }),
  ),
  allowEdit: PropTypes.bool,
  formik: PropTypes.shape({
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
  }),
  houseRentFormSortDirection: PropTypes.string,
  houseRentFormSortBy: PropTypes.string,
  houseRentFormOnSortChange: PropTypes.func,
  houseRentTableSortDirection: PropTypes.string,
  houseRentTableSortBy: PropTypes.string,
  houseRentTableOnSortChange: PropTypes.func,
};

export default HouseRentDeclarations;
