import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import { leaveAccountBlock } from '../style';

const LeaveAccount = ({ boxBackground, boxPadding, boxBorderRadius, leaveAccountData, calendarYear }) => {
  return (
    <Box bgcolor={boxBackground} padding={boxPadding} borderRadius={boxBorderRadius}>
      <Box sx={leaveAccountBlock.container}>
        {leaveAccountData.map((leave) => (
          <Box sx={leaveAccountBlock.innerBlock}>
            <Typographys variant="h1" fontWeight="600" color="secondary.600" sx={{ lineHeight: '1' }}>
              {leave.balance}
            </Typographys>
            <Typographys variant="body1" fontWeight="600" color="secondary.600">
              {leave.label}
            </Typographys>
          </Box>
        ))}
      </Box>

      <Box sx={{ textAlign: 'center', mt: 2 }}>
        <Typographys variant="caption" color="neutral.600">
          For the calendar year {calendarYear}
        </Typographys>
      </Box>
    </Box>
  );
};

LeaveAccount.defaultProps = {
  leaveAccountData: [
    {
      balance: 12,
      label: 'Total Requests',
    },
    {
      balance: 8,
      label: 'Approved',
    },
    {
      balance: 4,
      label: 'Cancelled',
    },
    {
      balance: 4,
      label: 'Pending approval',
    },
    {
      balance: 4,
      label: 'Rejected',
    },
  ],
  boxBackground: 'neutral.100',
  boxPadding: '2rem',
  boxBorderRadius: '0.75rem',
  calendarYear: '01-Jan-2023 to 31-Dec-2023',
};
LeaveAccount.propTypes = {
  boxBackground: PropTypes.string,
  boxPadding: PropTypes.string,
  boxBorderRadius: PropTypes.string,
  leaveAccountData: PropTypes.arrayOf(
    PropTypes.shape({
      balance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  calendarYear: PropTypes.string,
};

export default LeaveAccount;
