import { validateDateElseHyphen } from 'utils/commonUtils';

const GetDocumentList = (docArray) => {
  const allDocObjects = docArray.map((document, index) => ({
    slNo: index + 1,
    documentName: document?.documentName,
    documentType: document?.documentType,
    section: '-',
    documentCreated: validateDateElseHyphen(document?.dateCreated, 'DD MMMM YYYY'),
    documentVersion: document?.docVersion,
    documentDescription: document?.docDescription ? document?.docDescription : '-',
    documentStatus: document?.status,
    documentDistributionType: document?.docOwnerType,
    documentOwner: document?.creatingEmployee?.fullName ? document?.creatingEmployee?.fullName : '-',
    publicationStatus: document?.publicationStatus,
  }));

  return allDocObjects;
};

export { GetDocumentList };
