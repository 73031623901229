import PropTypes from 'prop-types';
import React from 'react';
import Dialog from 'components/Molecules/Dialog';

const LockEmployee = ({ handleComment, handleCancel, secondaryBtn, primaryBtn, open, handleClose }) => {
  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      dialogTitle="Lock Employee Records"
      dialogDesc="Are you sure you want to lock employee’s records?"
      secondaryBtn={secondaryBtn}
      primaryBtn={primaryBtn}
      handlePrimaryBtn={handleComment}
      handleSecondaryBtn={handleCancel}
      bodyContent={false}
    />
  );
};
LockEmployee.defaultProps = {
  secondaryBtn: 'No',
  primaryBtn: 'Lock',
  handleComment: () => {},
  handleCancel: () => {},
  open: true,
  handleClose: () => {},
};
LockEmployee.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleComment: PropTypes.func,
  handleCancel: PropTypes.func,
  secondaryBtn: PropTypes.string,
  primaryBtn: PropTypes.string,
};
export default LockEmployee;
