import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Divider, useTheme } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Button from 'components/Atoms/Button';
import InputField from 'components/Atoms/InputField';
import Typographys from 'components/Atoms/Typography';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { FieldArray, getIn, Formik } from 'formik';
import DatePicker from 'components/Atoms/Datepicker';
import Dropdown from 'components/Molecules/Dropdown';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';

import { onBoardingStyle } from '../../../style';

const KeyLifeEventDetails = ({ handleCancel, propData, label, handleSubmit, lifeEventOptions }) => {
  const defaultObj = {
    eventDetails: '',
    eventDate: '',
    comments: '',
  };
  const defaultValues = propData.allTabsData[label];

  return (
    <Box mt={1.5}>
      <Formik
        initialValues={
          defaultValues.keyLifeEvent.length > 0 && Object.keys(defaultValues.keyLifeEvent[0]).length
            ? defaultValues
            : { keyLifeEvent: [{ key: 1 }] }
        }
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form
            formik={formik}
            handleCancel={handleCancel}
            propData={propData}
            label={label}
            defaultObj={defaultObj}
            lifeEventOptions={lifeEventOptions}
          />
        )}
      </Formik>
    </Box>
  );
};

const Form = ({ formik, handleCancel, propData, label, defaultObj, lifeEventOptions }) => {
  const [keyCount, setKeyCount] = useState(2);
  useEffect(() => {
    propData.updateFormData(formik.values, label);
  }, [formik.values, label]);

  const handleDateChange = (date, setFieldValue, fieldName) => {
    setFieldValue(fieldName, date);
  };

  const theme = useTheme();

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 59rem)' }}>
          <Box sx={{ mb: 2 }}>
            <Typographys variant="h5" color="neutral.800">
              Key Life Event Details
            </Typographys>
          </Box>

          <FieldArray name="keyLifeEvent">
            {({ push, remove }) => (
              <Box>
                {formik?.values?.keyLifeEvent.map((e, index) => {
                  return (
                    <Accordion key={e.key} disableGutters elevation={0} square sx={onBoardingStyle.accordionWrapper}>
                      <AccordionSummary
                        aria-controls="keyLifeEvent-content"
                        id="keyLifeEvent-header"
                        expandIcon={<KeyboardArrowDownSharpIcon sx={{ fontSize: '3rem', color: 'neutral.800' }} />}
                      >
                        <Box sx={onBoardingStyle.deleteContainer}>
                          <Typography variant="subtitle2" color="neutral.800">
                            Record {index + 1}
                          </Typography>
                          <DeleteOutlineSharpIcon sx={{ color: 'neutral.600' }} onClick={() => remove(index)} />
                        </Box>
                      </AccordionSummary>

                      <AccordionDetails>
                        <Grid container rowSpacing={2} columnSpacing={9} pt={0} sx={onBoardingStyle.formContainer}>
                          <Grid item xs={12} md={6}>
                            <Dropdown
                              error={Boolean(
                                getIn(formik.touched, `keyLifeEvent[${index}].eventDetails`) &&
                                  getIn(formik.errors, `keyLifeEvent[${index}].eventDetails`),
                              )}
                              helperText={
                                getIn(formik.touched, `keyLifeEvent[${index}].eventDetails`) &&
                                getIn(formik.errors, `keyLifeEvent[${index}].eventDetails`)
                              }
                              handleChange={(k) => {
                                formik.handleChange(k);
                              }}
                              onBlur={formik.handleBlur}
                              value={e.eventDetails}
                              name={`keyLifeEvent[${index}].eventDetails`}
                              id={`keyLifeEvent[${index}].eventDetails`}
                              label="Key Life Events Details"
                              options={lifeEventOptions}
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <DatePicker
                              error={Boolean(
                                getIn(formik.touched, `keyLifeEvent[${index}].eventDate`) &&
                                  getIn(formik.errors, `keyLifeEvent[${index}].eventDate`),
                              )}
                              helperText={
                                getIn(formik.touched, `keyLifeEvent[${index}].eventDate`) &&
                                getIn(formik.errors, `keyLifeEvent[${index}].eventDate`)
                              }
                              onChange={(data) =>
                                handleDateChange(data, formik.setFieldValue, `keyLifeEvent[${index}].eventDate`)
                              }
                              onBlur={formik.handleBlur}
                              value={e?.eventDate}
                              name={`keyLifeEvent[${index}].eventDate`}
                              id={`keyLifeEvent[${index}].eventDate`}
                              label="Event Date"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <InputField
                              error={Boolean(
                                getIn(formik.touched, `keyLifeEvent[${index}].comments`) &&
                                  getIn(formik.errors, `keyLifeEvent[${index}].comments`),
                              )}
                              helperText={
                                getIn(formik.touched, `keyLifeEvent[${index}].comments`) &&
                                getIn(formik.errors, `keyLifeEvent[${index}].comments`)
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={e.comments}
                              name={`keyLifeEvent[${index}].comments`}
                              id={`keyLifeEvent[${index}].comments`}
                              label="Comments"
                              multiline
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}

                <Box sx={{ mt: 3 }}>
                  <Button
                    size="medium"
                    variant="outlined"
                    type="button"
                    startIcon={<AddOutlinedIcon />}
                    onClick={() => {
                      push({ ...defaultObj, key: keyCount });
                      setKeyCount(keyCount + 1);
                    }}
                  >
                    Add Another
                  </Button>
                </Box>
              </Box>
            )}
          </FieldArray>
        </Box>
      </PerfectScrollbar>

      <Box sx={onBoardingStyle.btnContainer}>
        <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

        <Box sx={onBoardingStyle.submitWrapper}>
          <Button
            size="large"
            color="info"
            variant="outlined"
            type="button"
            onClick={() => {
              formik.resetForm();
              handleCancel();
            }}
          >
            Cancel
          </Button>

          <Button size="large" type="submit" isDisable={false}>
            Save & Continue
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

Form.defaultProps = {
  handleCancel: () => {},
  label: '',
  propData: {},
  formik: {},
  defaultObj: {},
  lifeEventOptions: [],
};

Form.propTypes = {
  propData: PropTypes.shape({
    updateFormData: PropTypes.func,
    allTabsData: PropTypes.shape({
      'Key Life Event Details': PropTypes.shape(),
    }),
    allowEdit: PropTypes.bool,
  }),
  handleCancel: PropTypes.func,

  label: PropTypes.string,
  formik: PropTypes.shape({
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    values: PropTypes.shape(),
    resetForm: PropTypes.func,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    setFieldValue: PropTypes.func,
  }),
  defaultObj: PropTypes.shape({}),
  lifeEventOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
};

KeyLifeEventDetails.defaultProps = {
  handleSubmit: () => {},
  handleCancel: () => {},
  label: 'Key Life Event Details',

  propData: {
    allTabsData: {
      'Key Life Event Details': {
        keyLifeEvent: [
          {
            eventDetails: '',
            eventDate: '',
            comments: '',
          },
        ],
      },
    },
    updateFormData: () => {},
  },
  lifeEventOptions: [],
};

KeyLifeEventDetails.propTypes = {
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  propData: PropTypes.shape({
    allTabsData: PropTypes.shape({}),
    updateFormData: PropTypes.func,
  }),
  label: PropTypes.string,
  lifeEventOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
};

export default KeyLifeEventDetails;
