import PropTypes from 'prop-types';
import React from 'react';
import Typographys from 'components/Atoms/Typography';
import Dialog from 'components/Molecules/Dialog';
import InputField from 'components/Atoms/InputField/index';
import { Box } from '@mui/material';

const AnonymiseEmployee = ({
  handleComment,
  handleCancel,
  secondaryBtn,
  primaryBtn,
  primaryBtnColor,
  onChange,
  open,
  handleClose,
}) => {
  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      dialogTitle="Anonymise Employee"
      dialogDesc="Are you sure you want to anonymise employee?"
      color={primaryBtnColor}
      secondaryBtn={secondaryBtn}
      primaryBtn={primaryBtn}
      handlePrimaryBtn={handleComment}
      handleSecondaryBtn={handleCancel}
      bodyContent={
        <Box sx={{ width: '100%' }}>
          <InputField
            multiline
            name="username"
            required
            fullWidth
            id="username"
            label="Comments"
            autoComplete="username"
            autoFocus
            onChange={onChange}
          />
          <Box sx={{ marginTop: '2rem' }}>
            <Typographys variant="body2">Please Type the captcha</Typographys>
          </Box>
        </Box>
      }
    />
  );
};
AnonymiseEmployee.defaultProps = {
  secondaryBtn: 'Cancel',
  primaryBtn: 'Anonymise',
  handleComment: () => {},
  handleCancel: () => {},
  onChange: () => {},
  primaryBtnColor: 'primary',
  open: true,
  handleClose: () => {},
};
AnonymiseEmployee.propTypes = {
  primaryBtnColor: PropTypes.string,
  handleComment: PropTypes.func,
  onChange: PropTypes.func,
  handleCancel: PropTypes.func,
  secondaryBtn: PropTypes.string,
  primaryBtn: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};
export default AnonymiseEmployee;
