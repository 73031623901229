import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import MuiTab from 'components/Molecules/Tab';
import LanguageIcon from '@mui/icons-material/Language';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import SchoolIcon from '@mui/icons-material/School';
import HighlightIcon from '@mui/icons-material/Highlight';
import getHandleSubmit from 'utils/HandleTab';
import EducationHistory from './EducationHistory';
import EmploymentHistory from './EmploymentHistory';
import VisaDetail from './VisaDetails';
import KeyLifeEventDetails from './KeyEventDetail';
import SkillAttainmentDetails from './SkillsAttainmentDetails';
import OnBoarding from './OnBoarding';
import SocialData from './SocialData';
import DependentsAndNomintation from './DependentsAndNomintation';

const AdditionalDeatails = ({ handleSubmit, propData, label, dropdownOptions, handleCancel }) => {
  const defaultValues = propData.allTabsData['Additional Details'];
  const [allTabsData, setAllTabsData] = useState(defaultValues);
  const [initialTabs, setTabs] = useState([
    {
      icon: <GroupAddIcon />,
      iconPosition: 'start',
      label: 'OnBoarding',
      onChange: () => {},
      isDisabled: 0,
      RenderComponent: OnBoarding,
      componentProps: {
        noticeUnitOptions: dropdownOptions.noticeUnitOptions,
        seperationOptions: dropdownOptions.onboardingSeperationOptions,
        bloodOptions: dropdownOptions.bloodOptions,
        onboadringRefOptions: dropdownOptions.onboadringRefOptions,
      },
    },
    {
      icon: <LanguageIcon />,
      iconPosition: 'start',
      label: 'Social Data',
      onChange: () => {},
      isDisabled: propData?.allowEdit ? 0 : 1,
      RenderComponent: SocialData,
      componentProps: { handleCancel },
    },
    {
      icon: <SchoolIcon />,
      iconPosition: 'start',
      label: 'Education/Certification History',
      onChange: () => {},
      isDisabled: propData?.allowEdit ? 0 : 1,
      RenderComponent: EducationHistory,
      componentProps: { handleCancel },
    },

    {
      icon: <WatchLaterIcon />,
      iconPosition: 'start',
      label: 'Employment History',
      onChange: () => {},
      isDisabled: propData?.allowEdit ? 0 : 1,
      RenderComponent: EmploymentHistory,
      componentProps: { handleCancel },
    },
    {
      icon: <FlightTakeoffIcon />,
      iconPosition: 'start',
      label: 'Visa Details',
      onChange: () => {},
      isDisabled: propData?.allowEdit ? 0 : 1,
      RenderComponent: VisaDetail,
      componentProps: {},
    },
    {
      icon: <HighlightIcon />,
      iconPosition: 'start',
      label: 'Skill Attainment Details',
      onChange: () => {},
      isDisabled: propData?.allowEdit ? 0 : 1,
      RenderComponent: SkillAttainmentDetails,
      componentProps: { skillOptions: dropdownOptions.skillOptions, levelOptions: dropdownOptions.skillLevelOptions },
    },
    {
      icon: <EmojiEventsIcon />,
      iconPosition: 'start',
      label: 'Key Life Event Details',
      onChange: () => {},
      isDisabled: propData?.allowEdit ? 0 : 1,
      RenderComponent: KeyLifeEventDetails,
      componentProps: { lifeEventOptions: dropdownOptions.lifeEventOptions },
    },
    {
      icon: <EmojiEventsIcon />,
      iconPosition: 'start',
      label: 'Dependents Details',
      onChange: () => {},
      isDisabled: propData?.allowEdit ? 0 : 1,
      RenderComponent: DependentsAndNomintation,
      componentProps: {
        genderOptions: dropdownOptions.genderOptions,
        relationOptions: dropdownOptions.relationOptions,
        bloodOptions: dropdownOptions.bloodOptions,
      },
    },
  ]);

  function updateFormData(data, label1) {
    if (propData?.allowEdit) {
      const finalData = {
        ...allTabsData,
        [label1]: data,
      };
      setAllTabsData(finalData);
      propData.updateFormData(finalData, label);
    }
  }

  return (
    <Box sx={{ display: 'flex', padding: '2rem 3rem' }}>
      <MuiTab
        orientation="vertical"
        textColor="primary"
        indicatorColor="primary"
        isVertical
        panelPadding="0 3rem"
        tabs={initialTabs}
        setTabs={setTabs}
        setAllTabsData={setAllTabsData}
        propData={{ allTabsData, allowEdit: propData?.allowEdit, updateFormData }}
        handleSubmit={(label1, data, index) => {
          getHandleSubmit(label1)(data, { setTabs, setAllTabsData, selectedIndex: index });
          if (index === initialTabs.length - 1 || propData?.allowEdit) {
            const finalData = {
              ...allTabsData,
              [label1]: data,
            };
            handleSubmit(finalData);
          }
        }}
        disableChangingTabs={propData?.allowEdit}
      />
    </Box>
  );
};

AdditionalDeatails.defaultProps = {
  onboardingDetails: {},
  socialData: {},
  educationHistory: {},
  employmentHistory: {},
  visaDetails: {},
  skillsAttainmentDetails: {},
  keyEventDetails: {},
  handleSubmit: () => {},
  propData: {},
  label: '',
  dropdownOptions: {},
};

AdditionalDeatails.propTypes = {
  handleSubmit: PropTypes.func,
  onboardingDetails: PropTypes.shape({}),
  socialData: PropTypes.shape({}),
  educationHistory: PropTypes.shape({}),
  employmentHistory: PropTypes.shape({}),
  visaDetails: PropTypes.shape({}),
  skillsAttainmentDetails: PropTypes.shape({}),
  keyEventDetails: PropTypes.shape({}),
  propData: PropTypes.shape({
    updateFormData: PropTypes.func,
    allTabsData: PropTypes.shape({
      'Additional Details': PropTypes.shape({}),
    }),
    allowEdit: PropTypes.bool,
  }),
  label: PropTypes.string,
  dropdownOptions: PropTypes.shape({
    noticeUnitOptions: PropTypes.shape({}),
    bloodOptions: PropTypes.shape({}),
    onboadringRefOptions: PropTypes.shape({}),
    onboardingSeperationOptions: PropTypes.shape({}),
    skillOptions: PropTypes.shape({}),
    skillLevelOptions: PropTypes.shape({}),
    lifeEventOptions: PropTypes.shape({}),
    genderOptions: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })),
    relationOptions: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })),
  }),
};

export default AdditionalDeatails;
