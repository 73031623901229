import React, { useEffect } from 'react';
import ProfileDependentTemplate from 'components/Templates/Profile/Dependents';
import { useThunk } from 'hooks/useThunk';
import { useSelector } from 'react-redux';
import { fetchDependents } from 'store';
import { Typography, Skeleton, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Dependents = () => {
  const navigate = useNavigate();

  const [doFetchDependentDetails, isLoadingDependentDetails, loadingDependentDetailsError] = useThunk(fetchDependents);

  const { dependentsData } = useSelector((state) => {
    return state.profile;
  });

  useEffect(() => {
    doFetchDependentDetails();
  }, [doFetchDependentDetails]);

  const handleEdit = () => {
    navigate('/profile/dependents/addEdit');
  };

  if (isLoadingDependentDetails) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (loadingDependentDetailsError) {
    return (
      <Typography variant="h6">
        {loadingDependentDetailsError?.message || 'Error while fetching dependent details.'}
      </Typography>
    );
  }

  return <ProfileDependentTemplate handleEdit={handleEdit} details={dependentsData} />;
};

export default Dependents;
