import { makeStyles } from '@mui/styles';
import { getStyleWithImportant } from 'components/text-editor/getStyleWithImportant';
import { v4 as uuid } from 'uuid';

const uid = uuid();

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.palette.colors.colorBackgroundBase,
      border: theme.palette.colors.colorBorderPrimary,
      borderBottomLeftRadius: theme.shape.radiusTiny,
      borderBottomRightRadius: theme.shape.radiusTiny,
      borderWidth: theme.border.borderThin,
      borderTopWidth: theme.space.spaceSZero,
      borderStyle: 'solid',
    },
    innerBox: {
      padding: theme.space.spaceInsetNormal4x,
      backgroundColor: theme.palette.colors.colorBackgroundBase,
      borderBottomLeftRadius: theme.shape.radiusTiny,
      borderBottomRightRadius: theme.shape.radiusTiny,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    saveButton: {
      marginLeft: getStyleWithImportant(theme.space.spaceS3x),
    },
  }),
  {
    classNamePrefix: 'textEditorButtons',
    name: 'textEditorButtons',
    generateId: uid,
    index: uid,
  },
);
