import * as React from 'react';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import Typographys from 'components/Atoms/Typography';
import { Box } from '@mui/material';
import { TDSWorkingStyle } from 'components/Organisms/MyPay/TDSWorking/style';

const SalaryTable = ({ rows, columns }) => {
  return (
    <Paper sx={{ overflow: 'auto' }}>
      <Box sx={TDSWorkingStyle.SalaryTableHeader}>
        {columns.map((col) => {
          return (
            <Box sx={{ width: col.columnWidth }}>
              <Typographys variant="h6">{col.columnTitle}</Typographys>
            </Box>
          );
        })}
      </Box>

      {rows.map((row) => {
        return (
          <Box sx={TDSWorkingStyle.SalaryTableRows}>
            {columns.map((col) => {
              return (
                <Box sx={{ width: col.columnWidth }}>
                  <Typographys variant="body2">{row[col.fieldName]}</Typographys>
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Paper>
  );
};

SalaryTable.defaultProps = {
  rows: [],
  columns: [],
};

SalaryTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      columnTitle: PropTypes.string,
      columnWidth: PropTypes.string,
    }),
  ),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      earnings: PropTypes.string,
      annualAmount: PropTypes.string,
      perPeriodAmount: PropTypes.string,
    }),
  ),
};

export default SalaryTable;
