import React, { useEffect } from 'react';
import View from 'components/Templates/MyPay/PastEmployerTaxes/View';
import Dialog from 'components/Molecules/Dialog';
import { useThunk } from 'hooks/useThunk';
import { viewPreviousEmploymentTaxDetails } from 'store';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { isArray, isEmpty } from 'lodash';
import { hasPermission } from 'utils/permissions';
import { PAST_EMPLOYER_TAXES_STATUS_IN_DRAFT } from 'utils/SystemConfigConstants';
import useService from '../List/useService';
import useMenuItems from './useMenuItems';

const PreviousEmploymentTaxDetailsPage = () => {
  const { id } = useParams();
  const services = useService();
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasViewPermission = hasPermission(authorities, [
    'ROLE_BASE_PERMISSION_PAY_INDEX_VIEW_PREVIOUS_EMP_TAX_DETAILS_BY_EMPLOYEE',
  ]);
  const userHasEditPermission = hasPermission(authorities, [
    'ROLE_BASE_PERMISSION_PAY_INDEX_UPDATE_PREVIOUS_EMP_TAX_DETAILS_BY_EMPLOYEE',
  ]);

  const breadcrumbData = [
    { id: 1, name: 'Past Employer Taxes', path: '/home/mypay/past-employer-taxes', isActive: false },
    { id: 2, name: 'Details', path: '', isActive: true },
  ];

  const [
    getPreviousEmploymentTaxDetails,
    isLoadingPreviousEmploymentTaxDetails,
    loadingPreviousEmploymentTaxDetailsError,
  ] = useThunk(viewPreviousEmploymentTaxDetails);

  useEffect(() => {
    const query = new URLSearchParams({});
    query.append('id', id);
    getPreviousEmploymentTaxDetails(query);
  }, [id, getPreviousEmploymentTaxDetails, services?.refreshPage]);

  const { displayPreviousEmploymentTaxDetails = {} } = useSelector((state) => state.myPay);
  const {
    taxDetailsPreviousEmployerIndiaInstance = {},
    commentsMapList = [],
    commentsMap = {},
  } = displayPreviousEmploymentTaxDetails;

  const [menuItems] = useMenuItems(taxDetailsPreviousEmployerIndiaInstance, services);

  if (!userHasViewPermission) {
    return <Typography variant="h6">You do not have permission to view previous employment tax details</Typography>;
  }

  if (loadingPreviousEmploymentTaxDetailsError !== null) {
    return (
      <Typography variant="h6">
        {loadingPreviousEmploymentTaxDetailsError?.message || 'Error while loading previous employment tax details.'}
      </Typography>
    );
  }

  if (
    displayPreviousEmploymentTaxDetails &&
    !isEmpty(displayPreviousEmploymentTaxDetails) &&
    displayPreviousEmploymentTaxDetails?.status !== 'SUCCESS'
  ) {
    return (
      <Typography variant="h6">
        {displayPreviousEmploymentTaxDetails?.message || 'Past Employer Taxes has been disabled for the employee.'}
      </Typography>
    );
  }

  const getActionCreatorDetails = (comment) => {
    const commentsInstance = commentsMap && Object.keys(commentsMap)?.find((item) => item?.includes(comment?.id));

    return {
      fullName: commentsMap?.[`${commentsInstance}`]?.fullName || '',
      avatarSrc: `${commentsMap?.[`${commentsInstance}`]?.profile?.profilePic}`,
    };
  };

  const auditLogLists =
    isArray(commentsMapList) && !isEmpty(commentsMapList)
      ? commentsMapList?.map((item) => ({
          avatarSrc: getActionCreatorDetails(item)?.avatarSrc,
          description: getActionCreatorDetails(item)?.fullName,
          content: item?.content?.split('<br />'),
          date: item?.createdOn,
        }))
      : [];

  return (
    <Box>
      <View
        breadcrumbData={breadcrumbData}
        taxDetails={taxDetailsPreviousEmployerIndiaInstance}
        isEditAction={
          userHasEditPermission &&
          taxDetailsPreviousEmployerIndiaInstance?.status === PAST_EMPLOYER_TAXES_STATUS_IN_DRAFT
        }
        handleEdit={() => services?.editPastEmploymentTax({ id })}
        menuList={menuItems.length > 0 ? menuItems : []}
        loading={isLoadingPreviousEmploymentTaxDetails}
        handleAttachment={services?.handleAttachment}
        pageHeading="Past Employer Tax Details"
        auditLogLists={auditLogLists}
      />
      {taxDetailsPreviousEmployerIndiaInstance !== null && (
        <Dialog
          open={services?.showDeleteDialog}
          handleClose={services?.handleDeleteDialog}
          dialogTitle="Delete Past Employer Tax"
          dialogDesc={
            <Typography variant="body2" color="neutral.600" align="center">
              Are you sure you want to delete the entry for
              <Typography color="neutral.800" sx={{ display: 'inline', pl: 1 }}>
                {taxDetailsPreviousEmployerIndiaInstance?.employerName}
              </Typography>
              ?
            </Typography>
          }
          handlePrimaryBtn={() => services?.deletePastEmploymentTax(taxDetailsPreviousEmployerIndiaInstance, true)}
          primaryBtnColor="error"
          primaryBtn="Delete"
          secondaryBtn="Cancel"
          handleSecondaryBtn={services?.handleDeleteDialog}
          bodyContent={false}
        />
      )}
      {taxDetailsPreviousEmployerIndiaInstance !== null && (
        <Dialog
          open={services?.showSubmitDialog}
          handleClose={services?.handleSubmitDialog}
          dialogTitle="Submit Past Employer Tax"
          dialogDesc={
            <Typography variant="body2" color="neutral.600" align="center">
              Are you sure you want to submit the entry for
              <Typography color="neutral.800" sx={{ display: 'inline', pl: 1 }}>
                {taxDetailsPreviousEmployerIndiaInstance?.employerName}
              </Typography>
              ?
            </Typography>
          }
          handlePrimaryBtn={() => services?.submitPastEmploymentTax(taxDetailsPreviousEmployerIndiaInstance)}
          primaryBtnColor="primary"
          primaryBtn="Submit"
          secondaryBtn="Cancel"
          handleSecondaryBtn={services?.handleSubmitDialog}
          bodyContent={false}
        />
      )}
    </Box>
  );
};

export default PreviousEmploymentTaxDetailsPage;
