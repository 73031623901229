import React, { useState } from 'react';
import ProfileEditEducationalHistoryTemplate from 'components/Templates/Profile/EducationalHistory/Edit';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useToast from 'components/Provider/useToast';
import { updateEducationHistoryDetails } from 'api/profile';
import dayjs from 'dayjs';
import FormData from 'form-data';
import { dateFormat } from 'utils/commonUtils';

const EducationHistoryAddEdit = () => {
  const addToast = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { educationHistoryDetails } = useSelector((state) => {
    return state.profile;
  });

  const editOptions = {
    defaultValue: {
      education:
        educationHistoryDetails &&
        educationHistoryDetails?.map((education) => {
          return {
            institutionName: education?.institutionName || '',
            certificationName: education?.certificationName || '',
            startDate: education?.startDate ? dayjs(education?.startDate) : '',
            endDate: education?.endDate ? dayjs(education?.endDate) : '',
            specialization: education?.specialization || '',
            registrationNumber: education?.registrationNumber || '',
            referenceName: education?.referenceName || '',
            referenceNumber: education?.referencePhoneNumber || '',
            referenceEmail: education?.referenceEmail || '',
            comments: education?.comments || '',
            attachment: education?.documents.length > 0 ? education?.documents[0].fileName : '',
            isNew: false,
          };
        }),
    },
  };

  const getDataSet = (education, index) => {
    const data = {
      [`educationRecords[${index}].institutionName`]: education?.institutionName,
      [`educationRecords[${index}].certificationName`]: education?.certificationName,
      [`educationRecords[${index}].startDate`]: education?.startDate
        ? dayjs(education?.startDate).format('MM/DD/YYYY')
        : '',
      [`educationRecords[${index}].endDate`]: dateFormat(education?.endDate),
      [`educationRecords[${index}].registrationNumber`]: education?.registrationNumber,
      [`educationRecords[${index}].specialization`]: education?.specialization,
      [`educationRecords[${index}].referenceName`]: education?.referenceName,
      [`educationRecords[${index}].referencePhoneNumber`]: education?.referenceNumber || '',
      [`educationRecords[${index}].referenceEmail`]: education?.referenceEmail,
      [`educationRecords[${index}].comments`]: education?.comments,
    };
    if (education?.attachments) {
      data[`educationRecords[${index}].attachment`] = education?.attachments;
    } else if (!education?.isNew) {
      data[`educationRecords[${index}].id`] = educationHistoryDetails[index]?.id;
    }
    return data;
  };

  const configureFormDataAndUpdate = async (education) => {
    const formData = new FormData();
    education.forEach((payload) => {
      const index = education.indexOf(payload);
      const formDataObject = getDataSet(payload, index);
      Object.keys(formDataObject).forEach((property) => {
        formData.append(property, formDataObject[property]);
      });
    });
    formData.append(`eduRecordCtr`, education.length);

    return formData;
  };

  const handleCancel = () => {
    navigate('/profile/education-history');
  };

  const handleSubmit = async (formikData) => {
    const bodyFormData = await configureFormDataAndUpdate(formikData.education);

    try {
      setLoading(true);
      const response = await updateEducationHistoryDetails(bodyFormData);
      if (response?.data?.status !== 'ERROR') {
        addToast({
          type: 'success',
          title: 'SUCCESS',
          message: response?.data?.message || 'Education history updated successfully',
        });
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: error?.message || 'Failed to update education history',
      });
    } finally {
      setLoading(false);
      navigate('/profile/education-history');
    }
  };

  return (
    <ProfileEditEducationalHistoryTemplate
      defaultValue={editOptions.defaultValue}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      loading={loading}
    />
  );
};

export default EducationHistoryAddEdit;
