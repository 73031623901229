import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, useTheme } from '@mui/material';
import TableExtended from 'components/Molecules/TableExtended';
import Dropdown from 'components/Molecules/Dropdown';
import Chips from 'components/Molecules/Chip';
import Button from 'components/Atoms/Button';
import { useFormik } from 'formik';
import FilterButton from 'components/Organisms/TableFilter/FilterButton';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { policyListTabStyle } from '../../style';

const EmployeeUnmapped = ({
  handleFilter,
  filterFields,
  rows,
  totalRecordCount,
  loadNextPageData,
  handleSort,
  handleRowClick,
  page,
  sortBy,
  sortDirection,
  handleCancel,
  handleApplyChanges,
  initialValues,
}) => {
  const theme = useTheme();
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      Object.keys(values).forEach((id) => {
        if (values[id] === 'select') {
          values[id] = '';
        }
      });
      handleApplyChanges(values);
    },
  });

  const unmappedColumns = [
    {
      fieldName: 'employeeId',
      numeric: true,
      label: 'EMPLOYEE ID',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'employeeName',
      label: 'EMPLOYEE NAME',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'lcPolicy',
      label: 'LC POLICY',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'status',
      label: 'Status',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'department',
      label: 'DEPARTMENT',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'designation',
      label: 'DESIGNATION',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'location',
      label: 'LOCATION',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
    {
      fieldName: 'employmentType',
      label: 'EMPLOYMENT TYPE',
      formatValue: ({ row, column }) => row[column.fieldName],
    },
  ];
  const unMappedDropDown = (row, lcPolicies) => {
    return (
      <Dropdown
        id={row.id}
        name={row.id}
        label="Life Cycle Policy"
        size="small"
        handleChange={(d) => {
          formik.handleChange(d);
        }}
        onBlur={formik.handleBlur}
        value={formik.values[row.id] || null}
        options={lcPolicies}
      />
    );
  };
  const unMappedRow = rows.map((row) => {
    const lcPolicies = row.lcPolicy.map((list) => ({
      id: `${list.id}`,
      name: list.name,
      onClick: () => {},
    }));
    lcPolicies.unshift({ id: 'select', name: 'Please Select ..' });
    return {
      ...row,
      lcPolicy: unMappedDropDown(row, lcPolicies),
      status: <Chips label={row.status} isShowIcon={false} color="default" />,
    };
  });

  return (
    <Box sx={policyListTabStyle.wrapper}>
      <PerfectScrollbar>
        <Box sx={{ maxHeight: 'calc(100vh - 62rem)' }}>
          <FilterButton handleFilter={handleFilter} attributeOptions={filterFields} />
          <TableExtended
            columns={unmappedColumns}
            rows={unMappedRow}
            count={totalRecordCount}
            onPageChange={loadNextPageData}
            handleSort={handleSort}
            onRowClick={handleRowClick}
            page={page}
            sortBy={sortBy}
            sortDirection={sortDirection}
            showRowSelector={false}
            showActionList={false}
          />
        </Box>
      </PerfectScrollbar>
      <Box sx={policyListTabStyle.btnContainer}>
        <Divider sx={{ my: 2, borderColor: theme.palette.other[200] }} />
        <Box sx={policyListTabStyle.submitWrapper}>
          <Button
            size="large"
            variant="outlined"
            color="info"
            onClick={() => {
              formik.resetForm();
              handleCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            Apply Changes
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
EmployeeUnmapped.defaultProps = {
  filterFields: [
    {
      name: 'Employee Id',
      type: 'number',
    },
    {
      name: 'Employee Name',
      type: 'string',
    },
    {
      name: 'Lc Policy',
      type: 'number',
    },
    {
      name: 'Status',
      type: 'string',
    },
    {
      name: 'Department',
      type: 'string',
    },
    {
      name: 'Designation',
      type: 'string',
    },
    {
      name: 'Location',
      type: 'string',
    },
    {
      name: 'Employment Type',
      type: 'string',
    },
  ],
  handleFilter: () => {},
  totalRecordCount: 5,
  loadNextPageData: () => {},
  handleSort: () => {},
  handleRowClick: () => {},
  page: 1,
  rows: [
    {
      id: '1',
      employeeId: 1,
      employeeName: 'Sachin',
      lcPolicy: [
        { id: 0, name: 'Default Life Cycle Policy' },
        { id: 1, name: 'Life Cycle Policy 2022' },
        { id: 2, name: 'Updated Life Cycle Policy' },
        { id: 3, name: 'Description' },
      ],
      status: 'UnMapped',
      department: 'IT',
      designation: 'Design Head',
      location: 'Mumbai',
      employmentType: 'Permanent',
    },
    {
      id: '2',
      employeeId: 2,
      employeeName: 'Sachin',
      lcPolicy: [
        { id: 0, name: 'Default Life Cycle Policy' },
        { id: 1, name: 'Life Cycle Policy 2022' },
        { id: 2, name: 'Updated Life Cycle Policy' },
        { id: 3, name: 'Description' },
      ],
      status: 'UnMapped',
      department: 'IT',
      designation: 'Design Head',
      location: 'Mumbai',
      employmentType: 'Permanent',
    },
    {
      id: '3',
      employeeId: 3,
      employeeName: 'Sachin',
      lcPolicy: [
        { id: 0, name: 'Default Life Cycle Policy' },
        { id: 1, name: 'Life Cycle Policy 2022' },
        { id: 2, name: 'Updated Life Cycle Policy' },
        { id: 3, name: 'Description' },
      ],
      status: 'UnMapped',
      department: 'IT',
      designation: 'Design Head',
      location: 'Mumbai',
      employmentType: 'Permanent',
    },
    {
      id: '4',
      employeeId: 4,
      employeeName: 'Sachins',
      lcPolicy: [
        { id: 0, name: 'Default Life Cycle Policy' },
        { id: 1, name: 'Life Cycle Policy 2022' },
        { id: 2, name: 'Updated Life Cycle Policy' },
        { id: 3, name: 'Description' },
      ],
      status: 'UnMapped',
      department: 'IT',
      designation: 'Design Head',
      location: 'Mumbai',
      employmentType: 'Permanent',
    },
  ],
  sortDirection: 'asc',
  sortBy: '',
  handleCancel: () => {},
  handleApplyChanges: () => {},
  initialValues: { 1: 1, 2: '', 3: '', 4: '', 5: '' },
};
EmployeeUnmapped.propTypes = {
  filterFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  handleFilter: PropTypes.func,
  totalRecordCount: PropTypes.number,
  page: PropTypes.number,
  loadNextPageData: PropTypes.func,
  handleRowClick: PropTypes.func,
  handleSort: PropTypes.func,
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  sortBy: PropTypes.string,
  rows: PropTypes.arrayOf({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    employeeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    employeeName: PropTypes.string,
    lcPolicy: PropTypes.arrayOf({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    }),
    status: PropTypes.string,
    department: PropTypes.string,
    designation: PropTypes.string,
    location: PropTypes.string,
    employmentType: PropTypes.string,
  }),
  handleCancel: PropTypes.func,
  handleApplyChanges: PropTypes.func,
  initialValues: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};
export default EmployeeUnmapped;
