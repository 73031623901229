import React from 'react';
import PropTypes from 'prop-types';
import BlankTemplate from 'components/Templates/BlankTemplate';
import ProfileDependents from 'components/Organisms/Profile/Dependents';

const ProfileDependentTemplate = ({ details, handleEdit }) => {
  return (
    <BlankTemplate pageHeading="Dependents" isBreadcrumb={false} padding="2rem 3rem">
      <ProfileDependents details={details} handleEdit={handleEdit} />
    </BlankTemplate>
  );
};

ProfileDependentTemplate.defaultProps = {
  details: [],
  handleEdit: () => {},
};

ProfileDependentTemplate.propTypes = {
  details: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      gender: PropTypes.string,
      relation: PropTypes.string,
      dob: PropTypes.string,
    }),
  ),
  handleEdit: PropTypes.func,
};

export default ProfileDependentTemplate;
