import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import Button from 'components/Atoms/Button';
import { FilterDialog } from '../../../../../components/mui/FilterDialog';
import useColumns from './useColumns';

export const Filters = (props) => {
  const { disabled, filters, onFiltersApply, onFiltersClear } = props;
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [columns] = useColumns();
  const baseProperties = columns.filter((column) => column.type);
  return (
    <>
      <div>
        <Box
          sx={{
            alignItems: 'center',
            display: 'grid',
            justifyItems: 'flex-start',
            pr: 2,
          }}
        >
          <Button
            color="info"
            disabled={disabled}
            onClick={() => setOpenFilterDialog(true)}
            startIcon={<FilterListIcon />}
            variant="contained"
          >
            Filter
          </Button>
        </Box>
      </div>
      <FilterDialog
        onApply={onFiltersApply}
        onClear={onFiltersClear}
        onClose={() => setOpenFilterDialog(false)}
        open={openFilterDialog}
        properties={baseProperties}
      />
    </>
  );
};

Filters.defaultProps = {
  filters: [],
  disabled: false,
};

Filters.propTypes = {
  disabled: PropTypes.bool,
  filters: PropTypes.instanceOf(Array),
  onFiltersApply: PropTypes.func.isRequired,
  onFiltersClear: PropTypes.func.isRequired,
};
